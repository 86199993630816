import React from "react";
import { useTranslation } from "react-i18next";
import { translate } from "../../../globals/Common";
import MenuItem from "@material-ui/core/MenuItem";
import ShareOutlinedIcon from "@material-ui/icons/ShareOutlined";
import TitleMenu from "../../ui/titleMenu/TitleMenu";
import styles from "./RegistrationItem.module.css";
import Fab from "@material-ui/core/Fab";
import Tooltip from "@material-ui/core/Tooltip";
import PopMenu from "../../ui/popMenu/PopMenu";
import Divider from "@material-ui/core/Divider";
import DescriptionOutlinedIcon from "@material-ui/icons/DescriptionOutlined";
import ArchiveOutlinedIcon from "@material-ui/icons/ArchiveOutlined";
import UnarchiveOutlinedIcon from "@material-ui/icons/UnarchiveOutlined";
import PageviewOutlinedIcon from "@material-ui/icons/PageviewOutlined";
import LocationOnOutlinedIcon from "@material-ui/icons/LocationOnOutlined";
import OndemandVideoOutlinedIcon from "@material-ui/icons/OndemandVideoOutlined";
import LaptopMacOutlinedIcon from "@material-ui/icons/LaptopMacOutlined";
import EmailOutlinedIcon from "@material-ui/icons/EmailOutlined";
import Badge from "@material-ui/core/Badge";
import StarHalfIcon from "@material-ui/icons/StarHalf";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import SupervisorAccountIcon from "@material-ui/icons/SupervisorAccount";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import LockOpenOutlinedIcon from "@material-ui/icons/LockOpenOutlined";
import FlatButton from "../../ui/button/FlatButton";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { resolveCoverPhoto } from "../../../globals/Common";
import CustomIconButton from "../../ui/button/CustomIconButton";
import AssignmentOutlinedIcon from "@material-ui/icons/AssignmentOutlined";

const RegistrationItem = (props) => {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const dense = true;
  const useIconOnlyButton = useMediaQuery("(max-width:400px)");
  const hideProviderName = useMediaQuery("(max-width:500px)");

  // ------------------------------

  const renderShare = () => (
    <MenuItem
      dense={dense}
      onClick={() => {
        handleClose();
        props.onShareCourse();
      }}
    >
      <TitleMenu
        icon={<ShareOutlinedIcon />}
        title={t("registration_course_share")}
      />
    </MenuItem>
  );

  // ------------------------------

  const renderViewCourse = () => (
    <MenuItem
      dense={dense}
      onClick={() => {
        handleClose();
        props.onViewCourse();
      }}
    >
      <TitleMenu
        icon={<PageviewOutlinedIcon />}
        title={t("registration_view_course")}
      />
    </MenuItem>
  );

  // ------------------------------

  const renderInstruction = () => (
    <CustomIconButton
      icon={
        props.casting === "Classroom" ? (
          <LocationOnOutlinedIcon />
        ) : props.casting === "Online Video" ? (
          <OndemandVideoOutlinedIcon />
        ) : (
          <LaptopMacOutlinedIcon />
        )
      }
      text={
        props.casting === "Classroom"
          ? t("registration_course_location")
          : props.casting === "Online Video"
          ? t("registration_course_watch")
          : t("registration_course_stream")
      }
      onClick={() => {
        if (props.casting === "Classroom") {
          props.onOpenLocation();
        } else {
          props.onViewInstruction();
        }
      }}
    />
  );

  // ------------------------------

  const renderHandouts = () => (
    <CustomIconButton
      icon={<DescriptionOutlinedIcon />}
      text={t("registration_handout")}
      onClick={props.onViewHandout}
    />
  );

  // ------------------------------

  const renderCertificate = () => (
    <CustomIconButton
      icon={
        props.certificateEn || props.certificateFr ? (
          <LockOpenOutlinedIcon style={{ color: "green" }} />
        ) : (
          <LockOutlinedIcon />
        )
      }
      text={t("course_certificate")}
      onClick={props.onOpenCertificate}
    />
  );

  // ------------------------------

  const renderArchive = () => (
    <MenuItem
      dense={dense}
      onClick={() => {
        handleClose();
        props.onArchive();
      }}
    >
      <TitleMenu
        icon={
          props.archived ? <UnarchiveOutlinedIcon /> : <ArchiveOutlinedIcon />
        }
        title={
          props.archived
            ? t("registration_unarchive")
            : t("registration_archive")
        }
      />
    </MenuItem>
  );

  // ------------------------------

  const renderStaff = () => (
    <MenuItem
      dense={dense}
      onClick={() => {
        handleClose();
        props.onListRegistrants();
      }}
    >
      <TitleMenu
        icon={<SupervisorAccountIcon />}
        title={t("g_staff_registered")}
      />
    </MenuItem>
  );

  // ------------------------------

  const renderMessage = () => (
    <MenuItem
      dense={dense}
      onClick={() => {
        handleClose();
        props.onOpenInboxChat();
      }}
    >
      <TitleMenu
        icon={<EmailOutlinedIcon />}
        title={t("course_details_message")}
      />
    </MenuItem>
  );

  // ------------------------------

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  // ------------------------------

  const handleClose = () => {
    setAnchorEl(null);
  };

  // ------------------------------

  const renderActionButtons = () => {
    if (props.unregistered_date) {
      return (
        <div className={styles.bottomRow}>
          <Tooltip title="Delete" aria-label="Delete">
            <Fab color="default" size="small" onClick={props.onDelete}>
              <DeleteForeverIcon style={{ color: "#464E51" }} />
            </Fab>
          </Tooltip>
        </div>
      );
    }

    return (
      <div className={styles.bottomRow}>
        {renderInstruction()}
        {renderHandouts()}
        {renderCertificate()}
        &nbsp;
        <PopMenu
          anchorEl={anchorEl}
          handleClick={handleClick}
          handleClose={handleClose}
          regBtn
        >
          {renderShare()}
          {renderMessage()}
          {renderStaff()}
          {renderViewCourse()}
          <Divider />
          {renderArchive()}
        </PopMenu>
      </div>
    );
  };

  // ------------------------------

  return (
    <div className={styles.invisibleContainer} style={props.containerStyle}>
      <div
        className={styles.container}
        style={
          props.archived || props.unregistered_date
            ? { filter: "grayscale(50)" }
            : null
        }
      >
        <div className={styles.imageInfoContainer}>
          <div
            className={styles.imageContainer}
            style={{
              backgroundImage: props.image
                ? `url(${props.image})`
                : `url(${resolveCoverPhoto(props.course_title)})`,
            }}
            onClick={
              props.requiresRating ||
              (props.surveyToken && props.surveyToken !== "none")
                ? () => {}
                : props.onSelectCourse
                ? props.onSelectCourse
                : () => {}
            }
          >
            {props.requiresRating && (
              <Tooltip
                title={t("registration_rate")}
                aria-label={t("registration_rate")}
              >
                <div
                  style={{
                    width: "100%",
                    marginLeft: "10px",
                    marginTop: "5px",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "flex-start",
                  }}
                >
                  <FlatButton
                    style
                    icononly={useIconOnlyButton ? "true" : null}
                    onClick={props.onOpenRating}
                    label={t("registration_rate")}
                    icon={
                      <Badge
                        badgeContent={1}
                        color="error"
                        style={{ zIndex: 0 }}
                      >
                        <StarHalfIcon style={{ color: "orange" }} />
                      </Badge>
                    }
                  />
                </div>
              </Tooltip>
            )}

            {props.surveyToken && props.surveyToken !== "none" && (
              <Tooltip
                title={t("registration_evaluation")}
                aria-label={t("registration_evaluation")}
              >
                <div
                  style={{
                    width: "100%",
                    marginLeft: "10px",
                    marginTop: "5px",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "flex-end",
                  }}
                >
                  <FlatButton
                    style
                    icononly={useIconOnlyButton ? "true" : null}
                    onClick={props.onOpenSurvey}
                    label={t("registration_evaluation")}
                    icon={
                      <Badge
                        badgeContent={1}
                        color="primary"
                        style={{ zIndex: 0 }}
                      >
                        <AssignmentOutlinedIcon />
                      </Badge>
                    }
                  />
                </div>
              </Tooltip>
            )}
          </div>

          <div
            className={styles.infoContainer}
            onClick={props.onSelectCourse ? props.onSelectCourse : () => {}}
          >
            <div className={styles.date}>
              <div className={styles.headerTitleText}>
                {props.unregistered_date
                  ? t("g_unregistered")
                  : props.date === "Online"
                  ? t("cat_short_online")
                  : props.date === "TBD"
                  ? t("course_date_tbd")
                  : props.date}
              </div>

              {props.remaining ? (
                <div className={styles.headerTitleText}>{props.remaining}</div>
              ) : null}
            </div>

            <div className={styles.title}>
              {props.course_title} - {translate(t, props.casting)}
            </div>

            {hideProviderName ? null : (
              <div>
                {props.provider &&
                  t("cart_provided_by") + " " + props.provider.name}
              </div>
            )}
          </div>
        </div>

        {!props.hideActionButtons && renderActionButtons()}
      </div>
    </div>
  );
};

export default RegistrationItem;
