import React from "react";
import styles from "./MobileCartItem.module.css";
import PersonIcon from "@material-ui/icons/Person";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import IconButton from "@material-ui/core/IconButton";
import {
  resolveCurrency,
  resolveCurrencySymbol,
} from "../../../globals/Common";
import NumberFormat from "react-number-format";

const renderStudent = (props, student, index) => {
  return (
    <div key={index} className={styles.studentRow}>
      <div className={styles.studentColumn}>
        <PersonIcon
          className={styles.nameContainer}
          style={{ padding: "5px" }}
        />
        <div className={styles.studentTextBold}>{student.last}</div>
        {student.first}
      </div>

      <div className={styles.itemDataPrice}>
        {props.hasDiscount && (
          <div className={styles.strikeAmount}>
            <NumberFormat
              value={resolveCurrency(
                props.originalPrice,
                props.providerCurrency,
                props.rates
              )}
              displayType={"text"}
              thousandSeparator={true}
              prefix={resolveCurrencySymbol(props.setting.currency)}
            />
          </div>
        )}

        <div className={styles.amount}>
          <NumberFormat
            value={resolveCurrency(
              props.price,
              props.providerCurrency,
              props.rates
            )}
            displayType={"text"}
            thousandSeparator={true}
            prefix={resolveCurrencySymbol(props.setting.currency)}
          />
        </div>

        {props.deletable && (
          <div className={styles.deleteButton}>
            <IconButton
              edge="start"
              color="inherit"
              aria-label=""
              onClick={props.onRemove.bind(this, student.id)}
            >
              <DeleteForeverIcon style={{ color: "red" }} />
            </IconButton>
          </div>
        )}
      </div>
    </div>
  );
};

const MobileCartItem = (props) => {
  let renderStudents = [];
  Object.keys(props.staff).forEach((key, index) => {
    const student = props.staff[key];
    renderStudents.push(renderStudent(props, student, index));
  });

  return (
    <div className={styles.cartItem}>
      <div className={styles.studentContainer}>{renderStudents}</div>
      <div className={styles.itemData}>{props.option}</div>
    </div>
  );
};

export default MobileCartItem;
