import React, { useState } from "react";
import styles from "./Elements.module.css";
import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";
import Conversation from "./Conversation";
import TextButton from "../../../ui/button/TextButton";
import ChatBubbleOutlineOutlinedIcon from "@material-ui/icons/ChatBubbleOutlineOutlined";
import FlatList from "flatlist-react";
import Tooltip from "@material-ui/core/Tooltip";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Loader from "react-loader-spinner";

const ConversationList = (props) => {
  const editOnLargeScreen = useMediaQuery("(min-width:930px)");
  const [edit, setEdit] = useState(false);
  const [selectedConvo, setSelectedConvo] = useState([]);

  const deleteHandler = () => {
    props.onDeleteConversations(selectedConvo);
    setEdit(false);
    setSelectedConvo([]);
  };

  const cancelHandler = () => {
    setSelectedConvo([]);
    setEdit(false);
  };

  const renderConversation = (conversation, index) => {
    return (
      <Conversation
        isSelected={
          props.selectedConversation &&
          props.selectedConversation.id === conversation.id
            ? true
            : false
        }
        selectedForDelete={selectedConvo.some((c) => c.id === conversation.id)}
        edit={edit && editOnLargeScreen}
        leftPanel={props.leftPanel}
        key={index}
        conversation={conversation}
        isOdd={false}
        onSelectConversation={() => {
          props.onSelectConversation(conversation);
        }}
        updateDeleteSelection={() => {
          const convo = [...selectedConvo];
          if (selectedConvo.some((c) => c.id === conversation.id)) {
            setSelectedConvo(convo.filter((c) => c.id !== conversation.id));
          } else {
            convo.push(conversation);
            setSelectedConvo(convo);
          }
        }}
      />
    );
  };

  return (
    <div
      className={
        props.full ? styles.conversationListFull : styles.conversationList
      }
    >
      <div className={styles.conversationsTitle}>
        <div className={styles.row}>
          <ChatBubbleOutlineOutlinedIcon />
          &nbsp;Conversations ({props.conversations.length})
        </div>

        {props.onDeleteConversations &&
          editOnLargeScreen &&
          props.conversations &&
          props.conversations.length > 0 && (
            <div>
              {edit ? (
                <div className={styles.row}>
                  <Tooltip
                    title="Delete Selected Conversations"
                    aria-label="Delete Selected Conversations"
                  >
                    <IconButton
                      component="span"
                      edge="start"
                      aria-label="delete"
                      onClick={deleteHandler}
                      style={{ padding: "3px" }}
                      disabled={selectedConvo.length === 0}
                    >
                      <DeleteIcon
                        style={
                          selectedConvo.length === 0
                            ? { color: "#ccc" }
                            : { color: "blue" }
                        }
                      />
                    </IconButton>
                  </Tooltip>
                  <TextButton
                    onClick={cancelHandler}
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      height: "28px",
                    }}
                  >
                    &nbsp;&nbsp;Cancel
                  </TextButton>
                </div>
              ) : (
                <TextButton
                  onClick={setEdit.bind(this, !edit)}
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    height: "28px",
                  }}
                >
                  Edit
                </TextButton>
              )}
            </div>
          )}
      </div>

      {props.isDeleting ? (
        <div
          style={{
            width: "100%",
            height: "70%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Loader type="Oval" color="black" height={35} width={35} />
          Deleting
        </div>
      ) : (
        <FlatList
          list={props.conversations}
          renderItem={renderConversation}
          renderWhenEmpty={() => (
            <div style={{ fontSize: "small", padding: "20px" }}>
              No messages in your inbox.
            </div>
          )}
        />
      )}
    </div>
  );
};

export default ConversationList;
