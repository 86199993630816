import {
  momentShortDate,
  remainingDaysTime,
  isPastDate,
} from "../globals/Dates2";

class MobileRegistration {
  constructor(
    id,
    image,
    provider_id,
    order_ref_number,
    course_id,
    course_title,
    course_date,
    casting,
    audience,
    component,
    registered_date,
    unregistered_date,
    rated_date,
    provider_instruction,
    alert_identifier,
    alert_setting,
    archived,
    archived_date,
    zoom_id,
    zoom_join_url,
    survey_token,
    certificate_uri,
    certificate_uri_fr
  ) {
    this.id = id;
    this.image = image;
    this.provider_id = provider_id;
    this.order_ref_number = order_ref_number;
    this.course_id = course_id;
    this.course_title = course_title;
    this.course_date = course_date;
    this.casting = casting;
    this.audience = audience;
    this.component = component;
    this.registered_date = registered_date;
    this.unregistered_date = unregistered_date;
    this.rated_date = rated_date;
    this.provider_instruction = provider_instruction;
    this.alert_identifier = alert_identifier;
    this.alert_setting = alert_setting;
    this.archived = archived;
    this.archived_date = archived_date;
    this.zoom_id = zoom_id;
    this.zoom_join_url = zoom_join_url;
    this.survey_token = survey_token;
    this.certificate_uri = certificate_uri;
    this.certificate_uri_fr = certificate_uri_fr;
  }

  // ------------------------------

  renderRegistrationDate = () => {
    return momentShortDate(this.registered_date);
  };

  // ------------------------------

  renderUnregistrationDate = () => {
    if (this.unregistered_date) {
      return momentShortDate(this.unregistered_date);
    }

    return "";
  };

  // ------------------------------

  renderStartDate = () => {
    if (this.course_date) {
      return momentShortDate(this.course_date);
    }

    if (this.casting === "Online Video") {
      return "Online";
    }

    return "TBD";
  };

  // ------------------------------

  resolveDaysRemaining = () => {
    if (this.course_date) {
      return remainingDaysTime(this.course_date);
    }

    return "";
  };

  // ------------------------------

  hasPast = () => {
    if (this.course_date !== null) {
      return isPastDate(this.course_date, 1);
    }
  };

  // ------------------------------

  requiresRating = () => {
    if (this.rated_date !== null) {
      return false;
    }

    if (this.unregistered_date !== null) {
      return false;
    }

    if (this.course_date !== null) {
      return isPastDate(this.course_date, 2);
    }

    return isPastDate(this.registered_date, 2);
  };

  // ------------------------------
}

export default MobileRegistration;
