import React from "react";
import styles from "./Rating.module.css";
import StarRoundedIcon from "@material-ui/icons/StarRounded";
import StarHalfRoundedIcon from "@material-ui/icons/StarHalfRounded";
import StarOutlineRoundedIcon from "@material-ui/icons/StarOutlineRounded";
import { hasDecimal } from "../../../globals/Common";

const Rating = (props) => {
  const arrStar = [];

  for (let i = 1; i <= 5; i++) {
    if (props.rating >= i) {
      arrStar.push(
        <StarRoundedIcon
          key={i}
          style={{ color: "#ff6700" }}
          fontSize={props.fontSize}
        />
      );
    } else {
      if (hasDecimal(props.rating) && Math.round(props.rating) >= i) {
        arrStar.push(
          <StarHalfRoundedIcon
            key={i}
            style={{ color: "#ff6700" }}
            fontSize={props.fontSize}
          />
        );
      } else {
        arrStar.push(
          <StarOutlineRoundedIcon
            key={i}
            style={{ color: "gray" }}
            fontSize={props.fontSize}
          />
        );
      }
    }
  }

  return (
    <div className={styles.ratingContainer} style={props.style}>
      {arrStar}
    </div>
  );
};

export default Rating;
