import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import MatInputOutline from "../../../components/ui/input/MatInputOutline";
import Professor from "../../../model/Professor";
import MatSelectOutline from "../../../components/ui/labelSelect/MatSelectOutline";
import { selectPrefix } from "../../../globals/Data";
import { buildSingleSelect } from "../../../globals/Common";

const AddProfessorModal = (props) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("xs"));
  const fullWidth = true;
  const maxWidth = "sm";

  const [prefix, setPrefix] = useState("");
  const [first, setFirst] = useState("");
  const [professorName, setProfessorName] = useState("");

  const handleClose = (prefix, first, profressorName) => {
    if (profressorName) {
      props.onClose(
        new Professor(null, prefix, first, professorName, "", "", null)
      );
    } else {
      props.onClose();
    }
  };

  // ------------------------------

  const selectChangeHandler = (key, e, select) => {
    setPrefix(select ? select.value : "");
  };

  // ------------------------------

  const inputFirstHandler = (e) => {
    setFirst(e.target.value);
  };

  // ------------------------------

  const inputChangeHandler = (e) => {
    setProfessorName(e.target.value);
  };

  // ------------------------------

  return (
    <div>
      <Dialog
        fullScreen={fullScreen}
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={props.open}
        onClose={handleClose.bind(this, null)}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="alert-dialog-title">Add Professor</DialogTitle>

        <DialogContent>
          <MatSelectOutline
            title="Prefix"
            placeholder="Dr. / Mr. / Ms."
            name="prefix"
            id="prefix"
            onChange={selectChangeHandler.bind(this, "prefix")}
            options={selectPrefix}
            value={buildSingleSelect(prefix)}
            getOptionLabel={(option) => option.label}
            getOptionSelected={(opt1, opt2) => opt1.value === opt2.value}
            disabled={false}
            multiple={false}
            required={false}
          />

          <MatInputOutline
            nobottommargin="true"
            title="Professor First Name"
            name="first"
            value={first}
            onChange={inputFirstHandler}
            maxLength={50}
            readOnly={false}
          />

          <MatInputOutline
            nobottommargin="true"
            title="Professor Last Name"
            name="professorName"
            value={professorName}
            onChange={inputChangeHandler}
            maxLength={50}
            readOnly={false}
          />
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            onClick={handleClose.bind(this, null)}
            color="primary"
          >
            Cancel
          </Button>
          <Button
            autoFocus
            onClick={handleClose.bind(this, prefix, first, professorName)}
            color="primary"
            disabled={
              !professorName ||
              professorName.length === 0 ||
              !first ||
              first.length === 0 ||
              !prefix ||
              prefix.length === 0
            }
          >
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default AddProfessorModal;
