import { SET_MOBILE_ORDERS } from "../actions/mobileorders";
import { updateObject } from "../../globals/Common";
import { LOGOUT } from "../actions/auth";

const initialState = {
  orders: [],
  hasMore: true,
};

const mobileordersReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_MOBILE_ORDERS:
      return updateObject(state, {
        orders: action.orders,
        hasMore: action.hasMore,
      });

    case LOGOUT:
      return initialState;
    default:
      return state;
  }
};

export default mobileordersReducer;
