import React from "react";
import styles from "./LoadMore.module.css";
import { CircularProgress } from "@material-ui/core";
import RoundedButton from "../button/RoundedButton";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import { useTranslation } from "react-i18next";

const LoadMore = (props) => {
  const { t } = useTranslation();

  return props.hasMore && !props.loading ? (
    <RoundedButton
      onClick={props.onLoadMore}
      text={props.title ? props.title : t("g_load_more")}
      color={"rgb(28, 111, 211)"}
      icon={<ArrowDownwardIcon />}
    />
  ) : props.loading ? (
    <div className={styles.cirucularProgContainer}>
      <CircularProgress size={24} color="inherit" />
    </div>
  ) : (
    <div></div>
  );
};

export default LoadMore;
