import React from "react";
import styles from "../PricingScreen.module.css";
import { useTranslation } from "react-i18next";
import WbIncandescentOutlinedIcon from "@material-ui/icons/WbIncandescentOutlined";
import VerifiedUserOutlinedIcon from "@material-ui/icons/VerifiedUserOutlined";
import TouchAppIcon from "@material-ui/icons/TouchApp";
import DevicesIcon from "@material-ui/icons/Devices";
import AssignmentIcon from "@material-ui/icons/Assignment";
import EqualizerIcon from "@material-ui/icons/Equalizer";
import AssignmentIndIcon from "@material-ui/icons/AssignmentInd";
import RateReviewIcon from "@material-ui/icons/RateReview";
import PublicIcon from "@material-ui/icons/Public";
import PaymentIcon from "@material-ui/icons/Payment";
import SettingsIcon from "@material-ui/icons/Settings";
import InsertDriveFileIcon from "@material-ui/icons/InsertDriveFile";

const IncludedSlide = (props) => {
  const { t } = useTranslation();
  return (
    <div className={styles.pageContainerLight}>
      <div className={styles.centerTitleContainer}>
        <div className={styles.floatingTitle4}>
          {t("welcome_what_included_title")}
        </div>
      </div>

      <div className={styles.blubsContainer}>
        <div className={styles.blurbPriceContainer}>
          <div className={styles.blurbPriceTitle}>
            <WbIncandescentOutlinedIcon />
            &nbsp;&nbsp;{t("welcome_what_included_sub_1")}
          </div>
          <div className={styles.blurbPriceDescription}>
            {t("welcome_what_included_text_1")}
          </div>
        </div>

        <div className={styles.blurbPriceContainer}>
          <div className={styles.blurbPriceTitle}>
            <VerifiedUserOutlinedIcon />
            &nbsp;&nbsp;{t("welcome_what_included_sub_2")}
          </div>
          <div className={styles.blurbPriceDescription}>
            {t("welcome_what_included_text_2")}
          </div>
        </div>

        <div className={styles.blurbPriceContainer}>
          <div className={styles.blurbPriceTitle}>
            <TouchAppIcon />
            &nbsp;&nbsp;{t("welcome_what_included_sub_3")}
          </div>
          <div className={styles.blurbPriceDescription}>
            {t("welcome_what_included_text_3")}
          </div>
        </div>
        <div className={styles.blurbPriceContainer}>
          <div className={styles.blurbPriceTitle}>
            <DevicesIcon />
            &nbsp;&nbsp;{t("welcome_what_included_sub_4")}
          </div>
          <div className={styles.blurbPriceDescription}>
            {t("welcome_what_included_text_4")}
          </div>
        </div>
      </div>

      <div className={styles.spacer}>&nbsp;</div>

      <div className={styles.blubsContainer}>
        <div className={styles.blurbPriceContainer}>
          <div className={styles.blurbPriceTitle}>
            <AssignmentIcon />
            &nbsp;&nbsp;{t("welcome_what_included_sub_5")}
          </div>
          <div className={styles.blurbPriceDescription}>
            {t("welcome_what_included_text_5")}
          </div>
        </div>

        <div className={styles.blurbPriceContainer}>
          <div className={styles.blurbPriceTitle}>
            <EqualizerIcon />
            &nbsp;&nbsp;{t("welcome_what_included_sub_6")}
          </div>
          <div className={styles.blurbPriceDescription}>
            {t("welcome_what_included_text_6")}
          </div>
        </div>

        <div className={styles.blurbPriceContainer}>
          <div className={styles.blurbPriceTitle}>
            <AssignmentIndIcon />
            &nbsp;&nbsp;{t("welcome_what_included_sub_7")}
          </div>
          <div className={styles.blurbPriceDescription}>
            {t("welcome_what_included_text_7")}
          </div>
        </div>
        <div className={styles.blurbPriceContainer}>
          <div className={styles.blurbPriceTitle}>
            <InsertDriveFileIcon />
            &nbsp;&nbsp;{t("welcome_what_included_sub_8")}
          </div>
          <div className={styles.blurbPriceDescription}>
            {t("welcome_what_included_text_8")}
          </div>
        </div>
      </div>

      <div className={styles.spacer}>&nbsp;</div>

      <div className={styles.blubsContainer}>
        <div className={styles.blurbPriceContainer}>
          <div className={styles.blurbPriceTitle}>
            <PublicIcon />
            &nbsp;&nbsp;{t("welcome_what_included_sub_9")}
          </div>
          <div className={styles.blurbPriceDescription}>
            {t("welcome_what_included_text_9")}
          </div>
        </div>

        <div className={styles.blurbPriceContainer}>
          <div className={styles.blurbPriceTitle}>
            <PaymentIcon />
            &nbsp;&nbsp;{t("welcome_what_included_sub_10")}
          </div>
          <div className={styles.blurbPriceDescription}>
            {t("welcome_what_included_text_10")}
          </div>
        </div>

        <div className={styles.blurbPriceContainer}>
          <div className={styles.blurbPriceTitle}>
            <SettingsIcon />
            &nbsp;&nbsp;{t("welcome_what_included_sub_11")}
          </div>
          <div className={styles.blurbPriceDescription}>
            {t("welcome_what_included_text_11")}
          </div>
        </div>
        <div className={styles.blurbPriceContainer}>
          <div className={styles.blurbPriceTitle}>
            <RateReviewIcon />
            &nbsp;&nbsp;{t("welcome_what_included_sub_12")}
          </div>
          <div className={styles.blurbPriceDescription}>
            {t("welcome_what_included_text_12")}
          </div>
        </div>
      </div>
    </div>
  );
};

export default IncludedSlide;
