import React, { useState } from "react";
import Drawer from "@material-ui/core/Drawer";
import Divider from "@material-ui/core/Divider";
import { makeStyles } from "@material-ui/core/styles";
import TuneIcon from "@material-ui/icons/Tune";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import DateSelect from "../../../components/ui/dateSelect/DateSelect";
import MatSelectOutline from "../../../components/ui/labelSelect/MatSelectOutline";
import { addDays } from "../../../globals/Dates2";

// ------------------------------

const useStyles = makeStyles({
  paper: {
    background: "white",
    border: 0,
    boxShadow: "0px 0px 15px gray",
    width: 400,
  },
});

// ------------------------------

const FilterDrawer = (props) => {
  const styles = useStyles();
  const [date, setDate] = useState(props.date ? props.date : new Date());
  const [courseSel, setCourseSel] = useState((props.selectedCourse: null));

  // ------------------------------

  const toggleDrawer = () => (event) => {
    props.close(date, courseSel ? courseSel.course_id : null);
  };

  // ------------------------------

  const resetSelection = () => {
    setDate(new Date());
    setCourseSel(null);
  };

  // ------------------------------
  const selectChangeHandler = (e, select) => {
    if (select) {
      setCourseSel({
        course_id: select.value,
        course_title: select.label,
      });
    } else {
      setCourseSel(null);
    }
  };

  // ------------------------------

  return (
    <Drawer
      classes={{ paper: styles.paper }}
      anchor="right"
      open={props.open}
      onClose={toggleDrawer()}
      variant={null}
    >
      <div role="presentation">
        <div
          style={{
            cursor: "pointer",
            color: "#437aa1",
            paddingLeft: "14px",
            paddingTop: "16px",
            display: "flex",
            alignItems: "center",
            fontStyle: "italic",
          }}
          onClick={toggleDrawer()}
        >
          <TuneIcon style={{ fontSize: "25px", marginRight: "10px" }} />
          Settings
        </div>

        <div
          style={{
            paddingLeft: "20px",
            paddingRight: "20px",
            paddingTop: "10px",
            paddingBottom: "20px",
          }}
        >
          <DateSelect
            labelLong
            title="Start month / year"
            dateFormat="MMMM/yyyy"
            showMonthYearPicker
            showFullMonthYearPicker
            selected={date}
            maxDate={addDays(new Date(), 30)}
            onChange={(date) => {
              setDate(new Date(date));
            }}
          />

          <div
            style={{
              fontSize: "13px",
              textAlign: "justify",
              paddingTop: "10px",
              paddingLeft: "5px",
              width: "100%",
              color: "gray",
            }}
          >
            Analytics are shown over a period of one year. A start month/year of
            March 2019 will return analytics between March 1 2019 and April 30
            2020 inclusively.
          </div>
        </div>

        <div
          style={{
            paddingLeft: "20px",
            paddingRight: "20px",
            paddingTop: "20px",
            paddingBottom: "10px",
          }}
        >
          <MatSelectOutline
            double
            zeroMargin="true"
            title="Analytics by course"
            placeholder="Enter course..."
            name="course_id"
            id="course_id"
            onChange={selectChangeHandler}
            options={props.selectCourses}
            value={
              courseSel
                ? {
                    value: courseSel.course_id,
                    label: courseSel.course_title,
                  }
                : null
            }
            getOptionLabel={(option) => option.label}
            getOptionSelected={(opt1, opt2) => opt1.value === opt2.value}
            disabled={false}
            multiple={false}
            required={false}
          />

          <div
            style={{
              fontSize: "13px",
              textAlign: "justify",
              paddingTop: "10px",
              paddingLeft: "5px",
              width: "100%",
              color: "gray",
            }}
          >
            Course specific analytics. By default analytics are show as the sum
            of all courses unless specified here.
          </div>
        </div>
        <div style={{ height: "40px" }}>&nbsp;</div>
        <Divider />
        <DialogActions>
          <Button autoFocus onClick={resetSelection} color="primary">
            Reset
          </Button>
        </DialogActions>
      </div>
    </Drawer>
  );
};

export default FilterDrawer;
