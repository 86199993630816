import {
  SET_PROVIDER,
  SET_PROVIDER_COURSES,
  SET_SELECTED_PROVIDER,
  SET_PROVIDER_SELECTED_COURSE,
  SET_FOLLOWED_PROVIDERS,
} from "../actions/provider";
import { LOGOUT } from "../actions/auth";

const initialState = {
  selected_provider: null,
  selected_course: null,
  provider: [],
  courses: [],
  following: [],
};

const providerReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_SELECTED_PROVIDER:
      return {
        ...state,
        selected_provider: action.selected_provider,
      };
    case SET_PROVIDER_SELECTED_COURSE:
      return {
        ...state,
        selected_course: action.selected_course,
      };
    case SET_PROVIDER:
      return {
        ...state,
        provider: action.provider,
      };
    case SET_PROVIDER_COURSES:
      return {
        ...state,
        courses: action.courses,
      };
    case SET_FOLLOWED_PROVIDERS:
      return {
        ...state,
        following: action.following,
      };
    case LOGOUT:
      return initialState;
    default:
      return state;
  }
};

export default providerReducer;
