import Ce from "../../model/Ce";
import { handleErrorResponse } from "../../globals/Error";
import firebase from "firebase/app";
import { resolveCurrency } from "../../globals/Common";
import { dbName } from "../../globals/ApiKeys";

export const ADD_CE = "ADD_CE";
export const UPDATE_CE = "UPDATE_CE";
export const UPDATE_CE_URI = "UPDATE_CE_URI";
export const REMOVE_CE = "REMOVE_CE";
export const SET_CE = "SET_CE";
export const UPDATE_CE_HAS_URI = "UPDATE_CE_HAS_URI";
export const SET_REPORT = "SET_REPORT";
export const SET_CE_OBJ = "SET_CE_OBJ";

// ------------------------------

export const setEditAction = (ceObj) => {
  return {
    type: SET_CE_OBJ,
    ceObj: ceObj,
  };
};

// ------------------------------

export const fetchCeByYear = (fromYear, toYear, fromDate, toDate, badge) => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;

    if (!token) {
      return dispatch({
        type: SET_CE,
        ce: {
          ce: [],
          pending: 0,
          pendingLecture: 0,
          pendingHandsOn: 0,
          pendingCost: 0,
          completed: 0,
          completedLecture: 0,
          completedHandsOn: 0,
          completedCost: 0,
        },
      });
    }

    const userId = getState().auth.userId;
    const userProfile = getState().profile.userProfile;
    const rates = getState().rate.rate;

    const arrResponse = await Promise.all([
      fetch(
        `https://${dbName}.firebaseio.com/ce/${userId}.json?auth=${token}?&orderBy="courseYear"&startAt=${Number(fromYear)}&endAt=${Number(toYear)}`
      ),
      fetch(
        `https://${dbName}.firebaseio.com/ce/${userId}.json?auth=${token}?&orderBy="courseYear"&startAt=${new Date().getFullYear()}`
      ),
    ]);

    if (!arrResponse[0].ok) {
      handleErrorResponse(await arrResponse[0].json()); // completed
    }

    if (!arrResponse[1].ok) {
      handleErrorResponse(await arrResponse[1].json()); // pending
    }

    const resCompletedData = await arrResponse[0].json();
    const resPendingData = await arrResponse[1].json();

    const resData = { ...resCompletedData };
    const loadedCe = [];
    if (resData) {
      Object.keys(resData).forEach((key, index) => {
        const ce = resData[key];
        if (typeof resData[key] === "object") {
          loadedCe.push(
            new Ce(
              key,
              ce.title,
              ce.description,
              ce.notes ? ce.notes : "",
              ce.lecture_hours,
              ce.practice_hours,
              ce.credits,
              ce.category
                ? Array.isArray(ce.category)
                  ? ce.category
                  : [ce.category]
                : [],
              ce.organizer,
              ce.courseDate,
              ce.entryDate,
              ce.courseId,
              ce.price,
              ce.currency ? ce.currency : "",
              ce.hasCertificate ? ce.hasCertificate : "",
              ""
            )
          );
        }
      });
    }

    // ------------------------------

    let filterByDate = [...loadedCe];

    if (fromDate && toDate) {
      filterByDate = loadedCe.filter(
        (ce) =>
          new Date(ce.courseDate).getTime() > fromDate.getTime() &&
          new Date(ce.courseDate).getTime() < toDate.getTime()
      );
    }

    // ------------------------------

    let filteredByBadge = [...filterByDate];

    if (badge) {
      filteredByBadge = filteredByBadge.filter(
        (ce) => ce.category.indexOf(badge) !== -1
      );
    }

    if (resPendingData) {
      Object.keys(resPendingData).forEach((key, index) => {
        const ce = resPendingData[key];
        if (typeof resPendingData[key] === "object") {
          if (new Date(ce.courseDate).getTime() >= new Date().getTime()) {
            if (filteredByBadge.findIndex((c) => c.id === key) === -1) {
              filteredByBadge.push(
                new Ce(
                  key,
                  ce.title,
                  ce.description,
                  ce.notes ? ce.notes : "",
                  ce.lecture_hours,
                  ce.practice_hours,
                  ce.credits,
                  ce.category
                    ? Array.isArray(ce.category)
                      ? ce.category
                      : [ce.category]
                    : [],
                  ce.organizer,
                  ce.courseDate,
                  ce.entryDate,
                  ce.courseId,
                  ce.price,
                  ce.currency ? ce.currency : "",
                  "",
                  ""
                )
              );
            }
          }
        }
      });
    }

    const sortedCeByDate = filteredByBadge.sort((a, b) =>
      new Date(a.courseDate).getTime() > new Date(b.courseDate).getTime()
        ? 1
        : -1
    );

    const pending = calcPendingCe(sortedCeByDate, userProfile.isODQ());
    const completed = calcCompletedCe(sortedCeByDate, userProfile.isODQ());
    const pendingCost = calcPendingCost(sortedCeByDate, rates);
    const pendingLecture = calcPendingHours(sortedCeByDate, "lecture_hours");
    const pendingHandsOn = calcPendingHours(sortedCeByDate, "practice_hours");
    const completedCost = calcCompletedCost(sortedCeByDate, rates);
    const completedLecture = calcCompHours(sortedCeByDate, "lecture_hours");
    const completedHandsOn = calcCompHours(sortedCeByDate, "practice_hours");

    dispatch({
      type: SET_CE,
      ce: {
        ce: sortedCeByDate,
        pending: pending,
        pendingLecture: pendingLecture,
        pendingHandsOn: pendingHandsOn,
        pendingCost: pendingCost,
        completed: completed,
        completedLecture: completedLecture,
        completedHandsOn: completedHandsOn,
        completedCost: completedCost,
      },
    });
  };
};

// -------------------------------------

export const fetchCeReport = (fromDate, toDate) => {
  return async (dispatch, getState) => {
    const userId = getState().auth.userId;
    const token = getState().auth.token;
    const rates = getState().rate.rate;
    const userProfile = getState().userProfile.userProfile;

    const newFromDate = new Date(fromDate);
    const newToDate = new Date(toDate);
    const fromYear = newFromDate.getFullYear();
    const toYear = newToDate.getFullYear();

    const response = await fetch(
      `https://${dbName}.firebaseio.com/ce/${userId}.json?auth=${token}?&orderBy="courseYear"&startAt=${fromYear}&endAt=${toYear}`
    );

    if (!response.ok) {
      handleErrorResponse(await response.json());
    }

    const resData = await response.json();

    const loadedCe = [];

    if (resData) {
      const keys = Object.keys(resData);
      for (const key of keys) {
        if (typeof resData[key] === "object") {
          const ce = resData[key];

          const certDownloadUri = await getImageURI(userId, key);

          loadedCe.push(
            new Ce(
              key,
              ce.title,
              ce.description,
              ce.notes ? ce.notes : "",
              ce.lecture_hours,
              ce.practice_hours,
              ce.credits,
              ce.category
                ? Array.isArray(ce.category)
                  ? ce.category
                  : [ce.category]
                : [],
              ce.organizer,
              ce.courseDate,
              ce.entryDate,
              ce.courseId,
              ce.price,
              ce.currency ? ce.currency : "",
              true,
              certDownloadUri
            )
          );
        }
      }
    }

    const filterByDate = loadedCe.filter(
      (ce) =>
        new Date(ce.courseDate).getTime() > newFromDate.getTime() &&
        new Date(ce.courseDate).getTime() < newToDate.getTime()
    );

    const sortedCeByDate = filterByDate.sort((a, b) =>
      new Date(a.courseDate).getTime() > new Date(b.courseDate).getTime()
        ? 1
        : -1
    );

    // find all unique accreditations then calcualte points for each

    const uniqueAccreditations = [];

    for (const ce of sortedCeByDate) {
      const accreditationsOfThisCourse = ce.category;
      for (const accreditation of accreditationsOfThisCourse) {
        if (uniqueAccreditations.indexOf(accreditation) === -1) {
          uniqueAccreditations.push(accreditation);
        }
      }
    }

    const completed = calcCompletedCe(sortedCeByDate, userProfile.isODQ());
    const cost = calcCompletedCost(sortedCeByDate, rates);
    const lectureHours = calcCompHours(sortedCeByDate, "lecture_hours");
    const handsOnHours = calcCompHours(sortedCeByDate, "practice_hours");

    const ce_stats_by_accreditation = {};
    for (const accreditation of uniqueAccreditations) {
      const coursesAcc = sortedCeByDate.filter((ce) => {
        return ce.category.indexOf(accreditation) > -1;
      });

      const completedPerAcc = calcCompletedCe(coursesAcc, userProfile.isODQ());
      const costPerAcc = calcCompletedCost(coursesAcc, rates);
      const lectureHoursPerAcc = calcCompHours(coursesAcc, "lecture_hours");
      const handsOnHoursPerAcc = calcCompHours(coursesAcc, "practice_hours");

      ce_stats_by_accreditation[accreditation] = {
        completed: completedPerAcc,
        cost: costPerAcc,
        lectureHours: lectureHoursPerAcc,
        handsOnHours: handsOnHoursPerAcc,
      };
    }

    dispatch({
      type: SET_REPORT,
      reportCe: sortedCeByDate,
      reportSummary: {
        completed: completed,
        cost: cost,
        lectureHours: lectureHours,
        handsOnHours: handsOnHours,
        ce_stats_by_accreditation: ce_stats_by_accreditation,
      },
    });
  };
};

// -------------------------------------

export const fetchCePicture = (ceId) => {
  return async (dispatch, getState) => {
    const userId = getState().auth.userId;
    const token = getState().auth.token;

    if (ceId) {
      const certificateUri = await getImageURI(userId, ceId);
      dispatch({
        type: UPDATE_CE_URI,
        ce: { id: ceId, uri: certificateUri },
      });

      return certificateUri;
    }

    return "";
  };
};

// -------------------------------------

export const fetchCePictureMetadata = (ceId) => {
  return async (dispatch, getState) => {
    const userId = getState().auth.userId;
    const token = getState().auth.token;

    if (ceId) {
      const imageMeta = await getImageMetaData(userId, ceId);

      if (imageMeta && imageMeta.fullPath) {
        dispatch({
          type: UPDATE_CE_HAS_URI,
          ce: { id: ceId, hasCertificate: true },
        });
      }

      return imageMeta;
    }

    return null;
  };
};

// -------------------------------------

export const addCe = (ce, certs) => {
  return async (dispatch, getState) => {
    const userId = getState().auth.userId;
    const token = getState().auth.token;

    let courseDate = null;
    if (ce.courseDate) {
      courseDate = new Date(ce.courseDate);
    } else {
      courseDate = new Date();
    }

    try {
      const response = await fetch(
        `https://${dbName}.firebaseio.com/ce/${userId}.json?auth=${token}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "applications/json",
          },
          body: JSON.stringify({
            title: ce.title,
            description: ce.description,
            notes: ce.notes,
            lecture_hours: ce.lecture_hours,
            practice_hours: ce.practice_hours,
            credits: ce.credits,
            category: ce.category,
            organizer: ce.organizer,
            courseDate: courseDate,
            entryDate: new Date(),
            courseId: ce.courseId,
            price: ce.price,
            currency: ce.currency,
            courseYear: courseDate.getFullYear(),
            hasCertificate: !certs || certs.length === 0 ? false : true,
          }),
        }
      );

      if (!response.ok) {
        handleErrorResponse(await response.json());
      }

      const resData = await response.json();

      for (const cert of certs) {
        if (cert.file) {
          await dispatch(uploadCeCertificate(userId, resData.name, cert));
        }
      }

      dispatch({
        type: ADD_CE,
        ce: { ce: ce, id: resData.name },
      });
    } catch (error) {
      throw error;
    }
  };
};

// ------------------------------

export const updateCe = (ce, certs) => {
  return async (dispatch, getState) => {
    const ceId = ce.id;
    const userId = getState().auth.userId;
    const token = getState().auth.token;

    let courseDate = null;
    if (ce.courseDate) {
      courseDate = new Date(ce.courseDate);
    } else {
      courseDate = new Date();
    }

    try {
      await fetch(
        `https://${dbName}.firebaseio.com/ce/${userId}/${ceId}.json?auth=${token}`,
        {
          method: "PATCH",
          headers: {
            "Content-Type": "applications/json",
          },
          body: JSON.stringify({
            title: ce.title,
            description: ce.description,
            notes: ce.notes,
            lecture_hours: ce.lecture_hours,
            practice_hours: ce.practice_hours,
            credits: ce.credits,
            category: ce.category,
            organizer: ce.organizer,
            courseDate: courseDate,
            entryDate: ce.entryDate,
            courseId: ce.courseId,
            price: ce.price,
            currency: ce.currency,
            courseYear: courseDate.getFullYear(),
            hasCertificate: !certs || certs.length === 0 ? false : true,
          }),
        }
      );

      if (!certs || certs.length === 0) {
        await dispatch(deleteCeCertificate(ceId, false));
      }

      else {
        for (const cert of certs) {
          if (cert.file) {
            await dispatch(uploadCeCertificate(userId, ceId, cert));
          }
        }
      }

      dispatch({
        type: UPDATE_CE,
        ce: ce,
      });
    } catch (error) {
      throw error;
    }
  };
};

// ------------------------------

export const removeCe = (ceId) => {
  return async (dispatch, getState) => {
    const userId = getState().auth.userId;
    const token = getState().auth.token;
    const rates = getState().rate.rate;
    const currentCe = [...getState().ce.ce];

    await fetch(
      `https://${dbName}.firebaseio.com/ce/${userId}/${ceId}.json?auth=${token}`,
      {
        method: "DELETE",
      }
    );

    const newCeArr = currentCe.filter((ce) => {
      return ce.id !== ceId;
    });

    const pending = calcPendingCe(newCeArr);
    const completed = calcCompletedCe(newCeArr);
    const pendingCost = calcPendingCost(newCeArr, rates);
    const pendingLecture = calcPendingHours(newCeArr, "lecture_hours");
    const pendingHandsOn = calcPendingHours(newCeArr, "practice_hours");
    const completedCost = calcCompletedCost(newCeArr, rates);
    const completedLecture = calcCompHours(newCeArr, "lecture_hours");
    const completedHandsOn = calcCompHours(newCeArr, "practice_hours");

    dispatch({
      type: REMOVE_CE,
      ce: {
        ce: newCeArr,
        pending: pending,
        pendingLecture: pendingLecture,
        pendingHandsOn: pendingHandsOn,
        pendingCost: pendingCost,
        completed: completed,
        completedLecture: completedLecture,
        completedHandsOn: completedHandsOn,
        completedCost: completedCost,
      },
    });

    dispatch(deleteCeCertificate(ceId, true));
  };
};

// ------------------------------

const uploadCeCertificate = (userId, ceId, cert) => {
  return async (dispatch, getState) => {
    const file = cert.file;

    if (file && (file.type === "application/pdf" || file.type === "image/png" || file.type === "image/jpg")) {
      let ref = firebase
        .storage()
        .ref()
        .child(`images/${userId}/${ceId}_certificate`);

      await ref.put(file).then(function (snap) {
        snap.ref
          .getDownloadURL()
          .then(function (downloadUrl) {
            try {
              cert.uri = downloadUrl;
              dispatch({
                type: UPDATE_CE_URI,
                ce: { id: ceId, uri: downloadUrl },
              });

              delete cert.file; // prevent from reupload on next save
            } catch (err) {
              logger.error(err);
            }
            // ----------
          })
          .catch(function (error) {
            logger.error(error);
          });
      });
    }
  };
};


// ------------------------------

export const deleteCeCertificate = (ceId, isDeleteRecord) => {
  return async (dispatch, getState) => {
    const userId = getState().auth.userId;

    try {
      await firebase
        .storage()
        .ref()
        .child(`images/${userId}/${ceId}_certificate`)
        .delete();
    } catch (error) {
      // not found do nothing
    }

    if (!isDeleteRecord) {
      dispatch({
        type: UPDATE_CE_URI,
        ce: { id: ceId, uri: null },
      });

      dispatch({
        type: UPDATE_CE_HAS_URI,
        ce: { id: ceId, hasCertificate: null },
      });
    }
  };
};

// ------------------------------

const getImageURI = async function (userId, ceId) {
  try {
    const pathToImage = `images/${userId}/${ceId}_certificate`;
    return await firebase.storage().ref().child(pathToImage).getDownloadURL();
  } catch (error) {
    return "";
  }
};

// ------------------------------

const getImageMetaData = async function (userId, ceId) {
  const pathToImage = `images/${userId}/${ceId}_certificate`;
  try {
    return await firebase.storage().ref().child(pathToImage).getMetadata();
  } catch (error) {
    return "";
  }
};

// ------------------------------

const calculate = (a, b, isOdq) => {
  return parseFloat(a) + parseFloat(b.resolveCredits(isOdq));
};

// ------------------------------

const calcPendingCe = (ces, isOdq) => {
  const pendingCE = ces.filter(
    (ce) => new Date(ce.courseDate).getTime() >= new Date().getTime()
  );

  let pendingTotal = pendingCE.reduce((a, b, index) => {
    return calculate(a, b, isOdq);
  }, 0);

  return pendingTotal;
};

// ------------------------------

const calcCompletedCe = (ces, isOdq) => {
  const completedCE = ces.filter(
    (ce) => new Date(ce.courseDate).getTime() < new Date().getTime()
  );

  const completedTotal = completedCE.reduce((a, b, index) => {
    return calculate(a, b, isOdq);
  }, 0);

  return completedTotal;
};

// ------------------------------

const calcPendingCost = (ces, rates) => {
  const pendingCE = ces.filter(
    (ce) => new Date(ce.courseDate).getTime() >= new Date().getTime()
  );

  const pendingPriceTotal = pendingCE.reduce((a, b, index) => {
    const bPrice = resolveCurrency(b.price ? b.price : 0, b.currency, rates);

    return (
      parseFloat(a) +
      (parseFloat(bPrice).toFixed(2) * 100) / 100
    ).toFixed(2);
  }, 0);

  return pendingPriceTotal === 0
    ? pendingPriceTotal.toFixed(2)
    : pendingPriceTotal;
};

// ------------------------------

const calcCompletedCost = (ces, rates) => {
  const completedCE = ces.filter(
    (ce) => new Date(ce.courseDate).getTime() < new Date().getTime()
  );

  const completedPriceTotal = completedCE.reduce((a, b, index) => {
    const bPrice = resolveCurrency(b.price ? b.price : 0, b.currency, rates);

    return (
      parseFloat(a) +
      (parseFloat(bPrice).toFixed(2) * 100) / 100
    ).toFixed(2);
  }, 0);

  return completedPriceTotal === 0
    ? completedPriceTotal.toFixed(2)
    : completedPriceTotal;
};

// ------------------------------

const calcCompHours = (ces, field) => {
  const completedCE = ces.filter(
    (ce) => new Date(ce.courseDate).getTime() < new Date().getTime()
  );

  const total = completedCE.reduce((a, b, index) => {
    return parseFloat(a) + parseFloat(b[field] ? b[field] : 0);
  }, 0);

  return total;
};

// ------------------------------

const calcPendingHours = (ces, field) => {
  const pendingCE = ces.filter(
    (ce) => new Date(ce.courseDate).getTime() >= new Date().getTime()
  );

  const total = pendingCE.reduce((a, b, index) => {
    return parseFloat(a) + parseFloat(b[field] ? b[field] : 0);
  }, 0);

  return total;
};

// ------------------------------
