import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import styles from "./NavigationItem.module.css";
import Collapse from "@material-ui/core/Collapse";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import NotificationsNoneIcon from "@material-ui/icons/NotificationsNone";
import IconButton from "@material-ui/core/IconButton";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";

const useStyles = makeStyles((theme) => ({
  root: {
    width: 300,
    maxWidth: 460,
    backgroundColor: theme.palette.background.paper,
  },
  listItem: {
    padding: 0,
    cursor: "pointer",
  },
  primary: {
    fontSize: "13px",
  },
  secondary: {
    fontSize: "12px",
  },
}));

const NotificationItem = (props) => {
  const classes = useStyles();
  const [showAlerts, setShowAlerts] = useState(false);

  let arrStyles = [styles.navigationItem];
  if (props.topBar) {
    arrStyles.push(styles.smallFont);
  }

  if (props.mobile) {
    arrStyles.push(styles.mobileOnly);
  }

  const notifClickedHandler = (alert) => {
    if (alert.link) {
      props.goTo(alert.link);
      props.removeAlert(alert.id);
    } else {
      props.removeAlert(alert.id);
    }
  };

  return (
    <li
      className={arrStyles.join(" ")}
      onClick={setShowAlerts.bind(this, true)}
      onMouseEnter={setShowAlerts.bind(this, true)}
      onMouseLeave={setShowAlerts.bind(this, false)}
    >
      <NavLink exact to={"#"}>
        {props.children}
      </NavLink>

      <Collapse
        in={
          props.alertNotifications &&
          props.alertNotifications.length > 0 &&
          showAlerts
        }
      >
        <div className={styles.eventListContainer}>
          <List className={classes.root}>
            {props.alertNotifications.map((alert, index) => (
              <ListItem className={classes.listItem} key={index}>
                <ListItemAvatar onClick={notifClickedHandler.bind(this, alert)}>
                  <Avatar>
                    <NotificationsNoneIcon fontSize="inherit" />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={alert.description}
                  secondary={alert.formatEventDate()}
                  primaryTypographyProps={{ className: classes.primary }}
                  secondaryTypographyProps={{ className: classes.secondary }}
                  onClick={notifClickedHandler.bind(this, alert)}
                />

                {alert.link && (
                  <IconButton
                    color="default"
                    aria-label="link"
                    component="span"
                    onClick={notifClickedHandler.bind(this, alert)}
                  >
                    <ArrowForwardIcon />
                  </IconButton>
                )}

                <IconButton
                  color="default"
                  aria-label="delete"
                  component="span"
                  onClick={props.removeAlert.bind(this, alert.id)}
                >
                  <HighlightOffIcon />
                </IconButton>
              </ListItem>
            ))}
          </List>
        </div>
      </Collapse>
    </li>
  );
};

export default NotificationItem;
