import React, { Component } from "react";
import { connect } from "react-redux";
import * as paymentActions from "../../store/actions/payment";
import * as connectActions from "../../store/actions/connect";
import * as studentOrderActions from "../../store/actions/studentorder";
import * as profileActions from "../../store/actions/profile";
import * as settingActions from "../../store/actions/setting";
import InfoModal from "../../components/modals/infoModal/InfoModal";
import Spinner from "../../components/ui/spinner/Spinner";
import BankAccounts from "../../components/items/balanceItems/BankAccounts";
import BalanceTable from "../../components/items/balanceItems/BalanceTable";
import BalanceSummary from "../../components/items/balanceItems/BalanceSummary";
import FundAccountModal from "../../components/modals/fundAccountModal/FundAccountModal";
import { objectHasNoValues } from "../../globals/Common";
import * as logger from "../../globals/Logger";

class ConnectDashboardScreen extends Component {
  state = {
    wait: false,
    waitNoRender: false,
    error: null,
    success: null,
    openFundModal: false,
    spinMessage: "",
    tableLoading: false,
    waitBalance: false,
    waitBank: false,
    waitBalanceSheet: false,
  };

  // ------------------------------

  componentDidMount = async () => {
    window.scrollTo(0, 0);
  };

  // ------------------------------

  update = async (propValue) => {
    await this.setState({ ...this.state, ...propValue });
  };

  // ------------------------------

  componentDidMount = async () => {
    try {
      await this.update({
        error: null,
        wait: true,
        spinMessage: "Loading Account Balance",
        waitBank: true,
        waitBalance: true,
        waitBalanceSheet: true,
      });

      this.fetchBankBackgroundProcess();
      this.fetchBalanceBackgroundProcess();
      this.fetchBalanceSheetBackgroundProcess();

      const loadAsync = [];

      if (objectHasNoValues(this.props.profile)) {
        loadAsync.push(this.props.fetchProfile());
      }

      if (objectHasNoValues(this.props.setting)) {
        loadAsync.push(this.props.fetchSetting());
      }

      if (objectHasNoValues(this.props.paymentMethods)) {
        loadAsync.push(this.props.fetchPaymentMethods());
      }

      await Promise.all(loadAsync);

      await this.update({ wait: false, spinMessage: "" });
    } catch (error) {
      logger.error(error);
      await this.update({
        wait: false,
        error: error.message,
      });
    }
  };

  // ------------------------------

  fetchBankBackgroundProcess = () => {
    this.props
      .fetchStripeBank()
      .then(() => {
        this.update({ waitBank: false });
      })
      .catch((error) => {
        this.update({ waitBank: false });
        logger.error(error);
      });
  };

  // ------------------------------

  fetchBalanceBackgroundProcess = () => {
    this.props
      .fetchStripeBalance()
      .then(() => {
        this.update({ waitBalance: false });
      })
      .catch((error) => {
        this.update({ waitBalance: false });
        logger.error(error);
      });
  };

  // ------------------------------

  fetchBalanceSheetBackgroundProcess = () => {
    this.props
      .fetchBalanceSheet()
      .then(() => {
        this.update({ waitBalanceSheet: false });
      })
      .catch((error) => {
        this.update({ waitBalanceSheet: false });
        logger.error(error);
      });
  };

  // ------------------------------

  goToStripeDash = async () => {
    await this.update({
      waitNoRender: true,
      spinMessage: "Opening Stripe Dashboard",
    });
    await this.props.fetchDashboardLink();
    this.update({ waitNoRender: false, spinMessage: "" });

    if (
      this.props.dashboardLink &&
      this.props.dashboardLink.url.startsWith(
        "https://connect.stripe.com/express/"
      )
    ) {
      const myWidth = 600;
      const myHeight = 700;

      const width = window.innerWidth;
      const height = window.innerHeight;

      const left = (width - myWidth) / 2;
      const top = (height - myHeight) / 4;

      const refWin = window.open(
        this.props.dashboardLink.url,
        "mywindow",
        `directories=no,titlebar=no,toolbar=no,location=no,status=no,menubar=no,resizable=1,width=${myWidth},height=${myHeight},top=${top},left=${left}`
      );
      if (refWin === null) {
        await this.update({
          error:
            "Dashboard popup was blocked by your browser, please allow popups in your browser settings.",
        });
      } else {
        refWin.moveTo(left, top);
        this.props.dismissAccountWarnings();
      }
    }
  };

  // ------------------------------

  changePageHandler = async () => {
    const objectId = this.props.cursorLast;

    try {
      await this.update({ error: null, tableLoading: true });
      await this.props.fetchBalanceSheet("next_page", objectId);
      await this.update({ tableLoading: false });
    } catch (error) {
      logger.error(error);
      await this.update({
        tableLoading: false,
        error: error.message,
      });
    }
  };

  // ------------------------------

  fundAccountBalanceHandler = async () => {
    await this.update({
      wait: true,
    });
    await this.update({
      openFundModal: true,
    });
    await this.update({
      wait: false,
    });
  };

  // ------------------------------

  fundAccountBalanceConfirmed = async (amount, pmId) => {
    try {
      await this.update({ waitNoRender: true, spinMessage: "Processing" });
      await this.props.fundAccountBalance(amount, pmId);

      await Promise.all([
        this.props.fetchBalanceSheet(),
        this.props.fetchStripeBalance(),
      ]);

      await this.update({
        waitNoRender: false,
        spinMessage: "",
        success:
          "Account Balance funded successfully for an amount of " +
          this.props.currency.shortName +
          amount,
      });
    } catch (error) {
      logger.error(error);
      await this.update({
        waitNoRender: false,
        spinMessage: "",
        error: error.message,
      });
    }
  };

  // ------------------------------

  closeErrorHandler = () => {
    this.update({
      error: null,
      success: null,
    });
  };

  // ------------------------------

  editStudentOrderHandler = async (data) => {
    if (data.chargeId) {
      await this.props.setChargeId(data.chargeId, null);
      this.props.history.push("/studentorder");
    }
  };

  // ------------------------------

  render() {
    if (this.state.wait) {
      return <Spinner message={this.state.spinMessage} />;
    }

    return (
      <div>
        {this.state.waitNoRender && (
          <Spinner message={this.state.spinMessage} />
        )}
        {this.state.error && (
          <InfoModal
            isErrorModal={true}
            title="An error has occured"
            message={this.state.error}
            open={this.state.error !== null}
            onClose={this.closeErrorHandler}
          />
        )}
        {this.state.success && (
          <InfoModal
            isErrorModal={false}
            title="Add Funds Completed"
            message={this.state.success}
            open={this.state.success !== null}
            onClose={this.closeErrorHandler}
          />
        )}
        {this.state.openFundModal && (
          <FundAccountModal
            currency={this.props.currency}
            open={this.state.openFundModal}
            paymentMethods={this.props.paymentMethods}
            onClose={async (amount, paymentMethodId) => {
              await this.update({ openFundModal: false });
              if (amount && paymentMethodId) {
                await this.fundAccountBalanceConfirmed(amount, paymentMethodId);
              }
            }}
            onAddPaymentMethod={async () => {
              await this.update({ openFundModal: false });
              this.props.history.push("/addpayment");
            }}
          />
        )}
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            marginBottom: "10px",
            marginTop: "20px",
            flexWrap: "wrap",
          }}
        >
          <BalanceSummary
            wait={this.state.waitBalance}
            total={this.props.total}
            available={this.props.available}
            pending={this.props.pending}
            inTransit={this.props.inTransit}
            onAddFunds={this.fundAccountBalanceHandler}
          />
          &nbsp;&nbsp;&nbsp;
          <BankAccounts
            wait={this.state.waitBank}
            bankAccounts={this.props.bankAccount}
            onEdit={this.goToStripeDash}
            accountWarnings={this.props.accountWarnings}
          />
        </div>
        <BalanceTable
          wait={this.state.waitBalanceSheet}
          title={"Recent Activity"}
          data={this.props.balanceList}
          changePage={this.changePageHandler}
          hasMore={this.props.hasMore}
          tableLoading={this.state.tableLoading}
          editStudentOrder={this.editStudentOrderHandler}
        />
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    profile: state.profile.profile,
    setting: state.setting.setting,
    paymentMethods: state.payment.payment,
    currency: state.profile.profile.currency,
    accountWarnings:
      Object.keys(state.connect.account).length > 0 &&
      state.connect.account.accountWarnings(),
    bankAccount: state.connect.bankAccount,
    balanceList: state.connect.balanceList,
    total: state.connect.total,
    available: state.connect.available,
    pending: state.connect.pending,
    inTransit: state.connect.inTransit,
    dashboardLink: state.connect.dashboardLink,
    action: props.history.action,
    cursorFirst: state.connect.cursorFirst,
    cursorLast: state.connect.cursorLast,
    hasMore: state.connect.hasMore,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchSetting: () => dispatch(settingActions.fetchSetting()),
    fetchProfile: () => dispatch(profileActions.fetchProfile()),
    fetchPaymentMethods: () => dispatch(paymentActions.fetchPaymentMethods()),
    fetchDashboardLink: () => dispatch(connectActions.fetchDashboardLink()),
    setChargeId: (chargeId, courseId) =>
      dispatch(studentOrderActions.setChargeId(chargeId, courseId)),
    fundAccountBalance: (amount, pmId) =>
      dispatch(connectActions.fundAccountBalance(amount, pmId)),
    fetchStripeBalance: () => dispatch(connectActions.fetchStripeBalance()),

    dismissAccountWarnings: () =>
      dispatch(connectActions.dismissAccountWarnings()),
    fetchStripeBank: () => dispatch(connectActions.fetchStripeBank()),
    fetchBalanceSheet: (pageAction, objectId) =>
      dispatch(connectActions.fetchBalanceSheet(pageAction, objectId)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ConnectDashboardScreen);
