import React from "react";
import EmailOutlinedIcon from "@material-ui/icons/EmailOutlined";
import InputModal from "../inputModal/InputModal";

const ProviderMessageModal = (props) => {
  return (
    <InputModal
      hideInfoIcon
      open={props.open}
      title={props.title ? props.title : "Student Message"}
      titleIcon={<EmailOutlinedIcon />}
      message={
        <div>
          Send a message to&nbsp;
          <b>
            <u>all registered students</u>
          </b>{" "}
          of this course. Students will receive a mobile text and/or email. They
          will be able to reply from the ZubU app.{" "}
          <b>Please specify the course title </b>
          as students may be registered to multiple courses from the same
          provider.
        </div>
      }
      okText={"Send"}
      placeholder="Enter your message"
      onClose={props.onClose}
    />
  );
};

export default ProviderMessageModal;
