import React, { Component } from "react";
import styles from "./Legal.module.css";
import { connect } from "react-redux";
import { renderGeneralTermsOfUse } from "./templateEn/GeneralHtml";
import { renderGeneralTermsOfUseFr } from "./templateFr/GeneralHtmlFr";
import BackButtonBanner from "../../components/ui/backButtonBanner/BackButtonBanner";

class GeneralScreen extends Component {
  state = {};

  // ------------------------------

  componentDidMount = () => {
    window.scrollTo(0, 0);
  };

  // ------------------------------

  render() {
    return (
      <div>
        {this.props.showHeader && (
          <BackButtonBanner history={this.props.history} />
        )}
        <div className={styles.screen}>
          {this.props.fr === true
            ? renderGeneralTermsOfUseFr()
            : renderGeneralTermsOfUse()}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  let showHeader = state.auth.token === null;
  let fr = null;

  const query = new URLSearchParams(props.location.search);

  for (let param of query.entries()) {
    if (param[0] === "header" && param[1] === "false") {
      showHeader = false;
    } else if (param[0] === "lang" && param[1] === "fr") {
      fr = true;
    }
  }

  return {
    showHeader: showHeader,
    fr: fr,
  };
};

export default connect(mapStateToProps, null)(GeneralScreen);
