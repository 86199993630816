import React from "react";
import styles from "./Rating.module.css";
import Rating from "@material-ui/lab/Rating";

const Rater = (props) => {
  return (
    <div className={styles.raterContainer}>
      <div className={styles.raterTitle}>{props.title}</div>
      <div>
        <Rating
          size="large"
          name={props.name}
          value={props.value}
          onChange={(event, newValue) => {
            props.onChange(newValue);
          }}
        />
      </div>
    </div>
  );
};

export default Rater;
