class InstallmentStatus {
  constructor(
    id,
    date,
    amount,
    status,
    chargeId,
    ccExpiryDate,
    ccLast4,
    ccBrand,
    ccName
  ) {
    this.id = id;
    this.date = date;
    this.status = status;
    this.amount = amount;
    this.chargeId = chargeId;
    this.ccExpiryDate = ccExpiryDate;
    this.ccLast4 = ccLast4;
    this.ccBrand = ccBrand;
    this.ccName = ccName;
  }
}

export default InstallmentStatus;
