import {
  SET_PROMO_CODE,
  SET_PROMO_ID,
  ADD_PROMO,
  UPDATE_PROMO,
  REMOVE_PROMO,
} from "../actions/promo";
import { LOGOUT } from "../actions/auth";
import Promo from "../../model/Promo";
import { updateObject } from "../../globals/Common";

const initialState = {
  promo: [],
  promoId: null,
};

const promoReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_PROMO_CODE:
      return updateObject(state, {
        promo: action.promo,
      });
    case SET_PROMO_ID:
      return updateObject(state, { promoId: action.promoId });
    case ADD_PROMO:
      const newPromo = new Promo(
        action.promo.id,
        action.promo.promo.promo_code,
        action.promo.promo.discount,
        action.promo.promo.usageLimit,
        action.promo.promo.courses,
        action.promo.promo.users,
        action.promo.promo.start_date,
        action.promo.promo.end_date
      );
      return updateObject(state, { promo: state.promo.concat(newPromo) });
    case UPDATE_PROMO:
      const pIndex = state.promo.findIndex((p) => p.id === action.promo.id);

      const updatePromo = new Promo(
        action.promo.id,
        action.promo.promo_code,
        action.promo.discount,
        action.promo.usageLimit,
        action.promo.courses,
        action.promo.users,
        action.promo.start_date,
        action.promo.end_date
      );
      const updatedPromo = [...state.promo];
      updatedPromo[pIndex] = updatePromo;
      return updateObject(state, { promo: updatedPromo });
    case REMOVE_PROMO:
      const promoAfterDel = [...state.promo];

      const promoIdToDelete = action.id;

      const itemIndex = promoAfterDel.findIndex(
        (it) => it.id === promoIdToDelete
      );

      promoAfterDel.splice(itemIndex, 1);

      return updateObject(state, { promo: promoAfterDel });
    case LOGOUT:
      return initialState;
    default:
      return state;
  }
};

export default promoReducer;
