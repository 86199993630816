import {
  SET_STUDENTS,
  UNREGISTER_STUDENT,
  STUDENT_CERT_SENT,
  STUDENT_SURVEY_SENT,
} from "../actions/student";
import { updateObject } from "../../globals/Common";
import Student from "../../model/Student";
import { LOGOUT } from "../actions/auth";

const initialState = {
  students: [],
};

const studentReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_STUDENTS:
      return updateObject(state, { students: action.students.students });
    case UNREGISTER_STUDENT:
      const copyItemsDelete = [...state.students];
      const studentId = action.studentId;
      const itemIndex = copyItemsDelete.findIndex((it) => it.id === studentId);

      const copyStudent = { ...copyItemsDelete[itemIndex] };

      const unregStudent = new Student(
        copyStudent.id,
        copyStudent.first,
        copyStudent.last,
        copyStudent.license,
        copyStudent.occupation,
        copyStudent.email,
        copyStudent.registeredById,
        copyStudent.registeredByName,
        copyStudent.registrationOption,
        copyStudent.registeredDate,
        copyStudent.charge_id,
        copyStudent.orderRefNumber,
        true,
        new Date(),
        action.reason,
        copyStudent.courseId,
        copyStudent.certificate_sent,
        copyStudent.survey_sent,
        copyStudent.survey_completed,
        copyStudent.member_id
      );

      copyItemsDelete[itemIndex] = unregStudent;
      return updateObject(state, { students: copyItemsDelete });
    case STUDENT_CERT_SENT:
      const copyStudentsCert = [...state.students];
      const studentIds = action.studentIds;

      for (const studentId of studentIds) {
        const itemIndex = copyStudentsCert.findIndex(
          (it) => it.id === studentId
        );

        if (itemIndex !== -1) {
          const copyStudent = { ...copyStudentsCert[itemIndex] };

          const updateCertStudent = new Student(
            copyStudent.id,
            copyStudent.first,
            copyStudent.last,
            copyStudent.license,
            copyStudent.occupation,
            copyStudent.email,
            copyStudent.registeredById,
            copyStudent.registeredByName,
            copyStudent.registrationOption,
            copyStudent.registeredDate,
            copyStudent.charge_id,
            copyStudent.orderRefNumber,
            copyStudent.isUnregistered,
            copyStudent.unregisteredDate,
            copyStudent.unregisteredReason,
            copyStudent.courseId,
            new Date(),
            copyStudent.survey_sent,
            copyStudent.survey_completed,
            copyStudent.member_id
          );

          copyStudentsCert[itemIndex] = updateCertStudent;
        }
      }

      return updateObject(state, { students: copyStudentsCert });
    case STUDENT_SURVEY_SENT:
      const copyStudentsSurvey = [...state.students];

      const itemIndexSurvey = copyStudentsSurvey.findIndex(
        (it) => it.id === action.studentId
      );

      if (itemIndexSurvey !== -1) {
        const copyStudent = { ...copyStudentsSurvey[itemIndexSurvey] };

        const updateSurveyStudent = new Student(
          copyStudent.id,
          copyStudent.first,
          copyStudent.last,
          copyStudent.license,
          copyStudent.occupation,
          copyStudent.email,
          copyStudent.registeredById,
          copyStudent.registeredByName,
          copyStudent.registrationOption,
          copyStudent.registeredDate,
          copyStudent.charge_id,
          copyStudent.orderRefNumber,
          copyStudent.isUnregistered,
          copyStudent.unregisteredDate,
          copyStudent.unregisteredReason,
          copyStudent.courseId,
          copyStudent.certificate_sent,
          new Date(),
          copyStudent.survey_completed,
          copyStudent.member_id
        );

        copyStudentsSurvey[itemIndexSurvey] = updateSurveyStudent;
      }

      return updateObject(state, { students: copyStudentsSurvey });
    case LOGOUT:
      return initialState;
    default:
      return state;
  }
};

export default studentReducer;
