import React, { Fragment } from "react";
import styles from "./BackButtonBanner.module.css";
import Button from "@material-ui/core/Button";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import Grow from "@material-ui/core/Grow";
import Zoom from "@material-ui/core/Zoom";
import MatAppBar from "../../../components/ui/appBar/MatAppBar";
import zubu_white from "../../../assets/images/welcome/zubu_white.png";

const BackButtonBanner = (props) => {
  const loginStyle = {
    color: "white",
    fontSize: "21px",
    fontFamily: "Inter",
  };

  return (
    <Fragment>
      <MatAppBar
        static
        classBar={{
          position: "fixed",
          top: 0,
          background: "#3E8CAB",
          zIndex: 115,
        }}
        left={
          <Grow
            in={true}
            style={{ transformOrigin: "0 0 0" }}
            {...(true ? { timeout: 1000 } : {})}
          >
            <div>
              <img src={zubu_white} style={{ height: "80px" }} alt="" />
            </div>
          </Grow>
        }
        right={
          <div className={styles.titleBtnContainer}>
            <Zoom in={true} style={{ transitionDelay: true ? "300ms" : "0ms" }}>
              <Button
                style={loginStyle}
                onClick={props.history.goBack}
                startIcon={<ArrowBackIcon style={{ fontSize: "30px" }} />}
              >
                Back
              </Button>
            </Zoom>
          </div>
        }
      />
      <div style={{ height: "90px" }}>&nbps;</div>
    </Fragment>
  );
};

export default BackButtonBanner;
