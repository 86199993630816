import {
  SET_CERT,
  SET_CERT_ID,
  ADD_CERT,
  REMOVE_CERT,
  UPDATE_CERT,
  UPDATE_CERT_HEADER,
  UPDATE_CERT_FOOTER,
  SET_LINKED_COURSES,
  REMOVE_LINKED_COURSE,
} from "../actions/certificate";
import Certificate from "../../model/Certificate";
import { updateObject } from "../../globals/Common";
import { LOGOUT } from "../actions/auth";

const initialState = {
  certificate: [],
  certificateId: null,
  certificateLink: [],
};

const certificateReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_LINKED_COURSES:
      return updateObject(state, {
        certificateLink: action.certificateLink,
      });
    case REMOVE_LINKED_COURSE: {
      const certLinks = [...state.certificateLink];
      const certLinkIdToDelete = action.id;

      const itemIndex = certLinks.findIndex(
        (it) => it.certId === certLinkIdToDelete
      );

      certLinks.splice(itemIndex, 1);
      return updateObject(state, { certificateLink: certLinks });
    }
    case SET_CERT:
      return updateObject(state, {
        certificate: action.certificate,
      });
    case SET_CERT_ID:
      return updateObject(state, { certificateId: action.certificateId });
    case ADD_CERT:
      const newCert = new Certificate(
        action.certificate.id,
        action.certificate.title,
        "",
        ""
      );
      return updateObject(state, {
        certificate: state.certificate.concat(newCert),
      });
    case UPDATE_CERT:
      const sIndex = state.certificate.findIndex(
        (p) => p.id === action.certificate.id
      );
      const updateCert = new Certificate(
        action.certificate.id,
        action.certificate.title,
        action.certificate.certificate_header,
        action.certificate.certificate_footer
      );
      const updatedCertificates = [...state.certificate];
      updatedCertificates[sIndex] = updateCert;
      return updateObject(state, { certificate: updatedCertificates });
    case UPDATE_CERT_HEADER: {
      const sIndex = state.certificate.findIndex(
        (p) => p.id === action.certificate.id
      );

      const updatedCertificates = [...state.certificate];

      if (sIndex > -1) {
        const copyCert = updatedCertificates[sIndex];

        const updateCert = new Certificate(
          action.certificate.id,
          action.certificate.title,
          action.certificate.certificate_header,
          copyCert.footer
        );

        updatedCertificates[sIndex] = updateCert;
        return updateObject(state, { certificate: updatedCertificates });
      } else {
        const newCert = new Certificate(
          action.certificate.id,
          action.certificate.title,
          action.certificate.certificate_header,
          ""
        );

        return updateObject(state, {
          certificate: state.certificate.concat(newCert),
        });
      }
    }
    case UPDATE_CERT_FOOTER: {
      const sIndex = state.certificate.findIndex(
        (p) => p.id === action.certificate.id
      );

      const updatedCertificates = [...state.certificate];

      if (sIndex > -1) {
        const copyCert = updatedCertificates[sIndex];

        const updateCert = new Certificate(
          action.certificate.id,
          action.certificate.title,
          copyCert.header,
          action.certificate.certificate_footer
        );

        updatedCertificates[sIndex] = updateCert;
        return updateObject(state, { certificate: updatedCertificates });
      } else {
        const newCert = new Certificate(
          action.certificate.id,
          action.certificate.title,
          "",
          action.certificate.certificate_footer
        );

        return updateObject(state, {
          certificate: state.certificate.concat(newCert),
        });
      }
    }
    case REMOVE_CERT:
      const certAfterDel = [...state.certificate];
      const certIdToDelete = action.id;

      const itemIndex = certAfterDel.findIndex(
        (it) => it.id === certIdToDelete
      );

      certAfterDel.splice(itemIndex, 1);

      return updateObject(state, { certificate: certAfterDel });
    case LOGOUT:
      return initialState;
    default:
      return state;
  }
};

export default certificateReducer;
