import { ADD_TO_CART, REMOVE_FROM_CART, CLEAR_CART } from "../actions/cart";
import { LOGOUT } from "../actions/auth";
import CartItem from "../../model/CartItem";

const initialState = {
  items: [],
  currency: null,
  totalAmount: 0,
};

const cartReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_TO_CART:
      let items = [...state.items];
      let total = state.totalAmount;
      const newItem = action.cartItem;

      const cartItem = new CartItem(
        newItem.course_id,
        newItem.course_title,
        newItem.start_date,
        newItem.num_of_weeks,
        newItem.prime,
        newItem.prime_cost,
        newItem.cost,
        newItem.addCategory,
        newItem.slide,
        newItem.headline,
        newItem.callForAction,
        newItem.showLogo,
        newItem.logoUri,
        newItem.locations,
        newItem.excludeLocations,
        newItem.experience,
        newItem.occupation
      );

      items = items.concat(cartItem);
      total = total + newItem.prime_cost + newItem.cost;

      return {
        ...state,
        items: items,
        totalAmount: total,
        currency: newItem.currency,
      };
    case REMOVE_FROM_CART:
      const copyItemsDelete = [...state.items];

      const courseId = action.courseId;

      const itemIndex = copyItemsDelete.findIndex(
        (it) => it.course_id === courseId
      );

      const primeCostSubstract = copyItemsDelete[itemIndex].prime_cost;
      const feactureCostSubtract = copyItemsDelete[itemIndex].cost;
      copyItemsDelete.splice(itemIndex, 1);

      return {
        ...state,
        items: copyItemsDelete,
        totalAmount:
          state.totalAmount - primeCostSubstract - feactureCostSubtract,
      };
    case CLEAR_CART:
      return initialState;
    case LOGOUT:
      return initialState;
    default:
      return state;
  }
};

export default cartReducer;
