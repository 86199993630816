import React from "react";
import { useTranslation } from "react-i18next";
import styles from "./ProviderItem.module.css";
import StorefrontOutlinedIcon from "@material-ui/icons/StorefrontOutlined";
import LinesEllipsis from "react-lines-ellipsis";
import responsiveHOC from "react-lines-ellipsis/lib/responsiveHOC";
const ResponsiveEllipsis = responsiveHOC()(LinesEllipsis);
import CourseCardRating from "../../ui/rating/CourseCardRating";

const ProviderItem = (props) => {

  if (props.storefront) {
    return (
      <div className={styles.storefrontContainer}>
        {props.logo ? (
          <img src={props.logo} alt="" className={styles.storefrontImage} />
        ) : (
          <div className={styles.emptyLogo}>
            <StorefrontOutlinedIcon fontSize="inherit" />
          </div>
        )}
        <div style={{ paddingLeft: "15px", paddingRight: "10px" }}>
          <div className={styles.providerName} style={{ textAlign: "left" }}>
            {props.name}
          </div>
          {props.rating && props.rating.rating_count > 0 ? (
            <CourseCardRating
              rating={props.rating.averageRating()}
              rating_count={props.rating.rating_count}
            />
          ) : (
            <CourseCardRating rating={0} rating_count={0} />
          )}
        </div>
      </div>
    );
  }

  if (props.readOnly) {
    return (
      <div className={props.addToCart ? styles.containerNoHeight : styles.container}>
        {props.logo ? (
          <img src={props.logo} alt="" className={styles.image} />
        ) : (
          <div className={styles.emptyLogo}>
            <StorefrontOutlinedIcon fontSize="inherit" />
          </div>
        )}
        <div className={styles.providerName}>{props.name}</div>
      </div>
    );
  }

  return (
    <div className={styles.clickContainer} onClick={props.onViewCourses}>
      {props.logo ? (
        <img src={props.logo} alt="" className={styles.image} />
      ) : (
        <div className={styles.emptyLogo}>
          <StorefrontOutlinedIcon fontSize="inherit" />
        </div>
      )}
      <div className={styles.providerName}>
        <ResponsiveEllipsis
          text={props.name}
          maxLine="3"
          ellipsis="..."
          basedOn="letters"
        />
      </div>
    </div>
  );
};

export default ProviderItem;
