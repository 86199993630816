import React from "react";
import styles from "./CertificatePreviewModal.module.css";
import Button from "@material-ui/core/Button";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import CertificatePreview from "../../ui/certificatePreview/CertificatePreview";
import pdfImage from "../../../assets/images/inApp/pdf_icon.png";

const CertificatePreviewModal = (props) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("xs"));
  const fullWidth = true;
  const maxWidth = "md";

  // ------------------------------

  const handleClose = (answer) => {
    props.onClose(answer);
  };

  // ------------------------------

  return (
    <div>
      <Dialog
        fullScreen={fullScreen}
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={true}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="alert-dialog-title">
          Certificate of Completion Preview
        </DialogTitle>

        <DialogContent>
          <div className={styles.container}>
            <CertificatePreview
              course={props.course}
              profile={props.profile}
              header={props.header}
              footer={props.footer}
            />

            <div className={styles.content}>
              <div>
                <div>
                  <b>Send</b> student(s) the certificate of completion for this
                  course. Certificate will be sent in{" "}
                  <u>PDF format via email.</u>
                </div>
                <div className={styles.pdf}>
                  <img src={pdfImage} alt="" style={{ height: "50px" }} />
                </div>
              </div>

              <div
                style={{
                  paddingTop: "10px",
                }}
              >
                Course title:{" "}
                <span className={styles.courseTitle}>
                  {props.course.title}{" "}
                </span>
              </div>

              <span
                style={{
                  fontWeight: "bold",
                  display: "block",
                  paddingTop: "15px",
                }}
              >
                Send certificate now?
              </span>
            </div>
          </div>
        </DialogContent>

        <DialogActions>
          <Button autoFocus onClick={handleClose.bind(this, "cencel")}>
            Cancel
          </Button>
          <Button
            onClick={handleClose.bind(this, "ok")}
            color="primary"
            style={{ color: "white", backgroundColor: "#3E8CAB" }}
          >
            Send
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default CertificatePreviewModal;
