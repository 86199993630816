import { momentLongDateTime } from "../globals/Dates2";

class Refund {
  constructor(
    id,
    amount,
    chargeId,
    date,
    currency,
    reason,
    status,
    memberRefundObj
  ) {
    this.id = id;
    this.amount = amount;
    this.chargeId = chargeId;
    this.date = date;
    this.currency = currency;
    this.reason = reason;
    this.status = status;
    this.memberRefundObj = memberRefundObj; // breakdown per line
  }

  format_date = () => {
    return momentLongDateTime(this.date);
  };
}

export default Refund;
