import * as logger from "./Logger";
import EN from "../language/En";
import FR from "../language/Fr";

// ------------------------------

const MAXIMUM_ONE_REG_OPTION = "Maximum of one registration option per course";
const PAYMENT_DUPLICATE_REGISTRATION =
  "Already registered. Please update your cart and try again:";
const INVALID_PROMO_CODE =
  "Invalid promo code.  Promo code is expired, invalid or usage limit exceeded.";
const PAYMENT_PROCESS_FAILED =
  "Payment Process Failed.  Please try again at a later time.  If the problem persists please contact support.";
const PAYMENT_EXPIRED_REGISTRATION =
  "One of your selected registration options has expired.  Please select again.";
const PAYMENT_CAPACITY_LIMIT =
  "This course is at or reaching its capacity limit.";
const PERMISSION_DENIED =
  "Access denied.  Please try again later or contact your ZubU administrator.";

// ------------------------------

export const handleErrorResponse = (errorResonseData, locale) => {
  const lang = { language: locale };

  if (errorResonseData[0] && errorResonseData[0].error.code === 401) {
    throw new Error("Unauthorized Access");
  }

  if (
    errorResonseData[0] &&
    errorResonseData[0].error.code === 403 &&
    errorResonseData[0].error.status === "PERMISSION_DENIED"
  ) {
    throw new Error("Permission denied");
  }

  const errorId = errorResonseData.error.message;

  const status = errorResonseData.error.status;

  let message = trans("error_default_message", lang);

  if (errorId === "EMAIL_EXISTS") {
    message =
      "An account on the ZubU platform is associated to this email address.  Enter a different email address or use forgot password for account recovery.";
  }

  if (errorId === "WEAK_PASSWORD") {
    message = errorId;
  }

  if (errorId === "EMAIL_NOT_FOUND") {
    message = "User name and/or password invalid";
  }

  if (errorId === "INVALID_EMAIL" || errorId === "INVALID_PASSWORD") {
    message = "User name and/or password invalid";
  }

  if (errorResonseData.error === "Permission denied") {
    message = "Permission denied";
  }

  if (errorResonseData.error === "Auth token is expired") {
    message = "Auth token is expired";
  }

  if (status === "FAILED_PRECONDITION") {
    message = errorId;
  }

  if (errorId.startsWith("Funding Account")) {
    message = errorId;
  }

  if (errorId.startsWith("Error course cancellation:  Please refund")) {
    message = errorId;
  }

  if (errorId.startsWith("Invalid login")) {
    message = errorId;
  }

  if (errorId.startsWith("Invalid sign up")) {
    message = errorId;
  }

  if (
    errorId.startsWith(
      "A provider account is already associated to this signup code"
    )
  ) {
    message = errorId;
  }

  if (errorId.startsWith("Invalid promo code")) {
    message = errorId;
  }

  if (errorId.startsWith("Recaptcha failed")) {
    message = errorId;
  }

  // ------------------------------

  if (errorId === PAYMENT_PROCESS_FAILED) {
    message = errorId;
  }

  // ------------------------------

  if (errorId === PAYMENT_EXPIRED_REGISTRATION) {
    message = errorId;
  }

  // ------------------------------

  if (errorId === INVALID_PROMO_CODE) {
    message = errorId;
  }

  // ------------------------------

  if (errorId.startsWith(MAXIMUM_ONE_REG_OPTION)) {
    message = errorId;
  }

  // ------------------------------

  if (errorId.startsWith(PAYMENT_DUPLICATE_REGISTRATION)) {
    message = errorId;
  }

  // ------------------------------

  if (errorId.startsWith(PAYMENT_CAPACITY_LIMIT)) {
    message = errorId;
  }

  throw new Error(message);
};

// ------------------------------

export const clientFacingError = (errorMessage, context, locale) => {
  // ------------------------------

  if (context === "payment_edit_screen") {
    return trans("error_payment", locale);
  }

  // ------------------------------

  if (errorMessage.startsWith(MAXIMUM_ONE_REG_OPTION)) {
    return (
      trans("error_duplicate_reg_option", locale) + errorMessage.split(":")[1]
    );
  }

  // ------------------------------

  if (errorMessage.startsWith(PAYMENT_CAPACITY_LIMIT)) {
    const params = errorMessage.split(":");

    return (
      trans("error_capacity_limit", locale) +
      params[1] +
      trans("error_capacity_limit_2", locale) +
      " (" +
      params[2] +
      ") " +
      trans(params[3], locale).toLowerCase() +
      "."
    );
  }

  // ------------------------------

  if (errorMessage.startsWith(PAYMENT_DUPLICATE_REGISTRATION)) {
    return (
      trans("error_already_registered", locale) + errorMessage.split(":")[1]
    );
  }

  // ------------------------------

  if (errorMessage === INVALID_PROMO_CODE) {
    return trans("error_invalid_promo_code", locale);
  }

  // ------------------------------

  if (errorMessage === PAYMENT_PROCESS_FAILED) {
    return trans("error_payment_process_failed", locale);
  }

  // ------------------------------

  if (errorMessage === PAYMENT_EXPIRED_REGISTRATION) {
    return trans("error_expired_registration_option", locale);
  }

  // ------------------------------

  if (errorMessage === PERMISSION_DENIED) {
    return PERMISSION_DENIED;
  }

  return trans("error_default_fallback", locale);
};

// ------------------------------

export const trans = (key, i18n) => {
  if (i18n && i18n.language && i18n.language.startsWith("fr")) {
    return FR[key];
  } else {
    return EN[key];
  }
};
