import React, { useState } from "react";
import styles from "./AudienceSelector.module.css";
import LocationSelector from "./LocationSelector";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import LocationOffIcon from "@material-ui/icons/LocationOff";
import MatSelectOutline from "../../../components/ui/labelSelect/MatSelectOutline";
import TrackChangesIcon from "@material-ui/icons/TrackChanges";
import { selectOccupation, selectExperience } from "../../../globals/Data";
import { buildCategory } from "../../../globals/Common";

const AudienceSelector = (props) => {
  const [values, setValues] = useState({
    occupation: props.occupation,
    experience: props.experience,
    locations: props.locations,
    excludeLocations: props.excludeLocations,
  });

  // ------------------------------

  const multiSelectChangeHandler = (targetName, e, selects) => {
    const newValues = { ...values };
    const arrSelectValues = [];

    for (const select of selects) {
      arrSelectValues.push(select.value);
    }

    newValues[targetName] = arrSelectValues;
    setValues(newValues);
    props.update(newValues);
  };

  // ----------

  const addLocatonHandler = (value, country) => {
    const newValues = { ...values };
    newValues.locations.push(value);
    setValues(newValues);
    props.update(newValues);
  };

  const removeLocationHandler = (index) => {
    const newValues = { ...values };
    newValues.locations.splice(index, 1);
    setValues(newValues);
    props.update(newValues);
  };

  // ----------

  const addExcludeLocationHandler = (value, country) => {
    const newValues = { ...values };
    newValues.excludeLocations.push(value);
    setValues(newValues);
    props.update(newValues);
  };

  const removeExcludeLocationHandler = (index) => {
    const newValues = { ...values };
    newValues.excludeLocations.splice(index, 1);
    setValues(newValues);
    props.update(newValues);
  };

  return (
    <div className={styles.container}>
      <div className={styles.targetTitle}>
        <TrackChangesIcon />
        &nbsp;&nbsp;Target Audience
      </div>
      <div className={styles.row}>
        <div style={{ paddingLeft: "10px" }}>
          <div>
            ZubU will automatically show you course to those who are more likely
            to taken them. You can further target your ad by selecting the
            location, experience and occupation of people you wish to reach.
          </div>
        </div>
      </div>

      <div className={styles.carouselContainer}>
        <div className={styles.headlineContainer}>
          <div className={styles.locationContainer}>
            <LocationOnIcon />
            &nbsp;&nbsp;Include Locations
          </div>

          <div
            style={{
              fontSize: "13px",
              paddingTop: "5px",
              paddingLeft: "10px",
              paddingBottom: "10px",
              fontStyle: "italic",
            }}
          >
            You can <b>include</b> specific countries, state/regions and cities.
          </div>

          <LocationSelector
            locations={props.locations}
            onSelect={addLocatonHandler}
            onDelete={removeLocationHandler}
          />

          <div style={{ height: "20px" }}>&nbsp;</div>

          <div
            style={{
              fontSize: "13px",
              paddingTop: "5px",
              paddingLeft: "10px",
              paddingBottom: "10px",
              fontStyle: "italic",
            }}
          >
            You can <b>exclude</b> specific countries, state/regions and cities.
          </div>

          <div className={styles.locationContainer}>
            <LocationOffIcon />
            &nbsp;&nbsp;Exclude Locations
          </div>

          <LocationSelector
            exclude={true}
            locations={props.excludeLocations}
            onSelect={addExcludeLocationHandler}
            onDelete={removeExcludeLocationHandler}
          />

          <div style={{ height: "40px" }}>&nbsp;</div>

          <MatSelectOutline
            zeroMargin
            double="true"
            title="Years of Experience"
            placeholder="Enter years of experience..."
            name="experience"
            id="experience"
            onChange={multiSelectChangeHandler.bind(this, "experience")}
            options={selectExperience}
            value={buildCategory(values.experience, true)}
            getOptionLabel={(option) => option.label}
            getOptionSelected={(opt1, opt2) => opt1.value === opt2.value}
            multiple={true}
            required={false}
          />

          <div style={{ height: "40px" }}>&nbsp;</div>

          <MatSelectOutline
            zeroMargin
            double="true"
            title="Occupation"
            placeholder="Enter occupation..."
            name="occupation"
            id="occupation"
            onChange={multiSelectChangeHandler.bind(this, "occupation")}
            options={selectOccupation}
            value={buildCategory(values.occupation, true)}
            getOptionLabel={(option) => option.label}
            getOptionSelected={(opt1, opt2) => opt1.value === opt2.value}
            multiple={true}
            required={false}
          />
        </div>
      </div>
    </div>
  );
};

export default AudienceSelector;
