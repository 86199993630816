import React, { Component } from "react";
import { connect } from "react-redux";
import * as settingActions from "../../../store/actions/setting";
import styles from "./AlertScreen.module.css";
import Switch from "@material-ui/core/Switch";
import NotificationsOutlinedIcon from "@material-ui/icons/NotificationsOutlined";
import Spinner from "../../../components/ui/spinner/Spinner";
import notifImg from "../../../assets/images/inApp/notification.png";
import * as logger from "../../../globals/Logger";
import Incrementor from "../../../components/ui/increment/IncrementInput";

class AlertScreen extends Component {
  state = {
    wait: false,
    error: null,
  };

  // ------------------------------

  componentDidMount = async () => {
    window.scrollTo(0, 0);
  };

  // ------------------------------

  update = async (propValue) => {
    await this.setState({ ...this.state, ...propValue });
  };

  // ------------------------------

  componentDidMount = async () => {
    try {
      await this.update({ wait: true });
      await this.props.fetchSetting();
      await this.update({ wait: false });
    } catch (err) {
      logger.error(err);
      this.update({ wait: false, error: err.message });
    }
  };

  // ------------------------------

  updateNotifyPayoutPaidHandler = (event, value) => {
    this.props.updateNotifyPayoutPaid(value);
  };

  // ------------------------------

  updateNotifyLivestreamCapacityHandler = (event, value) => {
    this.props.updateNotifyLivestreamCapacity(value);
  };

  // ------------------------------

  updateNotifyClassroomLectureCapacityHandler = (event, value) => {
    this.props.updateNotifyClassroomLectureCapacity(value);
  };

  // ------------------------------

  updateNotifyClassroomHandsonCapacityHandler = (event, value) => {
    this.props.updateNotifyClassroomHandsonCapacity(value);
  };

  // ------------------------------

  updateNotifyOnlineVideoCapacityHandler = (event, value) => {
    this.props.updateNotifyOnineVideoCapacity(value);
  };

  // ------------------------------

  updateLivestreamCapacityLimit = (value) => {
    this.props.updateLivestreamCapacityLimit(value);
  };

  // ------------------------------

  updateClassroomLectureCapacityLimit = (value) => {
    this.props.updateClassroomLectureCapacityLimit(value);
  };

  // ------------------------------

  updateClassroomHandsonCapacityLimit = (value) => {
    this.props.updateClassroomHandsonCapacityLimit(value);
  };

  // ------------------------------

  updateOnineVideoCapacityLimit = (value) => {
    this.props.updateOnineVideoCapacityLimit(value);
  };

  // ------------------------------

  render() {
    if (this.state.wait) {
      return <Spinner />;
    }

    return (
      <form className={styles.form}>
        <div className={styles.settingRow}>
          <div className={styles.settingTitle}>
            <img src={notifImg} alt="" />
            <span style={{ paddingLeft: "5px" }}>
              Stay on top of things, get alerts on events that matter to you.
              Alerts will appear in the top menu{" "}
              <NotificationsOutlinedIcon fontSize="small" /> followed up with an
              email notification.{" "}
            </span>
          </div>
        </div>
        <div className={styles.settingRow}>
          <div
            className={
              this.props.setting.notify_payout_paid
                ? styles.settingDescription
                : styles.settingDescriptionDisabled
            }
          >
            <NotificationsOutlinedIcon fontSize="small" />
            &nbsp;&nbsp;Alert me when a&nbsp;<b>payout</b>&nbsp;is paid to my
            bank account
          </div>
          <div className={styles.settingAction}>
            <Switch
              checked={
                this.props.setting.notify_payout_paid
                  ? this.props.setting.notify_payout_paid
                  : false
              }
              onChange={this.updateNotifyPayoutPaidHandler}
              color="primary"
              inputProps={{ "aria-label": "primary checkbox" }}
            />
          </div>
        </div>
        <div className={styles.settingRow}>
          <div
            className={
              this.props.setting.notify_livestream_capacity
                ? styles.settingDescription
                : styles.settingDescriptionDisabled
            }
          >
            <NotificationsOutlinedIcon fontSize="small" />
            &nbsp;&nbsp;Alert me when a&nbsp;<b>Livestream</b>&nbsp;capacity
            reaches{" "}
            <Incrementor
              disabled={!this.props.setting.notify_livestream_capacity}
              value={this.props.setting.livestream_capacity_limit}
              onBlur={this.updateLivestreamCapacityLimit}
            />
            %
          </div>
          <div className={styles.settingAction}>
            <Switch
              checked={
                this.props.setting.notify_livestream_capacity
                  ? this.props.setting.notify_livestream_capacity
                  : false
              }
              onChange={this.updateNotifyLivestreamCapacityHandler}
              color="primary"
              inputProps={{ "aria-label": "primary checkbox" }}
            />
          </div>
        </div>
        <div className={styles.settingRow}>
          <div
            className={
              this.props.setting.notify_classroom_lecture_capacity
                ? styles.settingDescription
                : styles.settingDescriptionDisabled
            }
          >
            <NotificationsOutlinedIcon fontSize="small" />
            &nbsp;&nbsp;Alert me when a&nbsp;<b>Classroom Lecture</b>
            &nbsp;capacity reaches{" "}
            <Incrementor
              disabled={!this.props.setting.notify_classroom_lecture_capacity}
              value={this.props.setting.classroom_lecture_capacity_limit}
              onBlur={this.updateClassroomLectureCapacityLimit}
            />
            %
          </div>
          <div className={styles.settingAction}>
            <Switch
              checked={
                this.props.setting.notify_classroom_lecture_capacity
                  ? this.props.setting.notify_classroom_lecture_capacity
                  : false
              }
              onChange={this.updateNotifyClassroomLectureCapacityHandler}
              color="primary"
              inputProps={{ "aria-label": "primary checkbox" }}
            />
          </div>
        </div>
        <div className={styles.settingRow}>
          <div
            className={
              this.props.setting.notify_classroom_handson_capacity
                ? styles.settingDescription
                : styles.settingDescriptionDisabled
            }
          >
            <NotificationsOutlinedIcon fontSize="small" />
            &nbsp;&nbsp;Alert me when a&nbsp;<b>Classroom Hands-on</b>
            &nbsp;capacity reaches{" "}
            <Incrementor
              disabled={!this.props.setting.notify_classroom_handson_capacity}
              value={this.props.setting.classroom_handson_capacity_limit}
              onBlur={this.updateClassroomHandsonCapacityLimit}
            />
            %
          </div>
          <div className={styles.settingAction}>
            <Switch
              checked={
                this.props.setting.notify_classroom_handson_capacity
                  ? this.props.setting.notify_classroom_handson_capacity
                  : false
              }
              onChange={this.updateNotifyClassroomHandsonCapacityHandler}
              name="checkedA"
              color="primary"
              inputProps={{ "aria-label": "primary checkbox" }}
            />
          </div>
        </div>
        <div className={styles.settingRow}>
          <div
            className={
              this.props.setting.notify_online_video_capacity
                ? styles.settingDescription
                : styles.settingDescriptionDisabled
            }
          >
            <NotificationsOutlinedIcon fontSize="small" />
            &nbsp;&nbsp;Alert me when an&nbsp;<b>Online Video</b>
            &nbsp;capacity reaches{" "}
            <Incrementor
              disabled={!this.props.setting.notify_online_video_capacity}
              value={this.props.setting.online_video_capacity_limit}
              onBlur={this.updateOnineVideoCapacityLimit}
            />
            %
          </div>
          <div className={styles.settingAction}>
            <Switch
              checked={
                this.props.setting.notify_online_video_capacity
                  ? this.props.setting.notify_online_video_capacity
                  : false
              }
              onChange={this.updateNotifyOnlineVideoCapacityHandler}
              name="checkedA"
              color="primary"
              inputProps={{ "aria-label": "primary checkbox" }}
            />
          </div>
        </div>
      </form>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    setting: state.setting.setting,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchSetting: () => dispatch(settingActions.fetchSetting()),
    updateNotifyPayoutPaid: (notifyPayoutPaid) =>
      dispatch(settingActions.updateNotifyPayoutPaid(notifyPayoutPaid)),
    updateNotifyLivestreamCapacity: (notify) =>
      dispatch(settingActions.updateNotifyLivestreamCapacity(notify)),
    updateNotifyClassroomLectureCapacity: (notify) =>
      dispatch(settingActions.updateNotifyClassroomLectureCapacity(notify)),
    updateNotifyClassroomHandsonCapacity: (notify) =>
      dispatch(settingActions.updateNotifyClassroomHandsonCapacity(notify)),
    updateNotifyOnineVideoCapacity: (notify) =>
      dispatch(settingActions.updateNotifyOnineVideoCapacity(notify)),
    updateLivestreamCapacityLimit: (value) =>
      dispatch(settingActions.updateLivestreamCapacityLimit(value)),
    updateClassroomLectureCapacityLimit: (value) =>
      dispatch(settingActions.updateClassroomLectureCapacityLimit(value)),
    updateClassroomHandsonCapacityLimit: (value) =>
      dispatch(settingActions.updateClassroomHandsonCapacityLimit(value)),
    updateOnineVideoCapacityLimit: (value) =>
      dispatch(settingActions.updateOnineVideoCapacityLimit(value)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AlertScreen);
