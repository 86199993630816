import React from "react";
import styles from "./GradientCard.module.css";
import { resolveIcon } from "../../../globals/Common";
import useMediaQuery from "@material-ui/core/useMediaQuery";

const GradientCard = (props) => {
  const smallDevice = useMediaQuery("(max-width:599px)");

  return (
    <div>
      <div
        className={styles.card}
        style={{
          background: `linear-gradient(to top, ${props.color}, #ffffff)`,
          borderRadius: props.rounded ? "50%" : "5px",
        }}
      >
        <div className={styles.center}>
          {props.isSvg ? (
            <img
              src={resolveIcon(props.icon, smallDevice)}
              style={smallDevice ? { width: "16px" } : { width: "30px" }}
            />
          ) : (
            resolveIcon(props.icon, smallDevice)
          )}
          <div>{props.count > -1 ? props.count : 0}</div>
        </div>
      </div>

      <div className={styles.center}>{props.title}</div>
    </div>
  );
};

export default GradientCard;
