import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Input from "@material-ui/core/Input";

const useStyles = makeStyles({
  root: {
    width: 42,
    marginLeft: 10,
    marginRight: 10,
  },
  input: {
    width: 42,
  },
});

const IncrementInput = (props) => {
  const classes = useStyles();
  const [value, setValue] = React.useState(props.value ? props.value : 90);

  const handleInputChange = (event) => {
    setValue(event.target.value === "" ? "" : Number(event.target.value));
  };

  const handleBlur = () => {
    if (value < 10) {
      setValue(0);
      props.onBlur(10);
    } else if (value > 100) {
      setValue(100);
      props.onBlur(100);
    } else {
      props.onBlur(Number(value));
    }
  };

  return (
    <div className={classes.root}>
      <Input
        disabled={props.disabled}
        className={classes.input}
        value={value}
        margin="dense"
        onChange={handleInputChange}
        onBlur={handleBlur}
        inputProps={{
          step: 1,
          min: 10,
          max: 100,
          type: "number",
          "aria-labelledby": "input-slider",
        }}
      />
    </div>
  );
};

export default IncrementInput;
