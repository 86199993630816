class MarketingAd {
  constructor(
    id,
    course_id,
    course_title,
    category,
    prime,
    start_date,
    expiration_date,
    slide,
    headline,
    callForAction,
    showLogo,
    courses_featured_id,
    locations,
    exclude_locations,
    experience,
    occupation
  ) {
    this.id = id;
    this.course_id = course_id;
    this.course_title = course_title;
    this.category = category;
    this.prime = prime;
    this.start_date = start_date;
    this.expiration_date = expiration_date;
    this.slide = slide;
    this.headline = headline;
    this.callForAction = callForAction;
    this.showLogo = showLogo;
    this.courses_featured_id = courses_featured_id;
    this.locations = locations;
    this.exclude_locations = exclude_locations;
    this.experience = experience;
    this.occupation = occupation;
  }
}

export default MarketingAd;
