import React from "react";
import styles from "./SignupTypeModal.module.css";
import { useTranslation } from "react-i18next";
import Button from "@material-ui/core/Button";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";

import studentImg from "../../../assets/images/inApp/student.png";
import instructorImg from "../../../assets/images/inApp/instructor.jpg";
import TextButton from "../../ui/button/TextButton";

const SignupTypeModal = (props) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("xs"));
  const fullWidth = true;
  const maxWidth = "sm";

  const cancelHandler = () => {
    props.onClose(null);
  };

  const selecHandler = (signupType) => {
    if (signupType === "student") {
      props.history.push("/studentregister");
    } else if (signupType === "provider") {
      props.history.push("/register");
    }
  };

  return (
    <div>
      <Dialog
        fullScreen={fullScreen}
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={props.open}
        onClose={cancelHandler}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="alert-dialog-title">
          {t("sign_up")} - {t("sign_up_start_learning_title")}
        </DialogTitle>

        <DialogContent>
          <div className={styles.container}>
            <div style={{ width: "100%", textAlign: "center" }}>
              <div
                style={{
                  width: "100%",
                  textAlign: "center",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <div className={styles.title}>
                  {t("sign_up_are_you_dental")}
                </div>
                <img
                  onClick={selecHandler.bind(this, "student")}
                  className={styles.signUpTypeImg}
                  src={studentImg}
                  alt=""
                />
                <div className={styles.textContainer}>
                  <TextButton
                    onClick={selecHandler.bind(this, "student")}
                    style={{ fontSize: 14 }}
                  >
                    {t("sign_up_as_dental")}
                  </TextButton>{" "}
                  <div className={styles.hidden}>
                    {t("sign_up_start_learning_today")}
                  </div>
                </div>
              </div>
            </div>
            <div>{t("g_or")}</div>
            <div style={{ width: "100%", textAlign: "center" }}>
              <div
                style={{
                  width: "100%",
                  textAlign: "center",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <div className={styles.title}>
                  {t("sign_up_are_you_provider")}
                </div>
                <img
                  onClick={selecHandler.bind(this, "provider")}
                  className={styles.signUpTypeImg}
                  src={instructorImg}
                  alt=""
                />
                <div className={styles.textContainer}>
                  <TextButton
                    onClick={selecHandler.bind(this, "provider")}
                    style={{ fontSize: 14 }}
                  >
                    {t("sign_up_as_provider")}
                  </TextButton>{" "}
                  <div className={styles.hidden}>
                    {t("sign_up_start_posting_courses")}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </DialogContent>

        <DialogActions>
          <Button autoFocus onClick={cancelHandler} color="primary">
            {t("g_cancel")}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default SignupTypeModal;
