class InboxMessage {
  constructor(id, message, date, name, uid, isOwner) {
    this.id = id;
    this.message = message;
    this.date = date;
    this.name = name;
    this.uid = uid;
    this.isOwner = isOwner;
  }
}

export default InboxMessage;
