import React from "react";
import * as styles from "./CertificatePreview.module.css";
import ImageInput from "../image/ImageInput";

const CertificatePreview = (props) => {
  return (
    <div className={styles.bodyContainer}>
      <div className={styles.bodySubContainer}>
        <div className={styles.bodySubSubContainer}>
          <ImageInput
            readOnly={!props.editable}
            id="certificate_header"
            header="certificate_header"
            title=""
            deleteTitle="Delete Header Picture"
            headerFooter
            image={props.header ? props.header : "../cert_header.png"}
            onImageSelect={(imageUri) => {
              props.openImageCrop(imageUri, "certificate_header");
            }}
            onRemovePic={
              props.header && props.editable === true
                ? () => {
                  props.onRemovePic("certificate_header");
                }
                : null
            }
          />

          <div className={styles.pageContainer}>
            <div className={styles.title}>CERTIFICATE OF COMPLETION</div>
            <div className={styles.subText}>THIS CERTIFICATE GOES TO</div>
            <div className={styles.name}>Dr. John Smith</div>
            <div className={styles.subText}>License Number: 123456</div>
            <div className={styles.subText}>&nbsp;</div>

            {props.course.accreditation && props.course.accreditation.includes("AGD PACE") &&
              <div className={styles.line}>
                <div>Member ID</div>
                <div>-</div>
              </div>
            }
            <div className={styles.line}>
              <div>Provider</div>
              <div>{props.profile.providerName}</div>
            </div>

            <div className={styles.line}>
              <div
                style={{
                  width: "60px",
                }}
              >
                Course Title
              </div>
              <div style={{ textAlign: "right" }}>{props.course.title}</div>
            </div>

            {props.course.topic_number &&
              <div className={styles.line}>
                <div>Subject Code</div>
                <div>{props.course.topic_number}</div>
              </div>
            }

            <div className={styles.line}>
              <div>Course Date</div>
              <div>{props.course.format_start_date()}</div>
            </div>

            <div className={styles.line}>
              <div>Course Location</div>
              <div>{props.course.location}</div>
            </div>

            <div className={styles.line}>
              <div>Educational Method</div>
              <div>-</div>
            </div>

            <div className={styles.line}>
              <div>Credit Hours Total</div>
              <div>-</div>
            </div>

            <div className={styles.line}>
              <div>Breakdown (lecture/hands-on)</div>
              <div>-</div>
            </div>

            <div className={styles.line}>
              <div>ODQ Credits</div>
              <div>-</div>
            </div>

            <div className={styles.line}>
              <div>Accreditation</div>
              <div>{props.course.accreditation.join(" ")}</div>
            </div>



          </div>

          <div className={styles.signatureContainer}>
            <div className={styles.signatureWrap}>
              <div
                style={{
                  borderBottom: "1px solid #ccc",
                }}
              >
                <img
                  src={props.profile.digitalSignature}
                  alt=""
                  className={styles.image}
                />
              </div>
              <div className={styles.signatureTitle}>Authorized Signature</div>
            </div>

            <img src={props.profile.logo} alt="" className={styles.image} />
          </div>

          <ImageInput
            readOnly={!props.editable}
            id="certificate_footer"
            header="certificate_footer"
            title=""
            deleteTitle="Delete Footer Picture"
            headerFooter
            image={props.footer ? props.footer : "../cert_footer.png"}
            onImageSelect={(imageUri) => {
              props.openImageCrop(imageUri, "certificate_footer");
            }}
            onRemovePic={
              props.footer && props.editable === true
                ? () => {
                  props.onRemovePic("certificate_footer");
                }
                : null
            }
          />
        </div>
      </div>
    </div>
  );
};

export default CertificatePreview;
