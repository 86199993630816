import React, { Component } from "react";
import { connect } from "react-redux";
import { Route } from "react-router-dom";
import styles from "./SettingScreen.module.css";
import SettingMenu from "./SettingMenu";
import AlertScreen from "./alerts/AlertScreen";
import ProfileScreen from "./profile/ProfileScreen";
import HelpScreen from "./help/HelpScreen";
import AccountInfoScreen from "./accountInfo/AccountInfoScreen";
import LivestreamScreen from "./livestream/LivestreamScreen";
import PasswordScreen from "./resetPassword/PasswordScreen";
import PluginScreen from "./plugin/PluginScreen";

class SettingScreen extends Component {
  componentDidMount = async () => {
    window.scrollTo(0, 0);
  };

  render() {
    return (
      <div className={styles.container}>
        <SettingMenu history={this.props.history} />
        <div className={styles.pageContent}>
          <Route exact path="/settings" render={() => <AccountInfoScreen />} />

          <Route path="/settings/alerts" render={() => <AlertScreen />} />

          <Route
            path="/settings/resetpassword"
            render={() => <PasswordScreen />}
          />

          <Route
            path="/settings/livestream"
            render={(props) => <LivestreamScreen {...props} />}
          />

          <Route path="/settings/help" render={() => <HelpScreen />} />

          <Route path="/settings/profile" render={() => <ProfileScreen />} />

          <Route path="/settings/plugin" render={() => <PluginScreen />} />
        </div>
      </div>
    );
  }
}

export default connect(null, null)(SettingScreen);
