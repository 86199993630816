import React from "react";
import styles from "./Button.module.css";
import Button from "@material-ui/core/Button";

const FormButton = (props) => {
  return (
    <div
      className={
        props.alwaysfull ? styles.fullButtonContainer : styles.buttonContainer
      }
      style={props.style ? props.style : null}
    >
      <Button
        {...props}
        variant="contained"
        style={{
          backgroundColor: props.disabled ? "#CCC" : "#437aa1",
          color: "white",
        }}
      >
        {props.label}
      </Button>
    </div>
  );
};

export default FormButton;
