import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import { useTranslation } from "react-i18next";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import MatInputOutline from "../../../components/ui/input/MatInputOutline";

const DualInputModal = (props) => {
  const { t } = useTranslation();
  const [inputText, setInputText] = useState("");
  const [inputText2, setInputText2] = useState("");

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("xs"));
  const fullWidth = true;
  const maxWidth = "sm";

  // ------------------------------

  const handleClose = (inpuText, inputText2) => {
    props.onClose(inpuText, inputText2);
  };

  // ------------------------------

  const inputChangeHandler = (e) => {
    setInputText(e.target.value);
  };

  const input2ChangeHandler = (e) => {
    setInputText2(e.target.value);
  };

  // ------------------------------

  const isOkDisabled = () => {
    return !inputText || !inputText2;
  };

  // ------------------------------

  return (
    <div>
      <Dialog
        fullScreen={fullScreen}
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={props.open}
        onClose={handleClose.bind(this, null, null)}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="alert-dialog-title">
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            {props.title}
          </div>
        </DialogTitle>
        <div
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <img src={props.image} />
        </div>

        <DialogContent>
          <React.Fragment>
            <MatInputOutline
              nomargin="true"
              notopbottommargin="true"
              title={props.inputLabel1}
              name="inputText2"
              value={inputText2}
              onChange={input2ChangeHandler}
              maxLength={100}
              required
            />
            <MatInputOutline
              nomargin="true"
              notopbottommargin="true"
              title={props.inputLabel2}
              name="inputText"
              value={inputText}
              onChange={inputChangeHandler}
              maxLength={100}
              required
            />
          </React.Fragment>
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            onClick={handleClose.bind(this, inputText, inputText2)}
            color="primary"
            disabled={isOkDisabled()}
            style={
              isOkDisabled()
                ? { color: "gray", backgroundColor: "#ccc" }
                : { color: "white", backgroundColor: "#3E8CAB" }
            }
          >
            {props.okText ? props.okText : "Ok"}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default DualInputModal;
