class Survey {
  constructor(id, title, text, expiration_days) {
    this.id = id;
    this.title = title;
    this.text = text;
    this.expiration_days = expiration_days;
  }
}

export default Survey;
