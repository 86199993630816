import React from "react";
import styles from "../Legal.module.css";

export const renderSupplierTermsOfUse = () => {
  return (
    <div>
      <p className={styles.title}>
        <strong>
          <u>SUPPLIER TERMS AND CONDITIONS</u>
        </strong>
      </p>
      <p>
        Each Course Provider creating an Account on the ZubU Platform agrees to
        abide by these Supplier Terms and Conditions and to ensure their
        compliance by and enforce them upon their Speakers and other
        representatives.
      </p>
      <ol>
        <li className={styles.listItem}>
          <strong>INTERPRETATION</strong>
        </li>
      </ol>
      <p>
        These Supplier Terms and Conditions complete and supplement ZubU&rsquo;s
        General Terms and Conditions, which apply to all Users, including Course
        Providers and Speakers. In case of inconsistency, the General Terms and
        Conditions shall prevail.
      </p>
      <p>
        Any capitalized term or expression that is not defined herein shall have
        the meaning set forth in the General Terms and Conditions.
      </p>
      <p>
        Each Course Provider contracts directly with ZubU Inc. (a Canada federal
        corporation), regardless of whether another ZubU subsidiary or Supplier
        facilitates payments.
      </p>
      <p>
        If a Speaker creates an Account directly with ZubU and does not act for
        and on behalf of an institutional or corporate Course Provider, it shall
        be considered both a &ldquo;Course Provider&rdquo; and a
        &ldquo;Speaker&rdquo; for the purposes of the Contract.
      </p>
      <ol start="2">
        <li className={styles.listItem}>
          <strong>COURSE PROVIDER OBLIGATIONS</strong>
          <ul>
            <li className={styles.listItem}>
              <strong>Platform and Academic Content</strong>
            </li>
          </ul>
        </li>
      </ol>
      <p>
        Each Course Provider is responsible for all Academic and other content
        advertised on the Platform or on any other media or platform, including
        lectures, quizzes, exercises, practice tests, assignments, resources,
        answers, notes, presentations, course landing page content, and
        announcements. All the foregoing, together with any blurb, summary,
        extracts, abstracts or key words supplied by the Course Provider to ZubU
        shall qualify as &ldquo;<strong>Academic Content</strong>&rdquo; for the
        purposes of the Contract.
      </p>
      <p>Each Course Provider represent and warrant that:</p>
      <ul>
        <li className={styles.listItem}>
          It will comply and will ensure and enforce compliance of its Speakers
          and representatives with the Contract;
        </li>
        <li className={styles.listItem}>
          It will have the necessary licenses, rights, consents, permissions,
          and authority to supply and distribute the Academic Content, to
          advertise it on the Platform and to authorize ZubU to provide the
          Services as specified in the Contract;
        </li>
        <li className={styles.listItem}>
          the Academic Content will not infringe or misappropriate any third
          party's intellectual property rights;
        </li>
        <li className={styles.listItem}>
          it and its Speakers have the required qualifications, credentials, and
          expertise (including education, training, knowledge, and skillsets) to
          teach and offer the Academic Content and other products and services
          advertised on the Platform and to use the Services;
        </li>
        <li className={styles.listItem}>
          it will specify the location of the Academic Content and/or
          technologies required to access it prior to enrollment and, it will
          provide a link, access code and/or other credentials and support
          allowing the enrolled Students and Users to access the Academic
          Content without additional cost; and
        </li>
        <li className={styles.listItem}>
          it will respond promptly to other Users and ensure a quality of
          service of the highest standards in the continuing education industry.
        </li>
      </ul>
      <p>
        Each Course Provider represents and warrants that it will&nbsp;
        <u>not</u>:
      </p>
      <ul>
        <li className={styles.listItem}>
          post or transmit any unsolicited or unauthorized advertising,
          promotional materials, junk mail, spam, or any other form of
          solicitation (commercial or otherwise) on the Platform or through the
          Services;
        </li>
        <li className={styles.listItem}>
          use the Services for business other than providing Academic Content
          and other tutoring, teaching, and academic services to Students;
        </li>
        <li className={styles.listItem}>
          engage in any activity that would require ZubU to obtain licenses from
          or pay royalties to any third party, including the need to pay
          royalties for the public performance of a musical work or sound
          recording or the use of stock images or other copyrighted work;
        </li>
        <li className={styles.listItem}>
          frame or embed the Services or otherwise circumvent the Services;
        </li>
        <li className={styles.listItem}>
          interfere with or otherwise prevent other Course Providers from
          advertising their Academic Content or other services; or
        </li>
        <li className={styles.listItem}>
          abuse ZubU&rsquo;s resources, including support services.
          <ul>
            <li className={styles.listItem}>
              <strong>Contract with Enrolled Users</strong>
            </li>
          </ul>
        </li>
      </ul>
      <p>
        Once Academic Content is advertised on the Platform, the Course Provider
        acknowledges and accepts that it creates an irrevocable offer to
        Students and other Users of the Platform, open for their enrollment and
        acceptance as per the terms and conditions mentioned on the Platform.
        Enrollment and payment from the User on the Platform shall constitute
        acceptance.
      </p>
      <p>
        The Course Provider shall monitor the general attendance open for each
        Academic Content and make a clear number of places available on the
        Platform, which cannot be enrolled other than on the Platform.
      </p>
      <p>
        Each Course Provider shall provide ZubU with the details of its
        cancellation and refund policy for each Academic Content. In addition to
        the terms and conditions of the Contract, it shall be bound toward ZubU
        and the enrolled Users to comply with such policy.
      </p>
      <ol start="3">
        <li className={styles.listItem}>
          <strong>LICENSE TO ZubU</strong>
        </li>
      </ol>
      <p>
        Each Course Provider grants ZubU a non-exclusive, royalty-free,
        worldwide, perpetual and irrevocable license to use, copy, reproduce,
        process, adapt, modify, publish, transmit, display, translate, and
        distribute the content submitted or uploaded to the Platform, such as
        advertisement and Academic Content abstract or excerpt (the &ldquo;
        <strong>License</strong>&rdquo;). The License is sublicensable and gives
        ZubU the right to treat and use the Licensed content on the Platform and
        to provide the Students and other Users with the licenses set forth in
        the General Terms and Conditions. The License shall survive termination
        of the Contract.
      </p>
      <p>
        ZubU is allowed to share the Licensed content (including comments,
        reviews, advertising and summaries) to market, advertise and offer the
        Services and for any other purposes, through any media, including
        advertising on other websites.
      </p>
      <p>
        Each Course Provider and Speaker irrevocably waives in favour of ZubU
        its moral rights to integrity and association to the Licensed content,
        provided however that it preserves the right to identification. It means
        that ZubU shall always identify the Course Provider Speaker when it uses
        or makes Licensed content available, in whole or in part. However, ZubU
        may modify (ex. making summaries or translations) the Licensed content
        to the extent required for advertisement and distribution of the
        Services. Is so doing, ZubU shall provide its best effort not the change
        the purpose and intent of the author.
      </p>
      <p>
        ZubU may also associate some Licensed content or Academic Content
        advertisement with other content expressing a different, controversial,
        or dissenting opinion, or with content which a Course Provider (or its
        Speaker) disapproves. ZubU shall have entire discretion in that regard
        and the only remedy for a Course Provider is to remove the Academic
        Content from the Platform in accordance with the terms and conditions of
        the Contract.
      </p>
      <p>
        ZubU may record all or any part of Licensed content for quality control
        and for delivering, marketing, promoting, demonstrating, or operating
        the Services. Each Course Provider grants ZubU permission to use its
        name, likeness, voice, and image in connection with offering,
        delivering, marketing, promoting, demonstrating, and selling the
        Services, or ZubU's content, and waives any rights of privacy,
        publicity, or other rights of a similar nature, to the extent allowed
        under applicable law.
      </p>
      <ol start="4">
        <li className={styles.listItem}>
          <strong>CONTENT AND OTHER POLICIES</strong>
          <ul>
            <li className={styles.listItem}>
              <strong>Reviews</strong>
            </li>
          </ul>
        </li>
      </ol>
      <p>
        ZubU values meaningful feedback and reviews on its Platform. Course
        Providers and Speakers shall not try to influence reviews about their
        Academic Content, such as rewarding positive reviews (with free or
        discounted content), using guilt or insisting on a type of review or
        trade reviews with other Users. Illegitimate reviews will be punishable,
        such as the use of multiple enrollments, bots, scripts or other
        inorganic means. Each Course Provider must notify ZubU if it receives an
        offer from or becomes aware that another User is looking to trade
        reviews or otherwise influence feedback. ZubU may share reviews or
        consult them for quality control and its own purposes only.
      </p>
      <ul>
        <li className={styles.listItem}>
          <strong>Topics and Content</strong>
        </li>
      </ul>
      <p>
        ZubU requires each Course Provider and Speaker to comply at all time
        with the Contract and more specifically that the Academic Content
        advertised on the Platform comply with ZubU Policies. Academic Content
        shall maintain scientific credibility and comply with ZubU&rsquo;s
        standards or excellence and integrity to maintain a positive learning
        experience for Students. All Academic Content shall be relevant for ZubU
        Students and other Users, namely registered dental professionals,
        licensed from a professional order, board or association recognized by
        ZubU and likely to be accepted for continuing education credit
        requirements. ZubU reserves the right to remove Academic Content
        enrollment or suspend or terminate a Course Provider&rsquo;s access if
        it reflects unfavorably on ZubU or the Services or brings them into
        public disrepute, contempt, scandal, or ridicule. Academic Content
        shall:
      </p>
      <ul>
        <li className={styles.listItem}>
          Not be intended to market or sell products and services; Course
          Providers and Speakers shall be transparent when they are sponsored or
          affiliated to any business or are otherwise in conflict of interest;
          all sponsorship and affiliations must be clearly disclosed on the
          Academic Content description on the Platform;
        </li>
        <li className={styles.listItem}>
          contain spam, nonsense or deceptive content;
        </li>
        <li className={styles.listItem}>
          contain sexually explicit content, hate speech or discriminatory
          language, promoting violence or bodily harm;
        </li>
        <li className={styles.listItem}>
          relate to making, handling or using weapons or enabling illegal or
          unethical behavior;
        </li>
        <li className={styles.listItem}>
          be intentionally misleading or deceptive, harmful or inappropriate;
        </li>
        <li className={styles.listItem}>
          violate any applicable law or any third party intellectual property or
          other rights.
        </li>
      </ul>
      <p>
        Each Course Provider understands that Students from many jurisdictions
        may enroll in its Academic Content using the Platform must use its
        professional judgment to avoid any illegal content from a broader range
        of perspective.
      </p>
      <p>
        Executive summaries or Academic Content descriptions shall match the
        actual content and shall not be deceptive. If all or part of an Academic
        Content is repetitive with another Academic Content advertised on the
        Platform, the executive summary shall disclose this fact to Students so
        they don&rsquo;t enroll twice to the same material. ZubU reserves the
        right to preview content before it is approved and posted on the
        Platform.
      </p>
      <ul>
        <li className={styles.listItem}>
          <strong>Co-Course Providers and Speakers</strong>
        </li>
      </ul>
      <p>
        ZubU will not advise on any questions or mediate any disputes between
        the Course Providers and their Speakers and other partners or suppliers.
        Course Providers are solely responsible for any profit-share or other
        arrangement entered into with another Course Provider, Speaker or other
        partner or supplier.
      </p>
      <ul>
        <li className={styles.listItem}>
          <strong>Relationship to Other Users</strong>
        </li>
      </ul>
      <p>
        Each Course Provider and its Speaker(s) shall not use the data received
        about other Users for any purpose other than providing the Academic
        Content advertised on the ZubU Platform and shall not solicit additional
        personal data or store Students' personal data outside the Platform.
        Each Course Provider will indemnify ZubU against and from any claims
        arising from its or its Speakers or other representatives&rsquo; use of
        personal data.
      </p>
      <ul>
        <li className={styles.listItem}>
          <strong>Anti-Piracy Efforts</strong>
        </li>
      </ul>
      <p>
        ZubU may use anti-piracy software and methods to help protect content
        from unauthorized access and use, however it does not constitute a
        warranty or obligation from ZubU to enforce a right or to file or
        exercise any remedy.
      </p>
      <ul>
        <li className={styles.listItem}>
          <strong>
            Breach of Copyright or other Intellectual Property Rights
          </strong>
        </li>
      </ul>
      <p>
        ZubU reserves the right to exercise remedies under the Contract or to
        decline to act when it receives notice of a copyright or other
        intellectual property infringement. The choice of remedy remains in its
        entire discretion.
      </p>
      <p>
        Copyright law includes a &ldquo;fair use&rdquo; exception for certain
        uses of copyrighted content that are considered to be in the public
        interest. Fair use covers things like criticism, commentary, news
        reporting, and research. In considering whether use the of Academic
        Content or Licensed content qualifies as &ldquo;fair use&rdquo;, the
        following should be considered: (a)&nbsp;the purpose of the alleged
        infringing use (whether it is paid or unpaid, whether it
        critiques/parodies/transforms the initial material); (b)&nbsp;the type
        of copyrighted work being used (whether the work is factual or
        creative); (c)&nbsp;the portion being used (whether it uses small,
        necessary excerpts or substantial portions)&nbsp;; (d)&nbsp;the effect
        on the market (whether potential buyers would purchase the breaching
        content instead of the original material). Before submitting a copyright
        claim, a Course Provider or Speaker shall make sure that use of the
        content copied or used does not qualify as fair use.
      </p>
      <ul>
        <li className={styles.listItem}>
          <strong>Insurance</strong>
        </li>
      </ul>
      <p>
        Each Course Provider is responsible for obtaining and maintaining a
        general liability insurance policy with coverage amounts that are
        sufficient to cover all risks associated with the performance of and
        reliance on the Academic Content and their other activities.
      </p>
      <ol start="5">
        <li className={styles.listItem}>
          <strong>AMENDMENTS</strong>
        </li>
      </ol>
      <p>
        Once an Academic Content has been posted for enrollment on the Platform,
        the Course Provider may not alter the content thereof substantially
        (drafts can be saved and amended at convenience). The following
        amendments are authorized, subject to the following terms and conditions
        (which shall apply in addition and notwithstanding any
        cancellation/refund policy adopted by the Course Provider):
      </p>
      <ul>
        <li className={styles.listItem}>
          <strong>Cancellation</strong>
        </li>
      </ul>
      <p>
        A Course Provider may cancel Academic Content, by providing reasonable
        prior notice to ZubU and each Student or User enrolled and reimbursing
        the price in whole. ZubU will use the Course Provider <em>Stripe</em>{" "}
        Account to process the reimbursement and withdraw amounts automatically
        upon cancellation. The Enrollment Fees will not be reimbursed and the
        difference will have to be covered by the Course Provider (charged on
        credit card associated with the Account). In addition, ZubU may charge a
        cancellation fee or penalty, as set forth in Section 10.4.
      </p>
      <ul>
        <li className={styles.listItem}>
          <strong>Places Available for Enrollment</strong>
        </li>
      </ul>
      <p>
        Course Providers shall monitor the number of places available on the
        Platform for enrollment to their Academic Content. Such number may be
        updated from time to time to the extent enrollment has not yet been
        completed by a User on the Platform.
      </p>
      <ul>
        <li className={styles.listItem}>
          <strong>Date</strong>
        </li>
      </ul>
      <p>
        Academic Content dates may be amended at any time prior to enrollment.
        After enrollment, the Students or other Users shall have the option to
        cancel and obtain a full refund from the Course Provider. The Enrollment
        Fees will not be reimbursed. No Academic Content can be advertised on
        the Platform more than twelve (12) months prior to its Delivery.
      </p>
      <ul>
        <li className={styles.listItem}>
          <strong>Content</strong>
        </li>
      </ul>
      <p>
        The general topic and content of the Academic Content must comply with
        the original posting. If ZubU deems, in its discretion, that the changes
        reasonably affect the integrity of the original topic or announced
        content, the Course Provider shall cancel the Academic Content in
        accordance with the Contract and create a new advertised Academic
        Content with the proper information in which case all active enrolled
        Students and Users will have the option to be assigned to the new
        Academic Content or to cancel their enrollment. The Enrollment Fees will
        not be reimbursed and will apply again to the new posting.
      </p>
      <ul>
        <li className={styles.listItem}>
          <strong>Price</strong>
        </li>
      </ul>
      <p>
        A Course Provider has the right to amend the Base Price, provided
        however that the amendment will not apply to Students and other Users
        who have already enrolled.
      </p>
      <ul>
        <li className={styles.listItem}>
          <strong>Speaker(s)</strong>
        </li>
      </ul>
      <p>
        If the advertised Speaker is a known public figure or acknowledged
        expert in its field, the Course Provider may not amend the Speaker and
        must cancel the Academic Content and reimburse all enrolled Students and
        other Users. In other cases, the Speaker(s) may be replaced via an
        amendment to the posting, by an equally qualified and renowned Speaker
        (with similar experience, expertise and credentials). If ZubU deems, in
        its discretion, that the change reasonably affects the integrity of the
        original announced Academic Content, the Course Provider shall cancel
        the Academic Content in accordance with the Contract and create a new
        advertised Academic Content with the proper information, in which case
        all active enrolled Students and Users will have the option to be
        assigned to the new Academic Content or to cancel their enrollment. The
        Enrollment Fees will not be reimbursed and will apply again to the new
        posting.
      </p>
      <ul>
        <li className={styles.listItem}>
          <strong>Repeated Amendment</strong>
        </li>
      </ul>
      <p>
        A Course Provider shall have a limit of three (3) amendments to an
        Academic Content posted on the Platform, after which no additional
        amendments will be allowed on the Platform. If the Academic Content
        suffers a fourth or more amendment, then the Course Provider must cancel
        the Course Content, resulting in full reimbursement of all enrolled
        Students and other Users and create a new Academic Content posting with
        new and accurate information. All active enrolled Students and Users
        will have the option to be assigned to the new Academic Content or to
        cancel their enrollment. The Enrollment Fees will not be reimbursed and
        will apply again to the new posting.
      </p>
      <ul>
        <li className={styles.listItem}>
          <strong>Repeated Failure to Deliver</strong>
        </li>
      </ul>
      <p>
        Course Providers are responsible to Deliver the Academic Content
        advertised on the Platform. Repeated failure and cancellation may, in
        ZubU&rsquo;s discretion, constitute a breach of Contract.
      </p>
      <ul>
        <li className={styles.listItem}>
          <strong>Status</strong>
        </li>
      </ul>
      <p>
        Course Providers may gain Account statuses (levels) based on their
        performance and reputation. Advanced levels may provide benefits,
        including obtaining marketing benefits, the whole in ZubU&rsquo;s entire
        discretion.
      </p>
      <ol start="6">
        <li className={styles.listItem}>
          <strong>PRICING</strong>
          <ul>
            <li className={styles.listItem}>
              <strong>Academic Content Price</strong>
            </li>
          </ul>
        </li>
      </ol>
      <p>
        When advertising Academic Content to the Platform, each Course Provider
        will select a price ("<strong>Base Price</strong>") for the Academic
        Content or alternatively, may choose to offer the Academic Content for
        free. The Base Price will be in Canadian or US dollar, as determined by
        ZubU, or such other currency agreed to with the Course Provider.
      </p>
      <ul>
        <li className={styles.listItem}>
          <strong>Limited Exclusivity</strong>
        </li>
      </ul>
      <p>
        Academic Content advertised on the Platform cannot be offered for free
        or at a lower price off the Platform (ex. Youtube or other websites or
        platforms).
      </p>
      <ul>
        <li className={styles.listItem}>
          <strong>Currency</strong>
        </li>
      </ul>
      <p>
        When a Student enrolls into Academic Content using a foreign currency,
        ZubU&rsquo;s payment service Supplier (<em>Stripe</em>) will convert the
        relevant Base Price or Promotional Program price from the Student's
        applicable currency using a system-wide foreign currency conversion rate
        and into the currency of the Base Price. Those conversion rates may not
        be identical to the applicable market rate in effect when a transaction
        is processed.
      </p>
      <ul>
        <li className={styles.listItem}>
          <strong>Transaction Taxes</strong>
        </li>
      </ul>
      <p>
        If a Student enrolls in Academic Content or makes another payment from a
        country that requires ZubU to remit national, state, or local sales or
        use taxes, value added taxes (VAT), or other similar transaction taxes
        ("<strong>Transaction Taxes</strong>"), under applicable law, ZubU will
        collect and remit those Transaction Taxes to the competent tax
        authorities for those purchases. ZubU may increase the sale price in its
        discretion where it determines that such Transaction Taxes may be due.
        For purchases through mobile applications, applicable Transaction Taxes
        may be collected by the mobile platform (such as Apple's App Store or
        Google Play).
      </p>
      <ul>
        <li className={styles.listItem}>
          <strong>Promotional</strong> <strong>Programs</strong>
        </li>
      </ul>
      <p>
        ZubU may, in its discretion, offer optional marketing programs in which
        a Course Provider can choose to participate ("
        <strong>Promotional Programs</strong>"). Promotional Programs may not be
        available to all Course Providers. These programs can help increase a
        Course Provider enrollment on ZubU and promoting Academic Content
        through additional marketing channels. There is no up-front cost to
        participate in these Promotional Programs, other than the Base Price
        reduction, and a Course Provider can modify its participation status at
        any time, though changes will not apply to currently active campaigns.
        Not all Promotional Programs will be available in all geographic
        territories or for all Academic Content. ZubU has sole discretion to
        determine which Academic Content to advertise as part of a Promotional
        Programs. ZubU may remove any Academic Content from a Promotional
        Program at any time and in its sole discretion, with or without notice
        to the Course Provider.
      </p>
      <p>
        If the Course Provider does not participate in any Promotional Programs,
        ZubU will list the Academic Content for the Base Price. However, ZubU
        may, in its entire discretion, at its sole cost, offer discounts or
        promotional codes in order to advertise its Services or the Platform, in
        which case they will be deducted from its own revenues (ex. discount on
        the Enrollment Price set forth in Section 8.3).
      </p>
      <ol start="7">
        <li className={styles.listItem}>
          <strong>ADVERTISING AND OTHER SERVICES</strong>
          <ul>
            <li className={styles.listItem}>
              <strong>Purchase of Advertisement</strong>
            </li>
          </ul>
        </li>
      </ol>
      <p>
        ZubU may offer advertisement options for Academic Content and other
        related content on the Platform. Each advertisement will respect the
        norms and policies made available by ZubU from time to time on the
        Platform, such as size, language, content and overall aesthetics.
        Advertisements supplied by ZubU may not be used by the Course Supplier
        on any other platform or media and its use is limited to the Services. A
        Course Provider may not advertise content other than Academic Content
        without notification to ZubU and ZubU&rsquo;s prior written approval.
      </p>
      <ul>
        <li className={styles.listItem}>
          <strong>Statistics and other Data</strong>
        </li>
      </ul>
      <p>
        ZubU will provide the Course Provider with information regarding
        Students enrolled in their Academic Content through the Platform, such
        as name, professional license number and contact information. The Course
        Provider shall at all times comply with applicable privacy laws,
        including the laws of the Student&rsquo;s jurisdiction and use solely
        the information for the purposes of Delivering the Academic Content.
      </p>
      <p>
        ZubU may also supply the Course Provider with statistics regarding the
        visibility of Academic Content advertised on the Platform, enrollment
        and effectiveness of advertisement and other Services. Such information
        will not contain personal information governed by privacy laws.
      </p>
      <p>
        ZubU is not responsible for the truthfulness and reliability of the
        data, statistics and other information supplied to the Course Providers
        and forwards same &ldquo;as is and where is&rdquo;.
      </p>
      <ul>
        <li className={styles.listItem}>
          <strong>Other Services</strong>
        </li>
      </ul>
      <p>
        ZubU may offer other Services from time to time, as may be advertised or
        made available on the Platform, including exclusive or customized
        features to help promote Academic Content. Such Services will be
        governed by the terms and conditions of the Contract and be subject to
        the Fees set forth on the Platform at the time of purchase or
        subscription.
      </p>
      <ol start="8">
        <li className={styles.listItem}>
          <strong>PAYMENTS AND FINANCIAL TERMS AND CONDITIONS</strong>
          <ul>
            <li className={styles.listItem}>
              <strong>Stripe Account </strong>
            </li>
          </ul>
        </li>
      </ol>
      <p>
        In order to receive payments and advertise Academic Content, each Course
        Provider must set up and maintain a Stripe account in good standing on
        the Platform, associated with a credit card with sufficient capacity to
        cover its financial obligations pursuant to the Contract. Each Course
        Provider must also provide identifying information or tax documentation
        requested by ZubU or its payment service Supplier (Stripe) and valid
        credit card information. ZubU and the payment service Supplier reserve
        the right to withhold payments or impose other penalties if it does not
        receive proper identifying information or tax documentation from a
        Course Provider. ZubU may also request credit references or
        authorization to undertake a credit check on the Course Provider.
      </p>
      <ul>
        <li className={styles.listItem}>
          <strong>Enrollment in Academic Content</strong>
        </li>
      </ul>
      <p>
        When a Student enrolls into Academic Content using the Platform, ZubU
        will charge and collect the price (namely the Base Price or the
        discounted price under the Promotional Program) using the Student or
        other User <em>Stripe</em> Account. Such price will be remitted in full
        to the Course Provider, after deduction of Transaction Taxes, if any.
      </p>
      <p>
        This amount will be deposited into the Course Provider <em>Stripe</em>{" "}
        account, but will not be released (available for use or withdrawal)
        until the payment is considered irrevocable pursuant to the Standard
        Terms and Conditions, namely, on the earlier of (a)&nbsp;48 hours after
        the Academic Content&rsquo;s Delivery and (b)&nbsp;the expiry of the
        longest period authorized by law and by the payment service Supplier (3
        months from the Student or other User payment in Canada). With respect
        to online courses available over a certain period of time,
        &ldquo;Delivery&rdquo; may be considered gradual and payment may be
        released in monthly or other instalments over the offered period of
        Delivery (provided however that if the Course Provider failed to provide
        a proper link, access codes and/or credentials to an enrolled Student or
        other User who cannot access the Academic Content as advertised on the
        Platform, then the Academic Content will be deemed
        &ldquo;unDelivered&rdquo;. The amounts deposited but not released will
        be available to ZubU for refunds and credits claimed in accordance with
        the General Terms and Conditions or to enforce the Course
        Provider&rsquo;s cancellation and refund policies.
      </p>
      <p>
        Each Course Provider hereby appoints ZubU and its payment service
        Supplier as its limited authorized payment collection agent solely for
        the purpose of accepting payment from Students and other Users and
        remitting the revenue shares to the Course Provider.
      </p>
      <ul>
        <li className={styles.listItem}>
          <strong>Service Price</strong>
        </li>
      </ul>

      <p>
        For the Services rendered, ZubU will charge the Course Provider an
        amount equal to 20% of the Base Price per Academic Content enrollment,
        plus applicable taxes (the &ldquo;<strong>Enrollment Fee</strong>
        &rdquo;).
      </p>

      <p>
        Academic Content enrollment may be offered at a base starting price of
        (i) CAD$35,00 for Canadian Course Providers or (ii) USD35,00 for
        international Course Providers or (iii) free (CAD$0,00 / USD0,00).
      </p>

      <p>
        Academic Content enrollment that is offered for free, ZubU will charge a
        flat fee per Academic Content enrollment on the Platform of (i)
        CAD$20,00 for Canadian Course Providers or (ii) USD20,00 for
        international Course Providers, plus applicable taxes (the &ldquo;
        <strong>Enrollment Fee</strong>&rdquo;).
      </p>
      <p>
        The Enrollment Fee will be withdrawn directly by ZubU from the Course
        Provider&rsquo;s <em>Stripe</em> Account, except if the Fee exceeds the
        Base Price (such as free enrollments), in which case it will be charged
        to the Course Provider&rsquo;s credit card associated with the Account.
        The Enrollment Fee will be paid irrevocably to ZubU at enrollment and
        will not be credited in case of cancellation of the Academic Content,
        refunds or other claims. The Enrollment Fee includes the payment service
        Supplier charges and mobile platform fees and administrative and
        handling fee.
      </p>
      <p>
        Other Services (such as advertising and support) will be invoiced by
        ZubU as per the prices advertised on the Platform or agreed with the
        Course Provider from time to time, with applicable taxes (the &ldquo;
        <strong>Service Fees</strong>&rdquo; and collectively with the
        Enrollment Fee, the &ldquo;<strong>Fees</strong>&rdquo;). The Service
        Fees will be charged by ZubU on the Course Provider&rsquo;s credit card
        associated with the Account, upon purchase or monthly, as set forth on
        the Platform at the time or purchase or as otherwise agreed between the
        Course Provider and ZubU. They are not refundable, unless ZubU fails to
        perform the Service.
      </p>
      <ul>
        <li className={styles.listItem}>
          <strong>Reports</strong>
        </li>
      </ul>
      <p>
        ZubU will provide revenue reports monthly, showing (a)&nbsp;the sales
        price (in local currency) and the converted price paid to the Course
        Provider for Academic Content Enrollment&nbsp;; (b)&nbsp;the Enrollment
        Fees and other Fees; (c)&nbsp;the amounts released as irrevocable
        payment and those not yet released.
      </p>
      <ul>
        <li className={styles.listItem}>
          <strong>Tax Obligations and Compliance</strong>
        </li>
      </ul>
      <p>
        Each Course Provider understands and agrees that it is ultimately
        responsible for any taxes on its income and for determining whether it
        is eligible to receive payments from ZubU.
      </p>
      <p>
        ZubU and its payment service Suppliers reserve the right to withhold or
        not to pay out funds in the event of identified fraud, violations of the
        Contract or third party intellectual property rights, other violations
        of applicable law, as a result of security issues, improper behavior
        reported by Students or illicit activity.
      </p>
      <p>
        If ZubU or its payment service Supplier cannot settle funds into the
        Course Provider <em>Stripe</em> account after the period of time set
        forth by the relevant state, country, or other government authority in
        its unclaimed property laws, ZubU may process the funds due to such
        Course Provider in accordance with its legal obligations, including by
        submitting those funds to the appropriate government authority as
        required by law.
      </p>
      <ul>
        <li className={styles.listItem}>
          <strong>Refunds</strong>
        </li>
      </ul>
      <p>
        Each Course Provider acknowledges and agrees that Students and other
        Users have the right to receive a refund or credit as detailed in the
        General Terms and Conditions. Course Providers will assume the cost of
        such refund or credit and will have to upload sufficient funds to its
        Stripe Account (or ensure sufficient credit to the credit card
        associated with its Account) to cover any overdue amount. As an example,
        since the Enrollment Fees and other Fees are not reimbursable, an amount
        equal to such Fees will have to be paid by the Course Provider to ZubU
        (withdrawn directly from the Course Provider&rsquo;s Stripe Account or
        charged to the credit card) in case of cancellation or refund of
        Academic Content.
      </p>
      <p>
        If a Student is granted a refund or credit after ZubU has paid the
        relevant Course Provider payment, ZubU reserves the right to either
        (a)&nbsp;require the Course Provider to pay to ZubU the full amount of
        the reimbursement or credit; or (b)&nbsp;deduct the amount due by the
        Course Provider from the next payment(s) payable to the Course Provider.
      </p>
      <ul>
        <li className={styles.listItem}>
          <strong>Speakers</strong>
        </li>
      </ul>
      <p>
        Each Course Provider is exclusively responsible, to ZubU&rsquo;s
        complete exoneration, for all payments, salaries, indemnity, profit
        share or other consideration payable to its Speakers, including all
        source deductions and applicable taxes.
      </p>
      <ol start="9">
        <li className={styles.listItem}>
          <strong>TRADEMARKS</strong>
        </li>
      </ol>
      <p>
        As long as a Course Provider maintains Academic Content advertised on
        the Platform and unless its Account has been suspended, revoked or
        terminated by ZubU, the Course Provider may use ZubU&rsquo;s trademarks
        in accordance with the following:
      </p>
      <ul>
        <li className={styles.listItem}>
          only use the trademarks in the format made available by ZubU and as
          detailed in any guidelines ZubU may publish from time to time on the
          Platform;
        </li>
        <li className={styles.listItem}>
          only use the trademarks in connection with the promotion and sale of
          the Academic Content advertised on the Platform or otherwise in
          connection with the Course Provider&rsquo;s general use of the
          Platform;
        </li>
        <li className={styles.listItem}>
          immediately comply if ZubU requests that the Course Provider
          discontinue use of the trademarks;
        </li>
        <li className={styles.listItem}>
          not use the trademarks in a misleading or disparaging way, or in
          connexion with any product, service or cause other than the Platform
          or the Academic Content advertised thereon;
        </li>
        <li className={styles.listItem}>
          not use the trademarks in a way that implies that ZubU endorses,
          sponsors, or approves the Academic Content, its content or other
          products or services offered by the Course Provider or its Speaker(s);
          and
        </li>
        <li className={styles.listItem}>
          not use the trademarks in a way that violates applicable law, third
          party rights or the Contract or in connection with an obscene,
          indecent, or unlawful topic or material.
        </li>
      </ul>
      <p>ZubU may revoke this authorization at any time.</p>
      <ol start="10">
        <li className={styles.listItem}>
          <strong>TERMINATION AND OTHER REMEDIES</strong>
        </li>
      </ol>
      <p>
        Without limiting other remedies available under applicable law ZubU
        reserve the right to remove Academic Content, suspend payments, suspend
        Accounts, revoke access to the Platform and/or ban Course Providers or
        terminate the Contract for any reason at any time, without prior notice,
        including in the following cases.
      </p>
      <ul>
        <li className={styles.listItem}>
          <strong>Breach of Contract</strong>
        </li>
      </ul>
      <p>
        If a Course Provider fails to comply with the terms and conditions of
        the Contract, fails to enforce the provisions of the Contract on its
        Speaker(s) and other representatives, or supplies Academic Content in
        breach of the Contract, ZubU may, in its discretion, either remove the
        Academic Content, suspend the Course Provider and/or Speaker Account or
        terminate and revoke the Contract and Platform Account.
      </p>
      <ul>
        <li className={styles.listItem}>
          <strong>Sanctions and Export Laws</strong>
        </li>
      </ul>
      <p>
        Each Course Provider warrants that it and its Speakers aren't restricted
        from using the Services and supplying Academic Content under Canadian
        and U.S. sanctions or export laws (as an individual or as an officer,
        director, or controlling shareholder of any entity on whose behalf the
        Services are used). If a Course Provider or Speaker becomes subject to
        such a restriction while it is party to the Contract, it shall notify
        ZubU within 24 hours.
      </p>
      <p>
        Each Course Provider and Speaker shall not use the Services to conduct
        or facilitate any transaction with any other individual or entity
        subject to such a restriction nor remove, export, or allow the export or
        re-export of the Services (or any product thereof, including technical
        data or Academic Content advertised on the Platform) outside Canada or
        the U.S. in violation of any restrictions, laws, or regulations of
        Canada, the U.S. or any other applicable laws.
      </p>
      <p>
        ZubU will have the right to terminate any further obligations, effective
        immediately and with no further liability (but without prejudice to the
        Course Provider or Speaker outstanding obligations to ZubU).
      </p>
      <ul>
        <li className={styles.listItem}>
          <strong>Indemnification</strong>
        </li>
      </ul>
      <p>
        Each Speaker and Course Provider agrees to indemnify, defend and hold
        ZubU and its affiliates, officers, directors, suppliers, partners and
        agents harmless from and against all liabilities, claims, demands,
        losses, damages, or expenses (including reasonable attorney fees), and
        third party claims arising from (a)&nbsp;Academic Content advertised on
        the Platform and other Licensed content; (b)&nbsp;a breach of these
        Supplier Terms and Conditions; or (c)&nbsp;a violation of any rights of
        a third party by the Course Provider. The foregoing indemnification
        obligation will survive the termination of the Contract and the supply
        of Academic Content.
      </p>
      <ul>
        <li className={styles.listItem}>
          <strong>Interests, Fines and Penalties</strong>
        </li>
      </ul>
      <p>
        ZubU has the right to charge the following fines and penalties for
        breach of these Supplier Terms and Conditions, which shall constitute
        &ldquo;Fees&rdquo; for the purposes of this Contract:
      </p>
      <ul>
        {/**<li className={styles.listItem}>
          <u>Cancellation</u>: <strong>XX$</strong>
        </li>*/}
        <li className={styles.listItem}>
          <u>Default of payment/insufficient funds</u> (NSF): as charged by{" "}
          <em>Stripe</em> or other Supplier + 15% administrative charge
        </li>
        <li className={styles.listItem}>
          <u>Collection efforts</u>: in the event of default of payment, ZubU
          may charge all collection costs, including reasonable attorney&rsquo;s
          costs and collection agency cost;
        </li>
      </ul>
      <p>All overdue amounts will bear interest at a rate of 18% yearly.</p>
      <ul>
        <li className={styles.listItem}>
          <strong>Deleting an Account</strong>
        </li>
      </ul>
      <p>
        If a Course Provider wishes to terminate the Contract, it may delete its
        Account on the Platform if (a)&nbsp;it has no outstanding Academic
        Content advertised, (b)&nbsp;all Academic Content has been Delivered or
        properly cancelled and (c)&nbsp;it owes to amount to ZubU or another
        User and has a good standing status on the Platform. Instructions on how
        to delete a Course Provider Account are available&nbsp;on the Platform.
        ZubU will use commercially reasonable efforts to make any remaining
        scheduled payments that are owed to the Course Provider before deleting
        the Account. Each Course Provider understands that if Students or other
        Users have previously enrolled in Academic Content, the Course Provider
        name and that Academic Content may remain accessible to those Students
        after the Account is deleted.
      </p>
      <ol start="11">
        <li className={styles.listItem}>
          <strong>SERVICE AND STANDARDS</strong>
        </li>
      </ol>
      <p>
        ZubU provides customer service to Course Provider during business hours
        set forth on the Platform or in the General Terms and Conditions. ZubU
        does not warrant that the Services or customer service will be available
        at all times, but undertakes to provide reasonable efforts to maintain
        the availability.
      </p>
      <ol start="12">
        <li className={styles.listItem}>
          <strong>MISCELLANEOUS</strong>
          <ul>
            <li className={styles.listItem}>
              <strong>Updating the Supplier Terms and Conditions</strong>
            </li>
          </ul>
        </li>
      </ol>
      <p>
        From time to time, ZubU may update Supplier Terms and Conditions,
        including changes in Enrollment Fees and other Fees, and ZubU reserves
        the right in its sole discretion to modify and/or make changes to these
        Supplier Terms and Conditions at any time. Upon any material change,
        ZubU will notify each Course Provider using email notice sent to the
        email address specified in the Account or by posting a notice through
        the Services. Modifications will become effective on the day they are
        posted unless stated otherwise.
      </p>
      <p>
        A Course Provider&rsquo;s continued use of the Services after changes
        become effective shall mean that they are accepted. Any revised Supplier
        Terms and Conditions shall supersede all previous Supplier Terms and
        Conditions.
      </p>
      <p>
        If a Course Provider wishes to refuse the change, it shall provide a
        written notice to ZubU no later than 10 days from notification of the
        change and therefore accepts to terminate the Contract and revoke its
        Account when eligible to such termination in accordance with
        Section10.5. The previously accepted version of the Contract will apply
        up and until the termination date.
      </p>
      <ul>
        <li className={styles.listItem}>
          <strong>Language and Translations</strong>
        </li>
      </ul>
      <p>
        Any version of these Supplier Terms and Conditions in a language other
        than English is provided for convenience and each Course Provider and
        Speaker understands and agrees that the English language will control if
        there is any conflict. The parties have expressly requested that these
        Supplier Terms and Conditions be drafted in English.{" "}
        <em>
          Les parties ont express&eacute;ment demand&eacute; que ces termes et
          conditions soient r&eacute;dig&eacute;es en anglais.
        </em>
      </p>
      <ul>
        <li className={styles.listItem}>
          <strong>Relationship Between Us</strong>
        </li>
      </ul>
      <p>
        Each Course Provider agrees that no joint venture, partnership,
        employment, contractor, or agency relationship exists with ZubU or
        between ZubU and a Speaker.
      </p>
      <ul>
        <li className={styles.listItem}>
          <strong>Survival</strong>
        </li>
      </ul>
      <p>
        The following sections shall survive the expiration or termination of
        these Supplier Terms and Conditions: Sections 2 (License to ZubU), 4.4
        (Relationship to Other Users), 8 (Payments and Financial Terms and
        Conditions), 10 (Termination and other Remedies), and 11(Miscellaneous).
      </p>
      <ul>
        <li className={styles.listItem}>
          <strong>General Terms and Conditions</strong>
        </li>
      </ul>
      <p>
        Sections 9 and 10 of the General Terms and Conditions apply to these
        Supplier Terms and Conditions, <em>mutatis mutandis</em>.
      </p>
      <ol start="13">
        <li className={styles.listItem}>
          <strong>PRE-AUTHORIZATION TO WITHDRAW</strong>
          <ul>
            <li className={styles.listItem}>
              <strong>Authorization and Power of Attorney</strong>
            </li>
          </ul>
        </li>
      </ol>
      <p>
        The Course Provider hereby authorizes ZubU, its successors and assigns
        and its designated payment service Supplier to debit automatically and
        without notice, Fees and other amounts due to ZubU by the Course
        Provider (collectively, &ldquo;<strong>Amounts Due</strong>&rdquo;)
        directly from the <em>Stripe</em> account associated with its Account,
        in such amount and on the date due in accordance with in the Contract.
        &ldquo;Amounts Due&rdquo; shall include, without limitation, any
        penalties, interests and costs due du ZubU as provided in the Contract.
      </p>
      <p>
        The Course Provider hereby authorizes ZubU and gives it power of
        attorney to communicate with <em>Stripe</em> in connexion with the{" "}
        <em>Stripe</em>account associate with the Account.
      </p>
      <ul>
        <li className={styles.listItem}>
          <strong>Mandatory provisions</strong>
        </li>
      </ul>
      <p>
        The Amounts Due constitute payment for the supply of services for the
        Course Provider&rsquo;s business and commercial activities. The Course
        Provider hereby waives the right to receive prior notice of any
        withdrawal, irrespective of the amount which may be different from the
        Amounts Due initially contracted. To the extent required under
        applicable law, the Course Provider may revoke the debit authorization,
        by providing a 30 days prior written notice and the Course Provider may
        obtain a sample cancellation form or further information by visiting
        www.cdnpay.ca / www.nacha.org, as applicable. To the extent required
        under applicable law, the Course Provider has certain recourse right if
        any debit does not comply with the Contract. For example, the Course
        Provider has the right to receive reimbursement for any debit that is
        not authorized or is not consistent with the Contract. To obtain more
        information on Course Provider&rsquo;s recourse rights, the Course
        Provider may contact ZubU or visit www.cdnpay.ca / www.nacha.org, as
        applicable.
      </p>
      <ul>
        <li className={styles.listItem}>
          <strong>Contact</strong>
        </li>
      </ul>
      <p>
        For the purpose of this Section, ZubU&rsquo;s contact information is:
      </p>
      <div className={styles.address}>
        <p>
          <strong>ZubU inc. </strong>
        </p>
        <p>
          11655 Rue Letellier
          <br />
          Montreal, Quebec
          <br />
          H3M 2Z7 Canada
        </p>
        <p>
          <a
            className={styles.link}
            href="mailto:customerservice@ZubUeducation.com"
          >
            customerservice@ZubUeducation.com
          </a>
        </p>
        <p>&nbsp;</p>
        <p>&nbsp;</p>
      </div>
    </div>
  );
};
