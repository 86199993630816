import React, { Component } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { Redirect } from "react-router-dom";
import * as paymentActions from "../../store/actions/payment";
import * as redirectActions from "../../store/actions/redirect";
import CardForm from "./card/CardForm";
import SimpleContainer from "../../components/ui/container/Container";
import MatAppBar from "../../components/ui/appBar/MatAppBar";
import Spinner from "../../components/ui/spinner/Spinner";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import Button from "@material-ui/core/Button";
import * as logger from "../../globals/Logger";

class PaymentEditScreen extends Component {
  state = { wait: false, isSubmitting: false, error: null };

  // ------------------------------

  update = async (propValue) => {
    await this.setState({ ...this.state, ...propValue });
  };

  // ------------------------------

  componentDidMount = async () => {
    try {
      await this.update({ wait: true });
      await this.props.requestSetupIntent();
      await this.update({ wait: false });
    } catch (err) {
      logger.error(err);
      this.update({ wait: false, error: err.message });
    }
  };

  // ------------------------------

  saveCardHandler = async (paymentMethodId) => {
    try {
      await this.update({ isSubmitting: true });
      await this.props.requestAttachPaymentMethod(paymentMethodId);
      await this.update({ isSubmitting: false });

      if (this.props.goToCheckout) {
        this.props.setAutoSelectPaymentMethod(true);
        this.props.history.push("/checkout");
      } else {
        this.props.setAutoSelectPaymentMethod(true);
        this.props.history.push("/payment");
      }
    } catch (err) {
      logger.error(err);
      this.update({ isSubmitting: false, error: err.message });
    }
  };

  // ------------------------------

  render() {
    if (this.props.action !== "PUSH") {
      return <Redirect to="/payment" />;
    }

    return (
      <div>
        {this.state.wait && <Spinner />}

        <MatAppBar
          static
          left={
            <Button
              color="inherit"
              style={{ color: "black" }}
              onClick={this.props.history.goBack}
              startIcon={<ArrowBackIosIcon />}
            >
              {this.props.t("g_back")}
            </Button>
          }
        ></MatAppBar>

        <div style={{ height: "80px" }}></div>

        <SimpleContainer disableGutters={false}>
          <CardForm
            clientSecret={this.props.clientSecret}
            submitting={(value) => {
              this.update({ isSubmitting: value });
            }}
            saveCard={this.saveCardHandler}
            t={this.props.t}
          />
        </SimpleContainer>
      </div>
    );
  }
}

// ------------------------------

const mapStateToProps = (state, props) => {
  const query = new URLSearchParams(props.location.search);
  let goToCheckout = false;
  for (let param of query.entries()) {
    if (param[0] === "g" && param[1] === "checkout") {
      goToCheckout = true;
    }
  }

  return {
    goToCheckout: goToCheckout,
    action: props.history.action,
    clientSecret: state.payment.clientSecret,
  };
};

// ------------------------------

const mapDispatchToProps = (dispatch) => {
  return {
    requestAttachPaymentMethod: (paymentMethodId) =>
      dispatch(paymentActions.requestAttachPaymentMethod(paymentMethodId)),
    requestSetupIntent: () => dispatch(paymentActions.requestSetupIntent()),
    fetchPaymentMethods: () => dispatch(paymentActions.fetchPaymentMethods()),
    setAutoSelectPaymentMethod: (value) =>
      dispatch(redirectActions.setAutoSelectPaymentMethod(value)),
  };
};

export default withTranslation("translations")(
  connect(mapStateToProps, mapDispatchToProps)(PaymentEditScreen)
);
