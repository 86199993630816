import React, { Component } from "react";
import { connect } from "react-redux";
import styles from "./StudentOrderScreen.module.css";
import { Redirect } from "react-router-dom";

import * as connectActions from "../../store/actions/connect";
import * as studentActions from "../../store/actions/student";
import * as studentOrderActions from "../../store/actions/studentorder";

import Spinner from "../../components/ui/spinner/Spinner";
import MatAppBar from "../../components/ui/appBar/MatAppBar";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import Button from "@material-ui/core/Button";
import InfoModal from "../../components/modals/infoModal/InfoModal";
import OrderRegChargeItem from "../../components/items/orderRegChargeItem/OrderRegChargeItem";
import OrderRegDetailsItem from "../../components/items/orderRegDetailsItem/OrderRegDetailsItem";
import RefundConfirmModal from "../../components/modals/refundConfirmModal/RefundConfirmModal";
import ConfirmModal from "../../components/modals/confirmModal/ConfirmModal";
import * as logger from "../../globals/Logger";
import AccessAlarmsIcon from "@material-ui/icons/AccessAlarms";

class StudentOrderScreen extends Component {
  state = {
    wait: false,
    waitNoRender: false,
    waitRefund: false,
    refundOnly: true,
    openUnregisterRefundModal: false,
    idToBeUnregistered: null,

    // To refactor
    selectedStaffMember: [],
    selectedStaffMemberRefund: [],

    // -----
    message: null,
    messageTitle: null,
    errorModal: false,
    waitMessage: "",

    openCancelConfirmModal: false,
    idToBeDeleted: null,
  };

  // ------------------------------

  update = async (propValue) => {
    await this.setState({ ...this.state, ...propValue });
  };

  // ------------------------------

  componentDidMount = async () => {
    try {
      await this.update({ wait: true, waitMessage: "Loading Student Order" });
      await Promise.all([
        this.props.fetchStudentsByCharge(this.props.chargeId),
        this.props.fetchStudentOrder(this.props.chargeId),
        this.props.fetchRefund(this.props.chargeId),
      ]);
      await this.update({ wait: false, waitMessage: "" });
      window.scrollTo(0, 0);
    } catch (error) {
      logger.error(error);
      await this.update({
        wait: false,
        errorModal: true,
        message: error.message,
        waitMessage: "",
      });
    }
  };
  // ------------------------------

  cancelPaymentHandler = (id) => {
    this.update({
      openCancelConfirmModal: true,
      idToBeDeleted: id,
    });
  };

  // ------------------------------

  deleteConfirmHandler = async (answer) => {
    if (answer === "ok") {
      try {
        await this.update({
          wait: true,
          openCancelConfirmModal: false,
        });

        await this.props.cancelPaymentInstallment(
          this.props.chargeId,
          this.state.idToBeDeleted
        );

        await this.update({
          wait: false,
          idToBeDeleted: null,
        });
      } catch (err) {
        logger.error(err);
        this.update({
          wait: false,
          error: err.message,
        });
      }
    } else {
      this.update({
        openCancelConfirmModal: false,
        idToBeDeleted: null,
      });
    }
  };

  // ------------------------------

  loadPageDataPartial = async (waitMessage) => {
    try {
      await this.update({ waitNoRender: true, waitMessage: waitMessage });
      await Promise.all([
        this.props.fetchStripeBalance(),
        this.props.fetchRefund(this.props.chargeId),
      ]);
      await this.update({ waitNoRender: false, waitMessage: "" });
      window.scrollTo(0, 0);
    } catch (error) {
      logger.error(error);
      await this.update({
        wait: false,
        errorModal: true,
        message: error.message,
        waitMessage: "",
      });
    }
  };

  // ------------------------------

  loadAccountBalance = async () => {
    try {
      await this.update({ waitRefund: true });
      await this.props.fetchStripeBalance();
      await this.update({ waitRefund: false });
    } catch (error) {
      logger.error(error);
      await this.update({
        waitRefund: false,
        errorModal: true,
        message: error.message,
      });
    }
  };

  // ------------------------------

  calculateRefundTotal = () => {
    return this.state.selectedStaffMemberRefund.length > 0
      ? this.state.selectedStaffMemberRefund
          .map((item) => item.memberRefundValue)
          .reduce((prev, next) => Number(prev) + Number(next))
      : 0;
  };

  // ------------------------------

  priceInvalid = (price, discount, amount, refundSubTotal) => {
    return amount > price - discount - refundSubTotal;
  };

  // ------------------------------

  calcRefundSubTotalInvalid = () => {
    if (
      this.state.selectedStaffMemberRefund.length > 0 &&
      this.props.studentOrder
    ) {
      for (const orderCourse of this.props.studentOrder.order_details) {
        const courseId = orderCourse.courseId;

        for (const pricingItem of orderCourse.pricingItems) {
          const pi = pricingItem.pricingItem;

          for (const sm of pricingItem.staffMembers) {
            // -----
            let subTotal = 0;
            if (this.props.refund.length > 0) {
              for (const r of this.props.refund) {
                subTotal += r.memberRefundObj
                  .map((mfo) => {
                    return mfo.member.id === sm.id &&
                      mfo.member.courseId === courseId &&
                      mfo.memberPricingItem.isEqual(pi)
                      ? mfo.memberRefundValue
                      : 0;
                  })
                  .reduce((prev, next) => Number(prev) + Number(next));
              }
            }

            const refundAmountObj = this.state.selectedStaffMemberRefund.find(
              (selStaff) =>
                selStaff.member.id === sm.id &&
                selStaff.member.courseId === courseId &&
                selStaff.memberPricingItem.isEqual(pi)
            );

            const refundAmoutInvalid = this.priceInvalid(
              pricingItem.cost(),
              sm.promoDiscount,
              refundAmountObj ? refundAmountObj.memberRefundValue : 0,
              subTotal
            );

            if (refundAmoutInvalid) return true;
          }
        }
      }
    }

    return false;
  };

  // ------------------------------

  toggleUnregisterRow = async (courseId, selected) => {
    const selectStaffCopy = [...this.state.selectedStaffMember];

    selected.courseId = courseId;

    const index = selectStaffCopy.findIndex(
      (sm) => sm.id === selected.id && sm.courseId === selected.courseId
    );

    if (index === -1) {
      selectStaffCopy.push(selected);
      await this.update({ selectedStaffMember: selectStaffCopy });
    } else {
      selectStaffCopy.splice(index, 1);
      await this.update({ selectedStaffMember: selectStaffCopy });
    }
  };

  // ------------------------------

  findMatchingRefundRow = (selected, pricingItem, list) => {
    return list.findIndex(
      (sm) =>
        sm.member.id === selected.id &&
        sm.member.courseId === selected.courseId &&
        pricingItem.isEqual(sm.memberPricingItem) &&
        sm.chargeId === selected.chargeId
    );
  };

  // ------------------------------

  toggleRefundRow = async (courseId, selected, pricingItem, chargeId) => {
    const selectStaffCopy = [...this.state.selectedStaffMemberRefund];

    selected.courseId = courseId; // I dont like this
    selected.chargeId = chargeId ? chargeId : this.props.chargeId; // I dont like this

    const index = this.findMatchingRefundRow(
      selected,
      pricingItem,
      selectStaffCopy
    );

    if (index === -1) {
      selectStaffCopy.push({
        member: selected,
        memberRefundValue: 0,
        memberPricingItem: pricingItem,
        chargeId: chargeId ? chargeId : this.props.chargeId,
      });
      await this.update({ selectedStaffMemberRefund: selectStaffCopy });
    } else {
      selectStaffCopy.splice(index, 1);
      await this.update({ selectedStaffMemberRefund: selectStaffCopy });
    }
  };

  // ------------------------------

  updateRefundRowAmount = async (
    courseId,
    selected,
    amount,
    pricingItem,
    chargeId
  ) => {
    const selectStaffCopy = [...this.state.selectedStaffMemberRefund];

    selected.courseId = courseId;
    selected.chargeId = chargeId ? chargeId : this.props.chargeId; // I dont like this

    const index = this.findMatchingRefundRow(
      selected,
      pricingItem,
      selectStaffCopy
    );

    if (index !== -1 && amount && Number(amount) > 0) {
      selectStaffCopy[index] = {
        member: selected,
        memberRefundValue: amount,
        memberPricingItem: pricingItem,
        chargeId: chargeId ? chargeId : this.props.chargeId, //  associate it to a charge row
      };

      await this.update({
        selectedStaffMemberRefund: selectStaffCopy,
      });
    }
  };

  // ------------------------------

  unregisterRefundHandler = () => {
    this.update({
      refundOnly: this.state.selectedStaffMember.length === 0,
      openUnregisterRefundModal: true,
    });
  };

  // ------------------------------

  renderOrderActionHistory = (studentOrder) => {
    const orderHistory = [...this.props.unregistered, ...this.props.refund];

    const sortedEvents = orderHistory.sort((a, b) => {
      const aDate = a.unregisteredDate ? a.unregisteredDate : a.date;
      const bDate = b.unregisteredDate ? b.unregisteredDate : b.date;
      return aDate.getTime() < bDate.getTime() ? 1 : -1;
    });

    let courseIdToTitle = {};
    if (studentOrder) {
      for (const course of studentOrder.order_details) {
        courseIdToTitle[course.courseId] = course.title;
      }
    }

    const arrEvent = [];
    sortedEvents.map((event, index) =>
      arrEvent.push(
        <div key={index}>
          {event.unregisteredDate
            ? this.renderUnregister(courseIdToTitle, event, index)
            : this.renderRefund(courseIdToTitle, event, index)}
        </div>
      )
    );

    return arrEvent;
  };

  // ------------------------------

  renderRefund = (courseIdToTitle, refund, index) => {
    return (
      <div className={styles.eventContainer} key={index}>
        <div className={styles.eventDate}>
          <AccessAlarmsIcon fontSize="inherit" />
          &nbsp;
          {refund.format_date()}
        </div>
        <div className={styles.eventDescription}>
          <div className={styles.eventName}>Refund&nbsp;</div> issued for the
          amount of&nbsp;
          {this.props.currency.shortName}
          {refund.amount}&nbsp;
        </div>

        <div className={styles.eventReason}>
          <div className={styles.eventName}>Reason:&nbsp;</div>
          {refund.reason}
        </div>

        <div className={styles.refundEventContainer}>
          <div className={styles.refundSummaryTitle}>Refund Details:</div>
          {refund.memberRefundObj.map((r, index) => (
            <div className={styles.eventDescription} key={index}>
              {index + 1}. {r.member.first} {r.member.last} amount of&nbsp;
              {this.props.currency.shortName}
              {r.memberRefundValue} for&nbsp;
              <div className={styles.courseTitleEvent}>
                {courseIdToTitle[r.member.courseId]}
              </div>
              &nbsp;(
              {r.memberPricingItem.casting +
                " for " +
                r.memberPricingItem.audience.join(", ") +
                " " +
                r.memberPricingItem.component}
              )
            </div>
          ))}
        </div>
      </div>
    );
  };

  // ------------------------------

  renderUnregister = (courseIdToTitle, student, index) => {
    return (
      <div className={styles.eventContainer} key={index}>
        <div className={styles.eventDate}>
          <AccessAlarmsIcon fontSize="inherit" /> &nbsp;
          {student.format_unregistered_date()}
        </div>
        <div className={styles.eventDescription}>
          {student.first} {student.last}
          <div className={styles.eventName}>&nbsp;unregistered&nbsp;</div>
          from&nbsp;
          <div className={styles.courseTitleEvent}>
            {courseIdToTitle[student.courseId]}
          </div>
          &nbsp;({student.registrationOption})
        </div>
        <div className={styles.eventReason}>
          <div className={styles.eventName}>Reason:&nbsp;</div>
          {student.unregisteredReason}
        </div>
      </div>
    );
  };

  // ------------------------------

  unregisterRefundConfirmHandler = async (action, reason) => {
    try {
      await this.update({
        waitNoRender: true,
        waitMessage: "Processing",
      });

      if (action === "Refund" || action === "Refund and Unregister") {
        const refundTotal = this.calculateRefundTotal();
        if (refundTotal > 0) {
          const onlyRefundRowsWithAmounts = this.state.selectedStaffMemberRefund.filter(
            (smr) => Number(smr.memberRefundValue) > 0
          );

          await this.props.issueRefund(
            onlyRefundRowsWithAmounts,
            this.props.chargeId,
            reason
          );
        }
      }

      if (action === "Unregister" || action === "Refund and Unregister") {
        const loadAsync = [];
        for (const studentToUnregister of this.state.selectedStaffMember) {
          loadAsync.push(
            this.props.unregisterStudent(
              studentToUnregister.courseId,
              studentToUnregister.id,
              reason
            )
          );
        }
        await Promise.all(loadAsync);
      }

      await this.update({
        waitNoRender: false,
        waitMessage: "",
        errorModal: false,
        message: this.getSuccessMessage(action),
        messageTitle: this.getSuccessTitle(action),
      });
    } catch (err) {
      logger.error(err);
      this.update({
        waitNoRender: false,
        waitMessage: "",
        errorModal: true,
        message: err.message,
      });
    }
  };

  // ------------------------------

  getSuccessMessage = (action) => {
    if (action === "Refund") {
      return (
        "Congratulations, your refund has been issued successfully." +
        "  The ZubU user of the associated charge has been notified of the refund." +
        "  You may also see the refund details from your account balance."
      );
    }

    if (action === "Refund and Unregister") {
      return (
        "Congratulations, your refund has been issued successfully.  " +
        "The ZubU user of the associated charge has been notified of the refund and unregistration." +
        "  You may also see the refund details from your account balance."
      );
    }

    if (action === "Unregister") {
      return (
        "Congratulations, you have unregistered students successfully.  " +
        "The ZubU user has been notified of the unregistration."
      );
    }
  };

  // ------------------------------

  getSuccessTitle = (action) => {
    if (action === "Refund") {
      return "Issue Refund Success";
    }

    if (action === "Refund and Unregister") {
      return "Refund / Unregistration Success";
    }

    if (action === "Unregister") {
      return "Unregistration Success";
    }
  };

  // ------------------------------

  render() {
    if (this.props.action !== "PUSH") {
      return <Redirect to="/courses" />;
    }

    // ------------------------------

    if (this.state.wait) {
      return <Spinner message={this.state.waitMessage} />;
    }

    // ------------------------------

    return (
      <div style={{ background: "white" }}>
        {this.state.waitNoRender && (
          <Spinner message={this.state.waitMessage} />
        )}

        {this.state.openCancelConfirmModal && (
          <ConfirmModal
            title="Confirmation Required"
            message={"Are you sure you wish to cancel this payment ?"}
            open={this.state.openCancelConfirmModal}
            onClose={this.deleteConfirmHandler}
            cancelButtonText="No"
            okButtonText="Yes"
          />
        )}

        {this.state.message !== null && (
          <InfoModal
            isErrorModal={this.state.errorModal}
            title={
              this.state.errorModal
                ? "An error has occured"
                : this.state.messageTitle
            }
            message={this.state.message}
            open={this.state.message !== null}
            onClose={async () => {
              this.update({ message: null });

              if (!this.state.errorModal) {
                await this.update({
                  wait: false,
                  waitNoRender: false,
                  refundOnly: true,
                  openUnregisterRefundModal: false,
                  idToBeUnregistered: null,
                  selectedStaffMember: [],
                  selectedStaffMemberRefund: [],
                  message: null,
                  messageTitle: null,
                  errorModal: false,
                  waitMessage: "",
                });

                await this.loadPageDataPartial("Loading");

                await this.update({
                  selectedStaffMember: [],
                });
              }
            }}
          />
        )}
        {this.state.openUnregisterRefundModal && (
          <RefundConfirmModal
            loadAccountBalance={this.loadAccountBalance}
            wait={this.state.waitRefund}
            open={true}
            refundModal={this.calculateRefundTotal() > 0}
            refundTotal={this.calculateRefundTotal()}
            unregisterModal={this.state.selectedStaffMember.length > 0}
            currency={this.props.currency}
            total={this.props.stripeBalance}
            onClose={async (action, reason) => {
              await this.update({
                openUnregisterRefundModal: false,
              });
              if (action) {
                await this.unregisterRefundConfirmHandler(action, reason);
              }
            }}
            refund={this.props.refund}
          />
        )}
        <MatAppBar
          static
          left={
            <Button
              color="inherit"
              style={{ color: "black" }}
              onClick={() => {
                this.props.courseSelectedId
                  ? this.props.history.push("/students")
                  : this.props.history.push("/account");
              }}
              startIcon={<ArrowBackIosIcon />}
            >
              Back
            </Button>
          }
        />
        <div style={{ height: "70px" }}>&nbsp;</div>
        <div className={styles.form}>
          <OrderRegChargeItem
            currency={this.props.currency}
            studentOrder={this.props.studentOrder}
            refundTotal={this.props.refundTotal}
            onCancelPayment={this.cancelPaymentHandler}
          />
          &nbsp;
          <OrderRegDetailsItem
            title="Registration"
            refundTotal={this.calculateRefundTotal()}
            refundSubTotalInvalid={this.calcRefundSubTotalInvalid()}
            courseSelectedId={this.props.courseSelectedId}
            currency={this.props.currency}
            refund={this.props.refund}
            students={this.props.students}
            studentOrder={this.props.studentOrder}
            selectedStaffMember={this.state.selectedStaffMember}
            selectedStaffMemberRefund={this.state.selectedStaffMemberRefund}
            /**actions **/
            toggleUnregisterRow={this.toggleUnregisterRow}
            toggleRefundRow={this.toggleRefundRow}
            updateRefundRowAmount={this.updateRefundRowAmount}
            unregisterRefund={this.unregisterRefundHandler}
          />
          <div className={styles.card}>
            <div className={styles.sectionContainer}>
              <div className={styles.sectionTitle}>
                Refund / Unregister History
              </div>
              {this.renderOrderActionHistory(this.props.studentOrder)}
              <div>&nbsp;</div>
              <hr style={{ borderTop: "1px dashed #ccc" }}></hr>
              <div>&nbsp;</div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    action: props.history.action,
    currency: state.profile.profile.currency,
    chargeId: state.studentorder.chargeId,
    courseSelectedId: state.studentorder.courseSelectedId,
    studentOrder: state.studentorder.studentorder,
    stripeBalance: state.connect.total,
    refund: state.studentorder.refund,
    refundTotal: state.studentorder.refundTotal,
    students: state.student.students,
    unregistered: state.student.students.filter(
      (s) => s.isUnregistered === true
    ),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchStudentsByCharge: (chargeId) =>
      dispatch(studentActions.fetchStudentsByCharge(chargeId)),
    fetchStudentOrder: (chargeId) =>
      dispatch(studentOrderActions.fetchStudentOrder(chargeId)),
    unregisterStudent: (courseId, studentId, reason) =>
      dispatch(studentActions.unregisterStudent(courseId, studentId, reason)),
    fetchStripeBalance: () => dispatch(connectActions.fetchStripeBalance()),
    fetchRefund: (chargeId) =>
      dispatch(studentOrderActions.fetchRefund(chargeId)),
    issueRefund: (memberRefund, chargeId, reason) =>
      dispatch(connectActions.issueRefund(memberRefund, chargeId, reason)),
    cancelPaymentInstallment: (parentChargeId, installmentId) =>
      dispatch(
        studentOrderActions.cancelPaymentInstallment(
          parentChargeId,
          installmentId
        )
      ),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(StudentOrderScreen);
