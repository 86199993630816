import React, { Fragment, Component } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import styles from "./CourseDetailsScreen.module.css";
import * as inboxActions from "../../../store/actions/inbox";
import * as mobilecartActions from "../../../store/actions/mobilecart";
import * as redirectActions from "../../../store/actions/redirect";
import * as reviewActions from "../../../store/actions/reviews";
import * as staffActions from "../../../store/actions/staff";
import * as courseActions from "../../../store/actions/courses";
import * as providerActions from "../../../store/actions/provider";
import * as settingActions from "../../../store/actions/setting";
import * as profileActions from "../../../store/actions/profile";
import * as rateActions from "../../../store/actions/rate";
import * as memberActions from "../../../store/actions/member";
import Spinner from "../../../components/ui/spinner/Spinner";
import * as logger from "../../../globals/Logger";
import Footer from "../../../components/ui/footer/Footer";
import InfoModal from "../../../components/modals/infoModal/InfoModal";
import AddToCartModal from "../../../components/modals/addToCartModal/AddToCartModal";
import { CURRENCY, EXPIRED, CANCELLED } from "../../../globals/Data";
import StaffMember from "../../../model/StaffMember";
import CourseListing from "../../../components/ui/courseViewer/CourseListing";
import { compareArray } from "../../../globals/Common";
import Fab from "@material-ui/core/Fab";
import InboxChatModal from "../../../components/modals/inboxChatModal/InboxChatModal";
import QuestionAnswerIcon from "@material-ui/icons/QuestionAnswer";
import {
  objectHasNoValues,
  setLanguage,
} from "../../../globals/Common";
import RatingReviewsContainer from "../../../components/ui/rating/RatingReviewsContainer";
import AlertSuccess from "../../../components/ui/snackbar/AlertSuccess";
import DefaultToolbar from "../../welcome/DefaultToolbar";
import AddToCartBubble from "./AddToCartBubble";

class CourseDetailsScreen extends Component {
  state = {
    wait: false,
    error: null,
    showAddToCartModal: false,
    showChatModal: false,
    filters: { page: 1, limit: 3 },
    isLoadingMore: false,
    alertMessage: null,
    waitModal: false,
  };

  // ------------------------------

  update = async (propValue) => {
    await this.setState({ ...this.state, ...propValue });
  };

  // ------------------------------

  componentDidMount = async () => {
    try {
      window.scrollTo(0, 0);

      await this.update({ wait: true });
      const loadAsync = [];

      if (objectHasNoValues(this.props.mobileSetting)) {
        await this.props.fetchMobileSetting();
      }

      loadAsync.push(this.props.fetchStaffMembers());

      loadAsync.push(this.props.fetchMemberships());

      if (this.props.userId && objectHasNoValues(this.props.profile)) {
        loadAsync.push(this.props.fetchUserProfile());
      }

      if (this.props.providerid) {
        loadAsync.push(this.props.fetchProvider(this.props.providerid));
      }

      if (objectHasNoValues(this.props.rates)) {
        loadAsync.push(this.props.fetchRates());
      }

      if (this.props.courseId) {
        loadAsync.push(
          this.props.fetchCourse(this.props.courseId, this.props.providerid)
        );
      }

      if (this.props.courseId && this.props.providerid) {
        this.props.fetchReviews({
          page: 1,
          limit: 1,
          providerId: this.props.providerid,
        });
      } else if (this.props.course && this.props.course.provider_id) {
        this.props.fetchReviews({
          page: 1,
          limit: 1,
          providerId: this.props.course.provider_id,
        });
      } else {
        this.props.clearReviewAndRating();
      }

      await Promise.all(loadAsync);
      await this.update({ wait: false });
    } catch (err) {
      logger.error(err);
      this.update({ wait: false, error: err.message });
    }
  };

  // ------------------------------

  goBackHandler = async () => {
    if (this.props.provider) {
      this.props.history.push(`/shop?p=${this.props.provider.id}`);
    } else {
      this.props.history.goBack();
    }
  };

  // ------------------------------

  register = async () => {
    if (
      this.props.isAuthenticated &&
      this.props.profile &&
      this.props.profile.completedProfile()
    ) {
      await this.update({ showAddToCartModal: true });
    } else if (this.props.isAuthenticated) {
      this.props.setProfileToCourseRedirect(true);
      this.props.history.push("/mysettings");
    } else {
      this.props.setCourseDetailsRedirect(true);
      this.props.history.push("/login");
    }
  };

  // ------------------------------

  openInboxChat = async () => {
    if (this.props.isAuthenticated) {
      try {
        await this.props.fetchMessages(this.props.activeConversation);
        if (this.props.activeConversation) {
          await this.props.updateLastViewed(this.props.activeConversation.id);
        }
        await this.update({ showChatModal: true });
      } catch (error) {
        logger.error(err);
        this.update({ error: err.message });
      }
    } else {
      this.props.setCourseDetailsRedirect(true);
      this.props.history.push("/login");
    }
  };

  // ------------------------------

  getStaff = (userId, profile, selectParticipantId, staffList) => {
    if (profile.id === selectParticipantId) {
      return new StaffMember(
        userId,
        profile.licenseNumber,
        profile.occupation,
        profile.last,
        profile.first,
        profile.email,
        true
      );
    }

    return staffList.find((staff) => staff.id === selectParticipantId);
  };

  // ------------------------------

  onAddToCartHandler = (courseParticipants, staffMember) => {
    try {
      let pricingItemToStaff = [];

      for (const staffSelection of courseParticipants) {
        const staffMember = this.getStaff(
          this.props.userId,
          this.props.profile,
          staffSelection.id,
          this.props.staff
        );

        const pricingItemIndex = pricingItemToStaff.findIndex(
          (pi) =>
            pi.pricingItem.cost === staffSelection.pricingItem.cost &&
            compareArray(
              pi.pricingItem.audience,
              staffSelection.pricingItem.audience
            ) &&
            pi.pricingItem.casting === staffSelection.pricingItem.casting &&
            pi.pricingItem.credit_handson_hours ===
            staffSelection.pricingItem.credit_handson_hours &&
            pi.pricingItem.credit_lecture_hours ===
            staffSelection.pricingItem.credit_lecture_hours &&
            pi.pricingItem.ce_odq === staffSelection.pricingItem.ce_odq &&
            pi.pricingItem.component === staffSelection.pricingItem.component &&
            pi.pricingItem.description ===
            staffSelection.pricingItem.description
        );

        if (pricingItemIndex >= 0) {
          const copyStaff = [
            ...pricingItemToStaff[pricingItemIndex].staffMembers,
          ];

          copyStaff.push(staffMember);

          pricingItemToStaff[pricingItemIndex] = {
            cost: pricingItemToStaff[pricingItemIndex].cost,
            pricingItem: pricingItemToStaff[pricingItemIndex].pricingItem,
            staffMembers: copyStaff,
          };
        } else {
          const staffMembers = [];
          staffMembers.push(staffMember);
          pricingItemToStaff.push({
            cost: staffSelection.cost,
            pricingItem: staffSelection.pricingItem,
            staffMembers: staffMembers,
          });
        }
      }

      const duplicate = this.validateSameStudentCourseRegistrationOption(
        this.props.course,
        pricingItemToStaff
      );

      if (duplicate) {
        this.update({ alertMessage: duplicate });
      } else {
        this.props.addToCart(this.props.course, pricingItemToStaff);
        this.props.history.push(`/cart`);
      }
    } catch (err) {
      logger.error(err);
      this.update({ wait: false, error: err.message });
    }
  };

  // ------------------------------

  validateSameStudentCourseRegistrationOption = (
    selectedCourse,
    pricingItemToStaff
  ) => {
    for (const cartProvider of this.props.cartItems) {
      const cartCourse = cartProvider.cartCourseItems[selectedCourse.id];
      if (cartCourse) {
        for (const dataPi of cartCourse.pricingItems) {
          for (const pricingItemsToStaffDataPi of pricingItemToStaff) {
            for (const staffMember of dataPi.staffMembers) {
              for (const staffMember2 of pricingItemsToStaffDataPi.staffMembers) {
                if (staffMember.id === staffMember2.id) {
                  return (
                    staffMember.first +
                    " " +
                    staffMember.last +
                    this.props.t("cart_already_in_cart_text") +
                    "[" +
                    selectedCourse.title +
                    "]" +
                    this.props.t("cart_already_in_cart_text_try_again")
                  );
                }
              }
            }
          }
        }
      }
    }

    return null;
  };

  // ------------------------------

  updateNextPage = () => {
    const copyFilter = { ...this.state.filters };
    const nextPage = this.state.filters.page ? this.state.filters.page + 1 : 2;
    copyFilter["page"] = nextPage;
    copyFilter["providerId"] = this.props.course.provider_id;
    return copyFilter;
  };

  // ------------------------------

  loadMoreHandler = async () => {
    try {
      await this.update({
        isLoadingMore: true,
      });
      const newFilters = this.updateNextPage();
      this.update({ filters: newFilters });
      this.props.fetchReviews(newFilters);
      await this.update({
        isLoadingMore: false,
      });
    } catch (error) {
      logger.error(error);
      await this.update({
        isLoadingMore: false,
        error: error.message,
      });
    }
  };

  // ------------------------------

  updateMobileCurrency = async (currency, language) => {
    try {
      await this.props.updateCurrency(currency, language);
      setLanguage(language, this.props.i18n);
    } catch (error) {
      logger.error(error);
    }
  };

  // ------------------------------

  courseExpired = (course) => {
    if (!course) {
      return true;
    }

    if (
      course.activation_status !== "active" ||
      course.activation === EXPIRED ||
      course.activation === CANCELLED
    ) {
      return true;
    }

    return false;
  };

  // ------------------------------

  validateMemberIDHanlder = async (memberID) => {
    try {
      await this.update({ waitModal: true });
      await this.props.validateMembership(this.props.course.provider_id, memberID);
      await this.update({ waitModal: false });
      return true;
    } catch (error) {
      await this.update({ waitModal: false });
      logger.error(error);
      await this.update({
        wait: false,
        error: error.message,
      });
    }
    return false;
  }

  // ------------------------------

  render() {
    if (this.props.action !== "PUSH" && !this.props.courseId) {
      return <Redirect to="/" />;
    }

    if (this.state.wait) {
      return <Spinner message="Loading" />;
    }

    if (!this.state.wait && !this.props.course) {
      return (
        <InfoModal
          isErrorModal={true}
          title={this.props.t("error_occurred")}
          message={
            "Cannot find the page your are requesting.  Please try again."
          }
          open={true}
          onClose={() => {
            this.update({ error: null });
            this.props.history.push(`/`);
          }}
        />
      );
    }

    return (
      <div className={styles.screen}>
        {this.state.showChatModal && (
          <InboxChatModal
            selectedConversation={this.props.activeConversation}
            showAllConversations={false}
            conversations={this.props.conversations}
            messages={this.props.messages}
            open={this.state.showChatModal}
            onSendMessage={(message) => {
              try {
                this.props.sendMessage(
                  this.props.selectedConversation,
                  message,
                  this.props.course.provider_id,
                  this.props.course.provider_name
                );
              } catch (error) {
                logger.error(err);
                this.update({ error: err.message });
              }
            }}
            onClose={() => {
              this.update({ showChatModal: false });
              if (
                this.props.selectedConversation &&
                this.props.selectedConversation.id
              ) {
                this.props.stopInboxMessagesListener(
                  this.props.selectedConversation.id
                );
              }
            }}
            onSelectConversation={() => { }}
          />
        )}

        <div
          className={styles.floatingContainer}
          style={
            !this.props.isAuthenticated
              ? {
                top: "85px",
              }
              : null
          }
        >
          <AddToCartBubble
            course={this.props.course}
            rates={this.props.rates}
            currency={this.props.currency}
            onRegister={this.register}
            courseExpired={this.courseExpired(this.props.course)}
            provider={this.props.provider}
          />

          {!this.courseExpired(this.props.course) && (
            <div className={styles.bubble}>
              <div className={styles.question}>
                {this.props.t("course_details_message_blurb")}
              </div>
              <div className={styles.princeRange}>
                <Fab
                  variant="extended"
                  onClick={this.openInboxChat}
                  style={{ width: "100%" }}
                >
                  <QuestionAnswerIcon />
                  &nbsp;{this.props.t("course_details_message")}
                </Fab>
              </div>
            </div>
          )}
        </div>

        {this.props.profile &&
          this.props.profile.id &&
          this.state.showAddToCartModal && (
            <AddToCartModal
              open={this.state.showAddToCartModal}
              onFinish={() => {
                this.update({ showAddToCartModal: false });
              }}
              onAddToCart={this.onAddToCartHandler}
              multiPayOption={this.props.course.multi_pay_option}
              pricingItems={this.props.course.pricingItems}
              amendment={this.props.course.amendment}
              staff={this.props.staff}
              providerCurrency={this.props.course.provider_currency}
              currencyObj={this.props.currency}
              rates={this.props.rates}
              isVideo={this.props.course.is_video}
              capacityOnline={this.props.course.onlineSeatsAvailable()}
              capacityClassroom={this.props.course.classroomSeatsAvailable()}
              capacityClassroomHandson={this.props.course.classroomHandsonSeatsAvailable()}
              setting={this.props.mobileSetting}

              mobileMemberships={this.props.mobileMemberships}
              validateMemberID={this.validateMemberIDHanlder}
              providerName={this.props.course.provider_name}
              providerID={this.props.course.provider_id}
              waitModal={this.state.waitModal}
            />
          )}

        <InfoModal
          isErrorModal={true}
          title={this.props.t("error_occurred")}
          message={this.state.error}
          open={this.state.error !== null}
          onClose={() => {
            this.update({ error: null });
          }}
        />

        {this.state.alertMessage !== null && (
          <AlertSuccess
            severity={"error"}
            message={this.state.alertMessage}
            open={this.state.alertMessage !== null}
            onClose={() => {
              this.update({ alertMessage: null });
            }}
          />
        )}

        {this.props.isAuthenticated ? null : (
          <DefaultToolbar
            history={this.props.history}
            i18n={this.props.i18n}
            mobileSetting={this.props.mobileSetting}
            updateMobileCurrency={this.updateMobileCurrency}
            showSearch={false}
          />
        )}

        <div className={styles.gap}></div>

        <div className={styles.courseViewerContainer}>
          <CourseListing
            course={this.props.course}
            currency={this.props.currency}
            rates={this.props.rates}
          />
        </div>

        {this.props.reviews && this.props.reviews.length > 0 && (
          <Fragment>
            <div className={styles.courseViewerContainer}>
              <hr></hr>
              <RatingReviewsContainer
                full
                hasMore={this.props.hasMore}
                reviews={this.props.reviews}
                rating={this.props.course.rating}
                ratingCount={this.props.course.rating_count}
                loading={this.isLoadingMore}
                onLoadMore={this.loadMoreHandler}
              />
            </div>

            <div style={{ height: "100px" }}></div>
          </Fragment>
        )}

        {!this.props.isAuthenticated && <Footer history={this.props.history} />}
      </div>
    );
  }
}

// ------------------------------

const parseQueryString = (props) => {
  let providerId = null;
  let courseId = null;
  const query = new URLSearchParams(props.location.search);

  for (let param of query.entries()) {
    if (param[0] === "p") {
      providerId = param[1];
    }
    if (param[0] === "c") {
      courseId = param[1];
    }
  }

  if (providerId === null && courseId === null) {
    providerId = props.match.params.providerId;
    courseId = props.match.params.courseId;
  }

  return { providerId: providerId, courseId: courseId };
};

// ------------------------------

const mapStateToProps = (state, props) => {
  const params = parseQueryString(props);

  const provider = state.provider.selected_provider;

  let currencyObject = {};

  if (provider) {
    currencyObject = CURRENCY.find((c) => c.id === provider.currency);
  }

  if (state.setting.mobileSetting) {
    currencyObject = CURRENCY.find(
      (c) => c.id === state.setting.mobileSetting.currency
    );
  } else {
    currencyObject = CURRENCY[1];
  }

  const conversations = state.inbox.conversations;

  const providerCompareId = params.providerId
    ? params.providerId
    : state.provider.selected_course
      ? state.provider.selected_course.provider_id
      : null;

  const activeConversation = conversations.find(
    (c) => c.provider_uid === providerCompareId && c.by_user === true
  );

  const staffCopy = state.staff ? state.staff.staff : null;
  if (state.profile && state.profile.userProfile && staffCopy) {
    const profile = state.profile.userProfile;

    const indexOfUser = staffCopy.findIndex(
      (s) => s.id === state.profile.userProfile.id
    );

    if (indexOfUser === -1) {
      staffCopy.unshift(
        new StaffMember(
          profile.id,
          profile.licenseNumber,
          profile.occupation,
          profile.last,
          profile.first,
          profile.email,
          true
        )
      );
    }
  }

  return {
    providerid: params.providerId,
    courseId: params.courseId,

    provider: state.provider.selected_provider,
    course: params.courseId
      ? state.courses.select_course
      : state.provider.selected_course
        ? state.provider.selected_course
        : state.courses.select_course,

    profile: state.profile.userProfile,
    isAuthenticated: state.auth.token != null,
    action: props.history.action,
    currency: currencyObject,
    rates: state.rate.rate,
    mobileSetting: state.setting.mobileSetting,
    userId: state.auth.userId,
    activeConversation: activeConversation,
    conversations: conversations,
    messages: state.inbox.messages,
    cartItems: state.mobilecart.items,
    reviews: state.reviews.reviews,
    hasMore: state.reviews.hasMore,
    staff: state.staff.staff,
    mobileMemberships: state.member.memberships
  };
};

// ------------------------------

const mapDispatchToProps = (dispatch) => {
  return {
    addToCart: (selectedCourse, pricingItemToStaff) =>
      dispatch(mobilecartActions.addToCart(selectedCourse, pricingItemToStaff)),
    setCourseDetailsRedirect: (redirect) =>
      dispatch(redirectActions.setCourseDetailsRedirect(redirect)),
    setProfileToCourseRedirect: (redirect) =>
      dispatch(redirectActions.setProfileToCourseRedirect(redirect)),
    fetchMessages: (conversation) =>
      dispatch(inboxActions.fetchMessages(conversation)),
    sendMessage: (conversation, message, providerId, providerName) =>
      dispatch(
        inboxActions.sendMessage(
          conversation,
          message,
          providerId,
          providerName
        )
      ),
    updateLastViewed: (convoId) =>
      dispatch(inboxActions.updateLastViewed(convoId)),
    stopInboxMessagesListener: (convoId) =>
      dispatch(inboxActions.stopInboxMessagesListener(convoId)),
    fetchCourse: (id, providerId) =>
      dispatch(courseActions.fetchCourse(id, providerId)),
    fetchUserProfile: () => dispatch(profileActions.fetchMobileUserProfile()),
    fetchMobileSetting: () => dispatch(settingActions.fetchMobileSetting()),
    fetchProvider: (providerId) =>
      dispatch(providerActions.fetchProvider(providerId)),
    fetchRates: () => dispatch(rateActions.fetchRates()),
    fetchReviews: (filters) => dispatch(reviewActions.fetchReviews(filters)),
    clearReviewAndRating: () => dispatch(reviewActions.clearReviewAndRating()),
    fetchStaffMembers: () => dispatch(staffActions.fetchStaffMembers()),
    updateCurrency: (currency, language) =>
      dispatch(settingActions.updateCurrency(currency, language)),
    validateMembership: (providerId, memberID) =>
      dispatch(memberActions.validateMembership(providerId, memberID)),
    fetchMemberships: () =>
      dispatch(memberActions.fetchMemberships()),
  };
};

// ------------------------------

export default withTranslation("translations")(
  connect(mapStateToProps, mapDispatchToProps)(CourseDetailsScreen)
);
