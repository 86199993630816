import React from "react";
import styles from "./CertificateItem.module.css";
import PopMenu from "../../../components/ui/popMenu/PopMenu";
import EditIcon from "@material-ui/icons/Edit";
import MenuItem from "@material-ui/core/MenuItem";
import SchoolIcon from "@material-ui/icons/School";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import DeleteIcon from "@material-ui/icons/Delete";
import certicon from "../../../assets/images/inApp/certicon.png";
import TitleMenu from "../../../components/ui/titleMenu/TitleMenu";
import Divider from "@material-ui/core/Divider";

const CertificateItem = (props) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  // ------------------------------

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  // ------------------------------

  const handleClose = () => {
    setAnchorEl(null);
  };

  // ------------------------------

  const renderEdit = () => (
    <MenuItem
      onClick={() => {
        handleClose();
        props.edit();
      }}
    >
      <TitleMenu icon={<EditIcon />} title="Edit" />
    </MenuItem>
  );

  // ------------------------------

  const renderDelete = () => (
    <MenuItem
      onClick={() => {
        handleClose();
        props.delete();
      }}
    >
      <TitleMenu icon={<DeleteIcon />} title="Delete" />
    </MenuItem>
  );

  // ------------------------------

  const renderCertLinks = () => (
    <MenuItem
      onClick={() => {
        handleClose();
        props.certLinks();
      }}
    >
      <TitleMenu icon={<SchoolIcon />} title="Used by..." />
    </MenuItem>
  );

  // ------------------------------

  return (
    <div className={styles.itemContainer}>
      <div className={styles.column3}>
        <img className={styles.thumbnail} src={certicon} alt="" />
        <div className={styles.title}>{props.certificate.title}</div>
      </div>

      <div className={styles.column1}>
        <PopMenu
          anchorEl={anchorEl}
          handleClick={handleClick}
          handleClose={handleClose}
        >
          {renderEdit()}
          <Divider />
          {renderDelete()}
          <Divider />
          {renderCertLinks()}
        </PopMenu>
      </div>
    </div>
  );
};

export default CertificateItem;
