import React from "react";
import { useTranslation } from "react-i18next";
import * as styles from "./CeSummary.module.css";
import Avatar from "../../ui/image/Avatar";
import CountUp from 'react-countup';
import LocalCafeOutlinedIcon from '@material-ui/icons/LocalCafeOutlined';
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";

const CeSummary = (props) => {
  const { t } = useTranslation();

  return (
    <div className={styles.container} onClick={props.onClick}>
      {/*<div className={styles.avatarContainer}>
        <Avatar image={props.avatarPicture} /></div>*/}
      <div className={styles.summaryContainer}>
        <div className={styles.headerTextContainer}>
          <div className={styles.subTitle}>{t("ce_completed")}</div>
          <div className={styles.textCE}>
            <CountUp
              start={0}
              end={props.completed}
              duration={2}
              suffix=" CE"
            />
          </div>
        </div>
        <div className={styles.creditsCalendar}>
          <div>{t("g_manage_ce")}</div>
          <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center" }}>
            <LocalCafeOutlinedIcon fontSize="inherit" />&nbsp;{props.year}</div>
        </div>
        <div className={styles.headerTextContainer}>
          <div className={styles.subTitle}>{t("ce_pending")}</div>
          <div className={styles.textCE}>
            <CountUp
              start={0}
              end={props.pending}
              duration={2}
              suffix=" CE"
            />
          </div>
        </div>
        <ArrowForwardIcon />
      </div>
    </div>
  );
};

export default CeSummary;
