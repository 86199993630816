import React from "react";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Pop from "../popover/Pop";
import { makeStyles } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),

    "& > * + *": {
      marginTop: theme.spacing(3),
    },
  },
  rootFull: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    width: "100%",
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),

    "& > * + *": {
      marginTop: theme.spacing(3),
    },
  },
  rootSmall: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    width: "60%",
    margin: "auto",
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),

    "& > * + *": {
      marginTop: theme.spacing(3),
    },
  },
  onStyle: {
    fontSize: "12px",
    color: "black",
  },
  offStyle: {
    fontSize: "12px",
    color: "#ccc",
  },
}));

const MatSwitch = (props) => {
  const matches = useMediaQuery("(max-width:499px)");
  const classes = useStyles();
  const rootClass =
    matches || props.alwaysfull ? classes.rootFull : classes.rootSmall;

  return (
    <div className={props.title ? rootClass : classes.root}>
      <FormGroup row>
        {props.offTitle && props.onTitle && (
          <Typography component="div">
            <Grid component="label" container alignItems="center" spacing={1}>
              <Grid
                item
                className={props.checked ? classes.offStyle : classes.onStyle}
              >
                {props.offTitle}
              </Grid>
              <Grid item>
                <Switch
                  id={props.id}
                  name={props.name}
                  checked={props.checked}
                  onChange={props.onChange}
                  color="primary"
                />
              </Grid>
              <Grid
                item
                className={props.checked ? classes.onStyle : classes.offStyle}
              >
                {props.onTitle}
              </Grid>
            </Grid>
          </Typography>
        )}
        {props.title && (
          <FormControlLabel
            className={classes.onStyle}
            disabled={false}
            control={
              <Switch
                id={props.id}
                name={props.name}
                checked={props.checked}
                onChange={props.onChange}
                color="primary"
              />
            }
            label={props.title}
          />
        )}
        &nbsp;&nbsp;{props.help && <Pop help={props.help} />}
      </FormGroup>
    </div>
  );
};

export default MatSwitch;
