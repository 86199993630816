import React, { Component } from "react";
import styles from "./Legal.module.css";
import { connect } from "react-redux";
import { renderGeneralTermsOfUse } from "./templateEn/GeneralHtml";
import { renderPrivacyPolicy } from "./templateEn/PrivacyPolicyHtml";
import { renderGeneralTermsOfUseFr } from "./templateFr/GeneralHtmlFr";
import { renderPrivacyPolicyFr } from "./templateFr/PrivacyPolicyHtmlFr";

class GeneralAndPrivacyScreen extends Component {
  state = {};

  // ------------------------------

  componentDidMount = () => {
    window.scrollTo(0, 0);
  };

  // ------------------------------

  render() {
    return (
      <div>
        <div className={styles.screen}>
          {this.props.fr
            ? renderGeneralTermsOfUseFr()
            : renderGeneralTermsOfUse()}
          <div style={{ height: "40px" }}></div>
          <hr style={{ border: "0.5px dashed #ccc" }} />
          <div style={{ height: "30px" }}></div>
          {this.props.fr ? renderPrivacyPolicyFr() : renderPrivacyPolicy()}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  let fr = null;

  const query = new URLSearchParams(props.location.search);
  for (let param of query.entries()) {
    if (param[0] === "lang" && param[1] === "fr") {
      fr = true;
    }
  }

  return {
    fr: fr,
  };
};

export default connect(mapStateToProps, null)(GeneralAndPrivacyScreen);
