import {
  AUTHENTICATE,
  LOGOUT,
  SET_USER,
  SET_CONNECT_ID,
  SET_MOBILE_PICTURE,
} from "../actions/auth";
import { SET_CONNECTED } from "../actions/connect";

const initialState = {
  token: null,
  userId: null,
  connectId: null,
  email: null,
  refreshToken: null,
  user: null, // not sure why?
  isMobileUser: false, // fork dashboards
  staySignedIn: false,
  picture: null,
  displayId: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case AUTHENTICATE:
      return {
        ...state,
        token: action.token,
        userId: action.userId,
        connectId: action.connectId,
        email: action.email,
        refreshToken: action.refreshToken,
        isMobileUser: action.isMobileUser,
        staySignedIn: action.staySignedIn,
      };
    case SET_CONNECT_ID:
      return {
        ...state,
        connectId: action.connectId,
      };
    case SET_CONNECTED:
      return {
        ...state,
        connectId: action.value === false ? "123456" : "PENDING",
      };
    case SET_USER:
      return {
        ...state,
        user: action.user,
      };
    case SET_MOBILE_PICTURE:
      return {
        ...state,
        displayId: action.displayId,
        picture: action.picture,
      };
    case LOGOUT:
      return { ...initialState };
    default:
      return state;
  }
};
