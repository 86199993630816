import React, { Component } from "react";
import styles from "./OrderScreen.module.css";
import { connect } from "react-redux";
import FlatList from "flatlist-react";
import Spinner from "../../components/ui/spinner/Spinner";
import * as orderActions from "../../store/actions/orders";
import SimpleContainer from "../../components/ui/container/Container";
import OrderItem from "../../components/items/orderItem/OrderItem";
import InfoModal from "../../components/modals/infoModal/InfoModal";
import ListAltIcon from "@material-ui/icons/ListAlt";
import * as profileActions from "../../store/actions/profile";
import Empty from "../../components/ui/empty/Empty";
import ordersImg from "../../assets/images/inApp/orders.png";
import { objectHasNoValues } from "../../globals/Common";
import * as logger from "../../globals/Logger";

class OrderScreen extends Component {
  // ------------------------------
  state = {
    wait: false,
    error: null,
    success: null,
  };

  // ------------------------------

  update = async (propValue) => {
    await this.setState({ ...this.state, ...propValue });
  };

  // ------------------------------

  componentDidMount = async () => {
    try {
      await this.update({ error: null, wait: true });
      const loadAsync = [];

      if (objectHasNoValues(this.props.profile)) {
        loadAsync.push(this.props.fetchProfile());
      }
      loadAsync.push(this.props.fetchOrders());

      await Promise.all(loadAsync);
      await this.update({ wait: false });
    } catch (error) {
      logger.error(error);
      await this.update({
        wait: false,
        error: error.message,
      });
    }
  };

  // ------------------------------

  emailReceipt = async (orderId) => {
    try {
      this.update({ error: null });
      await this.props.emailReceipt(orderId);
      this.update({
        success:
          "Your order receipt was sent to you via email successfully.  Please verify that ZubU is on your accepted receiver list.",
      });
    } catch (error) {
      logger.error(error);
      await this.update({
        error: error.message,
      });
    }
  };

  // ------------------------------

  renderOrder = (item, index) => {
    return (
      <div key={index}>
        <OrderItem
          order={item}
          showDetails={false}
          onEmailReceipt={this.emailReceipt.bind(this, item.id)}
          emailWait={this.state.emailWait}
        />
        <div style={{ height: "25px" }}>&nbsp;</div>
      </div>
    );
  };

  // ------------------------------

  closeErrorHandler = () => {
    this.update({
      error: null,
      success: null,
    });
  };

  // ------------------------------

  render() {
    if (this.state.wait) {
      return <Spinner />;
    }

    if (!this.props.orders || this.props.orders.length === 0) {
      return (
        <Empty
          column
          image={ordersImg}
          message={
            <div>
              <p>You have no purchase history</p>
            </div>
          }
        />
      );
    }

    return (
      <div>
        {this.state.error !== null && (
          <InfoModal
            isErrorModal={true}
            title="An error has occured"
            message={this.state.error}
            open={this.state.error !== null}
            onClose={this.closeErrorHandler}
          />
        )}

        {(this.state.error !== null || this.state.success !== null) && (
          <InfoModal
            isErrorModal={this.state.error !== null}
            title={
              this.state.error !== null
                ? "An error has occured"
                : "Email Receipt Sent"
            }
            message={this.state.error ? this.state.error : this.state.success}
            open={true}
            onClose={() => {
              this.update({ error: null, success: null });
            }}
          />
        )}

        <SimpleContainer disableGutters={false}>
          <div style={{ height: "40px" }}>&nbsp;</div>
          <div className={styles.sectionTitle}>
            <div className={styles.centerRow}>
              <ListAltIcon style={{ color: "white" }} />
              &nbsp;&nbsp;Purchase History
            </div>
          </div>
          <div className={styles.list}>
            <FlatList list={this.props.orders} renderItem={this.renderOrder} />
          </div>
        </SimpleContainer>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    action: props.history.action,
    orders: state.orders.orders,
    profile: state.profile.profile,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchProfile: () => dispatch(profileActions.fetchProfile()),
    fetchOrders: () => dispatch(orderActions.fetchOrders()),
    emailReceipt: (orderId) => dispatch(orderActions.emailReceipt(orderId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(OrderScreen);
