import React, { Fragment, Component } from "react";
import styles from "./SignupScreen.module.css";
import { connect } from "react-redux";
import validate from "validate.js";
import ReCAPTCHA from "react-google-recaptcha";
import * as authActions from "../../store/actions/auth";
import Button from "@material-ui/core/Button";
import AddIcon from "@material-ui/icons/Add";
import GavelIcon from "@material-ui/icons/Gavel";
import { PROVINCE } from "../../globals/Data";
import Spinner from "../../components/ui/spinner/Spinner";
import InfoModal from "../../components/modals/infoModal/InfoModal";
import logo from "../../assets/images/inApp/logo.png";
import stripe from "../../assets/images/welcome/stripe.png";
import MatInput from "../../components/ui/input/MatInput";
import CountrySelect from "../../components/ui/labelSelect/CountrySelect";
import ProvinceSelect from "../../components/ui/labelSelect/ProvinceSelect";
import Footer from "../../components/ui/footer/Footer";
import TextButton from "../../components/ui/button/TextButton";
import MatCheckbox from "../../components/ui/checkbox/MatCheckbox";
import { COUNTRY } from "../../globals/Data";
import Document from "../../components/ui/document/Document";
import { renderPrivacyPolicy } from "../legal/templateEn/PrivacyPolicyHtml";
import { renderSupplierTermsOfUse } from "../legal/templateEn/SupplierHtml";
import { renderGeneralTermsOfUse } from "../legal/templateEn/GeneralHtml";
import DefaultToolbar from "../welcome/DefaultToolbar";
import * as logger from "../../globals/Logger";

import {
  constraints,
  hasFormErrors,
  hasError,
} from "../../globals/Validations";

class SignupScreen extends Component {
  constructor(props) {
    super(props);
    this.reRef = React.createRef();
  }

  state = {
    wait: false,
    waitMessage: "",

    email: "",
    password: "",
    password2: "",
    name: "",
    country: "",
    province: "",
    contact_email: "",
    message: null,
    errorModal: false,
    acceptTerms: false,
    prelaunch_code: "",

    //---------
    formErrors: {
      email: "",
      password: "",
      password2: "",
      name: "",
      country: "",
      province: "",
      contact_email: "",
    },
  };

  // ------------------------------

  update = async (propValue) => {
    await this.setState({ ...this.state, ...propValue });
  };

  // ------------------------------

  componentDidMount = async () => {
    try {
      window.scrollTo(0, 0);
      await this.update({ wait: true });
      const ipCountryCode = await this.props.getUserCountry();
      const countryMatch = COUNTRY.find((c) => c.code === ipCountryCode);

      if (countryMatch && countryMatch.country) {
        const updateFormErrors = { ...this.state.formErrors };
        delete updateFormErrors.country;

        let province = "";
        if (countryMatch.country !== "Canada") {
          province = "N/A";
          delete updateFormErrors.province;
        }

        await this.update({
          wait: false,
          country: countryMatch ? countryMatch.country : "",
          province: province,
          formErrors: updateFormErrors,
        });
      } else {
        await this.update({ wait: false });
      }
    } catch (err) {
      logger.error(err);
      this.update({ wait: false, error: err.message });
    }
  };

  // ------------------------------

  validateForm = async (field, validatorKey) => {
    let validateObj = {};
    validateObj[validatorKey] = this.state[field];
    const validationResponse = validate(validateObj, constraints);
    const updateFormErrors = { ...this.state.formErrors };

    if (validationResponse[validatorKey]) {
      const firstError = validationResponse[validatorKey][0];
      updateFormErrors[field] = firstError;
    } else {
      delete updateFormErrors[field];
    }

    if (
      (field === "password" || field === "password2") &&
      this.state.password !== this.state.password2
    ) {
      updateFormErrors["password2"] = "Passwords do not match";
    }

    await this.setState({ ...this.state, formErrors: { ...updateFormErrors } });
  };

  // ------------------------------

  hasErrorsHandler = () => {
    const values = {
      email: this.state.email,
      password: this.state.password,
      password2: this.state.password2,
      name: this.state.name,
      country: this.state.country,
      province: this.state.province,
      contact_email: this.state.contact_email,
    };

    return hasFormErrors(this.state.formErrors, values);
  };

  // ------------------------------

  openStripeUrl = async (event) => {
    window.open("http://www.stripe.com", "_blank");
  };

  // ------------------------------

  openEmailHandler = (event) => {
    window.open("mailto:info@zubueducation.com", "_blank");
  };

  // ------------------------------

  getProvinceCode = (province) => {
    const foundProvince = PROVINCE.find((p) => p.name === province);
    if (foundProvince) {
      return foundProvince.code;
    }
    return "";
  };

  // ------------------------------

  signupHandler = async (event) => {
    try {
      event.preventDefault();
      this.setState({
        ...this.state,
        message: null,
        wait: true,
        waitMessage: "Creating your account",
      });

      const recaptchaToken = await this.reRef.current.executeAsync();

      this.reRef.current.reset();

      await this.props.signup(
        {
          email: this.state.email,
          password: this.state.password,
          country: this.state.country,
          province: this.getProvinceCode(this.state.province),
          providerName: this.state.name,
          contact_email: this.state.contact_email,
          prelaunch_code: this.state.prelaunch_code,
        },
        recaptchaToken
      );

      this.setState({
        ...this.state,
        wait: false,
        errorModal: false,
        message:
          "You will receive an email activation link shortly.  The ZubU team is happy to have you on board.",
        waitMessage: "",
      });
    } catch (error) {
      logger.error(error);
      this.setState({
        ...this.state,
        wait: false,
        errorModal: true,
        message: error ? error.message : "Unkown error",
        waitMessage: "",
      });
    }
  };

  // ------------------------------

  signupSuccessHandler = () => {
    this.props.history.push("/login");
  };

  // ------------------------------

  inputChangeHandler = (e) => {
    if (e.target.name === "country") {
      if (e.target.value !== "Canada") {
        // if error in province we clear it
        const copyErrors = { ...this.state.formErrors };
        delete copyErrors.province;

        this.setState({
          ...this.state,
          formErrors: copyErrors,
          province: "N/A",
          [e.target.name]: e.target.value,
        });
      } else {
        this.setState({
          ...this.state,
          province: "",
          [e.target.name]: e.target.value,
        });
      }
    } else {
      this.setState({
        ...this.state,
        [e.target.name]: e.target.value,
      });
    }
  };

  // ------------------------------

  closeErrorHandler = () => {
    this.setState({
      ...this.state,
      message: null,
    });
  };

  // ------------------------------

  acceptHandler = (event, checked) => {
    this.setState({
      ...this.state,
      acceptTerms: checked,
    });
  };

  // ------------------------------

  render() {
    const hasFormErrors = this.hasErrorsHandler();

    return (
      <div className={styles.screen}>
        {this.state.wait && <Spinner message={this.state.waitMessage} />}

        <DefaultToolbar history={this.props.history} i18n={this.props.i18n} />

        <InfoModal
          isErrorModal={this.state.errorModal}
          title={
            this.state.errorModal ? "An error has occured" : "Sign Up Success"
          }
          message={this.state.message}
          open={this.state.message !== null}
          onClose={
            this.state.errorModal
              ? this.closeErrorHandler
              : this.signupSuccessHandler
          }
        />

        <div className={styles.formAndFooter}>
          <div className={styles.form}>
            <div className={styles.title}>
              Create ZubU Account / Course Provider
            </div>
            <div className={styles.infobold}>
              By proceeding, I agree that ZubU or its representatives may
              contact me by email, phone, or SMS (including by automatic dialing
              systems) at the email address or number I provide, including for
              marketing purposes.
            </div>
            <MatInput
              title="Promo Code"
              value={this.state.prelaunch_code}
              name="prelaunch_code"
              id="prelaunch_code"
              onChange={this.inputChangeHandler}
              maxLength={50}
              required={false}
            />
            <div className={styles.info}>
              If you have a promo code, please enter it here.
            </div>
            <MatInput
              title="Provider Name"
              value={this.state.name}
              name="name"
              id="name"
              onChange={this.inputChangeHandler}
              onBlur={this.validateForm.bind(this, "name", "plainRequired")}
              maxLength={200}
              error={hasError(this.state.formErrors.name)}
              helperText={this.state.formErrors.name}
              required
            />
            <div className={styles.info}>
              The name of your company. If you are an individual your first and
              last name. This must be the name that appears on your tax reports.
            </div>
            <CountrySelect
              title="Country"
              value={this.state.country}
              name="country"
              id="country"
              onChange={this.inputChangeHandler}
              onBlur={this.validateForm.bind(this, "country", "plainRequired")}
              maxLength={200}
              error={hasError(this.state.formErrors.country)}
              helperText={this.state.formErrors.country}
              required={true}
            />
            <div className={styles.info}>
              Country where you or your company reside. Not in the list? For
              more information
              <TextButton
                onClick={this.openEmailHandler}
                style={{ paddingLeft: 3 }}
              >
                contact us.
              </TextButton>
            </div>
            {this.state.country === "Canada" && (
              <Fragment>
                <ProvinceSelect
                  title="Province"
                  value={this.state.province}
                  name="province"
                  id="province"
                  onChange={this.inputChangeHandler}
                  onBlur={this.validateForm.bind(
                    this,
                    "province",
                    "plainRequired"
                  )}
                  maxLength={200}
                  error={hasError(this.state.formErrors.province)}
                  helperText={this.state.formErrors.province}
                  required={true}
                />
                <div className={styles.info}>
                  Province where you or your company reside.
                </div>
              </Fragment>
            )}
            <MatInput
              title="Email"
              value={this.state.email}
              name="email"
              id="email"
              onChange={this.inputChangeHandler}
              onBlur={this.validateForm.bind(this, "email", "email")}
              maxLength={200}
              error={hasError(this.state.formErrors.email)}
              helperText={this.state.formErrors.email}
              required
            />
            <div className={styles.info}>
              Email address will be used as part of the account verification and
              activation process.
            </div>
            <MatInput
              title="Course Contact Email"
              value={this.state.contact_email}
              name="contact_email"
              id="contact_email"
              onChange={this.inputChangeHandler}
              onBlur={this.validateForm.bind(this, "contact_email", "email")}
              maxLength={200}
              error={hasError(this.state.formErrors.contact_email)}
              helperText={this.state.formErrors.contact_email}
              required
            />
            <div className={styles.info}>
              Email that will appear as the contact email of your active
              courses. This can differ from the email associated to your
              account.
            </div>
            <MatInput
              title="Password"
              value={this.state.password}
              name="password"
              type="password"
              id="password"
              onChange={this.inputChangeHandler}
              onBlur={this.validateForm.bind(this, "password", "password")}
              maxLength={50}
              error={hasError(this.state.formErrors.password)}
              helperText={this.state.formErrors.password}
              required
            />
            <div className={styles.info}>&nbsp;</div>
            <MatInput
              title="Confirm Password"
              value={this.state.password2}
              name="password2"
              type="password"
              id="password2"
              onChange={this.inputChangeHandler}
              onBlur={this.validateForm.bind(this, "password2", "password")}
              maxLength={50}
              error={hasError(this.state.formErrors.password2)}
              helperText={this.state.formErrors.password2}
              required
            />
            <div className={styles.info}>&nbsp;</div>
            <div className={styles.pdf}>
              <GavelIcon />
              &nbsp;&nbsp;Legal Agreement
            </div>
            <Document>
              {renderGeneralTermsOfUse()}
              {renderSupplierTermsOfUse()}
              {renderPrivacyPolicy()}
            </Document>
            <MatCheckbox
              signup="true"
              label={
                <div>
                  By checking this box I confirm that I have read and agree to
                  be bound by the Agreement above.
                </div>
              }
              checked={this.state.acceptTerms}
              onChange={this.acceptHandler}
              name="acceptTerms"
            />

            <ReCAPTCHA
              sitekey="6LfceS4aAAAAAOXnwPywSDEi5DQptzsHEsnvrgCN"
              size="invisible"
              ref={this.reRef}
            />

            <div className={styles.buttonContainer}>
              <Button
                variant="contained"
                style={{
                  backgroundColor:
                    hasFormErrors || !this.state.acceptTerms
                      ? "#CCC"
                      : "#437aa1",
                  color: "white",
                  width: "97%",
                }}
                onClick={this.signupHandler}
                disabled={hasFormErrors || !this.state.acceptTerms}
              >
                Create Account
              </Button>
            </div>
            <div className={styles.partnerContainer}>
              <img src={logo} alt="ZubU" className={styles.logo} />
              <AddIcon style={{ color: "#437aa1" }}></AddIcon>
              <img src={stripe} alt="Stripe" className={styles.logoText} />
            </div>
            <div className={styles.info}>
              ZubU partners with Stripe for fast and secure payments that comply
              with the highest PCI standards. To learn more about Stripe
              visit&nbsp;
              <label className={styles.link} onClick={this.openStripeUrl}>
                stripe
              </label>
              .
            </div>
          </div>

          <Footer history={this.props.history} />
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    signup: (newUser, recaptchaToken) =>
      dispatch(authActions.signup(newUser, recaptchaToken)),
    getUserCountry: () => dispatch(authActions.getCountry()),
  };
};

export default connect(null, mapDispatchToProps)(SignupScreen);
