import React, { Component } from "react";
import Button from "@material-ui/core/Button";
import TouchAppIcon from "@material-ui/icons/TouchApp";
import { ElementsConsumer } from "@stripe/react-stripe-js";
import * as logger from "../../globals/Logger";

class CheckoutForm extends Component {
  // ------------------------------

  placeOrder = async (event) => {
    try {
      event.preventDefault();
      await this.props.submitting(true, "Processing");
      await this.props.placeOrder(
        this.props.cart,
        this.props.paymentMethod,
        this.props.creditsUsed
      );
      await this.props.submitting(false);
      this.props.orderCompleted();
    } catch (err) {
      if (err.result && err.result.requires_action === true) {
        this.props.submitting(true);

        const { stripe } = this.props;

        if (!stripe) {
          await this.props.submitting(false);
          return;
        }

        // ------------------------------

        const result = await stripe.confirmCardPayment(
          err.result.payment_intent_client_secret,
          {
            payment_method: this.props.paymentMethod.id,
          }
        );

        // ------------------------------

        if (result.error) {
          this.props.sendError(result);
        } else {
          if (result.paymentIntent.status === "succeeded") {
            this.props.submitting(false);
            this.props.orderCompleted();
          }
        }
      } else {
        logger.error(err);
        this.props.sendError(err);
      }

      this.props.submitting(false);
    }
  };

  render() {
    return (
      <Button
        onClick={this.placeOrder}
        disabled={
          !this.props.cart.items ||
          this.props.cart.items.length === 0 ||
          !this.props.stripe ||
          (this.props.payAmount > 0 && !this.props.paymentMethod)
        }
        variant="contained"
        color="primary"
        startIcon={<TouchAppIcon />}
      >
        Place Your Order
      </Button>
    );
  }
}

const InjectedForm = (props) => {
  return (
    <ElementsConsumer>
      {({ stripe, elements }) => (
        <CheckoutForm
          stripe={stripe}
          elements={elements}
          cart={props.cart}
          payAmount={props.payAmount}
          creditsUsed={props.creditsUsed}
          paymentMethod={props.paymentMethod}
          submitting={props.submitting}
          placeOrder={props.placeOrder}
          orderCompleted={props.orderCompleted}
          sendError={props.sendError}
        />
      )}
    </ElementsConsumer>
  );
};

export default InjectedForm;
