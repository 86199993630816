import React, { Fragment } from "react";
import styles from "./MarketingSelect.module.css";
import marketingAddImg from "../../../assets/images/inApp/marketingAdd.png";
import marketingFeaturedImg from "../../../assets/images/inApp/marketingFeatured.png";
import Tooltip from "@material-ui/core/Tooltip";

const MarketingSelect = (props) => (
  <Fragment>
    <div className={styles.imageRowContainer}>
      <Tooltip
        title="Select your advertisement"
        aria-label="Select your advertisement"
      >
        <div
          className={styles.imageContainer}
          onClick={() => {
            props.newAd("banner");
          }}
        >
          <div className={styles.addPrice}>
            $125 <span style={{ fontSize: "14px" }}>/ week</span>
          </div>
          <img src={marketingAddImg} alt="" />
          <div className={styles.addDescription}>
            Home page image advertisement
          </div>
        </div>
      </Tooltip>
      <Tooltip
        title="Select your advertisement"
        aria-label="Select your advertisement"
      >
        <div
          className={styles.imageContainer}
          onClick={() => {
            props.newAd("featured");
          }}
        >
          <div className={styles.addPrice}>
            $40 <span style={{ fontSize: "14px" }}>/ week *</span>
          </div>
          <img src={marketingFeaturedImg} alt="" />
          <div className={styles.addDescription}>
            Search page featured advertisement
          </div>
        </div>
      </Tooltip>
    </div>

    <div className={styles.priceDiclaimerContainer}>
      * Prices are subject to change at any time without notice. For more
      information please read the supplier terms and conditions.
    </div>
  </Fragment>
);

export default MarketingSelect;
