import React, { Component } from "react";
import styles from "./AdvertiseScreen.module.css";
import { connect } from "react-redux";
import * as adActions from "../../store/actions/advertisement";
import * as profileActions from "../../store/actions/profile";
import * as analyticsActions from "../../store/actions/analytics";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import LiveTvIcon from "@material-ui/icons/LiveTv";
import Button from "@material-ui/core/Button";
import Spinner from "../../components/ui/spinner/Spinner";
import MatAppBar from "../../components/ui/appBar/MatAppBar";
import ConfirmModal from "../../components/modals/confirmModal/ConfirmModal";
import MarketingSelect from "../../components/ui/marketingSelect/MarketingSelect";
import MarketingSelectModal from "../../components/modals/marketingSelectModal/MarketingSelectModal";
import MarketingAddItem from "../../components/items/marketingAddItem/MarketingAddItem";
import { objectHasNoValues } from "../../globals/Common";
import CourseViewerModal from "../../components/modals/courseViewModal/CourseViewModal";
import * as logger from "../../globals/Logger";

class AdvertiseScreen extends Component {
  state = {
    wait: false,
    openDeleteConfirmModal: false,
    addToDelete: null,
    error: null,
    showAdTypeModal: null,
    preview: null,
    selectedItem: null,
  };

  // ------------------------------

  update = async (propValue) => {
    await this.setState({ ...this.state, ...propValue });
  };

  // ------------------------------

  componentDidMount = async () => {
    try {
      const loadAsync = [];
      await this.update({ wait: true });
      loadAsync.push(this.props.fetchMarketingAd());
      loadAsync.push(this.props.fetchAdViews());

      if (objectHasNoValues(this.props.profile)) {
        loadAsync.push(this.props.fetchProfile());
      }

      await Promise.all(loadAsync);
      await this.update({ wait: false });
    } catch (err) {
      logger.error(err);
      this.update({ wait: false, error: err.message });
    }
  };

  // ------------------------------

  newAdHandler = async (adType) => {
    if (adType) {
      this.props.setNewAction(adType);
      this.props.history.push("/createad");
    } else {
      this.update({ showAdTypeModal: null });
    }
  };

  // ------------------------------

  extendAddHandler = (id) => {
    this.props.setExtendAction(id);
    this.props.history.push("/createadd");
  };

  // ------------------------------

  deleteHandler = (addToDelete) => {
    this.update({
      openDeleteConfirmModal: true,
      addToDelete: addToDelete,
    });
  };

  // ------------------------------

  deleteConfirmHandler = async (answer) => {
    if (answer === "ok") {
      try {
        await this.update({
          wait: true,
          openDeleteConfirmModal: false,
        });

        await this.props.removeMarketingAdd(this.state.addToDelete);

        await this.update({
          wait: false,
          addToDelete: null,
        });
      } catch (err) {
        logger.error(err);
        this.update({
          wait: false,
          error: err.message,
        });
      }
    } else {
      this.update({
        openDeleteConfirmModal: false,
        addToDelete: null,
      });
    }
  };

  // ------------------------------

  previewHandler = (item) => {
    this.update({
      preview: true,
      selectedItem: item,
    });
  };

  // ------------------------------

  renderAddItems = () => {
    return this.props.marketingAd.map((ad) => {
      let views = 0;
      if (this.props.adViews) {
        const adView = this.props.adViews.find(
          (v) => v.adId === ad.courses_featured_id
        );
        if (adView) {
          views = adView.views;
        }
      }

      return (
        <MarketingAddItem
          add={ad}
          key={ad.id}
          views={views}
          preview={this.previewHandler.bind(this, ad)}
          delete={this.deleteHandler.bind(this, ad)}
        />
      );
    });
  };

  // ------------------------------

  render() {
    if (this.state.wait) {
      return <Spinner />;
    }

    if (!this.props.marketingAd || this.props.marketingAd.length === 0) {
      return (
        <form className={styles.form}>
          <div className={styles.sectionTitle}>
            <LiveTvIcon style={{ marginRight: "10px" }} />
            ZubU App Advertisement
          </div>

          <MarketingSelect newAd={this.newAdHandler} />
        </form>
      );
    }

    return (
      <div style={{ backgroundColor: "white" }}>
        {this.state.preview && (
          <CourseViewerModal
            isAdd
            add={{
              courseTitle: this.state.selectedItem.course_title,
              addCategory: this.state.selectedItem.addCategory,
              slide: this.state.selectedItem.slide,
              headline: this.state.selectedItem.headline,
              callForAction: this.state.selectedItem.callForAction,
              showLogo: this.state.selectedItem.showLogo,
              logoUri: this.props.profile.logo,
            }}
            onClose={() => {
              this.update({ preview: null });
            }}
          />
        )}

        <ConfirmModal
          title="Confirmation Required"
          message={"Are you sure you wish to remove this advertisement ?"}
          open={this.state.openDeleteConfirmModal}
          onClose={this.deleteConfirmHandler}
        />
        {this.state.showAdTypeModal && (
          <MarketingSelectModal newAd={this.newAdHandler} />
        )}
        <MatAppBar
          right={
            <Button
              color="inherit"
              style={{ color: "black" }}
              onClick={() => {
                this.update({ showAdTypeModal: true });
              }}
              startIcon={<AddCircleIcon />}
            >
              New
            </Button>
          }
        ></MatAppBar>
        <div style={{ height: "58px" }}></div>
        {this.renderAddItems()}
      </div>
    );
  }
}

// ------------------------------

const mapStateToProps = (state) => {
  return {
    adViews: state.analytics.adViews,
    marketingAd: state.advertisement.marketingAd,
    profile: state.profile.profile,
  };
};

// ------------------------------

const mapDispatchToProps = (dispatch) => {
  return {
    removeMarketingAdd: (ad) => dispatch(adActions.removeMarketingAdd(ad)),
    setNewAction: (adType) => dispatch(adActions.setAddAction(adType)),
    setExtendAction: (id) => dispatch(adActions.setExtendAction(id)),
    fetchMarketingAd: () => dispatch(adActions.fetchMarketingAd()),
    fetchProfile: () => dispatch(profileActions.fetchProfile()),
    fetchAdViews: () => dispatch(analyticsActions.fetchAdViews()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AdvertiseScreen);
