import React from "react";
import Button from "@material-ui/core/Button";

const ToggleButton = (props) => {
    const selected = props.selection === props.id

    return (
        <Button
            {...props}
            variant="outlined"
            style={{
                minWidth: "50px",
                minHeight: "45px",
                backgroundColor: selected ? "green" : "white",
                borderColor: selected ? "#F0E68C" : "gray",
                borderWidth: selected ? 4 : 1,
                boxSizing: "border-box",
                color: selected ? "white" : "black",
                margin: "10px"
            }}
        >
            {props.label}
        </Button>
    );
};

export default ToggleButton;
