import React from "react";
import styles from "./PromoCode.module.css";
import { useTranslation } from "react-i18next";
import TextButton from "../../../components/ui/button/TextButton";
import InputBase from "@material-ui/core/InputBase";

const PromoCode = (props) => {
  const { t } = useTranslation();
  const styleButton = !props.value
    ? { fontSize: 14, color: "#CCC", cursor: "default" }
    : { fontSize: 14 };

  return (
    <div className={styles.container}>
      <div className={styles.rowContainer}>
        <div className={styles.textField}>
          <InputBase
            id={props.id}
            name={props.name}
            value={props.value.toUpperCase()}
            fullWidth={true}
            onChange={props.onChange}
            inputProps={{
              "aria-label": "naked",
              maxLength: 50,
            }}
          />
        </div>
        <div>
          <TextButton
            onClick={props.onApplyPromoCode}
            style={styleButton}
            disabled={!props.value}
          >
            {t("cart_apply_coupon")}
          </TextButton>
        </div>
      </div>
    </div>
  );
};

export default PromoCode;
