import { dbName } from "../../globals/ApiKeys";
import { handleErrorResponse } from "../../globals/Error";
import MobileRegistration from "../../model/MobileRegistration";
import StaffMember from "../../model/StaffMember";
import Handout from "../../model/Handout";
import {
  firebaseNameToId,
  fromFirebaseStringArrayValue,
  hasMorePages,
  booleanFieldFilter,
  stringFieldFilter,
} from "../../globals/Common";
import FireStoreParser from "firestore-parser";
export const SET_REGISTRATION = "SET_REGISTRATION";
export const SET_REGISTRATION_ARCHIVED = "SET_REGISTRATION_ARCHIVED";
export const REMOVE_REGISTRATION = "REMOVE_REGISTRATION";
export const UPDATE_RATED_REGISTRATION = "UPDATE_RATED_REGISTRATION";
export const UPDATE_ALERT_SETTING = "UPDATE_ALERT_SETTING";
export const UPDATE_ARCHIVED_REGISTRATION = "UPDATE_ARCHIVED_REGISTRATION";
export const UPDATE_REGISTRATION_CONTENT = "UPDATE_REGISTRATION_CONTENT";
export const SET_REGISTRANTS = "SET_REGISTRANTS";
export const UPDATE_SINGLE_REGISTRATION = "UPDATE_SINGLE_REGISTRATION";

const LIMIT = 5;

// ------------------------------

export const fetchRegistration = (page) => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;

    if (!token) {
      return dispatch({
        type: SET_REGISTRATION,
        registration: [],
        archived: [],
        toBeRatedCount: 0,
      });
    }

    const userId = getState().auth.userId;

    let condNotArchived = {};
    condNotArchived = { compositeFilter: { filters: [], op: "AND" } };
    const fieldFilter = booleanFieldFilter("archived", "EQUAL", false);
    condNotArchived.compositeFilter.filters.push({ fieldFilter: fieldFilter });

    const arrResponse = await fetch(
      `https://firestore.googleapis.com/v1/projects/${dbName}/databases/(default)/documents/stripe_customers/${userId}:runQuery`,
      {
        method: "POST",
        headers: {
          "Content-Type": "applications/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          structuredQuery: {
            from: [{ collectionId: "registrations" }],
            where: condNotArchived,
            orderBy: [
              {
                field: {
                  fieldPath: "registered_date",
                },
                direction: "DESCENDING",
              },
            ],
            limit: page ? LIMIT * page : LIMIT,
          },
        }),
      }
    );

    if (!arrResponse.ok) {
      handleErrorResponse(await arrResponse.json());
    }

    const resData = await arrResponse.json();

    const loadedRegistration = [];
    for (const documents of resData) {
      const registration = documents.document;

      if (registration && registration.name && registration.fields) {
        const tokenSaved = await dispatch(
          findUpdateMySurveyToken(registration)
        );

        loadedRegistration.push(
          buildMobileRegistration(registration, tokenSaved)
        );
      }
    }

    let toBeRatedCount = 0;
    loadedRegistration.map((r) => {
      toBeRatedCount += r.requiresRating() ? 1 : 0;
    });

    dispatch({
      type: SET_REGISTRATION,
      registration: loadedRegistration,
      toBeRatedCount: toBeRatedCount,
      hasMore: hasMorePages(loadedRegistration.length, page, LIMIT),
    });
  };
};

// ------------------------------

export const fetchArchivedRegistration = (page) => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;

    if (!token) {
      return dispatch({
        type: SET_REGISTRATION,
        registration: [],
        archived: [],
        toBeRatedCount: 0,
      });
    }

    const userId = getState().auth.userId;

    let condArchived = {};
    condArchived = { compositeFilter: { filters: [], op: "AND" } };
    const fieldFilter2 = booleanFieldFilter("archived", "EQUAL", true);
    condArchived.compositeFilter.filters.push({ fieldFilter: fieldFilter2 });

    const arrResponse = await fetch(
      `https://firestore.googleapis.com/v1/projects/${dbName}/databases/(default)/documents/stripe_customers/${userId}:runQuery`,
      {
        method: "POST",
        headers: {
          "Content-Type": "applications/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          structuredQuery: {
            from: [{ collectionId: "registrations" }],
            where: condArchived,
            orderBy: [
              {
                field: {
                  fieldPath: "registered_date",
                },
                direction: "DESCENDING",
              },
            ],
            limit: page ? LIMIT * page : LIMIT,
          },
        }),
      }
    );

    if (!arrResponse.ok) {
      handleErrorResponse(await arrResponse.json());
    }

    const resData = await arrResponse.json();

    const loadedArchived = [];
    for (const documents of resData) {
      const registration = documents.document;

      if (registration && registration.name) {
        const tokenSaved = await dispatch(
          findUpdateMySurveyToken(registration)
        );

        loadedArchived.push(buildMobileRegistration(registration, tokenSaved));
      }
    }

    dispatch({
      type: SET_REGISTRATION_ARCHIVED,
      registrationArchived: loadedArchived,
      hasMoreArchived: hasMorePages(loadedArchived.length, page, LIMIT),
    });
  };
};

// ------------------------------

export const findUpdateMySurveyToken = (registration) => {
  return async (dispatch, getState) => {
    if (!registration.fields.survey_token) {
      const tokenData = await dispatch(
        fetchSurveyToken(registration.fields.course_id.stringValue)
      );

      return await dispatch(
        updateRegCardWithSurveyToken(
          tokenData,
          firebaseNameToId(registration.name)
        )
      );
    }

    return null;
  };
};

// ------------------------------

export const fetchSurveyToken = (courseId) => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;
    const userId = getState().auth.userId;

    let cond = {};
    cond = { compositeFilter: { filters: [], op: "AND" } };
    const fieldFilter1 = stringFieldFilter("studentId", "EQUAL", userId);
    const fieldFilter2 = stringFieldFilter("courseId", "EQUAL", courseId);
    cond.compositeFilter.filters.push({ fieldFilter: fieldFilter1 });
    cond.compositeFilter.filters.push({ fieldFilter: fieldFilter2 });

    const arrResponse = await fetch(
      `https://firestore.googleapis.com/v1/projects/${dbName}/databases/(default)/documents:runQuery`,
      {
        method: "POST",
        headers: {
          "Content-Type": "applications/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          structuredQuery: {
            from: [{ collectionId: "survey_token" }],
            where: cond,
          },
        }),
      }
    );

    if (!arrResponse.ok) {
      handleErrorResponse(await arrResponse.json());
    }

    const resData = await arrResponse.json();

    for (const documents of resData) {
      const tokenDoc = documents.document;

      if (tokenDoc && tokenDoc.name) {
        return FireStoreParser(tokenDoc).fields;
      }
    }
  };
};

// ------------------------------

export const updateRegCardWithSurveyToken = (tokenData, id) => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;
    const userId = getState().auth.userId;

    const tokenSaved = tokenData ? tokenData.survey_token : "none";

    const response = await fetch(
      `https://firestore.googleapis.com/v1/projects/${dbName}/databases/(default)/documents/stripe_customers/${userId}/registrations/${id}` +
      `?updateMask.fieldPaths=survey_token`,
      {
        method: "PATCH",
        headers: {
          "Content-Type": "applications/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          fields: {
            survey_token: {
              stringValue: tokenSaved,
            },
          },
        }),
      }
    );

    if (!response.ok) {
      handleErrorResponse(await response.json());
    }

    return tokenSaved;
  };
};

// ------------------------------

const buildMobileRegistration = (registration, surveyToken) => {
  return new MobileRegistration(
    firebaseNameToId(registration.name),
    registration.fields.image ? registration.fields.image.stringValue : "",
    registration.fields.provider_id.stringValue,
    registration.fields.order_ref_number.stringValue,
    registration.fields.course_id.stringValue,
    registration.fields.course_title.stringValue,
    registration.fields.course_date &&
      registration.fields.course_date.timestampValue
      ? new Date(registration.fields.course_date.timestampValue)
      : null,
    registration.fields.registration_casting.stringValue,
    fromFirebaseStringArrayValue(
      registration.fields.registration_audience.arrayValue.values
    ),
    registration.fields.registration_component.stringValue,
    new Date(registration.fields.registered_date.timestampValue),
    registration.fields.unregistered_date &&
      registration.fields.unregistered_date.timestampValue
      ? new Date(registration.fields.unregistered_date.timestampValue)
      : null,
    registration.fields.rated_date &&
      registration.fields.rated_date.timestampValue
      ? new Date(registration.fields.rated_date.timestampValue)
      : null,
    registration.fields.provider_instruction
      ? registration.fields.provider_instruction.stringValue
      : "",
    registration.fields.alert_identifier
      ? registration.fields.alert_identifier.stringValue
      : "",
    registration.fields.alert_setting
      ? registration.fields.alert_setting.stringValue
      : "",
    registration.fields.archived && registration.fields.archived.booleanValue
      ? new Date(registration.fields.archived.booleanValue)
      : false,
    registration.fields.archived_date &&
      registration.fields.archived_date.timestampValue
      ? new Date(registration.fields.archived_date.timestampValue)
      : null,
    registration.fields.zoom_id
      ? registration.fields.zoom_id.integerValue
      : null,
    registration.fields.zoom_join_url
      ? registration.fields.zoom_join_url.stringValue
      : null,
    registration.fields.survey_token
      ? registration.fields.survey_token.stringValue
      : surveyToken
        ? surveyToken
        : null,
    registration.fields.certificate_uri
      ? registration.fields.certificate_uri.stringValue
      : null,
    registration.fields.certificate_uri_fr
      ? registration.fields.certificate_uri_fr.stringValue
      : null
  );
};

// ------------------------------

export const fetchStaffRegistered = (courseId, providerId) => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;
    const userId = getState().auth.userId;

    let condNotArchived = {};
    condNotArchived = { compositeFilter: { filters: [], op: "AND" } };
    const fieldFilter = stringFieldFilter("registeredById", "EQUAL", userId);
    condNotArchived.compositeFilter.filters.push({ fieldFilter: fieldFilter });

    const arrResponse = await fetch(
      `https://firestore.googleapis.com/v1/projects/${dbName}/databases/(default)/documents/registration/${providerId}/course/${courseId}:runQuery`,
      {
        method: "POST",
        headers: {
          "Content-Type": "applications/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          structuredQuery: {
            from: [{ collectionId: "students" }],
            where: condNotArchived,
          },
        }),
      }
    );

    if (!arrResponse.ok) {
      handleErrorResponse(await arrResponse.json());
    }

    const resData = await arrResponse.json();

    const loadedRegistration = [];
    for (const documents of resData) {
      const registration = documents.document;

      if (registration && registration.name) {
        loadedRegistration.push(buildStaffRegistration(registration));
      }
    }

    dispatch({
      type: SET_REGISTRANTS,
      staffRegistered: loadedRegistration,
    });
  };
};

// ------------------------------

const buildStaffRegistration = (registration) => {
  return new StaffMember(
    firebaseNameToId(registration.name),
    registration.fields.license.stringValue,
    registration.fields.occupation.stringValue,
    registration.fields.last.stringValue,
    registration.fields.first.stringValue,
    registration.fields.email.stringValue,
    registration.fields.registrationOption.stringValue,
    null
  );
};

// ------------------------------

export const fetchRegistrationContent = (providerId, courseId) => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;
    let handout = [];
    let email_instruction = "";

    const response = await fetch(
      `https://firestore.googleapis.com/v1/projects/${dbName}/databases/(default)/documents/registration_content/${providerId}/course/${courseId}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "applications/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );

    if (!response.ok) {
      const errResponse = await response.json();
      if (errResponse.error.status === "NOT_FOUND") {
        dispatch({
          type: UPDATE_REGISTRATION_CONTENT,
          selected_handout: handout,
          selected_instruction: email_instruction,
        });
      } else {
        handleErrorResponse(errResponse);
      }
    } else {
      const resData = await response.json();

      if (resData && resData.fields) {
        handout = buildHandoutFromArrMap(
          resData.fields.handout &&
            resData.fields.handout.arrayValue &&
            resData.fields.handout.arrayValue.values
            ? resData.fields.handout.arrayValue.values
            : []
        );
        email_instruction = resData.fields.email_instruction
          ? resData.fields.email_instruction.stringValue
          : "";
      }

      dispatch({
        type: UPDATE_REGISTRATION_CONTENT,
        selected_handout: handout,
        selected_instruction: email_instruction,
      });
    }
  };
};

// ------------------------------

export const removeRegistrationCard = (id) => {
  return async (dispatch, getState) => {
    const userId = getState().auth.userId;
    const token = getState().auth.token;

    const response = await fetch(
      `https://firestore.googleapis.com/v1/projects/${dbName}/databases/(default)/documents/stripe_customers/${userId}/registrations/${id}`,
      {
        method: "DELETE",
        headers: {
          "Content-Type": "applications/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );

    if (!response.ok) {
      handleErrorResponse(await response.json());
    }

    dispatch({
      type: REMOVE_REGISTRATION,
      registration: { id: id },
    });
  };
};

// ------------------------------

export const rateMyProvider = (id, rating, comment, courseId) => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;

    const response = await fetch(
      `https://us-central1-${dbName}.cloudfunctions.net/rateMyProvider`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          data: {
            registrationId: id,
            rating: rating,
            comment: comment,
            courseId: courseId,
          },
        }),
      }
    );

    if (!response.ok) {
      handleErrorResponse(await response.json());
    }

    dispatch({
      type: UPDATE_RATED_REGISTRATION,
      registration: { id: id },
    });
  };
};

// ------------------------------

export const skipRating = (id) => {
  return async (dispatch, getState) => {
    const userId = getState().auth.userId;
    const token = getState().auth.token;

    const response = await fetch(
      `https://firestore.googleapis.com/v1/projects/${dbName}/databases/(default)/documents/stripe_customers/${userId}/registrations/${id}?updateMask.fieldPaths=rated_date`,
      {
        method: "PATCH",
        headers: {
          "Content-Type": "applications/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          fields: {
            rated_date: { timestampValue: new Date() },
          },
        }),
      }
    );

    if (!response.ok) {
      handleErrorResponse(await response.json());
    }

    dispatch({
      type: UPDATE_RATED_REGISTRATION,
      registration: { id: id },
    });
  };
};

// ------------------------------

export const setAlertTrigger = (id, alert_identifier, alert_setting) => {
  return async (dispatch, getState) => {
    const userId = getState().auth.userId;
    const token = getState().auth.token;

    const response = await fetch(
      `https://firestore.googleapis.com/v1/projects/${dbName}/databases/(default)/documents/stripe_customers/${userId}/registrations/${id}` +
      `?updateMask.fieldPaths=alert_identifier` +
      `&updateMask.fieldPaths=alert_setting`,
      {
        method: "PATCH",
        headers: {
          "Content-Type": "applications/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          fields: {
            alert_identifier: { stringValue: alert_identifier },
            alert_setting: { stringValue: alert_setting },
          },
        }),
      }
    );

    if (!response.ok) {
      handleErrorResponse(await response.json());
    }

    dispatch({
      type: UPDATE_ALERT_SETTING,
      registration: {
        id: id,
        alert_identifier: alert_identifier,
        alert_setting: alert_setting,
      },
    });
  };
};

// ------------------------------

export const archiveRegistration = (registration) => {
  return async (dispatch, getState) => {
    const userId = getState().auth.userId;
    const token = getState().auth.token;
    const id = registration.id;

    const newArchivedDate = new Date();
    const newArchiveValue = registration.archived ? false : true;

    const response = await fetch(
      `https://firestore.googleapis.com/v1/projects/${dbName}/databases/(default)/documents/stripe_customers/${userId}/registrations/${id}` +
      `?updateMask.fieldPaths=archived&updateMask.fieldPaths=archived_date`,
      {
        method: "PATCH",
        headers: {
          "Content-Type": "applications/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          fields: {
            archived: { booleanValue: newArchiveValue },
            archived_date: { timestampValue: newArchivedDate },
          },
        }),
      }
    );

    if (!response.ok) {
      handleErrorResponse(await response.json());
    }

    dispatch({
      type: UPDATE_ARCHIVED_REGISTRATION,
      registration: {
        id: registration.id,
        archived: newArchiveValue,
        archived_date: newArchivedDate,
      },
    });
  };
};

// ------------------------------

const buildHandoutFromArrMap = (firebaseArrayOfMap) => {
  let handouts = [];

  for (var v of firebaseArrayOfMap) {
    const handout = v.mapValue.fields;
    handouts.push(
      new Handout(handout.name.stringValue, handout.uri.stringValue)
    );
  }

  return handouts;
};

// ------------------------------

export const openCertificate = (regId, providerId, courseId) => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;

    const response = await fetch(
      `https://us-central1-${dbName}.cloudfunctions.net/openCertificate`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          data: {
            providerId: providerId,
            courseId: courseId,
          },
        }),
      }
    );

    if (!response.ok) {
      handleErrorResponse(await response.json());
    }

    const resData = await response.json();

    if (resData && resData.result && resData.result.error) {
      return resData.result.errorType;
    } else if (resData && resData.result && resData.result.success) {
      return await dispatch(refreshRegistration(regId));
    }
  };
};

// ------------------------------

export const refreshRegistration = (regId) => {
  return async (dispatch, getState) => {
    const userId = getState().auth.userId;
    const token = getState().auth.token;

    const response = await fetch(
      `https://firestore.googleapis.com/v1/projects/${dbName}/databases/(default)/documents/stripe_customers/${userId}/registrations/${regId}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "applications/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );

    if (!response.ok) {
      handleErrorResponse(await response.json());
    }

    const resData = await response.json();



    if (resData) {
      const registration = buildMobileRegistration(resData);

      dispatch({
        type: UPDATE_SINGLE_REGISTRATION,
        id: regId,
        registration: registration,
      });

      return registration;
    }
  };
};


// ------------------------------

export const getRegistrationByCourseId = (courseId) => {
  return async (dispatch, getState) => {
    const userId = getState().auth.userId;
    const token = getState().auth.token;


    let condEQualCourseId = { compositeFilter: { filters: [], op: "AND" } };
    const fieldFilter = stringFieldFilter("course_id", "EQUAL", courseId);
    condEQualCourseId.compositeFilter.filters.push({ fieldFilter: fieldFilter });

    const response = await fetch(
      `https://firestore.googleapis.com/v1/projects/${dbName}/databases/(default)/documents/stripe_customers/${userId}:runQuery`,
      {
        method: "POST",
        headers: {
          "Content-Type": "applications/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          structuredQuery: {
            from: [{ collectionId: "registrations" }],
            where: condEQualCourseId,
            limit: 1,
          },
        }),
      }
    );

    if (!response.ok) {
      handleErrorResponse(await response.json());
    }

    const resData = await response.json();

    for (const documents of resData) {
      return buildMobileRegistration(documents.document);
    }
  };
};

