import React, { Component } from "react";
import { connect } from "react-redux";
import * as memberActions from "../../store/actions/member";
import Spinner from "../../components/ui/spinner/Spinner";
import InfoModal from "../../components/modals/infoModal/InfoModal";
import Empty from "../../components/ui/empty/Empty";
import memberImage from "../../assets/images/inApp/membership.png";
import MUIDataTable from "mui-datatables";
import * as logger from "../../globals/Logger";

const columns = ["Name", "Member ID", "Member Since", "Term", "Auto Renew"];

const options = {
    filterType: 'checkbox',
    filter: false,
    isRowSelectable: false,
    selectableRows: false
};

class MemberScreen extends Component {
    state = {
        wait: false,
        error: null
    };

    // ------------------------------

    update = async (propValue) => {
        await this.setState({ ...this.state, ...propValue });
    };

    // ------------------------------

    componentDidMount = async () => {
        try {
            window.scrollTo(0, 0);
            await this.update({ error: null, wait: true });
            await this.props.fetchMembers();
            await this.update({ wait: false });
        } catch (error) {
            logger.error(error);
            await this.update({
                wait: false,
                error: error.message,
            });
        }
    };

    // ------------------------------

    render() {
        if (this.state.wait) {
            return <Spinner />;
        }

        if (!this.props.members || this.props.members.length === 0) {
            return (
                <Empty
                    column
                    image={memberImage}
                    message={<div style={{ paddingTop: "10px" }}>
                        Memberships are a great way to give premium access to selected courses, offers and reduced pricing on the ZubU marketplace.  Want to learn more? Contact us at&nbsp;
                        <u style={{ cursor: "pointer" }} onClick={() => { window.open("mailto:info@zubueducation.com", "_blank"); }}>
                            info@zubueducation.com
                        </u> to discuss how to get started.
                    </ div>
                    }
                />
            );
        }

        return (
            <div style={{ background: "white" }}>
                <InfoModal
                    title="An error has occured"
                    message={this.state.error}
                    isErrorModal={this.state.error}
                    open={this.state.error !== null}
                    onClose={() => {
                        this.update({ error: null });
                    }}
                />

                <div style={{ paddingLeft: "3px" }}>
                    <MUIDataTable
                        title={"Memberships"}
                        data={this.props.data}
                        columns={columns}
                        options={options}
                    />
                </div>
            </div>
        );
    }
}

// ------------------------------

const mapStateToProps = (state, props) => {
    const memberData = [];
    const members = state.member.members;

    for (const member of members) {
        memberData.push(member.toArray())
    }

    return {
        setting: state.setting.setting,
        profile: state.profile.profile,
        action: props.history.action,
        members: members,
        data: memberData
    };
};

// ------------------------------

const mapDispatchToProps = (dispatch) => {
    return {
        fetchMembers: () =>
            dispatch(memberActions.fetchMembers()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(MemberScreen);
