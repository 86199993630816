import React from "react";
import { useTranslation } from "react-i18next";
import styles from "./Rating.module.css";
import RatingDistribution from "./RatingDistribution";
import ReviewItem from "../../items/reviewItem/ReviewItem";
import LoadMore from "../pagination/LoadMore";

const RatingReviewsContainer = (props) => {
  const { t } = useTranslation();

  const renderReviewItems = () => {
    return props.reviews ? (
      props.reviews.map((item, index) => {
        return (
          <ReviewItem
            full
            key={index}
            id={item.id}
            first={item.first}
            pic={item.pic}
            course_title={item.course_title}
            rating={item.rating}
            comment={item.comment}
            date={item.renderDate()}
            reply={item.reply}
            replyDate={item.renderReplyDate()}
            /*onReply={() => {
              this.update({ openInputModal: true, selectedReview: item });
            }}
            onDeleteReply={() => {
              this.props.deleteReply(item);
            }}*/
          />
        );
      })
    ) : (
      <div></div>
    );
  };

  return (
    <div
      className={styles.container}
      style={props.full ? { alignItems: "flex-start" } : null}
    >
      <div className={styles.title}>{t("course_student_ratings")}</div>

      <RatingDistribution
        full={props.full}
        rating={props.rating}
        ratingCount={props.ratingCount}
        five={0}
        four={0}
      />

      <div className={styles.title}>{t("course_student_reviews")}</div>

      <div style={{ height: "20px" }}>&nbsp;</div>

      <div>
        {renderReviewItems()}
        <div className={styles.loadMoreContainer}>
          <div className={styles.row}>
            <LoadMore
              title={t("course_more_reviews")}
              hasMore={props.hasMore}
              loading={props.isLoadingMore}
              onLoadMore={props.onLoadMore}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default RatingReviewsContainer;
