import React, { useState, useEffect } from "react";
import styles from "./RegistrationContentModal.module.css";
import { useTranslation } from "react-i18next";
import Button from "@material-ui/core/Button";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import { useTheme } from "@material-ui/core/styles";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import MatInputOutline from "../../../components/ui/input/MatInputOutline";
import VideoChapterModal from "../../../components/modals/videoModal/VideoChapterModal";
import VideoChapterItemList from "../../../components/items/videoChapterItem/VideoChaperItemList"
import Linkify from "linkify-react";

const RegistrationContentModal = (props) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("xs"));
  const fullWidth = true;
  const maxWidth = "md";
  const title = t("registration_instruction_title_provider");

  const [value, setValue] = useState(props.email_instruction);
  const [videoChapters, setVideoChapters] = useState(props.video_chapters);
  const [videoChapterModalOpen, setVideoChapterModalOpen] = useState(false);
  const [videoChapterSelected, setVideoChapterSelected] = useState(null);


  console.log(props.video_chapters);

  useEffect(() => {
    setVideoChapters(props.video_chapters);
  }, [props.video_chapters]);


  // ------------------------------

  const handleClose = (value, courseId, videoChapters) => {
    props.onClose(value, courseId, props.course.is_video ? videoChapters : null);
  };

  // ------------------------------

  const inputChangeHandler = (e) => {
    setValue(e.target.value);
  };

  // ------------------------------

  const linkProps = {
    onClick: (event) => {
      if (!confirm(t("g_confirmation_leave_desc"))) {
        event.preventDefault();
      }
    },
  };

  // ------------------------------

  const onNewEditVideoChapter = async (videoChapter) => {
    setVideoChapterModalOpen(true);
    setVideoChapterSelected(videoChapter);
  }

  // ------------------------------

  const addEditVideoChapter = async (videoChapter) => {
    const copyVideoChapters = videoChapters ? [...videoChapters] : []
    const index = copyVideoChapters.findIndex(vc => vc.id == videoChapter.id);

    if (index > -1) {
      copyVideoChapters[index] = videoChapter
    }
    else {
      copyVideoChapters.push(videoChapter);
    }

    setVideoChapterModalOpen(false);
    setVideoChapters(copyVideoChapters);
  }

  // ------------------------------

  const onUpdateVideoChapterOrder = (chapters) => {
    setVideoChapters([...chapters])
  }

  // ------------------------------

  return (
    <div>
      {videoChapterModalOpen !== null && (
        <VideoChapterModal
          selected={videoChapterSelected}
          open={videoChapterModalOpen}
          onClose={(videoChapter) => {
            if (videoChapter) {
              addEditVideoChapter(videoChapter);
            }
            else {
              setVideoChapterModalOpen(false)
            }
          }}
        />
      )}

      <Dialog
        fullScreen={fullScreen}
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={props.open}
        onClose={handleClose.bind(this, null, null, null)}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="alert-dialog-title">
          {props.readOnly ? t("registration_instruction_title") : title}
        </DialogTitle>
        <div className={styles.infoContainer}>
          <InfoOutlinedIcon />
          <div style={{ marginLeft: "10px" }}>
            {props.readOnly ? (
              <div>{t("registration_instruction_desc")}</div>
            ) : (
              <div>
                {t("registration_instruction_private_disclaimer")} &nbsp;
                <VisibilityOffIcon fontSize="inherit" />
              </div>
            )}
          </div>
        </div>

        <DialogContent>
          <div style={{ height: "10px" }}>&nbsp;</div>

          {props.readOnly ? (
            <div className={styles.readOnlyTextContainer}>
              <Linkify options={{ attributes: linkProps, target: "_blank" }}>
                {value}
              </Linkify>
            </div>
          ) : (
            <div>
              <MatInputOutline
                nomargin="true"
                notopbottommargin="true"
                title={
                  props.course.is_video
                    ? "Video Access Instructions"
                    : "Livestream Access Instructions"
                }
                name="email_instruction"
                placeholder={
                  props.course.is_video
                    ? "Url link to video, passcode etc."
                    : "Url link to zoom, teams, meeting id, passcode etc."
                }
                rows="15"
                multiline
                value={value}
                onChange={props.readOnly ? () => { } : inputChangeHandler}
                maxLength={1000}
                readOnly={false}
                required={false}
              />

              {/**props.course.is_video &&
                <VideoChapterItemList
                  fullWidth
                  videoChapters={videoChapters}
                  onNewEditVideoChapter={onNewEditVideoChapter}
                  onUpdateVideoChapterOrder={onUpdateVideoChapterOrder}
                />**/
              }
            </div>
          )}
        </DialogContent>
        {props.readOnly ? (
          <DialogActions>
            <Button
              onClick={handleClose.bind(this, null, null, null)}
              color="primary"
            >
              {t("g_ok")}
            </Button>
          </DialogActions>
        ) : (
          <DialogActions>
            <Button
              onClick={handleClose.bind(this, null, null, null)}
              color="primary"
            >
              {t("g_cancel")}
            </Button>
            <Button
              autoFocus
              onClick={handleClose.bind(this, value, props.course.id, videoChapters)}
              color="primary"
            >
              {t("g_save")}
            </Button>
          </DialogActions>
        )}
      </Dialog>
    </div>
  );
};

export default RegistrationContentModal;
