import styles from "./PhoneBadges.module.css";
import Zoom from "@material-ui/core/Zoom";
import appstore from "../../../assets/images/welcome/app_store.png";
import googleplay from "../../../assets/images/welcome/google_play.png";

const PhoneBadges = (props) => {
  if (props.plain) {
    return (
      <span className={styles.phoneBadeContainer}>
        <Zoom in={true} style={{ transitionDelay: true ? "300ms" : "0ms" }}>
          <span>
            <a
              target="_blank"
              href="https://apps.apple.com/ca/app/zubu/id1546508116"
              rel="noopener noreferrer"
            >
              <img src={appstore} alt="" className={styles.appleBadgeSmall} />
            </a>
          </span>
        </Zoom>
        &nbsp;
        <Zoom in={true} style={{ transitionDelay: true ? "500ms" : "0ms" }}>
          <span>
            <a
              target="_blank"
              href="https://play.google.com/store/apps/details?id=com.zubueducation.zubu"
              rel="noopener noreferrer"
            >
              <img
                src={googleplay}
                alt=""
                className={styles.androidBadgeSmall}
              />
            </a>
          </span>
        </Zoom>
      </span>
    );
  }

  return (
    <div className={styles.phoneBadeContainer}>
      <Zoom in={true} style={{ transitionDelay: true ? "300ms" : "0ms" }}>
        <div>
          <a
            target="_blank"
            href="https://apps.apple.com/ca/app/zubu/id1546508116"
            rel="noopener noreferrer"
          >
            <img src={appstore} alt="" className={styles.appleBadge} />
          </a>
        </div>
      </Zoom>
      &nbsp;
      <Zoom in={true} style={{ transitionDelay: true ? "500ms" : "0ms" }}>
        <div>
          <a
            target="_blank"
            href="https://play.google.com/store/apps/details?id=com.zubueducation.zubu"
            rel="noopener noreferrer"
          >
            <img src={googleplay} alt="" className={styles.androidBadge} />
          </a>
        </div>
      </Zoom>
    </div>
  );
};

export default PhoneBadges;
