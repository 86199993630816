import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { withTranslation } from "react-i18next";
import styles from "./Certificate.module.css";
import * as certActions from "../../store/actions/certificate";
import * as profileActions from "../../store/actions/profile";
import * as settingActions from "../../store/actions/setting";

import EditIcon from "@material-ui/icons/Edit";
import Button from "@material-ui/core/Button";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import Spinner from "../../components/ui/spinner/Spinner";
import InfoModal from "../../components/modals/infoModal/InfoModal";
import SimpleContainer from "../../components/ui/container/Container";
import CertificatePreview from "../../components/ui/certificatePreview/CertificatePreview";
import ImageCropModal from "../../components/modals/imageCropModal/ImageCropModal";
import MatAppBar from "../../components/ui/appBar/MatAppBar";
import FormButton from "../../components/ui/button/FormButton";
import Course from "../../model/Course";

import { hasFormErrors, validateField } from "../../globals/Validations";
import CertificateEditForm from "./CertificateEditForm";
import Certificate from "../../model/Certificate";

import * as logger from "../../globals/Logger";

class CertificateEditScreen extends Component {
  state = {
    wait: false,
    error: null,
    hasErrors: false,

    imageToCrop: null,
    aspectRatio: { aspect: 1002 / 253, width: 100, height: 50 },
    imageType: null,
    header: "",
    footer: "",

    // ----- form
    certificate: this.props.certificate,
    formErrors: {},
    hasErrors: false,
  };

  // ------------------------------

  update = async (propValue) => {
    await this.setState({ ...this.state, ...propValue });
  };

  // ------------------------------

  componentDidMount = async () => {
    try {
      window.scrollTo(0, 0);
      await this.update({ wait: true });
      await Promise.all([this.props.fetchSetting(), this.props.fetchProfile()]);
      await this.update({ wait: false });
    } catch (err) {
      logger.error(err);
      this.update({ wait: false, error: err.message });
    }
  };

  // ------------------------------

  componentDidUpdate = async (prevProps) => {
    if (prevProps.certificate !== this.props.certificate) {
      await this.update({
        certificate: this.props.certificate,
      });
    }
  };

  // ------------------------------

  saveHandler = async () => {
    try {
      const certIdWhenNew = await this.save();

      // ---------------

      const hasPicHeader =
        this.state.header && this.state.header.startsWith("blob:");

      const hasPicFooter =
        this.state.footer && this.state.footer.startsWith("blob:");

      // ---------------

      if (certIdWhenNew && hasPicHeader) {
        await this.props.updateCertHeaderFooter(
          this.state.header,
          "certificate_header",
          certIdWhenNew,
          this.state.certificate
        );
      }

      if (certIdWhenNew && hasPicFooter) {
        await this.props.updateCertHeaderFooter(
          this.state.footer,
          "certificate_footer",
          certIdWhenNew,
          this.state.certificate
        );
      }

      // ---------------

      //await this.update({ wait: false });

      this.props.history.goBack();

      // ---------------

      return true;
    } catch (err) {
      logger.error(err);
      this.update({
        wait: false,
        error: err.message,
      });
      return false;
    }
  };

  // ------------------------------

  save = async () => {
    await this.validateForm();

    if (this.state.hasErrors) {
      window.scrollTo(0, 0);
      await this.update({
        error: this.props.t("settings_please_verify"),
      });
      return false;
    }

    await this.update({
      wait: true,
    });

    if (this.props.cId === "new") {
      return await this.props.addCert(this.state.certificate);
    } else if (this.props.cId && this.state.certificate.id) {
      await this.props.updateCert(this.state.certificate);
    }
  };

  // ------------------------------

  validateForm = async () => {
    const t = this.props.t;
    const p = this.state.certificate;
    const e = {};
    const e0 = validateField(p, e, "title", "plainRequired", t);
    await this.update({ formErrors: e0 });
    await this.updateHasErrors(e0);
  };

  // ------------------------------

  updateHasErrors = async (formErrors) => {
    await this.update({ hasErrors: hasFormErrors(formErrors, {}) });
  };

  // ------------------------------

  openImageCropHandler = async (imageUri, imageType) => {
    await this.update({ imageToCrop: imageUri, imageType: imageType });
  };

  // ------------------------------

  updateCertificateHandler = async (imageBlob, imageType) => {
    let success = false;

    try {
      let certId = this.props.cId;

      const copyCertObj = { ...this.state.certificate };

      if (certId === "new") {
        certId = await this.save();

        if (certId) {
          this.props.setEditAction(certId);
        }
      }

      if (certId) {
        await this.update({ wait: true });
        await this.props.updateCertHeaderFooter(
          imageBlob,
          imageType,
          certId,
          copyCertObj
        );
        await this.update({ wait: false });
        success = true;
      }
    } catch (err) {
      logger.error(err);
      this.update({ wait: false, error: err.message });
    }

    return success;
  };

  // ------------------------------

  render() {
    if (this.props.action !== "PUSH") {
      return <Redirect to="/certificate" />;
    }

    if (this.state.wait) {
      return <Spinner />;
    }

    return (
      <div style={{ background: "white" }}>
        <MatAppBar
          static
          left={
            <Button
              color="inherit"
              style={{ color: "black" }}
              onClick={this.props.history.goBack}
              startIcon={<ArrowBackIosIcon />}
            >
              Back
            </Button>
          }
        ></MatAppBar>

        <SimpleContainer disableGutters={false}>
          <InfoModal
            isErrorModal={true}
            title="An error has occured"
            message={this.state.error}
            open={this.state.error !== null}
            onClose={() => {
              this.update({ error: null });
            }}
          />

          {this.state.imageToCrop !== null && (
            <ImageCropModal
              unlocked
              aspect={this.state.aspectRatio}
              finalImageSize={{ width: 1002, height: 253 }}
              title="Select and Crop Photo"
              file={this.state.imageToCrop}
              open={this.state.imageToCrop !== null}
              onClose={async (blob) => {
                if (blob) {
                  const imgType = this.state.imageType;

                  if (imgType === "certificate_header") {
                    await this.update({ header: blob });
                  }

                  if (imgType === "certificate_footer") {
                    await this.update({ footer: blob });
                  }

                  const success = await this.updateCertificateHandler(
                    blob,
                    imgType
                  );

                  if (!success) {
                    if (imgType === "certificate_header") {
                      await this.update({ header: "" });
                    }

                    if (imgType === "certificate_footer") {
                      await this.update({ footer: "" });
                    }
                  }
                }
                this.update({ imageToCrop: null, imageType: null });
              }}
            />
          )}

          <div style={{ height: "80px" }}>&nbsp;</div>

          <div className={styles.sectionTitle}>
            <div className={styles.centerRow}>
              <EditIcon style={{ color: "white" }} />
              &nbsp;&nbsp;
              {this.props.cId === "new"
                ? "Add Certificate"
                : "Edit Certificate"}
            </div>
          </div>

          <CertificateEditForm
            certificate={this.state.certificate}
            formErrors={this.state.formErrors}
            updateFormErrors={async (errors) => {
              await this.update({ formErrors: errors });
              await this.updateHasErrors(errors);
            }}
            updateHasErrors={async (errors) => {
              await this.updateHasErrors(errors);
            }}
            update={async (certificate) => {
              await this.update({ certificate: certificate });
            }}
          />

          <div className={styles.sectionContainer}>
            <div className={styles.brandingDesc}>
              Cutomize your certificate of completion with your company brand
              colors and accreditations or use the default template. Simply
              click the header and footer to replace the images.
            </div>
          </div>

          <div className={styles.sectionContainer}>
            <div className={styles.brandingDescSize}>
              Header and Footer images must be <b>1002 x 253 pixels</b> in size.
            </div>
          </div>

          <CertificatePreview
            editable
            course={new Course()}
            profile={this.props.profile}
            header={
              this.state.header
                ? this.state.header
                : this.props.certificate && this.props.certificate.header
                ? this.props.certificate.header
                : ""
            }
            footer={
              this.state.footer
                ? this.state.footer
                : this.props.certificate && this.props.certificate.footer
                ? this.props.certificate.footer
                : ""
            }
            openImageCrop={this.openImageCropHandler}
            onRemovePic={async (imageType) => {
              if (imageType === "certificate_header") {
                await this.update({ header: "" });
              }

              if (imageType === "certificate_footer") {
                await this.update({ footer: "" });
              }

              await this.updateCertificateHandler(null, imageType);
            }}
          />

          <div className={styles.sectionContainer}>
            <div className={styles.brandingDescSize}>
              <br></br>
              <br></br>* To modify your <b>company logo</b> and{" "}
              <b>authorized signature</b> navigate to settings / profile from
              the menu. All certificates will share the same company logo and
              signature.
            </div>
          </div>

          <FormButton
            label="Save"
            onClick={this.saveHandler}
            disabled={this.state.hasErrors}
          />
        </SimpleContainer>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  const cId = state.certificate.certificateId;
  const certList = state.certificate.certificate;
  const editCert = certList.find((c) => c.id === cId);

  return {
    action: props.history.action,
    certificate: editCert ? editCert : new Certificate("", ""),
    cId: cId,
    setting: state.setting.setting,
    profile: state.profile.profile,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setEditAction: (id) => dispatch(certActions.setEditAction(id)),
    fetchSetting: () => dispatch(settingActions.fetchSetting()),
    fetchProfile: () => dispatch(profileActions.fetchProfile()),
    addCert: (certificate) => dispatch(certActions.addCert(certificate)),
    updateCert: (certificate) => dispatch(certActions.updateCert(certificate)),
    updateCertHeaderFooter: (urlBlob, imageType, certId, certificate) =>
      dispatch(
        certActions.updateCertHeaderFooter(
          urlBlob,
          imageType,
          certId,
          certificate
        )
      ),
  };
};

export default withTranslation("translations")(
  connect(mapStateToProps, mapDispatchToProps)(CertificateEditScreen)
);
