import React from "react";
import styles from "./OurProviders.module.css";
import Tooltip from "@material-ui/core/Tooltip";
import Zoom from "@material-ui/core/Zoom";
import zblc from "../../../assets/images/providers/zblc.png";
import cidn from "../../../assets/images/providers/cidn.png";
import bisco from "../../../assets/images/providers/biscocanada.png";
import periomastery from "../../../assets/images/providers/periomastery.jpg";
import ahs from "../../../assets/images/providers/ahs.png";
import orthoperioxp from "../../../assets/images/providers/orthoperioxp.png";
import ico from "../../../assets/images/providers/ico.png";
import eschool from "../../../assets/images/providers/e_school.png";
import bite_club from "../../../assets/images/providers/bite_club.png";
import maxi from "../../../assets/images/providers/aaid_maxi_vancouver_stanley_park.png";
import dsd from "../../../assets/images/providers/dsd.png";
import paro from "../../../assets/images/providers/paro_implanto.png";
import hufriedygroup from "../../../assets/images/providers/hu_friedy_group.png";
import ProviderLink from "./ProviderLink";

const OurProviders = (props) => {
  return (
    <div className={styles.pageContainerWhite}>
      <ProviderLink
        url="https://paroimplanto.ca/"
        delay="1600ms"
        name="Paro Implanto"
        image={paro}
        className={styles.provider2}
      />
      <ProviderLink
        url="https://www.hufriedygroup.com/"
        delay="1200ms"
        name="HuFriedyGroup"
        image={hufriedygroup}
        className={styles.provider3}
      />
      <ProviderLink
        url="https://biscocanada.com"
        delay="1000ms"
        name="Bisco Canada"
        image={bisco}
        className={styles.provider1}
      />
      <ProviderLink
        url="https://orthoperioxp.com/courses/zubu-course-provider/"
        delay="1000ms"
        name="orthoperioxp"
        image={orthoperioxp}
        className={styles.provider}
      />
      <ProviderLink
        url="https://periomasterycourse.com/startnow"
        delay="1200ms"
        name="Perio Mastery"
        image={periomastery}
        className={styles.provider}
      />
      <ProviderLink
        url="https://advancedhygienesolutions.ca"
        delay="1000ms"
        name="Advanced Hygiene Solutions"
        image={ahs}
        className={styles.provider}
      />
      <ProviderLink
        url="https://education.tomaslinkevicius.com/"
        delay="1600ms"
        name="Zero Bone Loss Concepts"
        image={zblc}
        className={styles.provider2}
      />
      <a
        target="_blank"
        href="https://ico-institut.ca/"
        rel="noopener noreferrer"
      >
        <Zoom in={true} style={{ transitionDelay: true ? "1600ms" : "0ms" }}>
          <Tooltip
            title="Institut Canadien D'Occlusion"
            aria-label="Institut Canadien D'Occlusion"
          >
            <img
              src={ico}
              alt="Institut Canadien D'Occlusion"
              className={styles.provider}
            />
          </Tooltip>
        </Zoom>
      </a>
      <ProviderLink
        url="https://digitalsmiledesign.com/"
        delay="1600ms"
        name="Digital Smile Design"
        image={dsd}
        className={styles.providerNoPadding}
      />
      <a target="_blank" href="https://cidn-edu.com/" rel="noopener noreferrer">
        <Zoom in={true} style={{ transitionDelay: true ? "1600ms" : "0ms" }}>
          <Tooltip
            title="Canadian Implant Dentistry Network"
            aria-label="Canadian Implant Dentistry Network"
          >
            <img
              src={cidn}
              alt="Canadian Implant Dentistry Network"
              className={styles.provider}
            />
          </Tooltip>
        </Zoom>
      </a>
      <a
        target="_blank"
        href="https://soniachopradds.com/e-school/"
        rel="noopener noreferrer"
      >
        <Zoom in={true} style={{ transitionDelay: true ? "1600ms" : "0ms" }}>
          <Tooltip
            title="E-School Online Endodontic"
            aria-label="E-School Online Endodontic"
          >
            <img
              src={eschool}
              alt="E-School Online Endodontic"
              className={styles.provider2}
            />
          </Tooltip>
        </Zoom>
      </a>
      <a
        target="_blank"
        href="https://www.thebiteclub.ca/"
        rel="noopener noreferrer"
      >
        <Zoom in={true} style={{ transitionDelay: true ? "1600ms" : "0ms" }}>
          <Tooltip title="B.I.T.E Club" aria-label="B.I.T.E Club">
            <img
              src={bite_club}
              alt="B.I.T.E Club"
              className={styles.provider2}
            />
          </Tooltip>
        </Zoom>
      </a>
      <a
        target="_blank"
        href="https://vancouvermaxicourse.com/"
        rel="noopener noreferrer"
      >
        <Zoom in={true} style={{ transitionDelay: true ? "1600ms" : "0ms" }}>
          <Tooltip
            title="AAID MaxiCourse Vancouver"
            aria-label="AAID MaxiCourse Vancouver"
          >
            <img
              src={maxi}
              alt="AAID MaxiCourse Vancouver"
              className={styles.provider2}
            />
          </Tooltip>
        </Zoom>
      </a>
    </div>
  );
};

export default OurProviders;
