import React, { useEffect, useState } from "react";
import styles from "./Ce.module.css";
import { useTranslation } from "react-i18next";
import * as lodashCloneDeep from "lodash.clonedeep";
import MatInputOutline from "../../../components/ui/input/MatInputOutline";
import MatSelectOutline from "../../../components/ui/labelSelect/MatSelectOutline";
import { hasError } from "../../../globals/Validations";
import { validateField } from "../../../globals/Validations";
import { buildSingleSelect, buildCategory } from "../../../globals/Common";
import { selectHours, selectAccreditation, selectCurrency } from "../../../globals/Data";
import { FNS_MEDIUM_DATE_FORMAT } from "../../../globals/Dates";
import DateSelect from "../../../components/ui/dateSelect/DateSelect";
import CustomIconButton from "../../../components/ui/button/CustomIconButton";
import sendCertImg from "../../../assets/images/inApp/sendcert.png";


const CeEditForm = (props) => {
    const { t } = useTranslation();

    // ------------------------------

    const validate = async (field, validatorKey) => {
        if (field === null) {
            await props.updateHasErrors(props.formErrors);
        } else {
            const errors = validateField(
                props.ce,
                props.formErrors,
                field,
                validatorKey
            );

            await props.updateFormErrors(errors);
        }
    };

    // ------------------------------

    const inputChangeHandler = (e) => {
        const copy = lodashCloneDeep(props.ce);
        copy[e.target.name] = e.target.value;
        props.update(copy);
    };

    // ------------------------------

    const selectChangeHandler = (key, e, select) => {
        const copy = lodashCloneDeep(props.ce);
        copy[key] = select ? select.value : null;
        props.update(copy);
    };

    // ------------------------------

    const multiSelectChangeHandler = (targetName, e, selects) => {
        const copy = lodashCloneDeep(props.ce);
        const arrValues = [];

        for (const select of selects) {
            arrValues.push(select.value);
        }

        copy[targetName] = arrValues;
        props.update(copy);
    };

    // ------------------------------

    return (
        <form className={styles.form}>
            <div className={styles.pricingContainer}>
                <div>
                    <CustomIconButton
                        icon={<div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>

                            <img
                                src={sendCertImg}
                                alt=""
                                style={{ width: "70px" }}
                            />
                        </div>}
                        text={props.hasCertificate ? t("g_view_edit") + " " + t("course_certificate") : t("g_add") + " " + t("course_certificate")}
                        onClick={props.openCertificate}
                        style={{ borderColor: "black", border: "1px dashed gray" }}
                    />
                </div>
            </div>
            <MatInputOutline
                notopbottommargin="true"
                title={t("ce_title")}
                name="title"
                rows="2"
                multiline
                value={props.ce.title}
                onChange={inputChangeHandler}
                onBlur={validate.bind(this, "title", "plainRequired")}
                maxLength={200}
                error={hasError(props.formErrors.title)}
                helpertext={props.formErrors.title}
                readOnly={!props.isInEdit}
                required
            />
            <MatInputOutline
                notopbottommargin="true"
                title={t("course_description")}
                name="description"
                rows="6"
                multiline
                value={props.ce.description}
                onChange={inputChangeHandler}
                maxLength={1000}
                readOnly={!props.isInEdit}
            />
            <MatInputOutline
                notopbottommargin="true"
                title={t("course_notes")}
                name="notes"
                rows="6"
                multiline
                value={props.ce.notes}
                onChange={inputChangeHandler}
                maxLength={1000}
            />
            <MatSelectOutline
                title={t("ce_credit_lecture_hours")}
                placeholder={t("ce_credit_lecture_hours")}
                name="lecture_hours"
                id="lecture_hours"
                disableClearable={true}
                defaultValue={selectHours[0]}
                onChange={selectChangeHandler.bind(this, "lecture_hours")}
                onBlur={validate.bind(this, "lecture_hours", "required")}
                helpertext={props.formErrors.lecture_hours}
                required={true}
                options={selectHours}
                value={buildSingleSelect(props.ce.lecture_hours)}
                getOptionLabel={(option) => option.label}
                getOptionSelected={(opt1, opt2) => opt1.value === opt2.value}
                disabled={!props.isInEdit}
                multiple={false}
            />
            <MatSelectOutline
                title={t("ce_credit_handson_hours")}
                placeholder={t("ce_credit_handson_hours")}
                name="practice_hours"
                id="practice_hours"
                disableClearable={true}
                onChange={selectChangeHandler.bind(this, "practice_hours")}
                onBlur={validate.bind(this, "practice_hours", "required")}
                helpertext={props.formErrors.practice_hours}
                required={true}
                options={selectHours}
                value={buildSingleSelect(props.ce.practice_hours)}
                getOptionLabel={(option) => option.label}
                getOptionSelected={(opt1, opt2) => opt1.value === opt2.value}
                disabled={!props.isInEdit}
                multiple={false}
            />
            <MatSelectOutline
                title={t("ce_credit_odq")}
                placeholder={t("ce_credit_odq")}
                name="credits"
                id="credits"
                disableClearable={false}
                onChange={selectChangeHandler.bind(this, "credits")}
                options={selectHours}
                value={buildSingleSelect(props.ce.credits)}
                getOptionLabel={(option) => option.label}
                getOptionSelected={(opt1, opt2) => opt1.value === opt2.value}
                disabled={!props.isInEdit}
                multiple={false}
            />
            <MatSelectOutline
                title={t("course_accreditation")}
                placeholder={t("course_accreditation")}
                name="category"
                id="category"
                onChange={multiSelectChangeHandler.bind(this, "category")}
                options={selectAccreditation}
                value={buildCategory(props.ce.category)}
                getOptionLabel={(option) => option.label}
                getOptionSelected={(opt1, opt2) => opt1.value === opt2.value}
                disabled={!props.isInEdit}
                multiple={true}
                required={false}
            />
            <MatInputOutline
                notopbottommargin="true"
                title={t("ce_course_provider")}
                name="organizer"
                rows="1"
                multiline
                value={props.ce.organizer}
                onChange={inputChangeHandler}
                maxLength={200}
                readOnly={!props.isInEdit}
            />
            <div className={styles.pricingContainer}>
                <DateSelect
                    disabled={!props.isInEdit}
                    labelLong
                    withPortal
                    title={t("ce_course_start_date")}
                    selected={new Date(props.ce.courseDate ? props.ce.courseDate : new Date())}
                    dateFormat={FNS_MEDIUM_DATE_FORMAT}
                    required={true}
                    minDate={new Date(1970, 1, 1)}
                    onChange={(date) => {
                        const copy = lodashCloneDeep(props.ce);
                        if (date) {
                            date.setHours(0);
                            date.setMinutes(0);
                            date.setSeconds(0);
                            copy.courseDate = date;
                        } else {
                            copy.courseDate = new Date();
                        }
                        props.update(copy);
                    }}
                />
            </div>
            <MatSelectOutline
                noTopBottomMargin2
                title={t("ce_currency")}
                placeholder={t("ce_currency")}
                name="currency"
                id="currency"
                disableClearable={true}
                onChange={selectChangeHandler.bind(this, "currency")}
                options={selectCurrency}
                value={buildSingleSelect(props.ce.currency)}
                getOptionLabel={(option) => option.label}
                getOptionSelected={(opt1, opt2) => opt1.value === opt2.value}
                disabled={!props.isInEdit}
                multiple={false}
            />
            <div className={styles.pricingContainer}>
                <MatInputOutline
                    noTopBottomMargin2
                    currency={"CAD"}
                    notopbottommargin="true"
                    title={t("g_price")}
                    name="price"
                    value={props.ce.price}
                    onChange={inputChangeHandler}
                    maxLength={10}
                    readOnly={!props.isInEdit}
                    required={false}
                />
            </div>

        </form>
    );
};

export default CeEditForm;
