import React, { Fragment } from "react";
import styles from "./ResponsiveIconButton.module.css";
import Badge from "@material-ui/core/Badge";
import Button from "@material-ui/core/Button";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import IconButton from "@material-ui/core/IconButton";

// ------------------------------

const ResponsiveIconButton = (props) => {
    const matches = useMediaQuery("(max-width:599px)");

    return (
        <Fragment>
            {matches ? (
                <div className={styles.headerTitleBlurb}>
                    <IconButton
                        aria-label={props.title}
                        onClick={props.onClick}
                        style={{ backgroundColor: "white" }}
                    >
                        <Badge
                            badgeContent={props.filterCount > 0 ? props.filterCount : null}
                            color="error"
                            overlap="rectangular"
                        >
                            {props.icon}
                        </Badge>
                    </IconButton>
                </div>
            ) : (
                <div className={styles.headerTitleBlurb}>
                    <Badge
                        badgeContent={props.filterCount > 0 ? props.filterCount : null}
                        color="error"
                        overlap="rectangular"
                    >
                        <Button
                            variant="outlined"
                            color="primary"
                            size="large"
                            startIcon={props.icon}
                            onClick={props.onClick}
                            style={{ backgroundColor: "white", border: 0, fontSize: "12px" }}
                        >
                            {props.title}
                        </Button>
                    </Badge>
                </div>
            )}
        </Fragment>
    );
};

export default ResponsiveIconButton;
