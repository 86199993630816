import { handleErrorResponse } from "../../globals/Error";
import { dbName } from "../../globals/ApiKeys";
import { firebaseNameToId } from "../../globals/Common";
import firebase from "firebase/app";

import Attendance from "../../model/Attendance";
export const SET_ATTENDANCE = "SET_ATTENDANCE";
export const UPDATE_ATTENDANCE = "UPDATE_ATTENDANCE";
export const SET_ACTIVATE_ATTENDANCE = "SET_ACTIVATE_ATTENDANCE";
export const SET_ATTENDANCE_PASS = "SET_ATTENDANCE_PASS";
export const SET_ATTENDANCE_AUDIT = "SET_ATTENDANCE_AUDIT";
export const ADD_ATTENDANCE = "ADD_ATTENDANCE";

const STORE_KEY = "userDataPassless";

let unSubscribeAttendanceListener;

// ------------------------------

export const getLocalStorageUserInfo = () => {
  return async (dispatch) => {
    const data = await localStorage.getItem(STORE_KEY);
    const transformedData = JSON.parse(data);
    return transformedData;
  };
};

// ------------------------------

export const removeLocalStorageUserInfo = () => {
  return async (dispatch) => {
    localStorage.removeItem(STORE_KEY);
  };
};

// ------------------------------

export const validatePasscode = (pId, cId, uri, passcode, name) => {
  return async (dispatch, getState) => {
    const resData = await fetch(
      `https://us-central1-${dbName}.cloudfunctions.net/validateUriCode`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          data: {
            uriCode: uri,
            passcode: passcode,
            providerId: pId,
            courseId: cId,
          },
        }),
      }
    );

    if (!resData.ok) {
      const errorResonseData = await resData.json();

      if (
        errorResonseData &&
        errorResonseData.error &&
        errorResonseData.error.status === "PERMISSION_DENIED"
      ) {
        throw new Error(errorResonseData.error.message);
      }

      handleErrorResponse(errorResonseData);
    }

    localStorage.setItem(
      STORE_KEY,
      JSON.stringify({
        passcode: passcode,
        name: name,
      })
    );

    dispatch({
      type: SET_ATTENDANCE_AUDIT,
      passcode: passcode,
      name: name,
    });
  };
};

// ------------------------------

export const fetchAttendanceActiveFlag = (courseId) => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;
    const userId = getState().auth.userId;

    const arrResponse = await fetch(
      `https://firestore.googleapis.com/v1/projects/${dbName}/databases/(default)/documents/registration/${userId}/course/${courseId}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "applications/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );

    if (!arrResponse.ok) {
      handleErrorResponse(await arrResponse.json());
    }

    const resData = await arrResponse.json();

    dispatch({
      type: SET_ACTIVATE_ATTENDANCE,
      isActive:
        resData &&
        resData.fields &&
        resData.fields.attendance_activated &&
        resData.fields.attendance_activated.booleanValue
          ? true
          : false,
      passcode:
        resData && resData.fields && resData.fields.passcode
          ? resData.fields.passcode.stringValue
          : "",
      uricode:
        resData && resData.fields && resData.fields.attendance_uricode
          ? resData.fields.attendance_uricode.stringValue
          : "",
    });
  };
};

// ------------------------------

export const activateAttendance = (isActive, courseId) => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;

    const response = await fetch(
      `https://us-central1-${dbName}.cloudfunctions.net/activateAttendance`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          data: {
            courseId: courseId,
            activate: isActive ? true : false,
            deactivate: isActive ? false : true,
          },
        }),
      }
    );

    if (!response.ok) {
      handleErrorResponse(await response.json());
    }

    await response.json();

    dispatch({
      type: SET_ACTIVATE_ATTENDANCE,
      isActive: isActive,
    });
  };
};

// ------------------------------

export const fetchAttendance = (courseId, providerId) => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;

    let headers = {};

    if (token) {
      headers = {
        "Content-Type": "applications/json",
        Authorization: `Bearer ${token}`,
      };
    } else {
      headers = {
        "Content-Type": "applications/json",
      };
    }

    const arrResponse = await fetch(
      `https://firestore.googleapis.com/v1/projects/${dbName}/databases/(default)/documents/registration/${providerId}/course/${courseId}:runQuery`,
      {
        method: "POST",
        headers: headers,
        body: JSON.stringify({
          structuredQuery: {
            from: [{ collectionId: "attendance" }],
          },
        }),
      }
    );

    if (!arrResponse.ok) {
      handleErrorResponse(await arrResponse.json());
    }

    const resData = await arrResponse.json();
    const attendance = loadAttendance(resData);

    dispatch({
      type: SET_ATTENDANCE,
      studentAttendance: attendance,
    });
  };
};

// ------------------------------

const loadAttendance = (resData) => {
  const loaded = [];

  for (const documents of resData) {
    const student = documents.document;

    if (student && student.name) {
      loaded.push(
        new Attendance(
          firebaseNameToId(student.name),
          student.fields.first.stringValue,
          student.fields.last.stringValue,
          student.fields.license.stringValue,
          student.fields.occupation.stringValue,
          student.fields.registeredByName.stringValue,
          student.fields.orderRefNumber.stringValue,
          student.fields.checkedIn.booleanValue
        )
      );
    }
  }

  return loaded;
};

// ------------------------------

export const updateAttendance = (attendance, courseId, providerId) => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;

    let headers = {};

    if (token) {
      headers = {
        "Content-Type": "applications/json",
        Authorization: `Bearer ${token}`,
      };
    } else {
      headers = {
        "Content-Type": "applications/json",
      };
    }

    const response = await fetch(
      `https://firestore.googleapis.com/v1/projects/${dbName}/databases/(default)/documents/registration/${providerId}/course/${courseId}/attendance/${attendance.id}` +
        `?updateMask.fieldPaths=checkedIn`,
      {
        method: "PATCH",
        headers: headers,
        body: JSON.stringify({
          fields: {
            checkedIn: { booleanValue: attendance.checkedIn ? false : true },
          },
        }),
      }
    );

    if (!response.ok) {
      handleErrorResponse(await response.json());
    }

    // message will be automatically read by listener
  };
};

// ------------------------------

export const updatePasscode = (courseId, providerId, passcode) => {
  return async (dispatch, getState) => {
    const userId = getState().auth.userId;
    const token = getState().auth.token;

    await fetch(
      `https://firestore.googleapis.com/v1/projects/${dbName}/databases/(default)/documents/registration/${providerId}/course/${courseId}?updateMask.fieldPaths=passcode`,
      {
        method: "PATCH",
        headers: {
          "Content-Type": "applications/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          fields: {
            passcode: { stringValue: passcode },
          },
        }),
      }
    );

    dispatch({
      type: SET_ATTENDANCE_PASS,
      passcode: passcode,
    });
  };
};

// ------------------------------

export const startAttendanceListener = (courseId, providerId) => {
  return async (dispatch, getState) => {
    const ref = firebase
      .firestore()
      .collection("registration")
      .doc(providerId)
      .collection("course")
      .doc(courseId)
      .collection("attendance");

    unSubscribeAttendanceListener = ref.onSnapshot((querySnapshot) => {
      querySnapshot.docChanges().forEach((change) => {
        if (change.type === "added") {
          const msgData = change.doc.data();

          const newAttendance = new Attendance(
            change.doc.id,
            msgData.first,
            msgData.last,
            msgData.license,
            msgData.occupation,
            msgData.registeredByName,
            msgData.orderRefNumber,
            msgData.checkedIn
          );

          dispatch({
            type: ADD_ATTENDANCE,
            attendance: newAttendance,
          });
        } else if (change.type === "modified") {
          const msgData = change.doc.data();

          const updateAttendance = new Attendance(
            change.doc.id,
            msgData.first,
            msgData.last,
            msgData.license,
            msgData.occupation,
            msgData.registeredByName,
            msgData.orderRefNumber,
            msgData.checkedIn
          );

          dispatch({
            type: UPDATE_ATTENDANCE,
            attendance: updateAttendance,
          });
        }
      });
    });
  };
};

// ------------------------------

export const stopAttendanceListener = () => {
  return async (dispatch, getState) => {
    try {
      if (unSubscribeAttendanceListener) {
        unSubscribeAttendanceListener();
      }
    } catch (error) {
      logger.error(error);
    }
  };
};

// ------------------------------
