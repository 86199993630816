import { handleErrorResponse } from "../../globals/Error";
import { dbName } from "../../globals/ApiKeys";
import InboxChat from "../../model/InboxChat";
import InboxMessage from "../../model/InboxMessage";
import {
  firebaseNameToId,
  stringFieldFilter,
  booleanFieldFilter,
  timestampFieldFilter,
} from "../../globals/Common";
import * as logger from "../../globals/Logger";
import firebase from "firebase/app";

export const SET_CONVERSATIONS = "SET_CONVERSATIONS";
export const ADD_CONVERSATION = "ADD_CONVERSATION";
export const UPDATE_CONVERSATION = "UPDATE_CONVERSATION";
export const REMOVE_CONVERSATION = "REMOVE_CONVERSATION";
export const SET_MESSAGES = "SET_MESSAGES";
export const SEND_MESSAGE = "SEND_MESSAGE";
export const MESSAGE_ADDED = "MESSAGE_ADDED";
export const UPDATE_VIEWED_CONVO = "UPDATE_VIEWED_CONVO";

let unSubscribeConvoListener;
let unSubscribeMessagesListener;

// ------------------------------

export const updateLastViewed = (convoId) => {
  return async (dispatch, getState) => {
    const userId = getState().auth.userId;
    const token = getState().auth.token;
    const convo = getState().inbox.conversations.find((c) => c.id === convoId);

    if (convo && convo.count > 0) {
      const response = await fetch(
        `https://firestore.googleapis.com/v1/projects/${dbName}/databases/(default)/documents/inbox/${convoId}/viewed/${userId}?updateMask.fieldPaths=date`,
        {
          method: "PATCH",
          headers: {
            "Content-Type": "applications/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            fields: {
              date: {
                timestampValue: firebase.firestore.Timestamp.fromDate(
                  new Date()
                ).toDate(),
              },
            },
          }),
        }
      );

      if (!response.ok) {
        handleErrorResponse(await response.json());
      }

      dispatch({
        type: UPDATE_VIEWED_CONVO,
        conversation: new InboxChat(
          convo.id,
          0,
          convo.last_date,
          convo.last_message,
          convo.last_reply_uid,
          convo.provider_uid,
          convo.provider_name,
          convo.owner_uid,
          convo.owner_name,
          convo.is_mobile_user,
          convo.by_user
        ),
      });
    }
  };
};

// ------------------------------

export const fetchConvoRead = (convoId, lastReadDate) => {
  return async (dispatch, getState) => {
    const userId = getState().auth.userId;
    const token = getState().auth.token;

    const response = await fetch(
      `https://firestore.googleapis.com/v1/projects/${dbName}/databases/(default)/documents/inbox/${convoId}/viewed/${userId}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "applications/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );

    if (!response.ok) {
      const errResponse = await response.json();
      if (errResponse.error.status === "NOT_FOUND") {
        return false;
      } else {
        handleErrorResponse(errResponse);
      }
    }

    const resData = await response.json();

    if (resData && resData.fields) {
      const date = new Date(resData.fields.date.timestampValue);
      return date.getTime() > lastReadDate.getTime();
    }

    return true;
  };
};

// ------------------------------

export const fetchConversations = () => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;
    const userId = getState().auth.userId;
    const isMobileUser = getState().auth.isMobileUser;

    const arrResponse = await fetch(
      `https://firestore.googleapis.com/v1/projects/${dbName}/databases/(default)/documents:runQuery`,
      {
        method: "POST",
        headers: {
          "Content-Type": "applications/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(fetchConvoQuery("inbox", userId, isMobileUser)),
      }
    );

    if (!arrResponse.ok) {
      handleErrorResponse(await arrResponse.json());
    }

    const resData = await arrResponse.json();

    const loaded = [];
    for (const documents of resData) {
      const convo = documents.document;

      if (convo && convo.name) {
        const convoId = firebaseNameToId(convo.name);
        const lastMessageDate = new Date(convo.fields.last_date.timestampValue);

        let isRead = true;

        if (convo.fields.last_reply_uid.stringValue !== userId) {
          isRead = await dispatch(fetchConvoRead(convoId, lastMessageDate));
        }

        loaded.push(
          new InboxChat(
            convoId,
            isRead ? 0 : 1,
            lastMessageDate,
            convo.fields.last_message.stringValue,
            convo.fields.last_reply_uid.stringValue,
            convo.fields.provider_uid.stringValue,
            convo.fields.provider_name.stringValue,
            convo.fields.owner_uid.stringValue,
            convo.fields.owner_name.stringValue,
            isMobileUser,
            convo.fields.by_user.booleanValue
          )
        );
      }
    }

    const sortedConvo = loaded.sort((a, b) =>
      a.last_date.getTime() < b.last_date.getTime() ? 1 : -1
    );

    dispatch({
      type: SET_CONVERSATIONS,
      conversations: sortedConvo,
    });
  };
};

// ------------------------------

const fetchConvoQuery = (collection, uId, isMobileUser, limit) => {
  let structuredQuery = {
    structuredQuery: {
      from: [{ collectionId: collection }],
    },
  };

  const matchUidField = isMobileUser ? "owner_uid" : "provider_uid";

  let condition = {};
  condition = { compositeFilter: { filters: [], op: "AND" } };
  const fieldFilter = stringFieldFilter(matchUidField, "EQUAL", uId);
  condition.compositeFilter.filters.push({ fieldFilter: fieldFilter });

  if (!isMobileUser) {
    const fieldFilter2 = booleanFieldFilter("by_user", "EQUAL", true);
    condition.compositeFilter.filters.push({ fieldFilter: fieldFilter2 });
    const fieldFilter3 = booleanFieldFilter("provider_deleted", "EQUAL", false);
    condition.compositeFilter.filters.push({ fieldFilter: fieldFilter3 });
  } else {
    const fieldFilter3 = booleanFieldFilter("owner_deleted", "EQUAL", false);
    condition.compositeFilter.filters.push({ fieldFilter: fieldFilter3 });
  }

  structuredQuery.structuredQuery.where = condition;

  if (limit) {
    structuredQuery.structuredQuery.limit = limit;
  }

  return structuredQuery;
};

// ------------------------------

export const sendMessage = (
  conversation,
  message,
  providerId,
  providerName
) => {
  return async (dispatch, getState) => {
    const userId = getState().auth.userId;
    const token = getState().auth.token;
    const isMobileUser = getState().auth.isMobileUser;
    let activeConversation = null;

    if (!conversation) {
      const userProfile = getState().profile.userProfile;

      const foundConvo = getState().inbox.conversations.find(
        (c) => c.provider_uid === providerId && c.by_user === true
      );

      if (!foundConvo) {
        const existInDb = await dispatch(findConversation(providerId));

        if (existInDb) {
          activeConversation = existInDb;
          dispatch(startInboxMessagesListener(existInDb.id));
        } else {
          const response = await fetch(
            `https://firestore.googleapis.com/v1/projects/${dbName}/databases/(default)/documents/inbox/`,
            {
              method: "POST",
              headers: {
                "Content-Type": "applications/json",
                Authorization: `Bearer ${token}`,
              },
              body: JSON.stringify({
                fields: {
                  count: { integerValue: 1 },
                  last_date: { timestampValue: new Date() },
                  last_message: { stringValue: message },
                  last_reply_uid: { stringValue: userId },
                  owner_name: { stringValue: userProfile.resolveChatName() },
                  owner_uid: { stringValue: userId },
                  provider_name: { stringValue: providerName },
                  provider_uid: { stringValue: providerId },
                  by_user: { booleanValue: true },
                  owner_deleted: { booleanValue: false },
                  provider_deleted: { booleanValue: false },
                },
              }),
            }
          );

          if (!response.ok) {
            handleErrorResponse(await response.json());
          }

          const resData = await response.json();

          if (resData) {
            const inboxChatObj = new InboxChat(
              firebaseNameToId(resData.name),
              resData.fields.count.integerValue,
              new Date(resData.fields.last_date.timestampValue),
              resData.fields.last_message.stringValue,
              resData.fields.last_reply_uid.stringValue,
              resData.fields.provider_uid.stringValue,
              resData.fields.provider_name.stringValue,
              resData.fields.owner_uid.stringValue,
              resData.fields.owner_name.stringValue,
              isMobileUser,
              resData.fields.by_user.booleanValue
            );

            dispatch({
              type: ADD_CONVERSATION,
              conversation: inboxChatObj,
            });

            activeConversation = inboxChatObj;

            dispatch(startInboxMessagesListener(activeConversation.id));
          }
        }
      } else {
        activeConversation = foundConvo;
      }
    } else {
      activeConversation = conversation;
    }

    const response = await fetch(
      `https://firestore.googleapis.com/v1/projects/${dbName}/databases/(default)/documents/inbox/${activeConversation.id}/message/`,
      {
        method: "POST",
        headers: {
          "Content-Type": "applications/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          fields: {
            uid: { stringValue: userId },
            message: { stringValue: message },
            date: { timestampValue: new Date() },
          },
        }),
      }
    );

    if (!response.ok) {
      handleErrorResponse(await response.json());
    }

    await response.json();

    // message will be automatically read by listener
  };
};

// ------------------------------

export const findConversation = (providerId) => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;
    const userId = getState().auth.userId;
    const isMobileUser = getState().auth.isMobileUser;

    ////////////////////////////
    let structuredQuery = {
      structuredQuery: {
        from: [{ collectionId: "inbox" }],
      },
    };

    const matchUidField1 = isMobileUser ? "owner_uid" : "provider_uid";
    const matchUidField2 = isMobileUser ? "provider_uid" : "owner_uid";

    let condition = {};
    condition = { compositeFilter: { filters: [], op: "AND" } };
    const fieldFilter = stringFieldFilter(matchUidField1, "EQUAL", userId);
    condition.compositeFilter.filters.push({ fieldFilter: fieldFilter });
    const fieldFilter2 = stringFieldFilter(matchUidField2, "EQUAL", providerId);
    condition.compositeFilter.filters.push({ fieldFilter: fieldFilter2 });
    structuredQuery.structuredQuery.where = condition;
    structuredQuery.structuredQuery.limit = 1;
    ////////////////////////////

    const arrResponse = await fetch(
      `https://firestore.googleapis.com/v1/projects/${dbName}/databases/(default)/documents:runQuery`,
      {
        method: "POST",
        headers: {
          "Content-Type": "applications/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(structuredQuery),
      }
    );

    if (!arrResponse.ok) {
      handleErrorResponse(await arrResponse.json());
    }

    const resData = await arrResponse.json();

    for (const documents of resData) {
      const convo = documents.document;
      if (
        convo &&
        convo.name &&
        convo.fields.by_user &&
        convo.fields.by_user.booleanValue === true
      ) {
        return new InboxChat(
          firebaseNameToId(convo.name),
          convo.fields.count.integerValue,
          new Date(convo.fields.last_date.timestampValue),
          convo.fields.last_message.stringValue,
          convo.fields.last_reply_uid.stringValue,
          convo.fields.provider_uid.stringValue,
          convo.fields.provider_name.stringValue,
          convo.fields.owner_uid.stringValue,
          convo.fields.owner_name.stringValue,
          isMobileUser,
          convo.fields.by_user.booleanValue
        );
      }
    }

    return null;
  };
};

// ------------------------------

export const fetchMessages = (conversation) => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;
    const userId = getState().auth.userId;
    const isMobileUser = getState().auth.isMobileUser;

    let orderedMessages = [];

    if (conversation && conversation.id) {
      const arrResponse = await fetch(
        `https://firestore.googleapis.com/v1/projects/${dbName}/databases/(default)/documents/inbox/${conversation.id}:runQuery`,
        {
          method: "POST",
          headers: {
            "Content-Type": "applications/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(fetchMsgQuery("message", 25)),
        }
      );

      if (!arrResponse.ok) {
        handleErrorResponse(await arrResponse.json());
      }

      const resData = await arrResponse.json();

      const loaded = [];
      for (const documents of resData) {
        const message = documents.document;
        if (message && message.name) {
          loaded.push(
            new InboxMessage(
              firebaseNameToId(message.name),
              message.fields.message.stringValue,
              new Date(message.fields.date.timestampValue),
              message.fields.uid.stringValue === conversation.provider_uid
                ? conversation.provider_name
                : conversation.owner_name,
              message.fields.uid.stringValue,
              userId === message.fields.uid.stringValue
            )
          );
        }
      }

      orderedMessages = loaded.sort((a, b) =>
        a.date.getTime() > b.date.getTime() ? 1 : -1
      );

      // not sure why
      const lastMmessageForOrder = orderedMessages[orderedMessages.length - 1];

      if (orderedMessages.length > 0) {
        const inboxChatObj = new InboxChat(
          conversation.id,
          conversation.count,
          lastMmessageForOrder.date,
          lastMmessageForOrder.message,
          lastMmessageForOrder.uid,
          conversation.provider_uid,
          conversation.provider_name,
          conversation.owner_uid,
          conversation.owner_name,
          isMobileUser,
          conversation.by_user
        );

        dispatch({
          type: UPDATE_CONVERSATION,
          conversation: inboxChatObj,
        });
      }

      dispatch(startInboxMessagesListener(conversation.id));
    }

    dispatch({
      type: SET_MESSAGES,
      messages: orderedMessages,
      conversationId: conversation ? conversation.id : null,
    });
  };
};

// ------------------------------

const fetchMsgQuery = (collection, limit) => {
  let structuredQuery = {
    structuredQuery: {
      from: [{ collectionId: collection }],
    },
  };

  structuredQuery.structuredQuery.orderBy = [
    { field: { fieldPath: "date" }, direction: "DESCENDING" },
  ];

  if (limit) {
    structuredQuery.structuredQuery.limit = limit;
  }

  return structuredQuery;
};

// ------------------------------

export const deleteConversations = (conversations) => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;
    const isMobileUser = getState().auth.isMobileUser;
    let userType = null;
    let userDateType = null;
    let saveFields = null;

    if (isMobileUser) {
      userType = "owner_deleted";
      userDateType = "owner_deleted_date";
      saveFields = {
        fields: {
          owner_deleted: { booleanValue: true },
          owner_deleted_date: {
            timestampValue: firebase.firestore.Timestamp.fromDate(
              new Date()
            ).toDate(),
          },
        },
      };
    } else {
      userType = "provider_deleted";
      userDateType = "provider_deleted_date";
      saveFields = {
        fields: {
          provider_deleted: { booleanValue: true },
          provider_deleted_date: {
            timestampValue: firebase.firestore.Timestamp.fromDate(
              new Date()
            ).toDate(),
          },
        },
      };
    }

    if (userType && saveFields) {
      for (const convo of conversations) {
        const response = await fetch(
          `https://firestore.googleapis.com/v1/projects/${dbName}/databases/(default)/documents/inbox/${convo.id}?updateMask.fieldPaths=${userType}&updateMask.fieldPaths=${userDateType}`,
          {
            method: "PATCH",
            headers: {
              "Content-Type": "applications/json",
              Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify(saveFields),
          }
        );

        if (!response.ok) {
          handleErrorResponse(await response.json());
        }
      }

      dispatch({
        type: REMOVE_CONVERSATION,
        conversations: conversations,
      });
    }
  };
};

// ------------------------------

export const startConversationListener = (isMobileUser, uId) => {
  return async (dispatch, getState) => {
    const matchUidField = isMobileUser ? "owner_uid" : "provider_uid";

    const ref = firebase
      .firestore()
      .collection("inbox")
      .where(matchUidField, "==", uId);

    unSubscribeConvoListener = ref.onSnapshot((querySnapshot) => {
      dispatch(fetchConversations());
    });
  };
};

// ------------------------------

export const stopConversationListener = () => {
  if (unSubscribeConvoListener) {
    unSubscribeConvoListener();
  }
};

// ------------------------------

const startInboxMessagesListener = (conversationId) => {
  return async (dispatch, getState) => {
    const userId = getState().auth.userId;

    const ref = firebase
      .firestore()
      .collection("inbox")
      .doc(conversationId)
      .collection("message")
      .orderBy("date", "desc")
      .limit(25);

    unSubscribeMessagesListener = ref.onSnapshot((querySnapshot) => {
      const ref2 = firebase.firestore().collection("inbox").doc(conversationId);

      ref2
        .get()
        .then((convo) => {
          if (convo.exists) {
            const convoData = convo.data();

            querySnapshot.docChanges().forEach((change) => {
              if (change.type === "added") {
                const msgData = change.doc.data();

                const newMessage = new InboxMessage(
                  change.doc.id,
                  msgData.message,
                  new Date(msgData.date.toDate()),
                  msgData.uid === convoData.provider_uid
                    ? convoData.provider_name
                    : convoData.owner_name,
                  msgData.uid,
                  userId === msgData.uid
                );

                dispatch({
                  type: MESSAGE_ADDED,
                  message: newMessage,
                  conversationId: conversationId,
                });
              }
            });
          } else {
            console.log("No such document!");
          }
        })
        .catch((error) => {
          console.log("Error getting document:", error);
        });
    });
  };
};

// ------------------------------

export const stopInboxMessagesListener = () => {
  return async (dispatch, getState) => {
    try {
      unSubscribeMessagesListener();
    } catch (error) {
      logger.error(error);
    }
  };
};

// ------------------------------
