import { handleErrorResponse } from "../../globals/Error";
import { dbName } from "../../globals/ApiKeys";
import Survey from "../../model/Survey";
import FireStoreParser from "firestore-parser";
import * as lodashCloneDeep from "lodash.clonedeep";

export const SET_SURVEY = "SET_SURVEY";
export const SET_SURVEY_ID = "SET_SURVEY_ID";
export const ADD_SURVEY = "ADD_SURVEY";
export const REMOVE_SURVEY = "REMOVE_SURVEY";
export const UPDATE_SURVEY = "UPDATE_SURVEY";
export const OPEN_SURVEY = "OPEN_SURVEY";
export const SET_SURVEY_RESULTS = "SET_SURVEY_RESULTS";
export const UPDATE_SURVEY_EXPIRATION = "UPDATE_SURVEY_EXPIRATION";

// ------------------------------

export const setAddAction = () => {
  return {
    type: SET_SURVEY_ID,
    surveyId: "new",
  };
};

// ------------------------------

export const setEditAction = (id) => {
  return {
    type: SET_SURVEY_ID,
    surveyId: id,
  };
};

// ------------------------------

export const fetchSurvey = () => {
  return async (dispatch, getState) => {
    const userId = getState().auth.userId;
    const token = getState().auth.token;

    const response = await fetch(
      `https://firestore.googleapis.com/v1/projects/${dbName}/databases/(default)/documents/surveys/${userId}/survey`,
      {
        method: "GET",
        headers: {
          "Content-Type": "applications/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );

    if (!response.ok) {
      handleErrorResponse(await response.json());
    }

    const resData = await response.json();

    let arrDocs = [];
    if (Array.isArray(resData)) {
      arrDocs = resData;
    } else {
      arrDocs = resData.documents;
    }

    const loadedSurvey = [];

    if (arrDocs) {
      for (const survey of arrDocs) {
        if (survey && survey.name) {
          const surveyNameArray = survey.name.split("/");
          const surveyId = surveyNameArray[surveyNameArray.length - 1];
          loadedSurvey.push(
            new Survey(
              surveyId,
              survey.fields.title.stringValue,
              survey.fields.text.stringValue,
              survey.fields.expiration_days
                ? survey.fields.expiration_days.integerValue
                : 0
            )
          );
        }
      }
    }

    dispatch({
      type: SET_SURVEY,
      survey: loadedSurvey,
    });
  };
};

// ------------------------------

export const addSurvey = (jsonText) => {
  return async (dispatch, getState) => {
    const userId = getState().auth.userId;
    const token = getState().auth.token;
    const title = JSON.parse(jsonText).title;

    const response = await fetch(
      `https://firestore.googleapis.com/v1/projects/${dbName}/databases/(default)/documents/surveys/${userId}/survey/`,
      {
        method: "POST",
        headers: {
          "Content-Type": "applications/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          fields: {
            title: { stringValue: title },
            text: { stringValue: jsonText },
          },
        }),
      }
    );

    if (!response.ok) {
      handleErrorResponse(await response.json());
    }

    const resData = await response.json();
    const idPathName = resData.name.split("/");
    const surveyId = idPathName[idPathName.length - 1];

    dispatch({
      type: ADD_SURVEY,
      survey: { id: surveyId, title: title, text: jsonText },
    });
  };
};

// ------------------------------

export const updateSurvey = (surveyId, jsonText) => {
  return async (dispatch, getState) => {
    const userId = getState().auth.userId;
    const token = getState().auth.token;
    const title = JSON.parse(jsonText).title;

    await fetch(
      `https://firestore.googleapis.com/v1/projects/${dbName}/databases/(default)/documents/surveys/${userId}/survey/${surveyId}` +
      `?updateMask.fieldPaths=title&updateMask.fieldPaths=text`,
      {
        method: "PATCH",
        headers: {
          "Content-Type": "applications/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          fields: {
            title: { stringValue: title },
            text: { stringValue: jsonText },
          },
        }),
      }
    );

    dispatch({
      type: UPDATE_SURVEY,
      survey: { id: surveyId, title: title, text: jsonText },
    });
  };
};

// ------------------------------

export const removeSurvey = (surveyId) => {
  return async (dispatch, getState) => {
    const userId = getState().auth.userId;
    const token = getState().auth.token;

    await fetch(
      `https://firestore.googleapis.com/v1/projects/${dbName}/databases/(default)/documents/surveys/${userId}/survey/${surveyId}`,
      {
        method: "DELETE",
        headers: {
          "Content-Type": "applications/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );

    dispatch({
      type: REMOVE_SURVEY,
      id: surveyId,
    });
  };
};

// ------------------------------

export const openSurvey = (surveyToken) => {
  return async (dispatch, getState) => {
    dispatch({
      type: OPEN_SURVEY,
      openSurvey: null,
    });

    const response = await fetch(
      `https://us-central1-${dbName}.cloudfunctions.net/openSurvey`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          data: { code: surveyToken },
        }),
      }
    );

    if (!response.ok) {
      handleErrorResponse(await response.json());
    }

    const resData = await response.json();

    const jsonSurvey =
      resData && resData.result && resData.result[2]
        ? JSON.parse(resData.result[2])
        : {};

    dispatch({
      type: OPEN_SURVEY,
      openSurvey: jsonSurvey,
    });
  };
};

// ------------------------------

export const saveSurveyResults = (surveyToken, results) => {
  return async (dispatch, getState) => {
    const response = await fetch(
      `https://us-central1-${dbName}.cloudfunctions.net/saveSurveyResults`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          data: { code: surveyToken, results: results },
        }),
      }
    );

    if (!response.ok) {
      handleErrorResponse(await response.json());
    }
  };
};

// ------------------------------

export const fetchSurveyResults = (courseId) => {
  return async (dispatch, getState) => {
    const userId = getState().auth.userId;
    const token = getState().auth.token;
    const survey = getState().courses.selected_survey;

    const arrResponse = await fetch(
      `https://firestore.googleapis.com/v1/projects/${dbName}/databases/(default)/documents/registration_content/${userId}/course/${courseId}:runQuery`,
      {
        method: "POST",
        headers: {
          "Content-Type": "applications/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          structuredQuery: {
            from: [{ collectionId: "survey_result" }],
          },
        }),
      }
    );

    if (!arrResponse.ok) {
      handleErrorResponse(await arrResponse.json());
    }

    const resData = await arrResponse.json();

    const loadedResults = [];
    if (resData) {
      const resultJson = FireStoreParser(resData);

      for (const docData of resultJson) {
        const surveyResult = docData.document;

        if (
          surveyResult &&
          surveyResult.name &&
          surveyResult.fields &&
          surveyResult.fields.result
        ) {
          if (survey && survey.text) {
            const jsonParse = JSON.parse(survey.text);
            const copySurvey = replaceQuestionNo(
              surveyResult.fields.result,
              jsonParse
            );
            loadedResults.push(copySurvey);
          } else {
            loadedResults.push(surveyResult.fields.result);
          }
        }
      }
    }

    dispatch({
      type: SET_SURVEY_RESULTS,
      survey_results:
        loadedResults.length > 0
          ? loadedResults
          : [["No surveys have been completed for this course"]],
    });
  };
};

// ------------------------------

const replaceQuestionNo = (result, survey) => {
  const copyResult = lodashCloneDeep(result);
  const copyResult2 = {};

  if (survey && survey.pages && survey.pages[0] && survey.pages[0].elements) {
    for (const element of survey.pages[0].elements) {
      if (element.choices) {
        if (copyResult[element.name]) {
          const multiChoiceVal = [];

          if (copyResult[element.name] && Array.isArray(copyResult[element.name])) {
            for (const choiceAnswered of copyResult[element.name]) {
              const matchChoice = element.choices.find((c) => c.value === choiceAnswered);
              if (matchChoice) {
                multiChoiceVal.push(matchChoice.text);
              }
            }
          }
          else {
            const matchChoice = element.choices.find((c) => c.value === copyResult[element.name]);
            if (matchChoice) {
              multiChoiceVal.push(matchChoice.text);
            }
          }

          if (element.title && element.name === element.title) {
            copyResult2[element.name] = multiChoiceVal.join(", ");
          } else if (element.title && element.name !== element.title) {
            copyResult2[element.title] = multiChoiceVal.join(", ");
          } else {
            copyResult2[element.name] = multiChoiceVal.join(", ");
          }
        }
      }
      else if (element.type === "boolean") {
        copyResult2[element.title ? element.title : element.name] = copyResult[element.name] ? "Yes" : "No";
      }
      else if (copyResult[element.name] && element.title) {
        copyResult2[element.title] = copyResult[element.name];
      }
      else {
        copyResult2[element.name] = copyResult[element.name];
      }
    }
  }

  return copyResult2;
};



// ------------------------------

export const setSurveyExpiration = (surveyId, expiration) => {
  return async (dispatch, getState) => {
    const userId = getState().auth.userId;
    const token = getState().auth.token;

    await fetch(
      `https://firestore.googleapis.com/v1/projects/${dbName}/databases/(default)/documents/surveys/${userId}/survey/${surveyId}` +
      `?updateMask.fieldPaths=expiration_days`,
      {
        method: "PATCH",
        headers: {
          "Content-Type": "applications/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          fields: {
            expiration_days: { integerValue: Number(expiration) },
          },
        }),
      }
    );

    dispatch({
      type: UPDATE_SURVEY_EXPIRATION,
      survey: { id: surveyId, expiration_days: expiration },
    });
  };
};
