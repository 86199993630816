import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "center",
    margin: 20,
    width: "100%",
    "& > *": {
      margin: theme.spacing(0),
    },
  },
}));

const MatInput = (props) => {
  const classes = useStyles();
  return (
    <div className={classes.root} noValidate autoComplete="off">
      <TextField {...props} label={props.title} style={{ width: "90%" }} />
      <label>{props.errortext}</label>
    </div>
  );
};

export default MatInput;
