import React from "react";
import styles from "./Elements.module.css";
import Avatar from "react-avatar";
import { momentShortDateTime } from "../../../../globals/Dates2";

const Message = (props) => {
  return (
    <div
      className={
        props.message.isOwner
          ? styles.messageContainerRight
          : styles.messageContainerLeft
      }
    >
      <div
        className={props.message.isOwner ? styles.rightBuble : styles.leftBuble}
      >
        <div className={styles.messageAvatarContainer}>
          <Avatar name={props.message.name} size="40px" round={true} />

          <div className={styles.messageContentContainer}>
            <div className={styles.messageUserNameDateContainer}>
              <div className={styles.messageUserName}>{props.message.name}</div>
              <div
                className={
                  props.message.isOwner
                    ? styles.rightMessageDate
                    : styles.leftMessageDate
                }
              >
                {momentShortDateTime(props.message.date)}
              </div>
            </div>
            <div
              className={
                props.message.isOwner
                  ? styles.rightMessageText
                  : styles.leftMessageText
              }
            >
              {props.message.message}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Message;
