import Currency from "../model/Currency";
import Category from "../model/Category";
import Language from "../model/Language";
import Country from "../model/Country";
import Chart from "../model/Chart";
import Province from "../model/Province";

// ------------------------------
export const EXPIRED = "Expired";
export const CANCELLED = "Cancelled";

export const FEATURE_COST = [40, 80, 120, 160];
export const ADD_COST = [125, 250, 375, 500];
export const PRIME_COST = 45;

// ------------------------------

export const PROVIDER_CATEGORY = "provider";
export const SPECIALIST_CATEGORY = "sepcialist";
export const LAST_MINUTE_CATEGORY = "last";
export const FREE_CATEGORY = "free";
export const PROFESSOR_CATEGORY = "Professor";
export const COUNTRY_CATEGORY = "Country";
export const ONLINE_CATEGORY = "Online";
export const LIVESTREAM_CATEGORY = "Livestream";

const TOPCOLOR = "#3e8cab";

export const CATEGORIES_TOP = [
  new Category(PROVIDER_CATEGORY, "Providers", "SchoolRoundedIcon", TOPCOLOR),
  new Category(
    LIVESTREAM_CATEGORY,
    "Livestream",
    "ComputerRoundedIcon",
    TOPCOLOR
  ),
  new Category("Inperson", "In Person", "PinDropIcon", TOPCOLOR),
  new Category(ONLINE_CATEGORY, "Online", "YouTubeIcon", TOPCOLOR),
];

export const CATEGORIES = [
  new Category("management", "Clinic Mgmt", "HomeWorkRoundedIcon", "#88B04B"),
  new Category("endo", "Endo", "svgneedle", "#f59203"),
  new Category("implant", "Implant", "svgalpha-i-circle-outline", "#e7a595"),
  new Category("hygiene", "Hygiene", "svgtoothbrush-paste", "#A77C99"),
  new Category("patho", "Pathology", "svgheart-pulse", "#6ab28a"),
  new Category("pedo", "Pedo", "svghuman-male-child", "#f5d142"),
  new Category("perio", "Perio", "svgblood-bag", "#368dff"),
  new Category("pharma", "Pharma", "svgpill", "#ffe3bd"),
  new Category("ortho", "Ortho", "svgtooth-outline", "#eb6841"),
  new Category("resto / prostho", "Resto", "svgmedical-bag", "#d5b073"),
  new Category("surgery", "Surgery", "svgdoctor", "#6e3abd"),
  new Category("tmj", "TMJ", "svgalpha-t-circle-outline", "#B7950B"),
  new Category(FREE_CATEGORY, "Free", "svggift-outline", "#c7ebdb"),
  new Category("other", "Other", "svgbook-open-outline", "#626867"),
];

export const MONTHS_SHORT = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

// ------------------------------

export const PROVINCE = [
  new Province("AB", "Alberta"),
  new Province("BC", "British Columbia"),
  new Province("QC", "Quebec"),
  new Province("MB", "Manitoba"),
  new Province("ON", "Ontario"),
  new Province("PE", "Prince Edward Island"),
  new Province("SK", "Saskatchewan"),
  new Province("NB", "New Brunswick"),
  new Province("NL", "Newfoundland and Labrador"),
  new Province("NS", "Nova Scotia"),
  new Province("NT", "Northwest Territories"),
  new Province("NU", "Nunavut"),
  new Province("YT", "Yukon"),
];

// ------------------------------

export const GST_5_PROVINCE = [
  new Province("MB", "Manitoba"),
  new Province("SK", "Saskatchewan"),
  new Province("AB", "Alberta"),
  new Province("BC", "British Columbia"),
  new Province("YT", "Yukon"),
  new Province("NT", "Northwest Territories"),
  new Province("NU", "Nunavut"),
];

// ------------------------------

export const HST_15_PROVINCE = [
  new Province("NL", "Newfoundland and Labrador"),
  new Province("PE", "Prince Edward Island"),
  new Province("NS", "Nova Scotia"),
  new Province("NB", "New Brunswick"),
];

// ------------------------------

export const CHARTS = [
  new Chart("course_revenu", "Monthly Earnings", "", true),
  new Chart("reg_by_occupation", "Registrations by occupation", ""),
  new Chart("views_vs_reg", "Level of success", ""),
  new Chart("experience_profile", "Registrants experience profile", ""),
  new Chart("reg_by_category", "Registrations by category", ""),
  new Chart("online_vs_classroom", "Registrations online vs classroom", ""),
  new Chart("reg_by_professor", "Registrations by professor", ""),
  new Chart("reg_by_gender", "Registrations by gender", "", true),
  new Chart("reg_by_age_group", "Registrations by age group", "", true),
  new Chart("reg_by_location", "Registrations by location", ""),
  new Chart("reg_by_language", "Registrations by language", "", true),
];

// ------------------------------

export const CURRENCY = [
  new Currency("CAD", "Canadian Dollar", "$", "CA"),
  new Currency("USD", "US Dollar", "US $", "US"),
  new Currency("EUR", "Euro", "€", "EU"),
  new Currency("AUD", "Australian Dollar", "A $", "AU"),
  new Currency("GBP", "British Pound", "£", "GB"),
  //new Currency("INR", "Indian Rupee", "₹", "IN"),
];

// ------------------------------

export const LANGUAGES = [
  new Language("en", "English", "en-ca", "en"),
  new Language("fr", "Français", "fr-ca", "fr"),
];

// ------------------------------

export const COUNTRY = [
  new Country("Canada", "CA", CURRENCY[0]),
  new Country("United States of America", "US", CURRENCY[1]),
  new Country("Australia", "AU", CURRENCY[3]),
  new Country("United Kingdom", "GB", CURRENCY[4]),
  new Country("Belgium", "BE", CURRENCY[2]),
  new Country("Cyprus", "CY", CURRENCY[2]),
  new Country("Estonia", "EE", CURRENCY[2]),
  new Country("Finland", "FI", CURRENCY[2]),
  new Country("France", "FR", CURRENCY[2]),
  new Country("Germany", "DE", CURRENCY[2]),
  new Country("Greece", "GR", CURRENCY[2]),
  new Country("Ireland", "IE", CURRENCY[2]),
  new Country("Italy", "IT", CURRENCY[2]),
  new Country("Latvia", "LV", CURRENCY[2]),
  new Country("Lithuania", "LT", CURRENCY[2]),
  new Country("Slovakia", "SK", CURRENCY[2]),
  new Country("Slovenia", "SI", CURRENCY[2]),
  new Country("Spain", "ES", CURRENCY[2]),
];

// ------------------------------

export const selectPrefix = [
  { value: "Dr.", label: "Dr." },
  { value: "Mr.", label: "Mr." },
  { value: "Ms.", label: "Ms." },
];

// ------------------------------

export const selectCountry = [
  { value: "Canada", label: "Canada" },
  { value: "United States of America", label: "United States of America" },
  { value: "Australia", label: "Australia" },
  { value: "United Kingdom", label: "United Kingdom" },
  { value: "Belgium", label: "Belgium" },
  { value: "Cyprus ", label: "Cyprus" },
  { value: "Estonia", label: "Estonia" },
  { value: "Finland", label: "Finland" },
  { value: "France", label: "France" },
  { value: "Germany", label: "Germany" },
  { value: "Greece", label: "Greece" },
  { value: "Ireland", label: "Ireland" },
  { value: "Italy", label: "Italy" },
  { value: "Latvia", label: "Latvia" },
  { value: "Lithuania", label: "Lithuania" },
  { value: "Slovakia", label: "Slovakia" },
  { value: "Slovenia", label: "Slovenia" },
  { value: "Spain", label: "Spain" },
];

// ------------------------------

export const selectCurrency = [
  { value: "CAD", label: "Canadian Dollar" },
  { value: "USD", label: "US Dollar" },
  { value: "EUR", label: "Euro" },
  { value: "AUD", label: "Australian Dollar" },
  { value: "GBP", label: "British Pound" }
];

// ------------------------------

export const selectRefundAction = [
  {
    value: "I wish to unregister my selected students",
    label: "I wish to unregister my selected students",
  },
  {
    value: "I wish to unregister my selected students and issue a refund",
    label: "I wish to unregister my selected students and issue a refund",
  },
];

// ------------------------------

export const selectReason = [
  { value: "Cancellation", label: "Cancellation" },
  { value: "Duplicate", label: "Duplicate" },
  { value: "Fraudulent", label: "Fraudulent" },
  { value: "Requested by customer", label: "Requested by customer" },
  { value: "Other", label: "Other" },
];

// ------------------------------

export const selectAccreditation = [
  { value: "N/A", label: "N/A" },
  {
    value: "ADA-CERP",
    label: "ADA-CERP",
  },
  {
    value: "Quebec - ODQ Preferred Provider",
    label: "Quebec ODQ Preferred Provider",
  },
  { value: "AGD PACE", label: "AGD PACE" },
  { value: "Ontario - Core 1", label: "Ontario - Core 1" },
  { value: "Ontario - Core 2", label: "Ontario - Core 2" },
  { value: "Ontario - Core 3", label: "Ontario - Core 3" },
];

// ------------------------------

export const selectOccupation = [
  { value: "Endodontist", label: "Endodontist" },
  { value: "General Dentist", label: "General Dentist" },
  { value: "Hygienist", label: "Hygienist" },
  {
    value: "Oral and Maxillofacial Surgeon",
    label: "Oral and Maxillofacial Surgeon",
  },
  { value: "Oral Radiologist", label: "Oral Radiologist" },
  { value: "Orthodontist", label: "Orthodontist" },
  { value: "Oral Medicine Pathology", label: "Oral Medicine Pathology" },
  { value: "Pediatric Dentist", label: "Pediatric Dentist" },
  { value: "Periodontist", label: "Periodontist" },
  { value: "Prosthodontist", label: "Prosthodontist" },
  { value: "Dental Public Health", label: "Dental Public Health" },
];

// ------------------------------

export const selectSignupOccupation = [
  { value: "Assistant", label: "Assistant" },
  { value: "Clinic Manager", label: "Clinic Manager" },

  { value: "Dental Public Health", label: "Dental Public Health" },
  { value: "Denturologist", label: "Denturologist" },
  { value: "Student", label: "Student" },

  { value: "Endodontist", label: "Endodontist" },
  { value: "General Dentist", label: "General Dentist" },
  { value: "Hygienist", label: "Hygienist" },

  { value: "Lab Technician", label: "Lab Technician" },
  {
    value: "Oral and Maxillofacial Surgeon",
    label: "Oral and Maxillofacial Surgeon",
  },
  { value: "Oral Radiologist", label: "Oral Radiologist" },
  { value: "Orthodontist", label: "Orthodontist" },
  { value: "Oral Medicine Pathology", label: "Oral Medicine Pathology" },

  { value: "Other", label: "Other" },

  { value: "Pediatric Dentist", label: "Pediatric Dentist" },
  { value: "Periodontist", label: "Periodontist" },
  { value: "Prosthodontist", label: "Prosthodontist" },

  { value: "Receptionist", label: "Receptionist" },
];

// ------------------------------

export const selectExperience = [
  { value: "< 5 yrs", label: "< 5 yrs" },
  { value: "5-10 yrs", label: "5-10 yrs" },
  { value: "10-15 yrs", label: "10-15 yrs" },
  { value: "15-20 yrs", label: "15-20 yrs" },
  { value: "> 20 yrs", label: "> 20 yrs" },
];

export const selectCategories = [
  { value: "management", label: "Clinic Management" },
  { value: "endo", label: "Endodontics" },
  { value: "hygiene", label: "Hygiene" },
  { value: "implant", label: "Implant" },
  { value: "ortho", label: "Orthodontics" },
  { value: "patho", label: "Pathology" },
  { value: "pedo", label: "Pediatrics" },
  { value: "perio", label: "Periodontics" },
  { value: "pharma", label: "Pharmacology" },
  { value: "resto / prostho", label: "Restorative" },
  { value: "surgery", label: "Surgery" },
  { value: "tmj", label: "TMJ" },
  { value: "other", label: "Other" },
];

// ------------------------------

export const selectSpecialist = [
  { value: "Endodontist", label: "Endodontist" },
  {
    value: "Oral and Maxillofacial Surgeon",
    label: "Oral and Maxillofacial Surgeon",
  },
  { value: "Oral Radiologist", label: "Oral Radiologist" },
  { value: "Orthodontist", label: "Orthodontist" },
  { value: "Oral Medicine / Pathology", label: "Oral Medicine / Pathology" },
  { value: "Pediatric Dentist", label: "Pediatric Dentist" },
  { value: "Periodontist", label: "Periodontist" },
  { value: "Prosthodontist", label: "Prosthodontist" },
  { value: "Dental Public Health", label: "Dental Public Health" },
];

// ------------------------------

export const selectAudience = [
  { value: "Dentist", label: "Dentist" },
  { value: "Hygienist", label: "Hygienist" },
  { value: "Others", label: "Others" },
];

// ------------------------------

const initSpecialistAudience = () => {
  let arr = [{ value: "Specialist", label: "Specialist" }];
  return arr.concat(selectAudience);
};

export const selectSpecialistAudience = initSpecialistAudience();

// ------------------------------

export const selectComponent = [
  { value: "Lecture Only", label: "Lecture Only" },
  { value: "Lecture and Hands-on", label: "Lecture and Hands-on" },
];

// ------------------------------

const initHours = () => {
  let selectHours = [];
  for (var t = 0; t < 400.5; t = t + 0.5) {
    selectHours.push({ value: t + "", label: t + "" });
  }
  return selectHours;
};

export const selectHours = initHours();

// ------------------------------

const initOdq = () => {
  let selectOdq = [{ value: "-1", label: "N/A" }];
  for (var t = 0; t < 400.5; t = t + 0.5) {
    selectOdq.push({ value: t + "", label: t + "" });
  }
  return selectOdq;
};

export const selectOdq = initOdq();

// ------------------------------

export const initCapacitySeats = (min) => {
  let selectSeats = [];
  let i = -1;
  if (min || min === 0) {
    for (i = min; i < 101; i++) {
      selectSeats.push({ value: i + "", label: i + "" });
    }
  }

  return selectSeats;
};

// ------------------------------

const initNumberForSelect = (min, exclude, inter, max) => {
  const interval = inter ? inter : 1;

  let selectSeats = [];

  let i = -1;
  if (min) {
    for (i = min; i < max; i = i + interval) {
      if (i !== exclude) {
        selectSeats.push({ value: i + "", label: i + "" });
      }
    }
  } else {
    let selectSeats = [{ value: "-1", label: "N/A" }];
    for (i = 0; i < max; i++) {
      selectSeats.push({ value: i + "", label: i + "" });
    }
  }

  return selectSeats;
};

export const initInstallmentPercentLimit = (min, exclude, inter) => {
  return initNumberForSelect(min, exclude, inter, 96);
};

// ------------------------------

export const initInstallmentLimit = () => {
  return initNumberForSelect(1, null, 1, 4);
};

// ------------------------------

export const initPromoLimit = (min, exclude, inter) => {
  return initNumberForSelect(min, exclude, inter, 501);
};

// ------------------------------

export const initSeats = (min, exclude, inter) => {
  return initNumberForSelect(min, exclude, inter, 101);
};

// ------------------------------

const initInternalSeats = (online, excludeNa) => {
  let selectSeats = [];

  if (!excludeNa) {
    selectSeats.push({ value: "-1", label: "N/A" });
  }

  if (online) {
    selectSeats.push({ value: "-2", label: "Unlimited" });
  }

  for (let i = 1; i < 1001; i++) {
    selectSeats.push({ value: i + "", label: i + "" });
  }
  return selectSeats;
};

// ------------------------------

export const selectSeatsOnline = initInternalSeats(true, false);
export const selectSeatsClassroom = initInternalSeats(false, false);
export const selectSeatsVideo = initInternalSeats(true, false);

// ------------------------------

export const selectLang = [
  { value: "English", label: "English" },
  { value: "French", label: "French" },
];

// ------------------------------

export const selectFilterCity = [
  { value: "Montreal", label: "Montreal, Canada" },
  { value: "Vancouver", label: "Vancouver, Canada" },
  { value: "Surrey", label: "Surrey, Canada" },
];

export const selectFilterPrice = [
  { value: "free", label: "Free" },
  { value: "paid", label: "Paid" },
];

// ------------------------------

export const LIVESTREAM = "Livestream";
export const CLASSROOM = "Classroom";
export const ONLINE_VIDEO = "Online Video";
export const LECTURE_ONLY = "Lecture Only";
export const HANDSON = "Lecture and Hands-on";

// ------------------------------

export const selectCasting = [
  { value: "Livestream", label: "Livestream" },
  { value: "Classroom", label: "Classroom" },
];

// ------------------------------

export const selectCastingVideo = [
  { value: "Online Video", label: "Online Video" },
];

// ------------------------------

export const selectFeatureWeekCost = [
  { value: 1, label: "1 week" },
  { value: 2, label: "2 week" },
  { value: 3, label: "3 week" },
  { value: 4, label: "4 week" },
];

// ------------------------------

export const selectAddWeekCost = [
  { value: 1, label: "1 week" },
  { value: 2, label: "2 week" },
  { value: 3, label: "3 week" },
  { value: 4, label: "4 week" },
];

// ------------------------------

export const selectFeaturedType = [
  { value: "featured", label: "Featured" },
  { value: "newly", label: "Newly Added" },
];

// ------------------------------

export const COLORS = [
  "#4E549F",
  "#A23E1C",
  "#6C9765",
  "#CB502C",
  "#82E0AA",
  "#800000",
  "#9B59B6",
  "#717D7E",
  "#ffe577",
  "#00FFFF",
  "#45B39D",
  "#95A5A6",
  "#C39BD3",
  "orange",
  "#D68910",
  "#B7950B",
  "#98AF39",
  "#0E6655",
  "#1F618D",
];

// ------------------------------

export const SPECIALIST = [
  "Dental Public Health",
  "Endodontist",
  "Oral and Maxillofacial Surgeon",
  "Oral Medicine Pathology",
  "Oral Radiologist",
  "Orthodontist",
  "Pediatric Dentist",
  "Periodontist",
  "Prosthodontist",
];

export const OCCUPATION_DENTIST = [
  "General Dentist",
  "Endodontist",
  "Oral and Maxillofacial Surgeon",
  "Oral Radiologist",
  "Orthodontist",
  "Oral Medicine Pathology",
  "Pediatric Dentist",
  "Periodontist",
  "Prosthodontist",
  "Dental Public Health",
];

export const OCCUPATION_DENTIST_ONLY = ["General Dentist"];

export const OCCUPATION_HYGIENIST = ["Hygienist"];

export const OCCUPATION_OTHER = [
  "Assistant",
  "Lab Technician",
  "Receptionist",
  "Clinic Manager",
  "Denturologist",
  "Other",
  "Student",
];

export const OCCUPATION_SIGNUP = [
  ...OCCUPATION_DENTIST,
  ...OCCUPATION_HYGIENIST,
  ...OCCUPATION_OTHER, // added to allow all signups
];

export const OCCUPATION_ALL = [
  ...OCCUPATION_DENTIST,
  ...OCCUPATION_HYGIENIST,
  ...OCCUPATION_OTHER,
];

export const ACCREDITATION = [
  "ADA-CERP",
  "AGD PACE",
  "Quebec - ODQ Preferred Provider",
  "Ontario - Core 1",
  "Ontario - Core 2",
  "Ontario - Core 3",
];
