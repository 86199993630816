import {
  SET_COURSE_REDIRECT,
  SET_PROFILE_TO_COURSE_REDIRECT,
  SET_AUTO_SELECT_PAYMENT_METHOD,
} from "../actions/redirect";
import { LOGOUT } from "../actions/auth";

const initialState = {
  courseDetailsRedirect: false,
  profileToCourseRedirect: false,
  autoSelectPaymentMethod: false,
};

const redirectReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_COURSE_REDIRECT:
      return {
        ...state,
        courseDetailsRedirect: action.redirect,
      };
    case SET_PROFILE_TO_COURSE_REDIRECT:
      return {
        ...state,
        profileToCourseRedirect: action.redirect,
      };
    case SET_AUTO_SELECT_PAYMENT_METHOD:
      return {
        ...state,
        autoSelectPaymentMethod: action.autoSelect,
      };
    case LOGOUT:
      return initialState;
    default:
      return state;
  }
};

export default redirectReducer;
