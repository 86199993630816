import {
  SPECIALIST,
  OCCUPATION_DENTIST_ONLY,
  OCCUPATION_HYGIENIST,
  OCCUPATION_OTHER,
} from "../globals/Data";
import { valueInCollection } from "../globals/Common";

class StaffMember {
  constructor(
    id,
    license,
    occupation,
    last,
    first,
    email,
    owner,
    promoDiscount
  ) {
    this.id = id;
    this.license = license ? license : "";
    this.occupation = occupation ? occupation : "";
    this.last = last ? last : "";
    this.first = first ? first : "";
    this.email = email ? email : "";
    this.accountOwner = owner ? owner : false;
    this.promoDiscount = promoDiscount ? promoDiscount : 0;
  }

  doctorPrefix = () => {
    return this.isDentist() || this.isSpecialist() ? "Dr. " : "";
  };

  prefix = () => {
    return isDentist();
  };

  isSpecialist = () => {
    return valueInCollection(SPECIALIST, this.occupation);
  };

  isDentist = () => {
    return valueInCollection(OCCUPATION_DENTIST_ONLY, this.occupation);
  };

  isHygienist = () => {
    return valueInCollection(OCCUPATION_HYGIENIST, this.occupation);
  };

  isOther = () => {
    return valueInCollection(OCCUPATION_OTHER, this.occupation);
  };

  occupationMatch = (audience) => {
    if (this.isSpecialist()) {
      return (
        audience.indexOf("Specialist") > -1 || audience.indexOf("Dentist") > -1
      );
    }

    if (this.isDentist()) {
      return audience.indexOf("Dentist") > -1;
    }

    if (this.isHygienist()) {
      return audience.indexOf("Hygienist") > -1;
    }

    if (this.isOther()) {
      return audience.indexOf("Others") > -1;
    }

    return false;
  };
}

export default StaffMember;
