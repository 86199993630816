import React, { Component } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import styles from "./ProviderCourseScreen.module.css";
import * as providerActions from "../../../store/actions/provider";
import * as settingActions from "../../../store/actions/setting";
import * as rateActions from "../../../store/actions/rate";
import Spinner from "../../../components/ui/spinner/Spinner";
import * as logger from "../../../globals/Logger";
import DefaultToolbar from "../../welcome/DefaultToolbar";
import Footer from "../../../components/ui/footer/Footer";
import InfoModal from "../../../components/modals/infoModal/InfoModal";
import CourseViewModal from "../../../components/modals/courseViewModal/CourseViewModal";
import Empty from "../../../components/ui/empty/Empty";
import CourseItem from "../../../components/items/courseSearchItem/CourseItem";
import ProviderItem from "../../../components/items/providerItem/ProviderItem";
import coursesImg from "../../../assets/images/inApp/courses.png";
import { zubuUrl } from "../../../globals/ApiKeys";
import { CURRENCY } from "../../../globals/Data";
import { resolveCurrencySymbol, resolveCurrency, objectHasNoValues, isInIFrame } from "../../../globals/Common";
import { setLanguage } from "../../../globals/Common";


class ProviderCourseScreen extends Component {
  state = {
    wait: false,
    error: null,
    viewCourse: null,
  };

  // ------------------------------

  update = async (propValue) => {
    await this.setState({ ...this.state, ...propValue });
  };

  // ------------------------------

  componentDidMount = async () => {
    try {
      await this.update({ wait: true });

      if (objectHasNoValues(this.props.mobileSetting)) {
        await this.props.fetchMobileSetting();
      }

      const loadAsync = [];
      loadAsync.push(this.props.fetchRating(this.props.providerId));
      loadAsync.push(this.props.fetchProviderCourses(this.props.providerId));
      loadAsync.push(this.props.fetchProvider(this.props.providerId));

      if (objectHasNoValues(this.props.rates)) {
        loadAsync.push(this.props.fetchRates());
      }

      await Promise.all(loadAsync);
      await this.update({ wait: false });
      window.scrollTo(0, 0);
    } catch (err) {
      logger.error(err);
      this.update({ wait: false, error: err.message });
    }
  };

  // ------------------------------

  goBackHandler = async () => {
    this.props.history.goBack();
  };

  // ------------------------------

  contactHandler = () => {
    window.open(`mailto:${this.props.provider.contact_email}`, "_blank");
  };

  // ------------------------------

  registerHandler = (qr) => {
    this.props.history.push(qr);
  };

  // ------------------------------

  courseDetailsHandler = (course) => {
    if (isInIFrame()) {
      this.update({ viewCourse: course });
    } else {
      this.props.setSelectedProviderCourse(course);
      this.props.history.push(`/details/${course.provider_id}/${course.id}`);
    }
  };

  // ------------------------------

  updateMobileCurrency = async (currency, language) => {
    try {
      await this.props.updateCurrency(currency, language);
      setLanguage(language, this.props.i18n);
    } catch (error) {
      logger.error(error);
    }
  };

  // ------------------------------

  render() {
    if (this.state.wait) {
      return <Spinner />;
    }

    return (
      <div
        className={styles.screen}
        style={this.props.params.hideHeader ? { marginTop: "0px" } : null}
      >
        <InfoModal
          isErrorModal={true}
          title="An error has occured"
          message={this.state.error}
          open={this.state.error !== null}
          onClose={() => {
            this.update({ error: null });
          }}
        />
        {this.state.viewCourse && (
          <CourseViewModal
            noPhoneBorder
            provider={this.props.provider}
            currencyObject={this.props.currencyObject}
            currency={this.props.mobileSetting.currency}
            course={this.state.viewCourse}
            onRegister={() => {
              this.update({ viewCourse: null });

              if (this.props.isAuthenticated) {
                window.open(
                  `${zubuUrl}/details?p=${this.props.provider.id}&c=${this.state.viewCourse.id}`,
                  "_blank"
                );
              } else {
                window.open(
                  `${zubuUrl}/login?p=${this.props.provider.id}&c=${this.state.viewCourse.id}`,
                  "_blank"
                );
              }
            }}
            onClose={() => {
              this.update({ viewCourse: null });
            }}
          />
        )}

        {this.props.isAuthenticated ? null : this.props.params
          .hideHeader ? null : (
          <DefaultToolbar
            history={this.props.history}
            i18n={this.props.i18n}
            mobileSetting={this.props.mobileSetting}
            updateMobileCurrency={this.updateMobileCurrency}
            showSearch={true}
          />
        )}

        {this.props.provider && this.props.provider.storefront && (
          <div className={styles.center}>
            <img
              src={this.props.provider.storefront}
              className={styles.storefrontImage}
            />
          </div>
        )}

        {this.props.provider && (
          <div className={styles.providerDescContainer}>
            <ProviderItem
              storefront
              id={this.props.provider.id}
              name={this.props.provider.name}
              logo={this.props.provider.logoCached}
              country={this.props.provider.country}
              currency={this.props.provider.currency}
              onContact={this.contactHandler}
              contact={true}
              center={true}
              rating={this.props.rating}
            />

            {this.props.aboutText && (
              <div className={styles.aboutContainer}>
                <div className={styles.aboutTitle}>
                  {this.props.t("g_about_us")}
                </div>
                <div className={styles.about}>{this.props.aboutText}</div>
              </div>
            )}
          </div>
        )}

        <div className={styles.courseContainer}>
          {!this.props.courses || this.props.courses.length === 0 ? (
            <Empty
              style={{ paddingTop: "20px", height: "175px" }}
              column
              image={coursesImg}
              message={
                <div
                  style={{
                    padding: "20px",
                    textAlign: "center",
                    fontStyle: "normal",
                  }}
                >
                  We are working on bring you courses daily, please check
                  back soon.
                </div>
              }
            />
          ) : (
            <div className={styles.courseContainer}>
              {this.props.courses.map((c, index) => (
                <CourseItem
                  key={c.id}
                  image={c.image}
                  rating={c.rating}
                  rating_count={c.rating_count}
                  isVideo={c.is_video}
                  title={c.title}
                  description={c.description}
                  objectives={c.objectives}
                  credit={c.resolve_ce()}
                  date={c.short_start_date()}
                  avatar={c.avatar()}
                  price={c.resolve_display_cost(
                    resolveCurrencySymbol(this.props.mobileSetting.currency),
                    resolveCurrency(
                      c.lowest_cost(),
                      c.provider_currency,
                      this.props.rates
                    ),
                    resolveCurrency(
                      c.highest_cost(),
                      c.provider_currency,
                      this.props.rates
                    )
                  )}
                  onSelectCourse={this.courseDetailsHandler.bind(this, c)}
                />
              ))}
            </div>
          )}
        </div>

        <div style={{ height: "100px" }}></div>

        {this.props.isAuthenticated ? null : this.props.params
          .hideFooter ? null : (
          <Footer history={this.props.history} />
        )}
      </div>
    );
  }
}

// ------------------------------
// p=providerid
// &lang=en
// &header=none
// &footer=none

const parseQueryString = (props) => {
  let providerId = null;
  let filterLang = null;
  let hideHeader = null;
  let hideFooter = null;

  const query = new URLSearchParams(props.location.search);

  for (let param of query.entries()) {
    if (param[0] === "p") {
      providerId = param[1];
    }
    if (param[0] === "lang") {
      filterLang = param[1];
    }
    if (param[0] === "header") {
      hideHeader = param[1];
    }
    if (param[0] === "footer") {
      hideFooter = param[1];
    }
  }

  return {
    providerId: providerId,
    filterLang: filterLang,
    hideHeader: hideHeader,
    hideFooter: hideFooter,
  };
};

// ------------------------------

const mapStateToProps = (state, props) => {
  const params = parseQueryString(props);

  let courseList = [...state.provider.courses];
  const providerCourses = state.provider.courses;
  const provider = state.provider.selected_provider;

  let aboutText = "";

  if (params.filterLang === "en") {
    courseList = providerCourses.filter(
      (course) => course.language === "English"
    );

    aboutText = provider ? provider.about_en : "";
  } else if (params.filterLang === "fr") {
    courseList = providerCourses.filter(
      (course) => course.language === "French"
    );

    aboutText = provider ? provider.about_fr : "";
  } else {
    if (props.i18n && props.i18n.language === "fr") {
      aboutText = provider ? provider.about_fr : "";
    } else if (props.i18n && props.i18n.language === "en") {
      aboutText = provider ? provider.about_en : "";
    } else {
      aboutText = provider ? provider.about : "";
    }
  }

  if (!aboutText) {
    aboutText = provider
      ? provider.about_en
        ? provider.about_en
        : provider.about
      : "";
  }

  let currencyObject = {};

  if (state.setting.mobileSetting) {
    currencyObject = CURRENCY.find(
      (c) => c.id === state.setting.mobileSetting.currency
    );
  } else {
    currencyObject = CURRENCY[1];
  }

  return {
    currencyObject: currencyObject,
    providerId: params.providerId,
    provider: provider,
    aboutText: aboutText,
    params: params,
    rating: state.reviews.rating,
    courses: courseList,
    mobileSetting: state.setting.mobileSetting,
    rates: state.rate.rate,
    isAuthenticated: state.auth.token != null,
  };
};

// ------------------------------

const mapDispatchToProps = (dispatch) => {
  return {
    fetchMobileSetting: () => dispatch(settingActions.fetchMobileSetting()),
    fetchProviderCourses: (providerId) =>
      dispatch(providerActions.fetchProviderCourses(providerId)),
    fetchProvider: (providerId) =>
      dispatch(providerActions.fetchProvider(providerId)),
    fetchRating: (providerId) =>
      dispatch(providerActions.fetchRating(providerId)),
    setSelectedProviderCourse: (course) =>
      dispatch(providerActions.setSelectedProviderCourse(course)),
    updateCurrency: (currency, language) =>
      dispatch(settingActions.updateCurrency(currency, language)),
    fetchRates: () => dispatch(rateActions.fetchRates()),
  };
};

// ------------------------------

export default withTranslation("translations")(
  connect(mapStateToProps, mapDispatchToProps)(ProviderCourseScreen)
);
