import React from "react";
import Button from "@material-ui/core/Button";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import LiveTvIcon from "@material-ui/icons/LiveTv";
import MarketingAddItem from "../../../components/items/marketingAddItem/MarketingAddItem";

const CourseAddsModal = (props) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("xs"));
  const fullWidth = true;
  const maxWidth = "sm";

  // ------------------------------

  const handleClose = () => {
    props.onClose();
  };

  // ------------------------------

  return (
    <div>
      <Dialog
        fullScreen={fullScreen}
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={true}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="alert-dialog-title">
          <div style={{ display: "flex", alignItems: "center" }}>
            <LiveTvIcon style={{ paddingBottom: "2px" }} />
            &nbsp;Advertisements
          </div>
        </DialogTitle>

        <DialogContent>
          {props.marketingAd.map((add) => (
            <MarketingAddItem add={add} key={add.id} />
          ))}
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose} color="primary">
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default CourseAddsModal;
