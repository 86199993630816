import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Badge from "@material-ui/core/Badge";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
}));

const MatBadge = (props) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Badge badgeContent={props.count} color="error" overlap="rectangular">
        {props.children}
      </Badge>
    </div>
  );
};

export default MatBadge;
