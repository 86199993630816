import React, { Component } from "react";
import { connect } from "react-redux";
import * as authActions from "../../../store/actions/auth";
import Spinner from "../../../components/ui/spinner/Spinner";
import InfoModal from "../../../components/modals/infoModal/InfoModal";
import styles from "./PasswordScreen.module.css";
import SimpleContainer from "../../../components/ui/container/Container";
import { constraints, hasFormErrors } from "../../../globals/Validations";
import MatInputOutline from "../../../components/ui/input/MatInputOutline";
import LockOpenIcon from "@material-ui/icons/LockOpen";
import FormButton from "../../../components/ui/button/FormButton";
import { hasError } from "../../../globals/Validations";
import * as logger from "../../../globals/Logger";
import validate from "validate.js";

class PasswordScreen extends Component {
  state = {
    email: "",
    oldPassword: "",
    newPassword: "",
    wait: false,
    error: null,
    success: null,
    formErrors: {},
    hasErrors: false,
  };

  // ------------------------------

  componentDidMount = async () => {
    window.scrollTo(0, 0);
  };

  // ------------------------------

  update = async (propValue) => {
    await this.setState({ ...this.state, ...propValue });
  };

  // ------------------------------

  resetPasswordHandler = async () => {
    try {
      await this.validateForm();

      if (this.state.hasErrors) {
        await this.update({
          error: "Please verify details.  Invalid fields.",
        });
        return;
      }

      this.update({ wait: true });
      await this.props.reauthenticate(this.state.email, this.state.oldPassword);
      await this.props.resetPassword(this.state.newPassword);

      this.update({
        email: "",
        oldPassword: "",
        newPassword: "",
        wait: false,
        success: "Congratulations your password has been reset successfully.",
      });
    } catch (err) {
      logger.error(err);
      this.update({ wait: false, error: err.message });
    }
  };

  // ------------------------------

  validateForm = async () => {
    const e0 = this.state.formErrors;
    const e1 = this.validateField(e0, "email", "email");
    const e2 = this.validateField(e1, "oldPassword", "plainRequired");
    const e3 = this.validateField(e2, "newPassword", "plainRequired");
    await this.update({ formErrors: e3 });
    await this.hasErrorsHandler(e3);
  };

  // ------------------------------

  validateHandler = async (field, validatorKey) => {
    if (field === null) {
      await this.hasErrorsHandler(this.state.formErrors);
    } else {
      const errors = this.validateField(
        this.state.formErrors,
        field,
        validatorKey
      );
      await this.update({ formErrors: errors }); // await is crutial here
      await this.hasErrorsHandler(errors);
    }
  };

  // ------------------------------

  validateField = (paramFormErrors, field, validatorKey) => {
    let validateObj = {};
    validateObj[validatorKey] = this.state[field];
    const validationResponse = validate(validateObj, constraints);
    const updateFormErrors = { ...paramFormErrors };

    if (validationResponse[validatorKey]) {
      const firstError = validationResponse[validatorKey][0];
      updateFormErrors[field] = firstError;
    } else {
      delete updateFormErrors[field];
    }
    return updateFormErrors;
  };

  // ------------------------------

  hasErrorsHandler = async (formErrors) => {
    await this.update({ hasErrors: hasFormErrors(formErrors, {}) });
  };

  // ------------------------------

  inputChangeHandler = (e) => {
    const newState = { ...this.state };
    newState[e.target.name] = e.target.value;
    this.setState(newState);
  };

  // ------------------------------

  render() {
    if (this.state.wait) {
      return <Spinner />;
    }

    return (
      <div style={{ background: "white" }}>
        <SimpleContainer disableGutters={false}>
          <InfoModal
            isErrorModal={this.state.error !== null}
            title={
              this.state.error !== null
                ? "An error has occured"
                : "Password Reset Success"
            }
            message={this.state.error ? this.state.error : this.state.success}
            open={this.state.error !== null || this.state.success !== null}
            onClose={() => {
              this.update({ error: null, success: null });
            }}
          />

          <div style={{ height: "40px" }}>&nbsp;</div>

          <div className={styles.sectionTitle}>
            <div className={styles.centerRow}>
              <LockOpenIcon style={{ color: "white" }} />
              &nbsp;&nbsp;Reset Password
            </div>
          </div>

          <div style={{ height: "10px" }}>&nbsp;</div>

          <MatInputOutline
            title="Email"
            name="email"
            id="email"
            value={this.state.email}
            onChange={this.inputChangeHandler}
            onBlur={this.validateHandler.bind(this, "email", "email")}
            maxLength={100}
            error={hasError(this.state.formErrors.email)}
            helpertext={this.state.formErrors.email}
            readOnly={false}
            required
          />

          <MatInputOutline
            title="Current Password"
            name="oldPassword"
            id="oldPassword"
            type="password"
            value={this.state.oldPassword}
            onChange={this.inputChangeHandler}
            onBlur={this.validateHandler.bind(
              this,
              "oldPassword",
              "plainRequired"
            )}
            maxLength={50}
            error={hasError(this.state.formErrors.oldPassword)}
            helpertext={this.state.formErrors.oldPassword}
            readOnly={false}
            required
          />

          <MatInputOutline
            title="New Password"
            name="newPassword"
            id="newPassword"
            type="password"
            value={this.state.newPassword}
            onChange={this.inputChangeHandler}
            onBlur={this.validateHandler.bind(
              this,
              "newPassword",
              "plainRequired"
            )}
            maxLength={50}
            error={hasError(this.state.formErrors.newPassword)}
            helpertext={this.state.formErrors.newPassword}
            readOnly={false}
            required
          />

          <FormButton
            label="Reset Password"
            onClick={this.resetPasswordHandler}
            disabled={this.state.hasErrors}
          />
        </SimpleContainer>

        <div style={{ height: "80px" }}>&nbsp;</div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    reauthenticate: (email, oldPass) =>
      dispatch(authActions.reauthenticate(email, oldPass)),
    resetPassword: (newPass) => dispatch(authActions.resetPassword(newPass)),
  };
};

export default connect(null, mapDispatchToProps)(PasswordScreen);
