import React, { Component } from "react";
import { connect } from "react-redux";
import Tab from "@material-ui/core/Tab";
import styles from "./CourseScreen.module.css";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import MatTabs from "../../../components/ui/tabs/MatTabs";
import * as studentActions from "../../../store/actions/student";
import * as adActions from "../../../store/actions/advertisement";
import * as connectActions from "../../../store/actions/connect";
import * as courseActions from "../../../store/actions/courses";
import * as profileActions from "../../../store/actions/profile";
import * as addActions from "../../../store/actions/advertisement";
import * as settingActions from "../../../store/actions/setting";
import * as surveyActions from "../../../store/actions/survey";
import * as certActions from "../../../store/actions/certificate";
import * as logger from "../../../globals/Logger";
import { valueOrBlank, resolveCoverPhoto } from "../../../globals/Common";
import CourseItem from "../../../components/items/courseItem/CourseItem";
import InfoModal from "../../../components/modals/infoModal/InfoModal";
import ConfirmModal from "../../../components/modals/confirmModal/ConfirmModal";
import QrCodeModal from "../../../components/modals/qrCodeModal/QrCodeModal";
import Spinner from "../../../components/ui/spinner/Spinner";
import CourseViewModal from "../../../components/modals/courseViewModal/CourseViewModal";
import CapacityModal from "../../../components/modals/capacityModal/CapacityModal";
import Empty from "../../../components/ui/empty/Empty";
import CancelCourseModal from "../../../components/modals/cancelCourseModal/CancelCourseModal";
import CourseAddsModal from "../../../components/modals/courseAddsModal/CourseAddsModal";
import AlertSuccess from "../../../components/ui/snackbar/AlertSuccess";
import SpellcheckIcon from "@material-ui/icons/Spellcheck";
import EditIcon from "@material-ui/icons/Edit";
import HistoryIcon from "@material-ui/icons/History";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import MarketingSelectModal from "../../../components/modals/marketingSelectModal/MarketingSelectModal";
import RegistrationContentModal from "../../../components/modals/registrationContentModal/RegistrationContentModal";
import HandoutModal from "../../../components/modals/handoutModal/HandoutModal";
import ProviderMessageModal from "../../../components/modals/providerMessageModal/ProviderMessageModal";
import AttachSurveyModal from "../../../components/modals/surveyModal/AttachSurveyModal";
import ImageSelectConfirmModal from "../../../components/modals/imageSelectConfirmModal/ImageSelectConfirmModal";
import { objectHasNoValues } from "../../../globals/Common";
import { CSVLink } from "react-csv";
import * as lodashCloneDeep from "lodash.clonedeep";
import AlgoliaSearch from "../../../components/ui/algoliaAutocomplete/AlgoliaSearch";
import ToggleButton from '../../../components/ui/button/ToggleButton';
import learningLottie from "../../../assets/lottie/learning.json";

const IN_PERSON = "in_person";
const LIVESTREAM = "livestream";
const RECORDED_VIDEO = "recorded_video";
const LOADING = "Loading";

class CourseScreen extends Component {
  state = {
    wait: false,
    waitMessage: "",
    viewCourse: null,
    viewAdds: null,
    updateCapacity: null,
    openRemoveListingConfirmModal: null,
    openQrCodeModal: null,
    cancelCourse: null,
    acceptCancelTerms: false,
    singleCourseRegistered: 0,
    // -----
    message: null,
    messageTitle: null,
    errorModal: false,
    alertSuccess: null,
    showAdTypeModal: null,
    addTypeCourseId: null,
    removeListingMessage: null,
    openImageSelectConfirmModal: null,
    openRegistrationContentModal: null,
    openAttachSurveyModal: null,
    openHandoutsModal: null,
    openProviderMessageModal: null,
    providerMessageModalTitle: "",
    providerMessageCourseId: "",
    registrationContentModalCourse: null,
    attachSurveyModalCourse: null,
    csvLink: React.createRef(),

    active_tab_filter: IN_PERSON,
    history_tab_filter: IN_PERSON,
    active_search_query: "",
    active_search_query_courseId: ""
  };

  // ------------------------------

  update = async (propValue) => {
    await this.setState({ ...this.state, ...propValue });
  };

  // ------------------------------

  componentDidMount = async () => {
    try {
      await this.update({ wait: true });

      const loadAsync = [];

      await this.props.fetchActiveCoursesMetadata();

      loadAsync.push(this.tabClickHandler(this.props.activeTab));

      if (objectHasNoValues(this.props.profile)) {
        loadAsync.push(this.props.fetchProfile());
      }

      if (objectHasNoValues(this.props.setting)) {
        loadAsync.push(this.props.fetchSetting());
      }

      if (objectHasNoValues(this.props.marketingAd)) {
        loadAsync.push(this.props.fetchMarketingAd());
      }

      if (objectHasNoValues(this.props.survey)) {
        loadAsync.push(this.props.fetchSurvey());
      }

      if (objectHasNoValues(this.props.certificate)) {
        loadAsync.push(this.props.fetchCert());
      }

      await Promise.all(loadAsync);

      await this.update({ wait: false });
      window.scrollTo(0, 0);
    } catch (error) {
      logger.error(error);
      await this.update({
        wait: false,
        errorModal: true,
        message: error.message,
      });
    }
  };

  // ------------------------------

  componentDidUpdate = (prevProps) => {
    if (
      prevProps.courses !== this.props.courses &&
      this.props.activeTab === 0
    ) {
      this.props.setSelectedCourses(this.props.courses);
    }

    if (prevProps.drafts !== this.props.drafts && this.props.activeTab === 1) {
      this.props.setSelectedCourses(this.props.drafts);
    }

    if (
      prevProps.courses_history !== this.props.courses_history &&
      this.props.activeTab === 2
    ) {
      this.props.setSelectedCourses(this.props.courses_history);
    }
  };

  // ------------------------------

  tabClickHandler = async (id) => {
    let filters = null;
    switch (id) {
      case 0:
        if (this.state.active_search_query) {
          try {
            await this.update({ wait: true, waitMessage: LOADING });
            await this.props.fetchProviderCourse(this.state.active_search_query_courseId);
            await this.update({ wait: false });
          } catch (err) {
            await this.catchError(err)
          }
        }
        else {
          filters = this.applyCourseFilters(id);
          await this.loadActiveCourses(this.state.active_tab_filter, filters);
        }
        break;
      case 1:
        await this.loadDraftCourses();
        break;
      case 2:
        filters = this.applyCourseFilters(id);
        await this.loadHistoryCourses(this.state.history_tab_filter, filters);
        break;
      case 3:
        this.addHandler(null, null);
        break;
      default:
        selectedCourses = [];
    }

    if (id < 3) {
      this.props.setActiveTab(id);
    }

    window.scrollTo(0, 0);
  };

  // ------------------------------

  applyCourseFilters = (tabId) => {
    const metadata = this.props.providerCoursesMeta;

    if (metadata) {
      if (metadata.in_person > 0 && this.state.active_tab_filter === IN_PERSON && tabId === 0) {
        return { inPerson: true }
      }
      else if (metadata.livestream > 0 && this.state.active_tab_filter === LIVESTREAM && tabId === 0) {
        return { livestream: true };
      }
      else if (metadata.recorded_video > 0 && this.state.active_tab_filter === RECORDED_VIDEO && tabId === 0) {
        return { video: true };
      }
      else if (metadata.history_in_person > 0 && this.state.history_tab_filter === IN_PERSON && tabId === 2) {
        return { inPerson: true }
      }
      else if (metadata.history_livestream > 0 && this.state.history_tab_filter === LIVESTREAM && tabId === 2) {
        return { livestream: true };
      }
      else if (metadata.history_recorded_video > 0 && this.state.history_tab_filter === RECORDED_VIDEO && tabId === 2) {
        return { video: true };
      }
    }
    else
      return null;
  }

  // ------------------------------

  addHandler = (event, id) => {
    if (event) event.preventDefault();
    if (id) {
      this.props.initEditCourse(id);
      this.props.history.push("/editcourse/");
    } else {
      this.props.initAddCourse();
      this.props.history.push("/editcourse/");
    }
  };

  // ------------------------------

  viewStudentsHandler = (event, courseId) => {
    event.preventDefault();
    this.props.initEditCourse(courseId);
    this.props.history.push("/students");
  };

  // ------------------------------

  copyCourseHandler = async (course) => {
    try {
      const copyCourse = lodashCloneDeep(course);
      copyCourse.reference_number = "";

      if (this.props.activeTab !== 1) {
        const instruct = await this.props.fetchRegistrationContent(course.id);
        if (instruct) {
          copyCourse.email_instruction = instruct;
        }
      }

      this.props.copyCourse(copyCourse);
      this.update({ wait: false, alertSuccess: "Copied" });
    } catch (error) {
      logger.error(error);
      this.update({ errorModal: true, message: error.message });
    }
  };

  // ------------------------------

  deleteDraftHandler = async (course) => {
    try {
      this.update({ wait: true });
      this.props.deleteDraft(course);
      this.update({ wait: false });
    } catch (error) {
      logger.error(error);
      this.update({ wait: false, errorModal: true, message: error.message });
    }
  };

  // ------------------------------

  cancelCourseRequestHandler = async (
    item,
    onlineSeated,
    classHandsonSeated,
    classLectureSeated
  ) => {
    try {
      if (item.is_video) {
        this.update({
          selectedCourse: item,
          openRemoveListingConfirmModal: true,
          removeListingMessage:
            "Are you sure you wish to remove " +
            item.title +
            "? Removing an active course will remove it from the ZubU mobile app. " +
            "No refunds or unregistrations will occur if you proceed, however you are solely responsible to continue offering the video content to existing registrants if applicable.",
        });
      } else {
        await Promise.all([
          this.update({
            wait: true,
            waitMessage: "Preparing Course Cancellation",
          }),
          this.props.fetchStripeBalance(),
          this.props.cancelCourseRefundValue(item.id),
        ]);

        this.update({
          wait: false,
          waitMessage: "",
          cancelCourse: true,
          singleCourseRegistered:
            onlineSeated + classHandsonSeated + classLectureSeated,
          selectedCourse: item,
        });
      }
    } catch (err) {
      logger.error(err);
      this.update({
        wait: false,
        waitMessage: "",
        errorModal: true,
        message: err.message,
        selectedCourse: null,
        openRemoveListingConfirmModal: false,
        removeListingMessage: null,
      });
    }
  };

  // ------------------------------

  editHandoutsHandler = async (course) => {
    try {
      await this.update({ wait: true });
      await this.props.fetchRegistrationContent(course.id);
      await this.update({
        wait: false,
        openHandoutsModal: true,
        selectedCourse: course,
      });
    } catch (error) {
      logger.error(error);
      this.update({
        wait: false,
        errorModal: true,
        message: error.message,
        openHandoutsModal: null,
        selectedCourse: null,
      });
    }
  };

  // ------------------------------

  editRegistrationContentRequestHandler = async (course) => {
    try {
      await this.update({ wait: true });
      await this.props.fetchRegistrationContent(course.id);

      if (course.is_video) {
        await this.props.fetchRecordedVideo(course.id, true);
      }

      await this.update({
        wait: false,
        openRegistrationContentModal: true,
        registrationContentModalCourse: course,
      });
    } catch (error) {
      logger.error(error);
      this.update({
        wait: false,
        errorModal: true,
        message: error.message,
        openRegistrationContentModal: null,
        registrationContentModalCourse: null,
      });
    }
  };

  // ------------------------------

  editSurveyRequestHandler = async (course) => {
    try {
      await this.update({ wait: true });
      await this.props.fetchRegistrationContent(course.id);
      await this.props.fetchSelectedCertificate(course.id);
      await this.update({
        wait: false,
        openAttachSurveyModal: true,
        attachSurveyModalCourse: course,
      });
    } catch (error) {
      logger.error(error);
      this.update({
        wait: false,
        errorModal: true,
        message: error.message,
        openAttachSurveyModal: null,
        attachSurveyModalCourse: null,
      });
    }
  };

  // ------------------------------

  fetchSurveyResults = async (course) => {
    try {
      await this.update({ wait: true });
      await this.props.fetchRegistrationContent(course.id);
      await this.props.fetchSurveyResults(course.id);
      this.state.csvLink.current.link.click();
      await this.props.clearSurveyResultCount(course.id);
      await this.update({ wait: false });
    } catch (error) {
      logger.error(error);
      this.update({
        wait: false,
        errorModal: true,
        message: error.message,
      });
    }
  };

  // ------------------------------

  renderCourse = (item, index) => {
    let newStudents = 0;
    let onlineSeated = 0;
    let classLectureSeated = 0;
    let classHandsonSeated = 0;

    if (this.props.capacity[item.id]) {
      newStudents = this.props.capacity[item.id].newStudentCount;
      onlineSeated = this.props.capacity[item.id].onlineCount;
      classLectureSeated = this.props.capacity[item.id].classroomLectureCount;
      classHandsonSeated = this.props.capacity[item.id].classroomHandsonCount;
    }

    const revenu =
      this.props.revenu && this.props.revenu[item.id]
        ? this.props.revenu[item.id]
        : 0;

    const survey_result_count = this.props.survey_result_count && this.props.survey_result_count[item.id] ? this.props.survey_result_count[item.id] : 0;

    const adds = this.props.marketingAd.filter(
      (ad) => ad.course_id === item.id
    );

    return (
      <CSSTransition
        key={index}
        timeout={500}
        classNames={{
          enterActive: styles["item-enter-active"],
          exitActive: styles["item-exit-active"],
          enter: styles["item-enter"],
          exit: styles["item-exit"],
        }}
      >
        <CourseItem
          inReview={item.activation_status === "review"}
          key={index}
          revenu={revenu}
          survey_result_count={survey_result_count}
          courseViewType={this.props.courseViewType}
          image={item.image}
          isVideo={item.is_video}
          activation={item.activation}
          currency={this.props.currency}
          title={item.title}
          professor={item.professor}
          adCount={adds.length}
          amendmentCount={
            item.amendment && item.amendment.length > 0
              ? item.amendment.length
              : 0
          }
          newStudents={newStudents}
          categories={item.category.join(",")}
          startDate={item.format_start_date()}
          onlineSeats={item.resolve_online_seats()}
          classroomLectureSeats={item.resolve_classroom_seats()}
          classroomHandsonSeats={item.resolve_handson_seats()}
          classroomLectureSeated={classLectureSeated}
          classroomHandsonSeated={classHandsonSeated}
          onlineSeated={onlineSeated}
          daysRemaining={item.resolveDaysRemaining()}
          activeTab={this.props.activeTab}
          onClick={(event) => this.addHandler(event, item.id)}
          onClickStudent={(event) => this.viewStudentsHandler(event, item.id)}
          onCopyCourse={() => this.copyCourseHandler(item)}
          onDeleteDraft={() => this.deleteDraftHandler(item.id)}
          onViewCourseAdds={() => {
            this.update({ viewAdds: true, selectedCourse: item });
          }}
          onCourseView={() =>
            this.update({ viewCourse: true, selectedCourse: item })
          }
          onUpdateCapcity={() =>
            this.update({ updateCapacity: true, selectedCourse: item })
          }
          onAdvertise={() => {
            this.update({ showAdTypeModal: true, addTypeCourseId: item.id });
          }}
          onEditHandouts={() => {
            this.editHandoutsHandler(item);
          }}
          onEditVideoLink={() => {
            this.editRegistrationContentRequestHandler(item);
          }}
          onEditSurvey={() => {
            this.editSurveyRequestHandler(item);
          }}
          onFetchSurveyResults={() => {
            this.fetchSurveyResults(item);
          }}
          onQrCode={() => {
            this.update({ openQrCodeModal: true, selectedCourse: item });
          }}
          onCancelCourse={() => {
            this.cancelCourseRequestHandler(
              item,
              onlineSeated,
              classHandsonSeated,
              classLectureSeated
            );
          }}
          onAmendment={() => {
            if (item.amendment && item.amendment.length === 3) {
              this.update({
                errorModal: true,
                message: "Limit of three amendments reached for this course.",
              });
            } else {
              this.props.initEditCourse(item.id);
              this.props.history.push("/amendment");
            }
          }}
          onCoverPhoto={() => {
            this.update({
              openImageSelectConfirmModal: true,
              selectedCourse: item,
            });
          }}
          onStartZoom={() => { }}
          onZoomDetails={() => { }}
          onZoomParticipant={() => { }}
        />
      </CSSTransition>
    );
  };

  // ------------------------------

  closeErrorHandler = () => {
    this.update({
      message: null,
    });
  };

  // ------------------------------

  updateCapacityConfirmation = async (course, seats) => {
    try {
      await this.update({ wait: true, waitMessage: "Updating Capacity" });
      await this.props.updateCapacity(course, seats);
      await this.update({
        wait: false,
        waitMessage: "",
        errorModal: false,
        messageTitle: "Course Capacity Updated",
        message: "Course capacity updated successfully.",
      });
    } catch (err) {
      logger.error(err);
      await this.update({
        wait: false,
        waitMessage: "",
        errorModal: true,
        message: err.message,
      });
    }
  };

  // ------------------------------

  cancelCourseConfirmation = async (course) => {
    try {
      await this.update({ wait: true, waitMessage: "Cancelling Course" });
      await this.props.cancelCourse(course.id);
      await this.props.fetchCourses();
      await this.update({
        wait: false,
        waitMessage: "",
        errorModal: false,
        messageTitle: "Course Cancellation Success",
        message:
          "Course has been cancelled, you can view it at all times in history.",
      });
    } catch (err) {
      logger.error(err);
      await this.update({
        wait: false,
        errorModal: true,
        message: err.message,
        waitMessage: "",
      });
    }
  };

  // ------------------------------

  acceptCancelHandler = (event, checked) => {
    this.update({
      acceptCancelTerms: checked,
    });
  };


  // ------------------------------

  changeViewHandler = (e, viewType) => {
    if (viewType) {
      this.props.updateCourseView(viewType);
    }
  };

  // ------------------------------

  newAdHandler = async (adType) => {
    if (adType) {
      this.props.setNewAction(adType);
      this.props.history.push(`/createad?course=${this.state.addTypeCourseId}`);
    } else {
      this.update({ showAdTypeModal: null, addTypeCourseId: null });
    }
  };

  // ------------------------------

  removeListingConfirmHandler = async (answer) => {
    await this.update({
      openRemoveListingConfirmModal: false,
      removeListingMessage: null,
    });

    if (answer === "ok") {
      this.cancelCourseConfirmation(this.state.selectedCourse);
    }
  };

  // ------------------------------

  updateRegistrationContent = async (value, courseId, videoChapters) => {
    try {
      await this.update({ wait: true });
      await this.props.updateRegistrationContent(value, courseId, videoChapters);
      await this.update({
        wait: false,
        alertSuccess: "Saved",
        errorModal: false,
        openProviderMessageModal: true,
        providerMessageCourseId: courseId,
        providerMessageModalTitle: "Notify students of your changes",
      });
    } catch (err) {
      logger.error(err);
      await this.update({
        wait: false,
        errorModal: true,
        message: err.message,
      });
    }
  };

  // ------------------------------

  updateAttachedSurveyCert = async (survey, certificate, courseId) => {
    try {
      await this.update({ wait: true });
      if (survey !== null) {
        await this.props.updateAttachedSurvey(survey, courseId);
      }
      if (certificate !== null) {
        await this.props.linkCertActive(certificate, courseId);
      }
      await this.update({
        wait: false,
        alertSuccess: "Saved",
        errorModal: false,
      });
    } catch (err) {
      logger.error(err);
      await this.update({
        wait: false,
        errorModal: true,
        message: err.message,
      });
    }
  };

  // ------------------------------

  updateAttachedHandout = async (
    handout,
    handout_delivery,
    courseId,
    activation
  ) => {
    try {
      await this.update({ wait: true });
      await this.props.updateAttachedHandout(
        handout,
        handout_delivery,
        courseId
      );

      await this.update({
        wait: false,
        alertSuccess: "Handouts Saved",
        errorModal: false,
        openProviderMessageModal: true,
        providerMessageModalTitle: "Notify students of course handouts updates",
        providerMessageCourseId: courseId,
      });
    } catch (err) {
      logger.error(err);
      await this.update({
        wait: false,
        errorModal: true,
        message: err.message,
      });
    }
  };

  // ------------------------------

  confirmMessageAllHandler = async (answer, message) => {
    try {
      if (answer === "ok") {
        await this.update({
          openProviderMessageModal: false,
          wait: true,
          waitMessage: "Sending your message",
        });

        await this.props.sendProviderNotification(
          this.state.providerMessageCourseId,
          message
        );

        await this.update({
          providerMessageCourseId: "",
          wait: false,
          waitMessage: "",
          alertSuccess: "Message sent",
        });
      }
    } catch (error) {
      logger.error(error);
      await this.update({
        providerMessageCourseId: null,
        openProviderMessageModal: false,
        wait: false,
        waitMessage: "",
        message: error.message,
        errorModal: true,
      });
    }

    this.update({
      openProviderMessageModal: false,
    });
  };

  // ------------------------------

  saveImageHandler = async (imageBlob) => {
    try {
      const copyCourse = lodashCloneDeep(this.state.selectedCourse);

      if (copyCourse) {
        await this.update({ wait: true });
        await this.props.uploadDeleteCourseImage(imageBlob, copyCourse);
        await this.update({
          wait: false,
          alertSuccess: imageBlob ? "Photo saved" : "Photo removed",
        });
      }
    } catch (err) {
      logger.error(err);
      this.update({
        wait: false,
        message: err.message,
        errorModal: true,
      });
    }
  };

  // ------------------------------

  loadActiveCourses = async (active_tab_filter, filters) => {
    try {
      await this.update({ wait: true, waitMessage: LOADING, active_tab_filter: active_tab_filter });
      await this.props.fetchCourses(filters);
      await this.update({ wait: false, active_search_query: "" });
    } catch (err) {
      logger.error(err);
      await this.catchError(err)
    }
  }

  // ------------------------------

  loadDraftCourses = async () => {
    try {
      await this.update({ wait: true, waitMessage: LOADING });
      await this.props.fetchDraftCourses();
      await this.update({ wait: false });
    } catch (err) {
      await this.catchError(err)
    }
  }

  // ------------------------------

  loadHistoryCourses = async (history_tab_filter, filters) => {
    try {
      await this.update({ wait: true, waitMessage: LOADING, history_tab_filter: history_tab_filter });
      await this.props.fetchHistoryCourses(filters);
      await this.update({ wait: false });
    } catch (err) {
      await this.catchError(err)
    }
  }

  // ------------------------------

  catchError = async (err) => {
    logger.error(err);
    await this.update({
      wait: false,
      errorModal: true,
      message: err.message,
      waitMessage: "",
    });
  }

  // ------------------------------

  algoSearchHandler = async (courseId, providerId, title) => {
    try {
      await this.update({
        wait: true,
        waitMessage: LOADING,
        active_search_query: title,
        active_tab_filter: "",
        active_search_query_courseId: courseId
      });
      await this.props.fetchProviderCourse(courseId);
      await this.update({ wait: false });
    } catch (err) {
      await this.catchError(err)
    }
  };

  // ------------------------------

  render() {
    return (
      <div style={{ backgroundColor: "white" }}>
        {this.state.wait && <Spinner message={this.state.waitMessage} />}

        {this.state.openImageSelectConfirmModal && (
          <ImageSelectConfirmModal
            open={this.state.openImageSelectConfirmModal}
            removable={this.state.selectedCourse.image}
            title={"Course Cover Photo"}
            aspectRatio={{ aspect: 480 / 270, width: 480, height: 270 }}
            imageWidth="480px"
            imageHeight="270px"
            image={
              this.state.selectedCourse.image
                ? this.state.selectedCourse.image
                : `url(${resolveCoverPhoto(this.state.selectedCourse.title)})`
            }
            onClose={async (blob) => {
              if (blob && blob.startsWith("blob:")) {
                await this.saveImageHandler(blob);
              }

              this.update({ openImageSelectConfirmModal: null });
            }}
            onRemovePic={async (pic) => {
              if (pic && !pic.startsWith("blob:")) {
                await this.saveImageHandler(null);
              }

              this.update({ openImageSelectConfirmModal: null });
            }}
          />
        )}

        <CSVLink
          data={this.props.surveyResults}
          headers={this.props.surveyHeaders}
          filename={"survey_results.csv"}
          className="hidden"
          ref={this.state.csvLink}
          target="_blank"
        />

        <InfoModal
          isErrorModal={this.state.errorModal}
          title={
            this.state.errorModal
              ? "An error has occured"
              : this.state.messageTitle
          }
          message={this.state.message}
          open={this.state.message !== null}
          onClose={this.closeErrorHandler}
        />

        {this.state.openProviderMessageModal && (
          <ProviderMessageModal
            title={this.state.providerMessageModalTitle}
            open={this.state.openProviderMessageModal}
            onClose={this.confirmMessageAllHandler}
          />
        )}

        {this.state.openRemoveListingConfirmModal && (
          <ConfirmModal
            title="Confirmation Required"
            message={this.state.removeListingMessage}
            open={this.state.openRemoveListingConfirmModal}
            onClose={this.removeListingConfirmHandler}
          />
        )}

        {this.state.openQrCodeModal && (
          <QrCodeModal
            courseId={this.state.selectedCourse.id}
            providerId={this.state.selectedCourse.provider_id}
            courseTitle={this.state.selectedCourse.title}
            open={this.state.openQrCodeModal}
            onClose={() => {
              this.update({ openQrCodeModal: null, selectedCourse: null });
            }}
          />
        )}

        {this.state.alertSuccess !== null && (
          <AlertSuccess
            message={this.state.alertSuccess}
            open={this.state.alertSuccess !== null}
            onClose={() => {
              this.update({ alertSuccess: null });
            }}
          />
        )}

        {this.state.showAdTypeModal && (
          <MarketingSelectModal newAd={this.newAdHandler} />
        )}

        {this.state.openRegistrationContentModal && (
          <RegistrationContentModal
            open={this.state.openRegistrationContentModal}
            course={this.state.registrationContentModalCourse}
            email_instruction={this.props.email_instruction}
            video_chapters={this.props.selected_video_chapters}
            onClose={async (email_instruction, courseId, videoChapters) => {
              await this.update({
                openRegistrationContentModal: null,
                registrationContentModalCourse: null,
              });

              if (email_instruction !== null || videoChapters !== null) {
                this.updateRegistrationContent(
                  email_instruction,
                  courseId,
                  videoChapters
                );
              }
            }}
          />
        )}

        {this.state.openHandoutsModal && (
          <HandoutModal
            open={this.state.openHandoutsModal}
            course={this.state.selectedCourse}
            selected_handout={this.props.selected_handout}
            selected_handout_delivery={this.props.selected_handout_delivery}
            onClose={async (handout, handout_delivery, courseId) => {
              const activation = this.state.selectedCourse.activation;

              await this.update({
                openHandoutsModal: null,
                selectedCourse: null,
              });

              if (handout !== null) {
                this.updateAttachedHandout(
                  handout,
                  handout_delivery,
                  courseId,
                  activation
                );
              }
            }}
          />
        )}

        {this.state.openAttachSurveyModal && (
          <AttachSurveyModal
            open={this.state.openAttachSurveyModal}
            course={this.state.attachSurveyModalCourse}
            selected_survey={this.props.selected_survey}
            selected_certificate={this.props.selected_certificate}
            surveyList={this.props.survey}
            certificateList={this.props.certificate}
            profile={this.props.profile}
            onClose={async (survey, certificate, courseId) => {
              await this.update({
                openAttachSurveyModal: null,
                attachSurveyModalCourse: null,
              });

              if (survey !== null || certificate !== null) {
                this.updateAttachedSurveyCert(survey, certificate, courseId);
              }
            }}
          />
        )}

        <MatTabs
          onClick={this.tabClickHandler}
          activeTab={this.props.activeTab}
        >
          <Tab label="Active" icon={<SpellcheckIcon />} />
          <Tab label="Draft" icon={<EditIcon />} />
          <Tab label="History" icon={<HistoryIcon />} />
          <Tab label="New Course" icon={<AddCircleOutlineIcon />} />
        </MatTabs>

        <div style={{ marginTop: "140px" }} />

        {this.props.activeTab === 0 &&
          (
            <div style={{
              paddingLeft: "20px", paddingTop: "10px", paddingBottom: "10px", display: "flex", flexDirection: "row", alignItems: "center"
              , borderBottom: "1px solid #ccc"
            }}>
              {this.props.providerCoursesMeta && this.props.providerCoursesMeta.in_person > 0 &&
                <ToggleButton
                  id="in_person"
                  label={`In Person (${this.props.providerCoursesMeta.in_person})`}
                  selection={this.state.active_tab_filter}
                  onClick={this.loadActiveCourses.bind(this, IN_PERSON, { inPerson: true })}
                />
              }
              {this.props.providerCoursesMeta && this.props.providerCoursesMeta.livestream > 0 &&
                <ToggleButton
                  id="livestream"
                  label={`Livestream (${this.props.providerCoursesMeta.livestream})`}
                  selection={this.state.active_tab_filter}
                  onClick={this.loadActiveCourses.bind(this, LIVESTREAM, { livestream: true })}
                />
              }
              {this.props.providerCoursesMeta && this.props.providerCoursesMeta.recorded_video > 0 &&
                <ToggleButton
                  id="recorded_video"
                  label={`Recorded Video (${this.props.providerCoursesMeta.recorded_video})`}
                  selection={this.state.active_tab_filter}
                  onClick={this.loadActiveCourses.bind(this, RECORDED_VIDEO, { video: true })}
                />
              }
              <AlgoliaSearch
                onSelect={this.algoSearchHandler}
                providerName={this.props.profile.providerName}
                query={this.state.active_search_query}
              />
            </div>)
        }

        {this.props.activeTab === 1 &&
          (
            <div style={{
              paddingLeft: "20px", paddingTop: "10px", paddingBottom: "10px", display: "flex", flexDirection: "row", alignItems: "center"
              , borderBottom: "1px solid #ccc"
            }}>
              &nbsp;
            </div>
          )
        }


        {this.props.activeTab === 2 &&
          (
            <div style={{
              paddingLeft: "20px", paddingTop: "10px", paddingBottom: "10px", display: "flex", flexDirection: "row", alignItems: "center"
              , borderBottom: "1px solid #ccc"
            }}>
              {this.props.providerCoursesMeta && this.props.providerCoursesMeta.history_in_person > 0 &&
                <ToggleButton
                  id="in_person"
                  label={`In Person (${this.props.providerCoursesMeta.history_in_person})`}
                  selection={this.state.history_tab_filter}
                  onClick={this.loadHistoryCourses.bind(this, IN_PERSON, { inPerson: true })}
                />
              }
              {this.props.providerCoursesMeta && this.props.providerCoursesMeta.history_livestream > 0 &&
                <ToggleButton
                  id="livestream"
                  label={`Livestream (${this.props.providerCoursesMeta.history_livestream})`}
                  selection={this.state.history_tab_filter}
                  onClick={this.loadHistoryCourses.bind(this, LIVESTREAM, { livestream: true })}
                />
              }
              {this.props.providerCoursesMeta && this.props.providerCoursesMeta.recorded_video > 0 &&
                <ToggleButton
                  id="recorded_video"
                  label={`Recorded Video (${this.props.providerCoursesMeta.history_recorded_video})`}
                  selection={this.state.history_tab_filter}
                  onClick={this.loadHistoryCourses.bind(this, RECORDED_VIDEO, { video: true })}
                />
              }
              &nbsp;
            </div>)
        }


        {(!this.props.selectedCourses ||
          this.props.selectedCourses.length === 0) &&
          this.props.activeTab === 0 &&
          !this.state.wait && (
            <Empty
              style={{ marginTop: "140px" }}
              column
              lottie={learningLottie}
              message={
                <p>
                  You currently have no ACTIVE Courses on the ZubU mobile app.
                  Start adding courses by clicking <b>New Course</b>. Once a
                  course is created, it will remain in the <b>Draft</b>
                  &nbsp;section until you activate it. When activated, your
                  course will be visible on the ZubU mobile app and student
                  registrations can begin. An active course cannot be modified,
                  only amended. Original course information will always remain
                  visible to registrants. From here, you will be able to manage
                  registrants, preview the course, amend, adjust capacity and
                  more!
                </p>
              }
              onClick={this.addHandler}
              buttonIcon={<AddCircleOutlineIcon />}
              buttonLabel={"New Course"}
            />
          )}

        {(!this.props.selectedCourses ||
          this.props.selectedCourses.length === 0) &&
          this.props.activeTab === 1 &&
          !this.state.wait && (
            <Empty
              style={{ marginTop: "140px" }}
              column
              lottie={learningLottie}
              message={
                <p>
                  You currently have no DRAFT courses. Start adding courses by
                  clicking the + at the top of the screen. Once a course is
                  created, it will remain in the Draft section until you
                  activate it. You may make copies of your draft and active
                  courses to use as templates for future courses.
                </p>
              }
              onClick={this.addHandler}
              buttonIcon={<AddCircleOutlineIcon />}
              buttonLabel={"New Course"}
            />
          )}

        {(!this.props.selectedCourses ||
          this.props.selectedCourses.length === 0) &&
          this.props.activeTab === 2 &&
          !this.state.wait && (
            <Empty
              style={{ marginTop: "140px" }}
              column
              lottie={learningLottie}
              message={
                <div>
                  <p>
                    You currently have no HISTORICAL courses. Once an active
                    course is completed, it will appear here for your reference.
                  </p>
                </div>
              }
            />
          )}



        {this.props.selectedCourses && (
          <div>
            {this.state.viewCourse && (
              <CourseViewModal
                currency={this.props.currency}
                course={this.state.selectedCourse}
                onClose={() => {
                  this.update({ viewCourse: null, selectedCourse: null });
                }}
              />
            )}
            {this.state.viewAdds && (
              <CourseAddsModal
                course={this.state.selectedCourse}
                marketingAd={this.props.marketingAd.filter(
                  (ad) => ad.course_id === this.state.selectedCourse.id
                )}
                onClose={() => {
                  this.update({ viewAdds: null, selectedCourse: null });
                }}
              />
            )}
            {this.state.updateCapacity && (
              <CapacityModal
                capacity={this.props.capacity}
                course={this.state.selectedCourse}
                onClose={async (seats) => {
                  await this.update({
                    updateCapacity: null,
                  });
                  if (seats) {
                    this.updateCapacityConfirmation(
                      this.state.selectedCourse,
                      seats
                    );
                  }
                }}
              />
            )}
            {this.state.cancelCourse && (
              <CancelCourseModal
                blockFromMultiPay={
                  this.state.selectedCourse.multi_pay_option &&
                  this.state.selectedCourse.multi_pay_option.enabled &&
                  this.state.singleCourseRegistered > 0
                }
                courseTitle={this.state.selectedCourse.title}
                currency={this.props.currency}
                stripeBalance={this.props.stripeBalance}
                cancelCourseRefundAmount={this.props.cancelCourseRefundAmount}
                cancelCourseAffectedOrders={
                  this.props.cancelCourseAffectedOrders
                }
                acceptCancelTerms={this.state.acceptCancelTerms}
                registered={this.state.singleCourseRegistered}
                onAcceptCancelTerms={this.acceptCancelHandler}
                onClose={async (answer) => {
                  await this.update({
                    cancelCourse: null,
                    singleCourseRegistered: 0,
                    acceptCancelTerms: false,
                  });
                  if (answer) {
                    this.cancelCourseConfirmation(this.state.selectedCourse);
                  }
                }}
              />
            )}

            <div className={styles.cards}>
              <TransitionGroup
                style={{
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "wrap",
                  alignItems: "flex-start",
                  justifyContent: "flex-start",
                }}
              >
                {this.props.selectedCourses.map((c, index) =>
                  this.renderCourse(c, index)
                )}
              </TransitionGroup>
            </div>
          </div>
        )}
      </div>
    );
  }
}

// ------------------------------

const mapStateToProps = (state) => {
  const buildHeader = (survey) => {
    if (survey && survey.survey_results) {
      const collator = new Intl.Collator(undefined, { numeric: true, sensitivity: 'base' });
      if (survey.survey_results[0]) {
        const keys = Object.keys(survey.survey_results[0]);
        if (keys && keys[0] === "0") {
          return [];
        }

        return keys.sort(collator.compare);
      }
    }
    return [];
  }

  return {
    providerCoursesMeta: state.courses.providerCoursesMeta,
    certificate: state.certificate.certificate,
    survey: state.survey.survey,
    surveyResults: state.survey.survey_results,
    surveyHeaders: buildHeader(state.survey),
    capacity: state.courses.capacity,
    courses: state.courses.courses,
    drafts: state.courses.drafts,
    courses_history: state.courses.courses_history,
    stripeBalance: state.connect.total,
    profile: state.profile.profile,
    setting: state.setting.setting,
    currency: state.profile.profile.currency,
    marketingAd: state.advertisement.marketingAd,
    activeTab: state.courses.activeTab,
    selectedCourses: state.courses.selectedCourses,
    cancelCourseRefundAmount: state.courses.cancelCourseRefundAmount,
    cancelCourseAffectedOrders: state.courses.cancelCourseAffectedOrders,
    revenu: state.courses.revenu,
    survey_result_count: state.courses.survey_result_count,
    courseViewType: valueOrBlank(state.setting.setting.course_view),
    email_instruction: state.courses.email_instruction,
    selected_survey: state.courses.selected_survey,
    selected_handout: state.courses.selected_handout,
    selected_handout_delivery: state.courses.selected_handout_delivery,
    selected_certificate: state.courses.selected_certificate,
    selected_video_chapters: state.courses.selected_video_chapters
  };
};

// ------------------------------

const mapDispatchToProps = (dispatch) => {
  return {
    fetchActiveCoursesMetadata: () => dispatch(courseActions.fetchActiveCoursesMetadata()),
    fetchCourses: (filters) => dispatch(courseActions.fetchActiveCourses(filters)),
    fetchDraftCourses: () => dispatch(courseActions.fetchDraftCourses()),
    fetchHistoryCourses: (filters) => dispatch(courseActions.fetchHistoryCourses(filters)),
    fetchProviderCourse: (courseId) => dispatch(courseActions.fetchProviderCourse(courseId)),
    setSelectedCourses: (courses) => dispatch(courseActions.setSelectedCourses(courses)),
    fetchRecordedVideo: (courseId, isActiveCourse) => dispatch(courseActions.fetchRecordedVideo(courseId, isActiveCourse)),

    initAddCourse: () => dispatch(courseActions.initAdd()),
    initEditCourse: (id) => dispatch(courseActions.initEdit(id)),
    fetchProfile: () => dispatch(profileActions.fetchProfile()),
    copyCourse: (course) => dispatch(courseActions.addDraft(course)),
    deleteDraft: (courseId) => dispatch(courseActions.removeDraft(courseId)),
    fetchStripeBalance: () => dispatch(connectActions.fetchStripeBalance()),
    cancelCourse: (id) => dispatch(courseActions.cancelCourse(id)),
    cancelCourseRefundValue: (id) =>
      dispatch(courseActions.cancelCourseRefundValue(id)),
    updateCapacity: (course, seats) =>
      dispatch(courseActions.updateCapacity(course, seats)),
    fetchMarketingAd: () => dispatch(addActions.fetchMarketingAd()),
    setActiveTab: (tabIndex) => dispatch(courseActions.setActiveTab(tabIndex)),
    updateCourseView: (courseView) =>
      dispatch(settingActions.updateCourseView(courseView)),
    fetchSetting: () => dispatch(settingActions.fetchSetting()),
    setNewAction: (adType) => dispatch(adActions.setAddAction(adType)),
    fetchRegistrationContent: (courseId) =>
      dispatch(courseActions.fetchRegistrationContent(courseId)),
    updateRegistrationContent: (value, courseId, videoChapters) =>
      dispatch(courseActions.updateRegistrationContent(value, courseId, videoChapters)),
    fetchSelectedCertificate: (courseId) =>
      dispatch(courseActions.fetchSelectedCertificate(courseId)),
    fetchSurvey: () => dispatch(surveyActions.fetchSurvey()),
    fetchCert: () => dispatch(certActions.fetchCert()),
    linkCertActive: (cert, courseId) =>
      dispatch(certActions.linkCertActive(cert, courseId)),
    updateAttachedSurvey: (survey, courseId) =>
      dispatch(courseActions.updateAttachedSurvey(survey, courseId)),
    updateAttachedHandout: (handout, handout_delivery, courseId) =>
      dispatch(
        courseActions.updateAttachedHandout(handout, handout_delivery, courseId)
      ),
    fetchSurveyResults: (courseId) =>
      dispatch(surveyActions.fetchSurveyResults(courseId)),
    clearSurveyResultCount: (courseId) =>
      dispatch(courseActions.clearSurveyResultCount(courseId)),
    sendProviderNotification: (courseId, message) =>
      dispatch(studentActions.sendProviderNotification(courseId, message)),
    uploadDeleteCourseImage: (blob, course) =>
      dispatch(courseActions.uploadDeleteCourseImage(blob, course)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CourseScreen);
