import Provider from "../../model/Provider";
import Rating from "../../model/Rating";
import { dbName } from "../../globals/ApiKeys";
import { handleErrorResponse } from "../../globals/Error";
import { buildCourses } from "./courses";
import {
  firebaseNameToId,
  stringFieldFilter,
  fromFirebaseDoubleValue,
} from "../../globals/Common";

export const SET_RATING = "SET_RATING";
export const SET_PROVIDER = "SET_PROVIDER";
export const SET_PROVIDER_COURSES = "SET_PROVIDER_COURSES";
export const SET_SELECTED_PROVIDER = "SET_SELECTED_PROVIDER";
export const SET_PROVIDER_SELECTED_COURSE = "SET_PROVIDER_SELECTED_COURSE";
export const SET_FOLLOWED_PROVIDERS = "SET_FOLLOWED_PROVIDERS";

// ------------------------------

export const setSelectedProviderCourse = (course) => {
  return {
    type: SET_PROVIDER_SELECTED_COURSE,
    selected_course: course,
  };
};

// ------------------------------

export const fetchProviders = () => {
  return async (dispatch, getState) => {
    const response = await fetch(
      `https://firestore.googleapis.com/v1/projects/${dbName}/databases/(default)/documents/provider_index`,
      {
        method: "GET",
        headers: {
          "Content-Type": "applications/json",
        },
      }
    );

    if (!response.ok) {
      handleErrorResponse(await response.json());
    }

    const resData = await response.json();

    const loadedProvider = [];
    if (resData && resData.documents) {
      for (const provider of resData.documents) {
        if (provider && provider.name) {
          loadedProvider.push(
            new Provider(
              firebaseNameToId(provider.name),
              provider.fields.name.stringValue,
              provider.fields.logo ? provider.fields.logo.stringValue : "",
              provider.fields.logo ? provider.fields.logo.stringValue : "",
              provider.fields.country
                ? provider.fields.country.stringValue
                : "",
              provider.fields.currency
                ? provider.fields.currency.stringValue
                : "",
              provider.fields.contact_email
                ? provider.fields.contact_email.stringValue
                : "",
              provider.fields.date
                ? new Date(provider.fields.date.timestampValue)
                : new Date(),
              null,
              null,
              provider.fields.about ? provider.fields.about.stringValue : "",
              provider.fields.storefront
                ? provider.fields.storefront.stringValue
                : "",
              provider.fields.about_en
                ? provider.fields.about_en.stringValue
                : "",
              provider.fields.about_en
                ? provider.fields.about_fr.stringValue
                : ""
            )
          );
        }
      }
    }

    dispatch({
      type: SET_PROVIDER,
      provider: loadedProvider,
    });
  };
};

// ------------------------------

export const fetchProvider = (providerId) => {
  return async (dispatch, getState) => {
    const response = await fetch(
      `https://firestore.googleapis.com/v1/projects/${dbName}/databases/(default)/documents/provider_index/${providerId}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "applications/json",
        },
      }
    );

    if (!response.ok) {
      handleErrorResponse(await response.json());
    }

    const resData = await response.json();
    let provider = null;
    if (resData) {
      provider = new Provider(
        firebaseNameToId(resData.name),
        resData.fields.name.stringValue,
        resData.fields.logo ? resData.fields.logo.stringValue : "",
        resData.fields.logo ? resData.fields.logo.stringValue : "",
        resData.fields.country ? resData.fields.country.stringValue : "",
        resData.fields.currency ? resData.fields.currency.stringValue : "",
        resData.fields.contact_email
          ? resData.fields.contact_email.stringValue
          : "",
        resData.fields.date
          ? new Date(resData.fields.date.timestampValue)
          : new Date(),
        null,
        null,
        resData.fields.about ? resData.fields.about.stringValue : "",
        resData.fields.storefront ? resData.fields.storefront.stringValue : "",
        resData.fields.about_en ? resData.fields.about_en.stringValue : "",
        resData.fields.about_en ? resData.fields.about_fr.stringValue : ""
      );
    }

    dispatch({
      type: SET_SELECTED_PROVIDER,
      selected_provider: provider,
    });
  };
};

// ------------------------------

export const fetchProviderCourses = (providerId) => {
  return async (dispatch, getState) => {
    const arrResponse = await fetch(
      `https://firestore.googleapis.com/v1/projects/${dbName}/databases/(default)/documents:runQuery`,
      {
        method: "POST",
        headers: {
          "Content-Type": "applications/json",
        },
        body: JSON.stringify(fetchCourseQuery("courses", providerId)),
      }
    );

    if (!arrResponse.ok) {
      handleErrorResponse(await arrResponse.json());
    }

    const loadedCourses = buildCourses(await arrResponse.json());

    const filtereInactive = loadedCourses.filter(
      (course) => course.activation_status === "active"
    );

    dispatch({
      type: SET_PROVIDER_COURSES,
      courses: filtereInactive.sort((a, b) => {
        if (a.start_date[0] && b.start_date[0]) {
          return a.start_date[0].getTime() > b.start_date[0].getTime() ? 1 : -1;
        }
        return -1;
      }),
    });
  };
};

// ------------------------------

const fetchCourseQuery = (id, providerId) => {
  let structuredQuery = {
    structuredQuery: {
      from: [{ collectionId: id }],
    },
  };

  let condition = {};
  condition = { compositeFilter: { filters: [], op: "AND" } };
  const fieldFilter = stringFieldFilter("provider_id", "EQUAL", providerId);
  condition.compositeFilter.filters.push({ fieldFilter: fieldFilter });

  structuredQuery.structuredQuery.where = condition;
  return structuredQuery;
};

// ------------------------------

export const fetchRating = (providerId) => {
  return async (dispatch, getState) => {
    let resData = null;
    try {
      const response = await fetch(
        `https://firestore.googleapis.com/v1/projects/${dbName}/databases/(default)/documents/provider_rating/${providerId}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "applications/json",
          },
        }
      );

      if (!response.ok) {
        handleErrorResponse(await response.json());
      }

      resData = await response.json();
    } catch (error) {
      // left blank on purpose
    }

    let rating = null;
    if (resData && resData.fields) {
      rating = new Rating(
        resData.fields.rating_count
          ? resData.fields.rating_count.integerValue
          : 0,
        resData.fields.rating_sum
          ? fromFirebaseDoubleValue(
              resData.fields.rating_sum.doubleValue,
              resData.fields.rating_sum.integerValue
            )
          : 0
      );
    }

    dispatch({
      type: SET_RATING,
      rating: rating,
    });
  };
};

// ------------------------------

export const fetchFollowedProviders = () => {
  return async (dispatch, getState) => {
    const userId = getState().auth.userId;
    const token = getState().auth.token;

    const response = await fetch(
      `https://${dbName}.firebaseio.com/provider/${userId}.json?auth=${token}`
    );

    if (!response.ok) {
      handleErrorResponse(await response.json());
    }

    const resData = await response.json();

    const loadedProv = [];
    if (resData) {
      const arrKeys = Object.keys(resData);

      for (const key of arrKeys) {
        if (typeof resData[key] === "object") {
          const provider = resData[key];

          loadedProv.push(
            new Provider(
              key,
              provider.name,
              null,
              provider.logo,
              null,
              null,
              null,
              null,
              provider.notify,
              provider.code
            )
          );
        }
      }
    }

    dispatch({
      type: SET_FOLLOWED_PROVIDERS,
      following: loadedProv,
    });
  };
};
