import React from "react";
import styles from "./Empty.module.css";
import FormButton from "../button/FormButton";
import Lottie from "lottie-react";

const Empty = (props) => {
  return (
    <div className={styles.container} style={props.style ? props.style : null}>
      {props.padding && <div style={{ paddingTop: "30px" }}>&nbsp;</div>}

      <div
        className={props.column ? styles.columnContainer : styles.rowContainer}
      >
        {props.title ? <div className={styles.title}>{props.title}</div> : null}

        {props.image && (
          <img
            className={props.column ? styles.columnImage : styles.image}
            src={props.image}
            alt=""
          />
        )}

        {props.lottie && (
          <Lottie
            animationData={props.lottie}
            loop={props.dontLoop ? false : true}
            className={styles.image}
          />
        )}

        <div>
          <div className={styles.text}>{props.message}</div>

          {props.onClick && (
            <FormButton
              alwaysfull="true"
              startIcon={props.buttonIcon}
              label={props.buttonLabel}
              onClick={props.onClick}
              style={props.column ? { justifyContent: "center" } : null}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default Empty;
