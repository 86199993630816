import React from "react";
import styles from "../Legal.module.css";

export const renderGeneralTermsOfUse = () => {
  return (
    <div>
      <p className={styles.title}>
        <strong>
          <u>GENERAL TERMS AND CONDITIONS</u>
        </strong>
      </p>
      <p>
        The following General Terms and Conditions (the &ldquo;
        <strong>General Terms and Conditions</strong>&rdquo; or &ldquo;
        <strong>GTC</strong>&rdquo;) contain the legal framework governing the
        relationship between ZubU inc. (&ldquo;<strong>ZubU</strong>&rdquo;) and
        all Platform&rsquo;s Users. If a User does not want to accept the
        Contract, it must not access or use the Platform.
      </p>
      <p>
        All Course Providers and Speakers must also agree to the Supplier Terms
        and Conditions, which apply in addition to these General Terms and
        Conditions.
      </p>
      <p>
        <strong>
          By agreeing to these General Terms and Conditions, each User waives
          certain rights to participate in class actions, and to resolve
          disputes with ZubU through binding arbitration (with very limited
          exceptions, not in court), as detailed in Section{" "}
        </strong>
        <strong>9</strong>
        <strong>(Dispute Resolution). </strong>
      </p>
      <ol>
        <li className={styles.listItem}>
          <strong>DEFINITIONS</strong>
        </li>
      </ol>
      <p>
        &ldquo;<strong>Academic Content</strong>&rdquo; means a course or other
        form of content issued by a Course Provider advertised and/or made
        available for registration on the Platform to Students or other Users.
      </p>
      <p>
        &ldquo;<strong>Account</strong>&rdquo; means a User account created and
        maintained on the Platform as per these General Terms and Conditions.
      </p>
      <p>
        &ldquo;<strong>Beneficiary</strong>&rdquo; has the meaning set forth in
        Section 2.1.
      </p>
      <p>
        &ldquo;<strong>Contract</strong>&rdquo; means these General Terms and
        Conditions, the ZubU Policies applicable from time to time and any other
        contract or agreement entered into between ZubU and a User, including,
        without limitation, the Supplier Terms and Conditions if and when
        applicable.
      </p>
      <p>
        &ldquo;<strong>Course Provider</strong>&rdquo; means a university,
        school or other institution, private entity, organization or person
        providing Academic Content on the Platform.
      </p>
      <p>
        &ldquo;<strong>Delivery</strong>&rdquo; means, with respect to Academic
        Content, the performance and delivery of such Academic Content by the
        Course Provider substantially in accordance with the specifications and
        description, at the date and time, advertised on the Platform. With
        respect to online Academic Content which can be viewed by a Student
        during an ongoing period of time, &ldquo;Delivery&rdquo; means that the
        Course Provider has made the Academic Content available to enrolled
        Students substantially in accordance with the specifications and
        description, during the time period, and using the online platform or
        forum advertised on the Platform (including a proper link, access code
        and/or credentials).
      </p>
      <p>
        &ldquo;<strong>Platform</strong>&rdquo; means the ZubU mobile
        application, the ZubU website intended for Course Providers, and any
        other platform associated thereto, such as any website, software or
        other media supplied by ZubU allowing or facilitating the performance of
        Services.
      </p>
      <p>
        &ldquo;<strong>Supplier</strong>&rdquo; means any provider or supplier,
        other than Course Providers and Speakers, who supply products or
        services to ZubU or the Platform; as an example, <em>Stripe</em> is a
        payment service &ldquo;Supplier&rdquo; on the Platform.
      </p>
      <p>
        &ldquo;<strong>Services</strong>&rdquo; means the services provided by
        ZubU on the Platform from time to time, which may include
        (a)&nbsp;providing a database of Academic Content in the field of
        dentistry and dental health&nbsp;; (b)&nbsp;providing an online platform
        for Students to enroll to Academic Content&nbsp;; (c)&nbsp;handling
        registration and payments between Students and Course Providers&nbsp;;
        and/or (d)&nbsp;collecting relevant information to help Students
        retrieving relevant documents and certificates to qualify the Academic
        Content as mandatory professional training.
      </p>
      <p>
        &ldquo;<strong>Speaker</strong>&rdquo; means an individual teacher or
        speaker who performs the Academic Content for a Course Provider.
      </p>
      <p>
        &ldquo;<strong>Students</strong>&rdquo; means professionals and other
        workers in the field of dentistry and dental health care who are
        eligible to and have registered a Student Account on the Platform
        pursuant to these Standard Terms and Conditions, and who have enrolled
        for at least one Academic Content.
      </p>
      <p>
        &ldquo;<strong>Users</strong>&rdquo; means, collectively, Students,
        Speakers, Course Providers and all other users of the Platform, but
        excluding ZubU, its affiliates and their Suppliers and representatives,
        and &ldquo;<strong>User</strong>&rdquo; means any one of them.
      </p>
      <p>
        &ldquo;<strong>ZubU</strong> P<strong>olicies</strong>&rdquo; means the
        policies adopted by ZubU from time to time, such as its Privacy Policy,
        and including any policy or external terms and conditions adopted by
        ZubU in order to use a Supplier product or service, such as the Stripe
        Platform General Terms and Conditions. It may also include Community
        Standards adopted by ZubU and made available on the Platform.
      </p>
      <ol start="2">
        <li className={styles.listItem}>
          <strong>ACCOUNTS</strong>
          <ul>
            <li className={styles.listItem}>
              <strong>User Account</strong>
            </li>
          </ul>
        </li>
      </ol>
      <p>
        Each User needs an Account for most activities and functionalities on
        the Platform and namely to access Services. A User may connect to or
        register under its Account one or more members of its staff or
        professional group, in which case the initial User shall be considered
        the Account owner and the other members are considered &ldquo;
        <strong>Beneficiaries</strong>&rdquo; associated with such Account for
        the purposes of these Standard Terms and Conditions. The Account owner
        is responsible for all Beneficiaries associated with its Account and
        shall ensure their compliance with these Standard Terms and Conditions.
        The Beneficiaries are not considered &ldquo;Users&rdquo; under the
        Contract, and will have no direct access to the Platform, unless they
        also create an Account and accept the terms and conditions of the
        Contract. Credits and other information for the purposes of continuing
        education tracking will only be maintained for the Account owner.
      </p>
      <p>
        Each User is responsible for its Account, for all activities processed
        from its Account and for the security and confidentiality of its
        credentials. If a User suspects wrongful or unauthorized use of its
        credentials or Account, it should contact ZubU immediately.
      </p>
      <p>
        Each User and Beneficiary must be 18 years or older and have reached the
        age required to sign contracts in the country where such User is
        located, in order to create an Account, provide information to ZubU and
        use the Platform. Each User shall provide complete and accurate
        information to set-up the Account and shall update such information over
        time. Each Account is <em>intuitu personae</em> to the User and may not
        be assigned, transferred to a third party or allowed to be used by a
        third party. In order to set-up a Student Account, the User must be
        registered dental professionals, licensed from a professional order,
        board or association recognized by ZubU and provide its license number
        and continuing education credit requirements. ZubU may impose additional
        conditions upon the creation of an Account, which will be disclosed on
        the Platform.
      </p>
      <p>
        To the extent it has no outstanding balance due to ZubU or to another
        User, a User can close its Account at any time by following the steps
        set forth on the Platform, subject to these General Terms and
        Conditions.
      </p>
      <ol start="3">
        <li className={styles.listItem}>
          <strong>ACADEMIC CONTENT ENROLLMENT</strong>
        </li>
      </ol>
      <p>
        ZubU acts as intermediary between the Student and the Course Provider
        regarding the enrollment and payment for Academic Content. Upon
        enrollment and payment on the Platform, ZubU provides all the
        information to the Course Provider and confirms enrollment to the
        Academic Content. The Student will not access, view nor attend the
        Academic Content on the Platform and will have to follow the Course
        Provider&rsquo;s instruction as to the location or online availability
        of the Academic Content.
      </p>
      <p>
        The creation of an Account and the enrollment to Academic Content or
        other purchase on the Platform does not create any license nor authorize
        a User to copy, reproduce, distribute, transmit, assign, sell,
        broadcast, rent, share, lend, modify, adapt, edit, create derivative
        works of, sublicense, or otherwise transfer or use any Academic Content,
        in whole or in part. The terms and conditions of any such license shall
        be those supplied by the Course Provider pursuant to its own standard
        terms and conditions. A User undertakes not to do or attempt to the do
        the foregoing without obtaining prior authorization from the Course
        Provider and ZubU provides no guarantee in this respect.
      </p>
      <ol start="4">
        <li className={styles.listItem}>
          <strong>PAYMENTS, CREDITS AND REFUNDS</strong>
        </li>
      </ol>
      <p>
        In order to make transactions (make or receive payments) on the
        Platform, Users shall use the Platform payment methods, which is
        currently a <em>Stripe</em> account. The use of the Stripe payment
        platform is governed by the <em>Stripe</em> Connected Account Agreement
        (refer to this{" "}
        <a
          className={styles.link}
          href="https://stripe.com/en-ca/connect-account/legal"
        >
          link
        </a>
        ), which includes the <em>Stripe</em> terms of use (together referred to
        as the &ldquo;<strong>Stripe Services Agreement</strong>&rdquo; found at
        this{" "}
        <a className={styles.link} href="https://stripe.com/en-ca/legal">
          link
        </a>
        ). Other payment platforms could eventually be authorized for use on the
        Platform, in ZubU&rsquo;s discretion.
      </p>
      <p>
        Each Account owner must set up and maintain a Stripe account in good
        standing on the Platform, associated with a credit card with sufficient
        capacity to cover its financial obligations pursuant to the Contract.
        Each User may also have to provide identifying information or tax
        documentation requested by ZubU or Stripe.
      </p>
      <ul>
        <li className={styles.listItem}>
          <strong>Pricing</strong>
        </li>
      </ul>
      <p>
        The prices for Academic Content and other Services are as set forth on
        the Platform at the time of enrollment/purchase check out.
      </p>
      <p>
        ZubU may offer promotions or discounts from time to time, as set forth
        on the Platform, which shall not apply to Users who have already
        enrolled in an Academic Content or purchased another Service. If a price
        is no longer advertised or available at the time of enrollment/purchase,
        a User may not claim nor request such price (ex. early bird). Some
        promotions may be available only to new Users or subject to other
        restrictions.
      </p>
      <p>
        In some jurisdiction, sales tax and other taxes and duties may apply to
        the purchase, payment or Services. ZubU is responsible for collecting
        and remitting those taxes. Depending on the jurisdictions, prices may
        include taxes or not, which may appear only at check-out.
      </p>
      <ul>
        <li className={styles.listItem}>
          <strong>Payments Processing</strong>
        </li>
      </ul>
      <p>
        ZubU works with third party payment Suppliers such as <em>Stripe</em> to
        offer convenient payment methods and to keep payment information secure,
        as per the terms and conditions offered by such Suppliers. All payments
        must be completed using the payment Supplier authorized by ZubU in order
        to complete check-out, enrollment to Academic Content and access to
        another Service.
      </p>
      <p>
        Even if a User checks out for multiple transactions at once on the
        Platform, the fees may be charged individually and appear separately for
        each transaction (per Academic Content, per User or per Course
        Provider).
      </p>
      <p>
        ZubU will not be liable for any loss, damage or other liability
        resulting from any payment made through such third party Suppliers,
        other than in case of ZubU&rsquo;s gross fault or negligence or willful
        misconduct.
      </p>
      <ul>
        <li className={styles.listItem}>
          <strong>Academic Content Payment, Refunds and Credits</strong>
        </li>
      </ul>
      <p>
        Each Academic Content enrollment may be subject to different
        cancellation and/or reimbursement policies set forth by the Course
        Provider, which will be identified on the Platform. ZubU will enforce
        and apply such reimbursement policies, as set forth on the Platform at
        the time of enrollment and purchase. There will be no reimbursement or
        cancellation after the Delivery of the Academic Content, other than as
        specifically set forth hereafter.
      </p>
      <p>
        ZubU reserves discretion in the treatment of refund and credit requests
        which are not strictly complying with the Course Provider&rsquo;s
        policies: ZubU is not responsible for the Academic Content material and
        content, for the topics addressed or for the Speaker&rsquo;s skills and
        opinions expressed during the Services. Therefore, ZubU will only refund
        a User for a fraudulent Academic Content, namely if the Academic Content
        was not Delivered, was not in language advertised, was not given by a
        Speaker with minimum qualification or contained advertisement or
        publicity which did not allow any kind of learning experience (i.e.
        sponsored products or affiliation are not sufficient ground for refund
        or credit). In case of abuse or unreasonable request by a User,
        reimbursement or credit will be denied.
      </p>
      <p>
        ZubU reserves the right to grant (a)&nbsp;a credit for future Services
        rather than a refund, (b)&nbsp;a refund on the original payment
        method/Stripe account or (c)&nbsp;any other refund payment method.
      </p>
      <p>
        Payments made on the Platform are irrevocable and are remitted to the
        Course Provider on the earlier of (a)&nbsp;48 hours after the Academic
        Content&rsquo;s Delivery and (b)&nbsp;the expiry of the longest period
        authorized by law and by the payment service Supplier (3 months from
        payment in Canada). After such period, all refunds and credits are
        irrevocably waived by each User. Therefore, any request for refund or
        credit shall be submitted by a User to ZubU no later the earlier of
        (a)&nbsp;24 hours following the Academic Content&rsquo;s Delivery and
        (b)&nbsp;eighty (80) days from the purchase. There will be no refund or
        credit by ZubU after such period, unless the Course Provider has
        provided ZubU with the moneys required to process such refund or credit
        under its own cancellation or reimbursement policy.
      </p>
      <p>
        ZubU is not responsible for a Student or other User incapacity to attend
        Academic Content (including any failure to attend in person, mistake in
        date or location, technology failure or incompatibility.
      </p>
      <ul>
        <li className={styles.listItem}>
          <strong>Gift and Promotional Codes</strong>
        </li>
      </ul>
      <p>
        ZubU, Course Providers, Speakers or other Suppliers or partners may
        offer gift and promotional codes to Students or other Users. Certain
        codes may be redeemed for prepaid credits applied to the ZubU Account,
        which then may be used to purchase eligible Academic Content on the
        Platform. Other codes may be directly redeemable for specific Academic
        Content. Both are and remain subject to the General Terms and Conditions
        and the terms and conditions set forth on or with the promotional codes.
      </p>
      <p>
        The promotional codes and credits, as well as any promotional value
        linked to them, may expire if not used within the period specified on
        the ZubU Platform or on/with the promotional code. Gift and promotional
        codes may not be refunded nor redeemed for cash, unless otherwise
        specified in writing or as required by applicable law. Gift and
        promotional codes offered by a third party are subject to that third
        party&rsquo;s refund policies. If a User has multiple saved credit
        amounts in its Account, ZubU may determine which of such credits apply
        to a purchase.
      </p>
      <ul>
        <li className={styles.listItem}>
          <strong>Currency</strong>
        </li>
      </ul>
      <p>
        The price currency may be based on the location of the User when it
        created its Account or may be fixed by ZubU. The conversion rate will be
        that made available on the Stripe account or any other payment service
        Supplier, at the time of payment. Users are not allowed to view pricing
        in more than one currency or request specific exchange terms or
        conditions.
      </p>
      <ol start="5">
        <li className={styles.listItem}>
          <strong>STANDARDS OF CONDUCT</strong>
          <strong> AND REMEDIES</strong>
          <ul>
            <li className={styles.listItem}>
              <strong>Users Standard of Conduct</strong>
            </li>
          </ul>
        </li>
      </ol>
      <p>
        Each User agrees to be bound by the following with respect to the
        Services and Platform:
      </p>
      <ul>
        <li className={styles.listItem}>
          use the Platform and Services for lawful purposes only, in compliance
          with applicable law, the Contract (including these General Terms and
          Conditions and the ZubU Policies) and all third party intellectual
          property rights;
        </li>
        <li className={styles.listItem}>
          be fully responsible and hold ZubU harmless from all content that such
          User posts on or uploads to the Platform, including reviews,
          questions, posts, Academic Content description, advertisement and
          other content; ZubU will consider all such content as non-confidential
          and non-proprietary;
        </li>
        <li className={styles.listItem}>
          notify ZubU if it is aware of any infringement of its or any third
          party&rsquo;s intellectual property or other right, or breach of the
          Contract;
        </li>
        <li className={styles.listItem}>
          not to access the Platform or Services from a territory where Canadian
          and U.S. businesses are prohibited from engaging in business (such as
          Cuba, Iran, North Korea, Sudan, or Syria) or if the User has been
          designated a Specially Designated National, Denied Person, or Denied
          Entity by the U.S. government;
        </li>
        <li className={styles.listItem}>
          not to upload or post any content or technology (including information
          on encryption) whose export is specially controlled under the Export
          Administration Regulations or other applicable similar laws and
          regulations;
        </li>
        <li className={styles.listItem}>
          not to post or submit content or material that violates a third
          party&rsquo;s intellectual property (ex. as a Student, submit work
          created by someone else);
        </li>
        <li className={styles.listItem}>
          not to post or upload material that contains viruses, trojan horses,
          worms, time bombs or other computer programming routines or engines
          that are intended to damage, detrimentally interfere with,
          surreptitiously intercept or expropriate any system, data or
          information;
        </li>
        <li className={styles.listItem}>
          not to re-sell, license or make any commercial use of the Platform or
          any content posted thereon;
        </li>
        <li className={styles.listItem}>
          not to post, submit or upload content or material that is
          inappropriate or objectionable (ex. impersonating someone else,
          racism, defamation, libelous content or information, sexism, hatred,
          false or misleading information, etc.);
        </li>
        <li className={styles.listItem}>
          ensure that its Beneficiaries and representatives comply with the
          foregoing standards of conduct and other terms and conditions of this
          Contract; and
        </li>
        <li className={styles.listItem}>
          not to facilitate, encourage or participate in any of the above
          conduct.
        </li>
      </ul>
      <p>
        In addition, each User agrees to comply with the following trust and
        safety measures:
      </p>
      <ul>
        <li className={styles.listItem}>
          provide legitimate feedback and reviews, and limiting content to
          matters relevant to the Academic Content and the scope of the
          Services; irrelevant, personal or discriminatory content, as well as
          content which is intended at advertising other content, products or
          services may be removed by ZubU;
        </li>
        <li className={styles.listItem}>
          be transparent about conflicts of interest, example if a study or
          Academic Content has been sponsored or if a comment or other feedback
          is influence by other interests.
        </li>
      </ul>
      <p>
        Users may flag content or reviews if they consider that they are in
        breach of the Contract. ZubU reserves entire discretion regarding the
        removal of such content; an automatic (spam) filter may be used to
        monitor content; if a User believes that its content was removed without
        reasonable ground, it may contact ZubU for investigation and potential
        review of the decision.
      </p>
      <ul>
        <li className={styles.listItem}>
          <strong>Remedies</strong>
        </li>
      </ul>
      <p>
        ZubU may, in the event of (a)&nbsp;breach of these General Terms and
        Conditions; (b)&nbsp;default in payment; (c)&nbsp;insolvency, bankruptcy
        or any similar proceeding regarding a User; (d)&nbsp;upon request from a
        law enforcement authority or other government agency; or (e)&nbsp;upon a
        30 day prior notice in case of extended period(s) of inactivity:
      </p>
      <ul>
        <li className={styles.listItem}>
          close the Account and terminate use of the Services;
        </li>
        <li className={styles.listItem}>
          forbid or incapacitate the creation of a new Account;
        </li>
        <li className={styles.listItem}>
          suspend the Account and/or the use of the Services; or
        </li>
        <li className={styles.listItem}>
          remove Academic Content or other content or material, in whole or in
          part.
        </li>
      </ul>
      <p>
        ZubU may also terminate or suspend an Account or the Services at
        convenience, upon providing a 60 days prior notice, in its entire
        discretion.
      </p>
      <ul>
        <li className={styles.listItem}>
          <strong>Termination by ZubU</strong>
        </li>
      </ul>
      <p>
        Upon termination, ZubU may delete the Account, and prevent the User from
        further access to the Platform, the Account and the use of the Services.
        Content uploaded by a User may still be available on the Platform even
        if the Account is terminated or suspended, or may be deleted by ZubU.
        Personal Information (as defined in the ZubU Privacy Policy) will be
        deleted (unless ZubU is required under applicable law to keep the
        information).
      </p>
      <ul>
        <li className={styles.listItem}>
          <strong>Indemnity</strong>
        </li>
      </ul>
      <p>
        Each User agrees to indemnify, defend and hold ZubU and its affiliates,
        officers, directors, suppliers, partners and agents harmless from and
        against all liabilities, claims, demands, losses, damages, or expenses
        (including reasonable attorney fees), and third party claims arising
        from (a)&nbsp;content posted, submitted or advertised by such User
        (including Academic Content) on the Platform; (b)&nbsp;such User&rsquo;s
        use of the Services; (c)&nbsp;a breach of the Contract, including ZubU
        Policies; or (d)&nbsp;a violation of any rights of a third party. The
        foregoing indemnification obligation will survive the termination of the
        Contract and the User&rsquo;s use of or access to the Services.
      </p>
      <ol start="6">
        <li className={styles.listItem}>
          <strong>INTELLECTUAL PROPERTY</strong>
          <ul>
            <li className={styles.listItem}>
              <strong>Content</strong>
            </li>
          </ul>
        </li>
      </ol>
      <p>
        Each party to this Contract, namely ZubU and the Users, retains
        ownership to the content posted or uploaded to the Platform.
      </p>
      <p>
        The Course Provider remains at all times the exclusive owner of the
        Academic Content and other products or services advertised on the
        Platform and is responsible to manage and secure the proper legal
        framework applicable between it and its Speakers and Students.
      </p>
      <p>
        Each User grants ZubU a non-exclusive, royalty-free, worldwide,
        perpetual and irrevocable license to use, copy, reproduce, process,
        adapt, modify, publish, transmit, display, and distribute the content
        submitted or uploaded to the Platform. Such license shall be
        sublicensable and give the right to treat and use the content for any
        purpose whatsoever, subject however to ZubU&rsquo;s Privacy policy and
        the limitations set forth in the Supplier Terms and Conditions with
        respect to Academic Content. ZubU is therefore allowed to share,
        display, reproduce, summarize, translate and communicate each
        User&rsquo;s content (including comments, questions, reviews, ideas and
        suggestions) to anyone through any media, including (a)&nbsp;promoting
        it via advertising on other websites, and (b)&nbsp;making such content
        available to other companies, organizations, or individuals who partner
        with ZubU for the syndication, broadcast, distribution, or publication
        of content on other media. Each User waives its moral rights with
        respect to such content, to the extent allowed under applicable law, and
        represents and warrants to ZubU that it has the right, power and
        authority to share such content and grant the foregoing license.
      </p>
      <ul>
        <li className={styles.listItem}>
          <strong>Platform</strong>
        </li>
      </ul>
      <p>
        ZubU remains all at times the sole and exclusive owner of the Platform,
        of all features and development thereto, and of all intellectual
        property underlying or relating to it, including the websites, mobile
        applications, logos, API, code, technology, know how, the general
        layout, design and original content, etc.
      </p>
      <p>
        To the extent a User contributes to the Platform, for example, through a
        new feature or improvement suggestion, it automatically assigns to ZubU
        all rights to such development or suggestion and waives all moral rights
        thereto. ZubU shall have discretion to use, share, distribute, promote,
        modify or edit such development, on any media whatsoever.
      </p>
      <p>
        A User may not tamper with ZubU&rsquo;s intellectual property rights,
        including all copyrights, trademarks, technology, know-how or invention
        (whether patentable or not) and namely:
      </p>
      <ul>
        <li className={styles.listItem}>
          access, tamper with, or use non-public areas of the Platform,
          ZubU&rsquo;s computer systems, or the technical delivery systems of
          ZubU&rsquo;s service Suppliers;
        </li>
        <li className={styles.listItem}>
          disable, interfere with, or try to circumvent any of the features of
          the Platform related to security, privacy and anti-piracy, or probe,
          scan, or test the vulnerability of any of ZubU&rsquo;s systems;
        </li>
        <li className={styles.listItem}>
          copy, modify, create a derivative work of, reverse engineer, reverse
          assemble, or otherwise attempt to discover any source code of or
          content on the ZubU Platform or Services;
        </li>
        <li className={styles.listItem}>
          access, search or attempt to access or search the Platform by any
          means (automated or otherwise) other than through the available search
          functionalities;
        </li>
        <li className={styles.listItem}>
          scrape, spider, use a robot, or use other automated means of any kind
          to access the Services;
        </li>
        <li className={styles.listItem}>
          use the Services to send altered, deceptive, or false
          source-identifying information (such as sending email communications
          falsely appearing as ZubU&rsquo;s);
        </li>
        <li className={styles.listItem}>
          interfere with, or disrupt, (or attempt to do so), the access of any
          User, host, or network, including, without limitation, sending a
          virus, overloading, flooding, spamming, or mail-bombing the platforms
          or services, or in any other manner interfering with or creating an
          undue burden on the Services or Platform; or
        </li>
        <li className={styles.listItem}>
          use ZubU&rsquo;s legal or business name, trademark and logo for any
          purpose whatsoever other than as expressly authorized pursuant to the
          Contract.
        </li>
      </ul>
      <ol start="7">
        <li className={styles.listItem}>
          <strong>LIMITATION OF LIABILITY</strong>
        </li>
      </ol>
      <p>
        The use of the Platform and reliance on Academic Content and other
        Users&rsquo; content is at each User&rsquo;s own risk.
      </p>
      <p>
        ZubU does not review, edit nor control Academic Content and is not in a
        position to determine the legality of the content. ZubU does not
        exercise editorial control over the Academic Content and may no exercise
        such control over other Users&rsquo; content available on the Platform
        and, as such, does not guarantee in any manner the reliability,
        validity, accuracy, or truthfulness of the content.
      </p>
      <ul>
        <li className={styles.listItem}>
          <strong>Technical Issues</strong>
        </li>
      </ul>
      <p>
        It may happen that the Platform is down, either for planned maintenance
        or because of technical, security or technological issues. ZubU reserves
        the right to cease making available certain features of the Services at
        any time and for any reason. Under no circumstances will ZubU or its
        affiliates, suppliers, partners or agents be held liable for any damages
        due to such interruptions or lack of availability of such features.
      </p>
      <ul>
        <li className={styles.listItem}>
          <strong>Force Majeure</strong>
        </li>
      </ul>
      <p>
        ZubU is not responsible for delay or failure in its performance of any
        of the Services caused by events beyond its reasonable control, like a
        force majeure, an act of war, hostility, sabotage, natural disaster,
        electrical, Internet, or telecommunication outage or failure, pandemic,
        change in law or government restriction.
      </p>
      <ul>
        <li className={styles.listItem}>
          <strong>User Content</strong>
        </li>
      </ul>
      <p>
        By using the Services, a User will encounter and be exposed to
        third-party content. A User may consider such content offensive,
        indecent, or objectionable, or it may happen that a Course Provider,
        Speaker or other User provides false, misleading or inaccurate
        statements. ZubU has no responsibility to remove such content and no
        liability for a User choice of, enrollment in or access to any Academic
        Content or other content.
      </p>
      <p>
        When ZubU is notified by a User that some content might be illegal,
        breach applicable law or violate third party rights, it reserves the
        right to investigate, remove the content or apply any other remedy
        available under the Contract and applicable law.
      </p>
      <p>
        Users may have the option to interact with other Users on the Platform,
        including communications between Students. Each User shall be careful
        about the type of personal information shared, since ZubU is not liable
        for the treatment of such information shared wilfully to another User
        nor for files transferred, emailed or otherwise communicated to another
        User outside the Platform. ZubU is not liable for disputes, claims,
        losses, injuries, or damage of any kind that might arise out of or
        relate to the conduct of Course Providers, Speakers, Students and other
        Users.
      </p>
      <p>
        The foregoing limitation are applicable to the extent allowed under
        applicable law.
      </p>
      <ul>
        <li className={styles.listItem}>
          <strong>Academic Content</strong>
        </li>
      </ul>
      <p>
        The Academic Content and other content advertised on the Platform are
        offered for purposes of education, general information and discussion
        and is not meant to substitute a professional&rsquo;s own clinical
        judgement. ZubU does not represent nor warrant that it is accurate,
        adequate or complete or that it reflects the most current state of
        science and Users are encouraged to confirm and compare it with other
        sources before undertaking treatment, service or diagnostic. The
        Academic Content and other content advertised on the Platform is not
        intended to replace medical or technical knowledge, judgment, and care.
        Users should not rely only on the Academic Content and other content
        advertised on the Platform to attempt to remedy, cure, treat, diagnose,
        or otherwise intervene with respect to any medical or health condition
        or situation. A User assumes full responsibility for the choices made
        before, during, and after its enrollment in Academic Content and other
        use of the Services.
      </p>
      <ul>
        <li className={styles.listItem}>
          <strong>Continuing Education Requirements</strong>
        </li>
      </ul>
      <p>
        ZubU does not warrant that any Academic Content will be eligible to
        fulfill continuing education or other education requirements by a
        professional order, board or association or give the right to credit
        from any education institution. ZubU only acts as an intermediary to
        help Students save and access information about the Academic Content
        attended. Each Student should keep a copy of the original certificates
        evidencing their participation in Academic Content, in case of audit.
      </p>
      <ul>
        <li className={styles.listItem}>
          <strong>Third Party Content</strong>
        </li>
      </ul>
      <p>
        The Platform may contain links to other websites or platform that are
        not owned or controlled by ZubU. ZubU is not responsible for the content
        or any other aspect of these third-party sites or platforms, including
        their collection of information about a User. Each User should read such
        third party&rsquo;s general terms and conditions and privacy policies.
        ZubU assumes no responsibility or liability arising from such
        third-party content, sites or platforms.
      </p>
      <ul>
        <li className={styles.listItem}>
          <strong>Security and Copyright</strong>
        </li>
      </ul>
      <p>
        ZubU values its Users&rsquo; copyright and is committed to help
        safeguard it, using anti-piracy technology. For a motivated pirate or
        other person, any content is vulnerable to theft and ZubU considers the
        issue unavoidable. ZubU uses software and technology to identify theft
        and unauthorized access and provides its best effort to limit their
        impact on Users.
      </p>
      <p>
        Each User is responsible to identify whether it is entitled to use third
        party content or ideas. ZubU does not advise on copyright issue nor take
        responsibility for material uploaded on or to the Platform. All
        reporting for copyright infringement will be taken seriously and
        investigated by ZubU if submitted via the form available on the Platform
        and if all required information is provided. If and when appropriate,
        ZubU may remove infringing content, without any obligation to do so
        other than under applicable law or an order of a competent governmental
        or judicial authority. Counter-notification procedures are also
        available by contacting ZubU to contest the removal of content. Repeated
        infringement will lead to termination of a User&rsquo;s Account.
      </p>
      <ul>
        <li className={styles.listItem}>
          <strong>Disputes between Users</strong>
        </li>
      </ul>
      <p>
        ZubU encourages Course Providers, Speakers and Students to try and
        settle conflicts among themselves and will not intervene in dispute
        settlements.
      </p>
      <p>
        Users shall contact Course Providers directly if they have any question
        regarding Academic Content or the standard terms and conditions
        applicable to their supply.
      </p>
      <ul>
        <li className={styles.listItem}>
          <strong>Limitation of Liability</strong>
        </li>
      </ul>
      <p>
        The Services and their content are provided on an &ldquo;as is&rdquo;
        and &ldquo;as available&rdquo; basis. ZubU (and its affiliates,
        suppliers, partners, and agents) make no representations or warranties,
        whether expressed or implied (including legal warranty under the Civil
        code of Quebec) about the suitability, reliability, availability,
        timeliness, security, lack of errors, or accuracy of the Services or
        their content, and expressly disclaim any warranties or conditions
        (express or implied), including implied warranties of merchantability,
        fitness for a particular purpose, title, and non-infringement. ZubU (and
        its affiliates, suppliers, partners, and agents) make no warranty that a
        User will obtain specific results from use of the Services. A
        User&rsquo;s access and use of the Services (including any content) is
        entirely at its own risk. ZubU makes no warranty that the Platform or
        Service will be error-free or free from interruptions or other failures
        or that they will meet any User revenues and other expectations.
      </p>
      <p>
        <strong>
          To the extent permitted by law, ZubU (and its affiliates, suppliers,
          partners, and agents) will not be liable for any indirect, incidental,
          punitive, or consequential damages (including loss of data, revenue,
          profits, or business opportunities, or personal injury or death),
          whether arising in contract, warranty, extra-contractual liability,
          tort, negligence, product liability, or otherwise, and even if
          it&rsquo;s been advised of the possibility of such damages in advance.
        </strong>
      </p>
      <p>
        <strong>
          ZubU&rsquo;s aggregate liability (and the liability of each of its
          affiliates, suppliers, partners and agents) to a User and its
          stakeholders under any circumstance is limited to the amount such User
          or third party has paid to ZubU for Services in the twelve (12) months
          before the event giving rise to the claim.{" "}
        </strong>
      </p>
      <p>
        Any warranty provided under the Contract is void if the User
        (a)&nbsp;has modified or tempered with the Platform or Service,
        (b)&nbsp;has otherwise failed to comply with the Contract; (c)&nbsp;uses
        hardware, software or connection which is incompatible with the Platform
        or Service; or (d)&nbsp;misuses the Platform or Service.
      </p>
      <p>
        <strong>
          The foregoing limitation of liability are subject to applicable laws
          and may not apply to all circumstances if some jurisdictions
          don&rsquo;t allow their execution, for example, exclusion of
          consequential or incidental damages, exclusion of implied warranties
          or limitation of liability for bodily injury or death.{" "}
        </strong>
      </p>
      <ol start="8">
        <li className={styles.listItem}>
          <strong>MISCELLANEOUS</strong>
          <ul>
            <li className={styles.listItem}>
              <strong>Binding Agreement</strong>
            </li>
          </ul>
        </li>
      </ol>
      <p>
        Each User agrees that by registering, accessing, or using the Services,
        it enters into a legally binding contract with ZubU. If a User does not
        agree to these General Terms and Conditions, it shall not register,
        access, or otherwise use any of the Services.
      </p>
      <ul>
        <li className={styles.listItem}>
          <strong>Language</strong>
        </li>
      </ul>
      <p>
        To the extent permitted under applicable law, any version of these
        General Terms and Conditions in a language other than English is
        provided for convenience and the English language will control if there
        is any conflict. In the Province of Quebec, the French version shall
        govern if and when required under applicable law.
      </p>
      <ul>
        <li className={styles.listItem}>
          <strong>Severability</strong>
        </li>
      </ul>
      <p>
        If any part of the Contract is found to be invalid or unenforceable
        under applicable law, then that provision will be deemed superseded by a
        valid, enforceable provision that most closely matches the intent of the
        original provision and the remainder of the Contract will continue in
        full force and effect.
      </p>
      <ul>
        <li className={styles.listItem}>
          <strong>Waiver</strong>
        </li>
      </ul>
      <p>
        Even if ZubU is delayed or fails to exercise a right in one
        circumstance, it doesn&rsquo;t constitute a waiver of its rights under
        these General Terms and Conditions, and it may decide to enforce them in
        the future. A waiver shall be given by ZubU expressly in writing.
      </p>
      <ul>
        <li className={styles.listItem}>
          <strong>Survival</strong>
        </li>
      </ul>
      <p>
        The following sections shall survive the expiration or termination of
        these General Terms and Conditions: Section 4.3 and 4.5 and Articles 5,
        6, 7, 8 and 9.
      </p>
      <ul>
        <li className={styles.listItem}>
          <strong>Governing Law and Jurisdiction</strong>
        </li>
      </ul>
      <p>
        The Contract is governed by the laws of the Province of Quebec, Canada,
        without reference to its choice or conflict of law principles.
      </p>
      <ul>
        <li className={styles.listItem}>
          <strong>Relationship</strong>
        </li>
      </ul>
      <p>
        The Services and the Contract do not create any joint venture,
        partnership, employment, contractor, or agency relationship exists
        between a User and ZubU.
      </p>
      <ul>
        <li className={styles.listItem}>
          <strong>Assignment</strong>
        </li>
      </ul>
      <p>
        A User may not assign or transfer its rights or obligation under the
        Contract (or the rights and licenses granted under them, including an
        Account).
      </p>
      <p>
        ZubU may assign these General Terms and Conditions (or the rights and
        licenses granted under them) to another company or person without
        restriction.
      </p>
      <ol start="9">
        <li className={styles.listItem}>
          <strong>DISPUTE RESOLUTION</strong>
          <ul>
            <li className={styles.listItem}>
              <strong>Notice of Claim and Settlement</strong>
            </li>
          </ul>
        </li>
      </ol>
      <p>
        A User shall notify ZubU within 1 year in the event any cause of action
        accrues, by registered or certified mail return receipt requested, or
        email at{" "}
        <a className={styles.link} href="mailto:legal@zubueducation.com">
          legal@zubueducation.com
        </a>
        . Failure to do so shall constitute irrevocable waiver of the claim. If
        there&rsquo;s a dispute, ZubU is available to help resolve the issue
        amicably.
      </p>
      <ul>
        <li className={styles.listItem}>
          <strong>Arbitration</strong>
        </li>
      </ul>
      <p>
        If a dispute or claim cannot be resolved amicably, each User and ZubU
        agree to submit it to the exclusive jurisdiction of final and binding
        arbitration. The arbitration shall take place in English (or if the User
        is in the Province of Quebec, in French if he so requests), in the city
        of Montreal, in front of a single arbitrator appointed by ZubU. If the
        User contests the arbitrator appointment, it shall request its
        appointment by a Quebec tribunal, district of Montreal, in accordance
        with the Quebec Code of civil Procedure. If the arbitrator deems it
        suitable, the arbitration will be conducted by telephone, online, or
        based solely on written submissions. Any award rendered may be entered
        in any court with competent jurisdiction.
      </p>
      <p>
        Notwithstanding the foregoing, small claims can be bought in small
        claims courts in the Province of Quebec, district of Montreal.
      </p>
      <ul>
        <li className={styles.listItem}>
          <strong>Alternative Forum</strong>
        </li>
      </ul>
      <p>
        To the extent the foregoing arbitration cause is invalid, the claim,
        dispute or litigation shall be brought in the exclusive jurisdiction of
        Quebec, Canada courts, in the district of Montreal.
      </p>
      <ul>
        <li className={styles.listItem}>
          <strong>No Class Actions</strong>
        </li>
      </ul>
      <p>
        ZubU and all Users agree that it can each only bring claims against the
        other on an individual basis. This means: (a)&nbsp;neither can bring a
        claim as a plaintiff or class member in a class action, consolidated
        action, or representative action; (b)&nbsp;an arbitrator or tribunal
        can&rsquo;t combine multiple people&rsquo;s claims into a single case
        (or preside over any consolidated, class, or representative action); and
        (c)&nbsp;an arbitrator&rsquo;s or tribunal&rsquo;s decision or award in
        one person&rsquo;s case can only impact that User, not other Users, and
        can&rsquo;t be used to decide other Users&rsquo; disputes. If a court
        decides that this &ldquo;No class actions&rdquo; clause isn&rsquo;t
        enforceable or valid, then this Section 9.3 will be null and void, but
        the rest of the Contract will still apply.
      </p>
      <ol start="10">
        <li className={styles.listItem}>
          <strong>
            CHANGES AND UPDATES TO THE GENERAL TERMS AND CONDITIONS
          </strong>
        </li>
      </ol>
      <p>
        ZubU may, from time to time, update these General Terms and Conditions
        and the ZubU Policies to clarify the practices or to reflect new or
        different practices (such as new features), and ZubU reserves the right
        in its sole discretion to modify and/or make changes to these General
        Terms and Conditions and the ZubU Policies at any time. ZubU will
        provide notice upon issuing a change, such as email notice sent to the
        email address specified in the User Account or by posting a notice on
        the Platform. Modifications will become effective on the day they are
        posted unless stated otherwise.
      </p>
      <p>
        A User&rsquo;s continued use of the Services after changes become
        effective shall mean that the User accepts those changes. Any revised
        General Terms and Conditions shall supersede all previous General Terms
        and Conditions.
      </p>
      <p>
        Notwithstanding the foregoing, to the extent required under applicable
        law, if ZubU changes Article 9, a User may reject any such change by
        providing ZubU written notice of such rejection by mail or hand delivery
        at the address set forth in Article 11, within 30 days of the date such
        change became effective. To be effective, the notice must include the
        User full name and clearly indicate its intent to reject changes to this
        "Dispute Resolution" section. By rejecting changes, the User will submit
        any dispute or litigation in accordance with the provisions of Article 9
        prior to the objected change.
      </p>
      <ol start="11">
        <li className={styles.listItem}>
          <strong>CONTACT</strong>
        </li>
      </ol>
      <div className={styles.address}>
        <p>
          <strong>
            ZubU inc.
            <br />
          </strong>
          11655 Rue Letellier
          <br />
          Montreal, Quebec
          <br />
          H3M 2Z7 Canada
        </p>
        <p>
          Customer service is available at{" "}
          <a
            className={styles.link}
            href="mailto:customerservice@zubueducation.com"
          >
            customerservice@zubueducation.com
          </a>
        </p>
      </div>
      <p>
        A User may be reached at the contact information supplied on its Account
        or using the communication features of the Platform.
      </p>
      <p>&nbsp;</p>
      <p>&nbsp;</p>
    </div>
  );
};
