import React from "react";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import LockOpenIcon from "@material-ui/icons/LockOpen";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { makeStyles } from "@material-ui/core/styles";
import AccountCircleOutlinedIcon from "@material-ui/icons/AccountCircleOutlined";
import WebOutlinedIcon from "@material-ui/icons/WebOutlined";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import RecentActorsIcon from "@material-ui/icons/RecentActors";
import FiberNewIcon from "@material-ui/icons/FiberNew";

// ------------------------------

const useStyles = makeStyles({
  paper: {
    background: "#ECECEE",
    border: 0,
    boxShadow: "0px 0px 15px gray",
  },
  active: {
    backgroundColor: "rgb(198, 206, 212)",
    color: "black",
    "&:hover": {
      backgroundColor: "rgb(198, 206, 212)",
    },
  },
});

// ------------------------------

const SettingMenu = (props) => {
  const styles = useStyles();
  const dense = true;

  const isActive = (path) => {
    return props.history.location.pathname === path ? true : false;
  };

  const navTo = (navUri) => {
    if (navUri) {
      props.history.push(navUri);
    }
  };

  return (
    <List
      style={{
        top: 0,
        background: "linear-gradient(rgba(41, 90, 122, 0.2), white)",
        boxShadow: "0 8px 18px 0 rgba(0, 0, 0, 0.2)",
        overflow: "hidden",
      }}
    >
      <ListItem
        dense={dense}
        button
        onClick={() => {
          navTo("/settings");
        }}
        className={isActive("/settings") ? styles.active : null}
      >
        <ListItemIcon>
          <AccountCircleOutlinedIcon style={{ color: "#437aa1" }} />
        </ListItemIcon>
        <ListItemText primary="Account" />
      </ListItem>
      <Divider />

      <ListItem
        dense={dense}
        button
        onClick={() => {
          navTo("/settings/profile");
        }}
        className={isActive("/settings/profile") ? styles.active : null}
      >
        <ListItemIcon>
          <RecentActorsIcon style={{ color: "#437aa1" }} />
        </ListItemIcon>
        <ListItemText primary="Profile" />
        <FiberNewIcon style={{ color: "red" }} />
      </ListItem>
      <Divider />

      <ListItem
        dense={dense}
        button
        onClick={() => {
          navTo("/settings/plugin");
        }}
        className={isActive("/settings/plugin") ? styles.active : null}
      >
        <ListItemIcon>
          <WebOutlinedIcon style={{ color: "#437aa1" }} />
        </ListItemIcon>
        <ListItemText primary="Website Plugin" />
        &nbsp;&nbsp;
        <FiberNewIcon style={{ color: "red" }} />
      </ListItem>
      <Divider />

      <ListItem
        dense={dense}
        button
        onClick={() => {
          navTo("/settings/resetpassword");
        }}
        className={isActive("/settings/resetpassword") ? styles.active : null}
      >
        <ListItemIcon>
          <LockOpenIcon style={{ color: "#437aa1" }} />
        </ListItemIcon>
        <ListItemText primary="Reset Password" />
      </ListItem>
      <Divider />

      <ListItem
        dense={dense}
        button
        onClick={() => {
          navTo("/settings/help");
        }}
        className={isActive("/settings/help") ? styles.active : null}
      >
        <ListItemIcon>
          <HelpOutlineIcon style={{ color: "#437aa1" }} />
        </ListItemIcon>
        <ListItemText primary="Help" />
      </ListItem>
      <Divider />
    </List>
  );
};

export default SettingMenu;
