import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCreditCard } from "@fortawesome/free-solid-svg-icons";
import {
  faCcMastercard,
  faCcVisa,
  faCcAmex,
} from "@fortawesome/free-brands-svg-icons";
import { CURRENCY, COUNTRY } from "./Data";

import cover1 from "../assets/images/inApp/cover1.jpg";
import cover2 from "../assets/images/inApp/cover2.jpg";
import cover3 from "../assets/images/inApp/cover3.jpg";
import cover4 from "../assets/images/inApp/cover4.jpg";
import cover5 from "../assets/images/inApp/cover5.jpg";
import cover6 from "../assets/images/inApp/cover6.jpg";
import cover7 from "../assets/images/inApp/cover7.jpg";
import cover8 from "../assets/images/inApp/cover8.jpg";
import cover9 from "../assets/images/inApp/cover9.jpg";

// ------------------------------

export const shuffleArray = (array) => {
  if (array) {
    for (var i = array.length - 1; i > 0; i--) {
      var j = Math.floor(Math.random() * (i + 1));
      var temp = array[i];
      array[i] = array[j];
      array[j] = temp;
    }
  }
  return array;
};

// ------------------------------

export const toDollar = (cents) => {
  return cents / 100;
};

// ------------------------------

export const resolveCurrency = (amount, amountCurrency, rates) => {
  if (rates && rates.rates) {
    return roundAmount(
      amount * (1 / rates.rates[amountCurrency ? amountCurrency : "CAD"])
    );
  }

  return amount;
};

// ------------------------------

export const findCurrency = (currencyId) => {
  return CURRENCY.find((c) => c.id === currencyId);
};

// ------------------------------

export const resolveCurrencySymbol = (currencyId) => {
  const currency = CURRENCY.find((c) => c.id === currencyId);

  if (currency) {
    return currency.shortName;
  }

  return "";
};

// ------------------------------

export const resolveCurrencyIso = (currencyId) => {
  return CURRENCY.find((c) => c.id === currencyId).iso;
};

// ------------------------------

export const resolveCountry = (country) => {
  if (COUNTRY) {
    return COUNTRY.find((c) => c.country === country);
  }
};

// ------------------------------

export const resolveCc = (brand, size) => {
  const ccStyle = { fontSize: size, color: "#437aa1" };
  if (brand === "amex") {
    return <FontAwesomeIcon icon={faCcAmex} style={ccStyle} />;
  } else if (brand === "visa") {
    return <FontAwesomeIcon icon={faCcVisa} style={ccStyle} />;
  } else if (brand === "mastercard") {
    return <FontAwesomeIcon icon={faCcMastercard} style={ccStyle} />;
  } else {
    return <FontAwesomeIcon icon={faCreditCard} style={ccStyle} />;
  }
};

// ------------------------------

export const fromFirebaseNumberValue = (value) => {
  return value ? parseInt(value) : 0;
};

// ------------------------------

export const fromFirebaseDoubleValue = (value1, value2) => {
  return value1
    ? parseFloat(value1)
    : value2
      ? fromFirebaseNumberValue(value2)
      : 0;
};

// ------------------------------

export const fromFirebaseDoubleArrayValue = (objectArray) => {
  let array = [];

  if (typeof objectArray !== "undefined" && Array.isArray(objectArray)) {
    for (var obj of objectArray) {
      array.push(fromFirebaseDoubleValue(obj.doubleValue, obj.integerValue));
    }
  }
  return array;
};

// ------------------------------

export const fromFirebaseStringArrayValue = (objectArray) => {
  let array = [];

  if (typeof objectArray !== "undefined" && Array.isArray(objectArray)) {
    for (var obj of objectArray) {
      array.push(obj.stringValue);
    }
  }
  return array;
};

// ------------------------------

export const fromFirebaseDateArrayValue = (objectArray) => {
  let array = [];

  if (typeof objectArray !== "undefined" && Array.isArray(objectArray)) {
    for (var obj of objectArray) {
      array.push(new Date(obj.timestampValue));
    }
  }

  return array;
};

// ------------------------------

export const toFirebaseStringArrayValueUsingKey = (array, key) => {
  let firebaseArray = [];
  for (var v of array) {
    firebaseArray.push({ stringValue: v[key] });
  }

  return firebaseArray;
};

// ------------------------------

export const toFirebaseStringArrayValue = (array) => {
  let firebaseArray = [];

  if (array) {
    for (var v of array) {
      firebaseArray.push({ stringValue: v });
    }
  }

  return firebaseArray;
};

// ------------------------------

export const toFirebaseDateArrayValue = (array) => {
  let firebaseArray = [];
  if (typeof array !== "undefined") {
    for (var v of array) {
      firebaseArray.push({ timestampValue: v });
    }
  }

  return firebaseArray;
};

// ------------------------------

export const toFirebaseDoubleArrayValue = (array) => {
  let firebaseArray = [];
  if (typeof array !== "undefined") {
    for (var v of array) {
      firebaseArray.push({ doubleValue: v });
    }
  }

  return firebaseArray;
};

// ------------------------------

export const doubleFieldFilter = (fieldId, opType, filedValue) => {
  return {
    field: { fieldPath: fieldId },
    op: opType,
    value: { doubleValue: filedValue },
  };
};

// ------------------------------

export const timestampFieldFilter = (fieldId, opType, filedValue) => {
  return {
    field: { fieldPath: fieldId },
    op: opType,
    value: { timestampValue: filedValue },
  };
};

// ------------------------------

export const stringFieldFilter = (fieldId, opType, filedValue) => {
  return {
    field: { fieldPath: fieldId },
    op: opType,
    value: { stringValue: filedValue },
  };
};

// ------------------------------

export const booleanFieldFilter = (fieldId, opType, filedValue) => {
  return {
    field: { fieldPath: fieldId },
    op: opType,
    value: { booleanValue: filedValue },
  };
};

// ------------------------------

export const resolveCreditCardBrand = (brand) => {
  if (brand === "Visa") {
    return "cc-visa";
  }
  if (brand === "MasterCard") {
    return "cc-mastercard";
  }
  if (brand === "Amex") {
    return "cc-amex";
  }
  return "credit-card";
};

// ------------------------------

export const notEmpty = (object) => {
  return Object.keys(object).length > 0;
};

// ------------------------------

export const empty = (object) => {
  return Object.keys(object).length <= 0;
};

// ------------------------------

export const isEqual = (a, b) => {
  var aProps = Object.getOwnPropertyNames(a);
  var bProps = Object.getOwnPropertyNames(b);

  if (aProps.length !== bProps.length) {
    return false;
  }

  for (var i = 0; i < aProps.length; i++) {
    var propName = aProps[i];

    if (a[propName] !== b[propName]) {
      return false;
    }
  }

  return true;
};

// ------------------------------

// for category multi select component
export const buildCategory = (categories, same) => {
  if (categories) {
    return categories.map((value, index) => {
      return {
        value: value,
        label: same ? value : value.charAt(0).toUpperCase() + value.slice(1),
      };
    });
  }

  return [];
};

// ------------------------------

export const buildKeyValueSelect = (arrayOfKeyValue, keyId, valueId) => {
  return arrayOfKeyValue.map((object, index) => {
    return {
      value: object[keyId],
      label: object[valueId],
    };
  });
};

// ------------------------------

export const buildKeyValueSelectProf = (arrayOfKeyValue, keyId) => {
  return arrayOfKeyValue.map((object, index) => {
    return {
      value: object[keyId],
      label: object["prefix"] + " " + object["first"] + " " + object["name"],
    };
  });
};

// ------------------------------

export const buildSelectFromIdArray = (
  idArray,
  objectToFindLabel,
  labelKey
) => {
  return idArray.map((id, index) => {
    const object = objectToFindLabel.find((o) => id === o.id);
    let label = null;
    if (object) {
      label = object[labelKey];
    }

    return {
      value: id,
      label: label ? label : id,
    };
  });
};

// ------------------------------

export const buildSingleSelect = (value) => {
  if (value || value === 0) {
    if (value === "N/A") {
      return {
        value: -1 + "",
        label: value + "",
      };
    }
    if (value === -1 || value === "-1") {
      return {
        value: value + "",
        label: "N/A",
      };
    } else if (value === -2 || value === "-2") {
      return {
        value: value + "",
        label: "Unlimited",
      };
    } else {
      return {
        value: value + "",
        label: value + "",
      };
    }
  }

  return null;
};

// ------------------------------

// for course dates component
export const buildCourseDates = (
  start_date_desc,
  start_date,
  end_date,
  timezoneAbbr
) => {
  let datesArr = [];
  if (
    Array.isArray(start_date_desc) &&
    Array.isArray(start_date) &&
    Array.isArray(end_date)
  ) {
    for (var i = 0; i < start_date.length; i++) {
      datesArr.push({
        desc: start_date_desc[i],
        from: start_date[i],
        to: end_date[i],
        timezoneAbbr: timezoneAbbr,
      });
    }
  }
  return datesArr;
};

// ------------------------------

export const findTitle = (courses, courseId) => {
  const course = courses.find((c) => c.id === courseId);
  return course ? course.title : "";
};

// ------------------------------

export const findLabel = (selectIdValues, value) => {
  const object = selectIdValues.find((c) => c.value === value);
  return object ? object.label : "";
};

// ------------------------------

export const findSelect = (list, selectValue) => {
  const value = list.find((select) => select.value === selectValue);
  return value ? value : list[0];
};

// ------------------------------

export const findSelectNoFallback = (list, selectValue) => {
  const value = list.find((select) => select.value === selectValue);
  return value ? value : null;
};

// ------------------------------

export const updateObject = (oldObject, updatedValues) => {
  return {
    ...oldObject,
    ...updatedValues,
  };
};

// ------------------------------

export const generateToken = () => {
  return "TID-" + Date.now() + "-" + Math.floor(Math.random() * 1000000);
};

// ------------------------------

export const generateRandom = () => {
  return Math.floor(Math.random() * 1000 + 1);
};

// ------------------------------

export const compareArray = (arr1, arr2) => {
  return JSON.stringify(arr1.sort()) === JSON.stringify(arr2.sort());
};

// ------------------------------

export const centToDollar = (value) => {
  return (value / 100).toFixed(2);
};

// ------------------------------

export const centToDollarNoDecimal = (value) => {
  return formatDecimal(value / 100);
};

// ------------------------------

export const isConnectAccountCreated = (connectId) => {
  return connectId && connectId !== "PENDING";
};

// ------------------------------

export const formatDecimal = (value) => {
  return value % 1 !== 0 ? value.toFixed(2) : value;
};

// ------------------------------

export const valueOrBlank = (value) => {
  return value ? value : "";
};

// ------------------------------

export const objectHasNoValues = (object) => {
  return !object || Object.keys(object).length === 0;
};

// ------------------------------

export const hasDecimal = (value) => {
  return value % 1 !== 0;
};

// ------------------------------

export const roundAmount = (num) => {
  return formatDecimal(Math.round((num + Number.EPSILON) * 100) / 100);
};

// ------------------------------

export const roundAmountNoFormatDecimal = (num) => {
  return Math.round((num + Number.EPSILON) * 100) / 100;
};

// ------------------------------

export const safe_tags = (value) => {
  return value
    .replace(/&/g, "&amp;")
    .replace(/</g, "&lt;")
    .replace(/>/g, "&gt;");
};

// ------------------------------

export const validYouTube = (uri) => {
  if (uri) {
    return (
      uri.startsWith("https://www.youtube.com") ||
      uri.startsWith("https://youtu")
    );
  }

  return false;
};

// ------------------------------

export const firebaseNameToId = (name) => {
  const nameArray = name.split("/");
  return nameArray[nameArray.length - 1];
};

// ------------------------------

export const groupBy = function (xs, key) {
  return xs.reduce(function (rv, x) {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
};

// ------------------------------

export const totalBy = function (array, key) {
  return array.length > 0
    ? array
      .map((item) => item[key])
      .reduce((prev, next) => Number(prev) + Number(next))
    : 0;
};

// ------------------------------

export const setLanguage = (language, i18n) => {
  if (language) {
    if (language.startsWith("fr")) {
      i18n.changeLanguage("fr");
    } else {
      i18n.changeLanguage("en");
    }
    localStorage.setItem("language", language);
  }
};

// ------------------------------

export const valueInCollection = (collection, value) => {
  return collection.indexOf(value) > -1;
};

// ------------------------------

export const calcPromoDiscount = (item, coupon, rates) => {
  const isUserSpecific = coupon.uId;
  const isCourseSpecific = coupon.courses.length > 0;

  // apply discount to all staff / all courses
  if (!isUserSpecific && !isCourseSpecific) {
    return (coupon.discount / 100) * item.calcProviderViewSubTotal(rates);
  }

  // apply discount to user / all courses
  if (isUserSpecific && !isCourseSpecific) {
    let totalDiscount = 0;

    const keys = Object.keys(item.cartCourseItems);
    for (const key of keys) {
      const ci = item.cartCourseItems[key];
      for (const pi of ci.pricingItems) {
        if (pi.staffMembers.find((sm) => sm.id === coupon.uId)) {
          totalDiscount +=
            (coupon.discount / 100) * pi.viewCost(item.providerCurrency, rates);
        }
      }
    }

    return totalDiscount;
  }

  // apply discount to all users / specific courses
  if (!isUserSpecific && isCourseSpecific) {
    let totalDiscount = 0;

    for (const key in item.cartCourseItems) {
      if (coupon.courses.indexOf(key) > -1) {
        totalDiscount +=
          (coupon.discount / 100) *
          item.cartCourseItems[key].courseViewSubTotal(
            item.providerCurrency,
            rates
          );
      }
    }

    return totalDiscount;
  }

  // apply discount to user / specific courses
  if (isUserSpecific && isCourseSpecific) {
    let totalDiscount = 0;

    const keys = Object.keys(item.cartCourseItems);
    for (const key of keys) {
      const ci = item.cartCourseItems[key];
      if (coupon.courses.indexOf(key) > -1) {
        for (const pi of ci.pricingItems) {
          if (pi.staffMembers.find((sm) => sm.id === coupon.uId)) {
            totalDiscount +=
              (coupon.discount / 100) *
              pi.viewCost(item.providerCurrency, rates);
          }
        }
      }
    }

    return totalDiscount;
  }

  return 0;
};

// ------------------------------

export const calcMemberPromoDiscount = (
  coupon,
  courseId,
  costAfterDiscount,
  sm
) => {
  if (coupon) {
    const isUserSpecific = coupon.uId;
    const isCourseSpecific = coupon.courses.length > 0;

    // apply discount to all staff / all courses
    if (!isUserSpecific && !isCourseSpecific) {
      return (coupon.discount / 100) * costAfterDiscount;
    }

    // * apply discount to user / all courses
    if (isUserSpecific && !isCourseSpecific) {
      if (sm.id === coupon.uId) {
        return (coupon.discount / 100) * costAfterDiscount;
      }
    }

    // apply discount to all users / specific courses
    if (!isUserSpecific && isCourseSpecific) {
      if (coupon.courses.indexOf(courseId) > -1) {
        return (coupon.discount / 100) * costAfterDiscount;
      }
    }

    // * apply discount to user / specific courses
    if (isUserSpecific && isCourseSpecific) {
      if (coupon.courses.indexOf(courseId) > -1 && sm.id === coupon.uId) {
        return (coupon.discount / 100) * costAfterDiscount;
      }
    }
  }

  return 0;
};

// ------------------------------

export const translate = (t, value, prefix) => {
  const pre = prefix ? prefix : "pick_";
  return value
    ? t(pre + value.toLowerCase().split(" ").join("_").split("-").join("_"))
    : null;
};

// ------------------------------

export const hasMorePages = (totalRows, page, limit) => {
  return totalRows - (page ? page * limit : limit) >= 0;
};

// ------------------------------

export const resolveCoverPhoto = (title) => {
  if (title) {
    const char = title.charAt(0);

    if (char.toLowerCase() < "d") {
      return cover1;
    }

    if (char.toLowerCase() < "h") {
      return cover2;
    }

    if (char.toLowerCase() < "l") {
      return cover3;
    }

    if (char.toLowerCase() < "p") {
      return cover4;
    }

    if (char.toLowerCase() < "s") {
      return cover5;
    }

    if (char.toLowerCase() < "w") {
      return cover6;
    }

    if (char.toLowerCase() < "z") {
      return cover7;
    }
  }

  return cover8;
};

import YouTubeIcon from "@material-ui/icons/YouTube";
import SchoolRoundedIcon from "@material-ui/icons/SchoolRounded";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import EmojiPeopleRoundedIcon from "@material-ui/icons/EmojiPeopleRounded";
import LocationCityRoundedIcon from "@material-ui/icons/LocationCityRounded";
import ComputerRoundedIcon from "@material-ui/icons/ComputerRounded";
import HomeWorkRoundedIcon from "@material-ui/icons/HomeWorkRounded";
import PinDropIcon from "@material-ui/icons/PinDrop";

import Needle from "../assets/svg/needle.svg";
import IOutline from "../assets/svg/alpha-i-circle-outline.svg";
import ToothBrush from "../assets/svg/toothbrush-paste.svg";
import Pulse from "../assets/svg/heart-pulse.svg";
import Child from "../assets/svg/human-male-child.svg";
import Blood from "../assets/svg/blood-bag.svg";
import Pill from "../assets/svg/pill.svg";
import Tooth from "../assets/svg/tooth-outline.svg";
import MedicalBag from "../assets/svg/medical-bag.svg";
import Doctor from "../assets/svg/doctor.svg";
import TMJ from "../assets/svg/alpha-t-circle-outline.svg";
import Gift from "../assets/svg/gift-outline.svg";
import Book from "../assets/svg/book-open-outline.svg";

export const resolveIcon = (iconName, small) => {
  const style = small ? { fontSize: "20px" } : { fontSize: "30px" };

  if (iconName === "svgalpha-t-circle-outline") {
    return TMJ;
  }

  if (iconName === "svggift-outline") {
    return Gift;
  }

  if (iconName === "svgbook-open-outline") {
    return Book;
  }

  if (iconName === "svgdoctor") {
    return Doctor;
  }

  if (iconName === "svgmedical-bag") {
    return MedicalBag;
  }

  if (iconName === "svgtooth-outline") {
    return Tooth;
  }

  if (iconName === "svgpill") {
    return Pill;
  }

  if (iconName === "svgblood-bag") {
    return Blood;
  }

  if (iconName === "svghuman-male-child") {
    return Child;
  }

  if (iconName === "svgheart-pulse") {
    return Pulse;
  }

  if (iconName === "svgalpha-i-circle-outline") {
    return IOutline;
  }

  if (iconName === "svgtoothbrush-paste") {
    return ToothBrush;
  }

  if (iconName === "svgneedle") {
    return Needle;
  }

  if (iconName === "PinDropIcon") {
    return <PinDropIcon style={style} />;
  }

  if (iconName === "SchoolRoundedIcon") {
    return <SchoolRoundedIcon style={style} />;
  }

  if (iconName === "EmojiPeopleRoundedIcon") {
    return <EmojiPeopleRoundedIcon style={style} />;
  }

  if (iconName === "LocationCityRoundedIcon") {
    return <LocationCityRoundedIcon style={style} />;
  }

  if (iconName === "HomeWorkRoundedIcon") {
    return <HomeWorkRoundedIcon style={style} />;
  }

  if (iconName === "ComputerRoundedIcon") {
    return <ComputerRoundedIcon style={style} />;
  }

  if (iconName === "YouTubeIcon") {
    return <YouTubeIcon style={style} />;
  }

  return <HelpOutlineIcon />;
};

// ------------------------------

export const deepEqual = (object1, object2) => {
  const keys1 = Object.keys(object1);
  const keys2 = Object.keys(object2);
  if (keys1.length !== keys2.length) {
    return false;
  }
  for (const key of keys1) {
    const val1 = object1[key];
    const val2 = object2[key];
    const areObjects = isObject(val1) && isObject(val2);
    if (
      (areObjects && !deepEqual(val1, val2)) ||
      (!areObjects && val1 !== val2)
    ) {
      return false;
    }
  }
  return true;
};

// ------------------------------

function isObject(object) {
  return object != null && typeof object === "object";
}

// ------------------------------

export const isInIFrame = () => {
  return window.self !== window.top;
};

// ------------------------------

export const getRouteParam = (props, value) => {
  if (props.location && props.location.state && props.location.state[value]) {
    return props.location.state[value];
  }

  return "";
};

// ------------------------------
