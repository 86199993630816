import {
  SET_PROFILE,
  SET_PROFILE_LOGO,
  SET_PROFILE_ESIGN,
  SET_PROFILE_STOREFRONT,
  UPDATE_PROFILE,
  SET_PRICING_PLAN,
  SET_MOBILE_USER_PROFILE,
  ADD_MOBILE_USER_PROFILE,
  UPDATE_MOBILE_USER_PROFILE,
} from "../actions/profile";
import { LOGOUT } from "../actions/auth";
import Profile from "../../model/Profile";
import MobileUserProfile from "../../model/MobileUserProfile";
import { updateObject } from "../../globals/Common";

const initialState = {
  userProfile: {},
  profile: {},
  pricingPlan: {},
};

const profileReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_PROFILE:
      return updateObject(state, { profile: action.profile });
    case SET_PRICING_PLAN:
      return updateObject(state, { pricingPlan: action.pricingPlan });
    case SET_PROFILE_LOGO:
      const updateLogo = { ...state.profile };
      updateLogo["logo"] = action.logo;
      return updateObject(state, { profile: updateLogo });
    case SET_PROFILE_ESIGN:
      const updateEsign = { ...state.profile };
      updateEsign["digitalSignature"] = action.digitalSignature;
      return updateObject(state, { profile: updateEsign });
    case SET_PROFILE_STOREFRONT:
      const updateStorefront = { ...state.profile };
      updateStorefront["storefront"] = action.storefront;
      return updateObject(state, { profile: updateStorefront });
    case UPDATE_PROFILE:
      const updateProfile = new Profile(
        action.profile.id,
        action.profile.providerName,
        action.profile.country,
        action.profile.contact_email,
        action.profile.cancel_policy,
        action.profile.logo,
        action.profile.digitalSignature,
        action.profile.currency,
        action.profile.state,
        action.profile.storefront,
        action.profile.about_en,
        action.profile.about_fr
      );
      return updateObject(state, { profile: updateProfile });
    case SET_MOBILE_USER_PROFILE:
      return updateObject(state, { userProfile: action.userProfile });
    case ADD_MOBILE_USER_PROFILE:
      const newUserProfile = new MobileUserProfile(
        action.profile.pId,
        action.profile.userProfile.first,
        action.profile.userProfile.last,
        action.profile.userProfile.gender,
        action.profile.userProfile.occupation,
        action.profile.userProfile.email,
        action.profile.userProfile.birthDate,
        action.profile.userProfile.graduationDate,
        action.profile.userProfile.licencingBoard,
        action.profile.userProfile.licenseNumber,
        action.profile.userProfile.phone,
        action.profile.userProfile.streetNumber,
        action.profile.userProfile.streetName,
        action.profile.userProfile.suite,
        action.profile.userProfile.country,
        action.profile.userProfile.stateProvince,
        action.profile.userProfile.city,
        action.profile.userProfile.zipPostal,
        action.profile.userProfile.geoLat,
        action.profile.userProfile.geoLng,
        action.profile.userProfile.geoHash,
        action.profile.userProfile.zubuName,
        action.profile.userProfile.zubuLocation
      );
      return updateObject(state, { userProfile: newUserProfile });
    case UPDATE_MOBILE_USER_PROFILE:
      const updatedUserProfile = new MobileUserProfile(
        action.userProfile.id,
        action.userProfile.first,
        action.userProfile.last,
        action.userProfile.gender,
        action.userProfile.occupation,
        action.userProfile.email,
        action.userProfile.birthDate,
        action.userProfile.graduationDate,
        action.userProfile.licencingBoard,
        action.userProfile.licenseNumber,
        action.userProfile.phone,
        action.userProfile.streetNumber,
        action.userProfile.streetName,
        action.userProfile.suite,
        action.userProfile.country,
        action.userProfile.stateProvince,
        action.userProfile.city,
        action.userProfile.zipPostal,
        action.userProfile.geoLat,
        action.userProfile.geoLng,
        action.userProfile.geoHash,
        action.userProfile.zubuName,
        action.userProfile.zubuLocation
      );
      return updateObject(state, { userProfile: updatedUserProfile });
    case LOGOUT:
      return initialState;
    default:
      return state;
  }
};

export default profileReducer;
