import {
  ADD_CE,
  UPDATE_CE,
  UPDATE_CE_URI,
  UPDATE_CE_HAS_URI,
  REMOVE_CE,
  SET_CE,
  SET_REPORT,
  SET_CE_OBJ
} from "../actions/ces";
import Ce from "../../model/Ce";
import { updateObject } from "../../globals/Common";

const initialState = {
  ceObj: null,
  ce: [],
  reportCe: [],
  reportSummary: {},
  pending: 0,
  pendingLecture: 0,
  pendingHandsOn: 0,
  pendingCost: 0,
  completed: 0,
  completedLecture: 0,
  completedHandsOn: 0,
  completedCost: 0,
};

const cesReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_CE_OBJ:
      return updateObject(state, { ceObj: action.ceObj });
    case SET_REPORT:
      return {
        ...state,
        reportCe: action.reportCe,
        reportSummary: action.reportSummary,
      };
    case SET_CE:
      return {
        ce: action.ce.ce,
        report: [],
        reportSummary: null,
        pending: action.ce.pending,
        pendingLecture: action.ce.pendingLecture,
        pendingHandsOn: action.ce.pendingHandsOn,
        pendingCost: action.ce.pendingCost,
        completed: action.ce.completed,
        completedLecture: action.ce.completedLecture,
        completedHandsOn: action.ce.completedHandsOn,
        completedCost: action.ce.completedCost,
      };
    case ADD_CE:
      const newCe = new Ce(
        action.ce.id,
        action.ce.ce.title,
        action.ce.ce.description,
        action.ce.ce.notes,
        action.ce.ce.lecture_hours,
        action.ce.ce.practice_hours,
        action.ce.ce.credits,
        action.ce.ce.category,
        action.ce.ce.organizer,
        action.ce.ce.courseDate,
        action.ce.ce.entryDate,
        action.ce.ce.courseId,
        action.ce.ce.price,
        action.ce.ce.currency,
        action.ce.ce.hasCertificate,
        action.ce.ce.certificateUri
      );
      return {
        ...state,
        ce: state.ce.concat(newCe),
        ceObj: newCe
      };
    case UPDATE_CE:
      const ceIndex = state.ce.findIndex((ce) => ce.id === action.ce.id);

      const updatedCe = new Ce(
        action.ce.id,
        action.ce.title,
        action.ce.description,
        action.ce.notes,
        action.ce.lecture_hours,
        action.ce.practice_hours,
        action.ce.credits,
        action.ce.category,
        action.ce.organizer,
        action.ce.courseDate,
        action.ce.entryDate,
        action.ce.courseId,
        action.ce.price,
        action.ce.currency,
        action.ce.hasCertificate,
        action.ce.certificateUri
      );

      const updatedCes = [...state.ce];
      updatedCes[ceIndex] = updatedCe;
      return {
        ...state,
        ce: updatedCes,
        ceObj: updatedCe
      };
    case UPDATE_CE_URI:
      const ceUpdateUriIndex = state.ce.findIndex(
        (ce) => ce.id === action.ce.id
      );

      const updatedCesUri = [...state.ce];

      const copyCe = updatedCesUri[ceUpdateUriIndex];

      const updatedCeUri = new Ce(
        copyCe.id,
        copyCe.title,
        copyCe.description,
        copyCe.notes,
        copyCe.lecture_hours,
        copyCe.practice_hours,
        copyCe.credits,
        copyCe.category,
        copyCe.organizer,
        copyCe.courseDate,
        copyCe.entryDate,
        copyCe.courseId,
        copyCe.price,
        copyCe.currency,
        true,
        action.ce.uri // only update uri
      );

      const updatedCesUri2 = [...state.ce];

      updatedCesUri2[ceUpdateUriIndex] = updatedCeUri;
      return {
        ...state,
        ce: updatedCesUri2,
        ceObj: updatedCeUri
      };
    case UPDATE_CE_HAS_URI:
      const ceUpdateHasUriIndex = state.ce.findIndex(
        (ce) => ce.id === action.ce.id
      );

      const updatedCesHasUri = [...state.ce];

      const copyCeHasUri = updatedCesHasUri[ceUpdateHasUriIndex];

      const updatedCeHasUri = new Ce(
        copyCeHasUri.id,
        copyCeHasUri.title,
        copyCeHasUri.description,
        copyCeHasUri.notes,
        copyCeHasUri.lecture_hours,
        copyCeHasUri.practice_hours,
        copyCeHasUri.credits,
        copyCeHasUri.category,
        copyCeHasUri.organizer,
        copyCeHasUri.courseDate,
        copyCeHasUri.entryDate,
        copyCeHasUri.courseId,
        copyCeHasUri.price,
        copyCeHasUri.currency,
        action.ce.hasCertificate,
        ""
      );

      const updatedCesHasUri2 = [...state.ce];

      updatedCesHasUri2[ceUpdateHasUriIndex] = updatedCeHasUri;
      return {
        ...state,
        ce: updatedCesHasUri2,
        ceObj: updatedCeHasUri
      };
    case REMOVE_CE:
      return {
        ...state,
        ce: action.ce.ce,
        pending: action.ce.pending,
        pendingLecture: action.ce.pendingLecture,
        pendingHandsOn: action.ce.pendingHandsOn,
        pendingCost: action.ce.pendingCost,
        completed: action.ce.completed,
        completedLecture: action.ce.completedLecture,
        completedHandsOn: action.ce.completedHandsOn,
        completedCost: action.ce.completedCost,
      };
    case "LOGOUT":
      return initialState;
    default:
      return state;
  }
};

export default cesReducer;
