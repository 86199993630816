import React, { useState, Fragment } from "react";
import { useTranslation } from "react-i18next";
import Drawer from "@material-ui/core/Drawer";
import Button from "@material-ui/core/Button";
import FilterListIcon from "@material-ui/icons/FilterList";
import Badge from "@material-ui/core/Badge";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import {
  selectFilterPrice,
  selectLang,
  selectFilterCity,
} from "../../../../globals/Data";
import { objectHasNoValues, translate } from "../../../../globals/Common";
import styles from "./Drawer.module.css";
import Filter from "../../../../components/ui/filter/Filter";

// ------------------------------

const CourseFilterDrawer = (props) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.down("xs"));

  const [selectedLang, setSelectedLang] = useState(props.filterLanguage);
  const [selectedPrice, setSelectedPrice] = useState(props.filterPrice);
  const [selectedCity, setSelectedCity] = useState(props.filterCity);
  const [filters, setFilters] = useState(props.filters);

  // ------------------------------

  const translateList = (list) => {
    const translatedList = [];

    for (const item of list) {
      const copyItem = { ...item };

      copyItem.label = translate(t, copyItem.label);
      translatedList.push(copyItem);
    }

    translatedList.sort((a, b) =>
      a.label.toLowerCase() > b.label.toLowerCase() ? 1 : -1
    );

    return translatedList;
  };

  // ------------------------------
  const [translatedPrice, setTranslatedPrice] = useState(
    translateList(selectFilterPrice)
  );

  const [translatedLang, setTranslatedLang] = useState(
    translateList(selectLang)
  );

  // ------------------------------

  const clearHandler = async (event) => {
    setFilters(props.defaultFilters);
    setSelectedLang([]);
    setSelectedPrice([]);
    setSelectedCity([]);
    event.preventDefault();
  };

  const localFilterCount = () => {
    const count =
      selectedLang.length + selectedPrice.length + selectedCity.length;

    return count;
  };
  // ------------------------------

  const updateFilters = (value, filterType, event) => {
    const copyFilters = { ...filters };
    copyFilters[filterType] = Array.isArray(value) ? value[0] : value;

    if (!copyFilters[filterType]) {
      delete copyFilters[filterType];
    }

    setFilters(copyFilters);
  };

  // ------------------------------

  const filterHandler = async (filterType, value) => {
    updateFilters(value, filterType);
  };

  // ------------------------------

  const toggleDrawer = () => (event) => {
    props.close(filters, selectedLang, selectedPrice, selectedCity);
  };

  // ------------------------------

  return (
    <Drawer
      classes={{ paper: isLargeScreen ? styles.paper : styles.paperSmall }}
      anchor="left"
      open={props.open}
      onClose={toggleDrawer()}
      variant={null}
    >
      <div role="presentation">
        <div className={styles.drawer}>
          <FilterListIcon
            style={{ fontSize: "25px", marginRight: "10px" }}
            onClick={toggleDrawer()}
          />
          {t("g_filters")}
        </div>

        <div className={styles.filterContainer}>
          {localFilterCount() > 0 ? (
            <div className={styles.filterClearContainer}>
              <Badge badgeContent={localFilterCount()} color="error">
                <Button variant="outlined" onClick={clearHandler}>
                  {t("g_clear")}
                </Button>
              </Badge>
            </div>
          ) : (
            <div className={styles.filterClearContainer}></div>
          )}

          {props.filterExclude && props.filterExclude.city ? (
            <Fragment></Fragment>
          ) : (
            <Fragment>
              <hr className={styles.hr} />
              <Filter
                selected={selectedCity}
                title={t("g_city")}
                list={selectFilterCity}
                show={true}
                onFilter={filterHandler.bind(this, "city")}
                singleSelection={true}
                onSelected={(selected) => {
                  setSelectedCity(selected);
                }}
              />
            </Fragment>
          )}

          <hr className={styles.hr} />

          <Filter
            selected={selectedPrice}
            title={t("g_price")}
            list={translatedPrice}
            show={true}
            onFilter={filterHandler.bind(this, "searchFree")}
            singleSelection={true}
            onSelected={(selected) => {
              setSelectedPrice(selected);
            }}
          />

          <hr className={styles.hr} />

          <Filter
            selected={selectedLang}
            title={t("setting_language")}
            list={translatedLang}
            show={true}
            onFilter={filterHandler.bind(this, "language")}
            singleSelection={true}
            onSelected={(selected) => {
              setSelectedLang(selected);
            }}
          />
        </div>
      </div>
    </Drawer>
  );
};

export default CourseFilterDrawer;
