import React, { useState } from "react";
import styles from "./PermissionModal.module.css";
import { useTranslation } from "react-i18next";
import Button from "@material-ui/core/Button";
import HttpsOutlinedIcon from "@material-ui/icons/HttpsOutlined";
import IconButton from "@material-ui/core/IconButton";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import InputLabel from "@material-ui/core/InputLabel";
import InputAdornment from "@material-ui/core/InputAdornment";
import FormControl from "@material-ui/core/FormControl";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import LinkIcon from "@material-ui/icons/Link";
import AlertSuccess from "../../../components/ui/snackbar/AlertSuccess";
import { CopyToClipboard } from "react-copy-to-clipboard";

const PermissionModal = (props) => {
  const { t } = useTranslation();

  const [password, setPassword] = useState(props.passcode);
  const [showPassword, setShowPassword] = useState("");
  const [success, setSuccess] = useState(null);

  const handleChangePassword = (event) => {
    setPassword(event.target.value);
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const renderPasscodeInput = () => {
    return (
      <FormControl variant="outlined" style={{ width: "100%" }}>
        <InputLabel htmlFor="outlined-adornment-password">Passcode</InputLabel>
        <OutlinedInput
          id="outlined-adornment-password"
          type={showPassword ? "text" : "password"}
          value={password}
          onChange={handleChangePassword}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
                edge="end"
              >
                {showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          }
          labelWidth={70}
        />
      </FormControl>
    );
  };

  return (
    <div className={styles.container}>
      {success !== null && (
        <AlertSuccess
          message={success}
          open={success !== null}
          onClose={() => {
            setSuccess(null);
          }}
        />
      )}

      <div className={styles.title}>{props.title}</div>
      <div className={styles.description}>{props.description}</div>
      <div className={styles.disclaimer}>
        <div> {props.disclaimer} </div>
        <div>
          <HttpsOutlinedIcon fontSize="inherit" />
        </div>
      </div>

      <div className={styles.passcodeContainer}>
        <div style={{ flex: 1 }}>{renderPasscodeInput()}</div>
        <div style={{ height: "100%", paddingLeft: "10px" }}>
          <Button
            variant="contained"
            style={{
              backgroundColor: password ? "#437aa1" : "#ccc",
              color: "white",
              width: "100%",
              height: "54px",
            }}
            disabled={password ? false : true}
            onClick={() => {
              props.onSavePasscode(password);
              setShowPassword(false);
            }}
          >
            Save
          </Button>
        </div>
      </div>

      <div className={styles.messageLinkPreview}>{props.link}</div>

      <div>
        <CopyToClipboard
          text={props.link}
          onCopy={() => {
            setSuccess("Copied Link");
          }}
        >
          <Button
            variant="contained"
            style={{
              backgroundColor: "green",
              color: "white",
              width: "100%",
              height: "54px",
            }}
            onClick={() => {}}
          >
            Copy Link&nbsp;
            <LinkIcon />
          </Button>
        </CopyToClipboard>
      </div>
    </div>
  );
};

export default PermissionModal;
