import React, { useState } from "react";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import useMediaQuery from "@material-ui/core/useMediaQuery";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(5),

    "& > * + *": {
      marginTop: theme.spacing(3),
    },
  },
  rootNoMargin: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: theme.spacing(0),
    marginBottom: theme.spacing(5),

    "& > * + *": {
      marginTop: theme.spacing(3),
    },
  },
  rootNoBottomMargin: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),

    "& > * + *": {
      marginTop: theme.spacing(3),
    },
  },
  rootNoTopBottomMargin: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: theme.spacing(0),
    marginBottom: theme.spacing(3),

    "& > * + *": {
      marginTop: theme.spacing(3),
    },
  },

  noTopBottomMargin2: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: theme.spacing(0),
    marginBottom: theme.spacing(2),

    "& > * + *": {
      marginTop: theme.spacing(3),
    },
  },
  zeroMargin: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    margin: "0px",
    padding: "0px",
  },
  inputField: {
    width: "100%",
  },
  inputFieldSmall: {
    width: "60%",
  },
}));

const MatSelectOutline = (props) => {
  const [input, setInput] = useState("");
  const matches = useMediaQuery("(max-width:499px)");
  const classes = useStyles();

  let classRoot = classes.root;

  if (props.noTopBottomMargin) {
    classRoot = classes.rootNoTopBottomMargin;
  }

  if (props.noTopBottomMargin2) {
    classRoot = classes.noTopBottomMargin2;
  }

  if (props.noTopMargin) {
    classRoot = classes.rootNoMargin;
  }

  if (props.noBottomMargin) {
    classRoot = classes.rootNoBottomMargin;
  }

  if (props.zeroMargin) {
    classRoot = classes.zeroMargin;
  }

  const classSelect =
    matches || props.double ? classes.inputField : classes.inputFieldSmall;

  return (
    <div className={classRoot}>
      <Autocomplete
        name={props.name}
        id={props.id}
        onChange={props.onChange}
        onInputChange={(event, newInputValue) => {
          setInput(newInputValue);
        }}
        options={props.options}
        value={props.value}
        disableClearable={props.disableClearable}
        getOptionLabel={props.getOptionLabel}
        getOptionSelected={props.getOptionSelected}
        onBlur={() => {
          if (props.onBlur) {
            props.onBlur(input);
          }
          setInput("");
        }}
        selectOnFocus
        disabled={props.disabled}
        className={classSelect}
        filterSelectedOptions
        multiple={props.multiple}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            label={props.title}
            placeholder={props.placeholder}
            required={props.required}
            error={props.error}
            helperText={props.helperText}
          />
        )}
      />
    </div>
  );
};

export default MatSelectOutline;
