import { momentShortDateTime } from "../globals/Dates";

class ZoomMeeting {
  constructor(
    event_type,
    uuid,
    id,
    host_id,
    topic,
    agenda,
    type,
    start_time,
    duration,
    timezone,
    created_at,
    join_url,
    status,
    password,
    start_url,
    registration_url,
    host_email,
    settings_approval_type,
    occurrences
  ) {
    this.event_type = event_type;
    this.uuid = uuid;
    this.id = id;
    this.host_id = host_id;
    this.topic = topic;
    this.agenda = agenda;
    this.type = type;
    this.start_time = start_time;
    this.duration = duration;
    this.timezone = timezone;
    this.created_at = created_at;
    this.join_url = join_url;

    // ----- details
    this.status = status;
    this.password = password;
    this.start_url = start_url;
    this.registration_url = registration_url;
    this.host_email = host_email;

    // ZoomOccurrence
    this.occurrences = occurrences;

    // settings
    this.settings_approval_type = settings_approval_type;
  }

  startTime = () => {
    if (this.start_time) {
      return momentShortDateTime(this.start_time);
    }

    return "";
  };

  getType = () => {
    switch (this.type) {
      case 1:
        return "Instant meeting";
      case 2:
        return "Scheduled meeting";
      case 3:
        return "Recurring meeting with not fixed time";
      case 8:
        return "Recurring meeting with fixed time";
      default:
        return "Unknown meeting type";
    }
  };

  getApprovalType = () => {
    switch (this.settings_approval_type) {
      case 0:
        return "Automatically approve";
      case 1:
        return "Manually approve";
      case 2:
        return "No registration required";
      default:
        return "No registration required";
    }
  };
}

export default ZoomMeeting;
