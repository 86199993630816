import {
    SET_MEMBERS,
    SET_MEMBERSHIPS,
    REMOVE_MEMBERSHIP
} from "../actions/member";
import { LOGOUT } from "../actions/auth";

const initialState = {
    memberships: [],
    members: []
};

const memberReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_MEMBERSHIPS:
            return {
                ...state,
                memberships: action.memberships,
            };
        case SET_MEMBERS:
            return {
                ...state,
                members: action.members,
            };
        case REMOVE_MEMBERSHIP:
            const copyMemberships = [...state.memberships];
            const updatedDelMemberships = copyMemberships.filter((m) => {
                return m.id !== action.id;
            });

            return {
                ...state,
                memberships: updatedDelMemberships,
            };
        case LOGOUT:
            return initialState;
        default:
            return state;
    }
};

export default memberReducer;
