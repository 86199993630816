import { momentLongDate, fromNow } from "../globals/Dates2";

class PricingPlan {
  constructor(
    id,
    accountCreated,
    customerType,
    waveFee,
    credits,
    expirationDate
  ) {
    this.id = id;
    this.accountCreated = accountCreated;
    this.customerType = customerType;
    this.waveFee = waveFee;
    this.credits = credits;
    this.expirationDate = expirationDate;
  }

  fee = () => {
    if (this.customerType === "non_profit") {
      return 4;
    }
    else if (this.customerType === "collaborator") {
      return 5;
    }
    else if (this.customerType === "custom_6") {
      return 6;
    }
    else if (this.customerType === "custom_6_5") {
      return 6.5;
    }
    else if (this.customerType === "preferred") {
      return 13;
    }
    else if (this.customerType === "custom_10") {
      return 10;
    }
    else if (this.customerType === "custom_15") {
      return 15;
    }

    return 20;
  };

  createdDate = () => {
    return momentLongDate(this.accountCreated);
  };

  createdFromNow = () => {
    if (this.accountCreated) {
      return fromNow(this.accountCreated);
    }

    return "";
  };

  expiresIn = () => {
    if (this.expirationDate) {
      return fromNow(this.expirationDate);
    }

    return null;
  };
}

export default PricingPlan;
