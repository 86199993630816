import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import * as connectActions from "../store/actions/connect";
import * as alertActions from "../store/actions/alert";
import * as settingActions from "../store/actions/setting";
import * as inboxActions from "../store/actions/inbox";
import styles from "./AppContainer.module.css";
import AccountBalanceOutlinedIcon from "@material-ui/icons/AccountBalanceOutlined";
import ActivationWarning from "./ActivationWarning";
import Toolbar from "../components/navigation/toolbar/Toolbar";
import MainDrawer from "../components/navigation/drawer/MainDrawer";
import Footer from "../components/ui/footer/Footer";
import { stripeOAuthKey } from "../globals/ApiKeys";
import InfoModal from "../components/modals/infoModal/InfoModal";
import InboxChatModal from "../components/modals/inboxChatModal/InboxChatModal";
import { isMobile } from "react-device-detect";

import {
  generateToken,
  isConnectAccountCreated,
  setLanguage,
  isInIFrame,
} from "../globals/Common";
import * as logger from "../globals/Logger";

class AppContainer extends Component {
  state = {
    showDrawer: isMobile ? false : true,
    showChatModal: false,
    selectedConversation: null,
    accountWarnMessage: "",
    accountWarning: null,
    error: null,
    isDeletingConversations: false,
  };

  // ------------------------------

  update = async (propValue) => {
    await this.setState({ ...this.state, ...propValue });
  };

  // ------------------------------

  componentDidUpdate = async (prevProps) => {
    if (prevProps.accountRestricted !== this.props.accountRestricted) {
      await this.update({
        accountWarnMessage:
          "Your Account is restricted. Course activation and payments and payouts are paused until you resolve the issue.",
        accountWarning: this.props.accountRestricted,
      });
    } else if (
      prevProps.accountRequirements !== this.props.accountRequirements
    ) {
      await this.update({
        accountWarnMessage:
          "Your Account requires additional information.  This can impact your ability to receive payments and payouts.",
        accountWarning: this.props.accountRequirements,
      });
    }
  };

  // ------------------------------

  renderMessage = () => {
    return (
      <span>
        {this.state.accountWarnMessage} Select{" "}
        <AccountBalanceOutlinedIcon
          style={{ fontSize: "16px", paddingTop: 4 }}
        />{" "}
        Account Balance for more information.
      </span>
    );
  };

  // ------------------------------

  setupConnect = async () => {
    try {
      const stateToken = generateToken();
      await this.props.createConnectToken(stateToken);

      const connectUrl =
        `https://connect.stripe.com/express/oauth/authorize?` +
        `response_type=code` +
        `&client_id=${stripeOAuthKey}` +
        `&scope=read_write` +
        `&state=${stateToken}` +
        `&stripe_user[email]=${this.props.accountEmail}`;

      window.open(connectUrl, "_self");
    } catch (error) {
      logger.error(error);
    }
  };

  // ------------------------------

  drawerCloseHandler = () => {
    this.setState({ showDrawer: false });
  };

  // ------------------------------

  drawerToggleHandler = () => {
    this.setState((prevState) => {
      return { showDrawer: !prevState.showDrawer };
    });
  };

  // ------------------------------

  removeAlertHandler = (alertId) => {
    this.props.removeAlert(alertId);
  };

  // ------------------------------

  isVideoStreamingPage = () => {
    const videoStreamPath = "/video"
    if (this.props.pathname && this.props.pathname.indexOf(videoStreamPath) !== -1) {
      return true;
    }

    return false;
  }

  // ------------------------------

  renderProviderScreen = (isMobile) => {
    return (
      <Fragment>
        {this.renderInboxChatModal()}
        {!this.props.hasConnectId && (
          <ActivationWarning
            open={!this.props.hasConnectId}
            setupConnect={this.setupConnect}
          />
        )}
        {this.state.error && (
          <InfoModal
            isErrorModal={true}
            title="An error has occured"
            message={this.state.error}
            open={this.state.error !== null}
            onClose={() => {
              this.update({ error: null });
            }}
          />
        )}
        {this.state.accountWarning && (
          <InfoModal
            isWarnModal={true}
            title="Important Notice"
            message={this.renderMessage(this.props)}
            open={this.state.accountWarning !== null}
            onClose={() => {
              this.update({ accountWarning: null });
            }}
          />
        )}
        <Toolbar
          alertNotifications={this.props.alertNotifications}
          removeAlert={this.removeAlertHandler}
          currency={this.props.currency}
          toggleClicked={this.drawerToggleHandler}
          cartItemCount={this.props.cartItemCount}
          providerName={this.props.providerName}
          drawerOpen={this.state.showDrawer}
          connectAccountActive={this.props.hasConnectId}
          connectAccountWarning={this.props.accountWarnings}
          goTo={(navUri) => {
            if (this.props.history.location.pathname.indexOf(navUri) === -1) {
              this.props.history.push(navUri);
            }
          }}
          conversations={this.props.conversations}
          onSelectConversation={(conversation) => {
            try {
              this.props.fetchMessages(conversation);
              this.update({
                selectedConversation: conversation,
                showChatModal: true,
              });
            } catch (error) {
              this.logAlertError(error);
            }
          }}
        />
        <MainDrawer
          history={this.props.history}
          open={this.state.showDrawer}
          setOpen={this.drawerToggleHandler}
          cartItemCount={this.props.cartItemCount}
          isMobile={isMobile}
          connectAccountActive={this.props.hasConnectId}
          connectAccountWarning={this.props.accountWarnings}
        />
        <main
          className={
            !isMobile && this.state.showDrawer
              ? styles.contentWithDockedMenu
              : styles.content
          }
        >
          {this.props.children}
        </main>
        <Footer
          notTransparent
          drawerOpen={this.state.showDrawer}
          history={this.props.history}
          isAuthenticated={this.props.isAuthenticated}
        />
      </Fragment>
    );
  };

  // ------------------------------

  updateMobileCurrency = async (currency, language) => {
    try {
      await this.props.updateCurrency(currency, language);
      setLanguage(language, this.props.i18n);
    } catch (error) {
      this.logAlertError(error);
    }
  };

  // ------------------------------

  logAlertError = (error) => {
    logger.error(error);
    this.update({
      error: error.message,
    });
  };

  // ------------------------------

  renderInboxChatModal = () => {
    if (this.state.showChatModal) {
      try {
        if (this.state.selectedConversation) {
          this.props.updateLastViewed(this.state.selectedConversation.id);
        }
      } catch (error) {
        this.update({ showChatModal: false });
        this.logAlertError(error);
      }

      return (
        <InboxChatModal
          isDeleting={this.state.isDeletingConversations}
          selectedConversation={this.state.selectedConversation}
          showAllConversations={true}
          conversations={this.props.conversations}
          messages={this.props.messages}
          open={this.state.showChatModal}
          onSendMessage={(message) => {
            try {
              if (this.state.selectedConversation) {
                this.props.sendMessage(
                  this.state.selectedConversation,
                  message
                );
              }
            } catch (error) {
              this.logAlertError(error);
            }
          }}
          onSelectConversation={async (conversation) => {
            try {
              await this.props.fetchMessages(conversation);
              if (conversation) {
                await this.props.updateLastViewed(conversation.id);
              }
              this.update({
                selectedConversation: conversation,
              });
            } catch (error) {
              this.logAlertError(error);
            }
          }}
          onClose={() => {
            this.update({ showChatModal: false });
            if (
              this.state.selectedConversation &&
              this.state.selectedConversation.id
            ) {
              this.props.stopInboxMessagesListener(
                this.state.selectedConversation.id
              );
            }
          }}
          onDeleteConversations={async (conversationList) => {
            if (conversationList && conversationList.length > 0) {
              try {
                await this.update({
                  isDeletingConversations: true,
                });

                if (this.state.selectedConversation) {
                  const index = conversationList.findIndex(
                    (c) => c.id === this.state.selectedConversation.id
                  );

                  if (index !== -1) {
                    this.update({
                      selectedConversation: null,
                    });
                  }
                }

                await this.props.deleteConversations(conversationList);
                await this.update({ isDeletingConversations: false });
              } catch (error) {
                await this.update({ isDeletingConversations: false });
                this.logAlertError(error);
              }
            }
          }}
        />
      );
    }
  };

  // ------------------------------

  renderStudentScreen = (isMobile) => {
    return (
      <Fragment>
        {this.renderInboxChatModal()}
        {this.state.error && (
          <InfoModal
            isErrorModal={true}
            title="An error has occured"
            message={this.state.error}
            open={this.state.error !== null}
            onClose={() => {
              this.update({ error: null });
            }}
          />
        )}
        <Toolbar
          pathname={this.props.pathname}
          isMobileUser={this.props.isMobileUser}
          alertNotifications={this.props.alertNotifications}
          removeAlert={this.removeAlertHandler}
          currency={this.props.currency}
          toggleClicked={this.drawerToggleHandler}
          cartItemCount={this.props.cartItemCount}
          providerName={this.props.providerName}
          firstName={this.props.firstName}
          drawerOpen={this.state.showDrawer}
          connectAccountActive={this.props.hasConnectId}
          connectAccountWarning={this.props.accountWarnings}
          goTo={(navUri) => {
            if (this.props.history.location.pathname.indexOf(navUri) === -1) {
              this.props.history.push(navUri);
            }
          }}
          mobileSetting={this.props.mobileSetting}
          updateMobileCurrency={this.updateMobileCurrency}
          conversations={this.props.conversations}
          onSelectConversation={(conversation) => {
            try {
              this.props.fetchMessages(conversation);
              this.update({
                selectedConversation: conversation,
                showChatModal: true,
              });
            } catch (error) {
              this.logAlertError(error);
            }
          }}
        />
        <MainDrawer
          isMobileUser={this.props.isMobileUser}
          history={this.props.history}
          open={this.state.showDrawer}
          setOpen={this.drawerToggleHandler}
          cartItemCount={this.props.cartItemCount}
          isMobile={isMobile}
          connectAccountActive={this.props.hasConnectId}
          connectAccountWarning={this.props.accountWarnings}
          toBeRatedCount={this.props.toBeRatedCount}
        />
        <main
          className={
            !isMobile && this.state.showDrawer
              ? styles.contentWithDockedMenu
              : styles.content
          }
        >
          {this.props.children}
        </main>
        <Footer
          notTransparent
          drawerOpen={this.state.showDrawer}
          history={this.props.history}
          isAuthenticated={this.props.isAuthenticated}
        />
      </Fragment>
    );
  };

  // ------------------------------

  render() {
    const isMobileScreenSize = window.innerWidth && window.innerWidth < 500;

    let appScreen = (
      <Fragment>
        <main className={styles.fullscreen}>{this.props.children}</main>
      </Fragment>
    );

    if (
      !this.props.isSurveyScreen &&
      this.props.isAuthenticated &&
      !isInIFrame()
    ) {
      if (this.props.isMobileUser) {
        appScreen = this.renderStudentScreen(isMobileScreenSize);
      } else {
        appScreen = this.renderProviderScreen(isMobileScreenSize);
      }
    }

    return appScreen;
  }
}

// ------------------------------

const mapStateToProps = (state, props) => {
  const accountDefined = Object.keys(state.connect.account).length > 0;
  const pathname = props.history.location.pathname;
  return {
    pathname: pathname,
    isAuthenticated: state.auth.token != null,
    isSurveyScreen: pathname.startsWith("/studentsurvey"),
    alertNotifications: state.alert.alert,
    providerName: state.profile.profile.providerName,
    firstName: state.profile.userProfile.first
      ? state.profile.userProfile.first
      : state.profile.userProfile.zubuName,
    currency: state.profile.profile.currency,
    cartItemCount: state.auth.isMobileUser
      ? state.mobilecart.items.length
      : state.cart.items.length,
    accountEmail: state.auth.email ? state.auth.email : "",
    hasConnectId: isConnectAccountCreated(state.auth.connectId),
    accountWarnings:
      Object.keys(state.connect.account).length > 0 &&
      state.connect.account.accountWarnings(),
    accountRestricted:
      accountDefined && state.connect.account.accountRestricted(),
    accountRequirements:
      accountDefined && state.connect.account.accountRequiresAttention(),
    isMobileUser: state.auth.isMobileUser,
    mobileSetting: state.setting.mobileSetting,
    conversations: state.inbox.conversations,
    messages: state.inbox.messages,
    toBeRatedCount: state.registration.toBeRatedCount,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    createConnectToken: (token) =>
      dispatch(connectActions.createConnectToken(token)),
    removeAlert: (alertId) => dispatch(alertActions.removeAlert(alertId)),
    fetchMobileSetting: () => dispatch(settingActions.fetchMobileSetting()),
    updateCurrency: (currency, language) =>
      dispatch(settingActions.updateCurrency(currency, language)),
    fetchMessages: (conversation) =>
      dispatch(inboxActions.fetchMessages(conversation)),
    sendMessage: (conversation, message) =>
      dispatch(inboxActions.sendMessage(conversation, message)),
    updateLastViewed: (convoId) =>
      dispatch(inboxActions.updateLastViewed(convoId)),
    stopInboxMessagesListener: (convoId) =>
      dispatch(inboxActions.stopInboxMessagesListener(convoId)),
    deleteConversations: (conversations) =>
      dispatch(inboxActions.deleteConversations(conversations)),
  };
};

export default withTranslation("translations")(
  connect(mapStateToProps, mapDispatchToProps)(AppContainer)
);
