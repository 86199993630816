import React from "react";
import FlatList from "flatlist-react";
import styles from "./Balance.module.css";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import { CircularProgress } from "@material-ui/core";
import AccountBalanceIcon from "@material-ui/icons/AccountBalance";
import MatBadge from "../../ui/badge/MatBadge";
import Pop from "../../ui/popover/Pop";
import Grow from "@material-ui/core/Grow";

const BankAccounts = (props) => {
  const renderAccount = (item, index) => {
    return (
      <Grow
        key={index}
        in={true}
        style={{ transformOrigin: "0 0 0" }}
        {...(true ? { timeout: 1000 } : {})}
      >
        <div className={styles.bankAccountRow}>
          {item.bank_name} ****{item.last4} {item.currency} {item.country}
        </div>
      </Grow>
    );
  };

  const renderBlank = () => {
    return (
      <div style={{ width: "100%", textAlign: "center" }}>
        No accounts exist.
      </div>
    );
  };

  const stylePop = {
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
  };

  return (
    <div
      className={styles.card}
      style={{
        minWidth: "280px",
      }}
    >
      <div className={styles.container}>
        <div className={styles.fullContainer}>
          <div className={styles.cardContainer}>
            <div className={styles.title}>
              <Pop
                style={stylePop}
                help="Bank account where ZubU sends payouts"
              />
              <div style={{ width: "100%" }}>&nbsp;Your Bank</div>{" "}
              {props.onEdit && (
                <Tooltip
                  title="Edit Bank Information / View Payouts"
                  aria-label="Edit Bank Information / View Payouts"
                >
                  <IconButton onClick={props.onEdit}>
                    <MatBadge count={props.accountWarnings ? 1 : 0}>
                      <AccountBalanceIcon />
                    </MatBadge>
                  </IconButton>
                </Tooltip>
              )}
            </div>

            <div className={styles.list}>
              {props.wait ? (
                <div className={styles.balance}>
                  <CircularProgress size={26} color="inherit" />
                </div>
              ) : (
                <FlatList
                  displayRow
                  list={props.bankAccounts ? props.bankAccounts : []}
                  renderItem={renderAccount}
                  renderWhenEmpty={renderBlank}
                  rowGap="0px"
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BankAccounts;
