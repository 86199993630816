import React, { Component } from "react";
import { connect } from "react-redux";
import styles from "../Course.module.css";
import SimpleContainer from "../../../components/ui/container/Container";
import MatCheckbox from "../../../components/ui/checkbox/MatCheckbox";
import { renderPrivacyPolicy } from "../../legal/templateEn/PrivacyPolicyHtml";
import { renderSupplierTermsOfUse } from "../../legal/templateEn/SupplierHtml";
import { renderGeneralTermsOfUse } from "../../legal/templateEn/GeneralHtml";

class ActivationScreen extends Component {
  handleChange = (event) => {
    this.props.updateActivationCheck(event.target.checked);
  };

  render() {
    return (
      <SimpleContainer disableGutters={false}>
        <form className={styles.form}>
          <div className={styles.sectionTitle}>Activation </div>
          <div className={styles.row}>
            <div className={styles.column}>
              <div className={styles.pricingContainer}>
                <div className={styles.legalContainer}>
                  <div className={styles.title}>
                    {this.props.contextTitle} <div>({this.props.title})</div>
                  </div>
                  Upon clicking ACTIVATE, you hereby agree that the academic
                  content you are posting is valid and truthful. You will honor
                  your cancellation policy as well as the cancellation
                  requirements set forth by ZubU in the supplier terms and
                  conditions.
                </div>

                <div className={styles.legalSrollContainer}>
                  {renderGeneralTermsOfUse()}
                  {renderSupplierTermsOfUse()}
                  {renderPrivacyPolicy()}
                </div>
              </div>

              <MatCheckbox
                label="I have read, understand and agree to all terms and conditions"
                checked={this.props.activateConfirmation}
                onChange={this.handleChange}
                name="activateConfirmation"
              />
            </div>
          </div>
        </form>
      </SimpleContainer>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    contextTitle: props.contextTitle,
    title: props.title,
    activateConfirmation: props.activationConfirmation,
  };
};

export default connect(mapStateToProps, null)(ActivationScreen);
