import React, { Fragment } from "react";
import { useTranslation } from "react-i18next";
import styles from "./CourseItem.module.css";
import EventOutlinedIcon from "@material-ui/icons/EventOutlined";
import YouTubeIcon from "@material-ui/icons/YouTube";
import Avatar from "../../ui/image/Avatar";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { resolveCoverPhoto } from "../../../globals/Common";
import CourseCardRating from "../../ui/rating/CourseCardRating";
import LinesEllipsis from "react-lines-ellipsis";
import Fade from "@material-ui/core/Fade";
import responsiveHOC from "react-lines-ellipsis/lib/responsiveHOC";
const ResponsiveEllipsis = responsiveHOC()(LinesEllipsis);
import Tooltip from "@material-ui/core/Tooltip";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  arrow: {
    color: "#ccc",
  },
  customTooltip: {
    background: "white",
    border: "1px solid #ccc",
  },
}));

const CourseItem = (props) => {
  const { t } = useTranslation();
  const smallDevice = useMediaQuery("(max-width:450px)");
  const classes = useStyles();

  // ------------------------------

  const tooltipContent = () => (
    <div className={styles.tooltipContainer}>
      {props.description && (
        <Fragment>
          <div className={styles.tooltipTitle}>Description</div>
          <div className={styles.tooltipParagraph}>
            <ResponsiveEllipsis
              text={props.description}
              maxLine="5"
              ellipsis="..."
              basedOn="letters"
            />
          </div>
        </Fragment>
      )}
    </div>
  );

  // ------------------------------

  return (
    <Tooltip
      enterDelay={1000}
      enterNextDelay={1000}
      TransitionComponent={Fade}
      TransitionProps={{ timeout: 600 }}
      classes={{
        tooltip: classes.customTooltip,
        arrow: classes.arrow,
      }}
      title={tooltipContent()}
      placement="top-end"
      interactive
      arrow
    >
      <div
        className={
          props.strechable && smallDevice
            ? styles.containerSearch
            : styles.container
        }
        onClick={props.onSelectCourse}
      >
        <div
          className={
            props.strechable && smallDevice
              ? styles.imageContainerSearch
              : styles.imageContainer
          }
          style={{
            backgroundImage: props.image
              ? `url(${props.image})`
              : `url(${resolveCoverPhoto(props.title)})`,
          }}
        >
          <div className={styles.ceContainer}>
            <div className={styles.detailsLight}>CE: {props.credit}</div>
          </div>

          <div className={styles.avatarContainer}>
            {props.avatar ? <Avatar image={props.avatar} /> : null}
          </div>
        </div>

        <div
          className={
            props.strechable && smallDevice
              ? styles.infoContainerSearch
              : styles.infoContainer
          }
        >
          <div className={styles.titleContainer}>
            <div className={styles.title}>
              <ResponsiveEllipsis
                text={props.title}
                maxLine="2"
                ellipsis="..."
                basedOn="letters"
              />
            </div>
          </div>

          <div>
            <div className={styles.infoRowContainer}>
              {props.rating_count > 0 ? (
                <CourseCardRating
                  rating={props.rating}
                  rating_count={props.rating_count}
                />
              ) : (
                <CourseCardRating rating={0} rating_count={0} />
              )}
            </div>

            <div className={styles.infoRowContainer}>
              <div className={styles.details}>{props.price}&nbsp;</div>
              {props.isVideo ? (
                <div className={styles.details}>
                  <YouTubeIcon
                    style={{ color: "rgb(52, 52, 52)" }}
                    fontSize={"inherit"}
                  />
                </div>
              ) : (
                <div className={styles.details}>
                  <EventOutlinedIcon
                    style={{ color: "rgb(52, 52, 52)" }}
                    fontSize={"inherit"}
                  />
                  &nbsp;
                  {props.date}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </Tooltip>
  );
};

export default CourseItem;
