import React from "react";
import Paper from "@material-ui/core/Paper";
import Tabs from "@material-ui/core/Tabs";
import styles from "./MatTabs.module.css";

const MatTabs = (props) => {
  const handleChange = (event, newValue) => {
    props.onClick(newValue);
  };

  return (
    <Paper className={styles.root}>
      {props.headerTitle && (
        <div className={styles.headerTitle}>{props.headerTitle}</div>
      )}
      <Tabs style={props.tabsStyle ? props.tabsStyle : null}
        TabIndicatorProps={{
          style: {
            height: "6px",
          },
        }}
        value={props.activeTab}
        onChange={handleChange}
        indicatorColor="primary"
        textColor="primary"
        centered={props.centered}
        className={styles.tab}
      >
        {props.children}
      </Tabs>
      {props.otherComponent && props.otherComponent}
    </Paper>
  );
};

export default MatTabs;
