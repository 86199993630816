import { SET_ANALYTICS, SET_AD_ANALYTICS } from "../actions/analytics";
import { LOGOUT } from "../actions/auth";
import { updateObject } from "../../globals/Common";

const initialState = {
  adViews: [],
  viewsAndReg: [],
  chart_viewsVsReg: [],
  chart_onlineVsClass: [],
  chart_regByOcc: [],
  chart_regByCity: [],
  chart_regByExperience: [],
  chart_regByCategory: [],
  chart_regByProf: [],
};

const statReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_ANALYTICS:
      return updateObject(state, {
        viewsAndReg: action.viewsAndReg,
        chart_viewsVsReg: action.chart.chart_viewsVsReg,
        chart_onlineVsClass: action.chart.chart_onlineVsClass,
        chart_regByOcc: action.chart.chart_regByOcc,
        chart_regByCity: action.chart.chart_regByCity,
        chart_regByExperience: action.chart.chart_regByExperience,
        chart_regByCategory: action.chart.chart_regByCategory,
        chart_regByProf: action.chart.chart_regByProf,
      });
    case SET_AD_ANALYTICS:
      return updateObject(state, {
        adViews: action.adViews,
      });

    case LOGOUT:
      return initialState;
    default:
      return state;
  }
};

export default statReducer;
