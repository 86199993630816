import React from "react";
import Button from "@material-ui/core/Button";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { makeStyles } from "@material-ui/core/styles";

const CustomIconButton = (props) => {
  const useIconOnlyButton = useMediaQuery("(max-width:399px)");

  const useStyles = makeStyles(() => ({
    button: {
      height: 15,
    },
    label: {
      flexDirection: "column",
    },
  }));

  const classes = useStyles();

  return (
    <Button
      onClick={props.onClick}
      classes={{ label: classes.label }}
      variant="outlined"
      color="primary"
      size="large"
      style={{
        flex: 1,
        backgroundColor: "white",
        border: "1px solid #ccc",
        marginRight: "2px",
        color: "black",
        ...props.style
      }
      }
    >
      <div>{props.icon}</div>
      <div style={{ fontSize: "9px" }}>{props.text}</div>
    </Button>
  );
};

export default CustomIconButton;
