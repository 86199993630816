import React from "react";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";

const Spinner = (props) => {
  const useStyles = makeStyles((theme) => ({
    backdrop: {
      zIndex: theme.zIndex.drawer + 300,
      color: "black",
    },
    colorPrimary: {
      color: "silver",
    },
  }));

  const classes = useStyles();

  return (
    <Backdrop className={classes.backdrop} open={true}>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <CircularProgress color="primary" className={classes.colorPrimary} />
        <div style={{ paddingTop: "5px", color: "silver" }}>
          {props.message ? props.message : ""}
        </div>
      </div>
    </Backdrop>
  );
};

export default Spinner;
