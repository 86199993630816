import React from "react";
import { useTranslation } from "react-i18next";
import TextButton from "../../../components/ui/button/TextButton";
import styles from "./CreditCard.module.css";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import { resolveCc } from "../../../globals/Common"; import Fab from "@material-ui/core/Fab";

const updateSelectText = (t, paymentMethod) => {
  if (paymentMethod) {
    return t("cart_update_cc");
  }
  return t("cart_select_cc");
};

const resolve = (object, key) => {
  return object ? object[key] : "";
};

const CreditCard = (props) => {
  const { t } = useTranslation();
  const selectCardText = updateSelectText(t, props.paymentMethod);

  let paymentContent = null;
  if (!props.paymentMethods || props.paymentMethods.length === 0) {
    paymentContent = (
      <div className={styles.emptyMethodsContainer}>
        <div style={{ paddingBottom: "10px" }}>{t("cart_no_credit_card")}</div>
        <Fab
          variant="extended"
          color="primary"
          onClick={props.addPaymentMethod}
        >
          {t("g_add_payment_method")}
        </Fab>
      </div>
    );
  } else {
    paymentContent = (
      <div className={styles.container}>
        <div style={{ fontSize: "13px" }}>
          <div style={{ paddingBottom: "7px" }}>
            {props.paymentMethod
              ? resolveCc(props.paymentMethod.brand, 35)
              : ""}
          </div>
          <div style={{ fontWeight: "bold" }}>
            {resolve(props.paymentMethod, "name")}
          </div>
          <div>{resolve(props.paymentMethod, "lastDigitsMasked")}</div>
          <div>
            {props.paymentMethod
              ? "Exp: " + props.paymentMethod.expiryDate
              : ""}
          </div>
        </div>

        <div className={styles.selectButtonContainer}>
          <TextButton
            onClick={props.openPaymentMethodHandler}
            style={{ fontSize: 14 }}
          >
            {selectCardText}
            <ArrowForwardIosIcon
              style={{ marginLeft: "4px", fontSize: "12px" }}
            />
          </TextButton>
        </div>
      </div>
    );
  }

  return <div className={styles.summaryContainer}>{paymentContent}</div>;
};

export default CreditCard;
