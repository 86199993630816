import { SET_ORDERS } from "../actions/orders";
import { updateObject } from "../../globals/Common";
import { LOGOUT } from "../actions/auth";

const initialState = {
  orders: [],
};

const ordersReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_ORDERS:
      return updateObject(state, {
        orders: action.orders.orders,
      });
    case LOGOUT:
      return initialState;
    default:
      return state;
  }
};

export default ordersReducer;
