import { SET_ALERT, REMOVE_ALERT } from "../actions/alert";
import { updateObject } from "../../globals/Common";
import { LOGOUT } from "../actions/auth";

const initialState = {
  alert: [],
};

const alertReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_ALERT:
      return updateObject(state, {
        alert: action.alert,
      });
    case REMOVE_ALERT:
      const copyAlertDel = [...state.alert];
      const itemIndex = copyAlertDel.findIndex((it) => it.id === action.id);
      copyAlertDel.splice(itemIndex, 1);
      return updateObject({
        state,
        alert: copyAlertDel,
      });
    case LOGOUT:
      return initialState;
    default:
      return state;
  }
};

export default alertReducer;
