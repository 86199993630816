import React from "react";
import Button from "@material-ui/core/Button";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import { Survey, StylesManager } from "survey-react/survey.react.min.js";
import "survey-knockout/modern.min.css";

StylesManager.applyTheme("modern");

const SurveyPreviewModal = (props) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("xs"));
  const fullWidth = true;
  const maxWidth = "sm";

  // ------------------------------

  const handleClose = () => {
    props.onClose();
  };

  // ------------------------------

  return (
    <div>
      <Dialog
        fullScreen={fullScreen}
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={props.open}
        onClose={handleClose.bind(this, null, null)}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="alert-dialog-title">
          {props.title ? props.title : "Survey Preview"}
        </DialogTitle>

        <DialogContent>
          <div style={{ height: "10px" }}>&nbsp;</div>
          {props.surveyJson ? (
            <Survey
              json={props.surveyJson}
              onComplete={props.onComplete ? props.onComplete : () => {}}
            />
          ) : (
            <div>Error loading survey preview, please try again.</div>
          )}
        </DialogContent>
        <DialogActions
          style={
            props.actionButtonLeftAlign
              ? {
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-start",
                  width: "100%",
                  alignItems: "flex-start",
                  borderTop: "1px solid #ccc",
                }
              : null
          }
        >
          <div>
            <Button onClick={handleClose} color="primary">
              {props.okButtonText ? props.okButtonText : "Ok"}
            </Button>
          </div>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default SurveyPreviewModal;
