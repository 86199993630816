import React from "react";
import * as styles from "./Chart.module.css";
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip } from "recharts";
import IconButton from "@material-ui/core/IconButton";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import { CSVLink } from "react-csv";

const ZBarVertical = (props) => {
  return (
    <div className={styles.container}>
      <div className={styles.title}>
        {props.title}
        <IconButton>
          <CSVLink
            style={{ color: "black" }}
            data={props.data ? props.data : []}
            headers={props.headers}
            filename={props.csvfileName}
          >
            <CloudDownloadIcon />
          </CSVLink>
        </IconButton>
      </div>

      <div className={styles.chartContainer}>
        {props.data && props.data.length > 0 ? (
          <BarChart
            width={500}
            height={300}
            data={props.data}
            margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
            layout="vertical"
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis type="number" />
            <YAxis type="category" dataKey="name" width={150} />
            <Tooltip />
            <Bar dataKey="Registrations" fill={props.fillColor}></Bar>
          </BarChart>
        ) : (
          props.noDataComponent
        )}
      </div>
    </div>
  );
};

export default ZBarVertical;
