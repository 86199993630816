import Payment from "../../model/Payment";
import { dbName } from "../../globals/ApiKeys";
import { handleErrorResponse } from "../../globals/Error";
import { fromFirebaseDoubleValue } from "../../globals/Common";
export const SET_PAYMENTS = "SET_PAYMENTS";
export const REMOVE_PAYMENT = "REMOVE_PAYMENT";
export const SET_CLIENT_SECRET = "SET_CLIENT_SECRET";
export const SET_PAYMENT_CREDITS = "SET_PAYMENT_CREDITS";
export const PI_COMPLETED = "PI_COMPLETED";

// ------------------------------

export const requestSetupIntent = () => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;

    const response = await fetch(
      `https://us-central1-${dbName}.cloudfunctions.net/setupIntent`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          data: {},
        }),
      }
    );

    if (!response.ok) {
      handleErrorResponse(await response.json());
    }

    const resData = await response.json();

    dispatch({
      type: SET_CLIENT_SECRET,
      clientSecret: resData.result.client_secret,
    });
  };
};

// ------------------------------

export const requestAttachPaymentMethod = (paymentMethodId) => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;

    const response = await fetch(
      `https://us-central1-${dbName}.cloudfunctions.net/attachPayMethod`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          data: { paymentMethodId: paymentMethodId },
        }),
      }
    );

    if (!response.ok) {
      handleErrorResponse(await response.json());
    }

    await response.json();

    dispatch({
      type: SET_CLIENT_SECRET,
      clientSecret: null,
    });
  };
};

// ------------------------------

export const requestDetachPaymentMethod = (paymentMethodId) => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;

    const response = await fetch(
      `https://us-central1-${dbName}.cloudfunctions.net/detachPayMethod`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          data: { paymentMethodId: paymentMethodId },
        }),
      }
    );

    if (!response.ok) {
      handleErrorResponse(await response.json());
    }

    await response.json();

    dispatch({
      type: REMOVE_PAYMENT,
      paymentMethodId: paymentMethodId,
    });
  };
};

// ------------------------------

export const fetchPaymentMethods = () => {
  return async (dispatch, getState) => {
    const userId = getState().auth.userId;
    const token = getState().auth.token;

    const response = await fetch(
      `https://firestore.googleapis.com/v1/projects/${dbName}/databases/(default)/documents/stripe_customers/${userId}/sources`,
      {
        method: "GET",
        headers: {
          "Content-Type": "applications/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );

    if (!response.ok) {
      handleErrorResponse(await response.json());
    }

    const resData = await response.json();

    const loadedPayments = [];
    if (resData.documents) {
      for (const payment of resData.documents) {
        const pathArr = payment.name.split("/");
        const fingerprint = pathArr[pathArr.length - 1];

        const monthWithPad0 =
          payment.fields.card.mapValue.fields.exp_month.integerValue < 10
            ? "0" +
            payment.fields.card.mapValue.fields.exp_month.integerValue +
            "/" +
            payment.fields.card.mapValue.fields.exp_year.integerValue
            : payment.fields.card.mapValue.fields.exp_month.integerValue +
            "/" +
            payment.fields.card.mapValue.fields.exp_year.integerValue;

        loadedPayments.push(
          new Payment(
            fingerprint,
            payment.fields.id.stringValue,
            payment.fields.billing_details.mapValue.fields.name.stringValue,
            payment.fields.card.mapValue.fields.last4.stringValue,
            "**** **** **** " +
            payment.fields.card.mapValue.fields.last4.stringValue,
            monthWithPad0,
            payment.fields.card.mapValue.fields.brand.stringValue,
            payment.fields.card.mapValue.fields.country.stringValue
          )
        );
      }
    }

    dispatch({
      type: SET_PAYMENTS,
      payment: loadedPayments,
    });
  };
};

// ------------------------------

export const processPaymentIntent = (cart, paymentMethod, creditsUsed) => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;

    const response = await fetch(
      `https://us-central1-${dbName}.cloudfunctions.net/paymentIntent`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          data: {
            items: cart.items,
            totalAmount: cart.totalAmount,
            creditsUsed: creditsUsed,
            paymentMethodId: paymentMethod ? paymentMethod.id : null,
          },
        }),
      }
    );

    if (!response.ok) {
      handleErrorResponse(await response.json());
    }

    const resData = await response.json();

    if (resData.result && resData.result.requires_action === true) {
      throw resData; // throwing to the client to take action
    }
  };
};

// ------------------------------

export const fetchPaymentCredits = () => {
  return async (dispatch, getState) => {
    const userId = getState().auth.userId;
    const token = getState().auth.token;

    const response = await fetch(
      `https://firestore.googleapis.com/v1/projects/${dbName}/databases/(default)/documents/stripe_customers/${userId}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "applications/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );

    if (!response.ok) {
      handleErrorResponse(await response.json());
    }

    const resData = await response.json();

    let credits = 0;

    if (resData && resData.fields) {
      if (resData.fields.expiration_date) {
        const creditsExpirationDate = new Date(
          resData.fields.expiration_date.timestampValue
        );

        if (creditsExpirationDate.getTime() > new Date().getTime()) {
          credits = resData.fields.credits
            ? fromFirebaseDoubleValue(
              resData.fields.credits.doubleValue,
              resData.fields.credits.integerValue
            )
            : 0;
        }
      }
    }

    dispatch({
      type: SET_PAYMENT_CREDITS,
      paymentCredits: credits,
    });
  };
};

// ------------------------------

export const processMobilePaymentIntent = (
  cart,
  paymentMethodId,
  coupon,
  selectedPaymentOption
) => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;
    const lang = getState().setting.mobileSetting.language;

    const response = await fetch(
      `https://us-central1-${dbName}.cloudfunctions.net/mobilePaymentIntent`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          data: {
            items: cart.items,
            totalAmount: cart.totalAmount,
            paymentMethodId: paymentMethodId,
            promoCode: coupon ? coupon.promo_code : null,
            multiPayment: selectedPaymentOption === "multiple" ? true : false,
          },
        }),
      }
    );

    if (!response.ok) {
      handleErrorResponse(await response.json(), lang);
    }

    const resData = await response.json();

    if (resData.result && resData.result.success === false) {
      throw resData;
    }

    if (resData.result && resData.result.requires_action === true) {
      throw resData; // throwing to the client to take action
    }
  };
};
