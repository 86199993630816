class BalanceItem {
  constructor(
    id,
    orderRefNumber,
    type,
    source,
    amount,
    net,
    currency,
    fee,
    unixCreated,
    created,
    status,
    chargeId
  ) {
    this.id = id;
    this.orderRefNumber = orderRefNumber;
    this.type = type;
    this.source = source;
    this.amount = amount;
    this.net = net;
    this.currency = currency;
    this.fee = fee;
    this.unixCreated = unixCreated;
    this.created = created;
    this.status = status;
    this.chargeId = chargeId;
  }
}

export default BalanceItem;
