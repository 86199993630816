import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { Prompt } from "react-router"
import { withTranslation } from "react-i18next";
import styles from "./Ce.module.css";
import * as ceActions from "../../../store/actions/ces";
import * as regActions from "../../../store/actions/registration";
import Ce from "../../../model/Ce";
import SimpleContainer from "../../../components/ui/container/Container";
import Button from "@material-ui/core/Button";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import MatAppBar from "../../../components/ui/appBar/MatAppBar";
import FormButton from "../../../components/ui/button/FormButton";
import InfoModal from "../../../components/modals/infoModal/InfoModal";
import Spinner from "../../../components/ui/spinner/Spinner";
import EditIcon from "@material-ui/icons/Edit";
import CeEditForm from "./CeEditForm";
import { hasFormErrors, validateField } from "../../../globals/Validations";
import * as logger from "../../../globals/Logger";
import AlertSuccess from "../../../components/ui/snackbar/AlertSuccess";
import DocumentListModal from "../../../components/modals/documentListModal/DocumentListModal";


class CeEditScreen extends Component {
    state = {
        wait: false,
        message: null,
        messageTitle: null,
        errorModal: false,

        alertSuccess: null,
        showDocumentListModal: false,
        selectedRegistration: null,

        // ----- form
        ce: this.props.ce,
        certs: null,
        formErrors: {},
        hasErrors: false,
        touched: false,
    };

    // ------------------------------

    update = async (propValue) => {
        await this.setState({ ...this.state, ...propValue });
    };


    // ------------------------------

    componentDidMount = async () => {
        try {
            window.scrollTo(0, 0);
            await this.update({ wait: true })
            if (this.props.ce && this.props.ce.id) {
                await this.props.fetchCePicture(this.props.ce.id);
            }

            await this.update({ wait: false })
        } catch (err) {
            logger.error(err);
            this.update({
                wait: false,
                errorModal: true,
                message: err.message,
            });
        }
    };

    // ------------------------------

    componentDidUpdate = (prevProps) => {
        if (prevProps.ce.id !== this.props.ce.id
            || prevProps.ce.certificateUri !== this.props.ce.certificateUri
        ) {
            this.update({
                ce: this.props.ce,
            });
        }
    };

    // ------------------------------

    closeErrorHandler = () => {
        this.update({
            message: null,
        });
    };

    // ------------------------------

    saveHandler = async () => {
        try {
            await this.validateForm();

            if (this.state.hasErrors) {
                await this.update({
                    errorModal: true,
                    message: this.props.t("settings_please_verify"),
                });

                return false;
            }

            if (this.state.touched) {
                await this.update({ wait: true });

                if (!this.state.ce.id) {
                    await this.props.addCe(this.state.ce, this.state.certs ? this.state.certs : []);
                } else {
                    await this.props.updateCe(this.state.ce, this.state.certs ? this.state.certs : []);
                }

                await this.update({ wait: false, alertSuccess: this.props.t("ce_saved"), touched: false });
            }
        } catch (err) {
            logger.error(err);
            this.update({
                wait: false,
                errorModal: true,
                message: err.message,
            });
        }
    };

    // ------------------------------

    validateForm = async () => {
        const t = this.props.t;
        const p = this.state.ce;
        const e = {};
        const e2 = validateField(p, e, "title", "plainRequired", t);
        const e3 = validateField(p, e2, "practice_hours", "required", t);
        const e4 = validateField(p, e3, "lecture_hours", "required", t);
        await this.update({ formErrors: e4 });
        await this.updateHasErrors(e4);
    };

    // ------------------------------

    updateHasErrors = async (formErrors) => {
        await this.update({ hasErrors: hasFormErrors(formErrors, {}) });
    };

    // ------------------------------


    openCertHandler = async (courseId) => {
        try {
            let registration = null;

            if (courseId) {
                registration = await this.props.getRegistrationByCourseId(courseId);
            }

            this.update({ selectedRegistration: registration, showDocumentListModal: true })
        } catch (error) {
            logger.error(error);
            this.update({
                showDocumentListModal: false,
                wait: false,
                errorModal: true,
                message: error.message,
            });
        }
    };


    // ------------------------------

    updateDropCertificate = (certAttachments) => {
        this.update({ certs: certAttachments, touched: true })
    }


    // ------------------------------

    render() {
        if (this.props.action !== "PUSH") {
            return <Redirect to="/ce" />;
        }

        if (this.state.wait) {
            return <Spinner />;
        }

        return (
            <div style={{ background: "white" }}>
                <MatAppBar
                    static
                    left={
                        <Button
                            color="inherit"
                            style={{ color: "black" }}
                            onClick={this.props.history.goBack}
                            startIcon={<ArrowBackIosIcon />}
                        >
                            {this.props.t("g_back")}
                        </Button>
                    }
                />
                <SimpleContainer disableGutters={false}>
                    <div style={{ backgroundColor: "white" }}>
                        <Prompt
                            when={this.state.touched}
                            message={this.props.t("g_unsaved_changes")}
                        />

                        {this.state.alertSuccess !== null && (
                            <AlertSuccess
                                message={this.state.alertSuccess}
                                open={this.state.alertSuccess !== null}
                                onClose={() => {
                                    this.update({ alertSuccess: null });
                                }}
                            />
                        )}
                        <InfoModal
                            isErrorModal={this.state.errorModal}
                            title={
                                this.state.errorModal
                                    ? this.props.t("error_occurred")
                                    : this.state.messageTitle
                            }
                            message={this.state.message}
                            open={this.state.message !== null}
                            onClose={this.closeErrorHandler}
                        />
                        {this.state.showDocumentListModal && (
                            <DocumentListModal
                                showDropzone
                                mobileCerts={
                                    this.state.certs ?
                                        this.state.certs :
                                        this.state.ce.certificateUri ?
                                            [{ name: "certificate", uri: this.state.ce.certificateUri, file: null }]
                                            : []
                                }
                                open={this.state.showDocumentListModal}
                                registration={this.state.selectedRegistration}
                                documentListModalError={this.state.documentListModalError}
                                onDropzoneChange={this.updateDropCertificate}
                                onClose={async () => {
                                    await this.update({
                                        showDocumentListModal: false,
                                        documentListModalError: null,
                                    });
                                }}
                            />
                        )}

                        <div style={{ height: "80px" }}>&nbsp;</div>

                        <div className={styles.sectionTitle}>
                            <div className={styles.centerRow}>
                                <EditIcon style={{ color: "white" }} />
                                &nbsp;&nbsp;
                                {this.props.isNew
                                    ? this.props.t("ce_add")
                                    : this.props.t("ce_edit")}
                            </div>
                        </div>

                        <FormButton
                            label={this.props.t("g_save")}
                            onClick={this.saveHandler}
                            disabled={this.state.hasErrors || !this.state.touched}
                        />

                        {this.state.ce && (
                            <div style={{ paddingTop: "20px" }}>
                                <CeEditForm
                                    hasCertificate={this.state.ce.certificateUri || this.state.certs && this.state.certs.length > 0}
                                    openCertificate={this.openCertHandler.bind(this, this.state.ce.courseId)}
                                    ce={this.state.ce}
                                    formErrors={this.state.formErrors}
                                    updateFormErrors={async (errors) => {
                                        await this.update({ formErrors: errors });
                                        await this.updateHasErrors(errors);
                                    }}
                                    updateHasErrors={async (errors) => {
                                        await this.updateHasErrors(errors);
                                    }}
                                    update={async (ce) => {
                                        await this.update({
                                            ce: ce,
                                            touched: true
                                        });
                                    }}
                                    isInEdit={this.state.ce && !this.state.ce.courseId}
                                />
                                <FormButton
                                    label={this.props.t("g_save")}
                                    onClick={this.saveHandler}
                                    disabled={this.state.hasErrors || !this.state.touched}
                                />
                            </div>
                        )}
                    </div>
                </SimpleContainer>

                <div style={{ height: "80px" }}>&nbsp;</div>
            </div>
        );
    }
}

// ------------------------------

const mapStateToProps = (state, props) => {
    const ce = state.ce.ceObj;
    return {
        action: props.history.action,
        ce: ce ? ce : new Ce(),
        isNew: ce ? false : true
    };
};

// ------------------------------

const mapDispatchToProps = (dispatch) => {
    return {
        getRegistrationByCourseId: (courseId) => dispatch(regActions.getRegistrationByCourseId(courseId)),
        fetchCePicture: (ceId) => dispatch(ceActions.fetchCePicture(ceId)),
        addCe: (ce, certs) => dispatch(ceActions.addCe(ce, certs)),
        updateCe: (ce, certs) => dispatch(ceActions.updateCe(ce, certs)),
    };
};

export default withTranslation("translations")(
    connect(mapStateToProps, mapDispatchToProps)(CeEditScreen)
);
