import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Drawer from "@material-ui/core/Drawer";
import Button from "@material-ui/core/Button";
import { selectCategories } from "../../../../globals/Data";
import { translate } from "../../../../globals/Common";
import SortByAlphaIcon from "@material-ui/icons/SortByAlpha";
import styles from "./Drawer.module.css";

// ------------------------------

const CourseSortDrawer = (props) => {
  const { t } = useTranslation();
  const [selectedCategories, setSelectedCategories] = useState([]);

  // ------------------------------

  const translateList = (list) => {
    const translatedList = [];

    for (const item of list) {
      const copyItem = { ...item };

      copyItem.label = translate(t, copyItem.label);
      translatedList.push(copyItem);
    }

    translatedList.sort((a, b) =>
      a.label.toLowerCase() > b.label.toLowerCase() ? 1 : -1
    );

    return translatedList;
  };

  const [translatedCategories, setTranslatedCategories] = useState(
    translateList(selectCategories)
  );

  // ------------------------------

  const toggleDrawer = () => (event) => {
    props.close();
  };

  // ------------------------------

  return (
    <Drawer
      anchor="bottom"
      open={props.open}
      onClose={toggleDrawer()}
      variant={null}
    >
      <div role="presentation">
        <div className={styles.drawer} onClick={toggleDrawer()}>
          <SortByAlphaIcon style={{ fontSize: "25px", marginRight: "10px" }} />
          {t("g_sort")}
        </div>

        <div className={styles.sortButtonContainer}>
          <Button
            variant="outlined"
            color="primary"
            size="large"
            onClick={props.close.bind(this, "date_asc")}
            className={styles.sortButton}
          >
            {t("g_date")} ({t("g_ascending")})
          </Button>
          <br />
          <Button
            variant="outlined"
            color="primary"
            size="large"
            onClick={props.close.bind(this, "date_des")}
            className={styles.sortButton}
          >
            {t("g_date")} ({t("g_descending")})
          </Button>
          <br />

          <Button
            variant="outlined"
            color="primary"
            size="large"
            onClick={props.close.bind(this, "price_low")}
            className={styles.sortButton}
          >
            {t("g_price")} ({t("g_low_high")})
          </Button>
          <br />

          <Button
            variant="outlined"
            color="primary"
            size="large"
            onClick={props.close.bind(this, "price_high")}
            className={styles.sortButton}
          >
            {t("g_price")} ({t("g_high_low")})
          </Button>
          <br />
        </div>
      </div>
    </Drawer>
  );
};

export default CourseSortDrawer;
