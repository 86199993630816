import React, { useState } from "react";
import styles from "./ImageSelectConfirmModal.module.css";
import ImageInput from "../../ui/image/ImageInput";
import ImageCropModal from "../../modals/imageCropModal/ImageCropModal";

const ImageSelector = (props) => {
  const [imageForCrop, setImageForCrop] = useState(null);

  return (
    <div>
      {imageForCrop !== null && (
        <ImageCropModal
          aspect={props.aspectRatio}
          title="Select and Crop Photo"
          file={imageForCrop}
          defaultFile={imageForCrop}
          open={imageForCrop !== null}
          onClose={(url) => {
            if (url) {
              props.setImage(url);
            }

            setImageForCrop(null);
          }}
        />
      )}

      <div className={styles.dialogContainer}>
        <div className={styles.brandingDesc}>
          Stay on brand by adding a cover photo to your course. Simply click the
          image to replace it.
        </div>

        <div className={styles.brandingDescSize}>
          Image must be <b>480 x 270 pixels</b> in size.
        </div>
        <div style={{ border: "1px solid black" }}>
          <ImageInput
            deleteTitle="Remove Photo"
            readOnly={false}
            coverPhoto={
              props.image &&
              (props.image.startsWith("blob:") ||
                props.image.startsWith("https"))
                ? `url(${props.image})`
                : props.image
            }
            width={props.imageWidth}
            height={props.imageHeight}
            onImageSelect={(imageUri) => {
              setImageForCrop(imageUri);
            }}
            onRemovePic={
              props.image && !props.image.startsWith("url(/static")
                ? () => {
                    props.onRemovePic(props.image);
                  }
                : null
            }
          />
        </div>
      </div>
    </div>
  );
};

export default ImageSelector;
