import { handleErrorResponse } from "../../globals/Error";
import {
  toFirebaseStringArrayValue,
  fromFirebaseDoubleValue,
  fromFirebaseStringArrayValue,
} from "../../globals/Common";
import { dbName } from "../../globals/ApiKeys";
import * as logger from "../../globals/Logger";

import CartProvider from "../../model/CartProvider";
import CartCourseItem from "../../model/CartCourseItem";
import CartPricingItem from "../../model/CartPricingItem";
import StaffMember from "../../model/StaffMember";
import MultiPayOption from "../../model/MultiPayOption";
import PricingItem from "../../model/PricingItem";

export const ADD_TO_CART = "M_ADD_TO_CART";
export const REMOVE_FROM_CART = "M_REMOVE_FROM_CART";
export const CLEAR_CART = "M_CLEAR_CART";

export const SET_CART = "M_SET_CART";
export const SET_CART_PROMO_CODE = "M_SET_CART_PROMO_CODE";
export const CLEAR_PROMO_CODE = "M_CLEAR_PROMO_CODE";
export const SET_MULTI_PAY_OPTION = "M_SET_MULTI_PAY_OPTION";

// ------------------------------

export const addToCart = (course, pricingItemToStaff) => {
  return {
    type: ADD_TO_CART,
    cart: { course: course, pricingItemToStaff: pricingItemToStaff },
  };
};

// ------------------------------

export const removeFromCart = (
  providerId,
  courseId,
  pricingItem,
  studentId
) => {
  return {
    type: REMOVE_FROM_CART,
    cart: {
      providerId: providerId,
      courseId: courseId,
      pricingItem: pricingItem,
      studentId: studentId,
    },
  };
};

// ------------------------------

export const clearCart = () => {
  return async (dispatch, getState) => {
    const userId = getState().auth.userId;
    const token = getState().auth.token;

    try {
      await fetch(
        `https://firestore.googleapis.com/v1/projects/${dbName}/databases/(default)/documents/stripe_cart/${userId}/cart/1`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "applications/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
    } catch (error) {
      logger.error(error);
    }

    dispatch({
      type: CLEAR_CART,
    });
  };
};

// ------------------------------

export const saveCart = (items, totalAmount) => {
  return async (dispatch, getState) => {
    const userId = getState().auth.userId;
    const token = getState().auth.token;

    try {
      const response = await fetch(
        `https://firestore.googleapis.com/v1/projects/${dbName}/databases/(default)/documents/stripe_cart/${userId}/cart/1`,
        {
          method: "PATCH",
          headers: {
            "Content-Type": "applications/json",
            Authorization: `Bearer ${token}`,
          },
          body: prepareCartForSave(items, totalAmount),
        }
      );

      if (!response.ok) {
        handleErrorResponse(await response.json());
      }
    } catch (error) {
      logger.error(error);
      throw error;
    }
  };
};

// ------------------------------

const prepareCartForSave = (providerCartItems, totalAmount) => {
  return JSON.stringify({
    fields: {
      totalAmount: { doubleValue: totalAmount },
      items: {
        arrayValue: {
          values: cartProviderToFirebaseArrayMap(providerCartItems),
        },
      },
    },
  });
};

// ------------------------------

const cartProviderToFirebaseArrayMap = (providerCartItems) => {
  let firebaseArray = [];
  for (var v of providerCartItems) {
    let mapCartCourseItems = cartCourseItemsToFirebaseMap(v.cartCourseItems);

    firebaseArray.push({
      mapValue: {
        fields: {
          providerCurrency: { stringValue: v.providerCurrency },
          cartCourseItems: { mapValue: { fields: mapCartCourseItems } },
          providerName: { stringValue: v.providerName },
          id: { stringValue: v.id },
        },
      },
    });
  }

  return firebaseArray;
};

// ------------------------------

const cartCourseItemsToFirebaseMap = (cartCourseItems) => {
  let firebaseMap = {};
  const keys = Object.keys(cartCourseItems);

  for (const key of keys) {
    firebaseMap[key] = {
      mapValue: {
        fields: {
          title: { stringValue: cartCourseItems[key].title },
          pricingItems: {
            arrayValue: {
              values: cartPricingItemsToFirebaseArrayMap(
                cartCourseItems[key].pricingItems
              ),
            },
          },
        },
      },
    };
  }
  return firebaseMap;
};

// ------------------------------

const cartPricingItemsToFirebaseArrayMap = (pricingItems) => {
  let firebaseArray = [];
  for (var v of pricingItems) {
    let firebaseMapPricingItem = cartPricingItemToFirebaseMap(v.pricingItem);

    firebaseArray.push({
      mapValue: {
        fields: {
          costAfterDiscount: { doubleValue: v.costAfterDiscount },
          pricingItem: { mapValue: { fields: firebaseMapPricingItem } },
          staffMembers: {
            arrayValue: {
              values: staffMembersToFirebaseArrayMap(v.staffMembers),
            },
          },
        },
      },
    });
  }

  return firebaseArray;
};

// ------------------------------

const cartPricingItemToFirebaseMap = (pricingItem) => {
  return {
    casting: { stringValue: pricingItem.casting },
    audience: {
      arrayValue: {
        values: toFirebaseStringArrayValue(pricingItem.audience),
      },
    },
    component: { stringValue: pricingItem.component },
    description: { stringValue: pricingItem.description },
    ce_odq: { doubleValue: pricingItem.ce_odq },
    credit_lecture_hours: { doubleValue: pricingItem.credit_lecture_hours },
    credit_handson_hours: { doubleValue: pricingItem.credit_handson_hours },
    cost: { doubleValue: pricingItem.cost },
    members_only: { booleanValue: pricingItem.members_only }
  };
};

// ------------------------------

const staffMembersToFirebaseArrayMap = (staffMembers) => {
  let firebaseArray = [];
  for (var v of staffMembers) {
    let mapStaffMember = staffToFirebaseMap(v);

    firebaseArray.push({
      mapValue: {
        fields: {
          staffMember: { mapValue: { fields: mapStaffMember } },
        },
      },
    });
  }

  return firebaseArray;
};

// ------------------------------

const staffToFirebaseMap = (staffMember) => {
  return {
    email: { stringValue: staffMember.email },
    first: { stringValue: staffMember.first },
    last: { stringValue: staffMember.last },
    id: { stringValue: staffMember.id },
    license: { stringValue: staffMember.license },
    occupation: { stringValue: staffMember.occupation },
  };
};

// checkout ------------------------------

export const fetchCart = () => {
  return async (dispatch, getState) => {
    const userId = getState().auth.userId;
    const token = getState().auth.token;

    const response = await fetch(
      `https://firestore.googleapis.com/v1/projects/${dbName}/databases/(default)/documents/stripe_cart/${userId}:runQuery`,
      {
        method: "POST",
        headers: {
          "Content-Type": "applications/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          structuredQuery: {
            from: [{ collectionId: "cart" }],
          },
        }),
      }
    );

    if (!response.ok) {
      handleErrorResponse(await response.json());
    }

    const resData = await response.json();

    const items = [];
    let totalAmount = 0;

    if (resData[0]) {
      const document = resData[0].document;

      if (document) {
        totalAmount = fromFirebaseDoubleValue(
          document.fields.totalAmount.doubleValue,
          document.fields.totalAmount.integerVaule
        );

        for (const item of document.fields.items.arrayValue.values) {
          const cartProviderItem = item.mapValue.fields;

          items.push(
            new CartProvider(
              cartProviderItem.id.stringValue,
              cartProviderItem.providerName.stringValue,
              buildCartCourseItems(cartProviderItem.cartCourseItems),
              cartProviderItem.providerCurrency.stringValue
            )
          );
        }
      }
    }

    // elibible for mutlipayments, lets fetch the courses multiPaySettings
    if (
      totalAmount >= 2000 &&
      items &&
      items.length === 1 &&
      items[0].cartCourseItems &&
      Object.keys(items[0].cartCourseItems).length === 1
    ) {
      const courseId = Object.keys(items[0].cartCourseItems)[0];
      const cartCourseItem = items[0].cartCourseItems[courseId];

      if (
        cartCourseItem.pricingItems.length === 1 &&
        cartCourseItem.pricingItems[0].staffMembers.length === 1
      ) {
        await dispatch(fetchMultiPayOption(courseId));
      }
    }
    // ----------

    dispatch({
      type: SET_CART,
      items: items,
      totalAmount: totalAmount,
    });
  };
};

// ------------------------------

const fetchMultiPayOption = (courseId) => {
  return async (dispatch, getState) => {
    const response = await fetch(
      `https://firestore.googleapis.com/v1/projects/${dbName}/databases/(default)/documents/courses/${courseId}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "applications/json",
        },
      }
    );

    if (!response.ok) {
      handleErrorResponse(await response.json());
    }

    const courseData = await response.json();

    if (
      courseData &&
      courseData.name &&
      courseData.fields &&
      courseData.fields.activation_status &&
      courseData.fields.activation_status.stringValue === "active"
    ) {
      dispatch({
        type: SET_MULTI_PAY_OPTION,
        multiPayOption: buildMultiPayOption(courseData.fields.multi_pay_option),
      });
    }
  };
};

// ------------------------------

const buildCartCourseItems = (courseItems) => {
  let cartItems = {};
  const cartPricingItem = courseItems.mapValue.fields;

  Object.keys(cartPricingItem).forEach((key, index) => {
    const pi = cartPricingItem[key];

    cartItems = {
      ...cartItems,
      [key]: new CartCourseItem(
        null, // id
        pi.mapValue.fields.title.stringValue,
        buildCartPricingItems(pi.mapValue.fields.pricingItems.arrayValue)
      ),
    };
  });

  return cartItems;
};

// ------------------------------

const buildCartPricingItems = (pricingItems) => {
  let arrPricingItems = [];

  for (const item of pricingItems.values) {
    const costAfterDiscount = item.mapValue.fields.costAfterDiscount;
    const pi = item.mapValue.fields.pricingItem;
    const arrStaff = item.mapValue.fields.staffMembers;

    arrPricingItems.push(
      new CartPricingItem(
        fromFirebaseDoubleValue(
          costAfterDiscount.doubleValue,
          costAfterDiscount.integerValue
        ),
        new PricingItem(
          pi.mapValue.fields.casting.stringValue,
          fromFirebaseStringArrayValue(
            pi.mapValue.fields.audience.arrayValue.values
          ),
          pi.mapValue.fields.component.stringValue,
          pi.mapValue.fields.description.stringValue,
          fromFirebaseDoubleValue(
            pi.mapValue.fields.ce_odq.doubleValue,
            pi.mapValue.fields.ce_odq.integerValue
          ),
          fromFirebaseDoubleValue(
            pi.mapValue.fields.credit_lecture_hours.doubleValue,
            pi.mapValue.fields.credit_lecture_hours.integerValue
          ),
          fromFirebaseDoubleValue(
            pi.mapValue.fields.credit_handson_hours.doubleValue,
            pi.mapValue.fields.credit_handson_hours.integerValue
          ),
          fromFirebaseDoubleValue(
            pi.mapValue.fields.cost.doubleValue,
            pi.mapValue.fields.cost.integerValue
          ),
          pi.mapValue.fields.expiration_date
            ? new Date(pi.mapValue.fields.expiration_date.timestampValue)
            : null,
          pi.mapValue.fields.members_only ? pi.mapValue.fields.members_only.booleanValue : false
        ),
        buildArrayOfStaffMembers(arrStaff.arrayValue)
      )
    );
  }

  return arrPricingItems;
};

// ------------------------------

const buildArrayOfStaffMembers = (arrStaff) => {
  let arrStaffMembers = [];

  for (const staffMember of arrStaff.values) {
    const sm = staffMember.mapValue.fields.staffMember;

    arrStaffMembers.push(
      new StaffMember(
        sm.mapValue.fields.id.stringValue,
        sm.mapValue.fields.license.stringValue,
        sm.mapValue.fields.occupation.stringValue,
        sm.mapValue.fields.last.stringValue,
        sm.mapValue.fields.first.stringValue,
        sm.mapValue.fields.email.stringValue,
        false
      )
    );
  }

  return arrStaffMembers;
};

// ------------------------------

export const applyPromoCode = (promoCode, providerCourses) => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;
    const lang = getState().setting.mobileSetting.language;

    const response = await fetch(
      `https://us-central1-${dbName}.cloudfunctions.net/applyPromoCode`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          data: {
            promoCode: promoCode,
            providerCourses: providerCourses,
          },
        }),
      }
    );

    if (!response.ok) {
      handleErrorResponse(await response.json(), lang);
    }

    const resData = await response.json();

    dispatch({
      type: SET_CART_PROMO_CODE,
      coupon: resData.result,
    });
  };
};

// ------------------------------

export const clearPromoCode = () => {
  return {
    type: CLEAR_PROMO_CODE,
  };
};

// ------------------------------

const buildMultiPayOption = (multiPayOption) => {
  if (multiPayOption && multiPayOption.mapValue) {
    const mapMultiPayOption = multiPayOption.mapValue.fields;

    return new MultiPayOption(
      mapMultiPayOption.enabled.booleanValue,
      mapMultiPayOption.first_pay_percent
        ? fromFirebaseDoubleValue(
          mapMultiPayOption.first_pay_percent.doubleValue,
          mapMultiPayOption.first_pay_percent.integerValue
        )
        : 30,
      mapMultiPayOption.installment_count
        ? fromFirebaseDoubleValue(
          mapMultiPayOption.installment_count.doubleValue,
          mapMultiPayOption.installment_count.integerValue
        )
        : 3
    );
  }

  return new MultiPayOption(false, 30, 3);
};

// ------------------------------
