import React from "react";
import styles from "./Elements.module.css";
import Avatar from "react-avatar";
import { fromNow } from "../../../../globals/Dates2";
import MatCheckbox from "../../../ui/checkbox/MatCheckbox";

const Conversation = (props) => {
  return (
    <div
      className={
        props.isSelected
          ? styles.conversationContainerSelected
          : styles.conversationContainer
      }
      style={
        props.isOdd ? { backgroundColor: "rgba(194, 235, 181, 0.92)" } : null
      }
      onClick={props.edit ? () => {} : props.onSelectConversation}
    >
      <div className={styles.dotContainer}>
        {props.conversation.count > 0 ? (
          <div className={styles.dot} />
        ) : (
          <div />
        )}
      </div>
      <div className={styles.messageAvatarContainer}>
        <Avatar
          name={props.conversation.resolveName()}
          size="50px"
          round={true}
        />
        <div
          className={
            props.leftPanel
              ? styles.messageContentContainerLeftPanel
              : styles.messageContentContainer
          }
        >
          <div className={styles.messageUserNameDateContainer}>
            <div className={styles.messageUserName}>
              {props.conversation.resolveName()}
            </div>
            {props.edit ? (
              <div></div>
            ) : (
              <div className={styles.leftMessageDate}>
                {fromNow(props.conversation.last_date)}
              </div>
            )}
          </div>
          <div
            className={
              props.edit
                ? styles.conversationMessageTextWrapperInEdit
                : styles.conversationMessageTextWrapper
            }
          >
            {props.conversation.resolveMe() ? (
              <div style={{ fontWeight: "bold" }}>Me:&nbsp;</div>
            ) : (
              ""
            )}
            <div className={styles.conversationMssageText}>
              {props.conversation.last_message}
            </div>
          </div>
        </div>

        {props.edit && (
          <div className={styles.deleteCheck}>
            <MatCheckbox
              nostyle="true"
              name="delete"
              checked={props.selectedForDelete}
              onChange={props.updateDeleteSelection}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default Conversation;
