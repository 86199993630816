import React from "react";
import { withStyles } from "@material-ui/core/styles";
import styles from "./StudentTable.module.css";
import EmailOutlinedIcon from "@material-ui/icons/EmailOutlined";
import PanToolOutlinedIcon from "@material-ui/icons/PanToolOutlined";
import SendOutlinedIcon from "@material-ui/icons/SendOutlined";
import PopMenu from "../../../components/ui/popMenu/PopMenu";
import MenuItem from "@material-ui/core/MenuItem";
import Divider from "@material-ui/core/Divider";
import PanToolIcon from "@material-ui/icons/PanTool";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";

const defaultToolbarStyles = {
  iconButton: {},
};

class StudentDefaultToolbar extends React.Component {
  state = {
    anchorEl: null,
  };

  // ------------------------------

  update = async (propValue) => {
    await this.setState({ ...this.state, ...propValue });
  };

  // ------------------------------

  handleClick = (event) => {
    this.update({ anchorEl: event.currentTarget });
  };

  // ------------------------------

  handleClose = () => {
    this.update({ anchorEl: null });
  };

  render() {
    const rowStyle = {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
    };

    return (
      <React.Fragment>
        <span>
          {this.props.isAttendanceActive && (
            <span>
              <Tooltip title="Manage Attendance" aria-label="Manage Attendance">
                <IconButton
                  aria-label="Manage Attendance"
                  onClick={this.props.takeAttendance}
                >
                  <PanToolIcon fontSize={"small"} style={{ color: "green" }} />
                </IconButton>
              </Tooltip>
            </span>
          )}
          <PopMenu
            iconBtn
            anchorEl={this.state.anchorEl}
            handleClick={this.handleClick}
            handleClose={this.handleClose}
          >
            <div className={styles.menuTitle}>All Students</div>
            <Divider />

            <MenuItem
              onClick={() => {
                this.handleClose();
                this.props.messageAll();
              }}
              style={rowStyle}
            >
              <EmailOutlinedIcon />
              &nbsp;&nbsp;Send Message
            </MenuItem>
            <MenuItem
              onClick={() => {
                this.handleClose();
                this.props.issueCert();
              }}
              style={rowStyle}
            >
              <SendOutlinedIcon />
              &nbsp;&nbsp;Send Certificate
            </MenuItem>

            {this.props.hasAttendance && (
              <div>
                <div
                  className={styles.menuTitle}
                  style={{ paddingTop: "10px", paddingBottom: "5px" }}
                >
                  Attendance
                </div>
                <Divider />
                {this.props.isAttendanceActive && (
                  <MenuItem
                    onClick={() => {
                      this.handleClose();
                      this.props.takeAttendance();
                    }}
                    style={rowStyle}
                  >
                    <PanToolOutlinedIcon />
                    &nbsp;&nbsp;Manage Attendance
                  </MenuItem>
                )}
                {this.props.isAttendanceActive && (
                  <Divider style={{ marginBottom: "20px" }} />
                )}
                <MenuItem
                  onClick={() => {
                    this.handleClose();
                    this.props.setAttendanceActive(
                      !this.props.isAttendanceActive
                    );
                  }}
                  style={{
                    ...rowStyle,
                    fontStyle: this.props.isAttendanceActive
                      ? "italic"
                      : "normal",
                  }}
                >
                  {!this.props.isAttendanceActive && <PanToolOutlinedIcon />}
                  &nbsp;&nbsp;
                  {this.props.isAttendanceActive
                    ? "Deactivate"
                    : "Activate"}{" "}
                  Attendance
                </MenuItem>
              </div>
            )}
          </PopMenu>
        </span>
      </React.Fragment>
    );
  }
}

export default withStyles(defaultToolbarStyles, {
  name: "StudentDefaultToolbar",
})(StudentDefaultToolbar);
