class MobileMarketingAd {
  constructor(
    id,
    course,
    prime,
    expiration,
    slide,
    headline,
    callForAction,
    showLogo,
    logoUri,
    adRanking
  ) {
    this.id = id;
    this.course = course;
    this.prime = prime;
    this.expiration = expiration;
    this.slide = slide;
    this.headline = headline;
    this.callForAction = callForAction;
    this.showLogo = showLogo;
    this.logoUri = logoUri;
    this.adRanking = adRanking;
  }
}

export default MobileMarketingAd;
