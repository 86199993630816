import { handleErrorResponse } from "../../globals/Error";
import { dbName } from "../../globals/ApiKeys";
import ZoomMeeting from "../../model/ZoomMeeting";
import ZoomParticipant from "../../model/ZoomParticipant";
import ZoomOccurrence from "../../model/ZoomOccurrence";
export const SET_ZOOM_SETTINGS = "SET_ZOOM_SETTINGS";
export const SET_ZOOM_MEETINGS = "SET_ZOOM_MEETINGS";
export const SET_ZOOM_MEETING_DETAIL = "SET_ZOOM_MEETING_DETAIL";
export const SET_ZOOM_PARTICIPANTS = "SET_ZOOM_PARTICIPANTS";

const MEETINGS = "meetings";
const WEBINARS = "webinars";

// ------------------------------

export const connectZoom = (code) => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;

    const response = await fetch(
      `https://us-central1-${dbName}.cloudfunctions.net/zoomConnect`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          data: { code: code },
        }),
      }
    );

    if (!response.ok) {
      handleErrorResponse(await response.json());
    }

    await dispatch(fetchZoomSettings());
  };
};

// ------------------------------

export const fetchZoomSettings = () => {
  return async (dispatch, getState) => {
    const userId = getState().auth.userId;
    const token = getState().auth.token;

    const response = await fetch(
      `https://firestore.googleapis.com/v1/projects/${dbName}/databases/(default)/documents/stripe_customers/${userId}/api/zoom`,
      {
        method: "GET",
        headers: {
          "Content-Type": "applications/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );

    if (!response.ok) {
      const errResponse = await response.json();

      if (errResponse.error.status !== "NOT_FOUND") {
        handleErrorResponse(errResponse);
      }

      dispatch({
        type: SET_ZOOM_SETTINGS,
        connected: false,
      });
    } else {
      const resData = await response.json();

      if (resData && resData.fields) {
        dispatch({
          type: SET_ZOOM_SETTINGS,
          connected: true,
        });
      }
    }
  };
};

// ------------------------------

export const fetchZoomMeetings = () => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;

    const arrResponse = await Promise.all([
      fetch(`https://us-central1-${dbName}.cloudfunctions.net/zoomMeetings`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          data: {
            refToken: "",
          },
        }),
      }),
      fetch(`https://us-central1-${dbName}.cloudfunctions.net/zoomWebinars`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          data: {
            refToken: "",
          },
        }),
      }),
    ]);

    //  const courseRes = await
    if (!arrResponse[0].ok) {
      handleErrorResponse(await arrResponse[0].json());
    }

    //  const draftRes = await
    if (!arrResponse[1].ok) {
      handleErrorResponse(await arrResponse[1].json());
    }

    const resMeeting = await arrResponse[0].json();
    const resWebinar = await arrResponse[1].json();

    const zoomMeetings = [];
    if (resMeeting && resMeeting.result) {
      const meetings = resMeeting.result.meetings.meetings;

      for (const meeting of meetings) {
        zoomMeetings.push(
          new ZoomMeeting(
            MEETINGS,
            meeting.uuid,
            meeting.id,
            meeting.host_id,
            meeting.topic,
            meeting.agenda,
            meeting.type,
            meeting.start_time,
            meeting.duration,
            meeting.timezone,
            meeting.created_at,
            meeting.join_url
          )
        );
      }
    }

    if (resWebinar && resWebinar.result && resWebinar.result.webinars) {
      const webinars = resWebinar.result.webinars.webinars;

      for (const webinar of webinars) {
        zoomMeetings.push(
          new ZoomMeeting(
            WEBINARS,
            webinar.uuid,
            webinar.id,
            webinar.host_id,
            webinar.topic,
            webinar.agenda,
            webinar.type,
            webinar.start_time,
            webinar.duration,
            webinar.timezone,
            webinar.created_at,
            webinar.join_url
          )
        );
      }
    }

    dispatch({
      type: SET_ZOOM_MEETINGS,
      zoom_meetings: zoomMeetings,
    });
  };
};

// ------------------------------

export const fetchZoomMeeting = (courseId) => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;

    const meetingId = await dispatch(fetchZoomId(courseId));

    let meeting_detail = null;

    if (meetingId) {
      const response = await fetch(
        `https://us-central1-${dbName}.cloudfunctions.net/zoomMeeting`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            data: {
              meetingId: meetingId,
            },
          }),
        }
      );

      if (!response.ok) {
        handleErrorResponse(await response.json());
      }

      const resData = await response.json();

      if (resData && resData.result) {
        const meetingRes = resData.result.meeting;

        meeting_detail = new ZoomMeeting(
          MEETINGS,
          meetingRes.uuid,
          meetingRes.id,
          meetingRes.host_id,
          meetingRes.topic,
          meetingRes.agenda,
          meetingRes.type,
          meetingRes.start_time,
          meetingRes.duration,
          meetingRes.timezone,
          meetingRes.created_at,
          meetingRes.join_url,
          // details
          meetingRes.status,
          meetingRes.password,
          meetingRes.start_url,
          meetingRes.registration_url,
          meetingRes.host_email,
          meetingRes.settings.approval_type,
          buildOccurrences(meetingRes)
        );
      }
    }

    dispatch({
      type: SET_ZOOM_MEETING_DETAIL,
      zoom_meeting_detail: meeting_detail,
    });
  };
};

// ------------------------------

export const fetchZoomParticipants = (courseId) => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;
    const meetingId = await dispatch(fetchZoomId(courseId));

    let zoom_participants = null;

    if (meetingId) {
      zoom_participants = await fetchParticipants(token, meetingId);
    }

    dispatch({
      type: SET_ZOOM_PARTICIPANTS,
      zoom_participants: zoom_participants,
    });
  };
};

// ------------------------------

export const updateZoomParticipantStatus = (courseId, registrants) => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;

    const meetingId = await dispatch(fetchZoomId(courseId));

    const zoomApiRegistrant = [];
    for (const reg of registrants) {
      zoomApiRegistrant.push({ id: reg.id, email: reg.email });
    }

    if (meetingId) {
      const response = await fetch(
        `https://us-central1-${dbName}.cloudfunctions.net/zoomUpdateParticipantStatus`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            data: {
              meetingId: meetingId,
              action: "approve",
              registrants: zoomApiRegistrant,
            },
          }),
        }
      );

      if (!response.ok) {
        handleErrorResponse(await response.json());
      }

      const zoom_participants = await fetchParticipants(token, meetingId);

      dispatch({
        type: SET_ZOOM_PARTICIPANTS,
        zoom_participants: zoom_participants,
      });
    }
  };
};

// ------------------------------

export const fetchZoomId = (courseId) => {
  return async (dispatch, getState) => {
    const userId = getState().auth.userId;
    const token = getState().auth.token;

    const response = await fetch(
      `https://firestore.googleapis.com/v1/projects/${dbName}/databases/(default)/documents/registration_livestream/${userId}/course/${courseId}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "applications/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );

    if (!response.ok) {
      const errResponse = await response.json();

      if (errResponse.error.status !== "NOT_FOUND") {
        handleErrorResponse(errResponse);
      }
    } else {
      const resData = await response.json();

      if (resData && resData.fields) {
        return resData.fields.meeting_id.integerValue;
      }
    }

    return null;
  };
};

// ------------------------------

const fetchParticipants = async (token, meetingId) => {
  const response = await fetch(
    `https://us-central1-${dbName}.cloudfunctions.net/zoomParticipants`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        data: {
          meetingId: meetingId,
        },
      }),
    }
  );

  if (!response.ok) {
    handleErrorResponse(await response.json());
  }

  const resData = await response.json();

  const zoomParticipants = [];
  if (resData && resData.result) {
    const participants = resData.result.approved.registrants.concat(
      resData.result.pending.registrants,
      resData.result.denied.registrants
    );

    for (const participant of participants) {
      zoomParticipants.push(
        new ZoomParticipant(
          participant.id,
          participant.email,
          participant.first_name,
          participant.last_name,
          participant.status,
          participant.created_time
        )
      );
    }

    return zoomParticipants;
  }

  return null;
};

// ------------------------------

const buildOccurrences = (meeting) => {
  const occurrences_list = [];

  if (meeting && meeting.occurrences) {
    for (const occurrence of meeting.occurrences) {
      occurrences_list.push(
        new ZoomOccurrence(
          occurrence.occurrence_id,
          occurrence.duration,
          occurrence.start_time,
          occurrence.status
        )
      );
    }
  }

  return occurrences_list;
};

// ------------------------------
