import React, { useState } from "react";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";

const ImageCroper = (props) => {
  const [crop, setCrop] = useState(
    props.aspect ? props.aspect : { aspect: 1 / 1, width: 170, height: 170 }
  );

  const [finalImageSize, setFinalImageSize] = useState(
    props.finalImageSize
      ? props.finalImageSize
      : { width: props.aspect.width, height: props.aspect.height }
  );

  const [imageRef, setImageRef] = useState();

  const onCropComplete = (crop) => {
    makeClientCrop(crop);
  };

  const getCroppedImg = (image, crop, fileName) => {
    const canvas = document.createElement("canvas");
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width * (finalImageSize.width / crop.width);
    canvas.height = crop.height * (finalImageSize.height / crop.height);
    const ctx = canvas.getContext("2d");

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width * (finalImageSize.width / crop.width),
      crop.height * (finalImageSize.height / crop.height)
    );

    return new Promise((resolve, reject) => {
      canvas.toBlob(
        (blob) => {
          if (!blob) {
            console.error("Canvas is empty");
            return;
          }
          blob.name = fileName;
          const fileUrl = window.URL.createObjectURL(blob);
          resolve(fileUrl);
        },
        "image/png",
        1
      );
    });
  };

  const makeClientCrop = async (crop) => {
    if (imageRef && crop.width && crop.height) {
      const croppedImageUrl = await getCroppedImg(
        imageRef,
        crop,
        "newFile.png"
      );

      props.onCrop(croppedImageUrl);
    }
  };

  // If you setState the crop in here you should return false.
  const onImageLoaded = (image) => {
    setImageRef(image);
  };

  const onCropChange = (crop, percentCrop) => {
    setCrop(crop);
  };

  return (
    <div>
      {props.file && (
        <ReactCrop
          src={props.file}
          crop={crop}
          onImageLoaded={onImageLoaded}
          onComplete={onCropComplete}
          onChange={onCropChange}
          locked={props.locked ? props.locked : false}
          keepSelection={true}
        />
      )}
    </div>
  );
};

export default ImageCroper;
