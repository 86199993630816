import React, { Component } from "react";
import styles from "./LoginScreen.module.css";
import { connect } from "react-redux";
import PhonelinkLockIcon from "@material-ui/icons/PhonelinkLock";
import Grow from "@material-ui/core/Grow";
import MatAppBar from "../../components/ui/appBar/MatAppBar";
import zubu_white from "../../assets/images/welcome/zubu_white.png";
import LogoMenuBar from "../../components/ui/logo/LogoMenuBar";

class MaintenanceScreen extends Component {
  render() {
    return (
      <div className={styles.screen}>
        <MatAppBar
          static
          classBar={{
            position: "fixed",
            top: 0,
            background: "#3E8CAB",
            zIndex: 115,
          }}
          left={<LogoMenuBar />}
        />

        <div className={styles.formAndFooter}>
          <div className={styles.maintenanceForm}>
            <PhonelinkLockIcon
              style={{ fontSize: "170px", color: "#437aa1" }}
            />
            <div style={{ padding: "30px", textAlign: "center" }}>
              Under maintenance please check back later.
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(null, null)(MaintenanceScreen);
