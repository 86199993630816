class Account {
  constructor(
    id,
    charges_enabled,
    payouts_enabled,
    email,
    country,
    default_currency,
    requirements,
    warningDismissed
  ) {
    this.id = id;
    this.charges_enabled = charges_enabled;
    this.payouts_enabled = payouts_enabled;
    this.email = email;
    this.country = country;
    this.default_currency = default_currency;
    this.requirements = requirements;
    this.warningDismissed = warningDismissed;
  }

  accountWarnings = () => {
    if (this.warningDismissed) {
      return false;
    }

    return this.accountRestricted() || this.accountRequiresAttention();
  };

  accountRestricted = () => {
    return this.charges_enabled === false || this.payouts_enabled === false;
  };

  accountRequiresAttention = () => {
    const require = this.requirements;
    return (
      require &&
      ((require.currently_due && require.currently_due.length > 0) ||
        (require.eventually_due && require.eventually_due.length > 0))
    );
  };
}

export default Account;
