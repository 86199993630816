class Currency {
  constructor(id, name, shortName, iso) {
    this.id = id;
    this.name = name;
    this.shortName = shortName;
    this.iso = iso;
  }
}

export default Currency;
