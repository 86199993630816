import React from "react";
import styles from "./Rating.module.css";

const RatingBar = (props) => {
  const arrStyles = [
    { id: 5, style: { width: "5px" } },
    { id: 10, style: { width: "10%" } },
    { id: 15, style: { width: "15%" } },
    { id: 20, style: { width: "20%" } },
    { id: 25, style: { width: "25%" } },
    { id: 30, style: { width: "30%" } },
    { id: 35, style: { width: "35%" } },
    { id: 40, style: { width: "40%" } },
    { id: 45, style: { width: "45%" } },
    { id: 50, style: { width: "50%" } },
    { id: 55, style: { width: "55%" } },
    { id: 60, style: { width: "60%" } },
    { id: 65, style: { width: "65%" } },
    { id: 70, style: { width: "70%" } },
    { id: 75, style: { width: "75%" } },
    { id: 80, style: { width: "80%" } },
    { id: 85, style: { width: "85%" } },
    { id: 90, style: { width: "90%" } },
    { id: 95, style: { width: "95%" } },
    { id: 100, style: { width: "100%" } },
  ];

  const toNonPercent = props.value;
  const valueRounded = Math.ceil(toNonPercent / 5) * 5;
  let findStyle = arrStyles.find((s) => s.id === valueRounded);

  if (!findStyle) {
    findStyle = { width: "0%" };
  }

  return (
    <div className={styles.bar}>
      <div className={styles.progress} style={{ width: findStyle.width }}>
        &nbsp;
      </div>
    </div>
  );
};

export default RatingBar;
