import React, { Component } from "react";
import { connect } from "react-redux";
import styles from "./AccountInfoScreen.module.css";
import * as profileActions from "../../../store/actions/profile";
import * as settingActions from "../../../store/actions/setting";
import AccountCircleOutlinedIcon from "@material-ui/icons/AccountCircleOutlined";
import EcoIcon from "@material-ui/icons/Eco";
import ReactCountryFlag from "react-country-flag";
import Spinner from "../../../components/ui/spinner/Spinner";
import InfoModal from "../../../components/modals/infoModal/InfoModal";
import SimpleContainer from "../../../components/ui/container/Container";
import { objectHasNoValues, resolveCountry } from "../../../globals/Common";

import * as logger from "../../../globals/Logger";

class AccountInfoScreen extends Component {
  state = {
    wait: false,
    isInEdit: true,
    error: null,
  };

  // ------------------------------

  componentDidMount = async () => {
    window.scrollTo(0, 0);
  };

  // ------------------------------

  update = async (propValue) => {
    await this.setState({ ...this.state, ...propValue });
  };

  // ------------------------------

  componentDidMount = async () => {
    try {
      this.update({ error: null, wait: true });

      const loadAsync = [];

      if (objectHasNoValues(this.props.profile)) {
        loadAsync.push(this.props.fetchProfile());
      }

      if (objectHasNoValues(this.props.setting)) {
        loadAsync.push(this.props.fetchSetting());
      }

      if (objectHasNoValues(this.props.pricingPlan)) {
        loadAsync.push(this.props.fetchPricingPlan());
      }

      await Promise.all(loadAsync);

      this.update({ error: null, wait: false });
    } catch (error) {
      logger.error(error);
      this.update({ wait: false, error: error.message });
    }
  };

  // ------------------------------

  render() {
    const countryObj = resolveCountry(this.props.profile.country);

    if (this.state.wait) {
      return <Spinner />;
    }

    return (
      <div style={{ background: "white" }}>
        <SimpleContainer disableGutters={false}>
          <InfoModal
            isErrorModal={true}
            title="An error has occured"
            message={this.state.error}
            open={this.state.error !== null}
            onClose={() => {
              this.update({ error: null });
            }}
          />

          <div style={{ height: "40px" }}>&nbsp;</div>

          <div className={styles.sectionTitle}>
            <div className={styles.centerRow}>
              <AccountCircleOutlinedIcon style={{ color: "white" }} />
              &nbsp;&nbsp;Account
            </div>
          </div>

          <div className={styles.sectionContainer}>
            {this.props.pricingPlan && this.props.pricingPlan.createdDate && (
              <div className={styles.row}>
                <div className={styles.rowTitle}>Created:</div>
                <div>{this.props.pricingPlan.createdDate()}</div>
              </div>
            )}

            {this.props.pricingPlan && this.props.pricingPlan.createdFromNow && (
              <div className={styles.row}>
                <div className={styles.rowTitle}></div>
                <div>{this.props.pricingPlan.createdFromNow()}</div>
              </div>
            )}

            <div className={styles.row}>
              <div className={styles.rowTitle}>Associated Email:</div>
              <div>{this.props.accountEmail}</div>
            </div>

            {countryObj && (
              <div className={styles.row}>
                <div className={styles.rowTitle}>Country:</div>
                <div className={styles.centerRow}>
                  {countryObj.country}&nbsp;&nbsp;
                  <ReactCountryFlag
                    title={countryObj.country}
                    countryCode={countryObj.code}
                    svg
                    style={{
                      fontSize: "1.5em",
                      lineHeight: "1.5em",
                    }}
                  />
                </div>
              </div>
            )}

            {countryObj && (
              <div className={styles.row}>
                <div className={styles.rowTitle}>Currency:</div>
                <div className={styles.centerRow}>
                  {countryObj.currency.name}&nbsp;&nbsp;
                  <ReactCountryFlag
                    title={countryObj.currency.name}
                    countryCode={countryObj.currency.iso}
                    svg
                    style={{
                      fontSize: "1.5em",
                      lineHeight: "1.5em",
                    }}
                  />
                </div>
              </div>
            )}
          </div>

          <div className={styles.sectionTitle}>
            <div className={styles.centerRow}>
              <EcoIcon style={{ color: "white" }} />
              &nbsp;&nbsp;Pricing
            </div>
          </div>

          {this.props.pricingPlan && this.props.pricingPlan.fee && (
            <div className={styles.sectionContainer}>
              <div className={styles.row}>
                <div className={styles.rowTitle}>Service Fee:</div>
                <div>{this.props.pricingPlan.fee()}% / registration</div>
              </div>

              <div className={styles.row}>
                <div className={styles.rowTitle}>Flat Fee (free courses):</div>
                <div>
                  ${this.props.pricingPlan.waveFee ? 0 : 20} / registration
                </div>
              </div>

              <div className={styles.row}>
                <div className={styles.rowTitle}>Advertising Credits</div>
                <div>${this.props.pricingPlan.credits} </div>
              </div>

              {this.props.pricingPlan.expirationDate && (
                <div className={styles.row}>
                  <div className={styles.rowTitle}>Promotion Expiry:</div>
                  <div>{this.props.pricingPlan.expiresIn()}</div>
                </div>
              )}
            </div>
          )}
        </SimpleContainer>

        <div style={{ height: "80px" }}>&nbsp;</div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    accountEmail: state.auth.email,
    profile: state.profile.profile,
    pricingPlan: state.profile.pricingPlan,
    setting: state.setting.setting,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchSetting: () => dispatch(settingActions.fetchSetting()),
    fetchProfile: () => dispatch(profileActions.fetchProfile()),
    fetchPricingPlan: () => dispatch(profileActions.fetchPricingPlan()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AccountInfoScreen);
