import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import { useTranslation } from "react-i18next";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import ReportProblemOutlinedIcon from "@material-ui/icons/ReportProblemOutlined";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";

const ConfirmModal = (props) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("xs"));

  let icon = <HelpOutlineIcon />;

  const handleClose = (answer) => {
    props.onClose(answer);
  };

  let headerStyle = {};

  if (props.header) {
    headerStyle = {
      color: "white",
      background: "#354769",
    };
  }

  if (props.isWarnModal) {
    headerStyle = { backgroundColor: "orange", color: "white" };
    icon = <ReportProblemOutlinedIcon />;
  }

  if (props.isInfoModal) {
    headerStyle = { backgroundColor: "#1e92f4", color: "white" };
  }

  return (
    <div>
      <Dialog
        fullScreen={false}
        open={props.open}
        onClose={handleClose.bind(this, "cancel")}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title" style={headerStyle}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              width: "100%",
              fontSize: fullScreen ? "small" : null,
            }}
          >
            {props.icon ? (
              props.icon
            ) : (
              <HelpOutlineIcon fontSize={fullScreen ? "small" : "medium"} />
            )}
            &nbsp;
            <div style={{ paddingLeft: "2px" }}>{props.title}</div>
          </div>
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            component="span"
            style={fullScreen ? { fontSize: "small" } : { fontSize: "medium" }}
          >
            {props.message}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose.bind(this, "cancel")} color="primary">
            {props.cancelButtonText ? props.cancelButtonText : t("g_cancel")}
          </Button>

          <Button
            onClick={handleClose.bind(this, "ok")}
            color="primary"
            autoFocus
          >
            {props.okButtonText ? props.okButtonText : t("g_ok")}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ConfirmModal;
