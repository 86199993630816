// registration details
import { resolveCurrency, translate } from "../globals/Common";

class CartPricingItem {
  constructor(costAfterDiscount, pricingItem, staffMembers) {
    this.costAfterDiscount = costAfterDiscount;
    this.pricingItem = pricingItem;
    this.staffMembers = staffMembers;
  }

  hasDiscount = () => {
    return this.originalPrice() > this.cost();
  };

  originalPrice = () => {
    return Number(this.pricingItem.cost);
  };

  subTotal = () => {
    return Number(this.cost() * this.staffMembers.length);
  };

  viewSubTotal = (providerCurrency, rates) => {
    return Number(
      resolveCurrency(this.cost(), providerCurrency, rates) *
        this.staffMembers.length
    );
  };

  viewCost = (providerCurrency, rates) => {
    return Number(resolveCurrency(this.cost(), providerCurrency, rates));
  };

  cost = () => {
    return Number(this.costAfterDiscount);
  };

  resolvePricingItem = (t) => {
    const audience = [];
    for (const aud of this.pricingItem.audience) {
      audience.push(translate(t, aud));
    }

    return (
      "(" +
      translate(t, this.pricingItem.casting) +
      ` ${t("pricing_option_for")} ` +
      audience.join(", ") +
      " / " +
      translate(t, this.pricingItem.component) +
      ")"
    );
  };
}

export default CartPricingItem;
