import React from "react";
import styles from "./CopyLinkContainer.module.css";
import FileCopyOutlinedIcon from "@material-ui/icons/FileCopyOutlined";
import Tooltip from "@material-ui/core/Tooltip";
import { CopyToClipboard } from "react-copy-to-clipboard";
import LaunchIcon from "@material-ui/icons/Launch";
import IconButton from "@material-ui/core/IconButton";

const copyText = "Copy to clipboard";
const copiedText = "Copied to clipboard";

const CopyLinkContainer = (props) => {
  return (
    <div
      className={styles.columnContainer}
      style={!props.onCopy ? { paddingBottom: "0px" } : null}
    >
      <div
        className={styles.title}
        onClick={props.onLaunch ? props.onLaunch : () => {}}
        style={props.onLaunch ? { cursor: "pointer" } : null}
      >
        {props.onLaunch && (
          <IconButton onClick={props.onLaunch}>
            <LaunchIcon fontSize={"small"} style={{ color: "black" }} />
          </IconButton>
        )}
        {props.title}
      </div>

      {props.onCopy && (
        <Tooltip
          title={copyText}
          aria-label={copyText}
          className={styles.linkContainer}
        >
          <CopyToClipboard
            text={props.link}
            onCopy={() => {
              props.onCopy(copiedText);
            }}
          >
            <div className={styles.linkContainer}>
              <div className={styles.url}>{props.link}</div>
              <div className={styles.linkButtonContainer}>
                <FileCopyOutlinedIcon />
              </div>
            </div>
          </CopyToClipboard>
        </Tooltip>
      )}
    </div>
  );
};

export default CopyLinkContainer;
