import * as lodashCloneDeep from "lodash.clonedeep";

import {
  SET_ACTIVE_TAB,
  INIT_COURSE_ID,
  ADD_COURSE,
  UPDATE_COURSE,
  REMOVE_DRAFT,
  SET_COURSES,
  ACTIVATE_COURSE,
  UPDATE_CAPACITY,
  UPDATE_SEL_COURSE_REFUND_VALUE,
  SET_REGISTRATION_CONTENT,
  SET_SELECTED_CERTIFICATE,
  SET_MOBILE_SEARCH_COURSES,
  SELECT_MOBILE_COURSE,
  SET_FEATURED,
  SET_NEWLY,
  SET_BANNER,
  SET_TRENDING,
  SET_RECOMMEND,
  UPDATE_COURSE_CERTIFICATE,
  SET_CATEOGRY_INDEX,
  SET_COURSE_IMAGE,
  CLEAR_SURVEY_RESULT_COUNT,
  SET_PROVIDER_COURSES_META,
  SET_ACTIVE_COURSE,
  SET_ACTIVE_COURSES,
  SET_DRAFT_COURSES,
  SET_HISTORY_COURSES,
  SET_SELECTED_COURSES,
  SET_COURSE_VIDEO_CHAPTERS
} from "../actions/courses";

import { LOGOUT } from "../actions/auth";
import Course from "../../model/Course";
import Certificate from "../../model/Certificate";
import PricingItem from "../../model/PricingItem";
import MultiPayOption from "../../model/MultiPayOption";
import Amendment from "../../model/Amendment";
import { updateObject } from "../../globals/Common";

const initialState = {
  courseId: "",

  activeTab: 0,
  selectedCourses: [],

  courses: [],
  drafts: [],
  courses_history: [],

  capacity: [],
  revenu: [],
  survey_result_count: [],

  activeTabSearchText: "",
  searchTextActive: "",
  searchTextPending: "",
  searchTextHistory: "",

  cancelCourseRefundAmount: 0,
  cancelCourseAffectedOrders: [],
  email_instruction: "",
  selected_certificate: null,
  selected_survey: null,
  selected_handout: null,
  selected_handout_delivery: "",
  selected_video_chapters: null,

  // -----------
  search_courses: [],
  select_course: null,
  hasMore: false,
  recommend: [],
  trending: [],
  newly: [],
  banner: [],
  featured: [],
  // -----------
  categoryIndex: null,
  providerCoursesMeta: null,
};

const coursesReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_COURSE_VIDEO_CHAPTERS: {
      const isActive = action.isActiveCourse;

      const cIndex = isActive
        ? state.courses.findIndex((c) => c.id === action.courseId)
        : state.drafts.findIndex((c) => c.id === action.courseId);

      const copyCourses = isActive ? [...state.courses] : [...state.drafts];

      if (cIndex > -1) {
        const copyCourse = lodashCloneDeep(copyCourses[cIndex]);
        copyCourse.video_chapters = action.video_chapters;
        copyCourses[cIndex] = copyCourse;
      }

      console.log(action.video_chapters);
      console.log(isActive);

      return updateObject(
        state,
        isActive ? { courses: copyCourses, selected_video_chapters: [...action.video_chapters] } : { drafts: copyCourses }
      );
    }
    case SET_PROVIDER_COURSES_META:
      return updateObject(state, { providerCoursesMeta: action.providerCoursesMeta });
    case SET_CATEOGRY_INDEX:
      return updateObject(state, { categoryIndex: action.categoryIndex });
    case SET_COURSE_IMAGE: {
      const isActive = action.course.activation_status === "active";

      const cIndex = isActive
        ? state.courses.findIndex((c) => c.id === action.course.id)
        : state.drafts.findIndex((c) => c.id === action.course.id);

      const copyCourses = isActive ? [...state.courses] : [...state.drafts];

      if (cIndex > -1) {
        const copyCourse = lodashCloneDeep(copyCourses[cIndex]);
        copyCourse.image = action.image;
        copyCourses[cIndex] = copyCourse;
      }

      return updateObject(
        state,
        isActive ? { courses: copyCourses } : { drafts: copyCourses }
      );
    }
    case UPDATE_COURSE_CERTIFICATE: {
      const cIndex = state.courses.findIndex(
        (c) => c.id === action.certificate.courseId
      );

      const copyCourses = [...state.courses];

      if (cIndex > -1) {
        const copyCourse = lodashCloneDeep(copyCourses[cIndex]);
        const cert = action.certificate.cert;
        copyCourse.certificate =
          cert && cert.id ? new Certificate(cert.id, cert.title) : null;
        copyCourses[cIndex] = copyCourse;
      }

      return updateObject(state, { courses: copyCourses });
    }
    case UPDATE_SEL_COURSE_REFUND_VALUE:
      return updateObject(state, {
        cancelCourseRefundAmount: action.cancelCourseRefundAmount,
        cancelCourseAffectedOrders: action.cancelCourseAffectedOrders,
      });
    case SET_ACTIVE_TAB:
      return updateObject(state, {
        activeTab: action.activeTab,
      });
    case SET_SELECTED_COURSES:
      return updateObject(state, {
        selectedCourses: action.courses,
      });
    case SET_ACTIVE_COURSE:
      return updateObject(state, {
        selectedCourses: action.courses.courses,
        courses: action.courses.courses,
      });
    case SET_ACTIVE_COURSES:
      return updateObject(state, {
        selectedCourses: action.courses.courses,
        courses: action.courses.courses,
        capacity: action.courses.capacity ? action.courses.capacity : [],
        revenu: action.courses.revenu ? action.courses.revenu : [],
        survey_result_count: action.courses.survey_result_count ? action.courses.survey_result_count : []
      });
    case SET_DRAFT_COURSES:
      return updateObject(state, {
        selectedCourses: action.courses.courses,
        drafts: action.courses.courses
      });
    case SET_HISTORY_COURSES:
      return updateObject(state, {
        selectedCourses: action.courses.courses,
        courses_history: action.courses.courses,
        capacity: action.courses.capacity ? action.courses.capacity : []
      });
    case SET_COURSES:
      let selectedCourses = null;
      let activeTabSearchText = "";
      const activeTab = state.activeTab;
      if (activeTab === 0) {
        activeTabSearchText = state.searchTextActive;
        selectedCourses = action.courses.courses;
      } else if (activeTab === 1) {
        activeTabSearchText = state.searchTextPending;
        selectedCourses = action.courses.drafts;
      } else if (activeTab === 2) {
        activeTabSearchText = state.searchTextHistory;
        selectedCourses = action.courses.courses_history;
      } else {
        selectedCourses = action.courses.courses;
      }
      return updateObject(state, {
        activeTabSearchText: activeTabSearchText,
        selectedCourses: selectedCourses,
        courses: action.courses.courses,
        drafts: action.courses.drafts,
        capacity: action.courses.capacity,
        courses_history: action.courses.courses_history,
        revenu: action.courses.revenu,
        survey_result_count: action.courses.survey_result_count
      });
    case CLEAR_SURVEY_RESULT_COUNT:
      const updated_survey_result_count = [...state.survey_result_count];
      delete updated_survey_result_count[action.courseId];
      return updateObject(state, {
        survey_result_count: updated_survey_result_count,
      });
    case INIT_COURSE_ID:
      return updateObject(state, { courseId: action.courseId });
    case ADD_COURSE:
      const newCourse = new Course(
        action.course.id,
        null,
        action.course.course.reference_number,
        action.course.course.provider_id,
        action.course.course.provider_name,
        action.course.course.is_specialty,
        action.course.course.category,
        action.course.course.accreditation,
        action.course.course.title,
        action.course.course.description,
        action.course.course.start_date_desc,
        action.course.course.start_date,
        action.course.course.end_date,
        action.course.course.objectives,
        action.course.course.professor,
        action.course.course.location,
        action.course.course.address,
        action.course.course.notes,
        action.course.course.is_university_associated,
        action.course.course.language,
        action.course.course.seats_classroom,
        action.course.course.seats_online,
        action.course.course.suite,
        action.course.course.email,
        action.course.course.geo_lat,
        action.course.course.geo_lng,
        action.course.course.geo_hash,
        action.course.course.cancel_policy,
        action.course.course.pricingItems
          ? action.course.course.pricingItems
          : [],
        null,
        action.course.course.timezoneAbbr,
        action.course.course.seats_handson,
        action.course.course.is_video,
        action.course.course.email_instruction,
        action.course.course.survey,
        action.course.course.youtube_uri,
        action.course.course.activation_status,
        action.course.course.multi_pay_option,
        action.course.course.handout,
        action.course.course.handout_delivery,
        action.course.course.meeting_id,
        0,
        0,
        action.course.course.provider_currency,
        action.course.course.certificate,
        action.course.course.image,
        action.course.course.topic_number,
        action.course.course.video_chapters
      );
      return updateObject(state, { drafts: state.drafts.concat(newCourse) });
    case UPDATE_COURSE:
      const cIndex = state.drafts.findIndex((c) => c.id === action.course.id);

      const newPricingItems = action.course.pricingItems.map((item) => {
        return new PricingItem(
          item.casting,
          [...item.audience],
          item.component,
          item.description,
          item.ce_odq,
          item.credit_lecture_hours,
          item.credit_handson_hours,
          item.cost,
          item.expiration_date,
          item.members_only
        );
      });

      const updatedCourse = new Course(
        action.course.id,
        null,
        action.course.reference_number,
        action.course.provider_id,
        action.course.provider_name,
        action.course.is_specialty,
        [...action.course.category],
        [...action.course.accreditation],
        action.course.title,
        action.course.description,
        action.course.start_date_desc,
        action.course.start_date,
        action.course.end_date,
        action.course.objectives,
        action.course.professor, // issue copy
        action.course.location,
        [...action.course.address],
        action.course.notes,
        action.course.is_university_associated,
        action.course.language,
        action.course.seats_classroom,
        action.course.seats_online,
        action.course.suite,
        action.course.email,
        action.course.geo_lat,
        action.course.geo_lng,
        action.course.geo_hash,
        action.course.cancel_policy,
        newPricingItems,
        null,
        action.course.timezoneAbbr,
        action.course.seats_handson,
        action.course.is_video,
        action.course.email_instruction,
        action.course.survey,
        action.course.youtube_uri,
        action.course.activation_status,
        action.course.multi_pay_option,
        action.course.handout,
        action.course.handout_delivery,
        action.course.meeting_id,
        0,
        0,
        action.course.provider_currency,
        action.course.certificate,
        action.course.image,
        action.course.topic_number,
        action.course.video_chapters
      );

      const updatedCourses = [...state.drafts];
      updatedCourses[cIndex] = updatedCourse;
      return updateObject(state, { drafts: updatedCourses });
    case UPDATE_CAPACITY:
      const cIndexUpdateCap = state.courses.findIndex(
        (c) => c.id === action.course.id
      );

      const newAmendmentsCap = action.course.amendment.map((item) => {
        return new Amendment(
          item.professor,
          item.notes,
          item.start_date,
          item.discount,
          item.amended_date
        );
      });

      const newPricingItemsCap = action.course.pricingItems.map((item) => {
        return new PricingItem(
          item.casting,
          [...item.audience],
          item.component,
          item.description,
          item.ce_odq,
          item.credit_lecture_hours,
          item.credit_handson_hours,
          item.cost,
          item.expiration_date,
          item.members_only
        );
      });

      const newMultiPayOption = new MultiPayOption(
        action.course.multi_pay_option.enabled,
        action.course.multi_pay_option.firstPayPercent,
        action.course.multi_pay_option.installmentCount
      );

      let onlineSeats = action.course.seats_online;
      if (onlineSeats === -2 && action.seats.online) {
        onlineSeats = action.seats.online;
      } else {
        onlineSeats += action.seats.online;
      }

      const updatedCourseCap = new Course(
        action.course.id,
        null,
        action.course.reference_number,
        action.course.provider_id,
        action.course.provider_name,
        action.course.is_specialty,
        [...action.course.category],
        [...action.course.accreditation],
        action.course.title,
        action.course.description,
        action.course.start_date_desc,
        action.course.start_date,
        action.course.end_date,
        action.course.objectives,
        action.course.professor, // issue copy
        action.course.location,
        [...action.course.address],
        action.course.notes,
        action.course.is_university_associated,
        action.course.language,
        action.course.seats_classroom + action.seats.classroom,
        onlineSeats,
        action.course.suite,
        action.course.email,
        action.course.geo_lat,
        action.course.geo_lng,
        action.course.geo_hash,
        action.course.cancel_policy,
        newPricingItemsCap,
        newAmendmentsCap,
        action.course.timezoneAbbr,
        action.course.seats_handson + action.seats.handson,
        action.course.is_video,
        action.course.email_instruction,
        action.course.survey,
        action.course.youtube_uri,
        action.course.activation_status,
        newMultiPayOption,
        action.course.handout,
        action.course.handout_delivery,
        action.course.meeting_id,
        0,
        0,
        action.course.provider_currency,
        action.course.certificate,
        action.course.image,
        action.course.topic_number,
        action.course.video_chapters
      );

      const updatedCoursesCap = [...state.courses];
      updatedCoursesCap[cIndexUpdateCap] = updatedCourseCap;
      return updateObject(state, { courses: updatedCoursesCap });
    case REMOVE_DRAFT:
      const copyStateDrafts = [...state.drafts];
      const updatedDelDraft = copyStateDrafts.filter((draft) => {
        return draft.id !== action.draft.id;
      });

      return {
        ...state,
        drafts: updatedDelDraft,
      };
    case SET_REGISTRATION_CONTENT:
      return updateObject(state, {
        email_instruction: action.email_instruction,
        selected_survey: action.selected_survey,
        selected_handout: action.selected_handout,
        selected_handout_delivery: action.selected_handout_delivery,
      });
    case SET_SELECTED_CERTIFICATE:
      return updateObject(state, {
        selected_certificate: action.selected_certificate,
      });
    case ACTIVATE_COURSE:
      return state;
    case SET_MOBILE_SEARCH_COURSES:
      return updateObject(state, {
        search_courses: action.search_courses,
        hasMore: action.hasMore,
      });
    case SELECT_MOBILE_COURSE:
      return updateObject(state, {
        select_course: action.course,
      });
    case SET_TRENDING:
      return updateObject(state, { trending: action.courses.trending });
    case SET_RECOMMEND:
      return updateObject(state, { recommend: action.courses.recommend });
    case SET_FEATURED:
      return updateObject(state, {
        featured: action.courses.featured,
        //hasMore: action.hasMore,
      });
    case SET_NEWLY:
      return updateObject(state, {
        newly: action.courses.newly,
        //hasMore: action.hasMore,
      });
    case LOGOUT:
      return initialState;
    default:
      return state;
  }
};

export default coursesReducer;
