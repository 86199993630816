class Chart {
  constructor(id, title, description, disabled) {
    this.id = id;
    this.title = title;
    this.description = description;
    this.disabled = disabled ? disabled : false;
  }
}

export default Chart;
