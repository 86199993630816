import React, { useState } from "react";
import * as styles from "./Filter.module.css";
import MatCheckbox from "../checkbox/MatCheckbox";
import Tooltip from "@material-ui/core/Tooltip";
import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";

const Filter = (props) => {
  const [show, setShow] = useState(props.show ? props.show : false);

  const selectFilter = (id) => {
    const index = props.selected.indexOf(id);
    const copy = [...props.selected];

    if (index > -1) {
      copy.splice(index, 1);
    } else {
      if (props.singleSelection) {
        copy.splice(0, copy.length);
      }
      copy.push(id);
    }

    props.onSelected(copy);
    props.onFilter(copy);
  };

  return (
    <div className={styles.container}>
      <div className={styles.row}>
        <div
          className={styles.title}
          onClick={() => {
            setShow(!show);
          }}
        >
          {props.title}
        </div>

        <div>
          <Tooltip title="Show / Hide" aria-label="Show / Hide">
            <IconButton
              color="primary"
              onClick={() => {
                setShow(!show);
              }}
            >
              {show ? (
                <KeyboardArrowUpIcon fontSize="inherit" />
              ) : (
                <KeyboardArrowDownIcon fontSize="inherit" />
              )}
            </IconButton>
          </Tooltip>
        </div>
      </div>

      <Collapse in={show}>
        {props.list.map((c, index) => (
          <MatCheckbox
            key={index}
            plainright="true"
            name=""
            checked={props.selected.indexOf(c.value) !== -1}
            label={c.label}
            onChange={selectFilter.bind(this, c.value)}
          />
        ))}
      </Collapse>
    </div>
  );
};

export default Filter;
