import React from "react";
import styles from "./Balance.module.css";
import Pop from "../../ui/popover/Pop";
import { CircularProgress } from "@material-ui/core";
import MonetizationOnOutlinedIcon from "@material-ui/icons/MonetizationOnOutlined";
import PopMenu from "../../../components/ui/popMenu/PopMenu";
import MenuItem from "@material-ui/core/MenuItem";
import Grow from "@material-ui/core/Grow";
import NumberFormat from "react-number-format";

const renderAmount = (amount) => {
  return amount ? amount.toFixed(2) : 0;
};

const BalanceSummary = (props) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const stylePop = {
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
  };

  // ------------------------------

  return (
    <div
      className={styles.card}
      style={{
        minWidth: "440px",
        minHeight: "137px",
      }}
    >
      <Grow in={true} style={{ transformOrigin: "0 0 0" }} timeout={1000}>
        <div className={styles.container}>
          {(props.total || props.wait) && (
            <div>
              <div className={styles.fullContainer}>
                <div>
                  <div className={styles.cardContainer}>
                    <div className={styles.row}>
                      <div className={styles.title}>
                        <Pop
                          style={stylePop}
                          help="Pending transactions, will appear in 'Available for payout' shortly."
                        />
                        <div style={{ paddingLeft: "5px", width: "160px" }}>
                          Available soon:
                        </div>

                        {props.wait ? (
                          <div className={styles.balance}>
                            <CircularProgress size={26} color="inherit" />
                          </div>
                        ) : (
                          <div className={styles.balance}>
                            $
                            <NumberFormat
                              value={renderAmount(props.pending.amount)}
                              displayType={"text"}
                              thousandSeparator={true}
                            />
                            &nbsp;
                            {props.pending.currency}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className={styles.cardContainer}>
                    <div className={styles.row}>
                      <div className={styles.title}>
                        <Pop
                          style={stylePop}
                          help={
                            "Funds paid out to your bank.  Payouts arrive in your bank account on a monthly rolling basis."
                          }
                        />
                        <div style={{ paddingLeft: "5px", width: "160px" }}>
                          Available for payout:
                        </div>

                        {props.wait ? (
                          <div className={styles.balance}>
                            {" "}
                            <CircularProgress size={26} color="inherit" />
                          </div>
                        ) : (
                          <div className={styles.balance}>
                            $
                            <NumberFormat
                              value={renderAmount(props.available.amount)}
                              displayType={"text"}
                              thousandSeparator={true}
                            />
                            &nbsp;
                            {props.available.currency}
                          </div>
                        )}
                      </div>
                    </div>

                    <div className={styles.cardContainer}>
                      <div className={styles.row}>
                        <div style={{ paddingRight: "20px" }}>
                          <div className={styles.title}>
                            <Pop
                              style={stylePop}
                              help="Total balance (Available soon + Available for payout)"
                            />
                            <div style={{ paddingLeft: "5px", width: "160px" }}>
                              Total:&nbsp;
                            </div>

                            {props.wait ? (
                              <div
                                className={styles.balance}
                                style={{
                                  borderTop: "1px solid rgb(21, 121, 148)",
                                }}
                              >
                                &nbsp;
                              </div>
                            ) : (
                              <div
                                className={styles.balance}
                                style={{
                                  borderTop: "1px solid rgb(21, 121, 148)",
                                }}
                              >
                                $
                                <NumberFormat
                                  value={renderAmount(props.total.amount)}
                                  displayType={"text"}
                                  thousandSeparator={true}
                                />
                                &nbsp;
                                {props.total.currency}{" "}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <PopMenu
                  disabled={props.wait}
                  anchorEl={anchorEl}
                  handleClick={handleClick}
                  handleClose={handleClose}
                >
                  <MenuItem
                    onClick={() => {
                      handleClose();
                      props.onAddFunds();
                    }}
                  >
                    <div className={styles.simpleRow}>
                      <MonetizationOnOutlinedIcon />
                      &nbsp;Add Funds
                    </div>
                  </MenuItem>
                </PopMenu>
              </div>
            </div>
          )}
        </div>
      </Grow>
    </div>
  );
};

export default BalanceSummary;
