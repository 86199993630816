class CartItem {
  constructor(
    course_id,
    course_title,
    start_date,
    num_of_weeks,
    prime,
    prime_cost,
    cost,
    addCategory, // add, featured, recommended, newly
    slide,
    headline,
    callForAction,
    showLogo,
    logoUri,
    locations,
    exclude_locations,
    experience,
    occupation
  ) {
    this.course_id = course_id;
    this.course_title = course_title;
    this.start_date = start_date;
    this.num_of_weeks = num_of_weeks;
    this.prime = prime;
    this.prime_cost = prime_cost;
    this.cost = cost;
    this.addCategory = addCategory;
    this.slide = slide;
    this.headline = headline;
    this.callForAction = callForAction;
    this.showLogo = showLogo;
    this.logoUri = logoUri;
    this.locations = locations;
    this.exclude_locations = exclude_locations;
    this.experience = experience;
    this.occupation = occupation;
  }

  resolveCategory() {
    if (this.addCategory === "banner") {
      return "Fixed Ad";
    } else {
      return this.addCategory;
    }
  }
}

export default CartItem;
