import React, { Fragment } from "react";
import styles from "./MobilePricingItem.module.css";
import { useTranslation } from "react-i18next";
import { translate } from "../../../globals/Common";
import { resolveCurrency } from "../../../globals/Common";
import NumberFormat from "react-number-format";
import CreditCardIcon from "@material-ui/icons/CreditCard";
import MatCheckbox from "../../ui/checkbox/MatCheckbox";
import CardMembershipIcon from '@material-ui/icons/CardMembership';
import i18n from "../../../i18n";
import DoneIcon from '@material-ui/icons/Done';

// ------------------------------

const getDiscounts = (index, amendment) => {
  const discounts = [];
  for (const a of amendment) {
    if (a.discount && a.discount[index]) {
      discounts.push({
        discount: a.discount[index],
        amended_date: a.amended_date,
      });
    }
  }

  return discounts.sort((a, b) =>
    a.amended_date.getTime() < b.amended_date.getTime() ? -1 : 1
  );
};

// ------------------------------

const renderPrice = (price, discount, currency, providerCurrency, rates) => {
  let lastCalcPrice = parseFloat(price);

  return (
    <Fragment>
      <div className={discount.length > 0 ? styles.priceStrike : styles.price}>
        {i18n.t("g_price")}:{" "}
        <NumberFormat
          value={resolveCurrency(Number(price), providerCurrency, rates)}
          displayType={"text"}
          thousandSeparator={true}
          prefix={currency.shortName}
        />
      </div>

      {discount.map((discount, i) => {
        if (discount[index]) {
          discountPercent = discountPercent + discountPercent[index];

          lastCalcPrice =
            Number(lastCalcPrice) -
            (Number(lastCalcPrice) * discount[index]) / 100;

          let renderPrice = (
            <div
              key={i}
              className={
                discount.length - 1 === index
                  ? styles.price
                  : styles.priceStrike
              }
            >
              {i18n.t("g_price")}:{" "}
              <NumberFormat
                value={resolveCurrency(lastCalcPrice, providerCurrency, rates)}
                displayType={"text"}
                thousandSeparator={true}
                prefix={currency.shortName}
              />{" "}
              ({-discount[index]}%)
            </div>
          );

          return renderPrice;
        } else {
          return null;
        }
      })}
    </Fragment>
  );
};

// ------------------------------

const renderInstallmentOption = (multiPayOptions, finalPrice) => {
  if (multiPayOptions.enabled && finalPrice >= 2000) {
    return (
      <div className={styles.installmentIndicator}>
        <CreditCardIcon fontSize="inherit" style={{ marginRight: "3px" }} />{" "}
        {i18n.t("pricing_option_pay")} {multiPayOptions.firstPayPercent}%{" "}
        {i18n.t("pricing_option_today")} + {multiPayOptions.installmentCount}{" "}
        {i18n.t("pricing_option_monthly")}
      </div>
    );
  }

  return null;
};

// ------------------------------

const MobilePricingItem = (props) => {
  const { t } = useTranslation();
  const item = props.pricingItem;

  const audience = [];
  for (const aud of item.audience) {
    audience.push(translate(t, aud));
  }

  const index = props.row;
  const rates = props.rates;
  const currency = props.currency;
  const providerCurrency = props.providerCurrency;

  const multiPayOption = props.multiPayOption;

  let discountArrByDate = [];

  if (props.amendment) {
    discountArrByDate = getDiscounts(index, props.amendment);
  }

  let lastCalcPrice = item.cost;

  return (
    <div className={styles.row}>
      <div className={styles.registrationOptionGroup}>
        <div className={styles.registrationOptionTitle}>
          Option {index + 1}
          {item.members_only &&
            <span style={{
              flexDirection: "row",
              alignItems: "center",
              display: "flex",
              color: "green",
              paddingBottom: "0px",
            }}>&nbsp;- {i18n.t("membership_member")} &nbsp;<CardMembershipIcon style={{ color: "green" }} /></span>}
        </div>

        {item.description && <div style={{ fontWeight: "normal", fontSize: "small", wordBreak: "break-word" }}> ({item.description})</div>}

        <div style={{ paddingTop: "5px" }}>
          <DoneIcon fontSize="inherit" />
          {translate(t, item.casting)} {t("pricing_option_for")}{" "}
          {audience.join(", ")}
        </div>
        <div><DoneIcon fontSize="inherit" />{translate(t, item.component)}</div>
        <div className={styles.credits}><DoneIcon fontSize="inherit" />{item.resolveCreditsDisplay(t)}</div>
        {renderPrice(
          props.pricingItem.cost,
          discountArrByDate,
          currency,
          providerCurrency,
          rates
        )}
        {renderInstallmentOption(multiPayOption, lastCalcPrice)}
        {item.expirationDate() && <div>Exp: {item.expirationDate()}</div>}
      </div>
      <div className={styles.checkboxOptionContainer}>
        {props.pricingItem.members_only && props.optionMember &&
          props.optionMember.map((item, i) => (
            <MatCheckbox
              labelplacement="start"
              nostyle="true"
              label={item.text}
              key={i}
              value={item.id}
              checked={
                props.selectedParticipants.findIndex(
                  (it) =>
                    item.id === it.id && item.pricingItem === it.pricingItem
                ) >= 0
              }
              onChange={() => {
                props.onClick(item.id);
              }}
            />
          ))
        }

        {!props.pricingItem.members_only &&
          props.options &&
          props.options.map((item, i) => (
            <MatCheckbox
              labelplacement="start"
              nostyle="true"
              label={item.text}
              key={i}
              value={item.id}
              checked={
                props.selectedParticipants.findIndex(
                  (it) =>
                    item.id === it.id && item.pricingItem === it.pricingItem
                ) >= 0
              }
              onChange={() => {
                props.onClick(item.id);
              }}
            />
          ))
        }
      </div>
    </div>
  );
};

export default MobilePricingItem;
