import React from "react";
import { useTranslation } from "react-i18next";
import Button from "@material-ui/core/Button";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import emailLinkImg from "../../../assets/images/inApp/emaillink.gif";
import TextButton from "../../ui/button/TextButton";

const inlineImgStyle = {
  height: "140px",
  borderRadius: "15%",
  boxShadow: "0 8px 18px 0 rgba(0, 0, 0, 0.2)",
};

const EmailLinkModal = (props) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("xs"));
  const fullWidth = true;
  const maxWidth = "sm";

  const okHandler = (signupType) => {
    props.history.push("/login");
  };

  return (
    <div>
      <Dialog
        fullScreen={fullScreen}
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={props.open}
        onClose={okHandler}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="alert-dialog-title">
          {t("sign_up_thank_you")}
        </DialogTitle>

        <DialogContent>
          <div style={{ width: "100%", textAlign: "center" }}>
            <img style={inlineImgStyle} src={emailLinkImg} alt="" />

            <div style={{ fontWeight: "bold", marginTop: "25px" }}>
              {t("sign_up_email_sent_to")}
            </div>

            <div style={{ fontWeight: "bold", color: "green" }}>
              {props.email}
            </div>

            <div
              style={{
                fontWeight: "bold",
                marginTop: "25px",
                marginBottom: "25px",
              }}
            >
              {t("sign_up_go_to_inbox")}
            </div>

            <div
              style={{
                fontSize: "small",
                marginBottom: "5px",
              }}
            >
              {t("sign_up_check_spam")}{" "}
              <TextButton onClick={() => {}} style={{ fontSize: 14 }}>
                {t("sign_up_resend_email")}
              </TextButton>
            </div>

            <div
              style={{
                fontSize: "small",
              }}
            >
              {t("sign_up_email_incorrect")}{" "}
              <TextButton onClick={() => {}} style={{ fontSize: 14 }}>
                {t("sign_up_try_again")}
              </TextButton>{" "}
              {t("sign_up_different_email")}
            </div>
          </div>
        </DialogContent>

        <DialogActions>
          <Button autoFocus onClick={okHandler} color="primary">
            {t("g_close")}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default EmailLinkModal;
