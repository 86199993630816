import React, { Component } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import styles from "./CategorySearch.module.css";
import * as courseActions from "../../../store/actions/courses";
import * as providerActions from "../../../store/actions/provider";
import * as settingActions from "../../../store/actions/setting";
import * as profileActions from "../../../store/actions/profile";
import * as rateActions from "../../../store/actions/rate";
import * as logger from "../../../globals/Logger";
import Spinner from "../../../components/ui/spinner/Spinner";
import InfoModal from "../../../components/modals/infoModal/InfoModal";
import SignupTypeModal from "../../../components/modals/signupTypeModal/SignupTypeModal";
import DefaultToolbar from "../../welcome/DefaultToolbar";
import CourseItem from "../../../components/items/courseSearchItem/CourseItem";
import { sortArrayOfDates } from "../../../globals/Dates2";
import {
  resolveIcon,
  objectHasNoValues,
  resolveCurrencySymbol,
  resolveCurrency,
  deepEqual,
  setLanguage,
  translate,
} from "../../../globals/Common";
import LoadMore from "../../../components/ui/pagination/LoadMore";
import Lottie from "lottie-react";
import studentBooks from "../../../assets/lottie/studentbooks.json";
import FilterSortButtons from "./FilterSortButtons";

import CourseFilterDrawer from "./drawer/CourseFilterDrawer";
import CourseSortDrawer from "./drawer/CourseSortDrawer";

class CourseSearchListScreen extends Component {
  state = {
    wait: false,
    isLoadingMore: false,
    openSignupTypeModal: false,
    errorModal: false,
    message: null,
    defaultFilters: { page: 1 },
    filters: { page: 1 },
    filterMenuOpen: false,
    sortMenuOpen: false,

    filterSelectionCity: [],
    filterSelectionPrice: [],
    filterSelectionLanguage: [],
  };

  // ------------------------------

  update = async (propValue) => {
    await this.setState({ ...this.state, ...propValue });
  };

  // ------------------------------

  componentDidMount = async () => {
    try {
      await this.update({ wait: true });
      window.scrollTo(0, 0);
      const loadAsync = [];

      if (objectHasNoValues(this.props.mobileSetting)) {
        await this.props.fetchMobileSetting();
      }

      if (this.props.isAuthenticated && objectHasNoValues(this.props.profile)) {
        loadAsync.push(this.props.fetchUserProfile());
      }

      if (objectHasNoValues(this.props.rates)) {
        loadAsync.push(this.props.fetchRates());
      }

      if (this.props.searchOnlineOnly) {
        const filterParam = { page: 1, onlineOnly: true };

        loadAsync.push(this.props.searchCourses(filterParam));

        await this.update({
          filters: { ...filterParam },
          defaultFilters: { ...filterParam },
        });
      } else if (this.props.searchLivestream) {
        const filterParam = { page: 1, searchLivestream: true };

        loadAsync.push(this.props.searchCourses(filterParam));

        await this.update({
          filters: { ...filterParam },
          defaultFilters: { ...filterParam },
        });
      } else if (this.props.searchInPerson) {
        const filterParam = { page: 1, allcity: true };

        loadAsync.push(this.props.searchCourses(filterParam));

        await this.update({
          filters: { ...filterParam },
          defaultFilters: { ...filterParam },
        });
      } else if (this.props.searchFree) {
        const filterParam = { page: 1, searchFree: "free" };

        loadAsync.push(this.props.searchCourses(filterParam));

        await this.update({
          filters: { ...filterParam },
          defaultFilters: { ...filterParam },
        });
      } else if (this.props.categoryId) {
        const filterParam = { page: 1, category: this.props.categoryId };

        loadAsync.push(this.props.searchCourses(filterParam));

        await this.update({
          filters: { ...filterParam },
          defaultFilters: { ...filterParam },
        });
      }

      await Promise.all(loadAsync);
      await this.update({ wait: false });
    } catch (error) {
      logger.error(error);
      await this.update({
        wait: false,
        errorModal: true,
        message: error.message,
      });
    }
  };

  // ------------------------------

  incrementPage = () => {
    const nextPage = this.state.filters.page ? this.state.filters.page + 1 : 2;
    const copyFilter = { ...this.state.filters };
    copyFilter["page"] = nextPage;
    this.update({ filters: copyFilter });
    return copyFilter;
  };

  // ------------------------------

  loadMoreHandler = async () => {
    try {
      await this.update({
        isLoadingMore: true,
      });

      await this.props.searchCourses(this.incrementPage());

      await this.update({
        isLoadingMore: false,
      });
    } catch (error) {
      logger.error(error);
      await this.update({
        isLoadingMore: false,
        errorModal: true,
        message: error.message,
      });
    }
  };

  // ------------------------------

  signupHandler = () => {
    this.update({ openSignupTypeModal: true });
  };

  // ------------------------------

  courseDetailsHandler = (course) => {
    this.props.setSelectedProviderCourse(course);
    this.props.history.push(`/details/${course.provider_id}/${course.id}`);
  };

  // ------------------------------

  buildCourseCardItems = (courses) => {
    const items = [];

    for (const c of courses) {
      items.push(
        <CourseItem
          strechable
          key={c.id}
          image={c.image}
          rating={c.rating}
          rating_count={c.rating_count}
          isVideo={c.is_video}
          title={c.title}
          description={c.description}
          credit={c.resolve_ce()}
          date={c.short_start_date()}
          avatar={c.avatar()}
          price={c.resolve_display_cost(
            resolveCurrencySymbol(this.props.mobileSetting.currency),
            resolveCurrency(
              c.lowest_cost(),
              c.provider_currency,
              this.props.rates
            ),
            resolveCurrency(
              c.highest_cost(),
              c.provider_currency,
              this.props.rates
            )
          )}
          onSelectCourse={this.courseDetailsHandler.bind(this, c)}
        />
      );
    }

    return items;
  };

  // ------------------------------

  filterDrawerClosed = async (
    filters,
    selectedLang,
    selectedPrice,
    selectedCity
  ) => {
    try {
      await this.update({
        filterMenuOpen: false,
      });

      await this.update({
        wait: true,
      });

      if (!deepEqual(filters, this.state.filters)) {
        await this.props.searchCourses(filters);
      }

      await this.update({
        wait: false,
        filters: filters,
        filterSelectionCity: selectedCity,
        filterSelectionPrice: selectedPrice,
        filterSelectionLanguage: selectedLang,
      });
    } catch (error) {
      logger.error(error);
      await this.update({
        wait: false,
        errorModal: true,
        message: error.message,
      });
    }
  };

  // ------------------------------

  sortDrawerClosed = async (sort) => {
    try {
      await this.update({
        wait: true,
        sortMenuOpen: false,
      });

      let courseCopy = [...this.props.search_courses];

      if (this.props.search_courses && this.props.search_courses.length > 0) {
        if (sort === "price_low") {
          courseCopy.sort((a, b) =>
            a.lowest_cost() > b.lowest_cost() ? 1 : -1
          );
        } else if (sort === "price_high") {
          courseCopy.sort((a, b) =>
            a.lowest_cost() < b.lowest_cost() ? 1 : -1
          );
        } else if (sort === "date_asc") {
          courseCopy = sortArrayOfDates(courseCopy, true);
        } else if (sort === "date_des") {
          courseCopy = sortArrayOfDates(courseCopy, false);
        }
      }

      this.props.setMobileCourses(courseCopy);

      await this.update({
        wait: false,
      });
    } catch (error) {
      logger.error(error);
      await this.update({
        wait: false,
        errorModal: true,
        message: error.message,
      });
    }
  };

  // ------------------------------

  filterCount = () => {
    const count =
      this.state.filterSelectionCity.length +
      this.state.filterSelectionPrice.length +
      this.state.filterSelectionLanguage.length;

    return count;
  };

  // ------------------------------

  updateMobileCurrency = async (currency, language) => {
    try {
      await this.props.updateCurrency(currency, language);
      setLanguage(language, this.props.i18n);
    } catch (error) {
      logger.error(error);
    }
  };

  // ------------------------------

  render() {
    if (this.state.wait) {
      return <Spinner message={this.state.waitMessage} />;
    }

    return (
      <div className={styles.screen}>
        <CourseFilterDrawer
          filterCount={this.filterCount()}
          filterCity={this.state.filterSelectionCity}
          filterPrice={this.state.filterSelectionPrice}
          filterLanguage={this.state.filterSelectionLanguage}
          filterExclude={this.props.filterExclude}
          defaultFilters={this.state.defaultFilters}
          filters={this.state.filters}
          provider={this.props.provider}
          open={this.state.filterMenuOpen}
          close={this.filterDrawerClosed}
        />

        <CourseSortDrawer
          open={this.state.sortMenuOpen}
          close={this.sortDrawerClosed}
        />

        <InfoModal
          isErrorModal={this.state.errorModal}
          title={this.props.t("error_occurred")}
          message={this.state.message}
          open={this.state.message !== null}
          onClose={() => {
            this.update({
              message: null,
            });
          }}
        />

        {this.state.openSignupTypeModal && (
          <SignupTypeModal
            open={this.state.openSignupTypeModal}
            onClose={() => {
              this.setState({ ...this.state, openSignupTypeModal: false });
            }}
            history={this.props.history}
          />
        )}

        {this.props.isAuthenticated ? null : (
          <DefaultToolbar
            history={this.props.history}
            i18n={this.props.i18n}
            mobileSetting={this.props.mobileSetting}
            updateMobileCurrency={this.updateMobileCurrency}
            showSearch
          />
        )}

        <div
          className={styles.headerContainer}
          style={this.props.isAuthenticated ? { marginTop: "0px" } : null}
        >
          <FilterSortButtons
            filterCount={this.filterCount() + ""}
            onFilterClick={() => {
              this.update({ filterMenuOpen: true });
            }}
            onSortClick={() => {
              this.update({ sortMenuOpen: true });
            }}
          />
          <div className={styles.headerTitleBlurb}>
            {this.props.icon && this.props.icon.startsWith("svg") ? (
              <img src={resolveIcon(this.props.icon)} />
            ) : (
              resolveIcon(this.props.icon)
            )}
            &nbsp;&nbsp;
            {translate(this.props.t, this.props.title, "cat_short_")}
          </div>
        </div>

        {this.props.search_courses && this.props.search_courses.length > 0 ? (
          <div className={styles.courseSearchResultList}>
            {this.buildCourseCardItems(this.props.search_courses)}
            <LoadMore
              hasMore={this.props.hasMore}
              loading={this.state.isLoadingMore}
              onLoadMore={this.loadMoreHandler}
            />
          </div>
        ) : (
          <div className={styles.lottieImageContainer}>
            <Lottie
              animationData={studentBooks}
              loop={false}
              className={styles.lottieImage}
            />
            <div className={styles.lottiDisclaimer}>
              {this.props.t("course_search_not_found2")}
            </div>
          </div>
        )}
      </div>
    );
  }
}

// ------------------------------

const mapStateToProps = (state, props) => {
  const locationState = (value) => {
    if (props.location && props.location.state && props.location.state[value]) {
      return props.location.state[value];
    }

    return "";
  };

  const searchOnlineOnly = locationState("searchOnlineOnly");
  const searchLivestream = locationState("searchLivestream");
  const searchFree = locationState("searchFree");
  const searchInPerson = locationState("searchInPerson");
  const categoryId = locationState("categoryId");
  const title = locationState("title");
  const icon = locationState("icon");
  const filterExclude = locationState("filterExclude");

  return {
    title: title,
    searchOnlineOnly: searchOnlineOnly,
    searchLivestream: searchLivestream,
    searchFree: searchFree,
    searchInPerson: searchInPerson,
    categoryId: categoryId,
    icon: icon,
    filterExclude: filterExclude,

    search_courses: state.courses.search_courses,
    hasMore: state.courses.hasMore,
    profile: state.profile.userProfile,
    providers: state.provider.provider,
    mobileSetting: state.setting.mobileSetting,
    rates: state.rate.rate,
    isAuthenticated: state.auth.token != null,
  };
};

// ------------------------------

const mapDispatchToProps = (dispatch) => {
  return {
    setMobileCourses: (courses) =>
      dispatch(courseActions.setMobileCourses(courses)),
    setSelectedProviderCourse: (course) =>
      dispatch(providerActions.setSelectedProviderCourse(course)),
    searchCourses: (filters) => dispatch(courseActions.searchCourses(filters)),
    fetchUserProfile: () => dispatch(profileActions.fetchMobileUserProfile()),
    fetchMobileSetting: () => dispatch(settingActions.fetchMobileSetting()),
    fetchRates: () => dispatch(rateActions.fetchRates()),
    updateCurrency: (currency, language) =>
      dispatch(settingActions.updateCurrency(currency, language)),
  };
};

export default withTranslation("translations")(
  connect(mapStateToProps, mapDispatchToProps)(CourseSearchListScreen)
);
