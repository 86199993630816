import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import styles from "./MobileOrderScreen.module.css";
import { connect } from "react-redux";
import * as mobileOrderActions from "../../../store/actions/mobileorders";
import * as profileActions from "../../../store/actions/profile";
import * as rateActions from "../../../store/actions/rate";
import * as settingActions from "../../../store/actions/setting";
import FlatList from "flatlist-react";
import ListAltIcon from "@material-ui/icons/ListAlt";
import Spinner from "../../../components/ui/spinner/Spinner";
import SimpleContainer from "../../../components/ui/container/Container";
import MobileOrderItem from "../../../components/items/mobileOrderItem/MobileOrderItem";
import InfoModal from "../../../components/modals/infoModal/InfoModal";
import Empty from "../../../components/ui/empty/Empty";
import ordersImg from "../../../assets/images/inApp/orders.png";
import { objectHasNoValues } from "../../../globals/Common";
import * as logger from "../../../globals/Logger";

class MobileOrderScreen extends Component {
  state = {
    wait: false,
    error: null,
    success: null,
    emailWait: false,
    page: 1,
  };

  // ------------------------------

  update = async (propValue) => {
    await this.setState({ ...this.state, ...propValue });
  };

  // ------------------------------

  componentDidMount = async () => {
    try {
      await this.update({ error: null, wait: true });
      const loadAsync = [];

      if (objectHasNoValues(this.props.profile)) {
        loadAsync.push(this.props.fetchUserProfile());
      }
      if (objectHasNoValues(this.props.mobileSetting)) {
        await this.props.fetchMobileSetting();
      }
      if (objectHasNoValues(this.props.rates)) {
        loadAsync.push(this.props.fetchRates());
      }
      loadAsync.push(this.props.fetchMobileOrders(this.state.page));

      await Promise.all(loadAsync);
      await this.update({ wait: false });
    } catch (error) {
      logger.error(error);
      await this.update({
        wait: false,
        error: error.message,
      });
    }
  };

  // ------------------------------

  emailReceipt = async (orderId) => {
    try {
      this.update({ error: null });
      await this.props.emailReceipt(orderId);
      this.update({
        success: this.props.t("order_email_sent_message"),
      });
    } catch (error) {
      logger.error(error);
      await this.update({
        error: error.message,
      });
    }
  };

  // ------------------------------

  renderOrder = (item, index) => {
    return (
      <div key={index}>
        <MobileOrderItem
          order={item}
          showDetails={false}
          setting={this.props.setting}
          rates={this.props.rates}
          onEmailReceipt={this.emailReceipt.bind(this, item.id)}

          orderRefNumber={item.orderRefNumber}
          chargeAmount={item.chargeAmount / 100}
          amount={item.totalAmount / 100}
          couponValue={item.couponValue / 100}
          providerCurrency={item.items[0].providerCurrency}
          date={item.renderDate()}
          dateNoFormat={item.date}
          installmentStatusArray={item.installmentStatusArray}
        />
        <div style={{ height: "25px" }}>&nbsp;</div>
      </div>
    );
  };

  // ------------------------------

  fetchMoreOrders = async () => {
    try {
      const nextPage = this.state.page + 1;
      this.props.fetchMobileOrders(nextPage);
      await this.update({ page: nextPage });
    } catch (error) {
      logger.error(error);
      await this.update({
        wait: false,
        error: error.message,
      });
    }
  };

  // ------------------------------

  render() {
    if (this.state.wait) {
      return <Spinner />;
    }

    if (!this.props.orders || this.props.orders.length === 0) {
      return (
        <Empty
          column
          image={ordersImg}
          message={
            <div>
              <p>{this.props.t("mobile_orders_empty")}</p>
            </div>
          }
        />
      );
    }

    return (
      <div>
        {(this.state.error !== null || this.state.success !== null) && (
          <InfoModal
            isErrorModal={this.state.error}
            title={
              this.state.error ? this.props.t("error_occurred") : this.props.t("order_email_sent")
            }
            message={this.state.error ? this.state.error : this.state.success}
            open={true}
            onClose={() => {
              this.update({ error: null, success: null });
            }}
          />
        )}

        <SimpleContainer disableGutters={false}>
          <div style={{ height: "40px" }}>&nbsp;</div>
          <div className={styles.sectionTitle}>
            <div className={styles.centerRow}>
              <ListAltIcon style={{ color: "white" }} />
              &nbsp;&nbsp;{this.props.t("g_bar_purhase_history")}
            </div>
          </div>
          <div className={styles.list}>
            <FlatList
              list={this.props.orders}
              renderItem={this.renderOrder}
              hasMoreItems={this.props.hasMoreItems}
              loadMoreItems={this.fetchMoreOrders}
            />
          </div>
        </SimpleContainer>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    profile: state.profile.userProfile,
    setting: state.setting.mobileSetting,
    orders: state.mobileorders.orders,
    hasMoreItems: state.mobileorders.hasMore,
    rates: state.rate.rate,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchUserProfile: () => dispatch(profileActions.fetchMobileUserProfile()),
    fetchMobileSetting: () => dispatch(settingActions.fetchMobileSetting()),
    fetchMobileOrders: (page) => dispatch(mobileOrderActions.fetchOrders(page)),
    emailReceipt: (orderId) =>
      dispatch(mobileOrderActions.emailReceipt(orderId)),
    fetchRates: () => dispatch(rateActions.fetchRates()),
  };
};

export default withTranslation("translations")(
  connect(mapStateToProps, mapDispatchToProps)(MobileOrderScreen)
);
