// StaffMember, PricingItem, and  numeric amount, chargeId used for multi payments

class MemberRefundValue {
  constructor(member, memberPricingItem, memberRefundValue, chargeId) {
    this.member = member;
    this.memberPricingItem = memberPricingItem;
    this.memberRefundValue = memberRefundValue;
    this.chargeId = chargeId;
  }
}

export default MemberRefundValue;
