import React from "react";
import styles from "./VideoChapterItem.module.css";
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import OndemandVideoOutlinedIcon from '@material-ui/icons/OndemandVideoOutlined';

const CourseContentChapter = (props) => {
    const viewedProgress = 0;
    const title = props.videoChapter && props.videoChapter.title;
    const description = props.videoChapter && props.videoChapter.description;
    const duration = props.videoChapter ? props.videoChapter.getVideoLength() : 0;

    return (
        <div
            onClick={props.onSelectChapter}
            className={styles.courseContentChapter}
            style={props.selected ? { backgroundColor: "#bcc9d5", border: "0px" } : null}
        >
            <div className={styles.courseContentTitle}>
                <div><CheckBoxOutlineBlankIcon /></div>
                <div className={styles.courseContentTitleDesc}>
                    <div className={styles.courseContentTitle}>{title}</div>
                    <div className={styles.courseContentDescription}>{description}</div>
                </div>
            </div>

            <div className={styles.courseContentDuration}>
                <OndemandVideoOutlinedIcon fontSize="inherit" />&nbsp;{duration ? duration : "0:00"}
            </div>
        </div>
    );
};

export default CourseContentChapter;
