import React from "react";
import { useTranslation } from "react-i18next";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import FlatList from "flatlist-react";
import ProviderItem from "../../../components/items/providerItem/ProviderItem";

const ProviderListModal = (props) => {
  const { t } = useTranslation();
  const fullScreen = false;
  const fullWidth = true;
  const maxWidth = "lg";

  const handleClose = (selectedIndex) => {
    props.onSelect(props.list[selectedIndex]);
  };

  const renderItem = (provider, index) => {
    return (
      <div
        key={provider.id}
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          marginBottom: "10px",
        }}
      >
        <ProviderItem
          id={provider.id}
          name={provider.name}
          logo={provider.logoCached}
          onViewCourses={handleClose.bind(this, index)}
        />
      </div>
    );
  };

  return (
    <div>
      <Dialog
        fullScreen={fullScreen}
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={props.open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="alert-dialog-title">Providers</DialogTitle>
        <DialogContent
          style={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}
        >
          <FlatList
            list={props.list}
            renderItem={renderItem}
            displayGrid
            gridGap="3%"
            srollToTop
          />
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            onClick={handleClose.bind(this, null)}
            color="primary"
          >
            {t("g_cancel")}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ProviderListModal;
