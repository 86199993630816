import React, { useState } from "react";
import styles from "./RatingModal.module.css";
import Button from "@material-ui/core/Button";
import { useTranslation } from "react-i18next";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import Rater from "../../ui/rating/Rater";
import MatInputOutline from "../../ui/input/MatInputOutline";
import ConfirmModal from "../../modals/confirmModal/ConfirmModal";
import InfoModal from "../../modals/infoModal/InfoModal";
import RedoIcon from "@material-ui/icons/Redo";

const RatingModal = (props) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("xs"));
  const fullWidth = true;
  const maxWidth = "xs";
  const [warn, setWarn] = useState(false);
  const [rating1, setRating1] = useState(0);
  const [rating2, setRating2] = useState(0);
  const [comment, setComment] = useState(props.comment ? props.comment : "");
  const [openConfirm, setOpenConfirm] = useState(false);

  // ------------------------------

  const submitRatingHandler = () => {
    if ((rating1 <= 3 || rating2 <= 3) && !comment) {
      setWarn(true);
    } else {
      const rating = (rating1 + rating2) / 2;
      props.onSubmitRating(rating, comment);
    }
  };

  // ------------------------------

  const cancelHandler = () => {
    props.onSubmitRating(null, null);
  };

  // ------------------------------

  const skipRating = () => {
    setOpenConfirm(true);
  };

  // ------------------------------

  const skipConfirm = async (answer) => {
    setOpenConfirm(false);

    if (answer === "ok") {
      props.onSkipRating();
    }
  };

  // ------------------------------

  const rating1Completed = (rating) => {
    setRating1(!rating ? 1 : rating);
  };

  const rating2Completed = (rating) => {
    setRating2(!rating ? 1 : rating);
  };

  const commentChanged = (e) => {
    setComment(e.target.value);
  };

  // ------------------------------

  return (
    <div>
      {openConfirm && (
        <ConfirmModal
          okButtonText={t("registration_skip")}
          title={t("registration_skip_review")}
          message={t("registration_skip_review_desc")}
          open={openConfirm}
          onClose={skipConfirm}
        />
      )}

      {warn && (
        <InfoModal
          plain
          open={warn}
          title={t("rating_course_share_feedback")}
          message={t("rating_course_leave_a_comment")}
          onClose={() => {
            setWarn(false);
          }}
        />
      )}
      <Dialog
        fullScreen={fullScreen}
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={props.open}
        onClose={cancelHandler}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="alert-dialog-title">
          {t("registration_rate")} {props.courseTitle}
        </DialogTitle>
        <DialogContent>
          <Rater
            name={t("rating_course_as_described")}
            title={t("rating_course_as_described")}
            value={rating1}
            onChange={rating1Completed}
          />
          <Rater
            name={t("rating_course_recommend")}
            title={t("rating_course_recommend")}
            value={rating2}
            onChange={rating2Completed}
          />
          <div className={styles.disclaimer}>
            {t("rating_course_leave_comment_placeholder")}
          </div>
          <MatInputOutline
            nomargin="true"
            notopbottommargin="true"
            title={t("rating_course_leave_comment")}
            name="inputText"
            rows="6"
            multiline
            value={comment}
            onChange={commentChanged}
            maxLength={1000}
            error={
              ((rating1 > 0 && rating1 <= 3) ||
                (rating2 > 0 && rating2 <= 3)) &&
              !comment
                ? true
                : false
            }
            readOnly={false}
          />
        </DialogContent>
        <DialogActions>
          <div className={styles.buttonContainer}>
            <div>
              <Button
                autoFocus
                onClick={skipRating}
                color="primary"
                startIcon={<RedoIcon />}
              >
                {t("registration_skip_review")}
              </Button>
            </div>
            <div>
              <Button autoFocus onClick={cancelHandler} color="primary">
                {t("g_cancel")}
              </Button>
              &nbsp;&nbsp;
              <Button
                autoFocus
                onClick={submitRatingHandler}
                color="primary"
                disabled={rating1 === 0 || rating2 === 0}
                style={
                  rating1 === 0 || rating2 === 0
                    ? { color: "gray", backgroundColor: "#ccc" }
                    : { color: "white", backgroundColor: "#3E8CAB" }
                }
              >
                {t("g_save")}
              </Button>
            </div>
          </div>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default RatingModal;
