import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import MatInputOutline from "../../../components/ui/input/MatInputOutline";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import AccountBalanceIcon from "@material-ui/icons/AccountBalance";
import PaymentMethodModal from "../paymentMethodModal/PaymentMethodModal";
import TextButton from "../../../components/ui/button/TextButton";
import ArrowDropDownOutlinedIcon from "@material-ui/icons/ArrowDropDownOutlined";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import { resolveCc } from "../../../globals/Common";

const FundAccountModal = (props) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("xs"));
  const fullWidth = true;
  const maxWidth = "sm";

  const [fundAmount, setFundAmount] = useState(0);
  const [paymentMethod, setPaymentMethod] = useState(null);
  const [paymentModalOpen, setPaymentModalOpen] = useState(false);

  // ------------------------------

  const handleClose = (amount, paymentMethod) => {
    if ((amount, paymentMethod)) {
      props.onClose(amount, paymentMethod.id);
    } else {
      props.onClose();
    }
  };

  // ------------------------------

  const inputChangeHandler = (e) => {
    setFundAmount(e.target.value);
  };

  // ------------------------------

  const addPaymentMethodHandler = () => {
    props.onAddPaymentMethod();
  };

  return (
    <div>
      <PaymentMethodModal
        open={paymentModalOpen}
        list={props.paymentMethods}
        onSelect={(paymentMethod) => {
          let copyPayMethod = null;
          if (paymentMethod) {
            copyPayMethod = { ...paymentMethod };
          }

          setPaymentMethod(copyPayMethod);
          setPaymentModalOpen(false);
        }}
      />

      <Dialog
        fullScreen={fullScreen}
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={props.open}
        onClose={handleClose.bind(this, null, null)}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="alert-dialog-title">
          <div style={{ display: "flex", alignItems: "center" }}>
            <AccountBalanceIcon />
            &nbsp;Add Funds / Account Balance
          </div>
        </DialogTitle>

        <div
          style={{
            width: "100%",
            textAlign: "justify",
            padding: "15px",
            display: "flex",
            justifyContent: "center",
            backgroundColor: "#ccc",
            fontSize: 14,
          }}
        >
          <InfoOutlinedIcon />
          <div style={{ marginLeft: "10px" }}>
            Your account balance is funded with a charge to a payment method of
            choice. The charge will immediately be transfered to your account
            and available.
            <div style={{ paddingTop: "10px", fontWeight: "bold" }}>
              A fee of (3.5%) will be added to the amount.
            </div>
          </div>
        </div>

        <DialogContent>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              border: "1px solid #ccc",
              borderRadius: "5px",
              width: "100%",
              padding: "20px",
              marginTop: "10px",
            }}
          >
            {props.paymentMethods && props.paymentMethods.length > 0 ? (
              <TextButton onClick={setPaymentModalOpen.bind(this, true)}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center",
                    fontSize: "14px",
                  }}
                >
                  {paymentMethod
                    ? "Update Payment Method"
                    : "Select Payment Method"}
                  &nbsp;
                  <ArrowDropDownOutlinedIcon />
                </div>
              </TextButton>
            ) : (
              <TextButton onClick={addPaymentMethodHandler}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center",
                    fontSize: "14px",
                  }}
                >
                  You have no payment method on file. Please add one.{"  "}
                  <ArrowForwardIcon />
                </div>
              </TextButton>
            )}

            {paymentMethod && (
              <div style={{ fontSize: "15px", paddingTop: "15px" }}>
                <div>
                  {paymentMethod ? resolveCc(paymentMethod.brand, 35) : ""}
                </div>
                <div style={{ fontWeight: "bold" }}>
                  {paymentMethod ? paymentMethod.name : ""}
                </div>
                <div>{paymentMethod ? paymentMethod.lastDigitsMasked : ""}</div>
                <div>
                  {paymentMethod ? "Exp:" + paymentMethod.expiryDate : ""}
                </div>
              </div>
            )}
          </div>

          {paymentMethod &&
            props.paymentMethods &&
            props.paymentMethods.length > 0 && (
              <MatInputOutline
                currency={props.currency}
                nomargin="true"
                title="Amount "
                name="fundAmount"
                value={fundAmount}
                onChange={inputChangeHandler}
                maxLength={10}
                readOnly={false}
                required={true}
              />
            )}
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            onClick={handleClose.bind(this, null, null)}
            color="primary"
          >
            Cancel
          </Button>

          {paymentMethod &&
            props.paymentMethods &&
            props.paymentMethods.length > 0 && (
              <Button
                autoFocus
                onClick={handleClose.bind(this, fundAmount, paymentMethod)}
                color="primary"
                disabled={fundAmount === 0 || paymentMethod === null}
              >
                {"Fund " + props.currency.shortName + fundAmount}
              </Button>
            )}
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default FundAccountModal;
