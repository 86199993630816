import {
  SET_ADVERTISEMENT,
  SET_ADD_ID,
  REMOVE_MARKETING_ADD,
} from "../actions/advertisement";
import { updateObject } from "../../globals/Common";
import { LOGOUT } from "../actions/auth";

const initialState = {
  marketingAddId: "",
  marketingAd: [],
};

const advertisementReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_ADVERTISEMENT:
      return updateObject(state, {
        marketingAd: action.marketingAd,
      });
    case SET_ADD_ID:
      return updateObject(state, { marketingAddId: action.marketingAddId });
    case REMOVE_MARKETING_ADD:
      const remCopyMarkAdd = [...state.marketingAd];
      const remaining = remCopyMarkAdd.filter((add) => {
        return add.id !== action.mareketingAddId;
      });
      return updateObject(state, { marketingAd: remaining });
    case LOGOUT:
      return initialState;
    default:
      return state;
  }
};

export default advertisementReducer;
