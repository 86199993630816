class Tax {
  constructor(gst_hst, qst, amount, currency) {
    this.gst_hst = gst_hst;
    this.qst = qst;
    this.amount = amount;
    this.currency = currency; // code id
  }

  gst_hst_amount = () => {
    return this.gst_hst > 0 ? this.amount * this.gst_hst : 0;
  };

  qst_amount = () => {
    return this.qst > 0 ? this.amount * this.qst : 0;
  };

  taxTotal = () => {
    return this.gst_hst_amount() + this.qst_amount();
  };

  taxPriceTotal = () => {
    return this.amount + this.taxTotal();
  };
}

export default Tax;
