import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import styles from "./VideoPlayerScreen.module.css";
import { connect } from "react-redux";
import * as courseActions from "../../../store/actions/courses";
import * as profileActions from "../../../store/actions/profile";
import * as providerActions from "../../../store/actions/provider";
import * as settingActions from "../../../store/actions/setting";
import Spinner from "../../../components/ui/spinner/Spinner";
import { objectHasNoValues } from "../../../globals/Common";
import * as logger from "../../../globals/Logger";
import ProviderItem from "../../../components/items/providerItem/ProviderItem";
import InfoModal from "../../../components/modals/infoModal/InfoModal";
import CourseContentChapter from "../../../components/items/videoChapterItem/CourseContentChapter";
import ReactPlayer from "react-player";

class VideoPlayerScreen extends Component {
    state = {
        wait: false,
        error: null,
        waitMessage: null,
        playing_chapter: null,
        playing_uri: "null",
        playing_index: -1
    };

    // ------------------------------

    update = async (propValue) => {
        await this.setState({ ...this.state, ...propValue });
    };

    // ------------------------------

    componentDidMount = async () => {
        try {
            window.scrollTo(0, 0);
            await this.update({ wait: true });
            const loadAsync = [];

            if (objectHasNoValues(this.props.mobileSetting)) {
                await this.props.fetchMobileSetting();
            }

            if (this.props.userId && objectHasNoValues(this.props.profile)) {
                loadAsync.push(this.props.fetchUserProfile());
            }

            if (this.props.providerid) {
                loadAsync.push(this.props.fetchProvider(this.props.providerid));
            }

            if (this.props.courseId) {
                loadAsync.push(this.props.fetchCourse(this.props.courseId, this.props.providerid));
            }

            if (this.props.courseId) {
                loadAsync.push(this.props.fetchRecordedVideo(this.props.courseId));
                loadAsync.push(this.props.fetchRegistrationContent(this.props.courseId));
            }

            await Promise.all(loadAsync);
            await this.update({ wait: false });
        } catch (err) {
            logger.error(err);
            this.update({ wait: false, error: err.message });
        }
    };

    // ------------------------------


    componentDidUpdate = (prevProps) => {
        if (
            prevProps.selected_video_chapters === null && this.props.selected_video_chapters !== null
        ) {
            if (this.props.selected_video_chapters.length > 0) {
                this.update({
                    playing_chapter: this.props.selected_video_chapters[0],
                    playing_uri: this.props.selected_video_chapters[0].uri,
                    playing_index: 0
                })
            }
        }
    };

    // ------------------------------

    onSelectChapter = (chapter, playingIndex) => {
        this.update({ playing_chapter: chapter, playing_uri: chapter.uri, playing_index: playingIndex })
    }

    // ------------------------------

    render() {
        if (this.state.wait) {
            return <Spinner message={this.state.waitMessage} />
        }

        return (
            <div>
                {this.state.error && (
                    <InfoModal
                        isErrorModal={true}
                        title={this.props.t("error_occurred")
                        }
                        message={this.state.error}
                        open={this.state.message !== null}
                        onClose={() => {
                            this.update({ error: null });
                        }}
                    />
                )}

                <div className={styles.container}>
                    <div className={styles.courseTitle}>{this.props.course ? this.props.course.title : ""}</div>

                    <div className={styles.playerAndChaptersContainer}>
                        <div className={styles.playerContainer}>
                            <div className={styles.player}>
                                <ReactPlayer
                                    url={this.state.playing_uri}
                                    controls={false}
                                    muted={true}
                                    config={{
                                        youtube: {
                                            playerVars: { showinfo: 1, disablekb: 1 }
                                        },
                                        file: { attributes: { controlsList: "nodownload" } }
                                    }}

                                    onContextMenu={(e) => e.preventDefault()}
                                    width="100%"
                                    height="100%"
                                />
                            </div>

                            <div className={styles.playingChapterTextContainer}>
                                {this.state.playing_chapter && (
                                    <div>
                                        <div className={styles.playingChapterTextTitle}>{this.state.playing_chapter.title}</div>
                                        <div className={styles.playingChapterTextDescription}>{this.state.playing_chapter.description}</div>
                                    </div>
                                )}

                                {this.props.email_instruction && (
                                    <div className={styles.videoAccessContainer}>
                                        <div className={styles.playingChapterTextTitle}>Video Access Instructions</div>
                                        <div className={styles.playingChapterTextDescription}>{this.props.email_instruction}</div>
                                    </div>
                                )}

                                <div className={styles.videoAccessContainer}>
                                    <div className={styles.playingChapterTextTitle}>Certificate of Completion</div>
                                    <div className={styles.playingChapterTextDescription}>Certificate of completion will be released once you completed all course chapters.</div>
                                </div>
                            </div>

                        </div>

                        <div className={styles.chaptersContainer}>
                            <div className={styles.courseContentChapterHeader}>Course Content</div>
                            <div className={styles.courseContent}>
                                {this.props.selected_video_chapters && this.props.selected_video_chapters.length > 0
                                    ?
                                    this.props.selected_video_chapters.map(
                                        (chapter, index) =>
                                            <CourseContentChapter
                                                onSelectChapter={this.onSelectChapter.bind(this, chapter, index)}
                                                key={chapter.id}
                                                videoChapter={chapter}
                                                selected={this.state.playing_index === index}
                                            />
                                    )
                                    :
                                    <div className={styles.empty}>
                                        Video streaming content not updated by your course provider.  Please try again later.
                                    </div>
                                }


                                {this.props.provider && (
                                    <div style={{
                                        width: "100%",
                                        marginTop: "20px",
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItem: "center",
                                        justifyContent: "center"
                                    }}>
                                        <ProviderItem
                                            readOnly
                                            addToCart
                                            id={this.props.provider.id}
                                            name={this.props.provider.name}
                                            logo={this.props.provider.logoCached}
                                            country={this.props.provider.country}
                                            currency={this.props.provider.currency}
                                            onContact={() => { }}
                                            contact={false}
                                            center={true}
                                        />
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

// ------------------------------

const parseQueryString = (props) => {
    let providerId = null;
    let courseId = null;
    const query = new URLSearchParams(props.location.search);

    for (let param of query.entries()) {
        if (param[0] === "p") {
            providerId = param[1];
        }
        if (param[0] === "c") {
            courseId = param[1];
        }
    }

    if (providerId === null && courseId === null) {
        providerId = props.match.params.providerId;
        courseId = props.match.params.courseId;
    }

    return { providerId: providerId, courseId: courseId };
};


// ------------------------------

const mapStateToProps = (state, props) => {
    const params = parseQueryString(props);

    return {
        providerid: params.providerId,
        courseId: params.courseId,
        course: state.courses.select_course,
        selected_video_chapters: state.courses.selected_video_chapters,
        email_instruction: state.courses.email_instruction,

        isAuthenticated: state.auth.token != null,
        action: props.history.action,
        mobileSetting: state.setting.mobileSetting,
        mobileUserProfile: state.profile.userProfile,
        isMobileUser: state.auth.isMobileUser,
        profile: state.profile.userProfile,
        provider: state.provider.selected_provider,
        userId: state.auth.userId,
    };
};

// ------------------------------

const mapDispatchToProps = (dispatch) => {
    return {
        fetchMobileSetting: () => dispatch(settingActions.fetchMobileSetting()),
        fetchMobileUserProfile: () => dispatch(profileActions.fetchMobileUserProfile()),
        fetchUserProfile: () => dispatch(profileActions.fetchMobileUserProfile()),
        fetchCourse: (id, providerId) => dispatch(courseActions.fetchCourse(id, providerId)),
        fetchProvider: (providerId) => dispatch(providerActions.fetchProvider(providerId)),
        fetchRecordedVideo: (courseId) => dispatch(courseActions.fetchRecordedVideo(courseId, true)),
        fetchRegistrationContent: (courseId) => dispatch(courseActions.fetchRegistrationContent(courseId)),
    };
};

export default withTranslation("translations")(
    connect(mapStateToProps, mapDispatchToProps)(VideoPlayerScreen)
);
