import React from "react";
import * as styles from "./Chart.module.css";
import { PieChart, Pie, Legend, Tooltip } from "recharts";
import { CSVLink } from "react-csv";
import IconButton from "@material-ui/core/IconButton";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";

const ZPie = (props) => {
  return (
    <div className={styles.container}>
      <div className={styles.title}>
        {props.title}
        <IconButton>
          <CSVLink
            style={{ color: "black" }}
            data={props.data ? props.data : []}
            headers={props.headers}
            filename={props.csvfileName}
          >
            <CloudDownloadIcon />
          </CSVLink>
        </IconButton>
      </div>

      <div className={styles.chartContainer}>
        {props.data && props.data.length > 0 ? (
          <PieChart width={500} height={300}>
            <Pie
              data={props.data}
              dataKey="value"
              cx={110}
              cy={130}
              innerRadius={props.innerRadius ? props.innerRadius : 0}
              outerRadius={100}
              paddingAngle={3}
              fill="#82ca9d"
              label
            />
            <Legend
              align="left"
              layout="vertical"
              iconType="circle"
              verticalAlign="top"
              width={247}
            />
            <Tooltip />
          </PieChart>
        ) : (
          props.noDataComponent
        )}
      </div>
    </div>
  );
};

export default ZPie;
