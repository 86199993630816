import Setting from "../../model/Setting";
import MobileSetting from "../../model/MobileSetting";

import { fetchRates } from "../actions/rate";
import { dbName } from "../../globals/ApiKeys";
import { handleErrorResponse } from "../../globals/Error";

import { CURRENCY } from "../../globals/Data";
import firebase from "firebase/app";
import {
  toFirebaseStringArrayValue,
  fromFirebaseStringArrayValue,
  objectHasNoValues,
} from "../../globals/Common";
import * as logger from "../../globals/Logger";

export const SET_SETTING = "SET_SETTING";
export const UPDATE_COURSE_VIEW = "UPDATE_COURSE_VIEW";
export const SHOW_DASHBOARD = "SHOW_DASHBOARD";
export const UPDATE_CHARTS = "UPDATE_CHARTS";
export const SET_ANALYTICS_DATE = "SET_ANALYTICS_DATE";
export const SET_ANALYTICS_COURSE = "SET_ANALYTICS_COURSE";
export const UPDATE_NOTIFY_PAYOUT_PAID = "UPDATE_NOTIFY_PAYOUT_PAID";
export const UPDATE_NOTIFY_LIVESTREAM_CAPACITY = "UPD_NOTIF_LIVESTREAM_CAP";
export const UPDATE_NOTIFY_CLASS_LECTURE_CAPACITY = "UPD_NOTIF_LECTURE_CAP";
export const UPDATE_NOTIFY_ClASS_HANDSON_CAPACITY = "UPD_NOTIF_HANDSON_CAP";
export const UPDATE_NOTIFY_ONLINE_VIDEO_CAPACITY = "UPD_NOTIF_ONLINE_VIDEO_CAP";
export const UPDATE_LIVESTREAM_CAPACITY_LIMIT = "UPD_LIVESTREAM_CAP_LIMIT";
export const UPDATE_CLASS_LECTURE_CAPACITY_LIMIT = "UPD_LECTURE_CAP_LIMIT";
export const UPDATE_ClASS_HANDSON_CAPACITY_LIMIT = "UPD_HANDSON_CAP_LIMIT";
export const UPDATE_ONLINE_VIDEO_CAPACITY_LIMIT = "UPD_ONLINE_VIDEO_CAP_LIMIT";
export const UPD_CERT_LIVE_DELIVERY = "UPD_CERT_LIVE_DELIVERY";
export const UPD_CERT_VIDEO_DELIVERY = "UPD_CERT_VIDEO_DELIVERY";
export const UPD_CERT_HEADER = "UPD_CERT_HEADER";
export const UPD_CERT_FOOTER = "UPD_CERT_FOOTER";

export const SET_MOBILE_SETTING = "SET_MOBILE_SETTING";
export const UPDATE_MOBILE_CURRENCY = "UPDATE_MOBILE_CURRENCY";

// ------------------------------

export const updateSettingColumns = (columns) => {
  return async (dispatch, getState) => {
    const userId = getState().auth.userId;
    const token = getState().auth.token;

    const response = await fetch(
      `https://firestore.googleapis.com/v1/projects/${dbName}/databases/(default)/documents/setting/${userId}` +
      `?updateMask.fieldPaths=student_table_display`,
      {
        method: "PATCH",
        headers: {
          "Content-Type": "applications/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          fields: {
            student_table_display: {
              mapValue: {
                fields: buildStudentColumnsForSave(columns)
              }
            }
          }
        }),
      }
    );

    if (!response.ok) {
      handleErrorResponse(await response.json());
    }

    //dispatch({
    //  type: SET_ANALYTICS_DATE,
    //  analytics_date: date,
    // });
  };
};


// ------------------------------

const buildStudentColumnsForSave = (columns) => {
  let fields = {};
  for (const column of columns) {
    fields[column.name] = { booleanValue: column.display };
  }


  return fields;
}

// ------------------------------

export const fetchSetting = () => {
  return async (dispatch, getState) => {
    const userId = getState().auth.userId;
    const token = getState().auth.token;
    let setting = new Setting("cards", "en", true, [], new Date());

    try {
      const response = await fetch(
        `https://firestore.googleapis.com/v1/projects/${dbName}/databases/(default)/documents/setting/${userId}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "applications/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (!response.ok) {
        handleErrorResponse(await response.json());
      }

      const resData = await response.json();

      if (resData) {
        setting = new Setting(
          resData.fields.course_view
            ? resData.fields.course_view.stringValue
            : "cards",
          resData.fields.language ? resData.fields.language.stringValue : "en",
          resData.fields.show_dashboard
            ? resData.fields.show_dashboard.booleanValue
            : true,
          fromFirebaseStringArrayValue(
            resData.fields.charts ? resData.fields.charts.arrayValue.values : []
          ),
          resData.fields.analytics_date
            ? new Date(Number(resData.fields.analytics_date.integerValue))
            : new Date(),
          resData.fields.analytics_course
            ? resData.fields.analytics_course.stringValue
            : "",
          resData.fields.notify_payout_paid
            ? resData.fields.notify_payout_paid.booleanValue
            : false,
          resData.fields.notify_livestream_capacity
            ? resData.fields.notify_livestream_capacity.booleanValue
            : false,
          resData.fields.notify_classroom_lecture_capacity
            ? resData.fields.notify_classroom_lecture_capacity.booleanValue
            : false,
          resData.fields.notify_classroom_handson_capacity
            ? resData.fields.notify_classroom_handson_capacity.booleanValue
            : false,
          resData.fields.notify_online_video_capacity
            ? resData.fields.notify_online_video_capacity.booleanValue
            : false,
          resData.fields.livestream_capacity_limit
            ? resData.fields.livestream_capacity_limit.integerValue
            : 0,
          resData.fields.classroom_lecture_capacity_limit
            ? resData.fields.classroom_lecture_capacity_limit.integerValue
            : 0,
          resData.fields.classroom_handson_capacity_limit
            ? resData.fields.classroom_handson_capacity_limit.integerValue
            : 0,
          resData.fields.online_video_capacity_limit
            ? resData.fields.online_video_capacity_limit.integerValue
            : 0,
          resData.fields.certificate_delivery_live
            ? resData.fields.certificate_delivery_live.stringValue
            : "manual",
          resData.fields.certificate_delivery_video
            ? resData.fields.certificate_delivery_video.stringValue
            : "manual",
          resData.fields.certificate_header
            ? resData.fields.certificate_header.stringValue
            : null,
          resData.fields.certificate_footer
            ? resData.fields.certificate_footer.stringValue
            : null,
          resData.fields.student_table_display
            ? buildStudentTableDisplay(resData.fields.student_table_display.mapValue.fields)
            : null
        );
      }
    } catch (error) {
      logger.error(error);
    }

    dispatch({
      type: SET_SETTING,
      setting: setting,
    });
  };
};

// ------------------------------

const buildStudentTableDisplay = (fields) => {
  if (!fields) {
    return null;
  }

  const keys = Object.keys(fields)

  let newMap = {};
  for (const key of keys) {
    newMap[key] = fields[key].booleanValue;
  }

  return newMap;
}

export const updateCourseView = (courseView) => {
  return async (dispatch, getState) => {
    const userId = getState().auth.userId;
    const token = getState().auth.token;

    const response = await fetch(
      `https://firestore.googleapis.com/v1/projects/${dbName}/databases/(default)/documents/setting/${userId}` +
      `?updateMask.fieldPaths=course_view`,
      {
        method: "PATCH",
        headers: {
          "Content-Type": "applications/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          fields: {
            course_view: { stringValue: courseView },
          },
        }),
      }
    );

    if (!response.ok) {
      handleErrorResponse(await response.json());
    }

    dispatch({
      type: UPDATE_COURSE_VIEW,
      course_view: courseView,
    });
  };
};

// ------------------------------

export const setShowDashboard = (showDashboard) => {
  return async (dispatch, getState) => {
    const userId = getState().auth.userId;
    const token = getState().auth.token;

    const response = await fetch(
      `https://firestore.googleapis.com/v1/projects/${dbName}/databases/(default)/documents/setting/${userId}` +
      `?updateMask.fieldPaths=show_dashboard`,
      {
        method: "PATCH",
        headers: {
          "Content-Type": "applications/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          fields: {
            show_dashboard: { booleanValue: showDashboard },
          },
        }),
      }
    );

    if (!response.ok) {
      handleErrorResponse(await response.json());
    }

    dispatch({
      type: SHOW_DASHBOARD,
      show_dashboard: showDashboard,
    });
  };
};

// ------------------------------

export const updateAnalyticsDate = (date) => {
  return async (dispatch, getState) => {
    const userId = getState().auth.userId;
    const token = getState().auth.token;

    const response = await fetch(
      `https://firestore.googleapis.com/v1/projects/${dbName}/databases/(default)/documents/setting/${userId}` +
      `?updateMask.fieldPaths=analytics_date`,
      {
        method: "PATCH",
        headers: {
          "Content-Type": "applications/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          fields: {
            analytics_date: { integerValue: date.getTime() },
          },
        }),
      }
    );

    if (!response.ok) {
      handleErrorResponse(await response.json());
    }

    dispatch({
      type: SET_ANALYTICS_DATE,
      analytics_date: date,
    });
  };
};

// ------------------------------

export const updateAnalyticsCourse = (courseId) => {
  return async (dispatch, getState) => {
    const userId = getState().auth.userId;
    const token = getState().auth.token;

    const response = await fetch(
      `https://firestore.googleapis.com/v1/projects/${dbName}/databases/(default)/documents/setting/${userId}` +
      `?updateMask.fieldPaths=analytics_course`,
      {
        method: "PATCH",
        headers: {
          "Content-Type": "applications/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          fields: {
            analytics_course: { stringValue: courseId },
          },
        }),
      }
    );

    if (!response.ok) {
      handleErrorResponse(await response.json());
    }

    dispatch({
      type: SET_ANALYTICS_COURSE,
      analytics_course: courseId,
    });
  };
};

// ------------------------------

export const updateCharts = (charts) => {
  return async (dispatch, getState) => {
    const userId = getState().auth.userId;
    const token = getState().auth.token;

    const response = await fetch(
      `https://firestore.googleapis.com/v1/projects/${dbName}/databases/(default)/documents/setting/${userId}` +
      `?updateMask.fieldPaths=charts`,
      {
        method: "PATCH",
        headers: {
          "Content-Type": "applications/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          fields: {
            charts: {
              arrayValue: {
                values: toFirebaseStringArrayValue(charts),
              },
            },
          },
        }),
      }
    );

    if (!response.ok) {
      handleErrorResponse(await response.json());
    }

    dispatch({
      type: UPDATE_CHARTS,
      charts: charts,
    });
  };
};

// ------------------------------

export const updateNotifyPayoutPaid = (notifyPayoutPaid) => {
  return async (dispatch, getState) => {
    const userId = getState().auth.userId;
    const token = getState().auth.token;

    const response = await fetch(
      `https://firestore.googleapis.com/v1/projects/${dbName}/databases/(default)/documents/setting/${userId}` +
      `?updateMask.fieldPaths=notify_payout_paid`,
      {
        method: "PATCH",
        headers: {
          "Content-Type": "applications/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          fields: {
            notify_payout_paid: { booleanValue: notifyPayoutPaid },
          },
        }),
      }
    );

    if (!response.ok) {
      handleErrorResponse(await response.json());
    }

    dispatch({
      type: UPDATE_NOTIFY_PAYOUT_PAID,
      notify: notifyPayoutPaid,
    });
  };
};

// ------------------------------

export const updateNotifyLivestreamCapacity = (value) => {
  return async (dispatch, getState) => {
    const userId = getState().auth.userId;
    const token = getState().auth.token;

    const response = await fetch(
      `https://firestore.googleapis.com/v1/projects/${dbName}/databases/(default)/documents/setting/${userId}` +
      `?updateMask.fieldPaths=notify_livestream_capacity`,
      {
        method: "PATCH",
        headers: {
          "Content-Type": "applications/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          fields: {
            notify_livestream_capacity: { booleanValue: value },
          },
        }),
      }
    );

    if (!response.ok) {
      handleErrorResponse(await response.json());
    }

    dispatch({
      type: UPDATE_NOTIFY_LIVESTREAM_CAPACITY,
      notify: value,
    });
  };
};

// ------------------------------

export const updateNotifyClassroomLectureCapacity = (value) => {
  return async (dispatch, getState) => {
    const userId = getState().auth.userId;
    const token = getState().auth.token;

    const response = await fetch(
      `https://firestore.googleapis.com/v1/projects/${dbName}/databases/(default)/documents/setting/${userId}` +
      `?updateMask.fieldPaths=notify_classroom_lecture_capacity`,
      {
        method: "PATCH",
        headers: {
          "Content-Type": "applications/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          fields: {
            notify_classroom_lecture_capacity: { booleanValue: value },
          },
        }),
      }
    );

    if (!response.ok) {
      handleErrorResponse(await response.json());
    }

    dispatch({
      type: UPDATE_NOTIFY_CLASS_LECTURE_CAPACITY,
      notify: value,
    });
  };
};

// ------------------------------

export const updateNotifyClassroomHandsonCapacity = (value) => {
  return async (dispatch, getState) => {
    const userId = getState().auth.userId;
    const token = getState().auth.token;

    const response = await fetch(
      `https://firestore.googleapis.com/v1/projects/${dbName}/databases/(default)/documents/setting/${userId}` +
      `?updateMask.fieldPaths=notify_classroom_handson_capacity`,
      {
        method: "PATCH",
        headers: {
          "Content-Type": "applications/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          fields: {
            notify_classroom_handson_capacity: { booleanValue: value },
          },
        }),
      }
    );

    if (!response.ok) {
      handleErrorResponse(await response.json());
    }

    dispatch({
      type: UPDATE_NOTIFY_ClASS_HANDSON_CAPACITY,
      notify: value,
    });
  };
};

// ------------------------------

export const updateNotifyOnineVideoCapacity = (value) => {
  return async (dispatch, getState) => {
    const userId = getState().auth.userId;
    const token = getState().auth.token;

    const response = await fetch(
      `https://firestore.googleapis.com/v1/projects/${dbName}/databases/(default)/documents/setting/${userId}` +
      `?updateMask.fieldPaths=notify_online_video_capacity`,
      {
        method: "PATCH",
        headers: {
          "Content-Type": "applications/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          fields: {
            notify_online_video_capacity: { booleanValue: value },
          },
        }),
      }
    );

    if (!response.ok) {
      handleErrorResponse(await response.json());
    }

    dispatch({
      type: UPDATE_NOTIFY_ONLINE_VIDEO_CAPACITY,
      notify: value,
    });
  };
};

// ------------------------------

export const updateLivestreamCapacityLimit = (value) => {
  return async (dispatch, getState) => {
    const userId = getState().auth.userId;
    const token = getState().auth.token;

    const response = await fetch(
      `https://firestore.googleapis.com/v1/projects/${dbName}/databases/(default)/documents/setting/${userId}` +
      `?updateMask.fieldPaths=livestream_capacity_limit`,
      {
        method: "PATCH",
        headers: {
          "Content-Type": "applications/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          fields: {
            livestream_capacity_limit: { integerValue: value },
          },
        }),
      }
    );

    if (!response.ok) {
      handleErrorResponse(await response.json());
    }

    dispatch({
      type: UPDATE_LIVESTREAM_CAPACITY_LIMIT,
      limit: value,
    });
  };
};

// ------------------------------

export const updateClassroomLectureCapacityLimit = (value) => {
  return async (dispatch, getState) => {
    const userId = getState().auth.userId;
    const token = getState().auth.token;

    const response = await fetch(
      `https://firestore.googleapis.com/v1/projects/${dbName}/databases/(default)/documents/setting/${userId}` +
      `?updateMask.fieldPaths=classroom_lecture_capacity_limit`,
      {
        method: "PATCH",
        headers: {
          "Content-Type": "applications/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          fields: {
            classroom_lecture_capacity_limit: { integerValue: value },
          },
        }),
      }
    );

    if (!response.ok) {
      handleErrorResponse(await response.json());
    }

    dispatch({
      type: UPDATE_CLASS_LECTURE_CAPACITY_LIMIT,
      limit: value,
    });
  };
};

// ------------------------------

export const updateClassroomHandsonCapacityLimit = (value) => {
  return async (dispatch, getState) => {
    const userId = getState().auth.userId;
    const token = getState().auth.token;

    const response = await fetch(
      `https://firestore.googleapis.com/v1/projects/${dbName}/databases/(default)/documents/setting/${userId}` +
      `?updateMask.fieldPaths=classroom_handson_capacity_limit`,
      {
        method: "PATCH",
        headers: {
          "Content-Type": "applications/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          fields: {
            classroom_handson_capacity_limit: { integerValue: value },
          },
        }),
      }
    );

    if (!response.ok) {
      handleErrorResponse(await response.json());
    }

    dispatch({
      type: UPDATE_ClASS_HANDSON_CAPACITY_LIMIT,
      limit: value,
    });
  };
};

// ------------------------------

export const updateOnineVideoCapacityLimit = (value) => {
  return async (dispatch, getState) => {
    const userId = getState().auth.userId;
    const token = getState().auth.token;

    const response = await fetch(
      `https://firestore.googleapis.com/v1/projects/${dbName}/databases/(default)/documents/setting/${userId}` +
      `?updateMask.fieldPaths=online_video_capacity_limit`,
      {
        method: "PATCH",
        headers: {
          "Content-Type": "applications/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          fields: {
            online_video_capacity_limit: { integerValue: value },
          },
        }),
      }
    );

    if (!response.ok) {
      handleErrorResponse(await response.json());
    }

    dispatch({
      type: UPDATE_ONLINE_VIDEO_CAPACITY_LIMIT,
      limit: value,
    });
  };
};

// ------------------------------

export const updateCertificateLiveDelivery = (value) => {
  return async (dispatch, getState) => {
    const userId = getState().auth.userId;
    const token = getState().auth.token;

    const response = await fetch(
      `https://firestore.googleapis.com/v1/projects/${dbName}/databases/(default)/documents/setting/${userId}` +
      `?updateMask.fieldPaths=certificate_delivery_live`,
      {
        method: "PATCH",
        headers: {
          "Content-Type": "applications/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          fields: {
            certificate_delivery_live: { stringValue: value },
          },
        }),
      }
    );

    if (!response.ok) {
      handleErrorResponse(await response.json());
    }

    dispatch({
      type: UPD_CERT_LIVE_DELIVERY,
      delivery: value,
    });
  };
};

// ------------------------------

export const updateCertificateVideoDelivery = (value) => {
  return async (dispatch, getState) => {
    const userId = getState().auth.userId;
    const token = getState().auth.token;

    const response = await fetch(
      `https://firestore.googleapis.com/v1/projects/${dbName}/databases/(default)/documents/setting/${userId}` +
      `?updateMask.fieldPaths=certificate_delivery_video`,
      {
        method: "PATCH",
        headers: {
          "Content-Type": "applications/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          fields: {
            certificate_delivery_video: { stringValue: value },
          },
        }),
      }
    );

    if (!response.ok) {
      handleErrorResponse(await response.json());
    }

    dispatch({
      type: UPD_CERT_VIDEO_DELIVERY,
      delivery: value,
    });
  };
};

// ------------------------------

export const updateCertificate = (urlBlob, type) => {
  return async (dispatch, getState) => {
    await dispatch(uploadOrDelete(urlBlob, type));
  };
};

// ------------------------------

const uploadOrDelete = (urlBlob, prefix) => {
  return async (dispatch, getState) => {
    if (urlBlob && urlBlob.toString().startsWith("blob:")) {
      await dispatch(uploadPic(urlBlob, prefix));
    } else {
      await dispatch(
        deletePic(prefix, () => {
          return async (dispatch, getState) => {
            if (prefix === "certificate_header") {
              dispatch(updateCertificateHeader(""));
            } else if (prefix === "certificate_footer") {
              dispatch(updateCertificateFooter(""));
            }
          };
        })
      );
    }
  };
};

// ------------------------------

const updateCertificateHeader = (downloadURL) => {
  return async (dispatch, getState) => {
    const userId = getState().auth.userId;
    const token = getState().auth.token;

    const response = await fetch(
      `https://firestore.googleapis.com/v1/projects/${dbName}/databases/(default)/documents/setting/${userId}` +
      `?updateMask.fieldPaths=certificate_header`,
      {
        method: "PATCH",
        headers: {
          "Content-Type": "applications/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          fields: {
            certificate_header: { stringValue: downloadURL },
          },
        }),
      }
    );

    if (!response.ok) {
      handleErrorResponse(await response.json());
    }

    dispatch({
      type: UPD_CERT_HEADER,
      certificate_header: downloadURL,
    });
  };
};

// ------------------------------

const updateCertificateFooter = (downloadURL) => {
  return async (dispatch, getState) => {
    const userId = getState().auth.userId;
    const token = getState().auth.token;

    const response = await fetch(
      `https://firestore.googleapis.com/v1/projects/${dbName}/databases/(default)/documents/setting/${userId}` +
      `?updateMask.fieldPaths=certificate_footer`,
      {
        method: "PATCH",
        headers: {
          "Content-Type": "applications/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          fields: {
            certificate_footer: { stringValue: downloadURL },
          },
        }),
      }
    );

    if (!response.ok) {
      handleErrorResponse(await response.json());
    }

    dispatch({
      type: UPD_CERT_FOOTER,
      certificate_footer: downloadURL,
    });
  };
};

// ------------------------------

const uploadPic = (uri, postfix) => {
  return async (dispatch, getState) => {
    const userId = getState().auth.userId;

    if (uri && uri !== "undefined") {
      const response = await fetch(uri);
      const blob = await response.blob();
      let ref = firebase.storage().ref().child(`images/${userId}/${postfix}`);

      await ref.put(blob).then(function (snap) {
        snap.ref
          .getDownloadURL()
          .then(function (downloadUrl) {
            // ----------
            if (downloadUrl && postfix === "certificate_header") {
              try {
                dispatch(updateCertificateHeader(downloadUrl));
              } catch (err) {
                logger.error(err);
              }
            }
            // ----------
            if (downloadUrl && postfix === "certificate_footer") {
              try {
                dispatch(updateCertificateFooter(downloadUrl));
              } catch (err) {
                logger.error(err);
              }
            }
            // ----------
          })
          .catch(function (error) {
            logger.error(error);
          });
      });
    }
  };
};

// ------------------------------

const deletePic = (postfix, callback) => {
  return async (dispatch, getState) => {
    const userId = getState().auth.userId;

    try {
      await firebase
        .storage()
        .ref()
        .child(`images/${userId}/${postfix}`)
        .delete();
    } catch (error) {
      logger.info("picture not found, deletePic - setting");
    }

    await dispatch(callback());
  };
};

// ------------------------------

export const setMobileFavCategory = (
  userId,
  token,
  categories,
  courseLanguage
) => {
  return async (dispatch, getState) => {
    const response = await fetch(
      `https://${dbName}.firebaseio.com/setting/${userId}.json?auth=${token}`,
      {
        method: "PATCH",
        headers: {
          "Content-Type": "applications/json",
        },
        body: JSON.stringify({
          fav_category: categories,
          fav_course_lang: courseLanguage,
        }),
      }
    );

    if (!response.ok) {
      handleErrorResponse(await response.json());
    }

    await response.json();
  };
};

// ------------------------------
// Mobile setting
// ------------------------------

const resolveCurrency = async (currency) => {
  if (!currency) {
    return await tryToFindCurrency();
  } else {
    return currency;
  }
};

// ------------------------------

const tryToFindCurrency = async () => {
  try {
    const response = await fetch(`https://ipapi.co/json/`);

    if (!response.ok) {
      handleErrorResponse(await response.json());
    }

    const resData = await response.json();

    if (resData && resData.currency) {
      const indexOfCurrency = CURRENCY.findIndex(
        (c) => c.id === resData.currency
      );

      if (indexOfCurrency > -1) {
        return resData.currency;
      }
    }
  } catch (error) {
    // do nothing
  }

  return "USD";
};

// ------------------------------

export const fetchMobileSetting = () => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;

    let loadedSetting = {};

    if (token) {
      const userId = getState().auth.userId;
      const response = await fetch(
        `https://${dbName}.firebaseio.com/setting/${userId}.json?auth=${token}`
      );

      if (!response.ok) {
        handleErrorResponse(await response.json());
      }

      const resData = await response.json();

      if (resData) {
        const currency = await resolveCurrency(resData.currency);

        loadedSetting = new MobileSetting(
          resData.fav_category ? resData.fav_category : [],
          resData.fav_course_lang
            ? resData.fav_course_lang
            : ["English", "French"],
          resData.language ? resData.language : "en-ca",
          currency,
          resData.unit
            ? resData.unit
            : resData.currency && resData.currency === "CAD"
              ? "K"
              : "M",
          resData.pausePushNotifications
            ? resData.pausePushNotifications
            : false,
          resData.pauseEmailNotifications
            ? resData.pauseEmailNotifications
            : false
        );

        if (!resData.currency) {
          dispatch(updateCurrency(currency));
        }

        /*if (!resData.language) {
          if (i18n.locale.startsWith("fr")) {
            dispatch(updateLanguage("fr-ca"));
          } else {
            dispatch(updateLanguage("en-ca"));
          }
        }*/
      }
    } else {
      loadedSetting = getState().setting.mobileSetting;

      if (objectHasNoValues(loadedSetting)) {
        const localPref = await getLocalStoragePref();

        loadedSetting = new MobileSetting(
          [],
          ["English", "French"],
          localPref && localPref.language ? localPref.language : "en-ca",
          localPref && localPref.currency ? localPref.currency : "CAD",
          "K",
          false
        );
      }
    }

    dispatch({ type: SET_MOBILE_SETTING, mobileSetting: loadedSetting });
  };
};

// ------------------------------

export const updateCurrency = (currencyId, language) => {
  return async (dispatch, getState) => {
    const userId = getState().auth.userId;
    const token = getState().auth.token;

    if (token) {
      const response = await fetch(
        `https://${dbName}.firebaseio.com/setting/${userId}.json?auth=${token}`,
        {
          method: "PATCH",
          headers: {
            "Content-Type": "applications/json",
          },
          body: JSON.stringify({
            currency: currencyId,
            language: language,
          }),
        }
      );

      if (!response.ok) {
        handleErrorResponse(await response.json());
      }

      await response.json();
    }

    saveLocalStorageCurrency(currencyId, language);

    dispatch({
      type: UPDATE_MOBILE_CURRENCY,
      currency: currencyId,
      language: language,
    });

    dispatch(fetchRates(currencyId));
  };
};

// ------------------------------

const saveLocalStorageCurrency = (currency, language) => {
  localStorage.setItem(
    "userPreference",
    JSON.stringify({
      currency: currency,
      language: language,
    })
  );
};

// ------------------------------

const getLocalStoragePref = async () => {
  const userPref = await localStorage.getItem("userPreference");

  if (!userPref) {
    return;
  }

  const transformedData = JSON.parse(userPref);
  const { language, currency } = transformedData;
  return { currency: currency, language: language };
};

// ------------------------------
// End Mobile setting actions
// ------------------------------
