import { dbName } from "../../globals/ApiKeys";
import { handleErrorResponse } from "../../globals/Error";
import { firebaseNameToId } from "../../globals/Common";
import MobileOrder from "../../model/MobileOrder";
import InstallmentStatus from "../../model/InstallmentStatus";
import CartProvider from "../../model/CartProvider";
import CartCourseItem from "../../model/CartCourseItem";
import CartPricingItem from "../../model/CartPricingItem";
import PricingItem from "../../model/PricingItem";
import StaffMember from "../../model/StaffMember";
import {
  fromFirebaseDoubleValue,
  fromFirebaseStringArrayValue,
} from "../../globals/Common";

export const SET_MOBILE_ORDERS = "SET_MOBILE_ORDERS";

const LIMIT = 20;

// ------------------------------

export const emailReceipt = (orderId) => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;

    const idArray = orderId.split("/");
    const id = idArray[idArray.length - 1];

    const response = await fetch(
      `https://us-central1-${dbName}.cloudfunctions.net/resendEmailOrder`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          data: { orderId: id },
        }),
      }
    );

    if (!response.ok) {
      handleErrorResponse(await response.json());
    }
  };
};

// ------------------------------

export const fetchOrders = (page) => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;

    if (!token) {
      return dispatch({
        type: SET_MOBILE_ORDERS,
        orders: [],
        hasMore: false,
      });
    }

    const userId = getState().auth.userId;

    const response = await fetch(
      `https://firestore.googleapis.com/v1/projects/${dbName}/databases/(default)/documents/stripe_customers/${userId}:runQuery`,
      {
        method: "POST",
        headers: {
          "Content-Type": "applications/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          structuredQuery: {
            from: [{ collectionId: "orders" }],
            orderBy: [
              {
                field: {
                  fieldPath: "date",
                },
                direction: "DESCENDING",
              },
            ],
            limit: page ? LIMIT * page : LIMIT,
          },
        }),
      }
    );

    if (!response.ok) {
      handleErrorResponse(await response.json());
    }

    const resData = await response.json();

    const loadedOrders = [];
    for (const documents of resData) {
      const order = documents.document;
      if (order && order.name) {
        const installmentStatus = await fetchAndBuildInstallentStatus(
          userId,
          token,
          order
        );

        loadedOrders.push(
          new MobileOrder(
            order.name,
            order.fields.orderRefNumber.stringValue,
            new Date(order.fields.date.timestampValue),
            order.fields.chargeAmount
              ? fromFirebaseDoubleValue(
                order.fields.chargeAmount.doubleValue,
                order.fields.chargeAmount.integerValue
              )
              : fromFirebaseDoubleValue(
                order.fields.totalAmount.doubleValue,
                order.fields.totalAmount.integerValue
              ),
            fromFirebaseDoubleValue(
              order.fields.totalAmount.doubleValue,
              order.fields.totalAmount.integerValue
            ),
            order.fields.couponValue
              ? fromFirebaseDoubleValue(
                order.fields.couponValue.doubleValue,
                order.fields.couponValue.integerValue
              )
              : 0,
            buildCartProvider(order.fields.items.mapValue.fields),
            installmentStatus
          )
        );
      }
    }

    dispatch({
      type: SET_MOBILE_ORDERS,
      orders: loadedOrders,
      hasMore: hasMorePages(loadedOrders.length, page),
    });
  };
};

// ------------------------------

const hasMorePages = (totalRows, page) => {
  return totalRows - (page ? page * LIMIT : LIMIT) >= 0;
};

// ------------------------------

const buildCartProvider = (cartProvider) => {
  const orderItems = [];

  orderItems.push(
    new CartProvider(
      cartProvider.id.stringValue,
      cartProvider.providerName.stringValue,
      buildCartCourseItems(cartProvider.cartCourseItems),
      cartProvider.providerCurrency
        ? cartProvider.providerCurrency.stringValue
        : "CAD"
    )
  );

  return orderItems;
};

// ------------------------------

const buildCartCourseItems = (courseItems) => {
  let cartItems = {};
  const cartPricingItem = courseItems.mapValue.fields;

  Object.keys(cartPricingItem).forEach((key, index) => {
    const pi = cartPricingItem[key];

    cartItems = {
      ...cartItems,
      [key]: new CartCourseItem(
        key, // course id
        pi.mapValue.fields.title.stringValue,
        buildCartPricingItems(pi.mapValue.fields.pricingItems.arrayValue)
      ),
    };
  });

  return cartItems;
};

// ------------------------------

const buildCartPricingItems = (pricingItems) => {
  let arrPricingItems = [];

  for (const item of pricingItems.values) {
    const costAfterDiscount = item.mapValue.fields.costAfterDiscount;
    const pi = item.mapValue.fields.pricingItem;
    const arrStaff = item.mapValue.fields.staffMembers;

    arrPricingItems.push(
      new CartPricingItem(
        costAfterDiscount
          ? fromFirebaseDoubleValue(
            costAfterDiscount.doubleValue,
            costAfterDiscount.integerValue
          )
          : fromFirebaseDoubleValue(
            pi.mapValue.fields.cost.doubleValue,
            pi.mapValue.fields.cost.integerValue
          ),
        new PricingItem(
          pi.mapValue.fields.casting.stringValue,
          fromFirebaseStringArrayValue(
            pi.mapValue.fields.audience.arrayValue.values
          ),
          pi.mapValue.fields.component.stringValue,
          pi.mapValue.fields.description.stringValue,
          fromFirebaseDoubleValue(
            pi.mapValue.fields.ce_odq.doubleValue,
            pi.mapValue.fields.ce_odq.integerValue
          ),
          fromFirebaseDoubleValue(
            pi.mapValue.fields.credit_lecture_hours.doubleValue,
            pi.mapValue.fields.credit_lecture_hours.integerValue
          ),
          fromFirebaseDoubleValue(
            pi.mapValue.fields.credit_handson_hours.doubleValue,
            pi.mapValue.fields.credit_handson_hours.integerValue
          ),
          fromFirebaseDoubleValue(
            pi.mapValue.fields.cost.doubleValue,
            pi.mapValue.fields.cost.integerValue
          ),
          pi.mapValue.expiration_date
            ? new Date(pi.mapValue.expiration_date.timestampValue)
            : null,
          pi.mapValue.fields.members_only ? pi.mapValue.fields.members_only.booleanValue : false
        ),
        buildArrayOfStaffMembers(arrStaff.arrayValue)
      )
    );
  }

  return arrPricingItems;
};

// ------------------------------

const buildArrayOfStaffMembers = (arrStaff) => {
  let arrStaffMembers = [];

  for (const staffMember of arrStaff.values) {
    const sm = staffMember.mapValue.fields;

    arrStaffMembers.push(
      new StaffMember(
        sm.id.stringValue,
        sm.license ? sm.license.stringValue : "",
        sm.occupation.stringValue,
        sm.last.stringValue,
        sm.first.stringValue,
        sm.email.stringValue,
        false
      )
    );
  }

  return arrStaffMembers;
};

// ------------------------------

const fetchAndBuildInstallentStatus = async (userId, token, order) => {
  const orderId = firebaseNameToId(order.name);
  const multiPayOption = order.fields.multiPayOption;

  let installmentStatusArray = [];

  if (multiPayOption && multiPayOption.mapValue) {
    const response = await fetch(
      `https://firestore.googleapis.com/v1/projects/${dbName}/databases/(default)/documents/stripe_customers/${userId}/orders/${orderId}/installment`,
      {
        method: "GET",
        headers: {
          "Content-Type": "applications/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );

    if (!response.ok) {
      handleErrorResponse(await response.json());
    }

    const resData = await response.json();

    if (resData.documents) {
      const arrayUnsorted = [];

      for (const installment of resData.documents) {
        arrayUnsorted.push(
          new InstallmentStatus(
            new Date(installment.fields.date.timestampValue),
            new Date(installment.fields.date.timestampValue),
            installment.fields.chargeAmount
              ? installment.fields.chargeAmount.integerValue
              : installment.fields.amount
                ? installment.fields.amount.integerValue
                : 0,
            installment.fields.status.stringValue
          )
        );
      }





      installmentStatusArray = arrayUnsorted.sort((a, b) =>
        new Date(a.date).getTime() > new Date(b.date).getTime() ? 1 : -1
      );
    }
  }

  return installmentStatusArray;
};

// ------------------------------
