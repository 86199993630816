import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import MatInputOutline from "../../../components/ui/input/MatInputOutline";
import ReplyOutlinedIcon from "@material-ui/icons/ReplyOutlined";
import helpImage from "../../../assets/images/inApp/mobile_info.png";

const InputModal = (props) => {
  const [inputText, setInputText] = useState("");
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("xs"));
  const fullWidth = true;
  const maxWidth = "sm";

  // ------------------------------

  const handleClose = (action, inpuText) => {
    props.onClose(action, inpuText);
  };

  // ------------------------------

  const inputChangeHandler = (e) => {
    setInputText(e.target.value);
  };

  // ------------------------------

  const isOkDisabled = () => {
    return !inputText;
  };

  // ------------------------------

  return (
    <div>
      <Dialog
        fullScreen={fullScreen}
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={props.open}
        onClose={handleClose.bind(this, null, null)}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="alert-dialog-title">
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            {props.titleIcon ? props.titleIcon : <ReplyOutlinedIcon />}
            &nbsp;&nbsp;
            {props.title}
          </div>
        </DialogTitle>
        <div
          style={{
            width: "100%",
            padding: "15px",
            display: "flex",
            backgroundColor: "#ccc",
            fontSize: 14,
          }}
        >
          {props.hideInfoIcon ? (
            <img src={helpImage} alt="" style={{ height: "82px" }} />
          ) : (
            <InfoOutlinedIcon />
          )}
          <div
            style={{
              marginLeft: "10px",
              display: "flex",
              alignItems: "center",
            }}
          >
            <div>{props.message}</div>
          </div>
        </div>

        <DialogContent>
          <React.Fragment>
            <div style={{ height: "10px" }}>&nbsp;</div>
            <MatInputOutline
              nomargin="true"
              notopbottommargin="true"
              title={
                props.placeholder ? props.placeholder : "Enter your response"
              }
              name="inputText"
              rows="6"
              multiline
              value={inputText}
              onChange={inputChangeHandler}
              maxLength={1000}
              readOnly={false}
              required
            />
          </React.Fragment>
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            onClick={handleClose.bind(this, null, null)}
            color="primary"
          >
            Cancel
          </Button>
          <Button
            autoFocus
            onClick={handleClose.bind(this, "ok", inputText)}
            color="primary"
            disabled={isOkDisabled()}
            style={
              isOkDisabled()
                ? { color: "gray", backgroundColor: "#ccc" }
                : { color: "white", backgroundColor: "#3E8CAB" }
            }
          >
            {props.okText ? props.okText : "Ok"}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default InputModal;
