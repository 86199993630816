import Professor from "../../model/Professor";
import { dbName } from "../../globals/ApiKeys";
import { handleErrorResponse } from "../../globals/Error";
import firebase from "firebase/app";
import "firebase/storage";
import * as logger from "../../globals/Logger";
export const SET_PROFESSOR_ID = "SET_PROFESSOR_ID";
export const SET_PROFESSOR = "SET_PROFESSOR";
export const SET_PROFESSOR_PICTURE = "SET_PROFESSOR_PICTURE";
export const ADD_PROFESSOR = "ADD_PROFESSOR";
export const UPDATE_PROFESSOR = "UPDATE_PROFESSOR";
export const REMOVE_PROFESSOR = "REMOVE_PROFESSOR";

// ------------------------------

export const setAddAction = () => {
  return {
    type: SET_PROFESSOR_ID,
    professorId: "new",
  };
};

// ------------------------------

export const setEditAction = (id) => {
  return {
    type: SET_PROFESSOR_ID,
    professorId: id,
  };
};

// ------------------------------

export const fetchProfessor = () => {
  return async (dispatch, getState) => {
    const userId = getState().auth.userId;
    const token = getState().auth.token;

    const response = await fetch(
      `https://firestore.googleapis.com/v1/projects/${dbName}/databases/(default)/documents/professor/${userId}/professors`,
      {
        method: "GET",
        headers: {
          "Content-Type": "applications/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );

    if (!response.ok) {
      handleErrorResponse(await response.json());
    }

    const resData = await response.json();
    const professorArr = [];

    if (resData.documents) {
      for (const professor of resData.documents) {
        const profNameArray = professor.name.split("/");
        const profId = profNameArray[profNameArray.length - 1];

        professorArr.push(
          new Professor(
            profId,
            professor.fields.prefix
              ? professor.fields.prefix.stringValue
              : "Dr.",
            professor.fields.first ? professor.fields.first.stringValue : "",
            professor.fields.name.stringValue,
            professor.fields.occupation.stringValue,
            professor.fields.bio.stringValue,
            professor.fields.picture.stringValue
          )
        );
      }
    }

    dispatch({
      type: SET_PROFESSOR,
      professor: professorArr,
    });
  };
};

// ------------------------------

export const addProfessor = (professor, course) => {
  return async (dispatch, getState) => {
    const userId = getState().auth.userId;
    const token = getState().auth.token;

    const response = await fetch(
      `https://firestore.googleapis.com/v1/projects/${dbName}/databases/(default)/documents/professor/${userId}/professors/`,
      {
        method: "POST",
        headers: {
          "Content-Type": "applications/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          fields: {
            prefix: { stringValue: professor.prefix },
            first: { stringValue: professor.first },
            name: { stringValue: professor.name },
            occupation: { stringValue: professor.occupation },
            bio: { stringValue: professor.bio },
            picture: { stringValue: "" },
          },
        }),
      }
    );

    if (!response.ok) {
      handleErrorResponse(await response.json());
    }

    const resData = await response.json();
    const idPathName = resData.name.split("/");
    const profId = idPathName[idPathName.length - 1];

    if (professor.picture && professor.picture.toString().startsWith("blob:")) {
      await dispatch(uploadPic(professor.picture, profId));
    }

    dispatch(setEditAction(profId));

    dispatch({
      type: ADD_PROFESSOR,
      professor: { id: profId, professor: professor },
    });
  };
};

// ------------------------------

export const updateProfessor = (professor) => {
  return async (dispatch, getState) => {
    const userId = getState().auth.userId;
    const token = getState().auth.token;

    await fetch(
      `https://firestore.googleapis.com/v1/projects/${dbName}/databases/(default)/documents/professor/${userId}/professors/${professor.id}` +
        `?updateMask.fieldPaths=prefix&updateMask.fieldPaths=first&updateMask.fieldPaths=name&updateMask.fieldPaths=occupation&updateMask.fieldPaths=bio`,
      {
        method: "PATCH",
        headers: {
          "Content-Type": "applications/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          fields: {
            prefix: { stringValue: professor.prefix },
            first: { stringValue: professor.first },
            name: { stringValue: professor.name },
            occupation: { stringValue: professor.occupation },
            bio: { stringValue: professor.bio },
          },
        }),
      }
    );

    if (professor.picture && professor.picture.toString().startsWith("blob:")) {
      await dispatch(uploadPic(professor.picture, professor.id));
    }

    dispatch({
      type: UPDATE_PROFESSOR,
      professor: professor,
    });
  };
};

// ------------------------------

export const removeProfessor = (profId) => {
  return async (dispatch, getState) => {
    const userId = getState().auth.userId;
    const token = getState().auth.token;

    await fetch(
      `https://firestore.googleapis.com/v1/projects/${dbName}/databases/(default)/documents/professor/${userId}/professors/${profId}`,
      {
        method: "DELETE",
        headers: {
          "Content-Type": "applications/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );

    dispatch(removeProfessorPicture(profId));

    dispatch({
      type: REMOVE_PROFESSOR,
      id: profId,
    });
  };
};

// ---

export const removeProfessorPicture = (profId) => {
  return async (dispatch, getState) => {
    const userId = getState().auth.userId;

    let ref = firebase
      .storage()
      .ref()
      .child(`images/${userId}/professor/${profId}`);

    try {
      await ref.delete();
    } catch (error) {
      // file not found no delete
    }
  };
};

// ------------------------------

export const updateProfesssorPicture = (downloadURL, profId) => {
  return async (dispatch, getState) => {
    const userId = getState().auth.userId;
    const token = getState().auth.token;

    await fetch(
      `https://firestore.googleapis.com/v1/projects/${dbName}/databases/(default)/documents/professor/${userId}/professors/${profId}?updateMask.fieldPaths=picture`,
      {
        method: "PATCH",
        headers: {
          "Content-Type": "applications/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          fields: {
            picture: { stringValue: downloadURL },
          },
        }),
      }
    );

    dispatch({
      type: SET_PROFESSOR_PICTURE,
      id: profId,
      picture: downloadURL,
    });
  };
};

// ------------------------------

export const uploadPic = (uri, professorId) => {
  return async (dispatch, getState) => {
    const userId = getState().auth.userId;

    if (uri && uri !== "undefined") {
      const response = await fetch(uri);
      const blob = await response.blob();
      let ref = firebase
        .storage()
        .ref()
        .child(`images/${userId}/professor/${professorId}`);

      await ref.put(blob).then(function (snap) {
        snap.ref
          .getDownloadURL()
          .then(function (downloadUrl) {
            // ----------
            try {
              dispatch(updateProfesssorPicture(downloadUrl, professorId));
            } catch (err) {
              logger.error(err);
            }
            // ----------
          })
          .catch(function (error) {
            logger.error(error);
          });
      });
    }
  };
};

// ------------------------------
