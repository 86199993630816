class ProviderCoursesMeta {
    constructor(
        documents,
        in_person,
        livestream,
        recorded_video,
        history_documents,
        history_in_person,
        history_livestream,
        history_recorded_video
    ) {
        this.documents = documents;
        this.in_person = in_person;
        this.livestream = livestream;
        this.recorded_video = recorded_video;
        this.history_documents = history_documents;
        this.history_in_person = history_in_person;
        this.history_livestream = history_livestream;
        this.history_recorded_video = history_recorded_video;
    }
}

export default ProviderCoursesMeta;
