import React, { useState } from "react";
import * as styles from "./Increment.module.css";
import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";

const Increment = (props) => {
  const [counter, setCounter] = useState(props.discount ? props.discount : 0);

  const validPrice = (counter) => {
    const price = props.price - props.price * (counter / 100);
    return price === 0 || price > 35;
  };

  const calcValidDecrement = (decStart) => {
    for (let count = decStart; count > 0; count = count - 5) {
      if (validPrice(count)) {
        return count;
      }
    }

    return 0;
  };

  const handleIncrement = () => {
    if (counter < 100) {
      if (validPrice(counter + 5)) {
        setCounter(counter + 5);
        props.onDiscount(counter + 5);
      } else {
        setCounter(100);
        props.onDiscount(100);
      }
    }
  };

  const handleDecrement = () => {
    if (counter > 0) {
      if (validPrice(counter - 5)) {
        setCounter(counter - 5);
        props.onDiscount(counter - 5);
      } else {
        const validDecrement = calcValidDecrement(counter - 5);
        setCounter(validDecrement);
        props.onDiscount(validDecrement);
      }
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.title}>Add Discount</div>
      <ButtonGroup
        size="small"
        aria-label="small outlined button group"
        className={styles.buttonGroup}
      >
        <Button onClick={handleDecrement}>-</Button>
        <Button disabled style={{ color: "black" }}>
          {counter}%
        </Button>
        <Button onClick={handleIncrement}>+</Button>
      </ButtonGroup>

      {counter > 0 ? (
        <div className={styles.price}>
          New Price: {props.currency.shortName}
          {(props.price - props.price * (counter / 100)).toFixed(0)}
        </div>
      ) : (
        <div className={styles.price}>&nbsp;</div>
      )}
    </div>
  );
};

export default Increment;
