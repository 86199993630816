import React, { Fragment } from "react";
import styles from "./Modal.module.css";
import Backdrop from "../backdrop/Backdrop";

const Modal = (props) => {
  let modalType = styles.smallModal;
  if (props.large) {
    modalType = styles.largeModal;
  }

  return (
    <Fragment>
      <Backdrop show={props.show} onClick={props.onClick}></Backdrop>
      <div
        className={modalType}
        style={{
          transform: props.show ? "translateY(0)" : "translateY(+100vh)",
          opacity: props.show ? "1" : "0",
        }}
      >
        {props.children}
      </div>
    </Fragment>
  );
};

export default Modal;
