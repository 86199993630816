// registration details

class CartCourseItem {
  constructor(courseId, title, pricingItems) {
    this.courseId = courseId;
    this.title = title;
    this.pricingItems = pricingItems;
  }

  courseSubTotal = () => {
    let subTotal = 0;
    this.pricingItems.map((pi) => {
      return (subTotal = subTotal + pi.subTotal());
    });
    return subTotal;
  };

  courseViewSubTotal = (providerCurrency, rates) => {
    let subTotal = 0;
    this.pricingItems.map((pi) => {
      return (subTotal = subTotal + pi.viewSubTotal(providerCurrency, rates));
    });
    return subTotal;
  };
}

export default CartCourseItem;
