import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import * as professorActions from "../../store/actions/professor";
import Professor from "../../model/Professor";
import Spinner from "../../components/ui/spinner/Spinner";
import InfoModal from "../../components/modals/infoModal/InfoModal";
import styles from "./Professor.module.css";
import SimpleContainer from "../../components/ui/container/Container";
import validate from "validate.js";
import { constraints, hasFormErrors } from "../../globals/Validations";
import MatInputOutline from "../../components/ui/input/MatInputOutline";
import Button from "@material-ui/core/Button";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import MatAppBar from "../../components/ui/appBar/MatAppBar";
import FormButton from "../../components/ui/button/FormButton";
import { hasError } from "../../globals/Validations";
import ImageInput from "../../components/ui/image/ImageInput";
import ImageCropModal from "../../components/modals/imageCropModal/ImageCropModal";
import AssignmentIndIcon from "@material-ui/icons/AssignmentInd";
import MatSelectOutline from "../../components/ui/labelSelect/MatSelectOutline";
import { selectPrefix } from "../../globals/Data";
import { buildSingleSelect } from "../../globals/Common";
import * as logger from "../../globals/Logger";

class ProfessorEditScreen extends Component {
  state = {
    professor: this.props.professor,
    wait: false,
    isInEdit: true,
    error: null,
    formErrors: {},
    hasErrors: false,
    imageToCrop: null,
    aspectRatio: { aspect: 1 / 1, width: 170, height: 170 },
  };

  // ------------------------------

  update = async (propValue) => {
    await this.setState({ ...this.state, ...propValue });
  };

  // ------------------------------

  componentDidUpdate = (prevProps) => {
    if (prevProps.professor.id !== this.props.professor.id) {
      this.update({
        professor: this.props.professor,
      });
    }
  };

  // ------------------------------

  saveHandler = async () => {
    try {
      await this.validateForm();

      if (this.state.hasErrors) {
        await this.update({
          error: "Please verify details.  Invalid fields.",
        });
        return;
      }

      this.update({ wait: true });

      if (this.props.pId === "new") {
        await this.props.addProfessor(this.state.professor);
      } else if (this.props.pId && this.state.professor.id) {
        await this.props.updateProfessor(this.state.professor);
      }

      this.props.history.goBack();
    } catch (err) {
      logger.error(err);
      this.update({ wait: false, error: err.message });
    }
  };

  // ------------------------------

  validateForm = async () => {
    const e0 = this.state.formErrors;
    const e1 = this.validateField(e0, "first", "plainRequired");
    const e2 = this.validateField(e1, "name", "plainRequired");
    await this.update({ formErrors: e2 });
    await this.hasErrorsHandler(e2);
  };

  // ------------------------------

  validateHandler = async (field, validatorKey) => {
    if (field === null) {
      await this.hasErrorsHandler(this.state.formErrors);
    } else {
      const errors = this.validateField(
        this.state.formErrors,
        field,
        validatorKey
      );
      await this.update({ formErrors: errors }); // await is crutial here
      await this.hasErrorsHandler(errors);
    }
  };

  // ------------------------------

  validateField = (paramFormErrors, field, validatorKey) => {
    let validateObj = {};
    validateObj[validatorKey] = this.state.professor[field];
    const validationResponse = validate(validateObj, constraints);
    const updateFormErrors = { ...paramFormErrors };

    if (validationResponse[validatorKey]) {
      const firstError = validationResponse[validatorKey][0];
      updateFormErrors[field] = firstError;
    } else {
      delete updateFormErrors[field];
    }
    return updateFormErrors;
  };

  // ------------------------------

  hasErrorsHandler = async (formErrors) => {
    await this.update({ hasErrors: hasFormErrors(formErrors, {}) });
  };

  // ------------------------------

  inputChangeHandler = (e) => {
    const newProfessor = { ...this.state.professor };
    newProfessor[e.target.name] = e.target.value;
    this.update({ professor: newProfessor });
  };

  // ------------------------------

  selectChangeHandler = (key, e, select) => {
    const newProfessor = { ...this.state.professor };
    newProfessor[key] = select ? select.value : "";
    this.update({ professor: newProfessor });
  };

  // ------------------------------

  switchToEditHandler = () => {
    this.update({ isInEdit: !this.state.isInEdit });
  };

  render() {
    if (this.props.action !== "PUSH") {
      return <Redirect to="/professor" />;
    }

    if (this.state.wait) {
      return <Spinner />;
    }

    return (
      <div style={{ background: "white" }}>
        <MatAppBar
          static
          left={
            <Button
              color="inherit"
              style={{ color: "black" }}
              onClick={this.props.history.goBack}
              startIcon={<ArrowBackIosIcon />}
            >
              Back
            </Button>
          }
        ></MatAppBar>

        <SimpleContainer disableGutters={false}>
          <InfoModal
            isErrorModal={true}
            title="An error has occured"
            message={this.state.error}
            open={this.state.error !== null}
            onClose={() => {
              this.update({ error: null });
            }}
          />

          {this.state.imageToCrop !== null && (
            <ImageCropModal
              aspect={this.state.aspectRatio}
              title="Select and Crop Picture"
              file={this.state.imageToCrop}
              open={this.state.imageToCrop !== null}
              onClose={(url) => {
                if (url) {
                  const updateProf = { ...this.state.professor };
                  updateProf.picture = url;
                  this.update({ professor: updateProf, imageToCrop: null });
                } else {
                  this.update({ imageToCrop: null });
                }
              }}
            />
          )}

          <div style={{ height: "80px" }}>&nbsp;</div>

          <div className={styles.sectionTitle}>
            <div className={styles.centerRow}>
              <AssignmentIndIcon style={{ color: "white" }} />
              &nbsp;&nbsp;Professor
            </div>
          </div>

          <ImageInput
            header="Photo"
            title="Select Photo"
            image={this.state.professor.picture}
            onImageSelect={(imageUri) => {
              this.update({
                aspectRatio: { aspect: 1 / 1, width: 170, height: 170 },
                imageToCrop: imageUri,
              });
            }}
            readOnly={!this.state.isInEdit}
          />

          <MatSelectOutline
            title="Prefix"
            placeholder="Dr. / Mr. / Ms."
            name="prefix"
            id="prefix"
            onChange={this.selectChangeHandler.bind(this, "prefix")}
            options={selectPrefix}
            value={buildSingleSelect(this.state.professor.prefix)}
            getOptionLabel={(option) => option.label}
            getOptionSelected={(opt1, opt2) => opt1.value === opt2.value}
            disabled={false}
            multiple={false}
            required={false}
          />

          <MatInputOutline
            title="First   "
            name="first"
            id="first"
            value={this.state.professor.first}
            onChange={this.inputChangeHandler}
            onBlur={this.validateHandler.bind(this, "first", "plainRequired")}
            maxLength={100}
            error={hasError(this.state.formErrors.first)}
            readOnly={!this.state.isInEdit}
            required
          />

          <MatInputOutline
            title="Last   "
            name="name"
            id="name"
            value={this.state.professor.name}
            onChange={this.inputChangeHandler}
            onBlur={this.validateHandler.bind(this, "name", "plainRequired")}
            maxLength={100}
            error={hasError(this.state.formErrors.name)}
            readOnly={!this.state.isInEdit}
            required
          />

          <MatInputOutline
            title="Occupation"
            name="occupation"
            value={this.state.professor.occupation}
            onChange={this.inputChangeHandler}
            maxLength={100}
            readOnly={!this.state.isInEdit}
          />

          <MatInputOutline
            title="Biography"
            name="bio"
            value={this.state.professor.bio}
            multiline={true}
            rows={25}
            onChange={this.inputChangeHandler}
            maxLength={2000}
            readOnly={!this.state.isInEdit}
          />

          {this.state.isInEdit && (
            <FormButton
              label="Save"
              onClick={this.saveHandler}
              disabled={!this.state.isInEdit || this.state.hasErrors}
            />
          )}
        </SimpleContainer>

        <div style={{ height: "80px" }}>&nbsp;</div>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  const pId = state.professor.professorId;
  const professorList = state.professor.professor;
  const editProfessor = professorList.find((p) => p.id === pId);

  return {
    action: props.history.action,
    professor: editProfessor ? editProfessor : new Professor(),
    pId: pId,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addProfessor: (prof) => dispatch(professorActions.addProfessor(prof)),
    updateProfessor: (prof) => dispatch(professorActions.updateProfessor(prof)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProfessorEditScreen);
