import React, { Fragment, useState } from "react";
import IncrementSelect from "../increment/IncrementSelect";
import MatSwitch from "../switchInput/MatSwitch";
import Collapse from "@material-ui/core/Collapse";
import CreditCardIcon from "@material-ui/icons/CreditCard";
import {
  initInstallmentPercentLimit,
  initInstallmentLimit,
} from "../../../globals/Data";
import MatInputOutline from "../input/MatInputOutline";
import { roundAmount } from "../../../globals/Common";
import * as styles from "./Installment.module.css";

const Installment = (props) => {
  // ------------------------------
  const [simulatedAmount, setSimulatedAmount] = useState(2000);

  let exampleFirstPaymentValue = roundAmount(
    simulatedAmount * (props.multiPayOption.firstPayPercent / 100)
  );

  const exampleBalanceValue = roundAmount(
    (simulatedAmount - exampleFirstPaymentValue) /
    props.multiPayOption.installmentCount
  );

  const finalCompare =
    exampleFirstPaymentValue +
    props.multiPayOption.installmentCount * Number(exampleBalanceValue);

  if (simulatedAmount - finalCompare > 0) {
    exampleFirstPaymentValue += simulatedAmount - finalCompare;
  }

  exampleFirstPaymentValue = roundAmount(Number(exampleFirstPaymentValue));

  // ------------------------------

  const matSwitchChangeHandler = (event) => {
    const newMultiPayOption = { ...props.multiPayOption };
    newMultiPayOption.enabled = event.target.checked;
    props.updateMultiPayOption(newMultiPayOption);
  };

  // ------------------------------

  const updateHandler = (key, value) => {
    const newMultiPayOption = { ...props.multiPayOption };
    newMultiPayOption[key] = value;
    props.updateMultiPayOption(newMultiPayOption);
  };

  // ------------------------------

  const selectChangeHandler = (key, e, select) => {
    const newMultiPayOption = { ...props.multiPayOption };
    newMultiPayOption[key] = select ? select.value : "";
    props.updateMultiPayOption(newMultiPayOption);
  };

  // ------------------------------

  const inputChangeHandler = (e) => {
    setSimulatedAmount(e.target.value);
  };

  return (
    <div className={styles.container}>
      <MatSwitch
        alwaysfull={true}
        help={
          <Fragment>
            <span className={styles.spanParagraph}>
              Allow students to choose between single payment or multiple
              payments instalments for your course registration at checkout.
            </span>

            <span className={styles.spanParagraph}>
              Set the initial down payment percentage and the number of
              instalments to pay the remaining balance.
            </span>
            <span className={styles.spanParagraph}>
              Example:
              <br />
              Initial down payment 30%
              <br />
              Remaining balance paid in 3 equal payments.
            </span>
            <span className={styles.spanParagraph}>
              Please read the terms and conditions for multiple payment
              instalments eligibility.
            </span>
          </Fragment>
        }
        title="Allow multiple payment instalments if applicable (optional)"
        id={"enabled"}
        name={"enabled"}
        checked={props.multiPayOption.enabled}
        onChange={matSwitchChangeHandler}
      />

      <Collapse in={props.multiPayOption.enabled}>
        <Fragment>
          <div className={styles.boxContainer}>
            <IncrementSelect
              fullWidth={true}
              initialValue={5}
              maxValue={95}
              minValue={30}
              interval={5}
              title="First Payment (minimum 30%)"
              name="firstPayPercent"
              id="firstPayPercent"
              disableClearable={true}
              getOptionLabel={(option) => option.label}
              getOptionSelected={(opt1, opt2) => opt1.value === opt2.value}
              selectValue={props.multiPayOption.firstPayPercent}
              options={initInstallmentPercentLimit(30, 0, 5)}
              value={{
                value: props.multiPayOption.firstPayPercent + "",
                label: props.multiPayOption.firstPayPercent + "",
              }}
              onUpdate={updateHandler.bind(this, "firstPayPercent")}
              onChange={selectChangeHandler.bind(this, "firstPayPercent")}
            />

            <IncrementSelect
              fullWidth={true}
              initialValue={3}
              maxValue={3}
              minValue={1}
              interval={1}
              title="Balance Split (1 to 3 equal installments)"
              name="installmentCount"
              id="installmentCount"
              disableClearable={true}
              getOptionLabel={(option) => option.label}
              getOptionSelected={(opt1, opt2) => opt1.value === opt2.value}
              selectValue={props.multiPayOption.installmentCount}
              options={initInstallmentLimit()}
              value={{
                value: props.multiPayOption.installmentCount + "",
                label: props.multiPayOption.installmentCount + "",
              }}
              onUpdate={updateHandler.bind(this, "installmentCount")}
              onChange={selectChangeHandler.bind(this, "installmentCount")}
            />

            <div>
              <div className={styles.simulatorTitle}>
                Simulate payment instalments with our calculator
              </div>

              <div className={styles.simulatorResult}>
                <div style={{ width: "49%", paddingTop: "10px" }}>
                  <MatInputOutline
                    currency={props.currency}
                    notopbottommargin="true"
                    title=""
                    name="simulatedAmount"
                    value={simulatedAmount}
                    onChange={inputChangeHandler}
                    maxLength={10}
                    readOnly={false}
                    required={false}
                  />
                </div>

                {simulatedAmount >= 2000 ? (
                  <Fragment>
                    <div>
                      First payment of{"  "}
                      <b>
                        {props.currency.shortName}
                        {exampleFirstPaymentValue}
                      </b>
                    </div>
                    <div>
                      Balance{" "}
                      {props.multiPayOption.installmentCount > 1
                        ? "Split in "
                        : ""}
                      <b>{props.multiPayOption.installmentCount}</b>{" "}
                      {props.multiPayOption.installmentCount > 1
                        ? "equal payments "
                        : "payment "}
                      of{" "}
                      <b>
                        {props.currency.shortName}
                        {exampleBalanceValue}
                      </b>
                    </div>
                  </Fragment>
                ) : (
                  <div style={{ color: "red" }}>
                    Not applicable to courses less than{" "}
                    <b>
                      {props.currency.shortName}
                      2000
                    </b>
                  </div>
                )}
              </div>
            </div>
          </div>

          <div>
            <div className={styles.installmentsTitle}>
              <CreditCardIcon />
              &nbsp;&nbsp;Multiple Payment Instalments
            </div>
            <div className={styles.installmentsDesc}>
              Multiple payment instalments are available on courses priced over{" "}
              {props.currency.shortName}2000 with a Classroom or Livestream
              registration option. Single or multiple payment instalments are
              optional and can be selected by the student at checkout. If a
              student registers within 30 days of the course start date the
              instalments option will not be presented and a one time single
              full payment will be required in order to register for the course.
              A minimum of 30% is required on the first payment.
            </div>
          </div>
        </Fragment>
      </Collapse>
    </div>
  );
};

export default Installment;
