import React from "react";
import styles from "./Rating.module.css";
import { useTranslation } from "react-i18next";
import GradeIcon from "@material-ui/icons/Grade";
import RatingBar from "./RatingBar";

const buildStars = (starCount) => {
  const arr = [];
  for (let i = 1; i <= starCount; i++) {
    arr.push(
      <GradeIcon key={i} style={{ color: "orange" }} fontSize="inherit" />
    );
  }
  return arr;
};

const arrStarFive = buildStars(5);
const arrStarFour = buildStars(4);
const arrStarThree = buildStars(3);
const arrStarTwo = buildStars(2);
const arrStarOne = buildStars(1);

const RatingDistribution = (props) => {
  const { t } = useTranslation();

  return (
    <div
      className={
        props.full
          ? styles.distributionContainerFull
          : styles.distributionContainer
      }
    >
      <div className={styles.subContainer}>
        <div className={styles.ratingNumber}>
          <div className={styles.textLarge}>{props.rating}</div>
          <div className={styles.textSmall}>&nbsp;{t("g_out_of")} 5&nbsp;</div>
        </div>

        <div className={styles.ratingProgress}>
          <div className={styles.row}>
            <RatingBar value={(props.five / Number(props.ratingCount)) * 100} />
            &nbsp;
            <div className={styles.row}>{arrStarFive}</div>
          </div>
          <div className={styles.row}>
            <RatingBar value={(props.four / Number(props.ratingCount)) * 100} />
            &nbsp;
            <div className={styles.row}>{arrStarFour}</div>
          </div>
          <div className={styles.row}>
            <RatingBar
              value={(props.three / Number(props.ratingCount)) * 100}
            />
            &nbsp;
            <div className={styles.row}>{arrStarThree}</div>
          </div>
          <div className={styles.row}>
            <RatingBar value={(props.two / Number(props.ratingCount)) * 100} />
            &nbsp;
            <div className={styles.row}>{arrStarTwo}</div>
          </div>
          <div className={styles.row}>
            <RatingBar value={(props.one / Number(props.ratingCount)) * 100} />
            &nbsp;
            <div className={styles.row}>{arrStarOne}</div>
          </div>
        </div>
      </div>

      <div>
        <div className={styles.textSmall}>
          Total {t("sign_up_of")} {props.ratingCount}&nbsp;
          {props.ratingCount === 1 ? t("g_review") : t("g_reviews")}
        </div>
      </div>
    </div>
  );
};

export default RatingDistribution;
