import React from "react";
import styles from "./ProfessorItem.module.css";
import Image from "../../ui/image/Image";
import EditIcon from "@material-ui/icons/Edit";
import PopMenu from "../../../components/ui/popMenu/PopMenu";
import MenuItem from "@material-ui/core/MenuItem";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";

const ProfessorItem = (props) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  let renderOccupation = "";
  if (props.occupation) {
    renderOccupation = props.occupation;
  }

  return (
    <div className={styles.itemContainer}>
      <Image image={props.image} />

      <div className={styles.bio}>
        <div>
          <div className={styles.name}>{props.name}</div>
          <div className={styles.occupation}>{renderOccupation}</div>
        </div>

        <p>{props.bio}</p>
      </div>

      <div className={styles.nameAndReadFullContainer}>
        <div className={styles.readMore}>
          <PopMenu
            anchorEl={anchorEl}
            handleClick={handleClick}
            handleClose={handleClose}
          >
            <MenuItem
              onClick={() => {
                handleClose();
                props.edit();
              }}
            >
              <div className={styles.simpleRow}>
                <EditIcon />
                &nbsp;Edit
              </div>
            </MenuItem>

            <MenuItem
              onClick={() => {
                handleClose();
                props.delete();
              }}
            >
              <div className={styles.simpleRow}>
                <DeleteOutlineIcon />
                &nbsp;Delete
              </div>
            </MenuItem>
          </PopMenu>
        </div>
      </div>
    </div>
  );
};

export default ProfessorItem;
