import { handleErrorResponse } from "../../globals/Error";
import AlertNotification from "../../model/AlertNotification";
import { dbName } from "../../globals/ApiKeys";
export const SET_ALERT = "SET_ALERT";
export const REMOVE_ALERT = "REMOVE_ALERT";

// ------------------------------

export const fetchAlert = () => {
  return async (dispatch, getState) => {
    const userId = getState().auth.userId;
    const token = getState().auth.token;

    const response = await fetch(
      `https://firestore.googleapis.com/v1/projects/${dbName}/databases/(default)/documents/alert/${userId}/alerts`,
      {
        method: "GET",
        headers: {
          "Content-Type": "applications/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );

    if (!response.ok) {
      handleErrorResponse(await response.json());
    }

    const resData = await response.json();

    let arrDocs = [];
    if (Array.isArray(resData)) {
      arrDocs = resData;
    } else {
      arrDocs = resData.documents;
    }

    const loadedAlerts = [];

    if (arrDocs) {
      for (const alert of arrDocs) {
        if (alert && alert.name) {
          const alertNameArray = alert.name.split("/");
          const alertId = alertNameArray[alertNameArray.length - 1];
          loadedAlerts.push(
            new AlertNotification(
              alertId,
              alert.fields.description.stringValue,
              alert.fields.date
                ? new Date(alert.fields.date.timestampValue)
                : null,
              alert.fields.link ? alert.fields.link.stringValue : null
            )
          );
        }
      }
    }

    dispatch({
      type: SET_ALERT,
      alert: loadedAlerts,
    });
  };
};

// ------------------------------

export const removeAlert = (alertId) => {
  return async (dispatch, getState) => {
    const userId = getState().auth.userId;
    const token = getState().auth.token;

    await fetch(
      `https://firestore.googleapis.com/v1/projects/${dbName}/databases/(default)/documents/alert/${userId}/alerts/${alertId}`,
      {
        method: "DELETE",
        headers: {
          "Content-Type": "applications/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );

    dispatch({
      type: REMOVE_ALERT,
      id: alertId,
    });
  };
};

// ------------------------------
