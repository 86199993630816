import firebase from "firebase/app";
import "firebase/analytics";
import * as Sentry from "@sentry/react";
const prefix = "admin_";
// ------------------------------

export const error = (error) => {
  if (isProd()) {
    Sentry.captureException(error);
  } else {
    console.log(error);
  }
};

// ------------------------------

export const info = (source, message) => {
  if (isProd()) {
    Sentry.captureMessage(source + " : " + JSON.stringify(message));
  } else {
    console.log(source + " : " + JSON.stringify(message));
  }
};

// ------------------------------

export const event = (eventName, properties) => {
  firebase.analytics().logEvent(prefix + eventName, properties);
};

// ------------------------------

export const isProd = () => {
  return process.env.NODE_ENV === "production";
};

// ------------------------------
