import React, { Fragment, useState } from "react";
import styles from "./SurveyModal.module.css";
import Button from "@material-ui/core/Button";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import MatSelectOutline from "../../ui/labelSelect/MatSelectOutline";
import { buildKeyValueSelect } from "../../../globals/Common";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import InlinePop from "../../ui/popover/InlinePop";
import PageviewIcon from "@material-ui/icons/Pageview";
import SurveyPreviewModal from "./SurveyPreviewModal";
import CertPreviewModal from "../certPreviewModal/CertPreviewModal";

const AttachSurveyModal = (props) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("xs"));
  const fullWidth = true;
  const maxWidth = "sm";
  const title = "Update Course: Survey and Certificate of Completion";

  const [showSurveyPreviewModal, setShowSurveyPreviewModal] = useState(false);

  const [value, setValue] = useState(
    props.selected_survey
      ? {
          value: props.selected_survey.id,
          label: props.selected_survey.title,
        }
      : null
  );

  const [survey, setSurvey] = useState(
    props.selected_survey ? props.selected_survey : null
  );

  const [showCertPreviewModal, setShowCertPreviewModal] = useState(false);

  const [certValue, setCertValue] = useState(
    props.selected_certificate
      ? {
          value: props.selected_certificate.id,
          label: props.selected_certificate.title,
        }
      : null
  );

  const [certificate, setCertificate] = useState(
    props.selected_certificate
      ? props.certificateList.find(
          (s) => s.id === props.selected_certificate.id
        )
      : null
  );

  // ------------------------------

  const handleClose = (survey, certificate, courseId) => {
    props.onClose(survey, certificate, courseId);
  };

  // ------------------------------

  const surveyChangeHandler = (key, e, select) => {
    const selectedValue = select ? select.value : null;
    const survey = props.surveyList.find((s) => s.id === selectedValue);

    setValue(
      survey
        ? {
            value: survey.id,
            label: survey.title,
          }
        : null
    );

    setSurvey(survey);
  };

  // ------------------------------

  const certChangeHandler = (key, e, select) => {
    const selectedValue = select ? select.value : null;
    const cert = props.certificateList.find((s) => s.id === selectedValue);

    setCertValue(
      cert
        ? {
            value: cert.id,
            label: cert.title,
          }
        : null
    );

    setCertificate(cert);
  };

  // ------------------------------

  return (
    <div>
      {showSurveyPreviewModal && survey && (
        <SurveyPreviewModal
          open={showSurveyPreviewModal}
          surveyJson={survey.text}
          onClose={() => {
            setShowSurveyPreviewModal(false);
          }}
        />
      )}

      {showCertPreviewModal && props.profile && props.course && certificate && (
        <CertPreviewModal
          open={showCertPreviewModal}
          course={props.course}
          profile={props.profile}
          header={certificate.header}
          footer={certificate.footer}
          onClose={() => {
            setShowCertPreviewModal(false);
          }}
        />
      )}

      <Dialog
        fullScreen={fullScreen}
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={props.open}
        onClose={handleClose.bind(this, null, null, null)}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="alert-dialog-title">{title}</DialogTitle>

        <DialogContent>
          <div className={styles.container}>
            <InlinePop
              style={{ width: "100%" }}
              help={
                <Fragment>
                  <span className={styles.spanParagraph}>
                    Surveys are a great way to assess a student's understanding
                    and satisfaction on the content of your course. Use our
                    survey builder from the main menu to create a survey and
                    attach it to your course.
                  </span>
                  <span className={styles.spanParagraph}>
                    Surveys are sent automatically 48 hours after the course
                    date or 48 hours after a video course registration. &nbsp;
                  </span>
                  <span className={styles.spanParagraph}>
                    Surveys can be used as a student prerequisite to obtain a
                    certificate of completion. To enable this go to{" "}
                    <b>
                      Settings <ArrowForwardIcon fontSize="inherit" />{" "}
                      Certificate <ArrowForwardIcon fontSize="inherit" />{" "}
                      Delivery Options
                    </b>
                  </span>
                </Fragment>
              }
            />

            <MatSelectOutline
              double="true"
              noTopBottomMargin
              title="Select Survey"
              placeholder="Enter Survey Title..."
              name="survey"
              id="survey"
              onChange={surveyChangeHandler.bind(this, "survey")}
              options={buildKeyValueSelect(props.surveyList, "id", "title")}
              value={value}
              getOptionLabel={(option) => option.label}
              getOptionSelected={(opt1, opt2) => opt1.value === opt2.value}
              multiple={false}
              required={false}
            />

            <Button
              variant="outlined"
              startIcon={<PageviewIcon />}
              disabled={!survey}
              onClick={() => {
                setShowSurveyPreviewModal(true);
              }}
              style={
                survey ? { borderColor: "#3E8CAB" } : { borderColor: "#ccc" }
              }
            >
              Preview
            </Button>

            <InlinePop
              style={{ width: "100%" }}
              help={
                <Fragment>
                  <span className={styles.spanParagraph}>
                    Certificates are sent automatically after the completion of
                    the course survey. If no survey is selected they are sent 48
                    hours after the course date or 48 hours after a video course
                    registration.
                  </span>
                  <span className={styles.spanParagraph}>
                    No certificates in your list? Click <b>Certificate</b> from
                    the main menu. Create a template and attach it to your
                    course.
                  </span>
                </Fragment>
              }
            />

            <MatSelectOutline
              double="true"
              noTopBottomMargin
              title="Select Certificate"
              placeholder="Enter Certificate Title..."
              name="certificate"
              id="certificate"
              onChange={certChangeHandler.bind(this, "certificate")}
              options={buildKeyValueSelect(
                props.certificateList,
                "id",
                "title"
              )}
              value={certValue}
              getOptionLabel={(option) => option.label}
              getOptionSelected={(opt1, opt2) => opt1.value === opt2.value}
              multiple={false}
              required={false}
            />

            <Button
              variant="outlined"
              startIcon={<PageviewIcon />}
              disabled={!certificate}
              onClick={() => {
                setShowCertPreviewModal(true);
              }}
              style={
                certificate
                  ? { borderColor: "#3E8CAB" }
                  : { borderColor: "#ccc" }
              }
            >
              Preview
            </Button>
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleClose.bind(this, null, null, null)}
            color="primary"
          >
            Cancel
          </Button>
          <Button
            autoFocus
            onClick={handleClose.bind(
              this,
              survey,
              certificate,
              props.course.id
            )}
            color="primary"
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default AttachSurveyModal;
