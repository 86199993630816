import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Popover from "@material-ui/core/Popover";
import Typography from "@material-ui/core/Typography";
import InfoIcon from "@material-ui/icons/Info";

const useStyles = makeStyles((theme) => ({
  typography: {
    padding: theme.spacing(2),
  },
}));

const Pop = (props) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <div style={props.style ? props.style : { cursor: "pointer" }}>
      {props.privateIcon ? props.privateIcon : null}
      <InfoIcon
        style={{
          fontSize: "17px",
          padding: "0px",
          alignItems: "center",
          color: "#838383",
        }}
        aria-describedby={id}
        onClick={handleClick}
      ></InfoIcon>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        onClose={handleClose}
      >
        <Typography className={classes.typography}>{props.help}</Typography>
      </Popover>
    </div>
  );
};

export default Pop;
