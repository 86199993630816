class Profile {
  constructor(
    id,
    providerName,
    country,
    contact_email,
    cancel_policy,
    logo,
    digitalSignature,
    currency,
    state, // only used for canada
    storefront,
    about_en,
    about_fr
  ) {
    this.id = id;
    this.providerName = providerName;
    this.country = country;
    this.contact_email = contact_email;
    this.cancel_policy = cancel_policy;
    this.logo = logo;
    this.digitalSignature = digitalSignature;
    this.currency = currency;
    this.state = state;
    this.storefront = storefront;
    this.about_en = about_en;
    this.about_fr = about_fr;
  }
}

export default Profile;
