import React from "react";
import styles from "./AddImageSelector.module.css";
import img0 from "../../../assets/images/advertise/0.png";
import img1 from "../../../assets/images/advertise/1.png";
import img2 from "../../../assets/images/advertise/2.png";
import img3 from "../../../assets/images/advertise/3.png";
import img4 from "../../../assets/images/advertise/4.png";
import img5 from "../../../assets/images/advertise/5.png";
import img6 from "../../../assets/images/advertise/6.png";
import img7 from "../../../assets/images/advertise/7.png";
import img8 from "../../../assets/images/advertise/8.png";
import img9 from "../../../assets/images/advertise/9.png";
import img10 from "../../../assets/images/advertise/10.png";
import img11 from "../../../assets/images/advertise/11.png";
import img12 from "../../../assets/images/advertise/12.png";
import img13 from "../../../assets/images/advertise/13.png";
import img14 from "../../../assets/images/advertise/14.png";
import img15 from "../../../assets/images/advertise/15.png";
import img16 from "../../../assets/images/advertise/16.png";
import img17 from "../../../assets/images/advertise/17.png";
import img18 from "../../../assets/images/advertise/18.png";
import img19 from "../../../assets/images/advertise/19.png";
import img20 from "../../../assets/images/advertise/20.png";
import img21 from "../../../assets/images/advertise/21.png";
import img22 from "../../../assets/images/advertise/22.png";
import img23 from "../../../assets/images/advertise/23.png";
import img24 from "../../../assets/images/advertise/24.png";
import img25 from "../../../assets/images/advertise/25.png";
import img26 from "../../../assets/images/advertise/26.png";
import img27 from "../../../assets/images/advertise/27.png";
import img28 from "../../../assets/images/advertise/28.png";
import img29 from "../../../assets/images/advertise/29.png";
import img30 from "../../../assets/images/advertise/30.png";
import img31 from "../../../assets/images/advertise/31.png";
import img32 from "../../../assets/images/advertise/32.png";
import img33 from "../../../assets/images/advertise/33.png";
import img34 from "../../../assets/images/advertise/34.png";
import img35 from "../../../assets/images/advertise/35.png";
import img36 from "../../../assets/images/advertise/36.png";
import img37 from "../../../assets/images/advertise/37.png";
import img38 from "../../../assets/images/advertise/38.png";
import img39 from "../../../assets/images/advertise/39.png";
import img40 from "../../../assets/images/advertise/40.png";
import img41 from "../../../assets/images/advertise/41.png";
import img42 from "../../../assets/images/advertise/42.png";
import img43 from "../../../assets/images/advertise/43.png";
import img44 from "../../../assets/images/advertise/44.png";
import img45 from "../../../assets/images/advertise/45.jpg";
import img46 from "../../../assets/images/advertise/46.jpg";
import img47 from "../../../assets/images/advertise/47.jpg";

export const imageSelector = (slide) => {
  switch (slide) {
    case 0:
      return img0;
    case 1:
      return img1;
    case 2:
      return img2;
    case 3:
      return img3;
    case 4:
      return img4;
    case 5:
      return img5;
    case 6:
      return img6;
    case 7:
      return img7;
    case 8:
      return img8;
    case 9:
      return img9;
    case 10:
      return img10;
    case 11:
      return img11;
    case 12:
      return img12;
    case 13:
      return img13;
    case 14:
      return img14;
    case 15:
      return img15;
    case 16:
      return img16;
    case 17:
      return img17;
    case 18:
      return img18;
    case 19:
      return img19;
    case 20:
      return img20;
    case 21:
      return img21;
    case 22:
      return img22;
    case 23:
      return img23;
    case 24:
      return img24;
    case 25:
      return img25;
    case 26:
      return img26;
    case 27:
      return img27;
    case 28:
      return img28;
    case 29:
      return img29;
    case 30:
      return img30;
    case 31:
      return img31;
    case 32:
      return img32;
    case 33:
      return img33;
    case 34:
      return img34;
    case 35:
      return img35;
    case 36:
      return img36;
    case 37:
      return img37;
    case 38:
      return img38;
    case 39:
      return img39;
    case 40:
      return img40;
    case 41:
      return img41;
    case 42:
      return img42;
    case 43:
      return img43;
    case 44:
      return img44;
    case 45:
      return img45;
    case 46:
      return img46;
    case 47:
      return img47;
    default:
      return img0;
  }
};

const AddImageSelector = (props) => {
  return (
    <div>
      <div
        className={styles.bannerImage}
        style={{
          backgroundImage: `url(${imageSelector(props.slide)})`,
        }}
      >
        {props.children}
      </div>
    </div>
  );
};

export default AddImageSelector;
