import { handleErrorResponse } from "../../globals/Error";
import Membership from "../../model/Membership";
import MobileMembership from "../../model/MobileMembership";
import { dbName } from "../../globals/ApiKeys";
import { firebaseNameToId } from "../../globals/Common";
export const SET_MEMBERS = "SET_MEMBERS";
export const SET_MEMBERSHIPS = "SET_MEMBERSHIPS"
export const REMOVE_MEMBERSHIP = "REMOVE_MEMBERSHIP";

// ------------------------------

export const fetchMemberships = () => {
    return async (dispatch, getState) => {
        const userId = getState().auth.userId;
        const token = getState().auth.token;

        if (!token) {
            return dispatch({ type: SET_MEMBERSHIPS, memberships: [] });
        }

        const response = await fetch(
            `https://firestore.googleapis.com/v1/projects/${dbName}/databases/(default)/documents/memberships/${userId}:runQuery`,
            {
                method: "POST",
                headers: {
                    "Content-Type": "applications/json",
                    Authorization: `Bearer ${token}`,
                },
                body: JSON.stringify({
                    structuredQuery: {
                        from: [{ collectionId: "membership" }],
                    },
                }),
            }
        );

        if (!response.ok) {
            handleErrorResponse(await response.json());
        }

        const resData = await response.json();

        const loaded = [];
        for (const documents of resData) {
            const doc = documents.document;

            if (doc && doc.name) {
                loaded.push(
                    new MobileMembership(
                        firebaseNameToId(doc.name),
                        firebaseNameToId(doc.name),
                        doc.fields.provider_name.stringValue,
                        doc.fields.logo ? doc.fields.logo.stringValue : "",
                        doc.fields.member_id.stringValue
                    )
                );
            }
        }


        dispatch({
            type: SET_MEMBERSHIPS,
            memberships: loaded
        });
    };
};

// ------------------------------

export const fetchMembers = () => {
    return async (dispatch, getState) => {
        const userId = getState().auth.userId;
        const token = getState().auth.token;

        const response = await fetch(
            `https://firestore.googleapis.com/v1/projects/${dbName}/databases/(default)/documents/memberships/${userId}:runQuery`,
            {
                method: "POST",
                headers: {
                    "Content-Type": "applications/json",
                    Authorization: `Bearer ${token}`,
                },
                body: JSON.stringify({
                    structuredQuery: {
                        from: [{ collectionId: "members" }],
                    },
                }),
            }
        );

        if (!response.ok) {
            handleErrorResponse(await response.json());
        }

        const resData = await response.json();

        const loaded = [];
        for (const documents of resData) {
            const doc = documents.document;

            if (doc && doc.name) {
                loaded.push(
                    new Membership(
                        firebaseNameToId(doc.name),
                        doc.fields.member_id.stringValue
                    )
                );
            }
        }

        dispatch({
            type: SET_MEMBERS,
            members: loaded
        });
    };
};

// ------------------------------

export const validateMembership = (provider, memberID) => {
    return async (dispatch, getState) => {
        const token = getState().auth.token;

        const response = await fetch(
            `https://us-central1-${dbName}.cloudfunctions.net/validateMembership`,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
                body: JSON.stringify({
                    data: { provider: provider, memberID: memberID },
                }),
            }
        );

        if (!response.ok) {
            handleErrorResponse(await response.json());
        }


        dispatch(fetchMemberships());
    };
};

// ------------------------------


export const removeMembership = (id) => {
    return async (dispatch, getState) => {
        const userId = getState().auth.userId;
        const token = getState().auth.token;

        const response = await fetch(
            `https://firestore.googleapis.com/v1/projects/${dbName}/databases/(default)/documents/memberships/${userId}/membership/${id}`,
            {
                method: "DELETE",
                headers: {
                    "Content-Type": "applications/json",
                    Authorization: `Bearer ${token}`,
                },
            }
        );

        if (!response.ok) {
            handleErrorResponse(await response.json());
        }

        dispatch({
            type: REMOVE_MEMBERSHIP,
            id: id,
        });
    };
};
