import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import PricingItem from "../../../model/PricingItem";
import MatInputOutline from "../../../components/ui/input/MatInputOutline";
import MatSelectOutline from "../../../components/ui/labelSelect/MatSelectOutline";
import DateSelect from "../../../components/ui/dateSelect/DateSelect";
import { buildCategory, buildSingleSelect } from "../../../globals/Common";
import MatCheckbox from "../../../components/ui/checkbox/MatCheckbox";
import {
  FNS_MEDIUM_DATE_FORMAT,
  addDays,
  MAX_COURSE_DATE_LIMIT,
} from "../../../globals/Dates";
import InlinePop from "../../../components/ui/popover/InlinePop";
import { makeStyles } from "@material-ui/core/styles";
import {
  selectAudience,
  selectSpecialistAudience,
  selectHours,
  selectOdq,
  selectCasting,
  selectCastingVideo,
  selectComponent,
} from "../../../globals/Data";

const useStyles = makeStyles((theme) => ({
  doubledSmall: {
    display: "flex",
    flexDirection: "row",
    width: "60%",
    margin: "auto",
    padding: "0px",
    justifyContent: "space-between",
  },
  doubled: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    padding: "0px",
    justifyContent: "space-between",
  },
}));

const CoursePriceModal = (props) => {
  const matches = useMediaQuery("(max-width:499px)");
  const classes = useStyles();
  const classDouble = matches ? classes.doubled : classes.doubledSmall;

  const fullScreen = false;
  const fullWidth = true;
  const maxWidth = "md";

  const [touched, setTouched] = useState(false);

  const [pricingItem, setPricingItem] = useState({
    casting: props.selectedItem ? props.selectedItem.casting : "",
    audience: props.selectedItem ? props.selectedItem.audience : [],
    component: props.selectedItem ? props.selectedItem.component : "",
    description: props.selectedItem ? props.selectedItem.description : "",
    ce_odq: props.selectedItem ? props.selectedItem.ce_odq : -1,
    credit_lecture_hours: props.selectedItem
      ? props.selectedItem.credit_lecture_hours
      : 0,
    credit_handson_hours: props.selectedItem
      ? props.selectedItem.credit_handson_hours
      : 0,
    cost: props.selectedItem ? props.selectedItem.cost : -1,
    expiration_date: props.selectedItem
      ? props.selectedItem.expiration_date
      : new Date(),
    members_only: props.selectedItem
      ? props.selectedItem.members_only
      : false,
  });

  // if pricingItem changes udpate internal state
  useEffect(() => {
    setPricingItem({
      casting: props.selectedItem ? props.selectedItem.casting : "",
      audience: props.selectedItem ? props.selectedItem.audience : [],
      component: props.selectedItem ? props.selectedItem.component : "",
      description: props.selectedItem ? props.selectedItem.description : "",
      ce_odq: props.selectedItem ? props.selectedItem.ce_odq : -1,
      credit_lecture_hours: props.selectedItem
        ? props.selectedItem.credit_lecture_hours
        : 0,
      credit_handson_hours: props.selectedItem
        ? props.selectedItem.credit_handson_hours
        : 0,
      cost: props.selectedItem ? props.selectedItem.cost : 0,
      expiration_date: props.selectedItem
        ? props.selectedItem.expiration_date
        : new Date(),
      members_only: props.selectedItem
        ? props.selectedItem.members_only
        : false,
    });
  }, [props.selectedItem]);

  const handleClose = (pricingItem) => {
    props.close(
      pricingItem
        ? new PricingItem(
          pricingItem.casting,
          pricingItem.audience,
          pricingItem.component,
          pricingItem.description,
          Number(pricingItem.ce_odq),
          Number(pricingItem.credit_lecture_hours),
          Number(pricingItem.credit_handson_hours),
          Number(pricingItem.cost),
          pricingItem.expiration_date,
          pricingItem.members_only
        )
        : pricingItem
    );
  };

  const [openToDate, setOpenToDate] = useState(
    pricingItem.expiration_date &&
      props.courseStartDate &&
      pricingItem.expiration_date.getTime() <
      new Date(props.courseStartDate).getTime()
      ? new Date(props.courseStartDate)
      : pricingItem.expiration_date
        ? pricingItem.expiration_date
        : new Date()
  );

  // ------------------------------

  const selectChangeHandler = (key, e, select) => {
    const newState = { ...pricingItem };
    newState[key] = select ? select.value : "";
    setPricingItem(newState);
  };

  // ------------------------------

  const inputChangeHandler = (e) => {
    const newState = { ...pricingItem };
    newState[e.target.name] = e.target.value;
    setPricingItem(newState);
  };

  // ------------------------------

  const multiSelectChangeHandler = (targetName, e, selects) => {
    const newState = { ...pricingItem };
    const arrValues = [];

    for (const select of selects) {
      arrValues.push(select.value);
    }

    newState[targetName] = arrValues;
    setPricingItem(newState);
  };

  // ------------------------------

  const priceInvalid = () => {
    return pricingItem.cost >= 1 && pricingItem.cost < 25;
  };

  // ------------------------------

  const checkChangeHandler = (key, e, selected) => {
    const newState = { ...pricingItem };
    newState[key] = selected;
    setPricingItem(newState);
  };

  // ------------------------------

  return (
    <div>
      <Dialog
        fullScreen={fullScreen}
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={props.open}
        onClose={handleClose.bind(this, null)}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="alert-dialog-title">
          {"Student Registration Option"}
        </DialogTitle>

        <DialogContent >
          <div className={classDouble}
            style={{
              border: "1px solid #ccc",
              borderRadius: "5px",
              padding: "5px",
              flexDirection: "column",
              marginBottom: "15px",
              padding: "10px"
            }}>
            <MatCheckbox
              color="success"
              plainright
              name="members_only"
              checked={pricingItem.members_only ? pricingItem.members_only : false}
              label="Is this registration option available to members only?"
              onChange={checkChangeHandler.bind(this, "members_only")}
            />
            <div style={{ fontStyle: "italic", fontSize: "small" }}>
              By checking this box, registrants will be prompted to enter your membership ID when selecting the option on the course listing.  At this time, ZubU does not validate the membership ID but will collect and display it on your course registration list. It is the course providers responsibility to verify the registrant is a valid member.
            </div>
          </div>

          <InlinePop
            help={
              "Description is optional can be used to give more context to the registration option.  Ex: Early bird special"
            }
          />

          <MatInputOutline
            notopbottommargin="true"
            title="Description"
            name="description"
            id="description"
            placeholder=""
            rows="1"
            value={pricingItem.description}
            onChange={inputChangeHandler}
            maxLength={50}
            required={false}
          />

          <div className={classDouble}>
            <div style={{ width: "49%", padding: "0px" }}>
              <MatSelectOutline
                noTopBottomMargin2
                double="true"
                title="Setting"
                placeholder="Enter Setting..."
                name="casting"
                id="casting"
                disableClearable={true}
                onChange={selectChangeHandler.bind(this, "casting")}
                options={props.is_video ? selectCastingVideo : selectCasting}
                value={buildSingleSelect(pricingItem.casting)}
                getOptionLabel={(option) => option.label}
                getOptionSelected={(opt1, opt2) => opt1.value === opt2.value}
                disabled={false}
                multiple={false}
                required={true}
              />
            </div>
            <div style={{ width: "49%", padding: "0px" }}>
              <MatSelectOutline
                noTopBottomMargin2
                double="true"
                title="Component"
                placeholder="Enter Component..."
                name="component"
                id="component"
                disableClearable={true}
                onChange={selectChangeHandler.bind(this, "component")}
                options={selectComponent}
                value={buildSingleSelect(pricingItem.component)}
                getOptionLabel={(option) => option.label}
                getOptionSelected={(opt1, opt2) => opt1.value === opt2.value}
                disabled={false}
                multiple={false}
                required={true}
              />
            </div>
          </div>

          <InlinePop help="Dentist, Hygienist, Others (Assistants, Receptionists, etc)" />
          <MatSelectOutline
            noTopMargin
            title="Audience"
            placeholder="Enter audience..."
            name="audience"
            id="audience"
            onChange={multiSelectChangeHandler.bind(this, "audience")}
            options={
              props.is_specialty ? selectSpecialistAudience : selectAudience
            }
            value={buildCategory(pricingItem.audience)}
            getOptionLabel={(option) => option.label}
            getOptionSelected={(opt1, opt2) => opt1.value === opt2.value}
            onBlur={setTouched.bind(this, true)}
            error={
              touched &&
              (!pricingItem.audience || pricingItem.audience.length === 0)
            }
            disabled={false}
            multiple={true}
            required={true}
          />

          <div className={classDouble}>
            <div style={{ width: "49%", padding: "0px" }}>
              <MatSelectOutline
                noTopBottomMargin2
                double="true"
                title="Credit Lecture Hours"
                placeholder="Enter hours..."
                name="credit_lecture_hours"
                id="credit_lecture_hours"
                disableClearable={true}
                onChange={selectChangeHandler.bind(
                  this,
                  "credit_lecture_hours"
                )}
                options={selectHours}
                value={buildSingleSelect(pricingItem.credit_lecture_hours)}
                getOptionLabel={(option) => option.label}
                getOptionSelected={(opt1, opt2) => opt1.value === opt2.value}
                disabled={false}
                multiple={false}
                required={true}
              />
            </div>

            <div style={{ width: "49%", padding: "0px" }}>
              <MatSelectOutline
                noTopBottomMargin2
                double="true"
                title="Credit Hands-on Hours"
                placeholder="Enter hours..."
                name="credit_handson_hours"
                id="credit_handson_hours"
                disableClearable={true}
                onChange={selectChangeHandler.bind(
                  this,
                  "credit_handson_hours"
                )}
                options={selectHours}
                value={buildSingleSelect(pricingItem.credit_handson_hours)}
                getOptionLabel={(option) => option.label}
                getOptionSelected={(opt1, opt2) => opt1.value === opt2.value}
                disabled={false}
                multiple={false}
                required={true}
              />
            </div>
          </div>

          <InlinePop help="For Quebec courses only" />
          <MatSelectOutline
            noTopBottomMargin
            title="Credit ODQ"
            placeholder="Enter Credits..."
            name="ce_odq"
            id="ce_odq"
            disableClearable={true}
            onChange={selectChangeHandler.bind(this, "ce_odq")}
            options={selectOdq}
            value={buildSingleSelect(pricingItem.ce_odq)}
            getOptionLabel={(option) => option.label}
            getOptionSelected={(opt1, opt2) => opt1.value === opt2.value}
            disabled={false}
            multiple={false}
            required={false}
          />

          <div className={classDouble}>
            <div style={{ width: "49%", padding: "0px" }}>
              <DateSelect
                double="true"
                title="Expiration Date"
                selected={pricingItem.expiration_date}
                openToDate={openToDate}
                dateFormat={FNS_MEDIUM_DATE_FORMAT}
                minDate={addDays(new Date(), 1)}
                maxDate={
                  props.courseStartDate
                    ? new Date(props.courseStartDate)
                    : addDays(new Date(), MAX_COURSE_DATE_LIMIT)
                }
                required={false}
                onChange={(date) => {
                  const newState = { ...pricingItem };
                  if (date) {
                    date.setHours(0);
                    date.setMinutes(0);
                    date.setSeconds(0);
                    newState.expiration_date = date;
                  } else {
                    newState.expiration_date = null;
                  }
                  setPricingItem(newState);
                }}
              />
            </div>

            <div style={{ width: "49%", padding: "0px" }}>
              <MatInputOutline
                currency={props.currency}
                notopbottommargin="true"
                title="Price"
                name="cost"
                value={pricingItem.cost}
                onChange={inputChangeHandler}
                maxLength={10}
                readOnly={false}
                required={true}
                helpertext={
                  "Minimum " +
                  props.currency.shortName +
                  "25 or " +
                  props.currency.shortName +
                  "0 for free course"
                }
                haserror={priceInvalid().toString()}
              />
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            onClick={handleClose.bind(this, null)}
            color="primary"
          >
            Cancel
          </Button>
          <Button
            autoFocus
            onClick={handleClose.bind(this, pricingItem)}
            color="primary"
            disabled={
              !pricingItem.casting ||
              !pricingItem.casting.length ||
              !pricingItem.audience ||
              pricingItem.audience.length === 0 ||
              !pricingItem.component ||
              !pricingItem.component.length ||
              pricingItem.credit_lecture_hours < 0 ||
              pricingItem.credit_handson_hours < 0 ||
              priceInvalid()
            }
          >
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default CoursePriceModal;
