class Payment {
  constructor(
    fingerprint,
    id,
    name,
    lastDigits,
    lastDigitsMasked,
    expiryDate,
    brand,
    country
  ) {
    this.fingerprint = fingerprint;
    this.id = id;
    this.name = name;
    this.lastDigits = lastDigits;
    this.lastDigitsMasked = lastDigitsMasked;
    this.expiryDate = expiryDate;
    this.brand = brand;
    this.country = country;
  }

  isCad = () => {
    return this.country === "CA";
  };
}

export default Payment;
