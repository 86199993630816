import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import styles from "./AddressInput.module.css";
import LocationInput from "../addressSearch/locationInput/LocationInput";
import MatInputOutline from "../input/MatInputOutline";

/**
 * combined component :
 *
 *  1) location modal
 *  2) address fields
 */

const AddressInput = (props) => {
  const { t } = useTranslation();
  const [showAddressModal, setShowAddressModal] = useState(false);
  const [address, setAddress] = useState("");

  const showAddressModalHanlder = (value) => {
    if (!props.readOnly) {
      setShowAddressModal(value);
    }
  };

  return (
    <div className={styles.container}>
      <div className={[styles.row, styles.margin].join(" ")}>
        <div
          className={styles.street}
          onClick={() => {
            showAddressModalHanlder(true);
          }}
        >
          <MatInputOutline
            nomargin="true"
            title={t("g_street_number")}
            name="street"
            value={props.value[0]}
            onChange={() => {}}
            maxLength={100}
            error={false}
            helpertext=""
            readOnly={props.readOnly}
            required={false}
          />
        </div>
        <div
          className={styles.route}
          onClick={() => {
            showAddressModalHanlder(true);
          }}
        >
          <MatInputOutline
            nomargin="true"
            title={t("g_street_name")}
            name="streetname"
            value={props.value[1]}
            onChange={() => {}}
            maxLength={100}
            error={false}
            helpertext=""
            readOnly={props.readOnly}
            required={false}
          />
        </div>
        <div className={styles.suite}>
          <MatInputOutline
            nomargin="true"
            title="Suite"
            name="suite"
            value={props.suite}
            onChange={(e) => {
              const suite = e.target.value;
              props.onSuiteChange(suite);
            }}
            maxLength={20}
            error={false}
            helpertext=""
            readOnly={props.readOnly}
            required={false}
          />
        </div>
      </div>

      <div
        className={[styles.row, styles.margin].join(" ")}
        onClick={() => {
          showAddressModalHanlder(true);
        }}
      >
        <div className={styles.column}>
          <MatInputOutline
            nomargin="true"
            title={t("g_country")}
            name="country"
            value={props.value[2]}
            onChange={() => {}}
            maxLength={100}
            error={false}
            helpertext=""
            readOnly={props.readOnly}
            required={false}
          />
        </div>
        <div className={styles.column}>
          <MatInputOutline
            nomargin="true"
            title={t("g_city")}
            name="city"
            value={props.value[3]}
            onChange={() => {}}
            maxLength={100}
            error={false}
            helpertext=""
            readOnly={props.readOnly}
            required={false}
          />
        </div>
      </div>

      <div
        className={[styles.row, styles.margin].join(" ")}
        onClick={() => {
          showAddressModalHanlder(true);
        }}
      >
        <div className={styles.column}>
          <MatInputOutline
            nomargin="true"
            title={t("g_state")}
            name="province"
            value={props.value[4]}
            onChange={() => {}}
            maxLength={100}
            error={false}
            helpertext=""
            readOnly={props.readOnly}
            required={false}
          />
        </div>
        <div className={styles.column}>
          <MatInputOutline
            nomargin="true"
            title={t("g_zip")}
            name="zip"
            value={props.value[5]}
            onChange={() => {}}
            maxLength={100}
            error={false}
            helpertext=""
            readOnly={props.readOnly}
            required={false}
          />
        </div>
      </div>

      <LocationInput
        show={showAddressModal ? true : false}
        types={["geocode"]}
        searchOptions={{
          //location: new google.maps.LatLng(-34, 151),
          // radius: 2000,
          types: ["address"],
        }}
        output="json"
        value={address}
        onChange={(value) => {
          setAddress(value);
        }}
        onSelectAddress={(value, addressComponents, geo) => {
          props.onSelect(addressComponents, geo);
          setAddress(value);
          setShowAddressModal(false);
        }}
        clear={(event) => {
          event.preventDefault();
          props.onSelect(["", "", "", "", "", ""], {
            lat: -1,
            lng: -1,
            hash: "",
          });
          setAddress("");
          setShowAddressModal(false);
        }}
        close={(event) => {
          event.preventDefault();
          setShowAddressModal(false);
        }}
      />
    </div>
  );
};

export default AddressInput;
