import React, { useState } from "react";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import { makeStyles } from "@material-ui/core/styles";
import MatCheckbox from "../../ui/checkbox/MatCheckbox";
import EqualizerOutlinedIcon from "@material-ui/icons/EqualizerOutlined";

// ------------------------------

const useStyles = makeStyles({
  paper: {
    background: "white",
    border: 0,
    boxShadow: "0px 0px 15px gray",
    width: 400,
  },
});

// ------------------------------

const StatsDrawer = (props) => {
  const styles = useStyles();
  const dense = true;
  const [localCharts, setLocalCharts] = useState([...props.selectedCharts]);

  // ------------------------------

  const toggleDrawer = (charts) => (event) => {
    props.close(charts);
  };

  // ------------------------------

  const toggleChart = (chart) => {
    const chartIndex = localCharts.indexOf(chart.id);
    if (chartIndex > -1) {
      const chartsCopy = [...localCharts];
      chartsCopy.splice(chartIndex, 1);
      setLocalCharts(chartsCopy);
    } else {
      const chartsCopy = [...localCharts];
      chartsCopy.push(chart.id);
      setLocalCharts(chartsCopy);
    }
  };

  // ------------------------------

  return (
    <Drawer
      classes={{ paper: styles.paper }}
      anchor="right"
      open={props.open}
      onClose={toggleDrawer(localCharts)}
      variant={null}
    >
      <div role="presentation">
        <div
          style={{
            cursor: "pointer",
            color: "#437aa1",
            paddingLeft: "14px",
            paddingTop: "16px",
            display: "flex",
            alignItems: "center",
            fontStyle: "italic",
          }}
          onClick={toggleDrawer(localCharts)}
        >
          <EqualizerOutlinedIcon
            style={{ fontSize: "25px", marginRight: "10px" }}
          />
          Customize your analytics dashboard
        </div>

        <List>
          {props.charts.map((chart, key) => (
            <ListItem key={key} dense={dense} button onClick={() => {}}>
              <MatCheckbox
                plainright="true"
                name=""
                checked={localCharts.indexOf(chart.id) !== -1}
                label={chart.title}
                onChange={toggleChart.bind(this, chart)}
                disabled={chart.disabled}
              />
              <EqualizerOutlinedIcon />
            </ListItem>
          ))}
        </List>
      </div>
    </Drawer>
  );
};

export default StatsDrawer;
