import {
  SET_CONVERSATIONS,
  ADD_CONVERSATION,
  UPDATE_CONVERSATION,
  REMOVE_CONVERSATION,
  SET_MESSAGES,
  MESSAGE_ADDED,
  UPDATE_VIEWED_CONVO,
} from "../actions/inbox";
import { updateObject } from "../../globals/Common";

const initialState = {
  selectedConversationId: null,
  conversations: [],
  messages: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_CONVERSATIONS:
      const sortConvo = action.conversations.sort((a, b) =>
        a.last_date.getTime() < b.last_date.getTime() ? 1 : -1
      );

      return {
        ...state,
        conversations: sortConvo,
      };
    case ADD_CONVERSATION:
      const newConvo = action.conversation;
      const conversations = state.conversations.concat(newConvo);
      const sortNewConvo = conversations.sort((a, b) =>
        a.last_date.getTime() < b.last_date.getTime() ? 1 : -1
      );
      return updateObject(state, {
        conversations: sortNewConvo,
        selectedConversationId: newConvo.id,
      });
    case UPDATE_CONVERSATION:
      const updateConvo = action.conversation;

      const updatConvoIndex = state.conversations.findIndex(
        (c) => c.id === updateConvo.id
      );

      if (updatConvoIndex !== -1) {
        const updatedConvoArr = [...state.conversations];
        updatedConvoArr[updatConvoIndex] = updateConvo;
        return updateObject(state, {
          conversations: updatedConvoArr,
        });
      } else {
        return state;
      }
    case UPDATE_VIEWED_CONVO:
      const updateViewConvo = action.conversation;

      const updateViewConvoIndex = state.conversations.findIndex(
        (c) => c.id === updateViewConvo.id
      );

      if (updateViewConvoIndex !== -1) {
        const updatedConvoArr = [...state.conversations];
        updatedConvoArr[updateViewConvoIndex] = updateViewConvo;
        return updateObject(state, {
          conversations: updatedConvoArr,
        });
      } else {
        return state;
      }
    case REMOVE_CONVERSATION:
      const convoCopyDel = [...state.conversations];
      for (const delConvo of action.conversations) {
        const indexFound = convoCopyDel.findIndex((c) => c.id === delConvo.id);

        if (indexFound > -1) {
          convoCopyDel.splice(indexFound, 1);
        }
      }
      return {
        ...state,
        conversations: convoCopyDel,
      };
    case SET_MESSAGES:
      return {
        ...state,
        messages: action.messages,
        selectedConversationId: action.conversationId,
      };
    case MESSAGE_ADDED:
      let messagesAdd = [...state.messages];
      const index = messagesAdd.findIndex((m) => m.id === action.message.id);

      if (
        index === -1 &&
        state.selectedConversationId === action.conversationId
      ) {
        const concatMessagesAdd = messagesAdd.concat(action.message);
        const orderedMessages = concatMessagesAdd.sort((a, b) =>
          a.date.getTime() > b.date.getTime() ? 1 : -1
        );
        return { ...state, messages: orderedMessages };
      } else {
        return state;
      }
    case "LOGOUT":
      return initialState;
    default:
      return state;
  }
};
