import { momentShortDate } from "../globals/Dates2";
import { ACCREDITATION } from "../globals/Data";

class Ce {
  constructor(
    id,
    title,
    description,
    notes,
    lecture_hours,
    practice_hours,
    credits,
    category,
    organizer,
    courseDate,
    entryDate,
    courseId,
    price,
    currency,
    hasCertificate,
    certificateUri
  ) {
    this.id = id;
    this.title = title;
    this.description = description;
    this.notes = notes;
    this.lecture_hours = lecture_hours;
    this.practice_hours = practice_hours;
    this.credits = credits;
    this.category = category;
    this.organizer = organizer;
    this.courseDate = courseDate;
    this.entryDate = entryDate;
    this.courseId = courseId;
    this.price = price;
    this.currency = currency;
    this.hasCertificate = hasCertificate;
    this.certificateUri = certificateUri;
  }

  // ------------------------------

  get readableDate() {
    return momentShortDate(new Date(this.courseDate));
  }

  // ------------------------------

  resolveCredits = (odq) => {
    if (odq === true && this.credits) {
      return this.credits;
    } else {
      return parseFloat(this.lecture_hours) + parseFloat(this.practice_hours);
    }
  };

  // ------------------------------

  resolveCategory = () => {
    if (this.category === ACCREDITATION[2]) {
      return "ODQ";
    }

    return this.category;
  };
}

export default Ce;
