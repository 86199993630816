class BankAccount {
  constructor(id, bank_name, last4, currency, country) {
    this.id = id;
    this.bank_name = bank_name;
    this.last4 = last4;
    this.currency = currency;
    this.country = country;
  }
}

export default BankAccount;
