import React, { Component } from "react";
import "./Card.css";
import * as styles from "../Payment.module.css";
import { ElementsConsumer, CardElement } from "@stripe/react-stripe-js";
import Button from "@material-ui/core/Button";
import CreditCardIcon from "@material-ui/icons/CreditCard";
import MatInput from "../../../components/ui/input/MatInput";
import Spinner from "../../../components/ui/spinner/Spinner";

class CardForm extends Component {
  // ------------------------------

  state = {
    submitting: false,
    name: "",
  };

  // ------------------------------

  handleSubmit = async (event) => {
    event.preventDefault();
    await this.setState({ ...this.state, submitting: true });

    const { stripe, elements, clientSecret } = this.props;

    if (!stripe || !elements) {
      await this.setState({ ...this.state, submitting: false });
      return;
    }

    const result = await stripe.confirmCardSetup(clientSecret, {
      payment_method: {
        card: elements.getElement(CardElement),
        billing_details: {
          name: this.state.name,
        },
      },
    });

    if (result.error) {
      // Display result.error.message in your UI
      // set parent state error
      await this.setState({ ...this.state, submitting: false });
    } else {
      // The setup has succeeded. Display a success message and send
      // result.setupIntent.payment_method to your server to save the
      // card to a Customer
      await this.props.saveCard(result.setupIntent.payment_method);
    }
  };

  inputChangeHandler = (e) => {
    this.setState({ ...this.state, [e.target.name]: e.target.value });
  };

  render() {
    const CARD_ELEMENT_OPTIONS = {
      style: {
        base: {
          color: "#32325d",
          fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
          fontSmoothing: "antialiased",
          fontSize: "16px",
          "::placeholder": {
            color: "#aab7c4",
          },
        },
        invalid: {
          color: "#fa755a",
          iconColor: "#fa755a",
        },
      },
    };

    return (
      <form className={styles.form}>
        {this.state.submitting && <Spinner />}

        <div className={styles.sectionTitle}>
          <div className={styles.centerRow}>
            <CreditCardIcon style={{ color: "white" }} />
            &nbsp;&nbsp;{this.props.t("g_add_payment_method")}
          </div>
        </div>

        <div className={styles.matInput}>
          <MatInput
            title={this.props.t("g_name_on_card")}
            value={this.state.name}
            name="name"
            onChange={this.inputChangeHandler}
            maxLength={100}
            required
          />
        </div>

        <div className={styles.input}>
          <CardElement options={CARD_ELEMENT_OPTIONS} />
        </div>

        <div className={styles.pricingContainer}>
          <Button
            variant="contained"
            color="primary"
            startIcon={<CreditCardIcon />}
            onClick={this.handleSubmit}
            disabled={!this.props.stripe || this.state.submitting}
          >
            {this.props.t("g_save_card")}
          </Button>
        </div>
      </form>
    );
  }
}

const InjectedCardForm = (props) => {
  return (
    <ElementsConsumer>
      {({ stripe, elements }) => (
        <CardForm
          stripe={stripe}
          elements={elements}
          clientSecret={props.clientSecret}
          saveCard={props.saveCard}
          t={props.t}
        />
      )}
    </ElementsConsumer>
  );
};

export default InjectedCardForm;
