import {
  SET_ZOOM_SETTINGS,
  SET_ZOOM_MEETINGS,
  SET_ZOOM_MEETING_DETAIL,
  SET_ZOOM_PARTICIPANTS,
} from "../actions/zoom";
import { updateObject } from "../../globals/Common";
import { LOGOUT } from "../actions/auth";

const initialState = {
  connected: false,
  zoom_meetings: [],
  zoom_meeting_detail: null,
  zoom_participants: [],
};

const zoomReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_ZOOM_SETTINGS:
      return updateObject(state, {
        connected: action.connected,
      });
    case SET_ZOOM_MEETINGS:
      return updateObject(state, {
        zoom_meetings: action.zoom_meetings,
      });
    case SET_ZOOM_MEETING_DETAIL:
      return updateObject(state, {
        zoom_meeting_detail: action.zoom_meeting_detail,
      });
    case SET_ZOOM_PARTICIPANTS:
      return updateObject(state, {
        zoom_participants: action.zoom_participants,
      });
    case LOGOUT:
      return initialState;
    default:
      return state;
  }
};

export default zoomReducer;
