import React, { useState, Fragment } from "react";
import { useTranslation } from "react-i18next";
import styles from "./CourseViewModal.module.css";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import CourseListing from "../../../components/ui/courseViewer/CourseListing";
import CourseViewer from "../../../components/ui/courseViewer/CourseViewer";
import AddViewer from "../../../components/ui/courseViewer/AddViewer";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";
import AddIcon from "@material-ui/icons/Add";
import logo from "../../../assets/images/inApp/logo.png";
import AddToCartBubble from "../../../screens/mobile/search/AddToCartBubble";
import ConfirmModal from "../confirmModal/ConfirmModal";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const CourseViewModal = (props) => {
  const { t } = useTranslation();

  const theme = useTheme();
  const fullWidth = true;
  const maxWidth = props.noPhoneBorder ? "lg" : "sm";
  const fullScreen = useMediaQuery(theme.breakpoints.down("xs"));
  const [warnModalOpen, setWarnModalOpen] = useState(false);

  if (props.noPhoneBorder) {
    return (
      <Fragment>
        <Dialog
          TransitionComponent={Transition}
          open={true}
          onClose={props.onClose}
          aria-labelledby=""
          fullScreen={fullScreen}
          fullWidth={fullWidth}
          maxWidth={maxWidth}
        >
          {warnModalOpen && (
            <ConfirmModal
              isInfoModal
              title={t("store_open")}
              message={
                <div className={styles.blurbLogoContainer}>
                  <div className={styles.dialogLogoContainer}>
                    <div className={styles.logoWrapper}>
                      {props.provider && props.provider.logo ? (
                        <img
                          src={props.provider.logo}
                          style={{
                            width: "75px",
                            height: "75px",
                            borderRadius: "10px",
                          }}
                        />
                      ) : (
                        <div style={{ fontSize: "46px" }}>
                          <StorefrontOutlinedIcon fontSize="inherit" />
                        </div>
                      )}
                    </div>
                    <AddIcon style={{ color: "black", padding: "5px" }} />
                    <div className={styles.logoWrapper}>
                      <img src={logo} alt="ZubU" style={{ height: "55px" }} />
                    </div>
                  </div>
                  <div className={styles.blurbWrapper}>
                    <br></br>
                    {t("store_to_register_action")}
                    <ol>
                      <li>{t("store_to_register_1")}</li>
                      <li>{t("store_to_register_2")}</li>
                      <li>{t("store_to_register_3")}</li>
                    </ol>
                  </div>
                </div>
              }
              open={warnModalOpen}
              onClose={(answer) => {
                setWarnModalOpen(false);

                if (answer === "ok") {
                  props.onRegister();
                }
              }}
              okButtonText={t("g_yes")}
            />
          )}
          <DialogTitle className={styles.dialogTitle}>
            <div className={styles.dialogContainer}>
              <div>
                <IconButton
                  edge="start"
                  color="inherit"
                  onClick={props.onClose}
                  aria-label="close"
                  autoFocus
                >
                  <CloseIcon />
                </IconButton>
              </div>

              <div className={styles.blurbAndBubbleContainer}>
                <div className={styles.blurbLogoContainer}>
                  <div className={styles.dialogLogoContainer}>
                    <div className={styles.logoWrapper}>
                      {props.provider && props.provider.logo ? (
                        <img
                          src={props.provider.logo}
                          style={{
                            width: "75px",
                            height: "75px",
                            borderRadius: "10px",
                          }}
                        />
                      ) : (
                        <div style={{ fontSize: "46px" }}>
                          <StorefrontOutlinedIcon fontSize="inherit" />
                        </div>
                      )}
                    </div>
                    <AddIcon style={{ color: "black", padding: "5px" }} />
                    <div className={styles.logoWrapper}>
                      <img src={logo} alt="ZubU" style={{ height: "55px" }} />
                    </div>
                  </div>
                  <div className={styles.blurbWrapper}>
                    {t("store_powered_by_1")} <b>{props.provider.name} </b>
                    {t("store_powered_by_2")} <b>ZubU.</b>
                  </div>
                </div>

                <AddToCartBubble
                  course={props.course}
                  rates={props.rates}
                  currency={props.currencyObject}
                  onRegister={() => {
                    setWarnModalOpen(true);
                  }}
                  courseExpired={false}
                />
              </div>
            </div>
          </DialogTitle>

          <DialogContent style={{ padding: "0px" }}>
            <CourseListing
              course={props.course}
              currency={props.currency}
              rates={props.rates}
            />
            <br />
          </DialogContent>
        </Dialog>
      </Fragment>
    );
  }

  return (
    <Dialog
      open={true}
      onClose={props.onClose}
      aria-labelledby="responsive-dialog-title"
      fullScreen={fullScreen}
      fullWidth={fullWidth}
      maxWidth={maxWidth}
    >
      <DialogContent style={{ padding: "0px" }}>
        {props.isAdd && <AddViewer add={props.add} />}
        {!props.isAdd && (
          <CourseViewer
            currency={props.currency}
            course={props.course}
            rates={props.rates}
          />
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={props.onClose} color={"primary"} autoFocus>
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CourseViewModal;
