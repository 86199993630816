import React from "react";
import styles from "../BannerSlide.module.css";
import { useTranslation } from "react-i18next";
import img1 from "../../../assets/images/providerSlider/1.png";
import img2 from "../../../assets/images/providerSlider/2.png";
import img3 from "../../../assets/images/providerSlider/3.jpeg";
import img3_5 from "../../../assets/images/providerSlider/3_5.jpg";
import img4 from "../../../assets/images/providerSlider/4.jpeg";
import img5 from "../../../assets/images/providerSlider/5.png";
import img6 from "../../../assets/images/providerSlider/6.png";
import img7 from "../../../assets/images/providerSlider/7.png";
import img8 from "../../../assets/images/providerSlider/8.png";

import {
  CarouselProvider,
  Slider,
  Slide,
  Dot,
  DotGroup,
  ButtonBack,
  ButtonNext,
} from "pure-react-carousel";
import "pure-react-carousel/dist/react-carousel.es.css";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";

const ProviderSlider = () => {
  const { t } = useTranslation();
  return (
    <div className={styles.containerAreYou}>
      <div className={styles.textContainer}>
        <div className={styles.title}>{t("welcome_flexible_custom")}</div>
        <div
          className={styles.subTitle}
          style={{
            paddingBottom: "40px",
          }}
        >
          <div>{t("welcome_flexible_custom_text")}</div>
        </div>
      </div>
      <div style={{ maxWidth: "700px" }}>
        <CarouselProvider
          infinite={true}
          isIntrinsicHeight={true}
          totalSlides={9}
          interval={7000}
          isPlaying={true}
          dragEnabled={false}
          lockOnWindowScroll={true}
        >
          <Slider>
            <Slide index={0}>
              <img src={img1} alt="" className={styles.imageProvider} />
            </Slide>
            <Slide index={1}>
              <img src={img2} alt="" className={styles.imageProvider} />
            </Slide>
            <Slide index={2}>
              <img src={img3} alt="" className={styles.imageProvider} />
            </Slide>
            <Slide index={3}>
              <img src={img3_5} alt="" className={styles.imageProvider} />
            </Slide>
            <Slide index={4}>
              <img src={img4} alt="" className={styles.imageProvider} />
            </Slide>
            <Slide index={5}>
              <img src={img5} alt="" className={styles.imageProvider} />
            </Slide>
            <Slide index={6}>
              <img src={img6} alt="" className={styles.imageProvider} />
            </Slide>
            <Slide index={7}>
              <img src={img7} alt="" className={styles.imageProvider} />
            </Slide>
            <Slide index={8}>
              <img src={img8} alt="" className={styles.imageProvider} />
            </Slide>
          </Slider>

          <div className={styles.rowButtonSlideContainer}>
            <div className={styles.sliderButtonContainer}>
              <ButtonBack className={styles.slideButton}>
                <NavigateBeforeIcon />
              </ButtonBack>
            </div>
            <div>
              <DotGroup
                renderDots={(s) => (
                  <div className={styles.dotContainer}>
                    <Dot
                      slide={0}
                      className={
                        s.currentSlide === 0 ? styles.activeDot : styles.dot
                      }
                    />
                    &nbsp;
                    <Dot
                      slide={1}
                      className={
                        s.currentSlide === 1 ? styles.activeDot : styles.dot
                      }
                    />
                    &nbsp;
                    <Dot
                      slide={2}
                      className={
                        s.currentSlide === 2 ? styles.activeDot : styles.dot
                      }
                    />
                    &nbsp;
                    <Dot
                      slide={3}
                      className={
                        s.currentSlide === 3 ? styles.activeDot : styles.dot
                      }
                    />
                    &nbsp;
                    <Dot
                      slide={4}
                      className={
                        s.currentSlide === 4 ? styles.activeDot : styles.dot
                      }
                    />
                    &nbsp;
                    <Dot
                      slide={5}
                      className={
                        s.currentSlide === 5 ? styles.activeDot : styles.dot
                      }
                    />
                    &nbsp;
                    <Dot
                      slide={6}
                      className={
                        s.currentSlide === 6 ? styles.activeDot : styles.dot
                      }
                    />
                    &nbsp;
                    <Dot
                      slide={7}
                      className={
                        s.currentSlide === 7 ? styles.activeDot : styles.dot
                      }
                    />
                  </div>
                )}
              />
            </div>

            <div className={styles.sliderButtonContainer}>
              <ButtonNext className={styles.slideButton}>
                <NavigateNextIcon />
              </ButtonNext>
            </div>
          </div>
        </CarouselProvider>
      </div>
    </div>
  );
};

export default ProviderSlider;
