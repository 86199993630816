import React, { Component } from "react";
import { connect } from "react-redux";
import styles from "./PluginScreen.module.css";
import * as profileActions from "../../../store/actions/profile";
import Spinner from "../../../components/ui/spinner/Spinner";
import InfoModal from "../../../components/modals/infoModal/InfoModal";
import SimpleContainer from "../../../components/ui/container/Container";
import InlinePop from "../../../components/ui/popover/InlinePop";
import { zubuUrl } from "../../../globals/ApiKeys";
import * as logger from "../../../globals/Logger";
import AlertSuccess from "../../../components/ui/snackbar/AlertSuccess";
import WebOutlinedIcon from "@material-ui/icons/WebOutlined";
import PowerOutlinedIcon from "@material-ui/icons/PowerOutlined";
import CopyLinkContainer from "./CopyLinkContainer";

class PluginScreen extends Component {
  state = {
    wait: false,
    error: null,
    success: null,
  };

  // ------------------------------

  componentDidMount = async () => {
    window.scrollTo(0, 0);
  };

  // ------------------------------

  update = async (propValue) => {
    await this.setState({ ...this.state, ...propValue });
  };

  // ------------------------------

  componentDidMount = async () => {
    try {
      this.update({ error: null, wait: true });
      await this.props.fetchProfile();
      this.update({ error: null, wait: false });
    } catch (error) {
      logger.error(error);
      this.update({ wait: false, error: error.message });
    }
  };

  // ------------------------------

  render() {
    if (this.state.wait) {
      return <Spinner />;
    }

    return (
      <div style={{ background: "white" }}>
        {this.state.success !== null && (
          <AlertSuccess
            message={this.state.success}
            open={this.state.success !== null}
            onClose={() => {
              this.update({ success: null });
            }}
          />
        )}

        <SimpleContainer disableGutters={false}>
          <InfoModal
            isErrorModal={true}
            title="An error has occured"
            message={this.state.error}
            open={this.state.error !== null}
            onClose={() => {
              this.update({ error: null });
            }}
          />

          <div style={{ height: "40px" }}>&nbsp;</div>

          <div className={styles.sectionTitle}>
            <div className={styles.centerRow}>
              <WebOutlinedIcon style={{ color: "white" }} />
              &nbsp;&nbsp;Storefront URL
            </div>
          </div>

          <InlinePop
            help={
              <div style={{ width: "300px", paddingRight: "15px" }}>
                <p>
                  Share your storefront url link on social media or use it as a
                  link on your website to direct users to your course listings
                  on ZubU.
                </p>
              </div>
            }
          />

          <div className={styles.pricingContainer}>
            <CopyLinkContainer
              title="Courses (All)"
              link={this.props.ALL_COURSES_URL}
              onCopy={(copiedText) => {
                this.update({ success: copiedText });
              }}
              onLaunch={() => {
                window.open(
                  this.props.ALL_COURSES_URL,
                  "_blank",
                  "noopener,noreferrer"
                );
              }}
            />

            <CopyLinkContainer
              title="Courses (English only)"
              link={this.props.EN_COURSES_URL}
              onCopy={(copiedText) => {
                this.update({ success: copiedText });
              }}
              onLaunch={() => {
                window.open(
                  this.props.EN_COURSES_URL,
                  "_blank",
                  "noopener,noreferrer"
                );
              }}
            />

            <CopyLinkContainer
              title="Courses (French only)"
              link={this.props.FR_COURSES_URL}
              onCopy={(copiedText) => {
                this.update({ success: copiedText });
              }}
              onLaunch={() => {
                window.open(
                  this.props.FR_COURSES_URL,
                  "_blank",
                  "noopener,noreferrer"
                );
              }}
            />
          </div>

          <div className={styles.sectionTitle}>
            <div className={styles.centerRow}>
              <PowerOutlinedIcon style={{ color: "white" }} />
              &nbsp;&nbsp;Website Plugin
            </div>
          </div>

          <InlinePop
            help={
              <div style={{ width: "300px", paddingRight: "15px" }}>
                <p>
                  Copy this code snippet in any web page of your website. This
                  will embed your ZubU storefront on your website and allow you
                  to display your courses in real time without the need of a web
                  developer.
                </p>
              </div>
            }
          />

          <div className={styles.pricingContainer}>
            <CopyLinkContainer
              title="Courses (All)"
              link={this.props.EMBED_ALL}
              onCopy={(copiedText) => {
                this.update({ success: copiedText });
              }}
            />

            <CopyLinkContainer
              title="Courses (English only)"
              link={this.props.EMBED_EN}
              onCopy={(copiedText) => {
                this.update({ success: copiedText });
              }}
            />

            <CopyLinkContainer
              title="Courses (French only)"
              link={this.props.EMBED_FR}
              onCopy={(copiedText) => {
                this.update({ success: copiedText });
              }}
            />
          </div>
        </SimpleContainer>

        <div style={{ height: "80px" }}>&nbsp;</div>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  let ALL_COURSES_URL = "";
  let EN_COURSES_URL = "";
  let FR_COURSES_URL = "";

  let ALL_COURSES_PLUGIN = "";
  let EN_COURSES_PLUGIN = "";
  let FR_COURSES_PLUGIN = "";
  const noHeaderFoter = "&header=none&footer=none";

  if (state.profile && state.profile.profile) {
    const prof = state.profile.profile;
    ALL_COURSES_URL = `${zubuUrl}/shop?p=${prof.id}`;
    EN_COURSES_URL = `${zubuUrl}/shop?p=${prof.id}&lang=en`;
    FR_COURSES_URL = `${zubuUrl}/shop?p=${prof.id}&lang=fr`;
    ALL_COURSES_PLUGIN = ALL_COURSES_URL + noHeaderFoter;
    EN_COURSES_PLUGIN = EN_COURSES_URL + noHeaderFoter;
    FR_COURSES_PLUGIN = EN_COURSES_URL + noHeaderFoter;
  }

  const EMBED_ALL = `<embed type="text/html" src="${ALL_COURSES_PLUGIN}" width="100%" style="min-height:1300px;border:none;"/>`;
  const EMBED_EN = `<embed type="text/html" src="${EN_COURSES_PLUGIN}" width="100%" style="min-height:1300px;border:none;"/>`;
  const EMBED_FR = `<embed type="text/html" src="${FR_COURSES_PLUGIN}" width="100%" style="min-height:1300px;border:none;"/>`;

  return {
    profile: state.profile.profile,
    ALL_COURSES_URL: ALL_COURSES_URL,
    EN_COURSES_URL: EN_COURSES_URL,
    FR_COURSES_URL: FR_COURSES_URL,
    EMBED_ALL: EMBED_ALL,
    EMBED_EN: EMBED_EN,
    EMBED_FR: EMBED_FR,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchProfile: () => dispatch(profileActions.fetchProfile()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PluginScreen);
