import React, { Component } from "react";
import { connect } from "react-redux";
import Spinner from "../../components/ui/spinner/Spinner";
import { appDomain } from "../../globals/ApiKeys";
import * as logger from "../../globals/Logger";

class MobileRedirectQrScreen extends Component {
  // ------------------------------

  componentDidMount = async () => {
    try {
      setTimeout(
        function () {
          this.props.history.push("/mobile");
        }.bind(this),
        3500
      );

      window.location.replace(`${appDomain}://?course=${this.props.courseId}`);
    } catch (err) {
      logger.error(err);
      this.props.history.push("/");
    }
  };

  // ------------------------------

  render() {
    return <Spinner />;
  }
}

// ------------------------------

const parseQueryString = (props) => {
  const query = new URLSearchParams(props.location.search);

  let courseId = null;

  for (let param of query.entries()) {
    if (param[0] === "course") {
      courseId = param[1];
    }
  }

  return courseId;
};

// ------------------------------

const mapStateToProps = (state, props) => {
  return {
    courseId: parseQueryString(props),
  };
};

export default connect(mapStateToProps, null)(MobileRedirectQrScreen);
