import React from "react";
import styles from "./Logo.module.css";
import Grow from "@material-ui/core/Grow";
import Tooltip from "@material-ui/core/Tooltip";
import zubu from "../../../assets/images/welcome/zubu.png";
import { appVersion } from "../../../globals/ApiKeys";

const LogoMenuBar = (props) => (
  <div className={styles.logoContainer}>
    <Tooltip title={appVersion}>
      <img
        src={zubu}
        className={props.small ? styles.logozubufixed : styles.logozubu}
        alt=""
        onClick={props.onClick}
      />
    </Tooltip>
  </div>
);

export default LogoMenuBar;
