import { momentShortDate } from "../globals/Dates2";

// charge and order ref number where this student was registered

class Student {
  constructor(
    id,
    first,
    last,
    license,
    occupation,
    email,
    registeredById,
    registeredByName,
    registrationOption,
    registeredDate,
    charge,
    orderRefNumber,
    isUnregistered,
    unregisteredDate,
    unregisteredReason,
    courseId,
    certificate_sent,
    survey_sent,
    survey_completed,
    member_id
  ) {
    this.id = id;
    this.first = first;
    this.last = last;
    this.license = license;
    this.occupation = occupation;
    this.email = email;
    this.registeredById = registeredById;
    this.registeredByName = registeredByName;
    this.registrationOption = registrationOption;
    this.registeredDate = registeredDate;
    this.charge = charge;
    this.orderRefNumber = orderRefNumber;
    this.isUnregistered = isUnregistered;
    this.unregisteredDate = unregisteredDate;
    this.unregisteredReason = unregisteredReason;
    this.courseId = courseId;
    this.certificate_sent = certificate_sent;
    this.survey_sent = survey_sent;
    this.survey_completed = survey_completed;
    this.member_id = member_id;
  }

  format_registered_date = () => {
    return momentShortDate(this.registeredDate);
  };

  format_unregistered_date = () => {
    return momentShortDate(this.unregisteredDate);
  };

  format_certificate_date = () => {
    return momentShortDate(this.certificate_sent);
  };

  format_surve_date = () => {
    return momentShortDate(this.survey_sent);
  };

  format_survey_completed_date = () => {
    return momentShortDate(this.survey_completed);
  };
}

export default Student;
