class Certificate {
  constructor(id, title, header, footer, linked_courses) {
    this.id = id;
    this.title = title;
    this.header = header;
    this.footer = footer;
    this.linked_course = linked_courses;
  }
}

export default Certificate;
