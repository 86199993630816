import React from "react";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";

const FlatButton = (props) => {
  return props.icononly ? (
    <IconButton
      {...props}
      style={{
        minWidth: "50px",
        minHeight: "45px",
        border: "1px solid #ccc",
        borderRadius: "5px",
        backgroundColor: "white",
      }}
    >
      {props.icon}
    </IconButton>
  ) : (
    <Button
      {...props}
      variant="outlined"
      startIcon={props.icon}
      style={{
        minWidth: "50px",
        minHeight: "45px",
        backgroundColor: "white",
      }}
    >
      {props.label}
    </Button>
  );
};

export default FlatButton;
