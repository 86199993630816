import React, { Fragment } from "react";
import { useTranslation } from "react-i18next";
import styles from "./CategorySearch.module.css";
import Badge from "@material-ui/core/Badge";
import FilterListIcon from "@material-ui/icons/FilterList";
import Button from "@material-ui/core/Button";
import SortByAlphaIcon from "@material-ui/icons/SortByAlpha";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import IconButton from "@material-ui/core/IconButton";

// ------------------------------

const FilterSortButtons = (props) => {
  const { t } = useTranslation();

  const matches = useMediaQuery("(max-width:599px)");

  return (
    <Fragment>
      {matches ? (
        <div className={styles.headerTitleBlurb}>
          <IconButton
            aria-label={t("g_filters")}
            onClick={props.onFilterClick}
            style={{ backgroundColor: "white" }}
          >
            <Badge
              badgeContent={props.filterCount > 0 ? props.filterCount : null}
              color="error"
            >
              <FilterListIcon fontSize={"small"} />
            </Badge>
          </IconButton>
          &nbsp;&nbsp;
          <IconButton
            aria-label={t("g_sort")}
            style={{ backgroundColor: "white" }}
            onClick={props.onSortClick}
          >
            <SortByAlphaIcon fontSize={"small"} />
          </IconButton>
        </div>
      ) : (
        <div className={styles.headerTitleBlurb}>
          <Badge
            badgeContent={props.filterCount > 0 ? props.filterCount : null}
            color="error"
          >
            <Button
              variant="outlined"
              color="primary"
              size="large"
              startIcon={<FilterListIcon />}
              onClick={props.onFilterClick}
              style={{ backgroundColor: "white", border: 0 }}
            >
              {t("g_filters")}
            </Button>
          </Badge>
          &nbsp;&nbsp;
          <Button
            variant="outlined"
            color="primary"
            size="large"
            startIcon={<SortByAlphaIcon />}
            onClick={props.onSortClick}
            style={{ backgroundColor: "white", border: 0 }}
          >
            {t("g_sort")}
          </Button>
        </div>
      )}
    </Fragment>
  );
};

export default FilterSortButtons;
