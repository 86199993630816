import React from "react";
import styles from "./Image.module.css";
import CameraAltIcon from "@material-ui/icons/CameraAlt";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import DivImage from "./DivImage";

const ImageInput = (props) => {
  let styleFile = styles.file;
  let styleImageBoxArr = [styles.imageBox];
  const deleteTitle = props.deleteTitle ? props.deleteTitle : "Remove Picture";

  if (props.headerFooter) {
    styleFile = [styles.file, styles.headerFooter].join(" ");

    if (props.readOnly) {
      styleImageBoxArr = [];
    } else {
      styleImageBoxArr = [styles.imageHeaderFooterBox];
    }
  }

  if (props.rectangle) {
    styleFile = [styles.file, styles.fileSignature].join(" ");
    styleImageBoxArr = [styles.imageBox, styles.imageSignatureBox];
  }

  if (props.providerCoverPhoto) {
    styleFile = [styles.file, styles.fileProviderCoverPhoto].join(" ");
    styleImageBoxArr = [styles.imageBox, styles.imageProviderCoverPhotoBox];
  }

  if (props.readOnly) {
    styleImageBoxArr.push(styles.disableImage);
  }

  let styleImageBox = styleImageBoxArr.join(" ");

  // ------------------------------

  const fileSelectedHandler = (event) => {
    props.onImageSelect(URL.createObjectURL(event.target.files[0]));
    event.target.value = null;
  };

  // ------------------------------

  let imageContent = (
    <div className={styleImageBox}>
      <div>
        <CameraAltIcon style={{ color: "gray" }} />
      </div>
      <div>{props.title}</div>
    </div>
  );

  // ------------------------------

  if (props.image) {
    imageContent = (
      <div className={styleImageBox}>
        <img
          src={props.image}
          alt=""
          className={
            props.headerFooter
              ? styles.zubuEditable
              : props.providerCoverPhoto
              ? styles.providerCoverPhotoImageSize
              : null
          }
        />
      </div>
    );
  }

  // ------------------------------

  if (props.coverPhoto) {
    return (
      <div>
        <input
          name={props.id ? props.id : "file"}
          id={props.id ? props.id : "file"}
          type="file"
          accept="image/*"
          onChange={fileSelectedHandler}
          className={styles.inputFile}
          disabled={props.readOnly}
        />

        <Tooltip
          title="Edit Image"
          aria-label="Edit Image"
          disableHoverListener={props.readOnly}
          placement="top-start"
        >
          <label htmlFor={props.id ? props.id : "file"}>
            <div
              className={props.readOnly ? null : styles.imageHeaderFooterBox}
            >
              <DivImage
                image={props.coverPhoto}
                width={props.width}
                height={props.height}
              />
            </div>
          </label>
        </Tooltip>

        {props.onRemovePic && (
          <div className={styles.removePicture}>
            <Tooltip title={deleteTitle} aria-label={deleteTitle}>
              <IconButton
                aria-label={deleteTitle}
                onClick={props.onRemovePic}
                size="small"
                style={{
                  backgroundColor: "white",
                  marginBottom: "10px",
                  marginRight: "5px",
                }}
              >
                <DeleteForeverIcon size="small" color="primary" />
              </IconButton>
            </Tooltip>
          </div>
        )}
      </div>
    );
  }

  // ------------------------------

  return (
    <div>
      <input
        name={props.id ? props.id : "file"}
        id={props.id ? props.id : "file"}
        type="file"
        accept="image/*"
        onChange={fileSelectedHandler}
        className={styles.inputFile}
        disabled={props.readOnly}
      />

      <Tooltip
        title="Edit Image"
        aria-label="Edit Image"
        disableHoverListener={!props.headerFooter || props.readOnly}
        placement="top-start"
      >
        <div className={props.headerFooter ? null : styles.imageBoxContainer}>
          {!props.headerFooter && (
            <div className={styles.header}>{props.header}</div>
          )}
          <label htmlFor={props.id ? props.id : "file"} className={styleFile}>
            {imageContent}
          </label>
        </div>
      </Tooltip>

      {props.onRemovePic && (
        <div className={styles.removePicture}>
          <Tooltip title={deleteTitle} aria-label={deleteTitle}>
            <IconButton
              aria-label={deleteTitle}
              onClick={props.onRemovePic}
              size="small"
            >
              <DeleteForeverIcon size="small" color="primary" />
            </IconButton>
          </Tooltip>
        </div>
      )}
    </div>
  );
};

export default ImageInput;
