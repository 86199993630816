import { handleErrorResponse } from "../../globals/Error";
import { dbName } from "../../globals/ApiKeys";
import Course from "../../model/Course";
import Survey from "../../model/Survey";
import Certificate from "../../model/Certificate";
import Amendment from "../../model/Amendment";
import Professor from "../../model/Professor";
import PricingItem from "../../model/PricingItem";
import MultiPayOption from "../../model/MultiPayOption";
import Handout from "../../model/Handout";
import MobileMarketingAd from "../../model/MobileMarketingAd";
import ProviderCoursesMeta from "../../model/metadata/ProviderCoursesMeta"
import VideoChapter from "../../model/VideoChapter"
import {
  toFirebaseStringArrayValue,
  toFirebaseDateArrayValue,
  fromFirebaseDateArrayValue,
  fromFirebaseStringArrayValue,
  fromFirebaseDoubleArrayValue,
  fromFirebaseNumberValue,
  fromFirebaseDoubleValue,
  stringFieldFilter,
  booleanFieldFilter,
  timestampFieldFilter,
  shuffleArray,
  firebaseNameToId,
} from "../../globals/Common";
import {
  LIVESTREAM,
  ONLINE_VIDEO,
  LECTURE_ONLY,
  HANDSON,
  CLASSROOM,
} from "../../globals/Data";
import * as logger from "../../globals/Logger";
import firebase from "firebase/app";
import "firebase/storage";

export const INIT_COURSE_ID = "INIT_COURSE_ID";
export const ADD_COURSE = "ADD_COURSE";
export const UPDATE_COURSE = "UPDATE_COURSE";
export const REMOVE_DRAFT = "REMOVE_DRAFT";
export const ACTIVATE_COURSE = "ACTIVATE_COURSE";
export const SET_COURSES = "SET_COURSES";
export const SET_COURSES_HISTORY = "SET_COURSES_HISTORY";
export const UPDATE_CAPACITY = "UPDATE_CAPACITY";
export const SET_ACTIVE_TAB = "SET_ACTIVE_TAB";
export const UPDATE_SEL_COURSE_REFUND_VALUE = "UPDATE_SEL_COURSE_REFUND_VALUE";
export const SET_REGISTRATION_CONTENT = "SET_REGISTRATION_CONTENT";
export const SET_SELECTED_CERTIFICATE = "SET_SELECTED_CERTIFICATE";
export const CLEAR_SURVEY_RESULT_COUNT = "CLEAR_SURVEY_RESULT_COUNT";
export const SET_MOBILE_SEARCH_COURSES = "SET_MOBILE_SEARCH_COURSES";
export const SELECT_MOBILE_COURSE = "SELECT_MOBILE_COURSE";
export const SET_FEATURED = "SET_FEATURED";
export const SET_NEWLY = "SET_NEWLY";
export const SET_BANNER = "SET_BANNER";
export const SET_TRENDING = "SET_TRENDING";
export const SET_RECOMMEND = "SET_RECOMMEND";
export const UPDATE_COURSE_CERTIFICATE = "UPDATE_COURSE_CERTIFICATE";
export const SET_CATEOGRY_INDEX = "SET_CATEOGRY_INDEX";
export const SET_COURSE_IMAGE = "SET_COURSE_IMAGE";

export const SET_SELECTED_COURSES = "SET_SELECTED_COURSES";
export const SET_ACTIVE_COURSE = "SET_ACTIVE_COURSE";
export const SET_ACTIVE_COURSES = "SET_ACTIVE_COURSES";

export const SET_DRAFT_COURSES = "SET_DRAFT_COURSES";
export const SET_HISTORY_COURSES = "SET_HISTORY_COURSES";
export const SET_PROVIDER_COURSES_META = "SET_PROVIDER_COURSES_META";
export const SET_COURSE_VIDEO_CHAPTERS = "SET_COURSE_VIDEO_CHAPTERS";

const PAGE_SIZE = 20;

// ------------------------------

export const updateCourseCertificate = (courseId, cert) => {
  return {
    type: UPDATE_COURSE_CERTIFICATE,
    certificate: { cert: cert, courseId: courseId },
  };
};

// ------------------------------

export const setActiveTab = (activeTab) => {
  return {
    type: SET_ACTIVE_TAB,
    activeTab: activeTab
  };
};


export const setSelectedCourses = (courses) => {
  return {
    type: SET_SELECTED_COURSES,
    courses: courses,
  };
};

// ------------------------------

export const initAdd = () => {
  return {
    type: INIT_COURSE_ID,
    courseId: "new",
  };
};

// ------------------------------

export const initEdit = (id) => {
  return {
    type: INIT_COURSE_ID,
    courseId: id,
  };
};

// ------------------------------

// legacy
export const fetchCourses = () => {
  return async (dispatch, getState) => {
    const userId = getState().auth.userId;
    const token = getState().auth.token;

    const arrResponse = await Promise.all([
      fetch(
        `https://firestore.googleapis.com/v1/projects/${dbName}/databases/(default)/documents:runQuery`,
        {
          method: "POST",
          headers: {
            "Content-Type": "applications/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(fetchCourseQuery("courses", userId)),
        }
      ),
      fetch(
        `https://firestore.googleapis.com/v1/projects/${dbName}/databases/(default)/documents/drafts/${userId}:runQuery`,
        {
          method: "POST",
          headers: {
            "Content-Type": "applications/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            structuredQuery: {
              from: [{ collectionId: "course_drafts" }],
              limit: 1000,
            },
          }),
        }
      ),
      fetch(
        `https://firestore.googleapis.com/v1/projects/${dbName}/databases/(default)/documents/courses_history/${userId}:runQuery`,
        {
          method: "POST",
          headers: {
            "Content-Type": "applications/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            structuredQuery: {
              from: [{ collectionId: "history" }],
              limit: 1000,
            },
          }),
        }
      ),
      fetch(
        `https://firestore.googleapis.com/v1/projects/${dbName}/databases/(default)/documents/registration/${userId}:runQuery`,
        {
          method: "POST",
          headers: {
            "Content-Type": "applications/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            structuredQuery: {
              from: [{ collectionId: "course" }],
              limit: 1000,
            },
          }),
        }
      ),
      fetch(
        `https://firestore.googleapis.com/v1/projects/${dbName}/databases/(default)/documents/registration_content/${userId}:runQuery`,
        {
          method: "POST",
          headers: {
            "Content-Type": "applications/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            structuredQuery: {
              from: [{ collectionId: "course" }],
              limit: 1000,
            },
          }),
        }
      ),
    ]);

    //  const courseRes = await
    if (!arrResponse[0].ok) {
      handleErrorResponse(await arrResponse[0].json());
    }

    //  const draftRes = await
    if (!arrResponse[1].ok) {
      handleErrorResponse(await arrResponse[1].json());
    }

    ///  const historyRes = await
    if (!arrResponse[2].ok) {
      handleErrorResponse(await arrResponse[2].json());
    }

    //  const courseViewRes = await
    if (!arrResponse[3].ok) {
      handleErrorResponse(await arrResponse[3].json());
    }

    if (!arrResponse[4].ok) {
      handleErrorResponse(await arrResponse[4].json());
    }

    const lastViewed = [];
    const revenu = [];
    const courseViewResData = await arrResponse[3].json();

    for (const documents of courseViewResData) {
      const viewedDateDoc = documents.document;

      if (viewedDateDoc && viewedDateDoc.name) {
        const registrationCourseId = firebaseNameToId(viewedDateDoc.name);

        if (viewedDateDoc.fields.lastViewed) {
          lastViewed[registrationCourseId] =
            viewedDateDoc.fields.lastViewed.timestampValue;
        }

        revenu[registrationCourseId] = viewedDateDoc.fields.revenu
          ? fromFirebaseDoubleValue(
            viewedDateDoc.fields.revenu.doubleValue,
            viewedDateDoc.fields.revenu.integerValue
          )
          : 0;
      }
    }

    const surveyResultCount = [];
    const surveyResCountData = await arrResponse[4].json();

    for (const documents of surveyResCountData) {
      const surveyResCountDoc = documents.document;

      if (surveyResCountDoc && surveyResCountDoc.name) {
        const surveyCourseId = firebaseNameToId(surveyResCountDoc.name);

        if (surveyResCountDoc.fields.survey_result_count) {
          surveyResultCount[surveyCourseId] = Number(surveyResCountDoc.fields.survey_result_count.integerValue);
        }
      }
    }

    const loadedCourses = buildCourses(await arrResponse[0].json());
    const loadedDrafts = buildCourses(await arrResponse[1].json());
    const loadedHistory = buildCourses(await arrResponse[2].json());

    const capacity = [];
    for (const course of loadedCourses) {
      capacity[course.id] = 0; //await fetchStudentCount(
      // lastViewed[course.id] ? lastViewed[course.id] : null,
      // token,
      // userId,
      // course.id
      // );
    }

    dispatch({
      type: SET_COURSES,
      courses: {
        capacity: capacity,
        courses: loadedCourses.sort((a, b) => {
          if (a.start_date[0] && b.start_date[0]) {
            return a.start_date[0].getTime() > b.start_date[0].getTime()
              ? 1
              : -1;
          }
          else if (a.start_date[0] && !b.start_date[0]) {
            return -1
          }
          else if (!a.start_date[0] && b.start_date[0]) {
            return 1
          }
          return 1;
        }),
        drafts: loadedDrafts,
        courses_history: loadedHistory,
        revenu: revenu,
        survey_result_count: surveyResultCount
      },
    });
  };
};


// ------------------------------

export const fetchProviderCourse = (courseId) => {
  return async (dispatch, getState) => {
    const userId = getState().auth.userId;
    const token = getState().auth.token;

    const arrResponse = await fetch(
      `https://firestore.googleapis.com/v1/projects/${dbName}/databases/(default)/documents/courses/${courseId}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "applications/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );

    if (!arrResponse.ok) {
      handleErrorResponse(await arrResponse.json());
    }

    const course = buildSingleCourse(await arrResponse.json());

    const capacity = [];



    dispatch({
      type: SET_ACTIVE_COURSE,
      courses: {
        courses: [course]
      },
    });
  };
};


// ------------------------------

export const fetchActiveCoursesMetadata = () => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;
    const userId = getState().auth.userId;

    const arrResponse = await Promise.all([
      fetch(
        `https://firestore.googleapis.com/v1/projects/${dbName}/databases/(default)/documents/courses_metadata/${userId}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "applications/json",
            Authorization: `Bearer ${token}`,
          },
        }
      ),
      fetch(
        `https://firestore.googleapis.com/v1/projects/${dbName}/databases/(default)/documents/courses_history/${userId}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "applications/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
    ])

    let resData = null;
    if (!arrResponse[0].ok) {
      //handleErrorResponse(await arrResponse[0].json());
    }
    else {
      resData = await arrResponse[0].json();
    }

    let resData2 = null;
    if (!arrResponse[1].ok) {
      // handleErrorResponse(await arrResponse[1].json());
    }
    else {
      resData2 = await arrResponse[1].json();
    }

    const providerCoursesMeta = new ProviderCoursesMeta(
      resData && resData.fields && resData.fields.documents
        ? resData.fields.documents.integerValue
        : 0,
      resData && resData.fields && resData.fields.in_person
        ? resData.fields.in_person.integerValue
        : 0,
      resData && resData.fields && resData.fields.livestream
        ? resData.fields.livestream.integerValue
        : 0,
      resData && resData.fields && resData.fields.recorded_video
        ? resData.fields.recorded_video.integerValue
        : 0,
      resData2 && resData2.fields && resData2.fields.documents
        ? resData2.fields.documents.integerValue
        : 0,
      resData2 && resData2.fields && resData2.fields.in_person
        ? resData2.fields.in_person.integerValue
        : 0,
      resData2 && resData2.fields && resData2.fields.livestream
        ? resData2.fields.livestream.integerValue
        : 0,
      resData2 && resData2.fields && resData2.fields.recorded_video
        ? resData2.fields.recorded_video.integerValue
        : 0,
    )

    dispatch({
      type: SET_PROVIDER_COURSES_META,
      providerCoursesMeta: providerCoursesMeta
    });
  };
};

// ------------------------------

export const fetchActiveCourses = (filters) => {
  return async (dispatch, getState) => {
    const userId = getState().auth.userId;
    const token = getState().auth.token;

    const courseQuery = fetchCourseQuery("courses", userId);
    const newCourseQuery = appendFilterCondition(courseQuery, filters)

    const arrResponse = await Promise.all([fetch(
      `https://firestore.googleapis.com/v1/projects/${dbName}/databases/(default)/documents:runQuery`,
      {
        method: "POST",
        headers: {
          "Content-Type": "applications/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(newCourseQuery),
      }
    ),
    fetch(
      `https://firestore.googleapis.com/v1/projects/${dbName}/databases/(default)/documents/registration/${userId}:runQuery`,
      {
        method: "POST",
        headers: {
          "Content-Type": "applications/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          structuredQuery: {
            from: [{ collectionId: "course" }],
            limit: 1000,
          },
        }),
      }
    ),
    fetch(
      `https://firestore.googleapis.com/v1/projects/${dbName}/databases/(default)/documents/registration_content/${userId}:runQuery`,
      {
        method: "POST",
        headers: {
          "Content-Type": "applications/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          structuredQuery: {
            from: [{ collectionId: "course" }],
            limit: 1000,
          },
        }),
      }
    )]);

    if (!arrResponse[0].ok) {
      handleErrorResponse(await arrResponse[0].json());
    }
    if (!arrResponse[1].ok) {
      handleErrorResponse(await arrResponse[1].json());
    }
    if (!arrResponse[2].ok) {
      handleErrorResponse(await arrResponse[2].json());
    }

    const revenu = [];
    const newRegistrationCount = [];
    const capacity = [];
    const surveyResultCount = [];

    const loadedCourses = buildCourses(await arrResponse[0].json());
    const regMetaResData = await arrResponse[1].json();

    for (const regMetaDocs of regMetaResData) {
      const metaDoc = regMetaDocs.document;

      if (metaDoc && metaDoc.name) {
        const registrationCourseId = firebaseNameToId(metaDoc.name);

        if (metaDoc.fields.new_registration && metaDoc.fields.new_registration.integerValue > 0) {
          newRegistrationCount[registrationCourseId] =
            metaDoc.fields.new_registration.integerValue;
        }

        revenu[registrationCourseId] = metaDoc.fields.revenu
          ? fromFirebaseDoubleValue(
            metaDoc.fields.revenu.doubleValue,
            metaDoc.fields.revenu.integerValue
          )
          : 0;
      }
    }

    const surveyResCountData = await arrResponse[2].json();

    for (const documents of surveyResCountData) {
      const surveyResCountDoc = documents.document;

      if (surveyResCountDoc && surveyResCountDoc.name) {
        const surveyCourseId = firebaseNameToId(surveyResCountDoc.name);

        if (surveyResCountDoc.fields.survey_result_count) {
          surveyResultCount[surveyCourseId] = Number(surveyResCountDoc.fields.survey_result_count.integerValue);
        }
      }
    }

    for (const course of loadedCourses) {
      capacity[course.id] = await fetchStudentCount(
        token,
        userId,
        course.id,
        newRegistrationCount[course.id] ? newRegistrationCount[course.id] : 0
      );
    }

    dispatch({
      type: SET_ACTIVE_COURSES,
      courses: {
        courses: sortCoursesByEventDate(loadedCourses),
        capacity: capacity,
        revenu: revenu,
        survey_result_count: surveyResultCount
      },
    });
  };
};

// ------------------------------

export const fetchDraftCourses = () => {
  return async (dispatch, getState) => {
    const userId = getState().auth.userId;
    const token = getState().auth.token;

    const arrResponse = await fetch(
      `https://firestore.googleapis.com/v1/projects/${dbName}/databases/(default)/documents/drafts/${userId}:runQuery`,
      {
        method: "POST",
        headers: {
          "Content-Type": "applications/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          structuredQuery: {
            from: [{ collectionId: "course_drafts" }],
            limit: 1000,
          },
        }),
      }
    )

    if (!arrResponse.ok) {
      handleErrorResponse(await arrResponse.json());
    }

    const loadedDrafts = buildCourses(await arrResponse.json());

    dispatch({
      type: SET_DRAFT_COURSES,
      courses: {
        courses: sortCoursesByEventDate(loadedDrafts),
      },
    });
  };
};

// ------------------------------

export const fetchHistoryCourses = (filters) => {
  return async (dispatch, getState) => {
    const userId = getState().auth.userId;
    const token = getState().auth.token;

    const courseQuery = {
      structuredQuery: {
        from: [{ collectionId: "history" }],
        limit: 1000,
      }
    }

    const newCourseQuery = appendFilterCondition(courseQuery, filters)

    const arrResponse = await fetch(`https://firestore.googleapis.com/v1/projects/${dbName}/databases/(default)/documents/courses_history/${userId}:runQuery`,
      {
        method: "POST",
        headers: {
          "Content-Type": "applications/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(newCourseQuery),
      }
    )

    if (!arrResponse.ok) {
      handleErrorResponse(await arrResponse.json());
    }

    const loaded = buildCourses(await arrResponse.json());

    dispatch({
      type: SET_HISTORY_COURSES,
      courses: {
        courses: sortCoursesByEventDate(loaded),
      },
    });
  };
};


// ------------------------------

const sortCoursesByEventDate = (loadedCourses) => {
  return loadedCourses.sort((a, b) => {
    if (a.start_date[0] && b.start_date[0]) {
      return a.start_date[0].getTime() > b.start_date[0].getTime()
        ? 1
        : -1;
    }
    else if (a.start_date[0] && !b.start_date[0]) {
      return -1
    }
    else if (!a.start_date[0] && b.start_date[0]) {
      return 1
    }
    return 1;
  })
}

// ------------------------------

const fetchStudentCount = async (token, userId, courseId, newStudentCount) => {
  const resStudents = await fetch(
    `https://firestore.googleapis.com/v1/projects/${dbName}/databases/(default)/documents/registration/${userId}/course/${courseId}:runQuery`,
    {
      method: "POST",
      headers: {
        "Content-Type": "applications/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        structuredQuery: {
          from: [{ collectionId: "students" }],
          limit: 1000,
        },
      }),
    }
  );

  if (!resStudents.ok) {
    handleErrorResponse(await resStudents.json());
  }

  const resStudentDocs = await resStudents.json();

  let onlineCount = 0;
  let classroomLectureCount = 0;
  let classroomHandsonCount = 0;

  for (const documents of resStudentDocs) {
    const student = documents.document;

    if (student && student.name) {
      if (
        student.fields.casting &&
        (student.fields.casting.stringValue === LIVESTREAM ||
          student.fields.casting.stringValue === ONLINE_VIDEO)
      ) {
        onlineCount++;
      } else {
        if (
          student.fields.casting &&
          student.fields.component &&
          student.fields.casting.stringValue === CLASSROOM &&
          student.fields.component.stringValue === LECTURE_ONLY
        ) {
          classroomLectureCount++;
        } else if (
          student.fields.casting &&
          student.fields.component &&
          student.fields.casting.stringValue === CLASSROOM &&
          student.fields.component.stringValue === HANDSON
        ) {
          classroomLectureCount++;
          classroomHandsonCount++;
        }
      }
    }
  }

  return {
    classroomLectureCount: classroomLectureCount,
    classroomHandsonCount: classroomHandsonCount,
    onlineCount: onlineCount,
    newStudentCount: newStudentCount
  };
};

// ------------------------------

export const clearSurveyResultCount = (courseId) => {
  return async (dispatch, getState) => {
    const userId = getState().auth.userId;
    const token = getState().auth.token;

    const response = await fetch(
      `https://firestore.googleapis.com/v1/projects/${dbName}/databases/(default)/documents/registration_content/${userId}/course/${courseId}?updateMask.fieldPaths=survey_result_count`,
      {
        method: "PATCH",
        headers: {
          "Content-Type": "applications/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          fields: {
            survey_result_count: { integerValue: 0 },
          },
        }),
      }
    );

    if (!response.ok) {
      handleErrorResponse(await response.json());
    }

    dispatch({
      type: CLEAR_SURVEY_RESULT_COUNT,
      courseId: courseId,
    });
  };
};

// ------------------------------

export const addDraft = (course) => {
  return async (dispatch, getState) => {
    const userId = getState().auth.userId;
    const token = getState().auth.token;
    const providerName = getState().profile.profile.providerName;
    const cancel_policy = getState().profile.profile.cancel_policy;
    const email = getState().auth.email;
    const response = await fetch(
      `https://firestore.googleapis.com/v1/projects/${dbName}/databases/(default)/documents/drafts/${userId}/course_drafts/`,
      {
        method: "POST",
        headers: {
          "Content-Type": "applications/json",
          Authorization: `Bearer ${token}`,
        },
        body: prepareCourseForSave(
          course,
          userId,
          providerName,
          email,
          cancel_policy
        ),
      }
    );

    if (!response.ok) {
      handleErrorResponse(await response.json());
    }

    const resData = await response.json();
    const idPathName = resData.name.split("/");
    const courseId = idPathName[idPathName.length - 1];

    dispatch(initEdit(courseId));

    dispatch({
      type: ADD_COURSE,
      course: { course: course, id: courseId },
    });

    await dispatchUploadHandouts(dispatch, courseId, course.handout, false);

    if (course.image && course.image.startsWith("blob:")) {
      await dispatch(uploadDeleteCourseImage(course.image, { id: courseId }));
    }
  };
};

// ------------------------------

export const updateDraft = (course) => {
  return async (dispatch, getState) => {
    const courseId = course.id;
    const userId = getState().auth.userId;
    const token = getState().auth.token;
    const providerName = getState().profile.profile.providerName;
    const email = getState().profile.profile.contact_email;
    const cancel_policy = getState().profile.profile.cancel_policy;

    const response = await fetch(
      `https://firestore.googleapis.com/v1/projects/${dbName}/databases/(default)/documents/drafts/${userId}/course_drafts/${courseId}`,
      {
        method: "PATCH",
        headers: {
          "Content-Type": "applications/json",
          Authorization: `Bearer ${token}`,
        },
        body: prepareCourseForSave(
          course,
          userId,
          providerName,
          email,
          cancel_policy
        ),
      }
    );

    if (!response.ok) {
      handleErrorResponse(await response.json());
    }

    dispatch({
      type: UPDATE_COURSE,
      course: course,
    });

    if (course.is_video) {
      await dispatch(updateRecordedVideo(course.id, false, course.video_chapters));
    }

    await dispatchUploadHandouts(dispatch, courseId, course.handout, false);

    if (course.image && course.image.startsWith("blob:")) {
      await dispatch(uploadDeleteCourseImage(course.image, { id: courseId }));
    }
  };
};

// ------------------------------

const fetchRecordedVideoHelper = async (providerId, token, courseId, isActiveCourse) => {
  let fetchQuery = '';

  if (isActiveCourse) {
    fetchQuery = `https://firestore.googleapis.com/v1/projects/${dbName}/databases/(default)/documents/registration_content/${providerId}/course/${courseId}:runQuery`
  } else {
    fetchQuery = `https://firestore.googleapis.com/v1/projects/${dbName}/databases/(default)/documents/drafts/${providerId}/course_drafts/${courseId}:runQuery`
  }

  const arrResponse = await fetch(fetchQuery,
    {
      method: "POST",
      headers: {
        "Content-Type": "applications/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        structuredQuery: {
          from: [{ collectionId: "recorded_video" }],
          limit: 1000,
        },
      }),
    }
  )

  if (!arrResponse.ok) {
    handleErrorResponse(await arrResponse.json());
  }

  return await arrResponse.json();
}

// ------------------------------

export const fetchRecordedVideo = (courseId, isActiveCourse) => {
  return async (dispatch, getState) => {
    const userId = getState().auth.userId;
    const token = getState().auth.token;

    console.log(isActiveCourse)

    const resData = await fetchRecordedVideoHelper(userId, token, courseId, isActiveCourse);

    console.log(courseId);
    console.log(resData);

    const chapters = [];

    if (resData && Object.keys(resData).length !== 0) {
      for (const documents of resData) {
        const chapter = documents.document;

        if (chapter) {
          chapters.push(new VideoChapter(
            firebaseNameToId(chapter.name),
            chapter.fields.uri ? chapter.fields.uri.stringValue : "",
            chapter.fields.title ? chapter.fields.title.stringValue : "",
            chapter.fields.description ? chapter.fields.description.stringValue : "",
            chapter.fields.duration ? chapter.fields.duration.integerValue : 0,
            chapter.fields.sequence ? chapter.fields.sequence.integerValue : 0
          ));
        }
      }
    }

    dispatch({
      type: SET_COURSE_VIDEO_CHAPTERS,
      isActiveCourse: isActiveCourse,
      courseId: courseId,
      video_chapters: chapters.sort((a, b) => a.sequence > b.sequence ? 1 : -1)
    });
  };
};

// ------------------------------

export const updateRecordedVideo = (courseId, isActiveCourse, videoChapters) => {
  return async (dispatch, getState) => {
    const userId = getState().auth.userId;
    const token = getState().auth.token;

    // clear list ----------------------

    const existingRecVideoResData = await fetchRecordedVideoHelper(userId, token, courseId, isActiveCourse);

    const arrToDelete = [];

    if (existingRecVideoResData && existingRecVideoResData.length > 0) {
      for (const res of existingRecVideoResData) {
        if (res.document) {
          const docId = firebaseNameToId(res.document.name);

          let deleteQuery = '';

          if (isActiveCourse) {
            deleteQuery = `https://firestore.googleapis.com/v1/projects/${dbName}/databases/(default)/documents/registration_content/${userId}/course/${courseId}/recorded_video/${docId}`
          } else {
            deleteQuery = `https://firestore.googleapis.com/v1/projects/${dbName}/databases/(default)/documents/drafts/${userId}/course_drafts/${courseId}/recorded_video/${docId}`
          }

          arrToDelete.push(fetch(deleteQuery,
            {
              method: "DELETE",
              headers: {
                "Content-Type": "applications/json",
                Authorization: `Bearer ${token}`,
              },
            }
          ))
        }
      }
    }

    const arrResponse = await Promise.all(arrToDelete);

    for (const response of arrResponse) {
      if (!response.ok) {
        handleErrorResponse(await response.json());
      }
    }

    // -----------------------------------

    let index = 0;

    const video_chapters = videoChapters ? videoChapters : [];

    let insertQuery = '';

    if (isActiveCourse) {
      insertQuery = `https://firestore.googleapis.com/v1/projects/${dbName}/databases/(default)/documents/registration_content/${userId}/course/${courseId}/recorded_video/`
    } else {
      insertQuery = `https://firestore.googleapis.com/v1/projects/${dbName}/databases/(default)/documents/drafts/${userId}/course_drafts/${courseId}/recorded_video/`
    }

    for (const chapter of video_chapters) {
      const response = await fetch(insertQuery,
        {
          method: "POST",
          headers: {
            "Content-Type": "applications/json",
            Authorization: `Bearer ${token}`,
          },
          body:
            JSON.stringify({
              fields: {
                uri: { stringValue: chapter.uri },
                title: { stringValue: chapter.title ? chapter.title : "" },
                description: { stringValue: chapter.description ? chapter.description : "" },
                duration: { integerValue: chapter.duration ? chapter.duration : 0 },
                sequence: { integerValue: index }
              },
            }),
        }
      );

      if (!response.ok) {
        handleErrorResponse(await response.json());
      }

      index++;
    }

    await dispatch(fetchRecordedVideo(courseId, isActiveCourse));
  };
};

// ------------------------------

const dispatchUploadHandouts = async (
  dispatch,
  draftId,
  handouts,
  isActiveCourse
) => {
  const hasNewHandout = handouts.some(function (handout) {
    return handout.file;
  });

  if (hasNewHandout) {
    let index = 0;
    for (const h of handouts) {
      if (h.file) {
        await dispatch(uploadPdf(handouts, draftId, index, isActiveCourse));
      }
      index++;
    }
  }
};

// ------------------------------

export const removeDraft = (courseId) => {
  return async (dispatch, getState) => {
    const userId = getState().auth.userId;
    const token = getState().auth.token;

    const response = await fetch(
      `https://firestore.googleapis.com/v1/projects/${dbName}/databases/(default)/documents/drafts/${userId}/course_drafts/${courseId}`,
      {
        method: "DELETE",
        headers: {
          "Content-Type": "applications/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );

    if (!response.ok) {
      handleErrorResponse(await response.json());
    }

    dispatch({
      type: REMOVE_DRAFT,
      draft: { id: courseId },
    });

    // TODO remove handouts
    // TODO remove cover photo
    // TODO remove recorded video if any
  };
};

// ------------------------------

export const activateCourse = (course) => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;
    const professor = getState().professor.professor;
    const cancel_policy = getState().profile.profile.cancel_policy;
    const email = getState().profile.profile.contact_email;
    const latestSurveys = getState().survey.survey;

    if (course.survey && latestSurveys) {
      const matchSurvey = latestSurveys.find((s) => s.id === course.survey.id);
      if (matchSurvey) {
        course.survey = new Survey(
          matchSurvey.id,
          matchSurvey.title,
          matchSurvey.text,
          matchSurvey.expiration_days
        );
      }
    }

    let index = 0;
    for (const prof of course.professor) {
      const fullProfessor = professor.find((p) => p.id === prof.id);
      if (fullProfessor) {
        course.professor[index] = { ...fullProfessor };
      }
      index++;
    }

    await dispatch(updateDraft(course));

    // ------------------------------

    const response = await fetch(
      `https://us-central1-${dbName}.cloudfunctions.net/activateCourse`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          data: {
            draftId: course.id,
            professor: course.professor,
            cancel_policy: cancel_policy,
            email: email,
          },
        }),
      }
    );

    if (!response.ok) {
      handleErrorResponse(await response.json());
    }

    dispatch({
      type: ACTIVATE_COURSE,
    });
  };
};

// ------------------------------

export const activateAmendment = (courseId, amendment) => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;

    const response = await fetch(
      `https://us-central1-${dbName}.cloudfunctions.net/activateAmendment`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          data: { courseId: courseId, amendment: amendment },
        }),
      }
    );

    if (!response.ok) {
      handleErrorResponse(await response.json());
    }
  };
};

// ------------------------------

export const updateCapacity = (course, seats) => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;

    const response = await fetch(
      `https://us-central1-${dbName}.cloudfunctions.net/updateCapacity`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          data: { courseId: course.id, seats: seats },
        }),
      }
    );

    if (!response.ok) {
      handleErrorResponse(await response.json());
    }

    dispatch({
      type: UPDATE_CAPACITY,
      course: course,
      seats: seats,
    });
  };
};

// ------------------------------

const prepareCourseForSave = (
  course,
  userId,
  providerName,
  email,
  cancel_policy
) => {
  return JSON.stringify({
    fields: {
      reference_number: { stringValue: course.reference_number }, // auto generated number
      image: { stringValue: course.image ? course.image : "" },
      topic_number: { stringValue: course.topic_number ? course.topic_number : "" },
      provider_id: { stringValue: userId },
      provider_name: {
        stringValue: providerName ? providerName : course.provider_name,
      }, // must be injected
      is_specialty: {
        booleanValue: course.is_specialty,
      },
      category: {
        arrayValue: {
          values: toFirebaseStringArrayValue(course.category),
        },
      },
      accreditation: {
        arrayValue: {
          values: toFirebaseStringArrayValue(course.accreditation),
        },
      },
      title: { stringValue: course.title },
      description: { stringValue: course.description },
      start_date_desc: {
        arrayValue: {
          values: toFirebaseStringArrayValue(course.start_date_desc),
        },
      },
      start_date: {
        arrayValue: {
          values: toFirebaseDateArrayValue(course.start_date),
        },
      },
      end_date: {
        arrayValue: {
          values: toFirebaseDateArrayValue(course.end_date),
        },
      },
      objectives: { stringValue: course.objectives },
      professor: {
        arrayValue: {
          values: toFirebaseProfessorArrayMap(course.professor),
        },
      },
      location: { stringValue: course.location },
      address: {
        arrayValue: {
          values: toFirebaseStringArrayValue(course.address),
        },
      },
      notes: { stringValue: course.notes },
      is_university_associated: {
        booleanValue: course.is_university_associated,
      },
      language: { stringValue: course.language },
      seats_classroom: { integerValue: course.seats_classroom },
      seats_online: { integerValue: course.seats_online },
      suite: { stringValue: course.suite },
      email: { stringValue: email ? email : course.email ? course.email : "" },
      geo_lat: { doubleValue: course.geo_lat },
      geo_lng: { doubleValue: course.geo_lng },
      geo_hash: { stringValue: course.geo_hash },
      timezoneAbbr: {
        stringValue: course.timezoneAbbr ? course.timezoneAbbr : "",
      },
      cancel_policy: {
        stringValue: cancel_policy
          ? cancel_policy
          : course.cancel_policy
            ? course.cancel_policy
            : "",
      },
      pricing_items: {
        arrayValue: {
          values: toFirebaseArrayMap(course.pricingItems),
        },
      },
      seats_handson: { integerValue: course.seats_handson },
      is_video: { booleanValue: course.is_video },
      email_instruction: { stringValue: course.email_instruction },
      survey: {
        arrayValue: {
          values: course.survey
            ? [
              { stringValue: course.survey.id },
              { stringValue: course.survey.title },
              { stringValue: course.survey.text },
              {
                integerValue: course.survey.expiration_days
                  ? course.survey.expiration_days
                  : 0,
              },
            ]
            : null,
        },
      },
      youtube_uri: { stringValue: course.youtube_uri },
      //-----
      multi_pay_option: {
        mapValue: {
          fields: {
            enabled: { booleanValue: course.multi_pay_option.enabled },
            first_pay_percent: {
              integerValue: course.multi_pay_option.firstPayPercent,
            },
            installment_count: {
              integerValue: course.multi_pay_option.installmentCount,
            },
          },
        },
      },
      //-----
      handout: {
        arrayValue: {
          values: toHandoutArrayMap(course.handout),
        },
      },
      handout_delivery: { stringValue: course.handout_delivery },
      meeting_id: { integerValue: course.meeting_id ? course.meeting_id : 0 },
      certificate: {
        arrayValue: {
          values: course.certificate
            ? [
              { stringValue: course.certificate.id },
              { stringValue: course.certificate.title },
            ]
            : null,
        },
      },
    },
  });
};

// ------------------------------

const toFirebaseProfessorArrayMap = (array) => {
  let firebaseArray = [];
  for (var v of array) {
    firebaseArray.push({
      mapValue: {
        fields: {
          bio: { stringValue: v.bio },
          id: { stringValue: v.id },
          prefix: { stringValue: v.prefix },
          first: { stringValue: v.first },
          name: { stringValue: v.name },
          occupation: { stringValue: v.occupation },
          picture: { stringValue: v.picture },
        },
      },
    });
  }

  return firebaseArray;
};

// ------------------------------

const toFirebaseArrayMap = (array) => {
  let firebaseArray = [];
  for (var v of array) {
    if (!v.expiration_date) {
      firebaseArray.push({
        mapValue: {
          fields: {
            casting: { stringValue: v.casting },
            audience: {
              arrayValue: {
                values: toFirebaseStringArrayValue(v.audience),
              },
            },
            component: { stringValue: v.component },
            description: { stringValue: v.description },
            ce_odq: { doubleValue: v.ce_odq },
            credit_lecture_hours: { doubleValue: v.credit_lecture_hours },
            credit_handson_hours: { doubleValue: v.credit_handson_hours },
            cost: { doubleValue: v.cost },
            members_only: { booleanValue: v.members_only },
          },
        },
      });
    } else {
      firebaseArray.push({
        mapValue: {
          fields: {
            casting: { stringValue: v.casting },
            audience: {
              arrayValue: {
                values: toFirebaseStringArrayValue(v.audience),
              },
            },
            component: { stringValue: v.component },
            description: { stringValue: v.description },
            ce_odq: { doubleValue: v.ce_odq },
            credit_lecture_hours: { doubleValue: v.credit_lecture_hours },
            credit_handson_hours: { doubleValue: v.credit_handson_hours },
            cost: { doubleValue: v.cost },
            expiration_date: { timestampValue: v.expiration_date },
            members_only: { booleanValue: v.members_only },
          },
        },
      });
    }
  }

  return firebaseArray;
};

// ------------------------------

const toHandoutArrayMap = (array) => {
  let firebaseArray = [];
  for (var v of array) {
    firebaseArray.push({
      mapValue: {
        fields: {
          name: { stringValue: v.name },
          uri: { stringValue: v.uri ? v.uri : "" },
        },
      },
    });
  }

  return firebaseArray;
};

// ------------------------------

const fetchCourseQuery = (id, providerId) => {
  let structuredQuery = {
    structuredQuery: {
      from: [{ collectionId: id }],
    },
  };

  let condition = {};
  condition = { compositeFilter: { filters: [], op: "AND" } };
  const fieldFilter = stringFieldFilter("provider_id", "EQUAL", providerId);
  condition.compositeFilter.filters.push({ fieldFilter: fieldFilter });
  structuredQuery.structuredQuery.where = condition;
  return structuredQuery;
};

// ------------------------------

export const buildCourses = (resData) => {
  const loadedCourses = [];

  let arrDocs = [];
  if (Array.isArray(resData)) {
    arrDocs = resData;
  } else {
    arrDocs = resData.documents;
  }

  if (arrDocs && Object.keys(arrDocs).length !== 0) {
    for (const documents of arrDocs) {
      let course = documents.document;
      if (!course) {
        course = documents;
      }

      const singleCourse = buildSingleCourse(course);

      if (singleCourse) {
        loadedCourses.push(singleCourse);
      }
    }
  }

  return loadedCourses;
};

// ------------------------------

const buildSingleCourse = (course, idField, langSetting) => {
  try {
    if (
      course &&
      course.name &&
      course.fields
      //course.fields.activation_status &&
      //course.fields.activation_status.stringValue === "active"
    ) {
      // lang filter -----
      if (
        langSetting &&
        langSetting.indexOf(course.fields.language.stringValue) === -1
      ) {
        return null;
      }

      let courseId = "";
      if (idField) {
        courseId = course.fields[idField].stringValue;
      } else {
        const courseNameArray = course.name.split("/");
        courseId = courseNameArray[courseNameArray.length - 1];
      }

      return new Course(
        courseId,
        course.fields.activation ? course.fields.activation.stringValue : "",
        course.fields.reference_number
          ? course.fields.reference_number.stringValue
          : "",
        course.fields.provider_id.stringValue,
        course.fields.provider_name.stringValue,
        course.fields.is_specialty.booleanValue,
        fromFirebaseStringArrayValue(course.fields.category.arrayValue.values),
        fromFirebaseStringArrayValue(
          course.fields.accreditation.arrayValue.values
        ),
        course.fields.title.stringValue,
        course.fields.description.stringValue,
        course.fields.start_date_desc.arrayValue
          ? fromFirebaseStringArrayValue(
            course.fields.start_date_desc.arrayValue.values
          )
          : [],
        course.fields.start_date.arrayValue
          ? fromFirebaseDateArrayValue(
            course.fields.start_date.arrayValue.values
          )
          : [],
        course.fields.end_date.arrayValue
          ? fromFirebaseDateArrayValue(course.fields.end_date.arrayValue.values)
          : [],
        course.fields.objectives.stringValue,
        buildProfessors(course.fields.professor),
        course.fields.location.stringValue,
        fromFirebaseStringArrayValue(course.fields.address.arrayValue.values),
        course.fields.notes.stringValue,
        course.fields.is_university_associated.booleanValue,
        course.fields.language.stringValue,
        course.fields.seats_classroom
          ? fromFirebaseNumberValue(course.fields.seats_classroom.integerValue)
          : -1,
        course.fields.seats_online
          ? fromFirebaseNumberValue(course.fields.seats_online.integerValue)
          : -1,
        course.fields.suite ? course.fields.suite.stringValue : "",
        course.fields.email ? course.fields.email.stringValue : "",
        course.fields.geo_lat
          ? fromFirebaseDoubleValue(
            course.fields.geo_lat.doubleValue,
            course.fields.geo_lat.integerValue
          )
          : -1,
        course.fields.geo_lng
          ? fromFirebaseDoubleValue(
            course.fields.geo_lng.doubleValue,
            course.fields.geo_lng.integerValue
          )
          : -1,
        course.fields.geo_hash ? course.fields.geo_hash.stringValue : "",
        course.fields.cancel_policy
          ? course.fields.cancel_policy.stringValue
          : "",
        buildPricingItemsFromMapArray(
          course.fields.pricing_items &&
            course.fields.pricing_items.arrayValue &&
            course.fields.pricing_items.arrayValue.values
            ? course.fields.pricing_items.arrayValue.values
            : []
        ),
        buildAmendment(course.fields.amendment),
        course.fields.timezoneAbbr
          ? course.fields.timezoneAbbr.stringValue
          : "",
        course.fields.seats_handson
          ? fromFirebaseNumberValue(course.fields.seats_handson.integerValue)
          : -1,
        course.fields.is_video ? course.fields.is_video.booleanValue : false,
        course.fields.email_instruction
          ? course.fields.email_instruction.stringValue
          : "",
        course.fields.survey &&
          course.fields.survey.arrayValue &&
          course.fields.survey.arrayValue.values &&
          course.fields.survey.arrayValue.values.length > 0
          ? new Survey(
            course.fields.survey.arrayValue.values[0].stringValue,
            course.fields.survey.arrayValue.values[1].stringValue,
            course.fields.survey.arrayValue.values[2].stringValue,
            course.fields.survey.arrayValue.values[3]
              ? course.fields.survey.arrayValue.values[3].integerValue
              : 0
          )
          : null,
        course.fields.youtube_uri ? course.fields.youtube_uri.stringValue : "",
        course.fields.activation_status
          ? course.fields.activation_status.stringValue
          : "",
        buildMultiPayOption(course.fields.multi_pay_option),
        buildHandoutFromArrMap(
          course.fields.handout &&
            course.fields.handout.arrayValue &&
            course.fields.handout.arrayValue.values
            ? course.fields.handout.arrayValue.values
            : []
        ),
        course.fields.handout_delivery
          ? course.fields.handout_delivery.stringValue
          : "",
        course.fields.meeting_id ? course.fields.meeting_id.integerValue : 0,
        course.fields.rating
          ? fromFirebaseDoubleValue(
            Math.round(
              (course.fields.rating.doubleValue + Number.EPSILON) * 100
            ) / 100,
            course.fields.rating.integerValue
          )
          : 0,
        course.fields.rating_count
          ? fromFirebaseNumberValue(course.fields.rating_count.integerValue)
          : 0,
        course.fields.provider_currency
          ? course.fields.provider_currency.stringValue
          : "CAD",
        course.fields.certificate &&
          course.fields.certificate.arrayValue &&
          course.fields.certificate.arrayValue.values &&
          course.fields.certificate.arrayValue.values.length > 0
          ? new Certificate(
            course.fields.certificate.arrayValue.values[0].stringValue,
            course.fields.certificate.arrayValue.values[1].stringValue
          )
          : null,
        course.fields.image ? course.fields.image.stringValue : null,
        course.fields.topic_number ? course.fields.topic_number.stringValue : ""
      );
    }
  } catch (error) {
    logger.error(error);
    logger.error("Course" + course.name + " could not be loaded.");
    logger.error(course);
  }

  return null;
};

// ------------------------------

const buildMultiPayOption = (multiPayOption) => {
  if (multiPayOption && multiPayOption.mapValue) {
    const mapMultiPayOption = multiPayOption.mapValue.fields;

    return new MultiPayOption(
      mapMultiPayOption.enabled.booleanValue,
      mapMultiPayOption.first_pay_percent
        ? fromFirebaseDoubleValue(
          mapMultiPayOption.first_pay_percent.doubleValue,
          mapMultiPayOption.first_pay_percent.integerValue
        )
        : 30,
      mapMultiPayOption.installment_count
        ? fromFirebaseDoubleValue(
          mapMultiPayOption.installment_count.doubleValue,
          mapMultiPayOption.installment_count.integerValue
        )
        : 3
    );
  }

  return null;
};

// ------------------------------

const buildHandoutFromArrMap = (firebaseArrayOfMap) => {
  let handouts = [];

  for (var v of firebaseArrayOfMap) {
    const handout = v.mapValue.fields;
    handouts.push(
      new Handout(handout.name.stringValue, handout.uri.stringValue)
    );
  }

  return handouts;
};

// ------------------------------

const buildPricingItemsFromMapArray = (firebaseArrayOfMap) => {
  let coursePricingItems = [];
  for (var v of firebaseArrayOfMap) {
    const pricingItem = v.mapValue.fields;
    coursePricingItems.push(
      new PricingItem(
        pricingItem.casting.stringValue,
        fromFirebaseStringArrayValue(pricingItem.audience.arrayValue.values),
        pricingItem.component.stringValue,
        pricingItem.description.stringValue,
        fromFirebaseDoubleValue(
          pricingItem.ce_odq.doubleValue,
          pricingItem.ce_odq.integerValue
        ),
        fromFirebaseDoubleValue(
          pricingItem.credit_lecture_hours.doubleValue,
          pricingItem.credit_lecture_hours.integerValue
        ),
        fromFirebaseDoubleValue(
          pricingItem.credit_handson_hours.doubleValue,
          pricingItem.credit_handson_hours.integerValue
        ),
        fromFirebaseDoubleValue(
          pricingItem.cost.doubleValue,
          pricingItem.cost.integerValue
        ),
        pricingItem.expiration_date
          ? new Date(pricingItem.expiration_date.timestampValue)
          : null,
        pricingItem.members_only ? pricingItem.members_only.booleanValue : false
      )
    );
  }

  return coursePricingItems;
};

// ------------------------------

const buildAmendment = (amendment) => {
  const amendments = [];
  if (amendment && amendment.arrayValue) {
    const arrAmendment = amendment.arrayValue.values;

    if (Array.isArray(arrAmendment)) {
      for (const a of arrAmendment) {
        amendments.push(
          new Amendment(
            buildProfessors(a.mapValue.fields.professor),
            a.mapValue.fields.notes
              ? a.mapValue.fields.notes.stringValue
              : null,
            a.mapValue.fields.start_date
              ? a.mapValue.fields.start_date.timestampValue
              : null,
            a.mapValue.fields.discount
              ? fromFirebaseDoubleArrayValue(
                a.mapValue.fields.discount.arrayValue.values
              )
              : [],
            a.mapValue.fields.amended_date
              ? new Date(a.mapValue.fields.amended_date.timestampValue)
              : null
          )
        );
      }
    }
  }

  return amendments.sort((a, b) =>
    a.amended_date.getTime() < b.amended_date.getTime() ? 1 : -1
  );
};

// ------------------------------

const buildProfessors = (professor) => {
  const arrProfessors = [];
  if (professor && professor.arrayValue) {
    const arrProfessor = professor.arrayValue.values;

    if (Array.isArray(arrProfessor)) {
      for (const professor of arrProfessor) {
        arrProfessors.push(
          new Professor(
            professor.mapValue.fields.id
              ? professor.mapValue.fields.id.stringValue
              : "",
            professor.mapValue.fields.prefix
              ? professor.mapValue.fields.prefix.stringValue
              : "",
            professor.mapValue.fields.first
              ? professor.mapValue.fields.first.stringValue
              : "",
            professor.mapValue.fields.name
              ? professor.mapValue.fields.name.stringValue
              : "",
            professor.mapValue.fields.occupation
              ? professor.mapValue.fields.occupation.stringValue
              : "",
            professor.mapValue.fields.bio
              ? professor.mapValue.fields.bio.stringValue
              : "",
            professor.mapValue.fields.picture
              ? professor.mapValue.fields.picture.stringValue
              : "",
            professor.mapValue.fields.id
              ? professor.mapValue.fields.id.stringValue
              : ""
          )
        );
      }
    }
  }

  return arrProfessors;
};

// ------------------------------

export const cancelCourse = (courseId) => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;

    const response = await fetch(
      `https://us-central1-${dbName}.cloudfunctions.net/cancelCourse`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          data: {
            courseId: courseId,
          },
        }),
      }
    );

    if (!response.ok) {
      handleErrorResponse(await response.json());
    }

    await response.json();
  };
};

// ------------------------------

export const cancelCourseRefundValue = (courseId) => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;

    const response = await fetch(
      `https://us-central1-${dbName}.cloudfunctions.net/cancelCourseRefundValue`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          data: {
            courseId: courseId,
          },
        }),
      }
    );

    if (!response.ok) {
      handleErrorResponse(await response.json());
    }

    const resData = await response.json();

    if (resData && resData.result) {
      const registrations = resData.result.registrations;

      const uniqueReg = registrations.filter(
        (item, index) =>
          registrations.findIndex(
            (i) => i.orderRefNumber === item.orderRefNumber
          ) === index
      );

      dispatch({
        type: UPDATE_SEL_COURSE_REFUND_VALUE,
        cancelCourseRefundAmount: resData.result.totalToRefund,
        cancelCourseAffectedOrders: uniqueReg.map((o) => o.orderRefNumber),
      });
    } else {
      dispatch({
        type: UPDATE_SEL_COURSE_REFUND_VALUE,
        cancelCourseRefundAmount: 0,
        cancelCourseAffectedOrders: [],
      });
    }
  };
};

// ------------------------------

export const fetchRegistrationContent = (courseId, providerId) => {
  return async (dispatch, getState) => {
    const userId = getState().auth.userId;
    const token = getState().auth.token;
    let email_instruction = "";
    let survey = null;
    let handout = null;
    let handout_delivery = "";

    const documentId = providerId ? providerId : userId;

    const arrResponse = await fetch(
      `https://firestore.googleapis.com/v1/projects/${dbName}/databases/(default)/documents/registration_content/${documentId}/course/${courseId}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "applications/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );

    if (!arrResponse.ok) {
      const errResponse = await arrResponse.json();

      if (errResponse.error.status === "NOT_FOUND") {
        dispatch({
          type: SET_REGISTRATION_CONTENT,
          email_instruction: email_instruction,
          survey: survey,
        });
      } else {
        handleErrorResponse(errResponse);
      }
    } else {
      const instructionResult = await arrResponse.json();

      if (instructionResult && Object.keys(instructionResult).length > 0) {
        if (instructionResult.fields.email_instruction) {
          email_instruction =
            instructionResult.fields.email_instruction.stringValue;
        }

        if (instructionResult.fields.survey) {
          survey =
            instructionResult.fields.survey &&
              instructionResult.fields.survey.arrayValue &&
              instructionResult.fields.survey.arrayValue.values &&
              instructionResult.fields.survey.arrayValue.values.length > 0
              ? new Survey(
                instructionResult.fields.survey.arrayValue.values[0].stringValue,
                instructionResult.fields.survey.arrayValue.values[1].stringValue,
                instructionResult.fields.survey.arrayValue.values[2].stringValue,
                instructionResult.fields.survey.arrayValue.values[3]
                  ? instructionResult.fields.survey.arrayValue.values[3]
                    .integerValue
                  : 0
              )
              : null;
        }

        if (instructionResult.fields.handout) {
          handout = buildHandoutFromArrMap(
            instructionResult.fields.handout &&
              instructionResult.fields.handout.arrayValue &&
              instructionResult.fields.handout.arrayValue.values
              ? instructionResult.fields.handout.arrayValue.values
              : []
          );

          handout_delivery = instructionResult.fields.handout_delivery
            ? instructionResult.fields.handout_delivery.stringValue
            : "";
        }
      }

      dispatch({
        type: SET_REGISTRATION_CONTENT,
        email_instruction: email_instruction,
        selected_survey: survey,
        selected_handout: handout,
        selected_handout_delivery: handout_delivery,
      });
    }

    return email_instruction;
  };
};

// ------------------------------

export const fetchSelectedCertificate = (courseId) => {
  return async (dispatch, getState) => {
    let selected_certificate = null;
    const userId = getState().auth.userId;
    const token = getState().auth.token;

    const arrResponse = await fetch(
      `https://firestore.googleapis.com/v1/projects/${dbName}/databases/(default)/documents/courses/${courseId}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "applications/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );

    if (!arrResponse.ok) {
      const errResponse = await arrResponse.json();

      if (errResponse.error.status === "NOT_FOUND") {
        dispatch({
          type: SET_SELECTED_CERTIFICATE,
          selected_certificate: null,
        });
      } else {
        handleErrorResponse(errResponse);
      }
    } else {
      const result = await arrResponse.json();

      if (
        result &&
        Object.keys(result).length > 0 &&
        result.fields.certificate &&
        result.fields.certificate.arrayValue &&
        result.fields.certificate.arrayValue.values &&
        result.fields.certificate.arrayValue.values.length > 0
      ) {
        selected_certificate = new Certificate(
          result.fields.certificate.arrayValue.values[0].stringValue,
          result.fields.certificate.arrayValue.values[1].stringValue
        );
      }
    }

    dispatch({
      type: SET_SELECTED_CERTIFICATE,
      selected_certificate: selected_certificate,
    });
  };
};

// ------------------------------

export const updateRegistrationContent = (value, courseId, videoChapters) => {
  return async (dispatch, getState) => {
    const userId = getState().auth.userId;
    const token = getState().auth.token;

    const response = await fetch(
      `https://firestore.googleapis.com/v1/projects/${dbName}/databases/(default)/documents/registration_content/${userId}/course/${courseId}?updateMask.fieldPaths=email_instruction`,
      {
        method: "PATCH",
        headers: {
          "Content-Type": "applications/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          fields: {
            email_instruction: { stringValue: value },
          },
        }),
      }
    );

    if (!response.ok) {
      handleErrorResponse(await response.json());
    }

    if (videoChapters) {
      await dispatch(updateRecordedVideo(courseId, true, videoChapters));
    }
  };
};

// ------------------------------

export const updateAttachedSurvey = (survey, courseId) => {
  return async (dispatch, getState) => {
    const userId = getState().auth.userId;
    const token = getState().auth.token;

    const response = await fetch(
      `https://firestore.googleapis.com/v1/projects/${dbName}/databases/(default)/documents/registration_content/${userId}/course/${courseId}?updateMask.fieldPaths=survey`,
      {
        method: "PATCH",
        headers: {
          "Content-Type": "applications/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          fields: {
            survey: {
              arrayValue: {
                values: survey
                  ? [
                    { stringValue: survey.id },
                    { stringValue: survey.title },
                    { stringValue: survey.text },
                    {
                      integerValue: survey.expiration_days
                        ? survey.expiration_days
                        : 0,
                    },
                  ]
                  : null,
              },
            },
          },
        }),
      }
    );

    if (!response.ok) {
      handleErrorResponse(await response.json());
    }
  };
};

// ------------------------------

export const updateAttachedHandout = (handout, handout_delivery, courseId) => {
  return async (dispatch, getState) => {
    const userId = getState().auth.userId;
    const token = getState().auth.token;

    const response = await fetch(
      `https://firestore.googleapis.com/v1/projects/${dbName}/databases/(default)/documents/registration_content/${userId}/course/${courseId}` +
      `?updateMask.fieldPaths=handout&updateMask.fieldPaths=handout_delivery`,
      {
        method: "PATCH",
        headers: {
          "Content-Type": "applications/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          fields: {
            handout: {
              arrayValue: {
                values: toHandoutArrayMap(handout),
              },
            },
            handout_delivery: {
              stringValue: handout_delivery ? handout_delivery : "manual",
            },
          },
        }),
      }
    );

    if (!response.ok) {
      handleErrorResponse(await response.json());
    }

    await dispatchUploadHandouts(dispatch, courseId, handout, true);
  };
};

// ------------------------------

export const updateHandoutUri = (handout, courseId, isActiveCourse) => {
  return async (dispatch, getState) => {
    const userId = getState().auth.userId;
    const token = getState().auth.token;
    let saveUri = "";

    if (isActiveCourse) {
      saveUri = `https://firestore.googleapis.com/v1/projects/${dbName}/databases/(default)/documents/registration_content/${userId}/course/${courseId}?updateMask.fieldPaths=handout`;
    } else {
      saveUri = `https://firestore.googleapis.com/v1/projects/${dbName}/databases/(default)/documents/drafts/${userId}/course_drafts/${courseId}?updateMask.fieldPaths=handout`;
    }

    await fetch(saveUri, {
      method: "PATCH",
      headers: {
        "Content-Type": "applications/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        fields: {
          handout: {
            arrayValue: {
              values: toHandoutArrayMap(handout),
            },
          },
        },
      }),
    });
  };
};

// ------------------------------

const uploadPdf = (handout, courseId, index, isActiveCourse) => {
  const file = handout[index].file;
  const fileName = handout[index].name;

  return async (dispatch, getState) => {
    const userId = getState().auth.userId;

    if (file && file.type === "application/pdf") {
      let ref = firebase
        .storage()
        .ref()
        .child(`images/${userId}/handouts/${fileName}`);

      await ref.put(file).then(function (snap) {
        snap.ref
          .getDownloadURL()
          .then(function (downloadUrl) {
            // ----------
            try {
              handout[index].uri = downloadUrl;
              dispatch(updateHandoutUri(handout, courseId, isActiveCourse));
              delete handout[index].file; // prevent from reupload on next save
            } catch (err) {
              logger.error(err);
            }
            // ----------
          })
          .catch(function (error) {
            logger.error(error);
          });
      });
    }
  };
};

// ------------------------------

export const uploadDeleteCourseImage = (urlBlob, course) => {
  return async (dispatch, getState) => {
    if (urlBlob && urlBlob.toString().startsWith("blob:")) {
      await dispatch(uploadCourseImage(urlBlob, course));
    } else {
      await dispatch(
        deleteCourseImage(course, () => {
          return async (dispatch, getState) => {
            dispatch(updateCourseImageField("", course));
          };
        })
      );
    }
  };
};

// ------------------------------

const uploadCourseImage = (blobUri, course) => {
  return async (dispatch, getState) => {
    const userId = getState().auth.userId;

    if (blobUri && blobUri !== "undefined") {
      const response = await fetch(blobUri);
      const blob = await response.blob();

      let ref = firebase
        .storage()
        .ref()
        .child(`images/${userId}/cover_photo_${course.id}`);

      await ref.put(blob).then(function (snap) {
        snap.ref
          .getDownloadURL()
          .then(function (downloadUrl) {
            try {
              dispatch(updateCourseImageField(downloadUrl, course));
            } catch (err) {
              logger.error(err);
            }
          })
          .catch(function (error) {
            logger.error(error);
          });
      });
    }
  };
};

// ------------------------------

const deleteCourseImage = (course, callback) => {
  return async (dispatch, getState) => {
    const userId = getState().auth.userId;

    try {
      await firebase
        .storage()
        .ref()
        .child(`images/${userId}/cover_photo_${course.id}`)
        .delete();

      deleteCoverPhoto;
    } catch (error) {
      logger.info("picture not found, deleteCoverPhoto - course image");
    }

    if (callback) {
      await dispatch(callback());
    }
  };
};

// ------------------------------

const updateCourseImageField = (downloadURL, course) => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;
    const userId = getState().auth.userId;
    let saveUri = "";

    if (course.activation_status === "active") {
      saveUri = `https://firestore.googleapis.com/v1/projects/${dbName}/databases/(default)/documents/courses/${course.id}?updateMask.fieldPaths=image`;
    } else {
      saveUri = `https://firestore.googleapis.com/v1/projects/${dbName}/databases/(default)/documents/drafts/${userId}/course_drafts/${course.id}?updateMask.fieldPaths=image`;
    }

    await fetch(saveUri, {
      method: "PATCH",
      headers: {
        "Content-Type": "applications/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        fields: {
          image: { stringValue: downloadURL },
        },
      }),
    });

    dispatch({
      type: SET_COURSE_IMAGE,
      course: course,
      image: downloadURL,
    });
  };
};

// ------------------------------
// Mobile Course Actions
// ------------------------------

export const fetchCategoryIndex = () => {
  return async (dispatch, getState) => {
    const response = await fetch(
      `https://firestore.googleapis.com/v1/projects/${dbName}/databases/(default)/documents/category_index`,
      {
        method: "GET",
        headers: {
          "Content-Type": "applications/json",
        },
      }
    );

    if (!response.ok) {
      handleErrorResponse(await response.json());
    }

    const resData = await response.json();

    const categoryIndex = {};

    if (resData && resData.documents) {
      for (const category of resData.documents) {
        if (category && category.name) {
          categoryIndex[category.fields.category.stringValue] =
            category.fields.count.integerValue;
        }
      }
    }

    dispatch({
      type: SET_CATEOGRY_INDEX,
      categoryIndex: categoryIndex,
    });
  };
};

// ------------------------------

export const fetchCourse = (courseId, historyProviderId) => {
  return async (dispatch, getState) => {
    const response = await fetch(
      `https://firestore.googleapis.com/v1/projects/${dbName}/databases/(default)/documents/courses/${courseId}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "applications/json",
        },
      }
    );

    if (!response.ok) {
      const errResponse = await response.json();
      if (errResponse.error.status === "NOT_FOUND") {
        if (historyProviderId) {
          return dispatch(fetchHistoryCourse(courseId, historyProviderId));
        } else {
          dispatch({
            type: SELECT_MOBILE_COURSE,
            course: null,
          });
        }
      } else {
        handleErrorResponse(errResponse);
      }
    } else {
      const resData = await response.json();
      const c = buildSingleCourse(resData);
      dispatch({
        type: SELECT_MOBILE_COURSE,
        course: c,
      });

      return c;
    }
  };
};

// ------------------------------

const fetchHistoryCourse = (courseId, providerId) => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;

    const response = await fetch(
      `https://firestore.googleapis.com/v1/projects/${dbName}/databases/(default)/documents/courses_history/${providerId}/history/${courseId}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "applications/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );

    if (!response.ok) {
      const errResponse = await response.json();
      if (errResponse.error.status === "NOT_FOUND") {
        dispatch({
          type: SELECT_MOBILE_COURSE,
          course: null,
        });
      } else {
        handleErrorResponse(errResponse);
      }
    } else {
      const resData = await response.json();
      const c = buildSingleCourse(resData);
      dispatch({
        type: SELECT_MOBILE_COURSE,
        course: c,
      });

      return c;
    }
  };
};

// ------------------------------

export const setMobileCourses = (courses) => {
  return async (dispatch, getState) => {
    dispatch({
      type: SET_MOBILE_SEARCH_COURSES,
      search_courses: courses,
    });
  };
};

// ------------------------------

export const searchCourses = (filters) => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;

    let query = initStructuredQuery("courses", filters, PAGE_SIZE);
    const resData = await executeSearchQuery(token, query);
    const loadedCourses = buildCourses(resData); //, fav_course_lang);

    const activeCourses = loadedCourses.filter(
      (c) => c.activation_status === "active"
    );

    dispatch({
      type: SET_MOBILE_SEARCH_COURSES,
      search_courses: activeCourses,
      hasMore: hasMorePages(activeCourses.length, filters.page),
    });
  };
};

// ------------------------------

const initStructuredQuery = (id, filters, limit) => {
  let query = {
    structuredQuery: {
      from: [{ collectionId: id }],
    },
  };

  if (filters) {
    query.structuredQuery.where = buildCondition(filters);
  }

  if (limit) {
    query.structuredQuery.limit =
      filters && filters.page ? filters.page * limit : limit;
  }

  return query;
};

// ------------------------------

const executeSearchQuery = async (token, structuredQuery) => {
  const response = await fetch(
    `https://firestore.googleapis.com/v1/projects/${dbName}/databases/(default)/documents:runQuery`,
    {
      method: "POST",
      headers: {
        "Content-Type": "applications/json",
        // Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(structuredQuery),
    }
  );

  if (!response.ok) {
    handleErrorResponse(await response.json());
  }

  return await response.json();
};

// ------------------------------

const appendFilterCondition = (preQuery, filters) => {
  const eq = "EQUAL";
  const and = "AND";
  const gt = "GREATER_THAN_OR_EQUAL";
  const lt = "LESS_THAN_OR_EQUAL";
  const ac = "ARRAY_CONTAINS";
  const ne = "NOT_EQUAL";

  if (!filters) {
    return preQuery;
  }

  let preCondition = { ...preQuery.structuredQuery.where }

  if (!preCondition || !preCondition.compositeFilter) {
    preCondition = { compositeFilter: { filters: [], op: "AND" } };
  }

  if (filters.inPerson) {
    const fieldFilter = booleanFieldFilter("s_online", eq, false);
    preCondition.compositeFilter.filters.push({ fieldFilter: fieldFilter });
    const fieldFilter2 = booleanFieldFilter("is_video", eq, false);
    preCondition.compositeFilter.filters.push({ fieldFilter: fieldFilter2 });
  }

  if (filters.livestream) {
    const fieldFilter = booleanFieldFilter("s_online", eq, true);
    preCondition.compositeFilter.filters.push({ fieldFilter: fieldFilter });
  }

  if (filters.video) {
    const fieldFilter = booleanFieldFilter("is_video", eq, true);
    preCondition.compositeFilter.filters.push({ fieldFilter: fieldFilter });
  }

  preQuery.structuredQuery.where = preCondition;

  return preQuery;
};


const buildCondition = (filters) => {
  const eq = "EQUAL";
  const and = "AND";
  const gt = "GREATER_THAN_OR_EQUAL";
  const lt = "LESS_THAN_OR_EQUAL";
  const ac = "ARRAY_CONTAINS";
  const ne = "NOT_EQUAL";

  let condition = {};

  if (filters) {
    condition = { compositeFilter: { filters: [], op: and } };
  } else {
    return condition;
  }

  // Professor
  if (filters.professor) {
    const fieldFilter2 = stringFieldFilter(
      "s_professor",
      ac,
      filters.professor
    );
    condition.compositeFilter.filters.push({ fieldFilter: fieldFilter2 });
  }

  // all with city
  if (filters.allcity && !filters.city) {
    const fieldFilter = stringFieldFilter("s_city", ne, "");
    condition.compositeFilter.filters.push({ fieldFilter: fieldFilter });
  }

  // cityId
  if (filters.city) {
    const fieldFilter = stringFieldFilter("s_city", eq, filters.city);
    condition.compositeFilter.filters.push({ fieldFilter: fieldFilter });
  }

  // Provider
  if (filters.provider) {
    const fieldFilter = stringFieldFilter("provider_id", eq, filters.provider);
    condition.compositeFilter.filters.push({ fieldFilter: fieldFilter });
  }

  // Specialist
  if (filters.specialist) {
    const fieldFilter = booleanFieldFilter("is_specialty", eq, true);
    condition.compositeFilter.filters.push({ fieldFilter: fieldFilter });
    const fieldFilter2 = stringFieldFilter("category", ac, filters.specialist);
    condition.compositeFilter.filters.push({ fieldFilter: fieldFilter2 });
  }

  // Category
  if (filters.category) {
    const fieldFilter = booleanFieldFilter("is_specialty", eq, false);
    condition.compositeFilter.filters.push({ fieldFilter: fieldFilter });
    const fieldFilter2 = stringFieldFilter("category", ac, filters.category);
    condition.compositeFilter.filters.push({ fieldFilter: fieldFilter2 });
  }

  // Last Minute
  if (filters.searchLastMinute) {
    const low = timestampFieldFilter("s_date", gt, new Date());
    condition.compositeFilter.filters.push({ fieldFilter: low });
    const high = timestampFieldFilter("s_date", lt, addDays(new Date(), 30));
    condition.compositeFilter.filters.push({ fieldFilter: high });
  }

  // Free
  if (filters.searchFree && filters.searchFree === "free") {
    const fieldFilter = booleanFieldFilter("s_free", eq, true);
    condition.compositeFilter.filters.push({ fieldFilter: fieldFilter });
  }

  // Paid
  if (filters.searchFree && filters.searchFree === "paid") {
    const fieldFilter = booleanFieldFilter("s_free", eq, false);
    condition.compositeFilter.filters.push({ fieldFilter: fieldFilter });
  }

  // Online only
  if (filters.onlineOnly) {
    const fieldFilter = booleanFieldFilter("is_video", eq, true);
    condition.compositeFilter.filters.push({ fieldFilter: fieldFilter });
  }

  //searchLivestream
  if (filters.searchLivestream) {
    const fieldFilter = booleanFieldFilter("s_online", eq, true);
    condition.compositeFilter.filters.push({ fieldFilter: fieldFilter });
  }

  // date
  if (filters.dateFrom && filters.dateTo) {
    const low = timestampFieldFilter("s_date", gt, new Date(filters.dateFrom));
    condition.compositeFilter.filters.push({ fieldFilter: low });
    const high = timestampFieldFilter("s_date", lt, new Date(filters.dateTo));
    condition.compositeFilter.filters.push({ fieldFilter: high });
  }

  // language
  if (filters.language) {
    const fieldFilter = stringFieldFilter("language", eq, filters.language);
    condition.compositeFilter.filters.push({ fieldFilter: fieldFilter });
  }

  // university associated
  if (filters.is_university_associated) {
    const fieldFilter = booleanFieldFilter(
      "is_university_associated",
      eq,
      filters.is_university_associated
    );
    condition.compositeFilter.filters.push({ fieldFilter: fieldFilter });
  }

  return condition;
};

// ------------------------------

export const fetchRecommended = () => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;
    let loadedCourses = [];
    let filters = {};

    if (token) {
      const userProfile = getState().profile.userProfile;
      const usersOccupation = userProfile.occupation;
      const favCategory = getState().setting.mobileSetting.fav_category;
      const fav_course_lang = getState().setting.mobileSetting.fav_course_lang;

      if (userProfile && Object.keys(userProfile).length !== 0) {
        if (userProfile.isSpecialist()) {
          filters.specialist = usersOccupation;
        } else if (userProfile.isHygienist()) {
          filters.category = "Hygiene";
        }
      }

      if (favCategory) {
        for (const category of favCategory) {
          const catfilters = {};
          catfilters.category = category;
          let structuredQuery = initStructuredQuery("courses", catfilters);
          const resDataCatSearch = await executeSearchQuery(
            token,
            structuredQuery
          );
          const builtC = buildCourses(resDataCatSearch, fav_course_lang);
          loadedCourses = loadedCourses.concat(builtC);
        }
      }

      let structuredQuery = initStructuredQuery("courses", filters);
      const resData = await executeSearchQuery(token, structuredQuery);
      const builtC2 = buildCourses(resData, fav_course_lang);
      loadedCourses = loadedCourses.concat(builtC2);

      if (loadedCourses.length === 0) {
        structuredQuery = initStructuredQuery("courses", {});
        const resData = await executeSearchQuery(token, structuredQuery);
        const builtC2 = buildCourses(resData, fav_course_lang);
        loadedCourses = loadedCourses.concat(builtC2);
      }
    } else {
      let structuredQuery = initStructuredQuery("courses", filters);
      const resData = await executeSearchQuery(token, structuredQuery);
      loadedCourses = buildCourses(resData, null);
    }

    const recommend = [];
    for (const course of loadedCourses) {
      const found = recommend.find((ad) => ad.course.id === course.id);

      if (!found) {
        recommend.push(
          new MobileMarketingAd(
            course.id,
            course,
            true,
            new Date(),
            null,
            "",
            "",
            false,
            ""
          )
        );
      }

      if (recommend.length === 50) {
        break;
      }
    }

    dispatch({
      type: SET_RECOMMEND,
      courses: {
        recommend: shuffleArray(recommend),
      },
    });
  };
};

// ------------------------------

export const fetchTrending = () => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;

    const fav_course_lang = token
      ? getState().setting.mobileSetting.fav_course_lang
      : null;

    const countryCode = "CA";
    const response = await fetch(
      `https://firestore.googleapis.com/v1/projects/${dbName}/databases/(default)/documents/courses_trending/${countryCode}/trending`,
      {
        method: "GET",
        headers: {
          "Content-Type": "applications/json",
        },
      }
    );

    if (!response.ok) {
      handleErrorResponse(await response.json());
    }

    const resData = await response.json();

    const trending = [];

    if (resData.documents) {
      for (const trendingDoc of resData.documents) {
        if (trendingDoc) {
          const courseBuilt = buildSingleCourse(
            trendingDoc,
            "add_course_id",
            fav_course_lang
          );
          if (courseBuilt) {
            trending.push(
              new MobileMarketingAd(
                courseBuilt.id,
                courseBuilt,
                true,
                new Date(),
                null,
                "",
                "",
                false,
                ""
              )
            );
          }
        }
      }
    }

    dispatch({
      type: SET_TRENDING,
      courses: {
        trending: shuffleArray(trending),
      },
    });
  };
};

// ------------------------------

export const fetchNewly = (filters) => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;
    const fav_course_lang = getState().setting.mobileSetting.fav_course_lang;
    const resData = await fetchAdvertisment(token, "newly", filters);

    const newly = [];
    for (const documents of resData) {
      const featuredDoc = documents.document;
      if (featuredDoc && featuredDoc.name) {
        const courseBuilt = buildSingleCourse(
          featuredDoc,
          "add_course_id",
          fav_course_lang
        );
        if (courseBuilt) {
          pushMarketingAd(newly, "newly", courseBuilt, featuredDoc);
        }
      }
    }

    const shuffleFeataured = shuffleArray(newly);

    dispatch({
      type: SET_NEWLY,
      courses: {
        newly: shuffleFeataured,
      },
    });
  };
};

// ------------------------------

export const fetchFeatured = (searchFilter) => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;
    const userProfile = getState().profile.userProfile;
    const fav_course_lang = getState().setting.mobileSetting.fav_course_lang;
    const resData = await fetchAdvertisment(token, "featured", searchFilter);

    const featured = [];
    for (const documents of resData) {
      const featuredDoc = documents.document;
      if (featuredDoc && featuredDoc.name) {
        const courseBuilt = buildSingleCourse(
          featuredDoc,
          "add_course_id",
          fav_course_lang
        );
        if (courseBuilt) {
          pushMarketingAd(
            featured,
            "featured",
            courseBuilt,
            featuredDoc,
            0 //getAdRanking(featuredDoc, userProfile)
          );
        }
      }
    }

    const shuffleFeataured = shuffleArray(featured);

    const sortedByRank = shuffleFeataured.sort((a, b) => {
      return b.adRanking - a.adRanking;
    });

    const sortedFeatured = sortedByRank.sort((a, b) =>
      b.prime === true ? 1 : -1
    );

    dispatch({
      type: SET_FEATURED,
      courses: {
        featured: sortedFeatured,
      },
      //hasMore: hasMorePages(featured.length, searchFilter),
    });
  };
};

// ------------------------------

const fetchAdvertisment = async (token, addCategory, paramfilters) => {
  const eq = "EQUAL";
  const and = "AND";
  const gt = "GREATER_THAN_OR_EQUAL";
  let structuredQuery = initStructuredQuery("courses_featured", paramfilters);
  let condition = { compositeFilter: { filters: [], op: and } };
  const fieldFilter = timestampFieldFilter(
    "add_expiration_date",
    gt,
    new Date()
  );
  condition.compositeFilter.filters.push({ fieldFilter: fieldFilter });
  const fieldFilter2 = stringFieldFilter("add_category", eq, addCategory);
  condition.compositeFilter.filters.push({ fieldFilter: fieldFilter2 });
  structuredQuery.structuredQuery.where = condition;

  const response = await fetch(
    `https://firestore.googleapis.com/v1/projects/${dbName}/databases/(default)/documents:runQuery`,
    {
      method: "POST",
      headers: {
        "Content-Type": "applications/json",
      },
      body: JSON.stringify(structuredQuery),
    }
  );

  if (!response.ok) {
    handleErrorResponse(await response.json());
  }

  return await response.json();
};

// ------------------------------

const pushMarketingAd = async (
  featuredArray,
  category,
  course,
  doc,
  ranking
) => {
  const featuredCat = doc.fields.add_category.stringValue;
  if (featuredCat && featuredCat === category) {
    let cachedPic = null;
    if (
      category === "banner" &&
      doc.fields.add_showLogo &&
      doc.fields.add_showLogo.booleanValue === true &&
      doc.fields.add_logoUri &&
      doc.fields.add_logoUri.stringValue
    ) {
      //cachedPic = await cache(doc.fields.add_logoUri.stringValue);
    }

    featuredArray.push(
      new MobileMarketingAd(
        firebaseNameToId(doc.name),
        course,
        doc.fields.add_prime ? doc.fields.add_prime.booleanValue : false,
        new Date(doc.fields.add_expiration_date.timestampValue),
        doc.fields.add_slide ? doc.fields.add_slide.integerValue : null,
        doc.fields.add_headline ? doc.fields.add_headline.stringValue : "",
        doc.fields.add_callForAction
          ? doc.fields.add_callForAction.stringValue
          : "",
        doc.fields.add_showLogo ? doc.fields.add_showLogo.booleanValue : false,
        cachedPic,
        ranking
      )
    );
  }
};

// ------------------------------

const hasMorePages = (totalRows, page) => {
  const spliOver = page ? page * PAGE_SIZE : PAGE_SIZE;
  return totalRows - spliOver >= 0;
};

// ------------------------------

// ------------------------------
// End Mobile Course Actions
// ------------------------------
