import React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import styles from "./DateSelect.module.css";

const DateSelect = (props) => {
  return (
    <div className={styles.input}>
      <label className={props.labelLong ? styles.labelLong : styles.label}>
        {props.title}
        {props.required ? "*" : ""}
      </label>
      <DatePicker
        fixedHeight
        showMonthDropdown={props.hideMonth && props.hideMonth === true ? false : true}
        showYearDropdown
        dropdownMode="select"
        popperClassName={styles.datepickerPopper}
        timeClassName={() => styles.styleTimeColor}
        className={styles.inputElement}
        {...props}
        popperModifiers={{
          preventOverflow: {
            enabled: true,
          },
        }}
      />
    </div>
  );
};

export default DateSelect;
