import React from "react";
import styles from "./PromoItem.module.css";
import EditIcon from "@material-ui/icons/Edit";
import PopMenu from "../../../components/ui/popMenu/PopMenu";
import MenuItem from "@material-ui/core/MenuItem";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import TitleMenu from "../../../components/ui/titleMenu/TitleMenu";
import QueryBuilderIcon from "@material-ui/icons/QueryBuilder";
import SchoolOutlinedIcon from "@material-ui/icons/SchoolOutlined";
import PersonOutlineOutlinedIcon from "@material-ui/icons/PersonOutlineOutlined";

const PromoItem = (props) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  // ------------------------------

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  // ------------------------------

  const handleClose = () => {
    setAnchorEl(null);
  };

  // ------------------------------

  const renderEdit = () => (
    <MenuItem
      onClick={() => {
        handleClose();
        props.edit();
      }}
    >
      <TitleMenu icon={<EditIcon />} title="Edit" />
    </MenuItem>
  );

  // ------------------------------

  const renderDelete = () => (
    <MenuItem
      onClick={() => {
        handleClose();
        props.delete();
      }}
    >
      <TitleMenu icon={<DeleteOutlineIcon />} title="Delete" />
    </MenuItem>
  );

  return (
    <div className={styles.itemContainer}>
      <div className={styles.column}>
        <div className={styles.between}>
          <div className={styles.row}>
            {/***/}
            <div className={styles.column1}>
              <div className={styles.promoContainer}>
                <div className={styles.titleTop}>Promo Code</div>
                <div>
                  {props.promo_code} ({props.discount}% discount)
                </div>
              </div>

              <div className={styles.promoContainer}>
                <div className={styles.titleTop}>Usage Limit</div>
                <div>
                  {props.usageLimit > -1 ? (
                    <div>
                      {props.used_count} / {props.usageLimit}
                    </div>
                  ) : (
                    "Unlimited"
                  )}
                </div>
              </div>
            </div>
            {/***/}
            {/***/}
            <div className={styles.column2}>
              <div>
                <div className={styles.titleTop}>
                  <SchoolOutlinedIcon />
                  &nbsp; Applicable Courses
                </div>
                <div>
                  {props.courses.length > 0
                    ? props.courses.join(", ")
                    : "All active courses"}
                </div>
              </div>

              <div style={{ height: "20px" }}>&nbsp;</div>

              <div>
                <div className={styles.titleTop}>
                  <PersonOutlineOutlinedIcon />
                  &nbsp;Can be used by
                </div>

                {/**props.users.length > 0 && (
                  <div className={styles.sendContainer}>
                    <div className={styles.sentText}>
                      <AlarmOnIcon fontSize="inherit" />
                      Promo Code Sent
                    </div>
                  </div>
                )**/}

                <div>
                  {props.users.length > 0
                    ? props.users.map((email, index) => (
                        <div key={index}>
                          {email}
                          {/**<TextButton
                            style={{ paddingLeft: 6, paddingTop: 2 }}
                            onClick={props.onEmailPromoCode.bind(this, email)}
                          >
                            Resend
                          </TextButton> */}
                        </div>
                      ))
                    : "All users"}
                </div>
              </div>
            </div>

            {/***/}
            {/***/}
            <div className={styles.column3}>
              <div className={styles.titleTop}>
                <QueryBuilderIcon />
                &nbsp;Valid Between
              </div>
              <div>
                {props.start_date} and {props.end_date}
              </div>
            </div>
            {/***/}
          </div>

          <div className={styles.readMore}>
            <PopMenu
              anchorEl={anchorEl}
              handleClick={handleClick}
              handleClose={handleClose}
            >
              {renderEdit()}
              {renderDelete()}
            </PopMenu>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PromoItem;
