import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import InfoIcon from "@material-ui/icons/Info";
import TextButton from "../../../components/ui/button/TextButton";
import Tooltip from "@material-ui/core/Tooltip";
import { CopyToClipboard } from "react-copy-to-clipboard";
import QRCode from "qrcode.react";
import { zubuUrl } from "../../../globals/ApiKeys";
import LinkIcon from "@material-ui/icons/Link";
import AlertSuccess from "../../../components/ui/snackbar/AlertSuccess";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";

const QrCodeModal = (props) => {
  // ------------------------------

  const theme = useTheme();
  const [alert, setAlert] = useState(null);
  const fullScreen = useMediaQuery(theme.breakpoints.down("xs"));
  const qrCodeUrl = `${zubuUrl}/qr?course=${props.courseId}`;
  const webUrl = `${zubuUrl}/details?p=${props.providerId}&c=${props.courseId}`;

  // ------------------------------

  const downloadQR = (courseId) => {
    setAlert("Downloading...");
    const canvas = document.getElementById("qrCodeId");
    const pngUrl = canvas
      .toDataURL("image/png")
      .replace("image/png", "image/octet-stream");
    let downloadLink = document.createElement("a");
    downloadLink.href = pngUrl;
    downloadLink.download = `zubu_qr_code_${courseId}.png`;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  // ------------------------------

  return (
    <div>
      {alert !== null && (
        <AlertSuccess
          message={alert}
          open={alert !== null}
          onClose={() => {
            setAlert(null);
          }}
        />
      )}

      <Dialog
        fullScreen={fullScreen}
        fullWidth={true}
        maxWidth={"sm"}
        open={true}
        onClose={props.onClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="alert-dialog-title">
          QR Code: {props.courseTitle}
        </DialogTitle>

        <div
          style={{
            width: "100%",
            textAlign: "justify",
            padding: "20px",
            display: "flex",
            justifyContent: "center",
            backgroundColor: "#ccc",
          }}
        >
          <InfoIcon />
          <div style={{ marginLeft: "10px" }}>
            Students can register for your course by scanning this QR code with
            their mobile device. Download and add it on your website along with
            the direct link. Use the website link for registraton on any web
            browser (Chrome, Safari, Edge, etc.)
          </div>
        </div>

        <DialogContent>
          <div
            style={{
              flex: 1,
              width: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              padding: "20px",
              paddingTop: "10px",
            }}
          >
            <div style={{ fontColor: "black", paddingBottom: "0px" }}>
              Direct students to the website listing
            </div>

            <div style={{ color: "black" }}>
              <ArrowDownwardIcon />
            </div>

            <Tooltip title="Copy to clipboard" aria-label="Copy to clipboard">
              <CopyToClipboard
                text={webUrl}
                onCopy={() => {
                  setAlert("Copied to clipboard");
                }}
              >
                <TextButton
                  onClick={() => {}}
                  style={{
                    fontSize: "small",
                    cursor: "pointer",
                    border: "none",
                    textDecoration: "underline",
                    backgroundColor: "white",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <LinkIcon />
                  &nbsp;
                  {webUrl}
                </TextButton>
              </CopyToClipboard>
            </Tooltip>

            <div style={{ height: "45px" }} />

            <div style={{ fontColor: "black", paddingBottom: "0px" }}>
              Direct students to the mobile app listing (app installation
              required)
            </div>

            <div style={{ color: "black" }}>
              <ArrowDownwardIcon />
            </div>

            <QRCode id="qrCodeId" value={qrCodeUrl} />
            <div style={{ paddingTop: "10px" }}>
              <TextButton
                onClick={downloadQR.bind(this, props.courseId)}
                style={{
                  fontSize: "smaller",
                  cursor: "pointer",
                  color: "blue",
                  border: "none",
                  backgroundColor: "white",
                }}
              >
                Download QR Code
              </TextButton>
            </div>
            <div
              style={{
                paddingTop: "25px",
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Tooltip title="Copy to clipboard" aria-label="Copy to clipboard">
                <CopyToClipboard
                  text={qrCodeUrl}
                  onCopy={() => {
                    setAlert("Copied to clipboard");
                  }}
                >
                  <TextButton
                    onClick={() => {}}
                    style={{
                      fontSize: "small",
                      cursor: "pointer",
                      border: "none",
                      textDecoration: "underline",
                      backgroundColor: "white",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <LinkIcon />
                    &nbsp;
                    {qrCodeUrl}
                  </TextButton>
                </CopyToClipboard>
              </Tooltip>
            </div>
          </div>
        </DialogContent>

        <DialogActions>
          <Button autoFocus onClick={props.onClose} color="primary">
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default QrCodeModal;
