import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import * as profileActions from "../../../store/actions/profile";
import * as memberActions from "../../../store/actions/member";
import * as rateActions from "../../../store/actions/rate";
import * as settingActions from "../../../store/actions/setting";
import InfoModal from "../../../components/modals/infoModal/InfoModal";
import Spinner from "../../../components/ui/spinner/Spinner";
import Empty from "../../../components/ui/empty/Empty";
import memberImage from "../../../assets/images/inApp/membership.png";
import { objectHasNoValues } from "../../../globals/Common";
import * as logger from "../../../globals/Logger";
import MembershipItem from "../../../components/items/membershipItem/MembershipItem"

class MembershipScreen extends Component {
    state = {
        wait: false,
        waitMessage: "",
        error: null,
        openDeleteConfirmModal: false,
        idToBeDeleted: null,
        success: null,
        alertSuccess: null,
        alertSeverity: null,
        openCertLinkModal: false,
        certLinkToBeDeleted: null,
        certificateTitle: null,
    };

    // ------------------------------

    update = async (propValue) => {
        await this.setState({ ...this.state, ...propValue });
    };

    // ------------------------------

    componentDidMount = async () => {
        try {
            window.scrollTo(0, 0);
            const loadAsync = [];
            await this.update({ error: null, wait: true });
            loadAsync.push(this.props.fetchUserProfile());

            if (objectHasNoValues(this.props.mobileSetting)) {
                await this.props.fetchMobileSetting();
            }

            if (objectHasNoValues(this.props.rates)) {
                loadAsync.push(this.props.fetchRates());
            }

            loadAsync.push(this.props.fetchMemberships());
            await Promise.all(loadAsync);
            await this.update({ wait: false });
        } catch (error) {
            logger.error(error);
            await this.update({ wait: false, error: error.message });
        }
    };

    // ------------------------------

    deleteHandler = async (id) => {
        try {
            await this.update({ wait: true });
            await this.props.removeMembership(id);
            await this.update({ wait: false });
        } catch (error) {
            logger.error(error);
            this.update({
                wait: false,
                error: error.message,
            });
        }
    };

    // ------------------------------

    renderItems = () => {
        return this.props.memberships.map((item, index) => {
            return (
                <MembershipItem
                    key={index}
                    memberID={item.member_id}
                    providerName={item.provider_name}
                    providerLogo={item.provider_logo}
                    delete={this.deleteHandler.bind(this, item.id)}
                />
            );
        });
    };

    // ------------------------------

    render() {
        if (this.state.wait) {
            return <Spinner />;
        }

        if (!this.props.memberships || this.props.memberships.length === 0) {
            return (
                <Empty
                    column
                    image={memberImage}
                    message={<div style={{ paddingTop: "10px" }}>
                        {this.props.t("membership_blurb")}
                    </ div>
                    }
                />
            );
        }

        return (
            <div style={{ backgroundColor: "white" }}>
                {this.state.error !== null && (
                    <InfoModal
                        isErrorModal={true}
                        title={this.props.t("error_occurred")}
                        message={this.state.error}
                        open={this.state.error !== null}
                        onClose={this.closeErrorHandler}
                    />
                )}

                {this.renderItems()}
            </div>
        );
    }
}

const mapStateToProps = (state, props) => {
    return {
        action: props.history.action,
        memberships: state.member.memberships,
        mobileSetting: state.setting.mobileSetting,
        rates: state.rate.rate,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetchMemberships: () => dispatch(memberActions.fetchMemberships()),
        fetchMobileSetting: () => dispatch(settingActions.fetchMobileSetting()),
        fetchUserProfile: () => dispatch(profileActions.fetchMobileUserProfile()),
        fetchRates: () => dispatch(rateActions.fetchRates()),
        removeMembership: (id) => dispatch(memberActions.removeMembership(id)),
    };
};


export default withTranslation("translations")(
    connect(mapStateToProps, mapDispatchToProps)(MembershipScreen)
);
