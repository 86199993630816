import React, { Component } from "react";
import styles from "./MobileCart.module.css";
import Button from "@material-ui/core/Button";
import { ElementsConsumer } from "@stripe/react-stripe-js";
import TouchAppIcon from "@material-ui/icons/TouchApp";

class CheckoutForm extends Component {
  // ------------------------------

  placeOrder = async (event) => {
    try {
      this.props.lockBack();
      event.preventDefault();
      await this.props.submitting(true, this.props.t("cart_processing"));
      await this.props.placeOrder(
        this.props.cart,
        this.props.paymentMethod ? this.props.paymentMethod.id : null,
        this.props.coupon,
        this.props.selectedPaymentOption
      );
      await this.props.submitting(false, "");
      this.props.orderCompleted();
    } catch (err) {
      if (err.result && err.result.requires_action === true) {
        await this.addionalActionRequired(err);
      } else {
        this.props.sendError(err);
      }
      this.props.submitting(false, "");
      this.props.unlockBack();
    }
  };

  // ------------------------------

  addionalActionRequired = async (err) => {
    this.props.submitting(true);

    const { stripe } = this.props;

    if (!stripe) {
      await this.props.submitting(false);
      return;
    }

    const result = await stripe.confirmCardPayment(
      err.result.payment_intent_client_secret,
      {
        payment_method: this.props.paymentMethod.id,
      }
    );

    if (result.error) {
      this.props.sendError(result);
    } else {
      // The payment has been processed
      if (result.paymentIntent.status === "succeeded") {
        this.props.submitting(false);
        this.props.orderCompleted();
      }
    }
  };

  // ------------------------------

  render() {
    return (
      <Button
        onClick={this.placeOrder}
        className={styles.mobileCheckoutButton}
        style={{
          backgroundColor:
            !this.props.cart.items ||
            this.props.cart.items.length === 0 ||
            (!this.props.paymentMethod && this.props.total > 0) ||
            !this.props.stripe
              ? "#CCC"
              : "#3E8CAB",
          color: "white",
        }}
        disabled={
          !this.props.cart.items ||
          this.props.cart.items.length === 0 ||
          (!this.props.paymentMethod && this.props.total > 0) ||
          !this.props.stripe
        }
        variant="contained"
        color="default"
        startIcon={<TouchAppIcon />}
        size={"large"}
      >
        {this.props.t("cart_place_your_order")}
      </Button>
    );
  }
}

// ------------------------------

const InjectedForm = (props) => {
  return (
    <ElementsConsumer>
      {({ stripe, elements }) => (
        <CheckoutForm
          total={props.total}
          stripe={stripe}
          elements={elements}
          cart={props.cart}
          coupon={props.coupon}
          paymentMethod={props.paymentMethod}
          selectedPaymentOption={props.selectedPaymentOption}
          submitting={props.submitting}
          placeOrder={props.placeOrder}
          orderCompleted={props.orderCompleted}
          sendError={props.sendError}
          lockBack={props.lockBack}
          unlockBack={props.unlockBack}
          t={props.t}
        />
      )}
    </ElementsConsumer>
  );
};

export default InjectedForm;
