import React, { Fragment } from "react";
import { makeStyles } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Pop from "./Pop";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    width: "100%",
  },
  rootSmall: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    width: "60%",
    margin: "auto",
  },
}));

const InlinePop = (props) => {
  const matches = useMediaQuery("(max-width:499px)");
  const classes = useStyles();
  const rootClass = matches ? classes.root : classes.rootSmall;

  return (
    <div className={rootClass} style={props.style ? props.style : null}>
      {props.useTextButton ? (
        <Fragment>{props.children}</Fragment>
      ) : (
        <Pop help={props.help} privateIcon={props.privateIcon} />
      )}
    </div>
  );
};

export default InlinePop;
