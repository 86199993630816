import React from "react";
import styles from "./ReviewItem.module.css";
import Avatar from "../../ui/image/Avatar";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import PopMenu from "../../../components/ui/popMenu/PopMenu";
import MenuItem from "@material-ui/core/MenuItem";
import ReplyOutlinedIcon from "@material-ui/icons/ReplyOutlined";
import Rating from "../../ui/rating/Rating";

const ReviewItem = (props) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div
      className={styles.itemContainer}
      style={props.full ? { width: "100%" } : null}
    >
      <div className={styles.ratingFirstRow}>
        <div className={styles.courseTitleAndRating}>
          <div className={styles.courseTitle}>{props.course_title}</div>
          <Rating rating={props.rating} />
        </div>

        <div className={styles.nameAndDateRating}>
          <div style={{ paddingRight: "10px", paddingTop: "5px" }}>
            <div className={styles.name}>{props.first}</div>
            <div className={styles.date}>{props.date}</div>
          </div>
          {props.pic ? <Avatar image={props.pic} /> : <div></div>}
        </div>
      </div>

      <div className={styles.ratingFirstRow}>
        <div className={styles.comment}>{props.comment}</div>
      </div>

      <div className={styles.ratingFirstRow}>
        {props.reply && (
          <div className={styles.replyContainer}>
            <div className={styles.replyTitle}>
              {props.onReply ? "Your response" : "Provider response"}
              <div className={styles.date} style={{ textAlign: "left" }}>
                {props.replyDate}
              </div>
            </div>

            <div className={styles.reply}>{props.reply}</div>
          </div>
        )}
      </div>

      {props.onReply ? (
        <div className={styles.readMore}>
          <PopMenu
            anchorEl={anchorEl}
            handleClick={handleClick}
            handleClose={handleClose}
          >
            {!props.reply ? (
              <MenuItem
                onClick={() => {
                  handleClose();
                  props.onReply();
                }}
              >
                <div className={styles.simpleRow}>
                  <ReplyOutlinedIcon />
                  &nbsp;Reply
                </div>
              </MenuItem>
            ) : null}

            {props.reply && (
              <MenuItem
                onClick={() => {
                  handleClose();
                  props.onDeleteReply();
                }}
              >
                <div className={styles.simpleRow}>
                  <DeleteOutlineIcon />
                  &nbsp;Delete Reply
                </div>
              </MenuItem>
            )}
          </PopMenu>
        </div>
      ) : null}
    </div>
  );
};

export default ReviewItem;
