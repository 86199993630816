import { handleErrorResponse } from "../../globals/Error";
import Order from "../../model/Order";
import { dbName } from "../../globals/ApiKeys";
import CartItem from "../../model/CartItem";
import {
  fromFirebaseDoubleValue,
  fromFirebaseNumberValue,
} from "../../globals/Common";
import { toDollar } from "../../globals/Common";
export const SET_ORDERS = "SET_ORDERS";

// ------------------------------

export const fetchOrders = () => {
  return async (dispatch, getState) => {
    const userId = getState().auth.userId;
    const token = getState().auth.token;

    const response = await fetch(
      `https://firestore.googleapis.com/v1/projects/${dbName}/databases/(default)/documents/stripe_customers/${userId}:runQuery`,
      {
        method: "POST",
        headers: {
          "Content-Type": "applications/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          structuredQuery: {
            from: [{ collectionId: "purchase_history" }],
          },
        }),
      }
    );

    if (!response.ok) {
      handleErrorResponse(await response.json());
    }

    const resData = await response.json();

    const loadedOrders = [];
    for (const documents of resData) {
      const order = documents.document;

      if (
        order &&
        order.name &&
        order.fields.orderRefNumber.stringValue.startsWith("A-") // TODO need to show the Z- charnges for free courses
      ) {
        loadedOrders.push(
          new Order(
            order.name,
            order.fields.orderRefNumber.stringValue,
            new Date(order.fields.date.timestampValue),
            order.fields.currency.stringValue,
            toDollar(
              fromFirebaseDoubleValue(
                order.fields.totalAmount.doubleValue,
                order.fields.totalAmount.integerValue
              )
            ),
            order.fields.gst
              ? toDollar(
                  fromFirebaseDoubleValue(
                    order.fields.gst.doubleValue,
                    order.fields.gst.integerValue
                  )
                )
              : 0,
            order.fields.qst
              ? toDollar(
                  fromFirebaseDoubleValue(
                    order.fields.qst.doubleValue,
                    order.fields.qst.integerValue
                  )
                )
              : 0,
            buildCartItems(order.fields.items.arrayValue.values),
            order.fields.creditsUsed
              ? toDollar(
                  fromFirebaseDoubleValue(
                    order.fields.creditsUsed.doubleValue,
                    order.fields.creditsUsed.integerValue
                  )
                )
              : 0
          )
        );
      }
    }

    const sorderDateDesc = loadedOrders.sort((a, b) =>
      a.date.getTime() < b.date.getTime() ? 1 : -1
    );

    dispatch({
      type: SET_ORDERS,
      orders: {
        orders: sorderDateDesc,
      },
    });
  };
};

// ------------------------------

const buildCartItems = (cartItemsArray) => {
  let cartItems = [];
  for (const item of cartItemsArray) {
    const fields = item.mapValue.fields;
    cartItems.push(
      new CartItem(
        fields.add_course_id.stringValue,
        fields.add_course_title.stringValue,
        fields.add_start_date.timestampValue
          ? new Date(fields.add_start_date.timestampValue)
          : new Date(fields.add_start_date.stringValue),
        fields.add_num_of_weeks.integerValue,
        fields.add_prime.booleanValue,
        fromFirebaseDoubleValue(
          fields.add_prime_cost.doubleValue,
          fields.add_prime_cost.integerValue
        ),
        fromFirebaseDoubleValue(
          fields.add_cost.doubleValue,
          fields.add_cost.integerValue
        ),
        fields.add_category.stringValue,
        fromFirebaseNumberValue(fields.add_slide.integerValue),
        fields.add_headline.stringValue
          ? fields.add_headline.stringValue
          : null,
        fields.add_callForAction.stringValue
          ? fields.add_callForAction.stringValue
          : null,
        fields.add_callForAction.booleanValue
          ? fields.add_callForAction.booleanValue
          : false
      )
    );
  }

  return cartItems;
};

// ------------------------------

export const emailReceipt = (orderId) => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;
    const idArray = orderId.split("/");
    const id = idArray[idArray.length - 1];

    const response = await fetch(
      `https://us-central1-${dbName}.cloudfunctions.net/resendEmailOrder`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          data: { orderId: id, adds: true },
        }),
      }
    );

    if (!response.ok) {
      handleErrorResponse(await response.json());
    }
  };
};

// ------------------------------
