import React, { Component } from "react";
import { connect } from "react-redux";
import Empty from "../../../components/ui/empty/Empty";
import unavailableImg from "../../../assets/images/inApp/unavailable.jpg";
import PhoneBadges from "../../../components/ui/phoneBadges/PhoneBadges";
import Zoom from "@material-ui/core/Zoom";

class UnavailableScreen extends Component {
  // ------------------------------

  componentDidMount = async () => {
    window.scrollTo(0, 0);
  };

  // ------------------------------

  render() {
    return (
      <div style={{ backgroundColor: "white" }}>
        <Zoom in={true} style={{ transitionDelay: true ? "100ms" : "0ms" }}>
          <Empty
            column
            image={unavailableImg}
            message={
              <p>
                Available only on the ZubU mobile app. Download the app and
                login to to use this feature.
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    paddingTop: "40px",
                  }}
                >
                  <PhoneBadges />
                </div>
              </p>
            }
          />
        </Zoom>
      </div>
    );
  }
}

export default connect(null, null)(UnavailableScreen);
