import React, { Fragment } from "react";
import styles from "./MarketingAddItem.module.css";
import { momentShortDate, remainingDaysTime } from "../../../globals/Dates2";
import PopMenu from "../../../components/ui/popMenu/PopMenu";
import MenuItem from "@material-ui/core/MenuItem";
import Divider from "@material-ui/core/Divider";
import BlockIcon from "@material-ui/icons/Block";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import AssessmentOutlinedIcon from "@material-ui/icons/AssessmentOutlined";
import VisibilityIcon from "@material-ui/icons/Visibility";
import TitleMenu from "../../../components/ui/titleMenu/TitleMenu";
import QueryBuilderIcon from "@material-ui/icons/QueryBuilder";
import LiveTvIcon from "@material-ui/icons/LiveTv";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import marketingAddImg from "../../../assets/images/inApp/marketingAdd.png";
import marketingFeaturedImg from "../../../assets/images/inApp/marketingFeatured.png";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import LocationOffIcon from "@material-ui/icons/LocationOff";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import CheckIcon from "@material-ui/icons/Check";

const MarketingAddItem = (props) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const expired =
    new Date(props.add.expiration_date).getTime() < new Date().getTime();

  // ------------------------------

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  // ------------------------------

  const handleClose = () => {
    setAnchorEl(null);
  };

  // ------------------------------

  const renderView = () => (
    <MenuItem
      onClick={() => {
        handleClose();
        props.preview();
      }}
    >
      <TitleMenu icon={<VisibilityIcon />} title="View Ad" />
    </MenuItem>
  );

  // ------------------------------

  const renderDelete = (expired) => (
    <MenuItem
      onClick={() => {
        handleClose();
        props.delete();
      }}
    >
      <TitleMenu
        icon={expired ? <DeleteOutlineIcon /> : <BlockIcon color="secondary" />}
        title={expired ? "Remove" : "Cancel"}
      />
    </MenuItem>
  );

  // ------------------------------

  const audience = (
    <Fragment>
      <div style={{ height: "10px" }}>&nbsp;</div>
      {props.add.occupation && props.add.occupation.length > 0 && (
        <div>
          <div className={styles.titleTop}>Occupation</div>
          <div className={styles.simpleRow}>
            {props.add.occupation.join(", ")}
          </div>
        </div>
      )}
      {props.add.experience && props.add.experience.length > 0 && (
        <div>
          <div style={{ height: "5px" }}>&nbsp;</div>
          <div className={styles.titleTop}>Experience</div>
          <div className={styles.simpleRow}>
            {props.add.experience.join(", ")}
          </div>
        </div>
      )}
      {props.add.locations && props.add.locations.length > 0 && (
        <div>
          <div style={{ height: "5px" }}>&nbsp;</div>
          <div className={styles.titleTop}>
            Locations&nbsp;
            <LocationOnIcon fontSize="inherit" />
          </div>
          <div className={styles.simpleRow}>
            <CheckIcon fontSize="inherit" style={{ color: "green" }} /> &nbsp;
            {props.add.locations.join(", ")}
          </div>
        </div>
      )}
      {props.add.exclude_locations &&
        props.add.exclude_locations.length > 0 && (
          <div>
            <div style={{ height: "5px" }}>&nbsp;</div>
            <div className={styles.titleTop}>
              Exclude Locations&nbsp;
              <LocationOffIcon fontSize="inherit" />
            </div>
            <div className={styles.simpleRow}>
              <HighlightOffIcon fontSize="inherit" style={{ color: "red" }} />
              &nbsp;{props.add.exclude_locations.join(", ")}
            </div>
          </div>
        )}
    </Fragment>
  );

  return (
    <div className={styles.itemContainer}>
      <div className={styles.column}>
        <div className={styles.between}>
          <div className={styles.row}>
            {/***/}
            <div className={styles.column1}>
              <div className={styles.promoContainer}>
                {props.add.category === "banner" ? (
                  <div className={styles.imageSelectorContainer}>
                    <img
                      src={marketingAddImg}
                      className={styles.phonePic}
                      alt=""
                    />
                  </div>
                ) : (
                  <div className={styles.imageSelectorContainer}>
                    <img
                      src={marketingFeaturedImg}
                      className={styles.phonePic}
                      alt=""
                    />
                  </div>
                )}
              </div>
            </div>
            {/***/}
            {/***/}
            <div className={styles.column2}>
              <div>
                <div className={styles.titleTop}>
                  <LiveTvIcon />
                  &nbsp;{" "}
                  {props.add.category === "banner"
                    ? "Image Advertisement"
                    : "Featured Advertisement"}
                </div>
                <div>{props.add.course_title}</div>
              </div>

              <div style={{ height: "5px" }}>&nbsp;</div>

              {props.add.category === "banner" && (
                <div className={styles.subInfoBanner}>
                  <div className={styles.simpleRow}>
                    <div className={styles.titleTop}>Headline:</div>
                    <div>{props.add.headline}</div>
                  </div>

                  <div className={styles.simpleRow}>
                    <div className={styles.titleTop}>Call for Action:</div>
                    <div>{props.add.callForAction}</div>
                  </div>

                  {audience}
                </div>
              )}

              {props.add.category !== "banner" && (
                <div className={styles.subInfoBanner}>
                  <div className={styles.simpleRow}>
                    <div className={styles.titleTop}>Prime:</div>
                    <div>{props.add.prime ? "Yes" : "No"}</div>
                  </div>

                  {audience}
                </div>
              )}
            </div>
            {/***/}
            {/***/}
            <div className={styles.column3}>
              <div className={styles.titleTop}>
                <QueryBuilderIcon />
                &nbsp;Valid Between
              </div>
              <div>
                {momentShortDate(props.add.start_date)} and{" "}
                {momentShortDate(props.add.expiration_date)}
              </div>
              <div style={{ height: "10px" }}>&nbsp;</div>
              <div className={styles.titleTop}>
                <FiberManualRecordIcon
                  style={{
                    fontSize: "32px",
                    color: expired ? "red" : "green",
                    paddingRight: 5,
                  }}
                />
                Status: {expired ? "Expired" : "Active"}
              </div>
              <div>
                {expired ? "Expired" : "Expires"}{" "}
                {remainingDaysTime(props.add.expiration_date)}
              </div>

              <div style={{ height: "10px" }}>&nbsp;</div>

              {props.views > 0 && (
                <div className={styles.titleTop}>
                  <AssessmentOutlinedIcon
                    style={{
                      fontSize: "32px",
                      paddingRight: 5,
                    }}
                  />
                  Clicks: {props.views}
                </div>
              )}
            </div>
            {/***/}
          </div>

          {props.delete && (
            <div className={styles.readMore}>
              <PopMenu
                anchorEl={anchorEl}
                handleClick={handleClick}
                handleClose={handleClose}
              >
                {props.add.category === "banner" && renderView()}
                <Divider />
                {renderDelete(expired)}
              </PopMenu>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default MarketingAddItem;
