import React, { Fragment } from "react";
import styles from "../BannerSlide.module.css";
import { useTranslation } from "react-i18next";
import BannerSlide from "./BannerSlide";
import who from "../../../assets/images/welcome/4.png";
import classroom from "../../../assets/images/welcome/5.png";
import more from "../../../assets/images/welcome/6.png";
import track from "../../../assets/images/welcome/8.png";
import discover from "../../../assets/images/welcome/7.png";
import register from "../../../assets/images/welcome/9.png";
import learn from "../../../assets/images/welcome/11.png";

import {
  CarouselProvider,
  Slider,
  Slide,
  Dot,
  DotGroup,
  ButtonBack,
  ButtonNext,
} from "pure-react-carousel";
import "pure-react-carousel/dist/react-carousel.es.css";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";

const WelcomeSlider = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.container}>
      <div className={styles.slideContainer}>
        <CarouselProvider
          infinite={true}
          isIntrinsicHeight={true}
          totalSlides={7}
          interval={5000}
          isPlaying={true}
          dragEnabled={false}
          lockOnWindowScroll={true}
        >
          <Slider>
            <Slide index={0}>
              <BannerSlide
                title={t("welcome_learn_about_the_app")}
                subTitle={t("welcome_learn_about_the_app_sub")}
                text={t("welcome_learn_about_the_app_text")}
                img={learn}
              />
            </Slide>

            <Slide index={1}>
              <BannerSlide
                title={t("welcome_learn_about_the_app")}
                subTitle={
                  <Fragment>
                    {t("welcome_who_can_sub")}{" "}
                    <span className={styles.brand}>ZubU?</span>
                  </Fragment>
                }
                text={t("welcome_who_can_text")}
                img={who}
              />
            </Slide>
            <Slide index={2}>
              <BannerSlide
                title={t("welcome_learn_about_the_app")}
                subTitle={t("welcome_market_place_sub")}
                text={t("welcome_market_place_text")}
                img={classroom}
              />
            </Slide>
            <Slide index={3}>
              <BannerSlide
                title={t("welcome_learn_about_the_app")}
                subTitle={t("welcome_register_directly_sub")}
                text={t("welcome_register_directly_text")}
                img={register}
              />
            </Slide>
            <Slide index={4}>
              <BannerSlide
                title={t("welcome_learn_about_the_app")}
                subTitle={t("welcome_ce_track_sub")}
                text={t("welcome_ce_track_text")}
                img={track}
              />
            </Slide>
            <Slide index={5}>
              <BannerSlide
                title={t("welcome_learn_about_the_app")}
                subTitle={t("welcome_discover_sub")}
                text={t("welcome_discover_text")}
                img={discover}
              />
            </Slide>
            <Slide index={6}>
              <BannerSlide
                title={t("welcome_learn_about_the_app")}
                subTitle={t("welcome_and_more_sub")}
                text={t("welcome_and_more_text")}
                img={more}
              />
            </Slide>
          </Slider>
          <div className={styles.rowButtonSlideContainer}>
            <div className={styles.sliderButtonContainer}>
              <ButtonBack className={styles.slideButton}>
                <NavigateBeforeIcon />
              </ButtonBack>
            </div>
            <div>
              <DotGroup
                renderDots={(s) => (
                  <div className={styles.dotContainer}>
                    <Dot
                      slide={0}
                      className={
                        s.currentSlide === 0 ? styles.activeDot : styles.dot
                      }
                    />
                    &nbsp;
                    <Dot
                      slide={1}
                      className={
                        s.currentSlide === 1 ? styles.activeDot : styles.dot
                      }
                    />
                    &nbsp;
                    <Dot
                      slide={2}
                      className={
                        s.currentSlide === 2 ? styles.activeDot : styles.dot
                      }
                    />
                    &nbsp;
                    <Dot
                      slide={3}
                      className={
                        s.currentSlide === 3 ? styles.activeDot : styles.dot
                      }
                    />
                    &nbsp;
                    <Dot
                      slide={4}
                      className={
                        s.currentSlide === 4 ? styles.activeDot : styles.dot
                      }
                    />
                    &nbsp;
                    <Dot
                      slide={5}
                      className={
                        s.currentSlide === 5 ? styles.activeDot : styles.dot
                      }
                    />
                    &nbsp;
                    <Dot
                      slide={6}
                      className={
                        s.currentSlide === 6 ? styles.activeDot : styles.dot
                      }
                    />
                  </div>
                )}
              />
            </div>
            <div className={styles.sliderButtonContainer}>
              <ButtonNext className={styles.slideButton}>
                <NavigateNextIcon />
              </ButtonNext>
            </div>
          </div>
        </CarouselProvider>
      </div>
    </div>
  );
};

export default WelcomeSlider;
