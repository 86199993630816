import React from "react";
import MenuItem from "@material-ui/core/MenuItem";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import PostAddIcon from "@material-ui/icons/PostAdd";
import BlockIcon from "@material-ui/icons/Block";
import TitleMenu from "../../ui/titleMenu/TitleMenu";
import LiveTvIcon from "@material-ui/icons/LiveTv";
import EventSeatOutlinedIcon from "@material-ui/icons/EventSeatOutlined";
import VisibilityOutlinedIcon from "@material-ui/icons/VisibilityOutlined";
import FileCopyOutlinedIcon from "@material-ui/icons/FileCopyOutlined";
import CloudDownloadOutlinedIcon from "@material-ui/icons/CloudDownloadOutlined";
import styles from "./CourseCard.module.css";
import Fab from "@material-ui/core/Fab";
import EditIcon from "@material-ui/icons/Edit";
import GroupIcon from "@material-ui/icons/Group";
import Badge from "@material-ui/core/Badge";
import PopMenu from "../../ui/popMenu/PopMenu";
import Divider from "@material-ui/core/Divider";
import Tooltip from "@material-ui/core/Tooltip";
import LinkIcon from "@material-ui/icons/Link";
import ListAltIcon from "@material-ui/icons/ListAlt";
import CourseCard from "./CourseCard";
import FilterCenterFocusIcon from "@material-ui/icons/FilterCenterFocus";
import DescriptionOutlinedIcon from "@material-ui/icons/DescriptionOutlined";
import zoomIcon from "../../../assets/images/inApp/zoom_icon.png";
import PlayCircleFilledWhiteOutlinedIcon from "@material-ui/icons/PlayCircleFilledWhiteOutlined";
import PhotoCameraOutlinedIcon from "@material-ui/icons/PhotoCameraOutlined";
import InlinePop from "../../../components/ui/popover/InlinePop";
import DateRangeOutlinedIcon from "@material-ui/icons/DateRangeOutlined";
import GroupOutlinedIcon from "@material-ui/icons/GroupOutlined";
import CustomIconButton from "../../ui/button/CustomIconButton";

const CourseItem = (props) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorE2, setAnchorE2] = React.useState(null);
  const dense = true;

  // ------------------------------

  const renderZoomStart = () => (
    <MenuItem
      dense={dense}
      onClick={() => {
        handleClose2();
        props.onStartZoom();
      }}
    >
      <TitleMenu
        icon={<PlayCircleFilledWhiteOutlinedIcon />}
        title="Start Meeting"
      />
    </MenuItem>
  );

  // ------------------------------

  const renderZoomDetails = () => (
    <MenuItem
      dense={dense}
      onClick={() => {
        handleClose2();
        props.onZoomDetails();
      }}
    >
      <TitleMenu icon={<DateRangeOutlinedIcon />} title="Meeting Details" />
    </MenuItem>
  );

  // ------------------------------

  const renderZoomParticipants = () => (
    <MenuItem
      dense={dense}
      onClick={() => {
        handleClose2();
        props.onZoomParticipant();
      }}
    >
      <TitleMenu icon={<GroupOutlinedIcon />} title="View Participants" />
    </MenuItem>
  );


  // ------------------------------

  const renderQrCode = () => (
    <MenuItem
      dense={dense}
      onClick={() => {
        handleClose();
        props.onQrCode();
      }}
    >
      <TitleMenu icon={<FilterCenterFocusIcon />} title="QR Code" />
    </MenuItem>
  );

  // ------------------------------

  const renderViewCourse = () => (
    <MenuItem
      dense={dense}
      onClick={() => {
        handleClose();
        props.onCourseView();
      }}
    >
      <TitleMenu icon={<VisibilityOutlinedIcon />} title="View Course" />
    </MenuItem>
  );

  // ------------------------------

  const renderUpdateCapacity = () => (
    <MenuItem
      dense={dense}
      onClick={() => {
        handleClose();
        props.onUpdateCapcity();
      }}
    >
      <TitleMenu icon={<EventSeatOutlinedIcon />} title="Update Capacity" />
    </MenuItem>
  );

  // ------------------------------

  const renderPostAmendment = () => (
    <MenuItem
      dense={dense}
      onClick={() => {
        handleClose();
        props.onAmendment();
      }}
    >
      <TitleMenu icon={<PostAddIcon />} title="Add Amendment" />
    </MenuItem>
  );

  // ------------------------------
  const renderAdvertise = () => (
    <MenuItem
      dense={dense}
      onClick={() => {
        handleClose();
        props.onAdvertise();
      }}
    >
      <TitleMenu icon={<LiveTvIcon />} title="Advertise" />
    </MenuItem>
  );

  // ------------------------------

  const renderCancelCourse = (isVideo) => (
    <MenuItem
      dense={dense}
      onClick={() => {
        handleClose();
        props.onCancelCourse();
      }}
    >
      <TitleMenu
        icon={<BlockIcon color="secondary" />}
        title={isVideo ? "Remove Course..." : "Cancel Course..."}
      />
    </MenuItem>
  );

  // ------------------------------

  const renderCopyToDrafts = () => (
    <MenuItem
      dense={dense}
      onClick={() => {
        handleClose();
        props.onCopyCourse();
      }}
    >
      <TitleMenu icon={<FileCopyOutlinedIcon />} title="Copy to Draft" />
    </MenuItem>
  );

  // ------------------------------

  const renderDeleteDraft = () => (
    <MenuItem
      dense={dense}
      onClick={() => {
        handleClose();
        props.onDeleteDraft();
      }}
    >
      <TitleMenu icon={<DeleteOutlineIcon />} title="Delete" />
    </MenuItem>
  );

  // ------------------------------

  const renderEditHandouts = () => (
    <MenuItem
      dense={dense}
      onClick={() => {
        handleClose();
        props.onEditHandouts();
      }}
    >
      <TitleMenu icon={<DescriptionOutlinedIcon />} title="Edit Handouts" />
    </MenuItem>
  );

  // ------------------------------

  const renderContentInstructions = (isVideo) => (
    <MenuItem
      dense={dense}
      onClick={() => {
        handleClose();
        props.onEditVideoLink();
      }}
    >
      <TitleMenu
        icon={<LinkIcon />}
        title={isVideo ? "Video Link" : "Livestream Link"}
      />
    </MenuItem>
  );

  // ------------------------------

  const renderEditSurvey = (isVideo) => (
    <MenuItem
      dense={dense}
      onClick={() => {
        handleClose();
        props.onEditSurvey();
      }}
    >
      <TitleMenu icon={<ListAltIcon />} title={"Survey / Certificate"} />
    </MenuItem>
  );

  // ------------------------------

  const renderSurveyResults = () => (
    <MenuItem
      dense={dense}
      onClick={() => {
        handleClose();
        props.onFetchSurveyResults();
      }}
    >
      <TitleMenu icon={
        <Badge
          badgeContent={props.survey_result_count}
          color="error"
          overlap="circular"
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          <CloudDownloadOutlinedIcon />
        </Badge>} title="Survey Results" />
    </MenuItem>
  );

  // ------------------------------

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  // ------------------------------

  const handleClose = () => {
    setAnchorEl(null);
  };

  // ------------------------------

  const handleClick2 = (event) => {
    setAnchorE2(event.currentTarget);
  };

  // ------------------------------

  const handleClose2 = () => {
    setAnchorE2(null);
  };

  // ------------------------------

  const calcCapacityPercent = (seatsTaken, seatsAvailable) => {
    return seatsAvailable === 0 ? 0 : (seatsTaken / seatsAvailable) * 100;
  };

  // ------------------------------

  const renderActionButtons = () => {
    return (
      <div className={styles.actionButtonsContainer}>
        {props.activeTab === 1 && (
          <div className={styles.simpleRow}>
            <CustomIconButton
              icon={<EditIcon />}
              text={"Edit Draft"}
              onClick={props.onClick}
            />
            &nbsp;
            <PopMenu
              anchorEl={anchorEl}
              handleClick={handleClick}
              handleClose={handleClose}
              regBtn
            >
              {renderViewCourse()}
              {renderCopyToDrafts()}
              {renderDeleteDraft()}
            </PopMenu>
          </div>
        )}

        {props.activeTab === 2 && (
          <div className={styles.simpleRow}>
            <CustomIconButton
              icon={<GroupIcon />}
              text={"Registrations"}
              onClick={props.onClickStudent}
            />
            &nbsp;
            <Badge
              badgeContent={props.survey_result_count}
              color="error"
              style={{ zIndex: "0" }}
              overlap="circular"
            >
              <PopMenu
                anchorEl={anchorEl}
                handleClick={handleClick}
                handleClose={handleClose}
                regBtn
              >
                {renderViewCourse()}
                {renderCopyToDrafts()}
                {renderEditHandouts()}
                {props.onlineSeats !== "N/A" &&
                  renderContentInstructions(props.isVideo)}
                {renderSurveyResults()}
              </PopMenu>
            </Badge>
          </div>
        )}

        {props.activeTab === 0 && props.inReview === false && (
          <div className={styles.simpleRow}>
            <Badge
              badgeContent={props.newStudents}
              color="error"
              style={{ zIndex: "0", flex: 1 }}
              overlap="circular"
            >
              <CustomIconButton
                icon={<GroupIcon />}
                text={"Registrations"}
                onClick={props.onClickStudent}
              />
            </Badge>
            &nbsp;
            <Badge
              badgeContent={props.survey_result_count}
              color="error"
              style={{ zIndex: "0" }}
              overlap="circular"
            >
              <PopMenu
                anchorEl={anchorEl}
                handleClick={handleClick}
                handleClose={handleClose}
                regBtn
              >
                <div>
                  <div className={styles.courseItemMenuTitle}>{props.title}</div>
                  <div className={styles.courseItemMenuContainer}>
                    {renderQrCode()}
                    {renderViewCourse()}
                    {renderCopyToDrafts()}
                    {renderEditHandouts()}
                    {renderEditSurvey()}
                    {props.onlineSeats !== "N/A" &&
                      renderContentInstructions(props.isVideo)}
                    {renderUpdateCapacity()}
                    {renderPostAmendment()}
                    {renderSurveyResults()}
                    {renderCancelCourse(props.isVideo)}
                  </div>
                </div>
              </PopMenu>
            </Badge>
          </div>
        )}

        {props.activeTab === 0 && props.inReview === true && (
          <div
            className={styles.simpleRow}
            style={{
              diplay: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <InlinePop
              style={{ padding: 0, marginLeft: 4, width: 10 }}
              help="Course is under review.  Will be activated shortly."
            />
            <div style={{ marginLeft: 4 }}>UNDER REVIEW</div>
          </div>
        )}

      </div>
    );
  };

  // ------------------------------

  return (
    <CourseCard
      {...props}
      anchorEl={anchorEl}
      handleClick={handleClick}
      handleClose={handleClose}
      renderViewCourse={renderViewCourse}
      renderCopyToDrafts={renderCopyToDrafts}
      renderDeleteDraft={renderDeleteDraft}
      renderUpdateCapacity={renderUpdateCapacity}
      renderPostAmendment={renderPostAmendment}
      renderCancelCourse={renderCancelCourse}
      renderAdvertise={renderAdvertise}
      calcCapacityPercent={calcCapacityPercent}
      renderActionButtons={renderActionButtons}
    />
  );
};

export default CourseItem;
