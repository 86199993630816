import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Route, Redirect } from "react-router-dom";
import Spinner from "../../components/ui/spinner/Spinner";
import InfoModal from "../../components/modals/infoModal/InfoModal";
import Amendment from "../../model/Amendment";
import MatStepper from "../../components/ui/stepper/MatStepper";
import AmendmentForm from "./AmendmentForm";
import PreviewScreen from "../course/preview/PreviewScreen";
import SimpleContainer from "../../components/ui/container/Container";
import ActivationScreen from "../course/activate/ActivationScreen";
import * as courseActions from "../../store/actions/courses";
import * as logger from "../../globals/Logger";

class AmendmentScreen extends Component {
  state = {
    amendment: this.props.amendment,
    course: this.props.course,
    error: null,
    wait: false,
    activeStep: 0,
    activationChecked: false,
    showProf: false,
    showNotes: false,
    showDate: false,
    showPrice: false,
    activationSuccessModalOpen: null,
    waitNoRender: null,
    waitMessage: "",
  };

  // ------------------------------

  update = async (propValue) => {
    await this.setState({ ...this.state, ...propValue });
  };

  // ------------------------------

  componentDidUpdate = async (prevProps) => {
    if (prevProps.amendment.id !== this.props.amendment.id) {
      await this.update({
        amendment: this.props.amendment,
      });
    }

    if (prevProps.course.id !== this.props.course.id) {
      await this.update({
        course: this.props.course,
      });
    }
  };

  // ------------------------------

  validateMinOne = () => {
    const amend = this.state.amendment;

    if (
      (amend.notes && amend.notes.length > 0) ||
      (amend.discount && amend.discount.length > 0) ||
      (amend.professor && amend.professor.length > 0) ||
      amend.start_date
    ) {
      return true;
    }

    return false;
  };

  // ------------------------------

  activateAmendmentHandler = async () => {
    try {
      if (!this.validateMinOne() || !this.state.course.id) {
        await this.update({
          error: "Please verify amendment details.  Invalid fields.",
        });
        return;
      }
      await this.update({
        waitNoRender: true,
        waitMessage: "Activating Amendment",
      });
      const state = this.state;
      await this.props.activateAmendment(state.course.id, state.amendment);
      await this.update({
        waitNoRender: false,
        waitMessage: "",
        activationSuccessModalOpen: true,
      });
    } catch (err) {
      logger.error(err);
      this.update({ waitNoRender: false, waitMessage: "", error: err.message });
    }
  };

  // ------------------------------

  updateStepState = async (activeStep) => {
    await this.setState({
      ...this.state,
      activeStep: activeStep,
      activationChecked: false,
    });
  };

  // ------------------------------

  stepHandler = async (goingForward) => {
    const basePath = "/amendment";

    let activeStep = this.state.activeStep;
    if (goingForward) {
      activeStep = activeStep + 1;
    } else {
      activeStep = activeStep - 1;
    }

    switch (activeStep) {
      case 0:
        this.updateStepState(activeStep);
        this.props.history.push(basePath);
        break;
      case 1:
        if (goingForward) {
          const ok = await this.validateMinOne();

          if (ok) {
            this.updateStepState(activeStep);
            this.props.history.push(basePath + "/preview");
          }
        } else {
          this.updateStepState(activeStep);
          this.props.history.push(basePath + "/preview");
        }
        break;
      case 2:
        this.updateStepState(activeStep);
        this.props.history.push(basePath + "/activation");
        break;
      case 3:
        await this.activateAmendmentHandler();
        break;
      default:
        break;
    }

    window.scrollTo(0, 0);
  };

  // ------------------------------

  stepperExitHandler = () => {
    this.props.history.push("/courses");
  };

  // ------------------------------

  render() {
    if (!this.props.cId) {
      return <Redirect to="/courses" />;
    }

    if (this.state.wait) {
      return <Spinner />;
    }

    const stepper = (
      <MatStepper
        steps={["Enter amendment details", "Preview", "Activate on ZubU"]}
        touched={false}
        activeStep={this.state.activeStep}
        hideSave
        onSave={() => {}}
        onStep={this.stepHandler.bind(this)}
        onReset={() => {}}
        onExit={this.stepperExitHandler}
        blocked={
          (this.state.activationChecked === false &&
            this.state.activeStep === 2) ||
          !this.validateMinOne()
        }
      />
    );

    return (
      <div style={{ background: "white" }}>
        {this.state.waitNoRender && (
          <Spinner message={this.state.waitMessage} />
        )}

        {stepper}
        <SimpleContainer disableGutters={false}>
          {this.state.error !== null && (
            <InfoModal
              isErrorModal={true}
              title="An error has occured"
              message={this.state.error}
              open={this.state.error !== null}
              onClose={() => {
                this.update({ error: null });
              }}
            />
          )}
          {this.state.activationSuccessModalOpen !== null && (
            <InfoModal
              title="Amendment Activation Success"
              message="Congratulations you have completed the amendment activation on ZubU.  Registered students have been notified."
              open={true}
              onClose={() => {
                this.update({
                  activationSuccessModalOpen: null,
                });
                this.props.history.push("/courses");
              }}
            />
          )}

          <Route
            path="/amendment"
            exact
            render={() => (
              <AmendmentForm
                currency={this.props.currency}
                amendment={this.state.amendment}
                course={this.state.course}
                professorList={this.props.professorList}
                isInEdit={true}
                update={async (amendment) => {
                  await this.update({ amendment: amendment });
                }}
                showProf={this.state.showProf}
                showNotes={this.state.showNotes}
                showDate={this.state.showDate}
                showPrice={this.state.showPrice}
                setShowProf={this.update.bind(this, {
                  showProf: !this.state.showProf,
                })}
                setShowNotes={this.update.bind(this, {
                  showNotes: !this.state.showNotes,
                })}
                setShowDate={this.update.bind(this, {
                  showDate: !this.state.showDate,
                })}
                setShowPrice={this.update.bind(this, {
                  showPrice: !this.state.showPrice,
                })}
              />
            )}
          />

          <Route
            exact
            path="/amendment/preview"
            render={() => (
              <PreviewScreen
                course={this.state.course}
                amendment={this.state.amendment}
              />
            )}
          />

          <Route
            exact
            path="/amendment/activation"
            render={() => (
              <Fragment>
                <ActivationScreen
                  contextTitle="Amendment activation on ZubU"
                  title={this.state.course.title}
                  activationConfirmation={this.state.activationChecked}
                  updateActivationCheck={(confirmed) => {
                    this.setState({
                      ...this.state,
                      activationChecked: confirmed,
                    });
                  }}
                />
              </Fragment>
            )}
          />
        </SimpleContainer>
        {stepper}
        <div style={{ height: "70px" }}>&nbsp;</div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const cId = state.courses.courseId;
  const allCourses = [...state.courses.courses];
  const editCourse = allCourses.find((c) => c.id === cId);

  return {
    cId: cId,
    course: editCourse,
    amendment: new Amendment(),
    professorList: state.professor.professor,
    currency: state.profile.profile.currency,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    activateAmendment: (courseId, amendment) =>
      dispatch(courseActions.activateAmendment(courseId, amendment)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AmendmentScreen);
