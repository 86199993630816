// used for course viewer and registration details

import { momentLongDate } from "../globals/Dates2";
import { resolveCurrency, compareArray } from "../globals/Common";

class PricingItem {
  constructor(
    casting,
    audience,
    component,
    description,
    ce_odq,
    credit_lecture_hours,
    credit_handson_hours,
    cost,
    expiration_date,
    members_only
  ) {
    this.casting = casting;
    this.audience = audience;
    this.component = component;
    this.description = description;
    this.ce_odq = ce_odq;
    this.credit_lecture_hours = credit_lecture_hours;
    this.credit_handson_hours = credit_handson_hours;
    this.cost = cost;
    this.expiration_date = expiration_date;
    this.members_only = members_only ? members_only : false
  }

  resolve_odq = () => {
    if (this.ce_odq === -1 || this.ce_odq === "-1") {
      return "N/A";
    }

    return this.ce_odq;
  };

  expirationDate = () => {
    if (this.expiration_date) {
      return momentLongDate(this.expiration_date);
    }

    return "";
  };

  renderCreditBreakDown = (t) => {
    const total = this.credit_lecture_hours + this.credit_handson_hours;
    const ADATotal = `${t("pricing_option_credits")}: ` + total;

    if (this.credit_handson_hours > 0) {
      return (
        ADATotal +
        ` (${t("pricing_option_lecture")}: ` +
        this.credit_lecture_hours +
        ` / ${t("pricing_option_handson")}: ` +
        this.credit_handson_hours +
        ")"
      );
    } else {
      return (
        ADATotal +
        ` (${t("pricing_option_lecture")}: ` +
        this.credit_lecture_hours +
        ")"
      );
    }
  };

  // ------------------------------

  resolveCreditsDisplay = (t) => {
    if (this.ce_odq !== -1 && this.ce_odq !== "-1") {
      return (
        "ODQ: " + this.resolve_odq() + " / " + this.renderCreditBreakDown(t)
      );
    } else {
      return this.renderCreditBreakDown(t);
    }
  };

  // ------------------------------

  resolveCost = (amendments, index) => {
    let cost = this.cost;

    if (amendments) {
      for (const amendment of amendments) {
        if (amendment.discount && amendment.discount[index]) {
          cost = this.resolveCostByPercent(cost, amendment.discount[index]);
        }
      }
    }

    return cost;
  };

  // ------------------------------

  resolveCostByPercent = (amount, percent) => {
    const totalPercent = 100 - percent;
    return amount * (totalPercent / 100);
  };

  // ------------------------------

  resolveViewCost = (providerCurrency, rates, amendments, index) => {
    let cost = resolveCurrency(this.cost, providerCurrency, rates);

    if (amendments) {
      for (const amendment of amendments) {
        if (amendment.discount && amendment.discount[index]) {
          cost = this.resolveCostByPercent(cost, amendment.discount[index]);
        }
      }
    }

    return cost;
  };

  // ------------------------------

  isEqual = (pricingItem) => {
    return (
      this.casting === pricingItem.casting &&
      compareArray(this.audience, pricingItem.audience) &&
      this.component === pricingItem.component &&
      this.description === pricingItem.description &&
      this.ce_odq === pricingItem.ce_odq &&
      this.credit_lecture_hours === pricingItem.credit_lecture_hours &&
      this.credit_handson_hours === pricingItem.credit_handson_hours &&
      this.cost === pricingItem.cost &&
      this.expiration_date === pricingItem.expiration_date &&
      this.member_only === pricingItem.member_only
    );
  };
}

export default PricingItem;
