

import { getDuration } from "../globals/Dates2"

class VideoChapter {
    constructor(
        id,
        uri,
        title,
        description,
        duration,
        sequence
    ) {
        this.id = id;
        this.uri = uri;
        this.title = title;
        this.description = description;
        this.duration = duration;
        this.sequence = sequence;
    }

    getVideoLength = () => {
        if (this.duration > 0) {
            return getDuration(this.duration);
        }
        return 0;
    }
}

export default VideoChapter;
