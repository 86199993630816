import React from "react";
import { NavLink } from "react-router-dom";
import styles from "./NavigationItem.module.css";

const NavigationItem = (props) => {
  let arrStyles = [styles.navigationItem];
  if (props.topBar) {
    arrStyles.push(styles.smallFont);
  }
  if (props.mobile) {
    arrStyles.push(styles.mobileOnly);
  }

  return (
    <li className={arrStyles.join(" ")}>
      <NavLink exact to={props.link} activeClassName={styles.active}>
        {props.children}
      </NavLink>
    </li>
  );
};

export default NavigationItem;
