import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { withTranslation } from "react-i18next";
import styles from "./Staff.module.css";
import * as staffActions from "../../../store/actions/staff";
import SimpleContainer from "../../../components/ui/container/Container";
import StaffMember from "../../../model/StaffMember";
import Button from "@material-ui/core/Button";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import MatAppBar from "../../../components/ui/appBar/MatAppBar";
import FormButton from "../../../components/ui/button/FormButton";
import InfoModal from "../../../components/modals/infoModal/InfoModal";
import Spinner from "../../../components/ui/spinner/Spinner";
import EditIcon from "@material-ui/icons/Edit";
import StaffEditForm from "./StaffEditForm";
import { translate } from "../../../globals/Common";

import ConfirmModal from "../../../components/modals/confirmModal/ConfirmModal";

import { hasFormErrors, validateField } from "../../../globals/Validations";
import * as logger from "../../../globals/Logger";

class StaffEditScreen extends Component {
  state = {
    wait: false,
    waitMessage: "",
    message: null,
    messageTitle: null,
    errorModal: false,

    // ----- form
    staffMember: this.props.staffMember,
    formErrors: {},
    hasErrors: false,
    touched: false,
    openSaveConfirmModal: false,
  };

  // ------------------------------

  update = async (propValue) => {
    await this.setState({ ...this.state, ...propValue });
  };

  // ------------------------------

  componentDidUpdate = (prevProps) => {
    if (prevProps.staffMember.id !== this.props.staffMember.id) {
      this.update({
        staffMember: this.props.staffMember,
      });
    }
  };

  // ------------------------------

  closeErrorHandler = () => {
    this.update({
      message: null,
    });
  };

  // ------------------------------

  saveHandler = async () => {
    try {
      await this.validateForm();

      if (this.state.hasErrors) {
        await this.update({
          errorModal: true,
          message: this.props.t("settings_please_verify"),
        });
        return false;
      }

      if (this.state.touched) {
        this.openSaveConfirmHandler();
      }

      return true;
    } catch (err) {
      logger.error(err);
      this.update({
        wait: false,
        errorModal: true,
        message: err.message,
      });
      return false;
    }
  };

  // ------------------------------

  validateForm = async () => {
    const t = this.props.t;
    const p = this.state.staffMember;
    const e = {};
    const e2 = validateField(p, e, "first", "plainRequired", t);
    const e3 = validateField(p, e2, "last", "plainRequired", t);
    const e4 = validateField(p, e3, "email", "email", t);
    const e5 = validateField(p, e4, "occupation", "plainRequired", t);
    await this.update({ formErrors: e5 });
    await this.updateHasErrors(e5);
  };

  // ------------------------------

  updateHasErrors = async (formErrors) => {
    await this.update({ hasErrors: hasFormErrors(formErrors, {}) });
  };

  // ------------------------------

  openSaveConfirmHandler = () => {
    this.update({
      openSaveConfirmModal: true,
    });
  };

  // ------------------------------

  saveConfirmHandler = async (answer) => {
    if (answer === "ok") {
      try {
        await this.update({
          wait: true,
          openSaveConfirmModal: false,
        });

        if (this.props.sId === "new") {
          await this.props.addStaffMember(this.state.staffMember);
        } else if (this.props.sId && this.state.staffMember.id) {
          await this.props.updateStaffMember(this.state.staffMember);
        }

        this.props.history.goBack();
      } catch (err) {
        logger.error(err);
        this.update({
          wait: false,
          errorModal: true,
          message: err.message,
        });
      }
    } else {
      this.update({
        openSaveConfirmModal: false,
      });
    }
  };

  // ------------------------------

  render() {
    if (this.props.action !== "PUSH") {
      return <Redirect to="/mystaff" />;
    }

    if (this.state.wait) {
      return <Spinner />;
    }

    return (
      <div style={{ background: "white" }}>
        {this.state.openSaveConfirmModal && (
          <ConfirmModal
            isWarnModal
            title={this.props.t("g_confirmation_required")}
            message={
              <div className={styles.confirmModalContainer}>
                <div className={styles.confirmModalDisclaimer}>
                  {this.props.t("staff_save_disclaimer")}
                </div>

                <div className={styles.confirmModalInfoContainer}>
                  <div className={styles.row}>
                    {this.props.t("g_license_number")}:&nbsp;
                    <div
                      className={
                        this.state.staffMember.license
                          ? styles.bold
                          : styles.empty
                      }
                    >
                      {this.state.staffMember.license}
                    </div>
                  </div>

                  <div className={styles.row}>
                    Occupation:&nbsp;
                    <div className={styles.bold}>
                      {translate(
                        this.props.t,
                        this.state.staffMember.occupation
                      )}
                    </div>
                  </div>

                  <div className={styles.row}>
                    {this.props.t("g_first_name")}:&nbsp;
                    <div className={styles.bold}>
                      {this.state.staffMember.first}
                    </div>
                  </div>

                  <div className={styles.row}>
                    {this.props.t("g_last_name")}:&nbsp;
                    <div className={styles.bold}>
                      {this.state.staffMember.last}
                    </div>
                  </div>

                  <div className={styles.row}>
                    {this.props.t("g_email")}:&nbsp;
                    <div className={styles.bold}>
                      {this.state.staffMember.email}
                    </div>
                  </div>
                </div>
              </div>
            }
            open={this.state.openSaveConfirmModal}
            onClose={this.saveConfirmHandler}
            okButtonText={this.props.t("g_save")}
          />
        )}

        <MatAppBar
          static
          left={
            <Button
              color="inherit"
              style={{ color: "black" }}
              onClick={this.props.history.goBack}
              startIcon={<ArrowBackIosIcon />}
            >
              {this.props.t("g_back")}
            </Button>
          }
        />

        <SimpleContainer disableGutters={false}>
          <div style={{ backgroundColor: "white" }}>
            {this.state.wait && <Spinner message={this.state.waitMessage} />}

            <InfoModal
              isErrorModal={this.state.errorModal}
              title={
                this.state.errorModal
                  ? this.props.t("error_occurred")
                  : this.state.messageTitle
              }
              message={this.state.message}
              open={this.state.message !== null}
              onClose={this.closeErrorHandler}
            />

            <div style={{ height: "80px" }}>&nbsp;</div>

            <div className={styles.sectionTitle}>
              <div className={styles.centerRow}>
                <EditIcon style={{ color: "white" }} />
                &nbsp;&nbsp;
                {this.props.sId === "new"
                  ? this.props.t("g_add_staff")
                  : this.props.t("g_edit_staff")}
              </div>
            </div>

            {this.state.staffMember && (
              <div style={{ paddingTop: "20px" }}>
                <StaffEditForm
                  staffMember={this.state.staffMember}
                  formErrors={this.state.formErrors}
                  updateFormErrors={async (errors) => {
                    await this.update({ formErrors: errors });
                    await this.updateHasErrors(errors);
                  }}
                  updateHasErrors={async (errors) => {
                    await this.updateHasErrors(errors);
                  }}
                  update={async (staffMember) => {
                    await this.update({
                      staffMember: staffMember,
                      touched: true,
                    });
                  }}
                />
                <FormButton
                  label={this.props.t("g_save")}
                  onClick={this.saveHandler}
                  disabled={this.state.hasErrors || !this.state.touched}
                />
              </div>
            )}
          </div>
        </SimpleContainer>

        <div style={{ height: "80px" }}>&nbsp;</div>
      </div>
    );
  }
}

// ------------------------------

const mapStateToProps = (state, props) => {
  const sId = state.staff.staffId;
  const staffList = state.staff.staff;
  const editStaff = staffList.find((s) => s.id === sId);

  return {
    action: props.history.action,
    staffMember: editStaff ? editStaff : new StaffMember(),
    sId: sId,
  };
};

// ------------------------------

const mapDispatchToProps = (dispatch) => {
  return {
    addStaffMember: (staff) => dispatch(staffActions.addStaffMember(staff)),
    updateStaffMember: (staff) =>
      dispatch(staffActions.updateStaffMember(staff)),
  };
};

export default withTranslation("translations")(
  connect(mapStateToProps, mapDispatchToProps)(StaffEditScreen)
);
