import React, { Component } from "react";
import { connect } from "react-redux";
import styles from "./ProfileScreen.module.css";
import validate from "validate.js";
import * as profileActions from "../../../store/actions/profile";
import Spinner from "../../../components/ui/spinner/Spinner";
import InfoModal from "../../../components/modals/infoModal/InfoModal";
import SimpleContainer from "../../../components/ui/container/Container";
import { constraints, hasFormErrors } from "../../../globals/Validations";
import WallpaperIcon from "@material-ui/icons/Wallpaper";
import StorefrontIcon from "@material-ui/icons/Storefront";
import MatSelectOutline from "../../../components/ui/labelSelect/MatSelectOutline";
import MatInputOutline from "../../../components/ui/input/MatInputOutline";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import FormButton from "../../../components/ui/button/FormButton";
import { hasError } from "../../../globals/Validations";
import ImageInput from "../../../components/ui/image/ImageInput";
import ImageCropModal from "../../../components/modals/imageCropModal/ImageCropModal";
import { buildSingleSelect } from "../../../globals/Common";
import { selectCountry } from "../../../globals/Data";
import InlinePop from "../../../components/ui/popover/InlinePop";
import * as logger from "../../../globals/Logger";
import AlertSuccess from "../../../components/ui/snackbar/AlertSuccess";
import { zubuUrl } from "../../../globals/ApiKeys";
import helpLogoCertificate from "../../../assets/images/inApp/help_logo_certificate.png";
import helpSignCertificate from "../../../assets/images/inApp/help_sign_certificate.png";
import helpCoverStorefront from "../../../assets/images/inApp/help_cover_storefront.png";
import helpAboutStorefront from "../../../assets/images/inApp/help_about_storefront.png";

class ProfileScreen extends Component {
  state = {
    profile: this.props.profile,
    wait: false,
    isInEdit: true,
    error: null,
    formErrors: {},
    hasErrors: false,
    imageToCrop: null,
    imageField: "",
    aspectRatio: { aspect: 1 / 1, width: 170, height: 170 },
    success: null,
    finalImageSize: null,
  };

  // ------------------------------

  update = async (propValue) => {
    await this.setState({ ...this.state, ...propValue });
  };

  // ------------------------------

  componentDidMount = async () => {
    try {
      window.scrollTo(0, 0);
      this.update({ error: null, wait: true });
      await this.props.fetchProfile();
      this.update({ error: null, wait: false });
    } catch (error) {
      logger.error(error);
      this.update({ wait: false, error: error.message });
    }
  };

  // ------------------------------

  componentDidUpdate = (prevProps) => {
    if (prevProps.profile !== this.props.profile) {
      this.update({
        profile: this.props.profile,
      });
    }
  };

  // ------------------------------

  saveHandler = async () => {
    try {
      await this.validateForm();

      if (this.state.hasErrors) {
        await this.update({
          error: "Please verify details.  Invalid fields.",
        });
        return;
      }

      await this.update({ wait: true });
      await this.props.updateProfile(this.state.profile);
      await this.update({ wait: false, success: "Profile saved successfully" });
    } catch (err) {
      logger.error(err);
      await this.update({ wait: false, success: null, error: err.message });
    }
  };

  // ------------------------------

  validateForm = async () => {
    const e0 = this.state.formErrors;
    const e1 = this.validateField(e0, "providerName", "plainRequired");
    const e2 = this.validateField(e1, "country", "required");
    const e22 = this.validateField(e2, "cancel_policy", "requiredIfPopulated");
    const e3 = this.validateField(e22, "contact_email", "email");

    await this.update({ formErrors: e3 });
    await this.hasErrorsHandler(e3);
  };

  // ------------------------------

  validateHandler = async (field, validatorKey) => {
    if (field === null) {
      await this.hasErrorsHandler(this.state.formErrors);
    } else {
      const errors = this.validateField(
        this.state.formErrors,
        field,
        validatorKey
      );
      await this.update({ formErrors: errors }); // await is crutial here
      await this.hasErrorsHandler(errors);
    }
  };

  // ------------------------------

  validateField = (paramFormErrors, field, validatorKey) => {
    let validateObj = {};
    validateObj[validatorKey] = this.state.profile[field];
    const validationResponse = validate(validateObj, constraints);
    const updateFormErrors = { ...paramFormErrors };

    if (validationResponse[validatorKey]) {
      const firstError = validationResponse[validatorKey][0];
      updateFormErrors[field] = firstError;
    } else {
      delete updateFormErrors[field];
    }
    return updateFormErrors;
  };

  // ------------------------------

  hasErrorsHandler = async (formErrors) => {
    await this.update({ hasErrors: hasFormErrors(formErrors, {}) });
  };

  // ------------------------------

  inputChangeHandler = (e) => {
    const updateProfile = { ...this.state.profile };
    updateProfile[e.target.name] = e.target.value;
    this.update({ profile: updateProfile });
  };

  // ------------------------------

  selectChangeHandler = (key, e, select) => {
    const updateProfile = { ...this.state.profile };
    updateProfile[key] = select ? select.value : "";
    this.update({ profile: updateProfile });
  };

  // ------------------------------

  multiSelectChangeHandler = (targetName, e, selects) => {
    const updateProfile = { ...this.state.profile };
    const arrValues = [];

    for (const select of selects) {
      arrValues.push(select.value);
    }

    updateProfile[targetName] = arrValues;
    this.update({ profile: updateProfile });
  };

  // ------------------------------

  switchToEditHandler = () => {
    this.update({ isInEdit: !this.state.isInEdit });
  };

  // ------------------------------

  render() {
    if (this.state.wait) {
      return <Spinner />;
    }

    return (
      <div style={{ background: "white" }}>
        {this.state.success !== null && (
          <AlertSuccess
            message={this.state.success}
            open={this.state.success !== null}
            onClose={() => {
              this.update({ success: null });
            }}
          />
        )}

        <SimpleContainer disableGutters={false}>
          <InfoModal
            isErrorModal={true}
            title="An error has occured"
            message={this.state.error}
            open={this.state.error !== null}
            onClose={() => {
              this.update({ error: null });
            }}
          />

          {this.state.imageToCrop !== null && (
            <ImageCropModal
              defaultFile={this.state.defaultImage}
              finalImageSize={this.state.finalImageSize}
              aspect={this.state.aspectRatio}
              title="Select and Crop Photo"
              file={this.state.imageToCrop}
              open={this.state.imageToCrop !== null}
              onClose={(url) => {
                if (url) {
                  const updateProf = { ...this.state.profile };
                  updateProf[this.state.imageField] = url;

                  this.update({ profile: updateProf, imageToCrop: null });
                } else {
                  this.update({ imageToCrop: null });
                }
              }}
            />
          )}

          <div style={{ height: "40px" }}>&nbsp;</div>

          <div className={styles.sectionTitle}>
            <div className={styles.centerRow}>
              <AccountCircleIcon style={{ color: "white" }} />
              &nbsp;&nbsp;Profile
            </div>
          </div>

          <div style={{ height: "10px" }}>&nbsp;</div>

          <MatInputOutline
            nobottommargin="true"
            title="Provider Name"
            name="providerName"
            id="providerName"
            value={this.state.profile.providerName}
            onChange={this.inputChangeHandler}
            onBlur={this.validateHandler.bind(
              this,
              "providerName",
              "plainRequired"
            )}
            maxLength={100}
            error={hasError(this.state.formErrors.providerName)}
            helpertext={this.state.formErrors.providerName}
            readOnly={true}
            required
          />

          <MatSelectOutline
            notopbottommargin="true"
            title="Country"
            placeholder="Enter Country..."
            name="country"
            id="country"
            onChange={this.selectChangeHandler.bind(this, "country")}
            options={selectCountry}
            value={buildSingleSelect(this.state.profile.country)}
            getOptionLabel={(option) => option.label}
            getOptionSelected={(opt1, opt2) => opt1.value === opt2.value}
            onBlur={this.validateHandler.bind(this, "country", "required")}
            error={hasError(this.state.formErrors.country)}
            helperText={this.state.formErrors.country}
            disabled={true}
            multiple={false}
            required={true}
          />

          <MatInputOutline
            notopbottommargin="true"
            title="Email"
            name="accountEmail"
            id="accountEmail"
            value={this.props.accountEmail}
            onChange={() => {}}
            onBlur={() => {}}
            maxLength={100}
            readOnly={true}
            required
          />

          <InlinePop help="Email that will appear as the contact email of your active courses.  This can differ from the email associated to your account." />

          <MatInputOutline
            notopbottommargin="true"
            title="Course Contact Email"
            name="contact_email"
            id="contact_email"
            value={this.state.profile.contact_email}
            onChange={this.inputChangeHandler}
            onBlur={this.validateHandler.bind(this, "contact_email", "email")}
            maxLength={100}
            error={hasError(this.state.formErrors.contact_email)}
            helpertext={this.state.formErrors.contact_email}
            required
          />

          <InlinePop
            help={
              <div style={{ width: "300px", paddingRight: "15px" }}>
                <div className={styles.divp}>
                  Enter your detailed global cancellation policy. This policy
                  will automatically be attached to all your courses once posted
                  on the ZubU app. You must honor this policy. Failure to do so
                  may result in a suspended account.
                </div>
                <div className={styles.divp} style={{ paddingTop: "10px" }}>
                  Example:
                </div>
                <div className={styles.divp}>
                  Full refund 2 weeks prior to course.
                </div>
                <div className={styles.divp}>
                  50% refund 1 week prior to course.
                </div>
                <div className={styles.divp}>
                  No refund 24hrs prior to course.
                </div>
                <div className={styles.divp}>No refunds on video courses.</div>
                <div className={styles.divp}>
                  Courses over $2000 USD, no refund 2 weeks prior to course.
                </div>
              </div>
            }
          />

          <MatInputOutline
            notopbottommargin="true"
            title="Cancellation Policy"
            name="cancel_policy"
            value={this.state.profile.cancel_policy}
            multiline={true}
            rows={25}
            onChange={this.inputChangeHandler}
            maxLength={2000}
            onBlur={this.validateHandler.bind(
              this,
              "cancel_policy",
              "requiredIfPopulated"
            )}
            error={hasError(this.state.formErrors.cancel_policy)}
            helpertext={this.state.formErrors.cancel_policy}
            readOnly={!this.state.isInEdit}
          />

          <div style={{ height: "10px" }}>&nbsp;</div>

          <div className={styles.sectionTitle}>
            <div className={styles.centerRow}>
              <WallpaperIcon style={{ color: "white" }} />
              &nbsp;&nbsp;Branding
            </div>
          </div>

          <InlinePop
            help={
              <div style={{ width: "300px", paddingRight: "15px" }}>
                <div className={styles.divp}>
                  Image must be <b>170 x 170 pixels</b> in size.
                </div>

                <div className={styles.divp}>
                  Your logo will appear on:
                  <ol>
                    <li>Active course listings</li>
                    <li>Issued certificates of completion</li>{" "}
                    <li>Your provider storefront</li>
                  </ol>
                  <img src={helpLogoCertificate} style={{ width: "130px" }} />
                  <img src={helpCoverStorefront} style={{ width: "220px" }} />
                </div>
              </div>
            }
          />

          <ImageInput
            header="Company Logo"
            readOnly={!this.state.isInEdit}
            id="logo"
            title="Select Company Logo"
            image={this.state.profile.logo}
            onImageSelect={(imageUri) => {
              this.update({
                aspectRatio: { aspect: 1 / 1, width: 170, height: 170 },
                imageToCrop: imageUri,
                imageField: "logo",
                finalImageSize: null,
                defaultImage: imageUri,
              });
            }}
          />

          <InlinePop
            help={
              <div style={{ width: "300px", paddingRight: "15px" }}>
                <div className={styles.divp}>
                  Image must be <b>300 x 85 pixels</b> in size.
                </div>

                <div className={styles.divp}>
                  Your signature will appear on:
                  <ol>
                    <li>Issued certificates of completion.</li>
                  </ol>
                  <img src={helpSignCertificate} style={{ width: "130px" }} />
                </div>
              </div>
            }
          />

          <ImageInput
            readOnly={!this.state.isInEdit}
            id="signature"
            header="Digital Signature"
            title="Select Digital Signature"
            rectangle
            image={this.state.profile.digitalSignature}
            onImageSelect={(imageUri) => {
              this.update({
                imageToCrop: imageUri,
                aspectRatio: { aspect: 340 / 85, height: 85, width: 340 },
                imageField: "digitalSignature",
                finalImageSize: null,
                defaultImage: imageUri,
              });
            }}
          />

          <div style={{ height: "10px" }}>&nbsp;</div>

          <div className={styles.sectionTitle}>
            <div className={styles.centerRow}>
              <StorefrontIcon style={{ color: "white" }} />
              &nbsp;&nbsp;Storefront
            </div>
          </div>

          <InlinePop
            help={
              <div style={{ width: "300px", paddingRight: "15px" }}>
                <div className={styles.divp}>
                  Image must be <b>2050 x 512 pixels</b> in size.
                </div>

                <div className={styles.divp}>
                  Personalize your provider store front with a branded cover
                  photo.
                </div>

                <img src={helpAboutStorefront} />
              </div>
            }
          />

          <ImageInput
            providerCoverPhoto
            header="Storefront"
            readOnly={!this.state.isInEdit}
            id="storefront"
            title="Select Storefront"
            image={this.state.profile.storefront}
            onImageSelect={(imageUri) => {
              this.update({
                aspectRatio: {
                  aspect: 1 / 1,
                  width: 2050 / 4 + 39,
                  height: 512 / 4 + 10,
                },
                imageToCrop: imageUri,
                imageField: "storefront",
                finalImageSize: { width: 2050, height: 512 },
                defaultImage: imageUri,
              });
            }}
          />

          <InlinePop
            help={
              <div style={{ width: "300px", paddingRight: "15px" }}>
                <div className={styles.divp}>
                  Tell users about your company vision and CE program. Describe
                  your mission, goals and the types of CE courses your offer.
                </div>

                <img src={helpAboutStorefront} />
              </div>
            }
          />

          <MatInputOutline
            notopbottommargin="true"
            title="Storefront Description English"
            name="about_en"
            value={this.state.profile.about_en}
            multiline={true}
            rows={10}
            onChange={this.inputChangeHandler}
            maxLength={2000}
            readOnly={!this.state.isInEdit}
          />

          <MatInputOutline
            notopbottommargin="true"
            title="Storefront Description French"
            name="about_fr"
            value={this.state.profile.about_fr}
            multiline={true}
            rows={10}
            onChange={this.inputChangeHandler}
            maxLength={2000}
            readOnly={!this.state.isInEdit}
          />

          {this.state.isInEdit && (
            <FormButton
              label="Save"
              onClick={this.saveHandler}
              disabled={!this.state.isInEdit || this.state.hasErrors}
            />
          )}
        </SimpleContainer>

        <div style={{ height: "80px" }}>&nbsp;</div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  let ALL_COURSES_URL = "";

  if (state.profile && state.profile.profile) {
    const prof = state.profile.profile;
    ALL_COURSES_URL = `${zubuUrl}/shop?p=${prof.id}`;
  }

  return {
    ALL_COURSES_URL: ALL_COURSES_URL,
    accountEmail: state.auth.email,
    profile: state.profile.profile,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchProfile: () => dispatch(profileActions.fetchProfile()),
    updateProfile: (profile) => dispatch(profileActions.updateProfile(profile)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProfileScreen);
