import { roundAmount } from "../globals/Common";

class Rating {
  constructor(rating_count, rating_sum) {
    this.rating_count = rating_count;
    this.rating_sum = rating_sum;
  }

  averageRating = () => {
    return roundAmount(this.rating_sum / this.rating_count);
  };
}

export default Rating;
