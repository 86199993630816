import StaffMember from "../../model/StaffMember";
import { handleErrorResponse } from "../../globals/Error";
import { dbName } from "../../globals/ApiKeys";
export const SET_STAFF_ID = "SET_STAFF_ID";
export const ADD_MEMBER = "ADD_MEMBER";
export const UPDATE_MEMBER = "UPDATE_MEMBER";
export const REMOVE_MEMBER = "REMOVE_MEMBER";
export const SET_MEMBERS = "SET_MEMBERS";

// ------------------------------

export const setEditAction = (id) => {
  return {
    type: SET_STAFF_ID,
    staffId: id,
  };
};

// -------------------------------------

export const fetchStaffMembers = () => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;

    if (!token) {
      return dispatch({ type: SET_MEMBERS, staff: [] });
    }

    const userId = getState().auth.userId;

    const response = await fetch(
      `https://${dbName}.firebaseio.com/staff/${userId}.json?auth=${token}`
    );

    if (!response.ok) {
      handleErrorResponse(await response.json());
    }

    const resData = await response.json();

    const loadedStaff = [];
    if (resData) {
      Object.keys(resData).forEach((key, index) => {
        const staff = resData[key];
        if (typeof resData[key] === "object") {
          loadedStaff.push(
            new StaffMember(
              key,
              staff.license,
              staff.occupation,
              staff.last,
              staff.first,
              staff.email
            )
          );
        }
      });
    }

    dispatch({ type: SET_MEMBERS, staff: loadedStaff });
  };
};

// -------------------------------------

export const addStaffMember = (staffParam) => {
  return async (dispatch, getState) => {
    const userId = getState().auth.userId;
    const token = getState().auth.token;

    const response = await fetch(
      `https://${dbName}.firebaseio.com/staff/${userId}.json?auth=${token}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "applications/json",
        },
        body: JSON.stringify({
          license: staffParam.license,
          occupation: staffParam.occupation,
          last: staffParam.last,
          first: staffParam.first,
          email: staffParam.email,
        }),
      }
    );

    if (!response.ok) {
      handleErrorResponse(await response.json());
    }

    const resData = await response.json();

    dispatch({
      type: ADD_MEMBER,
      staff: { smParam: staffParam, smId: resData.name, ownerId: userId },
    });
  };
};

// -------------------------------------

export const updateStaffMember = (staffParam) => {
  return async (dispatch, getState) => {
    const staffMemberId = staffParam.id;
    const userId = getState().auth.userId;
    const token = getState().auth.token;

    await fetch(
      `https://${dbName}.firebaseio.com/staff/${userId}/${staffMemberId}.json?auth=${token}`,
      {
        method: "PATCH",
        headers: {
          "Content-Type": "applications/json",
        },
        body: JSON.stringify({
          license: staffParam.license,
          occupation: staffParam.occupation,
          last: staffParam.last,
          first: staffParam.first,
          email: staffParam.email,
        }),
      }
    );

    dispatch({
      type: UPDATE_MEMBER,
      staff: staffParam,
    });
  };
};

// -------------------------------------

export const removeStaffMember = (staffMemberId) => {
  return async (dispatch, getState) => {
    const userId = getState().auth.userId;
    const token = getState().auth.token;

    await fetch(
      `https://${dbName}.firebaseio.com/staff/${userId}/${staffMemberId}.json?auth=${token}`,
      {
        method: "DELETE",
      }
    );

    dispatch({ type: REMOVE_MEMBER, smId: staffMemberId });
  };
};
