import {
  ADD_MEMBER,
  UPDATE_MEMBER,
  REMOVE_MEMBER,
  SET_MEMBERS,
  SET_STAFF_ID,
} from "../actions/staff";
import StaffMember from "../../model/StaffMember";
import { LOGOUT } from "../actions/auth";
import { updateObject } from "../../globals/Common";

const initialState = {
  staffId: "",
  staff: [],
};

const staffReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_STAFF_ID:
      return updateObject(state, { staffId: action.staffId });
    case SET_MEMBERS:
      return { staff: action.staff };
    case ADD_MEMBER:
      const newSm = new StaffMember(
        action.staff.smId,
        action.staff.smParam.license,
        action.staff.smParam.occupation,
        action.staff.smParam.last,
        action.staff.smParam.first,
        action.staff.smParam.email
      );
      return {
        ...state,
        staff: state.staff.concat(newSm),
      };
    case UPDATE_MEMBER:
      const smIndex = state.staff.findIndex(
        (staff) => staff.id === action.staff.id
      );

      const updatedSm = new StaffMember(
        action.staff.id,
        action.staff.license,
        action.staff.occupation,
        action.staff.last,
        action.staff.first,
        action.staff.email
      );

      const updatedStaff = [...state.staff];
      updatedStaff[smIndex] = updatedSm;
      return {
        ...state,
        staff: updatedStaff,
      };
    case REMOVE_MEMBER:
      const copyStateStaff = [...state.staff];
      const updatedDelStaff = copyStateStaff.filter((sm) => {
        return sm.id != action.smId;
      });

      return {
        ...state,
        staff: updatedDelStaff,
      };
    case LOGOUT:
      return initialState;
    default:
      return state;
  }
};

export default staffReducer;
