import {
  SET_SETTING,
  UPDATE_COURSE_VIEW,
  SHOW_DASHBOARD,
  UPDATE_CHARTS,
  SET_ANALYTICS_DATE,
  SET_ANALYTICS_COURSE,
  UPDATE_NOTIFY_PAYOUT_PAID,
  UPDATE_NOTIFY_LIVESTREAM_CAPACITY,
  UPDATE_NOTIFY_CLASS_LECTURE_CAPACITY,
  UPDATE_NOTIFY_ClASS_HANDSON_CAPACITY,
  UPDATE_NOTIFY_ONLINE_VIDEO_CAPACITY,
  UPDATE_LIVESTREAM_CAPACITY_LIMIT,
  UPDATE_CLASS_LECTURE_CAPACITY_LIMIT,
  UPDATE_ClASS_HANDSON_CAPACITY_LIMIT,
  UPDATE_ONLINE_VIDEO_CAPACITY_LIMIT,
  UPD_CERT_LIVE_DELIVERY,
  UPD_CERT_VIDEO_DELIVERY,
  UPD_CERT_HEADER,
  UPD_CERT_FOOTER,
  SET_MOBILE_SETTING,
  UPDATE_MOBILE_CURRENCY,
} from "../actions/setting";
import Setting from "../../model/Setting";
import MobileSetting from "../../model/MobileSetting";
import { updateObject } from "../../globals/Common";
import { LOGOUT } from "../actions/auth";

const initialState = {
  setting: {},
  mobileSetting: {},
};

const settingReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_SETTING:
      return {
        setting: action.setting,
      };
    case UPDATE_COURSE_VIEW:
      const updateCourseView = { ...state.setting };
      return {
        setting: new Setting(
          action.course_view,
          updateCourseView.language,
          updateCourseView.show_dashboard,
          updateCourseView.charts,
          updateCourseView.analytics_date,
          updateCourseView.analytics_course,
          updateCourseView.notify_payout_paid,
          updateCourseView.notify_livestream_capacity,
          updateCourseView.notify_classroom_lecture_capacity,
          updateCourseView.notify_classroom_handson_capacity,
          updateCourseView.notify_online_video_capacity,
          updateCourseView.livestream_capacity_limit,
          updateCourseView.classroom_lecture_capacity_limit,
          updateCourseView.classroom_handson_capacity_limit,
          updateCourseView.online_video_capacity_limit,
          updateCourseView.certificate_delivery_live,
          updateCourseView.certificate_delivery_video,
          updateCourseView.certificate_header,
          updateCourseView.certificate_footer
        ),
      };
    case UPDATE_CHARTS:
      const updateCharts = { ...state.setting };
      return {
        setting: new Setting(
          updateCharts.course_view,
          updateCharts.language,
          updateCharts.show_dashboard,
          action.charts,
          updateCharts.analytics_date,
          updateCharts.analytics_course,
          updateCharts.notify_payout_paid,
          updateCharts.notify_livestream_capacity,
          updateCharts.notify_classroom_lecture_capacity,
          updateCharts.notify_classroom_handson_capacity,
          updateCharts.notify_online_video_capacity,
          updateCharts.livestream_capacity_limit,
          updateCharts.classroom_lecture_capacity_limit,
          updateCharts.classroom_handson_capacity_limit,
          updateCharts.online_video_capacity_limit,
          updateCharts.certificate_delivery_live,
          updateCharts.certificate_delivery_video,
          updateCharts.certificate_header,
          updateCharts.certificate_footer
        ),
      };
    case SHOW_DASHBOARD:
      const updateDashboard = { ...state.setting };
      return {
        setting: new Setting(
          updateDashboard.course_view,
          updateDashboard.language,
          action.show_dashboard,
          updateDashboard.charts,
          updateDashboard.analytics_date,
          updateDashboard.analytics_course,
          updateDashboard.notify_payout_paid,
          updateDashboard.notify_livestream_capacity,
          updateDashboard.notify_classroom_lecture_capacity,
          updateDashboard.notify_classroom_handson_capacity,
          updateDashboard.notify_online_video_capacity,
          updateDashboard.livestream_capacity_limit,
          updateDashboard.classroom_lecture_capacity_limit,
          updateDashboard.classroom_handson_capacity_limit,
          updateDashboard.online_video_capacity_limit,
          updateDashboard.certificate_delivery_live,
          updateDashboard.certificate_delivery_video,
          updateDashboard.certificate_header,
          updateDashboard.certificate_footer
        ),
      };
    case SET_ANALYTICS_DATE:
      const updateAnalyticsDate = { ...state.setting };
      return {
        setting: new Setting(
          updateAnalyticsDate.course_view,
          updateAnalyticsDate.language,
          updateAnalyticsDate.show_dashboard,
          updateAnalyticsDate.charts,
          action.analytics_date,
          updateAnalyticsDate.analytics_course,
          updateAnalyticsDate.notify_payout_paid,
          updateAnalyticsDate.notify_livestream_capacity,
          updateAnalyticsDate.notify_classroom_lecture_capacity,
          updateAnalyticsDate.notify_classroom_handson_capacity,
          updateAnalyticsDate.notify_online_video_capacity,
          updateAnalyticsDate.livestream_capacity_limit,
          updateAnalyticsDate.classroom_lecture_capacity_limit,
          updateAnalyticsDate.classroom_handson_capacity_limit,
          updateAnalyticsDate.online_video_capacity_limit,
          updateAnalyticsDate.certificate_delivery_live,
          updateAnalyticsDate.certificate_delivery_video,
          updateAnalyticsDate.certificate_header,
          updateAnalyticsDate.certificate_footer
        ),
      };
    case SET_ANALYTICS_COURSE:
      const updateAnalyticsCourse = { ...state.setting };
      return {
        setting: new Setting(
          updateAnalyticsCourse.course_view,
          updateAnalyticsCourse.language,
          updateAnalyticsCourse.show_dashboard,
          updateAnalyticsCourse.charts,
          updateAnalyticsCourse.analytics_date,
          action.analytics_course,
          updateAnalyticsCourse.notify_payout_paid,
          updateAnalyticsCourse.notify_livestream_capacity,
          updateAnalyticsCourse.notify_classroom_lecture_capacity,
          updateAnalyticsCourse.notify_classroom_handson_capacity,
          updateAnalyticsCourse.notify_online_video_capacity,
          updateAnalyticsCourse.livestream_capacity_limit,
          updateAnalyticsCourse.classroom_lecture_capacity_limit,
          updateAnalyticsCourse.classroom_handson_capacity_limit,
          updateAnalyticsCourse.online_video_capacity_limit,
          updateAnalyticsCourse.certificate_delivery_live,
          updateAnalyticsCourse.certificate_delivery_video,
          updateAnalyticsCourse.certificate_header,
          updateAnalyticsCourse.certificate_footer
        ),
      };
    case UPDATE_NOTIFY_PAYOUT_PAID:
      const updateNotifyPayoutPaid = { ...state.setting };
      return {
        setting: new Setting(
          updateNotifyPayoutPaid.course_view,
          updateNotifyPayoutPaid.language,
          updateNotifyPayoutPaid.show_dashboard,
          updateNotifyPayoutPaid.charts,
          updateNotifyPayoutPaid.analytics_date,
          updateNotifyPayoutPaid.analytics_course,
          action.notify,
          updateNotifyPayoutPaid.notify_livestream_capacity,
          updateNotifyPayoutPaid.notify_classroom_lecture_capacity,
          updateNotifyPayoutPaid.notify_classroom_handson_capacity,
          updateNotifyPayoutPaid.notify_online_video_capacity,
          updateNotifyPayoutPaid.livestream_capacity_limit,
          updateNotifyPayoutPaid.classroom_lecture_capacity_limit,
          updateNotifyPayoutPaid.classroom_handson_capacity_limit,
          updateNotifyPayoutPaid.online_video_capacity_limit,
          updateNotifyPayoutPaid.certificate_delivery_live,
          updateNotifyPayoutPaid.certificate_delivery_video,
          updateNotifyPayoutPaid.certificate_header,
          updateNotifyPayoutPaid.certificate_footer
        ),
      };
    case UPDATE_NOTIFY_LIVESTREAM_CAPACITY:
      const updateNotifyLiveCapacity = { ...state.setting };
      return {
        setting: new Setting(
          updateNotifyLiveCapacity.course_view,
          updateNotifyLiveCapacity.language,
          updateNotifyLiveCapacity.show_dashboard,
          updateNotifyLiveCapacity.charts,
          updateNotifyLiveCapacity.analytics_date,
          updateNotifyLiveCapacity.analytics_course,
          updateNotifyLiveCapacity.notify_payout_paid,
          action.notify,
          updateNotifyLiveCapacity.notify_classroom_lecture_capacity,
          updateNotifyLiveCapacity.notify_classroom_handson_capacity,
          updateNotifyLiveCapacity.notify_online_video_capacity,
          updateNotifyLiveCapacity.livestream_capacity_limit,
          updateNotifyLiveCapacity.classroom_lecture_capacity_limit,
          updateNotifyLiveCapacity.classroom_handson_capacity_limit,
          updateNotifyLiveCapacity.online_video_capacity_limit,
          updateNotifyLiveCapacity.certificate_delivery_live,
          updateNotifyLiveCapacity.certificate_delivery_video,
          updateNotifyLiveCapacity.certificate_header,
          updateNotifyLiveCapacity.certificate_footer
        ),
      };
    case UPDATE_NOTIFY_CLASS_LECTURE_CAPACITY:
      const updateNotifyClassLectureCapacity = { ...state.setting };
      return {
        setting: new Setting(
          updateNotifyClassLectureCapacity.course_view,
          updateNotifyClassLectureCapacity.language,
          updateNotifyClassLectureCapacity.show_dashboard,
          updateNotifyClassLectureCapacity.charts,
          updateNotifyClassLectureCapacity.analytics_date,
          updateNotifyClassLectureCapacity.analytics_course,
          updateNotifyClassLectureCapacity.notify_payout_paid,
          updateNotifyClassLectureCapacity.notify_livestream_capacity,
          action.notify,
          updateNotifyClassLectureCapacity.notify_classroom_handson_capacity,
          updateNotifyClassLectureCapacity.notify_online_video_capacity,
          updateNotifyClassLectureCapacity.livestream_capacity_limit,
          updateNotifyClassLectureCapacity.classroom_lecture_capacity_limit,
          updateNotifyClassLectureCapacity.classroom_handson_capacity_limit,
          updateNotifyClassLectureCapacity.online_video_capacity_limit,
          updateNotifyClassLectureCapacity.certificate_delivery_live,
          updateNotifyClassLectureCapacity.certificate_delivery_video,
          updateNotifyClassLectureCapacity.certificate_header,
          updateNotifyClassLectureCapacity.certificate_footer
        ),
      };
    case UPDATE_NOTIFY_ClASS_HANDSON_CAPACITY:
      const updateNotifyClassHandsonCapacity = { ...state.setting };
      return {
        setting: new Setting(
          updateNotifyClassHandsonCapacity.course_view,
          updateNotifyClassHandsonCapacity.language,
          updateNotifyClassHandsonCapacity.show_dashboard,
          updateNotifyClassHandsonCapacity.charts,
          updateNotifyClassHandsonCapacity.analytics_date,
          updateNotifyClassHandsonCapacity.analytics_course,
          updateNotifyClassHandsonCapacity.notify_payout_paid,
          updateNotifyClassHandsonCapacity.notify_livestream_capacity,
          updateNotifyClassHandsonCapacity.notify_classroom_lecture_capacity,
          action.notify,
          updateNotifyClassHandsonCapacity.notify_online_video_capacity,
          updateNotifyClassHandsonCapacity.livestream_capacity_limit,
          updateNotifyClassHandsonCapacity.classroom_lecture_capacity_limit,
          updateNotifyClassHandsonCapacity.classroom_handson_capacity_limit,
          updateNotifyClassHandsonCapacity.online_video_capacity_limit,
          updateNotifyClassHandsonCapacity.certificate_delivery_live,
          updateNotifyClassHandsonCapacity.certificate_delivery_video,
          updateNotifyClassHandsonCapacity.certificate_header,
          updateNotifyClassHandsonCapacity.certificate_footer
        ),
      };
    case UPDATE_NOTIFY_ONLINE_VIDEO_CAPACITY:
      const updateNotifyOnlineVideoCapacity = { ...state.setting };
      return {
        setting: new Setting(
          updateNotifyOnlineVideoCapacity.course_view,
          updateNotifyOnlineVideoCapacity.language,
          updateNotifyOnlineVideoCapacity.show_dashboard,
          updateNotifyOnlineVideoCapacity.charts,
          updateNotifyOnlineVideoCapacity.analytics_date,
          updateNotifyOnlineVideoCapacity.analytics_course,
          updateNotifyOnlineVideoCapacity.notify_payout_paid,
          updateNotifyOnlineVideoCapacity.notify_livestream_capacity,
          updateNotifyOnlineVideoCapacity.notify_classroom_lecture_capacity,
          updateNotifyOnlineVideoCapacity.notify_classroom_handson_capacity,
          action.notify,
          updateNotifyOnlineVideoCapacity.livestream_capacity_limit,
          updateNotifyOnlineVideoCapacity.classroom_lecture_capacity_limit,
          updateNotifyOnlineVideoCapacity.classroom_handson_capacity_limit,
          updateNotifyOnlineVideoCapacity.online_video_capacity_limit,
          updateNotifyOnlineVideoCapacity.certificate_delivery_live,
          updateNotifyOnlineVideoCapacity.certificate_delivery_video,
          updateNotifyOnlineVideoCapacity.certificate_header,
          updateNotifyOnlineVideoCapacity.certificate_footer
        ),
      };
    case UPDATE_LIVESTREAM_CAPACITY_LIMIT:
      const updateLivestreamCapacityLimit = { ...state.setting };
      return {
        setting: new Setting(
          updateLivestreamCapacityLimit.course_view,
          updateLivestreamCapacityLimit.language,
          updateLivestreamCapacityLimit.show_dashboard,
          updateLivestreamCapacityLimit.charts,
          updateLivestreamCapacityLimit.analytics_date,
          updateLivestreamCapacityLimit.analytics_course,
          updateLivestreamCapacityLimit.notify_payout_paid,
          updateLivestreamCapacityLimit.notify_livestream_capacity,
          updateLivestreamCapacityLimit.notify_classroom_lecture_capacity,
          updateLivestreamCapacityLimit.notify_classroom_handson_capacity,
          updateLivestreamCapacityLimit.notify_online_video_capacity,
          action.limit,
          updateLivestreamCapacityLimit.classroom_lecture_capacity_limit,
          updateLivestreamCapacityLimit.classroom_handson_capacity_limit,
          updateLivestreamCapacityLimit.online_video_capacity_limit,
          updateLivestreamCapacityLimit.certificate_delivery_live,
          updateLivestreamCapacityLimit.certificate_delivery_video,
          updateLivestreamCapacityLimit.certificate_header,
          updateLivestreamCapacityLimit.certificate_footer
        ),
      };
    case UPDATE_CLASS_LECTURE_CAPACITY_LIMIT:
      const updateLectureCapacityLimit = { ...state.setting };
      return {
        setting: new Setting(
          updateLectureCapacityLimit.course_view,
          updateLectureCapacityLimit.language,
          updateLectureCapacityLimit.show_dashboard,
          updateLectureCapacityLimit.charts,
          updateLectureCapacityLimit.analytics_date,
          updateLectureCapacityLimit.analytics_course,
          updateLectureCapacityLimit.notify_payout_paid,
          updateLectureCapacityLimit.notify_livestream_capacity,
          updateLectureCapacityLimit.notify_classroom_lecture_capacity,
          updateLectureCapacityLimit.notify_classroom_handson_capacity,
          updateLectureCapacityLimit.notify_online_video_capacity,
          updateLectureCapacityLimit.livestream_capacity_limit,
          action.limit,
          updateLectureCapacityLimit.classroom_handson_capacity_limit,
          updateLectureCapacityLimit.online_video_capacity_limit,
          updateLectureCapacityLimit.certificate_delivery_live,
          updateLectureCapacityLimit.certificate_delivery_video,
          updateLectureCapacityLimit.certificate_header,
          updateLectureCapacityLimit.certificate_footer
        ),
      };
    case UPDATE_ClASS_HANDSON_CAPACITY_LIMIT:
      const updateHandsonCapacityLimit = { ...state.setting };
      return {
        setting: new Setting(
          updateHandsonCapacityLimit.course_view,
          updateHandsonCapacityLimit.language,
          updateHandsonCapacityLimit.show_dashboard,
          updateHandsonCapacityLimit.charts,
          updateHandsonCapacityLimit.analytics_date,
          updateHandsonCapacityLimit.analytics_course,
          updateHandsonCapacityLimit.notify_payout_paid,
          updateHandsonCapacityLimit.notify_livestream_capacity,
          updateHandsonCapacityLimit.notify_classroom_lecture_capacity,
          updateHandsonCapacityLimit.notify_classroom_handson_capacity,
          updateHandsonCapacityLimit.notify_online_video_capacity,
          updateHandsonCapacityLimit.livestream_capacity_limit,
          updateHandsonCapacityLimit.classroom_lecture_capacity_limit,
          action.limit,
          updateHandsonCapacityLimit.online_video_capacity_limit,
          updateHandsonCapacityLimit.certificate_delivery_live,
          updateHandsonCapacityLimit.certificate_delivery_video,
          updateHandsonCapacityLimit.certificate_header,
          updateHandsonCapacityLimit.certificate_footer
        ),
      };
    case UPDATE_ONLINE_VIDEO_CAPACITY_LIMIT:
      const updateOnlineVideoCapacityLimit = { ...state.setting };
      return {
        setting: new Setting(
          updateOnlineVideoCapacityLimit.course_view,
          updateOnlineVideoCapacityLimit.language,
          updateOnlineVideoCapacityLimit.show_dashboard,
          updateOnlineVideoCapacityLimit.charts,
          updateOnlineVideoCapacityLimit.analytics_date,
          updateOnlineVideoCapacityLimit.analytics_course,
          updateOnlineVideoCapacityLimit.notify_payout_paid,
          updateOnlineVideoCapacityLimit.notify_livestream_capacity,
          updateOnlineVideoCapacityLimit.notify_classroom_lecture_capacity,
          updateOnlineVideoCapacityLimit.notify_classroom_handson_capacity,
          updateOnlineVideoCapacityLimit.notify_online_video_capacity,
          updateOnlineVideoCapacityLimit.livestream_capacity_limit,
          updateOnlineVideoCapacityLimit.classroom_lecture_capacity_limit,
          updateOnlineVideoCapacityLimit.classroom_handson_capacity_limit,
          action.limit,
          updateOnlineVideoCapacityLimit.certificate_delivery_live,
          updateOnlineVideoCapacityLimit.certificate_delivery_video,
          updateOnlineVideoCapacityLimit.certificate_header,
          updateOnlineVideoCapacityLimit.certificate_footer
        ),
      };
    case UPD_CERT_LIVE_DELIVERY:
      const updateLiveDelivery = { ...state.setting };
      return {
        setting: new Setting(
          updateLiveDelivery.course_view,
          updateLiveDelivery.language,
          updateLiveDelivery.show_dashboard,
          updateLiveDelivery.charts,
          updateLiveDelivery.analytics_date,
          updateLiveDelivery.analytics_course,
          updateLiveDelivery.notify_payout_paid,
          updateLiveDelivery.notify_livestream_capacity,
          updateLiveDelivery.notify_classroom_lecture_capacity,
          updateLiveDelivery.notify_classroom_handson_capacity,
          updateLiveDelivery.notify_online_video_capacity,
          updateLiveDelivery.livestream_capacity_limit,
          updateLiveDelivery.classroom_lecture_capacity_limit,
          updateLiveDelivery.classroom_handson_capacity_limit,
          updateLiveDelivery.online_video_capacity_limit,
          action.delivery,
          updateLiveDelivery.certificate_delivery_video,
          updateLiveDelivery.certificate_header,
          updateLiveDelivery.certificate_footer
        ),
      };
    case UPD_CERT_VIDEO_DELIVERY:
      const updateVideoDelivery = { ...state.setting };
      return {
        setting: new Setting(
          updateVideoDelivery.course_view,
          updateVideoDelivery.language,
          updateVideoDelivery.show_dashboard,
          updateVideoDelivery.charts,
          updateVideoDelivery.analytics_date,
          updateVideoDelivery.analytics_course,
          updateVideoDelivery.notify_payout_paid,
          updateVideoDelivery.notify_livestream_capacity,
          updateVideoDelivery.notify_classroom_lecture_capacity,
          updateVideoDelivery.notify_classroom_handson_capacity,
          updateVideoDelivery.notify_online_video_capacity,
          updateVideoDelivery.livestream_capacity_limit,
          updateVideoDelivery.classroom_lecture_capacity_limit,
          updateVideoDelivery.classroom_handson_capacity_limit,
          updateVideoDelivery.online_video_capacity_limit,
          updateVideoDelivery.certificate_delivery_live,
          action.delivery,
          updateVideoDelivery.certificate_header,
          updateVideoDelivery.certificate_footer
        ),
      };
    case UPD_CERT_HEADER:
      const updateCertHeader = { ...state.setting };
      return {
        setting: new Setting(
          updateCertHeader.course_view,
          updateCertHeader.language,
          updateCertHeader.show_dashboard,
          updateCertHeader.charts,
          updateCertHeader.analytics_date,
          updateCertHeader.analytics_course,
          updateCertHeader.notify_payout_paid,
          updateCertHeader.notify_livestream_capacity,
          updateCertHeader.notify_classroom_lecture_capacity,
          updateCertHeader.notify_classroom_handson_capacity,
          updateCertHeader.notify_online_video_capacity,
          updateCertHeader.livestream_capacity_limit,
          updateCertHeader.classroom_lecture_capacity_limit,
          updateCertHeader.classroom_handson_capacity_limit,
          updateCertHeader.online_video_capacity_limit,
          updateCertHeader.certificate_delivery_live,
          updateCertHeader.certificate_delivery_video,
          action.certificate_header,
          updateCertHeader.certificate_footer
        ),
      };
    case UPD_CERT_FOOTER:
      const updateCertFooter = { ...state.setting };
      return {
        setting: new Setting(
          updateCertFooter.course_view,
          updateCertFooter.language,
          updateCertFooter.show_dashboard,
          updateCertFooter.charts,
          updateCertFooter.analytics_date,
          updateCertFooter.analytics_course,
          updateCertFooter.notify_payout_paid,
          updateCertFooter.notify_livestream_capacity,
          updateCertFooter.notify_classroom_lecture_capacity,
          updateCertFooter.notify_classroom_handson_capacity,
          updateCertFooter.notify_online_video_capacity,
          updateCertFooter.livestream_capacity_limit,
          updateCertFooter.classroom_lecture_capacity_limit,
          updateCertFooter.classroom_handson_capacity_limit,
          updateCertFooter.online_video_capacity_limit,
          updateCertFooter.certificate_delivery_live,
          updateCertFooter.certificate_delivery_video,
          updateCertFooter.certificate_header,
          action.certificate_footer
        ),
      };
    case SET_MOBILE_SETTING:
      return updateObject(state, {
        mobileSetting: action.mobileSetting,
      });
    case UPDATE_MOBILE_CURRENCY:
      const updateCurrency = { ...state.mobileSetting };

      return updateObject(state, {
        mobileSetting: new MobileSetting(
          updateCurrency.fav_category,
          updateCurrency.fav_course_lang,
          action.language,
          action.currency,
          updateCurrency.unit,
          updateCurrency.pausePushNotifications,
          updateCurrency.pauseEmailNotifications
        ),
      });
    case LOGOUT:
      return initialState;
    default:
      return state;
  }
};

export default settingReducer;
