import React, { Component } from "react";
import { connect } from "react-redux";
import styles from "./Cart.module.css";
import FlatList from "flatlist-react";
import Button from "@material-ui/core/Button";
import PlayCircleOutlineIcon from "@material-ui/icons/PlayCircleOutline";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import LiveTvIcon from "@material-ui/icons/LiveTv";

import * as cartActions from "../../store/actions/cart";
import * as profileActions from "../../store/actions/profile";
import SimpleContainer from "../../components/ui/container/Container";
import SimpleCard from "../../components/ui/card/SimpleCard";
import CartItem from "../../components/items/cartItem/CartItem";
import MatAppBar from "../../components/ui/appBar/MatAppBar";
import Empty from "../../components/ui/empty/Empty";
import cartImg from "../../assets/images/inApp/cart.png";
import { momentLongDate } from "../../globals/Dates2";
import { findTitle, findCurrency } from "../../globals/Common";
import * as logger from "../../globals/Logger";

class CartScreen extends Component {
  // ------------------------------

  componentDidMount = async () => {
    try {
      const loadAsync = [];

      if (!this.props.profile || Object.keys(this.props.profile).length === 0) {
        loadAsync.push(this.props.fetchProfile());
      }

      await Promise.all(loadAsync);
    } catch (err) {
      logger.error(err);
      this.update({ wait: false, error: err.message });
    }
  };

  // ------------------------------

  checkoutHandler = () => {
    this.props.history.push("/checkout");
  };

  // ------------------------------

  deleteHandler = (index) => {
    this.props.deleteItem(this.props.cart.items[index].course_id);
  };

  // ------------------------------

  adHandler = () => {
    this.props.history.push("/advertise");
  };

  // ------------------------------

  renderCartItem = (item, index) => {
    return (
      <CartItem
        key={index}
        currency={this.props.currency}
        addCategoryName={item.resolveCategory()}
        courseTitle={findTitle(this.props.activeCourses, item.course_id)}
        startDate={momentLongDate(item.start_date)}
        numOfWeeks={item.num_of_weeks}
        cost={item.cost}
        prime={item.prime}
        primeCost={item.prime_cost}
        onClick={this.deleteHandler.bind(this, index)}
        deletable
      />
    );
  };

  // ------------------------------

  render() {
    if (!this.props.cart.items || this.props.cart.items.length === 0) {
      return (
        <Empty
          column
          image={cartImg}
          message={
            <p style={{ textAlign: "center", paddingTop: 20, margin: 0 }}>
              Your shopping cart is empty
            </p>
          }
          onClick={this.adHandler}
          buttonIcon={<LiveTvIcon />}
          buttonLabel={"Advertise now"}
        />
      );
    }

    return (
      <div style={{ background: "white" }}>
        <MatAppBar
          static
          left={
            <Button
              color="inherit"
              style={{ color: "black" }}
              onClick={this.props.history.goBack}
              startIcon={<ArrowBackIosIcon />}
            >
              Back
            </Button>
          }
        />

        <SimpleContainer disableGutters={false}>
          <div style={{ height: "80px" }}>&nbsp;</div>

          <div className={styles.sectionTitle}>
            <div className={styles.centerRow}>
              <ShoppingCartIcon style={{ color: "white" }} />
              &nbsp;&nbsp;Shopping Cart
            </div>
          </div>

          <SimpleCard>
            <FlatList
              list={this.props.cart.items}
              renderItem={this.renderCartItem}
            />
          </SimpleCard>

          <SimpleCard>
            <div className={styles.summaryRow}>
              <div>
                <Button
                  variant="contained"
                  color="primary"
                  className={styles.button}
                  startIcon={<PlayCircleOutlineIcon />}
                  disabled={
                    !this.props.cart.items || this.props.cart.items.length === 0
                  }
                  onClick={this.checkoutHandler}
                >
                  Checkout Now
                </Button>
              </div>
              <div className={styles.priceTotal}>
                Total: {this.props.currency.shortName}
                {this.props.cart
                  ? this.props.cart.totalAmount.toFixed(2)
                  : "0.00"}
              </div>
            </div>
          </SimpleCard>
        </SimpleContainer>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const currency = findCurrency(state.cart.currency);

  return {
    cart: state.cart,
    activeCourses: state.courses.courses,
    currency: currency,
    profile: state.profile.profile,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    deleteItem: (courseId) => dispatch(cartActions.removeFromCart(courseId)),
    fetchProfile: () => dispatch(profileActions.fetchProfile()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CartScreen);
