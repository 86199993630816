import React, { Fragment, useState } from "react";
import styles from "./OrderItem.module.css";
import CartItem from "../cartItem/CartItem";
import FlatList from "flatlist-react";
import TextButton from "../../ui/button/TextButton";
import { momentLongDateTime, momentShortDate } from "../../../globals/Dates2";
import { findCurrency } from "../../../globals/Common";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import Loader from "react-loader-spinner";
import Collapse from "@material-ui/core/Collapse";

const OrderItem = (props) => {
  const [emailWait, setEmailWait] = useState(false);
  const [showDetails, setShowDetails] = useState(props.showDetails);
  const currency = findCurrency(
    props.proposedTaxes ? props.proposedTaxes.currency : props.order.currency
  );

  const renderCartItem = (item, index) => {
    return (
      <CartItem
        key={index}
        currency={currency}
        addCategoryName={item.resolveCategory()}
        courseTitle={item.course_title}
        startDate={momentShortDate(item.start_date)}
        numOfWeeks={item.num_of_weeks}
        cost={item.cost}
        prime={item.prime}
        primeCost={item.prime_cost}
      />
    );
  };

  const totalTaxAmount = (order, proposedTaxes) => {
    if (order.gst > 0 && order.qst > 0) {
      return order.gst + order.qst;
    }

    if (proposedTaxes) {
      return proposedTaxes.taxTotal();
    }

    return 0;
  };

  let details = "Show Details";
  if (showDetails === true) {
    details = "Hide Details";
  }

  return (
    <div className={styles.container}>
      {props.order.orderRefNumber && (
        <div className={styles.orderNumberContainer}>
          <div>Order #: {props.order.orderRefNumber}</div>
          <div className={styles.orderHeaderContainer}>
            <div>Date: {momentLongDateTime(props.order.date)}</div>
            <div>
              {props.order && !showDetails
                ? currency.shortName +
                  (
                    props.order.totalAmount +
                    totalTaxAmount(props.order, props.proposedTaxes)
                  ).toFixed(2)
                : ""}
            </div>
          </div>
        </div>
      )}

      <Collapse in={showDetails}>
        <Fragment>
          <FlatList list={props.order.items} renderItem={renderCartItem} />

          {props.payAndCredit && props.payAndCredit.creditsUsed > 0 && (
            <div className={styles.priceCredits}>
              <div style={{ color: "black", paddingRight: "4px" }}>
                ZubU Credits{" "}
              </div>
              {"-"}
              {currency.shortName}
              {props.payAndCredit.creditsUsed.toFixed(2)}
            </div>
          )}

          {props.order && props.order.creditsUsed > 0 && (
            <div className={styles.priceCredits}>
              <div style={{ color: "black", paddingRight: "4px" }}>
                ZubU Credits{" "}
              </div>
              {"-"}
              {currency.shortName}
              {props.order ? props.order.creditsUsed.toFixed(2) : "0.00"}
            </div>
          )}

          {props.order.gst > 0 && (
            <div className={styles.priceTaxes}>
              <div style={{ color: "black", paddingRight: "4px" }}>
                GST/HST{" "}
              </div>
              {currency.shortName}
              {props.order ? props.order.gst.toFixed(2) : "0.00"}
            </div>
          )}

          {props.order.qst > 0 && (
            <div className={styles.priceTaxes}>
              <div style={{ color: "black", paddingRight: "4px" }}>
                QST/PST{" "}
              </div>
              {currency.shortName}
              {props.order ? props.order.qst.toFixed(2) : "0.00"}
            </div>
          )}

          {props.proposedTaxes && props.proposedTaxes.gst_hst > 0 && (
            <div className={styles.priceTaxes}>
              <div style={{ color: "black", paddingRight: "4px" }}>
                GST/HST ({props.proposedTaxes.gst_hst * 100}%){"  "}
              </div>
              {currency.shortName}
              {props.proposedTaxes.gst_hst_amount().toFixed(2)}
            </div>
          )}

          {props.proposedTaxes && props.proposedTaxes.qst > 0 && (
            <div className={styles.priceTaxes}>
              <div style={{ color: "black", paddingRight: "4px" }}>
                QST (9.975%){" "}
              </div>
              {currency.shortName}
              {props.proposedTaxes.qst_amount().toFixed(2)}
            </div>
          )}

          {props.payAndCredit && props.payAndCredit.creditsUsed > 0 ? (
            <div className={styles.priceTotal}>
              <div style={{ color: "black", paddingRight: "4px" }}>Total: </div>
              {currency.shortName}
              {props.order
                ? (
                    props.payAndCredit.payAmount +
                    totalTaxAmount(props.order, props.proposedTaxes)
                  ).toFixed(2)
                : "0.00"}
            </div>
          ) : (
            <div className={styles.priceTotal}>
              <div style={{ color: "black", paddingRight: "4px" }}>Total: </div>
              {currency.shortName}
              {props.order
                ? (
                    props.order.totalAmount +
                    totalTaxAmount(props.order, props.proposedTaxes)
                  ).toFixed(2)
                : "0.00"}
            </div>
          )}

          {props.payAndCredit && props.payAndCredit.creditsRemaining > 0 && (
            <div className={styles.creditsRemaining}>
              You have&nbsp;
              {currency.shortName}
              {props.payAndCredit.creditsRemaining.toFixed(2)} ZubU credits
              remaining.
            </div>
          )}

          {props.order.orderRefNumber && (
            <div>
              {emailWait === true ? (
                <div
                  style={{
                    width: "120px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Loader type="Oval" color="#ccc" height={30} width={30} />
                </div>
              ) : (
                <div
                  className={styles.link}
                  onClick={async () => {
                    try {
                      await setEmailWait(true);
                      await props.onEmailReceipt();
                      await setEmailWait(false);
                    } catch (error) {
                      await setEmailWait(false);
                      throw error;
                    }
                  }}
                >
                  <MailOutlineIcon />
                  &nbsp;Email Receipt
                </div>
              )}
            </div>
          )}
        </Fragment>
      </Collapse>

      {props.order.orderRefNumber && (
        <div className={styles.showDetailsContainer}>
          <TextButton
            onClick={setShowDetails.bind(this, !showDetails)}
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            {details}{" "}
            {showDetails ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
          </TextButton>
        </div>
      )}
    </div>
  );
};

export default OrderItem;
