import React from "react";
import styles from "./Algolia.module.css";
import SearchIcon from "@material-ui/icons/Search";

const AlgoliaProductItem = ({ hit, components }) => {
  return (
    <a href={hit.url} className="aa-ItemLink">
      <div className="aa-ItemContent">
        <div
          className="aa-ItemTitle"
          style={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}
        >
          <div className={styles.itemContainer}>
            <div className={styles.searchIcon}>
              <SearchIcon fontSize="small" style={{ color: "gray" }} />
            </div>

            <div>
              <components.Highlight hit={hit} attribute="title" />
              {hit && hit.category && hit.provider_name && (
                <div className={styles.categoryContainer}>
                  <div className={styles.category}>
                    {hit.category.join(", ")} |
                  </div>
                  <div className={styles.provider}>
                    <components.Highlight hit={hit} attribute="provider_name" />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </a>
  );
};

export default AlgoliaProductItem;
