import { handleErrorResponse } from "../../globals/Error";
import Balance from "../../model/Balance";
import BalanceItem from "../../model/BalanceItem";
import BankAccount from "../../model/BankAccount";
import Account from "../../model/Account";
import { dbName } from "../../globals/ApiKeys";
import { toDollar, groupBy, totalBy } from "../../globals/Common";
import moment from "moment";
import { momentLongDateTime } from "../../globals/Dates2";
export const SET_DASHBOARD_LINK = "SET_DASHBOARD_LINK";
export const SET_CONNECTED = "SET_CONNECTED";
export const CONNECT_ADMIN_SUCCESS = "CONNECT_ADMIN_SUCCESS";
export const SET_ACCOUNT = "SET_ACCOUNT";
export const SET_STRIPE_BANK = "SET_STRIPE_BANK";
export const SET_BALANCE_SHEET = "SET_BALANCE_SHEET";
export const SET_STRIPE_BALANCE = "SET_STRIPE_BALANCE";
export const DISMISS_ACCOUNT_WARNINGS = "DISMISS_ACCOUNT_WARNINGS";

// ------------------------------

export const dismissAccountWarnings = () => {
  return {
    type: DISMISS_ACCOUNT_WARNINGS,
  };
};

// ------------------------------

export const fetchStripeBank = () => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;

    const response = await fetch(
      `https://us-central1-${dbName}.cloudfunctions.net/stripeBank`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          data: {},
        }),
      }
    );

    if (!response.ok) {
      handleErrorResponse(await response.json());
    }

    const resData = await response.json();

    const bankAccount = [];
    if (resData.result.bankAccount) {
      for (const account of resData.result.bankAccount) {
        bankAccount.push(
          new BankAccount(
            account.id,
            account.bank_name,
            account.last4,
            account.currency,
            account.country
          )
        );
      }
    }

    dispatch({
      type: SET_STRIPE_BANK,
      bankAccount: bankAccount,
    });
  };
};

// ------------------------------

export const fetchBalanceSheet = (pageAction, objectId) => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;

    let dataParam = {};

    if (pageAction) {
      dataParam[pageAction] = objectId.id;
    }

    const response = await fetch(
      `https://us-central1-${dbName}.cloudfunctions.net/stripeBalanceSheet`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          data: dataParam,
        }),
      }
    );

    if (!response.ok) {
      handleErrorResponse(await response.json());
    }

    const resData = await response.json();

    let has_more = false;
    if (resData.result.has_more) {
      has_more = resData.result.has_more;
    }

    const balanceList = [];
    if (resData.result.balanceList) {
      for (const item of resData.result.balanceList) {
        let type = item.type;
        if (item.type === "payment_refund") {
          type = "refund";
        }

        balanceList.push(
          new BalanceItem(
            item.id,
            extractOrderRefNum(item),
            type,
            item.source,
            toDollar(item.amount),
            toDollar(item.net),
            item.currency,
            toDollar(item.fee),
            item.created,
            momentLongDateTime(moment.unix(item.created)),
            item.status,
            extractChargeId(item)
          )
        );
      }
    }

    dispatch({
      type: SET_BALANCE_SHEET,
      balanceList: balanceList,
      concatBalanceList: pageAction ? true : false,
      cursorFirst: resData.result.balanceList[0],
      cursorLast:
        resData.result.balanceList[resData.result.balanceList.length - 1],
      hasMore: has_more,
    });
  };
};

// ------------------------------

export const fetchStripeBalance = () => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;

    const response = await fetch(
      `https://us-central1-${dbName}.cloudfunctions.net/stripeBalance`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          data: {},
        }),
      }
    );

    if (!response.ok) {
      handleErrorResponse(await response.json());
    }

    const resData = await response.json();

    let total = null;
    let available = null;
    let pending = null;
    let inTransit = null;

    if (resData.result) {
      const balance = resData.result;
      pending = parseBalance(balance.pending);
      available = parseBalance(balance.available);
      total = balance.instant_available
        ? parseBalance(balance.instant_available)
        : new Balance(pending.amount + available.amount, pending.currency);
    }

    dispatch({
      type: SET_STRIPE_BALANCE,
      total: total,
      available: available,
      pending: pending,
      inTransit: inTransit,
    });
  };
};

// ------------------------------

export const fetchAccount = () => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;

    const response = await fetch(
      `https://us-central1-${dbName}.cloudfunctions.net/stripeAccount`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          data: {},
        }),
      }
    );

    if (!response.ok) {
      handleErrorResponse(await response.json());
    }

    const resData = await response.json();

    let account = {};

    if (resData) {
      const accRes = resData.result;
      account = new Account(
        accRes.id,
        accRes.charges_enabled,
        accRes.payouts_enabled,
        accRes.email,
        accRes.country,
        accRes.default_currency,
        accRes.requirements
      );
    }

    dispatch({
      type: SET_ACCOUNT,
      account: account,
    });
  };
};

// ------------------------------

export const fetchDashboardLink = () => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;

    const response = await fetch(
      `https://us-central1-${dbName}.cloudfunctions.net/getDashboardLink`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          data: {},
        }),
      }
    );

    if (!response.ok) {
      handleErrorResponse(await response.json());
    }

    const resData = await response.json();

    dispatch({
      type: SET_DASHBOARD_LINK,
      dashboardLink: resData.result,
    });
  };
};

// ------------------------------

export const createConnectToken = (generatedToken) => {
  return async (dispatch, getState) => {
    const userId = getState().auth.userId;
    const token = getState().auth.token;

    const response = await fetch(
      `https://firestore.googleapis.com/v1/projects/${dbName}/databases/(default)/documents/provider/${userId}?updateMask.fieldPaths=connect_token_id`,
      {
        method: "PATCH",
        headers: {
          "Content-Type": "applications/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          fields: {
            connect_token_id: { stringValue: generatedToken },
          },
        }),
      }
    );

    if (!response.ok) {
      handleErrorResponse(await response.json());
    }

    await response.json();
  };
};

// ------------------------------

export const warning = (value) => {
  return {
    type: SET_CONNECTED,
    value: value,
  };
};

// ------------------------------

export const clearConnectSuccess = () => {
  return {
    type: CONNECT_ADMIN_SUCCESS,
    value: false,
  };
};

// ------------------------------

export const connectAdmin = (code, state) => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;

    dispatch(warning(false));

    const response = await fetch(
      `https://us-central1-${dbName}.cloudfunctions.net/connectAdmin`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          data: { code: code, state: state },
        }),
      }
    );

    if (!response.ok) {
      handleErrorResponse(await response.json());
    }

    await response.json();
  };
};

// ------------------------------

const extractChargeId = (data) => {
  const parentChargeId = extractParentChargeId(data);

  if (parentChargeId) {
    return parentChargeId;
  } else if (
    data &&
    data.source &&
    data.source.source_transfer &&
    data.source.source_transfer.source_transaction &&
    data.source.source_transfer.source_transaction.metadata &&
    data.source.source_transfer.source_transaction.id
  ) {
    return data.source.source_transfer.source_transaction.id;
  } else if (
    data &&
    data.source &&
    data.source.source_transfer_reversal &&
    data.source.source_transfer_reversal.source_refund &&
    data.source.source_transfer_reversal.source_refund.charge
  ) {
    return data.source.source_transfer_reversal.source_refund.charge;
  }

  return null;
};

// ------------------------------

const extractOrderRefNum = (data) => {
  if (
    data &&
    data.source &&
    data.source.source_transfer &&
    data.source.source_transfer.source_transaction &&
    data.source.source_transfer.source_transaction.metadata &&
    data.source.source_transfer.source_transaction.metadata.orderRefNumber
  ) {
    return data.source.source_transfer.source_transaction.metadata
      .orderRefNumber;
  } else if (
    data &&
    data.source &&
    data.source.source_transfer_reversal &&
    data.source.source_transfer_reversal.source_refund &&
    data.source.source_transfer_reversal.source_refund.metadata &&
    data.source.source_transfer_reversal.source_refund.metadata.orderRefNumber
  ) {
    return data.source.source_transfer_reversal.source_refund.metadata
      .orderRefNumber;
  }

  return "";
};

// ------------------------------

const extractParentChargeId = (data) => {
  if (
    data &&
    data.source &&
    data.source.source_transfer &&
    data.source.source_transfer.source_transaction &&
    data.source.source_transfer.source_transaction.metadata &&
    data.source.source_transfer.source_transaction.metadata.parentCharge
  ) {
    return data.source.source_transfer.source_transaction.metadata.parentCharge;
  } else if (
    data &&
    data.source &&
    data.source.source_transfer_reversal &&
    data.source.source_transfer_reversal.source_refund &&
    data.source.source_transfer_reversal.source_refund.metadata &&
    data.source.source_transfer_reversal.source_refund.metadata.parentCharge
  ) {
    return data.source.source_transfer_reversal.source_refund.metadata
      .parentCharge;
  }

  return "";
};

// ---------------------------
// TODO fix try catch, looping for multiple refunds---
export const issueRefund = (memberRefund, chargeId, reason) => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;

    const memberRefundGroup = groupBy(memberRefund, "chargeId");

    for (const key of Object.keys(memberRefundGroup)) {
      const total = totalBy(memberRefundGroup[key], "memberRefundValue");

      const response = await fetch(
        `https://us-central1-${dbName}.cloudfunctions.net/issueRefund`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            data: {
              amount: Number(total),
              memberRefundValue: memberRefundGroup[key],
              chargeId: chargeId,
              reason: reason,
            },
          }),
        }
      );

      if (!response.ok) {
        handleErrorResponse(await response.json());
      }

      await response.json();
    }
  };
};

// ------------------------------

export const fundAccountBalance = (amount, paymentMethodId) => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;

    const response = await fetch(
      `https://us-central1-${dbName}.cloudfunctions.net/fundAccountBalance`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          data: {
            amount: Number(amount),
            paymentMethodId: paymentMethodId,
          },
        }),
      }
    );

    if (!response.ok) {
      handleErrorResponse(await response.json());
    }

    await response.json();
  };
};

// ------------------------------

const parseBalance = (value) => {
  if (value) {
    return new Balance(toDollar(value[0].amount), value[0].currency);
  }
  return new Balance(0, "");
};
