import React, { Fragment, useState } from "react";
import styles from "./MobileOrderItem.module.css";
import { useTranslation } from "react-i18next";
import FlatList from "flatlist-react";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import Loader from "react-loader-spinner";
import NumberFormat from "react-number-format";
import Collapse from "@material-ui/core/Collapse";
import MobileCartProviderItem from "../mobileCartProviderItem/MobileCartProviderItem";
import TextButton from "../../ui/button/TextButton";
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import InsertInvitationIcon from '@material-ui/icons/InsertInvitation';
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined';
import {
  resolveCurrency,
  resolveCurrencySymbol,
  translate
} from "../../../globals/Common";
import {
  momentTinyDate
} from "../../../globals/Dates2"

// ------------------------------

const renderIconFromStatus = (status) => {
  if (status === "pending") {
    return <InsertInvitationIcon style={{ color: "black" }} />;
  } else if (status === "success") {
    return <CheckCircleOutlineIcon style={{ color: "green" }} />;
  } else if (status === "failed") {
    return <ReportProblemOutlinedIcon style={{ color: "red" }} />;
  }

  return null;
};


const MobileOrderItem = (props) => {
  const { t } = useTranslation();
  const [emailWait, setEmailWait] = useState(false);
  const [currencyAsProvider, setCurrencyAsProvider] = useState(false);
  const [showDetails, setShowDetails] = useState(false);

  const propsAmount = Number(props.amount);
  const propsChargeAmount = Number(props.chargeAmount);
  let balance = propsAmount - propsChargeAmount;

  // ------------------------------

  const renderCartItem = (item, index) => {
    return (
      <MobileCartProviderItem
        key={index}
        couponValue={props.couponValue}
        item={item}
        setting={props.setting}
        rates={props.rates}
        currencyAsProvider={currencyAsProvider}
      />
    );
  };

  // ------------------------------

  const renderInstallmentStatus = (installmentStatusArray) => {
    const renderedInstallmentStatus = [];

    let key = 0;

    for (const installmentStatus of installmentStatusArray) {
      const installmentAmount = Number(installmentStatus.amount) / 100;

      let colorStyle = { color: "black" };
      if (installmentStatus.status === "success") {
        balance = Number(balance) - installmentAmount;
        colorStyle.color = "green";
      } else if (installmentStatus.status === "failed") {
        colorStyle.color = "red";
      }

      renderedInstallmentStatus.push(
        <div className={styles.installmentRow} key={key + ""}>
          <div className={styles.installmentInnerRow}>
            {renderIconFromStatus(installmentStatus.status)}&nbsp;&nbsp;
            <div style={colorStyle}>
              {momentTinyDate(installmentStatus.date)}{" "}- {translate(t, installmentStatus.status)}
            </div>
          </div>
          <div style={colorStyle}>
            <NumberFormat
              value={resolveCurrency(
                Number(installmentAmount),
                props.providerCurrency,
                props.rates
              )}
              displayType={"text"}
              thousandSeparator={true}
              prefix={resolveCurrencySymbol(props.setting.currency)}
            /></div>
        </div>
      );
      key++;
    }

    return renderedInstallmentStatus;
  };

  // ------------------------------

  let details = t("g_show_details");
  if (showDetails === true) {
    details = t("g_hide_details");
  }

  return (
    <div className={styles.container}>
      {props.order.orderRefNumber && (
        <div className={styles.orderNumberContainer}>
          <div>{t("order_number")}&nbsp;{props.order.orderRefNumber}</div>
          <div className={styles.orderHeaderContainer}>
            <div>Date: {props.date}</div>
            <div className={styles.price}>
              {props.order && !showDetails ? (
                <NumberFormat
                  value={resolveCurrency(
                    propsAmount,
                    props.providerCurrency, // providerCurrency
                    props.rates
                  )}
                  displayType={"text"}
                  thousandSeparator={true}
                  prefix={resolveCurrencySymbol(props.setting.currency)}
                />
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      )}

      <Collapse in={showDetails}>
        <Fragment>
          <FlatList list={props.order.items} renderItem={renderCartItem} />
          <div className={styles.priceTotal}>
            Total:&nbsp;
            {props.order ? (
              <NumberFormat
                value={resolveCurrency(
                  propsAmount,
                  props.providerCurrency,
                  props.rates
                )}
                displayType={"text"}
                thousandSeparator={true}
                prefix={resolveCurrencySymbol(props.setting.currency)}
              />
            ) : (
              ""
            )}
          </div>

          {props.installmentStatusArray.length > 0 && (
            <div className={styles.installmentContainer}>
              <div className={styles.installmentRow}>
                <div className={styles.installmentInnerRow}>
                  {renderIconFromStatus("success")}&nbsp;&nbsp;
                  <div style={{ color: "green", display: "flex", flexDirection: "row", flex: 1 }}>
                    {momentTinyDate(props.dateNoFormat)}{" "}- {t("pick_success")}
                  </div>
                </div>
                <div style={{ color: "green" }}>
                  <NumberFormat
                    value={resolveCurrency(
                      Number(propsChargeAmount.toFixed(2)),
                      props.providerCurrency,
                      props.rates
                    )}
                    displayType={"text"}
                    thousandSeparator={true}
                    prefix={resolveCurrencySymbol(props.setting.currency)}
                  />
                </div>
              </div>

              {renderInstallmentStatus(props.installmentStatusArray)}

              <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "flex-end", paddingTop: "10px" }}>
                <b>{t("order_balance_remaining")}</b>&nbsp;
                <NumberFormat
                  value={resolveCurrency(
                    Number(balance.toFixed(2)),
                    props.providerCurrency,
                    props.rates
                  )}
                  displayType={"text"}
                  thousandSeparator={true}
                  prefix={resolveCurrencySymbol(props.setting.currency)}
                />
              </div>
            </div>
          )}

          {props.order.orderRefNumber && (
            <div>
              {emailWait === true ? (
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Loader type="Oval" color="#ccc" height={30} width={30} />
                </div>
              ) : (
                <div
                  className={styles.link}
                  onClick={async () => {
                    try {
                      await setEmailWait(true);
                      await props.onEmailReceipt();
                      await setEmailWait(false);
                    } catch (error) {
                      await setEmailWait(false);
                      throw error;
                    }
                  }}
                >
                  <MailOutlineIcon />
                  &nbsp;{t("order_email_receipt")}
                </div>
              )}
            </div>
          )}
        </Fragment>
      </Collapse>

      {props.order.orderRefNumber && (
        <div className={styles.showDetailsContainer}>
          <TextButton
            onClick={setShowDetails.bind(this, !showDetails)}
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            {details}{" "}
            {showDetails ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
          </TextButton>
        </div>
      )}
    </div>
  );
};

export default MobileOrderItem;
