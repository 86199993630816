class Category {
  constructor(id, title, icon, color, longTitle) {
    this.id = id;
    this.title = title;
    this.icon = icon;
    this.color = color;
    this.longTitle = longTitle ? longTitle : title;
  }
}

export default Category;
