import React from "react";
import styles from "./MembershipItem.module.css";
import { useTranslation } from "react-i18next";
import PopMenu from "../../../components/ui/popMenu/PopMenu";
import MenuItem from "@material-ui/core/MenuItem";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import TitleMenu from "../../../components/ui/titleMenu/TitleMenu";
import ProviderItem from "../../../components/items/providerItem/ProviderItem";

const MembershipItem = (props) => {
    const { t } = useTranslation();

    const [anchorEl, setAnchorEl] = React.useState(null);

    // ------------------------------

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    // ------------------------------

    const handleClose = () => {
        setAnchorEl(null);
    };

    // ------------------------------

    const renderDelete = () => (
        <MenuItem
            onClick={() => {
                handleClose();
                props.delete();
            }}
        >
            <TitleMenu icon={<DeleteOutlineIcon />} title={t("g_delete")} />
        </MenuItem>
    );


    // ------------------------------

    return (
        <div className={styles.itemContainer}>
            <div className={styles.column}>
                <div className={styles.maskedColumn}>
                    <ProviderItem
                        readOnly
                        name={props.providerName}
                        logo={props.providerLogo}
                    />
                </div>
            </div>

            <div className={styles.column}>
                <div className={styles.title}>{t("membership_member_id")}</div>
                <div>{props.memberID}</div>
            </div>

            {props.delete && (
                <div className={styles.column}>
                    <PopMenu
                        anchorEl={anchorEl}
                        handleClick={handleClick}
                        handleClose={handleClose}
                    >
                        {renderDelete()}
                    </PopMenu>
                </div>
            )}
        </div>
    );
};

export default MembershipItem;
