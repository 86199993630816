import React, { useState } from "react";
import styles from "./InputModal.module.css";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import MatInputOutline from "../../../components/ui/input/MatInputOutline";
import { useTranslation } from "react-i18next";
import memberImage from "../../../assets/images/inApp/membership.png";
import help_member_en from "../../../assets/images/inApp/help_member_en.png";
import help_member_fr from "../../../assets/images/inApp/help_member_fr.png";
import i18n from "../../../i18n";



const InputMemberModal = (props) => {
    const { t } = useTranslation();
    const [inputText, setInputText] = useState("");

    // ------------------------------

    const handleClose = (inpuText) => {
        props.onClose(inpuText);
    };

    // ------------------------------

    const isOkDisabled = () => {
        return !inputText;
    };

    // ------------------------------

    const inputChangeHandler = (e) => {
        setInputText(e.target.value);
    };

    // ------------------------------

    return (
        <Dialog
            fullScreen={false}
            fullWidth={false}
            open={props.open}
            onClose={handleClose.bind(this, null)}
            aria-labelledby="responsive-dialog-title"
        >
            <DialogContent >
                <div className={styles.center}>
                    <img
                        className={styles.image}
                        src={memberImage}
                        alt=""
                    />
                </div>
                <div className={styles.title}>
                    {t("membership__modal_blurb1")}<span style={{ fontWeight: "bold" }}>{props.providerName}</span>
                    {t("membership__modal_blurb2")}
                </div>

                <MatInputOutline
                    nomargin="true"
                    title={t("membership_member_id")}
                    name="inputText"
                    rows="1"
                    multiline
                    value={inputText}
                    onChange={inputChangeHandler}
                    maxLength={15}
                    readOnly={false}
                    required
                />

                <div className={styles.helpContainer}>
                    <div>
                        {t("membership__modal_blurb3")} <span style={{ fontWeight: "bold" }}>{props.providerName}</span>.
                    </div>
                    <div style={{ flex: 1 }}>
                        {i18n.language.startsWith("fr") ?
                            <div className={styles.center}>
                                <img
                                    className={styles.imageHelp}
                                    src={help_member_fr}
                                    alt=""
                                />
                            </div> :
                            <div className={styles.center}>
                                <img
                                    className={styles.imageHelp}
                                    src={help_member_en}
                                    alt=""
                                />
                            </div>

                        }
                    </div>
                </div>
            </DialogContent>
            <DialogActions>
                <Button
                    autoFocus
                    onClick={handleClose.bind(this, null)}
                    color="primary"
                >
                    {t("g_cancel")}
                </Button>
                <Button
                    autoFocus
                    onClick={handleClose.bind(this, inputText)}
                    color="primary"
                    disabled={isOkDisabled()}
                    style={
                        isOkDisabled()
                            ? { color: "gray", backgroundColor: "#ccc" }
                            : { color: "white", backgroundColor: "#3E8CAB" }
                    }
                >
                    {t("g_save")}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default InputMemberModal;
