import React from "react";
import styles from "./PricingItem.module.css";
import AddIcon from "@material-ui/icons/Add";
import ButtonIcon from "../../ui/button/ButtonIcon";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import Increment from "../../ui/increment/Increment";
import NumberFormat from "react-number-format";
import CardMembershipIcon from '@material-ui/icons/CardMembership';

const PricingItem = (props) => {
  if (props.empty) {
    return (
      <div className={styles.pricingItemContainer} onClick={props.onClick}>
        <div className={styles.pricingItemContainerSample}>
          <div className={styles.registrationContentSample}>
            <AddIcon style={jss.button} />
          </div>
          <div className={styles.sampleTitle}>Add new Registration Option</div>
        </div>
      </div>
    );
  }

  return (
    <div className={styles.pricingItemContainer}>
      <div className={styles.registrationTitle}>
        <div>{props.title}</div>
        <div>
          {props.onDelete && (
            <ButtonIcon onClick={props.onDelete}>
              <HighlightOffIcon style={{ fontSize: "20px", color: "white" }} />
            </ButtonIcon>
          )}
        </div>
      </div>
      <div className={props.members_only ?
        styles.registrationContentMember :
        styles.registrationContent}
        onClick={props.onClick}
      >
        {props.members_only && (
          <div className={styles.registrationContentRow}>
            <div className={styles.registrationContentDescription}
              style={{ flexDirection: "row", alignItems: "center", display: "flex", color: "green", paddingBottom: "5px" }}>
              Member Option&nbsp;<CardMembershipIcon style={{ color: "green" }} />
            </div>
          </div>
        )}

        {props.description && (
          <div className={styles.registrationContentRow}>
            <div className={styles.registrationContentDescription}>
              <b>{props.description}</b>
            </div>
          </div>
        )}

        <div className={styles.registrationContentRow}>
          <div className={styles.registrationContentDescription}>
            {props.casting} for ({props.audience.join(", ")})
          </div>
        </div>

        <div className={styles.registrationContentRow}>
          <div className={styles.registrationContentDescription}>
            {props.component}
          </div>
        </div>

        <div className={styles.registrationContentRow}>
          {props.ce_odq === "N/A" ? (
            <div className={styles.registrationContentDescription}>
              Credits:{" "}
              {Number(props.credit_lecture_hours) +
                Number(props.credit_handson_hours)}
            </div>
          ) : (
            <div className={styles.registrationContentDescription}>
              ODQ: {props.ce_odq} / Credits:{" "}
              {Number(props.credit_lecture_hours) +
                Number(props.credit_handson_hours)}
            </div>
          )}
        </div>

        <div className={styles.registrationContentRow}>
          <div className={styles.registrationContentDescription}>
            <div style={{ fontWeight: "bold" }}>
              <NumberFormat
                value={props.cost}
                displayType={"text"}
                thousandSeparator={true}
                prefix={props.currency.shortName}
              />
            </div>
          </div>
        </div>

        {props.expiration_date ? (
          <div className={styles.registrationContentRow}>
            <div className={styles.registrationContentDescription}>
              Exp: {props.expiration_date}
            </div>
          </div>
        ) : (
          <div className={styles.registrationContentRow}>
            <div className={styles.registrationContentDescription}>&nbsp;</div>
          </div>
        )}

        {props.onDiscount && (
          <div className={styles.registrationContentRow}>
            <Increment
              currency={props.currency}
              price={Number(props.cost)}
              discount={props.discount}
              onDiscount={props.onDiscount}
            />
          </div>
        )}
      </div>
    </div>
  );
};

const jss = {
  buttonContainer: {
    margin: "10px",
    height: 40,
    width: 40,
  },
  button: {
    fontSize: "60px",
    color: "#CCC",
  },
};

export default PricingItem;
