import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import MatInputOutline from "../../../components/ui/input/MatInputOutline";
import DateSelect from "../../../components/ui/dateSelect/DateSelect";
import InfoIcon from "@material-ui/icons/Info";
import { selectTimezone } from "../../../globals/Timezone";
import {
  FNS_LONG_DATE_TIME_FORMAT,
  FNS_TIME_FORMAT,
  addDays,
  isEqualDate,
  MAX_COURSE_DATE_LIMIT,
} from "../../../globals/Dates";

// ------------------------------

const computeExcludeTime = (dateFrom, dateTo) => {
  if (isEqualDate(dateFrom, dateTo)) {
    const arrExcludeTime = [];

    const dateFirst = new Date(dateFrom);
    dateFirst.setHours(dateFrom.getHours());
    dateFirst.setMinutes(0);
    arrExcludeTime.push(dateFirst);

    const dateFirst30 = new Date(dateFrom);
    if (dateFirst30.getMinutes() === 30) {
      dateFirst30.setHours(dateFrom.getHours());
      dateFirst30.setMinutes(30);
      arrExcludeTime.push(dateFirst30);
    }

    for (let i = dateFrom.getHours() - 1; i >= 0; i--) {
      const date = new Date(dateFrom);
      date.setHours(i);
      date.setMinutes(0);
      arrExcludeTime.push(date);
      const date30 = new Date(dateFrom);
      date30.setHours(i);
      date30.setMinutes(30);
      arrExcludeTime.push(date30);
    }
    return arrExcludeTime;
  }

  return [];
};

// ------------------------------

const findTimeZoneObject = (abbr) => {
  const timezoneObjIndex = selectTimezone.findIndex((tz) => tz.abbr === abbr);
  if (timezoneObjIndex !== -1) {
    const select = selectTimezone[timezoneObjIndex];
    return { value: select.abbr, label: select.text };
  }

  return null;
};

// ------------------------------

const DateModal = (props) => {
  const fullScreen = false;
  const fullWidth = true;
  const maxWidth = "sm";

  // ------------------------------

  const [desc, setDesc] = useState(
    props.selectedDate ? props.selectedDate.desc : ""
  );

  const [dateFrom, setDateFrom] = useState(
    props.selectedDate ? new Date(props.selectedDate.from) : null
  );

  const [dateTo, setDateTo] = useState(
    props.selectedDate ? new Date(props.selectedDate.to) : null
  );

  const [excludeTime, setExcludeTime] = useState(
    props.selectedDate
      ? computeExcludeTime(
          new Date(props.selectedDate.from),
          new Date(props.selectedDate.to)
        )
      : []
  );

  const [timezone, setTimezone] = useState(
    props.selectedDate
      ? findTimeZoneObject(props.selectedDate.timezoneAbbr)
      : null
  );

  // ------------------------------

  const addDateHandler = (event) => {
    event.preventDefault();

    if (props.selectedDate && dateFrom && dateTo) {
      props.updateDate(dateFrom, dateTo, desc, timezone ? timezone.value : "");
    } else if (dateFrom && dateTo) {
      props.addDate(dateFrom, dateTo, desc, timezone ? timezone.value : "");
    }
  };

  // ------------------------------

  const handleClose = () => {
    props.onclose();
  };

  // ------------------------------

  const selectChangeHandler = (key, e, select) => {
    if (select && select.value && select.label) {
      setTimezone({ value: select.value, label: select.label });
    } else {
      setTimezone(null);
    }
  };

  // ------------------------------

  return (
    <div>
      <Dialog
        fullScreen={fullScreen}
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={props.isopen}
        onClose={handleClose.bind(this, null)}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="alert-dialog-title">
          {"Select your course start date / end time"}
        </DialogTitle>

        <DialogContent>
          <div style={{ display: "flex", flexDirection: "row" }}>
            <div style={{ paddingRight: "10px" }}>
              <InfoIcon />
            </div>
            <div style={{ textAlign: "justify", paddingBottom: "15px" }}>
              Enter the course start time in your local timezone. ZubU mobile
              app converts it to the users local timezone. You can also specify
              your timezone in notes.
            </div>
          </div>

          <DateSelect
            {...props}
            title="Date / Time"
            showTimeSelect
            timeFormat={FNS_TIME_FORMAT}
            timeIntervals={30}
            timeCaption="time"
            dateFormat={FNS_LONG_DATE_TIME_FORMAT}
            selected={dateFrom}
            minDate={addDays(new Date(), 1)}
            maxDate={addDays(new Date(), MAX_COURSE_DATE_LIMIT)}
            onChange={(date) => {
              setDateFrom(date);
              setDateTo(null);
              setExcludeTime(computeExcludeTime(date, date));
            }}
          />

          <DateSelect
            {...props}
            title="End Time"
            showTimeSelect
            showTimeSelectOnly
            timeFormat={FNS_TIME_FORMAT}
            timeIntervals={30}
            timeCaption="Time"
            dateFormat={FNS_TIME_FORMAT}
            selected={dateTo}
            minDate={dateFrom}
            maxDate={addDays(new Date(), MAX_COURSE_DATE_LIMIT)}
            excludeTimes={excludeTime}
            onChange={(date) => {
              if (dateFrom) {
                date.setDate(dateFrom.getDate());
                date.setMonth(dateFrom.getMonth());
                date.setFullYear(dateFrom.getFullYear());
              }
              setDateTo(date);
            }}
          />

          <MatInputOutline
            nomargin="true"
            helpertext="Day 1, Day 2, Day 3, etc."
            title="Description"
            name="date_description"
            value={desc}
            onChange={(e) => {
              setDesc(e.target.value);
            }}
            maxLength={200}
            readOnly={props.disabled}
          />
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            onClick={handleClose.bind(this, null)}
            color="primary"
          >
            Cancel
          </Button>
          <Button
            autoFocus
            color="primary"
            onClick={addDateHandler}
            disabled={!dateFrom || !dateTo}
          >
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default DateModal;
