import React from "react";
import styles from "../Legal.module.css";

export const renderPrivacyPolicy = () => {
  return (
    <div>
      <p className={styles.title}>
        <strong>
          <u>PRIVACY POLICY</u>
        </strong>
      </p>
      <p>
        Capitalized terms and expressions used in this Privacy Policy (the
        &ldquo;<strong>Policy</strong>&ldquo;) and which are not otherwise
        defined herein have the meaning given to them in the General Terms and
        Conditions issued by ZubU inc. (the &ldquo;
        <strong>General Terms and Conditions</strong>&ldquo;).
      </p>
      <p>
        This Policy sets out the way in which ZubU processes Personal Data of
        Users and other individuals in the context of the Services and the ZubU
        Platform.
      </p>
      <p>
        The Identified Individuals (hereinafter defined) are likely to
        communicate ZubU, publisher of the Platform, as well as to the Course
        Providers and certain subcontractors or suppliers (collectively the
        &ldquo;<strong>Suppliers</strong>&ldquo;), Personal Data concerning them
        or concerning other individuals, using the various collection media,
        tools and services made available to them on the Platform.
      </p>
      <p>For the purposes of the Policy:</p>
      <p>
        &ldquo;<strong>Personal Data</strong>&ldquo; means any information or
        data concerning a User or any other individual making it possible to
        identify him/her and any other similar information subject to Privacy
        Laws;
      </p>
      <p>
        &ldquo;<strong>Privacy Laws</strong>&ldquo; means any law regarding
        privacy and personal information or data, namely the{" "}
        <em>
          Act respecting the protection of personal information in the private
          sector
        </em>{" "}
        (Qu&eacute;bec), the{" "}
        <em>Act respecting the protection of personal information </em>(Canada)
        and, to the extent applicable, the{" "}
        <em>
          Regulation (EU) 2016/679 of the European Parliament and of the Council
          (GDPR) of 27 April 2016
        </em>{" "}
        and all other law, regulation, decree and directive governing Personal
        Data having the force of law in any jurisdiction with respect to ZubU in
        the circumstances.
      </p>
      <p>
        The Personal Data collected may be processed, automated or not, in
        accordance with the Privacy Laws and pursuant to the terms and
        conditions relating to each collection medium or service described in
        the Contract and this Policy.
      </p>
      <p>&nbsp;</p>
      <p>
        <a className={styles.link} href="#_Toc48567608">
          <strong>Article 1.1. Processing and Purpose</strong>
        </a>
      </p>
      <p>
        <a className={styles.link} href="#_Toc48567609">
          <strong>
            Article 1.2. Features regarding the Use and Collection of Personal
            Data
          </strong>
        </a>
      </p>
      <p>
        <a className={styles.link} href="#_Toc48567610">
          <strong>Article 1.3. Retention Period of Personal Data</strong>
        </a>
      </p>
      <p>
        <a className={styles.link} href="#_Toc48567611">
          <strong>Article 1.4. Enforcement of Rights</strong>
        </a>
      </p>
      <p>
        <a className={styles.link} href="#_Toc48567612">
          <strong>Article 1.5. Personal Data Recipients</strong>
        </a>
      </p>
      <p>
        <a className={styles.link} href="#_Toc48567613">
          <strong>Article 1.6. Identified Individual Responsibility</strong>
        </a>
      </p>
      <p>
        <a className={styles.link} href="#_Toc48567614">
          <strong>
            Article 2. The Course Provider acts as a Data Controller
          </strong>
        </a>
      </p>
      <p>
        <a className={styles.link} href="#_Toc48567615">
          <strong>Article 2.1 Course Provider&rsquo;s Undertakings</strong>
        </a>
      </p>
      <p>
        <a className={styles.link} href="#_Toc48567616">
          <strong>Article 3. Other Information Collected and Cookies</strong>
        </a>
      </p>
      <p>
        <a className={styles.link} href="#_Toc48567617">
          <strong>Article 3.1 Data that is not Personal Data</strong>
        </a>
      </p>
      <p>
        <a className={styles.link} href="#_Toc48567618">
          <strong>Article 3.2 Cookies</strong>
        </a>
      </p>
      <p>
        <a className={styles.link} href="#_Toc48567619">
          <strong>
            Article 3.3 Links to Third Party Sites or Applications
          </strong>
        </a>
      </p>
      <p>
        <a className={styles.link} href="#_Toc48567620">
          <strong>Article 4. Interpretation</strong>
        </a>
      </p>
      <p>
        <strong>&nbsp;</strong>
      </p>
      <p>
        <strong>
          <u>Article 1. ZubU acts as a Data Controller</u>
        </strong>
      </p>
      <h1>
        <a className={styles.link} name="_Toc48567608" href="/">
          &nbsp;
        </a>
        <strong>
          <u>Article 1.1. Processing and Purpose</u>
        </strong>
      </h1>
      <p>
        The Personal Data of Users collected via the various Services or
        collection media appearing on the Platform are collected by ZubU acting
        as data controller within the meaning of the Privacy Laws. The Personal
        Data of other Identified Individuals will be collected by ZubU, acting
        as data processor or as a service provider of the Course Providers,
        themselves responsible for processing. In both cases, it is possible
        that the data controller is a third party (such as an application
        provider), in which case it will be expressly mentioned on the
        collection media of Personal Data. When required under Privacy Laws, the
        data controller will specify to the Identified Individual the
        characteristics of the processing that it implements under its sole
        responsibility.
      </p>
      <p>
        The categories of persons targeted by this Policy (the &ldquo;
        <strong>Identified Individuals</strong>&ldquo;) are the Users, the
        employees of ZubU and the Course Provider&rsquo;s representatives and
        and all other Internet users consulting the Platform.
      </p>
      <p>
        In the context of the use of the Platform and its Services, the Personal
        Data that an Identified Individual communicates is collected and
        processed by ZubU, by the Course Provider or by a Supplier for the
        following purposes: a contractual or pre-contractual obligation,
        performing its obligations under applicable laws or orders of a
        competent government authorities and/or satisfy the pursuit of its
        legitimate interest, without detrimental treatment as per Identified
        Individuals rights.
      </p>
      <p>
        On the basis of these foregoing, Personal Data is collected and
        processed to provide the following services:
      </p>
      <ul>
        <li className={styles.listItem}>
          allow Users to access the ZubU Platform;
        </li>
        <li className={styles.listItem}>
          allow the Course Provider, ZubU or a Supplier to process the
          information required under the laws relating to the fight against
          money laundering and the financing of terrorism (KYC: you&rsquo;re
          your client), process financial transactions on behalf of ZubU or the
          Course Provider and benefit from advantageous tariffs with payment
          service Suppliers;
        </li>
        <li className={styles.listItem}>
          provide the Course Provider with Personal Data concerning its
          Students, so that it processes them in the normal course of its
          business and in accordance with its own privacy policies;
        </li>
        <li className={styles.listItem}>
          provide the Course Provider and other Users with Services and support
          relating to the Services and the Platform.
        </li>
      </ul>
      <p>The nature of the operations carried out on Personal Data is:</p>
      <ul>
        <li className={styles.listItem}>
          consolidation and processing of the data according to the Platform
          parameters and functionalities;
        </li>
        <li className={styles.listItem}>
          transfer to the Course Provider for their processing;
        </li>
        <li className={styles.listItem}>
          in certain cases, transfer to a Supplier or a government authority
          under the laws relating to the fight against money laundering and the
          financing of terrorism (KYC: know your client); and
        </li>
        <li className={styles.listItem}>
          in some cases, anonymization and transmission of part of the Personal
          Data to a Supplier.
        </li>
      </ul>
      <p>The purpose(s) of the processing are:</p>
      <ul>
        <li className={styles.listItem}>
          access to the ZubU Platform, create and maintain the ZubU Account;
        </li>
        <li className={styles.listItem}>
          use of the Services by the Course Provider and the other Users and
          enrollment to the services of the Course Provider by an Identified
          Individual;
        </li>
        <li className={styles.listItem}>
          manage and send order confirmations and important information on the
          ZubU Account, Service orders, enrollment, subscriptions and continuing
          education credits and information;
        </li>
        <li className={styles.listItem}>
          send newsletters, present offers or information concerning services;
        </li>
        <li className={styles.listItem}>
          the collection of mandatory information under the laws relating to the
          fight against money laundering and the financing of terrorism (KYC:
          know your client) and their transmission to Suppliers;
        </li>
        <li className={styles.listItem}>
          allow the Course Provider to manage enrollment;
        </li>
        <li className={styles.listItem}>
          enabling the good functioning and improvement of the ZubU Platform,
          incident analysis and customer service;
        </li>
        <li className={styles.listItem}>
          any other purpose to which the Identified Individual will have
          previously consented when Personal Data is provided; and
        </li>
        <li className={styles.listItem}>
          resolve disputes, investigate illegal behavior, comply with the laws
          or orders of a competent government authority and ensure compliance
          with contracts and policies binding on ZubU.
        </li>
      </ul>
      <p>The Personal Data collected consists of:</p>
      <ul>
        <li className={styles.listItem}>
          identification data (first name, name, date of birth, pictures,
          security questions and answer, etc.);
        </li>
        <li className={styles.listItem}>
          contact information (phone number, address, email address, etc.);
        </li>
        <li className={styles.listItem}>
          financial information (type of payment, bank identity, etc.);
        </li>
        <li className={styles.listItem}>
          connection and geolocation data (logs, IP address, etc.); and
        </li>
        <li className={styles.listItem}>
          the information that the Identified Individuals transmit in response
          to polls or surveys for the purpose of improving the Services or
          targeting additional needs or markets.
        </li>
      </ul>
      <p>
        The Personal Data of the Identified Individual are kept only for the
        duration of the contractual relationship between the Identified
        Individual and ZubU. The Personal Data of the Identified Individuals is
        erased upon the deletion of the ZubU Account, with the exception of data
        which is archived and anonymized for a certain period after the
        termination of the contractual relationship, such as indicated below
        (point 1.3. Retention period of Personal Data).
      </p>
      <h1>
        <a className={styles.link} name="_Toc48567609" href="/">
          &nbsp;
        </a>
        <strong>
          <u>
            Article 1.2. Features regarding the Use and Collection of Personal
            Data
          </u>
        </strong>
      </h1>
      <p>
        An Identified Individual is not obliged to answer all the questions
        which are asked to her/him.
      </p>
      <p>
        The compulsory nature of the communication of information is indicated
        by an asterisk on the Platform. Failure to communicate this data will
        result in ZubU being unable to process the request of the Identified
        Individual or for them to be able to use the tool or service.
      </p>
      <p>
        Personal Data may be hosted and processed in Canada, by ZubU inc., or in
        other countries in which ZubU has subsidiaries or operates. The
        Identified Individuals therefore accept, when permitted by applicable
        laws, that by transmitting their Personal Data, they do not receive the
        same degree of protection as that which is in force in their country of
        origin. ZubU will use reasonable efforts to ensure that its privacy
        practices comply with Privacy Laws and the principle of "responsibility
        for subsequent transfer" (privacy shield).
      </p>
      <p>
        A Identified Individual may request to no longer receive newsletters or
        other advertising information (opt-out) using the following link:{" "}
        <a className={styles.link} href="https://zubueducation.com/unsubscribe">
          www.zubueducation.com/unsubscribe
        </a>{" "}
        and following the instructions.
      </p>
      <h1>
        <a className={styles.link} name="_Toc48567610" href="/">
          &nbsp;
        </a>
        <strong>
          <u>Article 1.3. Retention Period of Personal Data</u>
        </strong>
      </h1>
      <p>
        The Personal Data of the Identified Individual may be stored and
        processed for the time necessary to achieve the intended purpose,
        subject to the maximum periods mentioned in this Policy, namely:
      </p>
      <ul>
        <li className={styles.listItem}>
          contracts concluded within the commercial relationship, for five (5)
          years from the end of such contracts;
        </li>
        <li className={styles.listItem}>
          bank documents, for five (5) years following the last transaction;
        </li>
        <li className={styles.listItem}>
          information relating to the management of Orders, for ten (10) years
          following the last transaction on the Platform;
        </li>
        <li className={styles.listItem}>
          information relating to billing management, for ten (10) years
          following the last invoice.
        </li>
      </ul>
      <p>
        In the event of litigation, all information and documents, and any
        document containing Personal Data tending to establish the disputed
        facts may be kept for the duration of the procedure, including for a
        period longer than that indicated above.
      </p>
      <p>
        Certain data may be archived beyond the durations indicated for the
        purposes of research, establishment, and prosecution of criminal
        offenses for the sole purpose of allowing, as necessary, the provision
        of such data to a competent judicial authority.
      </p>
      <h1>
        <a className={styles.link} name="_Toc48567611" href="/">
          &nbsp;
        </a>
        <strong>
          <u>Article 1.4. </u>
        </strong>
        <strong>
          <u>Enforcement of Rights</u>
        </strong>
      </h1>
      <p>
        Each Identified Individual has rights with respect to its Personal Data,
        which ZubU collects and processes. These rights are as follows:
      </p>
      <ul>
        <li className={styles.listItem}>
          a right to access, rectify and erase Personal Data (inaccurate,
          incomplete, ambiguous, or outdated);
        </li>
        <li className={styles.listItem}>
          a right to oppose the processing of Personal Data at any time in the
          context of commercial prospecting;
        </li>
        <li className={styles.listItem}>
          a right to limit the processing of Personal Data under the conditions
          provided for by Privacy Laws;
        </li>
        <li className={styles.listItem}>
          a right to the portability of Personal Data;
        </li>
        <li className={styles.listItem}>
          a right to withdraw consent at any time;
        </li>
        <li className={styles.listItem}>
          a right to lodge a complaint with a supervisory authority under the
          Privacy Laws; and
        </li>
        <li className={styles.listItem}>
          a right to communicate instructions to the data controller concerning
          Personal Data in the event of death.
        </li>
      </ul>
      <p>
        The Identified Individual can exercise its rights by contacting ZubU at
        the following:
      </p>
      <ul>
        <li className={styles.listItem}>par e-mail : dpo@zubueducation.com</li>
      </ul>
      <p>
        <strong>
          <u>Canada</u>
        </strong>
      </p>
      <ul>
        <li className={styles.listItem}>
          by mail: 11655 Rue Letellier, Montreal, Quebec, H3M 2Z7 Canada
        </li>
      </ul>
      <p>The Personal Data Protection Officer (DPO) is Vassilios Marcopulos.</p>
      <p>
        Each Identified Individual may, in the event of a dispute, lodge a
        complaint with a supervisory authority under Privacy Laws.
      </p>
      <p>
        ZubU reserves the right to refuse or modify a request of an Identified
        Individual if it does not comply with Privacy Laws or if ZubU has an
        obligation under applicable laws or regulations or is subject to a
        request or order from a competent administrative or judicial
        governmental authority to the opposite effect (for example: to keep or
        disclose information).
      </p>
      <h1>
        <a className={styles.link} name="_Toc48567612" href="/">
          &nbsp;
        </a>
        <strong>
          <u>Article 1.5. Personal Data Recipients</u>
        </strong>
      </h1>
      <p>
        ZubU may have to transfer temporarily and securely to Suppliers with
        whom it will, beforehand, enter into contractual relations, certain
        Identified Individual Personal Data, only when necessary, notably:
      </p>
      <p>&nbsp;</p>
      <ul>
        <li className={styles.listItem}>
          the operation and maintenance of the ZubU Platform (e.g. hosting,
          maintenance, etc. of the Website);
        </li>
        <li className={styles.listItem}>
          for the management and use of the payment Account;
        </li>
        <li className={styles.listItem}>
          for the fight against money laundering and the financing of terrorism
          (e.g. financial institution of the Course Provider or of the
          Identified Individual, or payment service provider) - The terms and
          conditions applicable to the treatment of Personal Data by each
          Supplier are detailed in the account agreement and privacy policy
          issued by each such Supplier;
        </li>
        <li className={styles.listItem}>
          enrollment to Course Provider Academic Content - The terms and
          conditions applicable to the treatment of Personal Data by each Course
          Provider are detailed in privacy policy issued by each such Course
          Provider;
        </li>
        <li className={styles.listItem}>
          to respond to any request from government authorities.
        </li>
      </ul>
      <p>
        As such, ZubU ensures that all third party recipients of Personal Data
        have the protection of Personal Data at the heart and undertake to take
        all reasonable measures to ensure the security and confidentiality of
        Personal Data in accordance with Privacy Laws applicable to them.
      </p>
      <p>
        Some of these Course Providers and Suppliers act as data controllers. In
        such case, they will communicate to ZubU or to the Identified Individual
        the relevant information and the terms and conditions applicable to its
        collection and processing of Personal Data.
      </p>
      <p>
        In addition, when the Course Provider or Supplier concerned is located
        outside the European Union or a country with adequate regulations within
        the meaning of Privacy Laws, ZubU oversees its relationship with the
        Course Provider or Supplier by adopting appropriate contractual
        arrangements. The Course Provider and Suppliers will be directly
        responsible for the suppliers that it retains or uses and that are not
        selected by, and contractually linked to ZubU.
      </p>
      <p>
        ZubU does not sell nor disclose Personal Data, other than in accordance
        with this Policy. A list of disclosures will be provided upon request
        from an Identified Individual.
      </p>
      <p>
        ZubU may, in the event of a sale, restructuring (merger, amalgamation,
        etc.) or, if applicable, bankruptcy, transfer Personal Data to any third
        party who will be required to resume or continue ZubU&rsquo;s business.
        When required by Privacy Laws, ZubU will communicate with the Identified
        Individuals to obtain their consent.
      </p>
      <h1>
        <a className={styles.link} name="_Toc48567613" href="/">
          &nbsp;
        </a>
        <strong>
          <u>Article 1.6. Identified Individual Responsibility</u>
        </strong>
      </h1>
      <p>
        Each Identified Individual may provide content and Personal Data on
        public platforms or features of the Platform (for example: discussions
        on a forum / chat, multi-user functions. The Identified Individuals must
        then ensure that their confidentiality parameters take account of their
        preference or refrain from providing Personal Data. Only the Identified
        Individual is responsible for the disclosure of its data.
      </p>
      <h1>
        <a className={styles.link} name="_Toc48567614" href="/">
          &nbsp;
        </a>
        <strong>
          <u>Article 2. The Course Provider acts as a Data Controller</u>
        </strong>
      </h1>
      <p>
        The Course Provider at times acts as data controller when it collects
        and processes Personal Data from Students and any other Identified
        Individual who enroll to its Academic Content.
      </p>
      <h1>
        <a className={styles.link} name="_Toc48567615" href="/">
          &nbsp;
        </a>
        <strong>
          <u>Article 2.1 Course Provider&rsquo;s Undertakings</u>
        </strong>
      </h1>
      <p>
        As part of the Course Provider processing of Personal Data via the ZubU
        Platform and the Website, the Course Provider undertakes to:
      </p>
      <ul>
        <li className={styles.listItem}>
          comply with the applicable provisions of the Privacy Laws;
        </li>
        <li className={styles.listItem}>
          process Personal Data lawfully, fairly and transparently with regard
          to the Identified Individual;
        </li>
        <li className={styles.listItem}>
          collect Personal Data for specific, explicit and legitimate purposes,
          in accordance with the Course Provider&rsquo;s privacy policy, and not
          process said data subsequently in a manner incompatible with these
          purposes;
        </li>
        <li className={styles.listItem}>
          not to collect so-called &ldquo;sensitive&ldquo; data within the
          meaning of Privacy Laws;
        </li>
        <li className={styles.listItem}>
          ensure that there is a legitimate and legal basis for its processing
          of Personal Data;
        </li>
        <li className={styles.listItem}>
          collect adequate and relevant Personal Data necessary for the purposes
          for which they are processed, maintain their accuracy and, if
          necessary, update them;
        </li>
        <li className={styles.listItem}>
          keep the Personal Data processed in a form allowing the identification
          of the persons concerned for a period not exceeding that necessary
          with regard to the purposes for which they are processed;
        </li>
        <li className={styles.listItem}>
          delete the Personal Data collected once the period for which it was
          collected expires;
        </li>
        <li className={styles.listItem}>
          not to transfer Personal Data to a data controller or processor
          located in a third country that does not ensure an adequate level of
          protection of personal data within the meaning of Privacy Laws. In the
          event that such a transfer should take place, the Course Provider
          shall ensure compliance with the conditions set forth in Privacy Laws;
        </li>
        <li className={styles.listItem}>
          guarantee appropriate security to Personal Data collected, including
          protection against unauthorized or unlawful processing and against
          accidental loss, destruction or damage, using appropriate technical
          and organizational measures;
        </li>
        <li className={styles.listItem}>
          comply, with regard to the Identified Individuals, with the
          information obligation set forth in Privacy Laws; and include in its
          general conditions as well as in any questionnaire or form (including
          electronic), legal notices imposed by said laws;
        </li>
        <li className={styles.listItem}>
          to respond, in writing, to requests to exercise Identified
          Individuals&rsquo; rights regarding their Personal Data, namely, right
          of access, rectification, deletion and opposition, right to limit
          processing, right to data portability , right not to be the subject of
          an automated decision and right to define the treatment Personal Data
          upon death;
        </li>
        <li className={styles.listItem}>
          keep a register of processing activities recording Personal Data
          processing;
        </li>
        <li className={styles.listItem}>
          have a Personal Data protection officer;
        </li>
        <li className={styles.listItem}>
          designate only subcontractors who secure sufficient guarantees and
          appropriate technical and organizational measures to protect Personal
          Data, conclude with these subcontractors a proper agreement and ensure
          its proper enforcement;
        </li>
        <li className={styles.listItem}>
          take necessary steps with the competent government authorities, in
          particular with a view to carrying out an impact assessment when the
          processing of Personal Data envisaged is likely to generate a high
          risk for the rights and freedoms of Identified Individuals pursuant to
          Privacy Laws;
        </li>
        <li className={styles.listItem}>
          set up an internal procedure, in order to identify cases of Personal
          Data breaches for which notification to the competent protection
          authority and / or to the Identified Individual is required.
        </li>
      </ul>
      <p>
        The Course Provider, in its capacity as data controller, is responsible
        to comply with the abovementioned obligations and must be able to
        demonstrate, at any time, that they are respected. ZubU cannot be held
        liable for a breach by the Course Provider of Privacy Laws or one of the
        stipulations of this Policy, except when the law expressly states
        otherwise.
      </p>
      <h1>
        <a className={styles.link} name="_Toc48567616" href="/">
          &nbsp;
        </a>
        <strong>
          <u>Article 3. Other Information Collected and Cookies</u>
        </strong>
      </h1>
      <h1>
        <a className={styles.link} name="_Toc48567617" href="/">
          &nbsp;
        </a>
        <strong>
          <u>Article 3.1 Data that is not Personal Data</u>
        </strong>
      </h1>
      <p>
        ZubU may collect information which does not qualify as &ldquo;Personal
        Data&rdquo;, including:
      </p>
      <ul>
        <li className={styles.listItem}>identifiers and avatars;</li>
        <li className={styles.listItem}>
          browser information (for example: preferences, language, etc.);
        </li>
        <li className={styles.listItem}>
          landing and exit pages, landing pages and page views and other
          interactions with web content;
        </li>
        <li className={styles.listItem}>
          information relating to devices, equipment and software, updates and
          add-ons used;
        </li>
        <li className={styles.listItem}>
          information relating to the functionalities and services used, usage
          statistics, donations, preferences, predispositions, behaviors and
          skills; and
        </li>
        <li className={styles.listItem}>
          events affecting use (for example: service interruptions, requested
          URLs, etc.).
        </li>
      </ul>
      <p>
        This information may not constitute Personal Data and may not benefit
        from Privacy Laws protection. For example, ZubU could collect, process
        and transfer non-nominative information, such as demographic
        information, material specifications or sales statistics with Course
        Providers, Suppliers or other partners.
      </p>
      <h1>
        <a className={styles.link} name="_Toc48567618" href="/">
          &nbsp;
        </a>
        <strong>
          <u>Article 3.2 Cookies</u>
        </strong>
      </h1>
      <p>
        ZubU may collect and store information on the device of an Identified
        Individual, using mechanisms such as cookies, browser data storage, and
        application data caches.
      </p>
      <p>
        A cookie is a small text, image or software file, containing
        information, which is saved on the hard drive of your terminal (for
        example: computer, tablet, smartphone, or any device allowing you to
        browse the Internet) when consulting a website with your browser
        software. It is transmitted by the website server to your browser. The
        cookie file relates in particular to the pages visited, the
        advertisements on which you have clicked, the type of browser you use,
        your IP address, and allows its issuer to identify the terminal in which
        it is registered, for the duration of validity or registration of the
        cookie concerned.
      </p>
      <p>
        Only the issuer of a cookie may read or modify the information contained
        therein.
      </p>
      <p>
        Cookies improve your browsing comfort on our Platform and are essential
        to access certain secure areas. If you decide to block all cookies
        through your internet browser, you will only be able to visit the public
        part of the Platform.
      </p>
      <p>
        As indicated on the information banner on the Platform, by continuing to
        browse, you accept the deposit of cookies on your terminal. You can
        reverse this decision at any time by opposing the deposit of cookies.
      </p>
      <p>
        ZubU may use cookies and similar technologies (eg web beacons, invisible
        GIFs) to help them in particular to understand which Internet pages,
        functionalities or advertisements the Identified Individuals consult.
        This information helps them to keep track of orders or transactions that
        have not been carried out, to measure the effectiveness of
        advertisements, to take care not to offer the same advertisement
        repeatedly as well as to improve the experience, to monitor traffic on
        the Platform, solve technical problems, detect and prevent fraud and
        enforce the General Terms and Conditions. Anyone can deactivate cookies,
        limit the types of cookies authorized or configure their browser to
        notify them when cookies are sent. To manage these preferences,
        reference should be made to the instructions provided by the web
        browser.
      </p>
      <p>
        Third-party analytical technologies can be integrated into the Platform
        and combine information they collect with other information collected
        independently over time on different platforms or sites. The privacy
        policies of these third parties govern the use of such data.
      </p>
      <p>
        Certain technical cookies are essential for the proper functioning of
        the Platform. Their deletion can lead to navigation difficulties as well
        as the impossibility of benefiting from all the services.
      </p>
      <h1>
        <a className={styles.link} name="_Toc48567619" href="/">
          &nbsp;
        </a>
        <strong>
          <u>Article 3.3 Links to Third Party Sites or Applications</u>
        </strong>
      </h1>
      <p>
        The Platform may contain links or advertisements to sites, applications
        or platforms belonging to third parties. If an Identified Individual
        clicks on them or consults these locations, site, applications or
        platforms, it leaves the services of ZubU and must consult the
        confidentiality policies of these third parties before sharing any
        Personal Data. ZubU does not provide any guarantee in this respect.
      </p>
      <h1>
        <a className={styles.link} name="_Toc48567620" href="/">
          &nbsp;
        </a>
        <strong>
          <u>Article 4. Interpretation</u>
        </strong>
      </h1>
      <p>
        ZubU may modify this Policy from time to time without notice. A notice
        will be sent to the Users and will be published on the home page as of
        the entry into force of the modification, unless there are additional
        requirements under the Privacy Laws.
      </p>
      <p>&nbsp;</p>
      <p>&nbsp;</p>
    </div>
  );
};
