import React, { useState, Fragment } from "react";
import styles from "./CeExportConfirmModal.module.css";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import { useTranslation } from "react-i18next";
import MatCheckbox from "../../../components/ui/checkbox/MatCheckbox";
import csvImage from "../../../assets/images/inApp/csv.png";
import certImage from "../../../assets/images/inApp/certicon.png";
import AddIcon from '@material-ui/icons/Add';

const CeExportConfirmModal = (props) => {
    const { t } = useTranslation();
    const [includeCertificates, setIncludeCertificates] = useState(false);

    // ------------------------------

    const handleClose = (confirm) => {
        props.onClose(confirm, includeCertificates);
    };

    // ------------------------------

    const checkChangeHandler = (key, e, selected) => {
        setIncludeCertificates(selected);
    };

    // ------------------------------

    return (
        <Dialog
            fullScreen={false}
            fullWidth={false}
            open={props.open}
            onClose={handleClose.bind(this, null)}
            aria-labelledby="responsive-dialog-title"
        >
            <DialogTitle id="alert-dialog-title">
                {t("ce_export_to_cvs_title")}
            </DialogTitle>

            <DialogContent >
                <div className={styles.center}>
                    <img
                        className={styles.image}
                        src={csvImage}
                        alt=""
                        style={{ marginRight: "5px" }}
                    />
                    {includeCertificates && (
                        <Fragment>
                            <AddIcon style={{ color: "green" }} />
                            <img
                                className={styles.image}
                                src={certImage}
                                alt=""
                            />
                        </Fragment>)}
                </div>
                <MatCheckbox
                    color="success"
                    plainright="true"
                    name="include_cert"
                    checked={includeCertificates ? true : false}
                    label={t("ce_export_to_cvs_include_cert")}
                    onChange={checkChangeHandler.bind(this, "include_cert")}
                />
                <div className={styles.disclaimer}>
                    {t("ce_export_to_cvs_include_cert_blurb")}
                </div>
            </DialogContent>
            <DialogActions>
                <Button
                    autoFocus
                    onClick={handleClose.bind(this, null)}
                    color="primary"
                >
                    {t("g_cancel")}
                </Button>
                <Button
                    autoFocus
                    onClick={handleClose.bind(this, true)}
                    color="primary"
                    style={{ color: "white", backgroundColor: "#3E8CAB" }}
                >
                    {t("g_export")}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default CeExportConfirmModal;
