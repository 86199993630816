import React from "react";
import styles from "../BannerSlide.module.css";
import "pure-react-carousel/dist/react-carousel.es.css";

const BannerSlide = (props) => {
  return (
    <div className={styles.slideWrapContainer}>
      <div className={styles.textContainer}>
        <div className={styles.title}>{props.title}</div>
        <div className={styles.subTitle}>{props.subTitle}</div>
        <div className={styles.text}>{props.text}</div>
      </div>
      <div className={styles.imgContainer}>
        <img src={props.img} alt="" className={styles.image} />
      </div>
    </div>
  );
};

export default BannerSlide;
