import React from "react";
import ReactDOM from "react-dom";
import { createStore, combineReducers, applyMiddleware, compose } from "redux";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import ReduxThunk from "redux-thunk";
import { stripePublicKey } from "./globals/ApiKeys";
import { I18nextProvider } from "react-i18next";
import i18n from "./i18n";
import "./index.css";

import App from "./App";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

import staffReducer from "./store/reducers/staff";
import coursesReducer from "./store/reducers/courses";
import ordersReducer from "./store/reducers/orders";
import authReducer from "./store/reducers/auth";
import cartReducer from "./store/reducers/cart";
import mobilecartReducer from "./store/reducers/mobilecart";
import mobileordersReducer from "./store/reducers/mobileorders";
import paymentReducer from "./store/reducers/payment";
import reviewsReducer from "./store/reducers/reviews";
import analyticsReducer from "./store/reducers/analytics";
import connectReducer from "./store/reducers/connect";
import professorReducer from "./store/reducers/professor";
import profileReducer from "./store/reducers/profile";
import studentReducer from "./store/reducers/student";
import promoReducer from "./store/reducers/promo";
import studentorderReducer from "./store/reducers/studentorder";
import advertisementReducer from "./store/reducers/advertisement";
import settingReducer from "./store/reducers/setting";
import alertReducer from "./store/reducers/alert";
import surveyReducer from "./store/reducers/survey";
import certificateReducer from "./store/reducers/certificate";
import providerReducer from "./store/reducers/provider";
import zoomReducer from "./store/reducers/zoom";
import registrationReducer from "./store/reducers/registration";
import cesReducer from "./store/reducers/ces";
import rateReducer from "./store/reducers/rate";
import redirectReducer from "./store/reducers/redirect";
import inboxReducer from "./store/reducers/inbox";
import attendanceReducer from "./store/reducers/attendance";
import memberReducer from "./store/reducers/member";
import { sentryConfig } from "./globals/ApiKeys";
import * as Sentry from "@sentry/react";

import "./ialgolia.css";

Sentry.init(sentryConfig);

const rootReducer = combineReducers({
  courses: coursesReducer,
  orders: ordersReducer,
  auth: authReducer,
  cart: cartReducer,
  mobilecart: mobilecartReducer,
  mobileorders: mobileordersReducer,
  payment: paymentReducer,
  analytics: analyticsReducer,
  connect: connectReducer,
  professor: professorReducer,
  profile: profileReducer,
  student: studentReducer,
  promo: promoReducer,
  studentorder: studentorderReducer,
  advertisement: advertisementReducer,
  setting: settingReducer,
  alert: alertReducer,
  survey: surveyReducer,
  certificate: certificateReducer,
  reviews: reviewsReducer,
  provider: providerReducer,
  zoom: zoomReducer,
  registration: registrationReducer,
  ce: cesReducer,
  rate: rateReducer,
  redirect: redirectReducer,
  inbox: inboxReducer,
  staff: staffReducer,
  attendance: attendanceReducer,
  member: memberReducer,
});

const LoggerMiddle = (store) => {
  return (next) => {
    return (action) => {
      const result = next(action);
      return result;
    };
  };
};

const composeHandlers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
  rootReducer,
  composeHandlers(applyMiddleware(LoggerMiddle, ReduxThunk))
);

const stripePromise = loadStripe(stripePublicKey);

const app = (
  <I18nextProvider i18n={i18n}>
    <Provider store={store}>
      <Elements stripe={stripePromise}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </Elements>
    </Provider>
  </I18nextProvider>
);

ReactDOM.render(app, document.getElementById("root"));
