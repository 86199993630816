import React, { Fragment, Component } from "react";
import { connect } from "react-redux";
import FlatList from "flatlist-react";
import styles from "../Course.module.css";
import SimpleContainer from "../../../components/ui/container/Container";
import CoursePriceModal from "../../../components/modals/coursePriceModal/CoursePriceModal";
import ConfirmModal from "../../../components/modals/confirmModal/ConfirmModal";
import PricingItem from "../../../components/items/pricingItem/PricingItem";
import Installment from "../../../components/ui/installment/Installment";
import InfoIcon from "@material-ui/icons/Info";

class PricingScreen extends Component {
  state = {
    pricingItems: this.props.pricingItems,
    selectedIndex: -1,
    selectedItem: null,
    openPriceModal: false,
    openDeleteConfirmModal: false,
  };

  // ------------------------------

  editHandler = (index, item, event) => {
    event.preventDefault();
    if (!item) {
      item = {
        casting: "",
        audience: [],
        component: "",
        description: "",
        ce_odq: -1,
        credit_lecture_hours: "",
        credit_handson_hours: "",
        cost: "0",
        expiration_date: this.props.courseStartDate,
      };
    }

    this.setState({
      ...this.state,
      selectedIndex: index,
      selectedItem: item,
      openPriceModal: true,
    });
  };

  // ------------------------------

  pricingAddedHandler = (pricingItem) => {
    if (pricingItem !== null) {
      let copyPricingItems = [...this.state.pricingItems];
      let index = this.state.selectedIndex;

      if (index > -1) {
        copyPricingItems[index] = pricingItem;
      } else {
        copyPricingItems = copyPricingItems.concat(pricingItem);
      }

      this.setState({
        ...this.state,
        pricingItems: copyPricingItems,
        openPriceModal: false,
      });

      this.props.updatePricingItems(copyPricingItems);
    } else {
      this.setState({ ...this.state, openPriceModal: false });
    }
  };

  // ------------------------------

  pricingRemovedHandler = (answer) => {
    if (answer === "ok") {
      let copyPricingItems = [...this.state.pricingItems];
      let index = this.state.selectedIndex;

      if (index > -1) {
        copyPricingItems.splice(index, 1);
      }

      this.setState({
        ...this.state,
        pricingItems: copyPricingItems,
        openDeleteConfirmModal: false,
      });

      this.props.updatePricingItems(copyPricingItems);
    } else {
      this.setState({ ...this.state, openDeleteConfirmModal: false });
    }
  };

  // ------------------------------

  renderPricingItem = (item, index) => {
    return (
      <PricingItem
        currency={this.props.currency}
        key={index}
        onClick={this.editHandler.bind(this, index, item)}
        title={"Registration Option #" + (index + 1)}
        casting={item.casting}
        audience={item.audience}
        component={item.component}
        description={item.description}
        ce_odq={item.resolve_odq()}
        credit_lecture_hours={item.credit_lecture_hours}
        credit_handson_hours={item.credit_handson_hours}
        cost={item.cost}
        expiration_date={item.expirationDate()}
        members_only={item.members_only}
        onDelete={(event) => {
          event.preventDefault();
          this.setState({
            ...this.state,
            selectedIndex: index,
            selectedItem: item,
            openDeleteConfirmModal: true,
          });
        }}
      />
    );
  };

  // ------------------------------

  render() {
    return (
      <SimpleContainer disableGutters={false}>
        <ConfirmModal
          title="Confirmation Required"
          message="Are you sure you wish to remove this registration option?"
          open={this.state.openDeleteConfirmModal}
          onClose={this.pricingRemovedHandler}
        />

        {this.state.openPriceModal && (
          <CoursePriceModal
            currency={this.props.currency}
            is_specialty={this.props.is_specialty}
            is_video={this.props.is_video}
            courseStartDate={this.props.courseStartDate}
            open={this.state.openPriceModal}
            selectedItem={this.state.selectedItem}
            close={this.pricingAddedHandler}
          />
        )}
        <form className={styles.form}>
          <div className={styles.sectionTitle}>Registration Options</div>

          <div className={styles.pricingContainer}>
            <b>* Minimum of one registration option is required.</b>
          </div>

          <div className={styles.row}>
            <div className={styles.column}>
              <div className={styles.pricingContainer}>
                <FlatList
                  renderWhenEmpty={() => {
                    return <div></div>;
                  }}
                  list={this.state.pricingItems}
                  renderItem={this.renderPricingItem}
                />

                <PricingItem
                  onClick={this.editHandler.bind(this, -1, null)}
                  empty
                />
              </div>
            </div>
          </div>

          <div className={styles.row}>
            <div className={styles.column}>
              <div className={styles.pricingContainer}>
                <div className={styles.rowIt}>
                  <div className={styles.icon}>
                    <InfoIcon />
                  </div>
                  <div>
                    Registration options are categorized packages that you offer
                    for your course. Each option will include the price, the
                    intended participant, the associated credits and the
                    specific setting.
                  </div>
                </div>
              </div>

              <div className={styles.pricingContainer}>
                <div className={styles.rowIt}>
                  <div className={styles.icon}>
                    <InfoIcon />
                  </div>
                  <div>
                    Registration options will determine who may register for
                    your course (Dentist, Hygienist, other) at your desired
                    price. Expiry dates on registration options are used to set
                    a registration deadline. Ex: Early bird special expiry date
                    set one month before the course start date.
                  </div>
                </div>
              </div>

              <div className={styles.pricingContainer}>
                <div className={styles.rowIt}>
                  <div className={styles.icon}>
                    <InfoIcon />
                  </div>
                  <div>
                    Registration options will always stay visible on the ZubU
                    mobile app even after expiry, however registration for that
                    option will not be permited.
                  </div>
                </div>
              </div>

              <div className={styles.pricingContainer}>
                <p>Example</p>
                <p>
                  Registration Option 1#
                  <br />
                  Online, dentist, lecture only / 3 ce credits /{" "}
                  {this.props.currency.shortName}300
                </p>
                <p>
                  Registration Option 2#
                  <br />
                  Classroom, dentists , lecture and hands-on / 6 ce credits /{" "}
                  {this.props.currency.shortName}500
                </p>
                <p>
                  Registration Option 3#
                  <br />
                  Classroom, hygienist and others , lecture only / 3 ce credits
                  / {this.props.currency.shortName}150
                </p>
              </div>

              {this.props.is_video ? (
                <div>&nbsp;</div>
              ) : (
                <Fragment>
                  <div className={styles.sectionTitle}>
                    Student Payment Options
                  </div>

                  <div className={styles.row}>
                    <Installment
                      currency={this.props.currency}
                      multiPayOption={this.props.multiPayOption}
                      updateMultiPayOption={(multiPayOption) => {
                        this.props.updateMultiPayOption(multiPayOption);
                      }}
                    />
                  </div>
                </Fragment>
              )}
            </div>
          </div>
        </form>
      </SimpleContainer>
    );
  }
}

// ------------------------------

const mapStateToProps = (state, props) => {
  return {
    multiPayOption: props.multiPayOption,
    currency: props.currency,
    courseStartDate: props.courseStartDate,
    is_specialty: props.is_specialty,
    is_video: props.is_video,
    pricingItems: props.pricingItems,
  };
};

export default connect(mapStateToProps, null)(PricingScreen);
