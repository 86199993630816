import React, { Component } from "react";
import { connect } from "react-redux";
import * as profileActions from "../../store/actions/profile";
import * as certActions from "../../store/actions/certificate";
import Button from "@material-ui/core/Button";
import AlertSuccess from "../../components/ui/snackbar/AlertSuccess";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import InfoModal from "../../components/modals/infoModal/InfoModal";
import MatAppBar from "../../components/ui/appBar/MatAppBar";
import CertificateItem from "../../components/items/certificateItem/CertificateItem";
import ConfirmModal from "../../components/modals/confirmModal/ConfirmModal";
import CertLinkModal from "../../components/modals/certLinkModal/CertLinkModal";
import Spinner from "../../components/ui/spinner/Spinner";
import Empty from "../../components/ui/empty/Empty";
import certificate from "../../assets/images/inApp/certificate.jpg";
import { objectHasNoValues } from "../../globals/Common";
import * as logger from "../../globals/Logger";

class CertificateListScreen extends Component {
  state = {
    wait: false,
    waitNoRender: false,
    waitMessage: "",
    error: null,
    openDeleteConfirmModal: false,
    idToBeDeleted: null,
    success: null,
    alertSuccess: null,
    alertSeverity: null,

    openCertLinkModal: false,
    certLinkToBeDeleted: null,
    certificateTitle: null,
  };

  // ------------------------------

  update = async (propValue) => {
    await this.setState({ ...this.state, ...propValue });
  };

  // ------------------------------

  componentDidMount = async () => {
    try {
      window.scrollTo(0, 0);
      const loadAsync = [];
      await this.update({ error: null, wait: true });

      if (objectHasNoValues(this.props.profile)) {
        loadAsync.push(this.props.fetchProfile());
      }

      loadAsync.push(this.props.fetchCert());

      await Promise.all(loadAsync);
      await this.update({ wait: false });
    } catch (error) {
      logger.error(error);
      await this.update({ wait: false, error: error.message });
    }
  };

  // ------------------------------

  addHandler = async () => {
    this.props.setEditAction("new");
    this.props.history.push("/editcertificate");
  };

  // ------------------------------

  editHandler = (id) => {
    this.props.setEditAction(id);
    this.props.history.push("/editcertificate");
  };

  // ------------------------------

  deleteHandler = async (id) => {
    try {
      await this.update({
        waitNoRender: true,
      });

      await this.props.fetchLinkedCourses(id);

      await this.update({
        waitNoRender: false,
      });
    } catch (error) {
      logger.error(error);
      this.update({
        waitNoRender: false,
        error: error.message,
      });
    }

    if (this.props.certificateLink && this.props.certificateLink.length > 0) {
      this.update({
        alertSuccess: `Cannot delete certificate.  Linked to ${
          this.props.certificateLink.length
        } course${
          this.props.certificateLink.length > 1 ? "s" : ""
        }.  Select 'Used by...' for more information.`,
        alertSeverity: "warning",
      });
    } else {
      this.update({
        openDeleteConfirmModal: true,
        idToBeDeleted: id,
      });
    }
  };

  // ------------------------------

  deleteConfirmHandler = async (answer) => {
    if (answer === "ok") {
      try {
        await this.update({
          waitNoRender: true,
          openDeleteConfirmModal: false,
        });

        await this.props.removeCert(this.state.idToBeDeleted);

        await this.update({
          waitNoRender: false,
          idToBeDeleted: null,
        });
      } catch (error) {
        logger.error(error);
        this.update({
          waitNoRender: false,
          error: error.message,
        });
      }
    } else {
      this.update({
        openDeleteConfirmModal: false,
        idToBeDeleted: null,
      });
    }
  };

  // ------------------------------

  certLinkHandler = async (certId, title) => {
    try {
      await this.update({
        waitNoRender: true,
      });

      await this.props.fetchLinkedCourses(certId);

      await this.update({
        openCertLinkModal: true,
        waitNoRender: false,
        certificateTitle: title,
      });
    } catch (error) {
      logger.error(error);
      this.update({
        waitNoRender: false,
        error: error.message,
      });
    }
  };

  // ------------------------------

  unlinkCertHandler = (certLink) => {
    this.update({
      openUnlinkCertConfirmModal: true,
      certLinkToBeDeleted: certLink,
    });
  };

  // ------------------------------

  unlinkCertConfirmHandler = async (answer) => {
    if (answer === "ok") {
      try {
        await this.update({
          waitNoRender: true,
          openUnlinkCertConfirmModal: false,
        });

        await this.props.unlinkCourse(this.state.certLinkToBeDeleted);

        await this.update({
          waitNoRender: false,
          certLinkToBeDeleted: null,
        });
      } catch (error) {
        logger.error(error);
        this.update({
          waitNoRender: false,
          error: error.message,
        });
      }
    } else {
      this.update({
        openUnlinkCertConfirmModal: false,
        certLinkToBeDeleted: null,
      });
    }
  };

  // ------------------------------

  renderItems = () => {
    return this.props.certificate.map((item, index) => {
      return (
        <CertificateItem
          key={index}
          certificate={item}
          edit={this.editHandler.bind(this, item.id)}
          delete={this.deleteHandler.bind(this, item.id)}
          certLinks={this.certLinkHandler.bind(this, item.id, item.title)}
        />
      );
    });
  };

  // ------------------------------

  render() {
    if (this.state.wait) {
      return <Spinner />;
    }

    if (!this.props.certificate || this.props.certificate.length === 0) {
      return (
        <div>
          {this.state.error !== null && (
            <InfoModal
              isErrorModal={true}
              title="An error has occured"
              message={this.state.error}
              open={this.state.error !== null}
              onClose={() => {
                this.update({ error: null });
              }}
            />
          )}
          <Empty
            column
            image={certificate}
            message={
              <p>
                Create custom certificates of completion for students who
                successfully completed your courses. Create your template here
                first, then link it to any course in the course builder.{" "}
                <b>(Certificates are sent in PDF format)</b>
              </p>
            }
            onClick={this.addHandler}
            buttonLabel={"Add Certificate"}
          />
        </div>
      );
    }

    return (
      <div style={{ backgroundColor: "white" }}>
        {this.state.waitNoRender && (
          <Spinner message={this.state.waitMessage} />
        )}

        {this.state.error !== null && (
          <InfoModal
            isErrorModal={true}
            title="An error has occured"
            message={this.state.error}
            open={this.state.error !== null}
            onClose={this.closeErrorHandler}
          />
        )}

        {this.state.openDeleteConfirmModal && (
          <ConfirmModal
            title="Confirmation Required"
            message={"Are you sure you wish to remove this certificate?"}
            open={this.state.openDeleteConfirmModal}
            onClose={this.deleteConfirmHandler}
            okButtonText={"Delete"}
          />
        )}

        {this.state.openUnlinkCertConfirmModal && (
          <ConfirmModal
            title="Confirmation Required"
            message={
              "Are you sure you wish to remove the certificate for this course?  Students will not receive a certificate of completion for courses with no configured template."
            }
            open={this.state.openUnlinkCertConfirmModal}
            onClose={this.unlinkCertConfirmHandler}
            okButtonText={"Delete"}
          />
        )}

        {this.state.openCertLinkModal && (
          <CertLinkModal
            certTitle={this.state.certificateTitle}
            list={this.props.certificateLink}
            unlinkCert={this.unlinkCertHandler}
            onClose={() => {
              this.update({ openCertLinkModal: false, certificateTitle: null });
            }}
          />
        )}

        {this.state.alertSuccess !== null && (
          <AlertSuccess
            severity={this.state.alertSeverity}
            message={this.state.alertSuccess}
            open={this.state.alertSuccess !== null}
            onClose={() => {
              this.update({ alertSuccess: null, alertSeverity: null });
            }}
          />
        )}

        <MatAppBar
          right={
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <Button
                color="inherit"
                style={{ color: "black" }}
                onClick={this.addHandler}
                startIcon={<AddCircleIcon />}
              >
                New
              </Button>
            </div>
          }
        />
        <div style={{ height: "58px" }}></div>
        {this.renderItems()}
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    action: props.history.action,
    certificate: state.certificate.certificate,
    profile: state.profile.profile,
    certificateLink: state.certificate.certificateLink,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setEditAction: (id) => dispatch(certActions.setEditAction(id)),
    fetchCert: () => dispatch(certActions.fetchCert()),
    removeCert: (id) => dispatch(certActions.removeCert(id)),
    fetchProfile: () => dispatch(profileActions.fetchProfile()),
    fetchLinkedCourses: (certId) =>
      dispatch(certActions.fetchLinkedCourses(certId)),
    unlinkCourse: (certLink) => dispatch(certActions.unlinkCourse(certLink)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CertificateListScreen);
