import React from "react";
import Button from "@material-ui/core/Button";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import CertificatePreview from "../../ui/certificatePreview/CertificatePreview";

const CertPreviewModal = (props) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("xs"));
  const fullWidth = true;
  const maxWidth = "sm";

  // ------------------------------

  const handleClose = (answer) => {
    props.onClose(answer);
  };

  // ------------------------------

  return (
    <div>
      <Dialog
        fullScreen={fullScreen}
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={true}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="alert-dialog-title">
          Certificate of Completion Preview
        </DialogTitle>

        <DialogContent>
          <div style={{
            flex: 1,
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center"
          }}>
            <div style={{ maxWidth: "315px" }}>
              <CertificatePreview
                course={props.course}
                profile={props.profile}
                header={props.header}
                footer={props.footer}
              />
            </div>
          </div>
        </DialogContent>

        <DialogActions>
          <Button
            onClick={handleClose.bind(this, "ok")}
            color="primary"
            style={{ color: "white", backgroundColor: "#3E8CAB" }}
          >
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default CertPreviewModal;
