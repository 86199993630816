import {
  SET_ATTENDANCE,
  UPDATE_ATTENDANCE,
  SET_ACTIVATE_ATTENDANCE,
  SET_ATTENDANCE_PASS,
  ADD_ATTENDANCE,
} from "../actions/attendance";
import Attendance from "../../model/Attendance";
import { LOGOUT } from "../actions/auth";
import { updateObject } from "../../globals/Common";

const initialState = {
  isActive: false,
  passcode: "",
  uricode: "",
  studentAttendance: [],
  audit: null, // {name, passcode}
};

const attendanceReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_ACTIVATE_ATTENDANCE:
      return updateObject(state, {
        isActive: action.isActive,
        passcode: action.passcode,
        uricode: action.uricode,
      });
    case SET_ATTENDANCE_PASS:
      return updateObject(state, {
        passcode: action.passcode,
      });
    case SET_ATTENDANCE:
      return updateObject(state, {
        studentAttendance: action.studentAttendance,
      });
    case UPDATE_ATTENDANCE:
      const updateIndex = state.studentAttendance.findIndex(
        (sa) => sa.id === action.attendance.id
      );

      if (updateIndex > -1) {
        const updatedStudentAttendanceList = [...state.studentAttendance];
        updatedStudentAttendanceList[updateIndex] = action.attendance;

        return updateObject(state, {
          studentAttendance: updatedStudentAttendanceList,
        });
      } else {
        return state;
      }
    case ADD_ATTENDANCE: {
      const addedIndex = state.studentAttendance.findIndex(
        (sa) => sa.id === action.attendance.id
      );

      const studentAttendanceList = [...state.studentAttendance];

      if (addedIndex === -1) {
        const concatRes = studentAttendanceList.concat(action.attendance);
        return updateObject(state, {
          studentAttendance: concatRes,
        });
      } else {
        return state;
      }
    }
    case LOGOUT:
      return initialState;
    default:
      return state;
  }
};

export default attendanceReducer;
