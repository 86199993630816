import React, { Fragment } from "react";
import styles from "./OrderRegItem.module.css";
import Toggle from "../../ui/toggle/Toggle";
import RefundInput from "../../ui/input/RefundInput";
import { formatDecimal, centToDollarNoDecimal } from "../../../globals/Common";
import NotInterestedIcon from "@material-ui/icons/NotInterested";
import NumberFormat from "react-number-format";

const OrderRegItem = (props) => {
  // ------------------------------

  const isRefundToggled = (list, sm, cId) => {
    const index = list.findIndex(
      (selStaff) =>
        selStaff.member.id === sm.id &&
        selStaff.member.courseId === sm.courseId &&
        selStaff.chargeId === cId
    );

    return index > -1;
  };

  // ------------------------------

  const calcRefundSubTotal = (sm, courseId, pricingItem, chargeId) => {
    let subTotal = 0;
    if (props.refund.length > 0) {
      for (const r of props.refund) {
        if (chargeId === r.chargeId) {
          subTotal += r.memberRefundObj
            .map((mfo) => {
              return mfo.member.id === sm.id &&
                mfo.member.courseId === courseId &&
                mfo.memberPricingItem.isEqual(pricingItem)
                ? mfo.memberRefundValue
                : 0;
            })
            .reduce((prev, next) => Number(prev) + Number(next));
        }
      }
    }

    return subTotal;
  };

  // ------------------------------

  const concatRegistrationOption = (dataPi) => {
    return (
      dataPi.pricingItem.casting +
      " for " +
      dataPi.pricingItem.audience.join(", ") +
      " " +
      dataPi.pricingItem.component
    );
  };

  // ------------------------------

  const installmentPriceInvalid = (price, amount, refundSubTotal) => {
    return price - amount - refundSubTotal < 0;
  };

  // ------------------------------

  const priceInvalid = (price, discount, amount, refundSubTotal) => {
    return amount > price - discount - refundSubTotal;
  };

  // ------------------------------

  const renderInstallmentRefundRow = (
    sm,
    installmentStatusArray,
    currencyShortName
  ) => {
    const renderedInstallmentStatus = [];

    for (const installmentStatus of installmentStatusArray) {
      if (installmentStatus.status === "success") {
        const installmentAmount = centToDollarNoDecimal(
          installmentStatus.amount
        );

        const refundAmountObj = props.selectedMemberRefund.find(
          (selStaff) =>
            selStaff.member.id === sm.id &&
            selStaff.member.courseId === props.courseId &&
            selStaff.memberPricingItem.isEqual(props.pricingItem.pricingItem) &&
            selStaff.chargeId === installmentStatus.chargeId
        );

        const refundSubTotal = calcRefundSubTotal(
          sm,
          props.courseId,
          props.pricingItem.pricingItem,
          installmentStatus.chargeId
        );

        const refundAmoutInvalid = installmentPriceInvalid(
          installmentAmount,
          refundAmountObj ? refundAmountObj.memberRefundValue : 0,
          refundSubTotal
        );

        renderedInstallmentStatus.push(
          <div className={styles.studentRow} key={installmentStatus.chargeId}>
            <div className={styles.studentColumn} style={{ columnSpan: 2 }}>
              <div className={styles.row} style={{ fontStyle: "italic" }}>
                <div>Payment</div>
              </div>
            </div>
            <div className={styles.priceColumn}>&nbsp;</div>
            <div className={styles.discountColumn}>&nbsp;</div>
            <div className={styles.paidColumn}>
              <NumberFormat
                value={installmentAmount}
                displayType={"text"}
                thousandSeparator={true}
                prefix={currencyShortName}
              />
            </div>
            <div className={styles.refundColumn}>
              {refundSubTotal > 0 ? (
                <NumberFormat
                  value={refundSubTotal}
                  displayType={"text"}
                  thousandSeparator={true}
                  prefix={currencyShortName}
                />
              ) : (
                <div>&nbsp;</div>
              )}
            </div>
            <div className={styles.unregColumn}>&nbsp;</div>
            <div className={styles.refundEnterAmount}>
              <RefundInput
                disabled={props.disabled}
                currency={props.currency}
                name="fundAmount"
                maxLength={10}
                haserror={refundAmoutInvalid}
                value={refundAmountObj ? refundAmountObj.memberRefundValue : 0}
                selected={isRefundToggled(
                  props.selectedMemberRefund,
                  sm,
                  installmentStatus.chargeId
                )}
                onToggleChange={props.onToggleRefundRow.bind(
                  this,
                  sm,
                  installmentStatus.chargeId
                )}
                onChange={(e) => {
                  props.updateRefundRowAmount(
                    sm,
                    e.target.value,
                    installmentStatus.chargeId
                  );
                }}
              />
            </div>
          </div>
        );
      }
    }

    return renderedInstallmentStatus;
  };

  // ------------------------------

  return (
    <div>
      {props.pricingItem.staffMembers.map((sm, index) => {
        const student = props.students.find(
          (s) =>
            s.courseId === props.courseId &&
            s.email === sm.email &&
            s.first === sm.first &&
            s.last === sm.last &&
            s.registrationOption === concatRegistrationOption(props.pricingItem)
        );

        const isUnregistered = student && student.isUnregistered;

        const isMultiCharge =
          props.studentOrder.installmentStatusArray.length > 0;

        const depositAmount = isMultiCharge
          ? centToDollarNoDecimal(props.studentOrder.totalAmount)
          : null;

        const refundAmountObj = props.selectedMemberRefund.find(
          (selStaff) =>
            selStaff.member.id === sm.id &&
            selStaff.member.courseId === props.courseId &&
            selStaff.memberPricingItem.isEqual(props.pricingItem.pricingItem) &&
            selStaff.chargeId === props.studentOrder.chargeId
        );

        const refundSubTotal = calcRefundSubTotal(
          sm,
          props.courseId,
          props.pricingItem.pricingItem,
          props.studentOrder.chargeId
        );

        const refundAmoutInvalid = isMultiCharge
          ? installmentPriceInvalid(
              depositAmount,
              refundAmountObj ? refundAmountObj.memberRefundValue : 0,
              refundSubTotal
            )
          : priceInvalid(
              props.pricingItem.cost(),
              sm.promoDiscount,
              refundAmountObj ? refundAmountObj.memberRefundValue : 0,
              refundSubTotal,
              props.studentOrder.totalAmount
            );

        return (
          <Fragment key={index}>
            <div className={styles.studentRow}>
              <div className={styles.studentColumn}>
                {index === 0 && <div className={styles.rowTitle}>Student</div>}
                <div className={styles.row}>
                  <div className={styles.last}>{sm.last}&nbsp;</div>
                  <div>{sm.first}</div>
                </div>
              </div>

              <div className={styles.priceColumn}>
                {index === 0 && <div className={styles.rowTitle}>Price</div>}
                <NumberFormat
                  value={props.pricingItem.cost()}
                  displayType={"text"}
                  thousandSeparator={true}
                  prefix={props.currency.shortName}
                />
              </div>

              <div className={styles.discountColumn}>
                {index === 0 && <div className={styles.rowTitle}>Promo</div>}
                {sm.promoDiscount > 0 ? (
                  <NumberFormat
                    value={formatDecimal(sm.promoDiscount)}
                    displayType={"text"}
                    thousandSeparator={true}
                    prefix={props.currency.shortName}
                  />
                ) : (
                  <div>&nbsp;</div>
                )}
              </div>

              <div className={styles.paidColumn}>
                {index === 0 && <div className={styles.rowTitle}>Paid</div>}

                {props.studentOrder && isMultiCharge ? (
                  <NumberFormat
                    value={depositAmount}
                    displayType={"text"}
                    thousandSeparator={true}
                    prefix={props.currency.shortName}
                  />
                ) : (
                  <NumberFormat
                    value={formatDecimal(
                      props.pricingItem.cost() - sm.promoDiscount
                    )}
                    displayType={"text"}
                    thousandSeparator={true}
                    prefix={props.currency.shortName}
                  />
                )}
              </div>

              <div className={styles.refundColumn}>
                {index === 0 && <div className={styles.rowTitle}>Refunds</div>}
                {refundSubTotal > 0 ? (
                  <NumberFormat
                    value={refundSubTotal}
                    displayType={"text"}
                    thousandSeparator={true}
                    prefix={props.currency.shortName}
                  />
                ) : (
                  <div>&nbsp;</div>
                )}
              </div>

              <div className={styles.unregColumn}>
                {index === 0 && <div>&nbsp;</div>}
                {isUnregistered ? (
                  <div className={styles.unregisteredStatus}>
                    <NotInterestedIcon fontSize="inherit" color="secondary" />{" "}
                    Unregistered
                  </div>
                ) : (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <Toggle
                      disabled={props.disabled}
                      title="Unregister"
                      selected={
                        props.selectedMember.findIndex(
                          (selStaff) =>
                            selStaff.id === sm.id &&
                            sm.courseId === selStaff.courseId
                        ) > -1
                      }
                      onChange={props.onToggleUnregisterRow.bind(this, sm)}
                    />
                  </div>
                )}
              </div>

              <div className={styles.refundEnterAmount}>
                {index === 0 && <div>&nbsp;</div>}
                <RefundInput
                  name="fundAmount"
                  disabled={props.disabled}
                  currency={props.currency}
                  maxLength={10}
                  haserror={refundAmoutInvalid}
                  /*****/
                  selected={isRefundToggled(
                    props.selectedMemberRefund,
                    sm,
                    props.studentOrder.chargeId
                  )}
                  /*****/
                  value={
                    refundAmountObj ? refundAmountObj.memberRefundValue : 0
                  }
                  /*****/
                  onChange={(e) => {
                    props.updateRefundRowAmount(sm, e.target.value);
                  }}
                  /*****/
                  onToggleChange={props.onToggleRefundRow.bind(
                    this,
                    sm,
                    props.studentOrder.chargeId
                  )}
                  /*****/
                />
              </div>
            </div>

            {props.studentOrder &&
              props.studentOrder.installmentStatusArray.length > 0 &&
              renderInstallmentRefundRow(
                sm,
                props.studentOrder.installmentStatusArray,
                props.currency.shortName
              )}
          </Fragment>
        );
      })}
    </div>
  );
};

export default OrderRegItem;
