import React from "react";
import SearchIcon from "@material-ui/icons/Search";
import CloseIcon from "@material-ui/icons/Close";
import InputAdornment from "@material-ui/core/InputAdornment";
import MatInputOutline from "../../../components/ui/input/MatInputOutline";

const SearchInput = (props) => {
  return (
    <MatInputOutline
      name="Search"
      nomargin="true"
      nohelper="true"
      search="true"
      title={props.title}
      placeholder={props.placeholder}
      value={props.searchTerm}
      onChange={props.onChange}
      maxLength={1000}
      readOnly={false}
      startAdornment={
        <InputAdornment position="start">
          <SearchIcon />
        </InputAdornment>
      }
      endAdornment={
        props.searchTerm && props.searchTerm.length > 0 ? (
          <InputAdornment
            position="end"
            style={{ cursor: "pointer" }}
            onClick={props.clear}
          >
            <CloseIcon />
          </InputAdornment>
        ) : null
      }
    />
  );
};

export default SearchInput;
