import React from "react";
import styles from "./MobileSetting.module.css";
import { useTranslation } from "react-i18next";
import InfoIcon from "@material-ui/icons/Info";
import * as lodashCloneDeep from "lodash.clonedeep";
import MatInputOutline from "../../../components/ui/input/MatInputOutline";
import InlinePop from "../../../components/ui/popover/InlinePop";
import Avatar from "../../../components/ui/image/Avatar";
import { hasError } from "../../../globals/Validations";
import { validateField } from "../../../globals/Validations";

const UserForm = (props) => {
  const { t } = useTranslation();

  const validate = async (field, validatorKey) => {
    if (field === null) {
      await props.updateHasErrors(props.formErrors);
    } else {
      const errors = validateField(
        props.profile,
        props.formErrors,
        field,
        validatorKey
      );

      await props.updateFormErrors(errors);
    }
  };

  // ------------------------------

  const inputChangeHandler = (e) => {
    const copyProfile = lodashCloneDeep(props.profile);
    copyProfile[e.target.name] = e.target.value;
    props.update(copyProfile);
  };

  // ------------------------------

  return (
    <form className={styles.form}>
      <div className={styles.pricingContainer}>
        <div className={styles.rowIt}>
          <div className={styles.icon}>
            <InfoIcon />
          </div>
          <div>{t("settings_user_blurb")}</div>
        </div>
      </div>

      <div className={styles.avatarContainer}>
        <Avatar image={props.avatarPicture} />
      </div>

      <InlinePop help={t("settings_user_blurb_tip")} />

      <MatInputOutline
        notopmargin="true"
        title={t("sign_up_dentist_user_name")}
        name="zubuName"
        value={props.profile.zubuName}
        onChange={inputChangeHandler}
        onBlur={validate.bind(this, "zubuName", "plainRequired")}
        maxLength={1000}
        error={hasError(props.formErrors.zubuName)}
        helpertext={props.formErrors.zubuName}
        readOnly={false}
        required
      />

      <MatInputOutline
        notopmargin="true"
        title={t("registration_course_location")}
        name="zubuLocation"
        value={props.profile.zubuLocation}
        onChange={inputChangeHandler}
        maxLength={1000}
        readOnly={false}
      />
    </form>
  );
};

export default UserForm;
