import { handleErrorResponse } from "../../globals/Error";
import firebase from "firebase/app";
import { dbName, apiKey } from "../../globals/ApiKeys";
import "firebase/auth";
import "firebase/firebase-auth";
import * as logger from "../../globals/Logger";
import * as profileActions from "../../store/actions/profile";
import * as settingActions from "../../store/actions/setting";
//import * as referralActions from "../../store/actions/referral";
import i18n from "../../i18n";

import { startConversationListener, stopConversationListener } from "./inbox";
export const AUTHENTICATE = "AUTHENTICATE";
export const LOGOUT = "LOGOUT";
export const SET_USER = "SET_USER";
export const SET_CONNECT_ID = "SET_CONNECT_ID";
export const SET_MOBILE_PICTURE = "SET_MOBILE_PICTURE";

let timer;

// ------------------------------

const getIpInfo = async () => {
  try {
    const response = await fetch(`https://ipapi.co/json/`);

    if (!response.ok) {
      handleErrorResponse(await response.json());
    }

    return await response.json();
  } catch (error) {
    logger.error(error);
    return "getIpInfo failed";
  }
};

// ------------------------------

export const getCountry = () => {
  return async (dispatch) => {
    try {
      const response = await fetch(`https://ipapi.co/json/`);

      if (!response.ok) {
        handleErrorResponse(await response.json());
      }

      const resData = await response.json();

      if (resData && resData.country) {
        return resData.country;
      }
    } catch (error) {
      // do nothing
    }

    return "";
  };
};

// ------------------------------

const getExpireTime = (expiresIn) => {
  return parseInt(expiresIn * 1000);
};

// ------------------------------

const getExpiredDate = (expireTime) => {
  return new Date(new Date().getTime() + expireTime);
};

// ------------------------------

export const login = (email, password, staySignedIn) => {
  return async (dispatch) => {
    const response = await fetch(
      `https://identitytoolkit.googleapis.com/v1/accounts:signInWithPassword?key=${apiKey}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "applications/json",
        },
        body: JSON.stringify({
          email: email,
          password: password,
          returnSecureToken: true,
        }),
      }
    );

    if (!response.ok) {
      handleErrorResponse(await response.json());
    }

    const resData = await response.json();

    const responseUserAccount = await fetch(
      `https://identitytoolkit.googleapis.com/v1/accounts:lookup?key=${apiKey}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "applications/json",
        },
        body: JSON.stringify({
          idToken: resData.idToken,
        }),
      }
    );

    if (!responseUserAccount.ok) {
      handleErrorResponse(await responseUserAccount.json());
    }

    const userAccountResData = await responseUserAccount.json();

    if (
      userAccountResData.users &&
      userAccountResData.users[0].emailVerified === true
    ) {
      let isMobileUser = false;
      const connectId = await getConnectId(resData.idToken, resData.localId);

      if (connectId === null) {
        isMobileUser = true;
      }

      await firebase
        .auth()
        .signInWithEmailAndPassword(email, password)
        .then(function (user) {
          logger.event("login", { email: user.email, uid: user.uid });
        })
        .catch(function (error) {
          logger.event("login failure", { email: email });
          logger.error(error);
        });

      const expireTime = getExpireTime(resData.expiresIn);
      const expirationDate = getExpiredDate(expireTime);

      dispatch(
        authenticate(
          resData.idToken,
          resData.localId,
          expireTime,
          connectId,
          email,
          resData.refreshToken,
          isMobileUser,
          staySignedIn
        )
      );

      saveDataToStorage(
        resData.idToken,
        resData.localId,
        expirationDate,
        connectId,
        email,
        resData.refreshToken,
        isMobileUser,
        staySignedIn
      );

      dispatch(startListener(isMobileUser, resData.localId));
    } else {
      try {
        await dispatch(accountVerification(resData.idToken));
      } catch (error) {
        logger.error(error);
      }
      throw new Error(i18n.t("error_account_unverified"));
    }
  };
};

// ------------------------------

export const reauthenticate = (email, password) => {
  return async (dispatch) => {
    await firebase
      .auth()
      .signInWithEmailAndPassword(email, password)
      .then(async (user) => {
        await dispatch({
          type: SET_USER,
          user: user,
        });
      })
      .catch(function (error) {
        logger.error(error);
        throw new Error("Invalid email and / or password.");
      });
  };
};

// ------------------------------

export const resetPassword = (newPassword) => {
  return async (dispatch, getState) => {
    const user = firebase.auth().currentUser;
    await user.updatePassword(newPassword);
  };
};

// ------------------------------

export const setConnectId = () => {
  return async (dispatch, getState) => {
    const userId = getState().auth.userId;
    const token = getState().auth.token;
    const connectId = await getConnectId(token, userId);
    dispatch({
      type: SET_CONNECT_ID,
      connectId: connectId,
    });
  };
};

// ------------------------------

export const getConnectId = async (token, userId) => {
  const response = await fetch(
    `https://firestore.googleapis.com/v1/projects/${dbName}/databases/(default)/documents/stripe_customers/${userId}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "applications/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );

  if (!response.ok) {
    handleErrorResponse(await response.json());
  }

  const resData = await response.json();
  if (resData && resData.fields) {
    return resData.fields.connect_id
      ? resData.fields.connect_id.stringValue
      : null;
  } else {
    return null;
  }
};

// ------------------------------

const responseHandler = async (response) => {
  if (!response.ok) {
    handleErrorResponse(await response.json());
  }

  return await response.json();
};

// ------------------------------

export const signup = (newUser, recaptchaToken) => {
  return async (dispatch, getState) => {
    const resCheckCodeUsage = await fetch(
      `https://us-central1-${dbName}.cloudfunctions.net/validateSecureCodeUsage`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          data: {
            code: newUser.prelaunch_code,
            recaptchaToken: recaptchaToken,
          },
        }),
      }
    );

    if (!resCheckCodeUsage.ok) {
      handleErrorResponse(await resCheckCodeUsage.json());
    }

    const response = await fetch(
      `https://identitytoolkit.googleapis.com/v1/accounts:signUp?key=${apiKey}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "applications/json",
        },
        body: JSON.stringify({
          email: newUser.email,
          password: newUser.password,
          returnSecureToken: true,
        }),
      }
    );

    const resData = await responseHandler(response);

    // ----------

    const signupResponse = await fetch(
      `https://us-central1-${dbName}.cloudfunctions.net/signupAdmin`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${resData.idToken}`,
        },
        body: JSON.stringify({
          data: {
            email: newUser.email,
            password: newUser.password,
            country: newUser.country,
            province: newUser.province,
            providerName: newUser.providerName,
            contact_email: newUser.contact_email,
            code: newUser.prelaunch_code,
          },
        }),
      }
    );

    await responseHandler(signupResponse);

    await dispatch(accountVerification(resData.idToken));
  };
};

// ------------------------------

export const signupMobile = (newUser, recaptchaToken) => {
  return async (dispatch, getState) => {
    const resCheckCodeUsage = await fetch(
      `https://us-central1-${dbName}.cloudfunctions.net/validateSecureCodeUsage`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          data: {
            code: "",
            recaptchaToken: recaptchaToken,
          },
        }),
      }
    );

    if (!resCheckCodeUsage.ok) {
      handleErrorResponse(await resCheckCodeUsage.json());
    }

    const response = await fetch(
      `https://identitytoolkit.googleapis.com/v1/accounts:signUp?key=${apiKey}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "applications/json",
        },
        body: JSON.stringify({
          email: newUser.profile ? newUser.profile.email : "",
          password: newUser.password,
          returnSecureToken: true,
        }),
      }
    );

    const resData = await responseHandler(response);

    await Promise.all([
      dispatch(
        profileActions.addMobileUserProfile(
          resData.localId,
          resData.idToken,
          newUser
        )
      ),
      dispatch(
        settingActions.setMobileFavCategory(
          resData.localId,
          resData.idToken,
          [],
          ["English", "French"]
        )
      ),
      dispatch(addToUserDirectory(resData.localId, resData.idToken, newUser)),
    ]);

    try {
      await dispatch(accountVerification(resData.idToken));
    } catch (error) {
      logger.error(error);
    }
  };
};

// ------------------------------

const addToUserDirectory = (userId, token, profile) => {
  return async (dispatch) => {
    const dateAdded = new Date();
    dateAdded.setHours(0, 0, 0, 0);

    const response = await fetch(
      `https://${dbName}.firebaseio.com/users/${userId}.json?auth=${token}`,
      {
        method: "PUT",
        headers: {
          "Content-Type": "applications/json",
        },
        body: JSON.stringify({
          displayId: userId, // backward compatibility all new users correct old ones stay with email
          first:
            profile.first && profile.last
              ? profile.first.trim().toLowerCase() +
                " " +
                profile.last.trim().toLowerCase()
              : "",
          city: profile.city ? profile.city : "",
          date: dateAdded.getTime(),
        }),
      }
    );

    if (!response.ok) {
      handleErrorResponse(await response.json());
    }
  };
};

// ------------------------------

export const accountVerification = (token) => {
  return async (dispatch) => {
    const response = await fetch(
      `https://identitytoolkit.googleapis.com/v1/accounts:sendOobCode?key=${apiKey}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "applications/json",
        },
        body: JSON.stringify({
          requestType: "VERIFY_EMAIL",
          idToken: token,
        }),
      }
    );

    if (!response.ok) {
      handleErrorResponse(await response.json());
    }
  };
};

// ------------------------------

export const forgotPassword = (email) => {
  return async (dispatch) => {
    try {
      const auth = firebase.auth();
      await auth.sendPasswordResetEmail(email);
    } catch (error) {
      logger.error(error);
    }
  };
};

// ------------------------------

export const tryLogin = () => {
  return async (dispatch) => {
    const userData = await localStorage.getItem("userData");
    if (!userData) {
      return;
    }

    const transformedData = JSON.parse(userData);

    let {
      token,
      userId,
      expirationDate,
      connectId,
      email,
      refreshToken,
      isMobileUser,
      staySignedIn,
    } = transformedData;

    const theExpirationDate = new Date(expirationDate);

    if (!refreshToken || !token || !userId) {
      return;
    }

    const expirationTime = theExpirationDate.getTime() - new Date().getTime();

    if (!connectId) {
      connectId = await getConnectId(token, userId);
    }

    await dispatch(
      authenticate(
        token,
        userId,
        expirationTime,
        connectId,
        email,
        refreshToken,
        isMobileUser,
        staySignedIn
      )
    );

    dispatch(startListener(isMobileUser, userId));
  };
};

// ------------------------------

export const authenticate = (
  token,
  userId,
  expirationTime,
  connectId,
  email,
  refreshToken,
  isMobileUser,
  staySignedIn
) => {
  return async (dispatch) => {
    if (expirationTime > 0) {
      dispatch(setRefreshToken(expirationTime, refreshToken));

      await dispatch({
        type: AUTHENTICATE,
        token: token,
        userId: userId,
        connectId: connectId,
        email: email,
        refreshToken: refreshToken,
        isMobileUser: isMobileUser,
        staySignedIn: staySignedIn,
      });
    } else {
      await dispatch(callRefreshToken(refreshToken));
    }
  };
};

// ------------------------------

export const setRefreshToken = (expirationTime, refreshToken) => {
  return (dispatch) => {
    clearLogoutTimer(timer);

    timer = setTimeout(() => {
      dispatch(callRefreshToken(refreshToken));
    }, expirationTime);
  };
};

// ------------------------------

export const callRefreshToken = (refreshToken) => {
  return async (dispatch) => {
    const response = await fetch(
      `https://securetoken.googleapis.com/v1/token?key=${apiKey}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "applications/json",
        },
        body: JSON.stringify({
          grant_type: "refresh_token",
          refresh_token: refreshToken,
        }),
      }
    );

    if (!response.ok) {
      handleErrorResponse(await response.json());
    }

    const resData = await response.json();

    await dispatch(
      updateUserSession(
        resData.id_token,
        resData.user_id,
        resData.expires_in,
        resData.refresh_token
      )
    );
  };
};

// ------------------------------

export const fetchUserFromDirectiory = () => {
  return async (dispatch, getState) => {
    const userId = getState().auth.userId;
    const token = getState().auth.token;

    const response = await fetch(
      `https://${dbName}.firebaseio.com/users/${userId}.json?auth=${token}`
    );

    if (!response.ok) {
      handleErrorResponse(await response.json());
    }

    const resData = await response.json();

    if (resData) {
      dispatch({
        type: SET_MOBILE_PICTURE,
        picture: resData.pic,
        displayId: resData.displayId,
      });
    } else {
      // throw and error  must have a user
    }
  };
};

// ------------------------------

const saveDataToStorage = (
  token,
  userId,
  expirationDate,
  connectId,
  email,
  refreshToken,
  isMobileUser,
  staySignedIn
) => {
  localStorage.setItem(
    "userData",
    JSON.stringify({
      token: token,
      userId: userId,
      expirationDate: expirationDate.toISOString(),
      connectId: connectId,
      email: email,
      refreshToken: refreshToken,
      isMobileUser: isMobileUser,
      staySignedIn: staySignedIn,
    })
  );
};

// ------------------------------

export const logout = () => {
  return (dispatch) => {
    firebase
      .auth()
      .signOut()
      .then(function (user) {})
      .catch(function (error) {});

    clearLogoutTimer();
    stopConversationListener();
    localStorage.removeItem("userData");
    localStorage.removeItem("userDataPassless");
    dispatch({ type: LOGOUT });
  };
};

// ------------------------------

const clearLogoutTimer = () => {
  if (timer) {
    clearTimeout(timer);
  }
};

// ------------------------------

const updateUserSession = (token, userId, expiresIn, refreshToken) => {
  return async (dispatch) => {
    const userData = await localStorage.getItem("userData");
    if (!userData) {
      return;
    }

    const transformedData = JSON.parse(userData);
    let { connectId, email, isMobileUser, staySignedIn } = transformedData;
    const newExpireTime = getExpireTime(expiresIn);
    const newExpirationDate = getExpiredDate(newExpireTime);

    // fix if first connection
    if (!connectId) {
      connectId = await getConnectId(token, userId);
    }

    dispatch(
      authenticate(
        token,
        userId,
        newExpireTime,
        connectId,
        email,
        refreshToken,
        isMobileUser,
        staySignedIn
      )
    );

    saveDataToStorage(
      token,
      userId,
      newExpirationDate,
      connectId,
      email,
      refreshToken,
      isMobileUser,
      staySignedIn
    );
  };
};

// ------------------------------

const startListener = (isMobileUser, uId) => {
  return (dispatch) => {
    dispatch(startConversationListener(isMobileUser, uId));
  };
};
