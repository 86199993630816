import React, { useState } from "react";
import styles from "./HandoutModal.module.css";
import { useTranslation } from "react-i18next";
import Button from "@material-ui/core/Button";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import { useTheme } from "@material-ui/core/styles";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import Handout from "../../../components/ui/handout/Handout";

const HandoutModal = (props) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const fullScreen = false;
  const fullWidth = true;
  const maxWidth = "sm";
  const title = props.readOnly
    ? t("registration_handout_title")
    : t("registration_edit_handout_title");

  const [handout, setHandout] = useState(props.selected_handout);

  const [handoutDelivery, setHandoutDelivery] = useState(
    props.selected_handout_delivery
  );

  // ------------------------------

  const handleClose = (handout, handoutDelivery, courseId) => {
    props.onClose(handout, handoutDelivery, courseId);
  };

  // ------------------------------

  const handoutChangeHandler = (handout, handoutDelivery) => {
    setHandout(handout);
    setHandoutDelivery(handoutDelivery);
  };

  // ------------------------------

  return (
    <div>
      <Dialog
        fullScreen={fullScreen}
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={props.open}
        onClose={handleClose.bind(this, null, null)}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
        <div className={styles.container}>
          <InfoOutlinedIcon />
          <div style={{ marginLeft: "10px" }}>
            {props.readOnly ? (
              <div> {t("registration_handout_info")}</div>
            ) : (
              <div>
                {t("registration_handout_blurb_1")}&nbsp;
                <VisibilityOffIcon fontSize="inherit" />
                &nbsp;{t("registration_handout_blurb_2")}&nbsp;
              </div>
            )}
          </div>
        </div>

        <DialogContent>
          <div style={{ height: "10px" }}>&nbsp;</div>
          <Handout
            readOnly={props.readOnly}
            modal
            handout={handout}
            handoutDelivery={handoutDelivery}
            onChange={handoutChangeHandler}
          />
        </DialogContent>

        {props.readOnly ? (
          <DialogActions>
            <Button
              onClick={handleClose.bind(this, null, null, null)}
              color="primary"
            >
              {t("g_ok")}
            </Button>
          </DialogActions>
        ) : (
          <DialogActions>
            <Button
              onClick={handleClose.bind(this, null, null, null)}
              color="primary"
            >
              {t("g_cancel")}
            </Button>
            <Button
              autoFocus
              onClick={handleClose.bind(
                this,
                handout,
                handoutDelivery,
                props.course.id
              )}
              color="primary"
              style={{ color: "white", backgroundColor: "#3E8CAB" }}
            >
              {t("g_save")}
            </Button>
          </DialogActions>
        )}
      </Dialog>
    </div>
  );
};

export default HandoutModal;
