import React from "react";
import styles from "./AttendanceItem.module.css";
import { useTranslation } from "react-i18next";
import Swipeout from "rc-swipeout";
import "rc-swipeout/assets/index.css";
import { isMobile } from "react-device-detect";
import { translate } from "../../../globals/Common";

const AttendanceItem = (props) => {
  const { t } = useTranslation();
  const swipeStyle = { color: "white", width: "90px", borderRadius: "10px" };

  return (
    <Swipeout
      disabled={!isMobile}
      autoClose
      right={[
        props.checkedIn
          ? {
              text: t("g_undo"),
              onPress: () => {
                props.onCheckInCheckOut();
              },
              style: {
                backgroundColor: "black",
                ...swipeStyle,
              },
            }
          : {
              text: t("attendance_check_in"),
              onPress: () => {
                props.onCheckInCheckOut();
              },
              style: {
                backgroundColor: "green",
                ...swipeStyle,
              },
            },
      ]}
    >
      <div
        className={styles.itemContainer}
        style={props.checkedIn ? { backgroundColor: "#90ee90" } : null}
      >
        <div className={styles.textContainer}>
          <div className={styles.nameText}>
            {props.prefix ? <div>{props.prefix}&nbsp;</div> : ""}
            {props.first}&nbsp;{props.last}
          </div>
          <div className={styles.otherText}>
            {translate(t, props.occupation)} | {t("attendance_license")}:{" "}
            {props.license} | {t("attendance_order")}: {props.orderRefNumber}
            {props.registeredByName &&
              !props.registeredByName.endsWith(`(${props.license})`) && (
                <span>
                  &nbsp;|&nbsp;{t("attendance_reg_by")}:{" "}
                  {props.registeredByName}
                </span>
              )}
          </div>
        </div>

        {!isMobile && (
          <div
            style={props.checkedIn ? { backgroundColor: "black" } : null}
            className={styles.button}
            onClick={() => {
              props.onCheckInCheckOut();
            }}
          >
            {props.checkedIn ? t("g_undo") : t("attendance_check_in")}
          </div>
        )}
      </div>
    </Swipeout>
  );
};

export default AttendanceItem;
