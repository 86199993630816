import React, { useState, Fragment } from "react";
import Button from "@material-ui/core/Button";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import ReportProblemOutlinedIcon from "@material-ui/icons/ReportProblemOutlined";
import MatCheckbox from "../../ui/checkbox/MatCheckbox";
import AccountBalanceIcon from "@material-ui/icons/AccountBalance";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import AlertSuccess from "../../../components/ui/snackbar/AlertSuccess";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { roundAmount } from "../../../globals/Common";
import NumberFormat from "react-number-format";

const CancelCourseModal = (props) => {
  const theme = useTheme();
  const [alert, setAlert] = useState(null);
  const fullScreen = useMediaQuery(theme.breakpoints.down("xs"));
  const fullWidth = true;
  const maxWidth = "sm";
  const insuficientFunds =
    props.cancelCourseRefundAmount > props.stripeBalance.amount;

  // ------------------------------

  const handleClose = (confirm) => {
    if (confirm) {
      props.onClose(confirm);
    } else {
      props.onClose();
    }
  };

  // ------------------------------

  return (
    <div>
      {alert !== null && (
        <AlertSuccess
          message={alert}
          open={alert !== null}
          onClose={() => {
            setAlert(null);
          }}
        />
      )}

      <Dialog
        fullScreen={fullScreen}
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={true}
        onClose={handleClose.bind(this, null, null)}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="alert-dialog-title">
          Cancel Course Confirmation
        </DialogTitle>

        <div
          style={{
            width: "100%",
            textAlign: "justify",
            padding: "15px",
            display: "flex",
            justifyContent: "center",
            backgroundColor: "#ccc",
            fontSize: 14,
          }}
        >
          <ReportProblemOutlinedIcon />
          <div style={{ marginLeft: "10px" }}>
            Cancelling an active course will remove it from the ZubU mobile app.
            All current registered students will be unregistered, issued a full
            refund and notified of the cancellation. Refunds will be subtracted
            from you account balance.
            {props.blockFromMultiPay && (
              <div style={{ paddingTop: "10px" }}>
                * For courses that accept payment instalments you are required
                to unregister all students, issue refunds and cancel future
                payments manually before cancelling the course.
              </div>
            )}
          </div>
        </div>

        <DialogContent>
          <div
            style={{
              marginTop: "10px",
              marginBottom: "20px",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                paddingBottom: "20px",
                justifyContent: "center",
              }}
            >
              <AccountBalanceIcon />
              &nbsp;Account Balance:&nbsp;
              <b>
                <NumberFormat
                  value={props.stripeBalance.amount}
                  displayType={"text"}
                  thousandSeparator={true}
                  prefix={"$"}
                  suffix={" " + props.stripeBalance.currency.toUpperCase()}
                />
              </b>
            </div>

            <div
              style={{
                border: "1px dashed gray",
                padding: "10px",
                width: "80%",
                margin: "auto",
                borderRadius: "5px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <b>{props.courseTitle}</b>
              </div>

              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                Registered Students:&nbsp;<b>{props.registered}</b>
              </div>

              {props.blockFromMultiPay ? (
                <div></div>
              ) : (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  Refund Total:&nbsp;
                  <b>
                    <NumberFormat
                      value={props.cancelCourseRefundAmount}
                      displayType={"text"}
                      thousandSeparator={true}
                      prefix={"$"}
                      suffix={" " + props.stripeBalance.currency.toUpperCase()}
                    />
                  </b>
                </div>
              )}
            </div>
          </div>

          <div
            style={{
              width: "80%",
              margin: "auto",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                Student Order Reference Numbers{" "}
              </div>
              <div>
                <Tooltip
                  title="Copy to clipboard"
                  aria-label="Copy to clipboard"
                >
                  <CopyToClipboard
                    text={props.cancelCourseAffectedOrders.join(", ")}
                    onCopy={() => {
                      setAlert("Copied to clipboard");
                    }}
                  >
                    <IconButton>
                      <FileCopyIcon />
                    </IconButton>
                  </CopyToClipboard>
                </Tooltip>
              </div>
            </div>
            <div
              style={{
                marginTop: "0px",
                marginBottom: "10px",
                padding: "10px",
                border: "1px dashed gray",
                borderRadius: "5px",
                minHeight: "60px",
              }}
            >
              {props.cancelCourseAffectedOrders.join(", ")}
            </div>
          </div>

          {props.blockFromMultiPay ? (
            <div></div>
          ) : (
            insuficientFunds && (
              <div
                style={{
                  paddingTop: "10px",
                  paddingBottom: "20px",
                  color: "red",
                  textAlign: "justify",
                }}
              >
                Insufficient funds. To cancel your course please add required
                funds of{" "}
                <b style={{ textDecoration: "underline" }}>
                  <NumberFormat
                    value={roundAmount(
                      props.stripeBalance.amount -
                        props.cancelCourseRefundAmount
                    )}
                    displayType={"text"}
                    thousandSeparator={true}
                    prefix={"$"}
                    suffix={" " + props.stripeBalance.currency.toUpperCase()}
                  />
                </b>{" "}
                to your account balance from Account Balance / Add Funds.
              </div>
            )
          )}

          {props.blockFromMultiPay ? (
            <div></div>
          ) : (
            !insuficientFunds && (
              <div
                style={{
                  paddingLeft: "2px",
                  paddingTop: "15px",
                  width: "80%",
                  margin: "auto",
                }}
              >
                <MatCheckbox
                  plain="true"
                  label="I wish to proceed and cancel this course."
                  checked={props.acceptCancelTerms}
                  onChange={props.onAcceptCancelTerms}
                  name="acceptTerms"
                />
              </div>
            )
          )}
        </DialogContent>
        <DialogActions>
          {props.blockFromMultiPay ? (
            <Button
              autoFocus
              onClick={handleClose.bind(this, null)}
              color="primary"
            >
              Close
            </Button>
          ) : (
            <Fragment>
              <Button
                autoFocus
                onClick={handleClose.bind(this, null)}
                color="primary"
              >
                Don't Cancel
              </Button>
              <Button
                autoFocus
                onClick={handleClose.bind(this, true)}
                color="primary"
                disabled={!props.acceptCancelTerms}
              >
                Cancel Course
              </Button>
            </Fragment>
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default CancelCourseModal;
