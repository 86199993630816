import {
  SET_REVIEWS,
  SET_RATING,
  UPDATE_REPLY,
  CLEAR_REVIEW_RATING,
} from "../actions/reviews";
import Review from "../../model/Review";
import { updateObject } from "../../globals/Common";
import { LOGOUT } from "../actions/auth";

const initialState = {
  reviews: [],
  rating: null,
  hasMore: true,
};

const reviewsReducer = (state = initialState, action) => {
  switch (action.type) {
    case CLEAR_REVIEW_RATING:
      return initialState;
    case SET_RATING:
      return { ...state, rating: action.rating };
    case SET_REVIEWS:
      return { ...state, reviews: action.reviews, hasMore: action.hasMore };
    case UPDATE_REPLY:
      const pIndex = state.reviews.findIndex((p) => p.id === action.review.id);

      const updateReview = new Review(
        action.review.id,
        action.review.provider_id,
        action.review.course_title,
        action.review.user_id,
        action.review.first,
        action.review.pic,
        action.review.rating,
        action.review.comment,
        action.review.date,
        action.reply,
        action.reply_date
      );

      const updatedReviews = [...state.reviews];
      updatedReviews[pIndex] = updateReview;
      return updateObject(state, { reviews: updatedReviews });
    case LOGOUT:
      return initialState;
    default:
      return state;
  }
};

export default reviewsReducer;
