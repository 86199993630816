import React from "react";
import { useTranslation } from "react-i18next";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import SchoolIcon from "@material-ui/icons/School";
import PaymentIcon from "@material-ui/icons/Payment";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import ZubuText from "../../../assets/images/inApp/zubuText.png";
import AccountBalanceIcon from "@material-ui/icons/AccountBalance";
import AssignmentIndOutlinedIcon from "@material-ui/icons/AssignmentIndOutlined";
import ShoppingBasketOutlinedIcon from "@material-ui/icons/ShoppingBasketOutlined";
import AssignmentTurnedInOutlinedIcon from "@material-ui/icons/AssignmentTurnedInOutlined";
import RedeemIcon from "@material-ui/icons/Redeem";
import SettingsIcon from "@material-ui/icons/Settings";
import ListAltIcon from "@material-ui/icons/ListAlt";
import MenuOpenIcon from "@material-ui/icons/MenuOpen";
import AppsIcon from "@material-ui/icons/Apps";
import { makeStyles } from "@material-ui/core/styles";
import EqualizerIcon from "@material-ui/icons/Equalizer";
import RateReviewOutlinedIcon from "@material-ui/icons/RateReviewOutlined";
import LiveTvIcon from "@material-ui/icons/LiveTv";
import MatBadge from "../../ui/badge/MatBadge";
import { appVersion } from "../../../globals/ApiKeys";
import Tooltip from "@material-ui/core/Tooltip";
import SearchIcon from "@material-ui/icons/Search";
import Badge from "@material-ui/core/Badge";
import SupervisorAccountIcon from "@material-ui/icons/SupervisorAccount";
import CardMembershipIcon from '@material-ui/icons/CardMembership';
import InsertChartOutlinedRoundedIcon from '@material-ui/icons/InsertChartOutlinedRounded';

const useStyles = makeStyles({
  paper: {
    background: "#ECECEE",
    border: 0,
    boxShadow: "0px 0px 15px gray",
  },
  active: {
    backgroundColor: "lightGray",
    color: "black",
    "&:hover": {
      backgroundColor: "rgb(198, 206, 212)",
    },
  },
  menuOpenButton: {
    cursor: "pointer",
    color: "#437aa1",
    paddingLeft: "13px",
    paddingTop: "12px",
  },
  zubuLogoContainer: {
    display: "flex",
    alignItems: "center",
    marginLeft: "35px",
    fontWeight: "bold",
    fontSize: "20px",
    cursor: "pointer",
  },
  zubUProviderText: {
    fontStyle: "italic",
    position: "fixed",
    fontSize: "9px",
    color: "gray",
    left: "120px",
    top: "61px",
  },
  menuIcon: { color: "#437aa1" },
  sectionTitle: {
    marginLeft: "12px",
    color: "gray",
    fontWeight: "bold",
    fontSize: "12px",
    padding: "5px",
  },
});

const MainDrawer = (props) => {
  const { t } = useTranslation();
  const styles = useStyles();
  const dense = true;

  // ------------------------------

  const isActive = (path) => {
    return props.history.location.pathname === path ? true : false;
  };

  // ------------------------------

  const toggleDrawer = (open, navUri) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    if (props.isMobile) {
      props.setOpen(open);
    }

    if (navUri) {
      props.history.push(navUri);
    }
  };

  // ------------------------------

  const providerMenuItems = () => (
    <div role="presentation">
      <div
        className={styles.menuOpenButton}
        onClick={() => {
          props.setOpen(false);
        }}
      >
        <MenuOpenIcon style={{ fontSize: "30px" }} />
      </div>

      <div
        className={styles.zubuLogoContainer}
        onClick={toggleDrawer(false, "/courses")}
      >
        <Tooltip title={appVersion}>
          <img src={ZubuText} alt="ZubU" style={{ width: "80px" }} />
        </Tooltip>
        <div className={styles.zubUProviderText}>Provider</div>
      </div>

      {props.isMobile && (
        <List>
          <ListItem
            dense={dense}
            button
            onClick={toggleDrawer(false, "/courses")}
            onKeyDown={toggleDrawer(false, "/courses")}
            className={isActive("/courses") ? styles.active : null}
          >
            <ListItemIcon>
              <SchoolIcon className={styles.menuIcon} />
            </ListItemIcon>
            <ListItemText primary="Courses" />
          </ListItem>

          {props.connectAccountActive && (
            <ListItem
              dense={dense}
              button
              onClick={toggleDrawer(false, "/account")}
              onKeyDown={toggleDrawer(false, "/account")}
              className={isActive("/account") ? styles.active : null}
            >
              <ListItemIcon>
                <MatBadge count={props.connectAccountWarning ? 1 : 0}>
                  <AccountBalanceIcon className={styles.menuIcon} />
                </MatBadge>
              </ListItemIcon>
              <ListItemText primary="Account Balance" />
            </ListItem>
          )}

          <ListItem
            dense={dense}
            button
            onClick={toggleDrawer(false, "/ganalytics")}
            onKeyDown={toggleDrawer(false, "/ganalytics")}
            className={isActive("/ganalytics") ? styles.active : null}
          >
            <ListItemIcon>
              <EqualizerIcon className={styles.menuIcon} />
            </ListItemIcon>
            <ListItemText primary="Analytics" />
          </ListItem>

          <ListItem
            dense={dense}
            button
            onClick={toggleDrawer(false, "/advertise")}
            onKeyDown={toggleDrawer(false, "/advertise")}
            className={isActive("/advertise") ? styles.active : null}
          >
            <ListItemIcon>
              <LiveTvIcon className={styles.menuIcon} />
            </ListItemIcon>
            <ListItemText primary="Advertise" />
          </ListItem>
        </List>
      )}

      {props.isMobile && <Divider />}

      <List>
        <ListItem
          dense={dense}
          button
          onClick={toggleDrawer(false, "/")}
          onKeyDown={toggleDrawer(false, "/")}
          className={isActive("/") ? styles.active : null}
        >
          <ListItemIcon>
            <AppsIcon className={styles.menuIcon} />
          </ListItemIcon>
          <ListItemText primary="Dashboard" />
        </ListItem>

        <ListItem
          dense={dense}
          button
          onClick={toggleDrawer(false, "/professor")}
          onKeyDown={toggleDrawer(false, "/professor")}
          className={isActive("/professor") ? styles.active : null}
        >
          <ListItemIcon>
            <AssignmentIndOutlinedIcon className={styles.menuIcon} />
          </ListItemIcon>
          <ListItemText primary="Professors" />
        </ListItem>

        <ListItem
          dense={dense}
          button
          onClick={toggleDrawer(false, "/promo")}
          onKeyDown={toggleDrawer(false, "/promo")}
          className={isActive("/promo") ? styles.active : null}
        >
          <ListItemIcon>
            <RedeemIcon className={styles.menuIcon} />
          </ListItemIcon>
          <ListItemText primary="Promo Codes" />
        </ListItem>

        <ListItem
          dense={dense}
          button
          onClick={toggleDrawer(false, "/certificate")}
          onKeyDown={toggleDrawer(false, "/certificate")}
          className={isActive("/certificate") ? styles.active : null}
        >
          <ListItemIcon>
            <AssignmentTurnedInOutlinedIcon className={styles.menuIcon} />
          </ListItemIcon>
          <ListItemText primary="Certificate" />
        </ListItem>

        <ListItem
          dense={dense}
          button
          onClick={toggleDrawer(false, "/survey")}
          onKeyDown={toggleDrawer(false, "/survey")}
          className={isActive("/survey") ? styles.active : null}
        >
          <ListItemIcon>
            <ListAltIcon className={styles.menuIcon} />
          </ListItemIcon>
          <ListItemText primary="Survey" />
        </ListItem>

        <ListItem
          dense={dense}
          button
          onClick={toggleDrawer(false, "/members")}
          onKeyDown={toggleDrawer(false, "/members")}
          className={isActive("/members") ? styles.active : null}
        >
          <ListItemIcon>
            <CardMembershipIcon className={styles.menuIcon} />
          </ListItemIcon>
          <ListItemText primary="Memberships" />
        </ListItem>

        <Divider />

        <ListItem
          dense={dense}
          button
          onClick={toggleDrawer(false, "/reviews")}
          onKeyDown={toggleDrawer(false, "/reviews")}
          className={isActive("/reviews") ? styles.active : null}
        >
          <ListItemIcon>
            <RateReviewOutlinedIcon className={styles.menuIcon} />
          </ListItemIcon>
          <ListItemText primary="Reviews" />
        </ListItem>

        <ListItem
          dense={dense}
          button
          onClick={toggleDrawer(false, "/orders")}
          onKeyDown={toggleDrawer(false, "/orders")}
          className={isActive("/orders") ? styles.active : null}
        >
          <ListItemIcon>
            <ShoppingBasketOutlinedIcon className={styles.menuIcon} />
          </ListItemIcon>
          <ListItemText primary="Purchase History" />
        </ListItem>

        <ListItem
          dense={dense}
          button
          onClick={toggleDrawer(false, "/payment")}
          onKeyDown={toggleDrawer(false, "/payment")}
          className={isActive("/payment") ? styles.active : null}
        >
          <ListItemIcon>
            <PaymentIcon className={styles.menuIcon} />
          </ListItemIcon>
          <ListItemText primary="Payment" />
        </ListItem>

        <ListItem
          dense={dense}
          button
          onClick={toggleDrawer(false, "/settings")}
          onKeyDown={toggleDrawer(false, "/settings")}
          className={isActive("/settings") ? styles.active : null}
        >
          <ListItemIcon>
            <SettingsIcon className={styles.menuIcon} />
          </ListItemIcon>
          <ListItemText primary="Settings" />
        </ListItem>
      </List>

      <Divider />

      <List>
        <ListItem
          dense={dense}
          button
          onClick={toggleDrawer(false, "/logout")}
          onKeyDown={toggleDrawer(false, "/logout")}
        >
          <ListItemIcon>
            <ExitToAppIcon className={styles.menuIcon} />
          </ListItemIcon>
          <ListItemText primary="Logout" />
        </ListItem>
      </List>
    </div>
  );

  // ------------------------------

  const mobileUserMenuItems = () => (
    <div role="presentation">
      <div
        className={styles.menuOpenButton}
        onClick={() => {
          props.setOpen(false);
        }}
      >
        <MenuOpenIcon style={{ fontSize: "30px" }} />
      </div>

      <div
        className={styles.zubuLogoContainer}
        onClick={toggleDrawer(false, "/search")}
      >
        <Tooltip title={appVersion}>
          <img src={ZubuText} alt="ZubU" style={{ width: "80px" }} />
        </Tooltip>
      </div>

      <List>
        <Divider />

        <div className={styles.sectionTitle}>{t("g_bar_learn")}</div>

        <ListItem
          dense={dense}
          button
          onClick={toggleDrawer(false, "/search")}
          onKeyDown={toggleDrawer(false, "/search")}
          className={isActive("/search") ? styles.active : null}
        >
          <ListItemIcon>
            <SearchIcon className={styles.menuIcon} />
          </ListItemIcon>
          <ListItemText primary={t("g_bar_search")} />
        </ListItem>

        <ListItem
          dense={dense}
          button
          onClick={toggleDrawer(false, "/mycourses")}
          onKeyDown={toggleDrawer(false, "/mycourses")}
          className={isActive("/mycourses") ? styles.active : null}
        >
          <ListItemIcon>
            <Badge badgeContent={props.toBeRatedCount} color="error">
              <SchoolIcon className={styles.menuIcon} />
            </Badge>
          </ListItemIcon>
          <ListItemText primary={t("g_bar_my_courses")} />
        </ListItem>

        <Divider />

        <div className={styles.sectionTitle}>{t("g_manage_title")} </div>

        <ListItem
          dense={dense}
          button
          onClick={toggleDrawer(false, "/ce")}
          onKeyDown={toggleDrawer(false, "/ce")}
          className={isActive("/ce") ? styles.active : null}
        >
          <ListItemIcon>
            <InsertChartOutlinedRoundedIcon className={styles.menuIcon} />
          </ListItemIcon>
          <ListItemText primary={t("g_manage_ce")} />
        </ListItem>

        <ListItem
          dense={dense}
          button
          onClick={toggleDrawer(false, "/mystaff")}
          onKeyDown={toggleDrawer(false, "/mystaff")}
          className={isActive("/mystaff") ? styles.active : null}
        >
          <ListItemIcon>
            <SupervisorAccountIcon className={styles.menuIcon} />
          </ListItemIcon>
          <ListItemText primary={t("g_manage_staff")} />
        </ListItem>

        <ListItem
          dense={dense}
          button
          onClick={toggleDrawer(false, "/memberships")}
          onKeyDown={toggleDrawer(false, "/memberships")}
          className={isActive("/memberships") ? styles.active : null}
        >
          <ListItemIcon>
            <CardMembershipIcon className={styles.menuIcon} />
          </ListItemIcon>
          <ListItemText primary={t("g_memberships")} />
        </ListItem>

        <Divider />

        <div className={styles.sectionTitle}>{t("g_bar_my_account")}</div>

        <ListItem
          dense={dense}
          button
          onClick={toggleDrawer(false, "/myorders")}
          onKeyDown={toggleDrawer(false, "/myorders")}
          className={isActive("/myorders") ? styles.active : null}
        >
          <ListItemIcon>
            <ShoppingBasketOutlinedIcon className={styles.menuIcon} />
          </ListItemIcon>
          <ListItemText primary={t("g_bar_purhase_history")} />
        </ListItem>

        <ListItem
          dense={dense}
          button
          onClick={toggleDrawer(false, "/payment")}
          onKeyDown={toggleDrawer(false, "/payment")}
          className={isActive("/payment") ? styles.active : null}
        >
          <ListItemIcon>
            <PaymentIcon className={styles.menuIcon} />
          </ListItemIcon>
          <ListItemText primary={t("g_payment")} />
        </ListItem>

        <ListItem
          dense={dense}
          button
          onClick={toggleDrawer(false, "/mysettings")}
          onKeyDown={toggleDrawer(false, "/mysettings")}
          className={isActive("/mysettings") ? styles.active : null}
        >
          <ListItemIcon>
            <SettingsIcon className={styles.menuIcon} />
          </ListItemIcon>
          <ListItemText primary={t("g_settings")} />
        </ListItem>
      </List>

      <Divider />

      <List>
        <ListItem
          dense={dense}
          button
          onClick={toggleDrawer(false, "/logout")}
          onKeyDown={toggleDrawer(false, "/logout")}
        >
          <ListItemIcon>
            <ExitToAppIcon className={styles.menuIcon} />
          </ListItemIcon>
          <ListItemText primary={t("g_logout")} />
        </ListItem>
      </List>
    </div>
  );

  // ------------------------------

  return (
    <Drawer
      classes={{ paper: styles.paper }}
      anchor="left"
      open={props.open}
      onClose={toggleDrawer(false)}
      variant={!props.isMobile ? "persistent" : null}
    >
      {props.isMobileUser ? mobileUserMenuItems() : providerMenuItems()}
    </Drawer>
  );
};

export default MainDrawer;
