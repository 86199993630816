import React, { Component } from "react";
import { connect } from "react-redux";
import styles from "./HelpScreen.module.css";
import * as profileActions from "../../../store/actions/profile";
import * as settingActions from "../../../store/actions/setting";
import Spinner from "../../../components/ui/spinner/Spinner";
import InfoModal from "../../../components/modals/infoModal/InfoModal";
import SimpleContainer from "../../../components/ui/container/Container";
import { objectHasNoValues } from "../../../globals/Common";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import helpImg from "../../../assets/images/inApp/help.png";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import Fab from "@material-ui/core/Fab";

import helpAccountImg from "../../../assets/images/inApp/help_account.png";
import helpStripeImg from "../../../assets/images/inApp/help_stripe_account.png";
import * as logger from "../../../globals/Logger";

class HelpScreen extends Component {
  state = {
    wait: false,
    error: null,
  };

  // ------------------------------

  componentDidMount = async () => {
    window.scrollTo(0, 0);
  };

  // ------------------------------

  update = async (propValue) => {
    await this.setState({ ...this.state, ...propValue });
  };

  // ------------------------------

  componentDidMount = async () => {
    try {
      this.update({ error: null, wait: true });

      const loadAsync = [];

      if (objectHasNoValues(this.props.profile)) {
        loadAsync.push(this.props.fetchProfile());
      }

      if (objectHasNoValues(this.props.setting)) {
        loadAsync.push(this.props.fetchSetting());
      }

      await Promise.all(loadAsync);

      this.update({ error: null, wait: false });
    } catch (error) {
      logger.error(error);
      this.update({ wait: false, error: error.message });
    }
  };

  // ------------------------------

  render() {
    if (this.state.wait) {
      return <Spinner />;
    }

    return (
      <div style={{ background: "white" }}>
        <SimpleContainer disableGutters={false}>
          <InfoModal
            isErrorModal={true}
            title="An error has occured"
            message={this.state.error}
            open={this.state.error !== null}
            onClose={() => {
              this.update({ error: null });
            }}
          />

          <div style={{ height: "40px" }}>&nbsp;</div>

          <div className={styles.sectionContainer}>
            <div className={styles.imgContainer}>
              <img src={helpImg} alt="help" />
            </div>

            <div className={styles.row}>
              <div className={styles.needHelp}>
                Need help? Send us an email or consult our frequently asked
                questions.
              </div>
            </div>

            <div style={{ height: "20px" }}>&nbsp;</div>

            <div className={styles.row}>
              <div className={styles.rowTitle}>Curtomer Service:</div>

              <a
                className={styles.link}
                href="mailto:customerservice@zubueducation.com"
              >
                customerservice@zubueducation.com
              </a>
            </div>

            <div className={styles.row}>
              <div className={styles.rowTitle}>General Questions:</div>
              <a className={styles.link} href="mailto:info@zubueducation.com">
                info@zubueducation.com
              </a>
            </div>
          </div>

          <div style={{ height: "40px" }}>&nbsp;</div>

          <div className={styles.sectionTitle}>
            <div className={styles.centerRow}>
              <HelpOutlineIcon style={{ color: "white" }} />
              &nbsp;&nbsp;FAQ
            </div>
          </div>

          <div style={{ height: "20px" }}>&nbsp;</div>

          <div className={styles.sectionContainer}>
            <div className={styles.faqTitle}>
              Question: I have created my provider account. What do I do next?
            </div>

            <div className={styles.faqAnswer}>
              <b>Stripe Setup</b> is the first step after your account creation.
              The Stripe Setup allows you to process payments and receive
              payouts.
            </div>
          </div>

          <div style={{ height: "40px" }}>&nbsp;</div>

          <div className={styles.sectionContainer}>
            <div className={styles.faqTitle}>
              Question: I completed the Stripe Setup however I get the following
              message:{" "}
              <span className={styles.warningText}>
                Your Account requires additional information. This can impact
                your ability to receive payments and payouts.
              </span>
            </div>

            <div className={styles.faqAnswer}>
              Stripe may require additional information at any time. Failure to
              provide all required documents may result in payment and/or payout
              restrictions. A restricted account may prevent registrants from
              purchasing your course from the mobile app.
            </div>

            <div className={styles.faqAnswer}>
              To correct this issue, select Account Balance from the top menu
              then click the bank icon as shown in the illustration. This will
              open the Stripe dashboard.
              <div className={styles.imgContainer}>
                <img
                  src={helpAccountImg}
                  alt="help account"
                  className={styles.helpImage}
                />
              </div>
              To provide additional information to Stripe select Account as
              shown in the illustration.
              <div className={styles.imgContainer}>
                <img
                  src={helpStripeImg}
                  alt="help stripe"
                  className={styles.helpImage}
                />
              </div>
            </div>
          </div>

          <div style={{ height: "40px" }}>&nbsp;</div>

          <div className={styles.sectionContainer}>
            <div className={styles.faqTitle}>
              Question: What should I do before activating my first course?
            </div>

            <div className={styles.faqAnswer}>
              <ul>
                <li>
                  <b>Create your list of speakers</b>
                  <p>
                    Select Professor from the main menu: This will facilitate
                    easy selection in the course builder. All photos and
                    biographies are automatically added upon activation.
                  </p>
                </li>

                <li>
                  <b>Add your cancellation policy</b>
                  <p>
                    Select <b>Settings, My Profile</b>. The cancellation policy
                    is mandatory in order to activate a course. The cancellation
                    policy. The cancellation policy gets attached to your course
                    listings. Here you may also add your company logo and
                    digital signature which will be used in the certificate of
                    completion.
                  </p>
                </li>

                <li>
                  <b>Add a payment method</b>
                  <p>This will allow you to: </p>
                  <ol>
                    <li>
                      Purchase advertising/ feature your course on the mobile
                      app.
                    </li>
                    <li>
                      If you offer free courses, the ZubU fixed fee per
                      registration will be charged to this card.
                    </li>
                    <li>
                      Fund your Stripe account if there are insufficient funds
                      to perform a refund.
                    </li>
                  </ol>
                </li>
              </ul>
            </div>
          </div>

          <div style={{ height: "40px" }}>&nbsp;</div>

          <div className={styles.sectionContainer}>
            <div className={styles.faqTitle}>
              Question: Can I restrict who registers for my course?
            </div>

            <div className={styles.faqAnswer}>
              You may specify the audience able to register for your course when
              you create your registration options in the course builder. If you
              create options for dentists only, then hygienist will be unable to
              register. In a standard course, dentists and specialists can
              register for your course. If you wish to offer your courses to
              specialists only or wish to create a registration option specific
              to specialists, you must do so in the course builder.
            </div>
          </div>

          <div style={{ height: "40px" }}>&nbsp;</div>

          <div className={styles.sectionContainer}>
            <div className={styles.faqTitle}>
              Question: Can I limit number of registrations?
            </div>

            <div className={styles.faqAnswer}>
              Yes. Set the capacity limit in the course builder. This limits the
              number of students who may register for your course. The capacity
              can be adjusted at any time.
            </div>
          </div>

          <div style={{ height: "40px" }}>&nbsp;</div>

          <div className={styles.sectionContainer}>
            <div className={styles.faqTitle}>
              Question: Can I make changes to a course after its activation?
            </div>

            <div className={styles.faqAnswer}>
              Yes. You can amend to an active course up to a maximum of three
              times. Amendments must not change the content of the course in
              such a matter that it would diminish its value to registered
              students. Permitted amendments include adding notes, discounts,
              changing the course date.
            </div>
          </div>

          <div style={{ height: "40px" }}>&nbsp;</div>

          <div className={styles.sectionContainer}>
            <div className={styles.faqTitle}>
              Question: What happens if I amend the course date of an active
              course?
            </div>

            <div className={styles.faqAnswer}>
              Amending the course date of an active course will send out an
              email to all registered students with the option to request a full
              refund within 72 hours or accept the new proposed date.
            </div>
          </div>

          <div style={{ height: "40px" }}>&nbsp;</div>

          <div className={styles.sectionContainer}>
            <div className={styles.faqTitle}>
              Question: How do I refund a student?
            </div>

            <div className={styles.faqAnswer}>
              Refunds are done through the order. You may access an order in two
              ways:
              <ol>
                <li>
                  The course card on My Courses. Find the course select the
                  student icon and select the student order number.
                </li>

                <li>
                  Account Balance. This lists all transactions starting with the
                  most recent. Select the order number.
                </li>
              </ol>
              <p>
                Both will bring you to the student order where you can refund
                (partial or in full) and/or unregister the student.
              </p>
            </div>
          </div>

          <div style={{ height: "40px" }}>&nbsp;</div>

          <div className={styles.sectionContainer}>
            <div className={styles.faqTitle}>
              Question: I wish to refund a student, however there is not enough
              funds in my Stripe account.
            </div>

            <div className={styles.faqAnswer}>
              Go to Account Balance and select the more icon{" "}
              <Fab
                color="default"
                size="small"
                aria-controls="simple-menu"
                aria-haspopup="true"
                onClick={() => {}}
                disabled={true}
              >
                <MoreHorizIcon />
              </Fab>
              &nbsp;Add Funds.
            </div>
          </div>

          <div style={{ height: "40px" }}>&nbsp;</div>

          <div className={styles.sectionContainer}>
            <div className={styles.faqTitle}>
              Question: How often are payouts sent to my bank?
            </div>

            <div className={styles.faqAnswer}>
              Payouts are issued every first of the month. This allows funds to
              remain in your account for potential refunds. If you wish to
              change the frequency, please contact us at{" "}
              <a
                className={styles.link}
                href="mailto:customerservice@zubueducation.com"
              >
                customerservice@zubueducation.com
              </a>
              .
            </div>
          </div>

          <div style={{ height: "40px" }}>&nbsp;</div>

          <div className={styles.sectionContainer}>
            <div className={styles.faqTitle}>
              Question: Is the ZubU service fee refundable?
            </div>

            <div className={styles.faqAnswer}>
              No. The ZubU service fee is not refundable.
            </div>
          </div>

          <div style={{ height: "40px" }}>&nbsp;</div>

          <div className={styles.sectionContainer}>
            <div className={styles.faqTitle}>
              Question: How does the targeted advertising work?
            </div>

            <div className={styles.faqAnswer}>
              We show ads to our mobile app users based on their interests and
              patterns of usage. You may further target your courses based on
              location, years of experience etc.
            </div>
          </div>

          <div style={{ height: "40px" }}>&nbsp;</div>

          <div className={styles.sectionContainer}>
            <div className={styles.faqTitle}>
              Question: Can I use my own image for an ad?
            </div>

            <div className={styles.faqAnswer}>
              Currently we do not allow providers to use their own image for
              quality control purposes.
            </div>
          </div>

          <div style={{ height: "40px" }}>&nbsp;</div>

          <div className={styles.sectionContainer}>
            <div className={styles.faqTitle}>
              Question: I no longer wish to sell academic content on the Zubu
              Platform, how do I delete my account?
            </div>

            <div className={styles.faqAnswer}>
              Before requesting your account deletion, please read the Supplier
              Terms and Conditions for deleting an account.
            </div>

            <div className={styles.faqAnswer}>
              If your account is in good standing order, send an email to{" "}
              <span>
                <a
                  className={styles.link}
                  href="mailto:customerservice@zubueducation.com"
                >
                  customerservice@zubueducation.com
                </a>{" "}
              </span>
              titled <b>Delete my Account</b>. Please provide us with a reason
              for your request. The email must be sent from the email address
              associated to the provider account.
            </div>
          </div>

          <div style={{ height: "40px" }}>&nbsp;</div>
        </SimpleContainer>

        <div style={{ height: "80px" }}>&nbsp;</div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    profile: state.profile.profile,
    setting: state.setting.setting,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchSetting: () => dispatch(settingActions.fetchSetting()),
    fetchProfile: () => dispatch(profileActions.fetchProfile()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(HelpScreen);
