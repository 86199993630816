import React from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControl from "@material-ui/core/FormControl";
import styles from "./RadioGroup.module.css";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import FormLabel from "@material-ui/core/FormLabel";

const MatRadioGroup = (props) => {
  const useStyles = makeStyles((theme) => ({
    root: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(3),

      "& > * + *": {
        marginTop: theme.spacing(3),
      },
    },
    textField: {
      width: "100%",
    },
    textFieldSmall: {
      width: "60%",
    },
    margin: {
      margin: theme.spacing(0),
    },
  }));

  const matches = useMediaQuery("(max-width:499px)");
  const classes = useStyles();
  const classttt =
    matches || props.fullWidth ? classes.textField : classes.textFieldSmall;

  return (
    <div className={classes.root}>
      <FormControl
        component="fieldset"
        className={clsx(classes.margin, classttt)}
      >
        <div className={props.noBorder ? null : styles.radioFullContainer}>
          <FormLabel component="legend" className={styles.row}>
            {props.title}
          </FormLabel>
          <RadioGroup
            aria-label={props.title}
            name={props.name}
            value={props.value}
            onChange={props.onChange}
          >
            {props.children}
          </RadioGroup>
        </div>
      </FormControl>
    </div>
  );
};

export default MatRadioGroup;
