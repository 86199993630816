import React, { Component } from "react";
import * as reviewActions from "../../store/actions/reviews";
import * as settingActions from "../../store/actions/setting";
import * as profileActions from "../../store/actions/profile";
import styles from "./ReviewScreen.module.css";
import RatingDistribution from "../../components/ui/rating/RatingDistribution";
import ReviewItem from "../../components/items/reviewItem/ReviewItem";
import { connect } from "react-redux";
import Spinner from "../../components/ui/spinner/Spinner";
import InputModal from "../../components/modals/inputModal/InputModal";
import InfoModal from "../../components/modals/infoModal/InfoModal";
import Empty from "../../components/ui/empty/Empty";
import reviewImg from "../../assets/images/inApp/reviews.png";
import { objectHasNoValues } from "../../globals/Common";
import LoadMore from "../../components/ui/pagination/LoadMore";
import * as logger from "../../globals/Logger";

class ReviewScreen extends Component {
  state = {
    wait: false,
    error: null,
    openInputModal: false,
    selectedReview: null,
    filters: { page: 1 },
    isLoadingMore: false,
  };

  // ------------------------------

  update = async (propValue) => {
    await this.setState({ ...this.state, ...propValue });
  };

  // ------------------------------

  componentDidMount = async () => {
    try {
      await this.update({ wait: true });
      const loadAsync = [];
      if (objectHasNoValues(this.props.profile)) {
        loadAsync.push(this.props.fetchProfile());
      }
      if (objectHasNoValues(this.props.setting)) {
        loadAsync.push(this.props.fetchSetting());
      }
      loadAsync.push(this.props.fetchRating());
      loadAsync.push(
        this.props.fetchReviews({
          page: 1,
        })
      );
      await Promise.all(loadAsync);
      await this.update({ wait: false });
    } catch (err) {
      logger.error(err);
      this.update({ wait: false, error: err.message });
    }
  };

  // ------------------------------

  openReplyDialogHanlder = async () => {
    this.props.setAddAction();
  };

  // ------------------------------

  updateNextPage = () => {
    const copyFilter = { ...this.state.filters };
    const nextPage = this.state.filters.page ? this.state.filters.page + 1 : 2;
    copyFilter["page"] = nextPage;
    return copyFilter;
  };

  // ------------------------------

  loadMoreHandler = async () => {
    try {
      await this.update({
        isLoadingMore: true,
      });
      const newFilters = this.updateNextPage();
      this.update({ filters: newFilters });
      this.props.fetchReviews(newFilters);
      await this.update({
        isLoadingMore: false,
      });
    } catch (error) {
      logger.error(error);
      await this.update({
        isLoadingMore: false,
        error: error.message,
      });
    }
  };

  // ------------------------------

  renderItems = () => {
    return this.props.reviews.map((item, index) => {
      return (
        <ReviewItem
          key={index}
          id={item.id}
          first={item.first}
          pic={item.pic}
          course_title={item.course_title}
          rating={item.rating}
          comment={item.comment}
          date={item.renderDate()}
          reply={item.reply}
          replyDate={item.renderReplyDate()}
          onReply={() => {
            this.update({ openInputModal: true, selectedReview: item });
          }}
          onDeleteReply={() => {
            this.props.deleteReply(item);
          }}
        />
      );
    });
  };

  // ------------------------------

  render() {
    if (this.state.wait) {
      return <Spinner />;
    }

    if (!this.props.reviews || this.props.reviews.length === 0) {
      return (
        <Empty
          column
          image={reviewImg}
          message={<p>You currently have no reviews.</p>}
        />
      );
    }

    return (
      <div style={{ backgroundColor: "white" }}>
        {this.state.error && (
          <InfoModal
            isErrorModal={true}
            title="Error"
            //title={this.props.t("error_occurred")}
            message={this.state.error}
            open={this.state.error !== null}
            onClose={() => {
              this.update({
                error: null,
              });
            }}
          />
        )}

        {this.state.openInputModal && (
          <InputModal
            title="Reply"
            message={"Registrants will be notified of your response."}
            open={this.state.openInputModal}
            onClose={(action, reply) => {
              if (action && reply) {
                this.props.addReply(this.state.selectedReview, reply);
              }
              this.update({ openInputModal: false, selectedReview: null });
            }}
          />
        )}

        <div style={{ height: "38px" }}></div>

        <div className={styles.ratingCardContainer}>
          <RatingDistribution
            rating={this.props.rating.averageRating()}
            ratingCount={this.props.rating.rating_count}
            five={0}
            four={0}
          />
        </div>

        <div className={styles.listContainer}>
          {this.renderItems()}
          <div className={styles.loadMoreContainer}>
            <LoadMore
              title="More Reviews"
              loading={this.state.isLoadingMore}
              hasMore={this.props.hasMore}
              onLoadMore={this.loadMoreHandler}
            />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    profile: state.profile.profile,
    setting: state.setting.setting,
    rating: state.reviews.rating,
    reviews: state.reviews.reviews,
    hasMore: state.reviews.hasMore,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchProfile: () => dispatch(profileActions.fetchProfile()),
    fetchSetting: () => dispatch(settingActions.fetchSetting()),
    fetchReviews: (filters) => dispatch(reviewActions.fetchReviews(filters)),
    fetchRating: () => dispatch(reviewActions.fetchRating()),
    addReply: (review, reply) =>
      dispatch(reviewActions.addReply(review, reply)),
    deleteReply: (review) => dispatch(reviewActions.deleteReply(review)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ReviewScreen);
