class Order {
  constructor(
    id,
    orderRefNumber,
    date,
    currency,
    totalAmount,
    gst,
    qst,
    items,
    creditsUsed
  ) {
    this.id = id;
    this.orderRefNumber = orderRefNumber;
    this.date = date;
    this.currency = currency;
    this.totalAmount = totalAmount;
    this.gst = gst;
    this.qst = qst;
    this.items = items;
    this.creditsUsed = creditsUsed;
  }
}

export default Order;
