class MobileMembership {
    constructor(
        id,
        provider_id,
        provider_name,
        provider_logo,
        member_id
    ) {
        this.id = id;
        this.provider_id = provider_id;
        this.provider_name = provider_name;
        this.provider_logo = provider_logo;
        this.member_id = member_id;
    }
}

export default MobileMembership;
