import React from "react";
import { useTranslation } from "react-i18next";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import { useTheme } from "@material-ui/core/styles";
import FlatList from "flatlist-react";
import PaymentItem from "../../../components/items/paymentItem/PaymentItem";

const PaymentMethodModal = (props) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const fullScreen = false;
  const fullWidth = true;
  const maxWidth = "sm";

  const handleClose = (selectedIndex) => {
    props.onSelect(props.list[selectedIndex]);
  };

  const renderPaymentMethod = (item, index) => {
    return (
      <PaymentItem
        key={index}
        id={item.id}
        lastDigits={item.lastDigitsMasked}
        cardExpiry={item.expiryDate}
        brand={item.brand}
        onClick={handleClose.bind(this, index)}
      />
    );
  };

  return (
    <div>
      <Dialog
        fullScreen={fullScreen}
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={props.open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogContent>
          {" "}
          <FlatList list={props.list} renderItem={renderPaymentMethod} />
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            onClick={handleClose.bind(this, null)}
            color="primary"
          >
            {t("g_cancel")}
          </Button>
          <Button autoFocus onClick={handleClose} color="primary">
            {t("g_ok")}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default PaymentMethodModal;
