import { dbName } from "../../globals/ApiKeys";
import { handleErrorResponse } from "../../globals/Error";
import Review from "../../model/Review";
import Rating from "../../model/Rating";
import {
  fromFirebaseDoubleValue,
  firebaseNameToId,
} from "../../globals/Common";
export const SET_REVIEWS = "SET_REVIEWS";
export const SET_RATING = "SET_RATING";
export const UPDATE_REPLY = "UPDATE_REPLY";
export const DELETE_REPLY = "DELETE_REPLY";
export const CLEAR_REVIEW_RATING = "CLEAR_REVIEW_RATING";

const LIMIT = 10;

// ------------------------------

export const clearReviewAndRating = () => {
  return {
    type: CLEAR_REVIEW_RATING,
  };
};

// ------------------------------

export const fetchRating = () => {
  return async (dispatch, getState) => {
    const userId = getState().auth.userId;
    const token = getState().auth.token;

    const response = await fetch(
      `https://firestore.googleapis.com/v1/projects/${dbName}/databases/(default)/documents/provider_rating/${userId}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "applications/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );

    if (!response.ok) {
      handleErrorResponse(await response.json());
    }

    const resData = await response.json();

    let rating = null;
    if (resData && resData.fields) {
      rating = new Rating(
        resData.fields.rating_count
          ? resData.fields.rating_count.integerValue
          : 0,
        resData.fields.rating_sum
          ? fromFirebaseDoubleValue(
              resData.fields.rating_sum.doubleValue,
              resData.fields.rating_sum.integerValue
            )
          : 0
      );
    }

    dispatch({
      type: SET_RATING,
      rating: rating,
    });
  };
};

// ------------------------------

export const fetchReviews = (filters) => {
  return async (dispatch, getState) => {
    const userId = filters.providerId
      ? filters.providerId
      : getState().auth.userId;
    const page = filters.page;
    const limit = filters.limit ? filters.limit : LIMIT;

    const response = await fetch(
      `https://firestore.googleapis.com/v1/projects/${dbName}/databases/(default)/documents/provider_rating/${userId}:runQuery`,
      {
        method: "POST",
        headers: {
          "Content-Type": "applications/json",
        },
        body: JSON.stringify({
          structuredQuery: {
            from: [{ collectionId: "rating" }],
            orderBy: [
              {
                field: {
                  fieldPath: "date",
                },
                direction: "DESCENDING",
              },
            ],
            limit: page ? limit * page : limit,
          },
        }),
      }
    );

    if (!response.ok) {
      handleErrorResponse(await response.json());
    }

    const resData = await response.json();

    const loadedReviews = [];
    for (const documents of resData) {
      const review = documents.document;
      if (review && review.name) {
        loadedReviews.push(
          new Review(
            firebaseNameToId(review.name),
            userId,
            review.fields.course_title
              ? review.fields.course_title.stringValue
              : "",
            review.fields.user_id.stringValue,
            review.fields.first.stringValue,
            review.fields.pic.stringValue,
            review.fields.rating
              ? fromFirebaseDoubleValue(
                  Math.round(
                    (review.fields.rating.doubleValue + Number.EPSILON) * 100
                  ) / 100,
                  review.fields.rating.integerValue
                )
              : 0,
            review.fields.comment ? review.fields.comment.stringValue : "",
            new Date(review.fields.date.timestampValue),
            review.fields.reply ? review.fields.reply.stringValue : "",
            review.fields.reply_date
              ? new Date(review.fields.reply_date.timestampValue)
              : null
          )
        );
      }
    }

    dispatch({
      type: SET_REVIEWS,
      reviews: loadedReviews,
      hasMore: hasMorePages(loadedReviews.length, page, limit),
    });
  };
};

// ------------------------------

export const addReply = (review, reply) => {
  return async (dispatch, getState) => {
    await updateReplyAndDate(dispatch, getState, review, reply, new Date());
  };
};

// ------------------------------

export const deleteReply = (review) => {
  return async (dispatch, getState) => {
    await updateReplyAndDate(dispatch, getState, review, "", null);
  };
};

// ------------------------------

const updateReplyAndDate = async (
  dispatch,
  getState,
  review,
  reply,
  reply_date
) => {
  const userId = getState().auth.userId;
  const token = getState().auth.token;

  const response = await fetch(
    `https://firestore.googleapis.com/v1/projects/${dbName}/databases/(default)/documents/provider_rating/${userId}/rating/${review.id}?updateMask.fieldPaths=reply&updateMask.fieldPaths=reply_date`,
    {
      method: "PATCH",
      headers: {
        "Content-Type": "applications/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        fields: {
          reply: { stringValue: reply },
          reply_date: { timestampValue: new Date() },
        },
      }),
    }
  );

  if (!response.ok) {
    handleErrorResponse(await response.json());
  }

  dispatch({
    type: UPDATE_REPLY,
    review: review,
    reply: reply,
    reply_date: reply_date,
  });
};

// ------------------------------

const hasMorePages = (totalRows, page, limit) => {
  const setLimit = limit ? limit : LIMIT;
  return totalRows - (page ? page * setLimit : setLimit) >= 0;
};

// ------------------------------
