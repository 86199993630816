import React from "react";
import { useTranslation } from "react-i18next";
import styles from "./Toolbar.module.css";
import MenuIcon from "@material-ui/icons/Menu";
import { NavLink } from "react-router-dom";
import ReactCountryFlag from "react-country-flag";
import Fade from "@material-ui/core/Fade";
import ShoppingCartOutlinedIcon from "@material-ui/icons/ShoppingCartOutlined";
import Badge from "@material-ui/core/Badge";
import SchoolOutlinedIcon from "@material-ui/icons/SchoolOutlined";
import AccountBalanceOutlinedIcon from "@material-ui/icons/AccountBalanceOutlined";
import EqualizerIcon from "@material-ui/icons/Equalizer";
import NotificationsOutlinedIcon from "@material-ui/icons/NotificationsOutlined";
import LiveTvIcon from "@material-ui/icons/LiveTv";
import Tooltip from "@material-ui/core/Tooltip";
import NavigationItem from "../navigationItem/NavigationItem";
import NotificationItem from "../navigationItem/NotificationItem";
import MatBadge from "../../ui/badge/MatBadge";
import CurrencyLabel from "../../ui/currencyLabel/CurrencyLabel";
import Avatar from "react-avatar";

import useMediaQuery from "@material-ui/core/useMediaQuery";
import InboxPop from "../../ui/popover/InboxPop";
import ConversationList from "../../modals/inboxChatModal/elements/ConversationList";

import AlgoliaSearch from "../../ui/algoliaAutocomplete/AlgoliaSearch";

const allowSearch = ["/", "/search", "/mycourses", "/courselist", "/ce"];

const Toolbar = (props) => {
  const { t } = useTranslation();
  const mobileOnly = useMediaQuery("(max-width: 499px)");

  // ------------------------------

  const courseHandler = (courseId, providerId) => {
    props.goTo(`details?p=${providerId}&c=${courseId}`);
  };

  // ------------------------------

  const providerToolbar = () => (
    <header>
      <div className={styles.toolbar}>
        <div className={styles.menuButton}>
          <MenuIcon
            onClick={props.toggleClicked}
            style={{ color: "black" }}
            className={styles.menuButton}
          />
        </div>

        <NavLink
          exact
          to={"/settings/profile"}
          className={
            props.drawerOpen
              ? [styles.providerName, styles.margin].join(" ")
              : styles.providerName
          }
        >
          {props.currency && (
            <ReactCountryFlag
              countryCode={props.currency.iso}
              svg
              style={{
                fontSize: "2.5em",
                lineHeight: "2.5em",
                paddingRight: 10,
                paddingBottom: 0,
              }}
            />
          )}
          <Fade in={props.providerName ? true : false}>
            <span className={styles.nine}>{props.providerName}</span>
          </Fade>
        </NavLink>

        <nav className={styles.destopOnly}>
          <ul className={styles.navigationItems}>
            <NavigationItem link="/courses">
              <Tooltip title="Courses">
                <SchoolOutlinedIcon />
              </Tooltip>
              <div className={styles.tablet}>&nbsp; &nbsp;Courses</div>
            </NavigationItem>

            {props.connectAccountActive && (
              <NavigationItem link="/account">
                <MatBadge count={props.connectAccountWarning ? 1 : 0}>
                  <Tooltip title="Account Balance">
                    <AccountBalanceOutlinedIcon />
                  </Tooltip>{" "}
                </MatBadge>
                <div className={styles.tablet}>Account Balance</div>
              </NavigationItem>
            )}

            <NavigationItem link="/ganalytics">
              <Tooltip title="Analytics">
                <EqualizerIcon />
              </Tooltip>
              <div className={styles.tablet}>&nbsp;&nbsp;Analytics</div>
            </NavigationItem>

            <NavigationItem link="/advertise">
              <Tooltip title="Advertise">
                <LiveTvIcon />
              </Tooltip>
              <div className={styles.tablet}>&nbsp;&nbsp;Advertise</div>
            </NavigationItem>

            <InboxPop conversations={props.conversations}>
              <ConversationList
                full
                conversations={props.conversations}
                onSelectConversation={props.onSelectConversation}
              />
            </InboxPop>

            <NotificationItem
              alertNotifications={props.alertNotifications}
              removeAlert={props.removeAlert}
              goTo={props.goTo}
            >
              <Badge
                badgeContent={
                  props.alertNotifications ? props.alertNotifications.length : 0
                }
                color="error"
                overlap="rectangular"
              >
                <NotificationsOutlinedIcon />
              </Badge>
            </NotificationItem>

            <NavigationItem link="/cart">
              <Badge badgeContent={props.cartItemCount} color="error" overlap="rectangular">
                <Tooltip title="Shop Cart">
                  <ShoppingCartOutlinedIcon />
                </Tooltip>
              </Badge>
            </NavigationItem>
          </ul>
        </nav>

        <nav className={styles.mobileOnly}>
          <ul className={styles.navigationItems}>
            <NotificationItem
              alertNotifications={props.alertNotifications}
              removeAlert={props.removeAlert}
              goTo={props.goTo}
            >
              <Badge
                badgeContent={
                  props.alertNotifications ? props.alertNotifications.length : 0
                }
                color="error"
                overlap="rectangular"
              >
                <NotificationsOutlinedIcon />
              </Badge>
            </NotificationItem>

            <NavigationItem link="/cart">
              <Badge badgeContent={props.cartItemCount} color="error" overlap="rectangular">
                <Tooltip title="Shop Cart">
                  <ShoppingCartOutlinedIcon />
                </Tooltip>
              </Badge>
            </NavigationItem>
          </ul>
        </nav>
      </div>
    </header>
  );

  // ------------------------------

  const showSearch = () => {
    return props.pathname && allowSearch.indexOf(props.pathname) > -1;
  };

  // ------------------------------

  const mobileUserToolbar = () => (
    <header>
      <div className={styles.toolbar}>
        <div className={styles.menuButton}>
          <MenuIcon
            onClick={props.toggleClicked}
            style={{ color: "black" }}
            className={styles.menuButton}
          />
        </div>

        {!mobileOnly ? (
          <nav
            style={
              props.drawerOpen ? { paddingLeft: "160px", flex: 1 } : { flex: 1 }
            }
          >
            <ul className={styles.navigationItems}>
              {showSearch() && <AlgoliaSearch onSelect={courseHandler} />}
              <Avatar
                name={props.firstName}
                size="40px"
                round={true}
                onClick={() => {
                  props.goTo("mySettings");
                }}
                style={{ cursor: "pointer" }}
              />
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <CurrencyLabel
                iconSize="medium"
                currencyAcronym={props.mobileSetting.currency}
                language={props.mobileSetting.language}
                updateMobileCurrency={props.updateMobileCurrency}
              />
              <InboxPop conversations={props.conversations}>
                <ConversationList
                  full
                  conversations={props.conversations}
                  onSelectConversation={props.onSelectConversation}
                />
              </InboxPop>
              <NotificationItem
                alertNotifications={props.alertNotifications}
                removeAlert={props.removeAlert}
                goTo={props.goTo}
              >
                <Badge
                  badgeContent={
                    props.alertNotifications
                      ? props.alertNotifications.length
                      : 0
                  }
                  color="error"
                  overlap="rectangular"
                >
                  <NotificationsOutlinedIcon />
                </Badge>
              </NotificationItem>
              <NavigationItem link="/cart">
                <Badge badgeContent={props.cartItemCount} color="error" overlap="rectangular">
                  <Tooltip title={t("cart_title")}>
                    <ShoppingCartOutlinedIcon />
                  </Tooltip>
                </Badge>
              </NavigationItem>
            </ul>
          </nav>
        ) : (
          <nav style={{ flex: 1 }}>
            <ul className={styles.navigationItems}>
              {showSearch() && <AlgoliaSearch onSelect={courseHandler} />}
              <CurrencyLabel
                currencyAcronym={props.mobileSetting.currency}
                language={props.mobileSetting.language}
                updateMobileCurrency={props.updateMobileCurrency}
              />
              &nbsp; &nbsp;
              <InboxPop conversations={props.conversations}>
                <ConversationList
                  full
                  conversations={props.conversations}
                  onSelectConversation={props.onSelectConversation}
                />
              </InboxPop>
              <NavigationItem link="/cart">
                <Badge badgeContent={props.cartItemCount} color="error" overlap="rectangular">
                  <Tooltip title={t("cart_title")}>
                    <ShoppingCartOutlinedIcon />
                  </Tooltip>
                </Badge>
              </NavigationItem>
            </ul>
          </nav>
        )}
      </div>
    </header>
  );

  // ------------------------------

  return props.isMobileUser ? mobileUserToolbar() : providerToolbar();
};

export default Toolbar;
