import moment from "moment";
import "moment/locale/fr";
import i18n from "../i18n";

export const MAX_COURSE_DATE_LIMIT = 365 * 3;

// moment.js
export const M_DATE_FORMAT_TINY = "MMM Do";
export const M_DATE_FORMAT = "MM-DD-YYYY";
export const M_TIME_FORMAT = "h:mm a";
export const M_LONG_DATE_FORMAT = "dddd MMMM Do YYYY"; //"llll";
export const M_SHORT_DATE_FORMAT = "MMM Do YYYY"; //"llll";

// fns
export const FNS_DATE_FORMAT = "MM-dd-yyyy";
export const FNS_MEDIUM_DATE_FORMAT = "MMMM d, yyyy";
export const FNS_LONG_DATE_FORMAT = "iiii MMMM do yyyy";

export const FNS_TIME_FORMAT = "h:mm aa";

const SEPERATOR = " : ";

export const FNS_LONG_DATE_TIME_FORMAT =
  FNS_LONG_DATE_FORMAT + SEPERATOR + FNS_TIME_FORMAT;

export const M_LONG_DATE_TIME_FORMAT =
  M_SHORT_DATE_FORMAT + SEPERATOR + M_TIME_FORMAT;

export const M_SHORT_DATE_TIME_FORMAT =
  M_SHORT_DATE_FORMAT + ", " + M_TIME_FORMAT;

// ------------------------------

export const newLocalDate = (date) => {
  return date ? new Date(date) : new Date();
};

// ------------------------------

export const momentTinyDate = (date) => {
  return moment(date).locale(i18n.language).format(M_DATE_FORMAT_TINY);
};

// ------------------------------

export const addDays = (date, days) => {
  const newDate = new Date(date);
  newDate.setMinutes(0);
  newDate.setSeconds(0);
  newDate.setDate(newDate.getDate() + days);
  return newDate;
};

// ------------------------------

export const incrementDayOfDate = (date, days) => {
  const newDate = new Date(date);
  newDate.setDate(newDate.getDate() + days);
  return newDate;
};

// ------------------------------

export const incrementMonthOfDate = (date, months) => {
  const newDate = new Date(date);
  newDate.setMonth(newDate.getMonth() + months);
  return newDate;
};

// ------------------------------

export const incrementYearOfDate = (date, years) => {
  const newDate = new Date(date);
  newDate.setFullYear(newDate.getFullYear() + years);
  return newDate;
};

// ------------------------------

export const isEqualDate = (date1, date2) => {
  return (
    date1.getDay() === date2.getDay() &&
    date1.getMonth() === date2.getMonth() &&
    date1.getYear() === date2.getYear()
  );
};

// ------------------------------

export const fromNow = (date) => {
  return moment(date).locale(i18n.language).fromNow();
};

export const momentLongDate = (date) => {
  return moment(date).format(M_LONG_DATE_FORMAT);
};

export const momentLongDateTime = (date) => {
  return moment(date).format(M_LONG_DATE_TIME_FORMAT);
};

export const momentShortDateTime = (date) => {
  return moment(date).format(M_SHORT_DATE_TIME_FORMAT);
};

export const momentShortDate = (date) => {
  return moment(date).locale(i18n.language).format(M_SHORT_DATE_FORMAT);
};

// ------------------------------

export const momentTime = (date) => {
  return moment(date).format(M_TIME_FORMAT);
};

// ------------------------------

export const remainingDaysTime = (date) => {
  if (date) {
    return moment(new Date(date)).from(new Date());
  }

  return "";
};

// ------------------------------

export const getDateRange = (startDate, days) => {
  const startDateCopy = new Date(startDate);
  startDateCopy.setDate(1);
  startDateCopy.setHours(0, 0, 0);

  const endDate = addDays(startDateCopy, days);
  endDate.setDate(0);
  endDate.setHours(23, 59, 59);

  return { startDate: startDateCopy, endDate: endDate };
};

// ------------------------------

export const isPastDate = (date, numberOfDays) => {
  const pastDate = new Date(date);
  pastDate.setDate(pastDate.getDate() + numberOfDays);
  const today = new Date();
  return pastDate.getTime() < today.getTime();
};

// ------------------------------

export const shortJsDate = (date, i18n) => {
  var options = {
    year: "numeric",
    month: "long",
    day: "numeric",
  };

  return date.toLocaleDateString(i18n.language, options);
};

// ------------------------------

export const sortArrayOfDates = (dateArray, ascending) => {
  let dateForNonDate = ascending
    ? incrementYearOfDate(new Date(), +10)
    : incrementYearOfDate(new Date(), -10);

  const sortedArray = dateArray.sort((a, b) => {
    let dateA = a.start_date[0] ? a.start_date[0] : dateForNonDate;
    let dateB = b.start_date[0] ? b.start_date[0] : dateForNonDate;

    if (ascending) {
      return dateA.getTime() - dateB.getTime();
    } else {
      return dateB.getTime() - dateA.getTime();
    }
  });

  return sortedArray;
};

// ------------------------------
