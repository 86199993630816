import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import { initCapacitySeats } from "../../../globals/Data";
import InfoIcon from "@material-ui/icons/Info";
import IncrementSelect from "../../ui/increment/IncrementSelect";

const CapacityModal = (props) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("xs"));
  const fullWidth = true;
  const maxWidth = "sm";
  const [touched, setTouched] = useState(false);

  const [aSetLimit, setASetLimit] = useState(null);

  let minOnlineSeats = 1;
  let minClassSeats = 1;
  let minClassHandsonSeats = 1;

  if (props.capacity[props.course.id]) {
    const onlineSeated = props.capacity[props.course.id].onlineCount;
    const classSeated = props.capacity[props.course.id].classroomLectureCount;
    const classHandsonSeated =
      props.capacity[props.course.id].classroomHandsonCount;

    if (props.course.seats_online !== -2) {
      minOnlineSeats = onlineSeated - props.course.seats_online;
    } else {
      minOnlineSeats = 0;
    }

    minClassSeats = classSeated - props.course.seats_classroom;
    minClassHandsonSeats = classHandsonSeated - props.course.seats_handson;
  }

  const [seats, setSeats] = useState({
    online: props.course.seats_online === -2 ? null : 0,
    classroom: 0,
    handson: 0,
  });

  // ------------------------------

  const handleClose = (seats) => {
    if (seats) {
      props.onClose(seats);
    } else {
      props.onClose();
    }
  };

  // ------------------------------

  const selectChangeHandler = (key, e, select) => {
    const newSeats = { ...seats };
    newSeats[key] = select ? Number(select.value) : "";
    setSeats(newSeats);
    setTouched(true);
  };

  // ------------------------------

  const renderTitle = (min, capacity, type) => {
    if (capacity === -2) {
      return (
        <div>
          (Unlimited) <span style={{ fontWeight: "bold" }}>{type}</span>:
        </div>
      );
    } else {
      return (
        <div>
          (Remaining {Math.abs(min)} / {capacity}) Increase / decrease{" "}
          <span style={{ fontWeight: "bold" }}>{type}</span> capacity by:
        </div>
      );
    }
  };

  // ------------------------------

  const setLimitHandler = () => {
    const newSeats = { ...seats };
    newSeats["online"] = 0;
    setSeats(newSeats);
    setASetLimit(props.capacity[props.course.id].onlineCount);
  };

  // ------------------------------

  return (
    <div>
      <Dialog
        fullScreen={fullScreen}
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={true}
        onClose={handleClose.bind(this, null)}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="alert-dialog-title">Update Capacity</DialogTitle>

        <div
          style={{
            width: "100%",
            textAlign: "justify",
            padding: "20px",
            display: "flex",
            justifyContent: "center",
            backgroundColor: "#ccc",
          }}
        >
          <InfoIcon />
          <div style={{ marginLeft: "10px" }}>
            If capacity reaches 100% no further registration will be permitted.
          </div>
        </div>

        <DialogContent>
          {props.course.seats_online !== "N/A" &&
            props.course.seats_online !== -1 && (
              <IncrementSelect
                unlimited={props.course.seats_online === -2}
                fullWidth
                selectValue={seats.online}
                onUpdate={(value) => {
                  const newSeats = { ...seats };
                  newSeats["online"] = value;
                  setSeats(newSeats);
                  setTouched(true);
                }}
                initialValue={0}
                maxValue={100}
                minValue={minOnlineSeats}
                title={renderTitle(
                  minOnlineSeats,
                  aSetLimit !== null ? aSetLimit : props.course.seats_online,
                  props.course.is_video ? "Online Video" : "Livestream"
                )}
                name="online"
                id="online"
                disableClearable={true}
                onChange={selectChangeHandler.bind(this, "online")}
                options={initCapacitySeats(minOnlineSeats, null)}
                value={{
                  value: seats.online + "",
                  label: seats.online + "",
                }}
                getOptionLabel={(option) => option.label}
                getOptionSelected={(opt1, opt2) => opt1.value === opt2.value}
                setLimit={setLimitHandler}
              />
            )}

          {props.course.seats_classroom !== "N/A" &&
            props.course.seats_classroom !== -1 && (
              <IncrementSelect
                fullWidth
                selectValue={seats.classroom}
                onUpdate={(value) => {
                  const newSeats = { ...seats };
                  newSeats["classroom"] = value;
                  setSeats(newSeats);
                  setTouched(true);
                }}
                initialValue={0}
                maxValue={100}
                minValue={minClassSeats}
                title={renderTitle(
                  minClassSeats,
                  props.course.seats_classroom,
                  "Classroom Lecture"
                )}
                name="classroom"
                id="classroom"
                onChange={selectChangeHandler.bind(this, "classroom")}
                options={initCapacitySeats(minClassSeats, null)}
                value={{
                  value: seats.classroom + "",
                  label: seats.classroom + "",
                }}
                getOptionLabel={(option) => option.label}
                getOptionSelected={(opt1, opt2) => opt1.value === opt2.value}
              />
            )}

          {props.course.seats_handson !== "N/A" &&
            props.course.seats_handson !== -1 && (
              <IncrementSelect
                fullWidth
                selectValue={seats.handson}
                onUpdate={(value) => {
                  const newSeats = { ...seats };
                  newSeats["handson"] = value;
                  setSeats(newSeats);
                  setTouched(true);
                }}
                initialValue={0}
                maxValue={100}
                minValue={minClassHandsonSeats}
                title={renderTitle(
                  minClassHandsonSeats,
                  props.course.seats_handson,
                  "Classroom Hands-on"
                )}
                name="handson"
                id="handson"
                onChange={selectChangeHandler.bind(this, "handson")}
                options={initCapacitySeats(minClassHandsonSeats, null)}
                value={{
                  value: seats.handson + "",
                  label: seats.handson + "",
                }}
                getOptionLabel={(option) => option.label}
                getOptionSelected={(opt1, opt2) => opt1.value === opt2.value}
              />
            )}
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            onClick={handleClose.bind(this, null)}
            color="primary"
          >
            Cancel
          </Button>
          <Button
            disabled={!touched}
            autoFocus
            onClick={handleClose.bind(this, seats)}
            color="primary"
          >
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default CapacityModal;
