import {
  SET_BALANCE_SHEET,
  SET_DASHBOARD_LINK,
  CONNECT_ADMIN_SUCCESS,
  SET_STRIPE_BALANCE,
  SET_ACCOUNT,
  SET_STRIPE_BANK,
  DISMISS_ACCOUNT_WARNINGS,
} from "../actions/connect";

import Account from "../../model/Account";

import { LOGOUT } from "../actions/auth";

const initialState = {
  dashboardLink: null,
  account: {},
  bankAccount: [],
  balanceList: [],
  total: null,
  available: null,
  pending: null,
  inTransit: null,
  connectAdminSuccess: false,
  cursorFirst: null,
  cursorLast: null,
};

const connectReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_ACCOUNT:
      return {
        ...state,
        account: action.account,
      };

    case DISMISS_ACCOUNT_WARNINGS:
      const accountCopy = { ...state.account };

      return {
        ...state,
        account: new Account(
          accountCopy.id,
          accountCopy.charges_enabled,
          accountCopy.payouts_enabled,
          accountCopy.email,
          accountCopy.country,
          accountCopy.default_currency,
          accountCopy.requirements,
          true
        ),
      };
    case SET_BALANCE_SHEET:
      return {
        ...state,
        balanceList: action.concatBalanceList
          ? state.balanceList.concat(action.balanceList)
          : action.balanceList,
        cursorFirst: action.cursorFirst,
        cursorLast: action.cursorLast,
        hasMore: action.hasMore,
      };
    case SET_STRIPE_BANK:
      return {
        ...state,
        bankAccount: action.bankAccount,
      };
    case SET_STRIPE_BALANCE:
      return {
        ...state,
        total: action.total,
        available: action.available,
        pending: action.pending,
        inTransit: action.inTransit,
      };
    case CONNECT_ADMIN_SUCCESS:
      return {
        ...state,
        connectAdminSuccess: action.value,
      };
    case SET_DASHBOARD_LINK:
      return {
        ...state,
        dashboardLink: action.dashboardLink,
      };
    case LOGOUT:
      return initialState;
    default:
      return state;
  }
};

export default connectReducer;
