import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import ImageCroper from "../../ui/image/ImageCroper";

const ImageCropModal = (props) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("xs"));
  const fullWidth = true;
  const maxWidth = "sm";

  const [croppedImage, setCroppedImage] = useState(
    props.defaultFile ? props.defaultFile : null
  );

  const cancelHandler = () => {
    props.onClose(null);
  };

  const saveHandler = () => {
    props.onClose(croppedImage);
  };

  return (
    <div>
      <Dialog
        fullScreen={fullScreen}
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={props.open}
        onClose={cancelHandler}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="alert-dialog-title">{props.title}</DialogTitle>

        <DialogContent>
          <ImageCroper
            locked={props.unlocked ? false : true}
            finalImageSize={props.finalImageSize}
            aspect={props.aspect}
            file={props.file}
            onCrop={(url) => {
              setCroppedImage(url);
            }}
          />
        </DialogContent>

        <DialogActions>
          <Button autoFocus onClick={cancelHandler} color="primary">
            Cancel
          </Button>
          <Button autoFocus onClick={saveHandler} color="primary">
            Crop and Select
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ImageCropModal;
