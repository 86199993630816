import React from "react";
import styles from "./CertLinkModal.module.css";
import Button from "@material-ui/core/Button";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import FlatList from "flatlist-react";
import PhonelinkEraseIcon from "@material-ui/icons/PhonelinkErase";
import SchoolIcon from "@material-ui/icons/School";
import { useTheme } from "@material-ui/core/styles";

const CertLinkModal = (props) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("xs"));
  const fullWidth = true;
  const maxWidth = "sm";

  // ------------------------------

  const unlinkCert = (certLink) => {
    props.unlinkCert(certLink);
  };

  // ------------------------------

  const handleClose = (answer) => {
    props.onClose(answer);
  };

  // ------------------------------

  const renderCertificateLink = (item, index) => {
    return (
      <div key={index} className={styles.itemContainer}>
        <div className={styles.row}>
          <SchoolIcon />
          &nbsp;&nbsp;{item.collection_title} - {item.title}
        </div>
        <div
          className={styles.removeButton}
          onClick={unlinkCert.bind(this, item)}
        >
          Delete&nbsp;&nbsp;
          <PhonelinkEraseIcon />
        </div>
      </div>
    );
  };

  // ------------------------------

  return (
    <div>
      <Dialog
        fullScreen={fullScreen}
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={true}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="alert-dialog-title">
          Courses Using ({props.certTitle}) Template
        </DialogTitle>

        <DialogContent className={styles.container}>
          <FlatList
            list={props.list}
            renderItem={renderCertificateLink}
            renderWhenEmpty={() => (
              <div style={{ fontSize: "small", padding: "20px" }}>
                No courses are using this certificate template.
              </div>
            )}
          />
        </DialogContent>

        <DialogActions>
          <Button
            onClick={handleClose.bind(this, "ok")}
            color="primary"
            style={{ color: "white", backgroundColor: "#3E8CAB" }}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default CertLinkModal;
