export const SET_COURSE_REDIRECT = "SET_COURSE_REDIRECT";
export const SET_PROFILE_TO_COURSE_REDIRECT = "SET_PROFILE_TO_COURSE_REDIRECT";
export const SET_AUTO_SELECT_PAYMENT_METHOD = "SET_AUTO_SELECT_PAYMENT_METHOD";

// ------------------------------

export const setCourseDetailsRedirect = (redirect) => {
  return {
    type: SET_COURSE_REDIRECT,
    redirect: redirect,
  };
};

// ------------------------------

export const setProfileToCourseRedirect = (redirect) => {
  return {
    type: SET_PROFILE_TO_COURSE_REDIRECT,
    redirect: redirect,
  };
};

// ------------------------------

export const setAutoSelectPaymentMethod = (value) => {
  return {
    type: SET_AUTO_SELECT_PAYMENT_METHOD,
    autoSelect: value,
  };
};
