import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import styles from "./Ce.module.css";
import { connect } from "react-redux";
import * as profileActions from "../../../store/actions/profile";
import * as ceActions from "../../../store/actions/ces";
import * as settingActions from "../../../store/actions/setting";
import * as regActions from "../../../store/actions/registration";
import MatTabs from "../../../components/ui/tabs/MatTabs";
import Tab from "@material-ui/core/Tab";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import Spinner from "../../../components/ui/spinner/Spinner";
import CeItem from "../../../components/items/ceItem/CeItem";
import ConfirmModal from "../../../components/modals/confirmModal/ConfirmModal";
import Empty from "../../../components/ui/empty/Empty";
import { objectHasNoValues } from "../../../globals/Common";
import * as logger from "../../../globals/Logger";
import dentist from "../../../assets/lottie/chart.json";
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import DateRangeIcon from '@material-ui/icons/DateRange';
import ResponsiveIconButton from "../../../components/ui/button/ResponsiveIconButton"
import NumberFormat from "react-number-format";
import { resolveCurrencySymbol } from "../../../globals/Common";
import DateRangeModal from "../../../components/modals/dateModal/DateRangeModal";
import { momentShortDate, momentNumericDate, incrementYearOfDate } from "../../../globals/Dates2"
import AlertSuccess from "../../../components/ui/snackbar/AlertSuccess";
import InfoModal from "../../../components/modals/infoModal/InfoModal";
import CeExportConfirmModal from "../../../components/modals/ceExportConfirmModal/CeExportConfirmModal";
import { CSVLink } from "react-csv";
import JSZip from "jszip";
import { saveAs } from 'file-saver';

const currentYear = new Date().getFullYear();
const DATE_TIMESTAMP = momentShortDate(new Date()).replaceAll(" ", "_");


class CeScreen extends Component {
    state = {
        alertSuccess: null,
        wait: false,
        waitMessage: null,
        openDeleteConfirmModal: false,
        idToBeDeleted: null,
        error: null,
        activeTab: 0,
        openDateModal: false,
        openExportModal: false,
        dateSelectionType: "current_year",
        dateFrom: null,
        dateTo: null,
        csvLink: React.createRef()
    };

    // ------------------------------

    update = async (propValue) => {
        await this.setState({ ...this.state, ...propValue });
    };

    // ------------------------------

    componentDidMount = async () => {
        try {
            window.scrollTo(0, 0);
            await this.update({ wait: true });
            const loadAsync = [];

            if (objectHasNoValues(this.props.mobileSetting)) {
                await this.props.fetchMobileSetting();
            }

            if (objectHasNoValues(this.props.mobileUserProfile)) {
                loadAsync.push(this.props.fetchMobileUserProfile());
            }

            await Promise.all(loadAsync);
            await this.props.fetchCeByYear(currentYear, currentYear);
            await this.update({ wait: false });
        } catch (err) {
            logger.error(err);
            this.update({ wait: false, error: err.message });
        }
    };

    // ------------------------------

    addHandler = async () => {
        this.props.setEditAction(null);
        this.props.history.push("/editCe");
    };

    // ------------------------------

    editHandler = (ceObj) => {
        this.props.setEditAction(ceObj);
        this.props.history.push("/editCe");
    };

    // ------------------------------

    viewCourseHandler = (ceObj) => { };

    // ------------------------------

    deleteHandler = (id) => {
        this.update({
            openDeleteConfirmModal: true,
            idToBeDeleted: id,
        });
    };

    // ------------------------------

    deleteConfirmHandler = async (answer) => {
        if (answer === "ok") {
            try {
                await this.update({ wait: true, openDeleteConfirmModal: false });
                await this.props.removeCe(this.state.idToBeDeleted);
                await this.update({ wait: false, idToBeDeleted: null });
            } catch (err) {
                logger.error(err);
                this.update({
                    wait: false,
                    error: err.message,
                });
            }
        } else {
            this.update({
                openDeleteConfirmModal: false,
                idToBeDeleted: null,
            });
        }
    };

    // ------------------------------

    tabClickHandler = (tabId) => {
        if (tabId === 0) {
            this.update({
                activeTab: tabId
            });
        } else {
            this.update({
                activeTab: tabId
            });
        }
    };

    // ------------------------------

    isCompletedTab = () => {
        return this.state.activeTab === 0
    }

    // ------------------------------

    applyDateFilterHandler = async (selection, from, to) => {
        try {
            await this.update({ wait: true, openDateModal: false });

            if (selection) {
                const qFromYear = this.resolveYearForQuery(selection, from, true);
                const qToYear = this.resolveYearForQuery(selection, to, false);

                if (selection === "custom") {
                    await this.props.fetchCeByYear(qFromYear, qToYear, from, to);
                }
                else {
                    await this.props.fetchCeByYear(qFromYear, qToYear);
                }
            }

            await this.update({ dateSelectionType: selection, dateFrom: from, dateTo: to, wait: false });
        } catch (err) {
            logger.error(err);
            this.update({
                wait: false,
                error: err.message,
            });
        }
    }

    // ------------------------------

    resolveYearForQuery = (selectionType, date, isFrom) => {
        if (selectionType === "custom" && date) {
            return date.getFullYear();
        }
        else if (selectionType === "last_three_years" && isFrom) {
            return incrementYearOfDate(new Date(), -2).getFullYear();
        }
        else if (selectionType === "all" && isFrom) {
            return incrementYearOfDate(new Date(), -1000).getFullYear();
        }
        else {
            return new Date().getFullYear();
        }
    }

    // ------------------------------

    resolveDateSelection = () => {
        if (this.state.dateSelectionType === "custom" && this.state.dateFrom && this.state.dateTo) {
            return momentShortDate(this.state.dateFrom) + "-" + momentShortDate(this.state.dateTo)
        }
        else if (this.state.dateSelectionType === "last_three_years") {
            return incrementYearOfDate(new Date(), -2).getFullYear() + "-" + new Date().getFullYear().toString();
        }
        else if (this.state.dateSelectionType === "all") {
            return "All Time"
        }
        else {
            return new Date().getFullYear().toString();
        }
    }

    // ------------------------------

    convertToCsv = (ces) => {
        const acceptEmpty = (object) => {
            return object ? object + "" : "";
        };

        const data = [];
        for (const ce of ces) {
            const row = {}
            const t = this.props.t;
            row[`${t("ce_title")}`] = acceptEmpty(ce.title);
            row[`${t("ce_description")}`] = acceptEmpty(ce.description);
            row[`${t("course_notes")}`] = acceptEmpty(ce.notes);
            row[`${t("ce_credit_lecture_hours")}`] = acceptEmpty(ce.lecture_hours);
            row[`${t("ce_credit_handson_hours")}`] = acceptEmpty(ce.practice_hours);
            row[`${t("ce_credit_odq")}`] = acceptEmpty(ce.credits);
            row[`${t("course_accreditation")}`] = ce.category ? ce.category.join(" ") : "";
            row[`${t("ce_course_provider")}`] = acceptEmpty(ce.organizer);
            row["Date"] = ce.courseDate ? momentNumericDate(new Date(ce.courseDate)) : "";
            row[`${t("g_price")}`] = acceptEmpty(ce.price);
            row[`${t("ce_currency")}`] = acceptEmpty(ce.currency);
            data.push(row)
        }

        return data
    }


    // ------------------------------

    exportCeToExcelConfirm = async () => {
        await this.update({ openExportModal: true });
    };

    // ------------------------------

    exportCeToExcel = async (includeCerts) => {
        try {
            await this.update({ wait: true, waitMessage: this.props.t("ce_downloading_certificates") });

            if (includeCerts) {
                const zipFilename = `ce_export_${DATE_TIMESTAMP}.zip`;
                const zip = new JSZip();
                const certURLs = [];

                for (const ce of this.props.completedCE) {
                    let downloadUrl = null;
                    const meta = await this.props.fetchCePictureMetadata(ce.id);

                    if (meta) {
                        downloadUrl = await this.props.fetchCePicture(ce.id);
                    }

                    if (meta && downloadUrl) {
                        certURLs.push({
                            title: ce.title,
                            url: downloadUrl,
                            extention: this.resolveExtention(meta)
                        })
                    }

                    if (ce.courseId) {
                        try {
                            const registration = await this.props.getRegistrationByCourseId(ce.courseId);

                            if (registration && registration.certificate_uri) {
                                certURLs.push({
                                    title: ce.title,
                                    url: registration.certificate_uri,
                                    extention: "_en.pdf"
                                })
                            }

                            if (registration && registration.certificate_uri_fr) {
                                certURLs.push({
                                    title: ce.title,
                                    url: registration.certificate_uri_fr,
                                    extention: "_fr.pdf"
                                })
                            }
                        }
                        catch (error) { }
                    }
                }

                let count = 0;

                certURLs.forEach(function (ceEntry) {
                    const xhr = new XMLHttpRequest();
                    xhr.responseType = 'blob';
                    xhr.onload = (event) => {
                        const blob = xhr.response;
                        zip.file(ceEntry.title + ceEntry.extention, blob, { binary: true });
                        count++
                        if (count === certURLs.length) {
                            zip.generateAsync({ type: 'blob' }).then(content => {
                                saveAs(content, zipFilename);
                            });
                        }
                    };
                    xhr.open('GET', ceEntry.url);
                    xhr.send();
                });
            }

            this.state.csvLink.current.link.click();
            await this.update({ wait: false, waitMessage: null });
        } catch (error) {
            logger.error(error);
            this.update({
                wait: false,
                waitMessage: null,
                error: error.message,
            });
        }
    };

    // ------------------------------

    resolveExtention = (meta) => {
        return meta.contentType === "image/png" ?
            ".png" :
            meta.contentType === "image/jpg" ?
                ".jpg" :
                meta.contentType === "application/pdf" ?
                    ".pdf" :
                    ""
    }

    // ------------------------------

    render() {
        if (this.state.wait) {
            return <div>
                <CSVLink
                    data={this.convertToCsv(this.props.ce)}
                    filename={`ce_export_${DATE_TIMESTAMP}.csv`}
                    className="hidden"
                    ref={this.state.csvLink}
                    target="_blank"
                />
                <Spinner message={this.state.waitMessage} />
            </div>
        }

        if (!this.props.ce || this.props.ce.length === 0) {
            return (
                <Empty
                    title={this.props.t("ce_empty_title")}
                    column
                    lottie={dentist}
                    dontLoop
                    message={<div>{this.props.t("ce_empty_blurb")}</div>}
                    onClick={this.addHandler}
                    buttonIcon={<AddCircleIcon />}
                    buttonLabel={this.props.t("ce_new_entry")}
                />
            );
        }

        return (
            <div>
                <CSVLink
                    data={this.convertToCsv(this.props.ce)}
                    filename={"ce_export.csv"}
                    className="hidden"
                    ref={this.state.csvLink}
                    target="_blank"
                />
                {this.state.error && (
                    <InfoModal
                        isErrorModal={true}
                        title={this.props.t("error_occurred")
                        }
                        message={this.state.error}
                        open={this.state.message !== null}
                        onClose={() => {
                            this.update({ error: null });
                        }}
                    />
                )}
                {this.state.openExportModal && (
                    <CeExportConfirmModal
                        title={""}
                        open={this.state.openExportModal !== null}
                        onClose={async (confirm, includeCerts) => {
                            await this.update({ openExportModal: null });
                            if (confirm) {
                                await this.exportCeToExcel(includeCerts);
                            }
                        }}
                    />
                )}
                {this.state.alertSuccess !== null && (
                    <AlertSuccess
                        message={this.state.alertSuccess}
                        open={this.state.alertSuccess !== null}
                        onClose={() => {
                            this.update({ alertSuccess: null });
                        }}
                    />
                )}
                {this.state.openDeleteConfirmModal && (
                    <ConfirmModal
                        title={this.props.t("g_confirmation_required")}
                        message={this.props.t("g_are_you_sure_delete")}
                        open={this.state.openDeleteConfirmModal}
                        onClose={this.deleteConfirmHandler}
                        okButtonText={this.props.t("g_delete")}
                    />
                )}
                {this.state.openDateModal && (
                    <DateRangeModal
                        selection={this.state.dateSelectionType}
                        dateFrom={this.state.dateFrom}
                        dateTo={this.state.dateTo}
                        open={this.state.openDateModal}
                        onClose={async (selection, from, to, isCancel) => {
                            if (selection && !isCancel) {
                                await this.applyDateFilterHandler(selection, from, to);
                            }
                            else {
                                this.update({ openDateModal: false })
                            }
                        }}
                    />
                )}
                <div style={{ height: "200px" }}></div>
                <MatTabs
                    headerTitle={this.props.t("g_manage_ce") + " (" +
                        (Number(this.props.completed) +
                            Number(this.props.pending)) + ")"}
                    tabsStyle={{ height: "60px", backgroundColor: "white", borderBottom: "1px solid #ccc" }}
                    onClick={this.tabClickHandler}
                    activeTab={this.state.activeTab}
                    centered={false}
                    otherComponent={
                        <div className={styles.iconButtonContainer}>&nbsp;&nbsp;
                            <ResponsiveIconButton
                                title={this.props.t("g_new")}
                                icon={<AddCircleIcon fontSize={"small"} />}
                                filterCount={0}
                                onClick={this.addHandler}
                            />&nbsp;&nbsp;&nbsp;&nbsp;
                            <ResponsiveIconButton
                                title={this.props.t("g_export")}
                                icon={<CloudDownloadIcon fontSize={"small"} />}
                                filterCount={0}
                                onClick={this.exportCeToExcelConfirm}
                            />
                            &nbsp;&nbsp;&nbsp;&nbsp;
                            {this.isCompletedTab() && <ResponsiveIconButton
                                title={"Date: " + this.resolveDateSelection()}
                                icon={<DateRangeIcon fontSize={"small"} />}
                                filterCount={0}
                                onClick={() => {
                                    this.update({ openDateModal: true })
                                }}
                            />}
                        </div>
                    }
                >
                    <Tab label={this.props.t("ce_completed") + " (" + this.props.completed + ")"}
                        style={{
                            borderLeft: "1px solid black",
                            borderRight: "1px solid black",
                            borderTop: "1px solid black",
                            borderTopLeftRadius: "20px",
                            borderTopRightRadius: "20px",
                            borderColor: this.isCompletedTab() ? "black" : "#ccc",
                            backgroundColor: this.isCompletedTab() ? "white" : null
                        }} />
                    <Tab label={this.props.t("ce_pending") + " (" + this.props.pending + ")"}
                        style={{
                            borderLeft: "1px solid black",
                            borderRight: "1px solid black",
                            borderTop: "1px solid black",
                            borderTopLeftRadius: "20px",
                            borderTopRightRadius: "20px",
                            borderColor: !this.isCompletedTab() ? "black" : "#ccc",
                            backgroundColor: !this.isCompletedTab() ? "white" : null
                        }} />
                </MatTabs>
                <div className={styles.summaryContainer}>
                    {this.isCompletedTab() ?
                        <div>
                            &nbsp;({this.props.t("terms_lecture")}: {this.props.completedLecture} /{" "}
                            {this.props.t("terms_handson")}: {this.props.completedHandsOn} / Total:{" "}
                            <NumberFormat
                                value={this.props.completedCost}
                                displayType={"text"}
                                thousandSeparator={true}
                                prefix={this.props.currencySymbol}
                            />)
                        </div>
                        : <div>
                            &nbsp;({this.props.t("terms_lecture")}: {this.props.pendingLecture} /{" "}
                            {this.props.t("terms_handson")}: {this.props.pendingHandsOn} / Total:{" "}
                            <NumberFormat
                                value={this.props.pendingCost}
                                displayType={"text"}
                                thousandSeparator={true}
                                prefix={this.props.currencySymbol}
                            />)
                        </div>}
                </div>
                <div>
                    {this.isCompletedTab() && this.props.completedCE.map((item, index) => (
                        <CeItem
                            key={index}
                            courseId={item.courseId}
                            id={item.id}
                            hasCertificate={item.hasCertificate}
                            readableDate={item.readableDate}
                            title={item.title}
                            credits={item.resolveCredits(this.props.mobileUserProfile.isODQ())}
                            category={item.resolveCategory()}
                            practice_hours={item.practice_hours}
                            lecture_hours={item.lecture_hours}
                            price={item.price}
                            currency={item.currency}
                            edit={this.editHandler.bind(this, item)}
                            delete={this.deleteHandler.bind(this, item.id)}
                            viewCourse={this.viewCourseHandler.bind(this, item)}
                        />
                    ))}
                    {!this.isCompletedTab() && this.props.pendingCE.map((item, index) => (
                        <CeItem
                            key={index}
                            id={item.id}
                            courseId={item.courseId}
                            hasCertificate={item.hasCertificate}
                            readableDate={item.readableDate}
                            title={item.title}
                            credits={item.resolveCredits(this.props.mobileUserProfile.isODQ())}
                            category={item.resolveCategory()}
                            practice_hours={item.practice_hours}
                            lecture_hours={item.lecture_hours}
                            price={item.price}
                            currency={item.currency}
                            edit={this.editHandler.bind(this, item)}
                            delete={this.deleteHandler.bind(this, item.id)}
                            viewCourse={this.viewCourseHandler.bind(this, item)}
                        />
                    ))}
                </div>
            </div >
        );
    }
}

// ------------------------------

const mapStateToProps = (state) => {
    const ces = state.ce.ce;
    const pendingCE = ces.filter((ce) => new Date(ce.courseDate).getTime() > new Date().getTime());
    const completedCE = ces.filter((ce) => new Date(ce.courseDate).getTime() < new Date().getTime());
    const currencySymbol = resolveCurrencySymbol(state.setting.mobileSetting.currency);

    return {
        mobileSetting: state.setting.mobileSetting,
        mobileUserProfile: state.profile.userProfile,
        paymentMethods: state.payment.payment,
        isMobileUser: state.auth.isMobileUser,
        ce: ces,
        pending: state.ce.pending,
        pendingLecture: state.ce.pendingLecture,
        pendingHandsOn: state.ce.pendingHandsOn,
        pendingCost: state.ce.pendingCost,
        completed: state.ce.completed,
        completedLecture: state.ce.completedLecture,
        completedHandsOn: state.ce.completedHandsOn,
        completedCost: state.ce.completedCost,
        currencySymbol: currencySymbol,
        pendingCE: pendingCE,
        completedCE: completedCE,
    };
};

// ------------------------------

const mapDispatchToProps = (dispatch) => {
    return {
        setEditAction: (ceObj) => dispatch(ceActions.setEditAction(ceObj)),
        fetchCeByYear: (fromYear, toYear, fromDate, toDate, badge) =>
            dispatch(ceActions.fetchCeByYear(fromYear, toYear, fromDate, toDate, badge)),
        removeCe: (id) => dispatch(ceActions.removeCe(id)),
        fetchMobileSetting: () => dispatch(settingActions.fetchMobileSetting()),
        fetchMobileUserProfile: () => dispatch(profileActions.fetchMobileUserProfile()),
        fetchCePicture: (ceId) => dispatch(ceActions.fetchCePicture(ceId)),
        fetchCePictureMetadata: (ceId) => dispatch(ceActions.fetchCePictureMetadata(ceId)),
        getRegistrationByCourseId: (courseId) => dispatch(regActions.getRegistrationByCourseId(courseId)),
    };
};

export default withTranslation("translations")(
    connect(mapStateToProps, mapDispatchToProps)(CeScreen)
);
