import React, { Component } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import styles from "./MobileCart.module.css";
import * as mobilecartActions from "../../../store/actions/mobilecart";
import * as settingActions from "../../../store/actions/setting";
import FlatList from "flatlist-react";
import Button from "@material-ui/core/Button";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import SearchIcon from "@material-ui/icons/Search";
import Spinner from "../../../components/ui/spinner/Spinner";
import PlayCircleOutlineIcon from "@material-ui/icons/PlayCircleOutline";
import SimpleContainer from "../../../components/ui/container/Container";
import SimpleCard from "../../../components/ui/card/SimpleCard";
import Empty from "../../../components/ui/empty/Empty";
import cartImg from "../../../assets/images/inApp/cart.png";
import InfoModal from "../../../components/modals/infoModal/InfoModal";
import MobileCartProviderItem from "../../../components/items/mobileCartProviderItem/MobileCartProviderItem";
import {
  resolveCurrencySymbol,
  formatDecimal,
  objectHasNoValues,
} from "../../../globals/Common";
import * as logger from "../../../globals/Logger";

class MobileCartScreen extends Component {
  state = {
    wait: false,
    error: null,
  };

  // ------------------------------

  update = async (propValue) => {
    await this.setState({ ...this.state, ...propValue });
  };

  // ------------------------------

  componentDidMount = async () => {
    try {
      await this.update({ wait: true });
      const loadAsync = [];

      if (objectHasNoValues(this.props.mobileSetting)) {
        loadAsync.push(this.props.fetchMobileSetting());
      }

      if (this.props.cartItems && this.props.cartTotalAmount) {
        loadAsync.push(
          this.props.saveCart(this.props.cartItems, this.props.cartTotalAmount)
        );
      }

      await Promise.all(loadAsync);
      await this.update({ wait: false });
    } catch (err) {
      logger.error(err);
      this.update({ wait: false, error: err.message });
    }
  };

  // ------------------------------

  calcTotalInCartItems = (cartItems, rates) => {
    let total = 0;
    for (const cartItem of cartItems) {
      total += cartItem.calcProviderViewSubTotal(rates);
    }
    return formatDecimal(total);
  };

  // ------------------------------

  checkoutNowHandler = () => {
    try {
      this.update({ wait: true });
      this.props.saveCart(this.props.cartItems, this.props.cartTotalAmount);
      this.update({ wait: false });
      this.props.history.push("/checkout");
    } catch (err) {
      logger.error(err);
      this.update({ wait: false, error: err.message });
    }
  };

  // ------------------------------

  shopHandler = () => {
    this.props.history.push("/search");
  };

  // ------------------------------

  renderCartItem = (item, index) => {
    return (
      <MobileCartProviderItem
        key={index}
        item={item}
        setting={this.props.setting}
        rates={this.props.rates}
        onRemove={(courseId, pricingItem, studentId) => {
          try {
            this.props.removeFromCart(
              item.id,
              courseId,
              pricingItem,
              studentId
            );

            if (this.props.cartItems && this.props.cartItems.length <= 1) {
              if (
                !this.props.cartItems ||
                !this.props.cartItems[0].cartCourseItems ||
                objectHasNoValues(this.props.cartItems[0].cartCourseItems)
              ) {
                this.props.clearCart();
              }
            }
          } catch (error) {
            logger.error(err);
            this.update({ wait: false, error: err.message });
          }
        }}
        deletable
      />
    );
  };

  // ------------------------------

  render() {
    if (this.state.wait) {
      return <Spinner message={this.state.waitMessage} />;
    }

    if (!this.props.cartItems || this.props.cartItems.length === 0) {
      return (
        <Empty
          column
          image={cartImg}
          message={
            <p style={{ textAlign: "center", paddingTop: 20, margin: 0 }}>
              {this.props.t("cart_blurb")}
            </p>
          }
          onClick={this.shopHandler}
          buttonIcon={<SearchIcon />}
          buttonLabel={this.props.t("g_bar_search")}
        />
      );
    }

    return (
      <div style={{ background: "white" }}>
        <InfoModal
          isErrorModal={true}
          title={this.props.t("error_occurred")}
          error={this.state.error}
          open={this.state.error !== null}
          onClose={() => {
            this.update({
              error: null,
            });
          }}
        />

        <SimpleContainer disableGutters={false}>
          <div style={{ height: "25px" }}>&nbsp;</div>
          <div className={styles.sectionTitle}>
            <div className={styles.centerRow}>
              <ShoppingCartIcon style={{ color: "white" }} />
              &nbsp;&nbsp;{this.props.t("cart_title")}
            </div>
          </div>

          <SimpleCard>
            <FlatList
              list={this.props.cartItems}
              renderItem={this.renderCartItem}
            />
          </SimpleCard>

          <SimpleCard>
            <div className={styles.summaryRow}>
              <div>
                <Button
                  variant="contained"
                  color="primary"
                  className={styles.button}
                  startIcon={<PlayCircleOutlineIcon />}
                  disabled={
                    !this.props.cartItems || this.props.cartItems.length === 0
                  }
                  onClick={this.checkoutNowHandler}
                >
                  {this.props.t("cart_checkout_now")}
                </Button>
              </div>
              <div className={styles.priceTotal}>
                Total:&nbsp;
                {resolveCurrencySymbol(this.props.setting.currency)}
                {this.calcTotalInCartItems(
                  this.props.cartItems,
                  this.props.rates
                )}
              </div>
            </div>
          </SimpleCard>
        </SimpleContainer>
      </div>
    );
  }
}

// ------------------------------

const mapStateToProps = (state) => {
  return {
    cartItems: state.mobilecart.items,
    cartTotalAmount: state.mobilecart.totalAmount,
    setting: state.setting.mobileSetting,
    rates: state.rate.rate,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    clearCart: () => dispatch(mobilecartActions.clearCart()),
    fetchMobileSetting: () => dispatch(settingActions.fetchMobileSetting()),
    saveCart: (cartItems, cartTotalAmount) =>
      dispatch(mobilecartActions.saveCart(cartItems, cartTotalAmount)),
    removeFromCart: (providerId, courseId, pricingItem, studentId) =>
      dispatch(
        mobilecartActions.removeFromCart(
          providerId,
          courseId,
          pricingItem,
          studentId
        )
      ),
  };
};

export default withTranslation("translations")(
  connect(mapStateToProps, mapDispatchToProps)(MobileCartScreen)
);
