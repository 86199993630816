import React from "react";
import CameraAltIcon from "@material-ui/icons/CameraAlt";

const DivImage = (props) => {
  return (
    <div>
      {props.image ? (
        <div
          style={{
            width: "100%",
            backgroundSize: "contain",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            width: props.width,
            height: props.height,
            backgroundImage: props.image,
          }}
        />
      ) : (
        <CameraAltIcon
          style={{
            width: props.width,
            height: props.height,
            color: "#ccc",
          }}
        />
      )}
    </div>
  );
};

export default DivImage;
