import React, { Fragment, Component } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import ShoppingCartOutlinedIcon from "@material-ui/icons/ShoppingCartOutlined";
import styles from "./AdvertiseScreen.module.css";
import AudienceSelector from "../../components/ui/audienceSelector/AudienceSelector";
import FormButton from "../../components/ui/button/FormButton";
import DateSelect from "../../components/ui/dateSelect/DateSelect";
import SimpleContainer from "../../components/ui/container/Container";
import MatSwitch from "../../components/ui/switchInput/MatSwitch";
import * as cartActions from "../../store/actions/cart";
import * as courseActions from "../../store/actions/courses";
import * as profileActions from "../../store/actions/profile";
import InfoIcon from "@material-ui/icons/Info";
import MatSelectOutline from "../../components/ui/labelSelect/MatSelectOutline";
import { buildKeyValueSelect, findLabel } from "../../globals/Common";
import { FNS_MEDIUM_DATE_FORMAT, newLocalDate } from "../../globals/Dates";
import AddBuilder from "../../components/ui/addBuilder/AddBuilder";
import MatAppBar from "../../components/ui/appBar/MatAppBar";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import Button from "@material-ui/core/Button";
import LiveTvIcon from "@material-ui/icons/LiveTv";
import { momentLongDate } from "../../globals/Dates2";
import InfoModal from "../../components/modals/infoModal/InfoModal";
import CourseViewerModal from "../../components/modals/courseViewModal/CourseViewModal";
import Spinner from "../../components/ui/spinner/Spinner";
import AlertSuccess from "../../components/ui/snackbar/AlertSuccess";
import marketingFeaturedImg from "../../assets/images/inApp/marketingFeatured.png";
import marketingFeaturedStandardImg from "../../assets/images/inApp/marketingFeaturedStandard.png";
import {
  selectFeatureWeekCost,
  selectAddWeekCost,
  FEATURE_COST,
  ADD_COST,
  PRIME_COST,
  CURRENCY,
} from "../../globals/Data";
import * as logger from "../../globals/Logger";

class AdvertiseScreen extends Component {
  state = {
    locations: [],
    excludeLocations: [],
    experience: [],
    occupation: [],
    slide: 0,
    headline: null,
    callForAction: null,
    showLogo: false,
    logoUri: this.props.logoUri,
    course_id: this.props.coursePreSelect,
    course_title: this.props.courseTitlePreSelect,
    start_date: new Date(),
    num_of_weeks: 1,
    prime: false,
    prime_cost: 0,
    cost: 0,
    preview: null,
    error: null,
    success: null,
    wait: false,
    alertSuccess: null,
  };

  // ------------------------------

  update = async (propValue) => {
    await this.setState({ ...this.state, ...propValue });
  };

  // ------------------------------

  componentDidMount = async () => {
    try {
      await this.update({ error: null, wait: true });

      const loadAsync = [];

      if (!this.props.profile || Object.keys(this.props.profile).length === 0) {
        loadAsync.push(this.props.fetchProfile());
      }

      if (!this.props.courses || this.props.courses.length === 0) {
        loadAsync.push(this.props.fetchCourses());
      }

      await Promise.all(loadAsync);

      await this.update({ wait: false });
    } catch (error) {
      logger.error(error);
      await this.update({
        wait: false,
        error: error.message,
      });
    }
  };

  // ------------------------------

  enableAddToCart = () => {
    return this.props.isFeaturedAdd
      ? this.state.course_id &&
          this.props.addCategory &&
          this.state.start_date &&
          this.state.num_of_weeks > 0
      : this.state.course_id &&
          this.state.slide >= 0 &&
          this.state.headline &&
          this.state.callForAction &&
          this.state.start_date &&
          this.state.num_of_weeks > 0;
  };

  // ------------------------------

  addToCartHandler = async () => {
    const newState = { ...this.state };
    newState.prime_cost = this.calcPrimeCost();
    newState.cost = this.calcRowCost();
    newState.currency = this.props.currency.id;
    newState.addCategory = this.props.addCategory;
    newState.isFeaturedAdd = this.props.isFeaturedAdd;
    this.props.addToCart(newState);

    newState.locations = [];
    newState.excludeLocations = [];
    newState.experience = [];
    newState.occupation = [];
    newState.slide = 0;
    newState.headline = null;
    newState.callForAction = null;
    newState.showLogo = false;
    newState.logoUri = this.props.logoUri;
    newState.course_id = null;
    newState.course_title = null;
    newState.start_date = new Date();
    newState.num_of_weeks = 1;
    newState.prime = false;
    newState.prime_cost = 0;
    newState.cost = 0;

    newState.alertSuccess = "Advertisement has been added to your cart";
    this.setState(newState);
  };

  // ------------------------------

  calcRowCost = () => {
    if (this.enableAddToCart()) {
      if (this.props.isFeaturedAdd) {
        return FEATURE_COST[this.state.num_of_weeks - 1];
      } else {
        return ADD_COST[this.state.num_of_weeks - 1];
      }
    } else return 0;
  };

  // ------------------------------

  calcPrimeCost = () => {
    if (this.state.prime) {
      return this.state.num_of_weeks > 0
        ? PRIME_COST * this.state.num_of_weeks
        : PRIME_COST;
    } else return 0;
  };

  // ------------------------------

  renderTotal = () => {
    return (this.calcPrimeCost() + this.calcRowCost()).toFixed(2);
  };

  // ------------------------------

  renderFeaturedRow = () => {
    if (this.enableAddToCart()) {
      let addTypeName = "Featured ";
      if (!this.props.isFeaturedAdd) {
        addTypeName = "Fixed banner ";
      }

      return (
        <div className={styles.summaryRow}>
          <div>
            <div className={styles.summaryPriceText}>
              {this.state.course_title}
            </div>
            <div className={styles.summaryText}>
              {addTypeName} on {momentLongDate(this.state.start_date)} for{" "}
              {this.state.num_of_weeks} week
              {this.state.num_of_weeks > 1 ? "s" : null}
            </div>
          </div>
          <div>
            {this.props.currency.shortName}
            {this.calcRowCost().toFixed(2)}
          </div>
        </div>
      );
    } else {
      return null;
    }
  };

  // ------------------------------

  selectChangeHandler = (key, e, select) => {
    const newState = { ...this.state };
    newState[key] = select ? select.value : "";

    if (key === "course_id") {
      newState.course_title = select ? select.label : "";
    }

    this.setState(newState);
  };

  // ------------------------------

  matSwitchChangeHandler = (event) => {
    const newState = { ...this.state };
    newState[event.target.name] = event.target.checked;
    this.setState(newState);
  };

  // ------------------------------

  resolveDescription = () => {
    return this.props.isFeaturedAdd
      ? "Increase your course's visibility by adding it to the Zubu mobile app's featured section on the search page." +
          "  The search page is used by Dentist and Hygenist who are actively looking for continuing eductation courses."
      : "An image advertisement allows you to get instant visibility on the home page of the ZubU mobile app." +
          "  Select your course, image, enter a catchy headline and target your audience for instant traffic to your course.";
  };

  // ------------------------------

  render() {
    if (this.props.action !== "PUSH") {
      return <Redirect to="/advertise" />;
    }

    if (this.state.wait) {
      return <Spinner />;
    }

    return (
      <div style={{ backgroundColor: "white" }}>
        <MatAppBar
          static
          left={
            <Button
              color="inherit"
              style={{ color: "black" }}
              onClick={this.props.history.goBack}
              startIcon={<ArrowBackIosIcon />}
            >
              Back
            </Button>
          }
        />
        <div style={{ height: "58px" }}></div>

        <SimpleContainer disableGutters={false}>
          {this.state.alertSuccess !== null && (
            <AlertSuccess
              message={this.state.alertSuccess}
              open={this.state.alertSuccess !== null}
              onClose={() => {
                this.update({ alertSuccess: null });
              }}
            />
          )}

          {(this.state.error !== null || this.state.success !== null) && (
            <InfoModal
              isErrorModal={this.state.error !== null}
              title={
                this.state.error !== null
                  ? "An error has occured"
                  : "Add to Cart Success"
              }
              message={this.state.error ? this.state.error : this.state.success}
              open={true}
              onClose={() => {
                this.update({ error: null, success: null });
              }}
            />
          )}

          {this.state.preview && (
            <CourseViewerModal
              isAdd
              add={{
                courseTitle: this.state.course_title,
                addCategory: this.props.addCategory,
                slide: this.state.slide,
                headline: this.state.headline,
                callForAction: this.state.callForAction,
                showLogo: this.state.showLogo,
                logoUri: this.props.logoUri,
              }}
              onClose={() => {
                this.update({ preview: null });
              }}
            />
          )}

          <form className={styles.form}>
            <div className={styles.sectionTitle}>
              <LiveTvIcon style={{ marginRight: "10px" }} />
              Advertise
            </div>

            <div className={styles.pricingContainer}>
              <div className={styles.icon}>
                <InfoIcon />
              </div>
              <div>{this.resolveDescription()}</div>
            </div>

            <MatSelectOutline
              noBottomMargin
              title="Which course would you like to advertise?"
              placeholder="Enter course..."
              name="course_id"
              id="course_id"
              onChange={this.selectChangeHandler.bind(this, "course_id")}
              options={buildKeyValueSelect(
                this.props.activeCourses,
                "id",
                "title"
              )}
              value={
                this.state.course_id
                  ? {
                      value: this.state.course_id,
                      label: this.state.course_title,
                    }
                  : null
              }
              getOptionLabel={(option) => option.label}
              getOptionSelected={(opt1, opt2) => opt1.value === opt2.value}
              disabled={false}
              multiple={false}
              required={false}
            />
            <div className={styles.pricingContainer}>
              <DateSelect
                labelLong
                title="When would you like your ad to start?"
                dateFormat={FNS_MEDIUM_DATE_FORMAT}
                selected={this.state.start_date}
                minDate={new Date()}
                onChange={(date) => {
                  const newState = { ...this.state };
                  newState.start_date = newLocalDate(date);
                  this.setState(newState);
                }}
              />
            </div>
            <div style={{ height: "20px" }}>&nbsp;</div>

            {!this.props.isFeaturedAdd && (
              <Fragment>
                <div style={{ height: "10px" }}>&nbsp;</div>

                <div className={styles.summaryContainer}>
                  <AddBuilder
                    courseTitle={this.state.course_title}
                    logoUri={this.props.logoUri}
                    onChange={(addBuilder) => {
                      this.setState({
                        ...this.state,
                        callForAction: addBuilder.callForAction,
                        headline: addBuilder.headline,
                        showLogo: addBuilder.showLogo,
                        logoUri: addBuilder.logoUri,
                        slide: addBuilder.slide,
                      });
                    }}
                    onPreview={() => {
                      this.update({ preview: true });
                    }}
                  />
                </div>

                <div style={{ height: "30px" }}>&nbsp;</div>
              </Fragment>
            )}

            <div style={{ height: "20px" }}>&nbsp;</div>

            <div className={styles.summaryContainer}>
              <AudienceSelector
                locations={this.state.locations}
                excludeLocations={this.state.excludeLocations}
                countries={this.state.countries}
                experience={this.state.experience}
                occupation={this.state.occupation}
                update={(audience) => {
                  this.update({
                    locations: audience.locations,
                    excludeLocations: audience.excludeLocations,
                    countries: audience.countries,
                    experience: audience.experience,
                    occupation: audience.occupation,
                  });
                }}
              />
            </div>

            <div style={{ height: "40px" }}>&nbsp;</div>

            {this.props.isFeaturedAdd && (
              <MatSelectOutline
                disableClearable={true}
                title="How many weeks would you like to advertise for?"
                placeholder="Enter number of weeks..."
                name="num_of_weeks"
                id="num_of_weeks"
                onChange={this.selectChangeHandler.bind(this, "num_of_weeks")}
                options={buildKeyValueSelect(
                  selectFeatureWeekCost,
                  "value",
                  "label"
                )}
                value={{
                  value: this.state.num_of_weeks,
                  label: findLabel(
                    selectFeatureWeekCost,
                    this.state.num_of_weeks
                  ),
                }}
                getOptionLabel={(option) => option.label}
                getOptionSelected={(opt1, opt2) => opt1.value === opt2.value}
                disabled={false}
                multiple={false}
                required={false}
              />
            )}

            {!this.props.isFeaturedAdd && (
              <MatSelectOutline
                disableClearable={true}
                noBottomMargin
                title="How many weeks would you like to advertise for?"
                placeholder="Enter number of weeks..."
                name="num_of_weeks"
                id="num_of_weeks"
                onChange={this.selectChangeHandler.bind(this, "num_of_weeks")}
                options={buildKeyValueSelect(
                  selectAddWeekCost,
                  "value",
                  "label"
                )}
                value={{
                  value: this.state.num_of_weeks,
                  label: findLabel(selectAddWeekCost, this.state.num_of_weeks),
                }}
                getOptionLabel={(option) => option.label}
                getOptionSelected={(opt1, opt2) => opt1.value === opt2.value}
                disabled={false}
                multiple={false}
                required={false}
              />
            )}
            {this.props.isFeaturedAdd && (
              <MatSwitch
                title={`Boost visibility in a prime slot? (+${this.props.currency.shortName}45.00)`}
                id={"prime"}
                name={"prime"}
                checked={this.state.prime}
                onChange={this.matSwitchChangeHandler}
                help={
                  <div style={{ width: "400px", paddingRight: "15px" }}>
                    <p className={styles.helpBlurb}>
                      <b>Prime featured ad</b> will boost your visibility by
                      appearing in priority in the top 20 results.
                    </p>

                    <div className={styles.phoneContainer}>
                      <img
                        src={marketingFeaturedImg}
                        className={styles.phonePic}
                        alt=""
                      />
                    </div>
                    <p className={styles.helpBlurb}>
                      <b>Standard featured ad</b> may or may not appear in the
                      top 20 and will appear on the view all page.
                    </p>

                    <div className={styles.phoneContainer}>
                      <img
                        src={marketingFeaturedStandardImg}
                        className={styles.phonePic}
                        alt=""
                      />{" "}
                    </div>
                  </div>
                }
              />
            )}
            <div style={{ height: "20px" }}>&nbsp;</div>

            <div className={styles.summaryContainer}>
              {this.renderFeaturedRow()}
              {this.state.prime === true && (
                <div className={styles.summaryRow}>
                  <div className={styles.summaryTextItalic}>
                    Appear in prime slot
                  </div>
                  <div>
                    {this.props.currency.shortName}
                    {this.calcPrimeCost().toFixed(2)}
                  </div>
                </div>
              )}
              <div className={styles.summaryRowTotal}>
                <div className={styles.summaryPriceText}>Total:&nbsp;</div>
                <div className={styles.summaryPriceText}>
                  {" "}
                  {this.props.currency.shortName}
                  {this.renderTotal()}
                </div>
              </div>
            </div>
            <FormButton
              startIcon={<ShoppingCartOutlinedIcon />}
              onClick={this.addToCartHandler}
              disabled={!this.enableAddToCart()}
              label="Add to cart"
            />
          </form>
        </SimpleContainer>
      </div>
    );
  }
}

// ------------------------------

const parseQueryString = (props) => {
  const query = new URLSearchParams(props.location.search);

  for (let param of query.entries()) {
    if (param[0] === "course") {
      return param[1];
    }
  }

  return null;
};

// ------------------------------

const mapStateToProps = (state, props) => {
  //const runningAdds = state.advertisement.marketingAd;

  // ----------

  const coursePreSelect = parseQueryString(props);

  let title = null;
  const selectCourses = state.courses.courses.map((c) => {
    if (coursePreSelect && c.id === coursePreSelect) {
      title = c.title;
    }
    return {
      value: c.id,
      label: c.title,
    };
  });

  // ----------

  // currency is us for international and cad for cad
  const currency =
    state.profile.profile.currency &&
    state.profile.profile.currency.id === "CAD"
      ? CURRENCY[1]
      : CURRENCY[4];
  // ----------

  const marketingAddId = state.advertisement.marketingAddId;

  return {
    profile: state.profile.profile,
    action: props.history.action,
    selectCourses: selectCourses,
    activeCourses: state.courses.courses,
    currency: currency,
    coursePreSelect: coursePreSelect,
    courseTitlePreSelect: title,
    isFeaturedAdd: marketingAddId === "featured",
    addCategory: marketingAddId === "featured" ? "featured" : "banner",
    logoUri: state.profile.profile.logo,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addToCart: (cartItem) => dispatch(cartActions.addToCart(cartItem)),
    fetchProfile: () => dispatch(profileActions.fetchProfile()),
    fetchCourses: () => dispatch(courseActions.fetchCourses()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AdvertiseScreen);
