class MobileSetting {
  constructor(
    fav_category,
    fav_course_lang,
    language,
    currency,
    unit,
    pausePushNotifications,
    pauseEmailNotifications
  ) {
    this.fav_category = fav_category;
    this.fav_course_lang = fav_course_lang;
    this.language = language;
    this.currency = currency;
    this.unit = unit;
    this.pausePushNotifications = pausePushNotifications;
    this.pauseEmailNotifications = pauseEmailNotifications;
  }
}

export default MobileSetting;
