import React from "react";
import "./HideScrollbar.css";
import * as styles from "./HorizontalScrollCards.module.css";
import IconButton from "@material-ui/core/IconButton";
import KeyboardArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import { ScrollMenu, VisibilityContext } from "react-horizontal-scrolling-menu";
import useMediaQuery from "@material-ui/core/useMediaQuery";

const HorizontalScrollCards = (props) => {
  const items = props.items ? props.items : [];
  const [selected, setSelected] = React.useState([]);

  const smallDevice = props.smallDevice
    ? props.smallDevice
    : useMediaQuery("(max-width:599px)");
  // ------------------------------

  const isItemSelected = (id) => !!selected.find((el) => el === id);

  // ------------------------------

  const handleClick =
    (id) =>
    ({ getItemById, scrollToItem }) => {
      const itemSelected = isItemSelected(id);

      setSelected((currentSelected) =>
        itemSelected
          ? currentSelected.filter((el) => el !== id)
          : currentSelected.concat(id)
      );
    };

  // ------------------------------

  const leftArrow = () => {
    const { isFirstItemVisible, scrollPrev } =
      React.useContext(VisibilityContext);

    return (
      <div className={styles.pageButtonContainer}>
        <IconButton
          color="primary"
          size="small"
          component="span"
          aria-label=""
          disabled={isFirstItemVisible}
          onClick={() => scrollPrev()}
          style={{
            backgroundColor: isFirstItemVisible ? "#ccc" : "rgb(8, 105, 147)",
            color: isFirstItemVisible ? "gray" : "white",
          }}
        >
          <KeyboardArrowLeftIcon />
        </IconButton>
      </div>
    );
  };

  // ------------------------------

  const rightArrow = () => {
    const { isLastItemVisible, scrollNext } =
      React.useContext(VisibilityContext);

    return (
      <div className={styles.pageButtonContainer}>
        <IconButton
          color="primary"
          size="small"
          component="span"
          aria-label=""
          onClick={() => scrollNext()}
          disabled={isLastItemVisible}
          style={{
            backgroundColor: isLastItemVisible ? "#ccc" : "rgb(8, 105, 147)",
            color: isLastItemVisible ? "gray" : "white",
          }}
        >
          <KeyboardArrowRightIcon />
        </IconButton>
      </div>
    );
  };

  // ------------------------------

  const Card = ({ onClick, selected, title, itemId, component }) => {
    const visibility = React.useContext(VisibilityContext);

    return (
      <div onClick={() => onClick(visibility)} tabIndex={0}>
        {component}
      </div>
    );
  };

  // ------------------------------

  return (
    <div className={styles.container}>
      <div className={styles.title} style={props.style ? props.style : null}>
        {props.title}
      </div>

      <ScrollMenu
        LeftArrow={smallDevice ? null : leftArrow}
        RightArrow={smallDevice ? null : rightArrow}
        wrapperClassName={styles.scroll}
      >
        {items.map(({ id, component }) => (
          <Card
            itemId={id}
            title={id}
            key={id}
            component={component}
            onClick={handleClick(id)}
            selected={isItemSelected(id)}
          />
        ))}
      </ScrollMenu>
    </div>
  );
};

export default HorizontalScrollCards;
