import {
  SET_STUDENT_ORDER,
  SET_CHARGE_ID,
  CLEAR_CHARGE_ID,
  SET_ORDER_REFUND,
} from "../actions/studentorder";
import { updateObject } from "../../globals/Common";
import { LOGOUT } from "../actions/auth";

const initialState = {
  studentorder: null,
  chargeId: null,
  courseSelectedId: null,
  refund: [],
  refundTotal: 0,
};

const studentorderReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_CHARGE_ID:
      return updateObject(state, {
        chargeId: action.chargeId,
        courseSelectedId: action.courseSelectedId,
      });
    case CLEAR_CHARGE_ID:
      return updateObject(state, {
        chargeId: null,
      });
    case SET_STUDENT_ORDER:
      return updateObject(state, {
        studentorder: action.studentorder,
      });
    case SET_ORDER_REFUND:
      return updateObject(state, {
        refund: action.refund,
        refundTotal: action.refundTotal,
      });
    case LOGOUT:
      return initialState;
    default:
      return state;
  }
};

export default studentorderReducer;
