import React, { Component, Fragment, lazy, Suspense } from "react";
import { Route, Switch, Redirect, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import IdleTimer from "react-idle-timer";
import AppContainer from "./hoc/AppContainer";
import Spinner from "./components/ui/spinner/Spinner";

import WelcomeScreen from "./screens/welcome/WelcomeScreen";
import CourseScreen from "./screens/course/courseList/CourseScreen";
import StudentsScreen from "./screens/student/StudentsScreen";
import StudentOrderScreen from "./screens/student/StudentOrderScreen";
import PromoScreen from "./screens/promo/PromoScreen";
import PromoEditScreen from "./screens/promo/PromoEditScreen";
import CourseEditScreen from "./screens/course/edit/CourseEditScreen";
import AdvertiseScreen from "./screens/advertise/AdvertiseScreen";
import AdvertiseEditScreen from "./screens/advertise/AdvertiseEditScreen";
import CartScreen from "./screens/cart/CartScreen";
import CheckoutScreen from "./screens/cart/CheckoutScreen";
import OrderScreen from "./screens/orders/OrderScreen";
import MaintenanceScreen from "./screens/login/MaintenanceScreen";
import LoginScreen from "./screens/login/LoginScreen";
import Logout from "./screens/logout/Logout";
import ProfessorScreen from "./screens/professor/ProfessorScreen";
import ProfessorEditScreen from "./screens/professor/ProfessorEditScreen";
import ReviewScreen from "./screens/reviews/ReviewScreen";
import PaymentScreen from "./screens/payment/PaymentScreen";
import PaymentEditScreen from "./screens/payment/PaymentEditScreen";
import AccountScreen from "./screens/account/AccountScreen";
import AccountConnected from "./hoc/AccountConnected";
import SignupScreen from "./screens/signup/SignupScreen";
import AmendmentScreen from "./screens/amendment/AmendmentScreen";
import SettingScreen from "./screens/settings/SettingScreen";
import ForgotPasswordScreen from "./screens/forgotPassword/ForgotPasswordScreen";
import DashboardScreen from "./screens/dashboard/DashboardScreen";
import AnalyticsScreen from "./screens/analytics/AnalyticsScreen";
import PrivacyPolicyScreen from "./screens/legal/PrivacyPolicyScreen";
import GeneralScreen from "./screens/legal/GeneralScreen";
import SupplierScreen from "./screens/legal/SupplierScreen";
import GeneralAndPrivacyScreen from "./screens/legal/GeneralAndPrivacyScreen";
import MobileRedirectScreen from "./screens/welcome/MobileRedirectScreen";
import MobileRedirectQrScreen from "./screens/welcome/MobileRedirectQrScreen";
import CertificateListScreen from "./screens/certificate/CertificateListScreen";
import CertificateEditScreen from "./screens/certificate/CertificateEditScreen";

const SURVEY_ROOT = "./screens/survey/";
const SurveyScreen = lazy(() => import(`${SURVEY_ROOT}SurveyScreen`));
const SurveyListScreen = lazy(() => import(`${SURVEY_ROOT}SurveyListScreen`));
const SurveyEditScreen = lazy(() => import(`${SURVEY_ROOT}SurveyEditScreen`));

import StudentSignupScreen from "./screens/mobile/signup/StudentSignupScreen";
import MyRegistrationScreen from "./screens/mobile/registration/MyRegistrationScreen";
import UnavailableScreen from "./screens/mobile/unavailable/UnavailableScreen";
import MobileSettingScreen from "./screens/mobile/settings/MobileSettingScreen";
import MobileCartScreen from "./screens/mobile/cart/MobileCartScreen";
import MobileCheckoutScreen from "./screens/mobile/cart/MobileCheckoutScreen";
import MobileOrderScreen from "./screens/mobile/orders/MobileOrderScreen";
import StaffScreen from "./screens/mobile/staff/StaffScreen";
import StaffEditScreen from "./screens/mobile/staff/StaffEditScreen";
import CategoryScreen from "./screens/mobile/search/CategoryScreen";
import CourseSearchListScreen from "./screens/mobile/search/CourseSearchListScreen";
import CourseDetailsScreen from "./screens/mobile/search/CourseDetailsScreen";
import ProviderCourseScreen from "./screens/mobile/search/ProviderCourseScreen";
import AttendanceScreen from "./screens/attendance/AttendanceScreen";
import MemberScreen from "./screens/members/MemberScreen";
import MembershipScreen from "./screens/mobile/membership/MembershipScreen";
import CeScreen from "./screens/mobile/ce/CeScreen";
import CeEditScreen from "./screens/mobile/ce/CeEditScreen";
import VideoPlayerScreen from "./screens/mobile/player/VideoPlayerScreen";

import * as authActions from "./store/actions/auth";
import * as settingActions from "./store/actions/setting";
import * as mobileCartActions from "./store/actions/mobilecart";
import * as inboxActions from "./store/actions/inbox";
import { apiKeys, underMaintenance } from "./globals/ApiKeys";
import firebase from "firebase/app";
import "firebase/analytics";
import "firebase/auth";
import "firebase/storage";

class App extends Component {
  constructor(props) {
    super(props);
    this.idleTimer = null;
    this.handleOnAction = this.handleOnAction.bind(this);
    this.handleOnActive = this.handleOnActive.bind(this);
    this.handleOnIdle = this.handleOnIdle.bind(this);
  }

  state = {
    wait: false,
  };
  // ------------------------------

  componentDidMount = async () => {
    try {
      this.setState({ ...this.state, wait: true });
      await this.props.tryLogin();
      this.setState({ ...this.state, wait: false });
    } catch (error) {
      if (this.props.isAuthenticated) {
        this.props.logout();
      }
    }

    if (!this.props.isAuthenticated) {
      await this.props.fetchMobileSetting();
    }

    this.setState({ ...this.state, wait: false });
  };

  // ------------------------------

  componentDidUpdate = async (prevProps) => {
    if (
      this.props.isMobileUser !== prevProps.isMobileUser &&
      this.props.isMobileUser === true
    ) {
      await this.props.fetchMobileCart();
    }

    if (
      this.props.isAuthenticated &&
      this.props.isAuthenticated !== prevProps.isAuthenticated
    ) {
      await this.props.fetchConversations();
    }
  };

  // ------------------------------

  maintenanceRoutes = () => {
    let r = [];
    r.push(<Route path="/" component={MaintenanceScreen} key="1" />);
    r.push(<Route path="/mobile" component={MobileRedirectScreen} key="m" />);
    r.push(<Route path="/qr" component={MobileRedirectQrScreen} key="qr" />);
    r.push(<Redirect to="/" key="3" />);
    return r;
  };

  // ------------------------------

  publicRoutes = () => {
    let r = [];
    r.push(<Route path="/mobile" component={MobileRedirectScreen} key="m" />);
    r.push(<Route path="/qr" component={MobileRedirectQrScreen} key="qr" />);
    r.push(<Route path="/shop" component={ProviderCourseScreen} key="s" />);
    r.push(<Route path="/details" component={CourseDetailsScreen} key="d" exact />);
    r.push(<Route path="/details/:providerId/:courseId" component={CourseDetailsScreen} key="d2" />);
    r.push(<Route path="/register" component={SignupScreen} key="0" />);
    r.push(<Route path="/password" component={ForgotPasswordScreen} key="1" />);
    r.push(<Route path="/privacy" component={PrivacyPolicyScreen} key="2" />);
    r.push(<Route path="/generalterms" component={GeneralScreen} key="3" />);
    r.push(<Route path="/supplierterms" component={SupplierScreen} key="4" />);
    r.push(<Route path="/generalterms_privacy" component={GeneralAndPrivacyScreen} key="5" />);
    r.push(<Route path="/studentsurvey" component={SurveyScreen} key="7" />);
    r.push(<Route path="/login" component={LoginScreen} key="8" />);
    r.push(<Route path="/studentregister" component={StudentSignupScreen} key="9" />);
    r.push(<Route path="/courselist" component={CourseSearchListScreen} key="csls" />);
    r.push(<Route path="/aboutus" component={WelcomeScreen} key="c" />);
    r.push(<Route path="/attendance/:providerId/:courseId/:uricode" component={AttendanceScreen} key="32" />);
    r.push(<Route path="/" component={CategoryScreen} key="10" />);
    r.push(<Redirect to="/" key="12" />);
    return r;
  };

  // ------------------------------

  authProviderRoutes = () => {
    let r = [];
    r.push(<Route path="/" exact component={DashboardScreen} key="0" />);
    r.push(<Route path="/mobile" component={MobileRedirectScreen} key="m" />);
    r.push(<Route path="/qr" component={MobileRedirectQrScreen} key="qr" />);
    r.push(<Route path="/shop" component={ProviderCourseScreen} key="s" />);
    r.push(<Route path="/connect" component={AccountConnected} key="1" />);
    r.push(<Route path="/account" component={AccountScreen} key="2" />);
    r.push(<Route path="/editcourse" component={CourseEditScreen} key="3" />);
    r.push(<Route path="/students" component={StudentsScreen} key="4" />);
    r.push(<Route path="/studentorder" component={StudentOrderScreen} key="4_1" />);
    r.push(<Route path="/promo" component={PromoScreen} key="5" />);
    r.push(<Route path="/editPromo" component={PromoEditScreen} key="6" />);
    r.push(<Route path="/ganalytics" component={AnalyticsScreen} key="7" />);
    r.push(<Route path="/advertise" component={AdvertiseScreen} key="8" />);
    r.push(<Route path="/createad" component={AdvertiseEditScreen} key="8_1" />);
    r.push(<Route path="/cart" component={CartScreen} key="9" />);
    r.push(<Route path="/checkout" component={CheckoutScreen} key="10" />);
    r.push(<Route path="/payment" component={PaymentScreen} key="11" />);
    r.push(<Route path="/addpayment" component={PaymentEditScreen} key="12" />);
    r.push(<Route path="/orders" component={OrderScreen} key="15" />);
    r.push(<Route path="/professor" component={ProfessorScreen} key="16" />);
    r.push(<Route path="/editprof" component={ProfessorEditScreen} key="17" />);
    r.push(<Route path="/logout" component={Logout} key="18" />);
    r.push(<Route path="/amendment" component={AmendmentScreen} key="19" />);
    r.push(<Route path="/settings" component={SettingScreen} key="20" />);
    r.push(<Route path="/courses" component={CourseScreen} key="21" />);
    r.push(<Route path="/privacy" component={PrivacyPolicyScreen} key="22" />);
    r.push(<Route path="/generalterms" component={GeneralScreen} key="23" />);
    r.push(<Route path="/supplierterms" component={SupplierScreen} key="24" />);
    r.push(<Route path="/generalterms_privacy" component={GeneralAndPrivacyScreen} key="25" />);
    r.push(<Route path="/survey" component={SurveyListScreen} key="26" />);
    r.push(<Route path="/editsurvey" component={SurveyEditScreen} key="27" />);
    r.push(<Route path="/studentsurvey" component={SurveyScreen} key="28" />);
    r.push(<Route path="/reviews" component={ReviewScreen} key="29" />);
    r.push(<Route path="/certificate" component={CertificateListScreen} key="30" />);
    r.push(<Route path="/editcertificate" component={CertificateEditScreen} key="31" />);
    r.push(<Route path="/attendance" component={AttendanceScreen} key="32" exact />);
    r.push(<Route path="/members" component={MemberScreen} key="33" />);
    r.push(<Route path="/video/:providerId/:courseId" component={VideoPlayerScreen} key="34" />);
    r.push(<Redirect to="/" key="30" />);
    return r;
  };

  // ------------------------------

  authMobileRoutes = () => {
    let r = [];
    r.push(<Route path="/" exact component={CategoryScreen} key="0" />);
    r.push(<Route path="/mycourses" component={MyRegistrationScreen} key="1" />);
    r.push(<Route path="/cart" component={MobileCartScreen} key="3" />);
    r.push(<Route path="/checkout" component={MobileCheckoutScreen} key="4" />);
    r.push(<Route path="/mysettings" component={MobileSettingScreen} key="5" />);
    r.push(<Route path="/mystaff" component={StaffScreen} key="6" />);
    r.push(<Route path="/editstaff" component={StaffEditScreen} key="66" />);
    r.push(<Route path="/mycredits" component={UnavailableScreen} key="7" />);
    r.push(<Route path="/myexpenses" component={UnavailableScreen} key="8" />);
    r.push(<Route path="/myorders" component={MobileOrderScreen} key="10" />);
    r.push(<Route path="/mobile" component={MobileRedirectScreen} key="11" />);
    r.push(<Route path="/qr" component={MobileRedirectQrScreen} key="12" />);
    r.push(<Route path="/shop" component={ProviderCourseScreen} key="13" />);
    r.push(<Route path="/details" component={CourseDetailsScreen} key="14" exact />);
    r.push(<Route path="/details/:providerId/:courseId" component={CourseDetailsScreen} key="d2" />);
    r.push(<Route path="/payment" component={PaymentScreen} key="15" />);
    r.push(<Route path="/addpayment" component={PaymentEditScreen} key="16" />);
    r.push(<Route path="/logout" component={Logout} key="17" />);
    r.push(<Route path="/studentsurvey" component={SurveyScreen} key="18" />);
    r.push(<Route path="/privacy" component={PrivacyPolicyScreen} key="19" />);
    r.push(<Route path="/generalterms" component={GeneralScreen} key="20" />);
    r.push(<Route path="/supplierterms" component={SupplierScreen} key="21" />);
    r.push(<Route path="/generalterms_privacy" component={GeneralAndPrivacyScreen} key="22" />);
    r.push(<Route path="/search" component={CategoryScreen} key="s" />);
    r.push(<Route path="/courselist" component={CourseSearchListScreen} key="csls" />);
    r.push(<Route path="/memberships" component={MembershipScreen} key="24" />);
    r.push(<Route path="/ce" component={CeScreen} key="25" />);
    r.push(<Route path="/editce" component={CeEditScreen} key="26" />);
    r.push(<Route path="/video/:providerId/:courseId" component={VideoPlayerScreen} key="27" />);
    r.push(<Redirect to="/" key="23" />);
    return r;
  };

  // ------------------------------

  handleOnAction(event) { }

  // ------------------------------

  handleOnActive(event) { }

  // ------------------------------

  handleOnIdle(event) {
    if (this.props.isAuthenticated && !this.props.staySignedIn) {
      this.props.logout();
    }
  }

  // ------------------------------

  render() {
    let route = [];

    route = this.publicRoutes();

    if (underMaintenance) {
      route = this.maintenanceRoutes();
    } else if (this.props.isAuthenticated && this.props.isMobileUser) {
      route = this.authMobileRoutes();
    } else if (this.props.isAuthenticated) {
      route = this.authProviderRoutes();
    }

    if (!firebase.apps.length) {
      firebase.initializeApp(apiKeys.firebaseConfig);
      firebase.analytics();
    }

    if (this.state.wait) {
      return <Spinner />;
    }

    return (
      <Fragment>
        <IdleTimer
          ref={(ref) => {
            this.idleTimer = ref;
          }}
          timeout={1000 * 60 * 30}
          onActive={this.handleOnActive}
          onIdle={this.handleOnIdle}
          onAction={this.handleOnAction}
          debounce={500}
        />
        <AppContainer history={this.props.history}>
          <Suspense fallback={<Spinner message={"Loading..."} />}>
            <Switch>{route}</Switch>
          </Suspense>
        </AppContainer>
      </Fragment>
    );
  }
}

// ------------------------------

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.auth.token != null,
    isMobileUser: state.auth.isMobileUser === true,
    staySignedIn: state.auth.staySignedIn === true,
  };
};

// ------------------------------

const mapDispatchToProps = (dispatch) => {
  return {
    tryLogin: () => dispatch(authActions.tryLogin()),
    logout: () => dispatch(authActions.logout()),
    fetchMobileSetting: () => dispatch(settingActions.fetchMobileSetting()),
    fetchMobileCart: () => dispatch(mobileCartActions.fetchCart()),
    fetchConversations: () => dispatch(inboxActions.fetchConversations()),
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
