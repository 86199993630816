import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import styles from "./MobileCart.module.css";

import * as redirectActions from "../../../store/actions/redirect";
import * as paymentActions from "../../../store/actions/payment";
import * as providerActions from "../../../store/actions/provider";
import * as mobilecartActions from "../../../store/actions/mobilecart";
import * as settingActions from "../../../store/actions/setting";
import * as rateActions from "../../../store/actions/rate";

import LockIcon from "@material-ui/icons/Lock";
import ReceiptIcon from "@material-ui/icons/Receipt";
import LanguageIcon from "@material-ui/icons/Language";
import RedeemIcon from "@material-ui/icons/Redeem";
import CreditCardIcon from "@material-ui/icons/CreditCard";
import CheckCircleOutlineOutlinedIcon from "@material-ui/icons/CheckCircleOutlineOutlined";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import NumberFormat from "react-number-format";

import CartProviderItem from "../../../components/items/mobileCartProviderItem/MobileCartProviderItem";
import Spinner from "../../../components/ui/spinner/Spinner";
import PaymentMethodModal from "../../../components/modals/paymentMethodModal/PaymentMethodModal";
import MobileCheckoutButton from "./MobileCheckoutButton";
import InfoModal from "../../../components/modals/infoModal/InfoModal";
import { clientFacingError } from "../../../globals/Error";
import CreditCard from "../../../components/ui/cc/CreditCard";
import { findCurrency } from "../../../globals/Common";
import PromoCode from "../../../components/ui/promoCode/PromoCode";
import PaymentOption from "../../../components/ui/paymentOption/PaymentOption";
import { incrementMonthOfDate } from "../../../globals/Dates2";
import {
  calcPromoDiscount,
  calcMemberPromoDiscount,
  roundAmount,
  roundAmountNoFormatDecimal,
} from "../../../globals/Common";
import * as logger from "../../../globals/Logger";

// ------------------------------

const calcTotalInCartItems = (cartProvider, rates) => {
  return cartProvider.calcProviderViewSubTotal(rates);
};

// ------------------------------

const MAX_MULTI_AMOUNT = 2000;

class MobileCheckoutScreen extends Component {
  state = {
    cart: null,
    paymentMethod: null,
    paymentModalOpen: false,
    orderSuccessModalOpen: false,
    isSubmitting: false,
    submitMessage: "",
    error: null,
    promoCode: "",
    currencyObject: null,
    paymentError: null,
    selectedPaymentOption: "single",
  };

  // ------------------------------

  update = async (propValue) => {
    await this.setState({ ...this.state, ...propValue });
  };

  // ------------------------------

  lockBack = () => { };

  // ------------------------------

  unlockBack = () => { };

  // ------------------------------

  bringToAddPayment = () => {
    this.props.history.push("/addpayment?g=checkout");
  };

  // ------------------------------

  componentDidMount = async () => {
    try {
      await this.setState({ ...this.state, isSubmitting: true });
      await this.props.fetchMobileSetting();
      await Promise.all([
        this.props.fetchRates(),
        this.props.fetchPaymentMethods(),
        this.props.fetchCart(),
      ]);
      await this.props.fetchFollowedProviders();
      await this.totalAndRenderCart();
      await this.setState({ ...this.state, isSubmitting: false });

      if (this.props.following) {
        const membershipProvider = this.findMembership();
        if (membershipProvider) {
          await this.update({
            promoCode: membershipProvider.code,
          });

          await this.applyPromoCodeHandler(
            null,
            this.props.t("g_applying_promo") +
            this.state.promoCode.toUpperCase()
          );
        }
      }

      if (this.props.autoSelectPaymentMethod) {
        this.props.setAutoSelectPaymentMethod(false);
        this.selectedPayMethodHandler(this.props.paymentMethods[0]);
      }

      if (this.props.setting) {
        const currencyObject = findCurrency(this.props.setting.currency);
        await this.update({ currencyObject: currencyObject });
      }
    } catch (err) {
      logger.error(err);
      this.setState({ ...this.state, isSubmitting: false, error: err.message });
    }
  };

  // ------------------------------

  componentDidUpdate = async (prevProps) => {
    if (
      this.props.setting.currency !== prevProps.setting.currency &&
      !prevProps.setting.currency
    ) {
      const currencyObject = findCurrency(this.props.setting.currency);
      await this.update({ currencyObject: currencyObject });
    }
  };

  // ------------------------------

  findMembership = () => {
    const providersInCard = this.buildProviderCourses(); // providerId

    for (const p of providersInCard) {
      const found = this.props.following.find((f) => f.id === p.providerId);
      if (found && found.code) {
        return found;
      }
    }

    return null;
  };

  // ------------------------------

  handleCheckoutServerError = (err) => {
    if (err.result && err.result.success === false) {
      this.setState({
        ...this.state,
        isSubmitting: false,
        paymentError: err.result.error,
      });
    } else {
      logger.error(err);
      this.setState({
        ...this.state,
        isSubmitting: false,
        error: clientFacingError(err.message, null, this.props.i18n),
      });
    }
  };

  // ------------------------------

  renderPaymentError = () => {
    const errorDetails = this.state.paymentError.paymentResults;

    const arrMessages = errorDetails.map((details, key) => {
      const providerName = this.getProviderName(details.providerId);

      return (
        <span key={key} className={styles.paymentModalRowContainer}>
          <span className={styles.paymentModalRow}>
            <span className={styles.paymentModalTitle}>
              {details.status === "error" ? (
                <HighlightOffIcon style={{ color: "#a10606" }} />
              ) : (
                <CheckCircleOutlineOutlinedIcon style={{ color: "green" }} />
              )}
            </span>
            <span className={styles.paymentModalText}>
              {details.message} <b>{providerName}</b>.
            </span>
          </span>
          {details.status === "error" && (
            <span className={styles.paymentModalRow}>
              <span className={styles.paymentModalTitle}>&nbsp;</span>
              <span className={styles.paymentModalText}>
                {details.cause}. {details.suggestion}.
              </span>
            </span>
          )}
        </span>
      );
    });

    return arrMessages;
  };

  // ------------------------------

  getProviderName = (providerId) => {
    let providerName = "";
    Object.keys(this.props.cart.items).forEach((key, index) => {
      const ci = this.props.cart.items[key];

      if (ci.id === providerId) {
        providerName = ci.providerName;
      }
    });

    return providerName;
  };

  // ------------------------------

  openCompletedHandler = () => {
    this.setState({ ...this.state, orderSuccessModalOpen: true });
  };

  // ------------------------------

  orderCompletedHandler = (event) => {
    this.setState({
      ...this.state,
      orderSuccessModalOpen: false,
      paymentError: null,
      isSubmitting: true,
      submitMessage: "",
    });

    this.props.clearCart();
    this.props.clearPromoCode();
    this.props.history.replace("/");
  };

  // ------------------------------

  openPaymentMethodHandler = () => {
    this.setState({ ...this.state, paymentModalOpen: true });
  };

  // ------------------------------

  selectedPayMethodHandler = (paymentMethod) => {
    let copyPayMethod = null;
    if (paymentMethod) {
      copyPayMethod = { ...paymentMethod };
    }
    this.setState({
      ...this.state,
      paymentModalOpen: false,
      paymentMethod: copyPayMethod,
    });
  };

  // ------------------------------

  renderCartProviderItem = (item, coupon, couponDiscount) => {
    return (
      <CartProviderItem
        key={item.id}
        item={item}
        coupon={coupon}
        couponDiscount={couponDiscount}
        setting={this.props.setting}
        rates={this.props.rates}
      />
    );
  };

  // ------------------------------

  closeErrorHandler = () => {
    this.setState({
      ...this.state,
      error: null,
    });
  };

  // ------------------------------

  applyPromoCodeHandler = async (e, message) => {
    try {
      await this.update({
        isSubmitting: true,
        submitMessage: message ? message : "",
      });

      await this.props.clearPromoCode();

      await this.props.applyPromoCode(
        this.state.promoCode.toUpperCase(),
        this.buildProviderCourses()
      );

      await this.update({
        isSubmitting: false,
        promoCode: "",
      });

      await this.totalAndRenderCart();
    } catch (err) {
      logger.error(err);
      await this.props.clearPromoCode();
      await this.totalAndRenderCart();
      await this.update({
        isSubmitting: false,
        error: clientFacingError(err.message, null, this.props.i18n),
        promoCode: "",
      });
    }
  };

  // ------------------------------

  buildProviderCourses = () => {
    const providerCourses = [];
    Object.keys(this.props.cart.items).forEach((key, index) => {
      const ci = this.props.cart.items[key];
      providerCourses.push({
        providerId: ci.id,
        courses: Object.keys(ci.cartCourseItems),
      });
    });

    return providerCourses;
  };

  // ------------------------------

  totalAndRenderCart = async () => {
    let total = 0;
    let renderedCartProviderItems = [];

    for (const key of Object.keys(this.props.cart.items)) {
      const ci = this.props.cart.items[key];

      if (this.props.coupon) {
        for (const coureKey of Object.keys(ci.cartCourseItems)) {
          const pricingItems = ci.cartCourseItems[coureKey].pricingItems;

          for (const dataPi of pricingItems) {
            const costAfterDiscount = dataPi.costAfterDiscount;

            for (const sm of dataPi.staffMembers) {
              const memberCouponValue = calcMemberPromoDiscount(
                this.props.coupon,
                key,
                costAfterDiscount,
                sm
              );

              const memberCost = costAfterDiscount - memberCouponValue;

              if (memberCost > 0 && memberCost < 35) {
                await this.setState({
                  ...this.state,
                  isSubmitting: false,
                  promoCode: "",
                });

                throw new Error(this.props.t("error_invalid_promo_code"));
              }
            }
          }
        }
      }

      const couponDiscount =
        this.props.coupon && this.props.coupon.provider === ci.id
          ? calcPromoDiscount(ci, this.props.coupon, this.props.rates)
          : 0;

      const coupon =
        this.props.coupon && this.props.coupon.provider === ci.id
          ? this.props.coupon
          : null;

      renderedCartProviderItems.push(
        this.renderCartProviderItem(ci, coupon, couponDiscount)
      );

      total += calcTotalInCartItems(ci, this.props.rates) - couponDiscount;
    }

    await this.setState({
      ...this.state,
      total: total % 1 !== 0 ? total.toFixed(2) : total,
      renderedCartProviderItems: renderedCartProviderItems,
      selectedPaymentOption:
        total < MAX_MULTI_AMOUNT ? "single" : this.state.selectedPaymentOption,
    });
  };

  // ------------------------------

  inputChangeHandler = (e) => {
    const value = e.target.value;
    this.setState({
      ...this.state,
      [e.target.name]: value,
    });
  };

  // ------------------------------

  calculateMultiPayment = (multiPayOption, cartAmount) => {
    const firstPaymentAmount = roundAmountNoFormatDecimal(
      cartAmount * (multiPayOption.firstPayPercent / 100)
    );

    const balanceInstallment = roundAmountNoFormatDecimal(
      (cartAmount - firstPaymentAmount) / multiPayOption.installmentCount
    );

    const finalCompareAmount =
      firstPaymentAmount + multiPayOption.installmentCount * balanceInstallment;

    // adjust because of rounding
    let adjustFirstPayment = firstPaymentAmount;

    if (cartAmount - finalCompareAmount !== 0) {
      adjustFirstPayment += cartAmount - finalCompareAmount;
    }

    const arrInstallmentDates = [];
    for (let i = 1; i <= multiPayOption.installmentCount; i++) {
      arrInstallmentDates.push(incrementMonthOfDate(new Date(), i));
    }

    return {
      firstPayment: roundAmount(Number(adjustFirstPayment)),
      installment: roundAmount(balanceInstallment),
      installmentDates: arrInstallmentDates,
    };
  };

  // ------------------------------

  render() {
    if (this.state.isSubmitting) {
      return <Spinner message={this.state.submitMessage} />;
    }

    const hasMultiPayOption =
      this.props.multiPayOption &&
      this.props.multiPayOption.enabled &&
      this.state.total >= MAX_MULTI_AMOUNT;

    let multiPayOptionResults = null;
    if (hasMultiPayOption) {
      multiPayOptionResults = this.calculateMultiPayment(
        this.props.multiPayOption,
        this.state.total
      );
    }

    return (
      <div className={styles.pageContainer}>
        {this.state.paymentError && (
          <InfoModal
            isErrorModal={!this.state.paymentError.partiallyProcessed}
            isWarnModal={this.state.paymentError.partiallyProcessed}
            title={this.state.paymentError.message}
            message={this.renderPaymentError()}
            open={this.state.paymentError !== null}
            onClose={() => {
              if (this.state.paymentError.partiallyProcessed) {
                this.orderCompletedHandler();
              } else {
                this.update({ paymentError: null });
              }
            }}
          />
        )}

        <InfoModal
          isErrorModal={true}
          title={this.props.t("error_occurred")}
          message={this.state.error}
          open={this.state.error !== null}
          onClose={this.closeErrorHandler}
        />

        <InfoModal
          title={this.props.t("checkout_order_processed")}
          message={this.props.t("checkout_order_message")}
          open={this.state.orderSuccessModalOpen}
          onClose={this.orderCompletedHandler}
        />

        <PaymentMethodModal
          open={this.state.paymentModalOpen}
          list={this.props.paymentMethods}
          onSelect={this.selectedPayMethodHandler}
        />

        <div style={{ fontWeight: "bold" }}>
          {this.props.t("cart_order_summary")}
        </div>

        <div className={styles.summaryCourseContainer}>
          {this.state.renderedCartProviderItems}

          <div>
            <div className={styles.priceTotal}>
              <div style={{ color: "black", paddingRight: "4px" }}>Total:</div>{" "}
              <NumberFormat
                value={this.state.total}
                displayType={"text"}
                thousandSeparator={true}
                prefix={
                  this.state.currencyObject &&
                    this.state.currencyObject.shortName
                    ? this.state.currencyObject.shortName
                    : ""
                }
              />
            </div>
          </div>
        </div>

        {hasMultiPayOption && (
          <Fragment>
            <div className={styles.centerAlign} style={{ fontWeight: "bold", marginTop: "50px" }}>
              {this.props.t("cart_payment_option")} &nbsp; <CreditCardIcon />
            </div>
            <PaymentOption
              fullWidth
              noBorder
              currencyObject={this.state.currencyObject}
              multiPayOption={this.props.multiPayOption}
              multiPayOptionResults={multiPayOptionResults}
              totalAmount={this.state.total}
              selectedPaymentOption={this.state.selectedPaymentOption}
              updateSelectedPaymentOption={(selection) => {
                this.update({ selectedPaymentOption: selection });
              }}
            />
          </Fragment>
        )}

        <div className={styles.centerAlign} style={{ fontWeight: "bold", marginTop: "50px" }}>
          2. {this.props.t("cart_coupon")} &nbsp;
          <RedeemIcon />
        </div>

        <PromoCode
          id="promoCode"
          name="promoCode"
          value={this.state.promoCode}
          onChange={this.inputChangeHandler}
          onApplyPromoCode={this.applyPromoCodeHandler}
        />

        <div className={styles.centerAlign} style={{ fontWeight: "bold", marginTop: "50px" }}>
          {this.props.t("cart_payment_info")} &nbsp; <CreditCardIcon />
        </div>

        <CreditCard
          paymentMethod={this.state.paymentMethod}
          paymentMethods={this.props.paymentMethods}
          openPaymentMethodHandler={this.openPaymentMethodHandler}
          addPaymentMethod={this.bringToAddPayment}
        />



        <div className={styles.receipt}>
          <div className={styles.receiptTitle}>
            <LockIcon
              className={styles.lockIcon}
              style={{
                paddingRight: 7,
              }}
            />
            <div style={{ fontWeight: "bold" }}>
              {this.props.t("cart_terms")}
            </div>{" "}
          </div>
          <div style={{ textAlign: "justify" }}>
            {this.props.t("cart_terms_desc")}
          </div>
        </div>

        <div className={styles.receipt}>
          <div className={styles.receiptTitle}>
            <ReceiptIcon
              className={styles.lockIcon}
              style={{
                paddingRight: 7,
              }}
            />
            <div style={{ fontWeight: "bold" }}>
              {this.props.t("cart_billing")}
            </div>
          </div>

          <div style={{ textAlign: "justify" }}>
            {this.props.t("cart_billing_desc")}
          </div>
        </div>

        <div className={styles.receipt}>
          <div className={styles.receiptTitle}>
            <LanguageIcon
              className={styles.lockIcon}
              style={{
                paddingRight: 7,
              }}
            />
            <div style={{ fontWeight: "bold" }}>
              {this.props.t("cart_exchange_rates")}
            </div>
          </div>
          <div style={{ textAlign: "justify" }}>
            {this.props.t("cart_exchange_rates_you_are")}{" "}
            {this.state.currencyObject && (
              <Fragment>({this.state.currencyObject.id})</Fragment>
            )}
            {this.props.t("cart_exchange_rates_you_are_desc")}
          </div>
        </div>

        <div>
          <div>&nbsp;</div>
          <div>
            <MobileCheckoutButton
              total={this.state.total}
              orderCompleted={this.openCompletedHandler}
              submitting={(value, submitMessage) => {
                this.setState({
                  ...this.state,
                  isSubmitting: value,
                  submitMessage: submitMessage,
                });
              }}
              cart={this.props.cart}
              coupon={this.props.coupon}
              paymentMethod={this.state.paymentMethod}
              selectedPaymentOption={this.state.selectedPaymentOption}
              placeOrder={this.props.placeOrder}
              sendError={this.handleCheckoutServerError}
              lockBack={this.lockBack}
              unlockBack={this.unlockBack}
              t={this.props.t}
            />
          </div>
        </div>
        <div style={{ height: "58px" }}></div>
      </div>
    );
  }
}

// ------------------------------

const mapStateToProps = (state, props) => {
  return {
    action: props.history.action,
    cart: state.mobilecart,
    coupon: state.mobilecart.coupon,
    multiPayOption: state.mobilecart.multiPayOption,
    paymentMethods: state.payment.payment,
    setting: state.setting.mobileSetting,
    rates: state.rate.rate,
    following: state.provider.following,
    autoSelectPaymentMethod: state.redirect.autoSelectPaymentMethod,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    clearCart: () => dispatch(mobilecartActions.clearCart()),
    fetchCart: () => dispatch(mobilecartActions.fetchCart()),
    fetchPaymentMethods: () => dispatch(paymentActions.fetchPaymentMethods()),
    fetchMobileSetting: () => dispatch(settingActions.fetchMobileSetting()),
    fetchRates: () => dispatch(rateActions.fetchRates()),
    placeOrder: (cart, payMethodId, coupon, selectedPaymentOption) =>
      dispatch(
        paymentActions.processMobilePaymentIntent(
          cart,
          payMethodId,
          coupon,
          selectedPaymentOption
        )
      ),
    applyPromoCode: (promoCode, providerCourses) =>
      dispatch(mobilecartActions.applyPromoCode(promoCode, providerCourses)),
    clearPromoCode: () => dispatch(mobilecartActions.clearPromoCode()),
    fetchFollowedProviders: () =>
      dispatch(providerActions.fetchFollowedProviders()),
    setAutoSelectPaymentMethod: (value) =>
      dispatch(redirectActions.setAutoSelectPaymentMethod(value)),
  };
};

export default withTranslation("translations")(
  connect(mapStateToProps, mapDispatchToProps)(MobileCheckoutScreen)
);
