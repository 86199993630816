import React from "react";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { makeStyles } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";

const useStyles = makeStyles((theme) => ({
  inputField: {
    width: "100%",
    paddingBottom: "10px",
  },
  inputFieldRight: {
    display: "flex",
    width: "100%",
  },
  inputFieldLeft: {
    display: "flex",
    justifyContent: "flex-end",
    width: "100%",
  },
  signup: {
    width: "90%",
    margin: 30,
    color: "rgb(131, 131, 131)",
  },
  inputFieldSmall: {
    width: "60%",
    margin: "auto",
    paddingBottom: "10px",
  },
}));

const MatCheckbox = (props) => {
  const matches = useMediaQuery("(max-width:499px)");
  const classes = useStyles();
  let classSelect = matches ? classes.inputField : classes.inputFieldSmall;

  if (props.nostyle) {
    classSelect = null;
  }

  if (props.signup) {
    classSelect = classes.signup;
  }
  if (props.plain) {
    classSelect = classes.inputField;
  }

  if (props.plainLeft) {
    classSelect = classes.inputFieldLeft;
  }

  if (props.plainright) {
    classSelect = classes.inputFieldRight;
  }

  return (
    <div className={classSelect}>
      <FormControlLabel
        control={
          <Checkbox
            {...props}
            color="primary"
            style={props.nostyle ? null : { paddingRight: "10px" }}
          />
        }
        label={props.label}
        labelPlacement={props.labelplacement ? props.labelplacement : "end"}
      />
    </div>
  );
};

export default MatCheckbox;
