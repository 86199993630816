import { handleErrorResponse } from "../../globals/Error";
import { dbName } from "../../globals/ApiKeys";
import Certificate from "../../model/Certificate";
import CertificateLink from "../../model/CertificateLink";
import { updateCourseCertificate } from "./courses";
import { firebaseNameToId, stringFieldFilter, ac } from "../../globals/Common";
import firebase from "firebase/app";
import * as logger from "../../globals/Logger";
export const SET_CERT = "SET_CERT";
export const SET_CERT_ID = "SET_CERT_ID";
export const ADD_CERT = "ADD_CERT";
export const REMOVE_CERT = "REMOVE_CERT";
export const UPDATE_CERT = "UPDATE_CERT";
export const UPDATE_CERT_HEADER = "UPDATE_CERT_HEADER";
export const UPDATE_CERT_FOOTER = "UPDATE_CERT_FOOTER";
export const SET_LINKED_COURSES = "SET_LINKED_COURSES";
export const REMOVE_LINKED_COURSE = "REMOVE_LINKED_COURSE";

const ACTIVE_COLLECTION = "courses";
const DRAFT_COLLECTION = "course_drafts";
// ------------------------------

export const setEditAction = (id) => {
  return {
    type: SET_CERT_ID,
    certificateId: id,
  };
};

// ------------------------------

export const fetchCert = () => {
  return async (dispatch, getState) => {
    const userId = getState().auth.userId;
    const token = getState().auth.token;

    const response = await fetch(
      `https://firestore.googleapis.com/v1/projects/${dbName}/databases/(default)/documents/certificates/${userId}/certificate`,
      {
        method: "GET",
        headers: {
          "Content-Type": "applications/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );

    if (!response.ok) {
      handleErrorResponse(await response.json());
    }

    const resData = await response.json();

    let arrDocs = [];
    if (Array.isArray(resData)) {
      arrDocs = resData;
    } else {
      arrDocs = resData.documents;
    }

    const loaded = [];
    if (arrDocs) {
      for (const cert of arrDocs) {
        if (cert && cert.name) {
          loaded.push(
            new Certificate(
              firebaseNameToId(cert.name),
              cert.fields.title.stringValue,
              cert.fields.certificate_header
                ? cert.fields.certificate_header.stringValue
                : null,
              cert.fields.certificate_footer
                ? cert.fields.certificate_footer.stringValue
                : null
            )
          );
        }
      }
    }

    dispatch({
      type: SET_CERT,
      certificate: loaded,
    });
  };
};

// ------------------------------

export const fetchLinkedCourses = (certId) => {
  return async (dispatch, getState) => {
    const userId = getState().auth.userId;
    const token = getState().auth.token;

    const arrResponse = await Promise.all([
      fetch(
        `https://firestore.googleapis.com/v1/projects/${dbName}/databases/(default)/documents:runQuery`,
        {
          method: "POST",
          headers: {
            "Content-Type": "applications/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(linkedCourseQuery("courses", certId)),
        }
      ),
      fetch(
        `https://firestore.googleapis.com/v1/projects/${dbName}/databases/(default)/documents/drafts/${userId}:runQuery`,
        {
          method: "POST",
          headers: {
            "Content-Type": "applications/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(linkedCourseQuery("course_drafts", certId)),
        }
      ),
    ]);

    if (!arrResponse[0].ok) {
      handleErrorResponse(await arrResponse[0].json());
    }

    if (!arrResponse[1].ok) {
      handleErrorResponse(await arrResponse[1].json());
    }

    const certificateLink = buildCertLinks(
      await arrResponse[0].json(),
      certId,
      ACTIVE_COLLECTION,
      "Active"
    ).concat(
      buildCertLinks(
        await arrResponse[1].json(),
        certId,
        DRAFT_COLLECTION,
        "Draft"
      )
    );

    dispatch({
      type: SET_LINKED_COURSES,
      certificateLink: certificateLink,
    });
  };
};

// ------------------------------

const linkedCourseQuery = (id, certificateId) => {
  const ac = "ARRAY_CONTAINS";
  let structuredQuery = {
    structuredQuery: {
      from: [{ collectionId: id }],
    },
  };
  let condition = {};
  condition = { compositeFilter: { filters: [], op: "AND" } };
  const fieldFilter = stringFieldFilter("certificate", ac, certificateId);
  condition.compositeFilter.filters.push({ fieldFilter: fieldFilter });
  structuredQuery.structuredQuery.where = condition;
  return structuredQuery;
};

// ------------------------------

export const buildCertLinks = (
  resData,
  certId,
  collection,
  collection_title
) => {
  const loadedCourses = [];

  let arrDocs = [];
  if (Array.isArray(resData)) {
    arrDocs = resData;
  } else {
    arrDocs = resData.documents;
  }

  if (arrDocs && Object.keys(arrDocs).length !== 0) {
    for (const documents of arrDocs) {
      let course = documents.document;
      if (!course) {
        course = documents;
      }

      try {
        course.fields;
        if (course && course.name && course.fields) {
          loadedCourses.push(
            new CertificateLink(
              firebaseNameToId(course.name),
              certId,
              course.fields.title.stringValue,
              collection,
              collection_title
            )
          );
        }
      } catch (error) {
        logger.error(error);
        logger.error("Course" + course.name + " could not be loaded.");
        logger.error(course);
      }
    }
  }

  return loadedCourses;
};

// ------------------------------

export const unlinkCourse = (certLink) => {
  return async (dispatch, getState) => {
    if (certLink && certLink.collection) {
      const collection = certLink.collection;
      if (collection === ACTIVE_COLLECTION) {
        await dispatch(unlinkActive(certLink));
      } else if (collection === DRAFT_COLLECTION) {
        await dispatch(unlinkDraft(certLink));
      }
    }
  };
};

// ------------------------------

export const linkCertActive = (cert, courseId) => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;

    const response = await fetch(
      `https://firestore.googleapis.com/v1/projects/${dbName}/databases/(default)/documents/courses/${courseId}` +
        `?updateMask.fieldPaths=certificate`,
      {
        method: "PATCH",
        headers: {
          "Content-Type": "applications/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          fields: {
            certificate: {
              arrayValue: {
                values: cert
                  ? [{ stringValue: cert.id }, { stringValue: cert.title }]
                  : null,
              },
            },
          },
        }),
      }
    );

    if (!response.ok) {
      handleErrorResponse(await response.json());
    }

    dispatch(updateCourseCertificate(courseId, cert));
  };
};

// ------------------------------

export const unlinkActive = (certLink) => {
  return async (dispatch, getState) => {
    const courseId = certLink.course_id;
    const token = getState().auth.token;

    const response = await fetch(
      `https://firestore.googleapis.com/v1/projects/${dbName}/databases/(default)/documents/courses/${courseId}` +
        `?updateMask.fieldPaths=certificate`,
      {
        method: "PATCH",
        headers: {
          "Content-Type": "applications/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          fields: {
            certificate: {
              arrayValue: {
                values: [],
              },
            },
          },
        }),
      }
    );

    if (!response.ok) {
      handleErrorResponse(await response.json());
    }

    dispatch({
      type: REMOVE_LINKED_COURSE,
      id: certLink.certId,
    });
  };
};

// ------------------------------

export const unlinkDraft = (certLink) => {
  return async (dispatch, getState) => {
    const courseId = certLink.course_id;
    const userId = getState().auth.userId;
    const token = getState().auth.token;

    const response = await fetch(
      `https://firestore.googleapis.com/v1/projects/${dbName}/databases/(default)/documents/drafts/${userId}/course_drafts/${courseId}` +
        `?updateMask.fieldPaths=certificate`,
      {
        method: "PATCH",
        headers: {
          "Content-Type": "applications/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          fields: {
            certificate: {
              arrayValue: {
                values: [],
              },
            },
          },
        }),
      }
    );

    if (!response.ok) {
      handleErrorResponse(await response.json());
    }

    dispatch({
      type: REMOVE_LINKED_COURSE,
      id: certLink.certId,
    });
  };
};

// ------------------------------

export const addCert = (certificate) => {
  return async (dispatch, getState) => {
    const userId = getState().auth.userId;
    const token = getState().auth.token;

    const response = await fetch(
      `https://firestore.googleapis.com/v1/projects/${dbName}/databases/(default)/documents/certificates/${userId}/certificate/`,
      {
        method: "POST",
        headers: {
          "Content-Type": "applications/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          fields: {
            title: { stringValue: certificate.title },
          },
        }),
      }
    );

    if (!response.ok) {
      handleErrorResponse(await response.json());
    }

    const resData = await response.json();
    const certId = firebaseNameToId(resData.name);

    dispatch({
      type: ADD_CERT,
      certificate: { id: certId, title: certificate.title },
    });

    return certId;
  };
};

// ------------------------------

export const updateCert = (certificate) => {
  return async (dispatch, getState) => {
    const userId = getState().auth.userId;
    const token = getState().auth.token;

    const certId = certificate.id;

    await fetch(
      `https://firestore.googleapis.com/v1/projects/${dbName}/databases/(default)/documents/certificates/${userId}/certificate/${certId}` +
        `?updateMask.fieldPaths=title`,
      {
        method: "PATCH",
        headers: {
          "Content-Type": "applications/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          fields: {
            title: { stringValue: certificate.title },
          },
        }),
      }
    );

    dispatch({
      type: UPDATE_CERT,
      certificate: {
        id: certId,
        title: certificate.title,
        certificate_header: certificate.certificate_header,
        certificate_footer: certificate.certificate_footer,
      },
    });
  };
};

// ------------------------------

export const removeCert = (certId) => {
  return async (dispatch, getState) => {
    const userId = getState().auth.userId;
    const token = getState().auth.token;

    await fetch(
      `https://firestore.googleapis.com/v1/projects/${dbName}/databases/(default)/documents/certificates/${userId}/certificate/${certId}`,
      {
        method: "DELETE",
        headers: {
          "Content-Type": "applications/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );

    await dispatch(deletePic("certificate_header", certId, null));
    await dispatch(deletePic("certificate_footer", certId, null));

    dispatch({
      type: REMOVE_CERT,
      id: certId,
    });
  };
};

// ------------------------------

export const updateCertHeaderFooter = (urlBlob, type, certId, certificate) => {
  return async (dispatch, getState) => {
    if (certId && certId !== "new") {
      await dispatch(uploadOrDelete(urlBlob, type, certId, certificate));
    } else {
      throw new Error("Please save your certificate template.");
    }
  };
};

// ------------------------------

const uploadOrDelete = (urlBlob, prefix, certId, certificate) => {
  return async (dispatch, getState) => {
    if (urlBlob && urlBlob.toString().startsWith("blob:")) {
      await dispatch(uploadPic(urlBlob, prefix, certId, certificate));
    } else {
      await dispatch(
        deletePic(prefix, certId, () => {
          return async (dispatch, getState) => {
            if (prefix === "certificate_header") {
              dispatch(updateCertificateHeader(certId, certificate, ""));
            } else if (prefix === "certificate_footer") {
              dispatch(updateCertificateFooter(certId, certificate, ""));
            }
          };
        })
      );
    }
  };
};

// ------------------------------

const updateCertificateHeader = (certId, certificate, downloadURL) => {
  return async (dispatch, getState) => {
    const userId = getState().auth.userId;
    const token = getState().auth.token;

    const response = await fetch(
      `https://firestore.googleapis.com/v1/projects/${dbName}/databases/(default)/documents/certificates/${userId}/certificate/${certId}` +
        `?updateMask.fieldPaths=certificate_header`,
      {
        method: "PATCH",
        headers: {
          "Content-Type": "applications/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          fields: {
            certificate_header: { stringValue: downloadURL },
          },
        }),
      }
    );

    if (!response.ok) {
      handleErrorResponse(await response.json());
    }

    dispatch({
      type: UPDATE_CERT_HEADER,
      certificate: {
        id: certId,
        title: certificate.title,
        certificate_header: downloadURL,
      },
    });
  };
};

// ------------------------------

const updateCertificateFooter = (certId, certificate, downloadURL) => {
  return async (dispatch, getState) => {
    const userId = getState().auth.userId;
    const token = getState().auth.token;

    const response = await fetch(
      `https://firestore.googleapis.com/v1/projects/${dbName}/databases/(default)/documents/certificates/${userId}/certificate/${certId}` +
        `?updateMask.fieldPaths=certificate_footer`,
      {
        method: "PATCH",
        headers: {
          "Content-Type": "applications/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          fields: {
            certificate_footer: { stringValue: downloadURL },
          },
        }),
      }
    );

    if (!response.ok) {
      handleErrorResponse(await response.json());
    }

    dispatch({
      type: UPDATE_CERT_FOOTER,
      certificate: {
        id: certId,
        title: certificate.title,
        certificate_footer: downloadURL,
      },
    });
  };
};

// ------------------------------

const uploadPic = (uri, postfix, certId, certificate) => {
  return async (dispatch, getState) => {
    const userId = getState().auth.userId;

    if (uri && uri !== "undefined") {
      const response = await fetch(uri);
      const blob = await response.blob();
      let ref = firebase
        .storage()
        .ref()
        .child(`images/${userId}/${postfix}_${certId}`);

      await ref.put(blob).then(function (snap) {
        snap.ref
          .getDownloadURL()
          .then(function (downloadUrl) {
            // ----------
            if (downloadUrl && postfix === "certificate_header") {
              try {
                dispatch(
                  updateCertificateHeader(certId, certificate, downloadUrl)
                );
              } catch (err) {
                logger.error(err);
              }
            }
            // ----------
            if (downloadUrl && postfix === "certificate_footer") {
              try {
                dispatch(
                  updateCertificateFooter(certId, certificate, downloadUrl)
                );
              } catch (err) {
                logger.error(err);
              }
            }
            // ----------
          })
          .catch(function (error) {
            logger.error(error);
          });
      });
    }
  };
};

// ------------------------------

const deletePic = (postfix, certId, callback) => {
  return async (dispatch, getState) => {
    const userId = getState().auth.userId;

    try {
      await firebase
        .storage()
        .ref()
        .child(`images/${userId}/${postfix}_${certId}`)
        .delete();
    } catch (error) {
      logger.info("picture not found, deletePic - certificate");
    }

    if (callback) {
      await dispatch(callback());
    }
  };
};

// ------------------------------
