import React, { Component } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import styles from "./CategorySearch.module.css";
import * as ceActions from "../../../store/actions/ces";
import * as registrationActions from "../../../store/actions/registration";
import * as courseActions from "../../../store/actions/courses";
import * as providerActions from "../../../store/actions/provider";
import * as settingActions from "../../../store/actions/setting";
import * as profileActions from "../../../store/actions/profile";
import * as rateActions from "../../../store/actions/rate";
import * as logger from "../../../globals/Logger";
import { CATEGORIES_TOP, CATEGORIES } from "../../../globals/Data";
import Spinner from "../../../components/ui/spinner/Spinner";
import InfoModal from "../../../components/modals/infoModal/InfoModal";
import GradientCard from "../../../components/ui/card/GradientCard";
import { translate } from "../../../globals/Common";
import DefaultToolbar from "../../welcome/DefaultToolbar";
import HorizontalScrollCards from "../../../components/ui/horizontalScrollCards/HorizontalScrollCards";
import Footer from "../../../components/ui/footer/Footer";
import CourseItem from "../../../components/items/courseSearchItem/CourseItem";
import ProviderItem from "../../../components/items/providerItem/ProviderItem";
import ProviderListModal from "../../../components/modals/providerListModal/ProviderListModal";
import Lottie from "lottie-react";
import doctor from "../../../assets/lottie/doctor.json";
import RoundedButton from "../../../components/ui/button/RoundedButton";
import RegistrationItem from "../../../components/items/registrationItem/RegistrationItem";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import CeSummary from "../../../components/ui/ceSummary/CeSummary";
import {
  objectHasNoValues,
  setLanguage,
  resolveCurrencySymbol,
  resolveCurrency,
} from "../../../globals/Common";

import tip1 from "../../../assets/images/inApp/tip1.png";
import tip2 from "../../../assets/images/inApp/tip2.png";
import tip3 from "../../../assets/images/inApp/tip3.png";

const CURRENT_YEAR = new Date().getFullYear();

class CategoryScreen extends Component {
  state = {
    openProviderListModal: false,
    wait: false,
    errorModal: false,
    message: null,
  };

  // ------------------------------

  update = async (propValue) => {
    await this.setState({ ...this.state, ...propValue });
  };

  // ------------------------------

  componentDidMount = async () => {
    try {
      await this.update({ wait: true });
      window.scrollTo(0, 0);

      const preloadAsync = [];
      const loadAsync = [];

      if (objectHasNoValues(this.props.categoryIndex)) {
        preloadAsync.push(this.props.fetchCategoryIndex());
      }

      if (objectHasNoValues(this.props.mobileSetting)) {
        preloadAsync.push(this.props.fetchMobileSetting());
      }

      if (objectHasNoValues(this.props.profile) && this.props.isAuthenticated) {
        preloadAsync.push(this.props.fetchUserProfile());
      }

      if (this.props.isAuthenticated) {
        loadAsync.push(this.props.fetchRegistration(1));
      }

      if (objectHasNoValues(this.props.provider)) {
        loadAsync.push(this.props.fetchProviders());
      }
      if (objectHasNoValues(this.props.rates)) {
        await this.props.fetchRates();
      }

      if (objectHasNoValues(this.props.trending)) {
        loadAsync.push(this.props.fetchTrending());
      }

      if (objectHasNoValues(this.props.newly)) {
        loadAsync.push(this.props.fetchNewly());
      }

      if (objectHasNoValues(this.props.recommend)) {
        loadAsync.push(this.props.fetchRecommended());
      }

      await Promise.all(preloadAsync);
      await Promise.all(loadAsync)
      await this.props.fetchCeByYear(CURRENT_YEAR, CURRENT_YEAR);
      this.update({ wait: false });
    } catch (error) {
      logger.error(error);
      await this.update({
        wait: false,
        errorModal: true,
        message: error.message,
      });
    }
  };

  // ------------------------------

  joinNowHandler = () => {
    this.props.history.push("/studentregister");
  };

  // ------------------------------

  openProviderListModal = () => {
    this.update({ openProviderListModal: true });
  };

  // ------------------------------

  categorySelectedHandler = (category) => {
    if (category.id === "provider") {
      this.openProviderListModal();
    } else if (category.id === "Inperson") {
      this.props.history.push({
        pathname: "/courselist",
        state: {
          title: "In person",
          searchInPerson: true,
          landingSource: "In person Search",
          icon: category.icon,
        },
      });
    } else if (category.id === "Online") {
      this.props.history.push({
        pathname: "/courselist",
        state: {
          title: "On demand",
          searchOnlineOnly: true,
          landingSource: "Online Only Search",
          filterExclude: {
            city: true,
            type: true,
            component: true,
            date: true,
          },
          icon: category.icon,
        },
      });
    } else if (category.id === "Livestream") {
      this.props.history.push({
        pathname: "/courselist",
        state: {
          title: "Livestream",
          searchLivestream: true,
          landingSource: "Livestream Search",
          filterExclude: { city: true, type: true, component: true },
          icon: category.icon,
        },
      });
    } else if (category.id === "free") {
      this.props.history.push({
        pathname: "/courselist",
        state: {
          title: "Free",
          searchFree: true,
          landingSource: "Free Search",
          icon: category.icon,
        },
      });
    } else {
      this.props.history.push({
        pathname: "/courselist",
        state: {
          title: category.title,
          categoryId: category.id,
          landingSource: "Category Search",
          icon: category.icon,
        },
      });
    }
  };

  // ------------------------------

  openShopHandler = async (providerId) => {
    this.props.history.push(`/shop?p=${providerId}`);
  };

  // ------------------------------

  myCoursesHandler = async () => {
    this.props.history.push(`/mycourses`);
  };

  // ------------------------------

  courseDetailsHandler = (course) => {
    this.props.setSelectedProviderCourse(course);
    this.props.history.push(`/details/${course.provider_id}/${course.id}`);
  };

  // ------------------------------

  findCategoryCount = (catId) => {
    if (
      this.props.categoryIndex &&
      Object.keys(this.props.categoryIndex).length > 0
    ) {
      if (this.props.categoryIndex[catId.toLowerCase()]) {
        return Number(this.props.categoryIndex[catId.toLowerCase()]);
      }
    }

    return 0;
  };

  // ------------------------------

  renderCategory = (itemData, rounded) => {
    return (
      <div
        key={itemData.id}
        onClick={this.categorySelectedHandler.bind(this, itemData)}
      >
        <GradientCard
          color={itemData.color}
          icon={itemData.icon}
          isSvg={itemData.icon.startsWith("svg")}
          rounded={rounded}
          title={translate(this.props.t, itemData.title, "cat_short_")}
          count={this.findCategoryCount(itemData.id)}
        />
      </div>
    );
  };

  // ------------------------------

  buildProviderItems = (providers) => {
    const items = [];

    for (const provider of providers) {
      items.push({
        id: provider.id,
        component: (
          <div style={{ marginRight: "20px" }}>
            <ProviderItem
              handcursor
              key={provider.id}
              id={provider.id}
              name={provider.name}
              logo={provider.logoCached}
              country={provider.country}
              currency={provider.currency}
              onViewCourses={this.openShopHandler.bind(this, provider.id)}
              view={true}
              about={provider.about}
            />
          </div>
        ),
      });
    }

    return items;
  };

  // ------------------------------

  buildCourseCardItems = (courses) => {
    const items = [];

    for (const courseObj of courses) {
      const c = courseObj.course;

      items.push({
        id: c.id,
        component: (
          <CourseItem
            key={courseObj.id}
            image={c.image}
            rating={c.rating}
            rating_count={c.rating_count}
            isVideo={c.is_video}
            title={c.title}
            description={c.description}
            objectives={c.objectives}
            credit={c.resolve_ce()}
            date={c.short_start_date()}
            avatar={c.avatar()}
            price={c.resolve_display_cost(
              resolveCurrencySymbol(this.props.mobileSetting.currency),
              resolveCurrency(
                c.lowest_cost(),
                c.provider_currency,
                this.props.rates
              ),
              resolveCurrency(
                c.highest_cost(),
                c.provider_currency,
                this.props.rates
              )
            )}
            onSelectCourse={this.courseDetailsHandler.bind(this, c)}
          />
        ),
      });
    }

    return items;
  };

  // ------------------------------

  updateMobileCurrency = async (currency, language) => {
    try {
      await this.props.updateCurrency(currency, language);
      setLanguage(language, this.props.i18n);
    } catch (error) {
      logger.error(error);
    }
  };

  // ------------------------------

  buildMyRegistrationItems = (registrations) => {
    const items = [];

    if (registrations && registrations.length > 0) {
      const c = registrations[0];

      items.push(
        <RegistrationItem
          containerStyle={{ paddingBottom: "0px", marginBottom: "0px" }}
          image={c.image}
          hideActionButtons
          key={c.id}
          archived={c.archived}
          course_id={c.course_id}
          course_title={c.course_title}
          casting={c.casting}
          date={c.renderStartDate()}
          registered_date={c.renderRegistrationDate()}
          unregistered_date={c.renderUnregistrationDate()}
          remaining={c.resolveDaysRemaining()}
          provider_instruction={c.provider_instruction}
          requiresRating={c.requiresRating()}
          provider={
            this.props.provider
              ? this.props.provider.find((p) => p.id === c.provider_id)
              : ""
          }
          onSelectCourse={this.myCoursesHandler}
          onViewCourse={() => { }}
          onViewHandout={() => { }}
          onViewInstruction={() => { }}
          onArchive={() => { }}
          onShareCourse={() => { }}
          onOpenInboxChat={() => { }}
          onOpenRating={this.myCoursesHandler}
          onOpenLocation={() => { }}
          onDelete={() => { }}
          onListRegistrants={() => { }}
        />
      );
    }

    return items;
  };

  // ------------------------------

  render() {
    if (this.state.wait) {
      return <Spinner message={this.state.waitMessage} />;
    }

    return (
      <div className={styles.screen}>
        <InfoModal
          isErrorModal={this.state.errorModal}
          title={this.props.t("error_occurred")}
          message={this.state.message}
          open={this.state.message !== null}
          onClose={() => {
            this.update({
              message: null,
            });
          }}
        />

        {this.state.openProviderListModal && (
          <ProviderListModal
            open={this.state.openProviderListModal}
            list={this.props.providers}
            onSelect={(provider) => {
              this.update({ openProviderListModal: false });

              if (provider) {
                this.openShopHandler(provider.id);
              }
            }}
          />
        )}

        {this.props.isAuthenticated ? null : (
          <DefaultToolbar
            history={this.props.history}
            i18n={this.props.i18n}
            mobileSetting={this.props.mobileSetting}
            updateMobileCurrency={this.updateMobileCurrency}
            showSearch={true}
          />
        )}

        {this.props.isAuthenticated ? null : (
          <div className={styles.welcomeToZubUContainer}>
            <div className={styles.welcomeToZubUTextContainer}>
              <div
                className={styles.welcomeBlurb}
                style={{
                  textAlign: "left",
                  flexDirection: "column",
                  alignItems: "flex-start",
                }}
              >
                <div className={styles.welcome}>
                  {this.props.t("sign_up_welcome")}
                </div>
                <div className={styles.welcomeSubTitle}>
                  {this.props.t("category_screen_the_marketplace")}
                </div>
                {this.props.t("category_screen_blurb")}
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-end",
                  alignItems: "flex-end",
                  flex: 1,
                  paddingTop: "10px",
                }}
              >
                <RoundedButton
                  onClick={this.joinNowHandler}
                  text={this.props.t("login_join")}
                  color={"rgb(28, 111, 211)"}
                />
              </div>
            </div>

            <div className={styles.lottieImageContainer}>
              <Lottie
                animationData={doctor}
                loop={true}
                className={styles.lottieImageHome}
              />
            </div>
          </div>
        )}

        {this.props.isAuthenticated && (
          <div className={styles.recentRegistrationContainer}>
            <div className={styles.loggedInWelcome}>
              {this.props.t("welcome_back")}
              {this.props.profile.first}
            </div>

            <CeSummary
              year={CURRENT_YEAR}
              completed={this.props.completedCE}
              pending={this.props.pendingCE}
              onClick={() => {
                this.props.history.push("/ce")
              }}
            />

            <div className={styles.recentRegTitle}>
              {this.props.t("home_recent_registrations")}
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
              }}
            >
              {this.buildMyRegistrationItems(this.props.registeredCourses)}

              <RoundedButton
                onClick={this.myCoursesHandler}
                text={this.props.t("g_bar_my_courses")}
                color={"rgb(28, 111, 211)"}
                icon={<ArrowForwardIcon style={{ color: "white" }} />}
              />
            </div>
            <hr style={{ width: "0.5px" }}></hr>
          </div>
        )}

        <div className={styles.categoryRootContainer}>
          <div className={styles.categoriesContainer}>
            {CATEGORIES_TOP.map((cat) => this.renderCategory(cat, false))}

            {CATEGORIES.map((cat) => this.renderCategory(cat, true))}
          </div>
        </div>

        {this.props.recommend && this.props.recommend.length > 0 && (
          <HorizontalScrollCards
            title={this.props.t("home_recommended")}
            items={this.buildCourseCardItems(this.props.recommend)}
          />
        )}

        {this.props.trending && this.props.trending.length > 0 && (
          <HorizontalScrollCards
            title={this.props.t("home_trending")}
            items={this.buildCourseCardItems(this.props.trending)}
          />
        )}

        {this.props.newly && this.props.newly.length > 0 && (
          <HorizontalScrollCards
            title={this.props.t("home_newly_added")}
            items={this.buildCourseCardItems(this.props.newly)}
          />
        )}

        {this.props.providers && this.props.providers.length > 0 && (
          <HorizontalScrollCards
            title={this.props.t("home_by_provider")}
            items={this.buildProviderItems(this.props.providers)}
          />
        )}

        <div className={styles.footerWhatIsContainer}>
          <div className={styles.footerWhatIsContainerWrapper}>
            <div className={styles.welcome}>
              {this.props.t("category_screen_what_is")}
            </div>

            <div className={styles.welcomeSubTitle}>
              {this.props.t("category_screen_the_marketplace")}
            </div>

            <div
              className={styles.headerTitleBlurb}
              style={{ alignItems: "flex-start", textAlign: "justify" }}
            >
              {this.props.t("category_screen_blurb")}
            </div>
          </div>
        </div>

        <div className={styles.featureImageContainer}>
          <div
            className={styles.featureImage}
            style={{
              backgroundImage: `url(${tip1})`,
            }}
          >
            <div className={styles.tipTitle}>
              {this.props.t("tips_ce_summary")}
            </div>
            <div className={styles.tipText}>
              {this.props.t("tips_ce_summary_desc")}
            </div>
          </div>
          <div
            className={styles.featureImage}
            style={{
              backgroundImage: `url(${tip2})`,
            }}
          >
            <div className={styles.tipTitle}>
              {this.props.t("tips_expense")}
            </div>
            <div className={styles.tipText}>
              {this.props.t("tips_expense_desc")}
            </div>
          </div>
          <div
            className={styles.featureImage}
            style={{
              backgroundImage: `url(${tip3})`,
            }}
          >
            <div className={styles.tipTitle}>
              {this.props.t("tips_register_team")}
            </div>
            <div className={styles.tipText}>
              {this.props.t("tips_register_team_desc")}
            </div>
          </div>
        </div>

        {this.props.isAuthenticated ? null : (
          <Footer history={this.props.history} />
        )}
      </div>
    );
  }
}

// ------------------------------

const mapStateToProps = (state) => {
  return {
    pendingCE: state.ce.pending,
    completedCE: state.ce.completed,
    recommend: state.courses.recommend,
    trending: state.courses.trending,
    newly: state.courses.newly,
    categoryIndex: state.courses.categoryIndex,
    profile: state.profile.userProfile,
    search_courses: state.courses.search_courses,
    hasMore: state.courses.hasMore,
    providers: state.provider.provider,
    mobileSetting: state.setting.mobileSetting,
    rates: state.rate.rate,
    registeredCourses: state.registration.registration,
    isAuthenticated: state.auth.token != null,
  };
};

// ------------------------------

const mapDispatchToProps = (dispatch) => {
  return {
    fetchTrending: () => dispatch(courseActions.fetchTrending()),
    fetchNewly: () => dispatch(courseActions.fetchNewly()),
    fetchRecommended: () => dispatch(courseActions.fetchRecommended()),
    fetchCategoryIndex: () => dispatch(courseActions.fetchCategoryIndex()),
    fetchProviders: () => dispatch(providerActions.fetchProviders()),
    setSelectedProviderCourse: (course) =>
      dispatch(providerActions.setSelectedProviderCourse(course)),
    searchCourses: (filters) => dispatch(courseActions.searchCourses(filters)),
    fetchUserProfile: () => dispatch(profileActions.fetchMobileUserProfile()),
    fetchMobileSetting: () => dispatch(settingActions.fetchMobileSetting()),
    updateCurrency: (currency, language) =>
      dispatch(settingActions.updateCurrency(currency, language)),
    fetchRates: () => dispatch(rateActions.fetchRates()),
    fetchRegistration: (page, archivedPage) =>
      dispatch(registrationActions.fetchRegistration(page, archivedPage)),
    fetchCeByYear: (fromYear, toYear, fromDate, toDate, badge) =>
      dispatch(ceActions.fetchCeByYear(fromYear, toYear, fromDate, toDate, badge)),
  };
};

export default withTranslation("translations")(
  connect(mapStateToProps, mapDispatchToProps)(CategoryScreen)
);
