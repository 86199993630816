import React, { Component } from "react";
import styles from "./LoginScreen.module.css";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import * as courseActions from "../../store/actions/courses";
import * as authActions from "../../store/actions/auth";
import * as redirectActions from "../../store/actions/redirect";
import * as settingActions from "../../store/actions/setting";
import ExitToAppRoundedIcon from "@material-ui/icons/ExitToAppRounded";
import Spinner from "../../components/ui/spinner/Spinner";
import InfoModal from "../../components/modals/infoModal/InfoModal";
import Button from "@material-ui/core/Button";
import MatInput from "../../components/ui/input/MatInput";
import Footer from "../../components/ui/footer/Footer";
import TextButton from "../../components/ui/button/TextButton";
import { objectHasNoValues, setLanguage } from "../../globals/Common";
import * as logger from "../../globals/Logger";
import DefaultToolbar from "../welcome/DefaultToolbar";
import Lottie from "lottie-react";
import phone from "../../assets/lottie/sittingphone.json";

import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";

class LoginScreen extends Component {
  state = {
    email: "",
    password: "",
    isLoading: false,
    error: null,
    keepSignedIn: true,
  };

  // ------------------------------

  componentDidMount = async () => {
    try {
      window.scrollTo(0, 0);
      if (objectHasNoValues(this.props.mobileSetting)) {
        await this.props.fetchMobileSetting();
      }

      if (
        this.props.params &&
        this.props.params.providerId &&
        this.props.params.courseId
      ) {
        await this.props.fetchCourse(
          this.props.params.courseId,
          this.props.params.providerid
        );
        this.props.setCourseDetailsRedirect(true);
      }
    } catch (error) {
      this.setState({ ...this.state, isLoading: false, error: error.message });
      logger.error(error);
    }
  };

  // ------------------------------

  loginHandler = async (event) => {
    try {
      event.preventDefault();
      this.setState({ ...this.state, error: null, isLoading: true });
      await this.props.login(
        this.state.email,
        this.state.password,
        this.state.keepSignedIn
      );

      if (this.props.courseDetailsRedirect) {
        this.props.setCourseDetailsRedirect(false);
        this.props.history.push("/details");
      } else {
        this.props.history.push("/");
      }
    } catch (error) {
      this.setState({ ...this.state, isLoading: false, error: error.message });
      logger.error(error);
    }
  };

  // ------------------------------

  inputChangeHandler = (e) => {
    const value = e.target.value;
    this.setState({
      ...this.state,
      [e.target.name]: value,
    });
  };

  // ------------------------------

  closeErrorHandler = () => {
    this.setState({
      ...this.state,
      error: null,
    });
  };

  // ------------------------------

  forgotPasswordHandler = () => {
    this.props.history.push("/password");
  };

  // ------------------------------

  joinNowHandler = () => {
    this.props.history.push("/studentregister");
  };

  // ------------------------------

  joinCourseProviderHandler = () => {
    this.props.history.push("/register");
  };

  // ------------------------------

  updateMobileCurrency = async (currency, language) => {
    try {
      await this.props.updateCurrency(currency, language);
      setLanguage(language, this.props.i18n);
    } catch (error) {
      logger.error(error);
    }
  };

  // ------------------------------

  onSwitch = async (e) => {
    const checked = e.target ? e.target.checked : false;
    this.setState({ ...this.state, keepSignedIn: checked });
  };

  // ------------------------------

  render() {
    if (this.state.isLoading) {
      return (
        <div className={styles.screen}>
          <Spinner />
        </div>
      );
    }

    return (
      <div className={styles.screen}>
        <DefaultToolbar
          history={this.props.history}
          i18n={this.props.i18n}
          mobileSetting={this.props.mobileSetting}
          updateMobileCurrency={this.updateMobileCurrency}
        />

        <InfoModal
          isErrorModal={true}
          title={this.props.t("error_occurred")}
          message={this.state.error}
          open={this.state.error !== null}
          onClose={this.closeErrorHandler}
        />

        <div className={styles.formAndFooter}>
          <div className={styles.form}>
            <MatInput
              title={this.props.t("g_email")}
              value={this.state.email}
              name="email"
              id="email"
              onChange={this.inputChangeHandler}
            />
            <MatInput
              title={this.props.t("g_password")}
              value={this.state.password}
              name="password"
              type="password"
              id="password"
              onChange={this.inputChangeHandler}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  e.preventDefault();
                  this.loginHandler(e);
                }
              }}
            />

            <div className={styles.buttonContainer}>
              <div className={styles.switchContainer}>
                <FormControlLabel
                  value="on"
                  control={
                    <Switch
                      color="secondary"
                      checked={this.state.keepSignedIn}
                      onChange={this.onSwitch}
                    />
                  }
                  label={
                    <div className={styles.switchText}>
                      {this.props.t("sign_in_stay_signed_in")}
                    </div>
                  }
                  labelPlacement="start"
                />
              </div>

              <Button
                variant="contained"
                style={{
                  backgroundColor: "#437aa1",
                  color: "white",
                }}
                startIcon={<ExitToAppRoundedIcon />}
                onClick={this.loginHandler}
              >
                {this.props.t("login")}
              </Button>
            </div>

            <div className={styles.paragraph2}>
              {this.props.t("forgot_password_trouble")}
              <div>
                {this.props.t("forgot_password_recover_pass")}&nbsp;
                <TextButton onClick={this.forgotPasswordHandler}>
                  {this.props.t("forgot_password_title")}.
                </TextButton>
              </div>
            </div>

            <div className={styles.paragraph2}>
              <div>
                {this.props.t("login_dont_have_account")}&nbsp;
                <TextButton onClick={this.joinNowHandler}>
                  {this.props.t("login_join")}
                </TextButton>
              </div>
            </div>

            <Lottie
              animationData={phone}
              loop={true}
              className={styles.lottieImage}
            />
          </div>

          <Footer history={this.props.history} />
        </div>
      </div>
    );
  }
}

// ------------------------------

const parseQueryString = (props) => {
  let providerId = null;
  let courseId = null;
  const query = new URLSearchParams(props.location.search);

  for (let param of query.entries()) {
    if (param[0] === "p") {
      providerId = param[1];
    }
    if (param[0] === "c") {
      courseId = param[1];
    }
  }

  return { providerId: providerId, courseId: courseId };
};

// ------------------------------

const mapStateToProps = (state, props) => {
  const params = parseQueryString(props);

  return {
    params: params,
    mobileSetting: state.setting.mobileSetting,
    courseDetailsRedirect: state.redirect.courseDetailsRedirect,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    login: (email, password, staySignedIn) =>
      dispatch(authActions.login(email, password, staySignedIn)),
    updateCurrency: (currency, language) =>
      dispatch(settingActions.updateCurrency(currency, language)),
    fetchMobileSetting: () => dispatch(settingActions.fetchMobileSetting()),
    setCourseDetailsRedirect: (redirect) =>
      dispatch(redirectActions.setCourseDetailsRedirect(redirect)),
    fetchCourse: (id, providerId) =>
      dispatch(courseActions.fetchCourse(id, providerId)),
  };
};

export default withTranslation("translations")(
  connect(mapStateToProps, mapDispatchToProps)(LoginScreen)
);
