import React from "react";
import NumberFormat from "react-number-format";
import styles from "./MatInputOutline.module.css";
import Toggle from "../../ui/toggle/Toggle";

const RefundInput = (props) => {
  return (
    <div
      style={{ display: "flex", flexDirection: "row", alignItems: "center" }}
    >
      <Toggle
        selected={props.selected}
        title="Refund"
        onChange={props.onToggleChange}
        disabled={props.disabled}
      />
      {props.selected && (
        <div
          className={
            props.haserror
              ? styles.refundContainerError
              : styles.refundContainer
          }
        >
          <NumberFormat
            maxLength={props.maxLength}
            value={props.value}
            onValueChange={(values) => {
              props.onChange({
                target: { name: props.name, value: values.value },
              });
            }}
            onChange={() => {}}
            className={styles.refund}
            autoComplete="off"
            thousandSeparator={true}
            prefix={props.currency.shortName}
            decimalScale={2}
            fixedDecimalScale={0}
            allowNegative={false}
            allowLeadingZeros={false}
          />
        </div>
      )}
    </div>
  );
};

export default RefundInput;
