import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import * as promoActions from "../../store/actions/promo";
import Promo from "../../model/Promo";
import Spinner from "../../components/ui/spinner/Spinner";
import InfoModal from "../../components/modals/infoModal/InfoModal";
import styles from "./Promo.module.css";
import SimpleContainer from "../../components/ui/container/Container";
import validate from "validate.js";
import { constraints, hasFormErrors } from "../../globals/Validations";
import MatInputOutline from "../../components/ui/input/MatInputOutline";
import Button from "@material-ui/core/Button";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import MatAppBar from "../../components/ui/appBar/MatAppBar";
import FormButton from "../../components/ui/button/FormButton";
import { hasError } from "../../globals/Validations";
import RedeemIcon from "@material-ui/icons/Redeem";
import InlinePop from "../../components/ui/popover/InlinePop";
import MatSwitch from "../../components/ui/switchInput/MatSwitch";
import DateSelect from "../../components/ui/dateSelect/DateSelect";
import MatSelectOutline from "../../components/ui/labelSelect/MatSelectOutline";
import IncrementSelect from "../../components/ui/increment/IncrementSelect";
import { initPromoLimit, initSeats } from "../../globals/Data";
import * as logger from "../../globals/Logger";
import {
  buildSelectFromIdArray,
  buildKeyValueSelect,
  buildCategory,
} from "../../globals/Common";
import { FNS_MEDIUM_DATE_FORMAT, newLocalDate } from "../../globals/Dates";

class PromoEditScreen extends Component {
  state = {
    promo: this.props.promo,
    wait: false,
    isInEdit: true,
    isForCourse: this.props.promo.courses.length > 0,
    isForUser: this.props.promo.users.length > 0,
    isLimitPromoCode: this.props.promo.usageLimit > 0,
    inputValue: "",
    error: null,
    formErrors: {},
    hasErrors: false,
  };

  // ------------------------------

  update = async (propValue) => {
    await this.setState({ ...this.state, ...propValue });
  };

  // ------------------------------

  componentDidUpdate = (prevProps) => {
    if (prevProps.promo.id !== this.props.promo.id) {
      this.update({
        promo: this.props.promo,
      });
    }
  };

  // ------------------------------

  saveHandler = async () => {
    try {
      await this.validateForm();

      if (this.state.hasErrors) {
        await this.update({
          error: "Please verify details.  Invalid fields.",
        });
        return;
      }

      if (this.props.promoId === "new" && this.isDuplicateCode()) {
        await this.update({
          error: `Promo code ${this.state.promo.promo_code} exists.  Please use a different code`,
        });
        return;
      }

      this.update({ wait: true });

      if (this.props.promoId === "new") {
        await this.props.addPromo(this.state.promo);
      } else if (this.props.promoId && this.state.promo.id) {
        await this.props.updatePromo(this.state.promo);
      }

      this.props.history.goBack();
    } catch (err) {
      logger.error(err);
      this.update({ wait: false, error: err.message });
    }
  };

  // ------------------------------

  validateForm = async () => {
    const e0 = this.state.formErrors;
    let e1 = this.validateField(e0, "promo_code", "plainRequired");

    if (this.state.isForCourse) {
      e1 = this.validateField(e1, "courses", "required");
    }

    if (this.state.isForUser) {
      e1 = this.validateField(e1, "users", "required");
    }

    await this.update({ formErrors: e1 });
    await this.hasErrorsHandler(e1);
  };

  // ------------------------------

  validateHandler = async (field, validatorKey) => {
    if (field === null) {
      await this.hasErrorsHandler(this.state.formErrors);
    } else {
      const errors = this.validateField(
        this.state.formErrors,
        field,
        validatorKey
      );
      await this.update({ formErrors: errors }); // await is crutial here
      await this.hasErrorsHandler(errors);
    }
  };

  // ------------------------------

  isDuplicateCode = () => {
    return (
      this.props.promoList.findIndex(
        (p) => p.promo_code === this.state.promo.promo_code
      ) > -1
    );
  };

  // ------------------------------

  validateField = (paramFormErrors, field, validatorKey) => {
    let validateObj = {};
    validateObj[validatorKey] = this.state.promo[field];
    const validationResponse = validate(validateObj, constraints);
    const updateFormErrors = { ...paramFormErrors };

    if (validationResponse[validatorKey]) {
      const firstError = validationResponse[validatorKey][0];
      updateFormErrors[field] = firstError;
    } else {
      delete updateFormErrors[field];
    }
    return updateFormErrors;
  };

  // ------------------------------

  hasErrorsHandler = async (formErrors) => {
    await this.update({ hasErrors: hasFormErrors(formErrors, {}) });
  };

  // ------------------------------

  inputChangeHandler = (e) => {
    const newPromo = { ...this.state.promo };
    newPromo[e.target.name] = e.target.value.toUpperCase();
    this.update({ promo: newPromo });
  };

  // ------------------------------

  matSwitchLimitHandler = async (key, event) => {
    const newState = { ...this.state };
    newState[event.target.name] = event.target.checked;

    if (!event.target.checked) {
      newState.promo[key] = -1;
      delete newState.formErrors[key];
    } else {
      newState.promo[key] = 100;
    }

    await this.setState(newState);
    await this.hasErrorsHandler(newState.formErrors);
  };

  // ------------------------------

  matSwitchChangeHandler = async (key, event) => {
    const newState = { ...this.state };
    newState[event.target.name] = event.target.checked;

    if (!event.target.checked) {
      newState.promo[key] = [];
      delete newState.formErrors[key];
    }

    await this.setState(newState);
    await this.hasErrorsHandler(newState.formErrors);
  };

  // ------------------------------

  multiSelectChangeHandler = (targetName, e, selects) => {
    const newPromo = { ...this.state.promo };
    const arrValues = [];

    for (const select of selects) {
      arrValues.push(select.value);
    }

    newPromo[targetName] = arrValues;
    this.update({ promo: newPromo });
  };

  // ------------------------------

  selectChangeHandler = (key, e, select) => {
    const newPromo = { ...this.state.promo };
    newPromo[key] = select ? select.value : "";
    this.update({ promo: newPromo });
  };

  // ------------------------------

  render() {
    if (this.props.action !== "PUSH") {
      return <Redirect to="/promo" />;
    }

    if (this.state.wait) {
      return <Spinner />;
    }

    return (
      <div style={{ background: "white" }}>
        <MatAppBar
          static
          left={
            <Button
              color="inherit"
              style={{ color: "black" }}
              onClick={this.props.history.goBack}
              startIcon={<ArrowBackIosIcon />}
            >
              Back
            </Button>
          }
        ></MatAppBar>

        <SimpleContainer disableGutters={false}>
          <InfoModal
            isErrorModal={true}
            title="An error has occured"
            message={this.state.error}
            open={this.state.error !== null}
            onClose={() => {
              this.update({ error: null });
            }}
          />

          <div style={{ height: "80px" }}>&nbsp;</div>

          <div className={styles.sectionTitle}>
            <div className={styles.centerRow}>
              <RedeemIcon style={{ color: "white" }} />
              &nbsp;&nbsp;Promo Code
            </div>
          </div>

          <InlinePop help="SAVE_15" />

          <MatInputOutline
            notopbottommargin="true"
            title="Promo Code Number  "
            name="promo_code"
            id="promo_code"
            value={this.state.promo.promo_code}
            onChange={this.inputChangeHandler}
            maxLength={100}
            onBlur={this.validateHandler.bind(
              this,
              "promo_code",
              "plainRequired"
            )}
            error={hasError(this.state.formErrors.promo_code)}
            readOnly={this.props.promoId !== "new"}
            helpertext={this.state.formErrors.promo_code}
            required
          />

          <IncrementSelect
            selectValue={this.state.promo.discount}
            onUpdate={(value) => {
              const newPromo = { ...this.state.promo };
              newPromo["discount"] = value;
              this.update({ promo: newPromo });
            }}
            initialValue={5}
            maxValue={100}
            minValue={5}
            interval={5}
            title="Discount value of your promo code in % "
            name="discount"
            id="discount"
            disableClearable={true}
            onChange={this.selectChangeHandler.bind(this, "discount")}
            options={initSeats(5, 0, 5)}
            value={{
              value: this.state.promo.discount + "",
              label: this.state.promo.discount + "",
            }}
            getOptionLabel={(option) => option.label}
            getOptionSelected={(opt1, opt2) => opt1.value === opt2.value}
          />

          <div style={{ height: "35px" }}>&nbsp;</div>

          <div className={styles.formElementContainer}>
            <DateSelect
              title="Start Date"
              required
              minDate={new Date()}
              dateFormat={FNS_MEDIUM_DATE_FORMAT}
              selected={this.state.promo.start_date}
              onChange={(date) => {
                const newPromo = { ...this.state.promo };
                newPromo.start_date = newLocalDate(date);
                if (
                  newPromo.start_date.getTime() >= newPromo.end_date.getTime()
                ) {
                  newPromo.end_date = newLocalDate(date);
                }
                this.update({ promo: newPromo });
              }}
            />
          </div>

          <div style={{ height: "27px" }}>&nbsp;</div>

          <div className={styles.formElementContainer}>
            <DateSelect
              title="Expiry Date"
              required
              minDate={this.state.promo.start_date}
              dateFormat={FNS_MEDIUM_DATE_FORMAT}
              selected={this.state.promo.end_date}
              onChange={(date) => {
                const newPromo = { ...this.state.promo };
                newPromo.end_date = newLocalDate(date);
                this.update({ promo: newPromo });
              }}
            />
          </div>

          <div style={{ height: "13px" }}>&nbsp;</div>

          <MatSwitch
            title="Limit the number of times this promo code can be used ?"
            id="isLimitPromoCode"
            name="isLimitPromoCode"
            checked={this.state.isLimitPromoCode}
            onChange={this.matSwitchLimitHandler.bind(this, "usageLimit")}
          />

          {this.state.isLimitPromoCode && (
            <IncrementSelect
              selectValue={this.state.promo.usageLimit}
              onUpdate={(value) => {
                const newPromo = { ...this.state.promo };
                newPromo["usageLimit"] = value;
                this.update({ promo: newPromo });
              }}
              initialValue={100}
              maxValue={500}
              minValue={1}
              title="Usage Limit: "
              name="usageLimit"
              id="usageLimit"
              disableClearable={true}
              onChange={this.selectChangeHandler.bind(this, "usageLimit")}
              options={initPromoLimit(1, 0)}
              value={{
                value: this.state.promo.usageLimit + "",
                label: this.state.promo.usageLimit + "",
              }}
              getOptionLabel={(option) => option.label}
              getOptionSelected={(opt1, opt2) => opt1.value === opt2.value}
            />
          )}

          <MatSwitch
            title="Applicable to specific course(s) ?"
            id="isForCourse"
            name="isForCourse"
            checked={this.state.isForCourse}
            onChange={this.matSwitchChangeHandler.bind(this, "courses")}
          />

          {this.state.isForCourse && (
            <MatSelectOutline
              noTopMargin
              title="To which course is your promo code applicable?"
              placeholder="Enter course..."
              name="courses"
              id="courses"
              onChange={this.multiSelectChangeHandler.bind(this, "courses")}
              options={buildKeyValueSelect(this.props.courses, "id", "title")}
              value={buildSelectFromIdArray(
                this.state.promo.courses ? this.state.promo.courses : [],
                this.props.courses,
                "title"
              )}
              getOptionLabel={(option) => option.label}
              getOptionSelected={(opt1, opt2) => opt1.value === opt2.value}
              onBlur={this.validateHandler.bind(this, "courses", "required")}
              error={hasError(this.state.formErrors.courses)}
              helperText={this.state.formErrors.courses}
              disabled={!this.state.isInEdit}
              multiple={true}
              required={true}
            />
          )}

          <MatSwitch
            title="Assigned to specific ZubU user(s) ? "
            id="isForUser"
            name="isForUser"
            checked={this.state.isForUser}
            onChange={this.matSwitchChangeHandler.bind(this, "users")}
          />

          {this.state.isForUser && (
            <MatSelectOutline
              noTopMargin
              title="Enter the email of the recipient of this promo code"
              placeholder="Enter email..."
              name="users"
              id="users"
              includeInputInList={true}
              onChange={this.multiSelectChangeHandler.bind(this, "users")}
              options={[]}
              value={buildCategory(this.state.promo.users, true)}
              getOptionLabel={(option) => option.label}
              getOptionSelected={(opt1, opt2) => opt1.value === opt2.value}
              onBlur={async (inputValue) => {
                if (inputValue) {
                  const newPromo = { ...this.state.promo };
                  const arrUsers = [...newPromo.users];
                  newPromo.users = arrUsers.concat(
                    inputValue.replace(" ", "").split(/[;,]+/)
                  );
                  await this.update({ promo: newPromo });
                }
                this.validateHandler("users", "required");
              }}
              error={hasError(this.state.formErrors.users)}
              helperText={this.state.formErrors.users}
              disabled={!this.state.isInEdit}
              multiple={true}
              required={false}
            />
          )}

          {this.state.isInEdit && (
            <FormButton
              label="Save"
              onClick={this.saveHandler}
              disabled={!this.state.isInEdit || this.state.hasErrors}
            />
          )}
        </SimpleContainer>

        <div style={{ height: "80px" }}>&nbsp;</div>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  const promoId = state.promo.promoId;
  const promoList = state.promo.promo;
  const editPromo = promoList.find((p) => p.id === promoId);

  return {
    action: props.history.action,
    promo: editPromo ? editPromo : new Promo(),
    promoId: promoId,
    courses: state.courses.courses,
    promoList: state.promo.promo,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addPromo: (promo) => dispatch(promoActions.addPromo(promo)),
    updatePromo: (promo) => dispatch(promoActions.updatePromo(promo)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PromoEditScreen);
