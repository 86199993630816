import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { isIOS, isIOS13, isAndroid } from "react-device-detect";
import Spinner from "../../components/ui/spinner/Spinner";
import * as logger from "../../globals/Logger";

class MobileRedirectScreen extends Component {
  state = {
    wait: true,
  };

  // ------------------------------

  update = async (propValue) => {
    await this.setState({ ...this.state, ...propValue });
  };

  // ------------------------------

  componentDidMount = async () => {
    try {
      if (isIOS || isIOS13) {
        window.location.href =
          "https://apps.apple.com/ca/app/zubu/id1546508116";
      } else if (isAndroid) {
        window.location.href =
          "https://play.google.com/store/apps/details?id=com.zubueducation.zubu";
      }
    } catch (err) {
      this.props.history.push("/");
      logger.error(err);
    }

    this.update({ wait: false });
  };

  // ------------------------------

  render() {
    if (this.state.wait) {
      return <Spinner />;
    } else {
      return <Redirect to="/" />;
    }
  }
}

export default connect(null, null)(MobileRedirectScreen);
