import React from "react";
import styles from "./CartItem.module.css";
import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";
import SchoolIcon from "@material-ui/icons/School";

const CartItem = (props) => {
  return (
    <div className={styles.itemContainer}>
      {/****************************************/}

      <div className={styles.columnTitle}>
        <div className={styles.summaryRow}>
          <div className={styles.descColumn}>
            <div className={styles.summaryPriceText}>
              <SchoolIcon
                style={{
                  size: "10px",
                  paddingRight: "5px",
                  paddingTop: "9px",
                }}
              />
              {props.courseTitle}
            </div>
            <div className={styles.cap}>
              {props.addCategoryName} {props.startDate} ( {props.numOfWeeks}{" "}
              week
              {props.numOfWeeks > 1 ? "s" : null} )
            </div>
          </div>
          <div className={styles.priceRow}>
            <div
              style={{
                height: "100%",
                verticalAlign: "baseline",
                display: "flex",
                alignItems: "baseline",
              }}
            >
              {" "}
              {props.currency.shortName}
              {props.cost.toFixed(2)}
            </div>
          </div>
        </div>
        {/*****************************************/}
        {props.prime === true && (
          <div className={styles.summaryRow}>
            <div className={styles.descColumn}>
              <div className={styles.summaryTextItalic}>
                (Appear in prime slot)
              </div>
            </div>
            <div className={styles.priceRow}>
              <div>
                {props.currency.shortName}
                {props.primeCost.toFixed(2)}
              </div>
            </div>
          </div>
        )}
        <div style={{ height: "15px" }}></div>
      </div>
      {props.deletable && (
        <div className={styles.columnDelete}>
          <IconButton
            edge="start"
            className={styles.menuButton}
            color="inherit"
            aria-label="menu"
            onClick={props.onClick}
          >
            <DeleteIcon style={{ color: "red" }} />
          </IconButton>
        </div>
      )}
    </div>
  );
};

export default CartItem;
