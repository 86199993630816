import React from "react";
import styles from "./RoundedButton.module.css";

const RoundedButton = (props) => {
  return (
    <div
      onClick={props.onClick}
      className={styles.card}
      style={{
        backgroundColor: `${props.color}`,
      }}
    >
      <div className={styles.center}>
        {props.text}&nbsp;{props.icon ? props.icon : null}
      </div>
    </div>
  );
};

export default RoundedButton;
