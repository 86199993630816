import { handleErrorResponse } from "../../globals/Error";
import MarketingAd from "../../model/MarketingAd";
import { fromFirebaseStringArrayValue } from "../../globals/Common";
import { dbName } from "../../globals/ApiKeys";
export const SET_ADVERTISEMENT = "SET_ADVERTISEMENT";
export const REMOVE_MARKETING_ADD = "REMOVE_MARKETING_ADD;";
export const SET_ADD_ID = "SET_ADD_ID";

// ------------------------------

export const setAddAction = (adType) => {
  return {
    type: SET_ADD_ID,
    marketingAddId: adType,
  };
};

// ------------------------------

export const setExtendAction = (id) => {
  return {
    type: SET_ADD_ID,
    marketingAddId: id,
  };
};

// ------------------------------

export const fetchMarketingAd = () => {
  return async (dispatch, getState) => {
    const userId = getState().auth.userId;
    const token = getState().auth.token;

    const response = await fetch(
      `https://firestore.googleapis.com/v1/projects/${dbName}/databases/(default)/documents/provider_advertisement/${userId}/advertisement`,
      {
        method: "GET",
        headers: {
          "Content-Type": "applications/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );

    if (!response.ok) {
      handleErrorResponse(await response.json());
    }

    const resData = await response.json();

    let arrDocs = [];
    if (Array.isArray(resData)) {
      arrDocs = resData;
    } else {
      arrDocs = resData.documents;
    }

    const loadedAds = [];

    if (arrDocs) {
      for (const ad of arrDocs) {
        if (ad && ad.name) {
          const adNameArray = ad.name.split("/");
          const adId = adNameArray[adNameArray.length - 1];

          loadedAds.push(
            new MarketingAd(
              adId,
              ad.fields.add_course_id.stringValue,
              ad.fields.add_course_title.stringValue,
              ad.fields.add_category.stringValue,
              ad.fields.add_prime.booleanValue,
              new Date(ad.fields.add_start_date.timestampValue),
              new Date(ad.fields.add_expiration_date.timestampValue),
              ad.fields.add_slide ? ad.fields.add_slide.integerValue : -1,
              ad.fields.add_headline ? ad.fields.add_headline.stringValue : "",
              ad.fields.add_callForAction
                ? ad.fields.add_callForAction.stringValue
                : "",
              ad.fields.add_showLogo
                ? ad.fields.add_showLogo.booleanValue
                : false,
              ad.fields.courses_featured_id.stringValue,
              ad.fields.add_locations
                ? fromFirebaseStringArrayValue(
                    ad.fields.add_locations.arrayValue.values
                  )
                : [],
              ad.fields.add_exclude_locations
                ? fromFirebaseStringArrayValue(
                    ad.fields.add_exclude_locations.arrayValue.values
                  )
                : [],
              ad.fields.add_experience
                ? fromFirebaseStringArrayValue(
                    ad.fields.add_experience.arrayValue.values
                  )
                : [],
              ad.fields.add_occupation
                ? fromFirebaseStringArrayValue(
                    ad.fields.add_occupation.arrayValue.values
                  )
                : []
            )
          );
        }
      }
    }

    dispatch({
      type: SET_ADVERTISEMENT,
      marketingAd: loadedAds,
    });
  };
};

// ------------------------------

export const removeMarketingAdd = (add) => {
  return async (dispatch, getState) => {
    const userId = getState().auth.userId;
    const token = getState().auth.token;

    await Promise.all([
      fetch(
        `https://firestore.googleapis.com/v1/projects/${dbName}/databases/(default)/documents/provider_advertisement/${userId}/advertisement/${add.id}`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "applications/json",
            Authorization: `Bearer ${token}`,
          },
        }
      ),
      fetch(
        `https://firestore.googleapis.com/v1/projects/${dbName}/databases/(default)/documents/courses_featured/${add.courses_featured_id}`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "applications/json",
            Authorization: `Bearer ${token}`,
          },
        }
      ),
    ]);

    dispatch({
      type: REMOVE_MARKETING_ADD,
      mareketingAddId: add.id,
    });
  };
};

// ------------------------------
