import React from "react";
import styles from "./Rating.module.css";
import Rating from "./Rating";

const CourseCardRating = (props) => {
  return (
    <div className={styles.courseCartRatingContainer}>
      <div className={styles.ratingScore}>{props.rating}</div>
      <Rating
        style={{
          width: "100%",
          justifyContent: "flex-start",
          padding: "0px",
        }}
        rating={props.rating}
        fontSize="inherit"
      />
      <div className={styles.ratingCount}>({props.rating_count})</div>
    </div>
  );
};

export default CourseCardRating;
