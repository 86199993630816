import React, { Fragment } from "react";
import { useTranslation } from "react-i18next";
import styles from "./CourseViewer.module.css";
import EventOutlinedIcon from "@material-ui/icons/EventOutlined";
import CheckIcon from "@material-ui/icons/Check";
import { buildCourseDates, validYouTube } from "../../../globals/Common";
import PinDropIcon from '@material-ui/icons/PinDrop';
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import AmendmentItem from "../../items/amendmentItem/AmendmentItem";
import ProfessorColapseItem from "../../items/professorColapseItem/ProfessorColapseItem";
import MobilePricingItem from "../../items/mobilePricingItem/MobilePricingItem";
import TextButton from "../button/TextButton";
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import {
  isEqualDate,
  momentLongDate,
  momentTime,
} from "../../../globals/Dates2";
import ReactPlayer from "react-player/youtube";
import useMediaQuery from "@material-ui/core/useMediaQuery";

// ------------------------------

const contactHandler = (email) => {
  window.open(`mailto:${email}`, "_blank");
};

const openGoogleMap = (urlMap) => {
  window.open(urlMap, "_blank");
};

// ------------------------------

const renderPricingItems = (
  currency,
  pricingItems,
  amendment,
  multiPayOption,
  rates,
  providerCurrency,
  onlineSeats,
  classroomSeats,
  classroomHandsonSeats,
  isVideo
) => {
  return pricingItems.map((item, index) => {
    return (
      <MobilePricingItem
        row={index}
        key={index}
        multiPayOption={multiPayOption}
        isVideo={false}
        pricingItem={item}
        amendment={amendment}
        providerCurrency={providerCurrency}
        currency={currency}
        capacity={
          isVideo || item.casting === "Livestream"
            ? onlineSeats
            : item.component === "Lecture Only"
              ? classroomSeats
              : classroomHandsonSeats
        }
        rates={rates}
      />
    );
  });
};

// ------------------------------

const renderDateFromTo = (dates, date, index) => {
  let previousDate = null;
  let sameDay = false;
  let dateTimeSheduleItem = "";

  const dateDescription = date.desc ? date.desc + ": " : "";

  if (index > 0) {
    previousDate = dates[index - 1].from;
    sameDay = isEqualDate(date.from, previousDate);
  }

  if (sameDay) {
    // just time range
    dateTimeSheduleItem = (
      <div>
        {dateDescription}
        {momentTime(date.from)} to
        {momentTime(date.to)}
      </div>
    );
  } else {
    // date title + time range
    dateTimeSheduleItem = (
      <div>
        <div className={styles.dateTitle}>{momentLongDate(date.from)}</div>
        <div className={styles.listItemColumn}>
          {dateDescription}
          {"  "}
          {momentTime(date.from)} - {momentTime(date.to)}
        </div>
      </div>
    );
  }

  return (
    <div key={index}>
      <div style={{ paddingBottom: "4px" }}>{dateTimeSheduleItem}</div>
    </div>
  );
};

// ------------------------------

const renderDates = (dates) => {
  const sortedDates = dates.sort((a, b) =>
    new Date(a.from).getTime() < new Date(b.from).getTime() ? -1 : 1
  );

  return sortedDates.map((item, index) => renderDateFromTo(dates, item, index));
};

// ------------------------------

const CourseListing = (props) => {
  const { t } = useTranslation();
  const matches = useMediaQuery("(min-width:699px)");

  return (
    <div className={styles.phoneContent}>
      <div className={styles.phoneContentCenterRow}>
        <div className={styles.phoneContentMainTitle}>{props.course.title}</div>
      </div>

      {props.amendment && (
        <AmendmentItem amendment={props.amendment} key={"-1"} />
      )}

      {props.course.amendment &&
        props.course.amendment.map((a, index) => (
          <AmendmentItem amendment={a} key={index} />
        ))}

      {validYouTube(props.course.youtube_uri) && (
        <div style={{ paddingBottom: "20px" }}>
          <ReactPlayer
            url={props.course.youtube_uri + "?rel=0"}
            controls={false}
            muted={false}
            width="100%"
            height={matches ? "420px" : "200px"}
            config={{
              file: { attributes: { controlsList: "nodownload" } },
            }}
            onContextMenu={(e) => e.preventDefault()}
          />
        </div>
      )}

      <div className={styles.phoneContentRow}>
        <div className={styles.phoneContentTitle100}>
          {t("cat_short_professors")}
        </div>
        {!props.course.professor ||
          (props.course.professor.length === 0 && (
            <div className={styles.phoneContentText}>{t("course_tbd")}</div>
          ))}
      </div>

      {props.course.professor &&
        props.course.professor.map((p, index) => (
          <ProfessorColapseItem professor={p} key={p.id} />
        ))}

      <div className={styles.phoneContentRow}>
        <div className={styles.phoneContentTitle100}>
          {t("course_description")}
        </div>
        <div className={styles.phoneContentText}>
          {props.course.description}
        </div>
      </div>

      {props.course.accreditation && props.course.accreditation.length > 0 && (
        <div className={styles.phoneContentRow}>
          <div className={styles.phoneContentTitle100}>
            {t("course_accreditation")}
          </div>
          <div className={styles.phoneContentText}>
            {props.course.accreditation.join(", ")}
          </div>
        </div>
      )}

      {props.course.topic_number && (
        <div className={styles.phoneContentRow}>
          <div className={styles.phoneContentTitle100}>
            {t("course_topic")}
          </div>
          <div className={styles.phoneContentText}>
            {props.course.topic_number}
          </div>
        </div>
      )}

      <hr></hr>

      {props.course.start_date && props.course.start_date.length > 0 ? (
        <Fragment>
          <div className={styles.phoneContentRow}>
            <div className={styles.phoneContentTitle}>
              <EventOutlinedIcon style={jss.iconsm} />
              &nbsp;
              {t("course_schedule")}
            </div>
            {renderDates(
              buildCourseDates(
                props.course.start_date_desc,
                props.course.start_date,
                props.course.end_date,
                props.course.timezoneAbbr
              )
            )}
          </div>
          <hr></hr>
        </Fragment>
      ) : !props.course.is_video ? (
        <Fragment>
          <div className={styles.phoneContentRow}>
            <div className={styles.phoneContentTitle}>
              <EventIcon style={jss.iconsm} />
              {t("course_schedule")}
            </div>
            <div>{t("course_tbd")}</div>
          </div>
          <hr></hr>
        </Fragment>
      ) : null}

      <div className={styles.phoneContentRow}>
        <div className={styles.phoneContentTitle100}>
          {t("course_reg_options")}
        </div>
        {renderPricingItems(
          props.currency,
          props.course.pricingItems,
          props.course.amendment,
          props.course.multi_pay_option,
          props.rates,
          props.course.provider_currency,
          props.course.onlineSeatsAvailable(),
          props.course.classroomSeatsAvailable(),
          props.course.classroomHandsonSeatsAvailable(),
          props.course.is_video
        )}
      </div>

      <hr></hr>

      <div className={styles.phoneContentRow}>
        <div className={styles.phoneContentTitle100}>
          {t("course_objectives")}
        </div>
        <div className={styles.phoneContentText}>{props.course.objectives}</div>
      </div>

      <hr></hr>

      <div className={styles.phoneContentRow}>
        {props.course.location && (
          <div className={styles.phoneContentInnerRow}>
            <div className={styles.phoneContentTitle}>
              {t("course_location")}
            </div>
            <div>{props.course.location}</div>
          </div>
        )}

        {props.course.address && props.course.address[0].length > 0 && (
          <div className={styles.phoneContentInnerRow}>
            <div className={styles.phoneContentTitle}>{t("g_address")}
              &nbsp;<PinDropIcon style={{ color: 'black' }} />
            </div>
            <div className={styles.phoneContentDescription}>
              <div className={styles.addressLink}
                onClick={openGoogleMap.bind(this, props.course.googleMapUrl())}>
                {props.course.resolveAddress()}<OpenInNewIcon style={{ marginLeft: "10px" }} />
              </div>
            </div>
          </div>
        )}

        {props.course.notes && (
          <div className={styles.phoneContentInnerRow}>
            <div className={styles.phoneContentTitle}>{t("course_notes")}</div>
            <div className={styles.phoneContentText}>{props.course.notes}</div>
          </div>
        )}

        {props.course.is_university_associated && (
          <div className={styles.phoneContentRow}>
            <div>
              <CheckIcon style={jss.iconCheck} /> {t("course_uni_associated")}
            </div>
          </div>
        )}

        <div className={styles.phoneContentInnerRow} style={{ paddingTop: 5 }}>
          <div className={styles.phoneContentTitle}>{t("course_ref")}</div>
          <div>
            {props.course.reference_number
              ? props.course.reference_number
              : "0000 - 00000 - 000000 - 00000000"}
          </div>
        </div>
      </div>

      <hr />

      <div className={styles.phoneContentRow}>
        <div className={styles.phoneContentTitle100}>
          {t("course_contact_provider")}
        </div>
        <div className={styles.phoneContentText}>
          <div className={styles.centerRow} style={{ paddingTop: "10px" }}>
            <MailOutlineIcon style={jss.iconsm} />
            <TextButton
              style={{ paddingLeft: 4 }}
              onClick={contactHandler.bind(this, props.course.email)}
            >
              {props.course.email}
            </TextButton>
          </div>
        </div>
      </div>

      <hr></hr>

      <div className={styles.phoneContentRow}>
        <div className={styles.phoneContentTitle100}>
          {t("course_cancellation_policy")}
        </div>
        <div className={styles.phoneContentText}>
          {props.course.cancel_policy}
        </div>
      </div>
    </div>
  );
};

// ------------------------------

const jss = {
  iconsm: {
    fontSize: "20px",
  },
  icon: {
    fontSize: "40px",
  },

  iconFooterDark: {
    fontSize: "40px",
  },
  iconFooter: {
    fontSize: "40px",
    color: "#77b5cc",
  },
  iconCheck: {
    fontSize: "20px",
    color: "greem",
  },
};

export default CourseListing;
