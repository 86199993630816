import React, { Component } from "react";
import { connect } from "react-redux";
import InfoModal from "../components/modals/infoModal/InfoModal";
import Spinner from "../components/ui/spinner/Spinner";
import * as connectActions from "../store/actions/connect";
import * as authActions from "../store/actions/auth";
import * as logger from "../globals/Logger";

class AccountConnected extends Component {
  state = {
    wait: false,
    error: true,
    message: "Account setup failed.",
  };

  // ------------------------------

  update = async (object) => {
    await this.setState({ ...this.state, ...object });
  };

  // ------------------------------

  componentDidMount = async () => {
    if (
      this.props.connectCode &&
      this.props.connectState &&
      !this.props.isConnected
    ) {
      try {
        await this.update({ message: null, wait: true, error: false });

        await this.props.connectAdmin(
          this.props.connectCode,
          this.props.connectState
        );

        await this.update({ wait: false, error: false });
      } catch (error) {
        logger.error(error);

        this.update({
          error: true,
          wait: false,
          message: error.message,
        });
      }
    }
  };

  // ------------------------------

  closeHandler = async () => {
    try {
      await this.props.setConnectId();
      this.props.history.push("/");
    } catch (error) {
      logger.error(error);
      this.props.logout();
    }
  };

  // ------------------------------

  render() {
    if (this.state.wait) {
      return <Spinner message="Preparing your account" />;
    }

    if (this.state.error === false) {
      return (
        <InfoModal
          isErrorModal={false}
          title="Account Setup Success"
          message={"Your ZubU provider account is ready."}
          open={true}
          onClose={this.closeHandler}
        />
      );
    }

    return (
      <InfoModal
        isErrorModal={true}
        title="An error has occured"
        message={this.state.message}
        open={true}
        onClose={this.closeHandler}
      />
    );
  }
}

// ------------------------------

const parseQueryString = (props) => {
  let connectCode = null;
  let connectState = null;

  const query = new URLSearchParams(props.location.search);

  for (let param of query.entries()) {
    if (param[0] === "code") {
      connectCode = param[1];
    }
    if (param[0] === "state") {
      connectState = param[1];
    }
  }

  return { connectCode: connectCode, connectState: connectState };
};

// ------------------------------

const mapStateToProps = (state, props) => {
  const params = parseQueryString(props);

  return {
    isConnected:
      state.auth.connectId !== null &&
      state.auth.connectId !== "PENDING" &&
      state.auth.connectId !== "123456",
    connectCode: params.connectCode,
    connectState: params.connectState,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    warning: (value) => dispatch(connectActions.warning(value)),
    connectAdmin: (code, state) =>
      dispatch(connectActions.connectAdmin(code, state)),
    logout: () => dispatch(authActions.logout()),
    setConnectId: () => dispatch(authActions.setConnectId()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AccountConnected);
