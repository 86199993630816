import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import ImageSelector from "./ImageSelector";
import { useTheme } from "@material-ui/core/styles";

const ImageSelectConfirmModal = (props) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("xs"));
  const fullWidth = true;
  const maxWidth = "sm";

  // ------------------------------

  const [image, setImage] = useState(props.image);

  // ------------------------------

  const cancelHandler = () => {
    props.onClose(null);
  };

  // ------------------------------

  const saveHandler = () => {
    props.onClose(image);
  };

  // ------------------------------

  return (
    <div>
      <Dialog
        fullScreen={fullScreen}
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={props.open}
        onClose={cancelHandler}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="alert-dialog-title">{props.title}</DialogTitle>

        <DialogContent>
          <ImageSelector
            image={image}
            aspectRatio={props.aspectRatio}
            imageWidth={props.imageWidth}
            imageHeight={props.imageHeight}
            setImage={(url) => {
              setImage(url);
            }}
            onRemovePic={() => {
              props.onRemovePic(image);
            }}
          />
        </DialogContent>

        <DialogActions>
          <Button autoFocus onClick={cancelHandler} color="primary">
            Cancel
          </Button>
          <Button autoFocus onClick={saveHandler} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ImageSelectConfirmModal;
