import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import ReportProblemOutlinedIcon from "@material-ui/icons/ReportProblemOutlined";
import CheckCircleOutlineOutlinedIcon from "@material-ui/icons/CheckCircleOutlineOutlined";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";

const InfoModal = (props) => {
  let headerStyle = {
    color: "white",
    background:
      "linear-gradient(15deg,rgba(2, 0, 36, 1) 0%,rgba(136, 174, 190, 1) 100%,rgba(247, 254, 255, 1) 100%)",
  };
  let icon = <CheckCircleOutlineOutlinedIcon />;

  if (props.isErrorModal) {
    headerStyle = { backgroundColor: "#a10606", color: "white" };
    icon = <ErrorOutlineIcon />;
  }

  if (props.isWarnModal) {
    headerStyle = { backgroundColor: "orange", color: "white" };
    icon = <ReportProblemOutlinedIcon />;
  }

  if (props.plain) {
    headerStyle = { backgroundColor: null };
    icon = <InfoOutlinedIcon />;
  }

  return (
    <div>
      <Dialog
        fullScreen={props.fullScreen ? props.fullScreen : false}
        open={props.open}
        onClose={props.onClose.bind(this)}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title" style={headerStyle}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            {icon}&nbsp;{props.title}
          </div>
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            style={{ textAlign: "left", fontStyle: "normal", color: "black" }}
          >
            {props.message}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={props.onClose.bind(this)}
            color={props.isErrorModal ? "secondary" : "primary"}
            autoFocus
          >
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default InfoModal;
