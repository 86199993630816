import React, { Component } from "react";

import styles from "../Course.module.css";
import { connect } from "react-redux";
import CourseViewer from "../../../components/ui/courseViewer/CourseViewer";

class PreviewScreen extends Component {
  render() {
    return (
      <div>
        <form className={styles.form}>
          <div className={styles.sectionTitle}>Preview </div>
          <CourseViewer
            currency={this.props.currency}
            course={this.props.course}
            amendment={this.props.amendment}
          />
        </form>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    currency: state.profile.profile.currency,
    amendment: props.amendment,
    course: props.course,
  };
};

export default connect(mapStateToProps, null)(PreviewScreen);
