import React, { Fragment, useState, useEffect } from "react";
import styles from "../Course.module.css";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import MatSelectOutline from "../../../components/ui/labelSelect/MatSelectOutline";
import MultiDateSelect from "../../../components/ui/multiDateSelect/MultiDateSelect";
import YouTubeIcon from "@material-ui/icons/YouTube";
import AddressInput from "../../../components/ui/addressInput/AddressInput";
import MatSwitch from "../../../components/ui/switchInput/MatSwitch";
import MatInputOutline from "../../../components/ui/input/MatInputOutline";
import MatCheckbox from "../../../components/ui/checkbox/MatCheckbox";
import InlinePop from "../../../components/ui/popover/InlinePop";
import AddProfessorModal from "../../../components/modals/addProfessorModal/AddProfessorModal";
import SurveyPreviewModal from "../../../components/modals/surveyModal/SurveyPreviewModal";
import CertPreviewModal from "../../../components/modals/certPreviewModal/CertPreviewModal";
import TextButton from "../../../components/ui/button/TextButton";
import Pop from "../../../components/ui/popover/Pop";
import RadioGroup from "../../../components/ui/radio/RadioGroup";
import Collapse from "@material-ui/core/Collapse";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import survey from "../../../assets/images/inApp/coursesurvey.png";
import handoutPic from "../../../assets/images/inApp/handout.png";
import EmojiFlagsIcon from "@material-ui/icons/EmojiFlags";

import ReactPlayer from "react-player/youtube";
import { validYouTube, resolveCoverPhoto } from "../../../globals/Common";
import MultiPayOption from "../../../model/MultiPayOption";
import Handout from "../../../components/ui/handout/Handout";
import ImageSelector from "../../../components/modals/imageSelectConfirmModal/ImageSelector";
import VideoChapterItemList from "../../../components/items/videoChapterItem/VideoChaperItemList"

import {
  buildKeyValueSelectProf,
  buildCategory,
  buildSingleSelect,
  buildCourseDates,
  buildKeyValueSelect,
} from "../../../globals/Common";
import {
  selectAccreditation,
  selectSpecialist,
  selectCategories,
  selectSeatsOnline,
  selectSeatsVideo,
  selectSeatsClassroom,
  selectLang,
} from "../../../globals/Data";

import { hasError } from "../../../globals/Validations";

import * as lodashCloneDeep from "lodash.clonedeep";

// ------------------------------

const thisSetting =
  " This setting can be changed / updated after course activation.";
const fourtyEightHoursAfter =
  " will be sent to registered students 48 hours after registration.  ";
const postCourseDate =
  " will be sent to registered students after the course end date.  ";
const noCert =
  "No certificate will be issued after completing the survey.  If you would like a certificate to be issued please select one below.";

// ------------------------------

const CourseEditForm = (props) => {
  // ------------------------------

  const [showProfModal, setShowProfModal] = useState(false);
  const [showSurveyPreviewModal, setShowSurveyPreviewModal] = useState(false);
  const [showCertPreviewModal, setShowCertPreviewModal] = useState(false);

  const [surveyHelper, setSurveyHelper] = useState("");
  const [certHelper, setCertHelper] = useState("");

  // ------------------------------

  useEffect(() => {
    resolveSurveyCertificateHelpers(props.course);
  }, []);

  // ------------------------------

  const resolveSurveyCertificateHelpers = (course) => {
    if (course.survey && !course.certificate) {
      setCertHelper("");

      if (course.is_video) {
        setSurveyHelper(
          `Survey ${fourtyEightHoursAfter} ${noCert} ${thisSetting}`
        );
      } else {
        setSurveyHelper(`Survey ${postCourseDate} ${noCert} ${thisSetting}`);
      }
    }

    if (!course.survey && course.certificate) {
      setSurveyHelper("");

      if (course.is_video) {
        setCertHelper(`Certificate ${fourtyEightHoursAfter} ${thisSetting}`);
      } else {
        setCertHelper(`Certificate ${postCourseDate} ${thisSetting}`);
      }
    }

    if (course.survey && course.certificate) {
      if (course.is_video) {
        setSurveyHelper(`Survey ${fourtyEightHoursAfter} ${thisSetting}`);
      } else {
        setSurveyHelper(`Survey ${postCourseDate} ${thisSetting}`);
      }

      setCertHelper(
        `Certificate will be sent to registered students after successfully completing the survey. ${thisSetting}`
      );
    }
  };

  // ------------------------------

  const inputChangeHandler = (e) => {
    const newCourse = lodashCloneDeep(props.course);
    newCourse[e.target.name] = e.target.value;

    props.update(newCourse);
  };

  // ------------------------------

  const matSwitchChangeHandler = (event) => {
    const newCourse = lodashCloneDeep(props.course);
    newCourse[event.target.name] = event.target.checked;
    props.update(newCourse);
  };

  // ------------------------------

  const selectChangeHandler = (key, e, select) => {
    const newCourse = lodashCloneDeep(props.course);

    newCourse[key] = select ? select.value : "";

    if (key === "seats_online" && Number(newCourse.seats_online) === -1) {
      newCourse.email_instruction = "";
    }

    props.update(newCourse);
  };

  // ------------------------------

  const multiSelectChangeHandler = (targetName, e, selects) => {
    const newCourse = lodashCloneDeep(props.course);
    const arrValues = [];

    for (const select of selects) {
      arrValues.push(select.value);
    }

    newCourse[targetName] = arrValues;
    props.update(newCourse);
  };

  // ------------------------------

  const multiProfessorChangeHandler = (e, select) => {
    const newCourse = lodashCloneDeep(props.course);
    const professorValues = [];

    for (const prof of select) {
      const professor = props.professorList.find((p) => p.id === prof.value);
      if (professor) {
        professorValues.push(professor);
      }
    }

    newCourse.professor = professorValues;
    props.update(newCourse);
  };

  // ------------------------------

  const checkChangeHangler = (key, event, selected) => {
    const newCourse = lodashCloneDeep(props.course);
    newCourse[key] = selected;

    if (event.target.name === "is_specialty") {
      newCourse["category"] = []; // clear selected categories
    }

    props.update(newCourse);
  };

  // ------------------------------

  const openEmailHandler = (event) => {
    window.open("mailto:info@zubueducation.com", "_blank");
  };

  // ------------------------------

  const settingChangeHandler = (event, setting) => {
    const newCourse = lodashCloneDeep(props.course);

    newCourse.seats_online = setting === "video" ? -2 : -1;
    newCourse.is_video = setting === "video";

    if (newCourse.is_video) {
      newCourse.seats_classroom = -1;
      newCourse.seats_handson = -1;
      newCourse.start_date_desc = [];
      newCourse.start_date = [];
      newCourse.end_date = [];
      newCourse.timezoneAbbr = "";
      newCourse.location = "";
      newCourse.address = ["", "", "", "", "", ""];
      newCourse.geo_lat = -1;
      newCourse.geo_lng = -1;
      newCourse.geo_hash = "";
      newCourse.email_instruction = "";
      newCourse.multi_pay_option = new MultiPayOption(false, 30, 3);
    }

    props.updateAndValidate(newCourse);

    resolveSurveyCertificateHelpers(newCourse);
  };

  // ------------------------------

  const surveyChangeHandler = (key, e, select) => {
    const newCourse = lodashCloneDeep(props.course);
    const selectedValue = select ? select.value : null;
    const survey = props.surveyList.find((s) => s.id === selectedValue);
    newCourse[key] = survey;
    props.update(newCourse);

    if (survey && survey.text) {
      setShowSurveyPreviewModal(true);
    }

    resolveSurveyCertificateHelpers(newCourse);
  };

  // ------------------------------

  const certChangeHandler = (key, e, select) => {
    const newCourse = lodashCloneDeep(props.course);
    const selectedValue = select ? select.value : null;
    const cert = props.certificate.find((s) => s.id === selectedValue);
    newCourse[key] = cert;
    props.update(newCourse);

    if (cert && cert.id) {
      setShowCertPreviewModal(true);
    }

    resolveSurveyCertificateHelpers(newCourse);
  };

  // ------------------------------

  const handoutChangeHandler = (handout, handoutDelivery) => {
    const newCourse = lodashCloneDeep(props.course);
    newCourse.handout = handout;
    newCourse.handout_delivery = handoutDelivery;
    props.update(newCourse);
  };

  // ------------------------------

  return (
    <form className={styles.form}>
      {showProfModal && (
        <AddProfessorModal
          open={true}
          onClose={(prof) => {
            if (prof) {
              props.onAddProfessor(prof);
            }
            setShowProfModal(false);
          }}
        />
      )}
      {showSurveyPreviewModal && props.course && props.course.survey.text && (
        <SurveyPreviewModal
          open={showSurveyPreviewModal}
          surveyJson={props.course.survey.text}
          onClose={() => {
            setShowSurveyPreviewModal(false);
          }}
        />
      )}
      {showCertPreviewModal && props.course && props.course.certificate && (
        <CertPreviewModal
          open={showCertPreviewModal}
          course={props.course}
          profile={props.profile}
          header={props.course.certificate.header}
          footer={props.course.certificate.footer}
          onClose={() => {
            setShowCertPreviewModal(false);
          }}
        />
      )}
      <div className={styles.sectionTitle}>Course Details</div>
      <InlinePop
        help={
          " If you select Specialists, your course will only appear in the 'Specialist Category' on the ZubU mobile app."
        }
      />
      <MatCheckbox
        name="is_specialty"
        checked={props.course.is_specialty}
        label="Course intended for specialists only?"
        onChange={checkChangeHangler.bind(this, "is_specialty")}
      />
      {!props.course.is_specialty && (
        <Fragment>
          <InlinePop
            help={
              "Select all categories that are relevant to your course.  " +
              "Your course will appear in the respected categories on the ZubU mobile app search page."
            }
          />
          <MatSelectOutline
            noTopBottomMargin
            title="Categories"
            placeholder="Enter Category..."
            name="category"
            id="category"
            onChange={multiSelectChangeHandler.bind(this, "category")}
            options={selectCategories}
            value={buildCategory(props.course.category)}
            getOptionLabel={(option) => option.label}
            getOptionSelected={(opt1, opt2) => opt1.value === opt2.value}
            onBlur={props.validate.bind(this, "category", "required")}
            error={hasError(props.formErrors.category)}
            helperText={props.formErrors.category}
            disabled={!props.isInEdit}
            multiple={true}
            required={true}
          />
        </Fragment>
      )}
      {props.course.is_specialty && (
        <Fragment>
          <InlinePop
            help={
              "Select all specialists that are relevant to your course.  " +
              "Your course will appear in the specialist category on the ZubU mobile app search page."
            }
          />

          <MatSelectOutline
            noTopBottomMargin
            title="Specialist"
            placeholder="Enter specialist..."
            name="category"
            id="category"
            onChange={multiSelectChangeHandler.bind(this, "category")}
            options={selectSpecialist}
            value={buildCategory(props.course.category)}
            getOptionLabel={(option) => option.label}
            getOptionSelected={(opt1, opt2) => opt1.value === opt2.value}
            onBlur={props.validate.bind(this, "category", "required")}
            error={hasError(props.formErrors.category)}
            helperText={props.formErrors.category}
            disabled={!props.isInEdit}
            multiple={true}
            required={true}
          />
        </Fragment>
      )}
      <InlinePop help="Attract more students with an eye catching title!" />
      <MatInputOutline
        notopbottommargin="true"
        title="Course Title"
        name="title"
        rows="2"
        multiline
        value={props.course.title}
        onChange={inputChangeHandler}
        onBlur={props.validate.bind(this, "title", "plainRequired")}
        maxLength={200}
        error={hasError(props.formErrors.title)}
        helpertext={props.formErrors.title}
        readOnly={!props.isInEdit}
        required
      />

      <div
        className={styles.pricingContainer}
        style={{ paddingBottom: "40px" }}
      >
        <ImageSelector
          image={
            props.course.image
              ? props.course.image
              : `url(${resolveCoverPhoto()})`
          }
          aspectRatio={{ aspect: 480 / 270, width: 480, height: 270 }}
          imageWidth="480px"
          imageHeight="270px"
          setImage={(url) => {
            props.saveImage(url);
          }}
          onRemovePic={(image) => {
            props.onDeleteImage(image);
          }}
        />
      </div>

      <InlinePop useTextButton>
        <Fragment>
          <TextButton
            style={{ paddingBottom: 4, paddingRight: 4 }}
            onClick={setShowProfModal.bind(this, true)}
          >
            Add Professor
          </TextButton>
          <Pop
            help={
              "Select the professors/speakers for this course." +
              "  If your professor is not on the list, click Add Professor to create one." +
              "  To add the biography you must do so from the professor section available from the main menu." +
              "  Make sure to update the biography prior to activating the course otherwise it will not be visible on the ZubU mobile app."
            }
          />
        </Fragment>
      </InlinePop>
      <MatSelectOutline
        noTopBottomMargin
        title="Professor"
        placeholder="Enter professor name..."
        name="professor"
        id="professor"
        onChange={multiProfessorChangeHandler}
        options={buildKeyValueSelectProf(props.professorList, "id")} // comming from store saved professors
        value={buildKeyValueSelectProf(props.course.professor, "id")} // [{id: xxx, name: Dr. X X}]
        getOptionLabel={(option) => option.label}
        getOptionSelected={(opt1, opt2) => opt1.value === opt2.value}
        error={hasError(props.formErrors.professor)}
        helperText={props.formErrors.professor}
        disabled={!props.isInEdit}
        multiple={true}
        required={false}
      />
      <InlinePop
        help={
          <Fragment>
            Select all accreditations that pertain to this course. If you do not
            see your accreditation on this list, please
            <TextButton
              onClick={openEmailHandler}
              style={{ fontSize: "16px", paddingLeft: "4px" }}
            >
              contact us.
            </TextButton>
          </Fragment>
        }
      />

      <MatSelectOutline
        noTopBottomMargin
        title="Accreditation"
        placeholder="Enter Accreditation..."
        name="accreditation"
        id="accreditation"
        onChange={multiSelectChangeHandler.bind(this, "accreditation")}
        options={selectAccreditation}
        value={buildCategory(props.course.accreditation)}
        getOptionLabel={(option) => option.label}
        getOptionSelected={(opt1, opt2) => opt1.value === opt2.value}
        onBlur={props.validate.bind(this, "accreditation", "required")}
        error={hasError(props.formErrors.accreditation)}
        helperText={props.formErrors.accreditation}
        disabled={!props.isInEdit}
        multiple={true}
        required={true}
      />

      <InlinePop
        help={
          <Fragment>
            <span className={styles.spanParagraph}>
              Optional field used for course classification.  If a value is entered, it  will appear on the course listing and the students certificate of completion.
            </span>
          </Fragment>
        }
      />

      <MatInputOutline
        notopbottommargin="true"
        title="Subject Code"
        name="topic_number"
        placeholder="Enter Subject Code..."
        rows="1"
        value={props.course.topic_number}
        onChange={inputChangeHandler}
        maxLength={100}
        readOnly={!props.isInEdit}
        required={false}
      />

      <MatSwitch
        help={"Is your course university associated? "}
        title="University Associated"
        id={"is_university_associated"}
        name={"is_university_associated"}
        checked={props.course.is_university_associated}
        onChange={matSwitchChangeHandler}
      />
      <InlinePop help="Select the language in which your course will be given." />
      <MatSelectOutline
        noTopBottomMargin
        title="Language"
        placeholder="Enter Language..."
        name="language"
        id="language"
        onChange={selectChangeHandler.bind(this, "language")}
        options={selectLang}
        value={buildSingleSelect(props.course.language)}
        getOptionLabel={(option) => option.label}
        getOptionSelected={(opt1, opt2) => opt1.value === opt2.value}
        onBlur={props.validate.bind(this, "language", "required")}
        error={hasError(props.formErrors.language)}
        helperText={props.formErrors.language}
        disabled={!props.isInEdit}
        multiple={false}
        required={true}
      />

      <InlinePop help="Write a detailed description of your course.  Be sure to include all important information such as detailed itinerairies etc." />
      <MatInputOutline
        notopmargin="true"
        title="Description"
        name="description"
        rows="18"
        multiline
        value={props.course.description}
        onChange={inputChangeHandler}
        onBlur={props.validate.bind(this, "description", "plainRequired")}
        maxLength={1000}
        error={hasError(props.formErrors.description)}
        helpertext={props.formErrors.description}
        readOnly={!props.isInEdit}
        required
      />
      <MatInputOutline
        nobottommargin="true"
        title="Objectives"
        name="objectives"
        rows="18"
        multiline
        value={props.course.objectives}
        onChange={inputChangeHandler}
        onBlur={props.validate.bind(this, "objectives", "plainRequired")}
        maxLength={1000}
        error={hasError(props.formErrors.objectives)}
        helpertext={props.formErrors.objectives}
        readOnly={!props.isInEdit}
        required
      />
      <InlinePop help="Ex: bring loupes, lunch included etc." />
      <MatInputOutline
        notopbottommargin="true"
        title="Notes"
        name="notes"
        rows="4"
        multiline
        value={props.course.notes}
        onChange={inputChangeHandler}
        maxLength={400}
        error={hasError(props.formErrors.notes)}
        helpertext={props.formErrors.notes}
        readOnly={!props.isInEdit}
      />
      <div className={styles.sectionTitle}>Course Content Delivery</div>

      <RadioGroup
        onChange={settingChangeHandler}
        isVideo={props.course.is_video}
      />

      <Collapse in={props.course.is_video}>
        <Fragment>
          <InlinePop help="Select the limit of participants who may register for your course." />
          <MatSelectOutline
            noTopMargin
            title="Recorded Video Registrations Available"
            placeholder="Enter number..."
            name="seats_online"
            id="seats_online"
            onChange={selectChangeHandler.bind(this, "seats_online")}
            options={selectSeatsVideo}
            value={buildSingleSelect(props.course.seats_online)}
            getOptionLabel={(option) => option.label}
            getOptionSelected={(opt1, opt2) => opt1.value === opt2.value}
            onBlur={props.validate.bind(this, "seats_online", "required")}
            error={hasError(props.formErrors.seats_online)}
            helperText={props.formErrors.seats_online}
            disabled={!props.isInEdit}
            multiple={false}
            required={true}
          />

          <InlinePop
            privateIcon={<VisibilityOffIcon fontSize="inherit" />}
            help={
              <Fragment>
                <span className={styles.spanParagraph}>
                  How will students access your academic content?
                </span>
                <span className={styles.spanParagraph}>
                  Any information entered here is private and will only be sent
                  to registered students. &nbsp;
                  <VisibilityOffIcon fontSize="inherit" />
                </span>
                <span className={styles.spanParagraph}>Example:</span>
                <span className={styles.spanParagraph}>
                  Video access http://www.url.com
                  <br />
                  User Name: ZubU
                  <br />
                  Access Code: Beta123
                </span>
              </Fragment>
            }
          />
          <MatInputOutline
            notopbottommargin="true"
            title="Video Access Instructions"
            name="email_instruction"
            placeholder="Url link to video, passcode etc."
            rows="9"
            multiline
            value={props.course.email_instruction}
            onChange={inputChangeHandler}
            maxLength={1000}
            error={hasError(props.formErrors.email_instruction)}
            helpertext={props.formErrors.email_instruction}
            readOnly={!props.isInEdit}
            required={false}
          />

          {/**  <VideoChapterItemList
            videoChapters={props.course.video_chapters}
            onNewEditVideoChapter={props.onNewEditVideoChapter}
            onUpdateVideoChapterOrder={props.onUpdateVideoChapterOrder}
          /> */}
        </Fragment>
      </Collapse>

      <Collapse in={!props.course.is_video}>
        <Fragment>
          <InlinePop help="Select the limit of participants who may register for your course in each capacity." />
          <MatSelectOutline
            noTopMargin
            title="Classroom Lecutre Seats Available"
            placeholder="Enter number..."
            name="seats_classroom"
            id="seats_classroom"
            onChange={selectChangeHandler.bind(this, "seats_classroom")}
            options={selectSeatsClassroom}
            value={buildSingleSelect(props.course.seats_classroom)}
            getOptionLabel={(option) => option.label}
            getOptionSelected={(opt1, opt2) => opt1.value === opt2.value}
            onBlur={props.validate.bind(this, "seats_classroom", "required")}
            error={hasError(props.formErrors.seats_classroom)}
            helperText={props.formErrors.seats_classroom}
            disabled={!props.isInEdit}
            multiple={false}
            required={true}
          />

          <InlinePop help="Select the limit of participants who may register for your course in each capacity." />
          <MatSelectOutline
            noTopMargin
            title="Classroom Hands-on Seats Available"
            placeholder="Enter number..."
            name="seats_handson"
            id="seats_handson"
            onChange={selectChangeHandler.bind(this, "seats_handson")}
            options={selectSeatsClassroom}
            value={buildSingleSelect(props.course.seats_handson)}
            getOptionLabel={(option) => option.label}
            getOptionSelected={(opt1, opt2) => opt1.value === opt2.value}
            onBlur={props.validate.bind(this, "seats_handson", "required")}
            error={hasError(props.formErrors.seats_handson)}
            helperText={props.formErrors.seats_handson}
            disabled={!props.isInEdit}
            multiple={false}
            required={true}
          />
          <InlinePop help="Select the limit of participants who may register for your course in each capacity." />
          <MatSelectOutline
            noTopMargin
            title="Livestream Seats Available"
            placeholder="Enter number..."
            name="seats_online_live"
            id="seats_online_live"
            onChange={selectChangeHandler.bind(this, "seats_online")}
            options={selectSeatsOnline}
            value={buildSingleSelect(props.course.seats_online)}
            getOptionLabel={(option) => option.label}
            getOptionSelected={(opt1, opt2) => opt1.value === opt2.value}
            onBlur={props.validate.bind(this, "seats_online", "required")}
            error={hasError(props.formErrors.seats_online)}
            helperText={props.formErrors.seats_online}
            disabled={!props.isInEdit}
            multiple={false}
            required={true}
          />

          <Collapse in={Number(props.course.seats_online) !== -1}>
            <Fragment>
              <InlinePop
                privateIcon={<VisibilityOffIcon fontSize="inherit" />}
                help={
                  <Fragment>
                    <span className={styles.spanParagraph}>
                      How will students access your academic content?
                    </span>
                    <span className={styles.spanParagraph}>
                      Any information entered here is private and will only be
                      sent to livestream registered students. &nbsp;
                      <VisibilityOffIcon fontSize="inherit" />
                    </span>
                    <span className={styles.spanParagraph}>
                      If you are using the Zoom integration we will
                      automatically generate Zoom meeting links for you and
                      therefore leave this field blank or add any other
                      important instruction for registered students.
                    </span>

                    <span
                      className={styles.spanParagraph}
                      style={{ fontStyle: "italic" }}
                    >
                      Example:
                    </span>
                    <span
                      className={styles.spanParagraph}
                      style={{ fontStyle: "italic" }}
                    >
                      Thank you for registering for this livestream course here
                      is follow this link to join the course:
                    </span>
                    <span
                      className={styles.spanParagraph}
                      style={{ fontStyle: "italic" }}
                    >
                      Zoom Meeting http://www.url.com
                      <br />
                      Meeting ID: Beta9999
                      <br />
                      Passcode: 123
                    </span>
                  </Fragment>
                }
              />
              <MatInputOutline
                notopmargin="true"
                title="Additional Livestream Instructions"
                name="email_instruction"
                placeholder="Url link to zoom, teams, meeting id, passcode etc."
                rows="6"
                multiline
                value={props.course.email_instruction}
                onChange={inputChangeHandler}
                maxLength={1000}
                error={hasError(props.formErrors.email_instruction)}
                helpertext={props.formErrors.email_instruction}
                readOnly={!props.isInEdit}
                required={false}
              />
            </Fragment>
          </Collapse>

          <div className={styles.sectionTitle}>Course Schedule</div>

          <InlinePop
            help="Add a start date, start time and end time for your
                course. If you are offering a multi-day course, you may add
                additional entries. Detailed itineraries of each day should be
                documented in either course description."
          />
          {props.course.is_video === false && (
            <MultiDateSelect
              courseDates={buildCourseDates(
                props.course.start_date_desc,
                props.course.start_date,
                props.course.end_date,
                props.course.timezoneAbbr
              )}
              disabled={!props.isInEdit}
              onDateChange={async (dates) => {
                let dateStarDescArr = [];
                let dateStarArr = [];
                let dateEndArr = [];
                dates.forEach((datePair) => {
                  dateStarDescArr.push(datePair.desc);
                  dateStarArr.push(new Date(datePair.from));
                  dateEndArr.push(new Date(datePair.to));
                });

                const newCourse = lodashCloneDeep(props.course);
                newCourse.start_date_desc = [...dateStarDescArr];
                newCourse.start_date = [...dateStarArr];
                newCourse.end_date = [...dateEndArr];
                newCourse.timezoneAbbr = dates[0] ? dates[0].timezoneAbbr : "";
                await props.update(newCourse);
              }}
            />
          )}
          <div className={styles.sectionTitle}>Address Details</div>
          <InlinePop help="Address is only required if your course has a classroom setting.  If your course is only offerred online then you may leave address empty." />
          <MatInputOutline
            notopbottommargin="true"
            title="Location"
            name="location"
            value={props.course.location}
            onChange={inputChangeHandler}
            maxLength={100}
            error={hasError(props.formErrors.location)}
            helpertext={props.formErrors.location}
            readOnly={!props.isInEdit}
            required={false}
          />
          <AddressInput
            value={props.course.address}
            suite={props.course.suite}
            readOnly={!props.isInEdit}
            onSelect={(selectAddress, geo) => {
              const newCourse = lodashCloneDeep(props.course);
              newCourse.address = selectAddress;
              newCourse.geo_lat = geo.lat;
              newCourse.geo_lng = geo.lng;
              newCourse.geo_hash = geo.hash;
              props.update(newCourse);
            }}
            onSuiteChange={(suite) => {
              const newCourse = lodashCloneDeep(props.course);
              newCourse.suite = suite;
              props.update(newCourse);
            }}
          />
        </Fragment>
      </Collapse>

      <div className={styles.sectionTitle}>
        <YouTubeIcon />
        &nbsp;&nbsp;YouTube
      </div>

      <InlinePop
        help={
          <Fragment>
            <span className={styles.spanParagraph}>
              Add a promo video on your course listing. This will help
              registrants get a better feel and understanding of the course
              content and speaker. Promo videos can only be a YouTube link.
              Example: https://youtu.be/d2adE7HhdFs
            </span>
          </Fragment>
        }
      />

      <MatInputOutline
        notopbottommargin="true"
        title="YouTube Promo Video"
        name="youtube_uri"
        placeholder="https://youtu.be/d2adE7HhdFs"
        rows="1"
        value={props.course.youtube_uri}
        onChange={inputChangeHandler}
        maxLength={100}
        error={hasError(props.formErrors.youtube_uri)}
        helpertext={props.formErrors.youtube_uri}
        readOnly={!props.isInEdit}
        required={false}
      />

      <Collapse in={validYouTube(props.course.youtube_uri)}>
        <div className={styles.pricingContainer}>
          <ReactPlayer
            url={props.course.youtube_uri + "?rel=0"}
            controls={true}
            muted={true}
            config={{ file: { attributes: { controlsList: "nodownload" } } }}
            onContextMenu={(e) => e.preventDefault()}
          />
        </div>
      </Collapse>

      <div className={styles.sectionTitle}>
        <img style={{ height: "50px" }} src={handoutPic} alt="" />
        &nbsp;&nbsp;Handouts
      </div>

      <InlinePop
        privateIcon={<VisibilityOffIcon fontSize="inherit" />}
        help={
          <Fragment>
            <span className={styles.spanParagraph}>
              Share course handouts with your students.
            </span>
            <span className={styles.spanParagraph}>
              Documents saved here are private and shared with registered
              students only.
            </span>
          </Fragment>
        }
      />

      <Handout
        handout={props.course.handout}
        handoutDelivery={props.course.handout_delivery}
        onChange={handoutChangeHandler}
      />

      <div className={styles.sectionTitle}>
        <img style={{ height: "50px" }} src={survey} alt="" />
        &nbsp;&nbsp;Survey
      </div>

      <InlinePop useTextButton>
        <Fragment>
          <TextButton
            style={{ paddingBottom: 4, paddingRight: 4 }}
            onClick={props.onAddSurvey}
          >
            Add Survey
          </TextButton>
          <Pop
            help={
              <Fragment>
                <span className={styles.spanParagraph}>
                  Surveys are a great way to assess a student's understanding
                  and satisfaction on the content of your course. Use our survey
                  builder from the main menu to create a survey and attach it to
                  your course.
                </span>
                <span className={styles.spanParagraph}>
                  Surveys are sent automatically after the course end date or 48
                  hours after a recorded video course registration. &nbsp;
                </span>
                <span className={styles.spanParagraph}>
                  Surveys can be used as a student prerequisite to obtain a
                  certificate of completion. To enable this go to{" "}
                  <b>
                    Settings <ArrowForwardIcon fontSize="inherit" /> Certificate{" "}
                    <ArrowForwardIcon fontSize="inherit" /> Delivery Options
                  </b>
                </span>
              </Fragment>
            }
          />
        </Fragment>
      </InlinePop>

      <MatSelectOutline
        noTopBottomMargin
        title="Survey"
        placeholder="Enter Survey Title..."
        name="survey"
        id="survey"
        onChange={surveyChangeHandler.bind(this, "survey")}
        options={buildKeyValueSelect(props.surveyList, "id", "title")}
        value={
          props.course.survey
            ? {
              value: props.course.survey.id,
              label: props.course.survey.title,
            }
            : null
        }
        getOptionLabel={(option) => option.label}
        getOptionSelected={(opt1, opt2) => opt1.value === opt2.value}
        disabled={!props.isInEdit}
        multiple={false}
        required={false}
      />

      <Collapse
        in={props.course.survey && props.course.survey.text ? true : false}
      >
        <div className={styles.pricingContainer}>
          <div className={styles.rowIt} style={{ color: "green" }}>
            <div style={{ paddingRight: "5px" }}>
              <EmojiFlagsIcon />
            </div>
            <div>{surveyHelper}</div>
          </div>
        </div>
      </Collapse>

      <div className={styles.sectionTitle}>Certificate of Completion</div>

      <InlinePop useTextButton>
        <Fragment>
          <TextButton
            style={{ paddingBottom: 4, paddingRight: 4 }}
            onClick={props.onAddCertificate}
          >
            Add Certificate
          </TextButton>
          <Pop
            help={
              <Fragment>
                <span className={styles.spanParagraph}>
                  Certificates are sent automatically after the completion of
                  the course survey. If no survey is selected they are sent
                  after the course end date or 48 hours after a recorded video
                  course registration.
                </span>
                <span className={styles.spanParagraph}>
                  No certificates in your list? Click <b>Certificate</b> from
                  the main menu. Create a template and attach it to your course.
                </span>
              </Fragment>
            }
          />
        </Fragment>
      </InlinePop>

      <MatSelectOutline
        noTopBottomMargin
        title="Certificate"
        placeholder="Enter Certificate Title..."
        name="certificate"
        id="certificate"
        onChange={certChangeHandler.bind(this, "certificate")}
        options={buildKeyValueSelect(props.certificate, "id", "title")}
        value={
          props.course.certificate
            ? {
              value: props.course.certificate.id,
              label: props.course.certificate.title,
            }
            : null
        }
        getOptionLabel={(option) => option.label}
        getOptionSelected={(opt1, opt2) => opt1.value === opt2.value}
        disabled={!props.isInEdit}
        multiple={false}
        required={false}
      />

      <Collapse
        in={
          props.course.certificate && props.course.certificate.id ? true : false
        }
      >
        <div className={styles.pricingContainer}>
          <div className={styles.rowIt} style={{ color: "green" }}>
            <div style={{ paddingRight: "5px" }}>
              <EmojiFlagsIcon />
            </div>
            <div>{certHelper}</div>
          </div>
        </div>
      </Collapse>
    </form>
  );
};
export default CourseEditForm;
