import { fromNow } from "../globals/Dates2";

class Review {
  constructor(
    id,
    provider_id,
    course_title,
    user_id,
    first,
    pic,
    rating,
    comment,
    date,
    reply,
    reply_date
  ) {
    this.id = id;
    this.provider_id = provider_id;
    this.course_title = course_title;
    this.user_id = user_id;
    this.first = first;
    this.pic = pic;
    this.rating = rating;
    this.comment = comment;
    this.date = date;
    this.reply = reply;
    this.reply_date = reply_date;
  }

  renderDate = () => {
    return fromNow(this.date);
  };

  renderReplyDate = () => {
    if (this.reply_date) {
      return fromNow(this.reply_date);
    }

    return null;
  };
}

export default Review;
