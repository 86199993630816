class Professor {
  constructor(id, prefix, first, name, occupation, bio, picture) {
    this.id = id;
    this.prefix = prefix ? prefix : "";
    this.first = first ? first : "";
    this.name = name ? name : "";
    this.occupation = occupation ? occupation : "";
    this.bio = bio ? bio : "";
    this.picture = picture ? picture : "";
  }

  fullName = () => {
    const fullName = this.prefix + " " + this.first + " " + this.name;
    return fullName.trim();
  };
}

export default Professor;
