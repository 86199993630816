import { momentShortDate } from "../globals/Dates2";

class MobileOrder {
  constructor(
    id,
    orderRefNumber,
    date,
    chargeAmount,
    totalAmount,
    couponValue,
    items,
    installmentStatusArray
  ) {
    this.id = id;
    this.orderRefNumber = orderRefNumber;
    this.date = date;
    this.chargeAmount = chargeAmount;
    this.totalAmount = totalAmount;
    this.couponValue = couponValue;
    this.items = items;
    this.installmentStatusArray = installmentStatusArray;
  }

  renderDate = () => {
    return momentShortDate(this.date);
  };
}

export default MobileOrder;
