import React, { Fragment, useState } from "react";
import { useTranslation } from "react-i18next";
import styles from "./ProfessorColapseItem.module.css";
import Image from "../../ui/image/Image";
import TextButton from "../../../components/ui/button/TextButton";

const ProfessorColapseItem = (props) => {
  const { t } = useTranslation();
  const [viewBio, setViewBio] = useState(false);

  return (
    <Fragment>
      <div className={styles.phoneContentRow}>
        <div
          className={
            props.isAmendment
              ? styles.phoneContentTextAmend
              : styles.phoneContentText
          }
        >
          {props.professor.fullName()}&nbsp;
          <TextButton
            onClick={(e) => {
              e.preventDefault();
              setViewBio(!viewBio);
            }}
          >
            {viewBio === true ? (
              <div>{t("course_hide_bio")}</div>
            ) : (
              <div>{t("course_view_bio")}</div>
            )}
          </TextButton>
        </div>
      </div>

      {viewBio === true && (
        <div className={styles.phoneContentRow}>
          <div className={styles.phoneContentText}>
            <Image image={props.professor.picture} />
            <div style={{ paddingTop: 10 }}>{props.professor.bio}</div>
          </div>
        </div>
      )}

      {viewBio === true && !props.noHr && <hr />}
    </Fragment>
  );
};

export default ProfessorColapseItem;
