import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Button from "@material-ui/core/Button";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  backButton: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  buttonContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    paddingTop: "10px",
    paddingLeft: "15px",
    paddingRight: "15px",
  },
}));

const MatStepper = (props) => {
  const classes = useStyles();

  const handleNext = () => {
    props.onStep(true);
  };

  const handleBack = () => {
    if (props.activeStep === 0) {
      props.onExit();
    } else {
      props.onStep(false);
    }
  };

  const handleReset = () => {
    props.onReset(false);
  };

  const resolveNextText = () => {
    if (props.activeStep === props.steps.length - 2) {
      return "Next";
    } else {
      return props.touched ? "Save & Next" : "Next";
    }
  };

  return (
    <div className={classes.root}>
      <Stepper
        activeStep={props.activeStep}
        alternativeLabel
        style={{ backgroundColor: "transparent" }}
      >
        {props.steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <div>
        {props.activeStep === props.steps.length ? (
          <div className={classes.buttonContainer}>
            <Button variant="contained" onClick={handleReset}>
              Reset
            </Button>
          </div>
        ) : (
          <div className={classes.buttonContainer}>
            <Button
              variant="contained"
              onClick={handleBack}
              className={classes.backButton}
            >
              Back
            </Button>
            {props.activeStep === 0 && !props.hideSave && (
              <Button
                disabled={props.blocked}
                variant="contained"
                onClick={props.onSave}
                className={classes.backButton}
              >
                Save
              </Button>
            )}
            {props.activeStep === 3 && (
              <Button
                variant="contained"
                onClick={props.onExit}
                className={classes.backButton}
              >
                Activate Later
              </Button>
            )}
            <Button
              variant="contained"
              color="primary"
              onClick={handleNext}
              disabled={props.blocked}
              endIcon={<NavigateNextIcon />}
            >
              {props.activeStep === props.steps.length - 1
                ? "Activate"
                : resolveNextText()}
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

export default MatStepper;
