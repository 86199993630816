import moment from "moment";
import "moment/locale/fr";
import i18n from "../i18n";

// ------------------------------

// moment.js
export const M_DATE_FORMAT_TINY = i18n.t("M_DATE_FORMAT_TINY");
export const M_DATE_FORMAT = i18n.t("M_DATE_FORMAT");
export const M_TIME_FORMAT = i18n.t("M_TIME_FORMAT");
export const M_LONG_DATE_FORMAT = i18n.t("M_LONG_DATE_FORMAT");
export const M_SHORT_DATE_FORMAT = i18n.t("M_SHORT_DATE_FORMAT");

// fns
export const FNS_DATE_FORMAT = i18n.t("FNS_DATE_FORMAT");
export const FNS_LONG_DATE_FORMAT = i18n.t("FNS_LONG_DATE_FORMAT");
export const FNS_TIME_FORMAT = i18n.t("FNS_TIME_FORMAT");

const SEPERATOR = " : ";

export const FNS_LONG_DATE_TIME_FORMAT =
  FNS_LONG_DATE_FORMAT + SEPERATOR + FNS_TIME_FORMAT;

export const M_LONG_DATE_TIME_FORMAT =
  M_SHORT_DATE_FORMAT + SEPERATOR + M_TIME_FORMAT;

export const M_SHORT_DATE_TIME_FORMAT =
  M_SHORT_DATE_FORMAT + ", " + M_TIME_FORMAT;

// ------------------------------

export const getDuration = (seconds) => {
  const formatted = moment.utc(seconds * 1000).format('HH:mm:ss');
  return formatted;
};


export const newLocalDate = (date) => {
  return date ? new Date(date) : new Date();
};

// ------------------------------

export const resolveDateByIncrement = (date, alertSetting) => {
  switch (alertSetting) {
    case "in 1 minute":
      return incrementMinutesOfDate(date, 1);
    case "in 15 minutes":
      return incrementMinutesOfDate(date, 15);
    case "in 30 minutes":
      return incrementMinutesOfDate(date, 30);
    case "in 1 hour":
      return incrementHourOfDate(date, 1);
    case "in 1 day":
      return incrementDayOfDate(date, 1);
    case "in 3 days":
      return incrementDayOfDate(date, 3);
    case "in 1 week":
      return incrementDayOfDate(date, 7);
    case "in 1 month":
      return incrementMonthOfDate(date, 1);
    case "1 minute before":
      return incrementMinutesOfDate(date, -1);
    case "15 minutes before":
      return incrementMinutesOfDate(date, -15);
    case "30 minutes before":
      return incrementMinutesOfDate(date, -30);
    case "1 hour before":
      return incrementHourOfDate(date, -1);
    case "1 day before":
      return incrementDayOfDate(date, -1);
    case "3 days before":
      return incrementDayOfDate(date, -3);
    case "1 week before":
      return incrementDayOfDate(date, -7);
    case "1 month before":
      return incrementMonthOfDate(date, -1);
    default:
      return null;
  }
};


// ------------------------------

export const incrementMinutesOfDate = (date, minutes) => {
  const newDate = new Date(date);
  newDate.setMinutes(newDate.getMinutes() + minutes);
  return newDate;
};

// ------------------------------

export const incrementHourOfDate = (date, hours) => {
  const newDate = new Date(date);
  newDate.setHours(newDate.getHours() + hours);
  return newDate;
};

// ------------------------------

export const incrementDayOfDate = (date, days) => {
  const newDate = new Date(date);
  newDate.setDate(newDate.getDate() + days);
  return newDate;
};

// ------------------------------

export const incrementMonthOfDate = (date, months) => {
  const newDate = new Date(date);
  newDate.setMonth(newDate.getMonth() + months);
  return newDate;
};

// ------------------------------

export const incrementYearOfDate = (date, years) => {
  const newDate = new Date(date);
  newDate.setFullYear(newDate.getFullYear() + years);
  return newDate;
};

// ------------------------------

export const addDays = (date, days) => {
  const newDate = new Date(date);
  newDate.setMinutes(0);
  newDate.setSeconds(0);
  newDate.setDate(newDate.getDate() + days);
  return newDate;
};

// ------------------------------

export const isEqualDate = (date1, date2) => {
  return (
    date1.getDay() === date2.getDay() &&
    date1.getMonth() === date2.getMonth() &&
    date1.getYear() === date2.getYear()
  );
};

// ------------------------------

export const monthDifference = (date1, date2) => {
  const momentStartDate = moment(date1);
  const momentEndDate = moment(date2);
  return momentEndDate.diff(momentStartDate, "months");
};

// ------------------------------

export const isPastDate = (date, numberOfDays) => {
  const pastDate = new Date(date);
  pastDate.setDate(pastDate.getDate() + numberOfDays);
  const today = new Date();
  return pastDate.getTime() < today.getTime();
};

// ------------------------------

export const fromNow = (date) => {
  return moment(date).locale(i18n.language).fromNow();
};

// ------------------------------

export const momentTinyDate = (date) => {
  return moment(date)
    .locale(i18n.language)
    .format(i18n.t("M_DATE_FORMAT_TINY"));
};

export const momentLongDate = (date) => {
  return moment(date)
    .locale(i18n.language)
    .format(i18n.t("M_LONG_DATE_FORMAT"));
};

export const momentLongDateTime = (date) => {
  return moment(date)
    .locale(i18n.language)
    .format(
      i18n.t("M_SHORT_DATE_FORMAT") + SEPERATOR + i18n.t("M_TIME_FORMAT")
    );
};

export const momentShortDateTime = (date) => {
  return moment(date).locale(i18n.language).format(M_SHORT_DATE_TIME_FORMAT);
};

export const momentShortDate = (date) => {
  return moment(date)
    .locale(i18n.language)
    .format(i18n.t("M_SHORT_DATE_FORMAT"));
};

export const momentNumericDate = (date) => {
  return moment(date).locale(i18n.language).format(i18n.t("M_DATE_FORMAT"));
};

// ------------------------------

export const momentTime = (date) => {
  return moment(date).locale(i18n.language).format(i18n.t("M_TIME_FORMAT"));
};

// ------------------------------

export const remainingDaysTime = (date) => {
  if (date) {
    return moment(new Date(date)).locale(i18n.language).from(new Date());
  }

  return "";
};

// ------------------------------

export const getDateRange = (startDate, days) => {
  const startDateCopy = new Date(startDate);
  startDateCopy.setDate(1);
  startDateCopy.setHours(0, 0, 0);

  const endDate = addDays(startDateCopy, days);
  endDate.setDate(0);
  endDate.setHours(23, 59, 59);

  return { startDate: startDateCopy, endDate: endDate };
};

// ------------------------------

export const shortJsDate = (date, i18n) => {
  var options = {
    year: "numeric",
    month: "long",
    day: "numeric",
  };

  return date.toLocaleDateString(i18n.language, options);
};

// ------------------------------

export const sortArrayOfDates = (dateArray, ascending) => {
  let dateForNonDate = ascending
    ? incrementYearOfDate(new Date(), +10)
    : incrementYearOfDate(new Date(), -10);

  const sortedArray = dateArray.sort((a, b) => {
    let dateA = a.start_date[0] ? a.start_date[0] : dateForNonDate;
    let dateB = b.start_date[0] ? b.start_date[0] : dateForNonDate;

    if (ascending) {
      return dateA.getTime() - dateB.getTime();
    } else {
      return dateB.getTime() - dateA.getTime();
    }
  });

  return sortedArray;
};

// ------------------------------
