import React, { useState, useEffect } from "react";
import {
  CarouselProvider,
  Slider,
  Slide,
  ButtonBack,
  ButtonNext,
  Dot,
} from "pure-react-carousel";
import "pure-react-carousel/dist/react-carousel.es.css";
import styles from "./AddBuilder.module.css";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import VisibilityIcon from "@material-ui/icons/Visibility";
import InfoIcon from "@material-ui/icons/Info";
import MatSwitch from "../../../components/ui/switchInput/MatSwitch";
import FormButton from "../../../components/ui/button/FormButton";
import MatInputOutline from "../../../components/ui/input/MatInputOutline";
import { imageSelector } from "../../ui/addImageSelector/AddImageSelector";

// ----------

const TOTAL_SLIDES = 48;

const arraySlides = [];
for (let i = 0; i < TOTAL_SLIDES; i++) {
  arraySlides.push(i);
}

// ----------

const AddBuilder = (props) => {
  const [slide, setSlide] = useState(0);
  const [headline, setHeadline] = useState("");
  const [callForAction, setCallForAction] = useState("");
  const [showLogo, setShowLogo] = useState(false);

  // ----------

  useEffect(() => {
    props.onChange({
      courseTitle: props.courseTitle,
      slide: slide,
      headline: headline,
      callForAction: callForAction,
      showLogo: showLogo,
      logoUri: props.logoUri,
    });
    //eslint-disable-next-line
  }, [slide, headline, callForAction, showLogo]);

  return (
    <div className={styles.container}>
      <div className={styles.row}>
        <div>
          <InfoIcon />
        </div>
        <div style={{ paddingLeft: "15px" }}>
          <div>1. Select an image as your advertisement background.</div>
          <div>2. Enter a catchy headline.</div>
          <div>3. Enter your call for action.</div>
          <div>4. Add your company logo.</div>
          <div>
            5. Preview the result as it will be seen on the ZubU mobile app.
          </div>
        </div>
      </div>

      <div className={styles.carouselContainer}>
        <CarouselProvider
          infinite={true}
          naturalSlideWidth={100}
          naturalSlideHeight={125}
          totalSlides={TOTAL_SLIDES}
          dragEnabled={false}
        >
          <div className={styles.sliderButtonContainer}>
            <ButtonBack
              className={styles.slideButton}
              onClick={() => {
                if (slide === 0) {
                  setSlide(TOTAL_SLIDES);
                } else {
                  setSlide(slide - 1);
                }
              }}
            >
              <ArrowBackIosIcon />
            </ButtonBack>
            <ButtonNext
              className={styles.slideButton}
              onClick={() => {
                if (slide === TOTAL_SLIDES) {
                  setSlide(0);
                } else {
                  setSlide(slide + 1);
                }
              }}
            >
              <ArrowForwardIosIcon />
            </ButtonNext>
          </div>

          <Slider style={{ height: "325px" }}>
            {arraySlides.map((i, index) => (
              <Slide index={index} key={index}>
                <img
                  src={imageSelector(index)}
                  alt=""
                  className={styles.sliderImage}
                />
              </Slide>
            ))}
          </Slider>

          <div className={styles.thumbnailContainer}>
            {arraySlides.map((i, index) => (
              <Dot
                key={index}
                slide={index}
                className={styles.thumbnailButton}
                onClick={() => {
                  setSlide(index);
                }}
              >
                <img
                  src={imageSelector(index)}
                  alt=""
                  className={styles.thumbnail}
                />
              </Dot>
            ))}
          </div>

          <div className={styles.headlineContainer}>
            <MatInputOutline
              nomargin="true"
              title="Enter your headline"
              name="headline"
              id="headline"
              value={headline}
              onChange={(e) => {
                setHeadline(e.target.value);
              }}
              maxLength={30}
              helpertext={
                "Maximum of 30 characters (" +
                (30 - headline.length) +
                " remaining)"
              }
              required
            />

            <div style={{ height: "10px" }}>&nbsp;</div>

            <MatInputOutline
              nomargin="true"
              title="Enter a call for action"
              name="callForAction"
              id="callForAction"
              value={callForAction}
              onChange={(e) => {
                setCallForAction(e.target.value);
              }}
              maxLength={40}
              helpertext={
                "Maximum of 40 characters (" +
                (40 - callForAction.length) +
                " remaining).  Ex: Early bird special for orthodontics lecture"
              }
              required
            />

            <div className={styles.switchInputContainer}>
              <MatSwitch
                alwaysfull="true"
                title="Display your profile logo"
                id="showLogo"
                name="showLogo"
                checked={showLogo}
                onChange={() => {
                  setShowLogo(!showLogo);
                }}
              />
            </div>

            <FormButton
              alwaysfull="true"
              label="Preview Add"
              onClick={props.onPreview}
              startIcon={<VisibilityIcon />}
              disabled={
                headline.length === 0 ||
                callForAction.length === 0 ||
                !props.courseTitle ||
                props.courseTitle.length === 0
              }
            />
          </div>
        </CarouselProvider>
      </div>
    </div>
  );
};

export default AddBuilder;
