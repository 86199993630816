class Provider {
  constructor(
    id,
    name,
    logoCached,
    logo,
    country,
    currency,
    contact_email,
    date,
    notify,
    code,
    about,
    storefront,
    about_en,
    about_fr
  ) {
    this.id = id;
    this.name = name;
    this.logoCached = logoCached;
    this.logo = logo;
    this.country = country;
    this.currency = currency;
    this.contact_email = contact_email;
    this.date = date ? date : new Date();
    this.notify = notify ? notify : false;
    this.code = code ? code : "";
    this.about = about ? about : "";
    this.storefront = storefront ? storefront : "";
    this.about_en = about_en ? about_en : "";
    this.about_fr = about_fr ? about_fr : "";
  }
}

export default Provider;
