import React from "react";
import styles from "./WelcomeScreen.module.css";
import Button from "@material-ui/core/Button";
import MatAppBar from "../../components/ui/appBar/MatAppBar";
import LogoMenuBar from "../../components/ui/logo/LogoMenuBar";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTranslation } from "react-i18next";
import CurrencyLabel from "../../components/ui/currencyLabel/CurrencyLabel";
import IconButton from "@material-ui/core/IconButton";
import ShoppingCartOutlinedIcon from "@material-ui/icons/ShoppingCartOutlined";
import AlgoliaSearch from "../../components/ui/algoliaAutocomplete/AlgoliaSearch";

const DefaultToolbar = (props) => {
  const { t } = useTranslation();
  const matches = useMediaQuery("(min-width:615px)");

  // ------------------------------

  const home = () => {
    props.history.push("/");
  };

  // ------------------------------

  const login = () => {
    props.history.push("/login");
  };

  // ------------------------------

  const courseHandler = (courseId, providerId) => {
    props.history.push(`details?p=${providerId}&c=${courseId}`);
  };

  // ------------------------------

  return (
    <MatAppBar
      static
      classBar={{
        position: "fixed",
        top: 0,
        background: "white",
        zIndex: 115,
      }}
      left={<LogoMenuBar onClick={home} />}
      right={
        <div className={styles.titleBtnContainer}>
          {props.showSearch ? (
            <AlgoliaSearch onSelect={courseHandler} />
          ) : (
            <div style={{ flex: 1 }}>&nbsp;</div>
          )}
          <Button
            style={{ textTransform: "none" }}
            classes={{
              label: styles.headerButton,
              root: styles.rootHeaderButton,
            }}
            onClick={login}
          >
            {t("login")}
          </Button>
          {matches ? <div>&nbsp;&nbsp;</div> : <div></div>}
          {props.mobileSetting &&
            props.mobileSetting.currency &&
            props.updateMobileCurrency && (
              <CurrencyLabel
                currencyAcronym={props.mobileSetting.currency}
                language={props.mobileSetting.language}
                updateMobileCurrency={props.updateMobileCurrency}
              />
            )}
          &nbsp;&nbsp;
          <IconButton aria-label="cart" onClick={login}>
            <ShoppingCartOutlinedIcon fontSize={matches ? "large" : "small"} />
          </IconButton>
        </div>
      }
    />
  );
};

export default DefaultToolbar;
