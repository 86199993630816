import {
  SET_PAYMENTS,
  REMOVE_PAYMENT,
  SET_CLIENT_SECRET,
  SET_PAYMENT_CREDITS,
} from "../actions/payment";

import { LOGOUT } from "../actions/auth";

const initialState = {
  paymentCredits: 0,
  payment: [],
  clientSecret: null,
  piClientSecret: null,
};

const paymentReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_CLIENT_SECRET:
      return {
        ...state,
        clientSecret: action.clientSecret,
      };
    case SET_PAYMENTS:
      return { ...state, payment: action.payment };
    case SET_PAYMENT_CREDITS:
      return { ...state, paymentCredits: action.paymentCredits };
    case REMOVE_PAYMENT:
      const copyStatePayment = [...state.payment];
      const updatedDelPayment = copyStatePayment.filter((payment) => {
        return payment.id !== action.paymentMethodId;
      });
      return {
        ...state,
        payment: updatedDelPayment,
      };
    case LOGOUT:
      return initialState;
    default:
      return state;
  }
};

export default paymentReducer;
