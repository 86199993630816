class Language {
  constructor(id, name, locale, iso) {
    this.id = id;
    this.name = name;
    this.locale = locale;
    this.iso = iso;
  }
}

export default Language;
