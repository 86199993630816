import React from "react";
import styles from "./CourseCard.module.css";
import ProgressBar from "../../ui/progress/ProgressBar";
import EventIcon from "@material-ui/icons/Event";
import PostAddIcon from "@material-ui/icons/PostAdd";
import LiveTvIcon from "@material-ui/icons/LiveTv";
import OndemandVideoIcon from "@material-ui/icons/OndemandVideo";
import { roundAmount } from "../../../globals/Common";
import NumberFormat from "react-number-format";
import CameraAltIcon from "@material-ui/icons/CameraAlt";


// ------------------------------

const resolveOnlineSeats = (props) => {
  if (props.onlineSeats !== "N/A" && props.onlineSeats !== "Unlimited") {
    return props.onlineSeated + "/" + props.onlineSeats;
  }

  return ""
}

const resolveClassSeats = (props) => {
  if (
    props.classroomLectureSeats !== "N/A" &&
    props.classroomLectureSeats !== "Unlimited"
  ) {
    return props.classroomLectureSeated + "/" + props.classroomLectureSeats;
  }

  return ""
}

const resolveHandsOnSeats = (props) => {
  if (
    props.classroomHandsonSeats !== "N/A" &&
    props.classroomHandsonSeats !== "Unlimited"
  ) {
    return props.classroomHandsonSeated + "/" + props.classroomHandsonSeats;
  }

  return ""
}

// ------------------------------

const CourseCard = (props) => {
  let renderOnlineSeats = resolveOnlineSeats(props);
  let renderClassroomSeats = resolveClassSeats(props);
  let renderHandsonSeats = resolveHandsOnSeats(props);
  const active = props.activeTab === 0;
  const draft = props.activeTab === 1;
  const history = props.activeTab === 2;

  return (
    <div className={styles.itemContainer}>
      <div className={styles.item}>

        {props.image ?
          <img
            className={(active || draft) ? styles.imageContainer : styles.imageContainerNoHover}
            src={props.image}
            onClick={(active || draft) ? props.onCoverPhoto : () => { }}
          />
          : <div
            className={(active || draft) ? styles.imageContainer : styles.imageContainerNoHover}
            onClick={(active || draft) ? props.onCoverPhoto : () => { }}
          >
            <div><CameraAltIcon /></div>
            {(active || draft) ? <div>Edit cover photo</div> : null}
          </div>
        }

        <div className={styles.dateOrTypeContainer}>
          {props.startDate && (
            <div className={styles.eventContainer}>
              <EventIcon
                style={{ paddingRight: "2px", fontSize: "18" }}
              />
              {props.startDate}&nbsp;
            </div>
          )}
          {props.isVideo && (
            <div className={styles.eventContainer}>
              <OndemandVideoIcon
                style={{ paddingRight: "2px", fontSize: "18" }}
              />
              Recorded Video&nbsp;
            </div>
          )}
          <div
            style={{
              color: "green",
              textAlign: "right",
            }}
          >
            {active ? props.daysRemaining : props.activation}
          </div>
        </div>

        {/*****************************************************************/}

        <div className={styles.itemColumn}>
          <div className={styles.title}>{props.title}</div>
          <div className={styles.description}>
            {props.professor
              .map(function (p) {
                return p.fullName();
              })
              .join(", ")}
          </div>
        </div>

        {/*****************************************************************/}

        {active && props.seats !== "N/A" && (
          <div className={styles.lineContainer}>
            <div className={styles.line}></div>
          </div>
        )}

        {/*****************************************************************/}

        {active && (
          <div className={styles.advertisementContainer}>
            {props.amendmentCount > 0 && (
              <div className={styles.clickable} onClick={props.onCourseView}>
                <PostAddIcon style={{ fontSize: "14px" }} />
                &nbsp;Amendments: {props.amendmentCount}/3
              </div>
            )}
            {props.adCount > 0 && (
              <div
                className={styles.clickable}
                onClick={props.onViewCourseAdds}
              >
                <LiveTvIcon
                  style={{
                    fontSize: "13px",
                  }}
                />
                &nbsp;Activated Ads: {props.adCount}
              </div>
            )}
          </div>
        )}

        {/*****************************************************************/}

        {active && (
          <div className={styles.capacityContainer}>
            {active &&
              props.onlineSeats !== "N/A" &&
              props.onlineSeats !== -1 &&
              props.onlineSeats !== "Unlimited" &&
              props.onlineSeats !== -2 && (
                <div className={styles.capacityRow}>
                  <div className={styles.statTitle}>
                    {props.isVideo ? "Online Video" : "Livestream "}
                    <div>{renderOnlineSeats}</div>
                  </div>
                  <ProgressBar
                    progress={props
                      .calcCapacityPercent(props.onlineSeated, props.onlineSeats)
                      .toFixed(0)}
                  />
                </div>
              )}

            {active && props.onlineSeats === "Unlimited" && (
              <div className={styles.capacityRow}>
                <div className={styles.statTitle}>
                  <div>{props.isVideo ? "Online Video" : "Livestream "}</div>
                  <div>{props.onlineSeated}/Unlimited</div>
                </div>
              </div>
            )}

            {/*****************************************************************/}

            {active &&
              props.classroomLectureSeats !== "N/A" &&
              props.classroomLectureSeats !== -1 &&
              props.classroomLectureSeats !== "Unlimited" &&
              props.classroomLectureSeats !== -2 && (
                <div className={styles.capacityRow}>
                  <div className={styles.statTitle}>
                    Class Lecture
                    <div>{renderClassroomSeats}&nbsp;</div>
                  </div>
                  <ProgressBar
                    progress={props
                      .calcCapacityPercent(
                        props.classroomLectureSeated,
                        props.classroomLectureSeats
                      )
                      .toFixed(0)}
                  />
                </div>
              )}

            {active && props.classroomLectureSeats === "Unlimited" && (
              <div className={styles.capacityRow}>
                <div className={styles.statTitle}>Class Lecture (Unlimited)</div>
              </div>
            )}

            {active &&
              props.classroomHandsonSeats !== "N/A" &&
              props.classroomHandsonSeats !== -1 &&
              props.classroomHandsonSeats !== "Unlimited" &&
              props.classroomHandsonSeats !== -2 && (
                <div className={styles.capacityRow}>
                  <div className={styles.statTitle}>
                    Class Hands-on<div>{renderHandsonSeats}&nbsp;</div>
                  </div>
                  <ProgressBar
                    progress={props
                      .calcCapacityPercent(
                        props.classroomHandsonSeated,
                        props.classroomHandsonSeats
                      )
                      .toFixed(0)}
                  />
                </div>
              )}

            {active && props.classroomHandsonSeats === "Unlimited" && (
              <div className={styles.capacityRow}>
                <div className={styles.statTitle}>Class Hands-on (Unlimited)</div>
              </div>
            )}
          </div>
        )}

        {/*****************************************************************/}

        {(active || history) && (
          <div className={styles.total}>
            <div className={styles.totalRevenu}>
              Earnings:&nbsp;
              <NumberFormat
                value={roundAmount(props.revenu)}
                displayType={"text"}
                thousandSeparator={true}
                prefix={props.currency.shortName}
              />
            </div>
          </div>
        )}
      </div>

      <div style={{ flex: 1 }}>
        {props.renderActionButtons()}
      </div>
    </div>
  );
};

export default CourseCard;
