import { handleErrorResponse } from "../../globals/Error";
import { dbName } from "../../globals/ApiKeys";
import FireStoreParser from "firestore-parser";
export const SET_RATE = "SET_RATE";
export const LOGOUT = "LOGOUT";

// ------------------------------

export const fetchRates = (viewingRate) => {
  return async (dispatch, getState) => {
    const setting = getState().setting.mobileSetting;

    const baseRate = viewingRate
      ? viewingRate
      : setting.currency
      ? setting.currency
      : "CAD";

    const response = await fetch(
      `https://firestore.googleapis.com/v1/projects/${dbName}/databases/(default)/documents/rates/${baseRate}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "applications/json",
        },
      }
    );

    let resData = null;

    if (!response.ok) {
      resData = await fetchOpenRates(baseRate);
    } else {
      const resFirestoreData = await response.json();
      resData = FireStoreParser(resFirestoreData).fields;
    }

    let rate = {};
    if (resData) {
      rate = { ...resData };
      if (!rate.rates[baseRate]) {
        rate.rates[baseRate] = 1;
      }
    }

    dispatch({ type: SET_RATE, rate: rate });
  };
};

// ------------------------------

const fetchOpenRates = async (baseRate) => {
  const response = await fetch(
    `https://api.openrates.io/latest?base=${baseRate}`
  );

  const resData = await response.json();

  if (!response.ok) {
    handleErrorResponse(await response.json());
  }

  return resData;
};

// ------------------------------
