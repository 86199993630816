import React from "react";
import styles from "./Button.module.css";

const ButtonIcon = (props) => {
  let mergedStyles = [styles.button];
  if (props.style) {
    mergedStyles.push(props.style);
  }

  let buttonJsx = (
    <button className={mergedStyles.join(" ")} onClick={props.onClick}>
      {props.children}
    </button>
  );

  if (props.disabled) {
    buttonJsx = (
      <button className={styles.disabled} onClick={props.onClick} disabled>
        {props.children}
      </button>
    );
  }

  return buttonJsx;
};

export default ButtonIcon;
