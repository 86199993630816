import React, { Component } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import styles from "./MobileSetting.module.css";
import Tab from "@material-ui/core/Tab";
import * as profileActions from "../../../store/actions/profile";
import * as authActions from "../../../store/actions/auth";
import * as redirectActions from "../../../store/actions/redirect";
import MatTabs from "../../../components/ui/tabs/MatTabs";
import FormButton from "../../../components/ui/button/FormButton";
import * as logger from "../../../globals/Logger";
import InfoModal from "../../../components/modals/infoModal/InfoModal";
import Spinner from "../../../components/ui/spinner/Spinner";
import AlertSuccess from "../../../components/ui/snackbar/AlertSuccess";
import PrivateProfileForm from "./PrivateProfileForm";
import UserForm from "./UserForm";
import { hasFormErrors, validateField } from "../../../globals/Validations";
import MobileUserProfile from "../../../model/MobileUserProfile";

class MobileSettingScreen extends Component {
  state = {
    wait: false,
    waitMessage: "",
    message: null,
    messageTitle: null,
    errorModal: false,
    alertSuccess: null,
    activeTab: 0,

    // ----- form
    profile: new MobileUserProfile(
      "",
      "",
      "",
      "",
      "",
      "",
      new Date(),
      new Date(),
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      ""
    ),
    formErrors: {},
    hasErrors: false,
    touched: false,
  };

  // ------------------------------

  update = async (propValue) => {
    await this.setState({ ...this.state, ...propValue });
  };

  // ------------------------------

  componentDidMount = async () => {
    try {
      await this.update({ wait: true });
      const loadAsync = [];
      loadAsync.push(this.props.fetchUserFromDirectiory());
      loadAsync.push(this.props.fetchUserProfile());
      await Promise.all(loadAsync);
      await this.update({ wait: false });
      window.scrollTo(0, 0);
    } catch (error) {
      logger.error(error);
      await this.update({
        wait: false,
        errorModal: true,
        message: error.message,
      });
    }
  };

  // ------------------------------

  componentDidUpdate = async (prevProps) => {
    if (prevProps.profile !== this.props.profile) {
      await this.update({
        profile: this.props.profile,
      });
    }
  };

  // ------------------------------

  tabClickHandler = (tabId) => {
    this.update({ activeTab: tabId });
  };

  // ------------------------------

  closeErrorHandler = () => {
    this.update({
      message: null,
    });
  };

  // ------------------------------

  saveHandler = async () => {
    try {
      await this.validateForm();

      if (this.state.hasErrors) {
        await this.update({
          errorModal: true,
          message: this.props.t("settings_please_verify"),
        });
        return false;
      }

      if (this.state.touched) {
        await this.update({ waitNoRender: true });
        await this.props.updateUserProfile(this.state.profile);

        await this.update({
          waitNoRender: false,
          touched: false,
          alertSuccess: this.props.t("settings_profile_saved"),
        });

        if (this.props.profileToCourseRedirect) {
          this.props.setProfileToCourseRedirect(false);
          this.props.history.push("/details");
        }
      }

      return true;
    } catch (err) {
      logger.error(err);
      this.update({
        waitNoRender: false,
        errorModal: true,
        message: err.message,
      });
      return false;
    }
  };

  // ------------------------------

  validateForm = async () => {
    const t = this.props.t;
    const p = this.state.profile;
    const e = {};
    const e0 = validateField(p, e, "zubuName", "plainRequired", t);
    const e1 = validateField(p, e0, "licenseNumber", "licenseNumber", t);
    const e1_0 = validateField(p, e1, "licencingBoard", "plainRequired", t);
    const e2 = validateField(p, e1_0, "first", "plainRequired", t);
    const e3 = validateField(p, e2, "last", "plainRequired", t);
    const e4 = validateField(p, e3, "phone", "phone", t);
    const e5 = validateField(p, e4, "gender", "plainRequired", t);
    const e6 = validateField(p, e5, "streetNumber", "required", t);
    const e7 = validateField(p, e6, "streetName", "plainRequired", t);
    const e8 = validateField(p, e7, "country", "plainRequired", t);
    const e9 = validateField(p, e8, "stateProvince", "plainRequired", t);
    const e10 = validateField(p, e9, "city", "plainRequired", t);
    const e11 = validateField(p, e10, "zipPostal", "plainRequired", t);
    await this.update({ formErrors: e11 });
    await this.updateHasErrors(e11);

    /*
      // Other license skip val
      if (
        valueInCollection(OCCUPATION_OTHER, values["occupation"]) &&
        updateFormErrors.licenseNumber
      ) {
        delete updateFormErrors.licenseNumber;
      }
    */
  };

  // ------------------------------

  updateHasErrors = async (formErrors) => {
    await this.update({ hasErrors: hasFormErrors(formErrors, {}) });
  };

  // ------------------------------

  render() {
    return (
      <div style={{ backgroundColor: "white" }}>
        {this.state.wait && <Spinner message={this.state.waitMessage} />}

        <InfoModal
          isErrorModal={this.state.errorModal}
          title={
            this.state.errorModal
              ? this.props.t("error_occurred")
              : this.state.messageTitle
          }
          message={this.state.message}
          open={this.state.message !== null}
          onClose={this.closeErrorHandler}
        />

        {this.state.alertSuccess !== null && (
          <AlertSuccess
            message={this.state.alertSuccess}
            open={this.state.alertSuccess !== null}
            onClose={() => {
              this.update({ alertSuccess: null });
            }}
          />
        )}

        <MatTabs
          onClick={this.tabClickHandler}
          activeTab={this.state.activeTab}
          centered={false}
        >
          <Tab label={this.props.t("settings_tab_registration")} />
          <Tab label={this.props.t("settings_tab_user")} />
        </MatTabs>
        <div className={styles.screenSetting}>
          {this.state.activeTab === 0 && this.state.profile && (
            <div style={{ paddingTop: "50px" }}>
              <PrivateProfileForm
                profile={this.state.profile}
                formErrors={this.state.formErrors}
                updateFormErrors={async (errors) => {
                  await this.update({ formErrors: errors });
                  await this.updateHasErrors(errors);
                }}
                updateHasErrors={async (errors) => {
                  await this.updateHasErrors(errors);
                }}
                update={async (profile) => {
                  await this.update({ profile: profile, touched: true });
                }}
              />
              <FormButton
                label={this.props.t("g_save")}
                onClick={this.saveHandler}
                disabled={this.state.hasErrors || !this.state.touched}
              />
            </div>
          )}
          {this.state.activeTab === 1 && this.state.profile && (
            <div style={{ paddingTop: "50px" }}>
              <UserForm
                avatarPicture={this.props.avatarPicture}
                profile={this.state.profile}
                formErrors={this.state.formErrors}
                updateFormErrors={async (errors) => {
                  await this.update({ formErrors: errors });
                  await this.updateHasErrors(errors);
                }}
                updateHasErrors={async (errors) => {
                  await this.updateHasErrors(errors);
                }}
                update={async (profile) => {
                  await this.update({ profile: profile, touched: true });
                }}
              />
              <FormButton
                label={this.props.t("g_save")}
                onClick={this.saveHandler}
                disabled={this.state.hasErrors || !this.state.touched}
              />
            </div>
          )}
        </div>
      </div>
    );
  }
}

// ------------------------------

const mapStateToProps = (state) => {
  return {
    avatarPicture: state.auth.picture,
    profile: state.profile.userProfile,
    profileToCourseRedirect: state.redirect.profileToCourseRedirect,
  };
};

// ------------------------------

const mapDispatchToProps = (dispatch) => {
  return {
    fetchUserFromDirectiory: () =>
      dispatch(authActions.fetchUserFromDirectiory()),
    fetchUserProfile: () => dispatch(profileActions.fetchMobileUserProfile()),
    updateUserProfile: (profile) =>
      dispatch(profileActions.updateMobileUserProfile(profile)),
    setProfileToCourseRedirect: (redirect) =>
      dispatch(redirectActions.setProfileToCourseRedirect(redirect)),
  };
};

export default withTranslation("translations")(
  connect(mapStateToProps, mapDispatchToProps)(MobileSettingScreen)
);
