import validate from "validate.js";

/**
 * shared validations used for all forms
 *
 */

export const constraints = {
  email: {
    presence: { message: "^* Email required", allowEmpty: false },
    email: { message: "^Please enter a valid email" },
  },
  licenseNumber: {
    presence: { message: "^* License Number required", allowEmpty: false },
    length: { minimum: 3, tooShort: "^ Please enter a valid license number" },
  },
  password: {
    presence: { message: "^* is required", allowEmpty: false },
    length: { minimum: 6, tooShort: "^ minimum of 6 characters" },
  },
  required: {
    presence: { message: "^* is required", allowEmpty: false },
  },
  plainRequired: {
    presence: { message: "^* is required", allowEmpty: false },
    length: { minimum: 2, tooShort: "^ Minimum length of two characters" },
  },
  requiredIfPopulated: {
    presence: { message: "^* is required", allowEmpty: false },
    length: {
      minimum: 10,
      tooShort: "^ Minimum length of 10 characters",
    },
  },
  amount: {
    format: {
      pattern: "^[0-9]+(.[0-9]{1,2})?$",
      flags: "i",
    },
  },
  phone: {
    presence: {
      message: "^* Phone number is required",
      allowEmpty: false,
    },
    length: {
      minimum: 15,
      tooShort: "^Please enter a valid phone number",
    },
  },
};

// ------------------------------

export const getConstraints = (t) => {
  return {
    email: {
      presence: {
        message: `^* ${t("validate_email_required")}`,
        allowEmpty: false,
      },
      email: {
        message: `^${t("validate_please_enter")} ${t("g_email").toLowerCase()}`,
      },
    },
    licenseNumber: {
      presence: {
        message: `^* ${t("validate_is_required")}`,
        allowEmpty: false,
      },
      length: {
        minimum: 3,
        tooShort: `^${t("validate_please_enter")} ${t(
          "g_license_number"
        ).toLowerCase()}`,
      },
    },
    password: {
      presence: {
        message: `^* ${t("validate_is_required")}`,
        allowEmpty: false,
      },
      length: { minimum: 6, tooShort: `^ ${t("validate_min_length_six")}` },
    },
    required: {
      presence: {
        message: `^* ${t("validate_is_required")}`,
        allowEmpty: false,
      },
    },
    plainRequired: {
      presence: {
        message: `^* ${t("validate_is_required")}`,
        allowEmpty: false,
      },
      length: { minimum: 2, tooShort: `^ ${t("validate_min_length_two")}` },
    },
    requiredIfPopulated: {
      presence: {
        message: `^* ${t("validate_is_required")}`,
        allowEmpty: false,
      },
      length: {
        minimum: 10,
        tooShort: `^ ${t("validate_min_length_ten")}`,
      },
    },
    amount: {
      format: {
        pattern: "^[0-9]+(.[0-9]{1,2})?$",
        flags: "i",
      },
    },
    phone: {
      presence: {
        message: `^* ${t("validate_phone_required")}`,
        allowEmpty: false,
      },
      length: {
        minimum: 15,
        tooShort: `^${t("validate_min_length_phone")}`,
      },
    },
  };
};

// ------------------------------

export const hasError = (value) => {
  return value && value.length > 0 ? true : false;
};

// ------------------------------

export const hasFormErrors = (errors, values) => {
  // check error keys for msgs
  for (const key in errors) {
    if (errors[key] && errors[key].length > 0) {
      return true;
    }
  }

  // values flagged for error check can't be undefined or empty
  for (const key in values) {
    if (key === "courseDates") {
      return false;
    }

    if (!values[key] || values[key].length === 0) {
      return true;
    }
  }

  return false;
};

// ------------------------------

// specific for courseDates
export const hasCourseDatesError = (courseDates) => {
  // values flagged for error check can't be undefined or empty

  if (!courseDates || courseDates.length === 0) {
    return true;
  }

  return false;
};

// ------------------------------

export const validateField = (
  model,
  paramFormErrors,
  field,
  validatorKey,
  t
) => {
  let validateObj = {};
  validateObj[validatorKey] = model[field];
  const validationResponse = validate(
    validateObj,
    t ? getConstraints(t) : constraints
  );
  const updateFormErrors = { ...paramFormErrors };

  if (validationResponse[validatorKey]) {
    const firstError = validationResponse[validatorKey][0];
    updateFormErrors[field] = firstError;
  } else {
    delete updateFormErrors[field];
  }

  return updateFormErrors;
};

// ------------------------------
