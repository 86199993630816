import { newLocalDate, momentLongDate } from "../globals/Dates2";

class Promo {
  constructor(
    id,
    promo_code,
    discount,
    usageLimit,
    courses,
    users,
    start_date,
    end_date,
    used_count
  ) {
    this.id = id;
    this.promo_code = promo_code ? promo_code : "";
    this.discount = discount ? discount : 5;
    this.usageLimit = usageLimit ? usageLimit : -1;
    this.courses = courses ? courses : [];
    this.users = users ? users : [];
    this.start_date = start_date ? start_date : newLocalDate();
    this.end_date = end_date ? end_date : newLocalDate();
    this.used_count = used_count;
  }

  startDate = () => {
    return momentLongDate(this.start_date);
  };

  endDate = () => {
    return momentLongDate(this.end_date);
  };
}

export default Promo;
