import Promo from "../../model/Promo";
import { dbName } from "../../globals/ApiKeys";
import {
  toFirebaseStringArrayValue,
  fromFirebaseStringArrayValue,
  fromFirebaseDoubleValue,
  fromFirebaseNumberValue,
} from "../../globals/Common";
import { handleErrorResponse } from "../../globals/Error";
export const SET_PROMO_ID = "SET_PROMO_ID";
export const SET_PROMO_CODE = "SET_PROMO_CODE";
export const ADD_PROMO = "ADD_PROMO";
export const UPDATE_PROMO = "UPDATE_PROMO";
export const REMOVE_PROMO = "REMOVE_PROMO";

// ------------------------------

export const emailPromoCode = (codeId, email) => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;

    const response = await fetch(
      `https://us-central1-${dbName}.cloudfunctions.net/sendEmailPromoCode`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          data: { codeId: codeId, email: email },
        }),
      }
    );

    if (!response.ok) {
      handleErrorResponse(await response.json());
    }
  };
};

// ------------------------------

export const setAddAction = () => {
  return {
    type: SET_PROMO_ID,
    promoId: "new",
  };
};

// ------------------------------

export const setEditAction = (id) => {
  return {
    type: SET_PROMO_ID,
    promoId: id,
  };
};

// ------------------------------

export const fetchPromo = () => {
  return async (dispatch, getState) => {
    const userId = getState().auth.userId;
    const token = getState().auth.token;

    const response = await fetch(
      `https://firestore.googleapis.com/v1/projects/${dbName}/databases/(default)/documents/promo/${userId}/codes`,
      {
        method: "GET",
        headers: {
          "Content-Type": "applications/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );

    if (!response.ok) {
      handleErrorResponse(await response.json());
    }

    const resData = await response.json();

    const promoArr = [];
    if (resData.documents) {
      for (const promo of resData.documents) {
        const promoNameArray = promo.name.split("/");
        const promoId = promoNameArray[promoNameArray.length - 1];
        promoArr.push(
          new Promo(
            promoId,
            promo.fields.promo_code.stringValue,
            fromFirebaseDoubleValue(
              promo.fields.discount.doubleValue,
              promo.fields.discount.integerValue
            ),
            fromFirebaseNumberValue(promo.fields.usageLimit.integerValue),
            fromFirebaseStringArrayValue(
              promo.fields.courses.arrayValue.values
            ),
            fromFirebaseStringArrayValue(promo.fields.users.arrayValue.values),
            promo.fields.start_date
              ? new Date(promo.fields.start_date.timestampValue)
              : new Date(),

            promo.fields.end_date
              ? new Date(promo.fields.end_date.timestampValue)
              : new Date(),
            promo.fields.used_count ? promo.fields.used_count.integerValue : 0
          )
        );
      }
    }

    dispatch({
      type: SET_PROMO_CODE,
      promo: promoArr,
    });
  };
};

// ------------------------------

export const addPromo = (promo) => {
  return async (dispatch, getState) => {
    const userId = getState().auth.userId;
    const token = getState().auth.token;

    const response = await fetch(
      `https://firestore.googleapis.com/v1/projects/${dbName}/databases/(default)/documents/promo/${userId}/codes/${promo.promo_code}`,
      {
        method: "PATCH",
        headers: {
          "Content-Type": "applications/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          fields: {
            promo_code: { stringValue: promo.promo_code },
            discount: { doubleValue: promo.discount },
            usageLimit: { integerValue: promo.usageLimit },
            courses: {
              arrayValue: {
                values: toFirebaseStringArrayValue(promo.courses),
              },
            },
            users: {
              arrayValue: {
                values: toFirebaseStringArrayValue(promo.users),
              },
            },
            start_date: { timestampValue: promo.start_date },
            end_date: { timestampValue: promo.end_date },
          },
        }),
      }
    );

    if (!response.ok) {
      handleErrorResponse(await response.json());
    }

    const resData = await response.json();
    const idPathName = resData.name.split("/");
    const promoId = idPathName[idPathName.length - 1];

    dispatch(setEditAction(promoId));

    dispatch({
      type: ADD_PROMO,
      promo: { id: promoId, promo: promo },
    });
  };
};

// ------------------------------

export const updatePromo = (promo) => {
  return async (dispatch, getState) => {
    const userId = getState().auth.userId;
    const token = getState().auth.token;

    await fetch(
      `https://firestore.googleapis.com/v1/projects/${dbName}/databases/(default)/documents/promo/${userId}/codes/${promo.id}` +
        `?updateMask.fieldPaths=promo_code&updateMask.fieldPaths=discount&updateMask.fieldPaths=usageLimit&updateMask.fieldPaths=courses&updateMask.fieldPaths=users` +
        `&updateMask.fieldPaths=start_date&updateMask.fieldPaths=end_date`,
      {
        method: "PATCH",
        headers: {
          "Content-Type": "applications/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          fields: {
            promo_code: { stringValue: promo.promo_code },
            discount: { doubleValue: promo.discount },
            usageLimit: { integerValue: promo.usageLimit },
            courses: {
              arrayValue: {
                values: toFirebaseStringArrayValue(promo.courses),
              },
            },
            users: {
              arrayValue: {
                values: toFirebaseStringArrayValue(promo.users),
              },
            },
            start_date: { timestampValue: promo.start_date },
            end_date: { timestampValue: promo.end_date },
          },
        }),
      }
    );

    dispatch({
      type: UPDATE_PROMO,
      promo: promo,
    });
  };
};

// ------------------------------

export const removePromo = (promoId) => {
  return async (dispatch, getState) => {
    const userId = getState().auth.userId;
    const token = getState().auth.token;

    await fetch(
      `https://firestore.googleapis.com/v1/projects/${dbName}/databases/(default)/documents/promo/${userId}/codes/${promoId}`,
      {
        method: "DELETE",
        headers: {
          "Content-Type": "applications/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );

    dispatch({
      type: REMOVE_PROMO,
      id: promoId,
    });
  };
};

// ------------------------------
