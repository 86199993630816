import React from "react";
import Button from "@material-ui/core/Button";
import Snackbar from "@material-ui/core/Snackbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import { makeStyles } from "@material-ui/core/styles";
import LaunchIcon from "@material-ui/icons/Launch";
import stripe from "../assets/images/welcome/stripe.png";
import AddIcon from "@material-ui/icons/Add";
import logo from "../assets/images/inApp/logo.png";

const useStyles = makeStyles((theme) => ({
  snackbarStyleViaContentProps: {
    backgroundColor: "black",
    color: "white",
    padding: 20,
  },
}));

const ActivationWarning = (props) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(props.open);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);

    setTimeout(() => {
      setOpen(true);
    }, 20000);
  };

  const connectHandler = (event, reason) => {
    handleClose(event, reason);
    props.setupConnect();
  };

  return (
    <Snackbar
      ContentProps={{
        className: classes.snackbarStyleViaContentProps,
      }}
      anchorOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      open={open}
      onClose={handleClose}
      message={
        <div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <ErrorOutlineIcon />
            &nbsp;&nbsp;Stripe Setup
          </div>
          <div style={{ paddingTop: "10px", paddingBottom: "10px" }}></div>
          <div style={{ padding: "10px" }}>
            Please complete the <b>Stripe Setup</b>. This will enable&nbsp;
            <b>payments</b> and <b>payouts</b> to your <b>bank account</b>.
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              src={logo}
              alt="ZubU"
              style={{ height: "42px", paddingRight: "9px" }}
            />
            <AddIcon style={{ color: "#437aa1" }}></AddIcon>{" "}
            <img
              src={stripe}
              alt="Stripe"
              style={{
                paddingTop: "10px",
                height: "43px",
                paddingLeft: "6px",
              }}
            />
          </div>
        </div>
      }
      action={
        <React.Fragment>
          <Button
            startIcon={<LaunchIcon />}
            size="small"
            onClick={connectHandler}
            style={{
              border: "1px solid white",
              color: "white",
            }}
          >
            Stripe Setup
          </Button>
          &nbsp;&nbsp;
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={handleClose}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        </React.Fragment>
      }
    />
  );
};

export default ActivationWarning;
