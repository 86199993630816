import React from "react";
import MUIDataTable from "mui-datatables";
import TableFooter from "@material-ui/core/TableFooter";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import { CircularProgress } from "@material-ui/core";
import Tooltip from "@material-ui/core/Tooltip";
import styles from "../studentItems/StudentTable.module.css";

const comas = (x) => {
  return x.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

const prepForTableGrid = (balanceList) => {
  let convertedArr = [];
  for (const balance of balanceList) {
    const newRow = {
      type: balance.type,
      orderRefNumber: balance.orderRefNumber,
      amount: "$" + comas(balance.amount.toFixed(2)),
      net: "$" + comas(balance.net.toFixed(2)),
      fee: "($" + comas(balance.fee.toFixed(2)) + ")",
      currency: balance.currency.toUpperCase(),
      status: balance.status,
      created: balance.created,
    };
    convertedArr.push(newRow);
  }

  return convertedArr;
};

const BalanceTable = (props) => {
  // ------------------------------

  const columns = [
    {
      name: "type",
      label: "Type",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "orderRefNumber",
      label: "Order#",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Tooltip
              title="View / Refund / Unregister"
              aria-label="View / Refund / Unregister"
            >
              <div
                className={styles.orderNumber}
                onClick={() => {
                  props.editStudentOrder(props.data[tableMeta.rowIndex]);
                }}
              >
                {value}
              </div>
            </Tooltip>
          );
        },
      },
    },
    {
      name: "amount",
      label: "Amount",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "net",
      label: "Net",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "fee",
      label: "Fee",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "currency",
      label: "Currency",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "status",
      label: "Status",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "created",
      label: "Date",
      options: {
        filter: false,
        sort: false,
      },
    },
  ];

  // ------------------------------

  const options = {
    search: false,
    filter: false,
    print: false,
    serverSide: true,
    filterType: "checkbox",
    rowsPerPage: 10,
    rowsPerPageOptions: [],
    viewColumns: false,
    selectableRowsHideCheckboxes: true,
    selectableRowsOnClick: true,
    responsive: "vertical",
    selectableRows: "single",

    customFooter: (count) => {
      return (
        <TableFooter>
          <TableRow>
            <TableCell colSpan={500}>Row count: {count}</TableCell>
            <TableCell colSpan={500}>
              {props.hasMore && !props.tableLoading && (
                <div
                  onClick={props.changePage}
                  style={{
                    width: "100px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    cursor: "pointer",
                  }}
                >
                  Load More
                </div>
              )}
              {props.tableLoading && (
                <div
                  style={{
                    width: "100px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <CircularProgress size={24} color="inherit" />
                </div>
              )}
            </TableCell>
          </TableRow>
        </TableFooter>
      );
    },
    isRowSelectable: (dataIndex, selectedRows) => {
      return false;
    },
    onTableChange: (action, tableState) => {
      switch (action) {
        case "changePage":
          props.changePage(tableState.page, tableState.sortOrder);
          break;
        //  case "search":
        // search(tableState);
        //   break;
        //case "sort":
        //this.sort(tableState.page, tableState.sortOrder);
        //break;
        default:
      }
    },

    textLabels: {
      body: {
        noMatch: props.wait ? (
          <CircularProgress size={24} color="inherit" />
        ) : (
          "Sorry, there is no matching data to display"
        ),
      },
    },
  };

  // ------------------------------

  return (
    <MUIDataTable
      title={props.title}
      data={prepForTableGrid(props.data)}
      columns={columns}
      options={options}
    />
  );
};

export default BalanceTable;
