import React from "react";
import Menu from "@material-ui/core/Menu";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import Fab from "@material-ui/core/Fab";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";

import FlatButton from "../button/FlatButton";

const PopMenu = (props) => {
  if (props.regBtn) {
    return (
      <div>
        <FlatButton
          icononly="true"
          onClick={props.handleClick}
          icon={<MoreHorizIcon />}
        />
        <Menu
          id="simple-menu"
          anchorEl={props.anchorEl}
          keepMounted
          open={Boolean(props.anchorEl)}
          onClose={props.handleClose}
          getContentAnchorEl={null}
          anchorOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        >
          {props.children}
        </Menu>
      </div>
    );
  }

  if (props.iconBtn) {
    return (
      <span>
        <Tooltip title={"More Options"}>
          <IconButton onClick={props.handleClick}>
            <MoreHorizIcon />
          </IconButton>
        </Tooltip>
        <Menu
          id="simple-menu"
          anchorEl={props.anchorEl}
          keepMounted
          open={Boolean(props.anchorEl)}
          onClose={props.handleClose}
          getContentAnchorEl={null}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        >
          {props.children}
        </Menu>
      </span>
    );
  }

  return props.noFab ? (
    <div>
      <MoreHorizIcon size="small" onClick={props.handleClick} />
      <Menu
        id="simple-menu"
        anchorEl={props.anchorEl}
        keepMounted
        open={Boolean(props.anchorEl)}
        onClose={props.handleClose}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        {props.children}
      </Menu>
    </div>
  ) : (
    <div>
      <Fab
        color="default"
        size="small"
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={props.handleClick}
        disabled={props.disabled ? props.disabled : false}
      >
        {props.buttonIcon ? props.buttonIcon : <MoreHorizIcon size="small" />}
      </Fab>
      <Menu
        id="simple-menu"
        anchorEl={props.anchorEl}
        keepMounted
        open={Boolean(props.anchorEl)}
        onClose={props.handleClose}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        {props.children}
      </Menu>
    </div>
  );
};

export default PopMenu;
