import React from "react";
import styles from "./Image.module.css";
import FaceOutlinedIcon from "@material-ui/icons/FaceOutlined";

const Avatar = (props) => {
  let imageContent = (
    <div className={styles.avatar}>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <FaceOutlinedIcon style={{ color: "#ccc", fontSize: "60px" }} />
      </div>
    </div>
  );

  if (props.image) {
    imageContent = (
      <div className={styles.avatar}>
        <img src={props.image} alt="" className={styles.avatarImage} />
      </div>
    );
  }

  return <div>{imageContent}</div>;
};

export default Avatar;
