import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import ToggleButton from "@material-ui/lab/ToggleButton";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "white",
    color: "black",
    borderColor: "#ccc",
  },
}));

const Toggle = (props) => {
  const classes = useStyles();
  return (
    <ToggleButton
      style={props.style}
      className={classes.root}
      color="secondary"
      value="check"
      size="small"
      selected={props.selected}
      onChange={props.onChange}
      disabled={props.disabled}
    >
      <div>{props.title}</div>
    </ToggleButton>
  );
};

export default Toggle;
