import React from "react";
import styles from "./OrderRegChargeItem.module.css";
import { centToDollarNoDecimal } from "../../../globals/Common";
import { momentTinyDate } from "../../../globals/Dates2";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import ReportProblemOutlinedIcon from "@material-ui/icons/ReportProblemOutlined";
import EventNoteIcon from "@material-ui/icons/EventNote";
import NumberFormat from "react-number-format";
import BlockIcon from "@material-ui/icons/Block";

import TitleMenu from "../../ui/titleMenu/TitleMenu";
import MenuItem from "@material-ui/core/MenuItem";
import PopMenu from "../../ui/popMenu/PopMenu";
import PaymentIcon from "@material-ui/icons/Payment";

// ------------------------------

const renderInstallmentStatus = (
  currencyShortName,
  installmentStatusArray,
  onCancelPayment
) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  // ------------------------------

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  // ------------------------------

  const handleClose = () => {
    setAnchorEl(null);
  };

  const renderedInstallmentStatus = [];
  let key = 0;

  for (const installmentStatus of installmentStatusArray) {
    let colorStyle = { color: "black" };
    if (installmentStatus.status === "success") {
      colorStyle.color = "green";
    } else if (installmentStatus.status === "failed") {
      colorStyle.color = "red";
    }

    renderedInstallmentStatus.push(
      <div className={styles.installmentRow} key={key + ""}>
        <div className={styles.installmentInnerRow}>
          {renderIconFromStatus(installmentStatus.status)}
          <div style={colorStyle}>
            &nbsp;
            {momentTinyDate(installmentStatus.date)}
          </div>
          <div style={colorStyle}>
            &nbsp;-&nbsp;
            {installmentStatus.status === "success"
              ? "paid"
              : installmentStatus.status}
          </div>
        </div>

        <NumberFormat
          value={centToDollarNoDecimal(installmentStatus.amount)}
          displayType={"text"}
          thousandSeparator={true}
          prefix={currencyShortName}
        />

        {installmentStatus.status !== "success" &&
          installmentStatus.status !== "cancelled" && (
            <div>
              <PopMenu
                anchorEl={anchorEl}
                handleClick={handleClick}
                handleClose={handleClose}
                noFab
              >
                <MenuItem
                  onClick={() => {
                    handleClose();
                    onCancelPayment(installmentStatus.id);
                  }}
                >
                  <TitleMenu icon={<PaymentIcon />} title={"Cancel"} />
                </MenuItem>
              </PopMenu>
            </div>
          )}
      </div>
    );
    key++;
  }

  return renderedInstallmentStatus;
};

// ------------------------------

const renderIconFromStatus = (status) => {
  if (status === "pending") {
    return <EventNoteIcon style={{ color: "black" }} />;
  } else if (status === "success") {
    return <CheckCircleOutlineIcon style={{ color: "green" }} />;
  } else if (status === "failed") {
    return <ReportProblemOutlinedIcon style={{ color: "red" }} />;
  } else if (status === "cancelled") {
    return <BlockIcon style={{ color: "black" }} />;
  }

  return null;
};

// ------------------------------

const OrderRegChargeItem = (props) => {
  return (
    <div className={styles.card}>
      <div className={styles.sectionContainer}>
        <div className={styles.sectionTitle}> Charge Details</div>
        {props.studentOrder && (
          <div className={styles.chargeContainer}>
            <div className={styles.chargeRow}>
              <div>Order #</div>
              <div>{props.studentOrder.orderRefNumber} </div>
            </div>
            <div className={styles.chargeRow}>
              <div>Date: </div>
              <div>{props.studentOrder.format_date()}</div>
            </div>

            {props.studentOrder.installmentStatusArray.length > 0 ? (
              <div className={styles.chargeRow}>&nbsp;</div>
            ) : (
              <div className={styles.chargeRow}>
                <div> Charge of: </div>
                <div className={styles.amount}>
                  <NumberFormat
                    value={centToDollarNoDecimal(
                      props.studentOrder.totalAmount
                    )}
                    displayType={"text"}
                    thousandSeparator={true}
                    prefix={props.currency.shortName}
                  />
                </div>
              </div>
            )}

            <div className={styles.chargeRow}>
              <div>Refunds:</div>
              <div>
                <NumberFormat
                  value={props.refundTotal}
                  displayType={"text"}
                  thousandSeparator={true}
                  prefix={props.currency.shortName}
                />
              </div>
            </div>
            <div>&nbsp;</div>
            <div className={styles.chargeRow}>
              <div>To: </div>
              <div>
                {props.studentOrder.ccBrand} ending in ***
                {props.studentOrder.ccLast4}{" "}
              </div>
            </div>
            <div className={styles.chargeRow}>
              <div>&nbsp;</div>
              <div>{props.studentOrder.ccName}</div>
            </div>
            <div className={styles.chargeRow}>
              <div>&nbsp;</div>
              <div>Exp: {props.studentOrder.ccExpiryDate}</div>
            </div>
            <div>&nbsp;</div>
          </div>
        )}
      </div>

      {props.studentOrder &&
        props.studentOrder.installmentStatusArray.length > 0 && (
          <div className={styles.sectionContainer}>
            <div className={styles.sectionTitle}> Payment Installments</div>
            <div style={{ height: "10px" }}>&nbsp;</div>

            <div className={styles.installmentRow}>
              <div className={styles.installmentInnerRow}>
                {renderIconFromStatus("success")}
                <div style={{ color: "green" }}>
                  &nbsp;
                  {momentTinyDate(props.studentOrder.date)} - paid
                </div>
              </div>
              <NumberFormat
                value={centToDollarNoDecimal(props.studentOrder.totalAmount)}
                displayType={"text"}
                thousandSeparator={true}
                prefix={props.currency.shortName}
              />
            </div>

            {renderInstallmentStatus(
              props.currency.shortName,
              props.studentOrder.installmentStatusArray,
              props.onCancelPayment
            )}
          </div>
        )}
    </div>
  );
};

export default OrderRegChargeItem;
