import React from "react";
import styles from "../Legal.module.css";

export const renderPrivacyPolicyFr = () => {
  return (
    <div>
      <p className={styles.title}>
        <strong>
          <u>POLITIQUE DE PROTECTION DES DONN&Eacute;ES</u>
        </strong>
      </p>
      <p>
        Les termes utilis&eacute;s dans la pr&eacute;sente Politique de
        protection des donn&eacute;es &agrave; caract&egrave;re personnel (la
        &laquo;&nbsp;<strong>Politique</strong>&nbsp;&raquo;) commen&ccedil;ant
        par une majuscule et qui ne sont pas autrement d&eacute;finis aux
        pr&eacute;sentes ont la signification qui leur est donn&eacute;e dans
        les Conditions G&eacute;n&eacute;rales d&rsquo;Utilisation &eacute;mises
        par ZubU inc. (les &laquo;
        <strong>&nbsp;Conditions G&eacute;n&eacute;rales</strong>{" "}
        <strong>d&rsquo;Utilisation</strong>&nbsp;&raquo;).
      </p>
      <p>&nbsp;</p>
      <p>
        Cette Politique fixe la mani&egrave;re dont les Donn&eacute;es
        personnelles des Utilisateurs et autres personnes sont trait&eacute;es
        par ZubU dans le cadre des Services et de la Plateforme ZubU.
      </p>
      <p>&nbsp;</p>
      <p>
        Les Personnes Vis&eacute;es (ci-apr&egrave;s d&eacute;fini) sont
        susceptibles de communiquer &agrave; ZubU inc., &eacute;diteur de la
        Plateforme, ainsi qu&rsquo;aux Prestataires de Formation et &agrave;
        certains sous-traitants ou fournisseurs (collectivement, les
        &laquo;&nbsp;<strong>Fournisseurs</strong>&nbsp;&raquo;), des
        Donn&eacute;es personnelles les concernant ou concernant d&rsquo;autres
        individus en utilisant les diff&eacute;rents supports de collecte,
        outils et services mis &agrave; leur disposition sur la Plateforme.
      </p>
      <p>&nbsp;</p>
      <p>Pour les fins des pr&eacute;sentes&nbsp;:</p>
      <p>&nbsp;</p>
      <p>
        &laquo;&nbsp;<strong>Donn&eacute;e Personnelle</strong>&nbsp;&raquo;
        d&eacute;signe tout renseignement ou donn&eacute;e concernant un
        Utilisateur ou tout autre individu permettant de l&rsquo;identifier et
        toute autre renseignement analogue faisant l&rsquo;objet des Lois sur
        les Donn&eacute;es Personnelles&nbsp;;
      </p>
      <p>&nbsp;</p>
      <p>
        &laquo;&nbsp;<strong>Lois sur les Donn&eacute;es Personnelles</strong>
        &nbsp;&raquo; signifie toute loi concernant la protection des
        renseignements personnels, notamment,{" "}
        <em>
          Loi sur la protection des renseignements personnels dans le secteur
          priv&eacute;
        </em>{" "}
        et la{" "}
        <em>
          Loi sur l'acc&egrave;s aux documents des organismes publics et sur la
          protection des renseignements personnels
        </em>{" "}
        et, dans la mesure applicable, le R&egrave;glement G&eacute;n&eacute;ral
        n&deg;2016/679 sur la protection des donn&eacute;es &laquo; RGPD &raquo;
        du 27 avril 2016 et toute autre loi, r&egrave;glement, d&eacute;cret et
        directive r&eacute;gissant les Donn&eacute;es Personnelles ayant force
        de loi dans toute juridiction &agrave; l&rsquo;&eacute;gard de ZubU dans
        les circonstances&nbsp;;
      </p>
      <p>
        Les Donn&eacute;es Personnelles collect&eacute;es pourront faire
        l&rsquo;objet de traitement, automatis&eacute; ou non,
        conform&eacute;ment aux Lois sur les Donn&eacute;es Personnelles et dans
        les conditions attach&eacute;es &agrave; chaque support de collecte ou
        service, d&eacute;crites dans le Contrat et la pr&eacute;sente
        Politique.
      </p>
      <p>&nbsp;</p>
      <p>
        <a className={styles.link} href="#_Toc51057594">
          <strong>Article 1.1. Traitement et finalit&eacute;s</strong>
        </a>
      </p>
      <p>
        <a className={styles.link} href="#_Toc51057595">
          <strong>
            Article 1.2. Caract&eacute;ristiques sur l'usage et la collecte de
            Donn&eacute;es Personnelles
          </strong>
        </a>
      </p>
      <p>
        <a className={styles.link} href="#_Toc51057596">
          <strong>
            Article 1.3. Dur&eacute;e de conservation des Donn&eacute;es
            Personnelles
          </strong>
        </a>
      </p>
      <p>
        <a className={styles.link} href="#_Toc51057597">
          <strong>
            Article 1.4. Modalit&eacute;s d'exercice de vos droits
          </strong>
        </a>
      </p>
      <p>
        <a className={styles.link} href="#_Toc51057598">
          <strong>
            Article 1.5. Destinataires des Donn&eacute;es Personnelles
          </strong>
        </a>
      </p>
      <p>
        <a className={styles.link} href="#_Toc51057599">
          <strong>
            Article 1.6. Responsabilit&eacute; de la Personne Vis&eacute;e
          </strong>
        </a>
      </p>
      <p>
        <a className={styles.link} href="#_Toc51057600">
          <strong>Article 2. Le Prestataire de </strong>F
          <strong>
            ormation agit en qualit&eacute; de responsable de traitement
          </strong>
        </a>
      </p>
      <p>
        <a className={styles.link} href="#_Toc51057601">
          <strong>Article 2.1 Engagements du Prestataire de </strong>F
          <strong>ormation</strong>
        </a>
      </p>
      <p>
        <a className={styles.link} href="#_Toc51057602">
          <strong>
            Article 3. Autres informations collect&eacute;es et cookies
          </strong>
        </a>
      </p>
      <p>
        <a className={styles.link} href="#_Toc51057603">
          <strong>Article 3.1 Donn&eacute;es non personnelles</strong>
        </a>
      </p>
      <p>
        <a className={styles.link} href="#_Toc51057604">
          <strong>Article 3.2 Cookies</strong>
        </a>
      </p>
      <p>
        <a className={styles.link} href="#_Toc51057605">
          <strong>
            Article 3.3 Liens vers des sites ou applications de tiers
          </strong>
        </a>
      </p>
      <p>
        <a className={styles.link} href="#_Toc51057606">
          <strong>Article 4. Interpr&eacute;tation</strong>
        </a>
      </p>
      <p>
        <strong>&nbsp;</strong>
      </p>
      <p>
        <strong>
          <u>
            Article 1. ZubU agit en qualit&eacute; de responsable de traitement
          </u>
        </strong>
      </p>
      <h1>
        <a className={styles.link} name="_Toc51057594" href="/">
          &nbsp;
        </a>
        <strong>
          <u>Article 1.1. Traitement et finalit&eacute;s</u>
        </strong>
      </h1>
      <p>
        Les Donn&eacute;es Personnelles des Utilisateurs collect&eacute;es via
        les diff&eacute;rents Services ou supports de collecte figurant sur la
        Plateforme le sont par ZubU agissant en qualit&eacute; de responsable du
        traitement au sens des Lois sur les Donn&eacute;es Personnelles. Les
        Donn&eacute;es Personnelles des autres Personnes Vis&eacute;es seront
        collect&eacute;es par ZubU, agissant en qualit&eacute; de responsable du
        traitement des donn&eacute;es ou en tant que prestataire de services des
        Prestataires de Formation, eux-m&ecirc;mes responsables du traitement.
        Dans les deux cas, il est possible que le responsable de traitement soit
        un tiers (tel qu&rsquo;un Fournisseur d&rsquo;une application ou
        compl&eacute;ment tiers), auquel cas il sera express&eacute;ment
        mentionn&eacute; sur le support de collecte des Donn&eacute;es
        Personnelles. Lorsque cela est requis en vertu des Lois sur les
        Donn&eacute;es Personnelles, le responsable de traitement
        pr&eacute;cisera &agrave; la Personne Vis&eacute;e les
        caract&eacute;ristiques du traitement qu&rsquo;il met en &oelig;uvre
        sous sa seule responsabilit&eacute;.
      </p>
      <p>
        Les cat&eacute;gories de personnes vis&eacute;es (les &laquo;&nbsp;
        <strong>Personnes Vis&eacute;es</strong>&nbsp;&raquo;) sont les
        Utilisateurs, les employ&eacute;s et pr&eacute;pos&eacute;s de ZubU et
        les repr&eacute;sentants d&rsquo;un Prestataire de Formation et tous
        autres internautes consultant la Plateforme.
      </p>
      <p>
        Dans le cadre de l&rsquo;utilisation de la Plateforme et de ses
        Services, les Donn&eacute;es Personnelles qu&rsquo;une Personne
        Vis&eacute;e communique sont collect&eacute;es et trait&eacute;es par
        ZubU, par les Prestataires de Formation ou par un Fournisseur pour une
        obligation contractuelle ou pr&eacute;contractuelle, respecter ses
        obligations en vertu des lois applicables ou des ordonnances des
        autorit&eacute;s gouvernementales comp&eacute;tentes et/ou satisfaire la
        poursuite de son int&eacute;r&ecirc;t l&eacute;gitime, sans
        m&eacute;conna&icirc;tre les droits de la Personne Vis&eacute;e.
      </p>
      <p>
        Sur la base de ces fondements, des Donn&eacute;es Personnelles sont
        r&eacute;cup&eacute;r&eacute;es et trait&eacute;es pour fournir les
        services suivants&nbsp;:
      </p>
      <ul>
        <li className={styles.listItem}>
          permettre &agrave; l&rsquo;Utilisateur d&rsquo;acc&eacute;der &agrave;
          la Plateforme ZubU&nbsp;;
        </li>
        <li className={styles.listItem}>
          permettre au Prestataire de Formation, &agrave; ZubU ou &agrave; un
          Fournisseur de traiter les informations requises en vertu des lois
          relatives &agrave; la lutte contre le blanchiment de capitaux et
          contre le financement du terrorisme (connaissance client), traiter des
          transactions financi&egrave;res pour le compte de ZubU ou des
          Prestataire de Formation et b&eacute;n&eacute;ficier de tarifs
          avantageux aupr&egrave;s des Fournisseurs des services de
          paiement&nbsp;;
        </li>
        <li className={styles.listItem}>
          remettre aux Prestataires de Formation les Donn&eacute;es Personnes
          concernant les &Eacute;tudiants et autres Utilisateurs, afin
          qu&rsquo;elle les traite dans le cours normal de ses op&eacute;rations
          et conform&eacute;ment &agrave; ses propres politiques concernant les
          Donn&eacute;es Personnelles&nbsp;;
        </li>
        <li className={styles.listItem}>
          fournir aux Prestataires de Formation et aux autres Utilisateurs les
          Services et le support relatif aux Services et &agrave; la Plateforme.
        </li>
      </ul>
      <p>
        La nature des op&eacute;rations r&eacute;alis&eacute;es sur les
        Donn&eacute;es Personnelles est&nbsp;:
      </p>
      <ul>
        <li className={styles.listItem}>
          consolidation et traitement de ces donn&eacute;es selon les
          param&egrave;tres et fonctionnalit&eacute;s de la Plateforme&nbsp;;
        </li>
        <li className={styles.listItem}>
          transfert aux Prestataire de Formation pour leur traitement;
        </li>
        <li className={styles.listItem}>
          dans certains cas, transfert &agrave; un Fournisseur ou une
          autorit&eacute; gouvernementale en vertu des lois relatives &agrave;
          la lutte contre le blanchiment de capitaux et contre le financement du
          terrorisme (connaissance client)&nbsp;; et
        </li>
        <li className={styles.listItem}>
          dans certains cas, anonymisation et transmission d&rsquo;une partie
          des Donn&eacute;es Personnelles &agrave; un Fournisseur.
        </li>
      </ul>
      <p>La ou les finalit&eacute;(s) du traitement sont&nbsp;:</p>
      <ul>
        <li className={styles.listItem}>
          l&rsquo;acc&egrave;s &agrave; la Plateforme ZubU, cr&eacute;er et
          maintenir le Compte ZubU&nbsp;;
        </li>
        <li className={styles.listItem}>
          l&rsquo;utilisation des Services par les Prestataires de Formation et
          les autres Utilisateurs et l&rsquo;utilisation des services des
          Prestataires cours par une autre Personne Vis&eacute;e&nbsp;;
        </li>
        <li className={styles.listItem}>
          g&eacute;rer et envoyer des confirmations de commande et informations
          importantes sur le Compte de ZubU, les commandes de Services, les
          inscriptions, les abonnements et les cr&eacute;dits et informations de
          formation continue &nbsp;;
        </li>
        <li className={styles.listItem}>
          transmettre de infolettres, pr&eacute;senter des offres ou des
          informations concernant les services&nbsp;;
        </li>
        <li className={styles.listItem}>
          la collecte des informations obligatoires en vertu des lois relatives
          &agrave; la lutte contre le blanchiment de capitaux et contre le
          financement du terrorisme (connaissance client) et leur transmission
          aux Fournisseurs&nbsp;;
        </li>
        <li className={styles.listItem}>
          permettre aux Prestataires de Formation de g&eacute;rer les
          inscriptions&nbsp;;
        </li>
        <li className={styles.listItem}>
          permettre le bon fonctionnement et l&rsquo;am&eacute;lioration de la
          Plateforme ZubU, l&rsquo;analyse des incidents et le service &agrave;
          la client&egrave;le&nbsp;;
        </li>
        <li className={styles.listItem}>
          toute autre fin &agrave; laquelle la Personne Vis&eacute;e aura
          pr&eacute;alablement consenti lorsque des Donn&eacute;es Personnelles
          sont fournies&nbsp;; et
        </li>
        <li className={styles.listItem}>
          r&eacute;soudre les litiges, enqu&ecirc;ter sur les comportements
          ill&eacute;gaux, se conformer aux lois ou ordonnances d&rsquo;une
          autorit&eacute; gouvernementale comp&eacute;tente et assurer le
          respect des contrats et politiques liant ZubU.
        </li>
      </ul>
      <p>Les Donn&eacute;es Personnelles collect&eacute;es sont&nbsp;:</p>
      <ul>
        <li className={styles.listItem}>
          les donn&eacute;es d&rsquo;identification (par exemple&nbsp;: nom,
          pr&eacute;nom, date de naissance, photos, questions et r&eacute;ponses
          de s&eacute;curit&eacute;,&hellip;)&nbsp;;
        </li>
        <li className={styles.listItem}>
          les coordonn&eacute;es (par exemple&nbsp;: t&eacute;l&eacute;phone,
          adresse e-mail, adresse postale,&hellip;)&nbsp;;
        </li>
        <li className={styles.listItem}>
          les informations financi&egrave;res (par exemple&nbsp;: moyens de
          paiement, identit&eacute; bancaire,&hellip;)&nbsp;;
        </li>
        <li className={styles.listItem}>
          les donn&eacute;es de connexion et de g&eacute;olocalisation (par
          exemple&nbsp;: logs, adresse IP,&hellip;)&nbsp;; et
        </li>
        <li className={styles.listItem}>
          les informations que les Personnes Vis&eacute;es transmettent en
          r&eacute;ponse &agrave; des sondages ou enqu&ecirc;tes aux fins
          d&rsquo;am&eacute;liorer les Services ou de cibler des besoins ou des
          march&eacute;s additionnels.
        </li>
      </ul>
      <p>&nbsp;</p>
      <p>
        Les Donn&eacute;es Personnelles des Personnes Vis&eacute;es sont
        conserv&eacute;es uniquement pendant la dur&eacute;e de la relation
        contractuelle entre les Personnes Vis&eacute;es et ZubU. Les
        Donn&eacute;es Personnelles des Personnes Vis&eacute;es sont
        effac&eacute;es &agrave; compter de la suppression du Compte ZubU,
        &agrave; l&rsquo;exception de donn&eacute;es qui sont archiv&eacute;es
        et anonymis&eacute;es pour une dur&eacute;e post&eacute;rieure &agrave;
        la rupture de la relation contractuelle, telle qu&rsquo;indiqu&eacute;e
        ci-dessous (point 1.3. Dur&eacute;e de conservation des donn&eacute;es).
      </p>
      <h1>
        <a className={styles.link} href="/" name="_Toc51057595">
          <strong>
            <u>
              Article 1.2. Caract&eacute;ristiques sur l'usage et la collecte de
              Donn&eacute;es Personnelles
            </u>
          </strong>
        </a>
      </h1>
      <p>
        La Personne Vis&eacute;e n'est pas oblig&eacute;e de r&eacute;pondre
        &agrave; toutes les questions qui lui sont pos&eacute;es.
      </p>
      <p>
        Le caract&egrave;re obligatoire de la communication des informations est
        indiqu&eacute; par la pr&eacute;sence d'un ast&eacute;risque sur la
        Plateforme. Le d&eacute;faut de communication de ces donn&eacute;es aura
        pour cons&eacute;quence l'impossibilit&eacute; pour ZubU de traiter la
        demande de la Personne Vis&eacute;e ou pour lui de pouvoir utiliser
        l&rsquo;outil ou le service.
      </p>
      <p>
        Les Donn&eacute;es Personnelles pourront &ecirc;tre
        h&eacute;berg&eacute;es et trait&eacute;es au Canada, par la
        soci&eacute;t&eacute; m&egrave;re ZubU inc., ou dans d&rsquo;autres pays
        au sein duquel ZubU a des filiales ou exerce des activit&eacute;s. Les
        Personnes Vis&eacute;es acceptent donc, lorsque permis par les lois
        applicables, qu&rsquo;en transmettant leurs Donn&eacute;es Personnelles,
        celles-ci ne re&ccedil;oivent pas le m&ecirc;me degr&eacute; de
        protection que celui qui est en vigueur dans leur pays d&rsquo;origine.
        ZubU d&eacute;ploiera les efforts raisonnables pour que ses pratiques de
        confidentialit&eacute; soient conformes aux Lois relatives aux
        Donn&eacute;es Personnelles et au principe de
        &laquo;&nbsp;responsabilit&eacute; de transfert
        ult&eacute;rieur&nbsp;&raquo; (<em>privacy shield</em>).
      </p>
      <p>
        Une Personne Vis&eacute;e pourra demander de ne plus recevoir
        d&rsquo;infolettres ou autres informations publicitaires (opt-out) en
        utilisant le lien suivant&nbsp;:{" "}
        <strong>[Note&nbsp;: &agrave; ins&eacute;rer</strong>
        <strong>.]</strong>
      </p>
      <h1>
        <a className={styles.link} href="/" name="_Toc51057596">
          <strong>
            <u>
              Article 1.3. Dur&eacute;e de conservation des Donn&eacute;es
              Personnelles
            </u>
          </strong>
        </a>
      </h1>
      <p>
        Les Donn&eacute;es Personnelles de la Personne Vis&eacute;e pourront
        &ecirc;tre conserv&eacute;es et trait&eacute;es pour la dur&eacute;e
        n&eacute;cessaire &agrave; la r&eacute;alisation de la finalit&eacute;
        poursuivie et au maximum pour les dur&eacute;es mentionn&eacute;es dans
        la pr&eacute;sente Politique&nbsp;:
      </p>
      <ul>
        <li className={styles.listItem}>
          les contrats conclus dans le cadre de cette relation commerciale,
          pendant cinq (5) ans &agrave; compter de la fin de ces contrats&nbsp;;
        </li>
        <li className={styles.listItem}>
          les documents bancaires, pendant cinq (5) ans suivant la
          derni&egrave;re transaction&nbsp;;
        </li>
        <li className={styles.listItem}>
          les informations relatives &agrave; la gestion des Commandes, pendant
          dix (10) ans suivant la derni&egrave;re transaction sur la
          Plateforme&nbsp;;
        </li>
        <li className={styles.listItem}>
          les informations relatives &agrave; la gestion de la facturation,
          pendant dix (10) ans suivant la derni&egrave;re facture.
        </li>
      </ul>
      <p>&nbsp;</p>
      <p>
        En cas de proc&eacute;dure contentieuse, toutes informations et
        pi&egrave;ces, et tout document contenant des Donn&eacute;es
        Personnelles tendant &agrave; &eacute;tablir les faits litigieux peuvent
        &ecirc;tre conserv&eacute;s pour la dur&eacute;e de la proc&eacute;dure,
        y compris pour une dur&eacute;e sup&eacute;rieure &agrave; celle
        indiqu&eacute;e ci-dessus.
      </p>
      <p>&nbsp;</p>
      <p>
        Certaines donn&eacute;es pourront &ecirc;tre archiv&eacute;es
        au-del&agrave; des dur&eacute;es indiqu&eacute;es pour les besoins de la
        recherche, de la constatation et de la poursuite des infractions
        p&eacute;nales dans le seul but de permettre, en tant que besoins, la
        mise &agrave; disposition de ces donn&eacute;es &agrave; une
        autorit&eacute; judiciaire comp&eacute;tente.
      </p>
      <h1>
        <a className={styles.link} href="/" name="_Toc51057597">
          <strong>
            <u>Article 1.4. Modalit&eacute;s d'exercice de vos droits</u>
          </strong>
        </a>
      </h1>
      <p>
        <strong>
          <u>&nbsp;</u>
        </strong>
      </p>
      <p>
        La Personne Vis&eacute;e dispose de droits sur ses Donn&eacute;es
        Personnelles, que ZubU collecte et traite. Ces droits sont les
        suivants&nbsp;:
      </p>
      <ul>
        <li className={styles.listItem}>
          un droit d'acc&egrave;s, de rectification et d&rsquo;effacement des
          Donn&eacute;es Personnelles (inexactes, incompl&egrave;tes,
          &eacute;quivoques, ou p&eacute;rim&eacute;es)&nbsp;;
        </li>
        <li className={styles.listItem}>
          un droit d&rsquo;opposition au traitement des Donn&eacute;es
          Personnelles &agrave; tout moment dans le cadre de la prospection
          commerciale&nbsp;;
        </li>
        <li className={styles.listItem}>
          un droit &agrave; la limitation du traitement des Donn&eacute;es
          Personnelles dans les conditions pr&eacute;vues par les Lois sur les
          Donn&eacute;es Personnelles&nbsp;;
        </li>
        <li className={styles.listItem}>
          un droit &agrave; la portabilit&eacute; des Donn&eacute;es
          Personnelles&nbsp;;
        </li>
        <li className={styles.listItem}>
          un droit de retirer son consentement &agrave; tout moment&nbsp;; et
        </li>
        <li className={styles.listItem}>
          un droit de communiquer au responsable de traitement les instructions
          concernant les Donn&eacute;es Personnelles en cas de
          d&eacute;c&egrave;s.
        </li>
      </ul>
      <p>
        La Personne Vis&eacute;e peut exercer ces droits en contactant ZubU aux
        coordonn&eacute;es suivantes&nbsp;:
      </p>
      <ul>
        <li className={styles.listItem}>par e-mail : dpo@zubueducation.com</li>
      </ul>
      <p>
        <strong>
          <u>Canada</u>
        </strong>
      </p>
      <ul>
        <li className={styles.listItem}>
          par adresse postale&nbsp;: 11655 Rue Letellier, Montreal, Quebec, H3M
          2Z7
        </li>
      </ul>
      <p>&nbsp;</p>
      <p>
        Le d&eacute;l&eacute;gu&eacute; &agrave; la protection des
        Donn&eacute;es Personnelles (DPO) est Vassilios Marcopoulos.
      </p>
      <p>&nbsp;</p>
      <p>
        La Personne Vis&eacute;e peut, en cas de contestation, former une
        r&eacute;clamation aupr&egrave;s d&rsquo;une autorit&eacute; de
        contr&ocirc;le en vertu des Lois sur les Donn&eacute;es Personnelles.
      </p>
      <p>&nbsp;</p>
      <p>
        ZubU r&eacute;serve le droit de refuser ou moduler une requ&ecirc;te
        d&rsquo;une Personne Vis&eacute;e si elle n&rsquo;est pas conforme aux
        Lois sur les Donn&eacute;es Personnelles ou si ZubU a une obligation en
        vertu des lois et r&egrave;glements applicables ou autre demande
        d&rsquo;une autorit&eacute; gouvernementale administrative ou judiciaire
        comp&eacute;tente &agrave; l&rsquo;effet contraire (par exemple&nbsp;:
        de conserver ou divulguer cette information).
      </p>
      <h1>
        <a className={styles.link} href="/" name="_Toc51057598">
          <strong>
            <u>Article 1.5. Destinataires des Donn&eacute;es Personnelles</u>
          </strong>
        </a>
      </h1>
      <p>&nbsp;</p>
      <p>
        ZubU peut &ecirc;tre amen&eacute;e &agrave; transf&eacute;rer de
        mani&egrave;re temporaire et s&eacute;curis&eacute;e &agrave; des
        Fournisseurs avec qui elle sera, au pr&eacute;alable, entr&eacute;e en
        relations contractuelles, certaines Donn&eacute;es Personnelles des
        Personnes Vis&eacute;es, seulement lorsque cela est
        n&eacute;cessaire&nbsp;:
      </p>
      <p>&nbsp;</p>
      <ul>
        <li className={styles.listItem}>
          &agrave; l&rsquo;exploitation et &agrave; la maintenance de la
          Plateforme ZubU (p. ex. h&eacute;bergement, maintenance, etc.)&nbsp;;
        </li>
        <li className={styles.listItem}>
          pour la gestion et l&rsquo;utilisation du Compte de paiement&nbsp;;
        </li>
        <li className={styles.listItem}>
          &agrave; la lutte contre le blanchiment de capitaux et le financement
          du terrorisme (p. ex. institution financi&egrave;re des Prestataire de
          Formation ou de la Personne Vis&eacute;e, ou fournisseur de services
          de paiement) - Les conditions de ce traitement par chaque Fournisseur
          sont d&eacute;taill&eacute;es dans la convention de compte et la
          politique de protection des donn&eacute;es &eacute;mises par ce
          Fournisseur&nbsp;;
        </li>
        <li className={styles.listItem}>
          inscription au Contenu Acad&eacute;mique des Prestataires de Formation
          - Les termes et conditions applicables au traitement des
          donn&eacute;es personnelles par chaque Prestataire de Formation sont
          d&eacute;taill&eacute;s dans la politique de confidentialit&eacute;
          &eacute;mise par chacun de ces Prestataires de Formation afin de
          r&eacute;pondre &agrave; toute demande des autorit&eacute;s
          gouvernementales.
        </li>
      </ul>
      <p>&nbsp;</p>
      <p>
        A ce titre, ZubU veille &agrave; ce que l&rsquo;ensemble des tiers
        destinataires de Donn&eacute;es Personnelles placent la protection des
        Donn&eacute;es Personnelles au c&oelig;ur de leur mission et
        s&rsquo;engagent &agrave; prendre toutes les mesures raisonnables afin
        d&rsquo;assurer la s&eacute;curit&eacute; et la confidentialit&eacute;
        de ces Donn&eacute;es Personnelles en conformit&eacute; avec les Lois
        sur les Donn&eacute;es Personnelles qui leur sont applicables.
      </p>
      <p>&nbsp;</p>
      <p>
        Certains de ces Prestataires de Formation et Fournisseurs agissent en
        tant que responsable de traitement. Dans cette hypoth&egrave;se, ils
        communiqueront &agrave; ZubU ou &agrave; la Personne Vis&eacute;e les
        informations pertinentes et les conditions de la collecte et du
        traitement des Donn&eacute;es Personnelles qu&rsquo;il r&eacute;alise.
      </p>
      <p>&nbsp;</p>
      <p>
        En sus, lorsque le Prestataire de Formation ou le Fournisseur
        concern&eacute; est situ&eacute; en dehors de l&rsquo;Union
        europ&eacute;enne ou d&rsquo;un pays disposant d&rsquo;une
        r&eacute;glementation ad&eacute;quate au sens des Loi sur les
        Donn&eacute;es Personnelles, ZubU encadre sa relation avec le
        Prestataire de Formation ou le Fournisseur en adoptant un dispositif
        contractuel appropri&eacute;. Les Prestataires de Formation et
        Fournisseurs seront directement responsable des fournisseurs
        qu&rsquo;elle retient ou utilise et qui ne sont pas
        s&eacute;lectionn&eacute;s par, et li&eacute;s contractuellement
        &agrave; ZubU.
      </p>
      <p>&nbsp;</p>
      <p>
        ZubU ne vend pas les Donn&eacute;es Personnelles et ne les divulgue pas
        autrement qu&rsquo;en conformit&eacute; avec la pr&eacute;sente
        Politique. Une liste des divulgations sera fournie sur demande
        d&rsquo;une Personne Vis&eacute;e.
      </p>
      <p>&nbsp;</p>
      <p>
        ZubU pourra, en cas de cession, de restructuration (fusion,
        fusion-absorption) ou, le cas &eacute;ch&eacute;ant, faillite,
        transf&eacute;rer les Donn&eacute;es Personnelles &agrave; tout tiers
        qui sera amen&eacute; &agrave; reprendre ou poursuivre
        l&rsquo;activit&eacute;. Lorsque cela est requis par les Lois sur les
        Donn&eacute;es Personnelles, ZubU communiquera avec les Personnes
        Vis&eacute;es pour obtenir leur consentement.
      </p>
      <h1>
        <a className={styles.link} href="/" name="_Toc51057599">
          <strong>
            <u>
              Article 1.6. Responsabilit&eacute; de la Personne Vis&eacute;e
            </u>
          </strong>
        </a>
      </h1>
      <p>
        La Personne Vis&eacute;e pourrait fournir du contenu et des
        Donn&eacute;es Personnelles sur les plateformes publiques de la
        Plateforme (par exemple&nbsp;: discussions sur un forum / <em>chat</em>,
        fonctions multi-utilisateurs).&nbsp; Les Personnes Vis&eacute;es devront
        alors s&rsquo;assurer que leurs param&egrave;tres de
        confidentialit&eacute; tiennent compte de leur pr&eacute;f&eacute;rence
        ou s&rsquo;abstenir de fournir des Donn&eacute;es Personnelles. Seule la
        Personne Vis&eacute;e est responsable de la divulgation de ses
        donn&eacute;es.
      </p>
      <h1>
        <a className={styles.link} href="/" name="_Toc51057600">
          <strong>
            <u>
              Article 2. Le Prestataire de Formation agit en qualit&eacute; de
              responsable de traitement
            </u>
          </strong>
        </a>
      </h1>
      <p>
        <u>&nbsp;</u>
      </p>
      <p>
        Le Prestataire de Formation pourra intervenir en qualit&eacute; de
        responsable de traitement lorsqu&rsquo;elle collecte et traite les
        Donn&eacute;es Personnelles de ses &eacute;tudiants et de toute autre
        Personne Vis&eacute;e qui s&rsquo;inscrit &agrave; son Contenu
        Acad&eacute;mique.
      </p>
      <h1>
        <a className={styles.link} href="/" name="_Toc51057601">
          <strong>
            <u>Article 2.1 Engagements du Prestataire de Formation</u>
          </strong>
        </a>
      </h1>
      <p>&nbsp;</p>
      <p>
        Dans le cadre des traitements de Donn&eacute;es Personnelles que le
        Prestataire de Formation met en &oelig;uvre via la Plateforme ZubU, le
        Prestataire de Formation s&rsquo;engage &agrave;&nbsp;:
      </p>
      <p>&nbsp;</p>
      <ul>
        <li className={styles.listItem}>
          respecter les dispositions applicables des Lois sur les Donn&eacute;es
          Personnelles&nbsp;;
        </li>
      </ul>
      <p>&nbsp;</p>
      <ul>
        <li className={styles.listItem}>
          traiter les Donn&eacute;es Personnelles de mani&egrave;re licite,
          loyale et transparente au regard de la personne
          concern&eacute;e&nbsp;;
        </li>
      </ul>
      <p>&nbsp;</p>
      <ul>
        <li className={styles.listItem}>
          collecter les Donn&eacute;es Personnelles pour des finalit&eacute;s
          d&eacute;termin&eacute;es, explicites et l&eacute;gitimes, en
          conformit&eacute; avec la politique relativement aux donn&eacute;es
          personnelles des Prestataires de Formation, et ne pas traiter lesdites
          donn&eacute;es ult&eacute;rieurement d&rsquo;une mani&egrave;re
          incompatible avec ces finalit&eacute;s&nbsp;;
        </li>
      </ul>
      <p>&nbsp;</p>
      <ul>
        <li className={styles.listItem}>
          ne pas collecter des donn&eacute;es dites
          &laquo;&nbsp;sensibles&nbsp;&raquo; au sens des Lois sur les
          Donn&eacute;es Personnelles&nbsp;;
        </li>
      </ul>
      <p>&nbsp;</p>
      <ul>
        <li className={styles.listItem}>
          s&rsquo;assurer qu&rsquo;il existe une base l&eacute;gale aux
          traitements de Donn&eacute;es Personnelles qu&rsquo;elle met en
          &oelig;uvre&nbsp;;
        </li>
      </ul>
      <p>&nbsp;</p>
      <ul>
        <li className={styles.listItem}>
          collecter des Donn&eacute;es Personnelles ad&eacute;quates,
          pertinentes, n&eacute;cessaires au regard des finalit&eacute;s pour
          lesquelles elles sont trait&eacute;es, maintenir leur exactitude et,
          si n&eacute;cessaire, les mettre &agrave; jour&nbsp;;
        </li>
      </ul>
      <p>&nbsp;</p>
      <ul>
        <li className={styles.listItem}>
          conserver les Donn&eacute;es Personnelles trait&eacute;es sous une
          forme permettant l&rsquo;identification des personnes
          concern&eacute;es pendant une dur&eacute;e n&rsquo;exc&eacute;dant pas
          celle n&eacute;cessaire au regard des finalit&eacute;s pour lesquelles
          elles sont trait&eacute;es&nbsp;;
        </li>
      </ul>
      <p>&nbsp;</p>
      <ul>
        <li className={styles.listItem}>
          proc&eacute;der &agrave; la suppression des Donn&eacute;es
          Personnelles collect&eacute;es au-del&agrave; de la p&eacute;riode
          n&eacute;cessaire pour laquelle elles sont trait&eacute;es&nbsp;;
        </li>
      </ul>
      <p>&nbsp;</p>
      <ul>
        <li className={styles.listItem}>
          &agrave; ne pas transf&eacute;rer les Donn&eacute;es Personnelles
          &agrave; un responsable de traitement ou un sous-traitant situ&eacute;
          dans un pays tiers n&rsquo;assurant pas un niveau ad&eacute;quate de
          protection des donn&eacute;es personnelles au sens des Lois sur les
          Donn&eacute;es Personnelles. Dans l&rsquo;hypoth&egrave;se o&ugrave;
          un tel transfert devrait intervenir, celui-ci ne peut avoir lieu que
          si les conditions d&eacute;finies aux Lois sur les Donn&eacute;es
          Personnelles sont respect&eacute;es&nbsp;;
        </li>
      </ul>
      <p>&nbsp;</p>
      <ul>
        <li className={styles.listItem}>
          traiter de fa&ccedil;on &agrave; garantir une s&eacute;curit&eacute;
          appropri&eacute;e des Donn&eacute;es Personnelles, y compris la
          protection contre le traitement non autoris&eacute; ou illicite et
          contre la perte, la destruction ou les d&eacute;g&acirc;ts
          d&rsquo;origine accidentelle, &agrave; l&rsquo;aide de mesures
          techniques et organisationnelles appropri&eacute;es&nbsp;;
        </li>
      </ul>
      <p>&nbsp;</p>
      <ul>
        <li className={styles.listItem}>
          se conformer, vis-&agrave;-vis des Personnes Vis&eacute;es
          aupr&egrave;s desquelles les Donn&eacute;es Personnelles sont
          collect&eacute;es &agrave; l&rsquo;obligation d&rsquo;information
          telle que pr&eacute;vue aux Lois sur les Donn&eacute;es
          Personnelles&nbsp;; et faire figurer dans ses conditions
          g&eacute;n&eacute;rales ainsi qu&rsquo;au pied de tout questionnaire
          ou formulaire, y compris &eacute;lectronique, les mentions
          l&eacute;gales impos&eacute;es par lesdites lois&nbsp;;
        </li>
      </ul>
      <p>&nbsp;</p>
      <ul>
        <li className={styles.listItem}>
          donner suite, par &eacute;crit, aux demandes d&rsquo;exercice des
          droits des Personnes Vis&eacute;es, &agrave; savoir, droit
          d&rsquo;acc&egrave;s, de rectification, d&rsquo;effacement et
          d&rsquo;opposition, droit &agrave; la limitation du traitement, droit
          &agrave; la portabilit&eacute; des donn&eacute;es, droit de ne pas
          faire l&rsquo;objet d&rsquo;une d&eacute;cision individuelle
          automatis&eacute;e et droit de d&eacute;finir le sort de ses
          donn&eacute;es apr&egrave;s son d&eacute;c&egrave;s&nbsp;;
        </li>
      </ul>
      <p>&nbsp;</p>
      <ul>
        <li className={styles.listItem}>
          tenir un registre des activit&eacute;s de traitement recensant les
          traitements de Donn&eacute;es Personnelles
          r&eacute;alis&eacute;es&nbsp;;
        </li>
      </ul>
      <p>&nbsp;</p>
      <ul>
        <li className={styles.listItem}>
          disposer d&rsquo;un d&eacute;l&eacute;gu&eacute; &agrave; la
          protection des Donn&eacute;es Personnelles&nbsp;;
        </li>
      </ul>
      <p>&nbsp;</p>
      <ul>
        <li className={styles.listItem}>
          ne d&eacute;signer que des sous-traitants qui pr&eacute;sentent des
          garanties suffisantes quant &agrave; la mise en &oelig;uvre de mesures
          techniques et organisationnelles appropri&eacute;es, conclure avec ces
          sous-traitants un accord appropri&eacute; et s&rsquo;assurer de sa
          bonne ex&eacute;cution&nbsp;;
        </li>
      </ul>
      <p>&nbsp;</p>
      <ul>
        <li className={styles.listItem}>
          accomplir aupr&egrave;s des autorit&eacute;s gouvernementales
          comp&eacute;tentes les d&eacute;marches requises, en particulier en
          vue de mener une analyse d&rsquo;impact lorsque le traitement de
          Donn&eacute;es Personnelles envisag&eacute; sera susceptible
          d&rsquo;engendrer un risque &eacute;lev&eacute; pour les droits et
          libert&eacute;s des personnes concern&eacute;es tel
          qu&rsquo;identifi&eacute; en vertu des Lois sur les Donn&eacute;es
          Personnelles&nbsp;;
        </li>
      </ul>
      <p>&nbsp;</p>
      <ul>
        <li className={styles.listItem}>
          mettre en place une proc&eacute;dure interne, afin d&rsquo;identifier
          les cas de violation des Donn&eacute;es Personnelles pour lesquels une
          notification &agrave; l&rsquo;autorit&eacute; nationale de protection
          comp&eacute;tente et/ou aux personnes concern&eacute;es est requise.
        </li>
      </ul>
      <p>&nbsp;</p>
      <p>
        Le Prestataire de Formation, en sa qualit&eacute; de responsable de
        traitement, est responsable du respect des obligations susvis&eacute;es
        et est en mesure de d&eacute;montrer, &agrave; tout moment, que
        celles-ci sont respect&eacute;es. ZubU ne pourra voir sa
        responsabilit&eacute; engag&eacute;e pour un manquement par le
        Prestataire de Formation aux Lois sur les Donn&eacute;es Personnelles ou
        &agrave; l&rsquo;une des stipulations de la pr&eacute;sente Politique,
        sauf lorsque la loi prescrit express&eacute;ment le contraire.
      </p>
      <h1>
        <a className={styles.link} href="/" name="_Toc51057602">
          <strong>
            <u>Article 3. Autres informations collect&eacute;es et cookies</u>
          </strong>
        </a>
      </h1>
      <h1>
        <a className={styles.link} href="/" name="_Toc51057603">
          <strong>
            <u>Article 3.1 Donn&eacute;es non personnelles</u>
          </strong>
        </a>
      </h1>
      <p>&nbsp;</p>
      <p>
        ZubU pourrait collecter d&rsquo;autres informations qui ne sont pas des
        Donn&eacute;es Personnelles, incluant&nbsp;:
      </p>
      <p>&nbsp;</p>
      <ul>
        <li className={styles.listItem}>les identifiants et avatars&nbsp;;</li>
        <li className={styles.listItem}>
          les informations du navigateurs (par exemple&nbsp;:
          pr&eacute;f&eacute;rences, langue,&hellip;)&nbsp;;
        </li>
        <li className={styles.listItem}>
          les pages de renvoi et de sortie, pages de destination et pages vues
          et autres interactions avec le contenu web&nbsp;;
        </li>
        <li className={styles.listItem}>
          les informations relatives aux appareils, &eacute;quipements et
          logiciels, les mises &agrave; jour et compl&eacute;ments
          utilis&eacute;s&nbsp;;
        </li>
        <li className={styles.listItem}>
          des informations relatives aux fonctionnalit&eacute;s et services
          utilis&eacute;s, aux statistiques d&rsquo;utilisation, aux dons, aux
          pr&eacute;f&eacute;rences, pr&eacute;dispositions, comportements et
          aptitudes&nbsp;; et
        </li>
        <li className={styles.listItem}>
          des &eacute;v&egrave;nements affectant l&rsquo;utilisation (par
          exemple&nbsp;: interruptions de services, URL
          demand&eacute;es,&hellip;).
        </li>
      </ul>
      <p>&nbsp;</p>
      <p>
        Ces informations pourraient ne pas constituer des Donn&eacute;es
        Personnelles et ne pas b&eacute;n&eacute;ficier des protections des Lois
        sur les Donn&eacute;es Personnelles. &Agrave; titre d&rsquo;exemple,
        ZubU pourrait collecter, traiter et c&eacute;der des informations non
        nominatives, tel que des informations d&eacute;mographiques, des
        sp&eacute;cifications mat&eacute;rielles ou des statistiques de vente
        avec des Prestataires de Formation, Fournisseurs ou autres partenaires.
      </p>
      <h1>
        <a className={styles.link} href="/" name="_Toc51057604">
          <strong>
            <u>Article 3.2 Cookies</u>
          </strong>
        </a>
      </h1>
      <p>
        ZubU peut &ecirc;tre amen&eacute;e &agrave; collecter et stocker des
        informations sur l&rsquo;appareil d&rsquo;une Personne Vis&eacute;e,
        &agrave; l&rsquo;aide de m&eacute;canismes comme les cookies, le
        stockage de donn&eacute;es dans le navigateur et les caches de
        donn&eacute;es d&rsquo;application.
      </p>
      <p>&nbsp;</p>
      <p>
        Un cookie est un petit fichier texte, image ou logiciel, contenant des
        informations, qui est enregistr&eacute; sur le disque dur de votre
        terminal (par exemple&nbsp;: ordinateur, tablette, smartphone, ou tout
        appareil permettant de naviguer sur Internet) &agrave; l&rsquo;occasion
        de la consultation d&rsquo;un site Internet avec votre logiciel de
        navigation. Il est transmis par le serveur d&rsquo;un site Internet
        &agrave; votre navigateur. Le fichier cookie porte notamment sur les
        pages visit&eacute;es, les publicit&eacute;s sur lesquelles vous avez
        cliqu&eacute;es, le type de navigateur que vous utilisez, votre adresse
        IP, et permet &agrave; son &eacute;metteur d&rsquo;identifier le
        terminal dans lequel il est enregistr&eacute;, pendant la dur&eacute;e
        de validit&eacute; ou d&rsquo;enregistrement du cookie concern&eacute;.
      </p>
      <p>&nbsp;</p>
      <p>
        Seul l&rsquo;&eacute;metteur d&rsquo;un cookie est susceptible de lire
        ou de modifier des informations qui y sont contenues.
      </p>
      <p>&nbsp;</p>
      <p>
        Les cookies am&eacute;liorent votre confort de navigation sur notre
        Plateforme et sont indispensables pour acc&eacute;der &agrave; certains
        espaces s&eacute;curis&eacute;s. Si vous d&eacute;cidez de bloquer tous
        les cookies par l&rsquo;interm&eacute;diaire de votre navigateur
        internet, nous ne pourrez plus visiter que la partie publique de la
        Plateforme.
      </p>
      <p>&nbsp;</p>
      <p>
        Comme indiqu&eacute; sur le bandeau d&rsquo;information de la
        Plateforme, en poursuivant votre navigation, vous acceptez le
        d&eacute;p&ocirc;t de cookies sur votre terminal. Vous pouvez revenir
        sur cette d&eacute;cision &agrave; tout moment en vous opposant au
        d&eacute;p&ocirc;t des cookies.
      </p>
      <p>&nbsp;</p>
      <p>
        ZubU peut utiliser les cookies et des technologies semblables (ex.
        pixels espions, GIF invisibles) pour les aider notamment &agrave;
        comprendre quelles pages Internet, fonctionnalit&eacute;s ou
        publicit&eacute;s les Personnes vis&eacute;es consultent. Ces
        informations les aident &agrave; garder une trace des commandes ou
        transactions non r&eacute;alis&eacute;es, &agrave; mesurer
        l'efficacit&eacute; de des publicit&eacute;s, &agrave; veiller &agrave;
        ne pas proposer la m&ecirc;me publicit&eacute; de fa&ccedil;on
        r&eacute;p&eacute;t&eacute;e ainsi qu'&agrave; am&eacute;liorer
        l&rsquo;exp&eacute;rience, surveiller l&rsquo;achalandage sur la
        Plateforme, r&eacute;soudre des probl&egrave;mes techniques,
        d&eacute;tecter et pr&eacute;venir la fraude et faire appliquer les
        Conditions G&eacute;n&eacute;rales d&rsquo;Utilisation. Toute personne
        peut d&eacute;sactiver les cookies, limiter les types de cookies
        autoris&eacute;s ou configurer son navigateur pour qu'il
        l&rsquo;avertisse lors de l'envoi de cookies. Pour g&eacute;rer ces
        pr&eacute;f&eacute;rences, il doit &ecirc;tre fait
        r&eacute;f&eacute;rence aux instructions fournies par le navigateur Web.
      </p>
      <p>&nbsp;</p>
      <p>
        Des technologies analytiques de tiers peuvent &ecirc;tre
        int&eacute;gr&eacute;s sur la Plateforme et associer des informations
        qu&rsquo;elles collectent &agrave; d&rsquo;autres informations
        collect&eacute;es ind&eacute;pendamment au fil du temps sur
        diff&eacute;rentes plateformes ou sites. Les politiques de
        confidentialit&eacute; de ces tiers r&eacute;gissent l&rsquo;utilisation
        de ces donn&eacute;es.
      </p>
      <p>&nbsp;</p>
      <p>
        Certains cookies techniques sont indispensables pour le bon
        fonctionnement de la Plateforme. Leur suppression peut entra&icirc;ner
        des difficult&eacute;s de navigation ainsi que
        l&rsquo;impossibilit&eacute; de b&eacute;n&eacute;ficier de
        l&rsquo;int&eacute;gralit&eacute; des services.
      </p>
      <h1>
        <a className={styles.link} href="/" name="_Toc51057605">
          <strong>
            <u>Article 3.3 Liens vers des sites ou applications de tiers</u>
          </strong>
        </a>
      </h1>
      <p>
        La Plateforme pourrait contenir des liens ou publicit&eacute;s vers des
        emplacements, sites, applications ou plateformes appartenant &agrave;
        des tiers. Si une Personne vis&eacute;e clique sur ceux-ci ou consulte
        ces emplacements, site, applications ou plateformes, elle quitte les
        services de ZubU et doit consulter les politiques relatives &agrave; la
        confidentialit&eacute; de ces tiers avant de partager quelconque
        Donn&eacute;e Personnelle. ZubU ne fournit aucune garantie &agrave; cet
        &eacute;gard.
      </p>
      <h1>
        <a className={styles.link} href="/" name="_Toc51057606">
          <strong>
            <u>Article 4. Interpr&eacute;tation</u>
          </strong>
        </a>
      </h1>
      <p>
        ZubU peut modifier ponctuellement cette Politique, sans pr&eacute;avis.
        Un avis sera transmis aux Utilisateurs et sera publi&eacute; sur la page
        d&rsquo;accueil &agrave; compter de l&rsquo;entr&eacute;e en vigueur de
        la modification, &agrave; moins d&rsquo;exigences additionnelles en
        vertu des Lois relatives aux Donn&eacute;es Personnelles.
      </p>
      <p>&nbsp;</p>
      <p>&nbsp;</p>
    </div>
  );
};
