import React, { useState, useEffect } from "react";
import styles from "./Handout.module.css";
import { useTranslation } from "react-i18next";
import { useDropzone } from "react-dropzone";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import DescriptionIcon from "@material-ui/icons/Description";
import DeleteForeverOutlinedIcon from '@material-ui/icons/DeleteForeverOutlined';

const baseStyle = {
    flex: 1,
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: "20px",
    borderWidth: 2,
    borderRadius: 2,
    borderColor: "#eeeeee",
    borderStyle: "dashed",
    backgroundColor: "#fafafa",
    color: "#bdbdbd",
    outline: "none",
    transition: "border .24s ease-in-out",
};

const activeStyle = {
    borderColor: "#2196f3",
};

const acceptStyle = {
    borderColor: "#00e676",
};

const rejectStyle = {
    borderColor: "#ff1744",
};

const CertDropzone = (props) => {
    const { t } = useTranslation();

    const [handoutList, setHandoutList] = useState(
        props.handout ? props.handout : []
    );

    const [sendVisible, setSendVisible] = useState(
        handoutList && handoutList.size > 0
    );

    // ------------------------------

    const openHandoutUri = async (uri) => {
        if (uri && uri.startsWith("https://firebasestorage.googleapis.com/")) {
            window.open(uri, "_blank");
        }
    };


    // ------------------------------

    const addHandout = (acceptedFiles) => {
        if (acceptedFiles.length > 0) {
            const copyHandouts = [...handoutList];

            for (const file of acceptedFiles) {
                const handout = {
                    name: file.name,
                    uri: null,
                    file: file,
                };
                copyHandouts.push(handout);
            }

            setHandoutList(copyHandouts);
            props.onChange(copyHandouts);
        }
    };

    // ------------------------------

    const removeHandout = (index) => {
        const delHandoutList = [...handoutList];
        delHandoutList.splice(index, 1);
        setHandoutList(delHandoutList);
        props.onChange(delHandoutList, props.handoutDelivery);
    };

    // ------------------------------

    const {
        getRootProps,
        getInputProps,
        isDragActive,
        isDragAccept,
        isDragReject,
    } = useDropzone({
        accept: ['image/png', 'image/jpg', 'application/pdf'],
        maxSize: 2000000, // 2MB
        maxFiles: 1,
        onDrop: (acceptedFiles) => {
            addHandout(acceptedFiles);
        },
    });

    // ------------------------------

    useEffect(() => {
        if (handoutList.length > 0) {
            setSendVisible(true);
        } else {
            setSendVisible(false);
        }
    }, [handoutList]);

    // ------------------------------

    const style = React.useMemo(
        () => ({
            ...baseStyle,
            ...(isDragActive ? activeStyle : {}),
            ...(isDragAccept ? acceptStyle : {}),
            ...(isDragReject ? rejectStyle : {}),
        }),
        [isDragActive, isDragReject, isDragAccept]
    );

    // ------------------------------

    const acceptedFileItems = handoutList.map((file, index) => {
        return (
            <div key={index} className={styles.fileRow}>
                <div className={styles.fileName} onClick={openHandoutUri.bind(this, file.uri)}>
                    <DescriptionIcon color="primary" />
                    &nbsp;{file.name !== "certificate" && "certificate ("}{file.name}{file.name !== "certificate" && ")"}
                </div>
                {!props.readOnly && (
                    <div>
                        <Tooltip title="Delete" aria-label="Delete">
                            <IconButton
                                aria-label="Delete"
                                onClick={(event) => {
                                    removeHandout(index);
                                }}
                                size="small"
                            >
                                <DeleteForeverOutlinedIcon size="fontSizeSmall" color="primary" />
                            </IconButton>
                        </Tooltip>
                    </div>
                )}
            </div>
        );
    });

    // ------------------------------

    return (
        <div className={styles.dropContainer} style={props.modal ? { width: "100%" } : null}>
            <div className={styles.fileContainer}>{acceptedFileItems}</div>
            {!props.readOnly && acceptedFileItems.length === 0 && (
                <div {...getRootProps({ style })}>
                    <input {...getInputProps()} />
                    <div className={styles.title}>{t("registration_handout_drag")}</div>
                    <em>{t("certificate_drag_disclaimer")}</em>
                </div>
            )}

        </div>
    );

    // ------------------------------
};

export default CertDropzone;
