import { SET_RATE } from "../actions/rate";

const initialState = {
  rate: {},
};

const rateReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_RATE:
      return {
        ...state,
        rate: action.rate,
      };
    case "LOGOUT":
      return initialState;
    default:
      return state;
  }
};

export default rateReducer;
