import React, { Component } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import styles from "./AttendanceScreen.module.css";
import * as attendanceActions from "../../store/actions/attendance";
import * as profileActions from "../../store/actions/profile";
import * as courseActions from "../../store/actions/courses";
import * as settingActions from "../../store/actions/setting";
import * as providerActions from "../../store/actions/provider";
import Button from "@material-ui/core/Button";
import { objectHasNoValues } from "../../globals/Common";
import { zubuUrl } from "../../globals/ApiKeys";
import FlatList from "flatlist-react";
import Footer from "../../components/ui/footer/Footer";
import CheckCircleOutlinedIcon from "@material-ui/icons/CheckCircleOutlined";
import AssignmentIndOutlinedIcon from "@material-ui/icons/AssignmentIndOutlined";
import MatAppBar from "../../components/ui/appBar/MatAppBar";
import InfoModal from "../../components/modals/infoModal/InfoModal";
import Spinner from "../../components/ui/spinner/Spinner";
import AlertSuccess from "../../components/ui/snackbar/AlertSuccess";
import SearchInput from "../../components/ui/input/SearchInput";
import AttendanceItem from "../../components/items/attendanceItem/AttendanceItem";
import LogoMenuBar from "../../components/ui/logo/LogoMenuBar";
import EventOutlinedIcon from "@material-ui/icons/EventOutlined";
import { momentLongDate, momentTime } from "../../globals/Dates2.js";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import SentimentDissatisfiedOutlinedIcon from "@material-ui/icons/SentimentDissatisfiedOutlined";
import * as logger from "../../globals/Logger";
import Lottie from "lottie-react";
import CurrencyLabel from "../../components/ui/currencyLabel/CurrencyLabel";
import attendance from "../../assets/lottie/attendance.json";
import MenuPop from "../../components/ui/popover/MenuPop";
import PermissionModal from "../../components/modals/permissionModal/PermissionModal";
import Tooltip from "@material-ui/core/Tooltip";
import Avatar from "@material-ui/core/Avatar";
import AvatarGroup from "@material-ui/lab/AvatarGroup";
import ExposurePlus1Icon from "@material-ui/icons/ExposurePlus1";
import DualInputModal from "../../components/modals/inputModal/DualInputModal";
import { isMobile } from "react-device-detect";
import TouchAppIcon from "@material-ui/icons/TouchApp";
import {
  setLanguage,
  getRouteParam,
  buildCourseDates,
} from "../../globals/Common";

class AttendanceScreen extends Component {
  state = {
    wait: false,
    waitMessage: null,
    error: null,
    alertSuccess: null,
    searchTerm: "",
    unauthorized_access: false,
    promptUserNamePasscode: false,
  };

  // ------------------------------

  update = async (propValue) => {
    await this.setState({ ...this.state, ...propValue });
  };

  // ------------------------------

  componentDidMount = async () => {
    try {
      window.scrollTo(0, 0);
      this.update({ loading: true });
      const loadAsync = [];
      const courseId = this.props.courseId;
      const providerId = this.props.providerId;

      if (objectHasNoValues(this.props.mobileSetting)) {
        await this.props.fetchMobileSetting();
      }

      loadAsync.push(this.props.fetchAttendance(courseId, providerId));
      loadAsync.push(this.props.fetchCourse(courseId, providerId));
      await Promise.all(loadAsync);

      if (this.props.isAuthenticated) {
        await this.props.fetchProfile();
        await this.props.fetchAttendanceActiveFlag(courseId);
      } else {
        await this.props.fetchProvider(providerId);
        const userInfo = await this.props.getLocalStorageUserInfo();
        await this.validateUserInfo(userInfo);
      }

      await this.props.startListener(courseId, providerId);
      await this.update({ loading: false });
    } catch (error) {
      if (
        error.message === "Unauthorized Access" ||
        error.message === "Permission denied"
      ) {
        this.update({ loading: false, unauthorized_access: true });
      } else {
        logger.error(error);
        await this.update({
          loading: false,
          wait: false,
          error: error.message,
        });

        await this.props.removeLocalStorageUserInfo();
      }
    }
  };

  // ------------------------------

  componentWillUnmount = () => {
    this.props.stopListener();
  };

  // ------------------------------

  validateUserInfo = async (userInfo) => {
    try {
      if (userInfo && userInfo.passcode && userInfo.name) {
        await this.props.validatePasscode(
          this.props.providerId,
          this.props.courseId,
          this.props.uriCodeParam,
          userInfo.passcode,
          userInfo.name
        );

        await this.update({
          loading: false,
          wait: false,
          promptUserNamePasscode: false,
        });
      } else {
        await this.update({
          loading: false,
          wait: false,
          promptUserNamePasscode: true,
        });
      }
    } catch (error) {
      await this.props.removeLocalStorageUserInfo();
      logger.error(error);
      this.update({
        loading: false,
        wait: false,
        promptUserNamePasscode: true,
        error: error.message,
      });
    }
  };

  // ------------------------------

  retryValidateUserInfo = async (passcode, name) => {
    try {
      await this.update({ wait: true });
      await this.validateUserInfo({ passcode: passcode, name: name });
      await this.update({ wait: false });
    } catch (error) {
      await this.props.removeLocalStorageUserInfo();
      logger.error(error);
      this.update({
        loading: false,
        wait: false,
        promptUserNamePasscode: true,
        error: error.message,
      });
    }
  };

  // ------------------------------

  renderAttendance = (itemData, index) => {
    return (
      <AttendanceItem
        key={index}
        row={index}
        prefix={itemData.prefix()}
        first={itemData.first}
        last={itemData.last}
        occupation={itemData.occupation}
        license={itemData.license}
        orderRefNumber={itemData.orderRefNumber}
        registeredByName={itemData.registeredByName}
        checkedIn={itemData.checkedIn}
        onCheckInCheckOut={this.onCheckInCheckOutHandler.bind(this, itemData)}
      />
    );
  };

  // ------------------------------

  inputChangeHandler = (e) => {
    this.update({ searchTerm: e.target.value });
  };

  // ------------------------------

  groupSeparator = (group, idx, groupLabel) => {
    const totalAttendance = this.props.studentAttendance
      ? this.props.studentAttendance.length
      : 0;

    return (
      <div className={styles.groupSeparator}>
        {groupLabel === "false" ? (
          <div>
            <div className={styles.notCheckedIn}>
              {this.props.t("attendance_not_checked_in")}&nbsp;(
              {group ? group.length : 0})
            </div>
            {isMobile && (
              <div className={styles.helpSwipe}>
                {this.props.t("attendance_swipe")} <TouchAppIcon />
              </div>
            )}
          </div>
        ) : (
          <div className={styles.checkedIn}>
            <CheckCircleOutlinedIcon />
            {this.props.t("attendance_checked_in")}&nbsp;(
            {group ? group.length : 0}/{totalAttendance})
          </div>
        )}
      </div>
    );
  };

  // ------------------------------

  emptyResults = () => {
    if (
      !this.props.studentAttendance ||
      this.props.studentAttendance.length === 0
    ) {
      return (
        <div className={styles.resultNotFound}>
          {this.props.t("attendance_list_empty")}
        </div>
      );
    }
    return (
      <div className={styles.resultNotFound}>
        {this.props.t("attendance_search_not_found")}&nbsp;"
        {this.state.searchTerm}"
      </div>
    );
  };

  // ------------------------------

  onCheckInCheckOutHandler = async (attendance) => {
    try {
      await this.update({ wait: true });
      await this.props.updateAttendance(
        attendance,
        this.props.courseId,
        this.props.providerId
      );
      await this.update({
        wait: false,
        alertSuccess: attendance.checkedIn
          ? this.props.t("g_undo")
          : this.props.t("attendance_checked_in"),
        errorModal: false,
        searchTerm: "",
      });
    } catch (error) {
      logger.error(error);
      this.update({
        wait: false,
        error: error.message,
      });
    }
  };

  // ------------------------------

  updateMobileCurrency = async (currency, language) => {
    try {
      await this.props.updateCurrency(currency, language);
      setLanguage(language, this.props.i18n);
    } catch (error) {
      logger.error(error);
    }
  };

  // ------------------------------

  renderDateFromTo = (dates, date, index) => {
    let previousDate = null;
    let sameDay = false;
    let dateTimeSheduleItem = "";

    if (index > 0) {
      previousDate = dates[index - 1].from;
      sameDay = isEqualDate(date.from, previousDate);
    }

    if (sameDay) {
      // just time range
      dateTimeSheduleItem = (
        <div>
          {momentTime(date.from)} -{momentTime(date.to)}
        </div>
      );
    } else {
      // date title + time range
      dateTimeSheduleItem = (
        <div>
          <div className={styles.dateTitle}>{momentLongDate(date.from)}</div>
          <div className={styles.listItemColumn}>
            {"  "}
            {momentTime(date.from)} - {momentTime(date.to)}
          </div>
        </div>
      );
    }

    return (
      <div key={index}>
        <div style={{ paddingBottom: "4px" }}>{dateTimeSheduleItem}</div>
      </div>
    );
  };

  // ------------------------------

  renderDates = (dates) => {
    const sortedDates = dates.sort((a, b) =>
      new Date(a.from).getTime() < new Date(b.from).getTime() ? -1 : 1
    );

    return sortedDates.map((item, index) =>
      this.renderDateFromTo(dates, item, index)
    );
  };

  // ------------------------------

  savePasscodeHandler = async (passcode) => {
    try {
      await this.update({ wait: true });

      await this.props.updatePasscode(
        this.props.courseId,
        this.props.providerId,
        passcode
      );

      await this.update({
        wait: false,
        alertSuccess: this.props.t("attendance_passcode_saved"),
        errorModal: false,
      });
    } catch (error) {
      logger.error(error);
      this.update({
        wait: false,
        errorModal: true,
        message: error.message,
      });
    }
  };

  // ------------------------------

  render() {
    if (this.state.loading) {
      return <Spinner message={this.state.waitMessage} />;
    }

    if (this.state.promptUserNamePasscode) {
      return (
        <div>
          {this.state.wait && <Spinner message={this.state.waitMessage} />}

          <InfoModal
            isErrorModal={true}
            title={this.props.t("error_occurred")}
            message={this.state.error}
            open={this.state.error !== null}
            onClose={() => {
              this.update({ error: null });
            }}
          />

          <DualInputModal
            image={this.props.provider ? this.props.provider.logo : null}
            title={this.props.t("attendance_enter_pass_name")}
            inputLabel1={this.props.t("g_name")}
            inputLabel2={this.props.t("g_passcode")}
            open={true}
            onClose={async (passcode, name) => {
              await this.retryValidateUserInfo(passcode, name);
            }}
          />
        </div>
      );
    }

    if (this.state.unauthorized_access) {
      return (
        <div className={styles.screen}>
          <MatAppBar
            static
            classBar={{
              position: "fixed",
              top: 0,
              background: "white",
              zIndex: 115,
            }}
            left={
              <LogoMenuBar
                onClick={() => {
                  this.props.history.push("/");
                }}
              />
            }
            right={
              <div className={styles.titleBtnContainer}>
                {this.props.mobileSetting &&
                  this.props.mobileSetting.currency &&
                  this.updateMobileCurrency && (
                    <CurrencyLabel
                      currencyAcronym={this.props.mobileSetting.currency}
                      language={this.props.mobileSetting.language}
                      updateMobileCurrency={this.updateMobileCurrency}
                    />
                  )}
              </div>
            }
          />

          <div className={styles.lottieContainer}>
            <Lottie
              animationData={attendance}
              loop={true}
              className={styles.lottieImage}
            />

            <div className={styles.lottieText}>
              <SentimentDissatisfiedOutlinedIcon />{" "}
              {this.props.t("attendance_unavailable")}
            </div>
          </div>
          <Footer history={this.props.history} />
        </div>
      );
    }

    return (
      <div className={styles.screen}>
        {this.state.wait && <Spinner message={this.state.waitMessage} />}

        <InfoModal
          isErrorModal={true}
          title={this.props.t("error_occurred")}
          message={this.state.error}
          open={this.state.error !== null}
          onClose={() => {
            this.update({ error: null });
          }}
        />

        {this.state.alertSuccess !== null && (
          <AlertSuccess
            message={this.state.alertSuccess}
            severity={this.state.severity}
            open={this.state.alertSuccess !== null}
            onClose={() => {
              this.update({ alertSuccess: null, severity: null });
            }}
          />
        )}

        {this.props.isAuthenticated ? (
          <MatAppBar
            noFixed
            static
            left={
              <Button
                color="inherit"
                style={{ color: "black" }}
                onClick={this.props.history.goBack}
                startIcon={<ArrowBackIosIcon />}
              >
                {this.props.t("g_back")}
              </Button>
            }
            right={
              <div className={styles.shareButtonContainer}>
                <MenuPop
                  button={
                    <Tooltip
                      title={"Share with people"}
                      aria-label={"Share with people"}
                      style={{ cursor: "pointer" }}
                    >
                      <AvatarGroup max={1}>
                        <Avatar
                          src={
                            this.props.profile ? this.props.profile.logo : null
                          }
                        />
                        <Avatar>
                          <ExposurePlus1Icon />
                        </Avatar>
                      </AvatarGroup>
                    </Tooltip>
                  }
                >
                  <PermissionModal
                    title={"Share Attendance"}
                    description={
                      "Need help taking attendance?  Grant others access with the link and passcode. The page will only be accessible to others 24 hours before the course start date and time."
                    }
                    disclaimer={
                      "Only people with the link and passcode will be granted access."
                    }
                    link={this.props.attendanceLink}
                    passcode={this.props.passcode ? this.props.passcode : ""}
                    onSavePasscode={this.savePasscodeHandler}
                  />
                </MenuPop>
              </div>
            }
          />
        ) : (
          <MatAppBar
            static
            classBar={{
              position: "fixed",
              top: 0,
              background: "white",
              zIndex: 115,
            }}
            left={
              <LogoMenuBar
                onClick={() => {
                  this.props.history.push("/");
                }}
              />
            }
            right={
              <div className={styles.titleBtnContainer}>
                {this.props.mobileSetting &&
                  this.props.mobileSetting.currency &&
                  this.updateMobileCurrency && (
                    <CurrencyLabel
                      currencyAcronym={this.props.mobileSetting.currency}
                      language={this.props.mobileSetting.language}
                      updateMobileCurrency={this.updateMobileCurrency}
                    />
                  )}

                <Button
                  onClick={() => {
                    this.props.removeLocalStorageUserInfo();
                    this.update({ promptUserNamePasscode: true });
                  }}
                >
                  {this.props.t("g_logout")}
                </Button>
              </div>
            }
          />
        )}

        <div
          className={
            !this.props.isAuthenticated
              ? styles.userContainer
              : styles.container
          }
        >
          <div
            style={{
              maxWidth: "100%",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              flexWrap: "wrap",
            }}
          >
            <div style={{ paddingBottom: "15px" }}>
              <div className={styles.courseTitle}>
                {this.props.t("attendance")} -{" "}
                {this.props.course && this.props.course.title}
              </div>
              <div className={styles.dateRow}>
                <div className={styles.dateIconTitle}>
                  <div className={styles.dateIcon}>
                    <EventOutlinedIcon />
                  </div>
                </div>
                <div className={styles.dateTime}>
                  {this.props.course &&
                    this.props.course.start_date &&
                    this.renderDates(
                      buildCourseDates(
                        this.props.course.start_date_desc,
                        this.props.course.start_date,
                        this.props.course.end_date,
                        this.props.course.timezoneAbbr
                      )
                    )}
                </div>
              </div>
            </div>

            <div style={{ width: "400px" }}>
              <SearchInput
                title={this.props.t("g_bar_search")}
                name="Search"
                placeholder={this.props.t("attendance_search_placeholder")}
                searchTerm={this.state.searchTerm}
                onChange={this.inputChangeHandler}
                clear={() => {
                  this.update({ searchTerm: "" });
                }}
              />
            </div>
          </div>

          <div className={styles.listContainer}>
            <FlatList
              list={this.props.studentAttendance}
              renderItem={this.renderAttendance}
              renderWhenEmpty={this.emptyResults}
              sortBy={[
                { key: "checkedIn", descending: false },
                "first",
                { key: "last", descending: false },
              ]}
              groupBy={"checkedIn"}
              groupSeparator={this.groupSeparator}
              searchBy={[
                "first",
                "last",
                "license",
                "orderRefNumber",
                "registeredByName",
              ]}
              searchTerm={this.state.searchTerm}
              searchCaseInsensitive={true}
              searchOnEveryWord={true}
            />
          </div>
        </div>

        {!this.props.isAuthenticated && <Footer history={this.props.history} />}
      </div>
    );
  }
}

// ------------------------------

const mapStateToProps = (state, props) => {
  const isAuthenticated = state.auth.token != null;

  const courseId = isAuthenticated
    ? getRouteParam(props, "courseId")
    : props.match.params.courseId;

  const providerId = isAuthenticated
    ? getRouteParam(props, "providerId")
    : props.match.params.providerId;

  const attendanceLink = `${zubuUrl}/attendance/${providerId}/${courseId}/${state.attendance.uricode}`;

  return {
    provider: state.provider.selected_provider,
    attendanceLink: attendanceLink,
    uriCodeParam: props.match.params.uricode,
    passcode: state.attendance.passcode,
    isAuthenticated: isAuthenticated,
    course: state.courses.select_course,
    studentAttendance: state.attendance.studentAttendance,
    mobileSetting: state.setting.mobileSetting,
    courseId: courseId,
    providerId: providerId,
    profile: state.profile.profile,
  };
};

// ------------------------------

const mapDispatchToProps = (dispatch) => {
  return {
    validatePasscode: (pId, cId, uri, pc, name) =>
      dispatch(attendanceActions.validatePasscode(pId, cId, uri, pc, name)),
    fetchProvider: (providerId) =>
      dispatch(providerActions.fetchProvider(providerId)),
    getLocalStorageUserInfo: () =>
      dispatch(attendanceActions.getLocalStorageUserInfo()),
    removeLocalStorageUserInfo: () =>
      dispatch(attendanceActions.removeLocalStorageUserInfo()),
    fetchAttendanceActiveFlag: (cId) =>
      dispatch(attendanceActions.fetchAttendanceActiveFlag(cId)),
    updateAttendance: (attendance, courseId, providerId) =>
      dispatch(
        attendanceActions.updateAttendance(attendance, courseId, providerId)
      ),
    updateCurrency: (currency, language) =>
      dispatch(settingActions.updateCurrency(currency, language)),
    fetchMobileSetting: () => dispatch(settingActions.fetchMobileSetting()),
    fetchAttendance: (cId, pId) =>
      dispatch(attendanceActions.fetchAttendance(cId, pId)),
    fetchCourse: (courseId, providerId) =>
      dispatch(courseActions.fetchCourse(courseId, providerId)),
    startListener: (cId, pId) =>
      dispatch(attendanceActions.startAttendanceListener(cId, pId)),
    stopListener: () => dispatch(attendanceActions.stopAttendanceListener()),
    fetchProfile: () => dispatch(profileActions.fetchProfile()),
    updatePasscode: (cId, pId, pcode) =>
      dispatch(attendanceActions.updatePasscode(cId, pId, pcode)),
  };
};

export default withTranslation("translations")(
  connect(mapStateToProps, mapDispatchToProps)(AttendanceScreen)
);
