import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import useMediaQuery from "@material-ui/core/useMediaQuery";

const useStyles = makeStyles({
  root: {
    width: "100%",
    border: "0px solid #ccc",
    marginBottom: "5px",
  },
  smallRoot: {
    width: "60%",
    margin: "auto",
    border: "0.5px solid #F0F0F0",
    marginBottom: "5px",
  },
});

const SimpleCard = (props) => {
  const classes = useStyles();
  const matches = useMediaQuery("(max-width:499px)");

  return (
    <Card className={matches ? classes.root : classes.smallRoot}>
      <CardContent>{props.children}</CardContent>
    </Card>
  );
};

export default SimpleCard;
