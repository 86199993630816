import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import LockIcon from "@material-ui/icons/Lock";
import Button from "@material-ui/core/Button";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import LanguageIcon from "@material-ui/icons/Language";
import RedeemIcon from "@material-ui/icons/Redeem";
import CreditCardIcon from "@material-ui/icons/CreditCard";
import styles from "./Cart.module.css";
import * as paymentActions from "../../store/actions/payment";
import * as cartActions from "../../store/actions/cart";
import * as logger from "../../globals/Logger";

import SimpleContainer from "../../components/ui/container/Container";
import OrderItem from "../../components/items/orderItem/OrderItem";
import Spinner from "../../components/ui/spinner/Spinner";
import PaymentMethodModal from "../../components/modals/paymentMethodModal/PaymentMethodModal";
import CheckoutButton from "./CheckoutButton";
import InfoModal from "../../components/modals/infoModal/InfoModal";
import MatAppBar from "../../components/ui/appBar/MatAppBar";
import { clientFacingError } from "../../globals/Error";
import CreditCard from "../../components/ui/cc/CreditCard";
import PromoCode from "../../components/ui/promoCode/PromoCode";
import { getTaxObject, getPayAmountAndCredits } from "../../globals/TaxResolve";

class CheckoutScreen extends Component {
  state = {
    paymentMethod: null,
    paymentModalOpen: false,
    orderSuccessModalOpen: false,
    wait: false,
    waitMessage: "",
    error: null,
    promoCode: "",
  };

  // ------------------------------

  update = async (propValue) => {
    await this.setState({ ...this.state, ...propValue });
  };

  // ------------------------------

  componentDidMount = async () => {
    try {
      await this.setState({ ...this.state, wait: true });

      await Promise.all([
        this.props.fetchPaymentMethods(),
        this.props.fetchPaymentCredits(),
      ]);

      await this.setState({ ...this.state, wait: false });
    } catch (err) {
      logger.error(err);
      this.setState({ ...this.state, wait: false, error: err.message });
    }
  };

  // ------------------------------

  orderCompleted = () => {
    this.setState({ ...this.state, orderSuccessModalOpen: true });
  };

  // ------------------------------

  addPaymentMethodHandler = () => {
    this.props.history.push("/addpayment");
  };

  // ------------------------------

  closeErrorHandler = () => {
    this.setState({
      ...this.state,
      error: null,
    });
  };

  // ------------------------------

  openPaymentMethodHandler = () => {
    this.setState({ ...this.state, paymentModalOpen: true });
  };

  // ------------------------------

  render() {
    if (this.props.action !== "PUSH") {
      return <Redirect to="/cart" />;
    }

    if (this.state.wait) {
      return <Spinner message={this.state.waitMessage} />;
    }

    return (
      <div style={{ background: "white" }}>
        <MatAppBar
          static
          left={
            <Button
              color="inherit"
              style={{ color: "black" }}
              onClick={this.props.history.goBack}
              startIcon={<ArrowBackIosIcon />}
            >
              Back
            </Button>
          }
        />

        <SimpleContainer disableGutters={false}>
          <InfoModal
            isErrorModal={true}
            title="An error has occured"
            message={this.state.error}
            open={this.state.error !== null}
            onClose={this.closeErrorHandler}
          />

          <InfoModal
            title="Order Success"
            message={
              "Your order has been placed successfully.  " +
              "Your order confirmation email is on the way." +
              " You may also view your Purchase History from the main menu."
            }
            open={this.state.orderSuccessModalOpen}
            onClose={() => {
              this.setState({
                ...this.state,
                orderSuccessModalOpen: false,
              });

              this.props.clearCart();
              this.props.history.push("/advertise");
            }}
          />

          <PaymentMethodModal
            open={this.state.paymentModalOpen}
            list={this.props.paymentMethods}
            onSelect={(paymentMethod) => {
              let copyPayMethod = null;
              if (paymentMethod) {
                copyPayMethod = { ...paymentMethod };
              }
              this.setState({
                ...this.state,
                paymentModalOpen: false,
                paymentMethod: copyPayMethod,
              });
            }}
          />

          <div style={{ height: "80px" }}>&nbsp;</div>

          <div className={styles.sectionTitle}>
            <div className={styles.centerRow}>
              <LockIcon style={{ color: "white" }} />
              &nbsp;&nbsp; Secure Checkout
            </div>
          </div>

          <div className={styles.container}>
            <div className={styles.centerRow} style={{ fontWeight: "bold" }}>
              1. Order Summary
            </div>
          </div>

          <OrderItem
            order={this.props.cart}
            showDetails={true}
            proposedTaxes={this.props.tax}
            payAndCredit={this.props.payAndCredit}
          />

          <div className={styles.container}>
            <div
              className={styles.centerRow}
              style={{ fontWeight: "bold", paddingTop: "30px" }}
            >
              2. Coupon &nbsp;
              <RedeemIcon />
            </div>

            <PromoCode
              id="promoCode"
              name="promoCode"
              value={this.state.promoCode}
              onChange={() => {}}
              onApplyPromoCode={() => {}}
            />
          </div>

          <div className={styles.container}>
            <div className={styles.centerRow} style={{ fontWeight: "bold" }}>
              3. Payment Information &nbsp; <CreditCardIcon />
            </div>

            <CreditCard
              paymentMethod={this.state.paymentMethod}
              paymentMethods={this.props.paymentMethods}
              openPaymentMethodHandler={this.openPaymentMethodHandler}
              addPaymentMethod={() => {
                this.props.history.push("/addpayment?g=checkout");
              }}
            />

            <div className={styles.receipt}>
              <div className={styles.receiptTitle}>
                <LockIcon
                  className={styles.lockIcon}
                  style={{
                    paddingRight: 7,
                  }}
                />
                <div style={{ fontWeight: "bold" }}>Terms</div>{" "}
              </div>
              <div style={{ textAlign: "justify" }}>
                By placing an order, you are agreeing to our General Terms of
                Use, the Supplier Terms of Use and our Privacy Policy.
              </div>
            </div>

            <div className={styles.receipt}>
              <div className={styles.receiptTitle}>
                <LanguageIcon
                  className={styles.lockIcon}
                  style={{
                    paddingRight: 7,
                  }}
                />
                <div style={{ fontWeight: "bold" }}>Exchange Rates</div>
              </div>
              <div style={{ textAlign: "justify" }}>
                Prices will be charged in CAD for Canadian course providers or
                USD for US and International course provider's. Conversion rates
                are determined by your credit card supplier at the time of
                purchase. Review your credit card statement for exact pricing
                after the purchase.
              </div>
            </div>

            <div className={styles.checkoutButton}>
              <CheckoutButton
                payAmount={this.props.payAndCredit.payAmount}
                creditsUsed={this.props.payAndCredit.creditsUsed}
                orderCompleted={this.orderCompleted}
                submitting={(value, message) => {
                  this.setState({
                    ...this.state,
                    wait: value,
                    waitMessage: message ? message : "",
                  });
                }}
                cart={this.props.cart}
                paymentMethod={this.state.paymentMethod}
                placeOrder={this.props.placeOrder}
                sendError={(err) => {
                  this.setState({
                    ...this.state,
                    wait: false,
                    error: clientFacingError(err.message),
                  });
                }}
              />
            </div>
          </div>
          <div style={{ height: "58px" }}></div>
        </SimpleContainer>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  const payAndCredit = getPayAmountAndCredits(
    state.cart.totalAmount,
    state.payment.paymentCredits
  );

  const tax = getTaxObject(
    state.cart.currency ? state.cart.currency : "",
    state.profile.profile.state ? state.profile.profile.state : "",
    payAndCredit.payAmount
  );

  return {
    action: props.history.action,
    paymentMethods: state.payment.payment,
    payAndCredit: payAndCredit,
    cart: state.cart,
    tax: tax,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    clearCart: () => dispatch(cartActions.clearCart()),
    fetchPaymentMethods: () => dispatch(paymentActions.fetchPaymentMethods()),
    fetchPaymentCredits: () => dispatch(paymentActions.fetchPaymentCredits()),
    placeOrder: (cart, payMethod, creditsUsed) =>
      dispatch(
        paymentActions.processPaymentIntent(cart, payMethod, creditsUsed)
      ),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CheckoutScreen);
