import React, { useState } from "react";
import styles from "./MultiDateSelect.module.css";
import IconButton from "@material-ui/core/IconButton";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import EditIcon from "@material-ui/icons/Edit";
import TodayIcon from "@material-ui/icons/Today";
import Tooltip from "@material-ui/core/Tooltip";
import DateRangeIcon from "@material-ui/icons/DateRange";
import DateModal from "../../modals/dateModal/DateModal";
import FormButton from "../../ui/button/FormButton";

import {
  momentTime,
  momentLongDate,
  isEqualDate,
  addDays,
} from "../../../globals/Dates2";

const MultiDateSelect = (props) => {
  const [showDateModal, setShowDateModal] = useState(false);
  const [dates, setDates] = useState(props.courseDates);
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedDateIndex, setSelectedDateIndex] = useState(null);

  let listContainerJoin = [styles.listContainer];

  if (props.error) {
    listContainerJoin.push(styles.inputElementError);
  }

  const editDatePairHandler = (event, index) => {
    event.preventDefault();
    setSelectedDateIndex(index);
    setSelectedDate(dates[index]);
    setShowDateModal(true);
  };

  // ------------------------------

  const deleteDatePairHandler = (event, index) => {
    event.preventDefault();
    setDates((prevDates) => {
      prevDates.splice(index, 1);
      props.onDateChange(prevDates);
      return prevDates;
    });
  };

  // ------------------------------

  const renderDateModal = () => {
    if (showDateModal) {
      const openToDate =
        dates &&
        dates[dates.length - 1] &&
        dates[dates.length - 1].from.getTime() > new Date().getTime()
          ? dates[dates.length - 1].from
          : addDays(new Date(), 1);

      return (
        <DateModal
          selectedDate={selectedDate}
          isopen={showDateModal}
          openToDate={openToDate}
          onclose={() => {
            setSelectedDateIndex(null);
            setSelectedDate(null);
            setShowDateModal(false);
          }}
          updateDate={(dateFrom, dateTo, desc, timezoneAbbr) => {
            const updatedDate = {
              desc: desc,
              from: dateFrom,
              to: dateTo,
              timezoneAbbr: timezoneAbbr,
            };

            const copyDates = [...dates];
            copyDates[selectedDateIndex] = updatedDate;

            const sortedDates = copyDates.sort((a, b) =>
              new Date(a.to).getTime() < new Date(b.to).getTime() ? -1 : 1
            );

            setDates(sortedDates);
            setSelectedDateIndex(null);
            setSelectedDate(null);

            props.onDateChange(sortedDates);

            setShowDateModal(false);
          }}
          addDate={(dateFrom, dateTo, desc, timezoneAbbr) => {
            setDates((prevDates) => {
              prevDates.push({
                desc: desc,
                from: dateFrom,
                to: dateTo,
                timezoneAbbr: timezoneAbbr,
              });

              const sortedDates = prevDates.sort((a, b) =>
                new Date(a.to).getTime() < new Date(b.to).getTime() ? -1 : 1
              );

              props.onDateChange(sortedDates);
              return sortedDates;
            });

            setShowDateModal(false);
          }}
        />
      );
    }
  };

  const renderDateFromTo = (date, index) => {
    const dateDes = date.desc ? date.desc + ": " : "";
    let previousDate = null;
    let sameDay = false;
    if (index > 0) {
      previousDate = dates[index - 1].from;
      sameDay = isEqualDate(date.from, previousDate);
    }

    let dateTimeSheduleItem = "";

    if (sameDay) {
      // just time rante
      dateTimeSheduleItem = (
        <div className={styles.listItemColumn}>
          {dateDes}
          {"  "}
          {momentTime(date.from)} - {momentTime(date.to)}
        </div>
      );
    } else {
      // date title + time range
      dateTimeSheduleItem = (
        <div>
          <div className={styles.dateTitle}>
            <TodayIcon style={{ paddingRight: "5px" }} />
            {momentLongDate(date.from)}
          </div>
          <div className={styles.listItemColumn}>
            {dateDes}
            {"  "}
            {momentTime(date.from)} - {momentTime(date.to)}
          </div>
        </div>
      );
    }

    return (
      <div className={styles.listItem} key={index}>
        {dateTimeSheduleItem}
        {!props.disabled && (
          <div className={styles.row}>
            <div className={styles.listItemDelete}>
              <Tooltip title="Edit" aria-label="Edit">
                <IconButton
                  aria-label="Edit"
                  onClick={(event) => {
                    editDatePairHandler(event, index);
                  }}
                  size="small"
                >
                  <EditIcon size="fontSizeSmall" color="primary" />
                </IconButton>
              </Tooltip>
            </div>
            &nbsp;&nbsp;
            <div className={styles.listItemDelete}>
              <Tooltip title="Delete" aria-label="Delete">
                <IconButton
                  aria-label="Delete"
                  onClick={(event) => {
                    deleteDatePairHandler(event, index);
                  }}
                  size="small"
                >
                  <DeleteOutlineIcon size="fontSizeSmall" color="primary" />
                </IconButton>
              </Tooltip>
            </div>
          </div>
        )}
      </div>
    );
  };

  // ------------------------------

  if (!dates || dates.length === 0) {
    return (
      <div className={styles.container}>
        <div className={listContainerJoin.join(" ")}>
          {renderDateModal()}

          <FormButton
            startIcon={<DateRangeIcon />}
            onClick={setShowDateModal.bind(this, true)}
            label="Add Course Date"
            style={{ width: "100%", justifyContent: "center" }}
          />

          <div style={{ padding: "15px", fontSize: "12px" }} key={0}>
            <div className={styles.dateExample}>Example:</div>

            <div className={styles.dateExample}>
              <div>Wednesday August 5th 2020</div>
              <div>Lecture: 09:00 AM - 3:00 PM</div>
            </div>

            <div className={styles.dateExample}>
              <div>Thursday August 6th 2020</div>
              <div>Hands-on: 08:00 AM - 3:00 PM</div>
            </div>

            <div className={styles.error}>{props.error}</div>
          </div>
        </div>
      </div>
    );
  }

  // ------------------------------

  return (
    <div className={styles.container}>
      <div className={listContainerJoin.join(" ")}>
        {renderDateModal()}

        <FormButton
          startIcon={<DateRangeIcon />}
          onClick={setShowDateModal.bind(this, true)}
          label="Add Additional Dates"
          style={{ width: "100%", justifyContent: "center" }}
        />

        {dates.map((item, index) => renderDateFromTo(item, index))}
      </div>
    </div>
  );
};

export default MultiDateSelect;
