import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import MatSelectOutline from "../../../components/ui/labelSelect/MatSelectOutline";
import { selectReason } from "../../../globals/Data";
import { buildSingleSelect } from "../../../globals/Common";
import AccountBalanceIcon from "@material-ui/icons/AccountBalance";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import MatInputOutline from "../../../components/ui/input/MatInputOutline";
import Loader from "react-loader-spinner";
import Collapse from "@material-ui/core/Collapse";
import NumberFormat from "react-number-format";

const renderAmount = (amount) => {
  return amount ? amount.toFixed(2) : 0;
};

const RefundConfirmModal = (props) => {
  const { loadAccountBalance, refundModal } = props;

  const [input, setInput] = useState({
    reason: "",
  });

  const [reasonText, setReasonText] = useState("");

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("xs"));
  const fullWidth = true;
  const maxWidth = "sm";

  // ------------------------------
  useEffect(() => {
    if (refundModal) {
      loadAccountBalance();
    }
  }, [loadAccountBalance, refundModal]);

  // ------------------------------

  const title =
    props.refundModal && props.unregisterModal
      ? "Refund / Unregister Confirmation"
      : props.refundModal
      ? "Refund Confirmation"
      : "Unregister Confirmation";

  const buttonText =
    props.refundModal && props.unregisterModal
      ? `Refund  ${props.currency.shortName}${props.refundTotal} 
   / Unregister`
      : props.refundModal
      ? `Refund ${props.currency.shortName}${props.refundTotal}`
      : "Unregister";

  const action =
    props.refundModal && props.unregisterModal
      ? "Refund and Unregister"
      : props.refundModal
      ? "Refund"
      : "Unregister";

  // ------------------------------

  const handleClose = (action, reason, reasonText) => {
    let finalReason = reasonText ? reason + ": " + reasonText : reason;
    props.onClose(action, finalReason);
  };

  // ------------------------------

  const selectChangeHandler = (key, e, select) => {
    const copyInputs = { ...input };
    copyInputs[key] = select ? select.value : "";
    setInput(copyInputs);
    setReasonText("");
  };

  // ------------------------------

  const inputChangeHandler = (e) => {
    setReasonText(e.target.value);
  };

  // ------------------------------

  const isOkDisabled = () => {
    if (props.refundModal) {
      return (
        !props.total ||
        props.refundTotal > props.total.amount ||
        (input.reason === "Other" && !reasonText) ||
        !input.reason
      );
    } else {
      return (input.reason === "Other" && !reasonText) || !input.reason;
    }
  };

  // ------------------------------

  return (
    <div>
      <Dialog
        fullScreen={fullScreen}
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={props.open}
        onClose={handleClose.bind(this, null, null)}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
        <div
          style={{
            width: "100%",
            textAlign: "justify",
            padding: "15px",
            display: "flex",
            justifyContent: "center",
            backgroundColor: "#ccc",
            fontSize: 14,
          }}
        >
          <InfoOutlinedIcon />
          <div style={{ marginLeft: "10px" }}>
            {props.refundModal && (
              <div>
                Refunds take 5-10 days to appear on a customer's statement.
              </div>
            )}

            <div>
              The following action cannot be reversed, please select a reason
              and confirm that you wish to proceed.
            </div>
          </div>
        </div>

        <DialogContent>
          <React.Fragment>
            <div style={{ height: "10px" }}>&nbsp;</div>
            {props.refundModal && (
              <React.Fragment>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    height: "60px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <AccountBalanceIcon />
                    &nbsp;&nbsp;Account Balance:
                  </div>
                  {props.wait && (
                    <div
                      style={{
                        paddingLeft: "10px",
                        paddingRight: "10px",
                        width: "130px",
                      }}
                    >
                      <Loader type="Oval" color="#ccc" height={20} width={20} />
                    </div>
                  )}
                  {!props.wait && props.total && (
                    <div
                      style={{
                        paddingLeft: "10px",
                        paddingRight: "10px",
                        width: "200px",
                      }}
                    >
                      <NumberFormat
                        value={renderAmount(props.total.amount)}
                        displayType={"text"}
                        thousandSeparator={true}
                        prefix={"$"}
                        suffix={" " + props.total.currency.toUpperCase()}
                      />
                    </div>
                  )}
                </div>

                <div style={{ paddingBottom: "20px", color: "red" }}>
                  {props.total && props.refundTotal > props.total.amount && (
                    <div>
                      You don't have sufficent funds to proceed with the refund.
                      Please fund your account. From the menu select{" "}
                      <b>Account Balance</b>, add funds and try again.
                    </div>
                  )}
                </div>
              </React.Fragment>
            )}
            <MatSelectOutline
              double
              zeroMargin
              title="Reason"
              placeholder="Enter reason..."
              name="reason"
              id="reason"
              disableClearable={true}
              onChange={selectChangeHandler.bind(this, "reason")}
              options={selectReason}
              value={buildSingleSelect(input.reason)}
              getOptionLabel={(option) => option.label}
              getOptionSelected={(opt1, opt2) => opt1.value === opt2.value}
              disabled={false}
              multiple={false}
              required={true}
            />
            <Collapse in={input.reason === "Other"}>
              <MatInputOutline
                nomargin="true"
                notopbottommargin="true"
                title="Reason Description"
                name="reasonText"
                rows="6"
                multiline
                value={reasonText}
                onChange={inputChangeHandler}
                maxLength={1000}
                readOnly={false}
                required
              />
            </Collapse>
          </React.Fragment>
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            onClick={handleClose.bind(this, null, null)}
            color="primary"
          >
            Cancel
          </Button>
          <Button
            autoFocus
            onClick={handleClose.bind(this, action, input.reason, reasonText)}
            color="primary"
            disabled={isOkDisabled()}
          >
            {buttonText}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default RefundConfirmModal;
