import React from "react";
import styles from "./OrderRegDetailsItem.module.css";
import Button from "@material-ui/core/Button";
import OrderRegItem from "../orderRegItem/OrderRegItem";

const OrderRegDetailsItem = (props) => {
  // ------------------------------

  const renderButtonTitle = (unregisterList, refundList) => {
    if (
      refundList &&
      refundList.length > 0 &&
      unregisterList &&
      unregisterList.length > 0
    ) {
      return `Refund ${props.currency.shortName} ${props.refundTotal} / Unregister (${unregisterList.length})`;
    } else if (refundList && refundList.length > 0) {
      return "Refund " + props.currency.shortName + "" + props.refundTotal;
    } else if (unregisterList && unregisterList.length > 0) {
      return `Unregister (${unregisterList.length})`;
    } else {
      return "Refund / Unregister";
    }
  };

  // ------------------------------

  return (
    <div className={styles.card}>
      <div className={styles.sectionContainer}>
        <div className={styles.sectionTitle}> {props.title}</div>
        {props.studentOrder && (
          <div className={styles.spaceBetweenLayout}>
            {props.studentOrder.order_details.map((cItem) => (
              <div
                key={cItem.courseId}
                className={
                  // disable if we are not the selected course
                  cItem.courseId !== props.courseSelectedId &&
                  props.courseSelectedId
                    ? styles.disabled
                    : null
                }
              >
                <div className={styles.courseTitle}>{cItem.title}</div>

                {cItem.pricingItems.map((pi, index) => (
                  <div key={index}>
                    <OrderRegItem
                      courseId={cItem.courseId}
                      currency={props.currency}
                      students={props.students}
                      pricingItem={pi}
                      selectedMember={props.selectedStaffMember}
                      selectedMemberRefund={props.selectedStaffMemberRefund}
                      refund={props.refund}
                      studentOrder={props.studentOrder}
                      readOnly={props.readOnly}
                      disabled={
                        cItem.courseId !== props.courseSelectedId &&
                        props.courseSelectedId
                      }
                      onToggleUnregisterRow={(sm) => {
                        props.toggleUnregisterRow(cItem.courseId, sm);
                      }}
                      onToggleRefundRow={(sm, chargeId) => {
                        props.toggleRefundRow(
                          cItem.courseId,
                          sm,
                          pi.pricingItem,
                          chargeId
                        );
                      }}
                      updateRefundRowAmount={(sm, amount, chargeId) => {
                        props.updateRefundRowAmount(
                          cItem.courseId,
                          sm,
                          amount,
                          pi.pricingItem,
                          chargeId
                        );
                      }}
                    />
                    <div className={styles.regOption}>
                      ({pi.pricingItem.casting} for{" "}
                      {pi.pricingItem.audience.join(", ")}{" "}
                      {pi.pricingItem.component})
                    </div>
                  </div>
                ))}
              </div>
            ))}
            {!props.readOnly && (
              <div className={styles.buttonContainer}>
                <Button
                  variant="contained"
                  style={{
                    backgroundColor:
                      (props.selectedStaffMember.length > 0 &&
                        props.refundSubTotalInvalid === false) ||
                      (props.refundTotal > 0 &&
                        props.refundSubTotalInvalid === false)
                        ? "#437aa1"
                        : "#CCC",
                    color: "white",
                  }}
                  onClick={props.unregisterRefund}
                  disabled={
                    (props.selectedStaffMember.length === 0 &&
                      props.refundTotal === 0) ||
                    props.refundSubTotalInvalid
                  }
                >
                  {renderButtonTitle(
                    props.selectedStaffMember,
                    props.selectedStaffMemberRefund
                  )}
                </Button>
              </div>
            )}
          </div>
        )}

        {!props.studentOrder && (
          <div>
            <div>{props.emptyMessage}</div>
          </div>
        )}
      </div>
    </div>
  );
};

export default OrderRegDetailsItem;
