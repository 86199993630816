import React, { Component } from "react";
import { connect } from "react-redux";
import * as styles from "./AnalyticsScreen.module.css";
import * as analyticsActions from "../../store/actions/analytics";
import * as profileActions from "../../store/actions/profile";
import * as settingActions from "../../store/actions/setting";
import * as courseActions from "../../store/actions/courses";
import Tooltip from "@material-ui/core/Tooltip";
import Fab from "@material-ui/core/Fab";
import EqualizerOutlinedIcon from "@material-ui/icons/EqualizerOutlined";
import AssessmentIcon from "@material-ui/icons/Assessment";
import TuneIcon from "@material-ui/icons/Tune";
import ZBar from "../../components/ui/chart/ZBar";
import ZBarVertical from "../../components/ui/chart/ZBarVertical";
import ZPie from "../../components/ui/chart/ZPie";
import ZStacked from "../../components/ui/chart/ZStacked";
import InfoModal from "../../components/modals/infoModal/InfoModal";
import Spinner from "../../components/ui/spinner/Spinner";
import StatsDrawer from "../../components/navigation/drawer/StatsDrawer";
import FilterDrawer from "../../components/navigation/drawer/FilterDrawer";
import {
  objectHasNoValues,
  compareArray,
  findTitle,
} from "../../globals/Common";
import { momentShortDate, getDateRange } from "../../globals/Dates2";
import analyticsImg from "../../assets/images/inApp/analytics.png";
import { CHARTS } from "../../globals/Data";
import * as logger from "../../globals/Logger";

const chart_regByProf = [
  { name: "Dr. Tim Smith", Registrations: 56 },
  { name: "Dr. Van Klein", Registrations: 94 },
  { name: "Dr Mat Domaz", Registrations: 59 },
  { name: "Dr. Thomas Jones", Registrations: 50 },
  { name: "Dr. Laura Bloom", Registrations: 30 },
  { name: "Dr Stacy Yu", Registrations: 74 },
];

const chart_viewsVsReg = [
  {
    name: "Dec",
    Views: 10,
    Registrations: 10,
  },
  {
    name: "Jan",
    Views: 13,
    Registrations: 3,
  },
  {
    name: "Feb",
    Views: 60,
    Registrations: 3,
  },
  {
    name: "Mar",
    Views: 15,
    Registrations: 15,
  },
  {
    name: "Apr",
    Views: 150,
    Registrations: 80,
  },
  {
    name: "May",
    Views: 60,
    Registrations: 30,
  },
  {
    name: "Jun",
    Views: 10,
    Registrations: 10,
  },
  {
    name: "Jul",
    Views: 74,
    Registrations: 26,
  },
  {
    name: "Aug",
    Views: 100,
    Registrations: 25,
  },
  {
    name: "Sep",
    Views: 110,
    Registrations: 50,
  },
  {
    name: "Oct",
    Views: 60,
    Registrations: 30,
  },
  {
    name: "Nov",
    Views: 10,
    Registrations: 4,
  },
];

const chart_onlineVsClass = [
  {
    name: "Dec",
    Online: 144,
    Classroom: 20,
  },
  {
    name: "Jan",
    Online: 34,
    Classroom: 50,
  },
  {
    name: "Feb",
    Online: 4,
    Classroom: 30,
  },

  {
    name: "Mar",
    Online: 55,
    Classroom: 26,
  },
  {
    name: "Apr",
    Online: 144,
    Classroom: 20,
  },
  {
    name: "May",
    Online: 70,
    Classroom: 2,
  },
  {
    name: "Jun",
    Online: 54,
    Classroom: 10,
  },

  {
    name: "Jul",
    Online: 55,
    Classroom: 46,
  },
  {
    name: "Aug",
    Online: 44,
    Classroom: 26,
  },
  {
    name: "Sep",
    Online: 34,
    Classroom: 10,
  },
  {
    name: "Oct",
    Online: 54,
    Classroom: 30,
  },
  {
    name: "Nov",
    Online: 5,
    Classroom: 6,
  },
];

const chart_regByOcc = [
  { name: "General Dentist", value: 59, fill: "blue" },
  { name: "Endodontist", value: 10, fill: "red" },
  { name: "Oral and Maxillofacial Surgeon", value: 2, fill: "green" },
  { name: "Oral Radiologist", value: 10, fill: "orange" },
  { name: "Orthodontist", value: 7, fill: "#82E0AA" },
  { name: "Oral Medicine / Pathology", value: 2, fill: "#800000" },
  { name: "Pediatric Dentist5", value: 3, fill: "#9B59B6" },
  { name: "Periodontist", value: 10, fill: "#717D7E" },
  { name: "Prosthodontist", value: 4, fill: "#A89112" },
  { name: "Dental Public Health", value: 14, fill: "black" },
];

const chart_regByCategory = [
  { name: "Clinic Management", value: 10, fill: "blue" },
  { name: "Endodontics", value: 10, fill: "red" },
  { name: "Hygiene", value: 20, fill: "green" },
  { name: "Orthodontics", value: 10, fill: "orange" },
  { name: "Pathology", value: 7, fill: "#82E0AA" },
  { name: "Pediatrics", value: 10, fill: "#800000" },
  { name: "Periodontics", value: 33, fill: "#9B59B6" },
  { name: "Pharmacology", value: 3, fill: "#F39C12" },
  { name: "Restorative / Prosthodontics", value: 20, fill: "#717D7E" },
  { name: "Seminar", value: 4, fill: "black" },
  { name: "Surgery", value: 14, fill: "#0E6251" },
  { name: "TMJ", value: 14, fill: "#977165" },
];

const chart_regByExperience = [
  { name: "< 5 yrs", Registrants: 50 },
  { name: "5-10 yrs", Registrants: 37 },
  { name: "10-15 yrs", Registrants: 10 },
  { name: "15-20 yrs", Registrants: 14 },
  { name: "> 20 yrs", Registrants: 7 },
];

const chart_regByCity = [
  { name: "Montreal", value: 10, fill: "#blue" },
  { name: "Vancouver", value: 10, fill: "red" },
  { name: "Ontario", value: 20, fill: "green" },
  { name: "Manitoba", value: 10, fill: "#800000" },
  { name: "New York", value: 33, fill: "#9B59B6" },
  { name: "Toronto", value: 3, fill: "#F39C12" },
];

// ------------------------------

class AnalyticsScreen extends Component {
  state = {
    wait: false,
    waitNoRender: false,
    error: null,
    statsMenuOpen: false,
    filterMenuOpen: false,
    demo: false,
  };

  // ------------------------------

  update = async (propValue) => {
    await this.setState({ ...this.state, ...propValue });
  };

  // ------------------------------

  componentDidMount = async () => {
    try {
      this.update({ error: null, wait: true });

      if (objectHasNoValues(this.props.courses)) {
        await this.props.fetchCourses();
      }

      if (objectHasNoValues(this.props.setting)) {
        await this.props.fetchSetting();
      }

      const loadAsync = [];

      loadAsync.push(this.props.fetchAnalytics());

      if (objectHasNoValues(this.props.profile)) {
        loadAsync.push(this.props.fetchProfile());
      }

      await Promise.all(loadAsync);
      await this.update({ wait: false });
    } catch (error) {
      logger.error(error);
      this.update({
        wait: false,
        error: error.message,
      });
    }
  };

  // ------------------------------

  displayCharts = () => {
    return this.props.setting.charts
      ? this.props.setting.charts.map((chartId, key) => {
          if (chartId === "reg_by_occupation") {
            return (
              <ZPie
                noDataComponent={this.noDataComponent()}
                csvfileName="registrationByOccupation.csv"
                headers={[
                  { label: "Occupation", key: "name" },
                  { label: "Registrations", key: "value" },
                ]}
                key={key}
                data={
                  this.state.demo ? chart_regByOcc : this.props.chart_regByOcc
                }
                innerRadius={70}
                title="Registration by occupation"
              />
            );
          }

          if (chartId === "views_vs_reg") {
            return (
              <ZStacked
                noDataComponent={this.noDataComponent()}
                csvfileName="levelOfSuccess.csv"
                headers={[
                  { label: "Month", key: "name" },
                  { label: "Views", key: "Views" },
                  { label: "Registrations", key: "Registrations" },
                ]}
                key={key}
                data={
                  this.state.demo
                    ? chart_viewsVsReg
                    : this.props.chart_viewsVsReg
                }
                key1="Views"
                fill1="#22937F"
                key2="Registrations"
                fill2="#8E3F8B"
                title="Level of success"
              />
            );
          }

          if (chartId === "experience_profile") {
            return (
              <ZBar
                noDataComponent={this.noDataComponent()}
                csvfileName="experienceProfile.csv"
                headers={[
                  { label: "Years of Experience", key: "name" },
                  { label: "Registrants", key: "Registrants" },
                ]}
                key={key}
                data={
                  this.state.demo
                    ? chart_regByExperience
                    : this.props.chart_regByExperience
                }
                title="Registrants Experience Profile"
              />
            );
          }

          if (chartId === "reg_by_category") {
            return (
              <ZPie
                noDataComponent={this.noDataComponent()}
                csvfileName="registrationByCategory.csv"
                headers={[
                  { label: "Category", key: "name" },
                  { label: "Registrations", key: "value" },
                ]}
                key={key}
                data={
                  this.state.demo
                    ? chart_regByCategory
                    : this.props.chart_regByCategory
                }
                innerRadius={70}
                title="Registration by category"
              />
            );
          }

          if (chartId === "online_vs_classroom") {
            return (
              <ZStacked
                noDataComponent={this.noDataComponent()}
                csvfileName="onlineVsClassroom.csv"
                headers={[
                  { label: "Month", key: "name" },
                  { label: "Online", key: "Online" },
                  { label: "Classroom", key: "Classroom" },
                ]}
                key={key}
                data={
                  this.state.demo
                    ? chart_onlineVsClass
                    : this.props.chart_onlineVsClass
                }
                key1="Online"
                fill1="#22937F"
                key2="Classroom"
                fill2="#8E3F8B"
                title="Registration Online Vs Classroom"
              />
            );
          }

          if (chartId === "reg_by_professor") {
            return (
              <ZBarVertical
                csvfileName="registrationByProfessor.csv"
                headers={[
                  { label: "Professor", key: "name" },
                  { label: "Registrations", key: "Registrations" },
                ]}
                noDataComponent={this.noDataComponent()}
                key={key}
                data={
                  this.state.demo ? chart_regByProf : this.props.chart_regByProf
                }
                title="Registrations by Professor"
                fillColor="#85C1E9"
              />
            );
          }

          if (chartId === "reg_by_location") {
            return (
              <ZPie
                noDataComponent={this.noDataComponent()}
                csvfileName="registrationByLocation.csv"
                headers={[
                  { label: "City", key: "name" },
                  { label: "Registrations", key: "value" },
                ]}
                key={key}
                data={
                  this.state.demo ? chart_regByCity : this.props.chart_regByCity
                }
                innerRadius={70}
                title="Registration by location"
              />
            );
          }

          return null;
        })
      : [];
  };

  // ------------------------------

  statsDrawerClosed = (newCharts) => {
    this.update({ statsMenuOpen: false });

    const oldCharts = this.props.setting.charts
      ? this.props.setting.charts
      : [];
    const isEqual = compareArray([...oldCharts], [...newCharts]);

    if (!isEqual) {
      this.props.updateCharts(newCharts);
    }
  };

  // ------------------------------

  filterDrawerClosed = async (newDate, newCourseId) => {
    try {
      await this.update({ filterMenuOpen: false });
      const oldDate = this.props.setting.analytics_date;
      const oldCourseId = this.props.setting.analytics_courseId;

      if (!oldCourseId || oldCourseId !== newCourseId) {
        await this.props.updateAnalyticsCourse(newCourseId ? newCourseId : "");
      }

      if (!oldDate || oldDate.getTime() !== newDate.getTime()) {
        this.update({ error: null, waitNoRender: true });
        await this.props.updateAnalyticsDate(newDate);
        await this.props.fetchAnalytics();
      }

      if (!oldCourseId || oldCourseId !== newCourseId) {
        await this.props.filterByCourse(newCourseId ? newCourseId : "");
      }

      await this.update({ waitNoRender: false });
    } catch (error) {
      logger.error(error);
      this.update({
        waitNoRender: false,
        error: error.message,
      });
    }
  };

  // ------------------------------

  switchDataHandler = () => {
    this.update({ demo: !this.state.demo });
  };

  // ------------------------------

  renderDateRange = () => {
    const dateRange = getDateRange(this.props.setting.analytics_date, 366);

    return (
      <div className={styles.dateCard}>
        <div className={styles.dateItemCard}>
          <div className={styles.dateTotalContainer}>
            <div className={styles.analyticsTitleContainer}>
              <AssessmentIcon />{" "}
              <span style={{ paddingLeft: 5, paddingBottom: 1 }}>
                Analytics between {momentShortDate(dateRange.startDate)} -{" "}
                {momentShortDate(dateRange.endDate)}
              </span>
            </div>

            <div className={styles.analyticsCourseTitleContainer}>
              {this.props.selectedCourse ? (
                <div className={styles.analyticsCourseTitle}>
                  (Filtered on {this.props.selectedCourse.course_title})
                </div>
              ) : (
                <div className={styles.analyticsCourseTitle}>(All courses)</div>
              )}
            </div>
          </div>

          <div className={styles.fabButtons}>
            <Tooltip title="Settings" aria-label="Settings">
              <Fab
                color="primary"
                aria-label="settings"
                onClick={() => {
                  this.update({ filterMenuOpen: true });
                }}
              >
                <TuneIcon />
              </Fab>
            </Tooltip>
            &nbsp;
            <Tooltip title="Charts" aria-label="Charts">
              <Fab
                color="primary"
                aria-label="charts"
                onClick={() => {
                  this.update({ statsMenuOpen: true });
                }}
              >
                <EqualizerOutlinedIcon />
              </Fab>
            </Tooltip>
            &nbsp;
            {/**<Tooltip
              title={this.state.demo ? "Live" : "Demo"}
              aria-label={this.state.demo ? "Live" : "Demo"}
            >
              <Fab
                color={this.state.demo ? "secondary" : "primary"}
                aria-label="demo"
                onClick={this.switchDataHandler}
              >
                <SwapHorizontalCircleIcon />
              </Fab>
            </Tooltip>**/}
          </div>
        </div>
      </div>
    );
  };

  // ------------------------------

  noDataComponent = () => {
    return (
      <div className={styles.notFound}>
        <img src={analyticsImg} alt="" />
        <div className={styles.notFoundText}>
          No data, analytics will appear as users interact with your courses.
        </div>
      </div>
    );
  };

  // ------------------------------

  render() {
    if (this.state.wait) {
      return <Spinner />;
    }

    return (
      <div className={styles.screen}>
        {this.state.waitNoRender && <Spinner />}
        <StatsDrawer
          charts={CHARTS}
          selectedCharts={
            this.props.setting.charts ? this.props.setting.charts : []
          }
          open={this.state.statsMenuOpen}
          close={this.statsDrawerClosed}
        />
        <FilterDrawer
          date={this.props.setting.analytics_date}
          courseId={this.props.setting.analytics_course}
          selectedCourse={this.props.selectedCourse}
          selectCourses={
            this.props.selectCourses ? this.props.selectCourses : []
          }
          open={this.state.filterMenuOpen}
          close={this.filterDrawerClosed}
        />

        <div className={styles.fabAndDateContainer}>
          {this.renderDateRange()}
        </div>

        <InfoModal
          isErrorModal={true}
          title="An error has occured"
          message={this.state.error}
          open={this.state.error !== null}
          onClose={() => {
            this.update({ error: null });
          }}
        />
        {this.displayCharts()}
      </div>
    );
  }
}

// ------------------------------

const buildCourseSelect = (courses) => {
  return courses
    ? courses.map((c) => {
        return {
          value: c.id,
          label: c.title,
        };
      })
    : null;
};

// ------------------------------

const mapStateToProps = (state) => {
  const courseIdFilter = state.setting.setting.analytics_course;
  let selectedCourse = null;
  if (courseIdFilter) {
    selectedCourse = {
      course_id: courseIdFilter,
      course_title: findTitle(state.courses.courses, courseIdFilter),
    };
  }

  return {
    courses: state.courses.courses,
    selectedCourse: selectedCourse,
    selectCourses: buildCourseSelect(state.courses.courses),
    profile: state.profile.profile,
    setting: state.setting.setting,
    chart_viewsVsReg: state.analytics.chart_viewsVsReg,
    chart_onlineVsClass: state.analytics.chart_onlineVsClass,
    chart_regByOcc: state.analytics.chart_regByOcc,
    chart_regByExperience: state.analytics.chart_regByExperience,
    chart_regByCategory: state.analytics.chart_regByCategory,
    chart_regByCity: state.analytics.chart_regByCity,
    chart_regByProf: state.analytics.chart_regByProf,
  };
};

// ------------------------------

const mapDispatchToProps = (dispatch) => {
  return {
    fetchSetting: () => dispatch(settingActions.fetchSetting()),
    fetchProfile: () => dispatch(profileActions.fetchProfile()),
    fetchAnalytics: () => dispatch(analyticsActions.fetchAnalytics()),
    filterByCourse: (courseId) =>
      dispatch(analyticsActions.filterByCourse(courseId)),
    updateCharts: (charts) => dispatch(settingActions.updateCharts(charts)),
    updateAnalyticsDate: (date) =>
      dispatch(settingActions.updateAnalyticsDate(date)),
    updateAnalyticsCourse: (courseId) =>
      dispatch(settingActions.updateAnalyticsCourse(courseId)),
    fetchCourses: () => dispatch(courseActions.fetchCourses()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AnalyticsScreen);
