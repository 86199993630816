import React from "react";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import { alpha, makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  bar: {
    position: "fixed",
    top: 57,
    background: "#CCC",
    zIndex: 115,
  },
  bar2: {
    background: "#CCC",
    zIndex: 115,
  },
}));

const MatAppBar = (props) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <AppBar
        position={props.static ? "static" : "fixed"}
        className={props.noFixed ? classes.bar2 : classes.bar}
        style={props.classBar ? props.classBar : null}
      >
        <Toolbar>
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <div>{props.left}</div>
            <div style={{ flex: 1 }}>{props.right}</div>
          </div>
        </Toolbar>
      </AppBar>
    </div>
  );
};

export default MatAppBar;
