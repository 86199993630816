import React from "react";
import { useTranslation } from "react-i18next";
import styles from "./CourseDetailsScreen.module.css";
import NumberFormat from "react-number-format";
import Fab from "@material-ui/core/Fab";
import { resolveCurrency, translate } from "../../../globals/Common";
import ApartmentIcon from "@material-ui/icons/Apartment";
import LibraryBooksIcon from "@material-ui/icons/LibraryBooks";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import BlockIcon from "@material-ui/icons/Block";
import LanguageIcon from "@material-ui/icons/Language";
import ProviderItem from "../../../components/items/providerItem/ProviderItem";

// ------------------------------

const AddToCartBubble = (props) => {
  const { t } = useTranslation();

  return (
    <div className={styles.bubble}>

      {props.provider && (
        <div style={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          alignItem: "center",
          justifyContent: "center"
        }}>
          <ProviderItem
            readOnly
            addToCart
            id={props.provider.id}
            name={props.provider.name}
            logo={props.provider.logoCached}
            country={props.provider.country}
            currency={props.provider.currency}
            onContact={() => { }}
            contact={false}
            center={true}
          />
        </div>
      )}


      <div className={styles.princeRange}>
        <NumberFormat
          value={resolveCurrency(
            Number(props.course.lowest_cost()),
            props.course.provider_currency,
            props.rates
          )}
          displayType={"text"}
          thousandSeparator={true}
          prefix={props.currency ? props.currency.shortName : "CAD"}
        />
      </div>

      <div className={styles.courseDetails}>
        <div>
          <div className={styles.listItem}>
            <LanguageIcon style={{ color: "green", fontSize: "17px" }} />
            &nbsp;{translate(t, props.course.language)}
          </div>
          <div className={styles.listItem}>
            <CheckCircleIcon style={{ color: "green", fontSize: "17px" }} />
            &nbsp;{props.course.resolve_ce()} {t("g_credits")}
          </div>
          <div className={styles.listItem}>
            <ApartmentIcon style={{ color: "green", fontSize: "17px" }} />
            &nbsp;{props.course.accreditation.join(", ")}
          </div>
          <div className={styles.listItem}>
            <LibraryBooksIcon style={{ color: "green", fontSize: "17px" }} />
            &nbsp;{t("course_details_certificate_completion")}
          </div>

          {props.courseExpired && (
            <div className={styles.listItem} style={{ paddingTop: "3px" }}>
              <BlockIcon style={{ color: "red", fontSize: "17px" }} />
              &nbsp;{t("course_reg_closed")}
            </div>
          )}
        </div>
      </div>

      {!props.courseExpired && (
        <div className={styles.princeRange}>
          <Fab
            variant="extended"
            color="primary"
            style={{ width: "100%" }}
            onClick={props.onRegister}
          >
            {t("course_details_register_now")}
          </Fab>
        </div>
      )}





    </div>
  );
};

export default AddToCartBubble;
