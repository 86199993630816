import React from "react";
import Tooltip from "@material-ui/core/Tooltip";
import Zoom from "@material-ui/core/Zoom";

const ProviderLink = (props) => {
  return (
    <a target="_blank" href={props.url} rel="noopener noreferrer">
      <Zoom in={true} style={{ transitionDelay: true ? props.delay : "0ms" }}>
        <Tooltip title={props.name} aria-label={props.name}>
          <img src={props.image} alt={props.name} className={props.className} />
        </Tooltip>
      </Zoom>
    </a>
  );
};

export default ProviderLink;
