import React, { Component } from "react";
import { connect } from "react-redux";
import Spinner from "../../components/ui/spinner/Spinner";
import * as promoActions from "../../store/actions/promo";
import * as courseActions from "../../store/actions/courses";
import * as profileActions from "../../store/actions/profile";
import MatAppBar from "../../components/ui/appBar/MatAppBar";
import Button from "@material-ui/core/Button";
import RedeemIcon from "@material-ui/icons/Redeem";
import PromoItem from "../../components/items/promoItem/PromoItem";
import ConfirmModal from "../../components/modals/confirmModal/ConfirmModal";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import { findTitle } from "../../globals/Common";
import Empty from "../../components/ui/empty/Empty";
import promocode from "../../assets/images/inApp/promocode.png";
import * as logger from "../../globals/Logger";
import InfoModal from "../../components/modals/infoModal/InfoModal";

class PromoScreen extends Component {
  state = {
    wait: false,
    waitNoRender: false,
    waitMessage: "",
    error: null,
    openDeleteConfirmModal: false,
    idToBeDeleted: null,
    success: null,
  };

  // ------------------------------

  update = async (propValue) => {
    await this.setState({ ...this.state, ...propValue });
  };

  // ------------------------------

  componentDidMount = async () => {
    try {
      await this.update({ error: null, wait: true });

      const loadAsync = [];


      loadAsync.push(this.props.fetchCourses());

      if (!this.props.profile || Object.keys(this.props.profile).length === 0) {
        loadAsync.push(this.props.fetchProfile());
      }

      loadAsync.push(this.props.fetchPromo());

      await Promise.all(loadAsync);

      await this.update({ wait: false });
    } catch (error) {
      logger.error(error);
      await this.update({ wait: false, error: error.message });
    }
  };

  // ------------------------------

  addHandler = async () => {
    this.props.setAddAction();
    this.props.history.push("/editPromo");
  };

  // ------------------------------

  editHandler = (id) => {
    this.props.setEditAction(id);
    this.props.history.push("/editPromo");
  };

  // ------------------------------

  deleteHandler = (id) => {
    this.update({
      openDeleteConfirmModal: true,
      idToBeDeleted: id,
    });
  };

  // ------------------------------

  deleteConfirmHandler = async (answer) => {
    if (answer === "ok") {
      try {
        await this.update({
          wait: true,
          openDeleteConfirmModal: false,
        });

        await this.props.removePromo(this.state.idToBeDeleted);

        await this.update({
          wait: false,
          idToBeDeleted: null,
        });
      } catch (error) {
        logger.error(error);
        this.update({
          wait: false,
          error: error.message,
        });
      }
    } else {
      this.update({
        openDeleteConfirmModal: false,
        idToBeDeleted: null,
      });
    }
  };

  // ------------------------------

  emailPromoCode = async (codeId, email) => {
    try {
      await this.update({
        error: null,
        waitNoRender: true,
        waitMessage: "Sending",
      });

      await this.props.emailPromoCode(codeId, email);

      await this.update({
        success: `The promo code ${codeId} has been resent to: ${email}`,
        waitNoRender: false,
        waitMessage: "",
      });
    } catch (error) {
      logger.error(error);
      await this.update({
        error: error.message,
        waitNoRender: false,
        waitMessage: "",
      });
    }
  };

  // ------------------------------

  renderPromoItems = () => {
    return this.props.promo.map((item, index) => {
      return (
        <PromoItem
          key={index}
          promo_code={item.promo_code}
          discount={item.discount}
          usageLimit={item.usageLimit}
          used_count={item.used_count}
          courses={this.resolveTitles(item.courses)}
          users={item.users}
          start_date={item.startDate()}
          end_date={item.endDate()}
          edit={this.editHandler.bind(this, item.id)}
          delete={this.deleteHandler.bind(this, item.id)}
          onEmailPromoCode={async (email) => {
            await this.emailPromoCode(item.id, email);
          }}
        />
      );
    });
  };

  // ------------------------------

  resolveTitles = (arrCourseId) => {
    return arrCourseId.map((cId) => findTitle(this.props.courses, cId));
  };

  // ------------------------------

  render() {
    if (this.state.wait) {
      return <Spinner />;
    }

    if (!this.props.promo || this.props.promo.length === 0) {
      return (
        <Empty
          column
          image={promocode}
          message={
            <p>
              Create a promo code for your courses. Specify whether applicable
              to all or specific courses and / or users. Set the expiration
              dates to control when the pomo begins and ends.
            </p>
          }
          onClick={this.addHandler}
          buttonIcon={<RedeemIcon />}
          buttonLabel={"Add Promo Code"}
        />
      );
    }

    return (
      <div style={{ backgroundColor: "white" }}>
        {this.state.waitNoRender && (
          <Spinner message={this.state.waitMessage} />
        )}

        <ConfirmModal
          title="Confirmation Required"
          message={"Are you sure you wish to remove this promo code?"}
          open={this.state.openDeleteConfirmModal}
          onClose={this.deleteConfirmHandler}
        />

        {this.state.success !== null && (
          <InfoModal
            isErrorModal={false}
            title={"Email Sent"}
            message={this.state.success}
            open={true}
            onClose={() => {
              this.update({ success: null });
            }}
          />
        )}

        <MatAppBar
          right={
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <Button
                color="inherit"
                style={{ color: "black" }}
                onClick={this.addHandler}
                startIcon={<AddCircleIcon />}
              >
                New
              </Button>
            </div>
          }
        />
        <div style={{ height: "58px" }}></div>
        {this.renderPromoItems()}
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    action: props.history.action,
    promo: state.promo.promo,
    courses: state.courses.courses,
    profile: state.profile.profile,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setAddAction: () => dispatch(promoActions.setAddAction()),
    setEditAction: (id) => dispatch(promoActions.setEditAction(id)),
    fetchPromo: () => dispatch(promoActions.fetchPromo()),
    removePromo: (id) => dispatch(promoActions.removePromo(id)),
    fetchCourses: () => dispatch(courseActions.fetchCourses()),
    fetchProfile: () => dispatch(profileActions.fetchProfile()),
    emailPromoCode: (codeId, email) =>
      dispatch(promoActions.emailPromoCode(codeId, email)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PromoScreen);
