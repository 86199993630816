import React, { Fragment } from "react";
import styles from "./Amendment.module.css";
import MatSelectOutline from "../../components/ui/labelSelect/MatSelectOutline";
import MatInputOutline from "../../components/ui/input/MatInputOutline";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import FlatList from "flatlist-react";
import PricingItem from "../../components/items/pricingItem/PricingItem";
import InfoIcon from "@material-ui/icons/Info";
import WarningIcon from "@material-ui/icons/Warning";
import DateSelect from "../../components/ui/dateSelect/DateSelect";
import { buildKeyValueSelectProf } from "../../globals/Common";
import {
  FNS_MEDIUM_DATE_FORMAT,
  MAX_COURSE_DATE_LIMIT,
} from "../../globals/Dates";
import { momentLongDate, addDays, newLocalDate } from "../../globals/Dates2";

const AmendmentForm = (props) => {
  // ------------------------------

  const resolveProfessorFullBio = (id) => {
    return props.professorList.find((p) => p.id === id);
  };

  // ------------------------------

  const inputChangeHandler = (e) => {
    const newAmendment = { ...props.amendment };
    newAmendment[e.target.name] = e.target.value;
    props.update(newAmendment);
  };

  // ------------------------------

  const buildProfessorOptions = (allProf, courseProf) => {
    const professor = [];
    for (const prof of allProf) {
      const indexOf = courseProf.findIndex((p) => prof.id === p.id);

      if (indexOf === -1) {
        professor.push(prof);
      }
    }
    return buildKeyValueSelectProf(professor, "id");
  };

  // ------------------------------

  const multiProfessorChangeHandler = (e, select) => {
    const newAmendment = { ...props.amendment };
    const professorValues = [];

    for (const prof of select) {
      professorValues.push(resolveProfessorFullBio(prof.value));
      //{ id: prof.value, name: prof.label });
    }

    newAmendment.professor = professorValues;
    props.update(newAmendment);
  };

  // ------------------------------

  const renderPricingItem = (item, index) => {
    return (
      <PricingItem
        currency={props.currency}
        key={index}
        onClick={() => {}}
        title={"Registration Option #" + (index + 1)}
        casting={item.casting}
        audience={item.audience}
        component={item.component}
        description={item.description}
        ce_odq={item.resolve_odq()}
        credit_lecture_hours={item.credit_lecture_hours}
        credit_handson_hours={item.credit_handson_hours}
        cost={item.resolveCost(props.course.amendment, index)}
        expiration_date={item.expirationDate()}
        discount={props.amendment.discount[index]}
        onDiscount={(discount) => {
          const newAmendment = { ...props.amendment };
          const arrDiscount = [...props.amendment.discount];

          if (discount === 0) arrDiscount.splice(index, 1);
          else arrDiscount[index] = discount;

          newAmendment.discount = arrDiscount;
          props.update(newAmendment);
        }}
      />
    );
  };

  // ------------------------------

  return (
    <form className={styles.form}>
      <div className={styles.pricingContainer}>
        <div className={styles.icon}>
          <WarningIcon style={{ color: "orange" }} />
        </div>
        <div>
          You can amend to an active course for a maximum of three times. You
          can preview your amendment before activating it on the Zubu app. Also
          note that registered students will be notified by email that an
          amendment occured.
        </div>
      </div>

      {!props.course.is_video && (
        <div className={styles.sectionTitle} onClick={props.setShowProf}>
          <IconButton onClick={props.setShowProf}>
            <EditIcon style={{ color: "white" }} />
          </IconButton>{" "}
          Amend Professor
        </div>
      )}

      {props.showProf && (
        <Fragment>
          <div className={styles.pricingContainer}>
            <div className={styles.icon}>
              <InfoIcon />
            </div>
            <div>
              Amend a new professor to your course. To replace an existing
              professor, select your replacement then add a note indicating the
              original professor will no longer be presenting.
            </div>
          </div>

          <div className={styles.pricingContainer}>
            <div className={styles.currentProfessor}>
              Active Course Professor(s): {props.course.renderProfessor()}
            </div>
          </div>

          <MatSelectOutline
            noTopBottomMargin
            title="Professor"
            placeholder="Enter professor name..."
            name="professor"
            id="professor"
            onChange={multiProfessorChangeHandler}
            options={buildProfessorOptions(
              props.professorList,
              props.course.professor
            )} // comming from store saved professors
            value={buildKeyValueSelectProf(props.amendment.professor, "id")}
            getOptionLabel={(option) => option.label}
            getOptionSelected={(opt1, opt2) => opt1.value === opt2.value}
            disabled={!props.isInEdit}
            multiple={true}
            required={true}
          />
        </Fragment>
      )}

      <div className={styles.sectionTitle} onClick={props.setShowNotes}>
        <IconButton onClick={props.setShowNotes}>
          <EditIcon style={{ color: "white" }} />
        </IconButton>{" "}
        Amend Notes
      </div>

      {props.showNotes && (
        <Fragment>
          <div className={styles.pricingContainer}>
            <div className={styles.icon}>
              <InfoIcon />
            </div>
            <div>
              Notes can be used to amend any information relavant to the course.
              Do not use notes to indicate price changes / a start date
              modification or a professor as the information will not be
              reflected on the ZubU app for checkout / searches / or visible
              professor biography.
            </div>
          </div>

          <MatInputOutline
            nobottommargin="true"
            title="Notes"
            name="notes"
            rows="4"
            multiline
            value={props.amendment.notes}
            onChange={inputChangeHandler}
            maxLength={400}
            readOnly={!props.isInEdit}
          />
        </Fragment>
      )}

      {!props.course.is_video && (
        <div className={styles.sectionTitle} onClick={props.setShowDate}>
          <IconButton onClick={props.setShowDate}>
            <EditIcon style={{ color: "white" }} />
          </IconButton>{" "}
          Amend Start Date
        </div>
      )}

      {props.showDate && (
        <Fragment>
          <div className={styles.pricingContainer}>
            <div className={styles.icon}>
              <InfoIcon />
            </div>
            <div>
              The new starting date of your course must take place after the
              original start date:{" "}
              <b>{momentLongDate(props.course.start_date[0])}</b>.
            </div>
          </div>

          <div className={styles.pricingContainer}>
            <div className={styles.icon}>
              <WarningIcon style={{ color: "orange" }} />
            </div>
            <div>
              Amending the course start date will notify all registered
              students. They will be given the option to request a full refund
              within 72 hours or accept the new start date. The refund request
              must be honored reguardless of your cancellation policy.
              <i style={{ color: "red" }}>
                &nbsp;&nbsp;If you wish to change additional information to the
                course itinerary please do so in the amended notes.
              </i>
            </div>
          </div>

          <div className={styles.pricingContainer}>
            <DateSelect
              isClearable
              title="Date"
              selected={
                props.amendment.start_date ? props.amendment.start_date : null
              }
              dateFormat={FNS_MEDIUM_DATE_FORMAT}
              minDate={addDays(
                props.course.start_date[0]
                  ? props.course.start_date[0]
                  : new Date(),
                1
              )}
              maxDate={addDays(new Date(), MAX_COURSE_DATE_LIMIT)}
              onChange={(date) => {
                const newAmend = { ...props.amendment };
                newAmend.start_date = date ? newLocalDate(date) : null;
                props.update(newAmend);
              }}
            />
          </div>
        </Fragment>
      )}

      <div className={styles.sectionTitle} onClick={props.setShowPrice}>
        <IconButton onClick={props.setShowPrice}>
          <EditIcon style={{ color: "white" }} />
        </IconButton>{" "}
        Amend Discount
      </div>

      {props.showPrice && (
        <Fragment>
          <div className={styles.pricingContainer}>
            <div className={styles.icon}>
              <InfoIcon />
            </div>
            <div>
              Minimum&nbsp;
              <b>{props.currency.shortName}35</b> or&nbsp;
              <b>{props.currency.shortName}0</b> for free course.
            </div>
          </div>
          <div className={styles.regOptionsContainer}>
            <FlatList
              renderWhenEmpty={() => {
                return <div></div>;
              }}
              list={props.course.pricingItems}
              renderItem={renderPricingItem}
            />
          </div>
        </Fragment>
      )}
    </form>
  );
};

export default AmendmentForm;
