import React, { useState, useRef, useEffect } from "react";
import styles from "./VideoChapterModal.module.css";
import { useTranslation } from "react-i18next";
import Button from "@material-ui/core/Button";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Collapse from "@material-ui/core/Collapse";
import ReactPlayer from "react-player";
import MatInputOutline from "../../../components/ui/input/MatInputOutline";
import VideoChapter from "../../../model/VideoChapter"

const VideoChapterModal = (props) => {
    const { t } = useTranslation();
    const mobileSize = useMediaQuery("(max-width:599px)");
    const fullWidth = true;
    const maxWidth = "md";

    const playerElement = useRef();
    const [uri, setUri] = useState(props.selected ? props.selected.uri : "");
    const [title, setTitle] = useState(props.selected ? props.selected.title : "");
    const [description, setDescription] = useState(props.selected ? props.selected.description : "");


    useEffect(() => {
        setUri(props.selected ? props.selected.uri : "");
        setTitle(props.selected ? props.selected.title : "");
        setDescription(props.selected ? props.selected.description : "");
    }, [props.selected]);


    const inputChangeHandler = (e) => {
        if (e.target.name === "uri") {
            setUri(e.target.value);
        }
        else if (e.target.name === "title") {
            setTitle(e.target.value);
        }
        else if (e.target.name === "description") {
            setDescription(e.target.value);
        }
    };

    const saveHandler = () => {
        const seconds = playerElement.current.getDuration()
        props.onClose(new VideoChapter(
            props.selected && props.selected.id ? props.selected.id : new Date().getTime().toString(),
            uri,
            title,
            description,
            seconds,
            -1)
        );
        setUri("");
        setTitle("");
        setDescription("");
    };

    const cancelHandler = () => {
        setUri("");
        setTitle("");
        setDescription("");
        props.onClose(null);
    };

    return (
        <Dialog
            fullWidth={fullWidth}
            maxWidth={maxWidth}
            open={props.open}
            onClose={cancelHandler}
            aria-labelledby="responsive-dialog-title"
        >
            <DialogTitle id="alert-dialog-title">
                <div>Add - Edit Video Chapter</div>
            </DialogTitle>

            <DialogContent>
                <div>
                    <MatInputOutline
                        nomargin="true"
                        title="Video URL (youtube, vimeo, twitch, facebook)"
                        name="uri"
                        rows="1"
                        value={uri}
                        onChange={inputChangeHandler}
                        maxLength={200}
                        readOnly={false}
                        required={true}
                    />

                    <Collapse in={uri !== null && uri !== ""}>
                        <div className={styles.playerContainer} style={mobileSize ? { flexDirection: "column" } : null}>
                            <div className={styles.player}>
                                <ReactPlayer
                                    ref={playerElement}
                                    style={{ borderRadius: "10px", overflow: "hidden" }}
                                    url={uri}
                                    controls={true}
                                    muted={true}
                                    config={{
                                        vimeo: { playerOptions: { controls: false } },
                                        file: { attributes: { controlsList: "nodownload" } }
                                    }}
                                    onContextMenu={(e) => e.preventDefault()}
                                    width="100%"
                                    height="100%"
                                />
                            </div>

                            <div className={styles.chapterInfo} style={mobileSize ? { marginLeft: "0px" } : null}>
                                <MatInputOutline
                                    nomargin="true"
                                    title="Title"
                                    name="title"
                                    rows="1"
                                    value={title}
                                    onChange={inputChangeHandler}
                                    maxLength={200}
                                    readOnly={false}
                                    required={true}
                                />
                                <MatInputOutline
                                    nomargin="true"
                                    title="Description"
                                    name="description"
                                    rows="6"
                                    multiline
                                    value={description}
                                    onChange={inputChangeHandler}
                                    maxLength={600}
                                    readOnly={false}
                                    required={false}
                                />
                            </div>
                        </div>
                    </Collapse>
                </div>
            </DialogContent>

            <DialogActions>
                <Button autoFocus onClick={cancelHandler}>
                    {t("g_cancel")}
                </Button>
                <Button autoFocus onClick={saveHandler} color="primary">
                    {t("g_save")}
                </Button>
            </DialogActions>

        </Dialog>
    );
};

export default VideoChapterModal;