import { handleErrorResponse } from "../../globals/Error";
import { dbName } from "../../globals/ApiKeys";
import {
  MONTHS_SHORT,
  selectCategories,
  selectSpecialist,
  COLORS,
} from "../../globals/Data";
import { getDateRange } from "../../globals/Dates2";
export const SET_ANALYTICS = "SET_ANALYTICS";
export const SET_AD_ANALYTICS = "SET_AD_ANALYTICS";

// ------------------------------

export const fetchAdViews = () => {
  return async (dispatch, getState) => {
    const userId = getState().auth.userId;
    const token = getState().auth.token;
    const month = getDateRange(new Date(), 31); // ads only run for a month

    const response = await fetch(
      `https://${dbName}.firebaseio.com/event/${userId}.json?auth=${token}?&orderBy="dateTime"&startAt=${month.startDate.getTime()}&endAt=${month.endDate.getTime()}`
    );

    if (!response.ok) {
      handleErrorResponse(await response.json());
    }

    const resData = await response.json();
    const adViews = buildAdViews(resData);

    dispatch({
      type: SET_AD_ANALYTICS,
      adViews: adViews,
    });
  };
};

// ------------------------------

const buildAdViews = (analytics) => {
  const views = [];

  const keys = Object.keys(analytics);
  for (const key of keys) {
    const childEvent = analytics[key];
    if (
      childEvent.event === "COURSE_VIEW" &&
      (childEvent.source.startsWith("Ad:") ||
        childEvent.source.startsWith("Featured:"))
    ) {
      const typeAdId = childEvent.source.split(":");
      const indexOfAd = views.findIndex((view) => view.adId === typeAdId[1]);

      if (indexOfAd === -1) {
        views.push({ adId: typeAdId[1], views: 1 });
      } else {
        const copy = { ...views[indexOfAd] };
        copy.views += 1;
        views[indexOfAd] = copy;
      }
    }
  }

  return views;
};

// ------------------------------

export const fetchAnalytics = () => {
  return async (dispatch, getState) => {
    let anaDate = getState().setting.setting.analytics_date;

    if (!anaDate) {
      anaDate = new Date();
    }

    const dateRange = getDateRange(anaDate, 366);

    const courseFilterId = getState().setting.setting.analytics_course;

    const userId = getState().auth.userId;
    const token = getState().auth.token;
    const courses = getState().courses.courses;
    const courses_history = getState().courses.courses_history;
    const allCoures = courses.concat(courses_history);

    const response = await fetch(
      `https://${dbName}.firebaseio.com/event/${userId}.json?auth=${token}?&orderBy="dateTime"&startAt=${dateRange.startDate.getTime()}&endAt=${dateRange.endDate.getTime()}`
    );

    if (!response.ok) {
      handleErrorResponse(await response.json());
    }

    const resData = await response.json();
    const viewsAndReg = splitViewsAndRegistrations(resData);
    const charts = filterCourse(viewsAndReg, allCoures, courseFilterId);

    dispatch({
      type: SET_ANALYTICS,
      viewsAndReg: viewsAndReg,
      chart: charts,
    });
  };
};

// ------------------------------

export const filterByCourse = (filterCourseId) => {
  return async (dispatch, getState) => {
    const viewsAndReg = getState().analytics.viewsAndReg;
    const courses = getState().courses.courses;
    const courses_history = getState().courses.courses_history;
    const allCourses = courses.concat(courses_history);
    const charts = filterCourse(viewsAndReg, allCourses, filterCourseId);

    dispatch({
      type: SET_ANALYTICS,
      viewsAndReg: viewsAndReg,
      chart: charts,
    });
  };
};

// ------------------------------

const filterCourse = (viewsAndReg, allCourses, filterCourseId) => {
  if (filterCourseId) {
    const viewsAndRegFilterd = {
      views: viewsAndReg.views.filter((vr) => vr.courseId === filterCourseId),
      regs: viewsAndReg.regs.filter((vr) => vr.courseId === filterCourseId),
    };
    return buildCharts(viewsAndRegFilterd, allCourses);
  }

  return buildCharts(viewsAndReg, allCourses);
};

// ------------------------------

const buildCharts = (viewsAndReg, courses) => {
  // by date and attribute
  const chart_viewsVsReg = viewVsReg(viewsAndReg);

  const chart_onlineVsClass = onlineVsClassroom(viewsAndReg.regs);

  // user related
  const chart_regByOcc = regByOccupation(viewsAndReg.regs);
  const chart_regByExperience = regByExperience(viewsAndReg.regs);
  const chart_regByCity = regByCity(viewsAndReg.regs);

  // course related
  const chart_regByCategory = regByCategory(viewsAndReg.regs, courses);
  const chart_regByProf = regByProfessor(viewsAndReg.regs, courses);

  return {
    chart_viewsVsReg: chart_viewsVsReg,
    chart_onlineVsClass: chart_onlineVsClass,
    chart_regByOcc: chart_regByOcc,
    chart_regByExperience: chart_regByExperience,
    chart_regByCity: chart_regByCity,
    chart_regByCategory: chart_regByCategory,
    chart_regByProf: chart_regByProf,
  };
};

// ------------------------------

const splitViewsAndRegistrations = (analytics) => {
  const views = [];
  const regs = [];

  const keys = Object.keys(analytics);
  for (const key of keys) {
    const childEvent = analytics[key];
    if (childEvent.event === "COURSE_REG") {
      regs.push(childEvent);
    } else if (childEvent.event === "COURSE_VIEW") {
      views.push(childEvent);
    }
  }

  return { views: views, regs: regs };
};

// ------------------------------

const regByOccupation = (eventList) => {
  return countByEventAttribute([], eventList, "occupation", "value");
};

// ------------------------------

const regByExperience = (eventList) => {
  return countByEventAttribute(
    [],
    resolveExperience(eventList),
    "experience",
    "Registrants"
  );
};

// ------------------------------

const regByCity = (eventList) => {
  return countByEventAttribute([], eventList, "city", "value");
};

// ------------------------------

const resolveExperience = (eventList) => {
  const resolvedArr = [];

  const sortAsc = eventList.sort((a, b) =>
    a.experience < b.experience ? 1 : -1
  );

  for (const event of sortAsc) {
    const cpyEvent = { ...event };

    if (event.experience < 5) {
      cpyEvent.experience = "< 5 yrs";
    } else if (event.experience < 10) {
      cpyEvent.experience = "5-10 yrs";
    } else if (event.experience < 15) {
      cpyEvent.experience = "10-15 yrs";
    } else if (event.experience < 20) {
      cpyEvent.experience = "15-20 yrs";
    } else if (event.experience > 20) {
      cpyEvent.experience = "> 20 yrs";
    } else cpyEvent.experience = "N/A";

    resolvedArr.push(cpyEvent);
  }

  return resolvedArr;
};

// ------------------------------

const viewVsReg = (viewsAndReg) => {
  const data = [];

  return countByDate(
    countByDate(data, viewsAndReg.views, "dateTime", "Views"),
    viewsAndReg.regs,
    "dateTime",
    "Registrations"
  );
};

// ------------------------------

const onlineVsClassroom = (eventList) => {
  const data = [];
  const onlineEventList = eventList.filter((e) => e.casting !== "Classroom");
  const classroomEventList = eventList.filter((e) => e.casting === "Classroom");

  return countByDate(
    countByDate(data, onlineEventList, "dateTime", "Online"),
    classroomEventList,
    "dateTime",
    "Classroom"
  );
};

// ------------------------------

const countByEventAttribute = (chartData, listOfEvents, countKey, valueKey) => {
  for (const anEvent of listOfEvents) {
    const indexOfAttribute = chartData.findIndex(
      (cd) => cd.name === anEvent[countKey]
    );

    if (indexOfAttribute === -1) {
      let newCount = { name: anEvent[countKey] };
      newCount[valueKey] = 1;
      newCount.fill = COLORS[chartData.length];
      chartData.push(newCount);
    } else {
      const copy = { ...chartData[indexOfAttribute] };
      copy[valueKey] += 1;
      chartData[indexOfAttribute] = copy;
    }
  }

  return chartData;
};

// ------------------------------

const countByDate = (chartData, listOfEvents, dateKey, countKey) => {
  const sortedEvents = listOfEvents.sort((a, b) =>
    new Date(a[dateKey]).getTime() > new Date(b[dateKey]).getTime() ? 1 : -1
  );

  for (const anEvent of sortedEvents) {
    const month = MONTHS_SHORT[new Date(anEvent[dateKey]).getMonth()];
    const indexOfMonth = chartData.findIndex((cd) => cd.name === month);

    if (indexOfMonth === -1) {
      let newCount = { name: month };
      newCount[countKey] = 1;
      chartData.push(newCount);
    } else {
      const copy = { ...chartData[indexOfMonth] };
      copy[countKey] = copy[countKey] ? copy[countKey] + 1 : 1;
      chartData[indexOfMonth] = copy;
    }
  }

  return chartData;
};

// ------------------------------

const regByCategory = (listOfEvents, courses) => {
  const explodedEventList = explodeCategoryOnEvent(listOfEvents, courses);
  return countByEventAttribute([], explodedEventList, "category", "value");
};

// ------------------------------

const regByProfessor = (listOfEvents, courses) => {
  const explodedEventList = explodeProfessorOnEvent(listOfEvents, courses);
  return countByEventAttribute(
    [],
    explodedEventList,
    "professor",
    "Registrations"
  );
};

// ------------------------------

const explodeCategoryOnEvent = (listOfEvents, courses) => {
  const exploded = [];
  for (const anEvent of listOfEvents) {
    const course = courses.find((c) => c.id === anEvent.courseId);

    if (course) {
      const categoryArr = course.category;
      for (const cat of categoryArr) {
        const catSelect = selectCategories.find((sc) => sc.value === cat);

        if (catSelect) {
          anEvent.category = catSelect.label;
          exploded.push(anEvent);
        } else {
          const specialistSelect = selectSpecialist.find(
            (sc) => sc.value === cat
          );
          if (specialistSelect) {
            anEvent.category = specialistSelect.label;
            exploded.push(anEvent);
          }
        }
      }
    }
  }

  return exploded;
};

// ------------------------------

const explodeProfessorOnEvent = (listOfEvents, courses) => {
  const exploded = [];
  for (const anEvent of listOfEvents) {
    const course = courses.find((c) => c.id === anEvent.courseId);

    if (course) {
      const professorArr = course.professor;
      for (const prof of professorArr) {
        anEvent.professor = prof.fullName();
        exploded.push(anEvent);
      }
    }
  }

  return exploded;
};

// ------------------------------
