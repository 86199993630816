class Membership {
    constructor(
        id,
        member_id,
        first,
        last,
        start_date,
        term,
        auto_renew
    ) {
        this.id = id;
        this.member_id = member_id;
        this.first = first;
        this.last = last;
        this.start_date = start_date;
        this.term = term;
        this.auto_renew = auto_renew;
    }

    toArray = () => {
        return [
            this.first && this.last ? this.first + " " + this.last : "N/A",
            this.member_id,
            this.start_date ? this.start_date : "N/A",
            this.term ? this.term : "Yearly",
            this.auto_renew ? this.term : "N/A"
        ]
    };
}

export default Membership;
