import React from "react";
import { useTranslation } from "react-i18next";
import styles from "./StaffItem.module.css";
import PopMenu from "../../../components/ui/popMenu/PopMenu";
import EditIcon from "@material-ui/icons/Edit";
import MenuItem from "@material-ui/core/MenuItem";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import TitleMenu from "../../../components/ui/titleMenu/TitleMenu";
import PersonIcon from "@material-ui/icons/Person";

const StaffItem = (props) => {
  const { t } = useTranslation();

  // ------------------------------

  const [anchorEl, setAnchorEl] = React.useState(null);

  // ------------------------------

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  // ------------------------------

  const handleClose = () => {
    setAnchorEl(null);
  };

  // ------------------------------

  const renderEdit = () => (
    <MenuItem
      onClick={() => {
        handleClose();
        props.edit();
      }}
    >
      <TitleMenu icon={<EditIcon />} title={t("g_edit")} />
    </MenuItem>
  );

  // ------------------------------

  const renderDelete = () => (
    <MenuItem
      onClick={() => {
        handleClose();
        props.delete();
      }}
    >
      <TitleMenu icon={<DeleteOutlineIcon />} title={t("g_delete")} />
    </MenuItem>
  );

  // ------------------------------

  return (
    <div className={styles.itemContainer} onClick={props.onClick.bind(this)}>
      <div className={styles.column}>
        <PersonIcon style={{ color: "#437aa1" }} />
        &nbsp;&nbsp;
        <div className={styles.text}>{props.first}&nbsp;</div>
        <div className={styles.text}>{props.last}</div>
      </div>

      <div className={styles.columnMiddle}>
        <div className={styles.text}>{props.occupation}</div>
      </div>

      <div className={styles.columnButton}>
        <PopMenu
          anchorEl={anchorEl}
          handleClick={handleClick}
          handleClose={handleClose}
        >
          {renderEdit()}
          {renderDelete()}
        </PopMenu>
      </div>
    </div>
  );
};

export default StaffItem;
