class Setting {
  constructor(
    course_view,
    language,
    show_dashboard,
    charts,
    analytics_date,
    analytics_course,
    notify_payout_paid,
    notify_livestream_capacity,
    notify_classroom_lecture_capacity,
    notify_classroom_handson_capacity,
    notify_online_video_capacity,
    livestream_capacity_limit,
    classroom_lecture_capacity_limit,
    classroom_handson_capacity_limit,
    online_video_capacity_limit,
    certificate_delivery_live,
    certificate_delivery_video,
    certificate_header,
    certificate_footer,
    student_table_display,
  ) {
    this.course_view = course_view;
    this.language = language;
    this.show_dashboard = show_dashboard;
    this.charts = charts;
    this.analytics_date = analytics_date;
    this.analytics_course = analytics_course;
    this.notify_payout_paid = notify_payout_paid;
    this.notify_livestream_capacity = notify_livestream_capacity;
    this.notify_classroom_lecture_capacity = notify_classroom_lecture_capacity;
    this.notify_classroom_handson_capacity = notify_classroom_handson_capacity;
    this.notify_online_video_capacity = notify_online_video_capacity;
    this.livestream_capacity_limit = livestream_capacity_limit;
    this.classroom_lecture_capacity_limit = classroom_lecture_capacity_limit;
    this.classroom_handson_capacity_limit = classroom_handson_capacity_limit;
    this.online_video_capacity_limit = online_video_capacity_limit;
    this.certificate_delivery_live = certificate_delivery_live;
    this.certificate_delivery_video = certificate_delivery_video;
    this.certificate_header = certificate_header;
    this.certificate_footer = certificate_footer;
    this.student_table_display = student_table_display;
  }
}

export default Setting;
