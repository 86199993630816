import React, { useState } from "react";
import styles from "./AudienceSelector.module.css";
import LocationInput from "../../../components/ui/addressSearch/locationInput/LocationInput";
import BlockIcon from "@material-ui/icons/Block";
import SearchIcon from "@material-ui/icons/Search";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import CheckIcon from "@material-ui/icons/Check";

const LocationSelector = (props) => {
  const [showAddressModal, setShowAddressModal] = useState(false);
  const [address, setAddress] = useState("");

  return (
    <div className={styles.locationSelectorContainer}>
      {/**[0: streetNumber, 1: streetname, 2: country, 3: city, 4: state, 5: postal] **/}
      <LocationInput
        title="Location Search"
        placeholder="Enter a location..."
        show={showAddressModal ? true : false}
        types={["(regions)"]}
        searchOptions={{
          types: ["(regions)"],
        }}
        output="json"
        value={address}
        onChange={(value) => {
          setAddress(value);
        }}
        onSelectAddress={(value, addressComponents) => {
          props.onSelect(value, addressComponents[2]);
          setAddress(value);
          setShowAddressModal(false);
        }}
        close={(event) => {
          event.preventDefault();
          setShowAddressModal(false);
        }}
      />

      <Button
        size="small"
        variant="outlined"
        startIcon={<SearchIcon />}
        onClick={setShowAddressModal.bind(this, true)}
      >
        Location
      </Button>

      <div className={styles.locationSelectorContainer}>
        {props.locations.map((location, index) => (
          <div className={styles.locationRow} key={index}>
            <div className={styles.locationIconText}>
              {props.exclude ? (
                <BlockIcon style={{ color: "red" }} />
              ) : (
                <CheckIcon style={{ color: "green" }} />
              )}
              &nbsp;&nbsp;{location}
            </div>
            <div>
              <IconButton onClick={props.onDelete.bind(this, index)}>
                <HighlightOffIcon />
              </IconButton>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default LocationSelector;
