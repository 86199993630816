import { dbName } from "../../globals/ApiKeys";
import { handleErrorResponse } from "../../globals/Error";
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/storage";
import Profile from "../../model/Profile";
import MobileUserProfile from "../../model/MobileUserProfile";
import PricingPlan from "../../model/PricingPlan";
import { roundAmount, fromFirebaseDoubleValue } from "../../globals/Common";
import * as logger from "../../globals/Logger";
import { CURRENCY } from "../../globals/Data";
export const SET_PROFILE = "SET_PROFILE";
export const SET_PROFILE_LOGO = "SET_PROFILE_LOGO";
export const SET_PROFILE_ESIGN = "SET_PROFILE_ESIGN";
export const SET_PROFILE_STOREFRONT = "SET_PROFILE_STOREFRONT";
export const UPDATE_PROFILE = "UPDATE_PROFILE";
export const SET_PRICING_PLAN = "SET_PRICING_PLAN";
export const SET_MOBILE_USER_PROFILE = "SET_MOBILE_USER_PROFILE";
export const ADD_MOBILE_USER_PROFILE = "ADD_MOBILE_USER_PROFILE";
export const UPDATE_MOBILE_USER_PROFILE = "UPDATE_MOBILE_USER_PROFILE";

// ------------------------------

export const fetchPricingPlan = () => {
  return async (dispatch, getState) => {
    const userId = getState().auth.userId;
    const token = getState().auth.token;

    const response = await fetch(
      `https://firestore.googleapis.com/v1/projects/${dbName}/databases/(default)/documents/stripe_customers/${userId}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "applications/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );

    if (!response.ok) {
      handleErrorResponse(await response.json());
    }

    const resData = await response.json();
    let pricingPlan = {};

    if (resData && resData.fields) {
      const nameArray = resData.name.split("/");
      const id = nameArray[nameArray.length - 1];

      pricingPlan = new PricingPlan(
        id,
        resData.fields.customer_created
          ? new Date(resData.fields.customer_created.timestampValue)
          : new Date(),
        resData.fields.customer_type
          ? resData.fields.customer_type.stringValue
          : "",
        resData.fields.wave_fee ? resData.fields.wave_fee.booleanValue : false,
        resData.fields.credits
          ? roundAmount(
            fromFirebaseDoubleValue(
              resData.fields.credits.doubleValue,
              resData.fields.credits.integerValue
            )
          )
          : 0,
        resData.fields.expiration_date
          ? new Date(resData.fields.expiration_date.timestampValue)
          : null
      );
    }

    dispatch({
      type: SET_PRICING_PLAN,
      pricingPlan: pricingPlan,
    });
  };
};

// ------------------------------

export const fetchProfile = () => {
  return async (dispatch, getState) => {
    const userId = getState().auth.userId;
    const token = getState().auth.token;

    const response = await fetch(
      `https://firestore.googleapis.com/v1/projects/${dbName}/databases/(default)/documents/provider/${userId}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "applications/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );

    if (!response.ok) {
      handleErrorResponse(await response.json());
    }

    const resData = await response.json();
    let profile = {};
    if (resData) {
      const profNameArray = resData.name.split("/");
      const profileId = profNameArray[profNameArray.length - 1];

      const currencyCode = resData.fields.currency
        ? resData.fields.currency.stringValue
        : "CAD";
      const currencyObject = CURRENCY.find((c) => currencyCode === c.id);

      profile = new Profile(
        profileId,
        resData.fields.providerName.stringValue,
        resData.fields.country.stringValue,
        resData.fields.contact_email
          ? resData.fields.contact_email.stringValue
          : "",
        resData.fields.cancel_policy
          ? resData.fields.cancel_policy.stringValue
          : "",
        resData.fields.logo ? resData.fields.logo.stringValue : "",
        resData.fields.digitalSignature
          ? resData.fields.digitalSignature.stringValue
          : "",
        currencyObject,
        resData.fields.state ? resData.fields.state.stringValue : "",
        resData.fields.storefront ? resData.fields.storefront.stringValue : "",
        resData.fields.about_en ? resData.fields.about_en.stringValue : "",
        resData.fields.about_fr ? resData.fields.about_fr.stringValue : ""
      );
    }

    dispatch({
      type: SET_PROFILE,
      profile: profile,
    });
  };
};

// ------------------------------

export const updateProfile = (profile) => {
  return async (dispatch, getState) => {
    const userId = getState().auth.userId;
    const token = getState().auth.token;

    const response = await fetch(
      `https://firestore.googleapis.com/v1/projects/${dbName}/databases/(default)/documents/provider/${userId}` +
      `?updateMask.fieldPaths=contact_email&updateMask.fieldPaths=cancel_policy&updateMask.fieldPaths=about_en&updateMask.fieldPaths=about_fr`,
      {
        method: "PATCH",
        headers: {
          "Content-Type": "applications/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          fields: {
            contact_email: { stringValue: profile.contact_email },
            cancel_policy: { stringValue: profile.cancel_policy },
            about_en: { stringValue: profile.about_en },
            about_fr: { stringValue: profile.about_fr },
          },
        }),
      }
    );

    if (!response.ok) {
      handleErrorResponse(await response.json());
    }

    if (profile.logo && profile.logo.toString().startsWith("blob:")) {
      await dispatch(uploadPic(profile.logo, "logo"));
    }

    if (
      profile.digitalSignature &&
      profile.digitalSignature.toString().startsWith("blob:")
    ) {
      await dispatch(uploadPic(profile.digitalSignature, "esign"));
    }

    if (
      profile.storefront &&
      profile.storefront.toString().startsWith("blob:")
    ) {
      await dispatch(uploadPic(profile.storefront, "storefront"));
    }

    dispatch({
      type: UPDATE_PROFILE,
      profile: profile,
    });
  };
};

// ------------------------------

export const updateProfileLogo = (downloadURL) => {
  return async (dispatch, getState) => {
    const userId = getState().auth.userId;
    const token = getState().auth.token;

    await fetch(
      `https://firestore.googleapis.com/v1/projects/${dbName}/databases/(default)/documents/provider/${userId}?updateMask.fieldPaths=logo`,
      {
        method: "PATCH",
        headers: {
          "Content-Type": "applications/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          fields: {
            logo: { stringValue: downloadURL },
          },
        }),
      }
    );

    dispatch({
      type: SET_PROFILE_LOGO,
      logo: downloadURL,
    });
  };
};

// ------------------------------

export const updateProfileEsign = (downloadURL) => {
  return async (dispatch, getState) => {
    const userId = getState().auth.userId;
    const token = getState().auth.token;

    await fetch(
      `https://firestore.googleapis.com/v1/projects/${dbName}/databases/(default)/documents/provider/${userId}?updateMask.fieldPaths=digitalSignature`,
      {
        method: "PATCH",
        headers: {
          "Content-Type": "applications/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          fields: {
            digitalSignature: { stringValue: downloadURL },
          },
        }),
      }
    );

    dispatch({
      type: SET_PROFILE_ESIGN,
      digitalSignature: downloadURL,
    });
  };
};

// ------------------------------

export const updateProfileStorefront = (downloadURL) => {
  return async (dispatch, getState) => {
    const userId = getState().auth.userId;
    const token = getState().auth.token;

    await fetch(
      `https://firestore.googleapis.com/v1/projects/${dbName}/databases/(default)/documents/provider/${userId}?updateMask.fieldPaths=storefront`,
      {
        method: "PATCH",
        headers: {
          "Content-Type": "applications/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          fields: {
            storefront: { stringValue: downloadURL },
          },
        }),
      }
    );

    dispatch({
      type: SET_PROFILE_STOREFRONT,
      storefront: downloadURL,
    });
  };
};

// ------------------------------

export const uploadPic = (uri, postfix) => {
  return async (dispatch, getState) => {
    const userId = getState().auth.userId;

    if (uri && uri !== "undefined") {
      const response = await fetch(uri);
      const blob = await response.blob();
      let ref = firebase.storage().ref().child(`images/${userId}/${postfix}`);

      await ref.put(blob).then(function (snap) {
        snap.ref
          .getDownloadURL()
          .then(function (downloadUrl) {
            // ----------

            if (downloadUrl && postfix === "logo") {
              try {
                dispatch(updateProfileLogo(downloadUrl));
              } catch (err) {
                logger.error(err);
              }
            }
            // ----------

            if (downloadUrl && postfix === "esign") {
              try {
                dispatch(updateProfileEsign(downloadUrl));
              } catch (err) {
                logger.error(err);
              }
            }

            // ----------

            if (downloadUrl && postfix === "storefront") {
              try {
                dispatch(updateProfileStorefront(downloadUrl));
              } catch (err) {
                logger.error(err);
              }
            }
          })
          .catch(function (error) {
            logger.error(error);
          });
      });
    }
  };
};

// ------------------------------

export const fetchMobileUserProfile = () => {
  return async (dispatch, getState) => {
    const userId = getState().auth.userId;
    const token = getState().auth.token;

    const response = await fetch(
      `https://${dbName}.firebaseio.com/profile/${userId}.json?auth=${token}`
    );

    if (!response.ok) {
      handleErrorResponse(await response.json());
    }

    const resData = await response.json();

    let loadedUserProfile = {};
    if (resData) {
      Object.keys(resData).forEach((key, index) => {
        if (typeof resData[key] === "object") {
          loadedUserProfile = new MobileUserProfile(
            key,
            resData[key].first,
            resData[key].last,
            resData[key].gender,
            resData[key].occupation,
            resData[key].email,
            resData[key].birthDate,
            resData[key].graduationDate,
            resData[key].licencingBoard,
            resData[key].licenseNumber,
            resData[key].phone,
            resData[key].streetNumber,
            resData[key].streetName,
            resData[key].suite ? resData[key].suite : "",
            resData[key].country,
            resData[key].stateProvince,
            resData[key].city,
            resData[key].zipPostal,
            resData[key].geoLat,
            resData[key].geoLng,
            resData[key].geoHash,
            resData[key].zubuName
              ? resData[key].zubuName
              : resData[key].first + " " + resData[key].last,
            resData[key].zubuLocation
              ? resData[key].zubuLocation
              : resData[key].city
          );
        }
      });
    }

    dispatch({ type: SET_MOBILE_USER_PROFILE, userProfile: loadedUserProfile });
  };
};

// ------------------------------

export const addMobileUserProfile = (userId, token, newUser) => {
  return async (dispatch, getState) => {
    const userProfile = newUser.profile;

    const response = await fetch(
      `https://${dbName}.firebaseio.com/profile/${userId}.json?auth=${token}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "applications/json",
        },
        body: JSON.stringify({
          first: userProfile.first ? userProfile.first.trim() : "",
          last: userProfile.last ? userProfile.last.trim() : "",
          gender: userProfile.gender,
          occupation: userProfile.occupation,
          email: userProfile.email,
          birthDate: userProfile.birthDate,
          graduationDate: userProfile.graduationDate,
          licencingBoard: userProfile.licencingBoard,
          licenseNumber: userProfile.licenseNumber,
          phone: userProfile.phone,
          streetNumber: userProfile.streetNumber,
          streetName: userProfile.streetName,
          suite: userProfile.suite,
          country: userProfile.country,
          stateProvince: userProfile.stateProvince,
          city: userProfile.city,
          zipPostal: userProfile.zipPostal,
          geoLat: userProfile.geoLat,
          geoLng: userProfile.geoLng,
          geoHash: userProfile.geoHash,
          zubuName: newUser.username ? newUser.username.trim() : "",
          zubuLocation: userProfile.city ? userProfile.city : "",
        }),
      }
    );

    if (!response.ok) {
      handleErrorResponse(await response.json());
    }
  };
};

// ------------------------------

export const updateMobileUserProfile = (userProfile) => {
  return async (dispatch, getState) => {
    const userId = getState().auth.userId;
    const token = getState().auth.token;

    const response = await fetch(
      `https://${dbName}.firebaseio.com/profile/${userId}/${userProfile.id}.json?auth=${token}`,
      {
        method: "PATCH",
        headers: {
          "Content-Type": "applications/json",
        },
        body: JSON.stringify({
          first: userProfile.first ? userProfile.first.trim() : "",
          last: userProfile.last ? userProfile.last.trim() : "",
          gender: userProfile.gender,
          occupation: userProfile.occupation,
          email: userProfile.email,
          birthDate: userProfile.birthDate,
          graduationDate: userProfile.graduationDate,
          licencingBoard: userProfile.licencingBoard,
          licenseNumber: userProfile.licenseNumber,
          phone: userProfile.phone,
          streetNumber: userProfile.streetNumber,
          streetName: userProfile.streetName,
          suite: userProfile.suite,
          country: userProfile.country,
          stateProvince: userProfile.stateProvince,
          city: userProfile.city,
          zipPostal: userProfile.zipPostal,
          geoLat: userProfile.geoLat,
          geoLng: userProfile.geoLng,
          geoHash: userProfile.geoHash,
          zubuName: userProfile.zubuName ? userProfile.zubuName.trim() : "",
          zubuLocation: userProfile.zubuLocation
            ? userProfile.zubuLocation.trim()
            : "",
        }),
      }
    );

    if (!response.ok) {
      handleErrorResponse(await response.json());
    }

    dispatch({
      type: UPDATE_MOBILE_USER_PROFILE,
      userProfile: userProfile,
    });

    dispatch(
      updateUserDirectory({
        last: null,
        first_last: null,
        first: resolveDiplpayName(userProfile),
        city: userProfile.zubuLocation
          ? userProfile.zubuLocation
          : userProfile.city
            ? userProfile.city
            : "",
      })
    );

    // update city, frist, last friends, inbox and messages
    dispatch(updateUserRefProfile(userProfile));
  };
};

// ------------------------------

const updateUserDirectory = (updateObject) => {
  return async (dispatch, getState) => {
    const userId = getState().auth.userId;
    const token = getState().auth.token;

    const response = await fetch(
      `https://${dbName}.firebaseio.com/users/${userId}.json?auth=${token}`,
      {
        method: "PATCH",
        headers: {
          "Content-Type": "applications/json",
        },
        body: JSON.stringify(updateObject),
      }
    );

    if (!response.ok) {
      handleErrorResponse(await response.json());
    }
  };
};

// ------------------------------

const updateUserRefProfile = (userProfile) => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;

    fetch(
      `https://us-central1-${dbName}.cloudfunctions.net/updateUserReference`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          data: {
            first: resolveDiplpayName(userProfile),
            last: "",
            city: userProfile.zubuLocation
              ? userProfile.zubuLocation
              : userProfile.city
                ? userProfile.city
                : "",
          },
        }),
      }
    );
  };
};

// ------------------------------

const updateUserRefPic = (pic) => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;

    fetch(
      `https://us-central1-${dbName}.cloudfunctions.net/updateUserReference`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          data: {
            pic: pic,
          },
        }),
      }
    );
  };
};

// ------------------------------

const resolveDiplpayName = (userProfile) => {
  if (userProfile.zubuName) {
    return userProfile.zubuName.trim().toLowerCase();
  } else {
    return userProfile.first && userProfile.last
      ? userProfile.first.trim().toLowerCase() +
      " " +
      userProfile.last.trim().toLowerCase()
      : "";
  }
};

// ------------------------------
