import { handleErrorResponse } from "../../globals/Error";
import { dbName } from "../../globals/ApiKeys";
import StudentOrder from "../../model/StudentOrder";
import StaffMember from "../../model/StaffMember";
import InstallmentStatus from "../../model/InstallmentStatus";
import CartCourseItem from "../../model/CartCourseItem";
import CartPricingItem from "../../model/CartPricingItem";
import PricingItem from "../../model/PricingItem";
import MemberRefundValue from "../../model/MemberRefundValue";
import Refund from "../../model/Refund";
import { centToDollar, firebaseNameToId } from "../../globals/Common";

import moment from "moment";

import {
  fromFirebaseDoubleValue,
  fromFirebaseStringArrayValue,
} from "../../globals/Common";
import "firebase/firestore";
export const SET_STUDENT_ORDER = "SET_STUDENT_ORDER";
export const SET_CHARGE_ID = "SET_CHARGE_ID";
export const CLEAR_CHARGE_ID = "CLEAR_CHARGE_ID";
export const SET_ORDER_REFUND = "SET_ORDER_REFUND";

// ------------------------------

export const setChargeId = (id, courseId) => {
  return {
    type: SET_CHARGE_ID,
    chargeId: id,
    courseSelectedId: courseId,
  };
};

// ------------------------------

export const fetchStudentOrder = (chargeId) => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;
    const userId = getState().auth.userId;

    const response = await fetch(
      `https://firestore.googleapis.com/v1/projects/${dbName}/databases/(default)/documents/stripe_customers/${userId}/orders/${chargeId}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "applications/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );

    if (!response.ok) {
      handleErrorResponse(await response.json());
    }

    const resData = await response.json();

    const studentOrder = resData;

    let loadedStudentOrder = null;
    if (resData.constructor === Object && Object.keys(resData).length > 0) {
      if (studentOrder && studentOrder.name) {
        const totalAmount = studentOrder.fields.chargeAmount
          ? fromFirebaseDoubleValue(
            studentOrder.fields.chargeAmount.doubleValue,
            studentOrder.fields.chargeAmount.integerValue
          )
          : fromFirebaseDoubleValue(
            studentOrder.fields.totalAmount.doubleValue,
            studentOrder.fields.totalAmount.integerValue
          );

        if (totalAmount === 0) {
          loadedStudentOrder = new StudentOrder(
            "", // charge
            studentOrder.fields.orderRefNumber.stringValue,
            new Date(studentOrder.fields.date.timestampValue),
            "",
            "",
            "",
            studentOrder.fields.regLicense.stringValue,
            "",
            0,
            0,
            0,
            totalAmount,
            buildOrderDetails(
              studentOrder.fields.items.mapValue.fields,
              studentOrder.fields.memberCouponValue
                ? studentOrder.fields.memberCouponValue.arrayValue
                : null
            ),
            []
          );
        } else {
          const installmentStatus = await fetchAndBuildInstallentStatus(
            userId,
            token,
            studentOrder
          );

          loadedStudentOrder = new StudentOrder(
            studentOrder.fields.charge.stringValue,
            studentOrder.fields.orderRefNumber.stringValue,
            new Date(studentOrder.fields.date.timestampValue),
            studentOrder.fields.ccBrand.stringValue,
            studentOrder.fields.ccExpiryDate.stringValue,
            studentOrder.fields.ccLast4.stringValue,
            studentOrder.fields.regLicense.stringValue,
            studentOrder.fields.ccName.stringValue,
            fromFirebaseDoubleValue(
              studentOrder.fields.appFee.doubleValue,
              studentOrder.fields.appFee.integerValue
            ),
            fromFirebaseDoubleValue(
              studentOrder.fields.gst.doubleValue,
              studentOrder.fields.gst.integerValue
            ),
            fromFirebaseDoubleValue(
              studentOrder.fields.qst.doubleValue,
              studentOrder.fields.qst.integerValue
            ),
            totalAmount,
            buildOrderDetails(
              studentOrder.fields.items.mapValue.fields,
              studentOrder.fields.memberCouponValue.arrayValue
            ),
            installmentStatus
          );
        }
      }
    }

    dispatch({
      type: SET_STUDENT_ORDER,
      studentorder: loadedStudentOrder,
    });
  };
};

// ------------------------------

const buildOrderDetails = (items, memberDiscountValue) => {
  const coursesItems = [];
  const courseItem = items.cartCourseItems.mapValue.fields;

  Object.keys(courseItem).forEach((key, index) => {
    const pi = courseItem[key];
    coursesItems.push(
      new CartCourseItem(
        key,
        pi.mapValue.fields.title.stringValue,
        buildCartPricingItems(
          key,
          pi.mapValue.fields.pricingItems.arrayValue,
          memberDiscountValue
        )
      )
    );
  });

  return coursesItems.sort((a, b) => (a.title > b.title ? 1 : -1));
};

// ------------------------------

const buildCartPricingItems = (cId, pricingItems, memberDiscountValue) => {
  let arrPricingItems = [];

  for (const item of pricingItems.values) {
    const costAfterDiscount = item.mapValue.fields.costAfterDiscount;
    const pi = item.mapValue.fields.pricingItem;
    const arrStaff = item.mapValue.fields.staffMembers;

    arrPricingItems.push(
      new CartPricingItem(
        costAfterDiscount
          ? fromFirebaseDoubleValue(
            costAfterDiscount.doubleValue,
            costAfterDiscount.integerValue
          )
          : fromFirebaseDoubleValue(
            pi.mapValue.fields.cost.doubleValue,
            pi.mapValue.fields.cost.integerValue
          ),
        buildSinglePricingItem(pi),
        buildArrayOfStaffMembers(
          arrStaff.arrayValue,
          cId,
          pi,
          memberDiscountValue
        )
      )
    );
  }

  return arrPricingItems;
};

// ------------------------------

const buildArrayOfStaffMembers = (arrStaff, cId, pi, memberDiscountValue) => {
  let arrStaffMembers = [];

  for (const staffMember of arrStaff.values) {
    const sm = staffMember.mapValue.fields;

    const memberDiscount = extractMemberDiscount(
      sm.id,
      cId,
      pi,
      memberDiscountValue
    );

    arrStaffMembers.push(
      new StaffMember(
        sm.id.stringValue,
        sm.license ? sm.license.stringValue : "",
        sm.occupation.stringValue,
        sm.last.stringValue,
        sm.first.stringValue,
        sm.email.stringValue,
        false,
        memberDiscount
      )
    );
  }

  return arrStaffMembers;
};

// ------------------------------

const extractMemberDiscount = (staffMemberId, cId, pi, memberDiscountValue) => {
  const pricingItem = buildSinglePricingItem(pi);

  if (memberDiscountValue && memberDiscountValue.values) {
    for (const memberDiscountVal of memberDiscountValue.values) {
      const objMember = memberDiscountVal.mapValue.fields.member;
      const objPricingItem =
        memberDiscountVal.mapValue.fields.memberPricingItem;
      const objCouponValue =
        memberDiscountVal.mapValue.fields.memberCouponValue;

      if (
        cId === memberDiscountVal.mapValue.fields.course.stringValue &&
        objMember.mapValue.fields.id.stringValue ===
        staffMemberId.stringValue &&
        pricingItem.isEqual(buildSinglePricingItem(objPricingItem))
      ) {
        return fromFirebaseDoubleValue(
          objCouponValue.doubleValue,
          objCouponValue.integerValue
        );
      }
    }
  }

  return 0;
};

// ------------------------------

export const fetchRefund = (orderId) => {
  return async (dispatch, getState) => {
    const userId = getState().auth.userId;
    const token = getState().auth.token;

    const response = await fetch(
      `https://firestore.googleapis.com/v1/projects/${dbName}/databases/(default)/documents/stripe_customers/${userId}/orders/${orderId}/refunds`,
      {
        method: "GET",
        headers: {
          "Content-Type": "applications/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );

    if (!response.ok) {
      handleErrorResponse(await response.json());
    }

    const resData = await response.json();

    const loadedRefund = [];

    let arrDocs = [];

    if (Array.isArray(resData)) {
      arrDocs = resData;
    } else {
      arrDocs = resData.documents;
    }

    if (arrDocs && Object.keys(arrDocs).length !== 0) {
      for (const documents of arrDocs) {
        let refund = documents.document;

        if (!refund) {
          refund = documents;
        }

        if (refund && refund.name) {
          loadedRefund.push(
            new Refund(
              refund.fields.id.stringValue,
              centToDollar(
                fromFirebaseDoubleValue(
                  refund.fields.amount.doubleValue,
                  refund.fields.amount.integerValue
                )
              ),
              refund.fields.charge.stringValue,
              new Date(moment.unix(refund.fields.created.integerValue)),
              refund.fields.currency.stringValue,
              refund.fields.reason.stringValue,
              refund.fields.status.stringValue,
              buildMemberRefundValueArray(
                refund.fields.memberRefundValue.arrayValue
              )
            )
          );
        }
      }
    }

    dispatch({
      type: SET_ORDER_REFUND,
      refund: loadedRefund,
      refundTotal:
        loadedRefund.length > 0
          ? loadedRefund
            .map((r) => r.amount)
            .reduce((prev, next) => Number(prev) + Number(next))
          : 0,
    });
  };
};

// ------------------------------

const buildMemberRefundValueArray = (memberRefundValueArray) => {
  const memberRefundArray = [];

  for (const memberRefund of memberRefundValueArray.values) {
    const m = memberRefund.mapValue.fields;

    const chargeId = m.chargeId;

    const sm = m.member.mapValue.fields;
    const memberRefundSm = new StaffMember(
      sm.id.stringValue,
      sm.license ? sm.license.stringValue : "",
      sm.occupation.stringValue,
      sm.last.stringValue,
      sm.first.stringValue,
      sm.email.stringValue,
      false,
      0
    );

    memberRefundSm.courseId = sm.courseId.stringValue;

    const memberRefundPi = buildSinglePricingItem(m.memberPricingItem);

    const refundValue = Number(m.memberRefundValue.stringValue);

    if (refundValue && Number(refundValue) > 0) {
      memberRefundArray.push(
        new MemberRefundValue(
          memberRefundSm,
          memberRefundPi,
          refundValue,
          chargeId
        )
      );
    }
  }

  return memberRefundArray;
};

// ------------------------------

const buildSinglePricingItem = (pi) => {
  return new PricingItem(
    pi.mapValue.fields.casting.stringValue,
    fromFirebaseStringArrayValue(pi.mapValue.fields.audience.arrayValue.values),
    pi.mapValue.fields.component.stringValue,
    pi.mapValue.fields.description.stringValue,
    fromFirebaseDoubleValue(
      pi.mapValue.fields.ce_odq.doubleValue,
      pi.mapValue.fields.ce_odq.integerValue
    ),
    fromFirebaseDoubleValue(
      pi.mapValue.fields.credit_lecture_hours.doubleValue,
      pi.mapValue.fields.credit_lecture_hours.integerValue
    ),
    fromFirebaseDoubleValue(
      pi.mapValue.fields.credit_handson_hours.doubleValue,
      pi.mapValue.fields.credit_handson_hours.integerValue
    ),
    fromFirebaseDoubleValue(
      pi.mapValue.fields.cost.doubleValue,
      pi.mapValue.fields.cost.integerValue
    ),
    pi.mapValue.expiration_date
      ? new Date(pi.mapValue.expiration_date.timestampValue)
      : null,
    pi.mapValue.fields.members_only ? pi.mapValue.fields.members_only.booleanValue : false
  );
};

// ------------------------------

const fetchAndBuildInstallentStatus = async (userId, token, order) => {
  const orderId = firebaseNameToId(order.name);
  const multiPayOption = order.fields.multiPayOption;

  let installmentStatusArray = [];

  if (multiPayOption && multiPayOption.mapValue) {
    const response = await fetch(
      `https://firestore.googleapis.com/v1/projects/${dbName}/databases/(default)/documents/stripe_customers/${userId}/orders/${orderId}/installment`,
      {
        method: "GET",
        headers: {
          "Content-Type": "applications/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );

    if (!response.ok) {
      handleErrorResponse(await response.json());
    }

    const resData = await response.json();

    if (resData.documents) {
      const arrayUnsorted = [];

      for (const installment of resData.documents) {
        arrayUnsorted.push(
          new InstallmentStatus(
            firebaseNameToId(installment.name),
            new Date(installment.fields.date.timestampValue),
            installment.fields.chargeAmount
              ? installment.fields.chargeAmount.integerValue
              : installment.fields.amount
                ? installment.fields.amount.integerValue
                : 0,
            installment.fields.status.stringValue,
            installment.fields.charge
              ? installment.fields.charge.stringValue
              : null
          )
        );
      }

      installmentStatusArray = arrayUnsorted.sort((a, b) =>
        new Date(a.date).getTime() > new Date(b.date).getTime() ? 1 : -1
      );
    }
  }

  return installmentStatusArray;
};

// ------------------------------

export const cancelPaymentInstallment = (parentChargeId, installmentId) => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;
    const userId = getState().auth.userId;

    await fetch(
      `https://firestore.googleapis.com/v1/projects/${dbName}/databases/(default)/documents/stripe_scheduled/${installmentId}`,
      {
        method: "DELETE",
        headers: {
          "Content-Type": "applications/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );

    await dispatch(updateInstallmentToCancelled(parentChargeId, installmentId));
    await dispatch(fetchStudentOrder(parentChargeId));

    // TODO Update the students payment installment
  };
};

// ------------------------------

export const updateInstallmentToCancelled = (chargeId, installmentId) => {
  return async (dispatch, getState) => {
    const userId = getState().auth.userId;
    const token = getState().auth.token;

    await fetch(
      `https://firestore.googleapis.com/v1/projects/${dbName}/databases/(default)/documents/stripe_customers/${userId}/orders/${chargeId}/installment/${installmentId}?updateMask.fieldPaths=status`,
      {
        method: "PATCH",
        headers: {
          "Content-Type": "applications/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          fields: {
            status: { stringValue: "cancelled" },
          },
        }),
      }
    );
  };
};
