import React, { useState } from "react";
import * as styles from "./Increment.module.css";
import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import MatSelectOutline from "../../../components/ui/labelSelect/MatSelectOutline";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";

const IncrementSelect = (props) => {
  const [unlimited, setUnlimited] = useState(props.unlimited ? true : false);

  const interval = props.interval ? props.interval : 1;

  const handleIncrement = () => {
    if (props.selectValue < props.maxValue) {
      props.onUpdate(Number(props.selectValue) + interval);
    }
  };

  const handleDecrement = () => {
    if (props.selectValue > props.minValue) {
      props.onUpdate(Number(props.selectValue) - interval);
    }
  };

  return (
    <div
      className={
        props.fullWidth ? styles.containerSelectFull : styles.containerSelect
      }
    >
      <div className={styles.titleSelect}>{props.title}</div>

      {unlimited ? (
        <ButtonGroup
          size="small"
          aria-label="small outlined button group"
          className={styles.buttonGroup}
          style={{ width: "240px", height: "58px" }}
        >
          <Button
            onClick={() => {
              setUnlimited(false);
              props.setLimit();
            }}
            style={{ width: "167px" }}
          >
            SET LIMIT
          </Button>
        </ButtonGroup>
      ) : (
        <ButtonGroup
          size="small"
          aria-label="small outlined button group"
          className={styles.buttonGroup}
        >
          <Button onClick={handleDecrement}>
            <ArrowDownwardIcon fontSize="small" />
          </Button>
          <MatSelectOutline
            zeroMargin
            double
            title={""}
            placeholder={""}
            name={props.name}
            id={props.id + "0"}
            onChange={props.onChange}
            options={props.options}
            value={props.value}
            getOptionLabel={props.getOptionLabel}
            getOptionSelected={props.getOptionSelected}
            disableClearable={true}
            multiple={false}
            required={false}
            error={Number(props.selectValue) < 0}
          />
          <Button onClick={handleIncrement}>
            <ArrowUpwardIcon fontSize="small" />
          </Button>
        </ButtonGroup>
      )}
    </div>
  );
};

export default IncrementSelect;
