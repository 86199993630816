import React, { Fragment } from "react";
import { useTranslation } from "react-i18next";
import Button from "@material-ui/core/Button";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";

import Divider from "@material-ui/core/Divider";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemText from "@material-ui/core/ListItemText";
import Avatar from "@material-ui/core/Avatar";
import PersonIcon from "@material-ui/icons/Person";

const StaffRegisteredModal = (props) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("xs"));
  const fullWidth = true;
  const maxWidth = "xs";

  // ------------------------------

  const handleClose = () => {
    props.onClose();
  };

  // ------------------------------

  return (
    <div>
      <Dialog
        fullScreen={fullScreen}
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={props.open}
        onClose={handleClose.bind(this, null, null)}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="alert-dialog-title">
          {t("g_registered")} ({props.registrants.length})
        </DialogTitle>

        <DialogContent>
          <div style={{ height: "10px" }}>&nbsp;</div>

          <Fragment>
            <List dense={true} style={{ width: "100%", maxWidth: 360 }}>
              {props.registrants.map((registrant) => {
                const labelId = `checkbox-list-label-${registrant.id}`;

                return (
                  <Fragment key={registrant.id}>
                    <ListItem key={registrant.id} role={undefined} dense>
                      <ListItemAvatar>
                        <Avatar>
                          <PersonIcon />
                        </Avatar>
                      </ListItemAvatar>

                      <ListItemText
                        id={labelId}
                        primary={
                          registrant.doctorPrefix() +
                          registrant.first +
                          " " +
                          registrant.last
                        }
                        secondary={registrant.email}
                      />
                    </ListItem>
                    <Divider variant="inset" component="li" />
                  </Fragment>
                );
              })}
            </List>
          </Fragment>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose} color="primary">
            {t("g_ok")}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default StaffRegisteredModal;
