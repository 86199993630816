import React, { Fragment, useState } from 'react';
import { useTranslation } from "react-i18next";
import Button from "@material-ui/core/Button";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DateSelect from "../../ui/dateSelect/DateSelect";
import Collapse from "@material-ui/core/Collapse";
import { FNS_MEDIUM_DATE_FORMAT, incrementYearOfDate } from "../../../globals/Dates";
import DateRangeOutlinedIcon from '@material-ui/icons/DateRangeOutlined';
import ToggleButton from '../../ui/button/ToggleButton';

const DateRangeModal = (props) => {
    const { t } = useTranslation();
    const fullScreen = false;
    const fullWidth = true;
    const maxWidth = "sm";

    const currentYear = new Date().getFullYear();
    const pastThreeYear = incrementYearOfDate(new Date(), -2).getFullYear();
    const [tempSelection, setTempSelection] = useState(props.selection);
    const [tempDateFrom, setTempDateFrom] = useState(props.dateFrom ? new Date(props.dateFrom) : new Date());
    const [tempDateTo, setTempDateTo] = useState(props.dateTo ? new Date(props.dateTo) : new Date());

    const handleCancel = () => {
        props.onClose(props.selection,
            props.dateFrom ? new Date(props.dateFrom) : new Date(),
            props.dateTo ? new Date(props.dateTo) : new Date(),
            true);
    }

    const handleClose = () => {
        props.onClose(tempSelection, tempDateFrom, tempDateTo, false);
    }

    return (
        <Dialog
            fullScreen={fullScreen}
            fullWidth={fullWidth}
            maxWidth={maxWidth}
            open={props.open}
            onClose={handleCancel}
            aria-labelledby="responsive-dialog-title"
        >
            <DialogTitle id="alert-dialog-title" >
                <div
                    style={{ display: "flex", flexDirection: 'row', alignItems: "center" }}>
                    <DateRangeOutlinedIcon />&nbsp;{t("ce_filter_date")}
                </div>
            </DialogTitle>
            <DialogContent
                style={{
                    flex: 1,
                    display: "flex",
                    flexDirection: "row",
                    width: "100%",
                    justifyContent: "center",
                    flexWrap: "wrap"
                }}>
                <ToggleButton
                    id="current_year"
                    label={currentYear}
                    selection={tempSelection}
                    onClick={() => {
                        setTempSelection("current_year")
                    }}
                />
                <ToggleButton
                    id="last_three_years"
                    label={pastThreeYear + "-" + currentYear}
                    selection={tempSelection}
                    onClick={() => {
                        setTempSelection("last_three_years")
                    }}
                />
                <ToggleButton
                    id="all"
                    label={t("g_all_time")}
                    selection={tempSelection}
                    onClick={() => {
                        setTempSelection("all")
                    }}
                />
                <ToggleButton
                    id="custom"
                    label={t("g_custom_date")}
                    selection={tempSelection}
                    onClick={() => {
                        setTempSelection("custom")
                    }}
                />
                <Collapse in={tempSelection === "custom"}>
                    <Fragment>
                        <DateSelect
                            withPortal
                            hideMonth
                            title={t("g_select_from_date")}
                            selected={tempDateFrom}
                            dateFormat={FNS_MEDIUM_DATE_FORMAT}
                            onChange={(date) => {
                                setTempDateFrom(date)
                            }}
                        />
                        <DateSelect
                            withPortal
                            hideMonth
                            title={t("g_select_to_date")}
                            selected={tempDateTo}
                            dateFormat={FNS_MEDIUM_DATE_FORMAT}
                            onChange={(date) => {
                                setTempDateTo(date)
                            }}
                        />
                    </Fragment>
                </Collapse>
            </DialogContent>
            <DialogActions>
                <Button
                    autoFocus
                    onClick={handleCancel}
                    color="primary"
                >
                    {t("g_cancel")}
                </Button>
                <Button
                    autoFocus
                    color="primary"
                    onClick={handleClose}
                    disabled={!tempSelection}
                >
                    {t("g_ok")}
                </Button>
            </DialogActions>
        </Dialog>
    );
}


export default DateRangeModal;
