import {
  SET_SURVEY,
  SET_SURVEY_ID,
  ADD_SURVEY,
  REMOVE_SURVEY,
  UPDATE_SURVEY,
  OPEN_SURVEY,
  SET_SURVEY_RESULTS,
  UPDATE_SURVEY_EXPIRATION,
} from "../actions/survey";
import Survey from "../../model/Survey";
import { updateObject } from "../../globals/Common";
import { LOGOUT } from "../actions/auth";

const initialState = {
  survey: [],
  surveyId: null,
  survey_results: [],
  openSurvey: null, // open survey
};

const surveyReducer = (state = initialState, action) => {
  switch (action.type) {
    case OPEN_SURVEY:
      return updateObject(state, {
        openSurvey: action.openSurvey,
      });
    case SET_SURVEY:
      return updateObject(state, {
        survey: action.survey,
      });
    case SET_SURVEY_ID:
      return updateObject(state, { surveyId: action.surveyId });
    case SET_SURVEY_RESULTS:
      return updateObject(state, { survey_results: action.survey_results });
    case ADD_SURVEY:
      const newSurvey = new Survey(
        action.survey.id,
        action.survey.title,
        action.survey.text,
        action.survey.expiration_days
      );
      return updateObject(state, { survey: state.survey.concat(newSurvey) });
    case UPDATE_SURVEY:
      const sIndex = state.survey.findIndex((p) => p.id === action.survey.id);
      const updateSurvey = new Survey(
        action.survey.id,
        action.survey.title,
        action.survey.text,
        action.survey.expiration_days
      );
      const updatedSurveys = [...state.survey];
      updatedSurveys[sIndex] = updateSurvey;
      return updateObject(state, { survey: updatedSurveys });
    case UPDATE_SURVEY_EXPIRATION:
      const eIndex = state.survey.findIndex((p) => p.id === action.survey.id);

      if (eIndex > -1) {
        const updatedSurveys = [...state.survey];

        const updateExSurvey = new Survey(
          updatedSurveys[eIndex].id,
          updatedSurveys[eIndex].title,
          updatedSurveys[eIndex].text,
          action.survey.expiration_days
        );

        updatedSurveys[eIndex] = updateExSurvey;
        return updateObject(state, { survey: updatedSurveys });
      }
      return state;
    case REMOVE_SURVEY:
      const surveyAfterDel = [...state.survey];
      const surveyIdToDelete = action.id;

      const itemIndex = surveyAfterDel.findIndex(
        (it) => it.id === surveyIdToDelete
      );

      surveyAfterDel.splice(itemIndex, 1);

      return updateObject(state, { survey: surveyAfterDel });
    case LOGOUT:
      return initialState;
    default:
      return state;
  }
};

export default surveyReducer;
