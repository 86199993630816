class Amendment {
  constructor(professor, notes, start_date, discount, amended_date) {
    this.professor = professor ? professor : [];
    this.notes = notes ? notes : "";
    this.start_date = start_date;
    this.discount = discount ? discount : []; // each element index associated to pricing option
    this.amended_date = amended_date;
  }
}

export default Amendment;
