import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import MatRadioGroup from "../radio/MatRadioGroup";
import Radio from "@material-ui/core/Radio";
import { shortJsDate } from "../../../globals/Dates2";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import styles from "./PaymentOption.module.css";
import NumberFormat from "react-number-format";

const PaymentOption = (props) => {
  const { t, i18n } = useTranslation();

  // ------------------------------

  const [selected, setSelected] = useState(
    props.selectedPaymentOption ? props.selectedPaymentOption : "single"
  );

  // ------------------------------

  const radioChangeHandler = (event) => {
    setSelected(event.target.value);
    props.updateSelectedPaymentOption(event.target.value);
  };

  const currency = props.currencyObject ? props.currencyObject.shortName : "";

  const firstPayment = props.multiPayOptionResults
    ? props.multiPayOptionResults.firstPayment
    : 0;

  const installment = props.multiPayOptionResults
    ? props.multiPayOptionResults.installment
    : 0;

  const installmentDates = props.multiPayOptionResults
    ? props.multiPayOptionResults.installmentDates
    : [];

  const installmentSpan = [];

  for (const date of installmentDates) {
    installmentSpan.push(
      <span key={date.toString()}>
        <NumberFormat
          value={installment}
          displayType={"text"}
          thousandSeparator={true}
          prefix={currency}
        />{" "}
        {t("cart_payment_option_on")} {shortJsDate(date, i18n)}
      </span>
    );
  }

  installmentSpan.push(
    <span
      style={{
        fontStyle: "italic",
        paddingTop: "5px",
        fontSize: "small",
      }}
      key="1"
    >
      {t("cart_payment_option_disclaimer")}
    </span>
  );

  return (
    <div className={styles.summaryContainer}>
      <div className={styles.container}>
        <MatRadioGroup
          name="multiPaySelect"
          value={selected}
          onChange={radioChangeHandler}
          fullWidth={props.fullWidth}
          noBorder={props.noBorder}
        >
          <div
            className={styles.option}
            style={
              selected === "single"
                ? {
                  border: "1px solid blue",
                  borderRadius: "5px",
                }
                : null
            }
          >
            <FormControlLabel
              value="single"
              control={
                <Radio color="primary" style={{ marginRight: "10px" }} />
              }
              label={
                <div className={styles.radioDescItems}>
                  <span style={{ fontWeight: "bold" }}>
                    <NumberFormat
                      value={props.totalAmount}
                      displayType={"text"}
                      thousandSeparator={true}
                      prefix={currency}
                    />
                  </span>
                </div>
              }
            />
          </div>

          <div
            className={styles.option2}
            style={
              selected === "multiple"
                ? { border: "1px solid blue", borderRadius: "5px" }
                : null
            }
          >
            <FormControlLabel
              value="multiple"
              control={
                <Radio color="primary" style={{ marginRight: "10px" }} />
              }
              label={
                <span className={styles.radioDescItems}>
                  <span style={{ fontWeight: "bold", paddingBottom: "5px" }}>
                    <NumberFormat
                      value={firstPayment}
                      displayType={"text"}
                      thousandSeparator={true}
                      prefix={currency}
                    />{" "}
                    ({props.multiPayOption.firstPayPercent}%{" "}
                    {t("cart_payment_option_pay_now")})
                  </span>
                  <span style={{ textDecoration: "underline" }}>
                    {t("cart_payment_option_pay_multiple")}:
                  </span>{" "}
                  {installmentSpan}
                </span>
              }
            />
          </div>
        </MatRadioGroup>
      </div>
    </div>
  );
};

export default PaymentOption;
