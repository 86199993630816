import React from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import FormHelperText from "@material-ui/core/FormHelperText";
import NumberFormat from "react-number-format";

const useStyles = makeStyles((theme) => ({
  margin: {
    margin: "0px",
    padding: "10px",
    width: "100%",
  },
}));

const Input = (props) => {
  const classes = useStyles();

  return (
    <FormControl
      className={classes.margin}
      variant="outlined"
      rows={props.rows}
    >
      <OutlinedInput inputProps={{ maxLength: props.maxLength }} {...props} />
    </FormControl>
  );
};

export default Input;
