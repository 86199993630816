import React, { useState } from "react";
import ReactCountryFlag from "react-country-flag";
import * as styles from "./CurrencyLabel.module.css";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { CURRENCY } from "../../../globals/Data";
import CurrencyModal from "../../modals/currencyModal/CurrencyModal";
import LanguageIcon from "@material-ui/icons/Language";

const CurrencyLabel = (props) => {
  const smallLangIcon = useMediaQuery("(min-width:500px)");
  const [showCurrencyModal, setShowCurrencyModal] = useState(false);

  const findCurrency = (ccyAccronym) => {
    if (ccyAccronym) {
      return CURRENCY.find((c) => c.id === ccyAccronym);
    } else {
      return CURRENCY[4]; // USD
    }
  };

  let selectedCurrency = findCurrency(props.currencyAcronym);

  return (
    <div
      className={styles.container}
      onClick={() => {
        if (showCurrencyModal === false) {
          setShowCurrencyModal(true);
        }
      }}
    >
      <div>
        {showCurrencyModal && (
          <CurrencyModal
            open={showCurrencyModal}
            selectedCurrencyId={selectedCurrency.id}
            selectedLanguageId={props.language}
            updateMobileCurrency={(currency, language) => {
              setShowCurrencyModal(false);
              if (currency) {
                props.updateMobileCurrency(currency, language);
              }
            }}
          />
        )}
      </div>

      <div className={styles.langContainer}>
        <LanguageIcon
          fontSize={
            props.iconSize ? props.iconSize : smallLangIcon ? "large" : "medium"
          }
          style={{ color: props.white ? "white" : "gray" }}
        />
        <ReactCountryFlag
          title=""
          aria-label=""
          countryCode={selectedCurrency.iso}
          svg
          style={{
            fontSize: "1.4em",
            lineHeight: "1.4em",
            paddingRight: 0,
            paddingBottom: 0,
            borderRadius: "10px",
            position: "relative",
            left: -14,
            top: -8,
            opacity: 0.8,
          }}
        />
      </div>
    </div>
  );
};

export default CurrencyLabel;
