import React from "react";
import Radio from "@material-ui/core/Radio";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import styles from "./RadioGroup.module.css";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import OndemandVideoIcon from "@material-ui/icons/OndemandVideo";
import SupervisorAccountIcon from "@material-ui/icons/SupervisorAccount";

const MatRadioGroup = (props) => {
  const useStyles = makeStyles((theme) => ({
    root: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(3),

      "& > * + *": {
        marginTop: theme.spacing(3),
      },
    },
    textField: {
      width: "100%",
    },
    textFieldSmall: {
      width: "60%",
    },
    margin: {
      margin: theme.spacing(0),
    },
  }));

  const matches = useMediaQuery("(max-width:499px)");
  const classes = useStyles();
  const classttt = matches ? classes.textField : classes.textFieldSmall;

  return (
    <div className={classes.root}>
      <FormControl
        component="fieldset"
        className={clsx(classes.margin, classttt)}
      >
        <RadioGroup
          row
          onChange={props.onChange}
          value={props.isVideo ? "video" : "live"}
          aria-label="position"
          name="position"
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <div
            className={
              !props.isVideo
                ? styles.radioSelectedContainer
                : styles.radioContainer
            }
          >
            <FormControlLabel
              value="live"
              control={<Radio color="primary" />}
              label={
                <div className={styles.center}>
                  <div
                    className={!props.isVideo ? styles.rowSelected : styles.row}
                  >
                    <SupervisorAccountIcon />
                    &nbsp; Live Speaker
                  </div>
                  <div className={styles.radioDesc}>
                    Classroom (Lecture / Hands-on) / Livestream
                  </div>
                </div>
              }
              labelPlacement="top"
            />
          </div>
          <div
            className={
              props.isVideo
                ? styles.radioSelectedContainer
                : styles.radioContainer
            }
          >
            <FormControlLabel
              value="video"
              control={<Radio color="primary" />}
              label={
                <div className={styles.center}>
                  <div
                    className={props.isVideo ? styles.rowSelected : styles.row}
                  >
                    <OndemandVideoIcon />
                    &nbsp; Recorded Video
                  </div>
                  <div className={styles.radioDesc}>
                    Instant available video content
                  </div>
                </div>
              }
              labelPlacement="top"
            />
          </div>
        </RadioGroup>
      </FormControl>
    </div>
  );
};

export default MatRadioGroup;
