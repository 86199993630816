import React from "react";
import MUIDataTable from "mui-datatables";
import styles from "./StudentTable.module.css";
import TableFooter from "@material-ui/core/TableFooter";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Tooltip from "@material-ui/core/Tooltip";
import HowToRegIcon from "@material-ui/icons/HowToReg";
import CustomToolbar from "./StudentDefaultToolbar";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import viewOrderImg from "../../../assets/images/inApp/vieworder.png";
import sendCertImg from "../../../assets/images/inApp/sendcert.png";
import SendOutlinedIcon from "@material-ui/icons/SendOutlined";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import ReplayIcon from "@material-ui/icons/Replay";

const StudentTable = (props) => {
  const display = props.display ? props.display : {
    name: true, orderRefNumber: true, license: true, occupation: true,
    member_id: true, date: false
  }

  const columns = [
    {
      name: "name",
      label: "Name",
      options: {
        filter: false,
        sort: true,
        display: display["name"],
      },
    },
    {
      name: "member_id",
      label: "Member ID",
      options: {
        filter: false,
        sort: true,
        display: display["member_id"],
        customBodyRender: (value, tableMeta, updateValue) => {
          return value ?
            <div style={{ backgroundColor: "green", borderRadius: "10px", padding: "3px", color: "white", textAlign: "center" }}>{value}</div> : <div>{value}</div>
        }
      },
    },
    {
      name: "orderRefNumber",
      label: "Order#",
      options: {
        filter: false,
        sort: false,
        display: display["orderRefNumber"],
      },
    },
    {
      name: "license",
      label: "License",
      options: {
        filter: false,
        sort: true,
        display: display["license"],
      },
    },
    {
      name: "occupation",
      label: "Occupation",
      options: {
        filter: true,
        sort: true,
        display: display["occupation"],
      },
    },

    {
      name: "date",
      label: "Registration Date",
      options: {
        filter: false,
        sort: true,
        display: display["date"],
      },
    },
  ];

  // ------------------------------

  const options = {
    selectableRowsHideCheckboxes: true,
    selectableRowsHeader: false,
    fixedHeader: true,
    response: "scroll",
    pagination: false,
    rowsPerPage: 100000000,
    rowsPerPageOptions: [],
    count: props.data.length,
    expandableRowsOnClick: true,
    viewColumns: true,
    expandableRows: true,


    onTableChange: (action, tableState) => {
      if (action === "viewColumnsChange") {
        props.updateSettingColumns(tableState.columns) // array display, name keys
      }
    },

    isRowSelectable: (dataIndex, selectedRows, data) => {
      if (props.data[dataIndex].isUnregistered === true) {
        return false;
      }
      return true;
    },

    renderExpandableRow: (rowData, rowMeta) => {
      const dataIndex = rowMeta.dataIndex;

      return (
        <TableRow>
          <TableCell colSpan={7}>
            <div className={styles.expandedRowContainer}>
              <div className={styles.expandedRowColumnMain}>
                <div className={styles.detailsHeader}>
                  Details for {props.data[dataIndex].first}
                </div>

                <div className={styles.row}>
                  <div className={styles.label}>
                    &nbsp;Registration Option:
                  </div>
                  <div className={styles.value}>
                    {props.data[dataIndex].registrationOption}
                  </div>
                </div>

                <div className={styles.row}>
                  <div className={styles.label}>
                    &nbsp;Registered By:
                  </div>
                  <div className={styles.value}>
                    {props.data[dataIndex].registeredByName}
                  </div>
                </div>

                {props.data[dataIndex].survey_sent && (
                  <div className={styles.rowTop}>
                    <div className={styles.label}>
                      &nbsp;Survey Status:
                    </div>
                    <div>
                      <div className={styles.value}>
                        {props.data[dataIndex].survey_sent && (
                          <MailOutlineIcon style={{ paddingRight: "5px" }} />
                        )}

                        {props.data[dataIndex].survey_sent &&
                          " Sent on " +
                          props.data[dataIndex].format_surve_date()}

                        {props.data[dataIndex].survey_sent &&
                          !props.data[dataIndex].survey_completed && (
                            <div
                              className={styles.link}
                              onClick={() => {
                                props.resendSurvey(props.data[dataIndex]);
                              }}
                            >
                              &nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;Resend
                              Survey&nbsp;
                              <ReplayIcon />
                            </div>
                          )}
                      </div>
                      <div className={styles.value}>
                        {props.data[dataIndex].survey_completed && (
                          <HowToRegIcon style={{ paddingRight: "5px" }} />
                        )}

                        {props.data[dataIndex].survey_completed &&
                          " Completed on " +
                          props.data[
                            dataIndex
                          ].format_survey_completed_date()}
                      </div>
                    </div>
                  </div>
                )}

                {props.data[dataIndex].certificate_sent && (
                  <div className={styles.row}>
                    <div className={styles.label}>
                      &nbsp;Certificate Status:
                    </div>
                    <div className={styles.value}>
                      {props.data[dataIndex].certificate_sent && (
                        <MailOutlineIcon style={{ paddingRight: "5px" }} />
                      )}

                      {props.data[dataIndex].certificate_sent &&
                        " Sent on " +
                        props.data[dataIndex].format_certificate_date()}
                    </div>
                  </div>
                )}
              </div>

              <div className={styles.expandedRowColumn}>
                <Tooltip title="Send Certificate" aria-label="Send Certificate">
                  <div
                    className={styles.imageTextContainer}
                    onClick={props.issueCert.bind(this, [
                      props.data[dataIndex],
                    ])}
                  >
                    <img
                      src={sendCertImg}
                      alt=""
                      className={styles.imageButton}
                    />
                    <div className={styles.title}>
                      Send Certificate&nbsp;
                      <SendOutlinedIcon fontSize="inherit" />
                    </div>
                  </div>
                </Tooltip>
              </div>

              <div className={styles.expandedRowColumn}>
                <Tooltip
                  title="View Order Details"
                  aria-label="View Order Details"
                >
                  <div
                    className={styles.imageTextContainer}
                    onClick={() => {
                      props.editStudentOrder(props.data[dataIndex]);
                    }}
                  >
                    <img
                      src={viewOrderImg}
                      alt=""
                      className={styles.imageButton}
                    />
                    <div className={styles.title}>
                      View Order Details&nbsp;
                      <EditOutlinedIcon fontSize="inherit" />
                    </div>
                  </div>
                </Tooltip>
              </div>
            </div>
          </TableCell>
        </TableRow>
      );
    },

    customFooter: (count) => {
      const countUnregistered = props.data.filter(
        (d) => d.isUnregistered
      ).length;

      return (
        <TableFooter>
          <TableRow>
            <TableCell colSpan={1000}>
              Total Registered: {count - countUnregistered} / Total
              Unregistered: {countUnregistered}
            </TableCell>
          </TableRow>
        </TableFooter>
      );
    },

    customToolbar: () => {
      return (
        <CustomToolbar
          hasAttendance={props.hasAttendance}
          isAttendanceActive={props.isAttendanceActive}
          setAttendanceActive={props.setAttendanceActive}
          takeAttendance={props.takeAttendance}
          messageAll={props.messageAll}
          issueCert={props.issueCert.bind(this, props.data)}
        />
      );
    },

    setRowProps: (row, dataIndex, rowIndex) => {
      if (props.data[dataIndex].isUnregistered === true) {
        return {
          style: { opacity: 0.5 },
        };
      }
    }
  };

  // ------------------------------

  const prepForTableGrid = (students) => {
    let convertedArr = [];
    for (const student of students) {
      let unregistered = student.isUnregistered ? " (Unregistered)" : "";
      convertedArr.push({
        name: student.last + ", " + student.first + unregistered,
        member_id: student.member_id,
        orderRefNumber: student.orderRefNumber,
        license: student.license,
        occupation: student.occupation,
        date: student.format_registered_date(),
      });
    }

    return convertedArr;
  };

  // ------------------------------

  return (
    <MUIDataTable
      title={props.data ? props.data.length + " " + props.title : props.title}
      data={prepForTableGrid(props.data)}
      columns={columns}
      options={options}
    />
  );
};

export default StudentTable;
