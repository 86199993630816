import React, { useEffect, useState } from "react";
import styles from "./Staff.module.css";
import { useTranslation } from "react-i18next";
import * as lodashCloneDeep from "lodash.clonedeep";
import MatInputOutline from "../../../components/ui/input/MatInputOutline";
import MatSelectOutline from "../../../components/ui/labelSelect/MatSelectOutline";
import { hasError } from "../../../globals/Validations";
import { validateField } from "../../../globals/Validations";
import { translate } from "../../../globals/Common";
import { selectSignupOccupation } from "../../../globals/Data";

const StaffEditForm = (props) => {
  const { t } = useTranslation();
  const [transOcc, setTransOcc] = useState([]);

  // ------------------------------

  const validate = async (field, validatorKey) => {
    if (field === null) {
      await props.updateHasErrors(props.formErrors);
    } else {
      const errors = validateField(
        props.staffMember,
        props.formErrors,
        field,
        validatorKey
      );

      await props.updateFormErrors(errors);
    }
  };

  // ------------------------------

  useEffect(() => {
    const translatedOccupation = [];

    for (const occ of selectSignupOccupation) {
      const copyOcc = { ...occ };
      copyOcc.label = translate(t, copyOcc.label);
      translatedOccupation.push(copyOcc);
    }

    translatedOccupation.sort((a, b) =>
      a.label.toLowerCase() > b.label.toLowerCase() ? 1 : -1
    );

    setTransOcc(translatedOccupation);
  }, []);

  // ------------------------------

  const inputChangeHandler = (e) => {
    const copyStaffMember = lodashCloneDeep(props.staffMember);
    copyStaffMember[e.target.name] = e.target.value;
    props.update(copyStaffMember);
  };

  // ------------------------------

  const selectChangeHandler = (key, e, select) => {
    const copyStaffMember = lodashCloneDeep(props.staffMember);
    copyStaffMember[key] = select.value;
    props.update(copyStaffMember);
  };

  // ------------------------------

  return (
    <form className={styles.form}>
      <MatInputOutline
        notopmargin="true"
        title={t("g_license_number")}
        name="license"
        value={props.staffMember.license}
        onChange={inputChangeHandler}
        maxLength={1000}
        readOnly={false}
      />
      <MatSelectOutline
        noTopMargin
        title={t("g_occupation")}
        placeholder={t("settings_reg_profile_enter_occ")}
        name="occupation"
        id="occupation"
        onChange={selectChangeHandler.bind(this, "occupation")}
        options={transOcc}
        value={
          props.staffMember.occupation
            ? {
                value: props.staffMember.occupation + "",
                label: translate(t, props.staffMember.occupation),
              }
            : null
        }
        getOptionLabel={(option) => option.label}
        getOptionSelected={(opt1, opt2) => opt1.value === opt2.value}
        onBlur={validate.bind(this, "occupation", "required")}
        error={hasError(props.formErrors.occupation)}
        helperText={props.formErrors.occupation}
        required={true}
      />
      <MatInputOutline
        notopmargin="true"
        title={t("g_first_name")}
        name="first"
        value={props.staffMember.first}
        onChange={inputChangeHandler}
        onBlur={validate.bind(this, "first", "plainRequired")}
        maxLength={1000}
        error={hasError(props.formErrors.first)}
        helpertext={props.formErrors.first}
        readOnly={false}
        required
      />
      <MatInputOutline
        notopmargin="true"
        title={t("g_last_name")}
        name="last"
        value={props.staffMember.last}
        onChange={inputChangeHandler}
        onBlur={validate.bind(this, "last", "plainRequired")}
        maxLength={1000}
        error={hasError(props.formErrors.last)}
        helpertext={props.formErrors.last}
        readOnly={false}
        required
      />
      <MatInputOutline
        notopmargin="true"
        title={t("g_email")}
        name="email"
        value={props.staffMember.email}
        onChange={inputChangeHandler}
        onBlur={validate.bind(this, "email", "email")}
        maxLength={200}
        error={hasError(props.formErrors.email)}
        helpertext={props.formErrors.email}
        readOnly={false}
        required
      />
    </form>
  );
};

export default StaffEditForm;
