import React, { Component } from "react";
import styles from "./DashboardScreen.module.css";
import { connect } from "react-redux";
import * as profileActions from "../../store/actions/profile";
import * as connectActions from "../../store/actions/connect";
import * as settingActions from "../../store/actions/setting";
import * as alertActions from "../../store/actions/alert";
import Spinner from "../../components/ui/spinner/Spinner";
import coursesImg from "../../assets/images/inApp/dash_courses.png";
import accountImg from "../../assets/images/inApp/dash_account.png";
import advertiseImg from "../../assets/images/inApp/dash_advertise.png";
import statsImg from "../../assets/images/inApp/dash_stats.png";
import { isConnectAccountCreated } from "../../globals/Common";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import * as logger from "../../globals/Logger";
import IconButton from "@material-ui/core/IconButton";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import Collapse from "@material-ui/core/Collapse";
import Tooltip from "@material-ui/core/Tooltip";
import InfoModal from "../../components/modals/infoModal/InfoModal";

class DashboardScreen extends Component {
  state = {
    wait: false,
    error: null,
    warn: null,
    warnMessage: null,
    waitMessage: "Loading Dashboard",
  };

  // ------------------------------

  update = async (propValue) => {
    await this.setState({ ...this.state, ...propValue });
  };

  // ------------------------------

  componentDidMount = async () => {
    try {
      window.scrollTo(0, 0);

      await this.update({ error: null, wait: true });

      const loadAsync = [];

      loadAsync.push(this.props.fetchAlert());

      if (!this.props.profile || Object.keys(this.props.profile).length === 0) {
        loadAsync.push(this.props.fetchProfile());
      }

      if (!this.props.setting || Object.keys(this.props.setting).length === 0) {
        loadAsync.push(this.props.fetchSetting());
      }

      if (
        this.props.hasConnectAccount &&
        Object.keys(this.props.account).length === 0
      ) {
        // noasync
        this.props.fetchAccount().then(function () {});

        if (!this.props.total) {
          this.props.fetchStripeBalance().then(function () {});
        }
      }

      await Promise.all(loadAsync);

      await this.update({ wait: false });
    } catch (error) {
      logger.error(error);
      await this.update({ wait: false, error: error.message });
    }
  };

  // ------------------------------

  coursesHandler = () => {
    this.props.history.push("/courses");
  };

  // ------------------------------

  accountHandler = () => {
    this.props.history.push("/account");
  };

  // ------------------------------

  statsHandler = () => {
    this.props.history.push("/ganalytics");
  };

  // ------------------------------

  advertiseHandler = () => {
    this.props.history.push("/advertise");
  };

  // ------------------------------

  closeErrorHandler = () => {
    this.update({
      error: null,
    });
  };

  // ------------------------------

  render() {
    if (this.state.wait) {
      return <Spinner message={this.state.waitMessage} />;
    }

    return (
      <div className={styles.screen}>
        <InfoModal
          isErrorModal={true}
          title="An error has occured"
          message={this.state.error}
          open={this.state.error !== null}
          onClose={this.closeErrorHandler}
        />

        <div
          className={styles.welcomeTitle}
          onClick={() => {
            this.props.setShowDashboard(!this.props.showDashboard);
          }}
        >
          Dashboard
          <Tooltip title="Show / Hide" aria-label="Show / Hide">
            <IconButton
              color="primary"
              onClick={() => {
                this.props.setShowDashboard(!this.props.showDashboard);
              }}
            >
              {this.props.showDashboard ? (
                <KeyboardArrowUpIcon fontSize="inherit" />
              ) : (
                <KeyboardArrowDownIcon fontSize="inherit" />
              )}
            </IconButton>
          </Tooltip>
        </div>

        <Collapse in={this.props.showDashboard}>
          <div className={styles.description}>
            From this page, you can access <b>My Courses</b> to create your
            courses and activate them on ZubU marketplace, view your
            <b>&nbsp;Account Balance</b>, monitor
            <b>&nbsp;Analytics</b> and purchase<b>&nbsp;Advertising</b> for
            active courses.
          </div>

          <div className={styles.description}>
            <br></br>
            Before you begin, we suggest the following steps:
          </div>

          <ol className={styles.description}>
            <li className={styles.li}>
              Complete <b>My Profile</b> in <b>Settings</b>. Save your{" "}
              <u>company logo</u>, <u>digital signature</u> and{" "}
              <u>cancellation policy</u>, <u>storefront photo</u> and{" "}
              <u>company description</u>. Company logo will be visible on your
              activated courses, certificates of completion and storefront.
              Digital signature will be used when issuing student course
              completion certificates. Your cancellation policy is{" "}
              <u>
                <b>mandatory</b>
              </u>{" "}
              in order to activate a course on the platform.
            </li>

            <li className={styles.li}>
              Create a list of your most commonly used speakers / professors
              along with their biographies by selecting <b>Professors</b> from
              the menu.
            </li>

            <li className={styles.li}>
              Create a branded certificate of completion by selecting{" "}
              <b>Certificate</b> from the menu.
            </li>

            <li className={styles.li}>
              Create a course survey by selecting <b>Survey</b> from the menu.
              You can create as many as you like and attach them to specific
              courses during course creation.
            </li>

            <li className={styles.li}>
              Add a <b>Payment</b> method to your account. This will allow you
              to purchase advertising for active courses.
            </li>

            <li className={styles.li}>
              Start creating and activating courses via <b>My Courses</b>.
            </li>

            <li className={styles.li}>
              <b>Advertise</b> your course to increase your visibility. Take
              advantage of the ZubU platform and purchase advertising space as
              either a fixed Ad on the ZubU mobile app's home page or as a
              featured course.
            </li>
          </ol>
        </Collapse>

        <div className={styles.imageWrapper}>
          <div className={styles.imageContainer}>
            <div
              className={styles.imageTextContainer}
              onClick={this.coursesHandler}
            >
              <img className={styles.image} src={coursesImg} alt="" />
              <div className={styles.title}>My Courses</div>
            </div>

            <div
              className={styles.imageTextContainer}
              onClick={this.accountHandler}
            >
              <img className={styles.image} src={accountImg} alt="" />
              <div className={styles.title}>Account Balance</div>
            </div>

            <div
              className={styles.imageTextContainer}
              onClick={this.statsHandler}
            >
              <img className={styles.image} src={statsImg} alt="" />
              <div className={styles.title}>Analytics</div>
            </div>

            <div
              className={styles.imageTextContainer}
              onClick={this.advertiseHandler}
            >
              <img className={styles.image} src={advertiseImg} alt="" />
              <div className={styles.title}>Advertise</div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    profile: state.profile.profile,
    setting: state.setting.setting,
    account: state.connect.account,
    total: state.connect.total,
    hasConnectAccount: isConnectAccountCreated(state.auth.connectId),
    showDashboard:
      state.setting.setting.show_dashboard === false ? false : true,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchAlert: () => dispatch(alertActions.fetchAlert()),
    fetchSetting: () => dispatch(settingActions.fetchSetting()),
    fetchProfile: () => dispatch(profileActions.fetchProfile()),
    fetchAccount: () => dispatch(connectActions.fetchAccount()),
    setShowDashboard: (show) => dispatch(settingActions.setShowDashboard(show)),
    fetchStripeBalance: () => dispatch(connectActions.fetchStripeBalance()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DashboardScreen);
