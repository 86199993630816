import MultiPayOption from "./MultiPayOption";
import {
  momentShortDate,
  remainingDaysTime,
  momentTinyDate,
} from "../globals/Dates2";

const defaultMulti = new MultiPayOption(false, 30, 3);

class Course {
  constructor(
    id,
    activation,
    reference_number,
    provider_id,
    provider_name,
    is_specialty,
    category,
    accreditation,
    title,
    description,
    start_date_desc,
    start_date,
    end_date,
    objectives,
    professor,
    location,
    address,
    notes,
    is_university_associated,
    language,
    seats_classroom,
    seats_online,
    suite,
    email,
    geo_lat,
    geo_lng,
    geo_hash,
    cancel_policy,
    pricingItems,
    amendment,
    timezoneAbbr,
    seats_handson,
    is_video,
    email_instruction,
    survey,
    youtube_uri,
    activation_status,
    multi_pay_option,
    handout,
    handout_delivery,
    meeting_id,
    rating,
    rating_count,
    provider_currency,
    certificate,
    image,
    topic_number,
    video_chapters
  ) {
    this.id = id;
    this.activation = activation;
    this.reference_number = reference_number ? reference_number : "";
    this.provider_id = provider_id;
    this.provider_name = provider_name ? provider_name : "";
    this.is_specialty = is_specialty ? is_specialty : false;
    this.category = category ? category : [];
    this.accreditation = accreditation ? accreditation : [];
    this.title = title ? title : "";
    this.description = description ? description : "";
    this.start_date_desc = start_date_desc;
    this.start_date = start_date && start_date.length > 0 ? start_date : [];
    this.end_date = end_date;
    this.objectives = objectives ? objectives : "";
    this.professor = professor ? professor : [];
    this.location = location ? location : "";
    this.address =
      address && address.length > 0 ? address : ["", "", "", "", "", ""];
    this.notes = notes ? notes : "";
    this.is_university_associated = is_university_associated
      ? is_university_associated
      : false;
    this.language = language ? language : "English";
    this.seats_classroom =
      seats_classroom || seats_classroom === 0 ? seats_classroom : -1;
    this.seats_online = seats_online || seats_online === 0 ? seats_online : -1;
    this.suite = suite ? suite : "";
    this.email = email ? email : "";
    this.geo_lat = geo_lat ? geo_lat : -1;
    this.geo_lng = geo_lng ? geo_lng : -1;
    this.geo_hash = geo_hash ? geo_hash : "";
    this.cancel_policy = cancel_policy ? cancel_policy : "";
    this.pricingItems = pricingItems ? pricingItems : [];
    this.amendment = amendment ? amendment : [];
    this.timezoneAbbr = timezoneAbbr ? timezoneAbbr : "";
    this.seats_handson =
      seats_handson || seats_handson === 0 ? seats_handson : -1;
    this.is_video = is_video ? is_video : false;
    this.email_instruction = email_instruction ? email_instruction : "";
    this.survey = survey ? survey : null;
    this.youtube_uri = youtube_uri ? youtube_uri : "";
    this.activation_status = activation_status ? activation_status : "";
    this.multi_pay_option = multi_pay_option ? multi_pay_option : defaultMulti;
    this.handout = handout ? handout : [];
    this.handout_delivery = handout_delivery ? handout_delivery : "manual";
    this.meeting_id = meeting_id;
    this.rating = rating;
    this.rating_count = rating_count;
    this.provider_currency = provider_currency;
    this.certificate = certificate;
    this.image = image;
    this.topic_number = topic_number;
    this.video_chapters = video_chapters ? video_chapters : [];

    // -----
    this.s_l_cost = Number.POSITIVE_INFINITY;
    this.s_cost = Number.NEGATIVE_INFINITY;
    let tmp;
    for (let i = this.pricingItems.length - 1; i >= 0; i--) {
      tmp = this.pricingItems[i].resolveCost(this.amendment, i);
      if (tmp < this.s_l_cost) this.s_l_cost = tmp;
      if (tmp > this.s_cost) this.s_cost = tmp;
    }
    // -----
  }

  // ------------------------------

  resolve_ce = () => {
    return this.pricingItems[0].ce_odq > 0
      ? this.pricingItems[0].ce_odq
      : this.pricingItems[0].credit_lecture_hours +
        this.pricingItems[0].credit_handson_hours >
        0
        ? this.pricingItems[0].credit_lecture_hours +
        this.pricingItems[0].credit_handson_hours
        : 0;
  };

  // ------------------------------

  resolve_display_cost = (prefix, low, high) => {
    const format_lower = Number.isInteger(low) ? low : Math.ceil(low);
    const format_upper = Number.isInteger(high) ? high : Math.ceil(high);

    if (low === high) {
      return prefix + format_lower;
    }

    return prefix + format_lower + "-" + format_upper;
  };

  // ------------------------------

  lowest_cost = () => {
    return this.s_l_cost;
  };

  // ------------------------------

  highest_cost = () => {
    return this.s_cost;
  };

  resolve_handson_seats = () => {
    if (this.seats_handson === -2) {
      return "Unlimited";
    }

    if (this.seats_handson === -1) {
      return "N/A";
    }

    return this.seats_handson ? this.seats_handson : 0;
  };

  // ------------------------------

  resolve_classroom_seats = () => {
    if (this.seats_classroom === -2) {
      return "Unlimited";
    }

    if (this.seats_classroom === -1) {
      return "N/A";
    }

    return this.seats_classroom ? this.seats_classroom : 0;
  };

  // ------------------------------

  resolve_online_seats = () => {
    if (this.seats_online === -2) {
      return "Unlimited";
    }

    if (this.seats_online === -1) {
      return "N/A";
    }

    return this.seats_online ? this.seats_online : 0;
  };

  // ------------------------------

  resolveDaysRemaining = () => {
    return remainingDaysTime(this.start_date[0]);
  };

  // ------------------------------

  short_start_date = () => {
    if (this.start_date.length > 0) {
      return momentTinyDate(this.start_date[0]);
    }

    return "TBD";
  };

  // ------------------------------

  format_start_date = () => {
    if (this.start_date && this.start_date.length > 0) {
      return momentShortDate(this.start_date[0]);
    }

    return "";
  };

  // ------------------------------

  resolveAddress = () => {
    let suiteText = "";
    if (this.suite.length > 0) {
      suiteText = " (Suite# " + this.suite + ")";
    }

    return this.address.join(" ").trimLeft() + suiteText;
  };

  // ------------------------------

  renderProfessor = () => {
    const arr = [];
    for (const prof of this.professor) {
      arr.push(prof.fullName());
    }
    return arr.join(", ");
  };

  // ------------------------------

  resolveAccreditation = () => {
    return this.accreditation.join(", ");
  };

  // ------------------------------

  isVideoString = () => {
    return this.is_video ? "video" : "live";
  };

  // ------------------------------

  onlineSeatsAvailable = () => {
    return this.seats_online === -2 || this.seats_online > 0;
  };

  // ------------------------------

  classroomSeatsAvailable = () => {
    return this.seats_classroom > 0;
  };

  // ------------------------------

  classroomHandsonSeatsAvailable = () => {
    return this.seats_handson > 0;
  };

  // ------------------------------

  googleMapUrl = () => {
    return "https://www.google.com/maps/place/" + this.address.join("+");
  };

  // ------------------------------

  avatar = () => {
    if (this.professor.length === 0) {
      return null;
    }
    return this.professor[0].picture;
  };
}

export default Course;
