import React, { useState } from "react";
import { algoliaAppKey, algoliaSearchKey } from "../../../globals/ApiKeys";

import { useTranslation } from "react-i18next";
import algoliasearch from "algoliasearch/lite";

import { getAlgoliaResults } from "@algolia/autocomplete-js";
import AlgoliaAutocomplete from "../../../components/ui/algoliaAutocomplete/AlgoliaAutocomplete";
import AlgoliaProductItem from "../../../components/ui/algoliaAutocomplete/AlgoliaProductItem";
import useMediaQuery from "@material-ui/core/useMediaQuery";

const searchClient = algoliasearch(algoliaAppKey, algoliaSearchKey);

const AlgoliaSearch = (props) => {
  const { t } = useTranslation();
  const matches = useMediaQuery("(max-width:699px)");

  return (
    <div
      style={{
        flex: 1,
        paddingLeft: "20px",
        paddingRight: "20px",
      }}
    >
      <AlgoliaAutocomplete
        initialState={
          { query: props.query ? props.query : "" }
        }
        openOnFocus={true}
        detachedMediaQuery={matches ? "" : "none"}
        placeholder={matches ? t("g_bar_search") : t("g_search_by")}
        onStateChange={({ state }) => { }}
        getSources={({ query }) => [
          {
            onSelect: (selected) => {
              if (selected && selected.item) {
                setTimeout(() => {
                  props.onSelect(
                    selected.item.objectID,
                    selected.item.provider_id,
                    selected.item.title
                  );
                }, 100);
              }
            },
            sourceId: "courses",
            getItems() {
              let refineQuery = query;

              if (props.providerName) {
                refineQuery = props.providerName + " " + query;
              }

              return getAlgoliaResults({
                searchClient,
                queries: [
                  {
                    query: refineQuery,
                    indexName: "courses",
                  },
                ],
              })
            },
            templates: {
              item({ item, components }) {
                return (
                  <AlgoliaProductItem hit={item} components={components} />
                )
              },
              noResults(res) {
                return `${t("course_search_not_found")} "${res.state.query}"`;
              },
            },
          },
        ]}
      />
    </div>
  );
};

export default AlgoliaSearch;
