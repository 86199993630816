import React, { Fragment } from "react";
import styles from "./AmendmentItem.module.css";
import DescriptionIcon from "@material-ui/icons/Description";
import { momentLongDate } from "../../../globals/Dates2";
import ProfessorColapseItem from "../../../components/items/professorColapseItem/ProfessorColapseItem";

const AmendmentItem = (props) => {
  return (
    <Fragment>
      <div className={styles.phoneContentRow}>
        <div className={styles.amendedWarning}>
          <DescriptionIcon style={jss.iconsmr} />
          Amendments made on{" "}
          {momentLongDate(
            props.amendment.amended_date
              ? props.amendment.amended_date
              : new Date()
          )}
        </div>
      </div>

      {/* Professor */}
      {props.amendment.professor.map((p, index) => (
        <ProfessorColapseItem professor={p} key={p.id} noHr isAmendment />
      ))}

      {/* Course Date */}

      {props.amendment.start_date && (
        <div className={styles.phoneContentRow}>
          <div className={styles.phoneContentInnerRow}>
            <div className={styles.phoneContentTitleAmend}>Date:</div>
            <div className={styles.phoneContentNotesAmend}>
              {momentLongDate(props.amendment.start_date)}
              <div className={styles.italic}>
                (Schedule times may or may not be affected by the date
                amendment, please verfiy any notes or validate with your course
                provider.)
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Notes */}
      {props.amendment.notes && (
        <div className={styles.phoneContentRow}>
          <div className={styles.phoneContentInnerRow}>
            <div className={styles.phoneContentTitleAmend}>Notes:</div>
            <div className={styles.phoneContentNotesAmend}>
              {props.amendment.notes}
            </div>
          </div>
        </div>
      )}

      {/* Price changes */}
      {props.amendment.discount && props.amendment.discount.length > 0 && (
        <div className={styles.phoneContentRow}>
          <div className={styles.phoneContentInnerRow}>
            <div className={styles.phoneContentTitleAmend}>Price:</div>
            <div className={styles.phoneContentNotesAmend}>
              See price changes below
            </div>
          </div>
        </div>
      )}

      <hr></hr>
    </Fragment>
  );
};

// ------------------------------

const jss = {
  iconsmr: {
    fontSize: "20px",
    color: "red",
    marginRight: "5px",
  },
};

export default AmendmentItem;
