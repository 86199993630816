import React, { Component } from "react";
import styles from "./ForgotPasswordScreen.module.css";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import validate from "validate.js";
import * as authActions from "../../store/actions/auth";
import Spinner from "../../components/ui/spinner/Spinner";
import InfoModal from "../../components/modals/infoModal/InfoModal";
import Footer from "../../components/ui/footer/Footer";
import FormButton from "../../components/ui/button/FormButton";
import MatInput from "../../components/ui/input/MatInput";
import MatAppBar from "../../components/ui/appBar/MatAppBar";
import * as logger from "../../globals/Logger";
import LogoMenuBar from "../../components/ui/logo/LogoMenuBar";
import {
  getConstraints,
  hasFormErrors,
  hasError,
} from "../../globals/Validations";

class ForgotPasswordScreen extends Component {
  state = {
    email: "",
    error: null,
    wait: false,
    hasErrors: false,
    formErrors: {},
  };

  // ------------------------------

  update = async (propValue) => {
    await this.setState({ ...this.state, ...propValue });
  };

  // ------------------------------

  sendEmailResetHandler = async () => {
    try {
      await this.validateForm();

      if (this.state.hasErrors) {
        await this.update({
          error: this.props.t("forgot_password_please_enter_valid_email"),
        });
        return;
      }

      this.update({ wait: true });
      await this.props.forgotPassword(this.state.email);
      this.props.history.goBack();
    } catch (err) {
      logger.error(err);
      this.update({ wait: false, error: err.message });
    }
  };

  // ------------------------------

  validateForm = async () => {
    const e0 = this.state.formErrors;
    const e1 = this.validateField(e0, "email", "email");
    await this.update({ formErrors: e1 });
    await this.hasErrorsHandler(e1);
  };

  // ------------------------------

  validateHandler = async (field, validatorKey) => {
    if (field === null) {
      await this.hasErrorsHandler(this.state.formErrors);
    } else {
      const errors = this.validateField(
        this.state.formErrors,
        field,
        validatorKey
      );
      await this.update({ formErrors: errors }); // await is crutial here
      await this.hasErrorsHandler(errors);
    }
  };

  // ------------------------------

  validateField = (paramFormErrors, field, validatorKey) => {
    let validateObj = {};
    validateObj[validatorKey] = this.state[field];
    const validationResponse = validate(
      validateObj,
      getConstraints(this.props.t)
    );
    const updateFormErrors = { ...paramFormErrors };

    if (validationResponse[validatorKey]) {
      const firstError = validationResponse[validatorKey][0];
      updateFormErrors[field] = firstError;
    } else {
      delete updateFormErrors[field];
    }
    return updateFormErrors;
  };

  // ------------------------------

  hasErrorsHandler = async (formErrors) => {
    await this.update({ hasErrors: hasFormErrors(formErrors, {}) });
  };

  // ------------------------------

  inputChangeHandler = (e) => {
    const value = e.target.value;
    this.setState({
      ...this.state,
      [e.target.name]: value,
    });
  };

  // ------------------------------

  render() {
    const loginStyle = {
      color: "gray",
      fontSize: "21px",
      fontFamily: "Inter",
    };

    if (this.state.wait) {
      return <Spinner />;
    }

    return (
      <div className={styles.screen}>
        {this.state.error !== null && (
          <InfoModal
            isErrorModal={true}
            title={this.props.t("error_occurred")}
            message={this.state.error}
            open={this.state.error !== null}
            onClose={() => {
              this.update({ error: null });
            }}
          />
        )}

        <MatAppBar
          static
          classBar={{
            position: "fixed",
            top: 0,
            background: "white",
            zIndex: 115,
          }}
          left={<LogoMenuBar onClick={this.props.history.goBack} />}
        />

        <div className={styles.formAndFooter}>
          <div className={styles.form}>
            <div className={styles.title}>
              {this.props.t("forgot_password")}
            </div>

            <div className={styles.subTitle}>
              {this.props.t("forgot_password_send_reset_description")}
            </div>

            <MatInput
              title={this.props.t("g_email")}
              value={this.state.email}
              name="email"
              id="email"
              onChange={this.inputChangeHandler}
              onBlur={this.validateHandler.bind(this, "email", "email")}
              maxLength={200}
              error={hasError(this.state.formErrors.email)}
              helperText={this.state.formErrors.email}
              required
            />

            <FormButton
              onClick={this.sendEmailResetHandler}
              label={this.props.t("forgot_password_send_reset")}
              style={{ width: "100%", paddingRight: "15px" }}
              disabled={this.state.hasErrors}
            />
          </div>
          <Footer history={this.props.history} />
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    forgotPassword: (email) => dispatch(authActions.forgotPassword(email)),
  };
};

export default withTranslation("translations")(
  connect(null, mapDispatchToProps)(ForgotPasswordScreen)
);
