import React from "react";
import styles from "../Legal.module.css";

export const renderGeneralTermsOfUseFr = () => {
  return (
    <div>
      <p className={styles.title}>
        <strong>
          <u>CONDITIONS G&Eacute;N&Eacute;RALES D&rsquo;UTILISATION</u>
        </strong>
      </p>

      <p>
        Les pr&eacute;sentes Conditions G&eacute;n&eacute;rales
        d&rsquo;Utilisation (&laquo;&nbsp;
        <strong>Conditions G&eacute;n&eacute;rales d&rsquo;Utilisation</strong>
        &nbsp;&raquo; ou &laquo;&nbsp;<strong>CGU</strong>&nbsp;&raquo;)
        r&eacute;gissent la relation juridique entre ZubU inc. (&laquo;&nbsp;
        <strong>ZubU</strong>&nbsp;&raquo;) et tous les Utilisateurs de la
        Plateforme. Si un Utilisateur ne souhaite pas accepter le Contrat, il ne
        doit pas utiliser ou acc&eacute;der &agrave; la Plateforme.
      </p>
      <p>
        Tous les Prestataires de Formation et Conf&eacute;renciers doivent
        &eacute;galement accepter les Conditions Sp&eacute;cifiques des
        Fournisseurs, qui s&rsquo;appliquent en plus de ces Conditions
        G&eacute;n&eacute;rales d&rsquo;Utilisation.
      </p>
      <p>
        <strong>
          En acceptant ces Conditions G&eacute;n&eacute;rales
          d&rsquo;Utilisation, chaque Utilisateur renonce &agrave; certains
          droits de d&rsquo;exercer ou de participer &agrave; des recours
          collectifs et de r&eacute;soudre des conflits avec ZubU par la
          m&eacute;diation (c&rsquo;est-&agrave;-dire, &agrave; moins
          d&rsquo;exceptions limit&eacute;es, autrement que devant les
          tribunaux), tel que d&eacute;taill&eacute; &agrave;
          l&rsquo;Article&nbsp;
        </strong>
        <strong>9</strong>
        <strong> (R&eacute;solution des diff&eacute;rends).</strong>
      </p>
      <ol>
        <li className={styles.listItem}>
          <strong>D&Eacute;FINITIONS</strong>
        </li>
      </ol>
      <p>
        &laquo;<strong>&nbsp;B&eacute;n&eacute;ficiaire&nbsp;</strong>&raquo;
        est d&eacute;fini au paragraphe 2.1.
      </p>
      <p>
        &laquo;<strong>&nbsp;Compte&nbsp;</strong>&raquo; d&eacute;signe un
        compte Utilisateur cr&eacute;&eacute; et maintenu sur la Plateforme
        conform&eacute;ment aux pr&eacute;sentes Conditions
        G&eacute;n&eacute;rales d&rsquo;Utilisation.
      </p>
      <p>
        &laquo;<strong>&nbsp;Conf&eacute;rencier&nbsp;</strong>&raquo;
        d&eacute;signe un professeur ou un conf&eacute;rencier qui fournit du
        Contenu Acad&eacute;mique pour un Prestataire de Formation.
      </p>
      <p>
        &laquo;<strong>&nbsp;</strong>
        <strong>Contenu Acad&eacute;mique&nbsp;</strong>&raquo; d&eacute;signe
        une formation ou tout autre forme de contenu publi&eacute; par un
        Prestataire de Formation annonc&eacute; sur la Plateforme et/ou dont
        l&rsquo;inscription est disponible &agrave; l&rsquo;intention des
        &Eacute;tudiants ou d&rsquo;autres Utilisateurs.
      </p>
      <p>
        &laquo;<strong>&nbsp;Contrat&nbsp;</strong>&raquo; d&eacute;signe ces
        pr&eacute;sentes Conditions G&eacute;n&eacute;rales d&rsquo;Utilisation,
        les Politiques ZubU applicables de temps en temps et tout autre contrat
        ou entente conclu entre ZubU et un Utilisateur, incluant, sans
        limitation, les Conditions Sp&eacute;cifiques des Fournisseurs, le cas
        &eacute;ch&eacute;ant.
      </p>
      <p>
        &laquo;<strong>&nbsp;&Eacute;tudiants&nbsp;</strong>&raquo;
        d&eacute;signe les professionnels et autres travailleurs dans le domaine
        de la dentisterie et de la sant&eacute; dentaire qui sont
        &eacute;ligibles et qui ont enregistr&eacute; un Compte &Eacute;tudiant
        sur la Plateforme conform&eacute;ment aux pr&eacute;sentes Conditions
        G&eacute;n&eacute;rales d&rsquo;Utilisation, et qui sont inscrits
        &agrave; au moins un Contenu Acad&eacute;mique.
      </p>
      <p>
        &laquo;<strong>&nbsp;Fournisseur&nbsp;</strong>&raquo; d&eacute;signe
        tout fournisseur, autre que les Prestataires de Formation et les
        Conf&eacute;renciers, qui fournissent des produits ou des services
        &agrave; ZubU ou &agrave; la Plateforme; &agrave; titre d&rsquo;exemple,{" "}
        <em>Stripe</em> est un &laquo;&nbsp;Fournisseur&nbsp;&raquo; d&rsquo;un
        compl&eacute;ment de paiement sur la Plateforme.
      </p>
      <p>
        &laquo;<strong>&nbsp;Livraison&nbsp;</strong>&raquo; d&eacute;signe, en
        ce qui concerne le Contenu Acad&eacute;mique, l&rsquo;ex&eacute;cution
        et la livraison dudit Contenu Acad&eacute;mique par le Prestataire de
        Formation, de fa&ccedil;on substantiellement conforme &agrave; ce qui
        est annonc&eacute; sur la Plateforme quant aux sp&eacute;cifications,
        &agrave; la description, la date et l&rsquo;heure. En ce qui concerne le
        Contenu Acad&eacute;mique en ligne qui peut &ecirc;tre visionn&eacute;
        par un &Eacute;tudiant durant une p&eacute;riode de diffusion en direct,
        la &laquo;&nbsp;Livraison&nbsp;&raquo; signifie que le Prestataire de
        Formation a mis le Contenu Acad&eacute;mique &agrave; la disposition des
        &Eacute;tudiants inscrits, de fa&ccedil;on substantiellement conforme
        &agrave; ce qui est annonc&eacute; sur la Plateforme quant aux
        sp&eacute;cifications, &agrave; la description, la date et
        l&rsquo;heure, durant la p&eacute;riode d&rsquo;inscription, et en
        utilisant la plateforme en ligne ou le forum annonc&eacute; sur la
        Plateforme (en fournissant les liens, codes d&rsquo;acc&egrave;s ou
        identifiants requis pour y acc&eacute;der).
      </p>
      <p>
        &laquo;<strong>&nbsp;Plateforme&nbsp;</strong>&raquo; d&eacute;signe
        l&rsquo;application mobile ZubU, le site Web ZubU destin&eacute; aux
        Prestataires de Formation, et toute autre plateforme qui y est
        associ&eacute;e, tel que tout site Web, logiciel ou autre support fourni
        par ZubU permettant ou facilitant l&rsquo;ex&eacute;cution des Services.
      </p>
      <p>
        &laquo;<strong>&nbsp;Politiques ZubU&nbsp;</strong>&raquo;
        d&eacute;signe les politiques adopt&eacute;es par ZubU de temps en
        temps, telles que sa Politique relative aux donn&eacute;es personnelles,
        incluant toute politique ou termes et conditions externes
        adopt&eacute;es par ZubU dans le but d&rsquo;utiliser les produits et
        les services d&rsquo;un Fournisseur, tel que l&rsquo;Accord sur les
        services de Stripe. Ce terme peut &eacute;galement inclure des normes de
        conduites adopt&eacute;es par ZubU applicables &agrave; la
        communaut&eacute; d&rsquo;Utilisateurs et rendues disponibles sur la
        Plateforme.
      </p>
      <p>
        &laquo;<strong>&nbsp;Prestataire de Formation&nbsp;</strong>&raquo;
        d&eacute;signe une universit&eacute;, une &eacute;cole ou autre
        institution, une entit&eacute; priv&eacute;e, une organisation ou une
        personne fournissant du Contenu Acad&eacute;mique sur la Plateforme.
      </p>
      <p>
        &laquo;&nbsp;<strong>Services&nbsp;</strong>&raquo; d&eacute;signe les
        services fournis par ZubU sur la Plateforme de temps en temps, ce qui
        peut inclure (a)&nbsp;fournir une base de donn&eacute;es du Contenu
        Acad&eacute;mique dans les domaines de la dentisterie et de la
        sant&eacute; dentaire&nbsp;; (b)&nbsp;fournir une plateforme en ligne
        permettant aux &Eacute;tudiants de s&rsquo;inscrire au Contenu
        Acad&eacute;mique&nbsp;; (c)&nbsp;g&eacute;rer l&rsquo;inscription et
        les paiements entre les &Eacute;tudiants et les Prestataires de
        Formation&nbsp;; et/ou (d)&nbsp;collecter des informations pertinentes
        pour faciliter la sauvegarde par les &Eacute;tudiants &agrave; des
        documents et des certificats relatifs &agrave; la qualification du
        Contenu Acad&eacute;mique &agrave; titre de formation professionnelle
        obligatoire.
      </p>
      <p>
        &laquo;&nbsp;<strong>Utilisateurs&nbsp;</strong>&raquo; d&eacute;signe,
        collectivement, les &Eacute;tudiants, les Conf&eacute;renciers et les
        Prestataires de Formation et tout autre utilisateur de la Plateforme,
        &agrave; l&rsquo;exclusion de ZubU, de ses personnes li&eacute;es et de
        leurs Fournisseurs et repr&eacute;sentants respectifs, et &laquo;&nbsp;
        <strong>Utilisateur</strong>&nbsp;&raquo; d&eacute;signe l&rsquo;un ou
        l&rsquo;autre d&rsquo;entre eux.
      </p>
      <ol start="2">
        <li className={styles.listItem}>
          <strong>COMPTES</strong>
          <ul>
            <li className={styles.listItem}>
              <strong>Compte d&rsquo;Utilisateur</strong>
            </li>
          </ul>
        </li>
      </ol>
      <p>
        Chaque Utilisateur a besoin d&rsquo;un Compte afin
        d&rsquo;acc&eacute;der &agrave; la majorit&eacute; des activit&eacute;s
        et des fonctionnalit&eacute;s de la Plateforme et de
        b&eacute;n&eacute;ficier des Services. Un Utilisateur peut connecter ou
        enregistrer sous son compte un ou plusieurs employ&eacute;s ou membres
        de son groupe professionnel, auquel cas l&rsquo;Utilisateur initial est
        consid&eacute;r&eacute; comme le titulaire du Compte et les autres
        membres sont consid&eacute;r&eacute; comme des &laquo;&nbsp;
        <strong>B&eacute;n&eacute;ficiaires</strong>&nbsp;&raquo;
        associ&eacute;s &agrave; ce Compte aux fins de ces Conditions
        G&eacute;n&eacute;rales d&rsquo;Utilisation. Le titulaire du Compte est
        responsable de tous les B&eacute;n&eacute;ficiaires associ&eacute;s
        &agrave; son Compte et doit s&rsquo;assurer qu&rsquo;ils se conforment
        &agrave; ces Conditions G&eacute;n&eacute;rales d&rsquo;Utilisation. Les
        B&eacute;n&eacute;ficiaires ne sont pas consid&eacute;r&eacute;s comme
        des &laquo;&nbsp;Utilisateurs&nbsp;&raquo; en vertu du Contrat et
        n&rsquo;ont aucun acc&egrave;s direct &agrave; la Plateforme, &agrave;
        moins qu&rsquo;ils ne cr&eacute;ent eux-m&ecirc;mes un Compte et
        acceptent les modalit&eacute;s du Contrat. Les cr&eacute;dits et les
        autres informations relatives &agrave; la formation continue obligatoire
        ne seront conserv&eacute;s que pour le titulaire du Compte.
      </p>
      <p>
        Chaque Utilisateur est responsable de son Compte, de toutes les
        activit&eacute;s trait&eacute;es de son Compte et de la
        s&eacute;curit&eacute; et confidentialit&eacute; des ses identifiants.
        Si un Utilisateur soup&ccedil;onne une utilisation abusive ou non
        autoris&eacute;e de ses identifiants ou de son Compte, il doit contacter
        ZubU imm&eacute;diatement.
      </p>
      <p>
        Chaque Utilisateur et B&eacute;n&eacute;ficiaire doit &ecirc;tre
        &acirc;g&eacute; d&rsquo;au moins dix-huit (18) ans et avoir atteint
        l&rsquo;&acirc;ge requis pour signer des contrats dans le pays o&ugrave;
        il se situe, afin de cr&eacute;er un Compte, fournir des informations
        &agrave; ZubU et utiliser la Plateforme. Chaque Utilisateur doit fournir
        des informations compl&egrave;tes et exactes lors de la cr&eacute;ation
        du Compte et doit mettre &agrave; jour ces informations au fil de temps.
        Chaque Compte est <em>intuitu personae</em> &agrave; l&rsquo;Utilisateur
        et ne peut pas &ecirc;tre c&eacute;d&eacute;, transf&eacute;r&eacute;
        &agrave; un tiers. Un Utilisateur ne peut autoriser un tiers &agrave;
        utiliser son Compte. Afin de cr&eacute;er un Compte &Eacute;tudiant,
        l&rsquo;Utilisateur doit &ecirc;tre un professionnel de la dentisterie
        ou de la sant&eacute; dentaire enregistr&eacute;, autoris&eacute; par un
        ordre, conseil ou association professionnelle reconnue par ZubU et il
        doit fournir son num&eacute;ro de licence et ses exigences en
        mati&egrave;re de cr&eacute;dits de formation continue. ZubU peut
        imposer des conditions additionnelles quant &agrave; la cr&eacute;ation
        d&rsquo;un Compte, qui seront divulgu&eacute;es sur la Plateforme.
      </p>
      <p>
        Dans la mesure o&ugrave; il n&rsquo;y a aucune somme due ou
        impay&eacute;e &agrave; ZubU ou &agrave; un autre Utilisateur, un
        Utilisateur peut fermer son Compte &agrave; tout moment en suivant les
        &eacute;tapes indiqu&eacute;es sur la Plateforme, sous r&eacute;serve
        des pr&eacute;sentes Conditions G&eacute;n&eacute;rales
        d&rsquo;Utilisation.
      </p>
      <ol start="3">
        <li className={styles.listItem}>
          <strong>INSCRIPTION AU CONTENU ACAD&Eacute;MIQUE</strong>
        </li>
      </ol>
      <p>
        ZubU agit &agrave; titre d&rsquo;interm&eacute;diaire entre
        l&rsquo;&Eacute;tudiant et le Prestataire de Formation en ce qui
        concerne l&rsquo;inscription au Contenu Acad&eacute;mique et le paiement
        des frais applicables. Lors de l&rsquo;inscription et du paiement sur la
        Plateforme, ZubU fournit toutes les informations au Prestataire de
        Formation et confirme l&rsquo;inscription au Contenu Acad&eacute;mique.
        L&rsquo;&Eacute;tudiant ne pourra pas acc&eacute;der, regarder ou
        assister au Contenu Acad&eacute;mique sur la Plateforme et devra suivre
        les instructions du Prestataire de Formation quant &agrave;
        l&rsquo;emplacement et/ou &agrave; la disponibilit&eacute; en ligne du
        Contenu Acad&eacute;mique.
      </p>
      <p>
        La cr&eacute;ation d&rsquo;un Compte et l&rsquo;inscription au Contenu
        Acad&eacute;mique ou tout autre achat sur la Plateforme ne cr&eacute;e
        aucune licence, ni n&rsquo;autorise un Utilisateur &agrave; copier,
        reproduire, distribuer, transmettre, c&eacute;der, vendre, diffuser,
        louer, partager, pr&ecirc;ter, modifier, adapter, &eacute;diter,
        cr&eacute;er des &oelig;uvres d&eacute;riv&eacute;es, fournir des
        sous-licences, ou tout autre moyen de transf&eacute;rer ou utiliser
        quelconque Contenu Acad&eacute;mique, en totalit&eacute; ou en partie.
        Les termes et conditions d&rsquo;une telle licence sont ceux fournis par
        le Prestataire de Formation conform&eacute;ment &agrave; ses propres
        conditions g&eacute;n&eacute;rales d&rsquo;utilisation. Chaque
        Utilisateur s&rsquo;engage &agrave; ne pas faire ou &agrave; ne pas
        tenter de faire ce qui pr&eacute;c&egrave;de sans en avoir obtenu
        l&rsquo;autorisation pr&eacute;alable du Prestataire de Formation et
        ZubU ne fournit aucune garantie &agrave; cet &eacute;gard.
      </p>
      <ol start="4">
        <li className={styles.listItem}>
          <strong>PAIEMENTS, CR&Eacute;DITS ET REMBOURSEMENTS</strong>
        </li>
      </ol>
      <p>
        Afin d&rsquo;effectuer des transactions (effectuer ou recevoir des
        paiements) sur la Plateforme, les Utilisateurs doivent utiliser les
        m&eacute;thodes de paiement de la Plateforme, soit pr&eacute;sentement
        un compte <em>Stripe</em>. L&rsquo;utilisation de la plateforme de
        paiement Stripe est r&eacute;gie par{" "}
        <em>l&rsquo;Accord sur le compte li&eacute; Stripe</em>{" "}
        (r&eacute;f&eacute;rez-vous &agrave; ce{" "}
        <a href="https://stripe.com/en-ca/connect-account/legal">lien</a>), qui
        inclut les termes d&rsquo;utilisation <em>Stripe</em>{" "}
        (r&eacute;f&eacute;r&eacute;s conjointement, l&rsquo;&laquo;&nbsp;
        <strong>Accord sur les services de</strong> <strong>Stripe</strong>
        &nbsp;&raquo; figurant &agrave; ce{" "}
        <a href="https://stripe.com/en-ca/legal">lien</a>). D&rsquo;autres
        plateformes de paiement pourront &eacute;ventuellement &ecirc;tre
        autoris&eacute;e sur la Plateforme, &agrave; la discr&eacute;tion de
        ZubU.
      </p>
      <p>
        Chaque titulaire d&rsquo;un Compte doit cr&eacute;er et maintenir un
        compte <em>Stripe</em> en bonne et due forme sur la Plateforme,
        associ&eacute;e &agrave; une carte de cr&eacute;dit avec une
        capacit&eacute; suffisante pour couvrir les obligations
        financi&egrave;res en vertu du Contrat. Chaque Utilisateur peut aussi
        &ecirc;tre amen&eacute; &agrave; fournir des informations
        d&rsquo;identification ou des documents fiscaux &agrave; la demande de
        ZubU ou <em>Stripe</em>.
      </p>
      <ul>
        <li className={styles.listItem}>
          <strong>Tarification</strong>
        </li>
      </ul>
      <p>
        Les prix du Contenu Acad&eacute;mique et des autres Services sont ceux
        indiqu&eacute;s sur la Plateforme au moment de l&rsquo;inscription / de
        l&rsquo;achat.
      </p>
      <p>
        ZubU peut offrir des promotions ou des rabais de temps &agrave; autre,
        tel qu&rsquo;indiqu&eacute; sur la Plateforme. Ces promotions ou rabais
        ne s&rsquo;appliqueront pas aux Utilisateurs qui sont d&eacute;j&agrave;
        inscrits au Contenu Acad&eacute;mique vis&eacute; ou qui ont
        d&eacute;j&agrave; achet&eacute; le Service vis&eacute;. Si un prix
        n&rsquo;est plus annonc&eacute; ou disponible au moment de
        l&rsquo;inscription/de l&rsquo;achat, l&rsquo;Utilisateur ne peut pas
        r&eacute;clamer ou demander un tel prix (ex. tarif l&egrave;ve-t&ocirc;t
        / inscription h&acirc;tive). Certaines promotions peuvent &ecirc;tre
        disponibles uniquement aux nouveaux Utilisateurs ou soumises &agrave;
        d&rsquo;autres restrictions.
      </p>
      <p>
        Dans certaines juridictions, des taxes de vente et d&rsquo;autres taxes
        et droits peuvent s&rsquo;appliquer &agrave; l&rsquo;achat, au paiement
        ou aux Services. ZubU est responsable de la collecte et du versement de
        ces taxes. Selon la juridiction, les prix peuvent inclure les taxes ou
        non, qui pourraient n&rsquo;appara&icirc;tre qu&rsquo;au moment du
        paiement.
      </p>
      <ul>
        <li className={styles.listItem}>
          <strong>Traitement des paiements</strong>
        </li>
      </ul>
      <p>
        ZubU travaille avec des Fournisseurs externes de services de paiement,
        tel que <em>Stripe</em>, afin d&rsquo;offrir des m&eacute;thodes de
        paiement pratiques et de s&eacute;curiser les informations de paiement,
        conform&eacute;ment aux termes et conditions de ces Fournisseurs. Afin
        de compl&eacute;ter un paiement, une inscription au Contenu
        Acad&eacute;mique ou un acc&egrave;s &agrave; un autre Service, le
        paiement doit &ecirc;tre effectu&eacute; en utilisant un Fournisseur de
        paiement autoris&eacute; par ZubU.
      </p>
      <p>
        M&ecirc;me si un Utilisateur compl&egrave;te le paiement pour plusieurs
        transactions &agrave; la fois sur la Plateforme, les frais peuvent
        &ecirc;tre charg&eacute;s de fa&ccedil;on individuelle et
        appara&icirc;tre s&eacute;par&eacute;ment pour chaque transaction (par
        Contenu Acad&eacute;mique, par Utilisateur ou par Prestataire de
        Formation).
      </p>
      <p>
        ZubU n&rsquo;est responsable d&rsquo;aucune perte, dommage ou autre
        responsabilit&eacute; pouvant r&eacute;sulter de tout paiement
        effectu&eacute; &agrave; travers ces Fournisseurs externes, sauf en cas
        de faute grave, de n&eacute;gligence ou de faute intentionnelle de ZubU.
      </p>
      <ul>
        <li className={styles.listItem}>
          <strong>
            Paiement, remboursements et cr&eacute;dits du Contenu
            Acad&eacute;mique
          </strong>
        </li>
      </ul>
      <p>
        Chaque inscription &agrave; du Contenu Acad&eacute;mique peut &ecirc;tre
        soumise &agrave; des politiques d&rsquo;annulation et/ou de
        remboursement diff&eacute;rentes, d&eacute;finies par le Prestataire de
        Formation, qui seront affich&eacute;es sur la Plateforme. ZubU impose et
        applique ces politiques de remboursement, telles que d&eacute;finies sur
        la Plateforme au moment de l&rsquo;inscription et de l&rsquo;achat. Il
        n&rsquo;y a pas remboursement ou d&rsquo;annulation apr&egrave;s la
        Livraison du Contenu Acad&eacute;mique, autrement que tel que
        stipul&eacute; sp&eacute;cifiquement ci-apr&egrave;s.
      </p>
      <p>
        ZubU r&eacute;serve le droit, &agrave; son enti&egrave;re
        discr&eacute;tion, de traiter les demandes de remboursement et de
        cr&eacute;dit qui ne sont pas totalement conformes avec les politiques
        du Prestataire de Formation&nbsp;: ZubU n&rsquo;est pas responsable du
        Contenu Acad&eacute;mique, des sujets abord&eacute;s ou des
        comp&eacute;tences et opinions exprim&eacute;es par le
        Conf&eacute;rencier au cours des Services. Par cons&eacute;quent, ZubU
        ne remboursera un Utilisateur que si le Contenu Acad&eacute;mique est
        frauduleux, &agrave; savoir si le Contenu Acad&eacute;mique n&rsquo;a
        pas &eacute;t&eacute; Livr&eacute;, n&rsquo;est pas dans la langue
        annonc&eacute;e, n&rsquo;est pas donn&eacute; par un Conf&eacute;rencier
        minimalement qualifi&eacute; ou s&rsquo;il contient des annonces ou des
        publicit&eacute;s qui emp&ecirc;chent toute forme d&rsquo;apprentissage
        (notez que le fait qu&rsquo;un Contenu Acad&eacute;mique
        r&eacute;f&egrave;re &agrave; des produits commandit&eacute;es ou autres
        formes d&rsquo;affiliation ne constitue pas un motif suffisant pour
        obtenir un remboursement ou un cr&eacute;dit). En cas d&rsquo;abus ou de
        demande d&eacute;raisonnable d&rsquo;un Utilisateur, la demande de
        remboursement ou de cr&eacute;dit sera refus&eacute;e.
      </p>
      <p>
        Zubu se r&eacute;serve le droit d&rsquo;accorder (a)&nbsp;un
        cr&eacute;dit pour des Services futurs plut&ocirc;t qu&rsquo;un
        remboursement&nbsp;; (b)&nbsp;un remboursement via la m&eacute;thode
        paiement originale/compte <em>Stripe&nbsp;</em>; ou (c)&nbsp;toute autre
        m&eacute;thode de remboursement.
      </p>
      <p>
        Les paiements effectu&eacute;s sur la Plateforme sont
        irr&eacute;vocables et sont remis aux Prestataires de Formation au plus
        t&ocirc;t (a)&nbsp;quarante-huit (48) heures apr&egrave;s la Livraison
        du Contenu Acad&eacute;mique&nbsp;; et (b)&nbsp;&agrave;
        l&rsquo;expiration du d&eacute;lai le plus long autoris&eacute; par la
        loi et par le service de paiement du Fournisseur (soit trois (3) mois
        apr&egrave;s le paiement au Canada). Apr&egrave;s cette p&eacute;riode,
        chaque Utilisateur renonce irr&eacute;vocablement &agrave; recevoir un
        remboursement ou un cr&eacute;dit. Par cons&eacute;quent, toute demande
        de remboursement ou de cr&eacute;dit doit &ecirc;tre soumise par un
        Utilisateur &agrave; ZubU au plus tard (a)&nbsp;vingt-quatre (24) heures
        suivant la Livraison du Contenu Acad&eacute;mique et
        (b)&nbsp;quatre-vingts (80) jours suivant l&rsquo;achat. ZubU
        n&rsquo;effectuera aucun remboursement ou cr&eacute;dit apr&egrave;s
        cette p&eacute;riode, &agrave; moins que le Prestataire de Formation ne
        fournisse &agrave; ZubU les sommes n&eacute;cessaires pour traiter un
        tel remboursement ou cr&eacute;dit dans le cadre de sa propre politique
        d&rsquo;annulation ou de remboursement.
      </p>
      <p>
        ZubU n&rsquo;est pas responsable en cas d&rsquo;incapacit&eacute;
        d&rsquo;un &Eacute;tudiant ou d&rsquo;un autre Utilisateur
        d&rsquo;assister au Contenu Acad&eacute;mique (incluant toute
        incapacit&eacute; de se pr&eacute;senter en personne, erreur de date ou
        de lieu, de d&eacute;faillance ou incompatibilit&eacute; technologique.
      </p>
      <ul>
        <li className={styles.listItem}>
          <strong>Codes cadeaux et promotionnels</strong>
        </li>
      </ul>
      <p>
        ZubU, les Prestataires de Formation, les Conf&eacute;renciers et
        d&rsquo;autres Fournisseurs ou partenaires peuvent offrir des codes
        cadeaux et promotionnels aux &Eacute;tudiants et aux autres
        Utilisateurs. Certains codes pourraient &ecirc;tre
        &eacute;chang&eacute;s contre des cr&eacute;dits pr&eacute;pay&eacute;s
        appliqu&eacute;s au compte ZubU, qui pourraient ensuite &ecirc;tre
        utilis&eacute;s pour acheter du Contenu Acad&eacute;mique
        &eacute;ligible sur la Plateforme. D&rsquo;autres codes peuvent
        &ecirc;tre directement &eacute;changeables contre du Contenu
        Acad&eacute;mique sp&eacute;cifique. Tous deux sont et demeurent soumis
        aux Conditions G&eacute;n&eacute;rales d&rsquo;Utilisation et aux termes
        et conditions associ&eacute;s aux codes promotionnels.
      </p>
      <p>
        Les codes promotionnels et les cr&eacute;dits, de m&ecirc;me que toute
        valeur promotionnelle qui leur est li&eacute;e, peuvent expirer
        s&rsquo;ils ne sont pas utilis&eacute;s dans le d&eacute;lai
        sp&eacute;cifi&eacute; sur la Plateforme ZubU ou associ&eacute; au code
        promotionnel. Les cadeaux et les codes promotionnels ne peuvent
        &ecirc;tre ni rembours&eacute;s ni &eacute;chang&eacute;s contre de
        l&rsquo;argent, sauf en cas d&rsquo;indication contraire par
        &eacute;crit ou lorsqu&rsquo;exig&eacute; par une loi applicable. Les
        cadeaux et les codes promotionnels offerts par un tiers sont soumis aux
        politiques de remboursement de ce tiers. Si un Utilisateur a plusieurs
        cr&eacute;dits enregistr&eacute;s &agrave; son Compte, ZubU peut
        d&eacute;terminer lequel de ces cr&eacute;dits s&rsquo;applique &agrave;
        un achat.
      </p>
      <ul>
        <li className={styles.listItem}>
          <strong>Devise</strong>
        </li>
      </ul>
      <p>
        La devise du prix peut &ecirc;tre bas&eacute;e sur la localisation de
        l'Utilisateur lors de la cr&eacute;ation de son Compte ou peut
        &ecirc;tre fix&eacute;e par ZubU. Le taux de conversion sera celui rendu
        disponible sur le compte <em>Stripe</em> ou tout autre compl&eacute;ment
        de services de paiement au moment du paiement. Les Utilisateurs ne sont
        pas autoris&eacute;s &agrave; visionner les prix dans plus d'une devise
        ou &agrave; demander des conditions de change sp&eacute;cifiques.
      </p>
      <ol start="5">
        <li className={styles.listItem}>
          <strong>NORMES DE CONDUITES ET RECOURS</strong>
          <ul>
            <li className={styles.listItem}>
              <strong>Norme de conduite des Utilisateurs</strong>
            </li>
          </ul>
        </li>
      </ol>
      <p>
        Chaque Utilisateur accepte d&rsquo;&ecirc;tre li&eacute; par ce qui suit
        en ce qui concerne les Services et la Plateforme&nbsp;:
      </p>
      <ul>
        <li className={styles.listItem}>
          utiliser la Plateforme et les Services uniquement &agrave; des fins
          l&eacute;gales, conform&eacute;ment aux lois applicables, au Contrat
          (incluant ces Conditions G&eacute;n&eacute;rales d&rsquo;Utilisation
          et les Politiques ZubU) et aux droits de propri&eacute;t&eacute;
          intellectuelle de tous les tiers&nbsp;;
        </li>
        <li className={styles.listItem}>
          &ecirc;tre enti&egrave;rement responsable et exon&eacute;rer ZubU de
          tout contenu qu&rsquo;un Utilisateur publie ou
          t&eacute;l&eacute;charge sur la Plateforme, incluant les avis, les
          questions, les publications, la description du Contenu
          Acad&eacute;mique, les publicit&eacute;s et tout autre contenu&nbsp;;
          ZubU consid&egrave;re tout ce contenu comme non confidentiel et non
          exclusif&nbsp;;
        </li>
        <li className={styles.listItem}>
          avertir ZubU s&rsquo;il a connaissance d&rsquo;une violation de sa
          propri&eacute;t&eacute; intellection ou de tout autre droit, ou
          d&rsquo;une violation du Contrat&nbsp;;
        </li>
        <li className={styles.listItem}>
          ne pas acc&eacute;der &agrave; la Plateforme ou &agrave; ses Services
          &agrave; partir d&rsquo;un territoire o&ugrave; les entreprises
          canadiennes et am&eacute;ricaines ne sont pas autoris&eacute;es
          &agrave; exercer des activit&eacute;s commerciales (comme Cuba,
          l&rsquo;Iran, la Cor&eacute;e du Nord, le Soudan ou la Syrie) ou si
          l&rsquo;Utilisateur a &eacute;t&eacute; d&eacute;sign&eacute; par le
          gouvernement am&eacute;ricain comme &laquo;&thinsp;Specially
          Designated National&thinsp;&raquo;, &laquo;&nbsp;Denied
          Person&nbsp;&raquo; ou &laquo;&nbsp;Denied Entity&nbsp;&raquo; (soit
          une un{" "}
          <em>Ressortissant express&eacute;ment d&eacute;sign&eacute;</em> ou
          une personne ou une entit&eacute; interdite)&nbsp;;
        </li>
        <li className={styles.listItem}>
          ne pas t&eacute;l&eacute;charger ou publier de contenu ou de
          technologie (incluant de l&rsquo;information sur le cryptage) dont
          l&rsquo;exportation est sp&eacute;cialement contr&ocirc;l&eacute;e
          sous l&rsquo;<em>Export Administration Regulations</em> ou
          d&rsquo;autres lois et r&egrave;glements similaires applicables&nbsp;;
        </li>
        <li className={styles.listItem}>
          ne pas publier ou soumettre du contenu ou du mat&eacute;riel qui viole
          la propri&eacute;t&eacute; intellectuelle d&rsquo;un tiers (par
          exemple, en tant qu&rsquo;&eacute;tudiant, soumettre un travail
          cr&eacute;&eacute; par quelqu&rsquo;un d&rsquo;autre)&nbsp;;
        </li>
        <li className={styles.listItem}>
          ne pas publier ou t&eacute;l&eacute;charger du mat&eacute;riel qui
          contient des virus, des chevaux de Troie, des bombes &agrave;
          retardement ou d&rsquo;autres routines ou moteurs de programmation
          informatique qui sont destin&eacute;s &agrave; endommager,
          interf&eacute;rer de mani&egrave;re pr&eacute;judiciable, intercepter
          ou exproprier tout syst&egrave;me, donn&eacute;e ou information&nbsp;;
        </li>
        <li className={styles.listItem}>
          ne pas revendre, conc&eacute;der une licence ou faire une utilisation
          commerciale de la Plateforme ou de tout contenu qui y est
          publi&eacute;&nbsp;;
        </li>
        <li className={styles.listItem}>
          ne pas publier, soumettre ou t&eacute;l&eacute;charger du contenu ou
          du mat&eacute;riel inappropri&eacute; ou r&eacute;pr&eacute;hensible
          (par exemple, usurpation de l'identit&eacute; de quelqu'un d'autre,
          racisme, contenu ou information diffamatoire, sexisme, haine,
          informations fausses ou trompeuses, etc.)&nbsp;;
        </li>
        <li className={styles.listItem}>
          s'assurer que ses B&eacute;n&eacute;ficiaires et repr&eacute;sentants
          se conforment aux normes de conduite ci-dessus et aux autres termes et
          conditions du pr&eacute;sent Contrat&nbsp;; et
        </li>
        <li className={styles.listItem}>
          ne pas faciliter, encourager ou participer &agrave; l'un des
          comportements ci-dessus.
        </li>
      </ul>
      <p>
        De plus, chaque Utilisateur s'engage &agrave; respecter les mesures
        d&rsquo;int&eacute;grit&eacute; et de s&eacute;curit&eacute;
        suivantes&nbsp;:
      </p>
      <ul>
        <li className={styles.listItem}>
          fournir des commentaires et des critiques l&eacute;gitimes, et limiter
          le contenu aux questions pertinentes pour le Contenu Acad&eacute;mique
          et la port&eacute;e des Services&nbsp;; le contenu non pertinent,
          personnel ou discriminatoire, ainsi que le contenu destin&eacute;
          &agrave; promouvoir d'autres contenus, produits ou services peuvent
          &ecirc;tre supprim&eacute;s par ZubU&nbsp;; et
        </li>
        <li className={styles.listItem}>
          &ecirc;tre transparent &agrave; l&rsquo;&eacute;gard des conflits
          d'int&eacute;r&ecirc;ts, par exemple si une &eacute;tude ou un Contenu
          Acad&eacute;mique a &eacute;t&eacute; commandit&eacute; ou si un
          commentaire ou autre r&eacute;troaction est influenc&eacute; par
          d'autres int&eacute;r&ecirc;ts.
        </li>
      </ul>
      <p>
        Les Utilisateurs peuvent signaler le contenu ou les avis qu&rsquo;ils
        consid&egrave;rent non conformes au Contrat. ZubU se r&eacute;serve
        l&rsquo;enti&egrave;re discr&eacute;tion concernant la suppression ou
        mod&eacute;ration d&rsquo;un tel contenu&nbsp;; un filtre automatique
        (spam) peut &ecirc;tre utilis&eacute; pour contr&ocirc;ler le
        contenu&nbsp;; si un Utilisateur estime que son contenu a
        &eacute;t&eacute; supprim&eacute; sans motif raisonnable, il peut
        contacter ZubU pour une enqu&ecirc;te et une r&eacute;vision potentielle
        de la d&eacute;cision.
      </p>
      <ul>
        <li className={styles.listItem}>
          <strong>Recours</strong>
        </li>
      </ul>
      <p>
        ZubU peut, en cas de (a)&nbsp;violation des pr&eacute;sentes Conditions
        G&eacute;n&eacute;rales d&rsquo;Utilisation&nbsp;;
        (b)&nbsp;d&eacute;faut de paiement&nbsp;; (c)&nbsp;insolvabilit&eacute;,
        faillite ou toute proc&eacute;dure similaire concernant un
        Utilisateur&nbsp;; (d)&nbsp;&agrave; la demande d'une autorit&eacute;
        charg&eacute;e de l'application de la loi ou d'un autre organisme
        gouvernemental comp&eacute;tent&nbsp;; ou (e)&nbsp;sur pr&eacute;avis de
        trente (30) jours en cas de p&eacute;riode(s) d'inactivit&eacute;
        prolong&eacute;e(s)&nbsp;:
      </p>
      <ul>
        <li className={styles.listItem}>
          fermer le Compte et mettre fin &agrave; l'utilisation des
          Services&nbsp;;
        </li>
        <li className={styles.listItem}>
          interdire ou emp&ecirc;cher la cr&eacute;ation d'un nouveau
          Compte&nbsp;;
        </li>
        <li className={styles.listItem}>
          suspendre le Compte et/ou l'utilisation des Services&nbsp;; ou
        </li>
        <li className={styles.listItem}>
          supprimer le Contenu Acad&eacute;mique ou tout autre contenu ou
          mat&eacute;riel, en tout ou en partie.
        </li>
      </ul>
      <p>
        ZubU peut &eacute;galement r&eacute;silier ou suspendre un Compte ou les
        Services &agrave; sa convenance, moyennant un pr&eacute;avis de soixante
        (60) jours, &agrave; son enti&egrave;re discr&eacute;tion.
      </p>
      <ul>
        <li className={styles.listItem}>
          <strong>R&eacute;siliation par ZubU</strong>
        </li>
      </ul>
      <p>
        En cas de r&eacute;siliation, ZubU peut supprimer le Compte et
        emp&ecirc;cher l'Utilisateur d'acc&eacute;der &agrave; la Plateforme, au
        Compte et &agrave; l'utilisation des Services. Le contenu
        t&eacute;l&eacute;charg&eacute; par un Utilisateur pourrait &ecirc;tre
        toujours disponible sur la Plateforme m&ecirc;me si le compte est
        r&eacute;sili&eacute; ou suspendu, ou peut &ecirc;tre supprim&eacute;
        par ZubU. Les informations personnelles (telles que d&eacute;finies dans
        la Politique relative aux donn&eacute;es personnelles de ZubU) seront
        supprim&eacute;es (&agrave; moins que ZubU ne soit tenu en vertu des
        lois applicables de conserver ces informations).
      </p>
      <ul>
        <li className={styles.listItem}>
          <strong>Indemnit&eacute;</strong>
        </li>
      </ul>
      <p>
        Chaque Utilisateur accepte d'indemniser, de d&eacute;fendre et de tenir
        ZubU et ses affili&eacute;s, dirigeants, administrateurs, fournisseurs,
        partenaires et agents &agrave; couvert de et contre toutes
        responsabilit&eacute;s, r&eacute;clamations, demandes, pertes, dommages
        ou d&eacute;penses (incluant les honoraires raisonnables d'avocat) et
        r&eacute;clamations de tiers r&eacute;sultant ou d&eacute;coulant
        (a)&nbsp;du contenu publi&eacute;, soumis ou annonc&eacute; par cet
        Utilisateur (incluant tout Contenu Acad&eacute;mique) sur la
        Plateforme&nbsp;; (b)&nbsp;l'utilisation des Services par cet
        Utilisateur&nbsp;; (c)&nbsp;une violation du Contrat, incluant les
        Politiques ZubU&nbsp;; ou (d)&nbsp;une violation des droits d'un tiers.
        L&rsquo;obligation d&rsquo;indemnisation qui pr&eacute;c&egrave;de
        survivra &agrave; la r&eacute;siliation du Contrat et &agrave; la fin de
        l&rsquo;utilisation ou de l&rsquo;acc&egrave;s aux Services par
        l&rsquo;Utilisateur.
      </p>
      <ol start="6">
        <li className={styles.listItem}>
          <strong>PROPRI&Eacute;T&Eacute; INTELLECTUELLE</strong>
          <ul>
            <li className={styles.listItem}>
              <strong>Contenu</strong>
            </li>
          </ul>
        </li>
      </ol>
      <p>
        Chaque partie &agrave; ce Contrat, soit ZubU et les Utilisateurs,
        conserve ses droits de propri&eacute;t&eacute; quant au contenu
        publi&eacute; ou t&eacute;l&eacute;charg&eacute; sur la Plateforme.
      </p>
      <p>
        Le Prestataire de Formation demeure en tout temps le propri&eacute;taire
        exclusif du Contenu Acad&eacute;mique et des autres produits et services
        annonc&eacute;s sur la Plateforme et est responsable de g&eacute;rer et
        de s&eacute;curiser le cadre juridique applicable entre les
        Conf&eacute;renciers, les &Eacute;tudiants et lui-m&ecirc;me.
      </p>
      <p>
        Chaque Utilisateur accorde &agrave; ZubU une licence non exclusive,
        gratuite, internationale, perp&eacute;tuelle et irr&eacute;vocable
        d&rsquo;utiliser, copier, reproduire, traiter, adapter, modifier,
        publier, transmettre, afficher et distribuer le contenu soumis ou
        t&eacute;l&eacute;charg&eacute; sur la Plateforme. Une telle licence est
        cessible et donne le droit &agrave; ZubU de traiter et d&rsquo;utiliser
        le contenu &agrave; quelque fin que ce soit, sous r&eacute;serve de la
        Politique relative aux donn&eacute;es personnelles de ZubU et des
        limitations &eacute;nonc&eacute;es dans les Conditions
        Sp&eacute;cifiques des Fournisseurs concernant le Contenu
        Acad&eacute;mique. ZubU est ainsi autoris&eacute; &agrave; partager,
        afficher, reproduire, r&eacute;sumer, traduire et communiquer le contenu
        de chaque Utilisateur (incluant les commentaires, les questions, les
        avis, les id&eacute;es et les suggestions) &agrave; quiconque par
        l&rsquo;interm&eacute;diaire de quelque moyen de communication que ce
        soit, incluant (a)&nbsp;en faisant de la promotion sur d&rsquo;autres
        sites Web&nbsp;; et (b)&nbsp;en rendant un tel contenu disponible aux
        autres compagnies, organisations ou individus qui s&rsquo;associent
        &agrave; ZubU pour la syndication, la diffusion, la distribution ou la
        publication de contenu sur d&rsquo;autres moyens de communication.
        Chaque Utilisateur renonce irr&eacute;vocablement aux droits moraux
        relatif de ce contenu, dans la mesure permise par les lois applicables,
        et d&eacute;clare et garantit &agrave; ZubU qu&rsquo;il a le droit, le
        pouvoir et l&rsquo;autorit&eacute; de partager un tel contenu et
        d&rsquo;accorder pr&eacute;sente la pr&eacute;sente licence.
      </p>
      <ul>
        <li className={styles.listItem}>
          <strong>Plateforme</strong>
        </li>
      </ul>
      <p>
        ZubU demeure en tout temps le propri&eacute;taire unique et exclusif de
        la Plateforme, de toutes ses composantes et d&eacute;veloppements, et de
        toute propri&eacute;t&eacute; intellectuelle sous-jacente ou lui
        &eacute;tant li&eacute;e, incluant les sites Web, les applications
        mobiles, les logos, les API, les codes, la technologie, le savoir-faire,
        la mise en page g&eacute;n&eacute;rale, la conception, le contenu
        original, etc.
      </p>
      <p>
        Dans la mesure o&ugrave; un Utilisateur contribue &agrave; la
        Plateforme, par exemple &agrave; travers le d&eacute;veloppement
        d&rsquo;une nouvelle fonctionnalit&eacute; ou d&rsquo;une recommandation
        d&rsquo;am&eacute;lioration, il c&egrave;de &agrave; ZubU
        automatiquement tous les droits relatifs au d&eacute;veloppement ou
        &agrave; la recommandation, et renonce irr&eacute;vocablement &agrave;
        tous les droits moraux qui y sont li&eacute;s. ZubU a enti&egrave;re
        discr&eacute;tion d&rsquo;utiliser, partager, distribuer, promouvoir,
        modifier ou &eacute;diter un tel d&eacute;veloppement, de quelque moyen
        que ce soit.
      </p>
      <p>
        Chaque Utilisateur s&rsquo;engage &agrave; ne pas alt&eacute;rer les
        droits de propri&eacute;t&eacute; intellectuelle de ZubU, incluant les
        droits d&rsquo;auteur, les marques de commerce, la technologie, le
        savoir-faire ou les inventions (brevetables ou non), incluant&nbsp;:
      </p>
      <ul>
        <li className={styles.listItem}>
          acc&eacute;der, alt&eacute;rer ou utiliser les zones non publiques de
          la Plateforme, les syst&egrave;mes informatiques de ZubU ou les
          syst&egrave;mes techniques des Fournisseurs de services de ZubU&nbsp;;
        </li>
        <li className={styles.listItem}>
          d&eacute;sactiver, interf&eacute;rer avec ou tenter de contourner
          l&rsquo;une des composantes li&eacute;e &agrave; la
          s&eacute;curit&eacute;, la confidentialit&eacute; ou
          l&rsquo;antipiratage de la Plateforme, sonder, analyser ou tester la
          vuln&eacute;rabilit&eacute; de quelque syst&egrave;me de ZubU&nbsp;;
        </li>
        <li className={styles.listItem}>
          copier, modifier, cr&eacute;er une &oelig;uvre d&eacute;riv&eacute;e,
          proc&eacute;der &agrave; une ing&eacute;nierie inverse,
          proc&eacute;der &agrave; un assemblage inverse ou tenter de
          d&eacute;couvrir tout code source ou contenu sur la Plateforme ou les
          Services ZubU&nbsp;;
        </li>
        <li className={styles.listItem}>
          acc&eacute;der, rechercher ou tenter d'acc&eacute;der ou de rechercher
          dans la Plateforme par quelconque moyen (automatis&eacute; ou autre)
          autre que par les fonctions de recherche disponibles&nbsp;;
        </li>
        <li className={styles.listItem}>
          faire la capture de donn&eacute;es (raclage web), utiliser un logiciel{" "}
          <em>spider</em>, un robot ou quelconque moyen automatis&eacute;
          d&rsquo;acc&eacute;der aux Services&nbsp;;
        </li>
        <li className={styles.listItem}>
          utiliser les Services pour envoyer des informations
          d&rsquo;identification modifi&eacute;es, trompeuses ou fausses (telles
          que l&rsquo;envoi de communications par courriel s&rsquo;identifiant
          &agrave; tort &agrave; ZubU)&nbsp;;
        </li>
        <li className={styles.listItem}>
          perturber ou interf&eacute;rer avec l&rsquo;acc&egrave;s de tout
          Utilisateur, h&ocirc;te ou r&eacute;seau ou tenter de le faire,
          incluant, sans limitation, envoyer un virus, une surcharge (
          <em>flooding</em>), un pourriel ou autre spam ou le bombardement de la
          messagerie des plateformes et des services, ou quelconque autre action
          interf&eacute;rant avec ou cr&eacute;ant une charge excessive sur les
          Services ou la Plateforme&thinsp;; ou
        </li>
        <li className={styles.listItem}>
          utiliser le nom l&eacute;gal ou commercial de ZubU, sa marque de
          commerce ou son logo &agrave; quelque fin que ce soit, autrement que
          tel qu&rsquo; express&eacute;ment autoris&eacute;e en vertu du
          Contrat.
        </li>
      </ul>
      <ol start="7">
        <li className={styles.listItem}>
          <strong>LIMITATION DE RESPONSABILIT&Eacute;</strong>
        </li>
      </ol>
      <p>
        L&rsquo;Utilisateur utilise la Plateforme et se fie au Contenu
        Acad&eacute;mique et au contenu d&rsquo;autres Utilisateurs &agrave; ses
        risques et p&eacute;rils.
      </p>
      <p>
        ZubU ne r&eacute;vise pas, ne modifie pas et ne contr&ocirc;le pas le
        Contenu Acad&eacute;mique et n'est pas en mesure de d&eacute;terminer la
        l&eacute;galit&eacute; du contenu. ZubU n'exerce pas de contr&ocirc;le
        &eacute;ditorial sur le Contenu Acad&eacute;mique et ne peut pas exercer
        un tel contr&ocirc;le sur le contenu des autres Utilisateurs disponible
        sur la Plateforme et, &agrave; cet effet, ne garantit en aucune
        mani&egrave;re la fiabilit&eacute;, la validit&eacute;, l'exactitude ou
        la v&eacute;racit&eacute; de ce contenu.
      </p>
      <ul>
        <li className={styles.listItem}>
          <strong>Probl&egrave;mes techniques</strong>
        </li>
      </ul>
      <p>
        Il peut arriver que la Plateforme soit en panne, soit pour une
        maintenance planifi&eacute;e, incluant en raison de probl&egrave;mes
        techniques, technologiques ou de s&eacute;curit&eacute;. ZubU se
        r&eacute;serve le droit de limiter la disponibilit&eacute; de certaines
        fonctionnalit&eacute;s des Services &agrave; tout moment et pour quelque
        raison que ce soit. En aucun cas, ZubU ou ses personnes li&eacute;es,
        fournisseurs, partenaires ou agents ne pourront &ecirc;tre tenus
        responsables des dommages r&eacute;sultant de telles interruptions ou
        non-disponibilit&eacute; de ces fonctionnalit&eacute;s.
      </p>
      <ul>
        <li className={styles.listItem}>
          <strong>Force majeure</strong>
        </li>
      </ul>
      <p>
        ZubU n'est pas responsable du retard ou de l'&eacute;chec de
        l'ex&eacute;cution de l'un des Services caus&eacute; par des
        &eacute;v&eacute;nements hors de son contr&ocirc;le raisonnable, comme
        en cas de force majeure, d&rsquo;un acte de guerre, d&rsquo;une
        hostilit&eacute;, d&rsquo;un sabotage, d&rsquo;une catastrophe
        naturelle, d&rsquo;une panne d'&eacute;lectricit&eacute;, d'Internet ou
        des r&eacute;seaux de t&eacute;l&eacute;communications, d&rsquo;une
        pand&eacute;mie ou d&rsquo;un changement aux lois ou autres restrictions
        gouvernementales.
      </p>
      <ul>
        <li className={styles.listItem}>
          <strong>Contenu de l&rsquo;Utilisateur</strong>
        </li>
      </ul>
      <p>
        En utilisant les Services, un Utilisateur rencontrera et pourra
        &ecirc;tre expos&eacute; &agrave; du contenu de tiers. Un Utilisateur
        pourrait consid&eacute;rer ce contenu comme offensant, ind&eacute;cent
        ou r&eacute;pr&eacute;hensible, ou il peut arriver qu'un Prestataire de
        Formation, un Conf&eacute;rencier ou un autre Utilisateur fournisse des
        d&eacute;clarations fausses, trompeuses ou inexactes. ZubU n'assume
        aucune responsabilit&eacute; relativement &agrave; un tel contenu et
        chaque Utilisateur le d&eacute;charge &agrave; cet &eacute;gard. ZubU
        n&rsquo;est pas responsable du choix d'un Utilisateur de
        s&rsquo;inscrire ou d&rsquo;acc&eacute;der &agrave; quelconque Contenu
        Acad&eacute;mique ou autre contenu.
      </p>
      <p>
        Lorsque ZubU est inform&eacute; par un Utilisateur qu&rsquo;un certain
        contenu est susceptible d&rsquo;&ecirc;tre ill&eacute;gal,
        d&rsquo;enfreindre une loi applicable ou de violer les droits d&rsquo;un
        tiers, ZubU se r&eacute;serve le droit d'enqu&ecirc;ter, de supprimer le
        contenu ou d'appliquer tout autre recours disponible en vertu du Contrat
        ou des lois applicables.
      </p>
      <p>
        Les Utilisateurs pourraient avoir la possibilit&eacute; d'interagir avec
        d'autres Utilisateurs sur la Plateforme, incluant les &Eacute;tudiants
        entre eux. Chaque Utilisateur doit faire attention &agrave;
        l'information personnelle qu&rsquo;il partage puisque ZubU n'est pas
        responsable du traitement de ces informations personnelles
        partag&eacute;es volontairement avec un autre Utilisateur ni des
        fichiers transf&eacute;r&eacute;s, envoy&eacute;s par courrier
        &eacute;lectronique ou autrement communiqu&eacute;s &agrave; un autre
        Utilisateur en dehors de la Plateforme. ZubU n'est pas responsable des
        litiges, r&eacute;clamations, pertes, blessures ou dommages de quelque
        nature que ce soit qui pourraient r&eacute;sulter ou &ecirc;tre
        li&eacute;es au comportement des Prestataires de Formation, des
        Conf&eacute;renciers, des &Eacute;tudiants et des autres Utilisateurs.
      </p>
      <p>
        La limitation ci-dessus est applicable dans la mesure permise par les
        lois applicables.
      </p>
      <ul>
        <li className={styles.listItem}>
          <strong>Contenu Acad&eacute;mique</strong>
        </li>
      </ul>
      <p>
        Le Contenu Acad&eacute;mique et tout autre contenu annonc&eacute;s sur
        la Plateforme sont offerts &agrave; des fins &eacute;ducatives,
        d'information g&eacute;n&eacute;rale et de discussion et ne sont pas
        destin&eacute;s &agrave; remplacer le jugement clinique d'un
        professionnel. ZubU ne d&eacute;clare ni ne garantit que ce contenu est
        exact, ad&eacute;quat ou complet ou qu'il refl&egrave;te l'&eacute;tat
        le plus actuel de la science et les Utilisateurs sont encourag&eacute;s
        &agrave; le confirmer et &agrave; le comparer avec d'autres sources
        avant d'entreprendre un traitement, un service ou un diagnostic. Le
        Contenu Acad&eacute;mique et les autres contenus annonc&eacute;s sur la
        Plateforme ne sont pas destin&eacute;s &agrave; remplacer les
        connaissances, le jugement et les soins m&eacute;dicaux ou techniques.
        Les Utilisateurs ne doivent pas se fier uniquement au Contenu
        Acad&eacute;mique et &agrave; tout autre contenu annonc&eacute; sur la
        Plateforme pour tenter de rem&eacute;dier, de gu&eacute;rir, de traiter,
        de diagnostiquer ou d'intervenir d'une mani&egrave;re quelconque en ce
        qui concerne toute condition ou situation m&eacute;dicale ou de
        sant&eacute;. Un Utilisateur assume l'enti&egrave;re
        responsabilit&eacute; des choix effectu&eacute;s avant, pendant et
        apr&egrave;s son inscription au Contenu Acad&eacute;mique et toute autre
        utilisation des Services.
      </p>
      <ul>
        <li className={styles.listItem}>
          <strong>
            Exigences en mati&egrave;re de formation continue obligatoire
          </strong>
        </li>
      </ul>
      <p>
        ZubU ne garantit pas que quelconque Contenu Acad&eacute;mique sera
        &eacute;ligible aux cr&eacute;dits de formation continue obligatoire ou
        autres exigences de formation &eacute;mises par un ordre, un conseil ou
        une association professionnelle ni ne donnera droit &agrave; quelconque
        cr&eacute;dit d&rsquo;un &eacute;tablissement d'enseignement. ZubU agit
        uniquement en tant qu'interm&eacute;diaire pour aider les
        &Eacute;tudiants &agrave; enregistrer et &agrave; acc&eacute;der aux
        informations sur le Contenu Acad&eacute;mique auquel ils ont
        assist&eacute;. Chaque &Eacute;tudiant doit conserver une copie des
        certificats originaux attestant sa participation au Contenu
        Acad&eacute;mique, &agrave; des fins d'audit.
      </p>
      <ul>
        <li className={styles.listItem}>
          <strong>Contenu d&rsquo;un tiers</strong>
        </li>
      </ul>
      <p>
        La Plateforme peut contenir des liens vers d'autres sites Web ou
        plateformes qui ne sont ni d&eacute;tenus ni contr&ocirc;l&eacute;s par
        ZubU. ZubU n'est pas responsable du contenu ou de tout autre aspect de
        ces sites ou plateformes tiers, incluant leur collecte d'informations
        concernant un Utilisateur. Chaque Utilisateur doit lire les conditions
        g&eacute;n&eacute;rales d&rsquo;utilisation et les politiques de
        confidentialit&eacute; de ces tiers. ZubU n'assume aucune
        responsabilit&eacute; d&eacute;coulant de ces contenus, sites ou
        plateformes tiers.
      </p>
      <ul>
        <li className={styles.listItem}>
          <strong>S&eacute;curit&eacute; et droit d&rsquo;auteur</strong>
        </li>
      </ul>
      <p>
        ZubU respecte les droits d'auteur de ses Utilisateurs et s'engage
        &agrave; contribuer &agrave; leur protection en utilisant une
        technologie antipiratage. Pour un pirate motiv&eacute; ou une autre
        personne, tout contenu est vuln&eacute;rable au vol et ZubU
        consid&egrave;re que le probl&egrave;me est in&eacute;vitable. ZubU
        utilise des logiciels et des technologies pour identifier les vols et
        les acc&egrave;s non autoris&eacute;s, et fournit ses meilleurs efforts
        pour limiter leur impact sur les Utilisateurs.
      </p>
      <p>
        Chaque Utilisateur est responsable d'identifier s'il est autoris&eacute;
        &agrave; utiliser du contenu ou des id&eacute;es de tiers. ZubU ne donne
        pas de conseils concernant le droit d'auteur et n'assume aucune
        responsabilit&eacute; relativement au mat&eacute;riel
        t&eacute;l&eacute;charg&eacute; sur la Plateforme. Tout signalement
        d&rsquo;une violation du droit d'auteur sera pris au s&eacute;rieux et
        enqu&ecirc;t&eacute; par ZubU s'il est soumis via le formulaire
        disponible sur la Plateforme, et si toutes les informations requises
        sont fournies. Le cas &eacute;ch&eacute;ant, ZubU peut supprimer le
        contenu contrefait, sans aucune obligation de le faire autrement qu'en
        vertu des lois applicables ou d'une ordonnance d'une autorit&eacute;
        gouvernementale ou judiciaire comp&eacute;tente. Des proc&eacute;dures
        de contre-avis sont &eacute;galement disponibles en contactant ZubU pour
        contester la suppression du contenu. Une infraction
        r&eacute;p&eacute;t&eacute;e entra&icirc;nera la r&eacute;siliation du
        Compte d'un Utilisateur.
      </p>
      <ul>
        <li className={styles.listItem}>
          <strong>Diff&eacute;rends entre Utilisateurs</strong>
        </li>
      </ul>
      <p>
        ZubU encourage les Prestataires de Formation, les Conf&eacute;renciers
        et les &Eacute;tudiants &agrave; tenter de r&eacute;gler les conflits
        entre eux et n'interviendra pas dans le r&egrave;glement des
        diff&eacute;rends.
      </p>
      <p>
        Les Utilisateurs doivent contacter directement les Prestataires de
        Formation s'ils ont des questions concernant le Contenu
        Acad&eacute;mique ou les conditions g&eacute;n&eacute;rales
        d&rsquo;utilisations applicables &agrave; leur mat&eacute;riel.
      </p>
      <ul>
        <li className={styles.listItem}>
          <strong>Limitation de responsabilit&eacute;</strong>
        </li>
      </ul>
      <p>
        Les Services et leur contenu sont fournis &laquo;&nbsp;tels
        quels&nbsp;&raquo; et &laquo;&nbsp;tels que disponibles&nbsp;&raquo;.
        ZubU (et ses personnes li&eacute;es, fournisseurs, partenaires et
        agents) ne font aucune repr&eacute;sentation ou aucune garantie,
        expresse ou implicite (y compris la garantie l&eacute;gale en vertu du
        Code civil du Qu&eacute;bec) sur la pertinence, la fiabilit&eacute;, la
        disponibilit&eacute;, la rapidit&eacute;, la s&eacute;curit&eacute;,
        l'absence d'erreurs ou l'exactitude des Services ou de leur contenu, et
        d&eacute;cline et rejette express&eacute;ment toute garantie ou
        condition (expresse ou implicite), incluant les garanties implicites de
        qualit&eacute; marchande, de pertinence (ou du caract&egrave;re
        ad&eacute;quat) &agrave; un usage particulier, de titre et de
        l&rsquo;absence de contrefa&ccedil;on. ZubU (et ses personnes
        li&eacute;es, fournisseurs, partenaires et agents) ne garantit pas qu'un
        Utilisateur obtiendrait des r&eacute;sultats sp&eacute;cifiques de
        l'utilisation des Services. L&rsquo;acc&egrave;s et l&rsquo;utilisation
        des Services (incluant tout contenu) par un Utilisateur sont
        enti&egrave;rement &agrave; ses propres risques. ZubU ne garantit pas
        que la Plateforme ou le Service sera exempt d'erreurs, d'interruptions
        ou d'autres d&eacute;faillances, ou qu'ils r&eacute;pondront aux
        attentes de l'Utilisateur en mati&egrave;re de revenus ou autres
        attentes.
      </p>
      <p>
        <strong>
          Dans la mesure permise par la loi, ZubU (et ses personnes
          li&eacute;es, fournisseurs, partenaires et agents) n&rsquo;est pas
          responsable des dommages indirects, accessoires, punitifs ou
          cons&eacute;cutifs (incluant la perte de donn&eacute;es, de revenus,
          de profits ou d'opportunit&eacute;s commerciales, ou dommages
          corporels ou d&eacute;c&egrave;s), que ce soit dans le cadre du
          Contrat, de la garantie, de la responsabilit&eacute;
          extracontractuelle, du d&eacute;lit, de la n&eacute;gligence, de la
          responsabilit&eacute; du fait des produits ou autre, et m&ecirc;me
          s'il a &eacute;t&eacute; inform&eacute; de la possibilit&eacute; de
          tels dommages &agrave; l'avance.
        </strong>
      </p>
      <p>
        <strong>
          La responsabilit&eacute; globale de ZubU (et la responsabilit&eacute;
          de chacun de ses personnes li&eacute;es, fournisseurs, partenaires et
          agents) envers un Utilisateur et ses parties prenantes est, en toute
          circonstance, limit&eacute;e au montant que cet Utilisateur ou tiers a
          pay&eacute; &agrave; ZubU pour les Services dans les douze (12) mois
          avant l'&eacute;v&eacute;nement donnant lieu &agrave; la
          r&eacute;clamation.{" "}
        </strong>
      </p>
      <p>
        Toute garantie fournie dans le cadre du Contrat est nulle si
        l'Utilisateur (a)&nbsp;a modifi&eacute; la Plateforme ou le
        Service&nbsp;; (b)&nbsp;n'a pas autrement respect&eacute; le
        contrat&nbsp;; (c)&nbsp;utilise du mat&eacute;riel, des logiciels ou une
        connexion incompatible avec la Plateforme ou le Service&nbsp;; ou
        (d)&nbsp;fait une utilisation abusive ou non-autoris&eacute;e de la
        Plateforme ou du Service.
      </p>
      <p>
        <strong>
          La limitation de responsabilit&eacute; ci-dessus est soumise aux lois
          applicables et peut ne pas s'appliquer &agrave; toutes les
          circonstances si certaines juridictions ne permettent pas son
          application, par exemple, quant &agrave; l'exclusion des dommages
          indirects ou accessoires, l'exclusion des garanties implicites ou la
          limitation de la responsabilit&eacute; pour blessures corporelles ou
          d&eacute;c&egrave;s.
        </strong>
      </p>
      <ol start="8">
        <li className={styles.listItem}>
          <strong>DIVERS</strong>
          <ul>
            <li className={styles.listItem}>
              <strong>Accord contraignant</strong>
            </li>
          </ul>
        </li>
      </ol>
      <p>
        Chaque Utilisateur accepte qu'en s'inscrivant, en acc&eacute;dant ou en
        utilisant les Services, il conclut un contrat contraignant avec ZubU
        d&rsquo;un point de vue juridique. Si un utilisateur n'accepte pas les
        pr&eacute;sentes Conditions G&eacute;n&eacute;rales d&rsquo;Utilisation,
        il ne doit pas s'inscrire, acc&eacute;der ou utiliser de quelque
        mani&egrave;re que ce soit les Services.
      </p>
      <ul>
        <li className={styles.listItem}>
          <strong>Langue</strong>
        </li>
      </ul>
      <p>
        Dans la mesure permise par la loi applicable, toute version des
        pr&eacute;sentes Conditions G&eacute;n&eacute;rales d&rsquo;Utilisation
        dans une langue autre que l'anglais est fournie pour des raisons de
        commodit&eacute; et la langue anglaise pr&eacute;vaut en cas de conflit.
        Dans la province de Qu&eacute;bec, la version fran&ccedil;aise
        pr&eacute;vaut si et quand les lois applicables l'exigent.
      </p>
      <ul>
        <li className={styles.listItem}>
          <strong>Divisibilit&eacute;</strong>
        </li>
      </ul>
      <p>
        Si une disposition du contrat est jug&eacute;e nulle ou inapplicable en
        vertu des lois applicables, la disposition en question sera
        r&eacute;put&eacute;e remplac&eacute;e par une disposition valide et
        ex&eacute;cutoire qui se rapproche le plus de l'intention et de
        l&rsquo;objet de la disposition originale et le reste du contrat
        continuera de s&rsquo;appliquer dans son int&eacute;gralit&eacute;.
      </p>
      <ul>
        <li className={styles.listItem}>
          <strong>Renonciation</strong>
        </li>
      </ul>
      <p>
        M&ecirc;me si ZubU retarde ou ne parvient pas &agrave; exercer un droit
        dans une circonstance, cela ne constitue pas une renonciation &agrave;
        ses droits en vertu des pr&eacute;sentes Conditions
        G&eacute;n&eacute;rales d&rsquo;Utilisation, et il peut d&eacute;cider
        de les exercer &agrave; l'avenir. Une renonciation de ZubU n&rsquo;est
        valide que si express&eacute;ment consentie par &eacute;crit.
      </p>
      <ul>
        <li className={styles.listItem}>
          <strong>Survie</strong>
        </li>
      </ul>
      <p>
        Les paragraphes et articles suivants subsisteront &agrave; l'expiration
        ou &agrave; la r&eacute;siliation des pr&eacute;sentes Conditions
        G&eacute;n&eacute;rales d&rsquo;Utilisation&nbsp;: paragraphes 4.3 et
        4.5 et Articles&nbsp;5, 6, 7, 8 et 9.
      </p>
      <ul>
        <li className={styles.listItem}>
          <strong>Lois applicables et juridiction</strong>
        </li>
      </ul>
      <p>
        Le Contrat est r&eacute;gi par les lois de la province de Qu&eacute;bec,
        Canada, excluant toute disposition relative aux conflits de loi ou
        comp&eacute;tence l&eacute;gislative.
      </p>
      <ul>
        <li className={styles.listItem}>
          <strong>Relation</strong>
        </li>
      </ul>
      <p>
        Les Services et le Contrat ne cr&eacute;ent aucune relation de
        coentreprise, de partenariat, d'emploi, de sous-traitant ou d'agence
        entre un Utilisateur et ZubU.
      </p>
      <ul>
        <li className={styles.listItem}>
          <strong>Transfert</strong>
        </li>
      </ul>
      <p>
        Un Utilisateur ne peut c&eacute;der ou transf&eacute;rer ses droits ou
        ses obligations en vertu du Contrat (ou les droits et licences
        accord&eacute;s en vertu de ceux-ci, y compris son Compte).
      </p>
      <p>
        ZubU peut c&eacute;der les pr&eacute;sentes Conditions
        G&eacute;n&eacute;rales d&rsquo;Utilisation (ou les droits et licences
        accord&eacute;s en vertu de celles-ci) &agrave; une autre
        soci&eacute;t&eacute; ou personne sans restriction.
      </p>
      <ol start="9">
        <li className={styles.listItem}>
          <strong>R&Egrave;GLEMENT DE DIFF&Eacute;RENDS</strong>
          <ul>
            <li className={styles.listItem}>
              <strong>Avis de r&eacute;clamation et de r&egrave;glement</strong>
            </li>
          </ul>
        </li>
      </ol>
      <p>
        Un utilisateur doit informer ZubU dans un d&eacute;lai maximal d'un (1)
        an &agrave; la suite de la survenance de la cause de quelconque action,
        par courrier recommand&eacute; avec demande d&rsquo;avis de
        r&eacute;ception ou par courrier &eacute;lectronique &agrave;{" "}
        <a href="mailto:legal@zubueducation.com">legal@zubueducation.com</a>. Le
        non-respect de cette consigne constituera une renonciation
        irr&eacute;vocable &agrave; la r&eacute;clamation. En cas de litige,
        ZubU est disponible pour aider &agrave; r&eacute;soudre le
        probl&egrave;me &agrave; l'amiable.
      </p>
      <ul>
        <li className={styles.listItem}>
          <strong>Arbitrage</strong>
        </li>
      </ul>
      <p>
        Si un litige ou une r&eacute;clamation ne peut pas &ecirc;tre
        r&eacute;solu &agrave; l'amiable, chaque Utilisateur et ZubU conviennent
        de le soumettre &agrave; la juridiction exclusive d&rsquo;un arbitrage
        d&eacute;finitif et ex&eacute;cutoire. L'arbitrage se d&eacute;roulera
        en anglais (ou si l'Utilisateur est dans la province de Qu&eacute;bec,
        en fran&ccedil;ais s'il le demande), dans la ville de Montr&eacute;al,
        devant un seul arbitre d&eacute;sign&eacute; par ZubU. Si l'Utilisateur
        conteste la nomination de l'arbitre, il doit demander sa nomination par
        un tribunal du Qu&eacute;bec, district de Montr&eacute;al,
        conform&eacute;ment au Code de proc&eacute;dure civile du Qu&eacute;bec.
        Si l'arbitre le juge appropri&eacute;, l'arbitrage sera men&eacute; par
        t&eacute;l&eacute;phone, en ligne ou uniquement par &eacute;crit. La
        sentence arbitrale rendue peut &ecirc;tre homologu&eacute;e par tout
        tribunal.
      </p>
      <p>
        Nonobstant ce qui pr&eacute;c&egrave;de, les petites cr&eacute;ances
        peuvent &ecirc;tre r&eacute;gl&eacute;es devant les tribunaux des
        petites cr&eacute;ances de la province de Qu&eacute;bec, district de
        Montr&eacute;al.
      </p>
      <ul>
        <li className={styles.listItem}>
          <strong>Forum alternatif</strong>
        </li>
      </ul>
      <p>
        Dans la mesure o&ugrave; la cause d'arbitrage ci-dessus est invalide, la
        r&eacute;clamation, le diff&eacute;rend ou le litige sera de la
        comp&eacute;tence exclusive des tribunaux du Qu&eacute;bec, au Canada,
        dans le district de Montr&eacute;al.
      </p>
      <ul>
        <li className={styles.listItem}>
          <strong>Aucun recours collectif</strong>
        </li>
      </ul>
      <p>
        ZubU et tous les Utilisateurs conviennent que chacun d&rsquo;entre eux
        ne peut intenter des r&eacute;clamations contre l'autre que sur une base
        individuelle. Cela signifie&nbsp;: (a)&nbsp;qu&rsquo;aucun d&rsquo;entre
        eux ne peut intenter une action en tant que demandeur ou membre
        d&rsquo;un recours collectif, une action consolid&eacute;e ou un mandat
        repr&eacute;sentatif&nbsp;; (b)&nbsp;un arbitre ou un tribunal ne peut
        pas combiner les r&eacute;clamations de plusieurs personnes en une seule
        affaire (ou pr&eacute;sider une action consolid&eacute;e, collective ou
        repr&eacute;sentative)&nbsp;; et (c)&nbsp;la d&eacute;cision ou la
        sentence d'un arbitre ou d'un tribunal dans le cas d'une personne ne
        peut avoir un impact que sur cet Utilisateur et non sur les autres
        Utilisateurs, et ne peut pas &ecirc;tre utilis&eacute;e pour trancher
        les diff&eacute;rends avec d&rsquo;autres Utilisateurs. Si un tribunal
        d&eacute;cide que ce paragraphe 9.4 n&rsquo;est ni ex&eacute;cutoire ni
        valide, alors il sera nul et non avenu, mais le reste du Contrat
        s&rsquo;appliquera int&eacute;gralement.
      </p>
      <ol start="10">
        <li className={styles.listItem}>
          <strong>
            MODIFICATIONS ET MISES &Agrave; JOUR DES CONDITIONS
            G&Eacute;N&Eacute;RALES D&rsquo;UTILISATION
          </strong>
        </li>
      </ol>
      <p>
        ZubU peut, de temps en temps, mettre &agrave; jour ces Conditions
        G&eacute;n&eacute;rales d&rsquo;Utilisation et les Politiques ZubU pour
        clarifier les pratiques ou pour refl&eacute;ter les nouvelles ou les
        diff&eacute;rentes pratiques (telles que de nouvelles
        fonctionnalit&eacute;s) et ZubU se r&eacute;serve le droit, &agrave; son
        enti&egrave;re discr&eacute;tion, de modifier et/ou apporter des
        modifications &agrave; tout moment aux Conditions
        G&eacute;n&eacute;rales d&rsquo;Utilisation et aux Politiques ZubU. ZubU
        fournira un avis au moment d&rsquo;&eacute;mettre un changement, tel
        qu&rsquo;un courriel envoy&eacute; &agrave; l&rsquo;adresse courriel de
        l&rsquo;Utilisateur indiqu&eacute; &agrave; son Compte ou en publiant un
        avis sur la Plateforme. Les modifications entrent en vigueur le jour
        m&ecirc;me de leur publication, &agrave; moins qu&rsquo;il ne soit
        autrement indiqu&eacute;.
      </p>
      <p>
        L&rsquo;utilisation continue des Services par un Utilisateur
        apr&egrave;s l&rsquo;entr&eacute;e en vigueur des modifications signifie
        que l&rsquo;Utilisateur accepte ces modifications. Toutes Conditions
        G&eacute;n&eacute;rales d&rsquo;Utilisation r&eacute;vis&eacute;es
        remplacent les Conditions G&eacute;n&eacute;rales d&rsquo;Utilisation
        pr&eacute;c&eacute;dentes.
      </p>
      <p>
        Nonobstant ce qui pr&eacute;c&egrave;de, dans la mesure requise par les
        lois applicables, si ZubU modifie l&rsquo;Article&nbsp;9, un Utilisateur
        peut refuser une telle modification en fournissant &agrave; ZubU un avis
        &eacute;crit de son rejet par courrier ou par remise en main propre
        &agrave; l&rsquo;adresse indiqu&eacute;e &agrave;
        l&rsquo;Article&nbsp;11, dans les trente (30) jours suivant la date de
        l&rsquo;entr&eacute;e en vigueur des modifications.
      </p>
      <p>
        Afin d&rsquo;&ecirc;tre valide, l&rsquo;avis doit inclure le nom complet
        de l&rsquo;Utilisateur et doit clairement indiquer son intention de
        refuser les modifications &agrave; la section
        &laquo;&thinsp;R&egrave;glement de diff&eacute;rends&thinsp;&raquo;. En
        refusant les modifications, l&rsquo;Utilisation devra r&eacute;gir tout
        conflit ou litige conform&eacute;ment aux dispositions de
        l&rsquo;Article&nbsp;9 tel qu&rsquo;en vigueur pr&eacute;alablement aux
        modifications contest&eacute;es.
      </p>
      <ol start="11">
        <li className={styles.listItem}>
          <strong>CONTACT</strong>
        </li>
      </ol>
      <p>
        <strong>ZubU inc.</strong> <br />
        11655 Rue Letellier
        <br />
        Montr&eacute;al (Quebec)
        <br />
        H3M 2Z7 Canada
      </p>
      <p>
        Le service &agrave; la client&egrave;le est disponible au{" "}
        <a href="mailto:customerservice@zubueducation.com">
          customerservice@zubueducation.com
        </a>
      </p>
      <p>
        ZubU peut rejoindre chaque Utilisateur aux coordonn&eacute;es fournies
        dans son Compte ou via les fonctions de communication de la Plateforme.
      </p>
      <p>&nbsp;</p>
      <p>&nbsp;</p>
    </div>
  );
};
