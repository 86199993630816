class CertificateLink {
  constructor(course_id, certId, title, collection, collection_title) {
    this.course_id = course_id;
    this.certId = certId;
    this.title = title;
    this.collection = collection;
    this.collection_title = collection_title;
  }
}

export default CertificateLink;
