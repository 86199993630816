import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import MatAppBar from "../../components/ui/appBar/MatAppBar";
import { connect } from "react-redux";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import Button from "@material-ui/core/Button";
import * as paymentActions from "../../store/actions/payment";
import * as profileActions from "../../store/actions/profile";
import Spinner from "../../components/ui/spinner/Spinner";
import PaymentItem from "../../components/items/paymentItem/PaymentItem";
import ConfirmModal from "../../components/modals/confirmModal/ConfirmModal";
import Empty from "../../components/ui/empty/Empty";
import PaymentIcon from "@material-ui/icons/Payment";
import paymentImg from "../../assets/images/inApp/payment.png";
import * as logger from "../../globals/Logger";
import { objectHasNoValues } from "../../globals/Common";

class PaymentScreen extends Component {
  state = {
    wait: false,
    openDeleteConfirmModal: false,
    idToBeDeleted: null,
    error: null,
  };

  // ------------------------------

  update = async (propValue) => {
    await this.setState({ ...this.state, ...propValue });
  };

  // ------------------------------

  componentDidMount = async () => {
    try {
      await this.update({ wait: true });

      const loadAsync = [];

      if (!this.props.isMobileUser && objectHasNoValues(this.props.profile)) {
        loadAsync.push(this.props.fetchProfile());
      } else if (
        this.props.isMobileUser &&
        objectHasNoValues(this.props.mobileUserProfile)
      ) {
        loadAsync.push(this.props.fetchMobileUserProfile());
      }

      loadAsync.push(this.props.fetchPaymentMethods());

      await Promise.all(loadAsync);

      await this.update({ wait: false });

      window.scrollTo(0, 0);
    } catch (err) {
      logger.error(err);
      this.update({ wait: false, error: err.message });
    }
  };

  // ------------------------------

  addPaymentMethod = async (event) => {
    try {
      this.props.history.push("/addpayment");
    } catch (err) {
      logger.error(err);
      this.update({ wait: false, error: err.message });
    }
  };

  // ------------------------------

  deleteHandler = (id) => {
    this.update({
      openDeleteConfirmModal: true,
      idToBeDeleted: id,
    });
  };

  // ------------------------------

  deleteConfirmHandler = async (answer) => {
    if (answer === "ok") {
      try {
        await this.update({
          wait: true,
          openDeleteConfirmModal: false,
        });

        await this.props.requestDetachPaymentMethod(this.state.idToBeDeleted);

        await this.update({
          wait: false,
          idToBeDeleted: null,
        });
      } catch (err) {
        logger.error(err);
        this.update({
          wait: false,
          error: err.message,
        });
      }
    } else {
      this.update({
        openDeleteConfirmModal: false,
        idToBeDeleted: null,
      });
    }
  };

  // ------------------------------

  render() {
    if (this.state.wait) {
      return <Spinner />;
    }

    if (!this.props.paymentMethods || this.props.paymentMethods.length === 0) {
      return (
        <Empty
          column
          image={paymentImg}
          message={
            <div>
              <p>{this.props.t("cart_no_credit_card")}</p>
            </div>
          }
          onClick={this.addPaymentMethod}
          buttonIcon={<PaymentIcon />}
          buttonLabel={this.props.t("g_add_payment_method")}
        />
      );
    }

    return (
      <div>
        {this.state.openDeleteConfirmModal && (
          <ConfirmModal
            title="Confirmation Required"
            message={"Are you sure you wish to remove this payment method ?"}
            open={this.state.openDeleteConfirmModal}
            onClose={this.deleteConfirmHandler}
          />
        )}

        <MatAppBar
          right={
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <Button
                color="inherit"
                style={{ color: "black" }}
                onClick={this.addPaymentMethod}
                startIcon={<AddCircleIcon />}
              >
                {this.props.t("g_new")}
              </Button>
            </div>
          }
        />
        <div style={{ height: "58px" }}></div>
        <div>
          {this.props.paymentMethods.map((item, index) => (
            <PaymentItem
              key={index}
              id={item.id}
              lastDigits={item.lastDigitsMasked}
              cardExpiry={item.expiryDate}
              brand={item.brand}
              onClick={() => {}}
              delete={this.deleteHandler.bind(this, item.id)}
            />
          ))}
        </div>
      </div>
    );
  }
}

// ------------------------------

const mapStateToProps = (state) => {
  return {
    profile: state.profile.profile,
    mobileUserProfile: state.profile.mobileUserProfile,
    paymentMethods: state.payment.payment,
    isMobileUser: state.auth.isMobileUser,
  };
};

// ------------------------------

const mapDispatchToProps = (dispatch) => {
  return {
    fetchPaymentMethods: () => dispatch(paymentActions.fetchPaymentMethods()),
    requestDetachPaymentMethod: (pmId) =>
      dispatch(paymentActions.requestDetachPaymentMethod(pmId)),
    fetchProfile: () => dispatch(profileActions.fetchProfile()),
    fetchMobileUserProfile: () =>
      dispatch(profileActions.fetchMobileUserProfile()),
  };
};

export default withTranslation("translations")(
  connect(mapStateToProps, mapDispatchToProps)(PaymentScreen)
);
