import React, { Component } from "react";
import styles from "./Legal.module.css";
import { connect } from "react-redux";
import { renderSupplierTermsOfUse } from "./templateEn/SupplierHtml";
import BackButtonBanner from "../../components/ui/backButtonBanner/BackButtonBanner";

class SupplierScreen extends Component {
  // ------------------------------

  componentDidMount = () => {
    window.scrollTo(0, 0);
  };

  // ------------------------------

  render() {
    return (
      <div>
        {!this.props.isAuthenticated && (
          <BackButtonBanner history={this.props.history} />
        )}
        <div className={styles.screen}>{renderSupplierTermsOfUse()}</div>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    isAuthenticated: state.auth.token != null,
  };
};

export default connect(mapStateToProps, null)(SupplierScreen);
