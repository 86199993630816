import { SPECIALIST, OCCUPATION_DENTIST_ONLY } from "../globals/Data";
import { valueInCollection } from "../globals/Common";

class Attendance {
  constructor(
    id,
    first,
    last,
    license,
    occupation,
    registeredByName,
    orderRefNumber,
    checkedIn
  ) {
    this.id = id;
    this.first = first;
    this.last = last;
    this.license = license;
    this.occupation = occupation;
    this.registeredByName = registeredByName;
    this.orderRefNumber = orderRefNumber;
    this.checkedIn = checkedIn;
  }

  prefix = () => {
    return this.isDentist() || this.isSpecialist() ? "Dr. " : "";
  };

  isSpecialist = () => {
    return valueInCollection(SPECIALIST, this.occupation);
  };

  isDentist = () => {
    return valueInCollection(OCCUPATION_DENTIST_ONLY, this.occupation);
  };
}

export default Attendance;
