import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import FlatList from "flatlist-react";
import MobilePricingItem from "../../items/mobilePricingItem/MobilePricingItem";
import InputMemberModal from "../../modals/inputModal/InputMemberModal"
import Spinner from "../../../components/ui/spinner/Spinner";

// ------------------------------


const transformSelfToOption = (staff, item) => {
  let team = [];

  if (item.members_only) {
    for (const key in staff) {
      if (staff[key].occupationMatch(item.audience) && staff[key].accountOwner) {
        team.push({
          id: staff[key].id,
          text: staff[key].first + " " + staff[key].last.substring(0, 1) + ".",
          pricingItem: item,
        });
      }
    }
  }

  return team;
};


const transformStaffToOptions = (staff, item) => {
  let team = [];
  for (const key in staff) {
    if (staff[key].occupationMatch(item.audience)) {
      team.push({
        id: staff[key].id,
        text: staff[key].first + " " + staff[key].last.substring(0, 1) + ".",
        pricingItem: item,
      });
    }
  }

  return team;
};

// ------------------------------

const checkMatchingMembership = (mobileMemberships, providerId) => {
  const foundIndex = mobileMemberships.findIndex(
    (m) => providerId === m.provider_id
  );

  return foundIndex > -1
}


// ------------------------------


const AddToCartModal = (props) => {
  const { t } = useTranslation();
  const [courseParticipants, setCourseParticipants] = useState([]);
  const [showInput, setShowInput] = useState(false);
  const fullScreen = false;
  const fullWidth = true;
  const maxWidth = "sm";
  const [tempSelection, setTempSelection] = useState(null);

  const isSelectDisabled =
    !courseParticipants || courseParticipants.length === 0;

  // ------------------------------

  const handleClose = () => {
    props.onFinish();
  };

  // ------------------------------

  const renderRegOption = (itemData, index) => {
    const registrationOptionCost = Number(
      itemData.resolveCost(props.amendment, index)
    );

    const registrationOptionViewCost = Number(
      itemData.resolveViewCost(
        props.providerCurrency, // providercurrency
        props.rates,
        props.amendment,
        index
      )
    );

    const memberStaffForOption = transformSelfToOption(
      props.staff,
      itemData
    );

    const listOfStaffForOptions = transformStaffToOptions(
      props.staff,
      itemData
    );

    const capacity =
      props.isVideo || itemData.casting === "Livestream"
        ? props.capacityOnline
        : itemData.component === "Lecture Only"
          ? props.capacityClassroom
          : props.capacityClassroomHandson;

    return (
      <MobilePricingItem
        optionMember={memberStaffForOption}
        options={listOfStaffForOptions}
        selectedParticipants={courseParticipants}
        row={index}
        key={index}
        multiPayOption={props.multiPayOption}
        isVideo={props.isVideo}
        pricingItem={itemData}
        amendment={props.amendment}
        providerCurrency={props.providerCurrency}
        currency={props.currencyObj}
        capacity={capacity}
        rates={props.rates}
        fromModal
        onClick={(smId) => {
          if (itemData.members_only && !checkMatchingMembership(props.mobileMemberships, props.providerID)) {
            const foundOptionIndex = courseParticipants.findIndex(
              (it) => smId === it.id && itemData === it.pricingItem
            );

            if (foundOptionIndex === -1) {
              setTempSelection({
                itemData: itemData,
                smId: smId,
                registrationOptionCost: registrationOptionCost,
                registrationOptionViewCost: registrationOptionViewCost
              })

              setShowInput(true)
            }
            else {
              updateSelection(itemData, smId, registrationOptionCost, registrationOptionViewCost);
            }
          }
          else {
            updateSelection(itemData, smId, registrationOptionCost, registrationOptionViewCost);
          }
        }}
      />
    );
  };

  // ------------------------------

  const updateSelection = (itemData, smId, registrationOptionCost, registrationOptionViewCost, addToCart) => {
    const foundOptionIndex = courseParticipants.findIndex(
      (it) => smId === it.id && itemData === it.pricingItem
    );

    const foundIndex = courseParticipants.findIndex(
      (it) => smId === it.id
    );

    if (foundIndex > -1 && foundOptionIndex === -1) {
      const copyArr = [...courseParticipants];
      copyArr.splice(foundIndex, 1);

      copyArr.push({
        id: smId,
        pricingItem: itemData,
        cost: registrationOptionCost,
        viewCost: registrationOptionViewCost,
      });

      setCourseParticipants(copyArr);
    } else if (foundIndex > -1) {
      const copyArr = [...courseParticipants];
      copyArr.splice(foundIndex, 1);
      setCourseParticipants(copyArr);
    } else {
      const copyArr = [...courseParticipants];

      copyArr.push({
        id: smId,
        pricingItem: itemData,
        cost: registrationOptionCost,
        viewCost: registrationOptionViewCost,
      });

      setCourseParticipants(copyArr)

      if (addToCart) {
        props.onAddToCart(
          copyArr,
          props.staff[0]
        )
        setTempSelection(null);
        setShowInput(false);
      }
    }
  }

  // ------------------------------

  return (
    <div>
      {props.waitModal && <Spinner />}
      <Dialog
        fullScreen={fullScreen}
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={props.open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >

        {showInput &&
          <InputMemberModal
            providerName={props.providerName}
            open={showInput}
            onClose={async (memberId) => {
              if (memberId) {
                const valid = await props.validateMemberID(memberId)
                if (valid) {
                  updateSelection(
                    tempSelection.itemData,
                    tempSelection.smId,
                    tempSelection.registrationOptionCost,
                    tempSelection.registrationOptionViewCost,
                    true);
                }
                else {
                  setTempSelection(null);
                }
              }

              setShowInput(false);
            }}
          />
        }

        <DialogTitle id="alert-dialog-title">
          {t("cart_confirm_option")}
        </DialogTitle>

        <DialogContent>
          <FlatList list={props.pricingItems} renderItem={renderRegOption} />
        </DialogContent>

        <DialogActions>
          <Button
            autoFocus
            onClick={handleClose.bind(this, null)}
            color="primary"
          >
            {t("g_cancel")}
          </Button>
          <Button
            autoFocus
            onClick={props.onAddToCart.bind(
              this,
              courseParticipants,
              props.staff[0]
            )}
            color="primary"
            disabled={isSelectDisabled}
            style={
              isSelectDisabled
                ? { color: "gray", backgroundColor: "#ccc" }
                : { color: "white", backgroundColor: "#3E8CAB" }
            }
          >
            {t("cart_add_to")}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default AddToCartModal;
