import React, { Component } from "react";
import * as professorActions from "../../store/actions/professor";
import MatAppBar from "../../components/ui/appBar/MatAppBar";
import { connect } from "react-redux";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import Button from "@material-ui/core/Button";
import Spinner from "../../components/ui/spinner/Spinner";
import ProfessorItem from "../../components/items/professorItem/ProfessorItem";
import ConfirmModal from "../../components/modals/confirmModal/ConfirmModal";
import Empty from "../../components/ui/empty/Empty";
import profImg from "../../assets/images/inApp/prof.png";
import AssignmentIndIcon from "@material-ui/icons/AssignmentInd";
import * as logger from "../../globals/Logger";

class ProfessorScreen extends Component {
  state = {
    wait: false,
    openDeleteConfirmModal: false,
    idToBeDeleted: null,
    error: null,
  };

  // ------------------------------

  update = async (propValue) => {
    await this.setState({ ...this.state, ...propValue });
  };

  // ------------------------------

  componentDidMount = async () => {
    try {
      await this.update({ wait: true });
      await this.props.fetchProfessor();
      await this.update({ wait: false });
    } catch (err) {
      logger.error(err);
      this.update({ wait: false, error: err.message });
    }
  };

  // ------------------------------

  addHandler = async () => {
    this.props.setAddAction();
    this.props.history.push("/editProf");
  };

  // ------------------------------

  editHandler = (id) => {
    this.props.setEditAction(id);
    this.props.history.push("/editProf");
  };

  // ------------------------------

  deleteHandler = (id) => {
    this.update({
      openDeleteConfirmModal: true,
      idToBeDeleted: id,
    });
  };

  // ------------------------------

  deleteConfirmHandler = async (answer) => {
    if (answer === "ok") {
      try {
        await this.update({
          wait: true,
          openDeleteConfirmModal: false,
        });

        await this.props.removeProfessor(this.state.idToBeDeleted);

        await this.update({
          wait: false,
          idToBeDeleted: null,
        });
      } catch (err) {
        logger.error(err);
        this.update({
          wait: false,
          error: err.message,
        });
      }
    } else {
      this.update({
        openDeleteConfirmModal: false,
        idToBeDeleted: null,
      });
    }
  };

  // ------------------------------

  renderProfessorItems = () => {
    return this.props.professor.map((item, index) => {
      return (
        <ProfessorItem
          key={index}
          id={item.id}
          name={item.fullName()}
          occupation={item.occupation}
          bio={item.bio}
          image={item.picture}
          edit={this.editHandler.bind(this, item.id)}
          delete={this.deleteHandler.bind(this, item.id)}
        />
      );
    });
  };
  // ------------------------------

  render() {
    if (this.state.wait) {
      return <Spinner />;
    }

    if (!this.props.professor || this.props.professor.length === 0) {
      return (
        <Empty
          column
          image={profImg}
          message={
            <p>
              You currently have no professors/speakers. Create a list of
              professors with detailed biographies that will be presenting your
              courses. When creating courses, you will be able to select a
              professor from this list and their biography will be automatically
              linked to your course page when activated on the ZubU mobile app.
              If you add a professor from the course building page directly, you
              will have to complete their biography here if you wish to include
              a biography. Biographies are not mandatory.
            </p>
          }
          onClick={this.addHandler}
          buttonIcon={<AssignmentIndIcon />}
          buttonLabel={"Add Professor"}
        />
      );
    }

    return (
      <div style={{ backgroundColor: "white" }}>
        <ConfirmModal
          title="Confirmation Required"
          message={"Are you sure you wish to remove this professor ?"}
          open={this.state.openDeleteConfirmModal}
          onClose={this.deleteConfirmHandler}
        />
        <MatAppBar

          right={
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <Button
                color="inherit"
                style={{ color: "black" }}
                onClick={this.addHandler}
                startIcon={<AddCircleIcon />}
              >
                New
              </Button>
            </div>
          }
        ></MatAppBar>
        <div style={{ height: "58px" }}></div>
        {this.renderProfessorItems()}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    professor: state.professor.professor,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    removeProfessor: (id) => dispatch(professorActions.removeProfessor(id)),
    setAddAction: () => dispatch(professorActions.setAddAction()),
    setEditAction: (id) => dispatch(professorActions.setEditAction(id)),
    fetchProfessor: () => dispatch(professorActions.fetchProfessor()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProfessorScreen);
