import React, { Component } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import * as settingActions from "../../store/actions/setting";
import styles from "./WelcomeScreen.module.css";
import styles2 from "./BannerSlide.module.css";
import Button from "@material-ui/core/Button";
import Grow from "@material-ui/core/Grow";
import Zoom from "@material-ui/core/Zoom";
import Footer from "../../components/ui/footer/Footer";
import ProviderSlider from "./slide/ProviderSlider";
import IncludedSlide from "./slide/IncludedSlide";
import WelcomeSlider from "./slide/WelcomeSlider";
import video_back from "../../assets/images/welcome/video_back.png";
import reach from "../../assets/images/welcome/4.png";
import visibility from "../../assets/images/welcome/3.png";
import manage from "../../assets/images/welcome/1.png";
import heavy from "../../assets/images/welcome/2.png";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import ReactPlayer from "react-player";
import DefaultToolbar from "./DefaultToolbar";
import backImg from "../../assets/images/welcome/flat.jpg";
import phones from "../../assets/images/welcome/phones.png";
import OurProviders from "../../components/ui/ourProviders/OurProviders";
import PhoneBadges from "../../components/ui/phoneBadges/PhoneBadges";
import SignupTypeModal from "../../components/modals/signupTypeModal/SignupTypeModal";
import { setLanguage } from "../../globals/Common";

import * as logger from "../../globals/Logger";

const videoFilePath = "../video/zubu_mobile_app.mp4";
const promoFilePath = "../video/zubu_promo.mp4";

class WelcomeScreen extends Component {
  state = {
    openSignupTypeModal: false,
  };

  componentDidMount = () => {
    window.scrollTo(0, 0);
  };

  // ------------------------------

  contactSalesHandler = () => {
    window.open("mailto:info@zubueducation.com", "_blank");
  };

  // ------------------------------

  updateMobileCurrency = async (currency, language) => {
    try {
      await this.props.updateCurrency(currency, language);
      setLanguage(language, this.props.i18n);
    } catch (error) {
      logger.error(error);
    }
  };

  // ------------------------------

  signupHandler = () => {
    this.setState({ ...this.state, openSignupTypeModal: true });
  };

  // ------------------------------

  render() {
    const enrollStyle = {
      backgroundColor: "#3E8CAB",
      color: "white",
      height: "45px",
    };

    const enrollStyleWhite = {
      backgroundColor: "white",
      color: "black",
      height: "45px",
    };

    return (
      <div className={styles.screen}>
        {this.state.openSignupTypeModal && (
          <SignupTypeModal
            open={this.state.openSignupTypeModal}
            onClose={() => {
              this.setState({ ...this.state, openSignupTypeModal: false });
            }}
            history={this.props.history}
          />
        )}
        <DefaultToolbar
          history={this.props.history}
          i18n={this.props.i18n}
          mobileSetting={this.props.mobileSetting}
          updateMobileCurrency={this.updateMobileCurrency}
          showSearch={true}
        />
        <div className={styles.headerBackground}>
          <div className={styles.headerTextContainer}>
            <div className={styles.headerSpaceBetween}>
              <div>
                <Grow
                  in={true}
                  style={{ transformOrigin: "0 0 0" }}
                  {...(true ? { timeout: 2000 } : {})}
                >
                  <div className={styles.floatingTitle1}>
                    {this.props.t("welcome_for_dental_professionals")}
                  </div>
                </Grow>

                <Grow
                  in={true}
                  style={{ transformOrigin: "0 0 0" }}
                  {...(true ? { timeout: 3000 } : {})}
                >
                  <div
                    className={styles.floatingTitle3}
                    style={{ paddingBottom: "15px" }}
                  >
                    {this.props.t("welcome_for_dental_desc_1")}
                  </div>
                </Grow>
              </div>

              <div>
                <Grow
                  in={true}
                  style={{ transformOrigin: "0 0 0" }}
                  {...(true ? { timeout: 3000 } : {})}
                >
                  <div
                    className={styles.floatingTitle2}
                    style={{ paddingBottom: "15px" }}
                  >
                    {this.props.t("welcome_for_dental_desc_2")}
                  </div>
                </Grow>

                <PhoneBadges />
              </div>
            </div>
          </div>

          {/******/}

          <div>
            <Zoom
              in={true}
              style={{ transitionDelay: true ? "1000ms" : "0ms" }}
            >
              <img src={phones} alt="" className={styles.headerImagePhone} />
            </Zoom>
          </div>

          {/******/}

          <div className={styles.headerTextContainer}>
            <div className={styles.headerNoSpaceBetween}>
              <div>
                <Grow
                  in={true}
                  style={{ transformOrigin: "0 0 0" }}
                  {...(true ? { timeout: 2000 } : {})}
                >
                  <div className={styles.floatingTitle1}>
                    {this.props.t("welcome_for_provider")}
                  </div>
                </Grow>

                <Grow
                  in={true}
                  style={{ transformOrigin: "0 0 0" }}
                  {...(true ? { timeout: 3000 } : {})}
                >
                  <div
                    className={styles.floatingTitle3}
                    style={{ paddingBottom: "15px" }}
                  >
                    {this.props.t("welcome_for_provider_desc_1")}
                  </div>
                </Grow>
              </div>
              <div>
                <Grow
                  in={true}
                  style={{ transformOrigin: "0 0 0" }}
                  {...(true ? { timeout: 3000 } : {})}
                >
                  <div
                    className={styles.floatingTitle2}
                    style={{ paddingBottom: "15px" }}
                  >
                    {this.props.t("welcome_for_provider_desc_2")}
                  </div>
                </Grow>
              </div>
            </div>
          </div>
          {/******/}
        </div>

        <OurProviders />
        <WelcomeSlider />

        <div className={styles2.containerAreYou}>
          <div className={styles2.textContainer}>
            <div className={styles2.title}>
              {this.props.t("welcome_discover_the_features")}
            </div>
            <div
              className={styles2.subTitle}
              style={{
                paddingBottom: "40px",
              }}
            >
              <div>{this.props.t("welcome_discover_the_features_sub")}</div>
            </div>
          </div>

          <ReactPlayer
            url={videoFilePath}
            controls={true}
            muted={false}
            light={video_back}
            playing={false}
            config={{ file: { attributes: { controlsList: "nodownload" } } }}
            onContextMenu={(e) => e.preventDefault()}
            style={{
              borderRadius: "10px",
              overflow: "hidden",
              maxWidth: "100%",
            }}
          />
        </div>
        <div className={styles2.containerAreYou}>
          <div className={styles2.textContainer}>
            <div className={styles2.title}>
              {this.props.t("welcome_for_provider")}
            </div>
            <div
              className={styles2.subTitle}
              style={{
                paddingBottom: "40px",
              }}
            >
              <div className={styles.paraBlurbTitle2}>
                {this.props.t("welcome_for_providers_sub_1")}
              </div>

              <div className={styles.paraBlurbTitle2}>
                {this.props.t("welcome_for_providers_sub_2")}
              </div>

              <div className={styles.paraBlurbTitle2}>
                {this.props.t("welcome_for_providers_sub_3")}
              </div>
            </div>
          </div>

          <ReactPlayer
            url={promoFilePath}
            controls={true}
            muted={false}
            playing={false}
            config={{ file: { attributes: { controlsList: "nodownload" } } }}
            onContextMenu={(e) => e.preventDefault()}
            style={{
              borderRadius: "10px",
              overflow: "hidden",
              maxWidth: "100%",
            }}
          />
        </div>
        <div className={styles.pageContainerLight}>
          <div className={styles.centerTitleContainer}>
            <div
              style={{
                textAlign: "center",
                width: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div className={styles.floatingTitle4}>
                {this.props.t("welcome_benefits_to_provider_title")}
              </div>
            </div>
          </div>

          <div className={styles.blubsContainer}>
            <div className={styles.blurbContainer}>
              <div className={styles.benefitImageContainer}>
                <img src={reach} className={styles.benefitImage} alt="" />
              </div>
              <div className={styles.blurbTitle}>
                {this.props.t("welcome_benefits_to_provider_sub_title_1")}
              </div>
              <div className={styles.blurbDescription}>
                {this.props.t("welcome_benefits_to_provider_sub_text_1")}
              </div>
            </div>

            <div className={styles.blurbContainer}>
              <div className={styles.benefitImageContainer}>
                <img src={visibility} className={styles.benefitImage} alt="" />
              </div>

              <div className={styles.blurbTitle}>
                {this.props.t("welcome_benefits_to_provider_sub_title_2")}
              </div>
              <div className={styles.blurbDescription}>
                {this.props.t("welcome_benefits_to_provider_sub_text_2")}
              </div>
            </div>

            <div className={styles.blurbContainer}>
              <div className={styles.benefitImageContainer}>
                <img src={manage} className={styles.benefitImage} alt="" />
              </div>
              <div className={styles.blurbTitle}>
                {this.props.t("welcome_benefits_to_provider_sub_title_3")}
              </div>
              <div className={styles.blurbDescription}>
                {this.props.t("welcome_benefits_to_provider_sub_text_3")}
              </div>
            </div>
            <div className={styles.blurbContainer}>
              <div className={styles.benefitImageContainer}>
                <img src={heavy} className={styles.benefitImage} alt="" />
              </div>
              <div className={styles.blurbTitle}>
                {this.props.t("welcome_benefits_to_provider_sub_title_4")}
              </div>
              <div className={styles.blurbDescription}>
                {this.props.t("welcome_benefits_to_provider_sub_text_4")}
              </div>
            </div>
          </div>
        </div>
        <ProviderSlider />
        <IncludedSlide />

        <div className={styles2.containerAreYou2}>
          <div className={styles2.textContainer}>
            <div className={styles2.title}>
              {this.props.t("welcome_ready_to_start")}
            </div>
            <div className={styles2.subTitle}>
              <div>{this.props.t("welcome_create_account_or_demo")}</div>
            </div>
            <div style={{ paddingBottom: "30px", paddingTop: "10px" }}>
              <Button
                variant="contained"
                style={enrollStyle}
                onClick={this.signupHandler}
                endIcon={<ArrowForwardIcon />}
              >
                {this.props.t("welcome_ready_create_account")}
              </Button>
              <div>&nbsp;</div>
              <Button
                variant="contained"
                style={enrollStyleWhite}
                onClick={this.contactSalesHandler}
                endIcon={<ArrowForwardIcon />}
              >
                {this.props.t("welcome_ready_request_demo")}
              </Button>
            </div>
          </div>
          <div className={styles.rightImageContainer2}>
            <img src={backImg} alt="" className={styles.bigToothImage} />
          </div>
        </div>
        <Footer history={this.props.history} />
      </div>
    );
  }
}

// ------------------------------

const mapStateToProps = (state) => {
  return {
    mobileSetting: state.setting.mobileSetting,
  };
};

// ------------------------------

const mapDispatchToProps = (dispatch) => {
  return {
    updateCurrency: (currency, language) =>
      dispatch(settingActions.updateCurrency(currency, language)),
  };
};

// ------------------------------

export default withTranslation("translations")(
  connect(mapStateToProps, mapDispatchToProps)(WelcomeScreen)
);
