import React, { useState } from "react";
import styles from "./ShareCourseModal.module.css";
import { useTranslation } from "react-i18next";
import Button from "@material-ui/core/Button";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import TextButton from "../../ui/button/TextButton";
import Tooltip from "@material-ui/core/Tooltip";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { zubuUrl } from "../../../globals/ApiKeys";
import LinkIcon from "@material-ui/icons/Link";
import AlertSuccess from "../../ui/snackbar/AlertSuccess";
import helpImage from "../../../assets/images/inApp/mobile_info.png";
import ShareOutlinedIcon from "@material-ui/icons/ShareOutlined";

const ShareCourseModal = (props) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const [alert, setAlert] = useState(null);
  const fullScreen = useMediaQuery(theme.breakpoints.down("xs"));
  const webUrl = `${zubuUrl}/details?p=${props.course.provider_id}&c=${props.course.id}`;

  return (
    <div>
      {alert !== null && (
        <AlertSuccess
          message={alert}
          open={alert !== null}
          onClose={() => {
            setAlert(null);
          }}
        />
      )}

      <Dialog
        fullScreen={fullScreen}
        fullWidth={true}
        maxWidth={"sm"}
        open={true}
        onClose={props.onClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="alert-dialog-title" className={styles.row}>
          {props.course.title}
        </DialogTitle>

        <div className={styles.titleContainer}>
          <img src={helpImage} alt="" style={{ height: "82px" }} />
          <div style={{ marginLeft: "10px" }}>{t("share_modal_blurb")}</div>
        </div>

        <DialogContent>
          <div className={styles.copyContainer}>
            <Tooltip
              title={t("share_modal_copy")}
              aria-label={t("share_modal_copy")}
            >
              <CopyToClipboard
                text={webUrl}
                onCopy={() => {
                  setAlert(t("share_modal_copied"));
                }}
              >
                <TextButton
                  onClick={() => {}}
                  style={{
                    fontSize: "small",
                    cursor: "pointer",
                    border: "none",
                    textDecoration: "underline",
                    borderRadius: "10px",
                    display: "flex",
                    alignItems: "center",
                    color: "black",
                    padding: "20px",
                    border: "1px dashed black",
                    backgroundColor: "white",
                    wordBreak: "break-all",
                  }}
                >
                  <div style={{ paddingRight: "7px" }}>
                    <LinkIcon />
                  </div>

                  {webUrl}
                </TextButton>
              </CopyToClipboard>
            </Tooltip>
          </div>
        </DialogContent>

        <DialogActions>
          <Button autoFocus onClick={props.onClose} color="primary">
            {t("g_ok")}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ShareCourseModal;
