import React from "react";
import styles from "./Certificate.module.css";
import InfoIcon from "@material-ui/icons/Info";
import MatInputOutline from "../../components/ui/input/MatInputOutline";
import * as lodashCloneDeep from "lodash.clonedeep";
import { useTranslation } from "react-i18next";
import { hasError } from "../../globals/Validations";
import { validateField } from "../../globals/Validations";

const CertificateEditForm = (props) => {
  const { t } = useTranslation();

  // ------------------------------

  const validate = async (field, validatorKey) => {
    if (field === null) {
      await props.updateHasErrors(props.formErrors);
    } else {
      const errors = validateField(
        props.certificate,
        props.formErrors,
        field,
        validatorKey,
        t
      );

      await props.updateFormErrors(errors);
    }
  };

  // ------------------------------

  const inputChangeHandler = (e) => {
    const copyCert = lodashCloneDeep(props.certificate);
    copyCert[e.target.name] = e.target.value;
    props.update(copyCert);
  };

  // ------------------------------

  return (
    <form className={styles.form}>
      <div className={styles.pricingContainer}>
        <div className={styles.rowIt}>
          <div className={styles.icon}>
            <InfoIcon />
          </div>
          <div>
            Enter a title for your certificate of completion. This information
            is not visible to users.
          </div>
        </div>
      </div>
      <MatInputOutline
        notopmargin="true"
        title={"Title"}
        name="title"
        value={props.certificate.title}
        onChange={inputChangeHandler}
        onBlur={validate.bind(this, "title", "plainRequired")}
        maxLength={1000}
        error={hasError(props.formErrors.title)}
        helpertext={props.formErrors.title}
        readOnly={false}
        required
      />
    </form>
  );
};

export default CertificateEditForm;
