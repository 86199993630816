import React from "react";
import styles from "./CourseViewer.module.css";
import stylesAdd from "./AddViewer.module.css";
import WifiIcon from "@material-ui/icons/Wifi";
import NetworkCellIcon from "@material-ui/icons/NetworkCell";
import BatteryChargingFullIcon from "@material-ui/icons/BatteryChargingFull";
import StarBorderIcon from "@material-ui/icons/StarBorder";
import ShareIcon from "@material-ui/icons/Share";
import HomeIcon from "@material-ui/icons/Home";
import SearchIcon from "@material-ui/icons/Search";
import StarIcon from "@material-ui/icons/Star";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import Badge from "@material-ui/core/Badge";
import SimpleContainer from "../container/Container";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import zubu from "../../../assets/images/inApp/logo.png";
import SubjectIcon from "@material-ui/icons/Subject";
import { imageSelector } from "../addImageSelector/AddImageSelector";

// ------------------------------

const AddViewer = (props) => {
  return (
    <SimpleContainer disableGutters={false}>
      <div className={styles.container}>
        <div className={styles.phoneBorder}>
          <div className={styles.phoneHeader}>
            <div className={styles.headerTimeRow}>
              <div>3:11</div>
              <div>ZubU </div>
              <div>
                <NetworkCellIcon />
                {"  "}
                <WifiIcon />
                {"  "}
                <BatteryChargingFullIcon />
              </div>
            </div>
            <div className={styles.headerTimeRow2}>
              <div className={styles.time}>
                <StarBorderIcon style={jss.icon} />
                {"   "}
                <ShareIcon style={jss.icon} />
              </div>
            </div>
          </div>
          {/**********************/}
          <div className={stylesAdd.blurbs}>
            <div className={stylesAdd.blurbContainer}>
              <SubjectIcon style={{ fontSize: "80px", color: "#CCC" }} />
            </div>
            <div className={stylesAdd.blurbContainer}>
              <SubjectIcon style={{ fontSize: "80px", color: "#CCC" }} />
            </div>
            <div className={stylesAdd.blurbContainer}>
              <SubjectIcon style={{ fontSize: "80px", color: "#CCC" }} />
            </div>
            <div className={stylesAdd.blurbContainer}>
              <SubjectIcon style={{ fontSize: "80px", color: "#CCC" }} />
            </div>
          </div>

          {/**********************/}
          <div
            className={stylesAdd.bannerImage}
            style={{
              backgroundImage: `url(${imageSelector(
                props.add.slide ? Number(props.add.slide) : 0
              )})`,
            }}
          >
            <div
              className={
                props.add.showLogo
                  ? stylesAdd.logoContainer
                  : stylesAdd.logoContainerNone
              }
            >
              <img
                src={props.add.logoUri ? props.add.logoUri : zubu}
                alt="ZubU"
                className={stylesAdd.logo}
              />
            </div>

            {props.add.courseTitle && (
              <div className={stylesAdd.titleContainer}>
                <div className={stylesAdd.title}>{props.add.courseTitle}</div>
              </div>
            )}

            {props.add.headline && (
              <div className={stylesAdd.textContainer}>
                <div>
                  <div className={stylesAdd.headline}>{props.add.headline}</div>
                  <div className={stylesAdd.callForAction}>
                    {props.add.callForAction}
                  </div>
                </div>

                <div className={stylesAdd.button}>
                  <ArrowForwardIosIcon
                    style={{
                      fontSize: "20px",
                    }}
                  />
                </div>
              </div>
            )}
          </div>

          {/**********************/}
          <div className={stylesAdd.blurbs}>
            <div className={stylesAdd.blurbContainer}>
              <SubjectIcon style={{ fontSize: "90px", color: "#CCC" }} />
            </div>
            <div className={stylesAdd.blurbContainer}>
              <SubjectIcon style={{ fontSize: "90px", color: "#CCC" }} />
            </div>
            <div className={stylesAdd.blurbContainer}>
              <SubjectIcon style={{ fontSize: "90px", color: "#CCC" }} />
            </div>
            <div className={stylesAdd.blurbContainer}>
              <SubjectIcon style={{ fontSize: "90px", color: "#CCC" }} />
            </div>
          </div>
          {/**********************/}

          <div className={styles.phoneFooter}>
            <HomeIcon style={jss.iconFooterDark} />
            <SearchIcon style={jss.iconFooter} />
            <StarIcon style={jss.iconFooterDark} />
            <Badge badgeContent={4} color="error">
              <ShoppingCartIcon style={jss.iconFooterDark} />
            </Badge>
          </div>
        </div>
      </div>
    </SimpleContainer>
  );
};

// ------------------------------

const jss = {
  iconsm: {
    fontSize: "20px",
  },
  icon: {
    fontSize: "40px",
  },

  iconFooterDark: {
    fontSize: "40px",
  },
  iconFooter: {
    fontSize: "40px",
    color: "#77b5cc",
  },
  iconCheck: {
    fontSize: "20px",
    color: "greem",
  },
};

// ------------------------------

export default AddViewer;
