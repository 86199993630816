import {
  ADD_TO_CART,
  REMOVE_FROM_CART,
  CLEAR_CART,
  SET_CART,
  SET_CART_PROMO_CODE,
  CLEAR_PROMO_CODE,
  SET_MULTI_PAY_OPTION,
} from "../actions/mobilecart";
import CartPricingItem from "../../model/CartPricingItem";
import CartCourseItem from "../../model/CartCourseItem";
import CartProvider from "../../model/CartProvider";
import { empty, compareArray } from "../../globals/Common";

const initialState = {
  items: [],
  totalAmount: 0,
  coupon: null,
  multiPayOption: null,
};

const mobilecartReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_TO_CART: {
      const course = action.cart.course;
      const pricingItemToStaff = action.cart.pricingItemToStaff;

      let items = [...state.items];

      for (const pricingForStaff of pricingItemToStaff) {
        const providerIndex = items.findIndex(
          (it) => it.id === course.provider_id
        );

        if (providerIndex >= 0) {
          const cCourseItem = items[providerIndex].cartCourseItems[course.id];

          if (!cCourseItem) {
            items[providerIndex] = new CartProvider(
              course.provider_id,
              course.provider_name,
              initCartCourseItems(
                pricingForStaff,
                course,
                providerIndex,
                [],
                items
              ),
              course.provider_currency
            );
          } else {
            // check if duplicat pricing item
            const piIndex = cCourseItem.pricingItems.findIndex((pi) =>
              comparePricingItem(pi.pricingItem, pricingForStaff.pricingItem)
            );

            if (piIndex === -1) {
              // we need to check if staff is in more than one pi if it is we will only keep the last one
              const staffReg = getStaffRegistered(cCourseItem.pricingItems);

              const arrStaff = pricingForStaff.staffMembers;
              const pricingStaffTrimmed = removeAlreadyReg(
                pricingForStaff,
                staffReg,
                arrStaff
              );

              if (pricingStaffTrimmed.staffMembers.length > 0) {
                items[providerIndex] = new CartProvider(
                  course.provider_id,
                  course.provider_name,
                  initCartCourseItems(
                    pricingStaffTrimmed,
                    course,
                    providerIndex,
                    cCourseItem.pricingItems,
                    items
                  ),
                  course.provider_currency
                );
              }
            } else {
              // merge staff and remove duplicates
              const pricingItemStaff = cCourseItem.pricingItems[piIndex];
              const arrStaff = [...pricingForStaff.staffMembers];
              const pricingStaffMerge = mergeStaff(pricingItemStaff, arrStaff);
              cCourseItem.pricingItems.splice(piIndex, 1);

              items[providerIndex] = new CartProvider(
                course.provider_id,
                course.provider_name,
                initCartCourseItems(
                  pricingStaffMerge,
                  course,
                  providerIndex,
                  cCourseItem.pricingItems,
                  items
                ),
                course.provider_currency
              );
            }
          }
        } else {
          // empty
          items = items.concat(
            new CartProvider(
              course.provider_id,
              course.provider_name,
              initCartCourseItems(pricingForStaff, course, -1, [], []),
              course.provider_currency
            )
          );
        }
      }
      return {
        ...state,
        items: items,
        totalAmount: calcTotal(items),
      };
    }
    case REMOVE_FROM_CART: {
      const providerId = action.cart.providerId;
      const courseId = action.cart.courseId;
      const pricingItem = action.cart.pricingItem;
      const studentId = action.cart.studentId;

      const items = [...state.items];

      const providerIndex = items.findIndex((it) => it.id === providerId);

      const courseItem = items[providerIndex].cartCourseItems[courseId];

      const piIndex = courseItem.pricingItems.findIndex((pi) =>
        comparePricingItem(pi.pricingItem, pricingItem)
      );

      const pItem = courseItem.pricingItems[piIndex];

      const smIndex = pItem.staffMembers.findIndex((sm) => sm.id === studentId);

      items[providerIndex].cartCourseItems[courseId].pricingItems[
        piIndex
      ].staffMembers.splice(smIndex, 1);

      if (
        !items[providerIndex].cartCourseItems[courseId].pricingItems[piIndex]
          .staffMembers ||
        empty(
          items[providerIndex].cartCourseItems[courseId].pricingItems[piIndex]
            .staffMembers
        )
      ) {
        // no staff associated to option
        items[providerIndex].cartCourseItems[courseId].pricingItems.splice(
          piIndex,
          1
        );

        if (
          !items[providerIndex].cartCourseItems[courseId].pricingItems ||
          empty(items[providerIndex].cartCourseItems[courseId].pricingItems)
        ) {
          // no options associated to the course // remove the course
          delete items[providerIndex].cartCourseItems[courseId];

          if (
            !items[providerIndex].cartCourseItems ||
            empty(items[providerIndex].cartCourseItems)
          ) {
            items.splice(providerIndex, 1);
          }
        }
      }

      return {
        ...state,
        items: items,
        totalAmount: calcTotal(items),
      };
    }
    case SET_MULTI_PAY_OPTION:
      return { ...state, multiPayOption: action.multiPayOption };
    case SET_CART:
      return { ...state, items: action.items, totalAmount: action.totalAmount };
    case SET_CART_PROMO_CODE:
      return { ...state, coupon: action.coupon };
    case CLEAR_PROMO_CODE: {
      return { ...state, coupon: null };
    }
    case CLEAR_CART:
      return initialState;
    case "LOGOUT":
      return initialState;
    default:
      return state;
  }
};

const getStaffRegistered = (pricingItems) => {
  let mergedStaff = [];
  for (const pricingItem of pricingItems) {
    mergedStaff = mergedStaff.concat(pricingItem.staffMembers);
  }
  return mergedStaff.filter(onlyUnique);
};

const removeAlreadyReg = (
  pricingForStaff,
  staffAlreadyRegistered,
  arrStaff
) => {
  const trimmedStaffArray = arrStaff.filter(function (el) {
    return staffAlreadyRegistered.findIndex((sm) => sm.id === el.id) === -1;
  });

  return {
    cost: pricingForStaff.cost,
    pricingItem: pricingForStaff.pricingItem,
    staffMembers: trimmedStaffArray,
  };
};

const mergeStaff = (pricingItemStaff, arrStaff) => {
  const mergeStaffArray = [...pricingItemStaff.staffMembers, ...arrStaff];
  const mergeStaffUniqueArray = mergeStaffArray.filter(onlyUnique);
  return {
    cost: pricingItemStaff.cost(),
    pricingItem: pricingItemStaff.pricingItem,
    staffMembers: mergeStaffUniqueArray,
  };
};

function onlyUnique(value, index, self) {
  return self.findIndex((sm) => sm.id === value.id) >= index;
}

const initCartCourseItems = (
  pricingForStaff,
  course,
  providerIndex,
  arrPricingItems,
  items
) => {
  let cartCourseItems = {};

  // pricing item
  const newCartPricingItem = new CartPricingItem(
    pricingForStaff.cost,
    pricingForStaff.pricingItem,
    [...pricingForStaff.staffMembers]
  );

  // array pricing item
  const arrCartPricingItems = [...arrPricingItems];
  arrCartPricingItems.push(newCartPricingItem);

  // course cart item
  const newCourseCartItem = new CartCourseItem(
    course.id,
    course.title,
    arrCartPricingItems
  );

  // arr course cart items
  if (providerIndex >= 0) {
    cartCourseItems = {
      ...items[providerIndex].cartCourseItems,
      [course.id]: newCourseCartItem,
    };
  } else {
    cartCourseItems = {
      ...[],
      [course.id]: newCourseCartItem,
    };
  }

  return cartCourseItems;
};

const calcTotal = (items) => {
  let total = 0;
  for (const cartProvider of items) {
    for (const key in cartProvider.cartCourseItems) {
      total = total + cartProvider.cartCourseItems[key].courseSubTotal();
    }
  }
  return total;
};

const comparePricingItem = (pi1, pi2) => {
  return (
    pi1.cost === pi2.cost &&
    compareArray(pi1.audience, pi2.audience) &&
    pi1.casting === pi2.casting &&
    pi1.ce_odq === pi2.ce_odq &&
    pi1.credit_lecture_hours === pi2.credit_lecture_hours &&
    pi1.credit_handson_hours === pi2.credit_handson_hours &&
    pi1.component === pi2.component &&
    pi1.description === pi2.description
  );
};

export default mobilecartReducer;
