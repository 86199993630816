import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import FormHelperText from "@material-ui/core/FormHelperText";
import ReactCountryFlag from "react-country-flag";
import { COUNTRY } from "../../../globals/Data";

const useStyles = makeStyles((theme) => ({
  formControl: {
    display: "flex",
    justifyContent: "center",
    margin: 20,
    width: "90%",
    "& > *": {
      margin: theme.spacing(0),
    },
  },
}));

const CountrySelect = (props) => {
  const classes = useStyles();

  return (
    <FormControl
      className={classes.formControl}
      error={props.error}
      required={props.required}
    >
      <InputLabel id="demo-simple-select-label">{props.title}</InputLabel>
      <Select
        labelId={props.id}
        id={props.id}
        name={props.name}
        value={props.value}
        onChange={props.onChange}
        required={props.required}
        onBlur={props.onBlur}
      >
        {COUNTRY.map((country) => (
          <MenuItem value={country.country} key={country.country}>
            <ReactCountryFlag
              title={country.country}
              countryCode={country.code}
              svg
              style={{
                paddingBottom: 4,
                fontSize: "2em",
                lineHeight: "2em",
              }}
            />
            &nbsp;{country.country}
          </MenuItem>
        ))}
      </Select>
      <FormHelperText>{props.helperText}</FormHelperText>
    </FormControl>
  );
};

export default CountrySelect;
