import React from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import FormHelperText from "@material-ui/core/FormHelperText";
import NumberFormat from "react-number-format";
import styles from "./MatInputOutline.module.css";
import ReactCountryFlag from "react-country-flag";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(5),

    "& > * + *": {
      marginTop: theme.spacing(3),
    },
  },
  rootNoMargin: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),

    "& > * + *": {
      marginTop: theme.spacing(3),
    },
  },

  rootNoTopMargin: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: theme.spacing(0),
    marginBottom: theme.spacing(5),

    "& > * + *": {
      marginTop: theme.spacing(3),
    },
  },
  rootNoBottomMargin: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),

    "& > * + *": {
      marginTop: theme.spacing(3),
    },
  },
  rootNoTopBottomMargin: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: theme.spacing(0),
    marginBottom: theme.spacing(3),

    "& > * + *": {
      marginTop: theme.spacing(3),
    },
  },
  searchMargin: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: theme.spacing(0),
    marginBottom: theme.spacing(0),

    "& > * + *": {
      marginTop: theme.spacing(0),
    },
  },
  margin: {
    margin: theme.spacing(0),
  },
  textField: {
    width: "100%",
  },
  textFieldSmall: {
    width: "60%",
  },
}));

const MatInputOutline = (props) => {
  const matches = useMediaQuery("(max-width:499px)");
  const classes = useStyles();

  let classRoot = classes.root;

  if (props.search) {
    classRoot = classes.searchMargin;
  }

  if (props.notopbottommargin) {
    classRoot = classes.rootNoTopBottomMargin;
  }

  if (props.notopmargin) {
    classRoot = classes.rootNoTopMargin;
  }

  if (props.nomargin) {
    classRoot = classes.rootNoMargin;
  }

  if (props.nobottommargin) {
    classRoot = classes.rootNoBottomMargin;
  }

  const classttt =
    matches || props.nomargin ? classes.textField : classes.textFieldSmall;

  let content = "";

  const clearOnFocus = () => {
    if (props.value && props.value > 0) {
    } else {
      props.onChange({
        target: { name: props.name, value: "" },
      });
    }
  };

  const resetIfEmpty = () => {
    if (!props.value) {
      props.onChange({
        target: { name: props.name, value: 0 },
      });
    }
  };

  if (props.currency) {
    content = (
      <div className={classes.textField}>
        {props.title && (
          <label className={styles.label}>
            {props.title}
            {props.required ? "*" : ""}
          </label>
        )}
        <div
          className={
            // eslint-disable-next-line
            props.haserror == "true"
              ? styles.currencyContainerError
              : styles.currencyContainer
          }
        >
          <NumberFormat
            {...props}
            onValueChange={(values) => {
              props.onChange({
                target: { name: props.name, value: values.value },
              });
            }}
            onChange={() => {
              // do nothing
            }}
            className={styles.currency}
            autoComplete="off"
            thousandSeparator={true}
            prefix={props.currency.shortName}
            decimalScale={0}
            fixedDecimalScale={0}
            allowNegative={false}
            allowLeadingZeros={false}
            onFocus={clearOnFocus}
            onBlur={resetIfEmpty}
          />

          <ReactCountryFlag
            countryCode={props.currency.iso}
            svg
            style={{
              fontSize: "3em",
              lineHeight: "3em",
            }}
          />
        </div>
        {!props.nohelper && (
          <FormHelperText id="component-helper-text" style={{ height: "10px" }}>
            {props.helpertext}
          </FormHelperText>
        )}
      </div>
    );
  } else {
    content = (
      <FormControl
        className={clsx(classes.margin, classttt)}
        variant="outlined"
        error={props.error}
        rows={props.rows}
        required={props.required}
        disabled={props.readOnly}
      >
        <InputLabel>{props.title}</InputLabel>
        <OutlinedInput
          inputProps={{ maxLength: props.maxLength }}
          {...props}
          labelWidth={props.title.length * 10}
        />
        {!props.nohelper && (
          <FormHelperText id="component-helper-text" style={{ height: "10px" }}>
            {props.helpertext}
          </FormHelperText>
        )}
      </FormControl>
    );
  }

  return <div className={classRoot}>{content}</div>;
};

export default MatInputOutline;
