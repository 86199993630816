import React, { useState } from "react";
import styles from "./CurrencyModal.module.css";
import { CURRENCY, LANGUAGES } from "../../../globals/Data";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import ReactCountryFlag from "react-country-flag";
import LanguageIcon from "@material-ui/icons/Language";
import Button from "@material-ui/core/Button";
import DialogActions from "@material-ui/core/DialogActions";

const itemStyle = {
  cursor: "pointer",
  flexDirection: "row",
  alignItems: "center",
  display: "flex",
  padding: "10px",
};

const CurrencyModal = (props) => {
  const [selCurrency, setSelCurrency] = useState(props.selectedCurrencyId);
  const [selLanguage, setSelLanguage] = useState(props.selectedLanguageId);

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("xs"));
  const fullWidth = true;
  const maxWidth = "sm";

  const cancelHandler = () => {
    props.updateMobileCurrency();
  };

  const okHandler = (currency, language) => {
    props.updateMobileCurrency(currency, language);
  };

  const updateSelectedCurrency = (currency) => {
    setSelCurrency(currency);
  };

  const updateSelectedLanguage = (language) => {
    setSelLanguage(language);
  };

  return (
    <div>
      <Dialog
        fullScreen={fullScreen}
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={props.open}
        onClose={okHandler.bind(this, props.selectedCurrencyId)}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="alert-dialog-title">
          Select your Currency and Language
        </DialogTitle>

        <DialogContent>
          <div className={styles.container}>
            <div className={styles.listWrapper} style={{ width: "40%" }}>
              {LANGUAGES.map((language) => (
                <div
                  key={language.id}
                  className={styles.listItem}
                  style={
                    language.locale == selLanguage
                      ? {
                          border: "1px solid green",
                          borderRadius: "7px",
                          ...itemStyle,
                        }
                      : itemStyle
                  }
                  onClick={updateSelectedLanguage.bind(this, language.locale)}
                >
                  <LanguageIcon style={{ fontSize: "27px" }} />
                  &nbsp;&nbsp;{language.name}
                </div>
              ))}
            </div>

            <div className={styles.listWrapper} style={{ width: "60%" }}>
              {CURRENCY.map((currency) => (
                <div
                  key={currency.id}
                  className={styles.listItem}
                  style={
                    currency.id == selCurrency
                      ? {
                          border: "1px solid green",
                          borderRadius: "7px",
                          cursor: "pointer",
                        }
                      : { cursor: "pointer" }
                  }
                  onClick={updateSelectedCurrency.bind(this, currency.id)}
                >
                  <ReactCountryFlag
                    title=""
                    aria-label=""
                    countryCode={currency.iso}
                    svg
                    style={{
                      fontSize: "3em",
                      lineHeight: "3em",
                      paddingRight: 10,
                      paddingBottom: 2,
                      paddingLeft: 10,
                    }}
                  />
                  {currency.name} - {currency.shortName}
                </div>
              ))}
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={cancelHandler} color="primary">
            Cancel
          </Button>
          <Button
            autoFocus
            onClick={okHandler.bind(this, selCurrency, selLanguage)}
            color="primary"
            style={{ color: "white", backgroundColor: "#3E8CAB" }}
          >
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default CurrencyModal;
