import React from "react";
import styles from "./CourseViewer.module.css";
import WifiIcon from "@material-ui/icons/Wifi";
import NetworkCellIcon from "@material-ui/icons/NetworkCell";
import BatteryChargingFullIcon from "@material-ui/icons/BatteryChargingFull";
import StarBorderIcon from "@material-ui/icons/StarBorder";
import ShareIcon from "@material-ui/icons/Share";
import HomeIcon from "@material-ui/icons/Home";
import SearchIcon from "@material-ui/icons/Search";
import StarIcon from "@material-ui/icons/Star";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import Badge from "@material-ui/core/Badge";
import SimpleContainer from "../../../components/ui/container/Container";
import CourseListing from "./CourseListing";

// ------------------------------

const CourseViewer = (props) => {
  return (
    <SimpleContainer disableGutters={false}>
      <div className={styles.container}>
        <div className={styles.phoneBorder}>
          <div className={styles.phoneHeader}>
            <div className={styles.headerTimeRow}>
              <div>3:11</div>
              <div>ZubU </div>
              <div>
                <NetworkCellIcon />
                {"  "}
                <WifiIcon />
                {"  "}
                <BatteryChargingFullIcon />
              </div>
            </div>
            <div className={styles.headerTimeRow2}>
              <div className={styles.time}>
                <StarBorderIcon style={jss.icon} />
                {"   "}
                <ShareIcon style={jss.icon} />
              </div>
            </div>
          </div>

          <CourseListing
            course={props.course}
            currency={props.currency}
            rates={props.rates}
          />

          <div style={{ padding: "2px", marginBottom: "2px" }}>
            <div className={styles.addToCart}>Add to cart</div>
          </div>

          <div className={styles.phoneFooter}>
            <HomeIcon style={jss.iconFooterDark} />
            <SearchIcon style={jss.iconFooter} />
            <StarIcon style={jss.iconFooterDark} />
            <Badge badgeContent={4} color="error">
              <ShoppingCartIcon style={jss.iconFooterDark} />
            </Badge>
          </div>
        </div>
      </div>
    </SimpleContainer>
  );
};

// ------------------------------

const jss = {
  iconsm: {
    fontSize: "20px",
  },
  icon: {
    fontSize: "40px",
  },

  iconFooterDark: {
    fontSize: "40px",
  },
  iconFooter: {
    fontSize: "40px",
    color: "#77b5cc",
  },
  iconCheck: {
    fontSize: "20px",
    color: "greem",
  },
};

// ------------------------------

export default CourseViewer;
