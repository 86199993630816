import React from "react";
import { useTranslation } from "react-i18next";
import styles from "./MobileCartProviderItem.module.css";
import MobileCartItem from "../mobileCartItem/MobileCartItem";
import SchoolIcon from "@material-ui/icons/School";
import { resolveCurrencySymbol } from "../../../globals/Common";
import NumberFormat from "react-number-format";

// ------------------------------

const cartCourseItems = (item) => {
  const transformedCartItems = [];
  for (const key in item) {
    transformedCartItems.push({
      itemId: key,
      courseTitle: item[key].title,
      coursePricingItems: item[key].pricingItems,
    });
  }
  return transformedCartItems.sort((a, b) => (a.itemId > b.itemId ? 1 : -1));
};

// ------------------------------

const renderPi = (props, pi, index, t, courseId) => {
  return (
    <MobileCartItem
      key={index}
      hasDiscount={pi.hasDiscount()}
      originalPrice={pi.originalPrice()}
      price={pi.cost()}
      option={pi.resolvePricingItem(t)}
      staff={pi.staffMembers}
      setting={props.setting}
      rates={props.rates}
      providerCurrency={props.item.providerCurrency}
      deletable={props.deletable}
      onRemove={(studentId) => {
        if (props.deletable) {
          props.onRemove(courseId, pi.pricingItem, studentId);
        }
      }}
    />
  );
};

// ------------------------------

const renderCourse = (props, cCartItem, t) => {
  let renderList = [];
  Object.keys(cCartItem.coursePricingItems).forEach((key, index) => {
    const pi = cCartItem.coursePricingItems[key];
    renderList.push(renderPi(props, pi, index, t, cCartItem.itemId));
  });

  return (
    <div key={cCartItem.itemId}>
      <div className={styles.title}>{cCartItem.courseTitle}</div>
      {renderList}
    </div>
  );
};

// ------------------------------

const MobileCartProviderItem = (props) => {
  const { t } = useTranslation();

  const subTotal = props.item.calcProviderViewSubTotal(props.rates);
  const cCartItems = cartCourseItems(props.item.cartCourseItems);

  let renderList = [];
  Object.keys(cCartItems).forEach((key, index) => {
    const ci = cCartItems[key];
    renderList.push(renderCourse(props, ci, t));
  });

  return (
    <div className={styles.itemContainer}>
      <div className={styles.itemSubContainer}>
        <div className={styles.iconTextContainer}>
          <SchoolIcon
            style={{
              paddingRight: 5,
              alignItems: "center",
              paddingTop: 3,
            }}
          />
          <div className={styles.text}>{t("cart_provided_by")}</div>
          <div className={styles.textBold}>{props.item.providerName} </div>
        </div>
      </div>
      {renderList}

      <div className={styles.priceTotal}>
        <div style={{ color: "black", paddingRight: "4px" }}>
          {t("cart_sub_total")}
        </div>
        <NumberFormat
          value={subTotal}
          displayType={"text"}
          thousandSeparator={true}
          prefix={resolveCurrencySymbol(props.setting.currency)}
        />
      </div>

      {props.coupon && props.couponDiscount > 0 && (
        <div className={styles.coupon}>
          <div style={{ paddingRight: "4px", color: "black" }}>
            {props.coupon.promo_code} / -{props.coupon.discount}% /{" "}
            {t("cart_applicable")}
            {props.coupon.uId
              ? t("cart_applicable_you")
              : t("cart_applicable_you_staff")}
            / {t("cart_coupon")}
          </div>
          <div className={styles.couponValue}>
            <NumberFormat
              value={props.couponDiscount}
              displayType={"text"}
              thousandSeparator={true}
              prefix={resolveCurrencySymbol(props.setting.currency) + " -"}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default MobileCartProviderItem;
