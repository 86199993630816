import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import * as profileActions from "../../../store/actions/profile";
import * as staffActions from "../../../store/actions/staff";
import * as settingActions from "../../../store/actions/setting";

import AddCircleIcon from "@material-ui/icons/AddCircle";
import GroupAddIcon from "@material-ui/icons/GroupAdd";
import Button from "@material-ui/core/Button";
import MatAppBar from "../../../components/ui/appBar/MatAppBar";
import Spinner from "../../../components/ui/spinner/Spinner";
import StaffItem from "../../../components/items/staffItem/StaffItem";
import ConfirmModal from "../../../components/modals/confirmModal/ConfirmModal";
import Empty from "../../../components/ui/empty/Empty";
import staffImg from "../../../assets/images/inApp/staff.png";
import { objectHasNoValues } from "../../../globals/Common";
import * as logger from "../../../globals/Logger";
import { translate } from "../../../globals/Common";

class StaffScreen extends Component {
  state = {
    wait: false,
    openDeleteConfirmModal: false,
    idToBeDeleted: null,
    error: null,
  };

  // ------------------------------

  update = async (propValue) => {
    await this.setState({ ...this.state, ...propValue });
  };

  // ------------------------------

  componentDidMount = async () => {
    try {
      window.scrollTo(0, 0);
      await this.update({ wait: true });
      const loadAsync = [];

      loadAsync.push(this.props.fetchStaffMembers());

      if (objectHasNoValues(this.props.mobileSetting)) {
        await this.props.fetchMobileSetting();
      }

      if (objectHasNoValues(this.props.mobileUserProfile)) {
        loadAsync.push(this.props.fetchMobileUserProfile());
      }

      await Promise.all(loadAsync);
      await this.update({ wait: false });
    } catch (err) {
      logger.error(err);
      this.update({ wait: false, error: err.message });
    }
  };

  // ------------------------------

  addHandler = async () => {
    this.props.setEditAction("new");
    this.props.history.push("/editStaff");
  };

  editHandler = (id) => {
    this.props.setEditAction(id);
    this.props.history.push("/editStaff");
  };

  // ------------------------------

  deleteHandler = (id) => {
    this.update({
      openDeleteConfirmModal: true,
      idToBeDeleted: id,
    });
  };

  // ------------------------------

  deleteConfirmHandler = async (answer) => {
    if (answer === "ok") {
      try {
        await this.update({
          wait: true,
          openDeleteConfirmModal: false,
        });

        await this.props.removeStaffMember(this.state.idToBeDeleted);

        await this.update({
          wait: false,
          idToBeDeleted: null,
        });
      } catch (err) {
        logger.error(err);
        this.update({
          wait: false,
          error: err.message,
        });
      }
    } else {
      this.update({
        openDeleteConfirmModal: false,
        idToBeDeleted: null,
      });
    }
  };

  // ------------------------------

  render() {
    if (this.state.wait) {
      return <Spinner />;
    }

    if (!this.props.staff || this.props.staff.length === 0) {
      return (
        <Empty
          title={this.props.t("staff_empty_title")}
          column
          image={staffImg}
          message={<div>{this.props.t("staff_empty_blurb")}</div>}
          onClick={this.addHandler}
          buttonIcon={<GroupAddIcon />}
          buttonLabel={this.props.t("staff_add_member")}
        />
      );
    }

    return (
      <div>
        {this.state.openDeleteConfirmModal && (
          <ConfirmModal
            title={this.props.t("g_confirmation_required")}
            message={this.props.t("staff_delete_confirm")}
            open={this.state.openDeleteConfirmModal}
            onClose={this.deleteConfirmHandler}
            okButtonText={this.props.t("g_delete")}
          />
        )}

        <MatAppBar
          right={
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <Button
                color="inherit"
                style={{ color: "black" }}
                onClick={this.addHandler}
                startIcon={<AddCircleIcon />}
              >
                {this.props.t("g_new")}
              </Button>
            </div>
          }
        />
        <div style={{ height: "58px" }}></div>
        <div>
          {this.props.staff.map((item, index) => (
            <StaffItem
              key={index}
              id={item.id}
              first={item.first}
              last={item.last}
              occupation={translate(this.props.t, item.occupation)}
              onClick={() => {}}
              edit={this.editHandler.bind(this, item.id)}
              delete={this.deleteHandler.bind(this, item.id)}
            />
          ))}
        </div>
      </div>
    );
  }
}

// ------------------------------

const mapStateToProps = (state) => {
  return {
    mobileSetting: state.setting.mobileSetting,
    mobileUserProfile: state.profile.mobileUserProfile,
    paymentMethods: state.payment.payment,
    isMobileUser: state.auth.isMobileUser,
    staff: state.staff.staff,
  };
};

// ------------------------------

const mapDispatchToProps = (dispatch) => {
  return {
    setEditAction: (id) => dispatch(staffActions.setEditAction(id)),
    fetchStaffMembers: () => dispatch(staffActions.fetchStaffMembers()),
    removeStaffMember: (id) => dispatch(staffActions.removeStaffMember(id)),
    fetchMobileSetting: () => dispatch(settingActions.fetchMobileSetting()),
    fetchMobileUserProfile: () =>
      dispatch(profileActions.fetchMobileUserProfile()),
  };
};

export default withTranslation("translations")(
  connect(mapStateToProps, mapDispatchToProps)(StaffScreen)
);
