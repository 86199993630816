import React, { Component } from "react";
import styles from "./StudentSignupScreen.module.css";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import * as settingActions from "../../../store/actions/setting";
import validate from "validate.js";
import ReCAPTCHA from "react-google-recaptcha";
import Button from "@material-ui/core/Button";
import * as authActions from "../../../store/actions/auth";
import InfoIcon from "@material-ui/icons/Info";
import Spinner from "../../../components/ui/spinner/Spinner";
import InfoModal from "../../../components/modals/infoModal/InfoModal";
import Footer from "../../../components/ui/footer/Footer";
import TextButton from "../../../components/ui/button/TextButton";
import MatCheckbox from "../../../components/ui/checkbox/MatCheckbox";
import DefaultToolbar from "../../welcome/DefaultToolbar";
import EmailLinkModal from "../../../components/modals/emailLinkModal/EmailLinkModal";
import PrivateProfileForm from "../settings/PrivateProfileForm";
import MobileUserProfile from "../../../model/MobileUserProfile";
import MatInputOutline from "../../../components/ui/input/MatInputOutline";
import { objectHasNoValues, setLanguage } from "../../../globals/Common";
import { incrementYearOfDate } from "../../../globals/Dates2";
import InlinePop from "../../../components/ui/popover/InlinePop";
import {
  hasFormErrors,
  hasError,
  getConstraints,
} from "../../../globals/Validations";
import * as logger from "../../../globals/Logger";

class StudentSignupScreen extends Component {
  constructor(props) {
    super(props);
    this.reRef = React.createRef();
  }

  state = {
    wait: false,
    waitMessage: "",
    message: null,
    acceptTerms: false,
    openEmailModal: null,

    // ----- form
    profile: new MobileUserProfile(
      "",
      "",
      "",
      "",
      "",
      "",
      incrementYearOfDate(new Date(), -18),
      new Date(),
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      ""
    ),

    username: "",
    password: "",
    password2: "",
    formErrors: {},
  };

  // ------------------------------

  update = async (propValue) => {
    await this.setState({ ...this.state, ...propValue });
  };

  // ------------------------------

  componentDidMount = async () => {
    window.scrollTo(0, 0);

    try {
      if (objectHasNoValues(this.props.mobileSetting)) {
        await this.props.fetchMobileSetting();
      }
    } catch (error) {
      logger.error(error);
      this.update({
        wait: false,
        message: error ? error.message : this.props.t("error_default_message"),
        waitMessage: "",
      });
    }
  };

  // ------------------------------

  validateForm = async (field, validatorKey) => {
    let validateObj = {};
    validateObj[validatorKey] = this.state[field];

    const validationResponse = validate(
      validateObj,
      getConstraints(this.props.t)
    );

    const updateFormErrors = { ...this.state.formErrors };

    if (validationResponse[validatorKey]) {
      const firstError = validationResponse[validatorKey][0];
      updateFormErrors[field] = firstError;
    } else {
      delete updateFormErrors[field];
    }

    if (
      (field === "password" || field === "password2") &&
      this.state.password !== this.state.password2
    ) {
      updateFormErrors["password2"] = this.props.t(
        "sign_up_password_not_match"
      );
    }

    await this.setState({ ...this.state, formErrors: { ...updateFormErrors } });
  };

  // ------------------------------

  hasErrorsHandler = () => {
    const p = this.state.profile;

    const values = {
      username: this.state.username, // zubuName
      password: this.state.password,
      password2: this.state.password2,
      licenseNumber: p.licenseNumber,
      licencingBoard: p.licencingBoard,
      first: p.first,
      last: p.last,
      phone: p.phone,
      gender: p.gender,
      streetNumber: p.streetNumber,
      streetName: p.streetName,
      country: p.country,
      stateProvince: p.stateProvince,
      city: p.city,
      zipPostal: p.zipPostal,
    };

    return hasFormErrors(this.state.formErrors, values);
  };

  // ------------------------------

  signupHandler = async () => {
    try {
      this.update({
        message: null,
        wait: true,
        waitMessage: this.props.t("sign_up_creating_account"),
      });

      const recaptchaToken = await this.reRef.current.executeAsync();

      this.reRef.current.reset();

      await this.props.signupMobile(
        {
          profile: this.state.profile,
          username: this.state.username,
          password: this.state.password,
          referralCode: this.state.referralCode,
        },
        recaptchaToken
      );

      this.update({
        openEmailModal: true,
        wait: false,
      });
    } catch (error) {
      logger.error(error);
      this.setState({
        ...this.state,
        wait: false,
        message: error ? error.message : this.props.t("error_default_message"),
        waitMessage: "",
      });
    }
  };

  // ------------------------------

  signupSuccessHandler = () => {
    this.props.history.push("/login");
  };

  // ------------------------------

  inputChangeHandler = (e) => {
    this.update({
      [e.target.name]: e.target.value,
    });
  };

  // ------------------------------

  closeErrorHandler = () => {
    this.update({
      message: null,
    });
  };

  // ------------------------------

  acceptHandler = (event, checked) => {
    this.update({
      acceptTerms: checked,
    });
  };

  // ------------------------------

  updateMobileCurrency = async (currency, language) => {
    try {
      await this.props.updateCurrency(currency, language);
      setLanguage(language, this.props.i18n);
    } catch (error) {
      logger.error(error);
    }
  };

  // ------------------------------

  render() {
    const hasFormErrors = this.hasErrorsHandler();

    const imageContainerStyleFr = [
      styles.imageContainer,
      styles.imageContainerFr,
    ].join(" ");

    return (
      <div className={styles.screen}>
        {this.state.wait && <Spinner message={this.state.waitMessage} />}

        <DefaultToolbar
          history={this.props.history}
          i18n={this.props.i18n}
          mobileSetting={this.props.mobileSetting}
          updateMobileCurrency={this.updateMobileCurrency}
        />

        {this.state.openEmailModal && (
          <EmailLinkModal
            email={this.state.profile.email}
            open={this.state.openEmailModal}
            history={this.props.history}
          />
        )}

        <InfoModal
          isErrorModal={true}
          title={this.props.t("error_occurred")}
          message={this.state.message}
          open={this.state.message !== null}
          onClose={this.closeErrorHandler}
        />

        <div className={styles.formAndFooter}>
          <div className={styles.container}>
            <div
              className={
                this.props.i18n && this.props.i18n.language === "fr"
                  ? imageContainerStyleFr
                  : styles.imageContainer
              }
            />

            <div className={styles.title}>
              {this.props.t("sign_up_dentist_title")}
            </div>
          </div>

          <PrivateProfileForm
            editEmail
            profile={this.state.profile}
            formErrors={this.state.formErrors}
            updateFormErrors={async (errors) => {
              await this.update({ formErrors: errors });
            }}
            updateHasErrors={async (errors) => {
              await this.update({ formErrors: errors });
            }}
            update={async (profile) => {
              await this.update({ profile: profile, touched: true });
            }}
          />

          <div className={styles.sectionTitle}>
            {this.props.t("settings_enter_a_password")}
          </div>

          <div className={styles.formFlex}>
            <div className={styles.pricingContainer}>
              <div className={styles.rowIt}>
                <div className={styles.icon}>
                  <InfoIcon />
                </div>
                <div>{this.props.t("sign_up_user_name_blurb")}</div>
              </div>
            </div>

            <MatInputOutline
              nomargin="true"
              title={this.props.t("sign_up_dentist_user_name")}
              value={this.state.username}
              name="username"
              id="username"
              onChange={this.inputChangeHandler}
              onBlur={this.validateForm.bind(this, "username", "plainRequired")}
              maxLength={200}
              error={hasError(this.state.formErrors.username)}
              helpertext={this.state.formErrors.username}
              required
            />

            <div className={styles.spacerTen} />

            <MatInputOutline
              nomargin="true"
              title={this.props.t("g_password")}
              value={this.state.password}
              name="password"
              type="password"
              id="password"
              onChange={this.inputChangeHandler}
              onBlur={this.validateForm.bind(this, "password", "password")}
              maxLength={50}
              error={hasError(this.state.formErrors.password)}
              helpertext={this.state.formErrors.password}
              required
            />

            <div className={styles.spacerFive} />

            <MatInputOutline
              nomargin="true"
              title={this.props.t("sign_up_confirm_pass")}
              value={this.state.password2}
              name="password2"
              type="password"
              id="password2"
              onChange={this.inputChangeHandler}
              onBlur={this.validateForm.bind(this, "password2", "password")}
              maxLength={50}
              error={hasError(this.state.formErrors.password2)}
              helpertext={this.state.formErrors.password2}
              required
            />

            <MatCheckbox
              signup="true"
              label={
                <div>
                  {this.props.t("sign_up_dentist_disclaimer")}&nbsp;
                  <TextButton
                    onClick={() => {}}
                    style={{ fontSize: "14px", textDecoration: "underline" }}
                  >
                    {this.props.t("sign_up_general_terms")}
                  </TextButton>
                  &nbsp;{this.props.t("sign_up_and")}&nbsp;
                  <TextButton
                    onClick={() => {}}
                    style={{ fontSize: "14px", textDecoration: "underline" }}
                  >
                    {this.props.t("sign_up_privacy_policy")}
                  </TextButton>
                </div>
              }
              checked={this.state.acceptTerms}
              onChange={this.acceptHandler}
              name="acceptTerms"
            />

            <ReCAPTCHA
              sitekey="6LfceS4aAAAAAOXnwPywSDEi5DQptzsHEsnvrgCN"
              size="invisible"
              ref={this.reRef}
            />

            <div className={styles.buttonContainer}>
              <Button
                variant="contained"
                style={{
                  backgroundColor:
                    hasFormErrors || !this.state.acceptTerms
                      ? "#CCC"
                      : "#437aa1",
                  color: "white",
                  width: "100%",
                }}
                onClick={this.signupHandler}
                disabled={hasFormErrors || !this.state.acceptTerms}
              >
                {this.props.t("sign_up")}
              </Button>
            </div>
          </div>

          <Footer history={this.props.history} />
        </div>
      </div>
    );
  }
}

// ------------------------------

const mapStateToProps = (state, props) => {
  return {
    mobileSetting: state.setting.mobileSetting,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    signupMobile: (newUser, recaptchaToken) =>
      dispatch(authActions.signupMobile(newUser, recaptchaToken)),
    updateCurrency: (currency, language) =>
      dispatch(settingActions.updateCurrency(currency, language)),
    fetchMobileSetting: () => dispatch(settingActions.fetchMobileSetting()),
  };
};

export default withTranslation("translations")(
  connect(mapStateToProps, mapDispatchToProps)(StudentSignupScreen)
);
