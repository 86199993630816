import React from "react";
import styles from "./TitleMenu.module.css";

const TitleMenu = (props) => {
  return (
    <div className={styles.simpleRow}>
      {props.icon}
      &nbsp;{props.title}
    </div>
  );
};

export default TitleMenu;
