import { momentLongDate } from "../globals/Dates2";

class StudentOrder {
  constructor(
    chargeId,
    orderRefNumber,
    date,
    ccBrand,
    ccExpiryDate,
    ccLast4,
    regLicense,
    ccName,
    appFee,
    gst,
    qst,
    totalAmount,
    order_details,
    installmentStatusArray
  ) {
    this.chargeId = chargeId;
    this.orderRefNumber = orderRefNumber;
    this.date = date;
    this.ccBrand = ccBrand;
    this.ccExpiryDate = ccExpiryDate;
    this.ccLast4 = ccLast4;
    this.regLicense = regLicense;
    this.ccName = ccName;
    this.appFee = appFee;
    this.gst = gst;
    this.qst = qst;
    this.totalAmount = totalAmount;
    this.order_details = order_details;
    this.installmentStatusArray = installmentStatusArray;
  }

  format_date = () => {
    return momentLongDate(this.date);
  };
}

export default StudentOrder;
