import React from "react";
import { useTranslation } from "react-i18next";
import styles from "./LocationInput.module.css";
import Modal from "../Modal";
import ButtonIcon from "../../button/ButtonIcon";
import TextButton from "../../button/TextButton";
import SearchIcon from "@material-ui/icons/Search";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import geohash from "ngeohash";
import LocationOnIcon from "@material-ui/icons/LocationOn";

const LocationInput = (props) => {
  const { t } = useTranslation();

  const clearInputHandler = (event) => {
    event.preventDefault();
    props.onChange("");
  };

  const selectHandler = async (value) => {
    const results = await geocodeByAddress(value);
    const lat_long = await getLatLng(results[0]);

    var p = {};
    for (var i = 0; i < results[0].address_components.length; i++) {
      var c = results[0].address_components[i];
      p[c.types[0]] = c;
    }

    const addressArray = [
      p["street_number"]
        ? p["street_number"].long_name
          ? p["street_number"].long_name
          : p["street_number"].short_name
          ? p["street_number"].short_name
          : ""
        : "", // street number
      p["route"] && p["route"].long_name ? p["route"].long_name : "", // route
      p["country"].long_name, //country
      p["locality"]
        ? p["locality"].long_name
        : p["administrative_area_level_3"]
        ? p["administrative_area_level_3"].long_name
        : p["postal_town"]
        ? p["postal_town"].long_name
        : "", // locality
      p["administrative_area_level_1"]
        ? p["administrative_area_level_1"].short_name
        : p["administrative_area_level_2"]
        ? p["administrative_area_level_2"].short_name
        : "", // administrative_area_level_1
      p["postal_code"] ? p["postal_code"].long_name : "", // postal_code
    ];

    const hash = geohash.encode(lat_long.lat, lat_long.lng);
    const geo = { lat: lat_long.lat, lng: lat_long.lng, hash: hash };
    props.onSelectAddress(value, addressArray, geo);
  };

  return (
    <Modal show={props.show ? true : false} onClick={props.close}>
      <div className={styles.titleButtonBar}>
        {props.clear && (
          <div>
            <TextButton onClick={props.clear}>{t("g_clear")}</TextButton>
          </div>
        )}
        <div>{props.title ? props.title : t("g_address_search")}</div>
        <div>
          <TextButton onClick={props.close}> {t("g_cancel")}</TextButton>
        </div>
      </div>

      <PlacesAutocomplete onSelect={selectHandler} {...props}>
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <div className={styles.modalScreen}>
            {/*************************************************/}
            <div className={styles.locationInputContainer}>
              <div className={styles.icon}>
                <SearchIcon />
              </div>

              <input
                autoFocus
                autoComplete="off"
                {...getInputProps({
                  className: styles.locationInput,
                  placeholder: props.placeholder
                    ? props.placeholder
                    : t("g_enter_an_address"),
                  autoComplete: "off",
                })}
              />

              {props.value && props.value.length > 0 && (
                <ButtonIcon onClick={clearInputHandler}>
                  <HighlightOffIcon
                    style={{ fontSize: "20px", color: "rgb(80, 79, 79)" }}
                  />
                </ButtonIcon>
              )}
            </div>
            {/*************************************************/}

            <div className="autocomplete-dropdown-container">
              {loading && (
                <div style={{ padding: "3px", paddingLeft: "13px" }}>
                  {t("g_loading")}
                </div>
              )}
              {suggestions.map((suggestion, index) => {
                const className = suggestion.active
                  ? "suggestion-item-active"
                  : "suggestion-item";
                // inline style for demonstration purpose
                const style = suggestion.active
                  ? {
                      padding: "3px",
                      paddingLeft: "13px",
                      backgroundColor: "#CCC",
                      cursor: "pointer",
                    }
                  : {
                      padding: "3px",
                      paddingLeft: "13px",
                      backgroundColor: "#ffffff",
                      cursor: "pointer",
                    };

                return (
                  <div
                    {...getSuggestionItemProps(suggestion, {
                      className,
                      style,
                    })}
                    key={index}
                  >
                    <span
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      <LocationOnIcon />
                      &nbsp;&nbsp;{suggestion.description}
                    </span>
                  </div>
                );
              })}
            </div>

            {/*************************************************/}
          </div>
        )}
      </PlacesAutocomplete>
    </Modal>
  );
};

export default LocationInput;
