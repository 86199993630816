/**
 * cart associated to a provider
 * contain array of cartCourseItem []
 */

class CartProvider {
  constructor(id, providerName, cartCourseItems, providerCurrency) {
    this.id = id;
    this.providerName = providerName;
    this.cartCourseItems = cartCourseItems;
    this.providerCurrency = providerCurrency;
  }

  calcProviderSubTotal = () => {
    let subTotal = 0;
    for (const key in this.cartCourseItems) {
      subTotal = subTotal + this.cartCourseItems[key].courseSubTotal();
    }
    return subTotal;
  };

  calcProviderViewSubTotal = (rates) => {
    let subTotal = 0;
    for (const key in this.cartCourseItems) {
      subTotal =
        subTotal +
        this.cartCourseItems[key].courseViewSubTotal(
          this.providerCurrency,
          rates
        );
    }
    return subTotal;
  };
}

export default CartProvider;
