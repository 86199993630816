import { momentShortDateTime } from "../globals/Dates2";

class AlertNotification {
  constructor(id, description, eventDate, link) {
    this.id = id;
    this.description = description;
    this.eventDate = eventDate;
    this.link = link;
  }

  formatEventDate = () => {
    if (this.eventDate) {
      return momentShortDateTime(this.eventDate);
    }

    return "";
  };
}

export default AlertNotification;
