import React, { useState, useEffect, Fragment } from "react";
import styles from "./InboxChatModal.module.css";
import Dialog from "@material-ui/core/Dialog";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import FlatList from "flatlist-react";
import Message from "./elements/Message";
import Input from "./elements/Input";
import TextButton from "../../../components/ui/button/TextButton";
import ConversationList from "./elements/ConversationList";
import TimelapseIcon from "@material-ui/icons/Timelapse";
import IconButton from "@material-ui/core/IconButton";
import CancelIcon from "@material-ui/icons/Cancel";
import ScrollToBottom, { useScrollToBottom } from "react-scroll-to-bottom";
import InfoIcon from "@material-ui/icons/Info";

import chatImg from "../../../assets/images/inApp/empty_chat.png";

const InboxChatModal = (props) => {
  const scrollToBottom = useScrollToBottom({ behavior: "smooth" });
  const [input, setInput] = useState("");
  const messagesEndRef = React.createRef();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("xs"));
  const fullWidth = true;
  const maxWidth = props.showAllConversations ? "md" : "xs";

  const cancelHandler = () => {
    props.onClose();
  };

  const sendMessageHandler = () => {
    if (input) {
      props.onSendMessage(input);
      setInput("");
    }
  };

  const renderMessage = (message, index) => {
    return <Message key={index} message={message} />;
  };

  return (
    <div>
      <Dialog
        fullScreen={fullScreen}
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={props.open}
        onClose={cancelHandler}
        PaperProps={{
          style: {
            backgroundColor: "rgba(247, 247, 247, 0.92)",
          },
        }}
      >
        <div className={styles.container}>
          {props.showAllConversations && (
            <ConversationList
              isDeleting={props.isDeleting}
              selectedConversation={props.selectedConversation}
              onDeleteConversations={props.onDeleteConversations}
              leftPanel={true}
              conversations={props.conversations}
              onSelectConversation={props.onSelectConversation}
            />
          )}

          <div className={styles.conversation}>
            <div className={styles.header}>
              <div>
                <div className={styles.headerUserName}>
                  {props.selectedConversation
                    ? props.selectedConversation.resolveName()
                    : props.providerName
                    ? props.providerName
                    : ""}
                </div>
                <div className={styles.headerUserDesc}>
                  <TimelapseIcon fontSize="inherit" />
                  &nbsp;Average response time 1 hour
                </div>
              </div>

              <div>
                <IconButton
                  aria-label=""
                  component="span"
                  onClick={cancelHandler}
                  style={{ padding: "3px" }}
                >
                  <CancelIcon />
                </IconButton>
              </div>
            </div>
            {!props.showAllConversations ||
            (props.showAllConversations && props.selectedConversation) ? (
              <Fragment>
                <ScrollToBottom
                  className={styles.messageList}
                  initialScrollBehavior="smooth"
                  mode="bottom"
                >
                  {props.messages.map((m, index) => renderMessage(m, index))}
                  <div ref={messagesEndRef} onFocus={scrollToBottom} />
                </ScrollToBottom>

                {!props.showAllConversations ||
                (props.selectedConversation &&
                  props.selectedConversation.by_user) ? (
                  <Fragment>
                    <div className={styles.footer}>
                      <Input
                        placeholder="Type your message here"
                        style={{ fontSize: "15px" }}
                        rows="2"
                        multiline
                        value={input}
                        onChange={(e) => {
                          setInput(e.target.value);
                        }}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            e.preventDefault();
                            sendMessageHandler();
                          }
                        }}
                        maxLength={200}
                      />
                    </div>

                    <div className={styles.footerActions}>
                      <div>&nbsp;</div>
                      <TextButton
                        onClick={sendMessageHandler}
                        disabled={input.length <= 0}
                        style={!input ? { color: "#ccc" } : null}
                      >
                        Send
                      </TextButton>
                    </div>
                  </Fragment>
                ) : (
                  <div className={styles.oneWayChatDisclaimer}>
                    <InfoIcon />
                    &nbsp;This message was sent by&nbsp;
                    {props.selectedConversation
                      ? props.selectedConversation.resolveName()
                      : props.providerName
                      ? props.providerName
                      : ""}
                    . You cannot reply to this thread but can message the
                    provider directly on the course page.
                  </div>
                )}
              </Fragment>
            ) : (
              <div className={styles.nothingSelected}>
                <img src={chatImg} alt="" />
                Select a conversation to send a message
              </div>
            )}
          </div>
        </div>
      </Dialog>
    </div>
  );
};

export default InboxChatModal;
