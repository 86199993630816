import Tax from "../model/Tax";
import { GST_5_PROVINCE, HST_15_PROVINCE } from "../globals/Data";

// ------------------------------

export const getTaxObject = (currency, provinceCode, amount) => {
  if (currency === "CAD") {
    if (provinceCode === "QC") {
      return new Tax(0.05, 0.0975, amount, currency);
    } else if (
      GST_5_PROVINCE.findIndex((p) => p.code === provinceCode) !== -1
    ) {
      return new Tax(0.05, 0, amount, currency);
    } else if (provinceCode === "ON") {
      return new Tax(0.13, 0, amount, currency);
    } else if (
      HST_15_PROVINCE.findIndex((p) => p.code === provinceCode) !== -1
    ) {
      return new Tax(0.15, 0, amount, currency);
    }
  }
  return new Tax(0, 0, amount, "USD");
};

// ------------------------------

export const getPayAmountAndCredits = (totalAmount, credits) => {
  if (credits > 0) {
    if (credits >= totalAmount) {
      return {
        payAmount: 0,
        creditsUsed: totalAmount,
        creditsRemaining: credits - totalAmount,
      };
    } else {
      return {
        payAmount: totalAmount - credits,
        creditsUsed: credits,
        creditsRemaining: 0,
      };
    }
  }

  return {
    payAmount: totalAmount,
    creditsUsed: 0,
    creditsRemaining: 0,
  };
};
