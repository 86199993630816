import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import EN_TRANSLATION from "./language/En";
import FR_TRANSLATION from "./language/Fr";
import { isProd } from "./globals/Logger";

i18n.use(LanguageDetector).init({
  resources: {
    en: {
      translations: EN_TRANSLATION,
    },
    fr: {
      translations: FR_TRANSLATION,
    },
  },
  fallbackLng: "en",
  debug: !isProd(),

  ns: ["translations"],
  defaultNS: "translations",

  keySeparator: false,

  interpolation: {
    escapeValue: false,
    formatSeparator: ",",
  },

  react: {
    wait: true,
  },
});

export default i18n;
