import { fromNow } from "../globals/Dates2";

class InboxChat {
  constructor(
    id,
    count,
    last_date,
    last_message,
    last_reply_uid,
    provider_uid,
    provider_name,
    owner_uid,
    owner_name,
    is_mobile_user,
    by_user
  ) {
    this.id = id;
    this.count = count;
    this.last_date = last_date;
    this.last_message = last_message;
    this.last_reply_uid = last_reply_uid;
    this.provider_uid = provider_uid;
    this.provider_name = provider_name;
    this.owner_uid = owner_uid;
    this.owner_name = owner_name;
    this.is_mobile_user = is_mobile_user;
    this.by_user = by_user;
  }

  fromNow = () => {
    return fromNow(this.last_date);
  };

  resolveMe = () => {
    if (this.is_mobile_user) {
      return this.last_reply_uid === this.owner_uid;
    } else {
      return this.last_reply_uid === this.provider_uid;
    }
  };

  resolveName = () => {
    if (this.is_mobile_user) {
      return this.provider_name;
    } else {
      return this.owner_name;
    }
  };
}

export default InboxChat;
