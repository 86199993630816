import React from "react";
import styles from "./Image.module.css";
import CameraAltIcon from "@material-ui/icons/CameraAlt";
import PersonIcon from "@material-ui/icons/Person";

const Image = (props) => {
  let imageContent = (
    <div className={styles.imagePlain}>
      <div>
        <CameraAltIcon style={{ color: "#CCC" }} />
      </div>
      <div>No Photo Available</div>
    </div>
  );

  if (props.person) {
    imageContent = (
      <div
        className={styles.imagePlain}
        style={props.rounded ? { borderRadius: "50%" } : null}
      >
        <div>
          <PersonIcon style={{ color: "#CCC", fontSize: "190px" }} />
        </div>
      </div>
    );
  }

  if (props.image) {
    imageContent = (
      <div
        className={styles.imagePlain}
        style={
          props.rounded
            ? { borderRadius: "50%" }
            : props.handcursor
            ? { cursor: "pointer" }
            : null
        }
      >
        <img src={props.image} alt="" />
      </div>
    );
  }

  return <div>{imageContent}</div>;
};

export default Image;
