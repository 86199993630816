import React from "react";
import styles from "./Footer.module.css";
import TextButton from "../button/TextButton";

import instaImg from "../../../assets/images/welcome/instagram.png";
import facebookImg from "../../../assets/images/welcome/facebook.png";
import { useTranslation } from "react-i18next";
import PhoneBadges from "../phoneBadges/PhoneBadges";

const Footer = (props) => {
  const { t } = useTranslation();
  // ------------------------------

  const home = () => {
    props.history.push("/");
  };

  const termsOfUseHandler = () => {
    props.history.push("/generalterms");
  };

  const privacyNoticeHandler = () => {
    props.history.push("/privacy");
  };

  const supplierTermsOfUseHandler = () => {
    props.history.push("/supplierterms");
  };

  const signup = () => {
    props.history.push("/register");
  };

  const aboutus = () => {
    props.history.push("/aboutus");
  };

  // ------------------------------

  if (props.home) {
    return (
      <div className={styles.footer2}>
        <div className={styles.footer2Row}>
          <div
            className={styles.footer2links}
            onClick={() => {
              window.open("mailto:info@zubueducation.com", "_blank");
            }}
          >
            {t("g_contact_us")}
          </div>
          <div className={styles.footer2links} onClick={termsOfUseHandler}>
            {t("sign_up_general_terms")}
          </div>
          <div
            className={styles.footer2links}
            onClick={supplierTermsOfUseHandler}
          >
            {t("sign_up_supplier_terms")}
          </div>
          <div className={styles.footer2links} onClick={privacyNoticeHandler}>
            {t("sign_up_privacy_policy")}
          </div>
        </div>

        <div className={styles.row}>
          <a
            href="https://www.facebook.com/zubueducation"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={facebookImg} alt="" className={styles.iconsImage} />
          </a>
          <a
            href="https://www.instagram.com/zubueducation/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={instaImg} alt="" className={styles.iconsImage} />
          </a>
        </div>

        <div className={styles.footer2company}>
          &copy; ZubU Inc - {new Date().getFullYear()}
        </div>
      </div>
    );
  }

  return (
    <div
      className={
        props.drawerOpen
          ? [styles.footer, styles.margin].join(" ")
          : styles.footer
      }
      style={{ backgroundColor: props.notTransparent ? "white" : null }}
    >
      {props.isAuthenticated ? null : (
        <div className={styles.row2}>
          <TextButton
            onClick={home}
            style={{
              fontSize: "11px",
              color: "gray",
            }}
          >
            {t("g_home")}
          </TextButton>
          <div className={styles.seperator}> | </div>
          <TextButton
            onClick={aboutus}
            style={{
              fontSize: "11px",
              color: "gray",
            }}
          >
            {t("g_about_us")}
          </TextButton>
          <div className={styles.seperator}> | </div>
          <TextButton
            onClick={signup}
            style={{
              fontSize: "11px",
              color: "gray",
            }}
          >
            {t("g_course_provider_signup")}
          </TextButton>

          <div className={styles.seperator}>|</div>
          <TextButton
            onClick={() => {
              window.open("mailto:info@zubueducation.com", "_blank");
            }}
            style={{
              fontSize: "11px",
              color: "gray",
            }}
          >
            {t("g_contact_us")}: info@zubueducation.com
          </TextButton>
        </div>
      )}

      <div>
        &nbsp;&nbsp;
        <TextButton onClick={termsOfUseHandler} style={{ fontSize: "11px" }}>
          {t("sign_up_general_terms")}
        </TextButton>
        &nbsp;&nbsp;
        <TextButton
          onClick={supplierTermsOfUseHandler}
          style={{ fontSize: "11px" }}
        >
          {t("sign_up_supplier_terms")}
        </TextButton>
        &nbsp;&nbsp;
        <TextButton onClick={privacyNoticeHandler} style={{ fontSize: "11px" }}>
          {t("sign_up_privacy_policy")}
        </TextButton>
      </div>

      <div style={{ paddingTop: "13px" }}>
        <PhoneBadges plain />
      </div>

      <div className={styles.row2}>
        <div>&#169; ZubU Inc. {new Date().getFullYear()}</div>
        <a
          href="https://www.facebook.com/zubueducation"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={facebookImg} alt="" className={styles.iconsImage2} />
        </a>
        <a
          href="https://www.instagram.com/zubueducation/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={instaImg} alt="" className={styles.iconsImage2} />
        </a>
      </div>
    </div>
  );
};

export default Footer;
