import {
  SET_PROFESSOR_ID,
  SET_PROFESSOR,
  SET_PROFESSOR_PICTURE,
  ADD_PROFESSOR,
  UPDATE_PROFESSOR,
  REMOVE_PROFESSOR,
} from "../actions/professor";
import { LOGOUT } from "../actions/auth";

import Professor from "../../model/Professor";
import { updateObject } from "../../globals/Common";

const initialState = {
  professorId: "",
  professor: [],
};

const professorReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_PROFESSOR_ID:
      return updateObject(state, { professorId: action.professorId });
    case SET_PROFESSOR:
      return updateObject(state, { professor: action.professor });
    case ADD_PROFESSOR:
      const newProf = new Professor(
        action.professor.id,
        action.professor.professor.prefix,
        action.professor.professor.first,
        action.professor.professor.name,
        action.professor.professor.occupation,
        action.professor.professor.bio,
        action.professor.professor.picture
      );

      return updateObject(state, {
        professor: state.professor.concat(newProf),
      });
    case UPDATE_PROFESSOR:
      const cIndex = state.professor.findIndex(
        (c) => c.id === action.professor.id
      );

      const updatedProfessor = new Professor(
        action.professor.id,
        action.professor.prefix,
        action.professor.first,
        action.professor.name,
        action.professor.occupation,
        action.professor.bio,
        ""
      );

      const updCopyProf = [...state.professor];
      updCopyProf[cIndex] = updatedProfessor;
      return updateObject(state, { professor: updCopyProf });
    case REMOVE_PROFESSOR:
      const remCopyProf = [...state.professor];

      const remaining = remCopyProf.filter((professor) => {
        return professor.id !== action.id;
      });

      return updateObject(state, { professor: remaining });

    case SET_PROFESSOR_PICTURE:
      const profIndex = state.professor.findIndex((c) => c.id === action.id);
      const updCopyProfArrPic = [...state.professor];
      const profCopy = { ...updCopyProfArrPic[profIndex] };

      const updCopyProfPic = new Professor(
        profCopy.id,
        profCopy.prefix,
        profCopy.first,
        profCopy.name,
        profCopy.occupation,
        profCopy.bio,
        action.picture
      );
      updCopyProfArrPic[profIndex] = updCopyProfPic;

      return updateObject(state, { professor: updCopyProfArrPic });
    case LOGOUT:
      return initialState;
    default:
      return state;
  }
};

export default professorReducer;
