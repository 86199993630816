import React from "react";
import styles from "./PaymentItem.module.css";
import { resolveCc } from "../../../globals/Common";
import PopMenu from "../../../components/ui/popMenu/PopMenu";
import MenuItem from "@material-ui/core/MenuItem";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import TitleMenu from "../../../components/ui/titleMenu/TitleMenu";

const PaymentItem = (props) => {
  // ------------------------------

  const [anchorEl, setAnchorEl] = React.useState(null);

  // ------------------------------

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  // ------------------------------

  const handleClose = () => {
    setAnchorEl(null);
  };

  // ------------------------------

  const renderDelete = () => (
    <MenuItem
      onClick={() => {
        handleClose();
        props.delete();
      }}
    >
      <TitleMenu icon={<DeleteOutlineIcon />} title="Delete" />
    </MenuItem>
  );

  // ------------------------------

  const cc = resolveCc(props.brand, 35);

  // ------------------------------

  return (
    <div className={styles.itemContainer} onClick={props.onClick.bind(this)}>
      <div className={styles.column}>
        {cc}
        <div className={styles.maskedColumn}>{props.lastDigits}</div>
      </div>

      <div className={styles.column}>Expires: {props.cardExpiry}</div>

      {props.delete && (
        <div className={styles.column}>
          <PopMenu
            anchorEl={anchorEl}
            handleClick={handleClick}
            handleClose={handleClose}
          >
            {renderDelete()}
          </PopMenu>
        </div>
      )}
    </div>
  );
};

export default PaymentItem;
