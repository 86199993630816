import React, { Fragment, Component } from "react";
import { connect } from "react-redux";
import styles from "./LivestreamScreen.module.css";
import * as zoomActions from "../../../store/actions/zoom";
import Spinner from "../../../components/ui/spinner/Spinner";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import InfoModal from "../../../components/modals/infoModal/InfoModal";
import SimpleContainer from "../../../components/ui/container/Container";
import VideocamIcon from "@material-ui/icons/Videocam";
import FormButton from "../../../components/ui/button/FormButton";
import AlertSuccess from "../../../components/ui/snackbar/AlertSuccess";
import * as logger from "../../../globals/Logger";
import zoomImage from "../../../assets/images/inApp/zoom.png";
import { zoomApiKey, zoomRedirectUrl } from "../../../globals/ApiKeys";
import PhonelinkEraseIcon from "@material-ui/icons/PhonelinkErase";
import SystemUpdateIcon from "@material-ui/icons/SystemUpdate";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";

class LivestreamScreen extends Component {
  state = {
    wait: false,
    waitMessage: "",
    success: null,
    error: null,
  };

  // ------------------------------

  update = async (propValue) => {
    await this.setState({ ...this.state, ...propValue });
  };

  // ------------------------------

  componentDidMount = async () => {
    window.scrollTo(0, 0);

    try {
      if (this.props.code) {
        await this.tryConnectZoom();
      } else {
        await this.fetchZoomSettings();
      }
    } catch (error) {
      logger.error(error);
      this.update({ wait: false, error: error.message });
    }
  };

  // ------------------------------

  fetchZoomSettings = async () => {
    await this.update({
      wait: true,
    });
    await this.props.fetchZoomSettings();
    await this.update({
      wait: false,
    });
  };

  // ------------------------------

  tryConnectZoom = async () => {
    await this.update({
      wait: true,
      waitMessage: "Connecting",
    });
    await this.props.connectZoom(this.props.code);
    await this.update({ wait: false, error: null, success: "Connected" });
  };

  // ------------------------------

  callZoomOath = async () => {
    window.location.replace(
      `https://zoom.us/oauth/authorize?response_type=code&client_id=${zoomApiKey}&redirect_uri=${zoomRedirectUrl}`
    );
  };

  // ------------------------------

  render() {
    if (this.state.wait) {
      return <Spinner message={this.state.waitMessage} />;
    }

    return (
      <div style={{ background: "white" }}>
        {this.state.success !== null && (
          <AlertSuccess
            message={this.state.success}
            open={this.state.success !== null}
            onClose={() => {
              this.update({ success: null });
            }}
          />
        )}

        <SimpleContainer disableGutters={false}>
          <InfoModal
            isErrorModal={true}
            title="An error has occurred"
            message={this.state.error}
            open={this.state.error !== null}
            onClose={() => {
              this.update({ error: null });
            }}
          />

          <div style={{ height: "40px" }}>&nbsp;</div>

          <div className={styles.sectionTitle}>
            <div className={styles.centerRow}>
              <VideocamIcon style={{ color: "white" }} />
              &nbsp;&nbsp;Livestream
            </div>
          </div>

          <div className={styles.sectionContainer}>
            <div className={styles.flowContainer}>
              <div style={{ paddingRight: "10px" }}>
                <InfoOutlinedIcon />
              </div>

              <div className={styles.brandingDesc}>
                Integration with your favorite video conferencing app is now
                available. Allow ZubU to manage registration, send meeting
                details and generate unique links to individual participants who
                register via the ZubU mobile app.
              </div>
            </div>
          </div>

          <div className={styles.sectionContainer}>
            <div className={styles.card}>
              <img src={zoomImage} alt="Zoom" style={{ height: "90px" }} />
              {this.props.connected ? (
                <Fragment>
                  <div className={styles.spaceBetween}>
                    <FormButton
                      label="Disconnect"
                      onClick={this.disconnectZoom}
                      alwaysfull="true"
                      endIcon={<PhonelinkEraseIcon />}
                      disabled={true}
                    />
                    <div className={styles.disclaimer}>
                      To disconnect, login to Zoom.<br></br>Select Admin{" "}
                      <ArrowForwardIcon fontSize="inherit" /> Advanced{" "}
                      <ArrowForwardIcon fontSize="inherit" />
                      App Marketplace <br></br>
                      Manage <ArrowForwardIcon fontSize="inherit" />
                      Installed Apps <ArrowForwardIcon fontSize="inherit" />{" "}
                      Uninstall.
                    </div>
                  </div>
                </Fragment>
              ) : (
                <FormButton
                  label="Connect"
                  onClick={this.callZoomOath}
                  alwaysfull="true"
                  endIcon={<SystemUpdateIcon />}
                />
              )}
            </div>
            <div style={{ paddingTop: "10px", fontStyle: "italic" }}></div>
          </div>
        </SimpleContainer>

        <div style={{ height: "80px" }}>&nbsp;</div>
      </div>
    );
  }
}

// ------------------------------

const parseQueryStringZoomCode = (props) => {
  const query = new URLSearchParams(props.location.search);

  for (let param of query.entries()) {
    if (param[0] === "code") {
      return param[1];
    }
  }

  return null;
};

// ------------------------------

const mapStateToProps = (state, props) => {
  const code = parseQueryStringZoomCode(props);

  return {
    connected: state.zoom.connected,
    code: code,
  };
};

// ------------------------------

const mapDispatchToProps = (dispatch) => {
  return {
    fetchZoomSettings: () => dispatch(zoomActions.fetchZoomSettings()),
    connectZoom: (code) => dispatch(zoomActions.connectZoom(code)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LivestreamScreen);
