import React from "react";
import styles from "./CeItem.module.css";
import { useTranslation } from "react-i18next";
import PopMenu from "../../../components/ui/popMenu/PopMenu";
import EditIcon from "@material-ui/icons/Edit";
import MenuItem from "@material-ui/core/MenuItem";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import TitleMenu from "../../../components/ui/titleMenu/TitleMenu";
import Divider from "@material-ui/core/Divider";
import AttachmentIcon from '@material-ui/icons/Attachment';
import BarChartIcon from '@material-ui/icons/BarChart';
import NumberFormat from "react-number-format";
import PageviewOutlinedIcon from "@material-ui/icons/PageviewOutlined";
import { resolveCurrencySymbol } from "../../../globals/Common";

const CeItem = (props) => {
    const { t } = useTranslation();
    const [anchorEl, setAnchorEl] = React.useState(null);

    // -----------
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    // ------------------------------

    const handleClose = () => {
        setAnchorEl(null);
    };

    // ------------------------------

    const renderEdit = () => (
        <MenuItem
            onClick={() => {
                handleClose();
                props.edit();
            }}
        >
            <TitleMenu icon={<EditIcon />} title={t("g_edit")} />
        </MenuItem>
    );


    // ------------------------------

    const renderViewCourse = () => (
        <MenuItem
            onClick={() => {
                handleClose();
                props.viewCourse();
            }}
        >
            <TitleMenu icon={<PageviewOutlinedIcon />} title={t("registration_view_course")} />
        </MenuItem>
    );

    // ------------------------------

    const renderDelete = () => (
        <MenuItem
            onClick={() => {
                handleClose();
                props.delete();
            }}
        >
            <TitleMenu icon={<DeleteOutlineIcon />} title={t("g_delete")} />
        </MenuItem>
    );

    // ------------------------------

    return (
        <div className={styles.itemContainer}>
            <div className={styles.columnContainer}>
                <div className={styles.rowContainer}>
                    <div className={styles.row}>
                        {props.hasCertificate === true && (
                            <AttachmentIcon fontSize="inherit"
                                style={{ paddingRight: 4 }}
                            />
                        )}
                        <div className={styles.title} style={{ fontWeight: "bold" }}>{props.title}</div>
                    </div>
                    <div className={styles.title2} style={{ fontWeight: "bold" }}>
                        {props.readableDate}
                    </div>
                </div>
                <div className={styles.rowContainer}>
                    <div className={styles.row}>
                        <BarChartIcon fontSize="inherit" />
                        <div className={styles.title2}>
                            &nbsp;({t("pricing_option_credits")}: {props.credits} /{" "}{t("g_price")}:&nbsp;
                            <NumberFormat
                                value={props.price}
                                displayType={"text"}
                                thousandSeparator={true}
                                prefix={resolveCurrencySymbol(props.currency)}
                            />)
                        </div>
                    </div>
                    {props.category && (
                        <div className={styles.title2}>
                            {props.category && props.category.length === 1
                                ? props.category[0]
                                : props.category && props.category.length > 1
                                    ? props.category[0] +
                                    " +" +
                                    (props.category.length - 1)
                                    : ""}
                        </div>
                    )}
                </div>
            </div>

            <div className={styles.column1}>
                <PopMenu
                    anchorEl={anchorEl}
                    handleClick={handleClick}
                    handleClose={handleClose}
                >
                    {renderEdit()}
                    <Divider />
                    {renderDelete()}
                </PopMenu>
            </div>
        </div >
    );
};

export default CeItem;
